import settings from 'Cloud/settings';
import { useSelector } from 'react-redux';
import { ALLOWED_INDEX_TITLE } from 'reactApp/appHelpers/configHelpers';
import { seoEnable } from 'reactApp/appHelpers/featuresHelpers/features/seoEnable';
import { AllDocumentsDomainMeta } from 'reactApp/modules/allDocuments/allDocuments.constants';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getFeatureStartPageRoot } from 'reactApp/modules/features/features.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import type { StockFolder } from 'reactApp/modules/stock/stock.type';
import { getPageTitleByStorage } from 'reactApp/modules/storage/storage.helpers';
import { getStorageCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getHasError, UserSelectors } from 'reactApp/modules/user/user.selectors';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import type { RootState } from 'reactApp/store';
import { ON_PREMISE } from 'server/constants/environment';

export const useDataContainer = () => {
    const hasError = useSelector(getHasError);
    const isPhone = useSelector(EnvironmentSelectors.isPhone);
    const isRootToReactPage = !!useSelector(getFeatureStartPageRoot);
    const storage = useSelector(getCurrentStorage);
    const isB2b = useSelector(UserSelectors.isB2BUser);
    const viewerItem = useSelector(ViewerSelectors.getViewerItem);
    const currentFolder = useSelector((state: RootState) => getStorageCurrentFolder(state, storage));

    let itemTitle = '';

    if (currentFolder && 'name' in currentFolder) {
        if (storage === EStorageType.alldocuments) {
            // В doc.mail.ru разделы - это папки, из-за этого  тайтле document на англ.
            // AllDocumentsDomainMeta кастить к русскому названию
            itemTitle = currentFolder.name
                ? AllDocumentsDomainMeta[currentFolder.name]?.name || currentFolder.name
                : AllDocumentsDomainMeta.trashbin.name;
        } else if (storage === EStorageType.trashbin) {
            itemTitle = '';
        } else {
            itemTitle = currentFolder.name;
        }
    }

    if (storage === EStorageType.stock && !(currentFolder as unknown as StockFolder)?.parent) {
        itemTitle = settings?.storages?.[EStorageType.stock]?.title;
    } else if (viewerItem?.name) {
        itemTitle = viewerItem.name;
    }

    if (ALLOWED_INDEX_TITLE) {
        itemTitle = ALLOWED_INDEX_TITLE;
    } else if (
        seoEnable &&
        !ON_PREMISE &&
        !isB2b &&
        storage === EStorageType.public &&
        currentFolder &&
        'weblinkSeoEnabled' in currentFolder &&
        currentFolder?.weblinkSeoEnabled
    ) {
        itemTitle = currentFolder.name || itemTitle;
    }

    const title = getPageTitleByStorage(storage, itemTitle);

    return {
        hasError,
        isPhone,
        isRootToReactPage,
        title,
        storage,
    };
};
