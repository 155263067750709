import type { DomainAccess } from 'reactApp/components/SharingWindow/Sharing.types';
import type { WeblinkDownloadable, WeblinkDownloadsCounts } from 'reactApp/modules/public/public.types';
import type { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { AccessRights, Count, Kind, SubKind, Url, VirusScan, Weblink } from 'reactApp/types/Tree';
import type { ESearchOptionSource } from 'reactApp/ui/WebSearch/WebSearch.data';

export interface ApiSearchResponseFolder extends WeblinkDownloadsCounts {
    name_parts?: [string, 0 | 1][];
    home: string;
    kind: Kind;
    name: string;
    size: number;
    public: Weblink;
    favorite?: 1 | 0;
    srch_src: string;
}

export interface ApiSearchFolderResponseFile {
    name_parts?: [string, 0 | 1][];
    home: string;
    illegal: 1 | 0;
    blocked: 1 | 0;
    favorite?: 1 | 0;
    hash: string;
    kind: Kind;
    mtime: number;
    name: string;
    size: number;
    virus_scan: VirusScan;
    public: Weblink;
    srch_src: string;
}

export type SearchFile = Omit<ApiSearchResponseFile, 'srch_src'> & { srchSrc?: ESearchOptionSource };

export type ApiSearchResponceList = (ApiSearchResponseFolder | ApiSearchFolderResponseFile)[];

export interface ApiSearchResponseFile extends WeblinkDownloadable {
    home?: string;
    weblink?: string;
    size: number;
    subKind: SubKind;
    kind: Kind;
    parent: '/';
    isFolder: false;
    hash: string;
    virusScan: VirusScan;
    parentFolder: string;
    mtime: number;
    pos?: number;
    srch_src?: string;
    ext: string;
    nameWithoutExt: string;
    name: string;
    id: string;
    storage: EStorageType.search;
    isIllegal: boolean;
    isBlocked: boolean;
    nameParts?: [string, 0 | 1][];
    thumbnails?: {
        [key: string]: string;
    };
    url?: Url;
    __reduxTree: true;
    weblinkAccessRights?: AccessRights;
    weblinkExpires?: number;
    ctime?: number;
    expiresTimeSize?: number;
    weblinkDomestic?: boolean;
    weblinkDomainAccess?: DomainAccess;
    weblinkSeoEnabled?: boolean;
    weblinkEnabledDisabledDownload?: boolean;
    isInFavorites?: boolean;
    isReadOnly?: boolean;
    intraDomainShare?: boolean;
}

export interface IHistoryItem {
    text: string;
}

export interface SearchFolder extends WeblinkDownloadable {
    kind: Kind;
    weblink?: string;
    parentFolder: string;
    id: string;
    name: string;
    storage: EStorageType.search;
    parent: '/';
    isFolder: true;
    home?: string;
    pos?: number;
    nameParts?: [string, 0 | 1][];
    __reduxTree: true;
    weblinkAccessRights?: AccessRights;
    weblinkExpires?: number;
    ctime?: number;
    expiresTimeSize?: number;
    weblinkDomestic?: boolean;
    weblinkDomainAccess?: DomainAccess;
    weblinkAutoDelete?: boolean;
    weblinkSeoEnabled?: boolean;
    weblinkEnabledDisabledDownload?: boolean;
    isInFavorites?: boolean;
    isReadOnly?: boolean;
    intraDomainShare?: boolean;
}

export type SearchItem = SearchFolder | SearchFile;

export interface SearchRequestParams {
    query: string;
    xPageId: string;
    xReqId: string;
    path?: string;
    type?: string;
    source?: string;
}

export interface SearchHistoryRequestParams {
    xPageId: string;
    xReqId: string;
}
export interface SearchState {
    '/': {
        count: Count;
        id: '/';
        childs: string[];
        isLoading: boolean;
        isLoaded: boolean;
        hasMoreToLoad: boolean;
        name: string;
        error: boolean;
    };
    list: {
        [key: string]: SearchItem;
    };
    listFull: (ApiSearchResponseFolder | ApiSearchFolderResponseFile)[];
    listFullOrigin: Record<
        Exclude<ESearchOptionSource, ESearchOptionSource.all>,
        (ApiSearchResponseFolder | ApiSearchFolderResponseFile)[]
    >;
    searchSection: ESearchOptionSource | null;
    requestParams: SearchRequestParams;
    searchHistoryRequestParams: SearchHistoryRequestParams;
    offset: number;
    searchStartLocation: { id: string; search: string };
    searchHistory: IHistoryItem[];
    emptyFilters: boolean;
    webSearchContentEnabled: boolean;
    touchSearchContentEnabled: boolean;
    contentIndexed?: boolean;
}

export interface splitBySource {
    [key: string]: (ApiSearchResponseFolder | ApiSearchFolderResponseFile)[];
}

export interface NewSearchApiRequestParams {
    query: string;
    path: string;
    limit: number;
    offset?: number;
    depth: number;
    exts?: string;
    type?: string;
    size?: { from: number; till: number };
    mtime?: { from: number; till: number };
    new_srch?: boolean;
    srch_src?: string;
    filesrch_type?: string;
    folder_size?: boolean;
}

export interface SearchHistoryGetApiRequestParams {
    query: string;
}

export interface SearchLoadMoreData {
    offset: number;
    list: (ApiSearchResponseFolder | ApiSearchFolderResponseFile)[];
}

export interface VirusScanResponce {
    [key: string]: {
        illegal: number;
        blocked: number;
        virus_scan: string;
    };
}

export interface SearchAnalyticsParams {
    place: 'touch' | 'web';
    name_files?: string;
    type_content?: string;
    pos?: number;
    hash?: string;
    size?: number;
    section: string;
    search_phrase: string;
    page_id: string;
    req_id: string;
}

export interface SearchLoadParams {
    queryParam?: string;
    type?: string;
    folder?: string;
    isAlbumsSearch?: boolean;
    searchSection?: ESearchOptionSource;
}

export enum EActionSearch {
    searchClose = 'search-close',
    searchClear = 'search-clear',
    filtersPlacement = 'filters-placement',
    filters = 'filters',
    copyPastText = 'copy-paste-text',
    openInNewTab = 'open-in-new-tab',
    initialClick = 'initial_click',
    closePublic = 'close-public',
}

export enum EActionSearchHistory {
    show = 'show',
    delete = 'delete',
    clear = 'clear',
    open = 'open',
}

export interface SearchLoadSuccessParams {
    history: IHistoryItem[];
    searchHistoryParams: {
        xPageId: string;
        xReqId: string;
    };
}

export enum SearchSourceTitle {
    filename = 'По названию',
    text_content = 'По содержанию',
}

export interface fakeLoadSuccessParams {
    origin: Record<Exclude<ESearchOptionSource, ESearchOptionSource.all>, (ApiSearchResponseFolder | ApiSearchFolderResponseFile)[]>;
    filtered: (ApiSearchResponseFolder | ApiSearchFolderResponseFile)[];
    searchSection?: ESearchOptionSource;
}
