import type { IProduct } from 'reactApp/modules/products/products.types';
import type { Tariff } from 'reactApp/types/Billing';

import type { TABS_MAP } from './MobileSubscriptionsPage.constants';

// Клиент, который может открыть вебвью. Передается параметром &client=android|ios
export enum IClient {
    ANDROID = 'android',
    IOS = 'ios',
}

export enum EPostMessageBuy {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
}

export type TabValue = keyof typeof TABS_MAP;

export type TOpenTariffBuy = (params: { id: string; period?: string; price?: number; shouldSendGa?: boolean; paySource: string }) => void;

export interface ITariffsMobile extends Pick<Tariff, 'space'> {
    countSnapshotsBySpace?: number;
    fullPrice?: number;
    hasOldPriceYear: boolean;
    products: IProduct[];
    isBase?: boolean;
}
