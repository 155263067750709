import { requestPayment } from 'Cloud/Application/Payment';
import blockedCloud from 'img/icons/cloud-blocked.svg?url';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getProductById } from 'reactApp/modules/products/products.selectors';
import type { RootState } from 'reactApp/store';
import { type UseContentHook, ESplashScreenMod } from 'reactApp/ui/Mobile/SplashScreen/SplashScreen.types';

export const useOverquotaSplashScreen: UseContentHook = ({ hookParams = {} }) => {
    const product = useSelector((state: RootState) => getProductById(state, hookParams?.productId));

    const description =
        'Хранилище переполнено и вы больше не можете загружать фото, делиться файлами и создавать папки. Для снятия ограничения увеличьте Облако.';

    const onClick = useCallback(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        requestPayment({ isMobile: true, tariff: product?.id, paySource: 'overquota_splash' });
    }, [product?.id]);

    return {
        id: 'mobile-overquota',
        title: 'Работа вашего Облака остановлена',
        description,
        buttonText: `Увеличить на ${product?.space?.value}`,
        onClick,
        img: blockedCloud,
        mod: ESplashScreenMod.rebranding,
    };
};
