import { type PayloadAction, createAction, createReducer } from '@reduxjs/toolkit';
import { ONLY_YOURS_ALLDOCS_CATEGORY_ENABLED } from 'reactApp/appHelpers/featuresHelpers/features/onlyYoursAllDocumentsCategory';
import { SHARED_WITH_YOU_ALLDOCS_CATEGORY_ENABLED } from 'reactApp/appHelpers/featuresHelpers/features/sharedWithYouAllDocumentsCategory';
import { YOU_SHARED_ALLDOCS_CATEGORY_ENABLED } from 'reactApp/appHelpers/featuresHelpers/features/youSharedAllDocumentsCategory';
import { getExtension, isFolder } from 'reactApp/modules/file/utils';
import { addFilesSuccess, removeFileSuccess, renameItemSuccess } from 'reactApp/modules/modifying/modifying.actions';
import type { IRenameItemSuccess } from 'reactApp/modules/modifying/modifying.types';

import {
    type AllDocumentsCategory,
    type AllDocumentsSections,
    type EAllDocumentsType,
    type IAllDocumentsState,
    type IWeblinksIncomingData,
    AllDocumentsCategoryType,
} from './allDocuments.types';

export const allDocumentsLoadRequest = createAction<{ docType: EAllDocumentsType }>('allDocuments/loadRequest');
export const allDocumentsAdditionalLoadRequest = createAction<AllDocumentsSections>('allDocuments/additionalLoadRequest');
export const allDocumentsMoreRequest = createAction('allDocuments/loadMoreRequest');
export const allDocumentsLoadSuccess = createAction<{
    type: EAllDocumentsType | undefined | null;
    category: AllDocumentsCategoryType;
    data?: { cursor?: string };
    idxs: string[];
    docsSize: number;
}>('allDocuments/loadSuccess');
export const allDocumentsIncomingLoadSuccess = createAction<{
    type: EAllDocumentsType | undefined | null;
    data?: IWeblinksIncomingData;
}>('allDocuments/incomingLoadSuccess');
export const allDocumentsLoadError = createAction<{
    type: EAllDocumentsType | undefined | null;
    category: AllDocumentsCategoryType;
    error: string;
}>('allDocuments/loadError');
export const resetCurrentAllDocumentType = createAction('allDocuments/resetCurrentAllDocumentType');
export const changeAllDocumentsCategory = createAction<{ category: AllDocumentsCategoryType }>('allDocuments/changeCategory');

const categoryBaseProps = {
    childs: [],
    cursor: '',
    isLoaded: false,
    isLoading: false,
    hasMoreToLoad: false,
};

const assemble_categories = (): AllDocumentsCategory[] => {
    const categories = [
        {
            name: 'Все',
            id: AllDocumentsCategoryType.all,
            value: AllDocumentsCategoryType.all,
            ...categoryBaseProps,
        },
    ];

    if (ONLY_YOURS_ALLDOCS_CATEGORY_ENABLED) {
        categories.push({
            name: 'Только ваши',
            id: AllDocumentsCategoryType.onlyYours,
            value: AllDocumentsCategoryType.onlyYours,
            ...categoryBaseProps,
        });
    }

    if (YOU_SHARED_ALLDOCS_CATEGORY_ENABLED) {
        categories.push({
            name: 'Вы поделились',
            id: AllDocumentsCategoryType.youShared,
            value: AllDocumentsCategoryType.youShared,
            ...categoryBaseProps,
        });
    }

    if (SHARED_WITH_YOU_ALLDOCS_CATEGORY_ENABLED) {
        categories.push({
            name: 'С вами поделились',
            id: AllDocumentsCategoryType.sharedWithYou,
            value: AllDocumentsCategoryType.sharedWithYou,
            ...categoryBaseProps,
        });
    }

    return categories;
};

const initialState: IAllDocumentsState = {
    currentDocType: null,
    isLoaded: false,
    isLoading: false,
    hasMoreToLoad: false,
    cursor: '',
    idxs: [],
    docsSize: 0,
    currentSection: null,
    prevSection: null,

    categories: assemble_categories(),
    currentCategory: AllDocumentsCategoryType.all,
};

export const allDocumentsReducer = createReducer(initialState, {
    [allDocumentsLoadRequest.type]: (state, action: ReturnType<typeof allDocumentsLoadRequest>) => {
        state.hasMoreToLoad = false;
        state.cursor = '';
        state.idxs = [];
        state.docsSize = 0;
        state.isLoaded = false;
        state.isLoading = true;
        state.prevSection = state.currentSection;
        state.currentSection = action.payload.docType;
        state.currentDocType = action.payload.docType;
        state.error = '';
        state.idxs = [];
        state.currentCategory = AllDocumentsCategoryType.all;
    },
    [allDocumentsAdditionalLoadRequest.type]: (state, action: ReturnType<typeof allDocumentsLoadRequest>) => {
        state.hasMoreToLoad = false;
        state.cursor = '';
        state.idxs = [];
        state.docsSize = 0;
        state.isLoaded = true;
        state.isLoading = false;
        state.prevSection = state.currentSection;
        state.currentSection = action.payload.docType;
        state.currentDocType = null;
        state.error = '';
        state.idxs = [];
        state.currentCategory = AllDocumentsCategoryType.all;
    },
    [allDocumentsLoadSuccess.type]: (state, action: ReturnType<typeof allDocumentsLoadSuccess>) => {
        const { type, data, idxs, docsSize, category } = action.payload;

        if (type === state.currentDocType && state.currentCategory === category) {
            state.isLoaded = true;
            state.isLoading = false;

            state.cursor = data?.cursor || '';
            state.hasMoreToLoad = !!data?.cursor;
            state.idxs = [...new Set([...state.idxs, ...idxs])];
            state.docsSize = docsSize;
        }
    },
    [allDocumentsLoadError.type]: (state, action: ReturnType<typeof allDocumentsLoadError>) => {
        if (action.payload.type === state.currentDocType && state.currentCategory === action.payload.category) {
            state.isLoaded = true;
            state.isLoading = false;
            state.error = action.payload.error;
        }
    },
    [resetCurrentAllDocumentType.type]: () => initialState,
    [removeFileSuccess.type]: (state, action: ReturnType<typeof removeFileSuccess>) => {
        const { ids } = action.payload;
        state.idxs = state.idxs.filter((id) => !ids.includes(id));
    },
    [addFilesSuccess.type]: (state, action: ReturnType<typeof addFilesSuccess>) => {
        if (
            state.currentCategory === AllDocumentsCategoryType.youShared ||
            state.currentCategory === AllDocumentsCategoryType.sharedWithYou
        ) {
            return;
        }

        const { items, allowedExt } = action.payload;
        items.forEach((item) => {
            const ext = getExtension(item.name);
            if (!isFolder(item) && allowedExt?.includes(ext)) {
                state.idxs.unshift(item.id);
            }
        });
    },
    [renameItemSuccess.type]: (state, action: PayloadAction<IRenameItemSuccess>) => {
        const { newItem, oldId } = action.payload;
        const file: { id: string } = newItem;
        const index = state.idxs.indexOf(oldId);

        if (!file || !oldId) {
            return;
        }

        state.idxs[index] = file.id;
    },
    [changeAllDocumentsCategory.type]: (state, action: ReturnType<typeof changeAllDocumentsCategory>) => {
        state.currentCategory = action.payload.category;
        const category = state.categories.find((category) => category.id === action.payload.category);

        if (category) {
            state.isLoaded = false;
            state.isLoading = true;
            state.docsSize = 0;
            state.hasMoreToLoad = false;
            state.cursor = '';
            state.idxs = [];
            category.cursor = '';
        }
    },
});
