import classNames from 'clsx';
import React, { type ReactElement, memo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ENABLE_FULL_RESPONSIVE, NEW_PORTAL_HEADER } from 'reactApp/appHelpers/configHelpers';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { FileHistorySelectors } from 'reactApp/modules/fileHistory/fileHistory.selectors';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import type { RootState } from 'reactApp/store';
import { ROW_HEIGHT } from 'reactApp/ui/Datalist/DataList.constants';
import { sendFilesHistoryGa } from 'reactApp/ui/FileHistory/FileHistory.helpers';
import type { IFileHistoryListProps } from 'reactApp/ui/FileHistory/FileHistory.types';
import { FileHistoryItem } from 'reactApp/ui/FileHistory/FileHistoryList/FileHistoryItem';
import { VirtualList } from 'reactApp/ui/VirtualList/VirtualList';
import { VirtualList as VirtualListNew } from 'reactApp/ui/VirtualList/VirtualList.new';
import { EDividerSize } from 'reactApp/ui/VirtualList/VirtualList.types';
import { noopVoid } from 'reactApp/utils/helpers';

import styles from './FileHistoryList.css';

const DIVIDER_HEIGHT = 40;

export const FileHistoryList = memo<IFileHistoryListProps>(({ itemId, onPaidInfo }): ReactElement => {
    const items = useSelector((state: RootState) => FileHistorySelectors.getIds(state, itemId));

    const rootRef = useRef<any>(null);

    const renderItem = (id: string) => <FileHistoryItem id={id} onPaidInfo={onPaidInfo} />;

    useEffect(() => {
        if (items.length) {
            // если нет версий то отправляем empty-list (тк в списке еще есть divider, то в проверке надо его учитывать)
            sendFilesHistoryGa('show', items.length === 2 ? 'empty-list' : 'list');
        }
    }, [items]);

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_responsive]: ENABLE_FULL_RESPONSIVE,
                [styles['root_virtual-list']]: items.length > 10,
            })}
        >
            {NEW_PORTAL_HEADER ? (
                <VirtualListNew
                    className={isRebranding && styles.rebranding}
                    list={items}
                    renderItem={renderItem}
                    hasMoreToLoad={false}
                    viewMode={EViewMode.list}
                    rowHeight={ROW_HEIGHT}
                    dividerHeight={DIVIDER_HEIGHT}
                    dividerSize={EDividerSize.SMALL}
                    isLoading={false}
                    loadOnScroll={noopVoid}
                    customScrollElement={rootRef.current}
                />
            ) : (
                <VirtualList
                    list={items}
                    renderItem={renderItem}
                    hasMoreToLoad={false}
                    viewMode={EViewMode.list}
                    rowHeight={ROW_HEIGHT}
                    dividerHeight={DIVIDER_HEIGHT}
                    dividerSize={EDividerSize.SMALL}
                    isLoading={false}
                    loadOnScroll={noopVoid}
                    customScrollElement={rootRef.current}
                />
            )}
        </div>
    );
});

FileHistoryList.displayName = 'FileHistoryList';
