import { isFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_MOBILE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

export const repeatPurchaseEnabled = IS_MOBILE_BROWSER && isFeature('repeat-purchase-enabled');

registerExperiment(
    'repeat-purchase',
    repeatPurchaseEnabled ? 'on' : 'off',
    [
        AnalyticEventNames.STATUS_202_ON_BUY_SUBSCRIPTION,
        AnalyticEventNames.REPEAT_PURCHASE_MOBILE_SHOW,
        AnalyticEventNames.REPEAT_PURCHASE_MOBILE_CLICK,
        AnalyticEventNames.REPEAT_PURCHASE_MOBILE_CANCEL,
        AnalyticEventNames.REPEAT_PURCHASE_MOBILE_CLOSE,
    ],
    {
        mapEventNames: {
            STATUS_202_ON_BUY_SUBSCRIPTION: '202',
            REPEAT_PURCHASE_MOBILE_SHOW: 'show',
            REPEAT_PURCHASE_MOBILE_CLICK: 'click',
            REPEAT_PURCHASE_MOBILE_CANCEL: 'cancel',
            REPEAT_PURCHASE_MOBILE_CLOSE: 'close',
        },
    }
);
