import { Icon16GiftOutline } from '@vkontakte/icons';
import React, { type ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { IS_MBIZUSER } from 'reactApp/appHelpers/configHelpers';
import { isFamilySubsInTariff } from 'reactApp/appHelpers/featuresHelpers';
import { billingDataEndpointEnabled } from 'reactApp/appHelpers/featuresHelpers/features/billingDataEndpoint';
import { getDiscoutOutflowPercentByProductId } from 'reactApp/appHelpers/featuresHelpers/features/discountInOutflow';
import { isPromoQuotaProEnabled } from 'reactApp/appHelpers/featuresHelpers/features/sassQuotaProLanding';
import { features } from 'reactApp/constants/products/features';
import type { IFeatureData } from 'reactApp/constants/products/features.types';
import { CardSelectors } from 'reactApp/modules/creditCard/creditCard.selectors';
import { cheapestProductIDs } from 'reactApp/modules/products/products.const';
import { AUTOUPLOAD_10TB_REGEX } from 'reactApp/modules/products/products.helpers';
import { EPromoSubscriptionType } from 'reactApp/modules/promoTariffs/promoTariffs.types';
import { PARTNER_TITLE } from 'reactApp/modules/subscriptions/subscriptions.data';
import { EPartner, ESubscriptionsTypes } from 'reactApp/modules/subscriptions/subscriptions.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { PersonalDiscountPrice } from 'reactApp/sections/SubscriptionsPage/ui/PersonalDiscountPrice/PersonalDiscountPrice';
import { Card } from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionInfo/Card/Card';
import { SubscriptionMobileName } from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal.types';
import { FeaturesList } from 'reactApp/ui/FeaturesList/FeaturesList';
import { formatFileHistoryDate } from 'reactApp/utils/formatDate';
import {
    getPeriodName,
    getPeriodNameAsWord,
    getTrialOrDiscountDateEnd,
    getTypeOfPeriodName,
    isMonth3Period,
    isMonth6Period,
    isYear2Period,
    nowIsTrialOrDiscountPeriod,
    periodToDaysPeriod,
} from 'reactApp/utils/Period';
import { formatPrice } from 'reactApp/utils/priceHelpers';

import styles from '../SubscriptionModal.css';

enum EInfoTypes {
    conditions = 'conditions',
    endDate = 'endDate',
    nextDate = 'nextDate',
    options = 'options',
    disko = 'disko',
    card = 'card',
}

enum EInfoTitles {
    conditions = 'Тариф',
    endDate = 'Дата окончания подписки',
    nextDate = 'Следующий платёж',
    options = 'Доступные опции',
    disko = 'Лицензия на Диск-О:',
    card = 'Способ оплаты',
}

interface IInfo {
    id: EInfoTypes;
    title: EInfoTitles;
    content: ReactElement;
}

type TSubscriptionsInfos = IInfo[];

export const useInfo = ({ subscription, product, partner, sendAnalytics, paused }): TSubscriptionsInfos => {
    const { card } = useSelector(CardSelectors.getCard);
    const isCorpUser = useSelector(UserSelectors.isCorpUser);

    const infos: TSubscriptionsInfos = [];

    let conditions = '';

    const isTrialPeriod = product?.trialPeriod && nowIsTrialOrDiscountPeriod(product?.trialPeriod, subscription.start);

    const isMbiz = IS_MBIZUSER && isPromoQuotaProEnabled && product?.isMbUser;

    const purchaseCost = subscription.purchaseCost;

    const renewalCost = subscription.renewalCost;

    let productPeriodSuffix =
        isYear2Period(product?.period) || isMonth6Period(product?.period)
            ? '— разовый платёж'
            : `в ${getTypeOfPeriodName(product?.period)}`;

    if (isMonth3Period(product?.period)) {
        productPeriodSuffix = 'на 3 месяца';
    }

    let bonusLabel;

    if (
        subscription?.type === ESubscriptionsTypes.ios ||
        subscription?.type === ESubscriptionsTypes.android ||
        subscription?.type === ESubscriptionsTypes.rustore
    ) {
        conditions = `Мобильная подписка ${SubscriptionMobileName[subscription.type]}`;
    } else if ((subscription?.isTrial || isTrialPeriod) && product?.trialPeriod) {
        const trialDateEnd = getTrialOrDiscountDateEnd(product?.trialPeriod, subscription.start);

        const freeCondition = isMbiz
            ? `${getPeriodName(periodToDaysPeriod(product?.trialPeriod))} бесплатно`
            : `${getPeriodNameAsWord(product?.trialPeriod, false, true)} бесплатно`;

        const discountCost = billingDataEndpointEnabled ? renewalCost : product?.discountPrice;

        const discountCondition =
            product?.discountPeriod && `затем ${formatPrice(discountCost, 'ru')} ₽ в ${getTypeOfPeriodName(product?.discountPeriod)}`;

        const defaultCondition = `${formatPrice(renewalCost, 'ru')} ₽ в ${getTypeOfPeriodName(product?.period)}`;
        // subscriptions/list и billing/data не возвращают эту информацию
        const upsaleTrialAferDateCondition = `${formatPrice(product?.price, 'ru')} ₽ в ${getTypeOfPeriodName(product?.period)}`;

        conditions = `${freeCondition}, затем ${defaultCondition}`;

        if (discountCondition) {
            const discountDateEnd = getTrialOrDiscountDateEnd(product?.discountPeriod, trialDateEnd);
            conditions = `${freeCondition}, ${discountCondition}, с ${formatFileHistoryDate(
                discountDateEnd.valueOf()
            )} — ${upsaleTrialAferDateCondition}`;
        }
    } else if (subscription?.type === EPromoSubscriptionType.quota && !partner) {
        conditions = 'Подарок';
    } else if (partner) {
        conditions = `Подписка ${PARTNER_TITLE[partner]}`;
    } else if (!subscription?.base) {
        const periodLetter = product?.period.slice(-1);
        let period = product?.discountPeriodCount ? `${product?.discountPeriodCount}${periodLetter}` : product?.discountPeriod;
        const isDiscountPeriod = product?.discountPeriod && nowIsTrialOrDiscountPeriod(period, subscription.start);
        const discountCost = billingDataEndpointEnabled ? purchaseCost : product?.discountPrice;

        /**
         * tempexp_17360-next-line
         * @TODO: CLOUDWEB-17360: Нестандартный скидочный период - 13 месяцев.
         * По дизайну надо показыва инфо про 1 год + лейбл про подарок в 1 месяц.
         * Сделать нормальное решение после АБ.
         */
        if (period === '13m') {
            period = '12m';
            bonusLabel = '30 дней';
        }

        conditions =
            product?.discountPeriod && discountCost && isDiscountPeriod
                ? `${getPeriodNameAsWord(period, false, true)} за ${formatPrice(discountCost, 'ru')} ₽, затем ${formatPrice(
                      /** для составных периодов скидки, мы в renewalCost получаем цену следующего СКИДОЧНОГО продления,
                       *  а не ЦЕНОЙ после скидок*/
                      product?.discountPeriodCount ? product?.price : renewalCost,
                      'ru'
                  )} ₽ в ${getTypeOfPeriodName(product?.period)}`
                : `${formatPrice(renewalCost, 'ru')} ₽ ${productPeriodSuffix}`;
    }

    let conditionsContent = <>{conditions}</>;

    const discountPercent = getDiscoutOutflowPercentByProductId(subscription.productId);
    if (renewalCost && discountPercent) {
        conditionsContent = <PersonalDiscountPrice newPrice={renewalCost} period={product?.period} percent={discountPercent} />;
    }

    if (conditions) {
        infos.push({
            id: EInfoTypes.conditions,
            title: EInfoTitles.conditions,
            content: (
                <>
                    {conditionsContent}
                    {/* tempexp_17360-next-line */}
                    {bonusLabel && (
                        <span>
                            <Icon16GiftOutline />
                            {bonusLabel}
                        </span>
                    )}
                </>
            ),
        });
    }

    if (paused && subscription?.renewAt) {
        infos.push({
            id: EInfoTypes.nextDate,
            title: EInfoTitles.nextDate,
            content: <>{`${formatFileHistoryDate(subscription.renewAt)}, возобновится после паузы`}</>,
        });
    } else if (subscription?.autorenewal && subscription?.renewAt) {
        infos.push({
            id: EInfoTypes.nextDate,
            title: EInfoTitles.nextDate,
            content: <>{formatFileHistoryDate(subscription.renewAt)}</>,
        });
    } else if (subscription?.expires) {
        infos.push({
            id: EInfoTypes.endDate,
            title: EInfoTitles.endDate,
            content: <>{formatFileHistoryDate(subscription.expires)}</>,
        });
    }

    const isSferum = partner === EPartner.sferum;
    const isVkCombo = partner === EPartner.mail;
    const partnerUpload = partner && (isSferum || isVkCombo);
    let featuresOverride: IFeatureData[] | undefined;

    // tempexp_16481-next-line
    const autoupload10TB = AUTOUPLOAD_10TB_REGEX.test(product?.id);

    // tempexp_16598-start
    const isCheapest = product?.id && Object.values(cheapestProductIDs).includes(product?.id);
    if (isCheapest) {
        featuresOverride = features.cheapest;
    }
    // tempexp_16598-end

    infos.push({
        id: EInfoTypes.options,
        title: EInfoTitles.options,
        content: (
            <FeaturesList
                partnerUpload={partnerUpload}
                base={subscription?.base}
                upload={subscription?.flags?.upload && !subscription?.isDisko && !subscription?.isProfessional && !subscription?.isMail}
                paidFeatures={
                    !isSferum &&
                    subscription?.flags?.paidFeatures &&
                    !subscription?.isDisko &&
                    !subscription?.isProfessional &&
                    !subscription?.isMail
                }
                autodelete={
                    !isSferum &&
                    subscription?.flags?.paidFeatures &&
                    !subscription?.isDisko &&
                    !subscription?.isProfessional &&
                    !subscription?.isMail
                }
                disko={subscription?.isDisko && !subscription?.isProfessional}
                modalPro={subscription?.isProfessional && !subscription?.isMail}
                isPromoPro={Boolean(subscription?.isProfessional && subscription?.promoId && !subscription?.isMail)}
                isSingleQuota={subscription?.isMail && !subscription?.isProfessional && !isMbiz}
                isSingleQuotaPro={subscription?.isMail && subscription?.isProfessional && !isMbiz}
                isSingleQuotaFamily={!partner && !subscription?.isProfessional && subscription?.isMail && isFamilySubsInTariff && !isMbiz}
                isCorpUser={isCorpUser}
                space={subscription?.space}
                plusSpace
                itemClassName={styles.condition}
                partner={partner}
                // tempexp_16481-next-line
                autoupload10TB={autoupload10TB}
                listOverride={featuresOverride}
                isMbiz={isMbiz}
            />
        ),
    });

    if (subscription?.diskoKey) {
        infos.push({
            id: EInfoTypes.disko,
            title: EInfoTitles.disko,
            content: <>{subscription.diskoKey}</>,
        });
    }

    if (card && subscription?.type === ESubscriptionsTypes.web && subscription?.autorenewal) {
        infos.push({
            id: EInfoTypes.card,
            title: EInfoTitles.card,
            content: <Card sendAnalytics={sendAnalytics} />,
        });
    }

    return infos;
};
