import Image from 'img/aside-promo-modal/gamer-bundle/gamer-bundle.png';
import ImageDark from 'img/aside-promo-modal/gamer-bundle/gamer-bundle-dark.png';
import { gamerBundleEnabled } from 'reactApp/appHelpers/featuresHelpers/features/gamerBundle';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { store as reduxStore } from 'reactApp/store';
import { openAsidePromoModal } from 'reactApp/ui/AsidePromoModal/AsidePromoModal.helpers';
import { openThanksBalloon } from 'reactApp/ui/ThanksBalloon/ThanksBalloon.helpers';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';
import { put } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';

const GAMER_BUNDLE_MODAL_ID = 'gamer-bundle-modal';

export function* initGamerBundleModal() {
    const storage = yield* select(getCurrentStorage);

    if (storeHelper.getValue(GAMER_BUNDLE_MODAL_ID)) {
        return;
    }

    const isCorrectUrl = storage === EStorageType.home;

    if (!isCorrectUrl || !gamerBundleEnabled) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.gamerBundle,
            promoId: GAMER_BUNDLE_MODAL_ID,
            onClick() {
                sendPaymentGa({
                    action: 'click',
                    eventCategory: ECategoryGa.balloon,
                    name: 'gamers_fakedoor',
                });
                reduxStore.dispatch(removePromo(EPromoType.gamerBundle));

                openThanksBalloon({
                    onShow: () => {
                        sendPaymentGa({
                            action: 'show',
                            eventCategory: ECategoryGa.balloon,
                            name: 'thanks_gamers_fakedoor',
                        });
                    },
                    onClick: () => {
                        sendPaymentGa({
                            action: 'click',
                            eventCategory: ECategoryGa.balloon,
                            name: 'thanks_gamers_fakedoor',
                        });
                        reduxStore.dispatch(closePopupHelper(popupNames.THANKS_BALLOON));
                    },
                });
            },
            onShow: () => {
                storeHelper.markAsShown(GAMER_BUNDLE_MODAL_ID);
                sendPaymentGa({
                    action: 'show',
                    eventCategory: ECategoryGa.balloon,
                    name: 'gamers_fakedoor',
                });
                reduxStore.dispatch(promoShown(EPromoType.gamerBundle));
            },
            onClose: () => {
                sendPaymentGa({
                    action: 'close',
                    eventCategory: ECategoryGa.balloon,
                    name: 'gamers_fakedoor',
                });
                reduxStore.dispatch(removePromo(EPromoType.gamerBundle));
            },
        })
    );
}

export function* showGamerBundleModal() {
    const promo = yield* select(PromoSelectors.getPromo(EPromoType.gamerBundle));
    const isDarkTheme = isDarkThemeModeEnabled();

    if (promo) {
        const { onShow, onClick, onClose } = promo;

        yield openAsidePromoModal({
            title: 'Новый тариф для\u00A0геймеров',
            text: 'Много места в Облаке для\u00A0бэкапов, кастомный фон\u00A0и\u00A0игровые события в\u00A0календаре с\u00A0подпиской Mail\u00A0Space',
            buttonText: 'Попробовать за 1 ₽',
            imageUrl: isDarkTheme ? ImageDark : Image,
            showAboveViewer: true,
            onShow,
            onClick,
            onClose,
        });
    }
}
