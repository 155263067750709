import { tariffCardPersonal } from 'reactApp/appHelpers/featuresHelpers/features/tariifCardPersonal';
import { SPACE_LIST } from 'reactApp/constants/spaceList';
import { TariffBorder } from 'reactApp/sections/QuotaLanding/TariffsPlans/TariffPlansCard/TariffPlansCard.types';

type Props = {
    quota?: number;
};

export const useTariffBorder = ({ quota }: Props): TariffBorder | undefined => {
    // tempexp_18084-start
    if (tariffCardPersonal === 'b') {
        if (quota === SPACE_LIST.gb_512) {
            return TariffBorder.tariffPersonalPink;
        }

        if (quota === SPACE_LIST.tb_1) {
            return TariffBorder.tariffPersonalBlue;
        }

        if (quota === SPACE_LIST.tb_2) {
            return TariffBorder.tariffPersonalGreen;
        }
    }
    // tempexp_18084-end

    return undefined;
};
