import promoModalImage from 'img/aside-promo-modal/themed-public-promo.webp';
import { IS_AUTO_TEST_HIDE, IS_BIZ_USER, IS_PAID_USER, IS_SOCIAL_USER } from 'reactApp/appHelpers/configHelpers';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { isThemedPublicPromo } from 'reactApp/appHelpers/featuresHelpers/features/themedPublic';
import { EPromoTooltip } from 'reactApp/components/SharingWindow/Sharing.types';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { createNewFolderAndPublish } from 'reactApp/modules/modifying/modifying.actions';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { store } from 'reactApp/store';
import { openAsidePromoModal } from 'reactApp/ui/AsidePromoModal/AsidePromoModal.helpers';
import { ESplashScreenAction, sendSplashScreenAnalytics } from 'reactApp/ui/Mobile/SplashScreen/SplashScreen.analytics';
import { put } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';

const THEMED_PUBLIC_PROMO_ID = 'themed-public-promo';
const THEMED_PUBLIC_PROMO_FREE = 'themed-public-promo-free';
const PERIOD_IN_DAYS = 5;
const MAX_SHOWN_COUNT = 3;

export function* initThemedPublicPromo() {
    const storage = yield* select(getCurrentStorage);
    const { isHome } = getStorage(storage);
    const isMobile = yield* select(EnvironmentSelectors.isMobile);

    // показываем на /home и просмотрщике
    // скрываем на таче ,автотестах, у соц/бих юзеров
    if (!isThemedPublicPromo || !isHome || isMobile || IS_AUTO_TEST_HIDE || IS_SOCIAL_USER || IS_BIZ_USER) {
        return;
    }

    const wasShownWhenFree = storeHelper.getValue(THEMED_PUBLIC_PROMO_FREE);

    // если промо показывалось юзеру, когда он был бесплатником,
    // то после покупки подписки больше не показываем
    if (IS_PAID_USER && Boolean(wasShownWhenFree)) {
        return;
    }

    // Показать платникам 3 раза с разницей в 5 дней, если нажал "попробовать" или на крестик - больше не показывать
    // Показать бесплатникам 3 раза с разницей в 5 дней

    const promoShownCount = storeHelper.getValue(THEMED_PUBLIC_PROMO_ID) || 0;

    if (promoShownCount > MAX_SHOWN_COUNT) {
        return;
    }

    const periodPassed =
        storeHelper.getValue(THEMED_PUBLIC_PROMO_ID) && storeHelper.isPassed(THEMED_PUBLIC_PROMO_ID, { daysCount: PERIOD_IN_DAYS });

    if (storeHelper.getValue(THEMED_PUBLIC_PROMO_ID) && !periodPassed) {
        return;
    }

    if (!IS_PAID_USER) {
        storeHelper.setValue(THEMED_PUBLIC_PROMO_FREE, true);
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.themedPublicPromo,
            promoId: THEMED_PUBLIC_PROMO_ID,
            onClick: () => {
                emitAnalyticEvent(AnalyticEventNames.BRAND_PUBLIC_PROMO_CLICK);
                sendSplashScreenAnalytics({ action: ESplashScreenAction.click, name: THEMED_PUBLIC_PROMO_ID });
                store.dispatch(createNewFolderAndPublish({ tooltipId: EPromoTooltip.ChangeDesign }));
                store.dispatch(removePromo(EPromoType.themedPublicPromo));
                if (IS_PAID_USER) {
                    storeHelper.setValue(THEMED_PUBLIC_PROMO_ID, MAX_SHOWN_COUNT);
                }
            },
            onShow: () => {
                emitAnalyticEvent(AnalyticEventNames.BRAND_PUBLIC_PROMO_SHOW);
                storeHelper.markAsShown(THEMED_PUBLIC_PROMO_ID, promoShownCount + 1);
                sendSplashScreenAnalytics({ action: ESplashScreenAction.show, name: THEMED_PUBLIC_PROMO_ID });
                store.dispatch(promoShown(EPromoType.themedPublicPromo));
            },
            onClose: () => {
                emitAnalyticEvent(AnalyticEventNames.BRAND_PUBLIC_PROMO_CLOSE);
                sendSplashScreenAnalytics({ action: ESplashScreenAction.close, name: THEMED_PUBLIC_PROMO_ID });
                store.dispatch(removePromo(EPromoType.themedPublicPromo));
                if (IS_PAID_USER) {
                    storeHelper.setValue(THEMED_PUBLIC_PROMO_ID, MAX_SHOWN_COUNT);
                }
            },
        })
    );
}

export function* showThemedPublicPromo() {
    const promo = yield* select(PromoSelectors.getPromo(EPromoType.themedPublicPromo));

    if (!promo) {
        return;
    }

    const { onShow, onClick, onClose } = promo;

    yield openAsidePromoModal({
        title: 'Оформляйте папки и\u00A0делитесь красиво',
        text: 'С подпиской Mail Space можно устанавливать фон для папок — его\u00A0увидят все получатели',
        note: 'Функция доступна только на компьютере',
        buttonText: 'Попробовать',
        imageUrl: promoModalImage,
        showAboveViewer: true,
        onShow,
        onClick,
        onClose,
    });
}
