import { createAction } from '@reduxjs/toolkit';
import type {
    AddAutoRenewAction,
    AddOrUpdateCardMobileAction,
    CancelAutoRenewAction,
    CancelAutoRenewMobileAction,
    RenewMobileAction,
} from 'reactApp/modules/subscriptionRenewal/subscriptionRenewal.types';

const cancelAutoRenew = createAction<CancelAutoRenewAction>('subscriptionRenewal/cancelAutoRenew');
const addAutoRenew = createAction<AddAutoRenewAction>('subscriptionRenewal/addAutoRenew');
const cancelAutoRenewMobile = createAction<CancelAutoRenewMobileAction>('subscriptionRenewal/cancelAutoRenewMobile');
const renewMobile = createAction<RenewMobileAction>('subscriptionRenewal/renewMobile');
const addOrUpdateCardMobile = createAction<AddOrUpdateCardMobileAction>('subscriptionRenewal/addOrUpdateCardMobile');

export const SubscriptionRenewalActions = {
    cancelAutoRenew,
    addAutoRenew,
    cancelAutoRenewMobile,
    renewMobile,
    addOrUpdateCardMobile,
} as const;
