import { Rubles } from 'Cloud/Application/Rubles';
import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DOM_ID_RIGHT_COL_BANNER_ERRORS_PAGE } from 'reactApp/appHelpers/advertisingConfigHelpers';
import { HIDE_ADS, IS_B2B_BIZ_USER, IS_ONPREMISE } from 'reactApp/appHelpers/configHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { routeChangeSuccess } from 'reactApp/modules/router/router.module';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { EErrorPageAction, sendErrorPageAnalytics } from 'reactApp/sections/ErrorPage/ErrorPage.analytics';
import { ESiteZoneErrorPage } from 'reactApp/ui/AdvBanners/Adv.types';
import { EButtonAction, sendDisableAdsAnalytics } from 'reactApp/ui/AdvBanners/DisableButton/DisableButton.analytics';
import { DisableButtonComponent } from 'reactApp/ui/AdvBanners/DisableButton/DisableButtonComponent';
import { createGaSender } from 'reactApp/utils/ga';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';
import { captureException } from 'reactApp/utils/tracer';

import styles from './ErrorPage.css';
import { disableAdvButtonHref } from './ErrorPage.helpers';
import { type IProps, EStatus } from './ErrorPage.types';
import { ErrorPageBlock } from './ErrorPageBlock';

const sendGa = createGaSender('error');

export const ErrorPage = memo<IProps>(function ErrorPage({ status }) {
    const storage = useSelector(getCurrentStorage);
    const isPhone = useSelector(EnvironmentSelectors.isPhone);
    const dispatch = useDispatch();

    // На стоках реклама справа уже приходит (Блок вставляется на сервер сайде)
    const showAd = !HIDE_ADS && !IS_ONPREMISE && storage !== EStorageType.stock;

    const sendAnalytics = useCallback(
        (action: EErrorPageAction) => {
            dispatch(
                routeChangeSuccess({
                    id: '/',
                    storage: null,
                    params: {},
                    __isFolder: false,
                    url: '',
                    __parent: '',
                })
            );
            sendErrorPageAnalytics({ action, number_error: status, source: storage });
            sendGa('show', status.toString());

            if (showAd) {
                sendDisableAdsAnalytics({
                    action: EButtonAction.SHOW,
                    source: status.toString(),
                    place: 'error-page',
                });
            }
        },
        [status, storage]
    );

    useEffect(() => {
        const statusText = EStatus[status];
        const siteZone = ESiteZoneErrorPage[statusText];

        sendAnalytics(EErrorPageAction.SHOW);

        if (!showAd) {
            return;
        }

        try {
            if (isPhone) {
                Rubles?.updateErrorPagePhoneTopAdv(+siteZone);
            } else {
                Rubles?.updateErrorPageRightColAdv(+siteZone);
            }
        } catch (error) {
            captureException(error);
        }
    }, []);

    const onClick = useCallback(() => sendAnalytics(EErrorPageAction.CLICK), []);

    const onButtonClick = useCallback(() => {
        sendDisableAdsAnalytics({
            action: EButtonAction.CLICK,
            source: status.toString(),
            place: 'error-page',
        });
    }, [status]);

    const pageDesktop = document.querySelector('[class^="Page-desktop__root"]');
    if (pageDesktop && status === EStatus.NOT_FOUND && !isDarkThemeModeEnabled()) {
        pageDesktop.classList.add('root_default_background');
    }

    return (
        <ErrorPageBlock
            status={status}
            onButtonClick={onClick}
            advDomIdDesktop={DOM_ID_RIGHT_COL_BANNER_ERRORS_PAGE}
            isBiz={IS_B2B_BIZ_USER}
        >
            {showAd && (
                <div className={styles.advHeader}>
                    <h3 className={styles.advTitle}>Реклама</h3>
                    <DisableButtonComponent onClick={onButtonClick} text="Отключить" href={disableAdvButtonHref} />
                </div>
            )}
        </ErrorPageBlock>
    );
});
