import React, { type ReactElement } from 'react';

interface IContent {
    header: string;
    description: ReactElement;
}

export const useContent = (item): IContent => {
    const header = `История файла «${item?.name}»`;

    const description = <>Для восстановления доступны файлы за последние 20 дней</>;

    return { header, description };
};
