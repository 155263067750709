import { Button, Spacing, Text, Title } from '@vkontakte/vkui';
import businessTemplatesTooltipImg from 'img/marketingPromo/business-templates-tooltip-img.png';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IS_MBIZUSER, IS_PAID_USER } from 'reactApp/appHelpers/configHelpers';
import { DEMO_BUSINESS_TEMPLATES_END_DATE } from 'reactApp/appHelpers/featuresHelpers/features/businessTemplates';
import type { DemoPromoTooltipData } from 'reactApp/modules/promo/promo.types';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { type ITooltipProps, ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';
import { sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './DemoPromoTooltip.css';

type DemoPromoTooltipProps = DemoPromoTooltipData & Pick<ITooltipProps, 'target'>;

/** Контент промо демо включения шаблонов документов */
const BusinessTemplatesTooltipContent = ({ onClose }: DemoPromoTooltipProps) => {
    const navigate = useNavigate();

    const sendAnalytics = useCallback(
        (eventCategory: string) =>
            sendPaymentGa({
                eventCategory,
                is_mailspace_pro: IS_MBIZUSER,
                is_mailspace: IS_PAID_USER,
            }),
        []
    );

    const goToTemplates = useCallback(() => {
        sendAnalytics('click_promo_biz_template');
        navigate('/templates');
        onClose();
    }, [onClose, navigate, sendAnalytics]);

    useEffect(() => {
        sendAnalytics('show_promo_biz_template');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.businessTemplates}>
            <div className={styles.content}>
                <Title level="3">Тут шаблоны документов</Title>
                <Spacing size={4} />
                <Text>Попробуйте до {DEMO_BUSINESS_TEMPLATES_END_DATE}</Text>
                <Spacing size={12} />
                <Button type="button" size="m" mode="primary" appearance="accent-invariable" onClick={goToTemplates}>
                    Посмотреть
                </Button>
            </div>
            <img src={businessTemplatesTooltipImg} />
        </div>
    );
};

/** Разводка контента тултипа */
const DemoPromoTooltipContent = (props: DemoPromoTooltipProps) => {
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (props.variant) {
        case 'business-templates-tooltip':
            return <BusinessTemplatesTooltipContent {...props} />;
        default:
            return null;
    }
};

/** Тултип рядом с `DemoPromo` */
const DemoPromoTooltipInner = (props: DemoPromoTooltipProps) => {
    const { onClose, onShow, target } = props;

    const [isTargetVisible, setIsTargetVisible] = useState(false);

    useEffect(() => {
        const node = target && ('current' in target ? target.current : target);
        if (node) {
            setIsTargetVisible(true);
        }
    }, [target]);

    if (!isTargetVisible) {
        return null;
    }

    return (
        <FloatingTooltip
            target={target}
            placement={ETooltipPlacement.right}
            allowFlip={false}
            hideArrow={false}
            usePortal
            closable
            onShow={onShow}
            onClose={onClose}
        >
            <DemoPromoTooltipContent {...props} />
        </FloatingTooltip>
    );
};

export const DemoPromoTooltip = React.memo(DemoPromoTooltipInner);
