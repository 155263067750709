import { flatten, partition } from 'ramda';
import { getCurrentRouteId } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { DOCUMENT_ID, ITEMS_META, MAP_DOCUMENT_ID } from 'reactApp/sections/PersonalDocuments/PersonalDocuments.constants';
import type { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

import { MAP_ROUTE_TO_ID } from './personalDocuments.constants';
import { type DocumentsItem, type IDocumentSection, type IState, EDocumentStatus } from './personalDocuments.types';

const getPersonalDocumentsStore = (state: RootState) => state.documents as IState;

export const getSectionLoadedState = createSelector(getPersonalDocumentsStore, (state) => state.isLoaded);

export const getCurrentDocument = createSelector(getCurrentRouteId, (id) => {
    const documentId = MAP_ROUTE_TO_ID[id];

    return MAP_DOCUMENT_ID[documentId];
});

export const getDocumentsRecognitionStatus = createSelector(getPersonalDocumentsStore, (state) => state?.recognitionStatus);

export const getPersonalDocumentsBreadcrumbs = createSelector(getCurrentDocument, (documentType) => {
    const result = [
        {
            name: 'Личные документы',
            id: '/documents',
            kind: 'storage' as const,
            storage: EStorageType.documents,
        },
    ];

    if (!documentType) {
        return result;
    }

    const documentName = ITEMS_META[DOCUMENT_ID[documentType]]?.name || '';

    result.push({
        name: documentName,
        id: `/documents/${documentType}`,
        kind: 'storage' as const,
        storage: EStorageType.documents,
    });

    return result;
});

export const getCurrentRouteDocument = createSelector(
    getPersonalDocumentsStore,
    getCurrentDocument,
    (state, documentType): IDocumentSection | undefined => {
        if (!documentType) {
            return;
        }

        return state.list[`/${documentType}`] as IDocumentSection;
    }
);

export const getDocumentLoadedState = createSelector(getCurrentRouteDocument, (state) => state?.isLoaded);

export const getDocumentCursor = createSelector(getCurrentRouteDocument, (state) => state?.cursor);

export const getDocumentChilds = createSelector(getCurrentRouteDocument, (state) => state?.childs);

export const isDocumentNotFound = createSelector(getCurrentRouteDocument, (state) => state?.status === EDocumentStatus.NOT_FOUND);

const getDocumentList = createSelector(getPersonalDocumentsStore, (state) => state.list);

export const getDocumentItemById = createSelector(
    getDocumentList,
    (state, id) => id,
    (list, id): DocumentsItem => list[id] as DocumentsItem
);

export const getDocumentsList = createSelector(getDocumentList, (list) => {
    const documents = Object.keys(DOCUMENT_ID).map((id) => list[`/${id}`] || { id: DOCUMENT_ID[id] }) as IDocumentSection[];
    const documentsList = partition((document: IDocumentSection) => Boolean(document?.count?.all && document?.avatar), documents);

    return flatten(documentsList);
});

export const isNotEmptyDocumentList = createSelector(getDocumentsList, (list) => {
    return list.some(({ avatar }) => avatar);
});
