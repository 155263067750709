import { Button } from '@vkontakte/vkui';
import printPhotoAlbumImg from 'img/printPhotoAlbumTooltip.png';
import React, { type FC, type MouseEvent, useCallback, useState } from 'react';
import { useIntersectionObserver } from 'reactApp/hooks/useInterSectionObserver';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipAppearance, ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';
import { renderThanksModal } from 'reactApp/ui/ThanksModal/ThanksModal.helpers';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';

import styles from './PrintPhotoAlbumBadge.css';

const PRINT_PHOTO_ALBUM_LOCAL_STORAGE_KEY = 'print-photo-album';
const PRINT_PHOTO_ALBUM_TOOLTIP_LOCAL_STORAGE_KEY = 'print-photo-album-tooltip';

interface Props {
    className?: string;
    onClick?: (evt: MouseEvent) => void;
}

export const PrintPhotoAlbumBadge: FC<Props> = ({ className, onClick }) => {
    const [hidden, setHidden] = useState(storeHelper.getValue(PRINT_PHOTO_ALBUM_LOCAL_STORAGE_KEY));
    const [intersectionTooltip, setIntersectionTooltip] = useState(false);
    const [closedTooltip, setClosedTooltip] = useState(storeHelper.getValue(PRINT_PHOTO_ALBUM_TOOLTIP_LOCAL_STORAGE_KEY));

    const isDarkTheme = isDarkThemeModeEnabled();

    const ref = useIntersectionObserver({
        onIntersecting: () => setIntersectionTooltip(true),
        onIntersectingOut: () => setIntersectionTooltip(false),
    });

    const handleCloseTooltip = useCallback(() => {
        setHidden(true);
        storeHelper.setValue(PRINT_PHOTO_ALBUM_LOCAL_STORAGE_KEY, true);

        setClosedTooltip(true);
        storeHelper.setValue(PRINT_PHOTO_ALBUM_TOOLTIP_LOCAL_STORAGE_KEY, true);
    }, []);

    return (
        <div className={className} onClick={onClick}>
            {!hidden && (
                <div className={styles.badge} ref={ref}>
                    Фотокнига
                </div>
            )}
            {!closedTooltip && intersectionTooltip && (
                <FloatingTooltip
                    target={ref}
                    appearance={isDarkTheme ? undefined : ETooltipAppearance.blue}
                    placement={ETooltipPlacement.rightStart}
                    qaId="tooltip-create-portfolio"
                    className={styles.tooltip}
                    arrowClassName={styles.tooltipArrow}
                    contentClassName={styles.contentTooltip}
                    closeClassName={styles.tooltipClose}
                    onClose={handleCloseTooltip}
                    closeOnOutsideClick={false}
                    usePortal
                >
                    <div className={styles.tooltipRoot}>
                        <div className={styles.tooltipContent}>
                            <div className={styles.tooltipTitle}>Печать фотографий из альбомов</div>
                            <div className={styles.tooltipText}>Сохраните воспоминания в фотокниге</div>
                            <Button
                                size="l"
                                appearance="accent-invariable"
                                onClick={(evt) => {
                                    evt.stopPropagation();

                                    sendDwh({
                                        eventCategory: ECategoryGa.uploadFileToCloud,
                                        action: 'click-album-print',
                                    });

                                    handleCloseTooltip();

                                    renderThanksModal();
                                }}
                            >
                                Попробовать
                            </Button>
                        </div>
                        <img className={styles.tooltipImg} src={printPhotoAlbumImg} />
                    </div>
                </FloatingTooltip>
            )}
        </div>
    );
};
