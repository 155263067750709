import React, { type FC } from 'react';
import type { EmptyComponentProps } from 'reactApp/ui/Empty/new/Empty.types';

interface DescriptionProps extends EmptyComponentProps {
    className?: string;
}

export const Description: FC<DescriptionProps> = ({ className }) => {
    return <div className={className}>Создайте папку, установите таймер на файлы —{'\n'}они удалятся сами и не будут занимать место </div>;
};
