import { Text } from '@vkontakte/vkui';
import React, { type RefObject, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { MediaSelectors } from 'reactApp/modules/media/media.selectors';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement, ETooltipSize } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';

import styles from './DisableSeoTooltip.css';

type DisableSeoTooltipProps = {
    onClose?: () => void;
    name: string;
    target: RefObject<HTMLElement>;
};

export const DisableSeoTooltip = memo(({ onClose, name, target }: DisableSeoTooltipProps) => {
    const windowWidth = useSelector(MediaSelectors.getWindowWidth);

    const handleClose = useCallback(() => {
        onClose?.();
    }, [onClose]);

    return (
        <FloatingTooltip
            className={styles.wrapper}
            target={target}
            placement={windowWidth > 1200 ? ETooltipPlacement.rightStart : ETooltipPlacement.bottom}
            size={ETooltipSize.medium}
            closeOnOutsideClick
            closable
            onClose={handleClose}
            qaId="seo-disable-tooltip"
            withinModal
            usePortal
        >
            <div className={styles.root}>
                <Text className={styles.title}>Закройте {name} для поисковиков, чтобы настроить доступ</Text>
            </div>
        </FloatingTooltip>
    );
});

DisableSeoTooltip.displayName = 'DisableSeoTooltip';
