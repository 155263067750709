import { splitEvery } from 'ramda';
import { addItemsToStore } from 'reactApp/modules/uploading/helpers/cloudFs/addItemsToStore';
import type { UploadingDescriptor } from 'reactApp/modules/uploading/serviceClasses/UploadingDescriptor';
import { uploadingService } from 'reactApp/modules/uploading/serviceClasses/UploadingService';
import type { sendDescriptorsToUI } from 'reactApp/modules/uploading/uploading.module';
import { type IInputFile, EProgressStatus } from 'reactApp/modules/uploadList/uploadList.model';
import { setInputFilesAction, setProgressStatusAction } from 'reactApp/modules/uploadList/uploadList.module';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { delay, put, select } from 'redux-saga/effects';

export function* handleSendDescriptorsToUI(action: ReturnType<typeof sendDescriptorsToUI>) {
    const BATCH_SIZE = 100;
    const BATCH_SIZE_FOR_UPLOAD_LIST = 250;
    const DESCRIPTORS_TO_UI_BATCH_TIMEOUT = 200;

    const descriptors = splitEvery<UploadingDescriptor>(BATCH_SIZE, action.payload.descriptors);
    const fileDescriptors = splitEvery<IInputFile>(BATCH_SIZE_FOR_UPLOAD_LIST, action.payload.fileDescriptors);

    const email = yield select(UserSelectors.getEmail);

    yield put(
        setProgressStatusAction({
            status: EProgressStatus.PROGRESS,
            packetId: descriptors[0]?.[0].uploadingPacketConfig?.currentPacketIdForUI,
        })
    );

    let descriptorChunk = descriptors.shift();
    let fileInputChunk = fileDescriptors.shift();
    while (fileInputChunk || descriptorChunk) {
        if (uploadingService.isUserCanceled) {
            break;
        }

        fileInputChunk = fileInputChunk?.filter((item) => !uploadingService.getDescriptorById(item.descriptorId)?.hasCanceledParent());
        if (fileInputChunk?.length) {
            yield put(setInputFilesAction(fileInputChunk));
            // Особенно это важно для первого чанка, чтобы как можно быстрее отрисовать интерфейс
            yield delay(DESCRIPTORS_TO_UI_BATCH_TIMEOUT);
        }

        if (uploadingService.isUserCanceled) {
            break;
        }

        descriptorChunk = descriptorChunk?.filter((descriptor) => !descriptor.hasCanceledParent());
        if (descriptorChunk?.length) {
            yield processAddToStoreChunk(descriptorChunk, email);
            yield delay(DESCRIPTORS_TO_UI_BATCH_TIMEOUT);
        }

        descriptorChunk = descriptors.shift();
        fileInputChunk = fileDescriptors.shift();
    }

    uploadingService.stopProcessing(action.payload.processingId);
}

function* processAddToStoreChunk(descriptors: UploadingDescriptor[], email: string) {
    yield addItemsToStore({
        email,
        files: descriptors,
        skipItemStoreUpdate: true,
    });
}
