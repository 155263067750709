import { useSelector } from 'react-redux';
import { isAlbumFolderCustom } from 'reactApp/modules/albums/albums.selector';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';

export const useShowBackItem = (): boolean => {
    const isSelectingMode = useSelector(SelectionsSelectors.isAnyIndexSelected);
    const isAlbumCustom = useSelector(isAlbumFolderCustom);

    return isSelectingMode && !isAlbumCustom;
};
