import { _classCallCheck, _createSuper, _inherits } from 'reactApp/ui/ReactViewer/VideoPlayer/utils/polyfills';
import type { VideoPlayer } from 'reactApp/ui/ReactViewer/VideoPlayer/VideoPlayer.types';
import videojs from 'video.js';

import type { ConcreteButtonType } from './ConcreteButton';
import type { HlsQualitySelectorPlugin, QualityValue } from './plugin';

// Concrete classes
const VideoJsMenuItemClass = videojs.getComponent('MenuItem');

type MenuItemType = InstanceType<typeof VideoJsMenuItemClass>;

interface Item {
    label: string;
    value: QualityValue;
    selected?: boolean;
}

export type ConcreteMenuItemType = MenuItemType & {
    item: Item;
    qualityButton: ConcreteButtonType;
    plugin: HlsQualitySelectorPlugin;
};

/**
 * Наслеюуем через прототипы из-за babel. См. комментарии к задаче
 * @link https://jira.vk.team/browse/CLOUDWEB-17571
 */
export const ConcreteMenuItem = (function (VideoJsMenuItemClass) {
    _inherits(ConcreteMenuItem, VideoJsMenuItemClass);
    const _super = _createSuper(ConcreteMenuItem);
    function ConcreteMenuItem(
        this: MenuItemType,
        player: VideoPlayer,
        item: Item,
        qualityButton: ConcreteButtonType,
        plugin: HlsQualitySelectorPlugin
    ): void {
        _classCallCheck(this, ConcreteMenuItem);
        const _this = _super.call(this, player, {
            label: item.label,
            selectable: true,
            selected: item.selected || false,
        });
        _this.item = item;
        _this.qualityButton = qualityButton;
        _this.plugin = plugin;
        return _this;
    }
    return ConcreteMenuItem;
})(VideoJsMenuItemClass);

/**
 * Click event for menu item.
 */
ConcreteMenuItem.prototype.handleClick = function handleClick(this: ConcreteMenuItemType) {
    // Reset other menu items selected status.
    for (let i = 0; i < this.qualityButton.items.length; ++i) {
        this.qualityButton.items[i].selected(false);
    }
    // Set this menu item to selected, and set quality.
    this.plugin.setQuality(this.item.value);
    this.selected(true);
};
