import type { PayloadAction } from '@reduxjs/toolkit';
import config from 'Cloud/config';
import { logger } from 'lib/logger';
import { RenameAlbumAPICal } from 'reactApp/api/albums/RenameAlbumAPICal';
import { renameFileApiCall } from 'reactApp/api/FileRenameApiCall';
import { EAlbumAction, sendAlbumAnalytics } from 'reactApp/modules/albums/albums.analytics';
import { albumErrors } from 'reactApp/modules/albums/albums.constants';
import { dispatchNewSearchRadar } from 'reactApp/modules/dwh/dwh.module';
import { changeHomeHistory } from 'reactApp/modules/home/home.actions';
import { getCurrentFolderHome } from 'reactApp/modules/home/home.selectors';
import { getNewItem } from 'reactApp/modules/modifying/getNewItem';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { resetSelected } from 'reactApp/modules/selections/selection.saga';
import { type CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import { store as reduxStore } from 'reactApp/store';
import { ECategoryGa } from 'reactApp/utils/paymentGa';
import { captureException } from 'reactApp/utils/tracer';
import { put, select } from 'redux-saga/effects';

import { renameFileRequest, renameItemSuccess } from '../modifying.actions';
import { renameErrors } from '../modifying.constants';
import type { IRenameItem } from '../modifying.types';

const MAX_NAME_LENGTH = config.get('ITEM_NAME_MAX_LENGTH');

const renameApiCall = ({ id, name, conflict = 'rename' }) => renameFileApiCall({ id, name, conflict });
const renameAlbumApiCall = ({ id, title }) => new RenameAlbumAPICal().makeRequest({ album_id: id, title });

export function renameItem(id: string, name: string) {
    return renameApiCall({ id, name })
        .then((response) => response.data)
        .catch((error) => error?.response);
}

export function renameRequest(item, newName) {
    return new Promise<string>((resolve, reject) => {
        const nameWithoutExtension = newName.replace(/\.\w+$/i, '');
        if (!item?.id || (!nameWithoutExtension && !item?.isFolder) || !newName) {
            // @ts-ignore
            return reject(renameErrors.getMessage('required'));
        }

        if (newName.length > MAX_NAME_LENGTH) {
            return reject(renameErrors.getMessage('name_too_long'));
        }

        if (item.storage === EStorageType.albums) {
            sendAlbumAnalytics({ action: EAlbumAction.RENAME });

            renameAlbumApiCall({ id: item.id, title: newName })
                .then(({ data }) => reduxStore.dispatch(renameFileRequest({ item, newId: data?.title })))
                .catch(({ error }) => reject(albumErrors.getMessage(error)));

            return;
        }

        renameItem(item.id, newName).then((response) => {
            if (response?.status === 200 || response?.status === undefined) {
                reduxStore.dispatch(renameFileRequest({ item, newId: response?.body ?? response?.path }));
                return resolve(response?.body ?? response?.path);
            }
            reject(renameErrors.getMessage(response?.body));
        });
    });
}

function* processRename(item: CloudItem, newId: string) {
    const storage: EStorageType = yield select(getCurrentStorage);
    const newItem: CloudItem = getNewItem({ item, newId });

    // Если переименовали текущую папку, то открываем родителя
    if (storage === EStorageType.home) {
        const currentFolder = yield select(getCurrentFolderHome);
        const parentId = currentFolder?.parent;

        if (item.id === currentFolder?.id && parentId) {
            yield put(changeHomeHistory({ id: parentId }));
        }
    }

    yield put(renameItemSuccess({ oldId: item.id, newItem }));

    if ('ext' in item && item.ext === 'pdf' && item.storage === EStorageType.search) {
        yield put(
            dispatchNewSearchRadar({
                dwhData: {
                    eventCategory: ECategoryGa.pdfEditorSearch,
                    action: 'rename',
                },
                items: [
                    {
                        file_name: item.nameWithoutExt,
                        type: item.kind,
                        pos: 'pos' in item && item.pos,
                        file_id: item.id,
                    },
                ],
            })
        );
    }

    yield resetSelected();
    yield closePopupHelper(popupNames.RENAME_DIALOG);
}

export function* handleRenameFile(action: PayloadAction<IRenameItem>) {
    const { item, newId } = action.payload;

    try {
        yield processRename(item, newId);
    } catch (error) {
        yield closePopupHelper(popupNames.RENAME_DIALOG);
        logger.error(error);
        captureException(error);
    }
}
