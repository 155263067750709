import { xray } from 'lib/xray';
import { IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';
import { historyPush } from 'reactApp/modules/router/router.module';
import { startSelecting } from 'reactApp/modules/selections/selections.actions';
import { hideSnackbarAction, showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getDeleteSnackbarText, getRemoveSnackbarText } from 'reactApp/modules/userQuotaCleaner/helpers/getDeleteSnackbarText';
import {
    type deleteUserQuotaCleanerItemsSuccess,
    reloadGroupAfterDelete,
    setCloudRemove,
    setIsItemsDeleteProcess,
    setNeedsGroupReload,
    setSuccessMovedToTrash,
} from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.actions';
import { isCloudRemoveNeeded } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.selectors';
import { store } from 'reactApp/store';
import { UserQuotaGroupType } from 'reactApp/types/QuotaCleaner';
import { deleteMalePluralV1, filesPlural } from 'reactApp/utils/pluralHelpers';
import { sendPostMessage } from 'reactApp/utils/windowHelper';
import { put, select } from 'redux-saga/effects';

export function* deleteItemsSuccess(action: ReturnType<typeof deleteUserQuotaCleanerItemsSuccess>) {
    const {
        payload: {
            count,
            groupType,
            groupId,
            ids,
            itemIds,
            size,
            isMsgRemove,
            withOutCloudRemove,
            withSnackBar,
            onDeleteSuccess,
            onSerialDeleteSuccess,
            needToDeleteFromTrashbin,
        },
    } = action;
    const isMobile = yield select(QuotaLandingSelectors.isMobile);
    const isCloudRemove = yield select(isCloudRemoveNeeded);
    if (!isCloudRemove && !withOutCloudRemove && !isMsgRemove) {
        yield put(setSuccessMovedToTrash({ value: true }));
    }
    if (groupType === UserQuotaGroupType.Cloud) {
        yield put(setCloudRemove({ value: !isCloudRemove }));
    }

    yield put(setIsItemsDeleteProcess({ value: false }));

    if (withSnackBar && !isCloudRemove && !isMsgRemove && count) {
        if (onSerialDeleteSuccess) {
            yield put(hideSnackbarAction('quotaCleanerDeleteAllItems'));
        }
        yield put(
            showSnackbarAction({
                id: 'quotaCleanerDeleteSuccess',
                type: SnackbarTypes.trashbin,
                text: getRemoveSnackbarText(count, groupType, groupId),
                closable: true,
                buttonText: isMobile ? 'Перейти' : 'Перейти в корзину',
                onButtonClick: () => {
                    store.dispatch(historyPush({ id: `/${EStorageType.trashbin}` }));
                },
                buttonRight: isMobile,
            })
        );
    }

    if ((isCloudRemove || isMsgRemove) && !withSnackBar && count) {
        if (onSerialDeleteSuccess) {
            yield put(hideSnackbarAction('quotaCleanerDeleteAllItems'));
        }
        onDeleteSuccess?.();
        yield put(
            showSnackbarAction({
                id: 'quotaCleanerDeleteSuccess',
                type: SnackbarTypes.success,
                text: getDeleteSnackbarText(count, groupType, groupId),
                closable: true,
            })
        );
    }

    if (count && onSerialDeleteSuccess && !withOutCloudRemove && !withSnackBar) {
        yield put(hideSnackbarAction('quotaCleanerDeleteAllItems'));
        yield put(
            showSnackbarAction({
                id: 'quotaCleanerDeleteAllItemsSuccess',
                type: SnackbarTypes.success,
                text: `${count} ${filesPlural(count)} ${deleteMalePluralV1(count)}`,
                closable: true,
            })
        );
    }

    if (IS_WEBVIEW && count) {
        sendPostMessage({
            files_deleted: count,
            files_size: size,
        });
    }

    xray.send(`quota-cln-delete-${isMsgRemove ? 'msg-' : ''}scc${isMobile ? '-mob' : ''}`, { i: groupId });

    if (isCloudRemove || withOutCloudRemove || isMsgRemove || onSerialDeleteSuccess) {
        yield put(setNeedsGroupReload({ value: true }));
        yield put(
            reloadGroupAfterDelete({
                ids,
                itemIds,
                groupId,
                size,
                onSuccess: !needToDeleteFromTrashbin ? onSerialDeleteSuccess : undefined,
            })
        );
        if (isMobile) {
            yield put(startSelecting());
        }
        if (needToDeleteFromTrashbin) {
            onSerialDeleteSuccess?.(false);
        }
    }
}
