import React, { type ReactElement, memo, useCallback } from 'react';
import { PaymentUTM } from 'reactApp/constants/paymentUTM';
import { useGoToSubscriptions } from 'reactApp/hooks/useGoToSubscriptions';
import { getDiscount } from 'reactApp/utils/getDiscount';
import { getTariffProductsByPeriods, isYearPeriod } from 'reactApp/utils/Period';

import styles from './FastCheckoutSidebar.css';
import type { FastCheckoutProps } from './FastCheckoutSidebar.types';
import { FeaturesList } from './FeaturesList';
import { PriceBlock } from './PriceBlock';
import { ProductSwitch } from './ProductSwitch';

export const FastCheckoutSidebar = memo(
    ({
        tariff,
        product,
        onProductChange,
        onClickTariffs,
        featuresList: customFeaturesList,
        isBuy,
        hideOtherTariffs,
        hideSwitchSidebarBlock,
    }: FastCheckoutProps): ReactElement => {
        const { goToSubscriptions } = useGoToSubscriptions();

        const { yearProduct, monthProduct } = getTariffProductsByPeriods(tariff?.products);

        const { space, period, discountPrice, price, hasDiscount } = product;

        const { discount } = getDiscount({ discountPrice: discountPrice || 0, price });

        const isYear = isYearPeriod(period);

        const switchChange = useCallback(() => {
            if (monthProduct && yearProduct) {
                onProductChange(isYear ? monthProduct.id : yearProduct.id);
            }
        }, [isYear, monthProduct, onProductChange, yearProduct]);

        const isSwitchBlockVisible = !hideSwitchSidebarBlock && tariff?.products?.length > 1 && Boolean(monthProduct);

        const clickTariffs = useCallback(() => {
            onClickTariffs();

            goToSubscriptions({
                inSameTab: false,
                isNewTariffsPage: true,
                query: isBuy ? PaymentUTM.fastcheckoutBuy : PaymentUTM.fastcheckout,
            });
        }, [onClickTariffs, goToSubscriptions, isBuy]);

        return (
            <div className={styles.root}>
                <div className={styles.tariffInfo}>
                    {hasDiscount && !!discount && <div className={styles.label}>Скидка {discount}%</div>}

                    <div className={styles.header}>
                        <div className={styles.quota}>+{space.space}</div>
                        <div className={styles.units}>{space.units}</div>
                    </div>

                    {isSwitchBlockVisible && (
                        <ProductSwitch
                            onSwitchChange={switchChange}
                            isYear={isYear}
                            productId={product.id}
                            monthProductPeriod={monthProduct?.period || '1m'}
                        />
                    )}

                    <PriceBlock product={product} />

                    <FeaturesList customList={customFeaturesList} productId={product.id} />
                </div>
                {!hideOtherTariffs && (
                    <div className={styles.tariffsLink} onClick={clickTariffs}>
                        Другие тарифы
                    </div>
                )}
            </div>
        );
    }
);

FastCheckoutSidebar.displayName = 'FastCheckoutSidebar';
