/* eslint-disable sonarjs/no-duplicate-string */
import type { PayloadAction } from '@reduxjs/toolkit';
import { logger } from 'lib/logger';
import { RemoveAlbumApiCall } from 'reactApp/api/albums/RemoveAlbumApiCall';
import { RemoveAlbumItemsAPICall } from 'reactApp/api/albums/RemoveAlbumItemsApiCall';
import { removeFileApiCall } from 'reactApp/api/FileRemoveApiCall';
import { removeFileWeblinksApiCall } from 'reactApp/api/weblinks/WeblinksFileRemoveApiCall';
import { IS_DOCUMENTS_DOMAIN, IS_PUBLIC_ALBUM } from 'reactApp/appHelpers/configHelpers';
import { renderRemoveDialog } from 'reactApp/components/RemoveDialog/RemoveDialog.helpers';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { EAlbumAction, sendAlbumAnalytics, sendAlbumItemsAnalytics } from 'reactApp/modules/albums/albums.analytics';
import { getCurrentAlbum } from 'reactApp/modules/albums/albums.selector';
import { isAlbum } from 'reactApp/modules/albums/albums.types';
import { handleSendAllDocumentsXray } from 'reactApp/modules/allDocuments/allDocuments.saga';
import { AllDocumentsXrayTypes } from 'reactApp/modules/allDocuments/allDocuments.types';
import { isFolder } from 'reactApp/modules/file/utils';
import { changeHomeHistory, loadHomeFolderRequest } from 'reactApp/modules/home/home.actions';
import { modifyingStart, modifyingStop, removeFileEnded, removeFileSuccess } from 'reactApp/modules/modifying/modifying.actions';
import { removeErrors } from 'reactApp/modules/modifying/modifying.constants';
import { getIdParams, getSnackbarText } from 'reactApp/modules/modifying/modifying.helpers';
import { type IRemoveItems, type PublishItem, EModifyReason, ESnackbarType } from 'reactApp/modules/modifying/modifying.types';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { getPublicItemId } from 'reactApp/modules/public/public.helpers';
import { getPublicRootWeblink, isOwnPublic } from 'reactApp/modules/public/public.selectors';
import { changeHistory, historyPush } from 'reactApp/modules/router/router.module';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { resetSelect } from 'reactApp/modules/selections/selections.actions';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { isMountedFolder } from 'reactApp/modules/storage/folder.helpers';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { getCurrentFolder as getCurrentFolderAction } from 'reactApp/modules/storage/storage.selectors';
import { type CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import type { IChangeItem } from 'reactApp/modules/uploadList/uploadList.model';
import { removeUploadFilesAction } from 'reactApp/modules/uploadList/uploadList.module';
import { isUploaderVisible } from 'reactApp/modules/uploadList/uploadList.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { updateUser } from 'reactApp/modules/user/user.thunkActions';
import { store } from 'reactApp/store';
import { noopVoid } from 'reactApp/utils/helpers';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { captureException } from 'reactApp/utils/tracer';
import { channel } from 'redux-saga';
import { all, call, put, select, take } from 'redux-saga/effects';

import { handleUmountFolder } from './mount.saga';
import { handleUnPublish, unshareAlbumAPICall } from './publish.saga';

const type = ESnackbarType.REMOVE;

const removeApiCall = (idParam) => removeFileApiCall(idParam);
const removeAlbumApiCall = ({ id }) => new RemoveAlbumApiCall().makeRequest({ album_id: id });
const removeWeblinkApiCall = (idParam) => removeFileWeblinksApiCall(idParam);
const removeAlbumItemsApiCall = ({ removeFromCloud = false, itemsId, albumId }) =>
    new RemoveAlbumItemsAPICall().makeRequest({
        remove_from_cloud: removeFromCloud,
        element_ids: itemsId,
        album_id: albumId,
    });

async function removeRequest(storage, item) {
    const { isPublic } = getStorage(storage);
    const idParam = getIdParams(storage, item.id);

    try {
        const {
            data: { body: id },
        } = await (isPublic ? removeWeblinkApiCall(idParam) : removeApiCall(idParam));

        return { id };
    } catch (error: any) {
        return { error: error?.response?.body };
    }
}

export function* handleRemoveItemsRequest(items: CloudItem[]) {
    const storage = yield select(getCurrentStorage);

    const folderFolder = yield select(getCurrentFolderAction);
    const folderFolderId = folderFolder?.id && folderFolder?.id !== ROOT_FOLDER_ID ? folderFolder.id : null;

    const selectedIds = yield select(SelectionsSelectors.getSelectedIdxs);

    const isUploader = yield select(isUploaderVisible);

    const { isInlineIntegration } = getStorage(storage);

    yield put(modifyingStart());

    if (storage !== EStorageType.public) {
        const published = items.filter((item) => Boolean('weblink' in item && item?.weblink)) as PublishItem[];

        if (published.length) {
            yield handleUnPublish({ items: published });
        }
    }

    const files = yield all(items.map((item) => removeRequest(storage, item)));
    const deletedItems: CloudItem[] = [];
    const itemsInfo: IChangeItem[] = [];
    let routeIdAfterDelete = '';

    const { foldersCount, filesCount } = items.reduce(
        (count, item, index) => {
            const file = files[index];

            if (file.error) {
                return count;
            }

            const isFile = !isFolder(item);
            if (!isFile && !routeIdAfterDelete && folderFolderId && folderFolderId === item.id) {
                routeIdAfterDelete = folderFolder.parent || ROOT_FOLDER_ID;
            }
            count.filesCount += Number(isFile);
            count.foldersCount += Number(!isFile);
            deletedItems.push(item);
            itemsInfo.push({ isFolder: !isFile, id: item.id });

            return count;
        },
        { foldersCount: 0, filesCount: 0 }
    );

    const { error } = files.find((item) => item.error) || {};

    if (error) {
        yield put(
            showSnackbarAction({
                id: 'remove-items-error',
                type: SnackbarTypes.failure,
                text: removeErrors.getMessage(error),
                closable: true,
            })
        );

        yield handleSendAllDocumentsXray({ action: 'remove_file', xrayType: AllDocumentsXrayTypes.onError });

        captureException(error, { message: 'REMOVE' });
    }

    if (!deletedItems.length) {
        yield put(modifyingStop({ storage, reason: EModifyReason.remove }));
        yield closePopupHelper(popupNames.REMOVE_DIALOG);

        return;
    }

    yield put(
        showSnackbarAction({
            closable: true,
            id: 'delete-items-success',
            type: SnackbarTypes.trashbin,
            text: getSnackbarText({
                items: deletedItems,
                foldersCount,
                filesCount,
                type: ESnackbarType.MOVE_TO_TRASHBIN,
            }),
            buttonText: !isInlineIntegration ? 'Перейти в корзину' : undefined,
            onButtonClick: () => {
                const id =
                    storage === EStorageType.alldocuments && !IS_DOCUMENTS_DOMAIN
                        ? `/${EStorageType.alldocuments}/${EStorageType.trashbin}`
                        : EStorageType.trashbin;

                store.dispatch(historyPush({ id }));
            },
        })
    );

    yield handleSendAllDocumentsXray({ action: 'remove_file', xrayType: AllDocumentsXrayTypes.onSuccess });

    deletedItems.forEach((deletedItem) => {
        if (isFolder(deletedItem)) {
            sendPaymentGa({
                eventCategory: ECategoryGa.folder,
                action: 'delete-folder',
                type_folder: folderFolder?.parent ? 'folder_in_folder' : 'folder',
            });
        }
    });

    const deletedIds = deletedItems.map((item) => item.id);
    yield put(removeFileSuccess({ ids: deletedIds }));
    yield put(removeUploadFilesAction(itemsInfo));

    // @ts-ignore
    yield put(updateUser());

    if (isMountedFolder(folderFolder)) {
        yield put(loadHomeFolderRequest({ id: folderFolder.home, isFolder: true, force: true }));
    }

    if (deletedIds.length === selectedIds.length) {
        yield put(resetSelect());
    }

    yield put(modifyingStop({ storage, reason: EModifyReason.remove }));
    if (isUploader) {
        // Чтобы обновлять папку в аплоадере https://jira.vk.team/browse/CLOUDWEB-14284
        // И не обновлять без него https://jira.vk.team/browse/CLOUDWEB-16945
        yield put(removeFileEnded({ ids: deletedIds }));
    }
    yield closePopupHelper(popupNames.REMOVE_DIALOG);

    if (routeIdAfterDelete) {
        yield put(changeHomeHistory({ id: routeIdAfterDelete }));
    }
}

function* handleRemoveAlbumRequest(item) {
    // Перед удалением нужно сделать анпаблиш
    if (item.weblink) {
        yield call(unshareAlbumAPICall, { id: item.id });
    }

    yield removeAlbumApiCall({ id: item.id });
    const currentAlbum = yield select(getCurrentAlbum);

    yield put(
        showSnackbarAction({
            closable: true,
            id: 'delete-items-success',
            type: SnackbarTypes.success,
            text: getSnackbarText({ items: [item], albumsCount: 1, type }),
        })
    );

    yield closePopupHelper(popupNames.REMOVE_DIALOG);

    sendAlbumAnalytics({ action: EAlbumAction.DELETE });

    yield put(removeFileSuccess({ ids: [item.id] }));

    if (item.id === currentAlbum?.id) {
        yield put(changeHistory('/albums'));
    }
}

function* handleRemoveAlbumItemsRequest(items) {
    // После появления совместной загрузки в альбомы,
    // уточнить у менеджеров и предусмотреть,
    // что удалять можно только свои файлы

    const storage = yield select(getCurrentStorage);
    const isAlbumPage = storage === EStorageType.albums;

    try {
        if (items.length === 1 && isAlbum(items[0])) {
            yield handleRemoveAlbumRequest(items[0]);
            return;
        }

        const currentAlbum = yield select(isAlbumPage ? getCurrentAlbum : getPublicRootWeblink);
        const albumId = isAlbumPage ? currentAlbum.id : currentAlbum.albumId;
        const itemsId = isAlbumPage ? items.map((item) => item.id) : items.map((item) => getPublicItemId(item.id));

        const { data } = yield removeAlbumItemsApiCall({ itemsId, albumId });
        const failedItems = data?.failed?.map((item) => item.id) || [];
        const deletedItems = failedItems.length ? items.filter((item) => !failedItems.includes(item.id)) : items;
        const filesCount = deletedItems.length;

        if (!filesCount) {
            yield put(
                showSnackbarAction({
                    id: 'remove-items-error',
                    type: SnackbarTypes.failure,
                    text: removeErrors.getMessage('unknown'),
                    closable: true,
                })
            );

            return;
        }

        yield put(
            showSnackbarAction({
                closable: true,
                id: 'delete-items-success',
                type: SnackbarTypes.success,
                text: getSnackbarText({ items: deletedItems, filesCount, type }),
            })
        );

        sendAlbumItemsAnalytics({ action: EAlbumAction.DELETE_MEDIA, items });

        yield put(removeFileSuccess({ ids: deletedItems.map((item) => item?.id).filter(Boolean) }));
    } catch (error) {
        yield put(
            showSnackbarAction({
                id: 'remove-items-error',
                type: SnackbarTypes.failure,
                text: removeErrors.getMessage(error),
                closable: true,
            })
        );
    }

    yield closePopupHelper(popupNames.REMOVE_DIALOG);
}

function* askForDeleteFiles({ items, hasAlienFiles = false, onSuccess = noopVoid }) {
    const deleteChannel = channel();
    const storage = yield select(getCurrentStorage);

    renderRemoveDialog({
        items,
        hasAlienFiles,
        onClose: () => deleteChannel.close(),
        onRemove: () => {
            onSuccess();
            deleteChannel.put(true);
        },
    });

    yield take(deleteChannel);
    yield deleteChannel.close();

    if (IS_PUBLIC_ALBUM || storage === EStorageType.albums) {
        yield handleRemoveAlbumItemsRequest(items);

        return;
    }

    yield handleRemoveItemsRequest(items);
}

function* processRemovePublicItems({ items }) {
    const isOwn = yield select(isOwnPublic);
    const email = yield select(UserSelectors.getEmail);

    // Если файлы пытается удалить не автор паблика, нужно отфильтровать чужие файлы
    const filteredItems = isOwn ? items : items.filter((item) => item.author === email);
    const hasAlienFiles = filteredItems.length !== items.length;

    // Оставляем только файлы, потому что удалять папки можно только внутри облака
    const files = filteredItems.filter((item) => !item.isFolder);
    const hasFolder = filteredItems.length !== files.length;

    if (hasFolder) {
        yield put(
            showSnackbarAction({
                id: 'folder-remove-error',
                text: removeErrors.getMessage('external_cloud'),
                type: SnackbarTypes.failure,
                closable: true,
            })
        );
    }

    if (!files.length && !hasAlienFiles) {
        return;
    }

    yield askForDeleteFiles({ items: files, hasAlienFiles });
}

export function* handleRemoveItems(action: PayloadAction<IRemoveItems>) {
    const storage = yield select(getCurrentStorage);
    const { isSharedIncoming, isPublic } = getStorage(storage);
    const { items, withRemoveDialog = true, onSuccess = noopVoid } = action.payload;

    if (!items?.length) {
        return;
    }

    if (!withRemoveDialog) {
        yield handleRemoveItemsRequest(items);

        return;
    }

    const item = items[0];

    if (isSharedIncoming || (items.length === 1 && item && isMountedFolder(item))) {
        yield handleUmountFolder({ item: items[0] as PublishItem });
        return;
    }

    try {
        yield call(isPublic ? processRemovePublicItems : askForDeleteFiles, { items, onSuccess });
    } catch (error) {
        logger.error(error);
        captureException(error);
        yield put(modifyingStop({ storage, reason: EModifyReason.remove }));
    }
}
