import React, { type Dispatch, type SetStateAction } from 'react';
import { ChangeHistory } from 'reactApp/ui/App/components/ChangeHistory';
import { FrozenWarningWrapper } from 'reactApp/ui/App/components/FrozenWarningWrapper';
import { InitComponent } from 'reactApp/ui/App/components/InitComponent';
import { PopupsNew } from 'reactApp/ui/Popup/PopupsNew';
import { Snackbars } from 'reactApp/ui/Snackbars/Snackbars';

interface CommonRootComponentsProps {
    onIsLoading: Dispatch<SetStateAction<boolean>>;
    ignorePaths?: string[];
}

export const CommonRootComponents = ({ onIsLoading, ignorePaths }: CommonRootComponentsProps) => {
    /* Child effects are run in order. Therefore initing here is before any other effects are called. */
    return (
        <>
            <InitComponent setIsLoading={onIsLoading} />
            <ChangeHistory ignorePaths={ignorePaths} />
            <Snackbars />
            <PopupsNew />
            <FrozenWarningWrapper />
        </>
    );
};
