/* eslint-disable max-lines */
import type { AxiosResponse } from 'lib/axios';
import { logger } from 'lib/logger';
import { xray } from 'lib/xray';
import { removeMessages } from 'reactApp/api/axios.corsapi';
import {
    getGroupType,
    getSortParams,
    prepareParamsQuotaCleaner,
    QuotaCleanerDeleteAPICall,
    QuotaCleanerGroupAPICall,
    QuotaCleanerListAPICall,
} from 'reactApp/api/QuotaCleanerAPICall';
import { MAIL_HOST } from 'reactApp/appHelpers/configHelpers';
import { quotaCleanerOldGroupYear } from 'reactApp/appHelpers/featuresHelpers';
import { seriallyClearQuotaCleanerFeature } from 'reactApp/appHelpers/featuresHelpers/features/quotaCleanerSeriallyClear';
import { AttachesSelectors } from 'reactApp/modules/attaches/attaches.selectors';
import type { AttachesFolderItem } from 'reactApp/modules/attaches/attaches.types';
import {
    getFeatureQuotaCleanerCloud,
    getFeatureQuotaCleanerGroups,
    getFeatureQuotaCleanerItemsMaxOffset,
    getFeatureQuotaCleanerLetters,
    getFeatureQuotaCleanerLimit,
    getFeatureQuotaCleanerMaxLettersCount,
    getFeatureQuotaCleanerYearFilter,
} from 'reactApp/modules/features/features.selectors';
import { showVirusDlg } from 'reactApp/modules/popup/popup.module';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';
import { resetSelect, startSelecting } from 'reactApp/modules/selections/selections.actions';
import { hideSnackbarAction, showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { setShowUploaderAction } from 'reactApp/modules/uploadList/uploadList.module';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { cloudGroups, letterGroups, quotaCleanerRlog } from 'reactApp/modules/userQuotaCleaner/helpers/constants';
import { getRemoveSnackbarText } from 'reactApp/modules/userQuotaCleaner/helpers/getDeleteSnackbarText';
import { getHasMoreToLoad } from 'reactApp/modules/userQuotaCleaner/helpers/getHasMoreToLoad';
import { openItemView } from 'reactApp/modules/userQuotaCleaner/helpers/openItemView';
import { deleteItemsError } from 'reactApp/modules/userQuotaCleaner/sagas/deleteItemsError';
import { deleteItemsSuccess } from 'reactApp/modules/userQuotaCleaner/sagas/deleteItemsSuccess';
import { deleteLetters } from 'reactApp/modules/userQuotaCleaner/sagas/deleteLetters';
import { handleGoToCleanerAndShowQuotaCleaner } from 'reactApp/modules/userQuotaCleaner/sagas/handleGoToCleanerAndShowQuotaCleaner';
import {
    deleteBatchUserQuotaCleanerAllItems,
    deleteBatchUserQuotaCleanerAllLetters,
    deleteLettersFromQuota,
    deleteUserQuotaCleanerAllItems,
    deleteUserQuotaCleanerAllLetters,
    deleteUserQuotaCleanerItems,
    deleteUserQuotaCleanerItemsFromStore,
    deleteUserQuotaCleanerItemsSuccess,
    getUserQuotaGroup,
    goToCleanerAndShowQuotaCleaner,
    loadUserQuotaCleanerList,
    loadUserQuotaCleanerListError,
    loadUserQuotaCleanerListSuccess,
    loadUserQuotaCleanerStart,
    loadUserQuotaGroup,
    loadUserQuotaGroupError,
    loadUserQuotaGroupFromList,
    loadUserQuotaGroupMore,
    loadUserQuotaGroupSuccess,
    openUserQuotaItemView,
    prepareUserQuotaCleanerParams,
    reloadGroupAfterDelete,
    setCloudRemove,
    setCurrentGroup,
    setIsItemsDeleteProcess,
    setMessagesRemove,
    setNeedsGroupReload,
    setSuccessMovedToTrash,
    setTrashFilesIds,
    setYearGroupConfig,
    setYearGroupsConfig,
    startLoadingGroup,
} from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.actions';
import {
    getCurrentGroup,
    getGroupById,
    getGroupsParams,
    getItemByGroup,
    getItemById,
    getYearGroupConfig,
    getYearGroupsConfig,
    isCloudRemoveNeeded,
    isMeesagesRemoveNeeded,
    trashFilesIds,
} from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.selectors';
import {
    type QuotaCleanerGroup,
    type QuotaCleanerGroupParam,
    type QuotaCleanerItem,
    type QuotaCleanerListParam,
    type QuotaCleanerYearConfig,
    QuotaCleanerYears,
} from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.types';
import { store } from 'reactApp/store';
import {
    type UserQuotaCleanerDelete,
    type UserQuotaCleanerGroup,
    type UserQuotaCleanerListParams,
    UserQuotaGroupId,
    UserQuotaGroupType,
} from 'reactApp/types/QuotaCleaner';
import opener from 'reactApp/utils/opener';
import { deleteMalePluralV2, deleteNeutralPluralV2, filesPlural, hePlural, lettersPlural } from 'reactApp/utils/pluralHelpers';
import { call, cancel, put, select, takeEvery } from 'redux-saga/effects';

import { handleAttachFoldersRequest } from '../attaches/attaches.saga';
import { getCurrentStorage } from '../router/router.selectors';
import { snackbarController } from '../snackbar/snackbar.controller';
import { type CloudItem, EStorageType } from '../storage/storage.types';
import { UserSelectors } from '../user/user.selectors';
import { getYearDeleteConfig } from './helpers/getYearDeleteConfig';
import { getYearParams } from './helpers/getYearParams';

const quotaCleanerListApiCall = (groupParams: QuotaCleanerListParam): Promise<AxiosResponse<{ body?: UserQuotaCleanerGroup[] }>> =>
    new QuotaCleanerListAPICall().makeRequest(groupParams);

const quotaCleanerGroup = (groupParam: QuotaCleanerGroupParam): Promise<AxiosResponse<{ body?: UserQuotaCleanerGroup }>> =>
    new QuotaCleanerGroupAPICall().makeRequest(groupParam);

const quotaCleanerItemsDelete = (ids: string[], file_list: boolean): Promise<AxiosResponse<{ body?: UserQuotaCleanerDelete }>> =>
    new QuotaCleanerDeleteAPICall().makeRequest({ ids, file_list });

function* prepareGroupParams(folders: AttachesFolderItem[], userFolders: AttachesFolderItem[], messagesTotalCount: number) {
    const externalGroups: UserQuotaGroupId[] = [];

    const isMailBinDisabled = yield select(UserQuotaSelectors.getIsMailBinDisabled);
    if (isMailBinDisabled) {
        externalGroups.push(UserQuotaGroupId.MailBin);
    }

    const isCloudEnabled = yield select(getFeatureQuotaCleanerCloud);
    if (!isCloudEnabled) {
        externalGroups.push(...cloudGroups);
    }

    const maxLettersCount = yield select(getFeatureQuotaCleanerMaxLettersCount);
    const isLettersEnabled = yield select(getFeatureQuotaCleanerLetters);
    const isLettersCountInvalid = maxLettersCount && messagesTotalCount > maxLettersCount;

    if (!isLettersEnabled || isLettersCountInvalid) {
        externalGroups.push(...letterGroups);
    }

    const folderIds = folders.map((folder) => folder.id);
    const userFoldersIds = userFolders.map((folder) => folder.id);

    const enabledGroups = yield select(getFeatureQuotaCleanerGroups);
    const disabledGroups = [...letterGroups, ...cloudGroups].filter((group) => !enabledGroups.includes(group));
    externalGroups.push(...disabledGroups);

    const limit = yield select(getFeatureQuotaCleanerLimit);
    const isYearFilter = yield select(getFeatureQuotaCleanerYearFilter);

    const params = prepareParamsQuotaCleaner({
        folders: folderIds,
        userFolders: userFoldersIds,
        limit,
        isYearFilter,
        oldYearGroupShift: quotaCleanerOldGroupYear,
        excludedGroupIds: externalGroups,
    });
    yield put(prepareUserQuotaCleanerParams(params));
}

function* getQuotaCleanerList() {
    yield put(loadUserQuotaCleanerStart());
    const groupParams: Record<UserQuotaGroupId, UserQuotaCleanerListParams> = yield select(getGroupsParams);
    const isMobile = yield select(QuotaLandingSelectors.isMobile);

    try {
        const groupList = Object.values(groupParams);
        if (!groupList.length) {
            yield put(loadUserQuotaCleanerListSuccess([]));
            return;
        }

        const {
            data: { groups },
        } = yield quotaCleanerListApiCall({ groups: groupList });
        yield put(loadUserQuotaCleanerListSuccess(groups));
        xray.send(`quota-cln-show-scc${isMobile ? '-mob' : ''}`);
    } catch (error) {
        yield put(loadUserQuotaCleanerListError());
        logger.error(error);
        xray.send(`quota-cln-get-list-err${isMobile ? '-mob' : ''}`, {
            rlog: quotaCleanerRlog,
            rlog_message: {
                error,
            },
        });
    }
}

function* loadQuotaCleanerList() {
    const isMobile = yield select(QuotaLandingSelectors.isMobile);
    let folders: AttachesFolderItem[] = [];
    let userFolders: AttachesFolderItem[] = [];
    let messagesTotalCount = 0;

    try {
        yield call(handleAttachFoldersRequest);
        const foldersData = yield select(AttachesSelectors.getAttachFolders);
        folders = Array.from(Object.values(foldersData));
        userFolders = folders.filter((folder) => !folder.system);
        messagesTotalCount = yield select(AttachesSelectors.getAllMessagesTotalCount);
    } catch (error) {
        logger.error(error);
        xray.send(`quota-cln-folders-err${isMobile ? '-mob' : ''}`, {
            rlog: quotaCleanerRlog,
            rlog_message: {
                error,
            },
        });
    }

    yield prepareGroupParams(folders, userFolders, messagesTotalCount);
    yield getQuotaCleanerList();
}

function* updateYearGroupConfig(action: { deletedCount: number; deletedSize: number; itemIds: string[] }) {
    const { deletedCount, deletedSize, itemIds } = action;
    const yearGroupsConfig = yield select(getYearGroupsConfig);
    const currentGroup: QuotaCleanerGroup | null = yield select(getCurrentGroup);
    if (!currentGroup) {
        return;
    }

    const { groupId, totalCount, size, list } = currentGroup;
    const resultedYearGroupsConfig: QuotaCleanerYearConfig = JSON.parse(JSON.stringify(yearGroupsConfig)); // делаем копию, т.к. потом будем удалять поле по ключу
    const currentYearGroupConfig = resultedYearGroupsConfig[groupId];
    if (!currentYearGroupConfig) {
        return;
    }

    // кейс когда ты находишься на фильтре все и удаляешь все элементы из какого-то года
    const deleteYearConfig = getYearDeleteConfig(list, itemIds);

    const { currentYear, config } = currentYearGroupConfig;
    const isAll = currentYear === QuotaCleanerYears.All;

    const updatedCount = Math.max(totalCount || 0 - deletedCount, 0);
    const updatedSize = Math.max(size - deletedSize, 0);

    if (!updatedCount || !updatedSize) {
        // когда вся группа пустая
        if (isAll) {
            delete resultedYearGroupsConfig[groupId];
        } else {
            currentYearGroupConfig[QuotaCleanerYears.All].size -= deleteYearConfig[currentYear]?.size || 0;
            currentYearGroupConfig[QuotaCleanerYears.All].totalCount -= deleteYearConfig[currentYear]?.count || 0;

            const years = Object.keys(config);
            const currentYearIndex = Object.keys(config).findIndex((year) => year === currentYear);
            // если за текущий год все кончилось, показываем предыдущий или, если его нет, следующий
            const updatedCurrentYear = years[currentYearIndex - 1] ?? years[currentYearIndex + 1] ?? QuotaCleanerYears.All;
            currentYearGroupConfig.currentYear = updatedCurrentYear;
            delete config[currentYear];
        }
    } else if (isAll) {
        Object.keys(deleteYearConfig).forEach((year) => {
            if (!config[year]) {
                return;
            }

            config[year].size -= deleteYearConfig[year]?.size || 0;
            config[year].totalCount -= deleteYearConfig[year]?.count || 0;
        });

        currentYearGroupConfig[QuotaCleanerYears.All] = {
            size: updatedSize,
            totalCount: updatedCount,
        };
    } else {
        currentYearGroupConfig[QuotaCleanerYears.All].size -= deleteYearConfig[currentYear]?.size || 0;
        currentYearGroupConfig[QuotaCleanerYears.All].totalCount -= deleteYearConfig[currentYear]?.count || 0;

        config[currentYear] = {
            size: updatedSize,
            totalCount: updatedCount,
        };
    }

    // чистим невалидные значения
    Object.keys(config).forEach((year) => {
        const { size, totalCount } = config[year];
        if (size <= 0 || totalCount <= 0) {
            delete config[year];
        }
    });

    // если после чистки текущий фильтр удалился
    if (!config[currentYearGroupConfig.currentYear]) {
        currentYearGroupConfig.currentYear = QuotaCleanerYears.All;
    }

    yield put(setYearGroupsConfig({ value: resultedYearGroupsConfig }));
}

function* loadGroup(action: ReturnType<typeof loadUserQuotaGroup>) {
    const {
        payload: { groupId, offset: currentOffset, isGroupReset, onSuccess },
    } = action;
    const validOffset = typeof currentOffset === 'number' && !Number.isNaN(currentOffset) ? currentOffset : 0;
    const maxOffset = yield select(getFeatureQuotaCleanerItemsMaxOffset);
    const limit = yield select(getFeatureQuotaCleanerLimit);
    const offset = Math.min(validOffset, maxOffset);

    const groupParams = yield select(getGroupsParams);
    const yearConfig = yield select(getYearGroupConfig, groupId);
    const isYearAallView = yearConfig?.currentYear === QuotaCleanerYears.All;
    const isMobile = yield select(QuotaLandingSelectors.isMobile);

    const groupParam = {
        ...groupParams[groupId],
        ...getSortParams(isYearAallView),
        ...getYearParams(yearConfig?.currentYear),
        limit,
        offset,
    };

    try {
        const { data } = yield quotaCleanerGroup(groupParam);
        yield put(loadUserQuotaGroupSuccess({ data, offset, limit, maxOffset, isGroupReset }));
        xray.send(`quota-cln-group-scc${isMobile ? '-mob' : ''}`, { i: groupId });

        onSuccess?.();
    } catch (error) {
        yield put(loadUserQuotaGroupError({ groupId, groupType: getGroupType(groupId) }));
        logger.error(error);
        xray.send(`quota-cln-get-group-err${isMobile ? '-mob' : ''}`, {
            i: groupId,
            rlog: quotaCleanerRlog,
            rlog_message: {
                error,
                groupId,
                groupParam,
                offset,
            },
        });
        if (seriallyClearQuotaCleanerFeature) {
            yield put(hideSnackbarAction('quotaCleanerDeleteAllItems'));
            yield put(
                showSnackbarAction({
                    type: SnackbarTypes.failure,
                    id: 'quotaCleanerDeleteAllItemsError',
                    text: `Не получилось удалить файлы. \nПовторите попытку позже`,
                    closable: true,
                    wrapText: true,
                })
            );
        } else {
            yield put(
                showSnackbarAction({
                    id: 'quotaCleanerLoadGroupError',
                    type: SnackbarTypes.failure,
                    text: 'Ошибка загрузки. Обновите страницу или попробуйте позже',
                    closable: true,
                })
            );
        }
    }
}

function* loadGroupFromList(action: ReturnType<typeof loadUserQuotaGroupFromList>) {
    const {
        payload: { groupId, onSuccess },
    } = action;
    const currentGroup = yield select(getGroupById, groupId);
    if (!currentGroup) {
        return;
    }

    const { childs, totalCount } = currentGroup;

    const maxOffset = yield select(getFeatureQuotaCleanerItemsMaxOffset);
    const limit = yield select(getFeatureQuotaCleanerLimit);

    yield put(
        setCurrentGroup({
            value: {
                ...currentGroup,
                hasMoreToLoad: getHasMoreToLoad({
                    currentCount: childs.length,
                    totalCount,
                    maxOffset,
                    limit,
                }),
            },
        })
    );

    onSuccess?.();
}

function* loadGroupMore(action: ReturnType<typeof loadUserQuotaGroupMore>) {
    const {
        payload: { offset },
    } = action;
    const { groupId } = yield select(getCurrentGroup) ?? {};
    if (!groupId) {
        return;
    }

    yield put(loadUserQuotaGroup({ groupId, offset }));
}

function* getGroup(action: ReturnType<typeof getUserQuotaGroup>) {
    const {
        payload: { groupId, year, onSuccess },
    } = action;
    const yearConfig = yield select(getYearGroupConfig, groupId);
    const hasYearChanged = year !== yearConfig?.currentYear;

    if (year) {
        yield put(
            setYearGroupConfig({
                groupId,
                value: {
                    ...yearConfig,
                    currentYear: year,
                },
            })
        );

        yield put(startLoadingGroup({ groupId, groupType: getGroupType(groupId), hasYearChanged }));
        yield put(loadUserQuotaGroup({ groupId, onSuccess }));
    } else {
        yield put(loadUserQuotaGroupFromList({ groupId, onSuccess }));
    }
}

function* onDeleteUpdate(action: ReturnType<typeof reloadGroupAfterDelete>) {
    const {
        payload: { ids, itemIds, groupId, size, isGroupReset, onSuccess },
    } = action;
    yield put(resetSelect());
    yield call(updateYearGroupConfig, { deletedCount: ids.length, deletedSize: size, itemIds });

    yield put(deleteUserQuotaCleanerItemsFromStore({ deleteIds: ids }));
    yield put(setMessagesRemove({ value: false }));
    yield put(setCloudRemove({ value: false }));
    yield put(setTrashFilesIds({ value: [] }));
    yield put(setSuccessMovedToTrash({ value: false }));

    yield put(loadUserQuotaGroup({ groupId, isGroupReset, onSuccess }));
}

function* deleteItems(action: ReturnType<typeof deleteUserQuotaCleanerItems>) {
    const {
        payload: {
            ids,
            itemIds,
            items,
            groupId,
            groupType,
            size,
            withOutMessagesRemove,
            withOutCloudRemove,
            withSnackBar,
            onDeleteSuccess,
        },
    } = action;
    try {
        yield put(setIsItemsDeleteProcess({ value: true }));
        const isCloudRemove = yield select(isCloudRemoveNeeded);
        const trashIds = yield select(trashFilesIds);
        const isMounted = groupId === UserQuotaGroupId.Mounted;
        let itemsToDelete: string[];
        let returnFileList: boolean;

        if (isCloudRemove && !ids[0].includes(':trash') && trashIds.length > 0) {
            itemsToDelete = trashIds.map((item: string) => `cloud:${isMounted ? 'm' : 'f'}:${item}`);
            returnFileList = false;
            yield put(setTrashFilesIds({ value: [] }));
        } else {
            itemsToDelete = ids;
            returnFileList = groupId !== UserQuotaGroupId.CloudBin;
        }

        const { data } = yield quotaCleanerItemsDelete(itemsToDelete, returnFileList);
        if (data.status === 'fail') {
            throw new Error(data);
        }
        if (data.succeeded_items) {
            yield put(setTrashFilesIds({ value: data.succeeded_items.map((item: { id: string }) => item.id) }));
        }

        const isMailBinDisabled = yield select(UserQuotaSelectors.getIsMailBinDisabled);
        const isLetterGroup = groupType === UserQuotaGroupType.Letter;
        if (isLetterGroup && !isMailBinDisabled) {
            yield removeLettersSuccess({ ids, itemIds, groupId, withOutMessagesRemove, withSnackBar, size });
            return;
        }

        yield put(
            deleteUserQuotaCleanerItemsSuccess({
                count: ids.length,
                groupType,
                groupId,
                ids,
                itemIds,
                items,
                size,
                withOutCloudRemove,
                withSnackBar,
                onDeleteSuccess,
            })
        );
    } catch (error) {
        yield put(setCloudRemove({ value: false }));
        yield put(setTrashFilesIds({ value: [] }));
        yield put(setSuccessMovedToTrash({ value: false }));
        yield deleteItemsError({ error, groupId, ids, itemIds, size });
    }
}

let stopDeletingAllItems = false;

function* deleteAllItems(action: ReturnType<typeof deleteUserQuotaCleanerAllItems>) {
    const {
        payload: { size, withOutMessagesRemove, withOutCloudRemove, withSnackBar, onDeleteSuccess },
    } = action;
    const storage = yield select(getCurrentStorage);
    const currentGroup = yield select(getCurrentGroup);
    const { groupId, groupType } = currentGroup as QuotaCleanerGroup;
    const limit = yield select(getFeatureQuotaCleanerLimit);
    let items: QuotaCleanerItem[] | CloudItem[] = [];
    if (currentGroup) {
        items = Object.values(currentGroup.list);
    }

    if (stopDeletingAllItems) {
        stopDeletingAllItems = false;
        return;
    }

    if (storage !== EStorageType.quotaCleaner) {
        return;
    }

    if (!items || (items && !items.length)) {
        yield put(
            showSnackbarAction({
                type: SnackbarTypes.failure,
                id: 'quotaCleanerDeleteAllItemsError',
                text: `Не получилось удалить ${groupType === 'letter' ? 'письма' : 'файлы'}. \nПовторите попытку позже`,
                closable: true,
                wrapText: true,
            })
        );
        return;
    }
    snackbarController.hideSnackbar('quotaCleanerDeleteAllItemsError');
    snackbarController.hideSnackbar('quotaCleanerDeleteAllItemsStopped');
    snackbarController.showSnackbar({
        id: 'quotaCleanerDeleteAllItems',
        text:
            !withOutMessagesRemove && !withOutCloudRemove && !withSnackBar
                ? `Удаление ${groupType === 'letter' ? 'писем' : 'файлов'}`
                : 'Перемещение в корзину',
        type: SnackbarTypes.loading,
        disableCloseTimeout: true,
        buttonText: currentGroup.hasMoreToLoad ? 'Остановить' : undefined,
        buttonRight: items?.length >= limit,
        onButtonClick: () => {
            if (items?.length >= limit) {
                stopDeletingAllItems = true;
            }
        },
    });

    try {
        yield put(resetSelect());
        yield put(
            deleteBatchUserQuotaCleanerAllItems({
                deletedLength: 0,
                size,
                withOutMessagesRemove,
                withOutCloudRemove,
                withSnackBar,
                needToDeleteFromTrashbin:
                    !withOutMessagesRemove &&
                    !withOutCloudRemove &&
                    !withSnackBar &&
                    groupId !== UserQuotaGroupId.CloudBin &&
                    groupId !== UserQuotaGroupId.MailBin,
                onDeleteSuccess,
            })
        );
    } catch (error) {
        yield put(hideSnackbarAction('quotaCleanerDeleteAllItems'));
        yield put(
            showSnackbarAction({
                type: SnackbarTypes.failure,
                id: 'quotaCleanerDeleteAllItemsError',
                text: `Не получилось удалить ${groupType === 'letter' ? 'письма' : 'файлы'}. \nПовторите попытку позже`,
                closable: true,
                wrapText: true,
            })
        );
        yield cancel();
    }
}

function* deleteBatchInDeletingAllItems(action: ReturnType<typeof deleteBatchUserQuotaCleanerAllItems>) {
    const {
        payload: {
            deletedLength,
            size,
            withOutMessagesRemove,
            withOutCloudRemove,
            withSnackBar,
            needToDeleteFromTrashbin,
            onDeleteSuccess,
        },
    } = action;
    const storage = yield select(getCurrentStorage);
    const currentGroup = yield select(getCurrentGroup);
    let items: QuotaCleanerItem[] | CloudItem[] = [];
    if (currentGroup) {
        items = Object.values(currentGroup.list);
    }
    const ids = items.map((item) => item.deleteId);
    const itemIds = items.map((item) => item.id);

    if (
        (stopDeletingAllItems &&
            items.length &&
            (needToDeleteFromTrashbin ||
                withOutMessagesRemove ||
                withOutCloudRemove ||
                withSnackBar ||
                currentGroup?.groupId === UserQuotaGroupId.CloudBin ||
                currentGroup?.groupId === UserQuotaGroupId.MailBin)) ||
        storage !== EStorageType.quotaCleaner
    ) {
        snackbarController.hideSnackbar('quotaCleanerDeleteAllItems');
        snackbarController.showSnackbar({
            type: SnackbarTypes.warning,
            id: 'quotaCleanerDeleteAllItemsStopped',
            text: `${deletedLength} ${
                currentGroup?.groupType === 'letter' ? lettersPlural(deletedLength) : filesPlural(deletedLength)
            } уже ${
                currentGroup?.groupType === 'letter' ? deleteNeutralPluralV2(deletedLength) : deleteMalePluralV2(deletedLength)
            } — восстановить ${hePlural(deletedLength)} не получится. Остальные будут в Облаке`,
            hideCloseIcon: true,
            closable: true,
        });
        stopDeletingAllItems = false;
        if (storage !== EStorageType.quotaCleaner) {
            yield cancel();
        }
        return;
    }

    if (!items.length) {
        stopDeletingAllItems = false;
        return;
    }

    const { groupId, groupType } = currentGroup as QuotaCleanerGroup;

    try {
        yield put(setIsItemsDeleteProcess({ value: true }));
        const isCloudRemove = yield select(isCloudRemoveNeeded);
        const trashIds = yield select(trashFilesIds);
        const isMounted = groupId === UserQuotaGroupId.Mounted;
        let itemsToDelete: string[];
        let returnFileList: boolean;

        if (isCloudRemove && !ids[0].includes(':trash') && trashIds.length > 0) {
            itemsToDelete = trashIds.map((item: string) => `cloud:${isMounted ? 'm' : 'f'}:${item}`);
            returnFileList = false;
            yield put(setTrashFilesIds({ value: [] }));
        } else {
            itemsToDelete = ids;
            returnFileList = groupId !== UserQuotaGroupId.CloudBin;
        }

        const { data } = yield quotaCleanerItemsDelete(itemsToDelete, returnFileList);
        if (data.status === 'fail') {
            throw new Error(data);
        }
        if (data.succeeded_items) {
            yield put(setTrashFilesIds({ value: data.succeeded_items.map((item: { id: string }) => item.id) }));
        }

        const isMailBinDisabled = yield select(UserQuotaSelectors.getIsMailBinDisabled);
        const isLetterGroup = groupType === UserQuotaGroupType.Letter;

        const deleteBatchUserQuotaCleanerAllItemsCallback = (needToDeleteFromTrashbinFromCallback?: boolean) => {
            store.dispatch(
                deleteBatchUserQuotaCleanerAllItems({
                    deletedLength:
                        !needToDeleteFromTrashbin &&
                        !withOutMessagesRemove &&
                        !withOutCloudRemove &&
                        !withSnackBar &&
                        groupId !== UserQuotaGroupId.CloudBin &&
                        groupId !== UserQuotaGroupId.MailBin
                            ? deletedLength
                            : deletedLength + itemsToDelete?.length,
                    size,
                    withOutMessagesRemove,
                    withOutCloudRemove,
                    withSnackBar,
                    needToDeleteFromTrashbin:
                        needToDeleteFromTrashbinFromCallback ??
                        (!withOutMessagesRemove &&
                            !withOutCloudRemove &&
                            !withSnackBar &&
                            groupId !== UserQuotaGroupId.CloudBin &&
                            groupId !== UserQuotaGroupId.MailBin),
                    onDeleteSuccess,
                })
            );
        };

        const deleteBatchUserQuotaCleanerAllLettersCallback = (needToDeleteFromTrashbinFromCallback?: boolean) => {
            store.dispatch(
                deleteBatchUserQuotaCleanerAllLetters({
                    deletedLength:
                        !needToDeleteFromTrashbin &&
                        !withOutMessagesRemove &&
                        !withOutCloudRemove &&
                        !withSnackBar &&
                        groupId !== UserQuotaGroupId.CloudBin &&
                        groupId !== UserQuotaGroupId.MailBin
                            ? deletedLength
                            : deletedLength + itemsToDelete?.length,
                    size,
                    needToDeleteFromTrashbin:
                        needToDeleteFromTrashbinFromCallback ??
                        (!withOutMessagesRemove &&
                            !withOutCloudRemove &&
                            !withSnackBar &&
                            groupId !== UserQuotaGroupId.CloudBin &&
                            groupId !== UserQuotaGroupId.MailBin),
                    onDeleteSuccess,
                })
            );
        };

        if (isLetterGroup && !isMailBinDisabled) {
            yield removeLettersSuccess({
                ids,
                itemIds,
                groupId,
                withOutMessagesRemove,
                withSnackBar,
                size,
                onSerialDeleteSuccess: needToDeleteFromTrashbin
                    ? deleteBatchUserQuotaCleanerAllLettersCallback
                    : deleteBatchUserQuotaCleanerAllItemsCallback,
                needToDeleteFromTrashbin,
                count: !currentGroup.hasMoreToLoad
                    ? !needToDeleteFromTrashbin &&
                      !withOutMessagesRemove &&
                      !withOutCloudRemove &&
                      !withSnackBar &&
                      groupId !== UserQuotaGroupId.CloudBin &&
                      groupId !== UserQuotaGroupId.MailBin
                        ? deletedLength
                        : deletedLength + itemsToDelete?.length
                    : undefined,
            });
            return;
        }

        yield put(
            deleteUserQuotaCleanerItemsSuccess({
                count: !currentGroup.hasMoreToLoad
                    ? !needToDeleteFromTrashbin &&
                      !withOutMessagesRemove &&
                      !withOutCloudRemove &&
                      !withSnackBar &&
                      groupId !== UserQuotaGroupId.CloudBin &&
                      groupId !== UserQuotaGroupId.MailBin
                        ? deletedLength
                        : deletedLength + itemsToDelete?.length
                    : undefined,
                groupType,
                groupId,
                ids,
                itemIds,
                items,
                size,
                withOutCloudRemove,
                withSnackBar,
                onDeleteSuccess,
                onSerialDeleteSuccess: deleteBatchUserQuotaCleanerAllItemsCallback,
                needToDeleteFromTrashbin,
            })
        );
    } catch (error) {
        yield put(setCloudRemove({ value: false }));
        yield put(setTrashFilesIds({ value: [] }));
        yield put(setSuccessMovedToTrash({ value: false }));
        yield deleteItemsError({ error, groupId, ids, itemIds, size });
        yield put(hideSnackbarAction('quotaCleanerDeleteAllItems'));
        yield put(
            showSnackbarAction({
                type: SnackbarTypes.failure,
                id: 'quotaCleanerDeleteAllItemsError',
                text: `Не получилось удалить ${groupType === 'letter' ? 'письма' : 'файлы'}. \nПовторите попытку позже`,
                closable: true,
                wrapText: true,
            })
        );
        snackbarController.hideSnackbar('quotaCleanerDeleteAllItems');
        yield cancel();
    }
}

export function* deleteAllLetters(action: ReturnType<typeof deleteUserQuotaCleanerAllLetters>) {
    const {
        payload: { size, onDeleteSuccess },
    } = action;
    const storage = yield select(getCurrentStorage);
    const currentGroup = yield select(getCurrentGroup);
    const { groupId } = currentGroup as QuotaCleanerGroup;
    const limit = yield select(getFeatureQuotaCleanerLimit);
    let items: QuotaCleanerItem[] | CloudItem[] = [];
    if (currentGroup) {
        items = Object.values(currentGroup.list);
    }

    if (stopDeletingAllItems) {
        stopDeletingAllItems = false;
        return;
    }

    if (storage !== EStorageType.quotaCleaner) {
        return;
    }

    if (!items || (items && !items.length)) {
        yield put(
            showSnackbarAction({
                type: SnackbarTypes.failure,
                id: 'quotaCleanerDeleteAllItemsError',
                text: `Не получилось удалить письма. \nПовторите попытку позже`,
                closable: true,
                wrapText: true,
            })
        );
        return;
    }
    snackbarController.hideSnackbar('quotaCleanerDeleteAllItemsError');
    snackbarController.hideSnackbar('quotaCleanerDeleteAllItemsStopped');
    snackbarController.showSnackbar({
        id: 'quotaCleanerDeleteAllItems',
        text: 'Перемещение в корзину',
        type: SnackbarTypes.loading,
        disableCloseTimeout: true,
        buttonText: currentGroup.hasMoreToLoad ? 'Остановить' : undefined,
        buttonRight: items?.length >= limit,
        onButtonClick: () => {
            if (items?.length >= limit) {
                stopDeletingAllItems = true;
            }
        },
    });

    try {
        yield put(resetSelect());
        yield put(
            deleteBatchUserQuotaCleanerAllLetters({
                deletedLength: 0,
                size,
                needToDeleteFromTrashbin: groupId !== UserQuotaGroupId.CloudBin && groupId !== UserQuotaGroupId.MailBin,
                onDeleteSuccess,
            })
        );
    } catch (error) {
        yield put(hideSnackbarAction('quotaCleanerDeleteAllItems'));
        yield put(
            showSnackbarAction({
                type: SnackbarTypes.failure,
                id: 'quotaCleanerDeleteAllItemsError',
                text: `Не получилось удалить письма. \nПовторите попытку позже`,
                closable: true,
                wrapText: true,
            })
        );
        yield cancel();
    }
}

function* deleteBatchInDeletingAllLetters(action: ReturnType<typeof deleteBatchUserQuotaCleanerAllLetters>) {
    const {
        payload: { deletedLength, size, needToDeleteFromTrashbin, onDeleteSuccess },
    } = action;

    const storage = yield select(getCurrentStorage);
    const currentGroup = yield select(getCurrentGroup);
    let items: QuotaCleanerItem[] | CloudItem[] = [];
    if (currentGroup) {
        items = Object.values(currentGroup.list);
    }
    const { groupId, groupType } = currentGroup as QuotaCleanerGroup;
    const ids = items.map((item) => item.deleteId);
    const itemIds = items.map((item) => item.id);

    if (
        (stopDeletingAllItems &&
            items.length &&
            (needToDeleteFromTrashbin ||
                currentGroup?.groupId === UserQuotaGroupId.CloudBin ||
                currentGroup?.groupId === UserQuotaGroupId.MailBin)) ||
        storage !== EStorageType.quotaCleaner
    ) {
        snackbarController.hideSnackbar('quotaCleanerDeleteAllItems');
        snackbarController.showSnackbar({
            type: SnackbarTypes.warning,
            id: 'quotaCleanerDeleteAllItemsStopped',
            text: `${deletedLength} ${lettersPlural(deletedLength)} уже ${deleteNeutralPluralV2(deletedLength)} — восстановить ${hePlural(
                deletedLength
            )} не получится. Остальные будут в Облаке`,
            hideCloseIcon: true,
            closable: true,
        });
        stopDeletingAllItems = false;
        if (storage !== EStorageType.quotaCleaner) {
            yield cancel();
        }
        return;
    }

    if (!items.length) {
        stopDeletingAllItems = false;
        return;
    }

    const email = yield select(UserSelectors.getEmail);
    yield put(setIsItemsDeleteProcess({ value: true }));

    try {
        const res = yield removeMessages(email, ids);
        if (res.status !== 200) {
            throw res;
        }

        const deleteBatchUserQuotaCleanerAllItemsCallback = (needToDeleteFromTrashbinFromCallback?: boolean) => {
            store.dispatch(
                deleteBatchUserQuotaCleanerAllItems({
                    deletedLength:
                        !needToDeleteFromTrashbin && groupId !== UserQuotaGroupId.CloudBin && groupId !== UserQuotaGroupId.MailBin
                            ? deletedLength
                            : deletedLength + items?.length,
                    size,
                    withOutMessagesRemove: false,
                    withOutCloudRemove: false,
                    withSnackBar: false,
                    needToDeleteFromTrashbin:
                        needToDeleteFromTrashbinFromCallback ??
                        (groupId !== UserQuotaGroupId.CloudBin && groupId !== UserQuotaGroupId.MailBin),
                    onDeleteSuccess,
                })
            );
        };

        const deleteBatchUserQuotaCleanerAllLettersCallback = (needToDeleteFromTrashbinFromCallback?: boolean) => {
            store.dispatch(
                deleteBatchUserQuotaCleanerAllLetters({
                    deletedLength:
                        !needToDeleteFromTrashbin && groupId !== UserQuotaGroupId.CloudBin && groupId !== UserQuotaGroupId.MailBin
                            ? deletedLength
                            : deletedLength + items?.length,
                    size,
                    needToDeleteFromTrashbin:
                        needToDeleteFromTrashbinFromCallback ??
                        (groupId !== UserQuotaGroupId.CloudBin && groupId !== UserQuotaGroupId.MailBin),
                    onDeleteSuccess,
                })
            );
        };

        yield put(
            deleteUserQuotaCleanerItemsSuccess({
                count: !currentGroup.hasMoreToLoad
                    ? !needToDeleteFromTrashbin && groupId !== UserQuotaGroupId.CloudBin && groupId !== UserQuotaGroupId.MailBin
                        ? deletedLength
                        : deletedLength + items?.length
                    : undefined,
                groupType,
                groupId,
                ids,
                itemIds,
                size,
                isMsgRemove: true,
                onDeleteSuccess,
                needToDeleteFromTrashbin,
                onSerialDeleteSuccess: currentGroup.hasMoreToLoad
                    ? groupId === UserQuotaGroupId.MailBin
                        ? deleteBatchUserQuotaCleanerAllLettersCallback
                        : deleteBatchUserQuotaCleanerAllItemsCallback
                    : undefined,
            })
        );
        if (!currentGroup.hasMoreToLoad) {
            yield put(hideSnackbarAction('quotaCleanerDeleteAllItems'));
        }
        yield put(setMessagesRemove({ value: false }));
    } catch (error) {
        yield deleteItemsError({ error, groupId, ids, itemIds, size, isMsgRemove: true });
        yield put(setMessagesRemove({ value: false }));
    }
}

function* removeLettersSuccess(action: {
    ids: string[];
    itemIds: string[];
    groupId: UserQuotaGroupId;
    withOutMessagesRemove: boolean;
    withSnackBar: boolean;
    size: number;
    count?: number;
    onSerialDeleteSuccess?: (needToDeleteFromTrashbinFromCallback?: boolean) => void;
    needToDeleteFromTrashbin?: boolean;
}) {
    const { ids, itemIds, groupId, withOutMessagesRemove, withSnackBar, size, onSerialDeleteSuccess, needToDeleteFromTrashbin, count } =
        action;
    const isMobile = yield select(QuotaLandingSelectors.isMobile);
    const isMeesagesRemove = yield select(isMeesagesRemoveNeeded);

    if (!isMeesagesRemove && !withOutMessagesRemove) {
        yield put(setSuccessMovedToTrash({ value: true }));
    }
    yield put(setMessagesRemove({ value: !withOutMessagesRemove }));
    yield put(setNeedsGroupReload({ value: true }));
    if (withOutMessagesRemove || onSerialDeleteSuccess) {
        yield put(
            reloadGroupAfterDelete({
                ids,
                itemIds,
                groupId,
                size,
                onSuccess: !needToDeleteFromTrashbin ? onSerialDeleteSuccess : undefined,
            })
        );
        if (isMobile) {
            yield put(setShowUploaderAction(false));
            yield put(startSelecting());
        }
        if (needToDeleteFromTrashbin) {
            onSerialDeleteSuccess?.(false);
        }
    }
    yield put(setIsItemsDeleteProcess({ value: false }));
    if (withSnackBar && !onSerialDeleteSuccess) {
        yield put(
            showSnackbarAction({
                id: 'quotaCleanerDeleteSuccess',
                type: SnackbarTypes.trashbin,
                text: getRemoveSnackbarText(ids.length, UserQuotaGroupType.Letter, groupId),
                closable: true,
                buttonText: isMobile ? 'Перейти' : 'Перейти в корзину',
                onButtonClick: () => {
                    opener(`https://${MAIL_HOST}/trash`);
                },
                buttonRight: isMobile,
            })
        );
    } else if (onSerialDeleteSuccess && withSnackBar && count) {
        yield put(hideSnackbarAction('quotaCleanerDeleteAllItems'));
        yield put(
            showSnackbarAction({
                id: 'quotaCleanerDeleteSuccess',
                type: SnackbarTypes.trashbin,
                text: getRemoveSnackbarText(count, UserQuotaGroupType.Letter, groupId),
                closable: true,
                buttonText: isMobile ? 'Перейти' : 'Перейти в корзину',
                onButtonClick: () => {
                    opener(`https://${MAIL_HOST}/trash`);
                },
                buttonRight: isMobile,
            })
        );
    }

    xray.send(`quota-cln-delete-scc${isMobile ? '-mob' : ''}`, { i: groupId });
}

function* loadItemView(action: ReturnType<typeof openUserQuotaItemView>) {
    const {
        payload: { id, groupId, isMobile },
    } = action;
    const item = groupId ? yield select(getItemByGroup, id, groupId) : yield select(getItemById, id);
    if (!item) {
        return;
    }

    if (item.isVirus) {
        return yield put(showVirusDlg({ items: [item] }));
    }

    openItemView(item, isMobile);
}

export function* watchUserQuotaCleaner() {
    yield takeEvery(loadUserQuotaCleanerList.toString(), loadQuotaCleanerList);
    // eslint-disable-next-line max-lines
    yield takeEvery(loadUserQuotaGroup.toString(), loadGroup);
    yield takeEvery(loadUserQuotaGroupMore.toString(), loadGroupMore);
    yield takeEvery(deleteUserQuotaCleanerItems.toString(), deleteItems);
    yield takeEvery(deleteUserQuotaCleanerAllItems.toString(), deleteAllItems);
    yield takeEvery(deleteBatchUserQuotaCleanerAllItems.toString(), deleteBatchInDeletingAllItems);
    yield takeEvery(openUserQuotaItemView.toString(), loadItemView);
    yield takeEvery(deleteUserQuotaCleanerItemsSuccess.toString(), deleteItemsSuccess);
    yield takeEvery(deleteLettersFromQuota.toString(), deleteLetters);
    yield takeEvery(deleteUserQuotaCleanerAllLetters.toString(), deleteAllLetters);
    yield takeEvery(deleteBatchUserQuotaCleanerAllLetters.toString(), deleteBatchInDeletingAllLetters);
    yield takeEvery(reloadGroupAfterDelete.toString(), onDeleteUpdate);
    yield takeEvery(loadUserQuotaGroupFromList.toString(), loadGroupFromList);
    yield takeEvery(getUserQuotaGroup.toString(), getGroup);
    yield takeEvery(goToCleanerAndShowQuotaCleaner.toString(), handleGoToCleanerAndShowQuotaCleaner);
}
