import { logger } from 'lib/logger';
import { handleSendAllDocumentsXray } from 'reactApp/modules/allDocuments/allDocuments.saga';
import { AllDocumentsXrayTypes } from 'reactApp/modules/allDocuments/allDocuments.types';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getIntegrationClient } from 'reactApp/modules/integration/integration.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { clearUploadedQueue } from 'reactApp/modules/uploading/uploading.module';
import { filesPlural } from 'reactApp/utils/pluralHelpers';
import { cancel, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { resetProgress, setNotAllowedExtensionsAction, uploaderTotalProgress } from './upload.module';

function* allDocumentsGa(count: number, xrayType: AllDocumentsXrayTypes) {
    for (let i = 0; i < count; i++) {
        yield handleSendAllDocumentsXray({ action: 'upload_file', xrayType });
    }
}

function* handleUploaderTotalProgress(action) {
    try {
        const { total, finished, failed, canceled } = action.payload;

        if (total > 0 && total !== finished) {
            return;
        }

        const isPhone = yield select(EnvironmentSelectors.isPhone);
        const storage = yield select(getCurrentStorage);

        const { isTutoria } = yield select(getIntegrationClient);
        const { isIntegration, isInlineIntegration } = getStorage(storage);

        const isTutoriaIntegration = (isIntegration && isTutoria) || isInlineIntegration;

        const failedCount = failed + canceled;
        const successCount = total - failedCount;

        if (failedCount > 0) {
            if (successCount > 0) {
                if (!isTutoriaIntegration) {
                    yield put(
                        showSnackbarAction({
                            type: SnackbarTypes.failure,
                            id: 'file-upload-err',
                            text: `Успешно загружено ${successCount} ${filesPlural(successCount)} из ${total}`,
                            closable: true,
                        })
                    );
                }

                yield allDocumentsGa(successCount, AllDocumentsXrayTypes.onSuccess);
            }
            yield allDocumentsGa(failedCount, AllDocumentsXrayTypes.onError);
        } else {
            if (!isTutoriaIntegration) {
                yield put(
                    showSnackbarAction({
                        type: SnackbarTypes.success,
                        id: 'file-upload',
                        title: isPhone ? 'Файлы загружены' : undefined,
                        text: isPhone ? 'Проверяются антивирусом' : 'Загрузка успешно завершена',
                        closable: true,
                    })
                );
            }
            yield allDocumentsGa(successCount, AllDocumentsXrayTypes.onSuccess);
        }

        yield delay(3000);

        if (storage === EStorageType.start) {
            yield put(clearUploadedQueue);
        }

        yield put(resetProgress());
    } catch (error) {
        logger.error(error);
        yield cancel();
    }
}

function* handleNotAllowedExtensions() {
    const currentStorage = yield select(getCurrentStorage);

    let item;

    if (currentStorage === EStorageType.documents) {
        item = {
            id: 'document-upload',
            closable: true,
            text: 'В личные документы можно загружать только изображения и PDF файлы',
            type: SnackbarTypes.failure,
        };
    }

    if (!item) {
        return;
    }

    yield put(showSnackbarAction(item));
}

export function* watchUploaderRoot() {
    yield takeLatest(uploaderTotalProgress.toString(), handleUploaderTotalProgress);
    yield takeEvery(setNotAllowedExtensionsAction.toString(), handleNotAllowedExtensions);
}
