import config from 'Cloud/config';
import React, { type ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { seriallyClearBinFeature } from 'reactApp/appHelpers/featuresHelpers/features/seriallyClearBin';
import {
    isMobileTrashbinSelectiveActive,
    isTrashbinSelectiveActive,
} from 'reactApp/appHelpers/featuresHelpers/features/tashbinSelectiveDeletion';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { groupedIds } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { binClearRequest, deleteFilesFromBinRequest, seriallyBinClearRequest } from 'reactApp/modules/trashbin/trashbin.module';
import { getOpenedBin } from 'reactApp/modules/trashbin/trashbin.selectors';
import type { RootState } from 'reactApp/store';
import { createGaSender } from 'reactApp/utils/ga';
import { isFile } from 'reactApp/utils/helpers';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { filesPlural, foldersPluralAccusative, hePlural, shePlural } from 'reactApp/utils/pluralHelpers';

import type { IProps } from './EmptyTrashBin.types';

const isBizUser = config.get('BIZ_USER');

const sendGa = createGaSender(`trashbin-empty${isBizUser ? '_biz' : ''}`);

export const EmptyTrashBin = React.memo(function EmptyTrashBin(props: IProps): ReactElement {
    const [loading, setLoading] = useState<boolean>(false);
    const bin = useSelector(getOpenedBin);

    const selected = useSelector(SelectionsSelectors.getSelectedIdxs);

    const items = useSelector((state: RootState) => groupedIds(state, EStorageType.trashbin)) as string[];

    const dispatch = useDispatch();

    const onConfirm = useCallback(() => {
        setLoading(true);

        if ((isTrashbinSelectiveActive || isMobileTrashbinSelectiveActive) && selected.length && selected.length !== items.length) {
            dispatch(deleteFilesFromBinRequest({ selected }));
        } else if (items && seriallyClearBinFeature) {
            dispatch(seriallyBinClearRequest());
        } else {
            dispatch(binClearRequest({ id: bin?.id || '', tree: bin?.tree || '' }));
        }

        sendGa('confirm');
        props.onClose();
    }, [bin?.id, bin?.tree, dispatch, props]);

    const onClose = useCallback(() => {
        sendGa('close');
        props.onClose();
    }, [props]);

    const renderHeader = useCallback(
        function () {
            if ((isTrashbinSelectiveActive || isMobileTrashbinSelectiveActive) && selected.length && selected.length !== items.length) {
                const files = selected.filter((item) => isFile(item));
                const foldersCount = selected.length - files.length;

                let res = 'Удалить ';

                if (files.length > 0) {
                    res += `${files.length} ${filesPlural(files.length)}`;
                }

                if (files.length > 0 && foldersCount > 0) {
                    res += ' и ';
                }

                if (foldersCount > 0) {
                    res += `${foldersCount} ${foldersPluralAccusative(foldersCount)}`;
                }

                res += ' из корзины?';

                return <>{res}</>;
            }

            return <>Удалить все файлы в корзине?</>;
        },
        [selected]
    );

    const renderContent = useCallback(() => {
        if ((isTrashbinSelectiveActive || isMobileTrashbinSelectiveActive) && selected.length && selected.length !== items.length) {
            const files = selected.filter((item) => isFile(item));
            const foldersCount = selected.length - files.length;

            let res = '';
            if (files.length > 0 && foldersCount > 0) {
                res = 'их';
            } else if (files.length > 0) {
                res = hePlural(selected.length);
            } else if (foldersCount > 0) {
                res = shePlural(selected.length);
            }

            return <>После этого {res} нельзя будет восстановить</>;
        }

        return <div>После этого их нельзя будет восстановить</div>;
    }, []);

    useEffect(() => {
        sendGa('show');
        const isMobile = EnvironmentSelectors.isMobile();
        if ((isTrashbinSelectiveActive || isMobileTrashbinSelectiveActive) && selected.length && selected.length !== items.length) {
            sendPaymentGa({
                eventCategory: ECategoryGa.basket,
                action: 'clean',
                count_files: selected.length,
            });
        } else if ((isMobile && isMobileTrashbinSelectiveActive) || (!isMobile && isTrashbinSelectiveActive)) {
            sendPaymentGa({
                eventCategory: ECategoryGa.basket,
                action: 'clean-all',
                count_files: selected.length === items.length ? bin?.count.all : selected.length || items.length,
            });
        } else {
            sendPaymentGa({
                eventCategory: ECategoryGa.basket,
                action: 'clean-all',
                selected_files: selected.length || items.length,
                count_files: items.length,
            });
        }
    }, []);

    return (
        <BaseConfirmDialog
            dataQAId="empty-trashbin-dialog"
            renderHeader={renderHeader}
            isNegative
            actionButtonText="Удалить навсегда"
            cancelButtonText="Не удалять"
            buttons={[EButtonType.action, EButtonType.cancel]}
            onClose={onClose}
            onAction={onConfirm}
            renderContent={renderContent}
            loading={loading}
            closeOnDimmerClick
        />
    );
});
