import classNames from 'clsx';
import { path } from 'ramda';
import React, { type ReactElement, memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { isDiscountABEnabled, isDiscountOutflowParticipantProductId } from 'reactApp/appHelpers/featuresHelpers/features/discountInOutflow';
import { abPauseSubscription } from 'reactApp/appHelpers/featuresHelpers/features/pauseSubscription';
import { BuySubscriptionActions } from 'reactApp/modules/buySubscription/buySubscription.module';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { PARTNER_TITLE } from 'reactApp/modules/subscriptions/subscriptions.data';
import { getPausedSubscriptionStorageKey, isPausedSubscription } from 'reactApp/modules/subscriptions/subscriptions.helpers';
import type { Partner } from 'reactApp/modules/subscriptions/subscriptions.types';
import { UserStorageSelectors } from 'reactApp/modules/user/userStorage';
import { SubscriptionFooter } from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionFooter/SubscriptionFooter';
import { SubscriptionInfoBlock } from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionInfo/SubscriptionInfoBlock/SubscriptionInfoBlock';
import { useInfo } from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionInfo/useInfo';
import {
    ESubscriptionModalAction,
    useSendSubscriptionModalAnalytics,
} from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal.analytics';
import { useContent } from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/useContent';
import { useSubscription } from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/useSubscription';
import type { RootState } from 'reactApp/store';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { isYear2Period } from 'reactApp/utils/Period';

import styles from './SubscriptionModal.css';

interface IProps {
    onClose: () => void;
    subscriptionId: string;
}

export const SubscriptionModal = memo(({ subscriptionId, onClose }: IProps): ReactElement | null => {
    const dispatch = useDispatch();
    const subscription = useSubscription({ id: subscriptionId });
    const product = useSelector((state: RootState) => ProductsSelectors.getProductById(state, subscription?.['productId']));
    const pausedSubscription: boolean =
        useSelector((state: RootState) => UserStorageSelectors.get(state, getPausedSubscriptionStorageKey(subscription.id))) &&
        isPausedSubscription(abPauseSubscription, subscription.renewAt);
    const partner = path<Partner>(['partner'], subscription);

    const sendAnalytics = useSendSubscriptionModalAnalytics(subscription);

    const { header, warning } = useContent({ subscription, product, partner });
    const subscriptionsInfos = useInfo({ subscription, product, partner, sendAnalytics, paused: pausedSubscription });

    useEffect(() => {
        emitAnalyticEvent(AnalyticEventNames.CANCEL_SUB_TARIFF);
    }, []);

    const onPartnerClick = useCallback(() => {
        sendAnalytics(ESubscriptionModalAction.partnerClick);
    }, [sendAnalytics]);

    const { status, renewable, autorenewal } = subscription;
    const isRenewable = product && status === 'ACTIVE' && renewable && !isYear2Period(product.period);

    const footer = useMemo(() => {
        if (warning && partner && subscription?.accountLink) {
            return (
                <div className={styles.warning}>
                    {warning}&nbsp;
                    <a target="_blank" className={styles.link} href={subscription.accountLink} rel="noreferrer" onClick={onPartnerClick}>
                        {PARTNER_TITLE[partner]}
                    </a>
                </div>
            );
        }

        if (warning) {
            return <div className={styles.warning}>{warning}</div>;
        }

        return product && <SubscriptionFooter subscription={subscription} product={product} sendAnalytics={sendAnalytics} />;
    }, [warning, partner, product, subscription, sendAnalytics, onPartnerClick]);

    useEffect(() => {
        if (subscription) {
            sendAnalytics(ESubscriptionModalAction.open);
        }
        if (abPauseSubscription && (isRenewable || autorenewal)) {
            dispatch(BuySubscriptionActions.checkPauseSubscription({ subscriptionId }));
        }
        /* tempexp_17581-start */
        if (isDiscountABEnabled && (isRenewable || autorenewal) && isDiscountOutflowParticipantProductId(product?.id)) {
            dispatch(
                BuySubscriptionActions.checkDiscountInOutflow({
                    subscriptionId,
                })
            );
        }
        /* tempexp_17581-end */
    }, [dispatch, sendAnalytics, subscription, subscription.id, subscriptionId, isRenewable]);

    if (!subscription) {
        return null;
    }

    return (
        <Dialog
            open
            header={<div className={styles.header}>{header}</div>}
            id="subscription-modal"
            onCancel={onClose}
            className={styles.root}
            footer={footer}
            size="tiny"
        >
            <Content isModal wrapClass={classNames(styles.content, { [styles.withoutFooter]: !footer })}>
                {subscriptionsInfos.map((info) => (
                    <SubscriptionInfoBlock title={info.title} key={info.id} id={info.id}>
                        {info.content}
                    </SubscriptionInfoBlock>
                ))}
            </Content>
        </Dialog>
    );
});

SubscriptionModal.displayName = 'SubscriptionModal';
