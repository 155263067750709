import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { ANONYM_USER, IS_B2B_BIZ_USER, IS_BIZ_USER, IS_MOBILE_BROWSER, IS_SOCIAL_USER } from 'reactApp/appHelpers/configHelpers';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

type TariffPoll = {
    variant: 'a' | 'b' | 'c' | 'd';
    delay: number;
};

const tariffPollValue: TariffPoll | undefined = getFeature('tariff-poll') || {};
const userCondition = !IS_MOBILE_BROWSER && !IS_B2B_BIZ_USER && !IS_BIZ_USER && !IS_SOCIAL_USER && !ANONYM_USER;

if (userCondition) {
    registerExperiment(
        'tariff-poll',
        tariffPollValue?.variant,
        [
            AnalyticEventNames.TARIFF_POLL_SHOW,
            AnalyticEventNames.TARIFF_POLL_SUCCESS_SHOW,
            AnalyticEventNames.TRIAL_TARIFF_BUY,
            AnalyticEventNames.TRIAL_TARIFF_SHOW,
        ],
        {
            sendXrayArgs: {
                [AnalyticEventNames.TRIAL_TARIFF_BUY]: ({ paySource }, { eventName }) => ({
                    eventName: paySource?.startsWith('trial_survey_landing') ? 'tariff_poll_buy' : eventName,
                }),
                [AnalyticEventNames.TRIAL_TARIFF_SHOW]: ({ paySource }, { eventName }) => ({
                    eventName: paySource?.startsWith('trial_survey_landing') ? 'tariff_poll_checkout' : eventName,
                }),
            },
        }
    );
}

export const tariffPollDelay = userCondition && tariffPollValue?.delay ? tariffPollValue.delay * 1000 : undefined;
