import { Icon24DownloadCloudOutline, Icon24DownloadOutline } from '@vkontakte/icons';
import React, { type ReactElement } from 'react';
import { ANONYM_USER, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { hasAuthorizationInfo } from 'reactApp/appHelpers/featuresHelpers/features/superAppAuthorizationInfo';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { EActions } from 'reactApp/modules/storage/storage.types';
import styles from 'reactApp/ui/Mobile/ActionsBar/ActionsBar.css';
import {
    Icon24AlbumShareOutline,
    Icon24ArrowReturnLeftOutline,
    Icon24CopyOutline,
    Icon24DeleteOutline,
    Icon24MoveOutline,
    Icon24ShareOutline,
} from 'reactApp/ui/touchIcons';
import { PostMessageTypes, superAppPostMessage } from 'reactApp/utils/superAppWebViewHelpers';
import { EQueryParams } from 'server/helpers/getRequestParams';

export interface IBarItem {
    id: EActions;
    text: string;
    icon: ReactElement;
    disabled: boolean;
    onClick: () => void;
}

const fromDeeplink = getQueryParams()[EQueryParams.fromDeeplink];
const isSuperAppWebview = IS_WEBVIEW && fromDeeplink;

const disableButton: boolean = isSuperAppWebview && ANONYM_USER && hasAuthorizationInfo(ANONYM_USER);

export const actionItems: Partial<Record<EActions, (disabled?: boolean, weblink?: string) => IBarItem>> = {
    [EActions.remove]: (disabled = false) => ({
        id: EActions.remove,
        text: 'Удалить',
        icon: <Icon24DeleteOutline className={styles.icon} />,
        disabled,
        onClick: toolbarActions.remove,
    }),
    [EActions.clone]: (disabled = false) => ({
        id: EActions.clone,
        text: 'Сохранить в облако',
        icon: <Icon24DownloadCloudOutline className={styles.icon} />,
        disabled,
        onClick: disableButton
            ? () => superAppPostMessage(PostMessageTypes.authorization)
            : () => toolbarActions.clone({ source: 'action-bar' }),
    }),
    [EActions.download]: (disabled = false) => ({
        id: EActions.download,
        text: 'Скачать',
        icon: <Icon24DownloadOutline className={styles.icon} />,
        disabled,
        onClick: toolbarActions.downloadMobileItem,
    }),
    [EActions.publish]: (disabled = false) => ({
        id: EActions.publish,
        text: 'Поделиться',
        icon: <Icon24ShareOutline className={styles.icon} />,
        disabled,
        onClick: () => toolbarActions.publish('toolbar'),
    }),
    [EActions.publishAlbum]: (disabled = false) => ({
        text: 'Поделиться',
        id: EActions.publishAlbum,
        disabled,
        icon: <Icon24AlbumShareOutline className={styles.icon} />,
        onClick: toolbarActions.createAndPublishAlbum,
    }),
    [EActions.move]: (disabled = false) => ({
        id: EActions.move,
        text: 'Переместить',
        icon: <Icon24MoveOutline className={styles.icon} />,
        disabled,
        onClick: toolbarActions.move,
    }),
    [EActions.copy]: (disabled = false) => ({
        id: EActions.copy,
        text: 'Копировать',
        icon: <Icon24CopyOutline className={styles.icon} />,
        disabled,
        onClick: toolbarActions.copy,
    }),
    [EActions.clear]: (disabled = false) => ({
        id: EActions.clear,
        text: 'Удалить навсегда',
        icon: <Icon24DeleteOutline className={styles.icon} />,
        disabled,
        onClick: toolbarActions.clear,
    }),
    [EActions.restore]: (disabled = false) => ({
        id: EActions.restore,
        text: 'Восстановить',
        icon: <Icon24ArrowReturnLeftOutline className={styles.icon} />,
        disabled,
        onClick: () => toolbarActions.restore(),
    }),
};
