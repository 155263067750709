import type { PayloadAction } from '@reduxjs/toolkit';
import { logger } from 'lib/logger';
import { xray } from 'lib/xray';
import { FamilyAcceptInviteAPICall } from 'reactApp/api/family/FamilyAcceptInviteAPICall';
import { GetFamilyInviteLinkAPICall } from 'reactApp/api/family/GetFamilyInviteLinkAPICall';
import { ProlongFamilyLinkAPICall } from 'reactApp/api/family/ProlongFamilyLinkAPICall';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import {
    type initFamilyInviteModal,
    createFamily,
    familyInitDataSuccess,
    familyUpdate,
    loadingInviteLink,
    loadInviteLinkFailure,
    updateFamilyInviteLink,
} from 'reactApp/modules/family/family.actions';
import { buildInviteLink } from 'reactApp/modules/family/family.helpers';
import {
    getFamilyId,
    getFamilyInviteLink,
    isUserFamilyOwner,
    isUserWithFamily,
    userCanJoinFamily,
} from 'reactApp/modules/family/family.selectors';
import { onboardingOverQuota } from 'reactApp/modules/family/sagas/onboarding.saga';
import { loggerSaga } from 'reactApp/modules/logger/logger.saga';
import { historyPush } from 'reactApp/modules/router/router.module';
import { isEmailAbsent } from 'reactApp/modules/socialUser/socialUser.selectors';
import { getIdByStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { waitForUserUpdate } from 'reactApp/modules/user/waitForUserUpdate';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { openFamilyAcceptInviteModal } from 'reactApp/sections/FamilyPage/FamilyAcceptInviteDialog/FamilyAcceptInviteDialog.helpers';
import { openFamilyErrorModal } from 'reactApp/sections/FamilyPage/FamilyErrorDialog/FamilyErrorDialog.helpers';
import { FamilyAcceptError, FamilyError } from 'reactApp/sections/FamilyPage/FamilyErrorDialog/FamilyErrorDialog.types';
import { openFamilyOverquotaModal } from 'reactApp/sections/FamilyPage/FamilySettings/FamilySettings.helpers';
import { renderConfirmEmailMobile } from 'reactApp/ui/ConfirmEmail/ConfirmEmailMobile/ConfirmEmailMobile.helpers';
import { openFamilyInviteModal } from 'reactApp/ui/Family/FamilyInviteModal/FamilyInviteModal.helpers';
import { channel } from 'redux-saga';
import { call, cancel, put, select, take } from 'typed-redux-saga';

const getFamilyInviteLinkAPICall = ({ link }) => new GetFamilyInviteLinkAPICall().makeRequest({ link });
const familyAcceptInviteAPICall = ({ link }) => new FamilyAcceptInviteAPICall().makeRequest({ link });
const prolongFamilyLinkAPICall = ({ link, family }) => new ProlongFamilyLinkAPICall().makeRequest({ link, family });

export function* handleOpenInviteModal(action?: ReturnType<typeof initFamilyInviteModal>) {
    const { isRebrandingQuotaLanding = false } = action?.payload || {};

    const { link: currentLink, expire_at = null } = yield* select(getFamilyInviteLink);
    const family = yield* select(getFamilyId);
    let inviteLink = currentLink;

    if (family && expire_at && (expire_at === -1 || expire_at < Math.floor(Date.now() / 1000))) {
        try {
            yield put(loadingInviteLink());
            const { data } = yield* call(prolongFamilyLinkAPICall, { link: currentLink, family });
            yield* put(updateFamilyInviteLink({ link: data, family }));
            inviteLink = data.link;
            xray.send('family_prolong_success');
        } catch (error) {
            yield put(loadInviteLinkFailure());
            xray.send('family_prolong_failure');
            logger.error(error);
        }
    }

    yield openFamilyInviteModal({
        link: buildInviteLink(inviteLink),
        isRebrandingQuotaLanding,
    });
}

export function* handleFamilyInviteRequest(action: PayloadAction<string>) {
    yield take(familyInitDataSuccess);

    const link = action.payload;
    const canJoinFamily = yield* select(userCanJoinFamily);
    const alredyInFamily = yield* select(isUserWithFamily);
    const isNewbie = yield* select(UserSelectors.isNewbie);
    const isMobile = EnvironmentSelectors.isMobile();

    if (isNewbie) {
        xray.send('family_invite_error_newbie');
        yield cancel();
        return;
    }

    let errorType;
    let storage: EStorageType | undefined;

    if (alredyInFamily) {
        errorType = FamilyError.alredyInFamily;
    } else if (!canJoinFamily) {
        errorType = FamilyError.wrongAccount;
    }

    if (!errorType) {
        try {
            const { data } = yield* call(getFamilyInviteLinkAPICall, { link });
            const { available, expire_at, owner, quota } = data;

            if (available === 0) {
                errorType = FamilyError.linkLimit;
            } else if (expire_at < Math.floor(Date.now() / 1000)) {
                errorType = FamilyError.linkExpired;
            }

            xray.send('family_link_load_success');

            if (!errorType) {
                yield openFamilyAcceptInviteModal({ link, owner, ownerQuota: quota });
                yield cancel();
            }
        } catch (error: any) {
            const { status } = error;
            if (status === 404) {
                errorType = FamilyError.ownerSubscriptionOrLinkExpired;
            } else {
                errorType = FamilyError.linkError;
            }

            xray.send(`family_link_load_failure_${errorType}`);
            logger.error(error);
        }
    }

    if (errorType !== FamilyError.alredyInFamily) {
        yield put(historyPush({ id: getIdByStorage(EStorageType.home) }));
        storage = EStorageType.home;
    }
    xray.send(`family_invite_error_${errorType}`);
    yield openFamilyErrorModal({ errorType, storage, isMobile });
}

export function* handleFamilyInviteAccept(action: PayloadAction<string>) {
    const link = action.payload;

    const isMobile = EnvironmentSelectors.isMobile();
    const isSocial = yield* select(UserSelectors.isSocialUser);
    const isSocialUserWithoutEmail = yield* select(isEmailAbsent);

    if (isSocial && isMobile && isSocialUserWithoutEmail) {
        const socialChannel = channel();

        yield* call(renderConfirmEmailMobile, {
            title: 'Почта для связи',
            text: 'Будем писать только по делу: пришлём чеки об оплате, предупредим, если место заканчивается',
            showLA: false,
            buttonText: 'Добавить',
            placeholder: 'Почтовый адрес',
            closable: true,
            onSuccess: () => socialChannel.put(true),
        });

        yield take(socialChannel);
    }

    try {
        const { data } = yield* call(familyAcceptInviteAPICall, { link });
        xray.send('family_invite_accept_success');
        yield put(familyUpdate(data));
        yield put(historyPush({ id: getIdByStorage(EStorageType.family) }));
        yield waitForUserUpdate();
    } catch (error: any) {
        const { status, response } = error;
        let errorType = FamilyError.linkError;

        if (status === 406) {
            switch (response?.error) {
                case FamilyAcceptError.tooEarly:
                    errorType = FamilyError.linkRemoved;
                    break;
                case FamilyAcceptError.overquota:
                    errorType = FamilyError.familyOverquota;
                    break;
                case FamilyAcceptError.tooManyPerYear:
                    errorType = FamilyError.familyMembersLimit;
                    break;
                default:
                    break;
            }
        }

        xray.send(`family_invite_accept_failure_${errorType}`);
        yield put(historyPush({ id: getIdByStorage(EStorageType.home) }));
        yield openFamilyErrorModal({ errorType, storage: EStorageType.home, isMobile });
        loggerSaga({ error });
    }
}

export function* handleInviteToFamilyOrCreate() {
    const isOverquota = yield* select(UserQuotaSelectors.isOverquota);
    const userQuota = yield* select(UserQuotaSelectors.getSpace);
    const isFamilyOwner = yield* select(isUserFamilyOwner);

    if (isOverquota) {
        const overquotaChanel = channel();

        openFamilyOverquotaModal({
            quota: userQuota,
            onClose: () => overquotaChanel.close(),
            onSuccess: () => overquotaChanel.put(true),
        });

        yield take(overquotaChanel);

        yield onboardingOverQuota();
        yield cancel();
    }

    if (isFamilyOwner) {
        yield handleOpenInviteModal();
        yield cancel();
    }

    yield put(createFamily());
}
