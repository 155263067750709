import { uploadNewFileApi } from 'reactApp/appHelpers/featuresHelpers/features/uploadNewFileApi';

export const hasType = (dataTransfer: DataTransfer, type: 'Files') => {
    let hasType = false;

    // непонятная ошибка стреляет под Edge 18
    try {
        const types = dataTransfer.types;

        if (types) {
            let i = types.length;

            while (i--) {
                if (types[i] === type) {
                    hasType = true;

                    break;
                }
            }
        }
    } catch (_) {}

    return hasType;
};

export const hasFileType = (dataTransfer: DataTransfer) => {
    return hasType(dataTransfer, 'Files');
};

const getEntry = (item: DataTransferItem): FileSystemEntry | null => {
    if ('getAsEntry' in item) {
        // @ts-ignore
        return item.getAsEntry();
    } else if (item.webkitGetAsEntry) {
        return item.webkitGetAsEntry();
    }

    return null;
};

export const getEntries = async (dataTransfer: DataTransfer) => {
    // @ts-ignore
    const items = dataTransfer.items;
    const entries: FileSystemEntry[] = [];
    const handles: Promise<FileSystemHandle | null>[] = [];

    if (!items?.length) {
        return entries;
    }

    for (let i = 0, count = items.length; i < count; i++) {
        const item = items[i];

        if (!item || item.kind !== 'file') {
            continue;
        }

        if (uploadNewFileApi && 'getAsFileSystemHandle' in item) {
            const handle = item.getAsFileSystemHandle();
            handles.push(handle);
        } else {
            const entry = getEntry(item);

            if (entry && (entry.isFile || entry.isDirectory)) {
                entries.push(entry);
            }
        }
    }

    return [...entries, ...(await Promise.all(handles))].filter(Boolean) as (FileSystemEntry | FileSystemHandle)[];
};

export const getFiles = (dataTransfer: DataTransfer) => {
    const files = dataTransfer.files;
    const result: File[] = [];

    if (files) {
        for (let i = 0, count = files.length; i < count; i++) {
            const file = files[i];

            if (file) {
                result.push(file);
            }
        }
    }

    return result;
};
