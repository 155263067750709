import classNames from 'clsx';
import React, { type VFC } from 'react';
import { blackFriday2024 } from 'reactApp/appHelpers/featuresHelpers/features/blackFriday2024';
import { compactFirstScroll } from 'reactApp/appHelpers/featuresHelpers/features/compactFirstScroll';
import { tariffCardPersonal } from 'reactApp/appHelpers/featuresHelpers/features/tariifCardPersonal';
import { Switch as SwitchUI } from 'reactApp/ui/Switch/Switch';
import { SwitchSlideThemes } from 'reactApp/ui/Switch/Switch.types';

import styles from './TariffSwitch.css';
import type { ISwitchProps } from './TariffSwitch.types';

export const TariffSwitch: VFC<ISwitchProps> = ({
    dataQa,
    options,
    name = '',
    checked = false,
    onClick,
    sizeSmall,
    /* tempexp_17127-start */
    sizeExtraSmall,
    gray,
    /* tempexp_17127-end */
    dark,
    isRebrandingQuotaLanding = false,
    isRebranding = false,
    /* tempexp_17509-next-line */
    isFromLitres = false,
    fromLanding = false,
}) => {
    const [firstOption, secondOption] = options;

    let themeSlider;

    /* tempexp_17509-next-line */
    if (isFromLitres) {
        themeSlider = SwitchSlideThemes.litres;
    }

    /* tempexp_18084-next-line */
    if (fromLanding && (blackFriday2024 || (tariffCardPersonal && tariffCardPersonal !== 'a'))) {
        themeSlider = SwitchSlideThemes.black;
    }

    return (
        <div
            className={classNames(styles.switch, {
                [styles.switch_sizeSmall]: sizeSmall,
                [styles.switch_sizeExtraSmall]: sizeExtraSmall,
                [styles.switch_dark]: dark,
                [styles.switch_rebrandingQuotaLanding]: isRebrandingQuotaLanding,
                [styles.switch_compact]: compactFirstScroll,
            })}
            onClick={onClick}
            data-qa={dataQa}
        >
            <div
                className={classNames(styles.switchLabel, {
                    [styles.switchLabel_active]: !checked,
                    [styles.switchLabel_rebranding]: isRebranding,
                })}
                data-qa={dataQa && 'firstOption'}
                data-qa-value={dataQa && firstOption.value}
                data-qa-checked={dataQa && !checked}
            >
                <div data-qa={dataQa && 'text'}>{firstOption.text}</div>
                {firstOption.note && (
                    <div
                        data-qa={dataQa && 'note'}
                        className={classNames(styles.switchLabelNote, { [styles.switchLabelNote_rebranding]: isRebranding })}
                    >
                        {firstOption.note}
                    </div>
                )}
            </div>
            <div className={classNames(styles.switchBlock)}>
                <SwitchUI
                    dark={dark}
                    gray={gray}
                    name={name}
                    checked={checked}
                    isRebrandingQuotaLanding={isRebrandingQuotaLanding}
                    theme="big"
                    isRebranding={isRebranding}
                    themeSlider={themeSlider}
                    fromLanding={fromLanding}
                />
            </div>
            <div
                className={classNames(styles.switchLabel, styles.switchLabel_right, {
                    [styles.switchLabel_active]: checked,
                    [styles.switchLabel_rebranding]: isRebranding,
                })}
                data-qa={dataQa && 'secondOption'}
                data-qa-value={dataQa && secondOption.value}
                data-qa-checked={dataQa && checked}
            >
                <div data-qa={dataQa && 'text'}>{secondOption.text}</div>
                {secondOption.note && (
                    <div
                        data-qa={dataQa && 'note'}
                        className={classNames(styles.switchLabelNote, { [styles.switchLabelNote_rebranding]: isRebranding })}
                    >
                        {secondOption.note}
                    </div>
                )}
            </div>
        </div>
    );
};
