import { Spacing, Text, Title } from '@vkontakte/vkui';
import { ReactComponent as DropImage } from 'img/drop.svg';
import React, { memo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { inputType } from 'reactApp/appHelpers/featuresHelpers/features/uploadNewFileApi';
import { isCurrentDarkTheme } from 'reactApp/modules/theme/theme.selectors';
import { useUploadInputHandlers } from 'reactApp/modules/uploading/hooks/useUploadInputHandlers';
import { CloudArrowUpIcon } from 'reactApp/ui/VKUIIcons';

import styles from './WelcomeNewPromo.css';

export const UploaderBlock = memo(() => {
    const inputRef = useRef<HTMLInputElement>(null);
    const isDarkTheme = useSelector(isCurrentDarkTheme);

    useUploadInputHandlers({ input: inputRef });

    return (
        <div className={styles.block} data-qa-id="uploader">
            <input type={inputType} multiple className={styles.input} ref={inputRef} />
            <div className={styles.icon}>
                <CloudArrowUpIcon />
            </div>
            <Spacing size={24} style={{ pointerEvents: 'none' }} />
            <Title className={styles.block_title} level="3">
                Загрузить файлы
            </Title>
            <Spacing size={8} style={{ pointerEvents: 'none' }} />
            <Text className={styles.block_text}>Нажмите или перетащите файлы для загрузки в Облако</Text>
            <Spacing size={24} style={{ pointerEvents: 'none' }} />
            <div className={styles.upload}>
                <DropImage
                    className={styles.drop}
                    fill={isRebranding && isDarkTheme ? 'none' : '#fff'}
                    stroke={isRebranding && isDarkTheme ? '#616161' : '#000'}
                    stroke-opacity={isRebranding && isDarkTheme ? '1' : '0.2'}
                />
                <div className={styles.upload_text}>
                    <span className={styles.blue}>Нажмите</span>{' '}
                    <span>
                        или перенесите
                        <br />
                        файлы для загрузки
                    </span>
                </div>
            </div>
        </div>
    );
});

UploaderBlock.displayName = 'UploaderBlock';
