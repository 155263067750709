import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { APP_LOGO, APP_TITLE, APP_WHITE_LOGO, IS_B2B_BIZ_USER, IS_CHOSEN_PUBLIC_FILE, LOGO_SRC } from 'reactApp/appHelpers/configHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getCurrentPublicItem } from 'reactApp/modules/public/public.selectors';
import type { PublicFile } from 'reactApp/modules/public/public.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { useHref } from 'reactApp/ui/Header/Logo/Logo.hooks';
import { ELogoSize, LogoComponent } from 'reactApp/ui/Header/Logo/LogoComponent';

export const Logo = memo(({ className, white = false }: { className?: string; white?: boolean }) => {
    const isPhone = useSelector(EnvironmentSelectors.isPhone);
    const size = IS_CHOSEN_PUBLIC_FILE ? ELogoSize.s : ELogoSize.m;
    const storage = useSelector(getCurrentStorage);
    const { isAllDocuments, isPublic, isSubscription } = getStorage(storage);
    const useRouterLink = !(isPhone && isPublic);
    const isBiz = IS_B2B_BIZ_USER;
    const bizLogo = LOGO_SRC || (isSubscription || white || isPhone ? APP_WHITE_LOGO : APP_LOGO);
    const href = useHref(storage, useRouterLink, isBiz);
    const isWebview = useSelector(EnvironmentSelectors.isWebview);
    const publicItem = useSelector(getCurrentPublicItem) as PublicFile;

    return (
        <LogoComponent
            className={className}
            isPhone={isPhone}
            white={isSubscription || white}
            bizLogo={bizLogo}
            bizBrandName={APP_TITLE}
            isBiz={isBiz}
            size={size}
            useRouterLink={useRouterLink}
            isDocuments={isAllDocuments}
            href={href}
            isWebView={isWebview}
            isPublic={isPublic}
            publicItem={publicItem}
        />
    );
});

Logo.displayName = 'Logo';
