import { Text } from '@vkontakte/vkui';
import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import {
    PublicPasswordEventCategory,
    sendPublicPasswordAnalytics,
} from 'reactApp/components/SharingWindow/SharingNew/Weblink/PublicPassword/PublicPassword.analytics';
import { deletePublicPin } from 'reactApp/modules/public/public.actions';

import styles from './DeletePublicPasswordConfirmModal.css';
import type { DeletePublicPasswordConfirmModalProps } from './DeletePublicPasswordConfirmModal.types';

const BUTTONS = [EButtonType.action, EButtonType.cancel];

export const DeletePublicPasswordConfirmModal = memo<DeletePublicPasswordConfirmModalProps>(
    ({ onDelete, onRestore, onClose, publicId }) => {
        const dispatch = useDispatch();

        useEffect(() => {
            sendPublicPasswordAnalytics({ eventCategory: PublicPasswordEventCategory.deletePassword, action: 'show' });
        }, []);

        const renderHeader = useCallback(() => <>Удалить пароль?</>, []);
        const renderContent = useCallback(() => {
            return <Text className={styles.text}>Файлы будут видны всем, у кого есть ссылка</Text>;
        }, []);

        const handleConfirmDeletion = useCallback(() => {
            dispatch(
                deletePublicPin({
                    public_id: publicId,
                    onSuccess: () => {
                        onDelete();
                        onClose?.();
                    },
                    onRestore: () => {
                        onRestore();
                    },
                })
            );
            sendPublicPasswordAnalytics({ eventCategory: PublicPasswordEventCategory.deletePassword, action: 'delete' });
        }, [onDelete, dispatch, onClose, onRestore, publicId]);

        const handleClose = useCallback(() => {
            onClose?.();
            sendPublicPasswordAnalytics({ eventCategory: PublicPasswordEventCategory.deletePassword, action: 'close' });
        }, [onClose]);

        return (
            <BaseConfirmDialog
                dataQAId="delete-public-password-confirm"
                renderHeader={renderHeader}
                renderContent={renderContent}
                buttons={BUTTONS}
                actionButtonText="Удалить"
                onAction={handleConfirmDeletion}
                cancelButtonText="Оставить"
                onClose={handleClose}
                isNegative
                buttonTheme="vk"
            />
        );
    }
);

DeletePublicPasswordConfirmModal.displayName = 'DeletePublicPasswordConfirmModal';
