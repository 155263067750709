import { DEFAULT_ACTION_NAME } from 'reactApp/constants';
import { createGaSender } from 'reactApp/utils/ga';

export const sendGa = createGaSender('tariffs');

export const isProTariff = (modificator) => modificator === 'pro';
export const isProProTariff = (modificator) => modificator === 'propro';
export const isDoubleTariff = (modificator) => modificator === 'double';
export const isMarketingPromo = (modificator) => modificator === DEFAULT_ACTION_NAME;
export const isOverquotaCard = (modificator) => modificator === 'overquota';
export const isMarketingPromoQuataLanding = (modificator) => modificator === 'quotaMarketingPromo';
