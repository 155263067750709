import { differenceInDays, differenceInMonths } from 'date-fns';
import { billingDataForPaidInfoEnabled } from 'reactApp/appHelpers/featuresHelpers/features/billingDataEndpoint';
import { BillingDataSelectors } from 'reactApp/modules/billingData/billingData.selectors';
import type { State } from 'reactApp/modules/paidInfo/paidInfo.types';
import type { RootState } from 'reactApp/store';
import type { LoadingState } from 'reactApp/types/commonStates';
import { createSelector } from 'reselect';

const getPaidInfoState = (state: RootState) => state.paidInfo as State;

const getLoadingState = createSelector(
    getPaidInfoState,
    (state: State): LoadingState => ({
        isLoading: state.isLoading,
        isLoaded: state.isLoaded,
    })
);

const isUserWithoutPayment = createSelector(
    getPaidInfoState,
    getLoadingState,
    (state: State, loading: LoadingState): boolean => loading.isLoaded && state.paidExpires === 0 && state.trialExpires === 0
);

const lastPaymentWasLessThan = createSelector(
    (_, periodData: { days?: number; months?: number }) => periodData,
    getPaidInfoState,
    isUserWithoutPayment,
    (periodData, paidInfo, isWithoutPayment) => {
        if (isWithoutPayment) {
            return false;
        }

        const { trialExpires, paidExpires } = paidInfo;

        const lastPayment = Math.max(trialExpires, paidExpires);

        const { days, months } = periodData;

        if (months && lastPayment) {
            const diff = differenceInMonths(Date.now(), lastPayment);

            return diff < months;
        }

        if (days && lastPayment) {
            const diff = differenceInDays(Date.now(), lastPayment);

            return diff < days;
        }

        return false;
    }
);

export const PaidInfoSelectors = {
    getLoadingState: billingDataForPaidInfoEnabled ? BillingDataSelectors.getBillingDataLoadingState : getLoadingState,
    isUserWithoutPayment: billingDataForPaidInfoEnabled ? BillingDataSelectors.isUserWithoutPayment : isUserWithoutPayment,
    lastPaymentWasLessThan: billingDataForPaidInfoEnabled ? BillingDataSelectors.lastPaymentWasLessThan : lastPaymentWasLessThan,
};
