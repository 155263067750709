import type { PayloadAction } from '@reduxjs/toolkit';
import { logger } from 'lib/logger';
import {
    DisableDocumentsRecognitionApiCall,
    DocumentImagesApiCall,
    DocumentsFileApiCall,
    DocumentsListApiCall,
    DocumentsRecognitionStatusApiCall,
    EnableDocumentsRecognitionApiCall,
    LinkToDocument,
    UnlinkDocument,
} from 'reactApp/api/DocumentApiCall';
import { enableNewPersonalDocuments } from 'reactApp/appHelpers/featuresHelpers/features/newPersonalDocuments';
import { addFilesSuccess } from 'reactApp/modules/modifying/modifying.actions';
import type { AddFileSuccessAction, AddItem } from 'reactApp/modules/modifying/modifying.types';
import {
    getCurrentDocument,
    getDocumentCursor,
    getDocumentsRecognitionStatus,
} from 'reactApp/modules/personalDocuments/personalDocuments.selectors';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { routeStatusPage } from 'reactApp/modules/router/router.module';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { getLoadMoreLimit } from 'reactApp/modules/storage/storage.helpers';
import { getItemById } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { uploaderTotalProgress } from 'reactApp/modules/upload/upload.module';
import { checkedDrop } from 'reactApp/modules/uploading/sagas/processDrop';
import { EFileStatus } from 'reactApp/modules/uploadList/uploadList.model';
import { updateUploadFilesAction } from 'reactApp/modules/uploadList/uploadList.module';
import { getInputFile } from 'reactApp/modules/uploadList/uploadList.selectors';
import { EStatus } from 'reactApp/sections/ErrorPage/ErrorPage.types';
import { DOCUMENT_ID, ITEMS_META } from 'reactApp/sections/PersonalDocuments/PersonalDocuments.constants';
import { sendDocumentPageGa, sendDocumentsGa } from 'reactApp/sections/PersonalDocuments/PersonalDocuments.helpers';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { getRecognitionError } from './personalDocuments.helpers';
import {
    addFileToDocument,
    disableDocumentsRecognition,
    disableDocumentsRecognitionSuccess,
    enableDocumentsRecognition,
    enableDocumentsRecognitionSuccess,
    loadDocumentFailure,
    loadDocumentRecognitionStatusSuccess,
    loadDocumentRequest,
    loadDocumentsMoreRequest,
    loadDocumentsSectionFailure,
    loadDocumentsSectionRequest,
    loadDocumentsSectionSuccess,
    loadDocumentSuccess,
    startUploadDocuments,
} from './personalDocuments.module';
import {
    type ApiDocumentResponse,
    type IApiPersonalDocumentsResponse,
    type IStartUploadDocuments,
    ERecognitionAction,
} from './personalDocuments.types';

const LOAD_LIMIT = getLoadMoreLimit(EStorageType.documents);

const callDocumentsListApi = ({ locale = 'ru' } = {}): Promise<{ data?: IApiPersonalDocumentsResponse }> =>
    new DocumentsListApiCall().makeRequest({ locale });
export const callDocumentsFileApi = ({
    path,
    locale = 'ru',
}: {
    path: string;
    locale?: string;
}): Promise<{ data?: IApiPersonalDocumentsResponse }> => new DocumentsFileApiCall().makeRequest({ path, locale });
const documentImagesApiCall = ({ documentId, cursor = '' }): Promise<{ data?: ApiDocumentResponse }> =>
    new DocumentImagesApiCall().makeRequest({
        limit: LOAD_LIMIT,
        document_id: documentId,
        ...(cursor ? { cursor } : {}),
    });
export const linkToDocument = ({ documentId, path }) => new LinkToDocument().makeRequest({ document_id: documentId, path });
export const unlinkDocument = ({ documentId, path }) => new UnlinkDocument().makeRequest({ document_id: documentId, path });
const recognitionStatusApiCall = () => new DocumentsRecognitionStatusApiCall().makeRequest();
const enableDocumentsRecognitionApiCall = () => new EnableDocumentsRecognitionApiCall().makeRequest();
const disableDocumentsRecognitionApiCall = () => new DisableDocumentsRecognitionApiCall().makeRequest();

function* loadDataForDocumentsPage() {
    try {
        const [{ data: documentsData }, { data: recognitionStatus }] = yield all([callDocumentsListApi(), recognitionStatusApiCall()]);

        yield put(loadDocumentsSectionSuccess({ ...documentsData }));
        yield put(loadDocumentRecognitionStatusSuccess({ recognitionStatus: recognitionStatus.status }));
        yield call(sendDocumentsGa, 'show', recognitionStatus.status, { count: documentsData?.list?.length });
    } catch (error) {
        logger.error(error);

        yield put(loadDocumentsSectionFailure());
    }
}

function* loadDataForDocument() {
    const currentDocument = yield select(getCurrentDocument);
    const documentId = DOCUMENT_ID[currentDocument];
    const gaDocument = ITEMS_META[documentId]?.gaId;

    try {
        if (!documentId) {
            yield put(routeStatusPage({ status: EStatus.NOT_FOUND }));
            return;
        }

        const { data: recognitionStatus } = yield recognitionStatusApiCall();
        yield put(loadDocumentRecognitionStatusSuccess({ recognitionStatus: recognitionStatus.status }));

        const { data: documentData } = yield documentImagesApiCall({ documentId });
        yield put(loadDocumentSuccess({ ...documentData, documentType: currentDocument }));

        yield call(sendDocumentPageGa, 'show', `${gaDocument}_${recognitionStatus.status}`);
    } catch (error: any) {
        logger.error(error);

        if (error.status === 404) {
            if (enableNewPersonalDocuments) {
                yield put(loadDocumentSuccess({ list: [], documentType: currentDocument }));
            } else {
                yield put(routeStatusPage({ status: EStatus.NOT_FOUND }));
            }

            return;
        }

        yield put(loadDocumentFailure({ documentType: currentDocument }));
    }
}

function* loadMoreDocument() {
    const documentType = yield select(getCurrentDocument);
    const cursor = yield select(getDocumentCursor);
    const documentId = DOCUMENT_ID[documentType];

    try {
        const { data: documentData } = yield documentImagesApiCall({ documentId, cursor });

        yield put(loadDocumentSuccess({ ...documentData, documentType }));
    } catch (error) {
        logger.error(error);

        yield put(loadDocumentFailure({ documentType }));
    }
}

function* enableDocumentsRecognitionCall() {
    try {
        yield enableDocumentsRecognitionApiCall();
        yield put(enableDocumentsRecognitionSuccess());

        if (enableNewPersonalDocuments) {
            yield put(
                showSnackbarAction({
                    id: 'enable-documents-recognition',
                    closable: true,
                    text: 'Ищем документы — как закончим, пришлём уведомление',
                    type: SnackbarTypes.success,
                })
            );
        } else {
            yield openPopupHelper({ popupName: popupNames.RECOGNITION_POPUP });
        }
    } catch (error) {
        logger.error(error);

        yield put(
            showSnackbarAction({
                id: 'documents-recognition',
                closable: true,
                ...getRecognitionError(ERecognitionAction.ENABLE),
                type: SnackbarTypes.failure,
            })
        );
    }
}

function* disableDocumentsRecognitionCall() {
    const currentDocument = yield select(getCurrentDocument);

    try {
        yield disableDocumentsRecognitionApiCall();
        yield put(disableDocumentsRecognitionSuccess());

        if (enableNewPersonalDocuments) {
            yield put(
                showSnackbarAction({
                    id: 'disable-documents-recognition',
                    closable: true,
                    text: 'Распознавание документов отключено',
                    type: SnackbarTypes.success,
                })
            );
        }

        if (!currentDocument) {
            yield loadDataForDocumentsPage();
        } else {
            yield loadDataForDocument();
        }
    } catch (error) {
        logger.error(error);

        yield put(
            showSnackbarAction({
                id: 'documents-recognition',
                closable: true,
                ...getRecognitionError(ERecognitionAction.DISABLE),
                type: SnackbarTypes.failure,
            })
        );
    }
}

function* linkDocument(action: PayloadAction<AddFileSuccessAction>) {
    const currentDocument = yield select(getCurrentDocument);
    const { items } = action.payload;
    const documents = yield all(
        items.map((item) =>
            select((state) =>
                getInputFile(
                    state,
                    'descriptorId' in item && item.descriptorId ? { descriptorId: item.descriptorId } : { cloudPath: item.home }
                )
            )
        )
    );

    for (let i = 0; i < documents.length; i++) {
        const document = documents[i];
        const documentType = document?.documentType || currentDocument;

        if (!documentType || !document) {
            return;
        }

        const path = items[i]?.home || document.cloudPath;
        const documentId = DOCUMENT_ID[documentType];
        const gaDocument = ITEMS_META[documentId].gaId;
        const item = yield select(getItemById, path);

        try {
            const file = items[i];
            yield linkToDocument({ path, documentId });
            yield put(addFileToDocument({ files: [file] as AddItem[], documentType }));

            if (!item) {
                yield put(
                    updateUploadFilesAction({
                        descriptorId: document.descriptorId,
                        cloudPath: document.cloudPath,
                        currentUpload: false,
                        status: EFileStatus.DONE,
                    })
                );
            }

            yield call(currentDocument ? sendDocumentPageGa : sendDocumentsGa, 'upload-success', gaDocument);
        } catch (error) {
            logger.error(error);

            yield put(
                showSnackbarAction({
                    id: 'document-link',
                    closable: true,
                    text: 'Произошла ошибка',
                    type: SnackbarTypes.failure,
                })
            );
        }
    }
}

function* handleStartUploadDocuments(action: PayloadAction<IStartUploadDocuments>) {
    const { data, documentType } = action.payload;

    yield call(checkedDrop, data, { checkAllowedExtensions: true, isDrop: true, documentType });
}

function* handleUploaderTotalProgress(action) {
    const { total, finished, failed, canceled } = action.payload;
    const failedCount = failed + canceled;
    const successCount = total - failedCount;
    const recognitionStatus = yield select(getDocumentsRecognitionStatus);
    const currentDocument = yield select(getCurrentDocument);
    const currentStorage = yield select(getCurrentStorage);
    const gaDocument = currentDocument ? ITEMS_META[DOCUMENT_ID[currentDocument]].gaId : '';

    if (currentStorage !== EStorageType.documents || (total > 0 && total !== finished)) {
        return;
    }

    yield call(currentDocument ? sendDocumentPageGa : sendDocumentsGa, 'upload', '', {
        ...(gaDocument ? { document: gaDocument } : {}),
        count: successCount,
        status: recognitionStatus,
    });
}

export function* watchLoadDocuments() {
    yield takeEvery(loadDocumentsSectionRequest.toString(), loadDataForDocumentsPage);
    yield takeEvery(loadDocumentRequest.toString(), loadDataForDocument);
    yield takeEvery(enableDocumentsRecognition.toString(), enableDocumentsRecognitionCall);
    yield takeEvery(disableDocumentsRecognition.toString(), disableDocumentsRecognitionCall);
    yield takeEvery(loadDocumentsMoreRequest.toString(), loadMoreDocument);
    yield takeEvery(addFilesSuccess.toString(), linkDocument);
    yield takeEvery(startUploadDocuments.toString(), handleStartUploadDocuments);
    yield takeLatest(uploaderTotalProgress.toString(), handleUploaderTotalProgress);
}
