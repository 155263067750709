/**
 * Файл с реализацией автозаполнения лидформ согласно схеме
 * https://confluence.vk.team/pages/viewpage.action?pageId=1064769140
 */

import config from 'Cloud/config';
import { xray } from 'lib/xray';
import { getUserShort } from 'reactApp/api/axios.corsapi';
import type { UserShort } from 'reactApp/api/axios.corsapi.types';
import { O2Auth } from 'reactApp/api/O2Auth';
import { IS_PHONE_BROWSER, OAUTH_HOST } from 'reactApp/appHelpers/configHelpers';
import { leadformOAuthClientId, leadformOriginRegexp } from 'reactApp/appHelpers/featuresHelpers/features/leadforms';

const OAUTH_CLIENT_ID = config.get('CORS_API_CLIENT_ID');
const EMAIL = config.get('user.email');

const sendXray = (event: string) => {
    xray.send('leadform', {
        i: event,
    });
};

let attachAuth: O2Auth | null = null;
function getOAuthAccessToken(shouldRefresh: boolean) {
    if (!attachAuth) {
        attachAuth = new O2Auth({ clientId: OAUTH_CLIENT_ID, login: EMAIL });
    }
    return shouldRefresh ? attachAuth.refreshToken() : attachAuth.getToken();
}

let _userData: UserShort | null = null;
async function getUserData() {
    if (!_userData) {
        try {
            sendXray('user_short_fetch');
            _userData = await getUserShort();
        } catch {
            sendXray('user_short_fetch_error');
        }
    }

    if (!_userData) {
        sendXray('user_short_empty');
    }

    return _userData;
}

let _city: string | null = null;
/**
 * Получение города юзера через слот погоды почты.
 * @returns Город, в котором находится юзер
 */
async function getCity() {
    if (!_city) {
        try {
            sendXray('city_fetch');
            // Слот таргетирован на почту, поэтому подставим её _SITEID
            const response = await fetch('https://ad.mail.ru/adp/?q=3031&_SITEID=105');
            const json = await response.json();
            const slotData = JSON.parse(json[0].html);
            _city = slotData.city;
        } catch {
            sendXray('city_error');
        }
    }

    if (!_city) {
        sendXray('city_empty');
    }

    return _city;
}

function fixPhone(phone: UserShort['phone_main']) {
    if (phone) {
        phone = phone.replace(/^\+/, '');
    }
    return phone;
}

function fixBirthday(birthday: UserShort['birthday']) {
    if (birthday) {
        const { day, month, year } = birthday;
        if (day && month && year) {
            return [`0${day}`.slice(-2), `0${month}`.slice(-2), year].join('.');
        }
    }
    return birthday;
}

function fixTrackingLink(link: string) {
    if (link) {
        try {
            const url = new URL(link);
            const searchParams = url.searchParams;
            if (leadformOriginRegexp.test(url.origin) && searchParams.get('type') === 'leadform') {
                searchParams.set('vk_client', 'mail');
                searchParams.set('use_custom_bridge', '1');
                searchParams.set('vk_app_id', leadformOAuthClientId);
                if (IS_PHONE_BROWSER) {
                    searchParams.set('view', 'mobile');
                }

                sendXray('fix_url');
                return url.href;
            }
        } catch (error) {
            sendXray('fix_url_error');
        }
    }
    return link;
}

async function getConvertedToken(): Promise<string | undefined> {
    try {
        const accessToken = await getOAuthAccessToken(true);
        if (accessToken) {
            try {
                const response = await fetch(`${OAUTH_HOST}/token`, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
                    },
                    body: [
                        `client_id=${OAUTH_CLIENT_ID}`,
                        `for_client_id=${leadformOAuthClientId}`,
                        `access_token=${accessToken}`,
                        'grant_type=convert',
                    ].join('&'),
                    method: 'POST',
                    mode: 'cors',
                });

                try {
                    const json = await response.json();

                    // eslint-disable-next-line max-depth
                    if (json.access_token) {
                        return json.access_token;
                    }
                    sendXray('access_token_empty');
                } catch (error) {
                    sendXray('access_token_error');
                }
            } catch (error) {
                sendXray('access_token_fetch_error');
            }
        } else {
            sendXray('main_token_empty');
        }
    } catch (error) {
        sendXray('main_token_error');
    }
}

function setTRGBViewCoreObserver() {
    let TRGBViewCoreCached = window['TRGBViewCore'];

    Object.defineProperty(window, 'TRGBViewCore', {
        set(TRGBViewCore) {
            const globInit = TRGBViewCore?.globInit;
            if (globInit) {
                TRGBViewCore.globInit = function (...args) {
                    const queue = args[0];

                    if (Array.isArray(queue)) {
                        queue.forEach((data) => {
                            if (data?.libOptions?.openInFrame && data.banner?.trackingLink) {
                                data.banner.trackingLink = fixTrackingLink(data.banner.trackingLink);
                            }
                        });
                    }
                    return globInit.apply(TRGBViewCore, args);
                };
            }
            TRGBViewCoreCached = TRGBViewCore;
        },
        get() {
            return TRGBViewCoreCached;
        },
        configurable: true,
    });
}

async function onLeadformMessage(event) {
    const { data, source } = event;
    const params = data?.params;

    if (!data || !source || !params?.request_id) {
        return;
    }

    let response: Record<string, any> | null = null;

    if (data.handler === 'VKWebAppGetUserInfo') {
        const userData = await getUserData();
        if (!userData) {
            return;
        }

        const city = await getCity();

        response = {
            first_name: userData.name?.first,
            last_name: userData.name?.last,
            bdate: fixBirthday(userData.birthday),
            ...(city && {
                city: {
                    title: city,
                },
            }),
        };

        sendXray('get_user_info');
    } else if (data.handler === 'VKWebAppGetAuthToken') {
        const accessToken = await getConvertedToken();
        if (accessToken) {
            response = {
                access_token: accessToken,
            };
        } else {
            sendXray('token_empty');
        }

        sendXray('get_token');
    } else if (data.handler === 'VKWebAppCallAPIMethod' && params.method === 'leadForms.getUserFormData') {
        const userData = await getUserData();
        if (!userData) {
            return;
        }

        response = {
            response: {
                phone: fixPhone(userData.phone_main),
                email: EMAIL,
            },
        };

        sendXray('get_phone');
    }

    if (response) {
        source.postMessage(
            {
                data: {
                    ...response,
                    request_id: params.request_id,
                },
                type: 'vk-connect',
            },
            '*'
        );
    }
}

export default function initLeadformFillObserver() {
    // Всякие соц юзеры не смогут ходить в эту корсапи за user/short.
    // Включаем автозаполнение только для обычных юзеров, у которых точно есть почта
    const userType = config.get('user.account_type');
    if (userType !== 'regular') {
        return;
    }

    setTRGBViewCoreObserver();

    window.addEventListener('message', onLeadformMessage);
}
