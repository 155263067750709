import { SubscriptionBuyAPICall } from 'reactApp/api/billing/subscription/SubscriptionBuyAPICall';
import { SubscriptionBuyTrialAPICall } from 'reactApp/api/billing/subscription/SubscriptionBuyTrialAPICall';
import { SubscriptionQuickAPICall } from 'reactApp/api/billing/subscription/SubscriptionQuickAPICall';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { isHidePayMethodsMenuTouch } from 'reactApp/appHelpers/featuresHelpers';
import { beforePurchaseEnabled } from 'reactApp/appHelpers/featuresHelpers/features/beforePurchaseMessage';
import { repeatPurchaseEnabled } from 'reactApp/appHelpers/featuresHelpers/features/repeatPurchase';
import { getBuyApiParams, getMidasPaymentLink, getRequestId } from 'reactApp/modules/billing/billing.helpers';
import { BillingActions } from 'reactApp/modules/billing/billing.module';
import type { BuyApiParams } from 'reactApp/modules/billing/billing.types';
import { loggerSaga } from 'reactApp/modules/logger/logger.saga';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import type { Product } from 'reactApp/types/Billing';
import { closeBeforePurchaseModal, renderBeforePurchaseModal } from 'reactApp/ui/BeforePurchaseModal/BeforePurchaseModal.helpers';
import {
    closeRepeatPurchaseMobileModal,
    renderRepeatPurchaseMobileModal,
} from 'reactApp/ui/RepeatPurchaseMobileModal/RepeatPurchaseMobileModal.helpers';
import { closeTariffBuyDialog } from 'reactApp/ui/TariffBuy/TariffBuy.helpers';
import { sendXray } from 'reactApp/utils/ga';
import { channel } from 'redux-saga';
import { put } from 'redux-saga/effects';
import { call, select, take } from 'typed-redux-saga';

export const subscriptionBuyApiCall = (params: BuyApiParams) => new SubscriptionBuyAPICall().makeRequest(params);
export const subscriptionBuyTrialApiCall = (params: BuyApiParams) => new SubscriptionBuyTrialAPICall().makeRequest(params);
export const subscriptionQuickApiCall = (params: BuyApiParams) => new SubscriptionQuickAPICall().makeRequest(params);

export function* handleBuySubscription(action: ReturnType<typeof BillingActions.buySubscription>) {
    const requestId = getRequestId();

    yield put(BillingActions.startLoading({ requestId }));

    const { id, isMobile, isQuick, xraySource = '', isMidas } = action.payload;

    const isMobileStr = isMobile ? 'touch' : '';

    // TODO: если id невалидный, то product будет undefined и всё посыпется. Стоит предусмотреть и убрать as Product
    const product = (yield* select(ProductsSelectors.getProductById, id)) as Product;

    const params = {
        ...getBuyApiParams(action.payload),
        product_id: id,
    };

    if (!product) {
        sendXray(['blng', 'buy', 'link', 'no-product', xraySource, isMobileStr]);
        yield put(BillingActions.hasError({ requestId }));
        yield loggerSaga({ error: 'buy_no_product' });
    }

    let link: string;
    let status: number;
    try {
        if (product.hasTrial) {
            const { data, ...rest } = yield* call(subscriptionBuyTrialApiCall, params);
            link = data.url;
            status = rest.status;
        } else if ((isMobile && isHidePayMethodsMenuTouch) || isQuick) {
            const { data, ...rest } = yield* call(subscriptionQuickApiCall, params);
            link = data.pw;
            status = rest.status;
        } else {
            const { data, ...rest } = yield* call(subscriptionBuyApiCall, params);
            link = isMidas ? getMidasPaymentLink(data.pw, isMobile) : data.pw;
            status = rest.status;
        }

        yield put(BillingActions.setLink({ link, requestId }));
        sendXray(['blng', 'buy', 'link', 'success', xraySource, isMobileStr]);

        const isRepeatPurchase = status === 202;

        if (beforePurchaseEnabled) {
            emitAnalyticEvent(AnalyticEventNames.BEFORE_PURCHASE_MESSAGE_STATUS);
            const beforePurchaseChannel = channel();
            yield renderBeforePurchaseModal({
                onClick() {
                    emitAnalyticEvent(AnalyticEventNames.BEFORE_PURCHASE_MESSAGE_CLICK);
                    beforePurchaseChannel.put(true);
                },
                onClose() {
                    emitAnalyticEvent(AnalyticEventNames.BEFORE_PURCHASE_MESSAGE_CLOSE);
                    beforePurchaseChannel.put(true);
                },
            });
            const continuePurchase = yield take(beforePurchaseChannel);
            yield closeBeforePurchaseModal();
            if (!continuePurchase) {
                yield closeTariffBuyDialog();
            }
            beforePurchaseChannel.close();
        } else if (isRepeatPurchase) {
            emitAnalyticEvent(AnalyticEventNames.STATUS_202_ON_BUY_SUBSCRIPTION);
            if (repeatPurchaseEnabled) {
                const repeatPurchaseChannel = channel();
                yield renderRepeatPurchaseMobileModal({
                    onCancel() {
                        emitAnalyticEvent(AnalyticEventNames.REPEAT_PURCHASE_MOBILE_CANCEL);
                        repeatPurchaseChannel.put(false);
                    },
                    onContinuePurchase() {
                        emitAnalyticEvent(AnalyticEventNames.REPEAT_PURCHASE_MOBILE_CLICK);
                        repeatPurchaseChannel.put(true);
                    },
                    onClose() {
                        emitAnalyticEvent(AnalyticEventNames.REPEAT_PURCHASE_MOBILE_CLOSE);
                        repeatPurchaseChannel.put(true);
                    },
                });
                const continuePurchase = yield take(repeatPurchaseChannel);
                yield closeRepeatPurchaseMobileModal();
                if (!continuePurchase) {
                    yield closeTariffBuyDialog();
                }
                repeatPurchaseChannel.close();
            }
        }
    } catch (error) {
        sendXray(['blng', 'buy', 'link', 'error', xraySource, isMobileStr]);
        yield put(BillingActions.hasError({ requestId }));
        yield loggerSaga({ error });
    }
}
