import { Rubles } from 'Cloud/Application/Rubles';
import config from 'Cloud/config';
import classNames from 'clsx';
import React, { type ReactElement, memo, useCallback } from 'react';
import { ADS_TIMER_MS } from 'reactApp/constants';
import { useShowAdsBannerTimer } from 'reactApp/hooks/useShowAdsBannerTimer';
import { DisableButton } from 'reactApp/ui/AdvBanners/DisableButton/DisableButton';
import { captureException } from 'reactApp/utils/tracer';

import styles from './SidebarBanner.css';

const DOM_ID_HOME_SIDEBAR_SMALL = config.get('DOM_ID_HOME_SIDEBAR_SMALL');
const DOM_ID_HOME_SIDEBAR = config.get('DOM_ID_HOME_SIDEBAR');

interface IProps {
    isSmall: boolean;
}

export const SidebarBanner = memo(({ isSmall }: IProps): ReactElement | null => {
    const showAD = !!DOM_ID_HOME_SIDEBAR && !!DOM_ID_HOME_SIDEBAR_SMALL;

    const updateAds = useCallback(() => {
        if (!showAD) {
            return;
        }

        try {
            if (isSmall) {
                Rubles?.updateHomeSidebarSmall();
            } else {
                Rubles?.updateHomeSidebar();
            }
        } catch (error) {
            captureException(error, { issueKey: 'SidebarBanner error' });
        }
    }, [showAD, isSmall]);

    const renderItem = () => {
        if (!showAD) {
            return null;
        }
        return (
            <div
                className={classNames(styles.root, {
                    [styles.root__small]: isSmall,
                })}
            >
                <div className={styles.header}>
                    {isSmall ? (
                        <DisableButton id="sidebar_hm" className={classNames(styles.headerButton, styles.headerButton__wide)} />
                    ) : (
                        <>
                            <span className={styles.headerText}>Реклама</span>
                            <DisableButton id="sidebig_hm" text="Отключить" className={styles.headerButton} />
                        </>
                    )}
                </div>
                <div
                    className={classNames(styles.content, {
                        [styles.content__small]: isSmall,
                    })}
                    id={isSmall ? DOM_ID_HOME_SIDEBAR_SMALL : DOM_ID_HOME_SIDEBAR}
                />
            </div>
        );
    };

    return useShowAdsBannerTimer({
        adsUpdateTimerMs: ADS_TIMER_MS,
        updateAds,
        suspendTimer: !showAD,
        renderItem,
    });
});

SidebarBanner.displayName = 'SidebarBanner';
