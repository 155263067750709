import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_MOBILE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

type UpsalesBonus = {
    variant: 'b' | 'c';
    tariffs: Record<string, string>;
};

const upsaleBonusValue = getFeature('upsale-bonus') || {};

if (!IS_MOBILE_BROWSER) {
    registerExperiment(
        'upsale-bonus',
        upsaleBonusValue?.variant || 'a',
        [AnalyticEventNames.UPSALE_BONUS_SHOW, AnalyticEventNames.TARIFF_BUY, AnalyticEventNames.FAST_CHECKOUT_SHOWN],
        {
            sendXrayArgs: {
                [AnalyticEventNames.TARIFF_BUY]: ({ paySource }, { eventName }) => ({
                    eventName: paySource?.startsWith('modal_year_offer') ? 'upsale_bonus_buy' : eventName,
                }),
                [AnalyticEventNames.FAST_CHECKOUT_SHOWN]: ({ paySource }, { eventName }) => ({
                    eventName: paySource?.startsWith('modal_year_offer') ? 'upsale_bonus_checkout' : eventName,
                }),
            },
        }
    );
}

export const upsaleBonus: UpsalesBonus | undefined = !IS_MOBILE_BROWSER && upsaleBonusValue;
