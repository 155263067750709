import type { Space } from '@mail/cross-sizes-utils';
import React, { memo } from 'react';
import { overquotaAfterClear } from 'reactApp/appHelpers/featuresHelpers/features/overquotaAfterClear';
import { overquotaNewCleared } from 'reactApp/appHelpers/featuresHelpers/features/overquotaNewCleared';
import { overquotaPayRestore } from 'reactApp/appHelpers/featuresHelpers/features/overquotaPayRestore';
import type { Product } from 'reactApp/types/Billing';
import type { ModeType } from 'reactApp/ui/OverquotaPopup/newSplash/OverquotaSplash.types';
import { formatFileHistoryDate } from 'reactApp/utils/formatDate';

export const Content: React.FC<{ mode?: ModeType; product?: Product; overquota?: Space; clearTime?: number; isMobile?: boolean }> = memo(
    ({ mode, product, overquota, clearTime, isMobile }) => {
        const br = isMobile ? ' ' : <br />;

        if (mode === 'cleared' && overquotaNewCleared) {
            return (
                <>
                    Ваше пространство было переполнено больше
                    {br}
                    397 дней. Спустя этот срок по&nbsp;правилам сервиса
                    {br}
                    оно автоматически очищается
                </>
            );
        }

        if (mode === 'cleared') {
            return (
                <>
                    Мы удалили все загруженные файлы, так как Облако было
                    <br />
                    переполнено более трёх месяцев.
                    <br />
                    Восстановление доступа в&nbsp;Облако займет некоторое время.
                </>
            );
        }

        if (mode === 'after_clear' && overquotaAfterClear) {
            if (overquotaPayRestore) {
                return (
                    <>
                        Расширить его и вернуть доступ можно с подпиской Mail Space:
                        <br /> {product ? `${product.space?.value} за ${product?.price} ₽` : ''} и все ваши файлы будут доступны через
                        <br /> несколько дней
                    </>
                );
            }

            return (
                <>
                    Ваше пространство переполнено, поэтому доступ к файлам
                    <br />
                    был заблокирован. Разблокируйте Облако и файлы будут доступны
                    <br />
                    через несколько дней
                </>
            );
        }

        return (
            <>
                Ваше пространство переполнено
                {overquota?.original ? (
                    <>
                        {' '}
                        на&nbsp;
                        <span>
                            <b>{overquota?.value}</b>
                        </span>
                    </>
                ) : (
                    ''
                )}
                . Удалите лишнее
                {br}
                или увеличьте место с подпиской Mail&nbsp;Space — без этого оно
                {br}
                очистится автоматически по правилам сервиса <span>{!!clearTime && formatFileHistoryDate(clearTime)}</span>
            </>
        );
    }
);

Content.displayName = 'Content';
