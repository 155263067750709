import classNames from 'clsx';
import React, { memo } from 'react';
import { IS_MY_TEAM } from 'reactApp/appHelpers/configHelpers';

import styles from './Logo.css';
import { getAlt, getLogoSrc } from './Logo.helpers';
import type { IProps } from './Logo.types';

export const LogoComponent = memo<IProps>(function LogoComponent({
    isBizUser,
    logoSrc: src,
    bizLogo,
    dark,
    themeOutsource = false,
    errorPage = false,
    isSuperAppWebView = false,
    clickable = true,
}) {
    const logoSrc = getLogoSrc({ isBizUser, logoSrc: src, bizLogo, dark });
    const alt = getAlt(isBizUser);
    if (isBizUser && IS_MY_TEAM) {
        return null;
    }

    return (
        <a
            href={isSuperAppWebView || !clickable ? undefined : '/'}
            title={alt}
            className={classNames(styles.root, {
                ['errorPageDisableCursorEvents']: errorPage,
                [styles.disableEvents]: isSuperAppWebView,
            })}
            data-qa-button="logo"
            id="mobile__logo-component"
        >
            <img height={24} src={logoSrc} alt={alt} className={classNames({ [styles.themeOutsourceLogo]: themeOutsource })} />
        </a>
    );
});
