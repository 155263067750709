import settings from 'Cloud/settings';
import {
    IS_AUTO_TEST_HIDE,
    IS_B2B_BIZ_USER,
    IS_MY_TEAM,
    IS_PHONE_BROWSER,
    IS_PUBLIC,
    IS_VKT_WEBVIEW,
    IS_WEBVIEW,
} from 'reactApp/appHelpers/configHelpers';
import { allAttachesBttn } from 'reactApp/appHelpers/featuresHelpers/features/allAttachesBttn';
import { isViewContentOnly } from 'reactApp/appHelpers/settingsHelpers';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { store } from 'reactApp/store';
import { put, select } from 'redux-saga/effects';

const PROMO_TIP_KEY = 'all-attaches-promo';
const PERIOD_TO_SHOW_DAYS = 30;

export function* initAllAttachesPromo() {
    if (
        !allAttachesBttn ||
        settings?.request?.action ||
        IS_PHONE_BROWSER ||
        isViewContentOnly ||
        IS_WEBVIEW ||
        IS_MY_TEAM ||
        IS_AUTO_TEST_HIDE ||
        IS_B2B_BIZ_USER ||
        IS_VKT_WEBVIEW ||
        IS_PUBLIC
    ) {
        return;
    }

    const isNewbie = yield select(UserSelectors.isNewbie);

    if (isNewbie) {
        return;
    }

    const allowShowPromo = !storeHelper.getValue(PROMO_TIP_KEY) || storeHelper.isPassed(PROMO_TIP_KEY, { daysCount: PERIOD_TO_SHOW_DAYS });

    if (!allowShowPromo) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.allAttaches,
            promoId: PROMO_TIP_KEY,
            forceTopmost: true,
            onShow: () => {
                storeHelper.markAsShown(PROMO_TIP_KEY);
                store.dispatch(promoShown(EPromoType.allAttaches));
            },
            onClose: () => store.dispatch(removePromo(EPromoType.allAttaches)),
        })
    );
}
