import React, { type FC, useCallback } from 'react';

import styles from './Button.css';

interface Props {
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const Button: FC<Props> = ({ children, onClick }) => {
    const handleClick = useCallback(
        (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (!onClick) {
                return;
            }

            evt.stopPropagation();
            onClick(evt);
        },
        [onClick]
    );

    return (
        <button className={styles.button} onClick={handleClick}>
            {children}
        </button>
    );
};
