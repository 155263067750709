import { Icon16GiftOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { type FC, type ReactNode, type SVGProps, type VFC } from 'react';

import styles from './SubscriptionInfoItem.css';

type Props = {
    title: string;
    subtitle: string | ReactNode;
    Icon: FC<SVGProps<SVGSVGElement>>;
    buttonText: string;
    showButton?: boolean;
    onClick: () => void;
    isRebrandingQuotaLanding?: boolean;
    discountBanner?: ReactNode;
    giftLabel?: string;
};

export const SubscriptionInfoItem: VFC<Props> = ({
    title,
    subtitle,
    Icon,
    buttonText,
    onClick,
    showButton = true,
    isRebrandingQuotaLanding = false,
    discountBanner,
    // tempexp_17360-next-line
    giftLabel,
}) => (
    <div className={classNames(styles.root, { [styles.root_rebrandingQuotaLanding]: isRebrandingQuotaLanding })}>
        <Icon className={styles.icon} width={28} height={28} />
        <div className={styles.container}>
            <span className={styles.title}>{title}</span>
            <span className={styles.subtitle}>{subtitle}</span>
            {showButton && (
                <div className={styles.button} onClick={onClick}>
                    {buttonText}
                </div>
            )}
        </div>
        {discountBanner && <div className={styles.discountBanner}>{discountBanner}</div>}
        {/* tempexp_17360-next-line */}
        {giftLabel && (
            <div className={styles.gift}>
                <Icon16GiftOutline />
                {giftLabel}
            </div>
        )}
    </div>
);
