import type { AxiosResponse } from 'lib/axios';
import { logger } from 'lib/logger';
import { equals } from 'ramda';
import { BillingDataAPICall } from 'reactApp/api/billing/data/BillingDataAPICall';
import type { BillingDataResponse } from 'reactApp/api/billing/data/BillingDataAPICall.types';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { sendXray } from 'reactApp/utils/ga';
import ping from 'reactApp/utils/ping';
import { captureException } from 'reactApp/utils/tracer';
import { call, cancel, put, select, takeEvery } from 'redux-saga/effects';

import { getBillingDataRequest, getBillingDataSuccess, updateBillingDataRequest, updateBillingDataSuccess } from './billingData.module';
import { BillingDataSelectors } from './billingData.selectors';
import { BillingDataNotifications } from './billingData.types';

const callBillingDataApi = () => new BillingDataAPICall().makeRequest();

// TODO: Аналогично billing/subscriptions, возможно нужен более корректный способ.
const checkBillingDataEquals =
    (oldData: Pick<BillingDataResponse['data'], 'android' | 'ios' | 'web'>) =>
    ({ data }: AxiosResponse<BillingDataResponse>) => {
        const resp = data || {};
        const { android, ios, web } = resp.data;
        const newData = {
            android,
            ios,
            web,
        };

        return !equals(newData, oldData);
    };

export function* handleBillingDataRequest() {
    const isUserWithBilling = yield select(UserSelectors.isUserWithBilling);
    if (!isUserWithBilling) {
        yield cancel();
    }

    try {
        sendXray(['billingData', 'request']);
        const { data }: AxiosResponse<BillingDataResponse> = yield callBillingDataApi();
        if (!data) {
            sendXray(['billingData', 'request', 'empty', 'error']);
            logger.error('no billing data from api');
            captureException('no billing data from api', { issueKey: 'billingData/empty' });
            yield cancel();
        }
        sendXray(['billingData', 'request', 'success']);
        yield put(getBillingDataSuccess({ billingData: data }));
    } catch (error) {
        sendXray(['billingData', 'request', 'error']);
        logger.error(error);
        captureException(error, { issueKey: 'billingData/error' });
        yield cancel();
    }
}

function* handleUpdateBillingDataRequest(action: ReturnType<typeof updateBillingDataRequest>) {
    const { showSnackbar = false } = action.payload || {};

    const isUserWithBilling = yield select(UserSelectors.isUserWithBilling);
    if (!isUserWithBilling) {
        yield cancel();
    }

    sendXray(['billingData', 'update', 'request']);
    try {
        const {
            data: { android, web, ios },
        }: BillingDataResponse = yield select(BillingDataSelectors.getBillindDataRaw);

        const initial = {
            android,
            ios,
            web,
        };

        const res: AxiosResponse<BillingDataResponse> = yield call(ping, {
            request: callBillingDataApi,
            check: checkBillingDataEquals(initial),
        });

        sendXray(['billingData', 'update', 'request', 'success']);
        yield put(updateBillingDataSuccess({ billingData: res.data }));

        if (showSnackbar) {
            yield put(
                showSnackbarAction({
                    text: 'Список подписок обновлён',
                    type: SnackbarTypes.success,
                    id: BillingDataNotifications.updateSuccess,
                    closable: true,
                })
            );
        }
    } catch (error) {
        sendXray(['billingData', 'update', 'request', 'error']);
        logger.error(error);
        captureException(error, { issueKey: 'billingData/update/error' });

        showSnackbarAction({
            text: 'Не удалось обновить список подписок',
            type: SnackbarTypes.failure,
            id: BillingDataNotifications.updateFailure,
            closable: true,
        });

        yield cancel();
    }
}

export function* watchBillingDataRoot() {
    yield takeEvery(getBillingDataRequest.toString(), handleBillingDataRequest);
    yield takeEvery(updateBillingDataRequest.toString(), handleUpdateBillingDataRequest);
}
