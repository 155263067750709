import { plural } from 'lib/plural';

export const pretextSPlural = (count: number): string => ['С', 'Со'][count === 2 ? 1 : 0];

export const filesPlural = (count: number): string => ['файл', 'файла', 'файлов'][plural(count)];

export const lettersPlural = (count: number): string => ['письмо', 'письма', 'писем'][plural(count)];

export const foldersPlural = (count: number): string => ['папка', 'папки', 'папок'][plural(count)];

export const filesPluralGenitive = (count: number): string => ['файла', 'файлов', 'файлов'][plural(count)];

export const foldersPluralGenitive = (count: number): string => ['папки', 'папок', 'папок'][plural(count)];

export const foldersPluralAccusative = (count: number): string => ['папку', 'папки', 'папок'][plural(count)];

export const imagesPlural = (count: number): string => (count >= 0 ? ['снимок', 'снимка', 'снимков'][plural(count)] : '');

export const leftPlural = (count: number): string => ['остался', 'осталось', 'осталось'][plural(count)];

export const downloadPlural = (count: number): string => ['загружен', 'загружено', 'загружено'][plural(count)];

export const fixedPlural = (count: number): string => ['исправлен', 'исправлено', 'исправлено'][plural(count)];

export const errorPlural = (count: number): string => ['ошибка', 'ошибки', 'ошибок'][plural(count)];

export const bePlural = (count: number): string => ['был', 'было'][plural(count)];

export const selectedPlural = (count: number): string => ['Выбран', 'Выбрано', 'Выбрано'][plural(count)];

export const deleteMalePluralV1 = (count: number): string => ['удалён', 'удалено', 'удалено'][plural(count)];

export const deleteMalePluralV2 = (count: number): string => ['удалён', 'удалены', 'удалены'][plural(count)];

export const restoreMalePluralV1 = (count: number): string => ['восстановлен', 'восстановлено', 'восстановлено'][plural(count)];

export const restoreMalePluralV2 = (count: number): string => ['восстановлен', 'восстановлены', 'восстановлены'][plural(count)];

export const deleteNeutralPluralV2 = (count: number): string => ['удалено', 'удалены', 'удалены'][plural(count)];

export const daysPlural = (count: number): string => ['дня', 'дней', 'дней'][plural(count)];

export const hePlural = (count: number): string => ['его', 'их', 'их'][plural(count)];
export const shePlural = (count: number): string => ['её', 'их', 'их'][plural(count)];
