import api from 'Cloud/Application/api';
import { getFeature, isFeature } from 'Cloud/Application/FeaturesEs6';
import config from 'Cloud/config';
import $ from 'jquery';
import { isDialogVisible } from 'reactApp/modules/dialog/dialog.selectors';
import { isEditorStorage } from 'reactApp/modules/editor/editor.selectors';
import { isModifyingInProgress } from 'reactApp/modules/modifying/modifying.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { isProgressingStatus } from 'reactApp/modules/uploadList/uploadList.selectors';
import { store } from 'reactApp/store';
import { sendXray } from 'reactApp/utils/ga';
import { captureException } from 'reactApp/utils/tracer';

const ONE_MIN = 60000;

let checkBuildTimeout = 5 * ONE_MIN; // проверять версию каждые 5 мин
let needReload = false;
let reloadTimer: undefined | number;
let lastHidden = 0;

const randomInterval = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

export default function Build() {
    const currentBuild = config.get('BUILD');

    const reloadWindowTime = getFeature('force-reload-window-time');

    // попытка обновления после 1-10 минут или значение из фичи
    let reloadDelay = randomInterval(1, reloadWindowTime ?? 10) * ONE_MIN;

    if (isFeature('force-build-reload')) {
        reloadDelay = 20;
        checkBuildTimeout = 20000; // 20 секунд
    }

    if (window.__PH) {
        window.__PH.on('visibilitychange', (event) => {
            if (event.data.visible) {
                lastHidden = 0;
            } else {
                lastHidden = Date.now();
            }
        });
    }

    // ежеминутно скидываем текущий билд в радар
    window.setInterval(() => {
        // eslint-disable-next-line new-cap
        window.RADAR(currentBuild);
    }, ONE_MIN);

    function stopWaiting() {
        window.clearTimeout(reloadTimer);
        reloadTimer = undefined;
    }

    function waitReload(reloadRadar) {
        if (reloadTimer) {
            return;
        }

        reloadTimer = window.setTimeout(() => {
            stopWaiting();

            if (!needReload) {
                return;
            }

            const state = store.getState();
            const storage = getCurrentStorage(state);
            const isEditor = isEditorStorage(storage);
            const isEditorIdleReload = isFeature('editor-idle-build-reload');
            const isTabActive = !lastHidden || ONE_MIN > Date.now() - lastHidden;
            const isEditing = isEditor && isEditorIdleReload && isTabActive;
            const isUploading = isProgressingStatus(state);
            const isModifying = isModifyingInProgress(state);
            const isDialog = isDialogVisible(state);
            const isApi = api.hasPostRequests();

            if (isUploading || isEditing || isModifying || isDialog || isApi) {
                if (isEditing) {
                    sendXray(`wait_reload_editor_from_feature`);
                }

                waitReload(reloadRadar);
                return;
            }

            if (isEditor) {
                sendXray(`reload_editor_from_change_build`);
            }

            reloadRadar.send().after(() => {
                window.location.reload();
            });
        }, reloadDelay);
    }

    function update() {
        const radar = RADAR.group('build_check');

        const fail = (...args) => {
            captureException(null, { issueKey: 'BUILD: fail check build update', message: 'BUILD: fail check build update' });
            radar.kaktam('build_check', JSON.stringify(args));
            radar.add('error');
            update();
        };

        const done = (result) => {
            const [buildString, buildVersion] = result.split(':');

            if (buildString === 'BUILD') {
                const actualBuild = buildVersion.replace(/\s+/g, '');

                needReload = actualBuild !== currentBuild;

                if (needReload) {
                    radar.add(actualBuild > currentBuild ? 'upgrade' : 'downgrage');
                    waitReload(RADAR.group('build_reload'));
                } else {
                    stopWaiting();
                }

                update();
            } else {
                fail(result);
            }
        };

        window.setTimeout(() => {
            radar.add('wait');

            $.get(`/BUILD?${Math.random()}`).done(done).fail(fail).always(radar.send);
        }, checkBuildTimeout);
    }

    update();
}
