import classNames from 'clsx';
import { ReactComponent as AppQRCode } from 'img/errors/app-qrcode.svg';
import React, { type FC } from 'react';
import { IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { DisableButtonComponent } from 'reactApp/ui/AdvBanners/DisableButton/DisableButtonComponent';
import { DownloadAppButtonComponent } from 'reactApp/ui/Mobile/DownloadAppButton/DownloadButtonComponent';
import { ON_PREMISE } from 'server/constants/environment';
import { EQueryParams } from 'server/helpers/getRequestParams';

import { ErrorPageButton } from './components/ErrorPageButton';
import styles from './ErrorPage.css';
import { disableAdvButtonHref, ERROR_STATUS_MAP, ERROR_STATUS_MAP_BIZ } from './ErrorPage.helpers';
import type { ErrorInfo, EStatus, IProps } from './ErrorPage.types';

export const ErrorPageComponent: FC<Omit<IProps, 'status'> & ErrorInfo & { status: EStatus | string }> = ({
    status,
    title,
    annotation,
    buttonLink,
    buttonText,
    imageSrc,
    imageSrcDark,
    downloadLink,
    onButtonClick,
    onClickHandler,
    advDomIdDesktop,
    children,
    isHidePromo = false,
    isDark = false,
    isStatic = false,
    isBiz = false,
}) => {
    /**
     * Обработчик, который вызывает onButtonClick (захардкожен - отправляется статистика)
     * и onClickHandler (любой обработчик из ERROR_STATUS_MAP) при его наличии.
     */
    const onButtonClickHandler = () => {
        onButtonClick?.();
        onClickHandler?.();
    };

    const fromDeeplink = getQueryParams()[EQueryParams.fromDeeplink];

    return (
        <div className={styles.container} data-qa-id={status}>
            <div className={styles.wrapper}>
                <div className={classNames(styles.root, { [styles.static]: isStatic, [styles.dark]: isDark, [styles.biz]: isBiz })}>
                    {(!isDark || !imageSrcDark) && <img className={classNames(styles.error, styles.errorLight)} src={imageSrc} />}
                    <img className={classNames(styles.error, styles.errorDark)} src={imageSrcDark} />
                    <div className={styles.description}>
                        <div className={styles.title}>{title}</div>
                        <div className={styles.annotation}>{annotation}</div>
                        <ErrorPageButton link={buttonLink} text={buttonText} onClick={onButtonClickHandler} />
                    </div>
                    {!isHidePromo && !ON_PREMISE && (
                        <>
                            <div className={styles.downloadAppContainer}>
                                <div className={styles.qrcodeContainer}>
                                    <div className={styles.qrcode}>
                                        <AppQRCode />
                                    </div>
                                    <div className={styles.qrcodeText}>
                                        Установите приложение, чтобы ваши файлы и&nbsp;фото всегда были под рукой
                                    </div>
                                </div>
                            </div>
                            <div
                                className={classNames(styles.appButton, {
                                    ['errorPageDisableElement']: process.env.SSR || (IS_WEBVIEW && fromDeeplink),
                                })}
                                id="error-page__download-button"
                            >
                                <DownloadAppButtonComponent linkToDownload={downloadLink} text="Установить приложение" />
                            </div>
                        </>
                    )}
                </div>
            </div>
            {!ON_PREMISE && (
                <div className={classNames(styles.advDesktop, 'js-right-block-error-page')}>
                    {children || (
                        <div className={styles.advHeaderStatic}>
                            <h3 className={styles.advTitle}>Реклама</h3>
                            <DisableButtonComponent
                                className={classNames('js-disable-button', styles.advDisableButton)}
                                text="Отключить"
                                href={disableAdvButtonHref}
                            />
                        </div>
                    )}
                    <div id={advDomIdDesktop} data-qa-id="Sidebar" />
                </div>
            )}
        </div>
    );
};

export const ErrorPageBlock: FC<IProps & { isBiz?: boolean }> = (props) => {
    const { status, isBiz } = props;

    const info = isBiz && ERROR_STATUS_MAP_BIZ[status] ? ERROR_STATUS_MAP_BIZ[status] : ERROR_STATUS_MAP[status];

    return <ErrorPageComponent {...props} {...info} isBiz={isBiz} />;
};
