import React from 'react';

export const templateFeatures = {
    // Про рекламу
    noadsWeb: {
        text: 'Без рекламы в веб-версии',
        key: 'ad',
    },
    noads: {
        text: 'Без рекламы',
        key: 'ad',
    },
    noadsMailCloud: {
        text: 'Без рекламы в Почте и Облаке',
        key: 'ad',
    },

    // Про квоту на загрузку
    upload100: {
        text: 'Загрузка файлов до\u00A0100\u00A0ГБ',
        key: 'upload',
    },
    upload100Cloud: {
        text: 'Загрузка файлов до\u00A0100\u00A0ГБ в\u00A0Облако',
        key: 'upload',
    },
    upload2Cloud: {
        text: 'Загрузка файлов до\u00A02\u00A0ГБ в\u00A0Облако',
        key: 'upload',
    },

    // Про папки
    foldersAutodelete: {
        text: 'Автоудаление папок',
        key: 'autodelete',
    },
    history60: {
        text: '60 дней истории изменений файлов',
        key: 'hist',
    },

    // Про ДискО
    diskoAllColored: {
        text: 'Доступ ко\u00A0всем возможностям Диск&#8209;О:',
        key: 'disko',
    },
    diskoAll: {
        text: 'Доступ ко\u00A0всем возможностям Диск-О:',
        key: 'disko',
    },

    // Про бонусы
    bonuses: {
        text: 'Скидки и бонусы от партнеров',
        key: 'discount',
    },
    otherBenefits: {
        text: 'И другие возможности',
        textJsx: (
            <>
                И другие{'\u00A0'}
                <a href="#benefits">возможности</a>
            </>
        ),
        key: 'benefits',
    },

    // Про поддержку
    supportFast: {
        text: 'Быстрая поддержка — ответим в течение часа с\u00A09:00\u00A0до\u00A021:00\u00A0МСК',
        key: 'support',
    },
    supportFastAnswer: {
        text: 'Быстрый ответ поддержки',
        key: 'support',
    },
    supportPriority: {
        text: 'Приоритетная поддержка',
        key: 'support',
    },

    // Про семью
    familyShare: {
        text: 'Делитесь местом с\u00A0близкими',
        key: 'family',
    },
    familyAdd3: {
        text: 'Добавление ещё 3\u00A0участников',
        key: 'family',
    },

    // Прочее про Почту
    letterCancel: {
        text: 'Отмена отправки писем',
        key: 'cancel',
    },
    send100Mail: {
        text: 'Отправка файлов до\u00A0100\u00A0ГБ в\u00A0письмах',
        key: 'sending',
    },
    send100: {
        text: 'Отправка файлов до\u00A0100\u00A0ГБ',
        key: 'sending',
    },
};
