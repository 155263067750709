import { Button } from '@vkontakte/vkui';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { restoreTrashbinFiles } from 'reactApp/appHelpers/featuresHelpers/features/restoreTrashbinFiles';
import { UserStorageActions, UserStorageSelectors } from 'reactApp/modules/user/userStorage';
import type { RootState } from 'reactApp/store';
import { EInformerColor } from 'reactApp/ui/Informer/Informer.types';
import { InlineInformer } from 'reactApp/ui/Informer/InlineInformer/InlineInformer';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './RestoreTrashbinFilesInformer.css';

const RESTORE_TRASHBIN_FILES_INFORMER_STORAGE_KEY = 'restore-trashbin-files-informer';

type Steps = 'start' | 'intermediate' | 'finish';

export const RestoreTrashbinFilesInformer = () => {
    const dispatch = useDispatch();
    const userClickedInformer: boolean = useSelector((state: RootState) =>
        UserStorageSelectors.get(state, RESTORE_TRASHBIN_FILES_INFORMER_STORAGE_KEY)
    );
    const [step, setStep] = useState<Steps>(userClickedInformer ? 'finish' : 'start');
    const restoreDuration = restoreTrashbinFiles === 'b' ? 60 : 30;

    useEffect(() => {
        if (step === 'start') {
            sendPaymentGa({
                action: `busket_${restoreDuration}d_show`,
                eventCategory: ECategoryGa.informer,
            });
        }
    }, []);

    const onSuccess = useCallback(() => {
        dispatch(UserStorageActions.set(RESTORE_TRASHBIN_FILES_INFORMER_STORAGE_KEY));
        setStep('intermediate');
        sendPaymentGa({
            action: `busket_${restoreDuration}d_click`,
            eventCategory: ECategoryGa.informer,
        });
        sendPaymentGa({
            action: `thanks_busket_${restoreDuration}d_show`,
            eventCategory: ECategoryGa.informer,
        });
    }, [dispatch, restoreDuration]);

    const onCancel = useCallback(() => {
        dispatch(UserStorageActions.set(RESTORE_TRASHBIN_FILES_INFORMER_STORAGE_KEY));
        setStep('finish');
        sendPaymentGa({
            action: `busket_${restoreDuration}d_close`,
            eventCategory: ECategoryGa.informer,
        });
    }, [dispatch, restoreDuration]);

    const onContinue = useCallback(() => {
        setStep('finish');
    }, []);

    const content = useMemo(() => {
        if (step === 'intermediate') {
            return (
                <>
                    Спасибо за интерес! Мы пока работаем над этой функцией. Сообщим сразу, как появится
                    <div className={styles.buttons}>
                        <Button mode="secondary" appearance="neutral" size="s" onClick={onContinue}>
                            Хорошо
                        </Button>
                    </div>
                </>
            );
        } else if (step === 'finish') {
            return <div className={styles.clickedInformer}>Удалённые файлы в корзине хранятся 14 дней</div>;
        }
        return (
            <>
                Удалённые файлы в корзине хранятся 14 дней. Увеличить срок до {restoreDuration}?
                <div className={styles.buttons}>
                    <Button mode="secondary" appearance="neutral" size="s" onClick={onSuccess}>
                        Увеличить за 1 ₽
                    </Button>
                    <Button mode="secondary" appearance="neutral" size="s" onClick={onCancel}>
                        Нет
                    </Button>
                </div>
            </>
        );
    }, [step, restoreDuration, onSuccess, onCancel, onContinue]);

    return (
        <InlineInformer color={EInformerColor.transparent} noPadding>
            <div className={styles.content}>{content}</div>
        </InlineInformer>
    );
};
