export enum PromoBlockTextColor {
    white = 'white',
    black = 'black',
}

export enum PromoBlockBgColor {
    blue = 'blue',
    lightBlue = 'lightBlue',
}

export enum PromoBlockButtonColor {
    black = 'black',
}

export const enum PromoBlockId {
    AUTOUPLOAD_10TB = 'AUTOUPLOAD_10TB_PROMO_BLOCK',
}

export interface PromoBlockBase {
    id: PromoBlockId;
    title: string;
    text: string;
    textColor: PromoBlockTextColor;
    buttonText: string;
    buttonColor: PromoBlockButtonColor;
    backgroundColor: PromoBlockBgColor;
    image: string;
    imageFullHeight?: boolean;
    analyticsName: string;
}

export interface PromoBlockProps extends PromoBlockBase {
    onClick: () => void;
    onClose?: () => void;
}
