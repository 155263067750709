import { getFeature, isFeature } from 'Cloud/Application/FeaturesEs6';

/**
 * Включение автозаполнение рекламного формата лидформ
 */
export const isLeadformAutofillEnabled: boolean = isFeature('adv-leadform-autofill');

/**
 * Регулярка для обнаружения ссылки на iframe с лидформой
 */
export const leadformOriginRegexp = new RegExp(getFeature('adv-leadform-origin-regexp') || '^https:\\/\\/ads\\.vk\\.com');

/**
 * clientId для лидформ
 */
export const leadformOAuthClientId: string = getFeature('leadform-oauth-client-id') || 'ef7a484e43624c78b9baa2f55211564a';
