import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';

import type { ThanksBalloonProps } from './ThanksBalloon.types';

export const openThanksBalloon = (data: ThanksBalloonProps) =>
    openPopupHelper({
        popupName: popupNames.THANKS_BALLOON,
        data,
    });
