import { classNames } from '@vkontakte/vkui';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useIsTabsDesign } from 'reactApp/components/SharingWindow/Sharing.helpers';
import { User } from 'reactApp/components/SharingWindow/SharingNew/User/User';
import type { IACListItem } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { Loader } from 'reactApp/ui/Loader/Loader';

import styles from './UserList.css';
import type { IProps } from './UserList.types';

export const UserList = React.memo(function UserList({
    acList,
    isLoading,
    isMounted,
    isSharedIncoming,
    owner,
    isPhone,
    item,
    onClose,
}: IProps) {
    const name = owner?.name || '';
    const email = name ? ` (${owner?.email})` : owner?.email;
    const isReadOnlyItem = !!item?.isReadOnly;
    const currentUserEmail = useSelector(UserSelectors.getEmail);
    const isTabs = useIsTabsDesign({ item, id: item?.id || '' });

    const renderItem = useCallback(
        (user) => (
            <div className={styles.user} key={user.email}>
                <User
                    user={user}
                    item={item}
                    isMounted={isMounted}
                    currentUserEmail={currentUserEmail}
                    onClose={onClose}
                    publicId={item?.weblink}
                    isPhone={isPhone}
                    isTabs={isTabs}
                />
            </div>
        ),
        [currentUserEmail, isMounted, item, onClose, isPhone]
    );

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_phone]: isPhone,
                [styles.mounted]: isSharedIncoming,
            })}
        >
            {isMounted && owner && (
                <div className={styles.mountedRights}>
                    <span>
                        {name}
                        {email}
                    </span>{' '}
                    предоставил вам доступ на
                    {isReadOnlyItem ? ' чтение' : ' редактирование'}
                </div>
            )}
            <div className={classNames(styles.usersList, { [styles.usersList_scroll]: !isMounted && acList.length > 2 })}>
                {isLoading ? (
                    <div className={styles.loading}>
                        <Loader centered isModal />
                    </div>
                ) : (
                    <>
                        {Boolean(owner) && (
                            <div className={styles.user}>
                                <User user={owner as IACListItem} isPhone={isPhone} isOwner isTabs={isTabs} />
                            </div>
                        )}
                        {acList.map((user) => renderItem(user))}
                    </>
                )}
            </div>
        </div>
    );
});
