import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IS_B2B_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { isPersonalAccessSharingEnabled } from 'reactApp/appHelpers/featuresHelpers/features/personalAccessSharing';
import { unPublishHelper } from 'reactApp/appHelpers/publishHelper';
import { sendGa } from 'reactApp/components/SharingNewBiz/SharingNew.helpers';
import { renderUnpublishWeblinkDialog } from 'reactApp/components/SharingNewBiz/Weblink/SharingNewWeblink.helpers';
import { deleteAllUsers } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.actions';
import { publishRequest } from 'reactApp/modules/modifying/modifying.actions';
import type { PublishItem } from 'reactApp/modules/modifying/modifying.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { weblinkDeleteAllEmailAccess } from 'reactApp/modules/weblinkAccessControl/weblinkAccessControl.actions';

import type { IProps } from './sharingNew.hook.types';

export const useSharingNew = (props: IProps) => {
    const { storage, item, acList, isFromSharing, isFromWeblink, isOwner } = props;

    const [isCloudUsersOpened, setCloudUsersOpened] = useState<boolean>(isFromSharing);
    const [isWeblinkOpened, setWeblinkOpened] = useState<boolean>(isFromWeblink);

    const dispatch = useDispatch();

    useEffect(() => sendGa('show'), []);

    const onActionClick = useCallback(
        (publicItem?: PublishItem) => {
            sendGa('unpublish');
            if (storage === EStorageType.attaches) {
                sendGa('attaches-unpublish');
            }

            if (!item) {
                return;
            }

            unPublishHelper({ items: [publicItem ?? item] });
        },
        [storage, item]
    );

    const onCloudUsersActionClick = useCallback(() => {
        if (!item) {
            return;
        }

        sendGa('delete-all');
        if (IS_B2B_BIZ_USER && (!isPersonalAccessSharingEnabled || item.isFolder)) {
            dispatch(deleteAllUsers({ item }));
        } else {
            dispatch(weblinkDeleteAllEmailAccess());
        }
        setCloudUsersOpened(false);
    }, [dispatch, item]);

    const onChangeCloudUsers = useCallback(() => {
        // Если нет приглашенных пользователей - просто закрываем, без предупреждения
        if (!acList.length) {
            setCloudUsersOpened(!isCloudUsersOpened);
            return;
        }

        if (isCloudUsersOpened) {
            return;
        }

        setCloudUsersOpened(true);
    }, [acList.length, isCloudUsersOpened]);

    const onChangeWeblink = useCallback(() => {
        if (!item) {
            return;
        }

        if (isWeblinkOpened) {
            renderUnpublishWeblinkDialog({
                onActionClick: () => {
                    if (isCloudUsersOpened && isOwner && item.isFolder) {
                        onCloudUsersActionClick();
                    }
                    onActionClick();
                },
            });
        } else {
            sendGa('publish');

            dispatch(publishRequest({ item }));
        }
    }, [item, isWeblinkOpened, isCloudUsersOpened, isOwner, onActionClick, onCloudUsersActionClick, dispatch]);

    useEffect(() => {
        if (isWeblinkOpened && isOwner) {
            onChangeCloudUsers();
        }
    }, [isWeblinkOpened]);

    return {
        isCloudUsersOpened,
        isWeblinkOpened,
        setWeblinkOpened,
        setCloudUsersOpened,
        onActionClick,
        onChangeWeblink,
    };
};
