/* eslint-disable max-lines */
import { initAndLoadMyOffice } from 'Cloud/Application/Editor/MyOffice/myOffice.helpers';
import { MYOFFICE_VARIANTS } from 'Cloud/Application/Editor/MyOffice/myOffice.types';
import { initR7Wopi } from 'Cloud/Application/Editor/R7Wopi';
import { EditorID } from 'Cloud/Application/Editor/types';
import { initAndLoadR7 } from 'Cloud/Application/r7helpers';
import { type IR7Data, R7Theme } from 'Cloud/Application/types';
import classNames from 'clsx';
import { logger } from 'lib/logger';
import { xray } from 'lib/xray';
import React, {
    type Dispatch,
    type ReactElement,
    type SetStateAction,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getDocsView, getDocumentUrl } from 'reactApp/api/axios.corsapi';
import { callR7ViewDirectAPICall } from 'reactApp/api/r7/R7EmbeddedViewDirectAPICall';
import {
    IS_BIZ_USER,
    IS_MOBILE_BROWSER,
    IS_MY_TEAM,
    IS_ONPREMISE,
    IS_PUBLIC,
    IS_STOCK,
    VIEWERS_CONFIG,
} from 'reactApp/appHelpers/configHelpers';
import { isTemporaryAttachView, isWopiEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { R7_EMBEDDED } from 'reactApp/appHelpers/featuresHelpers/features/r7Embedded';
import { VIEWER_DOC_R7_TOOLBAR } from 'reactApp/appHelpers/featuresHelpers/features/viewerDocR7Toolbar';
import { getStockIdOfFile } from 'reactApp/modules/attaches/attaches.helpers';
import { type AttachesItem, EAttachTypes } from 'reactApp/modules/attaches/attaches.types';
import type { EditorItem } from 'reactApp/modules/editor/editor.types';
import {
    getFeatureMyOfficeForMailAttaches,
    getFeatureMyOfficeForMailWopi,
    getFeatureMyOfficeForPublicAndStock,
    getFeatureMyOfficeSendDetailRadars,
    getMyOfficeProgressOn,
} from 'reactApp/modules/features/features.selectors';
import type { PublicFile } from 'reactApp/modules/public/public.types';
import { getCurrentStorage, selectStatusPage } from 'reactApp/modules/router/router.selectors';
import { type CloudFile, type CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { EStatus } from 'reactApp/sections/ErrorPage/ErrorPage.types';
import type { RootState } from 'reactApp/store';
import { MobilePdfViewer } from 'reactApp/ui/ReactViewer/MobilePdfViewer/MobilePdfViewer';
import { OvidiusV2 } from 'reactApp/ui/ReactViewer/OvidiusV2/OvidiusV2';
import { CONTENT_LOAD_TIMEOUT } from 'reactApp/ui/ReactViewer/ReactViewer.constants';
import { ATTACHES_DOCS_URL, getDocViewData } from 'reactApp/ui/ReactViewer/ReactViewer.helpers';
import type { ViewerConfigType } from 'reactApp/ui/ReactViewer/ReactViewer.types';
import { useIframeState } from 'reactApp/ui/ReactViewer/ViewerDoc/hooks/useIframeState';
import { ProgressBar } from 'reactApp/ui/ReactViewer/ViewerDoc/ProgressBar/ProgressBar';
import { Spinner } from 'reactApp/ui/Spinner/Spinner';
import { sendStandardXray } from 'reactApp/utils/analytics';
import { StandardPrefix } from 'reactApp/utils/analytics/types';
import { sendDwh, sendXray } from 'reactApp/utils/ga';
import { DOC_PLACEHOLDER_ID, getMyOfficeIframeId, noop, normalizeSymbols } from 'reactApp/utils/helpers';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';
import { captureException } from 'reactApp/utils/tracer';
import { callWopiEmbeddedViewAPICall, callWopiViewAPICall } from 'reactApp/utils/wopiHelpers';
import { getAvailableEditorsFor } from 'server/helpers/editors/getEditors';
import { type ServerEditor, EditorMode } from 'server/helpers/editors/types';
import { useSwiper } from 'swiper/react';

import { shallowEqualMobile } from './helpers/shallowEqualMobile';
import styles from './ViewerDoc.css';

const rlog = 'cloud-viewer-doc';

const isPublicFile = (file: any): file is PublicFile => file?.storage === EStorageType.public;

interface Props {
    file: CloudFile;
    /** TODO: CLOUDWEB-16228 сохранить возможность использовать просмотрщики
     *  по умолчанию на аттачах
     */
    viewersConfig?: ServerEditor[];
    isEditable?: boolean;
    renderError: () => ReactElement;
    onSelectViewer?: (id: string, viewerConfig: ViewerConfigType) => void;
    onReady?: (props?: { skipSendAnalytics?: boolean }) => void;
    onOpen?: () => void;
    onError?: () => void;
    embedded?: boolean;
    isArchive?: boolean;
    sharingAttach?: boolean;
    enableViewerMenu?: boolean;
    isLocalDocAttaches?: boolean;
    attachDocUrl?: string;
    isMobile?: boolean;
    setContentForPrint?: (is: string, content: ArrayBuffer[] | null) => void;
    setWopiCondition?: Dispatch<SetStateAction<boolean>>;
}

const addThemeUrlParameter = (baseUrl: string, editorThemeValue: R7Theme) => {
    try {
        const url = new URL(baseUrl);
        url.searchParams.set('thm', editorThemeValue);
        return url.toString();
    } catch {
        return baseUrl;
    }
};

// eslint-disable-next-line max-lines-per-function, complexity
export const ViewerDocComponent: React.FC<Omit<Props, 'setContentForPrint'> & { viewerApi: ServerEditor }> = ({
    isEditable,
    file,
    renderError,
    onReady = noop,
    onError = noop,
    onOpen = noop,
    embedded = false,
    isArchive,
    sharingAttach = false,
    enableViewerMenu,
    isLocalDocAttaches = false,
    attachDocUrl = '',
    isMobile,
    viewerApi,
    setWopiCondition = noop,
}): ReactElement | null => {
    const [url, setUrl] = useState('');
    const [docState, setDocState] = useState({ isError: false, isLoaded: false });
    const [showMyOfficeProgressBar, setShowMyOfficeProgressBar] = useState(false);
    const [documentR7InitData, setDocumentR7InitData] = useState<IR7Data>();
    const [showStub, setShowStub] = useState(false);

    useEffect(() => {
        setDocState({ isError: false, isLoaded: false });
    }, [viewerApi]);

    const isR7Wopi = VIEWERS_CONFIG.some((viewer) => viewer.id === EditorID.R7_WOPI);
    const isWopiEnabled =
        useSelector(
            (state: RootState) => ViewerSelectors.isWopiEnabled(state, file.ext?.toLowerCase()) && !sharingAttach && !isLocalDocAttaches
        ) || isR7Wopi;

    const storage = useSelector(getCurrentStorage);
    const isAttach = storage === EStorageType.attaches;
    const isEmbedded = storage === EStorageType.embedded;

    const isMyOfficeForMailWopiFeature = useSelector(getFeatureMyOfficeForMailWopi) && isWopiEnabled && viewerApi.id === EditorID.MYOFFICE;
    const isCloudStock = (file as AttachesItem).attachType === EAttachTypes.cloudStock;

    const isMyOfficeForMailWopi = isAttach && isMyOfficeForMailWopiFeature;

    const routerStatus = useSelector(selectStatusPage);

    useEffect(() => {
        if (routerStatus === EStatus.SOMETHING_WRONG) {
            handleError();
        }
    }, [routerStatus]);

    const [docViewData, setDocViewData] = useState({
        url: null,
        post_params: {
            access_token: null,
            access_token_ttl: null,
        },
        post_content_type: null,
        isDocumentsViewWopiLoading: isMyOfficeForMailWopi,
    });

    const errorTimeout = useRef<number>();

    useEffect(() => {
        const { isError, isLoaded } = docState;

        if (isLoaded && !isError) {
            sendDwh({
                eventCategory: 'docs_viewer',
                label: 'open',
                dwhData: {
                    source: storage,
                    size_file: file.size,
                    is_edit: isEditable,
                    provider: viewerApi.id,
                    id_public: isPublicFile(file) ? file.weblink : undefined,
                    extension: file.ext,
                },
            });
        }
    }, [docState, storage, file, isEditable, viewerApi.id]);

    const iframeRef = useRef<HTMLIFrameElement>(null);
    const iframeWopiRef = useRef<HTMLIFrameElement>(null);
    const iframeAmrRef = useRef<HTMLIFrameElement>(null);

    const iframeDocsRef = useRef<HTMLIFrameElement>(null);

    const isMyOfficeForMail = useSelector(getFeatureMyOfficeForMailAttaches);
    const isMyOfficeForPublicAndStock = useSelector(getFeatureMyOfficeForPublicAndStock);
    const isMyOfficeProgressBar = useSelector(getMyOfficeProgressOn);
    const isMyOfficeSendDetailRadars = useSelector(getFeatureMyOfficeSendDetailRadars);

    const docId = DOC_PLACEHOLDER_ID + normalizeSymbols(file.name);

    const { activeIframe, setVisibilityListener } = useIframeState(setDocState, setDocViewData);

    // Отдельная фича для аттачей isMyOfficeForMailWopi - для остальных isWopiEnabled
    const wopiEnableCondition = isAttach ? isMyOfficeForMailWopi && isWopiEnabled : isWopiEnabled;
    const myOfficeVariant = wopiEnableCondition ? MYOFFICE_VARIANTS.wopi : MYOFFICE_VARIANTS.amr;

    setWopiCondition(wopiEnableCondition);

    const handleError = useCallback(() => {
        setDocState({
            isError: true,
            isLoaded: true,
        });
        onReady({ skipSendAnalytics: true });
        onError();
        xray.send('view-doc-error');
    }, [onError, onReady]);

    const initShowAmrIframe = useCallback(() => {
        if (iframeAmrRef.current) {
            iframeAmrRef.current.style.opacity = '1';
        }
    }, [iframeAmrRef]);

    const showMyOfficeIframe = useCallback(() => {
        setShowMyOfficeProgressBar(false);
        initShowAmrIframe();
    }, []);

    const setDocumentLoaded = () => {
        setDocState({
            isError: false,
            isLoaded: true,
        });

        onOpen();

        // Если включен прогресс-бар, он сам вызовет эту функцию с небольшой задержкой
        if (!isMyOfficeProgressBar && viewerApi.id === EditorID.MYOFFICE) {
            showMyOfficeIframe();
        }
    };

    const onDocumentReady = useCallback(() => {
        if (errorTimeout.current) {
            clearTimeout(errorTimeout.current);
        }
        setDocumentLoaded();

        if (!isWopiEnabled && viewerApi.id === EditorID.MYOFFICE) {
            setVisibilityListener();
        }

        if (isMyOfficeSendDetailRadars && viewerApi.id === EditorID.MYOFFICE) {
            /** Проверка на флаг для моего офиса(моб/десктоп)
             *
             *  {@link https://jira.vk.team/browse/CLOUDWEB-13905 Задача}
             */
            const deviceType = !isMobile ? 'desktop' : 'mobile';
            const viewerType = isWopiEnabled && viewerApi.wopi ? 'wopi' : 'amr';
            const mode = 'view';
            const ext = file.ext;

            /** Формат радара для моего офиса
             *    @example Пример.
             *    myoffice_open_<device_type>_<viewer_type>_<mode>_<ext>
             */
            xray.send(`myoffice_open_${deviceType}_${viewerType}_${mode}_${ext}`);
        }
    }, []);

    const handlePostMessage = useCallback(
        (event) => {
            /**
             * На window может быть навешено несколько обработчиков
             * TODO: так надо сделать потом для всех типов viewerApi
             */
            if (viewerApi.id === EditorID.DOCS_MAIL && iframeRef?.current?.contentWindow !== event.source) {
                return;
            }

            // Убираем обработк для r7wopi, так как обрабатываем внутри функции initR7Wopi
            if (viewerApi.id === EditorID.R7_WOPI) {
                return;
            }

            // TODO: это для МойОфис? Почему условие только isAttach
            if (isAttach && !(viewerApi.id === EditorID.DOCS_MAIL)) {
                const { data } = event;

                if (!data || typeof data !== 'object') {
                    return;
                }

                const { type, docViewData } = data;

                if (type === 'documentsViewUrl') {
                    if (viewerApi.id === EditorID.R7) {
                        if (!docViewData.r7editor) {
                            xray.send('view-doc-r7-view-error');
                            setShowStub(true);
                        }
                        setDocumentR7InitData(docViewData.r7editor);
                    } else {
                        setDocViewData({
                            isDocumentsViewWopiLoading: false,
                            ...docViewData,
                        });
                    }
                    setDocumentLoaded();
                    clearTimeout(errorTimeout.current);
                    xray.send('view-doc-get-url');
                }

                if (type === 'ready') {
                    const useWopi = viewerApi.id !== EditorID.R7;
                    const docsData = getDocViewData(file as AttachesItem, useWopi);

                    if (viewerApi.id === EditorID.R7) {
                        iframeDocsRef?.current?.contentWindow?.postMessage(
                            {
                                type: 'docsData',
                                docsData: {
                                    login: docsData.login,
                                    o2_host: docsData.o2_host,
                                    corsapi_host: docsData.corsapi_host,
                                    id: docsData.id,
                                    name: docsData.name,
                                    content_type: docsData.content_type,
                                    viewer: 'r7api',
                                },
                            },
                            `${window.location.origin}${ATTACHES_DOCS_URL}`
                        );
                    } else {
                        iframeDocsRef?.current?.contentWindow?.postMessage(
                            {
                                type: 'docsData',
                                docsData,
                            },
                            `${window.location.origin}${ATTACHES_DOCS_URL}`
                        );
                    }
                }

                return;
            }

            const data = String(event.data);
            const message = data.split(' ')[0];

            switch (message) {
                case 'app.started':
                case 'loaded':
                    break;

                case 'rendered': {
                    if (errorTimeout.current) {
                        clearTimeout(errorTimeout.current);
                    }

                    if (viewerApi.id === EditorID.DOCS_MAIL) {
                        setDocumentLoaded();
                    }

                    xray.send('view-doc-pm-rndrd', {
                        i: viewerApi.id,
                    });
                    break;
                }

                case 'ms-success':
                    onReady({ skipSendAnalytics: false });
                    break;

                case 'fail':
                case 'status':
                    clearTimeout(errorTimeout.current);
                    setDocState({
                        isError: true,
                        isLoaded: false,
                    });
                    onReady({ skipSendAnalytics: true });
                    xray.send(`view-doc-pm-err_${message}`, {
                        i: viewerApi.id,
                        rlog,
                        rlog_message: {
                            error: 'post message err',
                            viewer: viewerApi.id,
                            data,
                        },
                    });
                    break;
            }
        },
        [errorTimeout, isAttach, viewerApi]
    );

    const getDocumentViewUrl = useCallback(async (): Promise<[string, Record<string, string>, IR7Data | null]> => {
        let item: CloudItem | { id: string; isStock: boolean } = file;
        if ('attachType' in item && item.attachType === EAttachTypes.cloudStock) {
            item = {
                id: getStockIdOfFile(item.id, file.name),
                isStock: true,
            };
        }

        if (viewerApi.id === EditorID.DOCS_MAIL) {
            if (url) {
                return Promise.resolve([url, {}, null]);
            }

            if (!item) {
                return Promise.reject();
            }

            const editorUrl = 'editors' in item ? item.editors?.docs?.url : null;
            const res = await getDocsView(editorUrl);
            return [res?.body?.url, {}, null];
        }

        if (!viewerApi.get_view_url) {
            return Promise.reject();
        }

        let wopiData;

        if (isWopiEnabled && viewerApi.wopi) {
            try {
                if (isMyOfficeForMailWopi) {
                    const { url, post_params, post_content_type } = docViewData;

                    if (url && post_params && post_content_type) {
                        xray.send('got-all-wopi-fields');
                    } else {
                        xray.send('got-wopi-fields-error', {
                            rlog: 'got-wopi-fields-error',
                            rlog_message: {
                                url,
                                post_params,
                                post_content_type,
                            },
                        });
                    }

                    wopiData = {
                        url,
                        access_token: post_params?.access_token,
                        access_token_ttl: post_params?.access_token_ttl,
                        post_content_type,
                    };
                }

                // cloud-stock аттачи не показываем в просмотрщике, поэтому не надо ходить в вопи
                if (!isAttach && !isCloudStock && !isEmbedded) {
                    const { data, status } = await callWopiViewAPICall(item.id, viewerApi.id);
                    wopiData = {
                        ...data,
                        status,
                    };
                }

                if (isEmbedded) {
                    const { name, url } = file || {};

                    const { data, status } = await callWopiEmbeddedViewAPICall(
                        {
                            name,
                            email: 'anonym',
                            url: url?.view || '',
                        },
                        viewerApi.id
                    );

                    wopiData = { ...data, status };
                }
            } catch (error) {
                if (isMyOfficeForMailWopi) {
                    xray.send('attach-wopi-data-error', {
                        rlog: 'attach-wopi-data-error',
                        rlog_message: {
                            error,
                        },
                    });
                }

                logger.error(error);
                captureException(error, { issueKey: 'ViewerDoc exc' });
                return Promise.reject();
            }
        }

        const { name: fileName, url: fileUrl } = file || {};
        let r7InitData: IR7Data | null = null;

        if (viewerApi.id === EditorID.R7 && isEmbedded && R7_EMBEDDED) {
            try {
                const viewDirectR7res = await callR7ViewDirectAPICall({
                    name: fileName,
                    direct_url: fileUrl?.view || '',
                    user_id: 'anonym',
                });

                r7InitData = viewDirectR7res.data.r7editor;
            } catch (error) {
                logger.error(error);
                captureException(error, { issueKey: 'ViewerDoc exc' });
                return Promise.reject();
            }
        }

        let viewUrl = await viewerApi.get_view_url(item, storage, wopiData);
        if (IS_MY_TEAM) {
            viewUrl = addThemeUrlParameter(viewUrl, isDarkThemeModeEnabled() ? R7Theme.dark : R7Theme.light);
        }

        return [viewUrl, wopiData, r7InitData];
    }, [file, isAttach, isEmbedded, viewerApi, url, docViewData]);

    const getMyOfficeIframe = useCallback(
        (myOfficeVariant: MYOFFICE_VARIANTS) => {
            const id = getMyOfficeIframeId(docId, myOfficeVariant);
            const ref = myOfficeVariant === MYOFFICE_VARIANTS.wopi ? iframeWopiRef : iframeAmrRef;

            return (
                <iframe
                    id={id}
                    name={id}
                    src={url}
                    allowFullScreen
                    frameBorder={0}
                    className={styles.doc}
                    onError={handleError}
                    ref={ref}
                    {...(isWopiEnabled && { onLoad: setVisibilityListener })}
                />
            );
        },
        [iframeAmrRef, iframeWopiRef]
    );

    // Урл документа загрузился - отображаем в просмотрщике
    // eslint-disable-next-line max-lines-per-function
    useEffect(() => {
        let destroyR7Wopi: () => void = noop;
        let onLoadIframe: undefined | (() => Promise<void>) | (() => void) = noop;

        if (!viewerApi.id) {
            sendXray('try-show-notype');
            handleError();
            return;
        }

        // Для wopi варианта моего офиса нужно ходить по особой схеме
        // Иначе это может привести к попытке открыть MS
        if (attachDocUrl && !isMyOfficeForMailWopiFeature) {
            getDocumentUrl({ url: attachDocUrl })
                .then((url) => {
                    // todo: заглушка, пока не поддержали мой офис в архивах
                    if (viewerApi.id === EditorID.MYOFFICE) {
                        sendXray('try-show-arch-myof');
                        handleError();
                        return;
                    }

                    setUrl(url);
                    setDocState({
                        isError: false,
                        isLoaded: true,
                    });
                })
                .catch(handleError);
        } else {
            if (isMyOfficeForMailWopi && docViewData.isDocumentsViewWopiLoading) {
                // ждем пока подгрузится инфа для wopi
                return;
            }
            if (isMyOfficeForMailWopi && !docViewData.isDocumentsViewWopiLoading && !docViewData.url) {
                xray.send('try-load-wopi');
                handleError();
                return;
            }

            getDocumentViewUrl()
                .then(([viewUrl, wopiData, r7InitData]) => {
                    if (viewUrl.length > 0) {
                        if (viewerApi.id === EditorID.MYOFFICE) {
                            const isCloudStock = (file as AttachesItem).attachType === EAttachTypes.cloudStock;
                            const isTemporary = (file as AttachesItem).attachType === EAttachTypes.temporary;

                            if ((isCloudStock && !isLocalDocAttaches && !IS_ONPREMISE) || (isTemporary && !isTemporaryAttachView)) {
                                xray.send('try-show-temp-myof');
                                handleError();
                                return;
                            }

                            if ((IS_PUBLIC || IS_STOCK) && !isMyOfficeForPublicAndStock) {
                                xray.send('try-show-cloud-myof');
                                handleError();
                                return;
                            }

                            setShowMyOfficeProgressBar(true);

                            if (!docState.isLoaded) {
                                const wopiParams = {
                                    accessParams: {
                                        access_token: wopiData?.access_token,
                                        access_token_ttl: wopiData?.access_token_ttl,
                                    },
                                    postContentType: wopiData?.post_content_type,
                                    url: viewUrl,
                                };

                                const amrParams = {
                                    item: file as EditorItem,
                                    initShowAmrIframe,
                                };

                                const listeners = {
                                    onReady: onDocumentReady,
                                    onError: handleError,
                                };

                                if (amrParams.item.ext === 'csv') {
                                    setShowMyOfficeProgressBar(false);
                                }

                                onLoadIframe = initAndLoadMyOffice({
                                    iframeAmr: iframeAmrRef.current,
                                    iframeWopi: iframeWopiRef.current,
                                    isMyOfficeForMail: isMyOfficeForMail && isAttach,
                                    isEmbedded,
                                    sharingAttach,
                                    isLocalDocAttaches,
                                    wopiParams,
                                    amrParams,
                                    myOfficeVariant,
                                    listeners,
                                    item: file as EditorItem,
                                });
                            }
                        } else if (viewerApi.id === EditorID.R7) {
                            if (!isAttach || (isAttach && documentR7InitData) || (isEmbedded && r7InitData)) {
                                let initData;

                                if (isAttach && documentR7InitData) {
                                    xray.send('viewer-r7-attach-init');
                                    initData = documentR7InitData;
                                } else if (isEmbedded && r7InitData) {
                                    xray.send('viewer-r7-embedded-init');
                                    initData = r7InitData;
                                } else {
                                    xray.send('viewer-r7-init');
                                }

                                initAndLoadR7(viewUrl, {
                                    r7InitData: initData,
                                    isAttach,
                                    placeholderId: iframeRef.current?.id ?? docId,
                                    container: document.body,
                                    onDocumentReady,
                                    onError() {},
                                });
                            }
                        } else if (viewerApi.id === EditorID.DOCS_MAIL) {
                            setUrl(viewUrl);
                        } else if (viewerApi.id === EditorID.R7_WOPI && iframeRef.current) {
                            destroyR7Wopi = initR7Wopi(file, {
                                wopiParams: {
                                    accessParams: {
                                        access_token: wopiData?.access_token,
                                        access_token_ttl: wopiData?.access_token_ttl,
                                    },
                                    postContentType: wopiData?.post_content_type,
                                    url: viewUrl,
                                },
                                mode: EditorMode.VIEW,
                                iframe: iframeRef.current,
                                container: document.body,
                                events: {
                                    onDocumentReady,
                                    onError,
                                },
                            });
                        } else {
                            setUrl(viewUrl);
                            setDocumentLoaded();
                        }

                        xray.send('view-doc-loaded');
                    }
                })
                .catch(handleError);
        }

        return () => {
            if (onLoadIframe !== undefined) {
                iframeRef?.current?.removeEventListener('load', onLoadIframe as EventListenerOrEventListenerObject);
            }
            destroyR7Wopi();
        };
    }, [
        file,
        handleError,
        onDocumentReady,
        viewerApi,
        isAttach,
        isEmbedded,
        sharingAttach,
        isLocalDocAttaches,
        isMyOfficeForMail,
        attachDocUrl,
        getDocumentViewUrl,
        docViewData,
        activeIframe,
        documentR7InitData,
    ]);

    useEffect(() => {
        if (isMyOfficeForMailWopi) {
            xray.send('myoffice-attach-switch-on');
        }

        if (errorTimeout.current) {
            clearTimeout(errorTimeout.current);
        }

        errorTimeout.current = window.setTimeout(() => {
            xray.send('view-doc-timeout', {
                i: viewerApi.id,
                rlog,
                rlog_message: {
                    error: 'timeout',
                    viewer: viewerApi.id,
                },
            });
            handleError();
        }, CONTENT_LOAD_TIMEOUT);

        window.addEventListener?.('message', handlePostMessage);

        return () => {
            window.removeEventListener?.('message', handlePostMessage);
            if (errorTimeout.current) {
                clearTimeout(errorTimeout.current);
            }
        };
    }, [url, handleError, errorTimeout]);

    const { isError, isLoaded } = docState;

    if (showStub || isError || (IS_BIZ_USER && (sharingAttach || isLocalDocAttaches))) {
        return renderError();
    }

    const isMyOfficeProgressCondition =
        viewerApi.id === EditorID.MYOFFICE && isMyOfficeProgressBar && showMyOfficeProgressBar && !isWopiEnabled; // Для серверного моего офиса не нужен прогресс бар

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_embedded]: embedded,
                [styles.root_archive]: isArchive,
                [styles.root_menu]: enableViewerMenu,
                [styles.root_mobile]: isMobile,
            })}
        >
            {isMyOfficeProgressCondition && <ProgressBar docLoaded={isLoaded} error={false} closeFunc={showMyOfficeIframe} />}

            {(Boolean(url) ||
                /* R7 и myOffice рендерит сам в DOC_PLACEHOLDER_ID  */
                viewerApi.id === EditorID.R7) && (
                <div
                    className={classNames(styles.iframeWrapper, {
                        [styles.iframeWrapper_r7]: viewerApi.id === EditorID.R7 && !VIEWER_DOC_R7_TOOLBAR,
                        [styles.iframeWrapper_r7_withToolbar]: viewerApi.id === EditorID.R7 && VIEWER_DOC_R7_TOOLBAR,
                        [styles.iframeWrapper_hidden]: viewerApi.id === EditorID.DOCS_MAIL && !isLoaded && !isError,
                    })}
                >
                    <iframe
                        id={docId}
                        name={docId}
                        src={url}
                        allowFullScreen
                        frameBorder={0}
                        className={classNames(styles.doc, {
                            [styles.docMobile]: isMobile,
                            [styles.iframeDocsMail]: viewerApi.id === EditorID.DOCS_MAIL,
                        })}
                        onError={handleError}
                        ref={iframeRef}
                    />
                </div>
            )}

            {viewerApi.id === EditorID.MYOFFICE && activeIframe && (
                <div className={classNames(styles.doc, { [styles.docMobile]: isMobile })}>
                    {myOfficeVariant === MYOFFICE_VARIANTS.wopi && getMyOfficeIframe(MYOFFICE_VARIANTS.wopi)}
                    {getMyOfficeIframe(MYOFFICE_VARIANTS.amr)}
                </div>
            )}

            {viewerApi.id === EditorID.R7_WOPI && activeIframe && (
                <div className={styles.doc}>
                    <iframe
                        id={docId}
                        name={file.name}
                        src={url}
                        allowFullScreen
                        frameBorder={0}
                        className={styles.doc}
                        onError={handleError}
                        ref={iframeRef}
                    />
                </div>
            )}

            {!url && !isLoaded && <div>{!isMyOfficeProgressCondition && !activeIframe && <Spinner />}</div>}

            {((isMyOfficeForMailWopi && docViewData.isDocumentsViewWopiLoading && viewerApi.wopi) ||
                (isAttach && viewerApi.id === EditorID.R7)) && <iframe ref={iframeDocsRef} frameBorder={0} src={ATTACHES_DOCS_URL} />}
        </div>
    );
};

export const ViewerDoc = memo<Props>(
    (props) => {
        const {
            file,
            isMobile,
            setContentForPrint,
            viewersConfig: _viewersConfig,
            onError = noop,
            renderError,
            sharingAttach,
            enableViewerMenu,
        } = props;
        /** TODO: CLOUDWEB-16228 сохранить возможность использовать просмотрщики
         *  по умолчанию на аттачах
         */
        const viewersConfig = _viewersConfig?.length ? _viewersConfig : VIEWERS_CONFIG;
        const swiper = useSwiper();
        const storage = useSelector(getCurrentStorage);
        const ovidiusZoom = useSelector((state: RootState) => ViewerSelectors.getOvidiusZoom(state, file?.id));
        const [viewerIndex, setViewerIndex] = useState(0);
        const [isWopiConditionEnabled, setIsWopiConditionEnabled] = useState<boolean>(false);

        const viewers = useMemo(
            () => getAvailableEditorsFor({ item: file, editors: viewersConfig, isEdit: false, storage, isWopiEnabled }),
            [file, storage, viewersConfig]
        );
        const viewerApi = useMemo(() => viewers[viewerIndex], [viewers, viewerIndex]);

        useEffect(() => {
            props.onSelectViewer?.(file.id, { id: viewerApi?.id, wopi: isWopiConditionEnabled });
        }, [props.onSelectViewer, viewerApi, isWopiConditionEnabled]);

        const handleError = useCallback(
            (error: string) => {
                setViewerIndex((item) => item + 1);
                sendStandardXray({
                    prefix: StandardPrefix.DOC_FB,
                    event: error,
                    file,
                    storage,
                });
                onError();
            },
            [file, storage, onError]
        );

        const handleDocError = useCallback(() => {
            handleError('docs-iframes');
        }, [handleError]);

        if (viewerApi?.id === EditorID.OVIDIUS_V2 && !isMobile && !sharingAttach) {
            return (
                <OvidiusV2
                    scale={ovidiusZoom}
                    file={file}
                    storage={storage}
                    enableViewerMenu={enableViewerMenu}
                    setContentForPrint={setContentForPrint}
                    onError={handleError}
                />
            );
        }

        if (viewerApi?.id === EditorID.OVIDIUS_V2 && isMobile && !sharingAttach) {
            return (
                <MobilePdfViewer
                    item={file}
                    storage={storage}
                    onShow={() => {
                        swiper?.zoom?.disable();
                    }}
                    onClose={() => {
                        swiper?.zoom?.enable();
                    }}
                    onError={handleError}
                />
            );
        }

        if (!viewerApi) {
            return renderError();
        }

        return (
            <ViewerDocComponent
                key={viewerIndex}
                {...props}
                onError={handleDocError}
                viewerApi={viewerApi}
                setWopiCondition={setIsWopiConditionEnabled}
            />
        );
    },
    IS_MOBILE_BROWSER ? shallowEqualMobile : shallowEqual
);

ViewerDoc.displayName = 'ViewerDoc';

ViewerDoc.whyDidYouRender = true;
