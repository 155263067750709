import { useSelector } from 'react-redux';
import { PROMO_TARIFFS } from 'reactApp/appHelpers/configHelpers';
import { abHeavyFileTrial } from 'reactApp/appHelpers/featuresHelpers/features/heavyFileTrial';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { EProductPeriod } from 'reactApp/modules/products/products.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import type { RootState } from 'reactApp/store';
import type { Product } from 'reactApp/types/Billing';

import { type TariffInfo, EShowTariffType } from '../UploadBigFileDialog.types';

export const useTariff = (fileSizeBytes: number): TariffInfo => {
    const { isFull: isOverQuota } = useSelector(UserSelectors.getCloudSpace);

    // tempexp_17995-next-line
    const trialTariff = abHeavyFileTrial ? PROMO_TARIFFS?.heavyFileTrial : PROMO_TARIFFS?.default128GbTrial;

    // TODO: если id невалидный, то product будет undefined и всё посыпется. Стоит предусмотреть и убрать as Product
    const trial = useSelector((state: RootState) => ProductsSelectors.getProductById(state, trialTariff)) as Product;
    const { product } = useSelector((state: RootState) =>
        ProductsSelectors.getProductAndTariffByQuota(state, fileSizeBytes, EProductPeriod.year, false)
    );
    const isPaidUser = useSelector(UserSelectors.isPaidUser);

    // если в квоту триала помещаются загружаемые файлы, пользователей не платник,
    // не в оверквоте и ему доступен триальный тариф, то показываем его
    const isTrialUser = !isPaidUser && !isOverQuota && Boolean(trial) && fileSizeBytes < trial?.space.original;

    return {
        trial,
        usualProduct: product,
        showTariffType: isTrialUser ? EShowTariffType.TRIAL : EShowTariffType.USUAL_TARIFF,
    };
};
