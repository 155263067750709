import { logger } from 'lib/logger';
import { weblinksIncomingApiCall } from 'reactApp/api/weblinks/WeblinksIncomingApiCall';
import { getIncomingPublicCursor } from 'reactApp/modules/incomingPublic/incomingPublic.selectors';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { getLoadMoreLimit } from '../storage/storage.helpers';
import { EStorageType } from '../storage/storage.types';
import { loadIncomingPublicRequest, loadIncomingPublicSuccess, loadMoreIncomingPublicRequest } from './incomingPublic.module';
import type { LoadIncomingPublicOpts } from './incomingPublic.types';

const LOAD_LIMIT = getLoadMoreLimit(EStorageType.sharedIncoming);

const getIncoming = async ({ limit = LOAD_LIMIT, cursor = '', exts = '' }: LoadIncomingPublicOpts) =>
    (await weblinksIncomingApiCall({ limit, cursor, exts })).data;

function* handleLoadIncomingPublic() {
    try {
        const data = yield call(getIncoming, { limit: LOAD_LIMIT, cursor: '', exts: '' });
        yield put(loadIncomingPublicSuccess(data));
    } catch (error) {
        logger.error(error);
    }
}

function* handleLoadMoreIncomingPublic() {
    const cursor = yield select(getIncomingPublicCursor);

    try {
        const data = yield call(getIncoming, { limit: LOAD_LIMIT, cursor, exts: '' });
        yield put(loadIncomingPublicSuccess(data));
    } catch (error) {
        logger.error(error);
    }
}

export function* watchIncomingPublic() {
    yield takeEvery(loadIncomingPublicRequest.toString(), handleLoadIncomingPublic);
    yield takeEvery(loadMoreIncomingPublicRequest.toString(), handleLoadMoreIncomingPublic);
}
