import axios from 'lib/axios';
import getBase58Id from 'lib/getBase58Id';
import { APIConfig } from 'reactApp/api/APIConfig';
import { SUCCESS_HTTP_CODES } from 'reactApp/api/apiConstants';
import { CancelError } from 'reactApp/errors/clientErrors/CancelError';
import { JavaScriptError } from 'reactApp/errors/clientErrors/JavaScriptError';
import { ConnectionError } from 'reactApp/errors/serverErrors/ConnectionError';
import { HttpError } from 'reactApp/errors/serverErrors/HttpError';
import { UserError } from 'reactApp/errors/serverErrors/UserError';
import { UserNoAuthError } from 'reactApp/errors/serverErrors/UserNoAuthError';
import { WrongResponseError } from 'reactApp/errors/serverErrors/WrongResponseError';
import { sendGa, sendKaktamLog } from 'reactApp/utils/ga';

const isResponseFormatCorrect = (response) => {
    let isCorrect = false;
    const { headers = {} } = response;

    if (headers['x-req-id'] && headers['x-server']) {
        isCorrect = true;
    } else {
        console.error('wrong response format: no x headers.');
        sendGa('api', 'badformat', 'headers');
    }

    return isCorrect;
};

const HTTPS = 'https://';
const isExternalRequest = (config) => config && (config.baseURL.startsWith(HTTPS) || config.url.startsWith(HTTPS));

const requestSuccess =
    (version = 3) =>
    (request) => {
        /* //TODO: move it to v2
    request[request.method === 'get' ? 'params' : 'data'] = {
        ...request[request.method === 'get' ? 'params' : 'data'],

        token: APIConfig.get('csrf-token'),
        'x-email': APIConfig.get('user'),
        api: 3,
        'x-page-id': APIConfig.get('x-page-id')
    };*/

        if (!isExternalRequest(request)) {
            request.headers = {
                ...request.headers,
                'X-CSRF-Token': APIConfig.get('csrf-token'),
                'X-Email': APIConfig.get('user'),
                'X-API-Version': version,
                'X-Page-Id': APIConfig.get('x-page-id'),
                'X-Req-Id': getBase58Id(8),
            };
        }

        return request;
    };

function requestError(error) {
    return Promise.reject(error);
}

function getRequestInfo(response) {
    const { headers = {}, url, baseURL, responseType } = response.config;
    const apiUrl = url.replace(baseURL, '');
    const requestId = headers['X-Req-Id'];

    return {
        requestId,
        url,
        apiUrl,
        status: response.status || 0,
        responseData: response.data,
        user: APIConfig.get('user'),
        responseType,
    };
}

const processResponse =
    (version = 3) =>
    (response) => {
        const kaktamFileName = `api-v${version}`;
        let errorConf = {};

        return new Promise((resolve, reject) => {
            const isCanceled = axios.isCancel(response);

            if (isCanceled) {
                throw new CancelError({ source: 'api' });
            }

            if (response instanceof Error && response.response) {
                response = response.response;
            }

            const { requestId, status, url, user, responseData, responseType } = getRequestInfo(response);

            errorConf = {
                source: 'api',
                requestUrl: url,
                pageId: APIConfig.get('x-page-id'),
                status,
                id: requestId,
                user,
                response: responseData ?? {},
                version,
                responseType,
            };

            let error = false;

            if (status === ConnectionError.CONNECTION_FAILURE) {
                error = new ConnectionError(errorConf);
            } else if (!isExternalRequest(response.config) && !isResponseFormatCorrect(response)) {
                error = new WrongResponseError(errorConf);
            } else if (status === 400) {
                error = new UserError(errorConf, response.headers);
            } else if (status === 403) {
                error = new UserNoAuthError(errorConf);
            } else if (!SUCCESS_HTTP_CODES.includes(status)) {
                error = new HttpError(errorConf);
            }

            if (error) {
                sendKaktamLog({ ...error, apiUrl: errorConf.requestUrl, respData: errorConf.response }, kaktamFileName);

                reject(error);
                return;
            }

            resolve(response);
        }).catch((err) => {
            if (err instanceof Error) {
                const error = new JavaScriptError({ ...errorConf, error: err });
                sendKaktamLog({ ...error, apiUrl: errorConf.requestUrl, respData: errorConf.response }, kaktamFileName);

                throw error;
            } else {
                throw err;
            }
        });
    };

export const getHandlersFactory = (version = 3) => ({
    responseSuccess: processResponse(version),
    responseError: processResponse(version),
    requestSuccess: requestSuccess(version),
    requestError,
});
