import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';

import type { Props } from './UsedSpacePostpaidModalThanks.types';

export const renderUsedSpacePostpaidModalThanks = (data: Props) => {
    openPopupHelper({
        popupName: popupNames.USED_SPACE_POSTPAID_THANKS_MODAL,
        data: {
            ...data,
            onCloseClick: data.onClose,
        },
    });
};
