import store from 'lib/store';
import { USER_EMAIL } from 'reactApp/appHelpers/configHelpers';
import { noAuthUser } from 'reactApp/modules/user/userStorage';

function storeKey(videoName: string, publicId: string) {
    return `${USER_EMAIL || noAuthUser}|${publicId}|${videoName}`;
}

/**
 * Получает время воспроизведения видео из localStorage
 * @param videoName - имя видеофайла
 * @param publicId - id паблика
 * @returns время воспроизведения видео
 */
export function getVideoPlayTime(videoName: string, publicId: string): number | undefined {
    return store.get(storeKey(videoName, publicId));
}

/**
 * Сохраняет время воспроизведения видео в localStorage
 * @param videoName - имя видеофайла
 * @param publicId - id паблика
 * @param time - время воспроизведения видео
 */
export function storeVideoPlayTime(videoName: string, publicId: string, time: number) {
    return store.set(storeKey(videoName, publicId), time);
}
