import type { UflrType } from 'lib/uflr';
import type { DomainAccess } from 'reactApp/components/SharingWindow/Sharing.types';
import type { WeblinkDownloadable } from 'reactApp/modules/public/public.types';
import type { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { AccessRights, Kind, SubKind, Url, VirusScan, Weblink } from 'reactApp/types/Tree';

export enum FeedCategoryType {
    all = 'all',
    image = 'image',
    document = 'document',
    audio = 'audio',
    video = 'video',
}

export interface FeedCategory {
    name: string;
    id: FeedCategoryType;
    value: FeedCategoryType;
    childs: string[];
    cursor: string;
    isLoaded: boolean;
    isLoading: boolean;
    hasMoreToLoad: boolean;
}

export interface FeedItemBase extends WeblinkDownloadable {
    id: string;
    isFolder: false;
    isInFavorites: boolean;
    parent: string;
    ext: string;
    nameWithoutExt: string;
    name: string;
    home: string;
    size: number;
    kind: Kind;
    subKind: SubKind;
    url: Url;
    weblink?: string;
    weblinkAccessRights?: AccessRights;
    weblinkAutoDelete?: boolean;
    weblinkSeoEnabled?: boolean;
    weblinkEnabledDisabledDownload?: boolean;
    weblinkExpires?: number;
    ctime?: number;
    expiresTimeSize?: number;
    weblinkDomestic?: boolean;
    weblinkDomainAccess?: DomainAccess;
    mtime: number;
    hash: string;
    __reduxTree?: boolean;
    thumbnails?: {
        [key: string]: string;
    };
    virusScan: VirusScan;
    isReadOnly: boolean;
    uflr?: UflrType;
    intraDomainShare?: boolean;
}

export interface FeedItem extends FeedItemBase {
    storage: EStorageType.feed;
}

export interface FeedState {
    categories: FeedCategory[];
    currentCategory: FeedCategoryType;
    // eslint-disable-next-line @typescript-eslint/ban-types
    list: {};
}

export interface FeedItemApiResponse {
    hash: string;
    mtime: number;
    name: string;
    home: string;
    size: number;
    favorite: number | boolean;
    public?: Weblink;
    virus_scan: VirusScan;
    uflr?: UflrType;
}

export interface FeedApiResponse {
    count: number;
    cursor?: string;
    objects: FeedItemApiResponse[];
}

export interface FeedLoadSuccessAction {
    category: FeedCategoryType;
    data: FeedApiResponse;
    mountedFoldersIds?: string[] | null;
}

export interface ChangeFeedCategoryAction {
    category: FeedCategoryType;
}

export interface FeedLoadRequestAction {
    category: FeedCategoryType;
}

export interface FeedLoadMoreSuccessAction {
    data: FeedApiResponse;
    category: FeedCategoryType;
    mountedFoldersIds?: string[] | null;
}

export interface SharedFolderApiResponse {
    list: SharedFolderItemApiResponse[];
}

export interface SharedFolderItemApiResponse extends FeedItemApiResponse {
    access: string;
    home: string;
    name: string;
    owner: {
        email: string;
        name: string;
    };
    size: number;
    tree: string;
}
