// CLOUDWEB-17843 Тест CTA в приветственном баннере
import { getFeature, isFeature } from 'Cloud/Application/FeaturesEs6';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

const welcomeTrialBannerAbGroup: string | undefined = getFeature('ab-welcome-trial-banner-ab-group-name');

registerExperiment('welcome-trial-cta-change', welcomeTrialBannerAbGroup, [
    AnalyticEventNames.WELCOME_TRIAL_BANNER_SHOW,
    AnalyticEventNames.WELCOME_TRIAL_BANNER_CLICK,
    AnalyticEventNames.WELCOME_TRIAL_BANNER_BUY,
]);

export const welcomeTrialBannerTrialCtaText: string = getFeature('ab-welcome-trial-banner-trial-cta-text') || 'Попробовать бесплатно';

export const welcomeTrialBannerFastchekout: boolean = isFeature('ab-welcome-trial-banner-fastcheckout');

export const welcomeTrialBannerSpecialInfoText: string | undefined = getFeature('ab-welcome-trial-banner-trial-info-text');
