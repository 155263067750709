import classNames from 'clsx';
import { ReactComponent as MailIcon } from 'img/confirm-email/send-mail.svg';
import React, { type ReactElement, PureComponent } from 'react';
import { connect } from 'react-redux';
import { getEmail } from 'reactApp/modules/socialUser/socialUser.selectors';
import { Button, ButtonVariant } from 'reactApp/ui/Button/Button';
import { noop } from 'reactApp/utils/helpers';

import styles from './Step.css';

interface Props {
    email: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onButtonClick: Function;
    isInsideDialog?: boolean;
}

const mapStateToProps = (state): { email: string } => ({
    email: getEmail(state),
});

export class FinalStepComponent extends PureComponent<Props> {
    public static defaultProps: Props = {
        onButtonClick: noop,
        email: '',
    };

    private handleClick = (): void => {
        this.props.onButtonClick();
    };

    public render(): ReactElement {
        return (
            <div className={classNames(styles.step)}>
                <div className={styles.icon}>
                    <MailIcon width={184} height={175} />
                </div>
                <div className={styles.title}>Письмо отправлено</div>
                <div className={styles.text}>
                    На адрес <span className={styles.email}>{this.props.email}</span> отправлено письмо.
                    <br />
                    Откройте его и нажмите кнопку, чтобы потвердить ваш email.
                </div>
                <div className={styles.control}>
                    <div>
                        <Button
                            fluid
                            size="big"
                            theme="white"
                            variant={ButtonVariant.contrast}
                            onClick={this.handleClick}
                            className={classNames(styles.button, styles.buttonContrast, {
                                [styles.dialog]: this.props.isInsideDialog,
                            })}
                        >
                            Перейти в Облако
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export const FinalStep = connect(mapStateToProps)(FinalStepComponent);
