import 'video.js/dist/video-js.min.css';

import hotkeys from 'hotkeys-js';
import React, { type FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMinWidthBreakpoint } from 'reactApp/hooks/responsiveness/useMinWidthBreakpoint';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import type { Kind } from 'reactApp/types/Tree';
import { hotKeysVideoPlayerScope } from 'reactApp/ui/ReactViewer/ReactViewer.constants';
import type { IViewerDwhData } from 'reactApp/ui/ReactViewer/ReactViewer.helpers';
import { Stub } from 'reactApp/ui/ReactViewer/Stub/Stub';
import videojs from 'video.js';

import hlsQualitySelector, { type QualityValue } from './hlsQualitySelector/plugin';
import loopPlugin from './LoopPlugin/plugin';
import { errorsMap, updateControlBarButtons, useVideoPlayer } from './useVideoPlayer';
import styles from './VideoPlayer.css';
import type { VideoErrorHandler, VideoMetaData, VideoPlayerWithPlugins } from './VideoPlayer.types';

export interface Props {
    streamUrl?: string;
    nativeUrl: string;
    posterUrl: string;
    kind: Kind;
    ext: string;
    name: string;
    id?: string;
    isVisible?: boolean;
    autoplay?: boolean;
    controls?: boolean;
    startQuality?: QualityValue;
    size?: number;
    isArchive?: boolean;
    isAttaches?: boolean;
    isPaused?: boolean;
    onCanPlay?: () => void;
    onWaiting?: () => void;
    onLoadStart?: () => void;
    onLoadedMetaData?: (data: VideoMetaData) => void;
    onNavigateLeft?: () => void;
    onNavigateRight?: () => void;
    onClose?: () => void;
    onError?: VideoErrorHandler;
    onDelete?: () => void;
    sendRadar?: (data: IViewerDwhData) => void;
}

const RESPONSIVE_PLAYER_BUTTONS_BREAKPOINT = 560;

videojs.addLanguage('ru', {
    Play: 'Смотреть',
    Pause: 'Пауза',
    Quality: 'Качество',
    Mute: 'Отключение звука',
    Unmute: 'Включение звука',
    Auto: 'Авто',
    auto: 'авто',
    'Playback Rate': 'Скорость воспроизведения',
    'Picture-in-Picture': 'Мини-плеер',
    'Exit Picture-in-Picture': 'Мини-плеер',
    Fullscreen: 'Во весь экран',
    'Non-Fullscreen': 'Свернуть',
    Loop: 'Повтор',
    'Non-Loop': 'Повтор',
    ...errorsMap,
});

// Добавляем ts-igore, т.к. videojs ругается на более широкий тип
// ts-ignore является меньшим злом в сравнении с кастом this в функциях плагинах

// @ts-ignore
videojs.registerPlugin('hlsQualitySelector', hlsQualitySelector);
// @ts-ignore
videojs.registerPlugin('loopPlugin', loopPlugin);

const VideoPlayerComponent: FC<Props> = (props) => {
    const {
        streamUrl,
        posterUrl,
        kind,
        ext,
        name,
        id,
        isVisible = true,
        autoplay = false,
        controls = true,
        startQuality = 'auto',
        nativeUrl,
        size,
        isArchive = false,
        isAttaches = false,
        isPaused = true,
        onCanPlay,
        onWaiting,
        onLoadStart,
        onLoadedMetaData,
        onNavigateLeft,
        onNavigateRight,
        onClose,
        onDelete,
        onError,
        sendRadar,
    } = props;
    const [adm, setAdm] = useState<any | undefined>();
    const isVideo = kind === 'video';
    const isPhone = useSelector(EnvironmentSelectors.isPhone);
    const videoRef = useRef<HTMLVideoElement>(null);
    const playerRef = useRef<VideoPlayerWithPlugins>();

    const [isMediaQueryHit] = useMinWidthBreakpoint(RESPONSIVE_PLAYER_BUTTONS_BREAKPOINT);

    /**
     * Создаем уникальный для каждого видоса скоуп горячих клавиш
     */
    const hotkeysScope = `${hotKeysVideoPlayerScope}_${name}`;

    /**
     * Удаляем скоуп при изменении имени файла или закрытии плеера
     */
    useEffect(() => {
        return () => {
            hotkeys.deleteScope(hotkeysScope);
        };
    }, [hotkeysScope]);

    /**
     * Устанавливаем новый скоуп горячих клавишь
     * Так как при переходе на другой элемент плеер не делает unmount возвращаем старый скоуп если плеер не видим
     */
    useEffect(() => {
        if (!isVisible) {
            return;
        }

        const previousKeyScope = hotkeys.getScope();
        hotkeys.setScope(hotkeysScope);

        return () => {
            if (!isVisible) {
                return;
            }

            hotkeys.setScope(previousKeyScope);
        };
    }, [isVisible, hotkeysScope]);

    useVideoPlayer(videoRef, playerRef, {
        streamUrl,
        posterUrl,
        kind,
        autoplay,
        controls,
        startQuality,
        nativeUrl,
        size,
        isArchive,
        isAttaches,
        isVideo,
        isPhone,
        hotkeysScope,
        onCanPlay,
        onWaiting,
        onLoadStart,
        onLoadedMetaData,
        onNavigateLeft,
        onNavigateRight,
        onClose,
        onError,
        onDelete,
        name,
        id,
        ext,
        isMediaQueryHit,
        setAdm,
        mediaBreakpoint: RESPONSIVE_PLAYER_BUTTONS_BREAKPOINT,
    });

    useEffect(() => {
        if (isPaused) {
            playerRef.current?.pause();
        } else {
            playerRef.current?.play();
        }
    }, [isPaused]);

    // При переходе на другой элемент viewer компонент не совершает unmount, ставим на паузу
    useEffect(() => {
        if (!isVisible) {
            playerRef.current?.pause();
            adm?.pause();
        }

        const handleOnPlay = () => {
            // Если поставить видео на паузу, перейти на другой элемент и там перейти в фуллскрин, то либа videojs продолжит играть этот невидимый фоновый элемент, потому стопаем принудительно
            if (!isVisible && document.fullscreenElement && !playerRef.current?.paused()) {
                playerRef.current?.pause();
                adm?.pause();
            }
        };

        playerRef.current?.on('play', handleOnPlay);

        return () => {
            playerRef.current?.off('play', handleOnPlay);
        };
    }, [isVisible, adm]);

    useEffect(() => {
        updateControlBarButtons(playerRef.current, isMediaQueryHit);
    }, [isMediaQueryHit]);

    return (
        <div className={`${styles.root} ${isPhone ? 'mobile' : ''}`}>
            <video ref={videoRef} className="video-js" />
            {!isVideo && (
                <div className={styles.audioIcon}>
                    <Stub mode="dark" ext={ext} kind={kind} isVirus={false} name={name} sendRadar={sendRadar} />
                </div>
            )}
        </div>
    );
};

// При переходе на другой элемент в архиве videojs делает dispose и удаляет <video из ДОМ,
// это создает проблему с videoRef при повторном переключении на этот же файл
// Поэтому сделана эта обертка с key, чтобы целиком удалять из ДОМ всё и заново рендерить вместе с новым <video
export const VideoPlayer: FC<Props> = (props) => {
    const { name, ...rest } = props;

    return <VideoPlayerComponent key={encodeURIComponent(name)} {...rest} name={name} />;
};
