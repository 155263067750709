import { classNames } from '@vkontakte/vkui';
import React, { type VFC } from 'react';
import { ProtectedTooltip } from 'reactApp/ui/Mobile/ProtectedTooltip/ProtectedTooltip';
import { NameComponent } from 'reactApp/ui/Name/Name';
import { Icon20ArrowLeft, Icon20ChevronLeft } from 'reactApp/ui/touchIcons';

import styles from './Breadcrumbs.css';

interface IBreadcrumbsProps {
    showBackButton?: boolean;
    showProtectedHint?: boolean;
    useH1?: boolean;
    onClickBackButton?: () => void;
    name: string;
    backButtonIsArrow?: boolean;
    className?: string;
}

export const Breadcrumbs: VFC<IBreadcrumbsProps> = ({
    className,
    showBackButton,
    showProtectedHint,
    onClickBackButton,
    name,
    backButtonIsArrow = false,
    useH1 = false,
}) => (
    <div className={classNames(styles.root, className, { [styles.isMobileArchiveViewer]: backButtonIsArrow })}>
        {showBackButton && (
            <div
                className={classNames(styles.button_back, { [styles.buttonArrowBack]: backButtonIsArrow })}
                onClick={onClickBackButton}
                data-qa-button="back"
            >
                {backButtonIsArrow ? (
                    <Icon20ArrowLeft className={styles.arrowIcon} />
                ) : (
                    <Icon20ChevronLeft width={20} height={20} fill="currentColor" />
                )}
            </div>
        )}
        <NameComponent name={name} truncate delayTruncate useH1={useH1} />
        {showProtectedHint && <ProtectedTooltip />}
    </div>
);
