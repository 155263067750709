import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { hasSuperAppVersion } from 'reactApp/utils/superAppWebViewHelpers';

export interface SuperAppAuthorization {
    anonymous: boolean;
    authorized: boolean;
    disablePurchase: boolean;
}

/** CLOUDWEB-17797: Фича для показывания кнопок и доп. информации в вебвью супераппа для анонимного и авторизованного пользователя */
export const superAppAuthorizationInfo: SuperAppAuthorization | undefined = getFeature('superapp-authorization-info');

export const hasAuthorizationInfo = (isAnonymous: boolean): boolean => {
    return !!(isAnonymous ? superAppAuthorizationInfo?.anonymous : superAppAuthorizationInfo?.authorized);
};

export const hasAuthorizationInfoWithVersion = (isAnonymous: boolean): boolean => {
    return isAnonymous && !hasSuperAppVersion() ? false : hasAuthorizationInfo(isAnonymous);
};

export const isPurchaseDisabled = (): boolean => {
    return superAppAuthorizationInfo?.disablePurchase || false;
};
