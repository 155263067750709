import { getOpenPopups } from 'reactApp/modules/popup/popup.selectors';
import type { PopupHelper, popupNames } from 'reactApp/modules/popup/popup.types';
import { setIsOpening } from 'reactApp/modules/viewer/viewer.module';
import { store } from 'reactApp/store';
import { sendXray } from 'reactApp/utils/ga';
import { noopVoid } from 'reactApp/utils/helpers';
import { actionDecorator, closePopup, openPopup } from 'redux-popup';

export const closePopupHelper = (popupName) => store.dispatch(actionDecorator()(closePopup(popupName)));

export interface IPopupHelperProps<T = any> {
    popupName: keyof typeof popupNames;
    data?: T;
    onClose?: () => void;
}

export type IPropsWithPopup<T = any> = T & { onClose: () => void };

export const openPopupHelper = <T>({ popupName, data, onClose = noopVoid }: IPopupHelperProps<T>): PopupHelper | null => {
    const popups = getOpenPopups(store.getState());

    if (popups.find((p) => p.name === popupName)) {
        return null;
    }

    const handleClose = () => {
        onClose?.();
        store.dispatch(setIsOpening(false));
        closePopupHelper(popupName);
    };

    sendXray(['pppshow', popupName]);

    store.dispatch(
        actionDecorator()(
            openPopup(popupName, {
                ...(data || {}),
                onClose: handleClose,
            })
        )
    );

    return {
        closeDialog: handleClose,
    };
};
