import { Spacing, Text } from '@vkontakte/vkui';
import coverImage from 'img/quota-landing/partners/partners-fakedoor.png';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PROMO_TARIFFS } from 'reactApp/appHelpers/configHelpers';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { openTariffBuy } from 'reactApp/modules/payment/payment.module';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { PartnersBlockId } from 'reactApp/sections/QuotaLanding/Partners/Partners.data';
import { getQuotaBillingSource } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import type { RootState } from 'reactApp/store';
import { sendModalAnalytics } from 'reactApp/utils/modalAnalytics';
import { formatPrice } from 'reactApp/utils/priceHelpers';

import styles from './PartnersFakedoorModal.css';
import type { PartnersFakedoorModalProps } from './PartnersFakedoorModal.types';

export const PartnersFakedoorModal = memo<PartnersFakedoorModalProps>(({ onClose }) => {
    const productId = PROMO_TARIFFS.partnersFakedoor;

    const dispatch = useDispatch();

    const isPaidUser = useSelector(UserSelectors.isPaidUser);
    const product = useSelector((state: RootState) => ProductsSelectors.getProductById(state, productId));

    useEffect(() => {
        sendModalAnalytics({ action: 'show', name: 'fake_offer' });
        emitAnalyticEvent(AnalyticEventNames.PARTNERS_FAKEDOOR_MODAL_VIEW);
        dispatch(UserStorageActions.set(PartnersBlockId, true));
    }, []);

    const handlePrimaryClick = useCallback(() => {
        sendModalAnalytics({ action: 'click', name: 'fake_offer' });
        onClose?.();
        if (!isPaidUser) {
            dispatch(
                openTariffBuy({
                    productId,
                    source: getQuotaBillingSource(),
                    isMobile: false,
                    paySource: 'fake_offer',
                })
            );
        }
    }, [onClose, isPaidUser, productId]);

    const handleSecondaryClick = useCallback(() => {
        sendModalAnalytics({ action: 'click', label: 'reject', name: 'fake_offer' });
        onClose?.();
    }, [onClose]);

    const handleClose = useCallback(() => {
        sendModalAnalytics({ action: 'close', name: 'fake_offer' });
        onClose?.();
    }, [onClose]);

    const content = useMemo(() => {
        return (
            <>
                <Spacing size={12} />
                <Text className={styles.text}>
                    {isPaidUser
                        ? 'Мы пока работаем над этой функцией. Сообщим сразу, как появится'
                        : `Функция ещё в работе — сообщим, как появится. А\u00A0пока можете подключить выгодный тариф: ${
                              product?.space.value
                          } за\u00A0${formatPrice((product?.discountPrice || product?.price || 0) / 12)}\u00A0₽ в месяц при покупке на год`}
                </Text>
                <Spacing size={24} />
            </>
        );
    }, [isPaidUser, product]);

    return (
        <WhatsNewDialog
            title="Спасибо за интерес"
            content={content}
            imageUrl={coverImage}
            primaryButtonText={isPaidUser ? 'Хорошо' : `Подключить ${product?.space.value}`}
            onClick={handlePrimaryClick}
            secondaryButtonText={!isPaidUser ? 'Подожду функцию' : undefined}
            onSecondary={!isPaidUser ? handleSecondaryClick : undefined}
            onClose={handleClose}
            qaId="partners-fakedoor-modal"
            imageBgColor="var(--vkui--color_background_secondary)"
            buttonTheme="vk"
            dialogSize="tiny"
        />
    );
});

PartnersFakedoorModal.displayName = 'PartnersFakedoorModal';
