import { Icon20Add } from '@vkontakte/icons';
import React, { useCallback, useMemo, useRef } from 'react';
import { IS_FREE_BIZ_SAAS_USER } from 'reactApp/appHelpers/configHelpers';
import { createCreateNewMenu } from 'reactApp/ui/ContextMenu/createContextMenuList';
import { renderContextMenu } from 'reactApp/ui/ContextMenu/createContextMenuToolkit';
import { DropdownTheme } from 'reactApp/ui/DropdownList/DropdownList';
import { sendGa } from 'reactApp/utils/ga';

import styles from '../EmptyFolder.css';
import { getPopupPosition } from '../EmptyFolder.helpers';
import { gaSuffix } from '../EmptyFolder.types';

const DEFAULT_ANNOTATION = 'Вы можете создать пустую папку, шаблон таблицы, документа или презентации';
const FREE_BIZ_SAAS_USER_ANNOTATION =
    'В бесплатной версии вы можете создать папку. В расширенной можно создавать шаблоны таблиц, документы или презентации и редактировать их';
export const useCreate = () => {
    const createRef = useRef<HTMLInputElement>(null);

    const Create = useMemo(
        () => (
            <div className={styles.icon} ref={createRef}>
                <Icon20Add />
            </div>
        ),
        [createRef]
    );

    const handleClickCreateNew = useCallback(() => {
        if (!createRef.current) {
            return;
        }

        sendGa(gaSuffix, 'create');

        renderContextMenu({
            theme: DropdownTheme.medium,
            menuItems: createCreateNewMenu({ from: 'empty-folder' }),
            ...getPopupPosition(createRef.current),
        });
    }, []);
    const annotation = useMemo(() => (IS_FREE_BIZ_SAAS_USER ? FREE_BIZ_SAAS_USER_ANNOTATION : DEFAULT_ANNOTATION), []);
    return useCallback(() => {
        return {
            title: 'Создать документ или папку',
            annotation,
            onClick: handleClickCreateNew,
            Icon: Create,
        };
    }, [Create, annotation, handleClickCreateNew]);
};
