import React, { type ReactElement, useRef } from 'react';
import { useSelector } from 'react-redux';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { type Props, ConfirmEmail } from 'reactApp/ui/ConfirmEmail/ConfirmEmail';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { Screen } from 'reactApp/ui/Screen/Screen';

import styles from './ConfirmEmail.css';

export const ConfirmEmailScreen = (props: Props): ReactElement => {
    return (
        <Screen closable={false} backSecondary className={styles.screen}>
            <ConfirmEmail onClose={props.onClose} />
        </Screen>
    );
};

export const ConfirmEmailDialog = (props: Props): ReactElement => {
    const isPhone = useSelector(EnvironmentSelectors.isPhone);
    const scrollRef = useRef(null);

    if (isPhone) {
        return (
            <MobileDialog
                scrollRef={scrollRef}
                id="mobile-confirm-email"
                onClose={props.onClose}
                disableScrolling
                isRebranding
                mod="mobileV1"
                topmost
            >
                <ConfirmEmail onClose={props.onClose} isDialog />
            </MobileDialog>
        );
    }

    return (
        <Dialog
            open
            closeOnDimmerClick
            closeOnEscape
            id="confirmEmail"
            theme="octavius"
            showCloseIcon
            size="large"
            onCancel={props.onClose}
        >
            <ConfirmEmail onClose={props.onClose} isDialog />
        </Dialog>
    );
};

export const renderConfirmEmailScreen = ({ onClose }) => openPopupHelper({ popupName: popupNames.CONFIRM_EMAIL_SCREEN, onClose });
export const renderConfirmEmailDialog = ({ onClose }) => openPopupHelper({ popupName: popupNames.CONFIRM_EMAIL_DIALOG, onClose });
