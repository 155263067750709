import { EditorID } from 'Cloud/Application/Editor/types';
import { useEffect } from 'react';
import { CHOSEN_EDITOR, IS_CHOSEN_PUBLIC_FILE, IS_ONPREMISE, PUBLIC_SHOVE } from 'reactApp/appHelpers/configHelpers';
import { setPortalMenuTheme } from 'reactApp/modules/ph/ph.helpers';

export const useOnPremiseHeadlineTheme = () => {
    useEffect(() => {
        if (!IS_ONPREMISE) {
            return;
        }

        if (IS_CHOSEN_PUBLIC_FILE && !PUBLIC_SHOVE && CHOSEN_EDITOR?.id !== EditorID.MYOFFICE) {
            return setPortalMenuTheme('dark');
        }

        return setPortalMenuTheme('light');
    }, []);
};
