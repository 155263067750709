import { Rubles } from 'Cloud/Application/Rubles';
import classNames from 'clsx';
import React, { memo, useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { DOM_ID_WORM, IS_PUBLIC, NEW_PORTAL_HEADER } from 'reactApp/appHelpers/configHelpers';
import { ADS_TIMER_MS } from 'reactApp/constants';
import { useShowAdsBannerTimer } from 'reactApp/hooks/useShowAdsBannerTimer';
import { getFeatureNewAdFormat } from 'reactApp/modules/features/features.selectors';
import { ThemeContext } from 'reactApp/modules/theme/theme.context';
import { DisableButton } from 'reactApp/ui/AdvBanners/DisableButton/DisableButton';
import { captureException } from 'reactApp/utils/tracer';

import styles from './Worm.css';

export const Worm = memo(() => {
    const isNewAdFormat = useSelector(getFeatureNewAdFormat);

    const isDark = useContext(ThemeContext).isDark;
    const showAD = !!DOM_ID_WORM;

    const handleUpdateWorm = useCallback(() => {
        if (!showAD) {
            return;
        }

        try {
            Rubles?.updateWorm(isDark);
        } catch (error) {
            captureException(error, { issueKey: 'Worm ad error' });
        }
    }, [showAD, isDark]);

    return useShowAdsBannerTimer({
        adsUpdateTimerMs: ADS_TIMER_MS,
        updateAds: handleUpdateWorm,
        suspendTimer: !showAD,
        renderItem: () =>
            showAD ? (
                <div className={classNames(styles.root, { [styles.root_new_portal_header]: NEW_PORTAL_HEADER })}>
                    <style>{`
               #${DOM_ID_WORM} {
                    overflow: hidden;
                    position: relative;
                    ${isNewAdFormat ? 'height: 48px;' : 'height: 23px;'}
                    ${isNewAdFormat ? 'width: 100%;' : ''}
                    ${isNewAdFormat ? 'padding: 0;' : 'padding: 6px 0 7px 7px;'}
                    line-height: 18px;
                    white-space: nowrap;
                }
                #${DOM_ID_WORM} .title__link {
                    color: #656972 !important;
                    text-decoration: underline !important;
                }

                #${DOM_ID_WORM} .content {
                    color: #656972 !important;
                    margin-left: .6em !important;
                    background: none !important;
                    box-shadow: none !important;
                    font-family: inherit !important;
                    border-radius: 0 !important;
                    font-size: inherit !important;
                }
            `}</style>
                    {!IS_PUBLIC && (
                        <style>{`
                #${DOM_ID_WORM} {
                    background-color: var(--vkui--octavius_color_list_letter_adv_background);
                    ${isNewAdFormat ? 'padding-top: 0;' : 'padding-top: 10px;'}
                    ${isNewAdFormat ? 'margin-top: 0;' : 'margin-top: 2px;'}
                    border-radius: 12px;
                }
            `}</style>
                    )}
                    <div id={DOM_ID_WORM} />
                    <DisableButton className={classNames(styles.btn, { [styles.btn_new]: isNewAdFormat })} id="top-ad" />
                </div>
            ) : null,
    });
});

Worm.displayName = 'Worm';
