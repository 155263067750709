import { getFeature, isFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_MOBILE_BROWSER } from 'reactApp/appHelpers/configHelpers';

export interface PromoQuotaFakedoorBlock {
    blockTitle: string;
    blockDescription: string;
    blockImageUrl: string;
    blockButtonText: string;
}
export interface PromoQuotaFakedoorModal {
    modalTitle: string;
    modalDescription: string;
    modalImageUrl: string;
    modalPrimaryButtonText: string;
    modalSecondaryButtonText?: string;
    modalSecondaryButtonEnable?: boolean;
}

type PromoQuotaFakedoor = PromoQuotaFakedoorBlock & PromoQuotaFakedoorModal;

/**
 * CLOUDWEB-18556: Фича для настройки блока и модалки фейкдора в лендинге promo/quota
 */
export const promoQuotaFakedoor: PromoQuotaFakedoor = getFeature('promo-quota-fakedoor') || {};

export const isPromoQuotaFakedoor: boolean = !IS_MOBILE_BROWSER && isFeature('promo-quota-fakedoor');
