import classNames from 'clsx';
import React, { type ReactElement, type ReactNode, memo, useCallback, useEffect, useState } from 'react';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import Content from 'reactApp/ui/Content/Content';
import { CopyWeblink } from 'reactApp/ui/CopyWeblink/CopyWeblink';
import { type DialogSize, Dialog } from 'reactApp/ui/Dialog/Dialog';
import { noop } from 'reactApp/utils/helpers';
import opener from 'reactApp/utils/opener';

import styles from './WhatsNewDialog.css';

export interface IProps {
    className?: string;
    titleClassName?: string;
    textClassName?: string;
    imageWrapperClassName?: string;
    imageClassName?: string;
    contentClassName?: string;
    closeIconClassName?: string;
    footerClassName?: string;
    primaryButtonText: ReactNode;
    primaryButtonHref?: string;
    primaryButtonTargetBlank?: boolean;
    secondaryButtonText?: string;
    secondaryButtonHref?: string;
    secondaryButtonTargetBlank?: boolean;
    date?: number;
    title: string;
    text?: string | ReactElement;
    content?: ReactElement;
    hideImage?: boolean;
    imageUrl: string;
    imageBgColor?: string;
    imageFluid?: boolean;
    closeBySecondaryButton?: boolean;
    closeAfterClick?: boolean;
    closable?: boolean;
    link?: string;
    onShow?(): void;
    onClose?(): void;
    onClick?(): void;
    onSecondary?(): void;
    actionButtonText?: string;
    onActionClick?(): void;
    footerText?: ReactElement;
    primaryButtonColor?: 'black' | 'grey';
    qaId?: string;
    dialogSize?: DialogSize;
    footerTextBelowButtons?: boolean;
    buttonTheme?: 'vk' | 'octavius';
    isRebranding?: boolean;
    isRebrandingQuotaLanding?: boolean;
    imageAdditionalContent?: ReactElement;
    onPrimaryButtonHover?: () => void;
    onSecondaryButtonHover?: () => void;
}

export const WhatsNewDialog = memo(function WhatsNewDialog({
    className,
    titleClassName,
    textClassName,
    imageWrapperClassName,
    imageClassName,
    contentClassName,
    closeIconClassName,
    footerClassName,
    primaryButtonText,
    secondaryButtonHref,
    primaryButtonHref,
    primaryButtonTargetBlank = false,
    secondaryButtonTargetBlank = false,
    onClick = noop,
    onSecondary = noop,
    onClose = noop,
    onShow = noop,
    secondaryButtonText,
    date = 0,
    title,
    text,
    content,
    link,
    closable = true,
    imageUrl,
    imageBgColor,
    imageFluid = true,
    closeAfterClick = false,
    closeBySecondaryButton = false,
    actionButtonText,
    onActionClick = noop,
    footerText,
    primaryButtonColor,
    qaId,
    dialogSize = 'large',
    footerTextBelowButtons = false,
    buttonTheme = 'octavius',
    isRebranding = false,
    isRebrandingQuotaLanding = false,
    imageAdditionalContent,
    hideImage = false,
    onPrimaryButtonHover,
    onSecondaryButtonHover,
}: IProps): ReactElement {
    const [open, setOpen] = useState(true);
    useEffect(() => onShow(), []);

    const onPrimaryHandler = useCallback(() => {
        onClick();
        if (closeAfterClick) {
            setOpen(false);
        }
        if (primaryButtonHref) {
            opener(primaryButtonHref, !primaryButtonTargetBlank);
        }
    }, [onClick, primaryButtonHref, closeAfterClick, primaryButtonTargetBlank]);

    const onSecondaryHandler = useCallback(() => {
        onSecondary();

        if (closeAfterClick) {
            setOpen(false);
        }

        if (secondaryButtonHref) {
            opener(secondaryButtonHref, !secondaryButtonTargetBlank);
        }

        if (closeBySecondaryButton) {
            onClose?.();
        }
    }, [onSecondary, secondaryButtonHref, onClose, closeBySecondaryButton, closeAfterClick, secondaryButtonTargetBlank]);

    const onCloseHandler = useCallback(() => {
        onClose?.();
    }, [onClose]);

    useEffect(() => {
        if (!open) {
            onClose?.();
        }
    }, [
        /** onClose */
        open,
    ]);

    return (
        <Dialog
            open={open}
            header={''}
            closeOnDimmerClick={false}
            id="whatsnew-dlg"
            qaId={qaId}
            onCancel={onCloseHandler}
            size={dialogSize}
            bigCloseIcon
            closable={closable}
            className={classNames(styles.root, className, {
                [styles[`root_${dialogSize}`]]: dialogSize,
            })}
            closeIconClassName={closeIconClassName}
            isRebrandingQuotaLanding={isRebrandingQuotaLanding}
        >
            <Content isModal>
                <div
                    className={classNames(styles.content, contentClassName, {
                        [styles.content_rebrandingQuotaLanding]: isRebrandingQuotaLanding,
                        [styles.normalTopPadding]: hideImage,
                    })}
                >
                    {!hideImage ? (
                        <div
                            className={classNames(styles.imageWrapper, imageWrapperClassName)}
                            style={{
                                backgroundColor: imageBgColor,
                            }}
                        >
                            <img
                                className={classNames(styles.image, imageClassName, {
                                    [styles.image_fluid]: imageFluid,
                                })}
                                src={imageUrl}
                                alt="Cover image"
                            />
                            {imageAdditionalContent}
                        </div>
                    ) : null}
                    {Boolean(date) && <div className={styles.date}>{date}</div>}
                    <div className={classNames(styles.title, titleClassName)}>{title}</div>
                    {text && (
                        <div className={classNames(styles.text, textClassName)}>
                            {typeof text === 'string' ? text.replace(/\\n/g, '\n') : text}
                        </div>
                    )}
                    {Boolean(content) && content}
                    <div
                        className={classNames(styles.footerWrapper, footerClassName, {
                            [styles.footerWrapperColumn]: footerTextBelowButtons,
                        })}
                    >
                        {footerText}
                        <div className={styles.buttonWrapper}>
                            {actionButtonText && (
                                <Button
                                    className={classNames({
                                        [styles[`button_${buttonTheme}`]]: !!buttonTheme,
                                    })}
                                    data-name="freeze-renewal"
                                    theme={buttonTheme}
                                    primary
                                    sizeMode={buttonTheme === 'vk' ? ButtonSizeMode.small : ButtonSizeMode.middle}
                                    onClick={onActionClick}
                                >
                                    {actionButtonText}
                                </Button>
                            )}
                            {primaryButtonText && (
                                <Button
                                    className={classNames({
                                        [styles[`button_${buttonTheme}`]]: !!buttonTheme,
                                        [styles[`button_${primaryButtonColor}`]]: !!primaryButtonColor,
                                    })}
                                    onClick={onPrimaryHandler}
                                    data-name="success"
                                    theme={buttonTheme}
                                    primary={!actionButtonText}
                                    secondary={Boolean(actionButtonText)}
                                    sizeMode={buttonTheme === 'vk' ? ButtonSizeMode.small : ButtonSizeMode.middle}
                                    onPointerEnter={onPrimaryButtonHover}
                                >
                                    {primaryButtonText}
                                </Button>
                            )}
                            {secondaryButtonText && (
                                <Button
                                    className={classNames({
                                        [styles[`button_${buttonTheme}`]]: !!buttonTheme,
                                        [styles.button_rebranding]: isRebranding,
                                        [styles.secondaryButton_rebrandingQuotaLanding]: isRebrandingQuotaLanding,
                                    })}
                                    onClick={onSecondaryHandler}
                                    theme={buttonTheme}
                                    data-name="secondary"
                                    sizeMode={buttonTheme === 'vk' ? ButtonSizeMode.small : ButtonSizeMode.middle}
                                    onPointerEnter={onSecondaryButtonHover}
                                >
                                    {secondaryButtonText}
                                </Button>
                            )}
                            {link && (
                                <div className={styles.copyWeblink}>
                                    <CopyWeblink publicUrl={link} type="new" onCopy={onPrimaryHandler} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Content>
        </Dialog>
    );
});
