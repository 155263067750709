import { Rubles } from 'Cloud/Application/Rubles';
import config from 'Cloud/config';
import classNames from 'clsx';
import React, { type ReactElement, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ADS_TIMER_MS } from 'reactApp/constants';
import { useShowAdsBannerTimer } from 'reactApp/hooks/useShowAdsBannerTimer';
import { getFeatureNewAdFormat } from 'reactApp/modules/features/features.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { DisableButton } from 'reactApp/ui/AdvBanners/DisableButton/DisableButton';
import { captureException } from 'reactApp/utils/tracer';

import styles from './ReactViewer.css';

const DOM_ID_WORM = config.get('DOM_ID_WORM');

export const BottomBanner = memo(({ isPublic }: { isPublic?: boolean }): ReactElement | null => {
    const updateWorm = isPublic ? Rubles?.updateIndiPublicWorm : Rubles?.updateViewerPublicBottomWorm;

    const storage = useSelector(getCurrentStorage);
    const isNewAdFormat =
        useSelector(getFeatureNewAdFormat) && storage !== EStorageType.attaches && storage !== EStorageType.viewerAttaches;

    const updateAds = useCallback(() => {
        try {
            updateWorm();
        } catch (error) {
            captureException(error, { issueKey: 'BottomBanner error' });
        }
    }, []);

    return useShowAdsBannerTimer({
        adsUpdateTimerMs: ADS_TIMER_MS,
        updateAds,
        renderItem: () => (
            <div className={classNames(styles.bottomBannerWrapper, { [styles.bottomBannerWrapper_new]: isNewAdFormat })}>
                <div className={classNames(styles.bottomBanner, { [styles.bottomBanner_new]: isNewAdFormat })} id={DOM_ID_WORM} />
                <DisableButton className={styles.bottomBannerButton} id={`${isPublic ? 'public' : 'viewer'}-bottom-ad`} />
            </div>
        ),
    });
});

BottomBanner.displayName = 'BottomBanner';

BottomBanner.whyDidYouRender = true;
