import cloudIcon from 'img/icons/rebranding-cloud.svg?url';
import { ascend, find, path, propEq, sortWith, values } from 'ramda';
import { PROMO_TARIFFS } from 'reactApp/appHelpers/configHelpers';
import { billingDataEndpointEnabled } from 'reactApp/appHelpers/featuresHelpers/features/billingDataEndpoint';
import { BillingDataSelectors } from 'reactApp/modules/billingData/billingData.selectors';
import { getBaseUserQuota } from 'reactApp/modules/family/family.selectors';
import { type ISubscriptionsState, ESubscriptionsTypes } from 'reactApp/modules/subscriptions/subscriptions.types';
import type { RootState } from 'reactApp/store';
import type { LoadingState } from 'reactApp/types/commonStates';
import { createSelector } from 'reselect';

const getSubscriptionsRoot = (state: RootState) => state.subscriptions;
const getSubscriptions = createSelector(getSubscriptionsRoot, (subscriptions) => subscriptions.list);
export const getSubscriptionsRaw = createSelector(getSubscriptionsRoot, (subscriptions) => subscriptions.rawList);
export const getSubscriptionsList = createSelector(getSubscriptions, (list) => values(list));

export const getSubscriptionsById = createSelector(
    getSubscriptions,
    (_: RootState, id: string | number) => id,
    (subscriptions, id) => path([id], subscriptions)
);

const getBasicSubscription = createSelector(getBaseUserQuota, (baseQuota) => ({
    id: 'base',
    space: baseQuota,
    icon: cloudIcon,
    base: true,
    type: ESubscriptionsTypes.base,
}));

export const getTrialSubscription = createSelector(
    getSubscriptions,
    (subscriptions) => subscriptions && Object.values(subscriptions).find((item) => item.isTrial)
);

const getAutorenewalTrialSubscription = createSelector(getSubscriptions, (subscriptions) => {
    const trials = subscriptions && Object.values(subscriptions).filter((subs) => subs.isTrial && subs.autorenewal);

    return trials.length && (trials.find((trial) => trial.isDisko) || trials[0]);
});

export const sortSubscriptionsBySpace = createSelector(getSubscriptions, (subscriptions) => {
    // tempexp_18088-start
    const productsToExclude: string[] = [];
    if (PROMO_TARIFFS?.heavyFile) {
        productsToExclude.push(PROMO_TARIFFS.heavyFile);
    }
    const list = Object.values(subscriptions).filter((item) => !productsToExclude.includes(item.productId));
    // tempexp_18088-end
    return sortWith([ascend(path(['space', 'original'])), ascend(path(['id']))], list);
});

const getComboSubscription = createSelector(
    getSubscriptions,
    (subscriptions) => subscriptions && find(propEq('type', 'reward'), values(subscriptions))
);

const getProfessionalSubscription = createSelector(
    getSubscriptions,
    (subscriptions) => subscriptions && Object.values(subscriptions).find((item) => item.isProfessional)
);

const getActiveSubscriptionId = createSelector(getSubscriptionsRoot, (subscriptions) => subscriptions.activeSubscriptionId);

const hasMailSubscription = createSelector(getSubscriptionsList, (list) => list.some((subscription) => subscription.isMail));

const getSubscriptionsLoadingState = createSelector(
    getSubscriptionsRoot,
    (state: ISubscriptionsState): LoadingState => ({
        isLoading: state.isLoading,
        isLoaded: state.isLoaded,
    })
);

const getSubscriptionsCount = createSelector(getSubscriptions, (subscriptions) => {
    return Object.values(subscriptions).length;
});

export const SubscriptionsSelector = {
    getSubscriptionsById: !billingDataEndpointEnabled ? getSubscriptionsById : BillingDataSelectors.getSubscriptionsById,
    getBasicSubscription,
    getTrialSubscription: !billingDataEndpointEnabled ? getTrialSubscription : BillingDataSelectors.getTrialSubscription,
    getAutorenewalTrialSubscription: !billingDataEndpointEnabled
        ? getAutorenewalTrialSubscription
        : BillingDataSelectors.getAutorenewalTrialSubscription,
    sortSubscriptionsBySpace: !billingDataEndpointEnabled ? sortSubscriptionsBySpace : BillingDataSelectors.sortSubscriptionsBySpace,
    getComboSubscription: !billingDataEndpointEnabled ? getComboSubscription : BillingDataSelectors.getComboSubscription,
    getProfessionalSubscription: !billingDataEndpointEnabled
        ? getProfessionalSubscription
        : BillingDataSelectors.getProfessionalSubscription,
    getActiveSubscriptionId,
    hasMailSubscription: !billingDataEndpointEnabled ? hasMailSubscription : BillingDataSelectors.hasMailSubscription,
    getSubscriptionsLoadingState: !billingDataEndpointEnabled
        ? getSubscriptionsLoadingState
        : BillingDataSelectors.getBillingDataLoadingState,
    getSubscriptionsCount: !billingDataEndpointEnabled ? getSubscriptionsCount : BillingDataSelectors.getSubscriptionsCount,
} as const;
