import type { PayloadAction } from '@reduxjs/toolkit';
import { billingDataEndpointEnabled } from 'reactApp/appHelpers/featuresHelpers/features/billingDataEndpoint';
import { renderNewComfirmationDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog.helpers';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { BillingActions } from 'reactApp/modules/billing/billing.module';
import { getBillingDataSuccess } from 'reactApp/modules/billingData/billingData.module';
import { CardActions } from 'reactApp/modules/creditCard/creditCard.module';
import { CardSelectors } from 'reactApp/modules/creditCard/creditCard.selectors';
import { cancel } from 'reactApp/modules/integration/integration.actions';
import type { OpenRenewSubscriptionModalAction } from 'reactApp/modules/payment/payment.types';
import { updateSubscriptionsRequest } from 'reactApp/modules/subscriptions/subscriptions.module';
import { handleSubscriptionsRequest } from 'reactApp/modules/subscriptions/subscriptions.saga';
import { SubscriptionsSelector } from 'reactApp/modules/subscriptions/subscriptions.selectors';
import { openRenewSubscriptionModal } from 'reactApp/ui/RenewSubscriptionModal/RenewSubscriptionModal.helpers';
import { sendXray } from 'reactApp/utils/ga';
import { channel } from 'redux-saga';
import { call, put, select, take } from 'redux-saga/effects';

export function* openRenewModal(action: PayloadAction<OpenRenewSubscriptionModalAction>) {
    const { subscriptionId, isMobile } = action.payload;

    sendXray(['subs', 'renew', 'req', 'open']);

    let subscription = yield select(SubscriptionsSelector.getSubscriptionsById, subscriptionId);

    if (!subscription) {
        yield call(handleSubscriptionsRequest);

        if (billingDataEndpointEnabled) {
            yield take(getBillingDataSuccess);
        }
    }

    subscription = yield select(SubscriptionsSelector.getSubscriptionsById, subscriptionId);

    if (!subscription) {
        sendXray(['subs', 'renew', 'no-sub']);
        renderNewComfirmationDialog({
            dataQAId: 'no-tariff-dialog',
            renderHeader: () => 'Войдите в другой аккаунт',
            renderContent: () => 'Возможно, у вас несколько аккаунтов — выберите тот, в котором хотите продлить подписку',
            buttons: [EButtonType.cancel],
            cancelButtonText: 'Хорошо',
        });
        yield cancel();
    }

    const modalChannel = channel();

    yield openRenewSubscriptionModal({
        subscriptionId,
        onSuccess: () => modalChannel.put(true),
        onClose: () => modalChannel.close(),
        isMobile,
    });

    const isSuccess = yield take(modalChannel);

    const card = yield select(CardSelectors.getCard);

    if (isSuccess) {
        yield put(BillingActions.resetLink());
        yield put(updateSubscriptionsRequest());
        yield put(CardActions.updateCard({ isSilent: Boolean(card), delay: 1000 }));
    }
}
