import themeCoverImage from 'img/aside-promo-modal/theme-cover.png';
import { IS_AUTO_TEST_HIDE } from 'reactApp/appHelpers/configHelpers';
import { themeAsidePromoFeature } from 'reactApp/appHelpers/featuresHelpers/features/themeAsidePromo';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { changeTheme, WhitelineTheme } from 'reactApp/modules/theme/theme.module';
import { isCurrentDarkTheme } from 'reactApp/modules/theme/theme.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { store as reduxStore } from 'reactApp/store';
import { openAsidePromoModal } from 'reactApp/ui/AsidePromoModal/AsidePromoModal.helpers';
import { ESplashScreenAction, sendSplashScreenAnalytics } from 'reactApp/ui/Mobile/SplashScreen/SplashScreen.analytics';
import { put } from 'redux-saga/effects';
import { ON_PREMISE } from 'server/constants/environment';
import { select } from 'typed-redux-saga';

const THEME_PROMO_ID = 'theme-aside-promo';
const THEME_PROMO_SHOW_COUNT = `${THEME_PROMO_ID}-count`;
const PERIOD_IN_DAYS = 7;
const MAX_SHOWN_COUNT = 2;

const PROMO_NAME = 'theme_promo_baloon';

function markPromoShown() {
    reduxStore.dispatch(promoShown(EPromoType.themeAsidePromo));
    storeHelper.markAsShown(THEME_PROMO_ID);
    reduxStore.dispatch(removePromo(EPromoType.themeAsidePromo));
}

export function* initThemeAsidePromoModal() {
    const storage = yield* select(getCurrentStorage);
    const isCorpUser = yield* select(UserSelectors.isCorpUser);
    const isBizUser = yield* select(UserSelectors.isBizUser);
    const shownCount = yield put(UserStorageActions.get(THEME_PROMO_SHOW_COUNT)) || 0;
    const isDark = yield* select(isCurrentDarkTheme);

    const { isAllDocuments } = getStorage(storage);

    if (shownCount >= MAX_SHOWN_COUNT) {
        return;
    }
    // Форс-включение промки для отдельных автотестов.
    const force = yield put(UserStorageActions.get(`force_${THEME_PROMO_ID}`)) || false;

    const periodPassed = storeHelper.getValue(THEME_PROMO_ID) && storeHelper.isPassed(THEME_PROMO_ID, { daysCount: PERIOD_IN_DAYS });

    if (periodPassed) {
        storeHelper.clearStore(THEME_PROMO_ID);
    }

    if (
        isAllDocuments ||
        !themeAsidePromoFeature ||
        storeHelper.getValue(THEME_PROMO_ID) ||
        (IS_AUTO_TEST_HIDE && !force) ||
        isCorpUser ||
        isBizUser ||
        ON_PREMISE ||
        storage !== EStorageType.home
    ) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.themeAsidePromo,
            promoId: THEME_PROMO_ID,
            onClick: () => {
                UserStorageActions.set(THEME_PROMO_SHOW_COUNT, MAX_SHOWN_COUNT + 1);
                markPromoShown();
                sendSplashScreenAnalytics({
                    action: ESplashScreenAction.click,
                    name: PROMO_NAME,
                    button_name: isDark ? 'Сменить тему' : 'Включить',
                    storage,
                });

                reduxStore.dispatch(changeTheme(isDark ? WhitelineTheme.default : WhitelineTheme.dark));
            },
            onShow: () => {
                markPromoShown();
                sendSplashScreenAnalytics({
                    action: ESplashScreenAction.show,
                    name: PROMO_NAME,
                    storage,
                });
            },
            onClose: () => {
                markPromoShown();
                sendSplashScreenAnalytics({
                    action: ESplashScreenAction.close,
                    name: PROMO_NAME,
                    button_name: 'Закрыть',
                    storage,
                });
            },
        })
    );
}

export function* showThemeAsidePromoModal() {
    const promo = yield* select(PromoSelectors.getPromo(EPromoType.themeAsidePromo));
    const shownCount = yield put(UserStorageActions.get(THEME_PROMO_SHOW_COUNT)) || 0;
    const isDark = yield* select(isCurrentDarkTheme);

    if (promo) {
        const { onShow, onClick, onClose } = promo;

        yield openAsidePromoModal({
            title: 'Тёмная тема в Облаке',
            text: 'При свете — стильно, в темноте — не\u00A0устанут\u00A0глаза',
            buttonText: isDark ? 'Сменить тему' : 'Включить',
            imageUrl: themeCoverImage,
            qaId: 'theme-aside-promo',
            onShow,
            onClick,
            onClose,
            darkClose: true,
            textCompact: true,
            closeOnNavigation: true,
        });

        yield put(UserStorageActions.set(THEME_PROMO_SHOW_COUNT, shownCount + 1));
    }
}
