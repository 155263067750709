import React from 'react';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';

import styles from './SharingNewCollapsedDialog.css';
import { SharingNewCollapsedContent } from './SharingNewCollapsedDialogContent';

export const SharingNewCollapsedDialog = (props) => {
    return (
        <Dialog open id="collapsed-list-dialog" className={styles.dialog} onCancel={props.onClose}>
            <Content isModal>
                <SharingNewCollapsedContent {...props} />
            </Content>
        </Dialog>
    );
};
