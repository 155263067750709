export enum SwitchSlideThemes {
    litres = 'litres',
    black = 'black',
}

export interface SwitchProps {
    checked: boolean;
    name: string;
    value?: string;
    disabled?: boolean;
    isAwaysBlue?: boolean;
    isAlwaysWhite?: boolean;
    onChange?: () => void | null;
    theme?: '' | 'rounded' | 'big';
    dark?: boolean;
    /* tempexp_17127-next-line */
    gray?: boolean;
    isAction?: boolean;
    isRebrandingQuotaLanding?: boolean;
    isRebranding?: boolean;
    /* tempexp_17509-next-line */
    isFromLitres?: boolean;
    fromLanding?: boolean; // on /promo/quota landing
    themeSlider?: SwitchSlideThemes;
}
