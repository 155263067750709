import { Buffer } from 'buffer';
import { xray } from 'lib/xray';
import { NewPdfInfoAPICall, PdfInfoAPICall } from 'reactApp/api/PdfInfoAPICall';
import { PdfViewAttachPartAPICall } from 'reactApp/api/PdfViewAttachPartAPICall';
import { NewPdfViewPartAPICall, PdfViewPartAPICall } from 'reactApp/api/PdfViewPartAPICall';
import { isAttachFile, isAttachFileWithoutCloudType } from 'reactApp/modules/attaches/attaches.helpers';
import { ovidiusNewPrefix } from 'reactApp/modules/features/features.helpers';
import { type CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';
import { parse } from 'reactApp/utils/parseMultipartData';

import type { OvidiusPartsInfo } from './types';

const getRealStorage = (storage: EStorageType): EStorageType => {
    if (storage === EStorageType.public || storage === EStorageType.attaches) {
        return storage;
    } else if (storage === EStorageType.viewerAttaches || storage === EStorageType.stock) {
        return EStorageType.attaches;
    }

    return EStorageType.home;
};

const getPdfFilePart = ({
    file,
    storage,
    publicPath,
    signal,
    hash,
}: {
    file: CloudFile;
    storage: EStorageType;
    publicPath?: string;
    hash?: string | null;
    signal?: AbortSignal;
}) => {
    return async (partId: string) => {
        const isAttachReq = isAttachFileWithoutCloudType(file);
        const radarPrefix = isAttachFile(file) ? `${file.attachType}_` : '';
        const realStorage = getRealStorage(storage);

        const API = ovidiusNewPrefix ? NewPdfViewPartAPICall : PdfViewPartAPICall;

        try {
            const params = publicPath ? { public: publicPath } : { [realStorage]: file.id };
            const { data } = isAttachReq
                ? await new PdfViewAttachPartAPICall().makeRequest({ hash, part_id: partId }, { signal })
                : await new API().makeRequest({ ...params, part_id: partId }, { signal });

            if (!data?.byteLength) {
                throw new Error('no content');
            }

            xray.send(`ovidius_${radarPrefix}get_part_success`);

            return { content: Buffer.from(data) };
        } catch (error: any) {
            xray.send(`ovidius_${radarPrefix}get_part_inf_error`, {
                rlog: 'cloud_norm_pdf_err',
                rlog_message: {
                    message: 'get_part_inf_error',
                    error,
                    partId,
                    storage,
                    isAttachReq,
                },
            });
            return {};
        }
    };
};

const getPdfFileInfo = async ({
    file,
    storage,
    publicPath,
    signal,
}: {
    file: CloudFile;
    storage: EStorageType;
    publicPath?: string;
    signal?: AbortSignal;
}): Promise<{ partsInfo?: OvidiusPartsInfo; firstPart?: ArrayBuffer }> => {
    const realStorage = getRealStorage(storage);
    try {
        const API = ovidiusNewPrefix ? NewPdfInfoAPICall : PdfInfoAPICall;
        const params = publicPath ? { public: publicPath } : { [realStorage]: file.id };
        const { headers, data } = await new API().makeRequest(params, { signal });

        const contentType = headers['content-type'];
        if (!contentType) {
            xray.send(`ovidius_parts_no_content_type`, {
                rlog: 'cloud_norm_pdf_err',
                rlog_message: {
                    error: 'no_content_type',
                    contentType,
                    file: file.name,
                },
            });
            return {};
        }

        const boundary = contentType?.split('boundary=')?.[1];
        const buffer = Buffer.from(data);
        const parts = parse(buffer, boundary);

        if (parts.length !== 2) {
            xray.send(`ovidius_parts_format_error`, {
                rlog: 'cloud_norm_pdf_err',
                rlog_message: {
                    error: 'parts_format_error',
                    parts: parts.length,
                    file: file.name,
                },
            });
            return {};
        }

        const partsInfoData = parts[0]?.data;
        const partsString = new TextDecoder().decode(partsInfoData);
        const partsInfo = JSON.parse(partsString);

        const firstPart = parts[1]?.data;

        xray.send(`ovidius_parts_success`);

        return { partsInfo, firstPart };
    } catch (error: any) {
        xray.send(`ovidius_get_parts_error`, {
            rlog: 'cloud_norm_pdf_err',
            rlog_message: {
                message: 'get_parts_error',
                error,
            },
        });
        return {};
    }
};

export const Ovidius = {
    getPdfFilePart,
    getPdfFileInfo,
};
