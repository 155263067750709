import classNames from 'clsx';
import throttle from 'lodash.throttle';
import React, { memo, useEffect, useRef, useState } from 'react';
import { ENABLE_FULL_RESPONSIVE, IS_ONPREMISE } from 'reactApp/appHelpers/configHelpers';
import { type ICountStatistic, EProgressStatus } from 'reactApp/modules/uploadList/uploadList.model';
import { ProtectedHint } from 'reactApp/ui/ProtectedHint/ProtectedHint';
import { downloadPlural, filesPlural, fixedPlural, leftPlural } from 'reactApp/utils/pluralHelpers';
import { upperFirstLetter } from 'reactApp/utils/textHelpers';

import styles from './Progress.css';

interface IProps {
    percentLoaded: number;
    countStatistic: ICountStatistic;
    scrollToStart: () => void;
    status: EProgressStatus;
    isMobile?: boolean;
    remainTime?: number;
}

const period = 100; // Rate of asymptote going to upperLimit
const timerIntervalMs = 1000;
const upperLimit = 10;

const calcNewVal = (percentLoaded, counter) => {
    const val = upperLimit - period / (counter + period / upperLimit); // Asymptote from 0 to upperLimit
    return (percentLoaded + val / upperLimit).toFixed(1).replace('.0', '');
};

// При изменении значения прогресса в компоненте, запускаем этот таймер. Он раз в timerIntervalMs будет добавлять десятую долю к прогрессу,
// чтобы прогресс в UI долго не застревал визуально на одном значении.
// Это важно при подсчете хешей больших файлов и туплении апи при добавлении больших файлов после аплоада,
// так как значение прогресса меняется только при аплоаде, а в этих случаях его еще/уже нет.
const setProgressTimer = throttle((percentLoaded, setProgressVal, timerRef: { current: number | null }) => {
    if (percentLoaded >= 100) {
        return;
    }

    let counter = 0;
    const increaseProgess = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        setProgressVal(calcNewVal(percentLoaded, counter));
        counter++;
        timerRef.current = window.setTimeout(increaseProgess, timerIntervalMs);
    };
    timerRef.current = window.setTimeout(increaseProgess, timerIntervalMs);
    setProgressVal(calcNewVal(percentLoaded, counter));
}, 250);

export const Progress = memo(({ percentLoaded, scrollToStart, status, countStatistic, isMobile = false }: IProps) => {
    const { successCount, errorCount, autoFixCount, leftCount, totalCount } = countStatistic;
    let mainText;
    let secondaryText;
    const [progressVal, setProgressVal] = useState(percentLoaded.toString());
    const timerRef = useRef<number | null>(null);
    const showProtectedHint = !(isMobile || IS_ONPREMISE);

    useEffect(() => {
        setProgressTimer(percentLoaded, setProgressVal, timerRef);

        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, [percentLoaded, setProgressVal]);

    switch (status) {
        case EProgressStatus.STARTING: {
            mainText = 'Подготовка файлов...';
            secondaryText = `Файлы загрузятся максимально быстро`;
            break;
        }
        case EProgressStatus.PROGRESS: {
            mainText = (
                <>
                    Идёт загрузка • <span>{progressVal}%</span>
                </>
            );
            secondaryText = `${upperFirstLetter(leftPlural(leftCount))} ${leftCount} ${filesPlural(leftCount)}`;
            break;
        }
        case EProgressStatus.COMPLETE: {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }

            if (successCount) {
                mainText = `${successCount}  ${filesPlural(successCount)} ${downloadPlural(successCount)}`;
            } else if (errorCount || (!successCount && !errorCount)) {
                // если ни один не загружен или удалили из даталиста
                mainText = 'Загрузка завершена';
            }

            if (errorCount) {
                let errorCountFixed = errorCount;
                if (errorCount + successCount < totalCount) {
                    // Файлы попали в очередь загрузки, но в интерфейс загрузчика еще не прилетели.
                    errorCountFixed = totalCount - successCount;
                }
                secondaryText = (
                    <span className={styles.error} onClick={scrollToStart}>
                        {errorCountFixed} {filesPlural(errorCountFixed)} не {downloadPlural(errorCountFixed)}
                    </span>
                );
            } else if (autoFixCount) {
                secondaryText = `${autoFixCount} ${filesPlural(autoFixCount)} ${fixedPlural(autoFixCount)}`;
            }

            break;
        }
        case EProgressStatus.PAUSE: {
            mainText = 'Загрузка приостановлена';
            secondaryText = `1 ошибка требует исправления`;
            break;
        }
    }

    return (
        <div className={classNames({ [styles.root]: true, [styles.root_mobile]: isMobile })}>
            <div className={styles.textWrapper}>
                <div className={classNames(styles.mainText, { [styles.responsive]: ENABLE_FULL_RESPONSIVE })}>
                    <span>{mainText}</span>
                    {showProtectedHint && <ProtectedHint text="Файлы проверяются антивирусом" hover />}
                </div>
                <div className={styles.secondaryText}>{secondaryText}</div>
            </div>
        </div>
    );
});

Progress.displayName = 'UploadListProgress';
