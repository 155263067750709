import { getFeature, getFeatureParam, isFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_PHONE_BROWSER, IS_PUBLIC } from 'reactApp/appHelpers/configHelpers';
import { type RootState, store as reduxStore } from 'reactApp/store';

import { EStorageType } from '../storage/storage.types';
import type {
    FeatureEditingNonEditableFormatsData,
    FeatureEditingNoneditableFormatsInit,
    FeatureInlineToolbar,
    FeatureOptions,
    FeatureVariants,
    TemplateVariantString,
} from './features.types';
export const MAX_PARALLEL_UPLOAD_COUNT = getFeatureParam('upload-parallel', 'count', 1);
export const MAX_PARALLEL_UPLOAD_COUNT_SAFARI = getFeatureParam('upload-parallel', 'countSafari', MAX_PARALLEL_UPLOAD_COUNT);
export const MAX_SIZE_FOR_WASM_HASH_LIB = getFeatureParam('wasm-hash-size', 'maxSize', 0);
export const FILE_READ_BLOCK_LENGTH = getFeatureParam('uploader-file-block', 'size', 20 * 1024 * 104);
export const UPLOAD_PROMO_LINK = getFeature('upload-promo-link');
export const isUploadResumeAvailable = !IS_PUBLIC && !IS_PHONE_BROWSER && isFeature('upload-resumable');
export const isNewVideoStreamUrl = isFeature('video-stream-token');
/** CLOUDWEB-15326 Включение белой шапки в редакторе. */
export const isWhiteEditorHeader = isFeature('white-editor-header');
/** CLOUDWEB-15789 Добавить в шапку редактора в Облаке отправку письма из Почты */
export const isWhiteEditorHeaderSendByMail = isFeature('white-editor-header-send-by-mail');
/** CLOUDWEB-15325 Показывать промо автосохранения при открытии редактора */
export const isAutosavePromo = isFeature('autosave-promo');
/** CLOUDWEB-15375 Включение редактора изображений (fake door) */
export const isImageEditor = isFeature('image-editor');
/** CLOUDWEB-16060 Ovidius. Переехать под ручкой с префикса АПИ api/v3/ovidius/... на api/v3/ovidius/client_cloudweb/... */
export const ovidiusNewPrefix = isFeature('ovidius-new-api-prefix');
/** CLOUDWEB-15210 Квота в разделе документы */
export const isAllDocumentsHideSpace = isFeature('alldocuments-hide-space');

export const inlineHeader: FeatureInlineToolbar = getFeature('inline-header');
/**
 * CLOUDWEB-15712 Включение LLM в редакторе.
 *
 * @param [LLMFeature] options - Опции LLM.
 **/
export const LLM = getFeatureParam('llm', 'options');
/** CLOUDWEB-15786 Включение редактирования копии на пабликах */
export const isEditPublicCopy = isFeature('edit-public-copy');
/** CLOUDWEB-16477 Текст в диалоге создания нового файла через гет-параметр **/
export const CREATE_DOCUMENT_BY_LINK = getFeatureParam('create-document-by-link', 'options');
/**
 * @deprecated Ушли от redux в фичах. Теперь стоит использовать хелпер отсюда - reactApp/appHelpers/featuresHelpers/utils.ts
 */
export const isToggleFeature = <T extends boolean | TemplateVariantString, V>(
    variants: FeatureVariants<T, V>,
    result: T
): variants is FeatureVariants<boolean, V> => typeof result === 'boolean';

/**
 * @deprecated Ушли от redux в фичах. Теперь стоит использовать хелпер отсюда - reactApp/appHelpers/featuresHelpers/utils.ts
 */
export const isMultiVariantFeature = <T extends boolean | TemplateVariantString, V>(
    variants: FeatureVariants<T, V>,
    result: T
): variants is FeatureVariants<TemplateVariantString, V> => typeof result !== 'boolean';

/**
 * @deprecated Ушли от redux в фичах. Теперь стоит использовать хелпер отсюда - reactApp/appHelpers/featuresHelpers/utils.ts
 */
export const isMultiVariantFeatureResult = (result: boolean | TemplateVariantString): result is TemplateVariantString =>
    typeof result !== 'boolean';

/**
 * @deprecated Ушли от redux в фичах. Теперь стоит использовать хелпер отсюда - reactApp/appHelpers/featuresHelpers/utils.ts
 */
export const chooseVariant = <T extends boolean | TemplateVariantString, V>(
    selector: (state: RootState) => T,
    variants: FeatureVariants<T, V> & { control: V },
    options: FeatureOptions = {}
): (() => V) => {
    return () => {
        const result = selector(reduxStore.getState());

        const { control, ...restVariants } = variants;

        const { skipCondition } = options;
        /**
         * TS не справляется с деструктуризацией(...restVariants) и не хочет поверить, что
         * Omit<FeatureVariants<T, V> & { control: V; }, "control"> это тоже самое, что FeatureVariants<T, V>
         */
        const _variants = restVariants as unknown as FeatureVariants<T, V>;

        if (isToggleFeature(_variants, result)) {
            if (result && !(skipCondition?.variant1?.() || skipCondition?.forAll?.())) {
                return _variants.variant1 as V; // variant1 может быть любым типом, как и V
            }

            return control;
        }

        if (isMultiVariantFeature(_variants, result) && isMultiVariantFeatureResult(result)) {
            const selected = _variants[result];

            if (skipCondition?.[result]?.() || skipCondition?.forAll?.()) {
                return control;
            }

            return selected ?? control;
        }

        return control;
    };
};

export const getFeatureNoneditableFormats = (
    { file, storage }: FeatureEditingNoneditableFormatsInit,
    { formats, activePages, pagesReplacedToHome, maxConvertSize }: FeatureEditingNonEditableFormatsData
): { storage: EStorageType; ext: string } | undefined => {
    const ext = file?.ext ? formats?.[file?.ext] : undefined;
    const isFeatureEnabled = Boolean(activePages?.includes(storage));

    if (!isFeatureEnabled || !ext || (maxConvertSize && file.size && file.size > maxConvertSize)) {
        return undefined;
    }

    const converterStorage = pagesReplacedToHome?.includes(storage) ? EStorageType.home : storage;

    return { storage: converterStorage, ext };
};
/** B2BCLOUD-1802 Доступен ли в OnPrem продукт R7 */
export const isOnPremR7Product = isFeature('onprem-r7-product');

/** CLOUDWEB-17143 Побудить оверквотников купить подписку / 0 Этап / Web / Модальное окно */
export const isNewOverquotaModal = isFeature('ab-overquota-modal');

/** CLOUDWEB-17163 Побудить оверквотников купить подписку / 0 Этап / Web / Растяжка */
export const isAlertForOverquota = isFeature('overquota-desc-alert');

/** CLOUDWEB-17200 Ребрендинг. Облако. Обновление онбординга */
export const isRebrandingPromo = isFeature('rebranding-promo');

/**
 * Во избежании конфликтов при добавлении таски в merge train НЕ ДОБАВЛЯЕМ в файл новых фич!
 *
 * Вместо этого следуем соглашению https://confluence.vk.team/pages/viewpage.action?pageId=1229289649
 *
 */
