import type { Product } from 'reactApp/types/Billing';

export interface Props {
    file?: IFile;
    files?: IFiles;
    numberOfFiles: number;
    onClose: () => void;
    continueFileLoading: () => void;
    skipFileLoading: () => void;
}

export interface TariffInfo {
    trial: Product;
    usualProduct: Product | undefined;
    showTariffType: EShowTariffType;
}

export interface Content {
    header: string | JSX.Element;
    description: JSX.Element;
    annotation: JSX.Element;
    actions: JSX.Element | null;
}

export enum EShowTariffType {
    USUAL_TARIFF = 'usual_tariff',
    TRIAL = 'trial',
}

interface IFile {
    extension: string;
    name: string;
    sizeBytes: number;
}

interface IFiles {
    size: string;
    number: number;
    sizeBytes: number;
}

export interface IBigFileModalContent {
    tariffInfo: TariffInfo;
    numberOfFiles: number;
    callbacks: IModalCallbacks;
    files?: IFiles;
    file?: IFile;
}

export interface IModalCallbacks {
    onSkip: () => void;
    onRequestPayment: () => void;
    onClose: () => void;
    sendAnalytics: (label: string) => void;
    // tempexp_18088-next-line
    onRequestHeavyFile: () => void;
}

export const FILES_PLURAL = [
    'Не может быть загружен {count} файл',
    'Не могут быть загружены {count} файла',
    'Не могут быть загружены {count} файлов',
];
