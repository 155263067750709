import { classNames } from '@vkontakte/vkui';
import React, { type FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GalleryCategoryType } from 'reactApp/modules/gallery/gallery.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { isCurrentDarkTheme } from 'reactApp/modules/theme/theme.selectors';
import { sendEmptyStatesAnalitics } from 'reactApp/utils/emptyStatesGa';

import { useConfigByStorage } from './Empty.config';
import styles from './Empty.css';
import type { CategoryType, Config } from './Empty.types';
import { EmptyComponent } from './EmptyComponent';

interface EmptyProps {
    className?: string;
    storage?: EStorageType;
    categoryType?: CategoryType;
    isMobile?: boolean;
    config?: Config;
}

const configIsMap = (config: Config | Map<CategoryType | undefined, Config> | undefined): config is Map<CategoryType | undefined, Config> =>
    !!config && config instanceof Map;

export const Empty: FC<EmptyProps> = ({ className, storage, categoryType, isMobile = false, config }) => {
    const configByStorage = useConfigByStorage({ isMobile });
    const isDarkTheme = useSelector(isCurrentDarkTheme);

    useEffect(() => {
        sendEmptyStatesAnalitics({ action: 'show' });
    }, []);

    let configToUse = config || (storage ? configByStorage.get(storage) : undefined);

    if (!config && configIsMap(configToUse) && categoryType) {
        configToUse = configToUse.get(categoryType);
    }

    if (!configIsMap(configToUse) && !!configToUse) {
        const { icon, iconDark, title, description: Description, BottomContent } = configToUse;

        const IconComponent: FC = () => (
            <img
                src={isDarkTheme ? iconDark : icon}
                className={classNames(styles.icon, {
                    [styles.iconMobile]:
                        (storage === EStorageType.gallery && categoryType === GalleryCategoryType.mobile) ||
                        storage === EStorageType.documents,
                })}
            />
        );

        return (
            <EmptyComponent
                title={title}
                icon={icon}
                iconDark={iconDark}
                className={className}
                description={Description}
                BottomContent={BottomContent}
                IconComponent={IconComponent}
                isMobile={isMobile}
            />
        );
    }

    return null;
};
