import { JavaScriptError } from 'reactApp/errors/clientErrors/JavaScriptError';
import { Failure } from 'reactApp/errors/Failure';
import { captureException } from 'reactApp/utils/tracer';

class Radar {
    /**
     * @type {RegExp}
     */
    static counterNamePattern = /^[a-z][a-z0-9-_]{3,}$/;

    /**
     * @type {string}
     */
    _groupName = '';

    /**
     * На grepmaillog файл будет иметь имя cloud_NewUpload.log
     * @type {string}
     */
    _kaktamName = '';

    _logRecords = [];
    _counters = {};

    /**
     * @param groupName {String} radar group name
     * @param logFile {String} kaktam log file name
     */
    constructor({ groupName, logFile }) {
        this._groupName = groupName;
        this._kaktamName = logFile;
    }

    /**
     * @param {string} counterName
     * @param {number} [counterValue]
     */
    addCounter(counterName, counterValue) {
        const pattern = Radar.counterNamePattern;

        if (!pattern.test(counterName)) {
            throw new TypeError(`The counter name does not match the pattern: ${  pattern}`);
        }

        if (counterValue === undefined) {
            counterValue = 1; // prevent time sent
        } else if (parseInt(counterValue, 10) != counterValue) {
            throw new TypeError('The counter value may only be an integer or undefined');
        }

        this._counters[counterName] = counterValue;
    }

    setGroupName(groupName) {
        this._groupName = groupName;
    }

    /**
     * @param {string} counterName
     * @param {number} [counterValue]
     * @returns {Promise}
     */
    sendCounters(counterName, counterValue) {
        if (counterName) {
            this.addCounter(counterName, counterValue);
        }

        const counters = Object.keys(this._counters);

        if (!counters.length) {
            return;
        }

        const radar = this._create();

        counters.forEach(counterName => {
            const counterValue = this._counters[counterName];

            radar.add(counterName, counterValue);
        });

        radar.send();

        const deferred = radar.deferred;

        this._counters = {};

        return new Promise((resolve, reject) => {
            deferred.then(resolve).catch(reject);
        });
    }

    _sendKaktamFail() {
        this.sendCounters('error_web-client_kaktam-not-sent');
    }

    /**
     * @param {Object} record
     */
    addLogRecord(record) {
        if (Object(record) !== record) {
            throw new TypeError('The data may only be an Object');
        }

        if (record instanceof Failure) {
            this.addCounter(record.radarName);

            if (record instanceof JavaScriptError) {
                captureException(record.error, { issueKey: 'radar addLogRecord err' });
            }

            // record = record.toLogString();
            record = record.getDetails({
                stack: true,
            });
        }

        this._logRecords.push(record);
    }

    /**
     * @param {Object} record
     * @returns {Promise}
     */
    sendLog(record) {
        if (record) {
            this.addLogRecord(record);
        }

        if (!this._logRecords.length) {
            return;
        }

        const radar = this._create();
        const message = JSON.stringify(this._logRecords);

        radar.add('kaktam');
        radar.kaktam(this._kaktamName, message);
        radar.send('post');

        const deferred = radar.deferred;

        deferred.fail(() => {
            this._sendKaktamFail();
        });

        this._logRecords = [];

        this.sendCounters();

        return new Promise((resolve, reject) => {
            deferred.then(resolve).catch(reject);
        });
    }

    send() {
        this.sendLog();
        this.sendCounters();
    }

    /**
     * @returns {RADAR}
     */
    _create() {
        return window.RADAR.group(this._groupName);
    }
}

export { Radar };
