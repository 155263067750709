import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_BIZ_USER, IS_CORP_USER, IS_MBIZUSER, IS_MOBILE_BROWSER, IS_PAID_USER } from 'reactApp/appHelpers/configHelpers';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

const value: 'a' | 'b' = getFeature('heavy-file-trial');
const userCondition = IS_PAID_USER || IS_BIZ_USER || IS_MBIZUSER || IS_CORP_USER;

if (!IS_MOBILE_BROWSER && !userCondition) {
    registerExperiment(
        'heavy-file-trial',
        value,
        [AnalyticEventNames.BIG_FILE_DIALOG_SHOW, AnalyticEventNames.FAST_CHECKOUT_SHOWN, AnalyticEventNames.TARIFF_BUY],
        {
            sendXrayArgs: {
                [AnalyticEventNames.TARIFF_BUY]: ({ paySource }, { eventName }) => ({
                    eventName: paySource?.startsWith('cloud_popup_heavy_file') ? 'heavy_trial_buy' : eventName,
                }),
                [AnalyticEventNames.FAST_CHECKOUT_SHOWN]: ({ paySource }, { eventName }) => ({
                    eventName: paySource?.startsWith('cloud_popup_heavy_file') ? 'heavy_trial_checkout' : eventName,
                }),
            },
        }
    );
}

export const abHeavyFileTrial = !IS_MOBILE_BROWSER && !userCondition && value === 'b';
