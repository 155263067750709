import type { SharedItem, SharedLinksState, SharedStorage } from 'reactApp/modules/shared/shared.type';
import type { RootState } from 'reactApp/store';
import type { LoadingState } from 'reactApp/types/commonStates';
import type { Count } from 'reactApp/types/Tree';
import { createSelector } from 'reselect';

const getSharedState = (state: RootState): SharedLinksState => state.shared;

export const getSharedStorage = createSelector(getSharedState, (state): SharedStorage => state['/']);

export const getSharedIds = createSelector(getSharedState, (state): string[] => state['/'].childs);
export const getSharedLinksStatistics = createSelector(getSharedStorage, (state): Count => state.count);

export const getCurrentSharedTab = createSelector(getSharedState, (state) => state.currentTab);

export const getSharedItemById = createSelector(
    getSharedState,
    (state, id): string => id,
    // CLOUDWEB-11253 в хомяке пути могут быть с upperCase, а в SharedLinks бек присылает все в лоу кейс. toLowerCase актуально при переходе из хомяка.
    (state, id): undefined | SharedItem => state.list[id?.toLowerCase?.()]
);

export const getLastPublished = createSelector(
    (state) => state,
    getSharedIds,
    (state, number): number => number,
    (state, idxs, number): any[] => {
        const shared = idxs.map((id) => getSharedItemById(state, id));

        const list = shared.sort((a, b) => (b?.ctime || 0) - (a?.ctime || 0));

        if (number > 0) {
            return list.slice(0, number);
        }

        return list;
    }
);

export const getSeoPublished = createSelector(
    (state) => state,
    getSharedIds,
    (state, idxs): any[] =>
        idxs
            .map((id) => getSharedItemById(state, id))
            .filter((item) => item && item.weblinkSeoEnabled)
            .map((item) => item?.id)
);

export const getSharedLoadingState = createSelector(getSharedStorage, (shared) => {
    return {
        isLoading: shared.isLoading,
        isLoaded: shared.isLoaded,
    } as LoadingState;
});
