import React, { type FC, useMemo } from 'react';
import type { AttachesItem } from 'reactApp/modules/attaches/attaches.types';
import type { CloudFile } from 'reactApp/modules/storage/storage.types';
import { DownloadMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/DownloadMenuButton/DownloadMenuButton';
import { EditMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/EditMenuButton/EditMenuButton';
import { MenuInfo } from 'reactApp/ui/ReactViewer/ViewerMenu/MenuInfo/MenuInfo';
import { MenuItemName, MoreMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/MoreMenuButton/MoreMenuButton';
import { PdfEditMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/PdfEditMenuButton/PdfEditMenuButton';
import { ReturnMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/ReturnMenuButton/ReturnMenuButton';
import { ShareMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/ShareMenuButton/ShareMenuButton';
import {
    type ViewerMenuProps,
    ViewerMenuCenter,
    ViewerMenuEnd,
    ViewerMenuStart,
    ViewerMenuWrapper,
} from 'reactApp/ui/ReactViewer/ViewerMenu/ViewerMenu';
import { Zoomer } from 'reactApp/ui/ReactViewer/ViewerMenu/Zoomer/Zoomer';

interface Props extends ViewerMenuProps {
    file: CloudFile | AttachesItem;
}

export const IntegrationMenu: FC<Props> = ({
    file,
    zoom,
    decreaseZoom,
    increaseZoom,
    isArchiveItem,
    enableFavorites,
    onPublish,
    showDownload,
    onDownload,
    showEdit,
    onEdit,
    normalizedOvidiusEnabled,
    showPdfEdit,
    onPdfEdit,
    onPdfCopyEdit,
    showImageEdit,
    onImageEdit,
    onToggleFavorites,
    onCopy,
    allowPrinting,
    onPrint,
    onMove,
    onDelete,
    showClose,
    onClose,
}) => {
    const isSingleEdit = normalizedOvidiusEnabled ? showEdit && normalizedOvidiusEnabled : showEdit;

    const isInFavorites = 'isInFavorites' in file && !!file.isInFavorites;
    const moreItems = useMemo(
        () => [
            { name: MenuItemName.COPY, onClick: onCopy },
            ...(allowPrinting
                ? [
                      {
                          name: MenuItemName.PRINT,
                          onClick: () => {
                              onPrint(file);
                          },
                      },
                  ]
                : []),
            { name: MenuItemName.MOVE, divider: !enableFavorites, onClick: onMove },
            ...(enableFavorites
                ? [
                      {
                          name: MenuItemName.FAVORITE,
                          active: isInFavorites,
                          divider: true,
                          onClick: () => {
                              onToggleFavorites(file);
                          },
                      },
                  ]
                : []),
            {
                name: MenuItemName.DELETE,
                onClick: () => {
                    onDelete(file);
                },
            },
        ],
        [onCopy, allowPrinting, onPrint, onMove, enableFavorites, isInFavorites, onToggleFavorites, onDelete]
    );

    return (
        <ViewerMenuWrapper>
            <ViewerMenuStart>
                {showClose && (
                    <ReturnMenuButton
                        onClick={() => {
                            onClose(file);
                        }}
                    />
                )}
                <MenuInfo file={file} />
            </ViewerMenuStart>
            <ViewerMenuCenter>{zoom && <Zoomer value={zoom} decrease={decreaseZoom} increase={increaseZoom} />}</ViewerMenuCenter>
            <ViewerMenuEnd>
                {isArchiveItem ? (
                    <>
                        {showDownload && (
                            <DownloadMenuButton
                                file={file}
                                onClick={() => {
                                    onDownload(file);
                                }}
                            />
                        )}
                    </>
                ) : (
                    <>
                        <ShareMenuButton
                            onClick={() => {
                                onPublish(file);
                            }}
                        />
                        {showDownload && (
                            <DownloadMenuButton
                                file={file}
                                onClick={() => {
                                    onDownload(file);
                                }}
                            />
                        )}
                        {isSingleEdit ? (
                            <EditMenuButton
                                onClick={() => {
                                    onEdit(file);
                                }}
                            />
                        ) : (
                            <>
                                {showPdfEdit && (
                                    <PdfEditMenuButton
                                        onPdfEdit={() => {
                                            onPdfEdit(file);
                                        }}
                                        onPdfCopyEdit={() => {
                                            onPdfCopyEdit(file);
                                        }}
                                    />
                                )}
                            </>
                        )}
                        {showImageEdit && (
                            <EditMenuButton
                                onClick={() => {
                                    onImageEdit(file);
                                }}
                            />
                        )}
                        <MoreMenuButton list={moreItems} />
                    </>
                )}
            </ViewerMenuEnd>
        </ViewerMenuWrapper>
    );
};
