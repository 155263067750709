import React, { type FC, useMemo, useState } from 'react';
import type { AttachesItem } from 'reactApp/modules/attaches/attaches.types';
import type { CloudFile } from 'reactApp/modules/storage/storage.types';
import { PaginatorLine } from 'reactApp/ui/ReactViewer/PaginatorLine/PaginatorLine';
import { CloseMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/CloseMenuButton/CloseMenuButton';
import { DownloadMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/DownloadMenuButton/DownloadMenuButton';
import { MenuItemName, MoreMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/MoreMenuButton/MoreMenuButton';
import { ShareMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/ShareMenuButton/ShareMenuButton';
import { StoryMenuInfo } from 'reactApp/ui/ReactViewer/ViewerMenu/StoryMenuInfo/StoryMenuInfo';
import { type ViewerMenuProps, ViewerMenuEnd, ViewerMenuStart, ViewerMenuWrapper } from 'reactApp/ui/ReactViewer/ViewerMenu/ViewerMenu';

import styles from './StoryMenu.css';

interface Props extends ViewerMenuProps {
    file: CloudFile | AttachesItem;
}

export const StoryMenu: FC<Props> = ({
    file,
    isAttachesEnabled,
    count,
    currentIdx,
    transitionTime,
    isPaused,
    nextBlockId,
    onNavigation,
    topTitle,
    topSubTitle,
    onPublish,
    showDownload,
    onDownload,
    enableFavorites,
    onToggleFavorites,
    onOpenParent,
    onCopy,
    onMove,
    onMail,
    onDelete,
    showClose,
    onClose,
}) => {
    const isInFavorites = 'isInFavorites' in file && !!file.isInFavorites;
    const moreItems = useMemo(
        () => [
            { name: MenuItemName.OPEN_IN_PARENT, onClick: onOpenParent },
            { name: MenuItemName.COPY, onClick: onCopy },
            { name: MenuItemName.MOVE, divider: !enableFavorites, onClick: onMove },
            ...(enableFavorites
                ? [
                      {
                          name: MenuItemName.FAVORITE,
                          active: isInFavorites,
                          divider: true,
                          onClick: () => {
                              onToggleFavorites(file);
                          },
                      },
                  ]
                : []),
            ...(isAttachesEnabled ? [{ name: MenuItemName.MAIL, divider: true, onClick: onMail }] : []),
            {
                name: MenuItemName.DELETE,
                onClick: () => {
                    onDelete(file);
                },
            },
        ],
        [onOpenParent, onCopy, onMove, enableFavorites, isInFavorites, onToggleFavorites, isAttachesEnabled, onMail, onDelete]
    );

    const [isMoreOpen, setIsMoreOpen] = useState(false);

    return (
        <>
            <PaginatorLine
                className={styles.paginator}
                onClick={onNavigation}
                count={count}
                currentIdx={currentIdx}
                isPaused={isPaused || isMoreOpen}
                transitionTime={transitionTime}
                nextBlockId={nextBlockId}
            />
            <ViewerMenuWrapper>
                <ViewerMenuStart>
                    <StoryMenuInfo title={topTitle} subTitle={topSubTitle} />
                </ViewerMenuStart>
                <ViewerMenuEnd>
                    <ShareMenuButton
                        onClick={() => {
                            onPublish(file);
                        }}
                    />
                    {showDownload && (
                        <DownloadMenuButton
                            file={file}
                            onClick={() => {
                                onDownload(file);
                            }}
                        />
                    )}
                    <MoreMenuButton
                        list={moreItems}
                        onOpen={() => {
                            setIsMoreOpen(true);
                        }}
                        onClose={() => {
                            setIsMoreOpen(false);
                        }}
                    />
                    {showClose && (
                        <CloseMenuButton
                            onClick={() => {
                                onClose(file);
                            }}
                        />
                    )}
                </ViewerMenuEnd>
            </ViewerMenuWrapper>
        </>
    );
};
