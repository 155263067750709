import type { ReactElement, ReactNode } from 'react';

export enum EInformerColor {
    blue = 'blue',
    red = 'red',
    grey = 'grey',
    transparent = 'transparent',
    newTransparent = 'newTransparent',
}

export interface IInlineInformerProps {
    children: ReactNode;
    color: EInformerColor;
    className?: string;
    noPadding?: boolean;
    newRoot?: boolean;
}

export interface IProps {
    children: ReactNode;
    color: EInformerColor;
    icon: ReactElement;
    title?: string | ReactNode;
    closable?: boolean;
    centered?: boolean;
    onClose?: () => void;
    buttonText?: string;
    onButtonClick?: () => void;
    button?: ReactNode;
    className?: string;
    dataQaId?: string;
}
