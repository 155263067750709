/* eslint-disable complexity */
/* eslint-disable sonarjs/cognitive-complexity */
import { Spacing } from '@vkontakte/vkui';
import config from 'Cloud/config';
import classNames from 'clsx';
import { ReactComponent as ComboIcon } from 'img/icons/vk_combo.svg';
import React, { type ReactElement, type VFC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { Portal } from 'react-portal';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { isSpaceOverquotaSimplified } from 'reactApp/appHelpers/featuresHelpers/features/spaceOverquotaSimplified';
import { useGoToSubscriptions } from 'reactApp/hooks/useGoToSubscriptions';
import { isUserWithFamily } from 'reactApp/modules/family/family.selectors';
import { getFeatureAbSpace, getFeatureByName } from 'reactApp/modules/features/features.selectors';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { SubscriptionsSelector } from 'reactApp/modules/subscriptions/subscriptions.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import type { RootState } from 'reactApp/store';
import { Button } from 'reactApp/ui/Button/Button';
import { ComboPromo } from 'reactApp/ui/ComboPromo/ComboPromo';
import { ProTooltip } from 'reactApp/ui/ProTooltip/ProTooltip';
import { useSpaceCallbacks } from 'reactApp/ui/Space/hooks/useSpaceCallbacks';
import { SingleSpace } from 'reactApp/ui/Space/SingleSpace/SingleSpace';
import type { Props } from 'reactApp/ui/Space/Space.types';
import { SpaceCountdown } from 'reactApp/ui/Space/SpaceCountdown/SpaceCountdown';
import { SpaceLoader } from 'reactApp/ui/Space/SpaceLoader/SpaceLoader';
import { SpaceProgress } from 'reactApp/ui/SpaceProgress/SpaceProgress';
import { noop } from 'reactApp/utils/helpers';

import { SpaceOverquotaSimplified } from './SingleSpace/SpaceOverquotaSimplified/SpaceOverquotaSimplified';
import styles from './Space.css';
import { getSpaceState, getSpaceText, getSubscriptionText } from './Space.helpers';

const IS_ACTION_USER = config.get('IS_ACTION_USER');

const Label: VFC<{
    proSubscription?: boolean;
    comboSubscription?: Record<string, unknown>;
    handleProClick: () => void;
    isMobile?: boolean;
}> = ({ proSubscription, comboSubscription, handleProClick, isMobile }) => {
    if (proSubscription) {
        return (
            <div className={styles.pro} onClick={isMobile ? undefined : handleProClick}>
                PRO
            </div>
        );
    }
    if (comboSubscription) {
        return <ComboIcon height={20} width={77} className={styles.combo} />;
    }

    return null;
};

const SpaceComponent = memo<Props>(
    // eslint-disable-next-line max-lines-per-function
    ({ isSubscriptionsPage, isMobile, onClose = noop, className }) => {
        const subscriptionsRef = useRef<HTMLDivElement>(null);
        const [showSpacePro, setShowSpacePro] = useState(false);

        const userEmail = useSelector(UserSelectors.getEmail);
        const comboSubscription = useSelector(SubscriptionsSelector.getComboSubscription);
        const comboPromo: any = useSelector((state: RootState) => getFeatureByName(state, 'combo-promo'));
        const proSubscription = useSelector(SubscriptionsSelector.getProfessionalSubscription);
        const { isLoaded: isSingleQuotaLoaded } = useSelector(UserQuotaSelectors.getLoadingState);
        const isPaidUser = useSelector(UserSelectors.isPaidUser);
        const isB2BUser = useSelector(UserSelectors.isB2BUser);
        const isOldBizUser = useSelector(UserSelectors.isOldBizUser);
        const isBizUser = useSelector(UserSelectors.isBizUser);
        const countdownPromo = useSelector(PromoSelectors.getPromo(EPromoType.space));
        const { isOverQuota, used, remaining, userTotalSpace } = useSelector(UserQuotaSelectors.getUserQuotaState);
        const isAction = IS_ACTION_USER && !isOverQuota;
        const spaceText = getSpaceText({ isMobile, used, userTotalSpace, isOverQuota, remaining });
        const subscriptionText = getSubscriptionText(isPaidUser, isMobile);

        const spaceState = getSpaceState(isAction, isOverQuota);

        const { goToSubscriptions } = useGoToSubscriptions();

        const { onPayment, onShow } = useSpaceCallbacks({
            isBizUser,
            isSubscriptionsPage,
            countdownPromo,
            isAction,
            isB2BUser,
            isOldBizUser,
            isPaidUser,
            goToSubscriptions,
            isMobile,
        });

        const handleProClick = () => {
            setShowSpacePro(!showSpacePro);
        };

        useEffect(() => {
            onShow();
        }, [onShow]);

        const buttonText = useMemo(() => {
            return isAction ? 'Новый год в Облаке' : 'Увеличить объем';
        }, [isAction]);

        const button = useMemo(
            () => (
                <Button primary fluid theme={isMobile ? 'vk' : 'octavius'} onClick={onPayment}>
                    <div className={styles.buttonText}>{buttonText}</div>
                </Button>
            ),
            [buttonText, isMobile, onPayment]
        );

        if (!isSingleQuotaLoaded) {
            return (
                <div
                    className={classNames(styles.root, {
                        [styles.root_subscriptions]: isSubscriptionsPage,
                        [styles.root_noButton]: isB2BUser,
                    })}
                >
                    <SpaceLoader withButton={!isSubscriptionsPage && !isB2BUser} />
                </div>
            );
        }

        const subscriptionLink = (
            <Link
                to={{
                    pathname: '/subscriptions',
                }}
            >
                <div className={styles.subscriptions} ref={subscriptionsRef} onClick={onClose}>
                    {subscriptionText}
                </div>
            </Link>
        );

        return (
            <div
                className={classNames(styles.root, styles.change_padding, className, {
                    [styles.root_overquota]: isOverQuota,
                    [styles.root_mobile]: isMobile,
                    [styles.root_subscriptions]: isSubscriptionsPage,
                    [styles.root_noButton]: isB2BUser,
                    [styles.root_marketingPromo]: isAction,
                })}
                data-qa-id={`space:${spaceState}`}
            >
                <div className={styles.infoBlock}>
                    <div className={styles.header}>
                        <div className={styles.tariff}>{isMobile ? userEmail : userTotalSpace?.value}</div>
                        <Label
                            isMobile={isMobile}
                            proSubscription={proSubscription}
                            comboSubscription={comboSubscription}
                            handleProClick={handleProClick}
                        />
                    </div>
                    <SpaceProgress />
                    <div className={styles.space}>
                        <div className={styles.spaceText}>{spaceText}</div>
                        {!isBizUser && !isSubscriptionsPage && subscriptionLink}
                    </div>
                    {showSpacePro && (
                        <Portal>
                            <ProTooltip target={subscriptionsRef} onClose={handleProClick} santa={proSubscription.santa} />
                        </Portal>
                    )}
                </div>
                {countdownPromo ? (
                    <div className={styles.promo}>
                        <SpaceCountdown onClick={onPayment} button={button} />
                    </div>
                ) : (
                    !isB2BUser &&
                    !isSubscriptionsPage && (
                        <div className={styles.payment}>
                            {button}
                            {comboPromo && !isPaidUser && !isBizUser && (
                                <ComboPromo href={comboPromo.spaceUnder} description={`Получить +${comboPromo.space} c`} under />
                            )}
                        </div>
                    )
                )}
            </div>
        );
    }
);

SpaceComponent.displayName = 'SpaceComponent';

// tempexp_14278-start
export const Space = memo((props: Props): ReactElement => {
    const abSpace = useSelector(getFeatureAbSpace);

    // tempexp_17991-start
    const isAlmostFull = useSelector(UserQuotaSelectors.isAlmostFull);
    const isOverQuota = useSelector(UserQuotaSelectors.isOverquota);
    const is100PercentFull = useSelector(UserQuotaSelectors.is100PercentFull);
    const isFamilyUser = useSelector(isUserWithFamily);

    if (isSpaceOverquotaSimplified && (isAlmostFull || isOverQuota || is100PercentFull) && !isFamilyUser) {
        return (
            <>
                <SpaceOverquotaSimplified isSubscriptionsPage={props.isSubscriptionsPage} isMobile={props.isMobile} />
                <Spacing size={20} />
            </>
        );
    }
    // tempexp_17991-end

    return abSpace === 'b' ? (
        <SingleSpace {...props} isRebranding={isRebranding} />
    ) : (
        <SpaceComponent {...props} isRebranding={isRebranding} />
    );
});
// tempexp_14278-end

Space.displayName = 'Space';
