/* eslint-disable complexity */
import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { Icon24PenOutline } from '@vkontakte/icons';
import { Button, classNames } from '@vkontakte/vkui';
import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { openEditor } from 'reactApp/appHelpers/editorHelpers';
import { hasAuthorizationInfoWithVersion } from 'reactApp/appHelpers/featuresHelpers/features/superAppAuthorizationInfo';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { MAIL_ATTACHES_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { EAttachTypes } from 'reactApp/modules/attaches/attaches.types';
import { downloadMobileItem } from 'reactApp/modules/modifying/modifying.actions';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { getStorageItemById } from 'reactApp/modules/storage/storage.selectors';
import type { CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { selectIsItemEditable } from 'reactApp/modules/viewer/selectIsViewerItemEditable';
import type { RootState } from 'reactApp/store';
import { DownloadIcon, UploadIcon } from 'reactApp/ui/VKUIIcons';
import { canCloneWeblink } from 'reactApp/utils/cloneHelpers';
import { noop } from 'reactApp/utils/helpers';
import { PostMessageTypes, superAppPostMessage } from 'reactApp/utils/superAppWebViewHelpers';
import { EQueryParams } from 'server/helpers/getRequestParams';

import styles from './MobileViewerBottomToolbar.css';

const ViewerButton = ({
    primary,
    iconOnly,
    className,
    ...props
}: React.ComponentProps<typeof Button> & { primary?: boolean; iconOnly?: boolean }) => {
    return (
        <Button
            className={classNames(styles.btn, { [styles.secondary]: !primary, [styles.iconOnly]: iconOnly }, className)}
            size="l"
            appearance={primary ? 'accent' : 'neutral'}
            mode={primary ? 'primary' : 'tertiary'}
            {...props}
        />
    );
};

interface MobileViewerBottomToolbarProps {
    id: string;
    storage: EStorageType;
    sendAnalytics?: (data?: any) => void;
}

const MobileViewerBottomToolbarInner = ({ sendAnalytics = noop, id, storage }: MobileViewerBottomToolbarProps) => {
    const { isAttaches, isPublic } = getStorage(storage);
    const item = useSelector((state: RootState) => getStorageItemById(state, storage, id)) as CloudFile;
    const { showEditButton } = useSelector((state: RootState) => selectIsItemEditable(state, { file: item, itemStorage: storage }));
    const isAuthorized = !useSelector(UserSelectors.isAnonymous);
    const dispatch = useDispatch();
    let isTempOrCloudAttachType = false;
    if (item && 'attachType' in item) {
        isTempOrCloudAttachType = item.attachType === EAttachTypes.temporary || item.attachType === EAttachTypes.cloud;
    }
    const fromDeeplink = getQueryParams()[EQueryParams.fromDeeplink];
    const isSuperAppWebview = IS_WEBVIEW && fromDeeplink;
    const showSuperAppControlButtons: boolean = isSuperAppWebview ? hasAuthorizationInfoWithVersion(!isAuthorized) : true;
    const enablePostMessage = !!isSuperAppWebview && showSuperAppControlButtons && !isAuthorized;
    const showClone =
        Boolean((isPublic || isAttaches) && canCloneWeblink(isAuthorized, storage)) &&
        !isTempOrCloudAttachType &&
        showSuperAppControlButtons;

    const handleClone = useCallback(() => {
        toolbarActions.clone({
            id,
            destination: isAttaches ? MAIL_ATTACHES_FOLDER_ID : '',
            source: isAttaches ? 'viewer-attaches' : 'viewer',
            storage: storage ?? undefined,
        });
    }, [id, isAttaches, storage]);

    const handleDownload = useCallback(() => {
        sendAnalytics?.({ action: 'download-content' });

        dispatch(downloadMobileItem({ id, storage }));
    }, [id, sendAnalytics, storage]);

    const openItemInEditor = useCallback(() => {
        if (item) {
            openEditor(item);
        }
    }, [item]);

    return (
        <div className={styles.root} data-qa-id="bottom-toolbar">
            {showEditButton && (
                <ViewerButton
                    className={styles.editBtn}
                    primary
                    stretched
                    onClick={openItemInEditor}
                    before={<Icon24PenOutline width={24} height={24} />}
                    data-qa-id="edit"
                >
                    Редактировать документ
                </ViewerButton>
            )}
            <div className={styles.controlsRow}>
                <ViewerButton
                    primary={!showEditButton}
                    stretched
                    onClick={handleDownload}
                    before={<DownloadIcon width={24} height={24} viewBox="0 0 18 18" />}
                    data-qa-id="download"
                >
                    Скачать файл ({item ? bytesToNDigits(item.size, 3).value : ''})
                </ViewerButton>
                {showClone && (
                    <ViewerButton
                        className={styles.cloneBtn}
                        iconOnly
                        onClick={enablePostMessage ? () => superAppPostMessage(PostMessageTypes.authorization) : handleClone}
                        before={<UploadIcon width={24} height={24} />}
                        data-qa-id="clone"
                    />
                )}
            </div>
        </div>
    );
};

export const MobileViewerBottomToolbar = memo(MobileViewerBottomToolbarInner);
