import { type PayloadAction, createAction, createReducer } from '@reduxjs/toolkit';

import type { GetPublicThemeSuccessProps, SetPublicThemeActionParams, State } from './themePublic.types';

const initialState: State = {
    publicThemes: {},
};

export const setPublicTheme = createAction<SetPublicThemeActionParams>('themeOutsource/setTheme');
export const getPublicThemeSucces = createAction<GetPublicThemeSuccessProps>('public/get/theme/success');

export const themePublicReducer = createReducer(initialState, {
    [getPublicThemeSucces.type]: (state, action: PayloadAction<GetPublicThemeSuccessProps>): void => {
        const { themeId, public_id } = action.payload;
        state.publicThemes = { ...state.publicThemes, [public_id]: themeId };
    },
});
