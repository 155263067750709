/* eslint-disable complexity */
import api from 'Cloud/Application/api';
import { logger } from 'lib/logger';
import { clone } from 'ramda';
import { addFileApiCall } from 'reactApp/api/FileAddAPICall';
import { clearItemFromLocalStorage, getItemFromLocalStorage, setItemFromLocalStorage } from 'reactApp/appHelpers/localstorageHelpers';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { addFilesSuccess, removeFileSuccess } from 'reactApp/modules/modifying/modifying.actions';
import { type ISnackbarWordDeclination, snackbarMessage } from 'reactApp/modules/modifying/modifying.constants';
import { type ESnackbarType, EModifyReason } from 'reactApp/modules/modifying/modifying.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { folderCloudPath } from 'reactApp/modules/uploadList/uploadList.helpers';
import { store } from 'reactApp/store';
import { EConflictMode } from 'reactApp/types/EConflictMode';
import { filesPlural, foldersPlural } from 'reactApp/utils/pluralHelpers';
import { truncateTextMiddle } from 'reactApp/utils/textHelpers';
import { captureException } from 'reactApp/utils/tracer';

export const addFolderHelper = ({
    item,
    storage,
    parent,
    reason = EModifyReason.add,
    skipXhr = false,
    conflictMode = EConflictMode.RENAME,
}) => {
    function _success(item) {
        if ((item.id || {}).apiStatus === 'faked') {
            // CLOUDWEB-5028
            item.id = item.id.id;
        }

        item.parent = parent;
        item.size = item.__size || item.size || 0;

        if (reason !== EModifyReason.rename) {
            store.dispatch(
                addFilesSuccess({
                    items: [
                        {
                            ...clone(item),
                            kind: 'folder',
                            name: item.name || item.id.split('/').pop(),
                            count: {
                                folders: 0,
                                files: 0,
                            },
                            home: item.home || (storage !== EStorageType.public ? item.id : undefined),
                        },
                    ],
                    parent,
                    storage,
                    reason,
                })
            );
        }

        return item;
    }

    if (typeof item === 'string') {
        item = {
            name: item,
            id: `${parent}/${item}`,
        };
    }

    if (!skipXhr && item.name) {
        item.name = item.name.replace(/\//g, '_'); // Сейчас просто заменяем. Но, в будущем, должно проверяться на стороне API и выдавать ошибку
    }

    if (skipXhr) {
        return _success(item);
    }
    return new Promise((resolve, reject) => {
        const params = {
            home: parent + (parent === '/' ? '' : '/') + item.name,
            conflict: conflictMode,
        };

        api.folder
            .add(params)
            .done(function (added) {
                resolve(_success({ id: added }));
            })

            .fail(function (errors) {
                reject(errors);
            });
    });
};

export const addFileHelper = ({ item, parent, skipXhr, storage, conflictMode, reason }) => {
    function _success(item) {
        if (item.mtime === undefined) {
            item.mtime = Date.now() / 1000 || undefined;
        }

        store.dispatch(removeFileSuccess({ ids: [item.id], reason }));

        store.dispatch(
            addFilesSuccess({
                parent,
                storage,
                items: [
                    {
                        ...item,
                        name: item.name || item.nameWithoutExt,
                        kind: 'file',
                    },
                ],
                reason,
            })
        );

        return item;
    }

    if (skipXhr) {
        return _success(item);
    }

    const params = {
        home: `${parent}/${item.name}`,
        hash: item.hash,
        size: item.size,
        conflict: conflictMode || 'rename',
    };

    return addFileApiCall(params)
        .then((response) => {
            const id = (response as string).replace(/\/{2,}/g, '/');
            return _success({
                ...item,
                id,
                name: id.split('/').pop(),
            });
        })
        .catch((error) => {
            const [errors = '', _status = '', response = ''] = error;

            if (response?.isInsufficient()) {
                captureException(error, {
                    message: 'OVERQUOTED for ADD FILE',
                    extra: errors,
                });
            } else {
                captureException(error, {
                    message: 'ADD FILE',
                    extra: errors,
                });
            }

            throw error;
        });
};

export const getSnackbarText = ({
    items,
    foldersCount = 0,
    filesCount = 0,
    albumsCount = 0,
    type,
}: {
    items?: { name: string }[];
    foldersCount?: number;
    filesCount?: number;
    albumsCount?: number;
    type: ESnackbarType;
}) => {
    const snackbarItemNameMaxLength = 18;
    const message: ISnackbarWordDeclination = snackbarMessage[type];
    const firstName = items?.[0]?.name ?? '';

    if (albumsCount === 1) {
        return `Альбом «${items?.[0]?.name}» ${message.file}`;
    }

    // Файл «name» скопирован
    if (filesCount === 1 && !foldersCount) {
        const fileName = truncateTextMiddle(firstName, snackbarItemNameMaxLength);
        return `Файл «${fileName}» ${message.file}`;
    }

    // Папка «name» скопирована
    if (foldersCount === 1 && !filesCount) {
        const folderName = truncateTextMiddle(firstName, snackbarItemNameMaxLength);
        return `Папка «${folderName}» ${message.folder}`;
    }

    // Скопировано: 1 файл и 1 папка
    let text = `${message.many}: `;

    if (foldersCount) {
        text += `${foldersCount} ${foldersPlural(foldersCount)}`;
    }

    if (foldersCount && filesCount) {
        text += ' и ';
    }

    if (filesCount) {
        text += `${filesCount} ${filesPlural(filesCount)}`;
    }

    return text;
};

export const getCloneSnackbarText = ({ total, success }): string => {
    const fromText = success !== total ? `: ${success} из ${total}` : '';

    return `Успешно загружено в Облако${fromText}`;
};

export const getGoToAndScrollUrl = (folderId: string, itemHome?: string) => {
    return itemHome
        ? `/home${folderId}${folderId?.slice(-1) === '/' ? '' : '/'}?item=${encodeURIComponent(itemHome)}&action=scroll-to`
        : `/home${folderId}`;
};

export const getSnackbarButton = ({ items, destination, homeIdForScrollTo = '' }) => {
    const itemsCount = items.length;
    const item = itemsCount === 1 ? items?.[0] : undefined;
    const buttonText = item ? `Перейти к ${item.isFolder ? 'папке' : 'файлу'}` : 'Открыть папку';

    const onButtonClick = () => toolbarActions.showInParentFolder({ folderId: destination, itemId: homeIdForScrollTo || item?.home });
    return { buttonText, onButtonClick };
};

export const isFileInCurrentFolder = (path: string) => {
    if (!path) {
        return false;
    }

    const pathName = window.location.pathname;
    const urlEndsWithSlash = pathName.charAt(pathName.length - 1) === '/' ? '/' : '';
    const itemPath = `${folderCloudPath(path)}${urlEndsWithSlash}`;

    return encodeURI(`/home${itemPath}`) === pathName || encodeURI(`/public/${itemPath}`) === pathName;
};

export const getIdParams = (storage: EStorageType, id: string | string[]) => {
    switch (storage) {
        case EStorageType.r7:
        case EStorageType.feed:
        case EStorageType.alldocuments:
        case EStorageType.home:
        case EStorageType.story:
        case EStorageType.start:
        case EStorageType.search:
        case EStorageType.gallery:
        case EStorageType.favorites:
        case EStorageType.recommend:
        case EStorageType.documents:
        case EStorageType.templates:
        case EStorageType.sharedLinks:
        case EStorageType.sharedIncoming:
        case EStorageType.sharedAutodelete:
        case EStorageType.attaches:
        case EStorageType.quotaCleaner:
        case EStorageType.editor:
        case EStorageType.inlineIntegration:
            return { home: id };
        case EStorageType.public:
            return { weblink: id };
        case EStorageType.stock:
            return { stock: id };
        case EStorageType.trashbin:
            return { trashbin: id };
        case EStorageType.albums:
            return { id };
        default:
            logger.error('idParams wrong storage');
    }
};

const EDIT_ATTACH_COPY_LOCAL_STORAGE_KEY = 'edit-attach-copy-notification';

export const editAttachCopyNotification = {
    getText: () => store.dispatch(getItemFromLocalStorage(EDIT_ATTACH_COPY_LOCAL_STORAGE_KEY)),
    setText: (text: string) => store.dispatch(setItemFromLocalStorage(EDIT_ATTACH_COPY_LOCAL_STORAGE_KEY, text)),
    clear: () => store.dispatch(clearItemFromLocalStorage(EDIT_ATTACH_COPY_LOCAL_STORAGE_KEY)),
};

const EDIT_PUBLIC_COPY_LOCAL_STORAGE_KEY = 'edit-public-copy-notification';

export const editPublicCopyNotification = {
    getText: () => store.dispatch(getItemFromLocalStorage(EDIT_PUBLIC_COPY_LOCAL_STORAGE_KEY)),
    setText: (text: string) => store.dispatch(setItemFromLocalStorage(EDIT_PUBLIC_COPY_LOCAL_STORAGE_KEY, text)),
    clear: () => store.dispatch(clearItemFromLocalStorage(EDIT_PUBLIC_COPY_LOCAL_STORAGE_KEY)),
};
