import { type PayloadAction, createAction, createReducer } from '@reduxjs/toolkit';
import type { MYOFFICE_VARIANTS } from 'Cloud/Application/Editor/MyOffice/myOffice.types';
import { IS_ONPREMISE } from 'reactApp/appHelpers/configHelpers';
import type { EditorState, ExtensionRecord, InitEditorAction, StartEditorAction } from 'reactApp/modules/editor/editor.types';
import { getLLMErrorMessage } from 'reactApp/modules/editor/helpers/get-llm-error-message';
import { getExtension, getItemNameWithoutExt, getParent } from 'reactApp/modules/file/utils';
import {
    addToFavoritesSuccess,
    publishWeblink,
    removeFromFavoritesSuccess,
    renameItemSuccess,
    resetWeblinkCountDownloads,
    toggleWeblinkAccessRights,
    toggleWeblinkDomestic,
    toggleWeblinkDownloadable,
    unPublishWeblink,
    updateWeblinkCountDownloads,
    updateWeblinkExpires,
    weblinkSetDomainAccess,
} from 'reactApp/modules/modifying/modifying.actions';
import type {
    IAddToFavoritesSuccess,
    IRemoveFromFavoritesSuccess,
    IRenameItemSuccess,
    IWeblinkSetDomainAccess,
    ResetWeblinkCountDownloads,
    ToggleWeblinkAccessRights,
    ToggleWeblinkDomestic,
    ToggleWeblinkDownloadable,
    UnpublishWeblinkAction,
    UpdateWeblinkAction,
    UpdateWeblinkCountDownloads,
    UpdateWeblinkExpires,
} from 'reactApp/modules/modifying/modifying.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UrlBuilder } from 'reactApp/modules/urlBuilder/UrlBuilder';
import { AccessRights } from 'reactApp/types/Tree';
import { DEFAULT_MESSAGES, DEFAULT_SELECT_TYPE } from 'reactApp/ui/EditorLLM/EditorLLMDialog/constants/EditorLlmDialog.constants';
import type { Message, SelectType } from 'reactApp/ui/EditorLLM/EditorLLMDialog/types/EditorLlmDialog.types';
import {
    deleteMessagesBeforeNearestOwn,
    findNearestOwnMessageAbove,
} from 'reactApp/ui/EditorLLM/EditorLLMDialog/utils/EditorLlmDialog.utils';

const urlBuilder = new UrlBuilder();

const initialState: EditorState = {
    item: null,
    hasSharedEditor: false,
    isReady: false,
    availableEditors: {},
    extensions: {} as ExtensionRecord,
    sharedExtensions: {},
    llmDialog: {
        isOpen: false,
        messages: DEFAULT_MESSAGES,
        selectType: DEFAULT_SELECT_TYPE,
        isLoading: false,
        inputMessage: '',
    },
};

const startEditor = createAction<StartEditorAction>('editor/start');
const startEditorRequest = createAction<{ storage: EStorageType }>('editor/startEditorRequest');
const initEditor = createAction<InitEditorAction>('editor/init');
const readyEditor = createAction('editor/ready');
const setMyOfficeType = createAction<MYOFFICE_VARIANTS>('editor/setMyOfficeType');
const initLlmDialog = createAction('editor/llmDialog/init');
const toggleOpenLlmDialog = createAction<boolean>('editor/llmDialog/toggleOpen');
const sendMessage = createAction<Message>('editor/llmDialog/sendMessage');
const getMessageFromLLM = createAction<Message>('editor/llmDialog/getMessageStart');
const getMessageFromLLMSuccess = createAction<string>('editor/llmDialog/getMessageSuccess');
const getMessageFromLLMError = createAction<number>('editor/llmDialog/getMessageError');
const changeSelectType = createAction<SelectType>('editor/llmDialog/changeSelectType');
const changeInputMessage = createAction<string>('editor/llmDialog/changeInputMessage');
const deleteMessages = createAction<string>('editor/llmDialog/deleteMessages');
const continueMessage = createAction<string>('editor/llmDialog/continueMessage');
const rewriteMessage = createAction<string>('editor/llmDialog/rewriteMessage');
const removeErrorMessages = createAction('editor/llmDialog/removeErrorMessages');

const editorReducer = createReducer(initialState, {
    [initEditor.type]: (
        state,
        { payload: { availableEditors, sharedExtensions, hasSharedEditor, extensions } }: PayloadAction<InitEditorAction>
    ) => {
        state.availableEditors = availableEditors;
        state.hasSharedEditor = hasSharedEditor;
        state.sharedExtensions = sharedExtensions;
        state.extensions = extensions;
    },
    [setMyOfficeType.type]: (state, action: ReturnType<typeof setMyOfficeType>) => {
        state.myOfficeType = action.payload;
    },
    [readyEditor.type]: (state) => {
        state.isReady = true;
    },
    [startEditor.type]: (state, action: PayloadAction<StartEditorAction>) => {
        const { editorId, item } = action.payload;
        state.editorId = editorId;
        state.myOfficeType = undefined;
        state.isReady = false;

        /**
         * Если item не передан
         * то в соответствии с фиксом CLOUDWEB-12524 для пабликов, новый не устанавливаем
         *
         * Это нужно для кейса если item уже установлен
         */
        if (typeof item === 'undefined') {
            return;
        }

        const ext = getExtension(item);
        let weblinkExpires = item.weblink_expires ? item.weblink_expires * 1000 : item.weblinkExpires || 0;
        if (IS_ONPREMISE) {
            weblinkExpires = item.weblink_expires || item.weblinkExpires || 0;
        }
        const parent = item.home ? getParent(item.home) : item.parent;

        state.item = item
            ? {
                  id: item.id,
                  isFolder: item.isFolder,
                  parent: item.parent || parent,
                  virusScan: item.virus_scan,
                  ext,
                  nameWithoutExt: getItemNameWithoutExt(item.name, ext),
                  isInFavorites: item.isInFavorites,
                  name: item.name,
                  home: item.home,
                  size: item.size,
                  kind: item.kind,
                  subKind: item.subKind,
                  weblink: item.weblink,
                  weblinkAccessRights: item.weblink_access_rights || item.weblinkAccessRights,
                  weblinkDomainAccess: item.weblinkDomainAccess,
                  weblinkExpires,
                  ctime: item.ctime || 0,
                  weblinkDomestic: item.weblink_domestic,
                  mtime: item.mtime,
                  hash: item.hash,
                  readOnly: item.readOnly,
                  thumbnails: item.thumbnails,
                  url:
                      item.url ??
                      urlBuilder.getUrls({
                          ext,
                          weblink: item.weblink,
                          isPublic: !!item.weblink,
                          kind: item.kind,
                          subKind: item.subKind,
                          id: item.id,
                          name: item.name,
                          size: item.size,
                      }),
                  storage: EStorageType.editor,
              }
            : null;
    },
    [addToFavoritesSuccess.type]: (state, action: PayloadAction<IAddToFavoritesSuccess>): void => {
        const { ids } = action.payload;
        const id = ids?.[0];
        const item = state.item;

        if (!item || item.id !== id) {
            return;
        }

        item.isInFavorites = true;
    },
    [removeFromFavoritesSuccess.type]: (state, action: PayloadAction<IRemoveFromFavoritesSuccess>): void => {
        const { ids } = action.payload;
        const id = ids?.[0];
        const item = state.item;

        if (!item || item.id !== id) {
            return;
        }

        item.isInFavorites = false;
    },
    [publishWeblink.type]: (state, action: PayloadAction<UpdateWeblinkAction>): void => {
        const { weblink, id } = action.payload;
        const item = state.item;

        if (!item || item.id !== id) {
            return;
        }

        item.weblink = weblink;
    },
    [unPublishWeblink.type]: (state, action: PayloadAction<UnpublishWeblinkAction>): void => {
        const { ids } = action.payload;
        const id = ids?.[0];
        const item = state.item;

        if (!item || item.id !== id) {
            return;
        }

        item.weblink = undefined;
        item.weblinkAccessRights = AccessRights.r;
        item.weblinkDomestic = false;
        item.weblinkExpires = 0;
    },
    [toggleWeblinkAccessRights.type]: (state, action: PayloadAction<ToggleWeblinkAccessRights>): void => {
        const { id, type } = action.payload;

        const item = state.item;

        if (!item || item.id !== id) {
            return;
        }

        item.weblinkAccessRights = type;
    },
    [toggleWeblinkDownloadable.type]: (state, action: PayloadAction<ToggleWeblinkDownloadable>): void => {
        const { id, downloadable } = action.payload;

        const item = state.item;

        if (!item || item.id !== id) {
            return;
        }

        item.weblinkDownloadable = downloadable;
    },
    [updateWeblinkCountDownloads.type]: (state, action: PayloadAction<UpdateWeblinkCountDownloads>): void => {
        const { id, count_downloads } = action.payload;

        const item = state.item;

        if (!item || item.id !== id) {
            return;
        }

        item.count_downloads_left = count_downloads;
        item.count_downloads_total = count_downloads;
    },
    [resetWeblinkCountDownloads.type]: (state, action: PayloadAction<ResetWeblinkCountDownloads>): void => {
        const { id } = action.payload;

        const item = state.item;

        if (!item || item.id !== id) {
            return;
        }

        item.count_downloads_total = undefined;
        item.count_downloads_left = undefined;
    },
    [updateWeblinkExpires.type]: (state, action: PayloadAction<UpdateWeblinkExpires>): void => {
        const { id, expires } = action.payload;

        const item = state.item;

        if (!item || item.id !== id) {
            return;
        }

        item.weblinkExpires = expires;
    },
    [toggleWeblinkDomestic.type]: (state, action: PayloadAction<ToggleWeblinkDomestic>): void => {
        const { id, domestic } = action.payload;

        const item = state.item;

        if (!item || item.id !== id) {
            return;
        }

        item.weblinkDomestic = domestic;
    },
    [weblinkSetDomainAccess.type]: (state, action: PayloadAction<IWeblinkSetDomainAccess>): void => {
        const { domain, itemId } = action.payload;

        const item = state.item;

        if (!item || item.id !== itemId) {
            return;
        }

        item.weblinkDomainAccess = domain;
    },
    [renameItemSuccess.type]: (state, action: PayloadAction<IRenameItemSuccess>) => {
        const { newItem, oldId } = action.payload;
        const item = state.item;

        if (!item || item.id !== oldId) {
            return;
        }

        item.name = newItem.name;
        item.id = newItem.id;
        item.home = newItem.id;

        const ext = getExtension(item);

        item.url = urlBuilder.getUrls({
            ext,
            weblink: item.weblink,
            isPublic: !!item.weblink,
            kind: item.kind,
            subKind: item.subKind,
            id: item.id,
            name: item.name,
            size: item.size,
        });

        item.ext = ext.toLowerCase();
        item.nameWithoutExt = getItemNameWithoutExt(item.name, ext);
    },
    [toggleOpenLlmDialog.type]: (state, action: ReturnType<typeof toggleOpenLlmDialog>) => {
        state.llmDialog.isOpen = action.payload;
    },
    [sendMessage.type]: (state, action: ReturnType<typeof sendMessage>) => {
        const message = action.payload;

        state.llmDialog.messages = [...state.llmDialog.messages, message];
    },
    [getMessageFromLLM.type]: (state) => {
        state.llmDialog.isLoading = true;
    },
    [getMessageFromLLMSuccess.type]: (state, action: ReturnType<typeof getMessageFromLLMSuccess>) => {
        const llmDialog = state.llmDialog;
        const message: Message = { message: action.payload, isOwn: false, hasError: false };
        llmDialog.isLoading = false;
        llmDialog.inputMessage = '';
        llmDialog.messages = [...state.llmDialog.messages, message];
    },
    [getMessageFromLLMError.type]: (state, { payload: status }: ReturnType<typeof getMessageFromLLMError>) => {
        const llmDialog = state.llmDialog;
        const errorMessage = getLLMErrorMessage(status);
        const message: Message = { message: errorMessage, isOwn: false, hasError: true };
        llmDialog.isLoading = false;
        llmDialog.inputMessage = '';
        llmDialog.messages = [...state.llmDialog.messages, message];
    },
    [changeSelectType.type]: (state, action: ReturnType<typeof changeSelectType>) => {
        state.llmDialog.selectType = action.payload;
    },
    [changeInputMessage.type]: (state, action: ReturnType<typeof changeInputMessage>) => {
        state.llmDialog.inputMessage = action.payload;
    },
    [deleteMessages.type]: (state, action: ReturnType<typeof deleteMessages>) => {
        const llmDialog = state.llmDialog;
        const message = action.payload;

        llmDialog.messages = deleteMessagesBeforeNearestOwn(llmDialog.messages, message);
    },
    [continueMessage.type]: (state) => {
        state.llmDialog.isLoading = true;
    },
    [rewriteMessage.type]: (state, action: ReturnType<typeof rewriteMessage>) => {
        const llmDialog = state.llmDialog;
        const message = action.payload;

        const messageNeedToRewrite = findNearestOwnMessageAbove(llmDialog.messages, message);

        if (messageNeedToRewrite) {
            llmDialog.isLoading = true;
            llmDialog.inputMessage = messageNeedToRewrite.message;
        }
    },
    [removeErrorMessages.type]: (state) => {
        const llmDialog = state.llmDialog;

        llmDialog.messages = llmDialog.messages.filter((message) => !message.hasError);
    },
});

export {
    editorReducer,
    startEditor,
    initEditor,
    readyEditor,
    setMyOfficeType,
    initLlmDialog,
    toggleOpenLlmDialog,
    sendMessage,
    getMessageFromLLM,
    getMessageFromLLMSuccess,
    getMessageFromLLMError,
    changeInputMessage,
    changeSelectType,
    deleteMessages,
    continueMessage,
    rewriteMessage,
    removeErrorMessages,
    startEditorRequest,
};
