import React, { type ReactElement, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { IS_PUBLIC_ALBUM, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { hasAuthorizationInfo } from 'reactApp/appHelpers/featuresHelpers/features/superAppAuthorizationInfo';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { useActionsConfig } from 'reactApp/hooks/useActionsConfig';
import { RequiredSignUpTouchSource } from 'reactApp/modules/requiredAuthorization/constants';
import { requiredAuthorizationHelpers } from 'reactApp/modules/requiredAuthorization/helpers';
import { getStorageCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import { type EStorageType, EActions } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import type { RootState } from 'reactApp/store';
import { renderAuthModalDialog } from 'reactApp/ui/Mobile/AuthModal/AuthModal.helpers';
import { FacesCounter } from 'reactApp/ui/Mobile/FacesCounter/FacesCounter';
import { UploadInput } from 'reactApp/ui/Mobile/UploadInput/UploadInput';
import {
    Icon20AddOutline,
    Icon20ArrowReturnLeftOutline,
    Icon20ChainOutline,
    Icon20DeleteOutline,
    Icon20DownloadOutline,
    Icon20FolderCreateOutline,
    Icon20UploadOutline,
} from 'reactApp/ui/touchIcons';
import { PostMessageTypes, superAppPostMessage } from 'reactApp/utils/superAppWebViewHelpers';
import { EQueryParams } from 'server/helpers/getRequestParams';

import styles from './ActionsCarousel.css';

export interface IActionItem {
    id: EActions;
    text?: string;
    onClick?: () => void;
    icon?: ReactElement;
    active?: boolean;
    component?: ReactElement;
    ref?: React.RefObject<HTMLElement>;
}

export const useItems = ({ storage, downloadBtnRef }: { storage: EStorageType; downloadBtnRef?: React.RefObject<HTMLDivElement> }) => {
    const isAnonymous = useSelector(UserSelectors.isAnonymous);
    const currentFolder = useSelector((state: RootState) => getStorageCurrentFolder(state, storage));
    const fromDeeplink = getQueryParams()[EQueryParams.fromDeeplink];
    const isSuperAppWebview = IS_WEBVIEW && fromDeeplink;

    const disableButton: boolean = isSuperAppWebview && isAnonymous && hasAuthorizationInfo(isAnonymous);

    const onAuth = useCallback(() => {
        if (isAnonymous) {
            renderAuthModalDialog();
        }
    }, [isAnonymous]);

    const actionItems: Partial<Record<EActions, IActionItem>> = {
        [EActions.createFolder]: {
            id: EActions.createFolder,
            text: 'Создать папку',
            onClick: () => toolbarActions.create('folder', 'carousel'),
            icon: <Icon20FolderCreateOutline />,
        },
        [EActions.facesFilter]: {
            id: EActions.facesFilter,
            component: <FacesCounter />,
        },
        [EActions.clone]: {
            id: EActions.clone,
            text: 'Сохранить в Облако',
            icon: <Icon20UploadOutline />,
            onClick: disableButton
                ? () => superAppPostMessage(PostMessageTypes.authorization)
                : () => toolbarActions.clone({ source: 'carousel' }),
        },
        [EActions.publish]: {
            id: EActions.publish,
            text: currentFolder && 'weblink' in currentFolder && currentFolder?.weblink ? 'Настроить доступ' : 'Поделиться',
            onClick: () => toolbarActions.publish('carousel'),
            icon: <Icon20ChainOutline className={styles.iconRepaint} />,
            active: Boolean(currentFolder && 'weblink' in currentFolder && currentFolder?.weblink),
        },
        [EActions.download]: {
            id: EActions.download,
            text: 'Скачать',
            icon: <Icon20DownloadOutline />,
            onClick: () => {
                toolbarActions.downloadMobileItem();
                requiredAuthorizationHelpers.setRequiredSignUpTouchSource(RequiredSignUpTouchSource.PUBLIC_TOOLBAR);
            },
            ref: downloadBtnRef,
        },
        [EActions.restoreAll]: {
            id: EActions.restoreAll,
            text: 'Восстановить все',
            icon: <Icon20ArrowReturnLeftOutline />,
            onClick: toolbarActions.restoreAll,
        },
        [EActions.clear]: {
            id: EActions.clear,
            text: 'Очистить корзину',
            icon: <Icon20DeleteOutline />,
            onClick: toolbarActions.clear,
        },
        [EActions.upload]: {
            id: EActions.upload,
            text: 'Загрузить файлы',
            icon: <Icon20AddOutline />,
            onClick: disableButton ? () => superAppPostMessage(PostMessageTypes.authorization) : onAuth,
            component: isAnonymous ? undefined : (
                <div className={styles.upload}>
                    <UploadInput inputName="public-carousel" />
                </div>
            ),
        },
    };

    const actions = useActionsConfig({ useOnlyCurrentFolder: true, isMobile: true, skipUpload: IS_PUBLIC_ALBUM });

    return Object.keys(actions)
        .map((id) => actionItems[id])
        .filter((action) => action);
};
