import classNames from 'clsx';
import React, { type FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { IS_DOCUMENTS_DOMAIN, NEW_PORTAL_HEADER } from 'reactApp/appHelpers/configHelpers';
import { showAllDocumentsIncomingPublic } from 'reactApp/appHelpers/featuresHelpers/features/incomingPublic';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { ALL_DOCUMENTS_DOMAIN_ROUTES, ALL_DOCUMENTS_PATH } from 'reactApp/modules/allDocuments/allDocuments.constants';
import { allDocumentsLoadRequest, changeAllDocumentsCategory } from 'reactApp/modules/allDocuments/allDocuments.module';
import { getAllDocumentsCategories, getAllDocumentsCategory } from 'reactApp/modules/allDocuments/allDocuments.selectors';
import {
    type AllDocumentsSections,
    AllDocumentsCategoryType,
    EAdditionalSections,
    EAllDocumentsType,
} from 'reactApp/modules/allDocuments/allDocuments.types';
import { isAllDocumentsHideSpace } from 'reactApp/modules/features/features.helpers';
import { getFeatureAllDocumentsHideSections } from 'reactApp/modules/features/features.selectors';
import { EIncomingPublicCategory } from 'reactApp/modules/incomingPublic/incomingPublic.types';
import { resetSelect } from 'reactApp/modules/selections/selections.actions';
import { snackbarController } from 'reactApp/modules/snackbar/snackbar.controller';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import IncomingPublicPage from 'reactApp/sections/IncomingPublicPage/IncomingPublicPage';
import { MainPage } from 'reactApp/sections/MainPage/MainPage';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import { PageTabMenu } from 'reactApp/ui/PageTabMenu/PageTabMenu';
import { sendDwh, sendXray } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

import styles from './AllDocumentsPage.css';
import { AllDocumentsTrashbinPage } from './custom/AllDocumentsTrashbinPage/AllDocumentsTrashbinPage';
import { getSnackbarText } from './helpers/getSnackbarText';
import { useAllDocumentsRadars } from './hooks/useAllDocumentsRadars';

interface Props {
    documentType: EAllDocumentsType | EAdditionalSections.trashbin;
}

// eslint-disable-next-line max-lines-per-function
const AllDocumentsPageContent: FC<Props> = ({ documentType }) => {
    const dispatch = useDispatch();
    const currentCategory = useSelector(getAllDocumentsCategory);
    const categories = useSelector(getAllDocumentsCategories);
    const isTrashbinSection = documentType === EAdditionalSections.trashbin;
    const queryParams = getQueryParams();

    useAllDocumentsRadars({ documentType });

    const handleMenuTabClick = useCallback(
        (category: string) => {
            sendXray(['alldcs', 'ctgry', 'chng'], {
                [category]: 1,
            });

            if (Object.values(AllDocumentsCategoryType).includes(category as AllDocumentsCategoryType)) {
                dispatch(changeAllDocumentsCategory({ category: category as AllDocumentsCategoryType }));
                dispatch(resetSelect());
                return;
            }

            sendXray(['alldcs', 'ctgry', 'chng', 'err'], {
                [category]: 1,
            });
        },
        [dispatch]
    );

    useEffect(() => {
        sendXray(['page', 'enter', 'alldocs']);
    }, []);

    useEffect(() => {
        if (queryParams?.from === 'mail') {
            snackbarController.showSnackbar({
                id: 'all-documents-from-mail',
                closable: true,
                text: getSnackbarText(),
                disableCloseTimeout: true,
            });
            sendDwh({
                eventCategory: ECategoryGa.entered,
                action: 'docs-from-mail',
            });
        }
    }, [queryParams]);

    useEffect(() => {
        if (!isTrashbinSection) {
            dispatch(allDocumentsLoadRequest({ docType: documentType }));
        }
    }, [dispatch, documentType, isTrashbinSection]);

    if (isTrashbinSection) {
        return <AllDocumentsTrashbinPage />;
    }

    return (
        <MainPage storage={EStorageType.alldocuments} renderWorm showUploader showSpace={!isAllDocumentsHideSpace}>
            <div className={styles.root}>
                <div className={classNames({ [styles.headerContainer_new_portal_header]: NEW_PORTAL_HEADER })}>
                    <BreadcrumbsContainer />
                    {categories.length > 1 && <PageTabMenu current={currentCategory} tabs={categories} onClick={handleMenuTabClick} />}
                </div>
                <div className={classNames({ [styles.datalistContainer_new_portal_header]: NEW_PORTAL_HEADER })}>
                    <DataList goTo={noop} storage={EStorageType.alldocuments} />
                </div>
            </div>
        </MainPage>
    );
};

export const AllDocumentsPage = () => {
    const { documentType = EAllDocumentsType.document } = (useParams() || {}) as { documentType: AllDocumentsSections };
    const hiddenSections = useSelector(getFeatureAllDocumentsHideSections);

    const redirectComponent = (
        <Navigate
            replace
            to={`${IS_DOCUMENTS_DOMAIN ? ALL_DOCUMENTS_DOMAIN_ROUTES.document : ALL_DOCUMENTS_PATH}${window.location.search}`}
        />
    );

    if (hiddenSections.includes(documentType)) {
        return redirectComponent;
    }

    if (documentType === EAdditionalSections.incoming) {
        if (showAllDocumentsIncomingPublic) {
            return <IncomingPublicPage showSpace={!isAllDocumentsHideSpace} category={EIncomingPublicCategory.allDocuments} />;
        }
        return redirectComponent;
    }

    return <AllDocumentsPageContent documentType={documentType} />;
};
