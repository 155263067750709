import { Spacing } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { type VFC, useRef } from 'react';
import { useSelector } from 'react-redux';
import type { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import { SubscriptionsSelector } from 'reactApp/modules/subscriptions/subscriptions.selectors';
import type { ISubscription } from 'reactApp/modules/subscriptions/subscriptions.types';
import type { RootState } from 'reactApp/store';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { formatDefaultDate } from 'reactApp/utils/formatDate';

import styles from './CancelAutoRenewalModal.css';
import type { DialogProps } from './CancelAutoRenewalModal.types';
import { useHandlers } from './hooks/useHandlers';
import { useSendAnalyticAfterOpen } from './hooks/useSendAnalyticsAfterOpen';
import { PrivilegeList } from './PrivilegeList/PrivilegeList';

type Props = IPropsWithPopup<DialogProps>;

export const CancelAutoRenewalModal: VFC<Props> = ({
    currentSpace,
    expires,
    id,
    dwhData,
    onClose,
    isRebrandingQuotaLanding,
    isRebranding = false,
}) => {
    const scrollRef = useRef<HTMLDivElement>(null);

    useSendAnalyticAfterOpen(dwhData);
    const subscription = useSelector((state: RootState) => SubscriptionsSelector.getSubscriptionsById(state, id)) as ISubscription;

    const { handleCloseSubscription, handleGoToAllSubscriptions } = useHandlers(dwhData, onClose, subscription);

    return (
        <MobileDialog
            id="mobile-cancel-auto-renewal-modal"
            onClose={onClose}
            closeOnDimmerClick
            mod="confirm"
            topmost
            isRebrandingQuotaLanding={isRebrandingQuotaLanding}
            content16PaddingOnly={isRebrandingQuotaLanding}
            title={
                <span className={classNames(styles.title, { [styles.title_rebrandingQuotaLanding]: isRebrandingQuotaLanding })}>
                    Отключить
                    <br />
                    автопродление?
                </span>
            }
            scrollRef={scrollRef}
            footer={
                <>
                    <Spacing size={8} />
                    <Button
                        primary
                        onClick={() => handleGoToAllSubscriptions(isRebrandingQuotaLanding, isRebranding)}
                        theme="vk"
                        sizeMode={ButtonSizeMode.big}
                        className={classNames(styles.button, { [styles.button_rebrandingQuotaLanding]: isRebrandingQuotaLanding })}
                    >
                        Сохранить продление
                    </Button>
                    <Spacing size={12} />
                    <Button
                        secondary
                        onClick={() => handleCloseSubscription(id, isRebrandingQuotaLanding, isRebranding)}
                        theme="vk"
                        sizeMode={ButtonSizeMode.big}
                        className={classNames(styles.button, {
                            [styles.button_rebrandingQuotaLanding]: isRebrandingQuotaLanding,
                            [styles.button_rebranding]: isRebranding,
                        })}
                    >
                        Отключить
                    </Button>
                </>
            }
        >
            {expires && (
                <span className={classNames(styles.subtitle, { [styles.subtitle_rebrandingQuotaLanding]: isRebrandingQuotaLanding })}>
                    С {formatDefaultDate(expires)} вы потеряете:
                </span>
            )}
            {/* tempexp_16460-next-line */}
            <PrivilegeList
                ref={scrollRef}
                currentSpace={currentSpace}
                productId={subscription.productId}
                isRebrandingQuotaLanding={isRebrandingQuotaLanding}
            />
        </MobileDialog>
    );
};
