import { EditorID } from 'Cloud/Application/Editor/types';
import { EDITORS_CONFIG, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { getLlmDialogState } from 'reactApp/modules/editor/editor.selectors';
import { LLM } from 'reactApp/modules/features/features.helpers';
import { getCurrentStorage, selectStatusPage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { EStatus } from 'reactApp/sections/ErrorPage/ErrorPage.types';
import { store as reduxStore } from 'reactApp/store';
import { SCENARIO_SELECT_OPTIONS } from 'reactApp/ui/EditorLLM/EditorLLMDialog/constants/EditorLlmDialog.constants';
import { put, select } from 'typed-redux-saga';

import { storeHelper } from '../promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from '../promo.module';
import { EPromoType } from '../promo.types';

export const PROMO_ID = 'tooltip-llm-button';

export function* initEditorLLMButton() {
    const alreadyShown = storeHelper.getValue(PROMO_ID);
    const storage = yield* select(getCurrentStorage);
    const { isPublic, isEditor } = getStorage(storage);

    const isMyOfficeEditor =
        storage === EStorageType.myoffice ||
        (isEditor && EDITORS_CONFIG[0]?.id === EditorID.MYOFFICE) ||
        (isPublic && EDITORS_CONFIG[0]?.id === EditorID.MYOFFICE);
    const isR7Editor =
        storage === EStorageType.r7 ||
        storage === EStorageType.r7wopi ||
        (isEditor && (EDITORS_CONFIG[0]?.id === EditorID.R7 || EDITORS_CONFIG[0]?.id === EditorID.R7_WOPI)) ||
        (isPublic && (EDITORS_CONFIG[0]?.id === EditorID.R7 || EDITORS_CONFIG[0]?.id === EditorID.R7_WOPI));
    const isAllowedEditor = isMyOfficeEditor || isR7Editor;

    const isEditorPageHasError = (yield* select(selectStatusPage)) === EStatus.SOMETHING_WRONG;
    const { isOpen } = yield* select(getLlmDialogState);

    const isLLMEnabled = LLM && SCENARIO_SELECT_OPTIONS.length;

    if (!isAllowedEditor || alreadyShown || IS_WEBVIEW || isEditorPageHasError || isOpen || !isLLMEnabled) {
        return;
    }

    yield* put(
        addPromoToShowQueue({
            type: EPromoType.editorLLMButton,
            promoId: PROMO_ID,
            onShow: () => {
                reduxStore.dispatch(promoShown(EPromoType.editorLLMButton));
            },
            onClose: () => {
                reduxStore.dispatch(removePromo(EPromoType.editorLLMButton));
                storeHelper.markAsShown(PROMO_ID);
            },
        })
    );
}
