import { Icon20InfoCircleOutline } from '@vkontakte/icons';
import React, { type ReactElement, memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { overquotaBlockDate } from 'reactApp/appHelpers/featuresHelpers/features/overquotaBlockDate';
import { getUserOverquotaState } from 'reactApp/modules/profile/profile.selectors';
import { historyPush } from 'reactApp/modules/router/router.module';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { OverquotaBannerCtrl, sendDwhOverquota } from 'reactApp/ui/OverquotaPopup/new/OverquotaModal.helpers';
import { formatFileHistoryDate } from 'reactApp/utils/formatDate';
import { ECategoryGa } from 'reactApp/utils/paymentGa';
import { getUtmString } from 'reactApp/utils/urlHelper';

import styles from './OverquotaBanner.css';

const SOURCE = 'cloud';
const MEDIUM = 'web_union_quota_landing';

const UTM = {
    step_1: {
        buy: getUtmString({
            utm_source: SOURCE,
            utm_medium: MEDIUM,
            utm_campaign: 'overquota_future_block_infoblock',
        }),
        clear: getUtmString({
            utm_source: SOURCE,
            utm_medium: MEDIUM,
            utm_campaign: 'overquota_future_block_infoblock_cleaner',
        }),
    },
    step_2: {
        buy: getUtmString({
            utm_source: SOURCE,
            utm_medium: MEDIUM,
            utm_campaign: 'overquota_future_delete_infoblock',
        }),
        clear: getUtmString({
            utm_source: SOURCE,
            utm_medium: MEDIUM,
            utm_campaign: 'overquota_future_delete_infoblock_cleaner',
        }),
    },
};

export const OverquotaBanner = memo(function OverquotaPopup(): ReactElement | null {
    const storage = useSelector(getCurrentStorage);
    const dispatch = useDispatch();
    const { blockTime = undefined, clearTime, state }: ReturnType<typeof getUserOverquotaState> = useSelector(getUserOverquotaState);

    const title = useMemo(() => {
        if (overquotaBlockDate && blockTime && state === 'started') {
            return `${formatFileHistoryDate(blockTime)} доступ к файлам будет заблокирован`;
        }

        if (overquotaBlockDate && clearTime && (state === 'blocked' || state === 'unblocked')) {
            return `${formatFileHistoryDate(clearTime)} ваши файлы будут удалены`;
        }

        return <>Вашим файлам не&nbsp;хватает места</>;
    }, [overquotaBlockDate, blockTime, clearTime, state]);

    const content = useMemo(() => {
        if (overquotaBlockDate && (state === 'started' || state === 'blocked' || state === 'unblocked')) {
            return <>Пространство для ваших файлов переполнено. Увеличьте&nbsp;или&nbsp;очистите&nbsp;место</>;
        }

        return <>Скоро они будут удалены. Увеличьте или&nbsp;очистите место, чтобы их&nbsp;сохранить</>;
    }, [overquotaBlockDate, state]);

    useEffect(
        () =>
            sendDwhOverquota({
                action: 'show',
                nameAction: 'overquota_splash_new',
                storage,
                category: ECategoryGa.informationAlert,
            }),
        [storage]
    );

    const onPrimaryHandler = useCallback(() => {
        let search = 'utm_source=cloud_web&utm_medium=infoblock&utm_campaign=overquota_infoblock_new';

        sendDwhOverquota({
            action: 'click',
            storage,
            category: ECategoryGa.informationAlert,
            control: OverquotaBannerCtrl.add_space,
        });

        if (overquotaBlockDate && blockTime && state === 'started') {
            search = UTM.step_1.buy;
        }

        if (overquotaBlockDate && clearTime && (state === 'blocked' || state === 'unblocked')) {
            search = UTM.step_2.buy;
        }

        dispatch(
            historyPush({
                id: '/promo/quota',
                search: `?${search}`,
            })
        );
    }, []);

    const onSecondaryHandler = useCallback(() => {
        let search = `utm_source=cloud_web&utm_medium=infoblock&utm_campaign=overquota_infoblock_new`;

        sendDwhOverquota({
            action: 'click',
            storage,
            category: ECategoryGa.informationAlert,
            control: OverquotaBannerCtrl.clear,
        });

        if (overquotaBlockDate && blockTime && state === 'started') {
            search = UTM.step_1.clear;
        }

        if (overquotaBlockDate && clearTime && (state === 'blocked' || state === 'unblocked')) {
            search = UTM.step_2.clear;
        }

        dispatch(
            historyPush({
                id: '/promo/quota',
                search: `?${search}`,
                hash: 'cleaner',
            })
        );
    }, []);

    return (
        <div className={styles.root}>
            <Icon20InfoCircleOutline className={styles.icon} />
            <div className={styles.content}>
                <div className={styles.title}>{title}</div>
                <div className={styles.text}>{content}</div>
            </div>
            <div className={styles.buttons}>
                <Button
                    className={styles.button}
                    data-name="freeze-renewal"
                    theme="octavius"
                    sizeMode={ButtonSizeMode.small}
                    onClick={onPrimaryHandler}
                    error
                >
                    Увеличить место
                </Button>
                <Button
                    className={styles.button}
                    onClick={onSecondaryHandler}
                    theme="octavius"
                    sizeMode={ButtonSizeMode.small}
                    data-name="secondary"
                >
                    {overquotaBlockDate && ((blockTime && state === 'started') || state === 'unblocked') ? 'Очистить' : 'Очистить Облако'}
                </Button>
            </div>
        </div>
    );
});
