import React, { memo } from 'react';
import { IS_B2B_BIZ_USER, IS_WEBVIEW, LOGO_SRC } from 'reactApp/appHelpers/configHelpers';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { EQueryParams } from 'server/helpers/getRequestParams';

import { LogoComponent } from './LogoComponents';

export const Logo = memo<{ dark?: boolean; themeOutsource?: boolean }>(function Logo({ dark, themeOutsource }) {
    const fromDeeplink = getQueryParams()[EQueryParams.fromDeeplink];
    const isSuperAppWebView = !!(IS_WEBVIEW && fromDeeplink);

    if (IS_WEBVIEW && !isSuperAppWebView) {
        return null;
    }

    return (
        <LogoComponent
            dark={dark}
            logoSrc={LOGO_SRC}
            isBizUser={IS_B2B_BIZ_USER}
            themeOutsource={themeOutsource}
            isSuperAppWebView={isSuperAppWebView}
        />
    );
});
