/* eslint-disable max-lines */
import {
    Icon20AddCircleOutline,
    Icon20ArrowUturnLeftOutline,
    Icon20Cancel,
    Icon20Chain,
    Icon20CheckCircleOutline,
    Icon20CloudArrowUpOutline,
    Icon20CopyOutline,
    Icon20DeleteOutline,
    Icon20DocumentPlusOutline,
    Icon20DownloadOutline,
    Icon20FolderSimpleArrowRightOutlune,
    Icon20FolderSimplePlusOutline,
    Icon20Grid4UnevenVerticalOutline,
    Icon20HistoryBackwardOutline,
    Icon20Like,
    Icon20LikeOutline,
    Icon20LockOutline,
    Icon20MailOutline,
    Icon20More,
    Icon20PictureOutline,
    Icon20PicturePlusOutline,
    Icon20Rectangle2HorizontalOutline,
    Icon20Square4Outline,
    Icon20Users3Outline,
    Icon20UsersOutline,
    Icon20WarningTriangleOutline,
    Icon20WriteSquareOutline,
} from '@vkontakte/icons';
import { downloadAsJpeg } from 'Cloud/Application/DownloadAsJpeg';
import { ReactComponent as Icon20ArrowDownSmallOutline } from 'img/icons/vkui/arrow_down_small_outline_20.svg';
import { ReactComponent as Icon20CheckCircleOutlineOn } from 'img/icons/vkui/check_circle_outline_on_20.svg';
import React from 'react';
import { getDownloadItemSize } from 'reactApp/appHelpers/appHelpers';
import { IS_BIZ_USER, IS_MY_TEAM } from 'reactApp/appHelpers/configHelpers';
import { isDarkTheme, isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { VIEW_MODE_THUMBS_TEXT } from 'reactApp/constants/viewMode';
import type { CreatorsDescRecord } from 'reactApp/modules/editor/editor.types';
import type { PublicFile } from 'reactApp/modules/public/public.types';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { ESortOder, ESortTypes } from 'reactApp/modules/sort/sort.types';
import { isIntegrationStorage } from 'reactApp/modules/storage/storage.helpers';
import { type CloudItem, EActions, EStorageType } from 'reactApp/modules/storage/storage.types';
import { type ListItem, DropdownTheme } from 'reactApp/ui/DropdownList/DropdownList';
import {
    ToolbarPublicThemePreview,
    ToolbarPublicThemeText,
} from 'reactApp/ui/PublicThemePreview/ToolbarPublicThemePreview/ToolbarPublicThemePreview';
import type { TToolbarDropdownItemsKeys, TToolbarItemsKeys, TToolbarStartPageItemsKeys } from 'reactApp/ui/Toolbar/Toolbar.config.new';
import { type TToolbarDropdownItem, type TToolbarItem, EDropdownPosition, EToolbarItemTooltip } from 'reactApp/ui/Toolbar/Toolbar.types';
import { emitUploadStart } from 'reactApp/ui/UploadInput/UploadInput';
import { FileAddIcon, FileSizeIcon, FolderAddIcon, Icon20CalendarOutline, TextIcon } from 'reactApp/ui/VKUIIcons';
import { sendEmptyStatesAnalitics } from 'reactApp/utils/emptyStatesGa';
import { getSize } from 'reactApp/utils/sizeHelper';

import { sendToolbarGa } from './analytics';
import styles from './Toolbar.css';
import { getSortDateText, getSortDateType } from './Toolbar.helpers';

const removeFromFavorites = {
    id: EActions.removeFromFavorites,
    text: 'Из избранных',
    hint: 'Из избранных',
    icon: <Icon20Like data-qa-id="removeFromFavorites" color="var(--vkui--color_background_negative)" />,
    onClick: toolbarActions.toggleFavorites,
};
const addToFavorites = {
    text: 'В избранные',
    hint: 'В избранные',
    id: EActions.addToFavorites,
    icon: <Icon20LikeOutline data-qa-id="addToFavorites" />,
    onClick: toolbarActions.toggleFavorites,
};
const copy = {
    id: EActions.copy,
    text: 'Копировать',
    hint: 'Копировать',
    icon: <Icon20CopyOutline data-qa-id="copy" />,
    onClick: toolbarActions.copy,
};
const history = {
    id: EActions.history,
    text: 'Посмотреть историю',
    icon: <Icon20HistoryBackwardOutline data-qa-id="history" />,
    onClick: () => toolbarActions.viewHistory(),
};
const move = {
    id: EActions.move,
    text: 'Переместить в папку',
    hint: 'Переместить в папку',
    icon: <Icon20FolderSimpleArrowRightOutlune data-qa-id="move" />,
    onClick: toolbarActions.move,
};

export const toolbarItems: Pick<Record<EActions, TToolbarItem>, TToolbarItemsKeys> = {
    [EActions.back]: {
        id: EActions.back,
        icon: <Icon20Cancel data-qa-id="back" />,
        onClick: () => {
            sendToolbarGa('cancel');

            toolbarActions.back();
        },
    },
    [EActions.upload]: {
        id: EActions.upload,
        text: 'Загрузить',
        icon: <Icon20CloudArrowUpOutline data-qa-id="upload" />,
    },
    [EActions.create]: {
        id: EActions.create,
        text: 'Создать',
        icon: <Icon20AddCircleOutline data-qa-id="create" />,
    },
    [EActions.selectAll]: {
        id: EActions.selectAll,
        onClick: toolbarActions.selectAll,
    },
    [EActions.download]: {
        id: EActions.download,
        text: 'Скачать',
        icon: <Icon20DownloadOutline data-qa-id="download" />,
        onClick: toolbarActions.download,
    },
    [EActions.publish]: {
        id: EActions.publish,
        text: 'Получить ссылку',
        icon: <Icon20Chain data-qa-id="publish" />,
        onClick: () => toolbarActions.publish('toolbar'),
    },
    [EActions.publishAlbum]: {
        text: 'Поделиться',
        id: EActions.publishAlbum,
        icon: <Icon20Chain data-qa-id="publish-album" />,
        onClick: toolbarActions.createAndPublishAlbum,
    },
    [EActions.addToAlbum]: {
        text: 'Добавить в альбом',
        id: EActions.addToAlbum,
        icon: <Icon20PicturePlusOutline data-qa-id="add-to-album" />,
        onClick: toolbarActions.addToAlbum,
    },
    [EActions.unpublish]: {
        id: EActions.unpublish,
        text: 'Закрыть доступ',
        icon: IS_BIZ_USER ? <Icon20LockOutline /> : <Icon20Chain data-qa-id="unPublish" />,
        onClick: toolbarActions.unPublish,
    },
    [EActions.share]: {
        id: EActions.share,
        text: 'Настроить доступ',
        icon: <Icon20UsersOutline data-qa-id="share" />,
        onClick: toolbarActions.share,
    },
    [EActions.removeFromFavorites]: removeFromFavorites,
    [EActions.view]: {
        id: EActions.view,
        text: 'Вид',
    },
    [EActions.sort]: {
        id: EActions.sort,
        icon: <Icon20ArrowDownSmallOutline data-qa-id="sort" />,
    },
    [EActions.sendViaMail]: {
        id: EActions.sendViaMail,
        text: 'Переслать',
        icon: <Icon20MailOutline data-qa-id="sendViaMail" />,
        onClick: toolbarActions.forward,
    },
    [EActions.more]: {
        id: EActions.more,
        icon: <Icon20More data-qa-id="more" />,
        withoutDropdownIcon: true,
        onClick: toolbarActions.moreAction,
    },
    [EActions.restore]: {
        id: EActions.restore,
        text: 'Восстановить',
        icon: <Icon20ArrowUturnLeftOutline data-qa-id="restore" />,
        onClick: toolbarActions.restore,
    },
    [EActions.restoreAll]: {
        id: EActions.restoreAll,
        text: 'Восстановить все',
        icon: <Icon20ArrowUturnLeftOutline data-qa-id="restoreAll" />,
        onClick: toolbarActions.restoreAll,
    },
    [EActions.clear]: {
        id: EActions.clear,
        icon: <Icon20DeleteOutline data-qa-id="clear" />,
        text: 'Очистить корзину',
        onClick: toolbarActions.clear,
    },
    [EActions.mount]: {
        id: EActions.mount,
        icon: <Icon20Users3Outline data-qa-id="mount" />,
        text: 'Подключить к Облаку',
        onClick: toolbarActions.mount,
    },
    [EActions.clone]: {
        id: EActions.clone,
        icon: <Icon20CloudArrowUpOutline data-qa-id="clone" />,
        text: 'Сохранить в Облако',
        onClick: (id?: string) => toolbarActions.clone({ id, source: 'toolbar' }),
    },
    [EActions.createAlbum]: {
        id: EActions.createAlbum,
        text: 'Создать альбом',
        icon: <Icon20PicturePlusOutline data-qa-id="create-album" />,
        onClick: () => {
            sendEmptyStatesAnalitics({ action: 'album-click' });

            toolbarActions.createAlbum();
        },
    },
    // tempexp_17340-start
    [EActions.changeDesign]: {
        id: EActions.changeDesign,
        text: <ToolbarPublicThemeText />,
        icon: <Icon20PictureOutline data-qa-id="change-design" />,
        onClick: toolbarActions.changeDesignToolbar,
    },
    // tempexp_17340-end
    [EActions.remove]: {
        id: EActions.remove,
        text: 'Переместить в корзину',
        icon: <Icon20DeleteOutline data-qa-id="remove" />,
        onClick: toolbarActions.remove,
    },
};

export const toolbarDropdownItems: Pick<Record<EActions, ListItem>, TToolbarDropdownItemsKeys> = {
    [EActions.addToFavorites]: addToFavorites,
    [EActions.removeFromFavorites]: removeFromFavorites,
    [EActions.copy]: copy,
    [EActions.history]: history,
    [EActions.move]: move,
    [EActions.rename]: {
        id: EActions.rename,
        text: 'Переименовать',
        icon: <Icon20WriteSquareOutline data-qa-id="rename" />,
        onClick: toolbarActions.rename,
    },
    [EActions.viewThumbs]: {
        id: EActions.viewThumbs,
        key: EViewMode.thumbs,
        text: VIEW_MODE_THUMBS_TEXT,
        icon: <Icon20Square4Outline data-qa-id="viewThumbs" />,
        withStatus: true,
        onClick: () => toolbarActions.view('thumbs'),
    },
    [EActions.viewList]: {
        text: 'Списком',
        id: EActions.viewList,
        key: EViewMode.list,
        withStatus: true,
        icon: <Icon20Rectangle2HorizontalOutline data-qa-id="viewList" />,
        onClick: () => toolbarActions.view('list'),
    },
    [EActions.viewGallery]: {
        id: EActions.viewGallery,
        key: EViewMode.gallery,
        text: 'Галереей',
        icon: <Icon20Grid4UnevenVerticalOutline />,
        withStatus: true,
        onClick: () => toolbarActions.view('gallery'),
    },
    [EActions.sortName]: {
        id: EActions.sortName,
        text: 'По алфавиту',
        // TODO/FIXME CLOUDWEB-17158 Заменить на компонент из VKUI, когда он появится
        icon: <TextIcon data-qa-id="sortName" />,
        withStatus: true,
        onClick: (asc: boolean) => toolbarActions.sort(`name-${asc ? ESortOder.asc : ESortOder.desc}`),
    },
    [EActions.sortSize]: {
        id: EActions.sortSize,
        text: 'По размеру файла',
        // TODO/FIXME CLOUDWEB-17158 Заменить на компонент из VKUI, когда он появится
        icon: <FileSizeIcon data-qa-id="sortSize" />,
        withStatus: true,
        onClick: (asc: boolean) => toolbarActions.sort(`size-${asc ? ESortOder.asc : ESortOder.desc}`),
    },
    [EActions.sortDate]: {
        id: EActions.sortDate,
        text: `По дате${IS_BIZ_USER ? ' изменения' : ''}`,
        // TODO/FIXME CLOUDWEB-17158 Заменить на компонент из VKUI, когда он появится
        icon: <Icon20CalendarOutline data-qa-id="sortDate" />,
        withStatus: true,
        onClick: (asc: boolean) => toolbarActions.sort(`mtime-${asc ? ESortOder.asc : ESortOder.desc}`),
    },
    [EActions.createFolder]: {
        id: EActions.createFolder,
        text: 'Папку',
        icon: <FolderAddIcon data-qa-id="createFolder" />,
        onClick: (): void => toolbarActions.create('folder', 'toolbar'),
        hotkeyLabel: 'Shift+F',
    },
    [EActions.createDoc]: {
        id: EActions.createDoc,
        text: 'Документ',
        icon: <FileAddIcon data-qa-id="createDocx" color="var(--vkui--color_icon_accent)" />,
        onClick: (): void => toolbarActions.create('docx', 'toolbar'),
        hotkeyLabel: 'Shift+T',
    },
    [EActions.createCell]: {
        id: EActions.createCell,
        text: 'Таблицу',
        icon: <FileAddIcon data-qa-id="createXlsx" color="var(--vkui--color_background_positive)" />,
        onClick: (): void => toolbarActions.create('xlsx', 'toolbar'),
        hotkeyLabel: 'Shift+S',
    },
    [EActions.createPpt]: {
        id: EActions.createPpt,
        text: 'Презентацию',
        icon: <FileAddIcon data-qa-id="createPptx" color="var(--vkui--color_background_accent_alternative)" />,
        onClick: (): void => toolbarActions.create('pptx', 'toolbar'),
        hotkeyLabel: 'Shift+P',
    },
    [EActions.uploadFile]: {
        id: EActions.uploadFile,
        text: 'Загрузить файлы',
        icon: <Icon20DocumentPlusOutline data-qa-id="upload" />,
        onClick: (from) => emitUploadStart({ type: 'file', from }),
        hotkeyLabel: 'Shift+I',
    },
    [EActions.uploadFolder]: {
        id: EActions.uploadFolder,
        text: 'Загрузить папку',
        icon: <Icon20FolderSimplePlusOutline data-qa-id="uploadFolder" />,
        onClick: (from) => emitUploadStart({ type: 'folder', from }),
        hotkeyLabel: 'Shift+U',
    },
    // tempexp_17340-start
    [EActions.changeDesignMore]: {
        id: EActions.changeDesign,
        text: <ToolbarPublicThemePreview />,
        icon: <Icon20PictureOutline data-qa-id="change-design-more" />,
        onClick: toolbarActions.changeDesignBreadcrumbs,
    },
    // tempexp_17340-end
    [EActions.complain]: {
        id: EActions.complain,
        text: 'Пожаловаться',
        icon: <Icon20WarningTriangleOutline data-qa-id="complain-more" />,
        onClick: toolbarActions.sendComplain,
    },
};

export const toolbarStartPageItems: Pick<Record<EActions, ListItem>, TToolbarStartPageItemsKeys> = {
    [EActions.addToFavorites]: addToFavorites,
    [EActions.removeFromFavorites]: removeFromFavorites,
    [EActions.copy]: copy,
    [EActions.history]: history,
    [EActions.move]: move,
};

const toolBarViewModes = {
    [EViewMode.list]: EActions.viewList,
    [EViewMode.thumbs]: EActions.viewThumbs,
    [EViewMode.gallery]: EActions.viewGallery,
};

export const getViewItemByModes = (activeViewMode: EViewMode, viewModes: EViewMode[]): TToolbarItem => {
    const list = viewModes.map((viewMode) => ({
        ...((Object.values(toolbarDropdownItems).find((item) => item.key === viewMode) ?? {}) as ListItem),
        active: activeViewMode === viewMode,
    }));

    return {
        ...toolbarItems[EActions.view],
        dropdownPosition: EDropdownPosition.right,
        list,
    };
};

export const getViewItem = ({ viewMode, isGalleryAvailable }): TToolbarItem => {
    const currentViewType = toolBarViewModes[viewMode];
    const icon = toolbarDropdownItems[currentViewType].icon;
    const viewItemOptions = {
        ...toolbarItems[EActions.view],
        icon,
    };

    delete viewItemOptions.text;

    const viewItem: TToolbarItem = {
        ...viewItemOptions,
        dropdownPosition: EDropdownPosition.right,
        dropdownTheme: DropdownTheme.large,
        list: [
            {
                ...toolbarDropdownItems[EActions.viewList],
                active: viewMode === EViewMode.list,
            },
            {
                ...toolbarDropdownItems[EActions.viewThumbs],
                active: viewMode === EViewMode.thumbs,
            },
        ],
    };

    if (isGalleryAvailable) {
        viewItem.list?.push({
            ...toolbarDropdownItems[EActions.viewGallery],
            active: viewMode === EViewMode.gallery,
        });
    }

    return viewItem;
};

export const getUploadItem = (storage: EStorageType): TToolbarItem => {
    if (storage === EStorageType.alldocuments || storage === EStorageType.incomingPublic) {
        return {
            ...toolbarItems[EActions.upload],
            onClick: (from) => emitUploadStart({ type: 'file', from }),
        };
    }

    return {
        ...toolbarItems[EActions.upload],
        dropdownPosition: EDropdownPosition.right,
        list: [
            {
                ...toolbarDropdownItems[EActions.uploadFile],
                // todo: вернуть хоткеи для /integration в https://jira.vk.team/browse/CLOUDWEB-15655
                ...(isIntegrationStorage(storage) && {
                    hotkeyLabel: undefined,
                }),
            },
            {
                ...toolbarDropdownItems[EActions.uploadFolder],
                // todo: вернуть хоткеи для /integration в https://jira.vk.team/browse/CLOUDWEB-15655
                ...(isIntegrationStorage(storage) && {
                    hotkeyLabel: undefined,
                }),
            },
        ],
    };
};

export const getSortItem = ({ sortType, sortOrder, storage }): TToolbarItem => {
    const asc = sortOrder === ESortOder.asc;
    const newOrder = !asc ? 'asc' : 'desc';

    const sortByName = {
        ...toolbarDropdownItems[EActions.sortName],
        active: sortType === ESortTypes.name,
        onClick: () => toolbarActions.sort(`name-${sortType === 'name' ? newOrder : sortOrder}`),
        asc,
    };

    const sortBySize = {
        ...toolbarDropdownItems[EActions.sortSize],
        active: sortType === ESortTypes.size,
        onClick: () => toolbarActions.sort(`size-${sortType === 'size' ? newOrder : sortOrder}`),
        asc,
    };

    const sortByMtimeOrDeletedAt = {
        ...toolbarDropdownItems[EActions.sortDate],
        active: sortType === ESortTypes.mtime || sortType === ESortTypes.deletedAt,
        onClick: () =>
            toolbarActions.sort(
                `${getSortDateType(storage)}-${sortType === ESortTypes.mtime || sortType === ESortTypes.deletedAt ? newOrder : sortOrder}`
            ),
        asc,
        text: getSortDateText(storage),
    };

    let sortItems: TToolbarDropdownItem[] = IS_BIZ_USER
        ? [sortBySize, sortByName, sortByMtimeOrDeletedAt]
        : [sortByName, sortBySize, sortByMtimeOrDeletedAt];

    if (IS_BIZ_USER && (storage === EStorageType.sharedIncoming || storage === EStorageType.attaches)) {
        sortItems = sortItems.filter((item) => item.id !== EActions.sortDate);
    }

    return {
        ...toolbarItems[EActions.sort],
        dropdownPosition: EDropdownPosition.right,
        list: sortItems,
    };
};

export const getEditorsItem = (availableCreateEditors: CreatorsDescRecord[]) =>
    availableCreateEditors.reduce((acc, cur) => {
        switch (cur.name) {
            case 'docx':
                return [...acc, toolbarDropdownItems[EActions.createDoc]];
            case 'xlsx':
                return [...acc, toolbarDropdownItems[EActions.createCell]];
            case 'pptx':
                return [...acc, toolbarDropdownItems[EActions.createPpt]];
            default:
                return acc;
        }
    }, [] as ListItem[]);

export const getDownloadItem = (
    items: CloudItem[],
    filesWithFaces: PublicFile[],
    currentFolder: CloudItem,
    isRoot?: boolean,
    downloadButtonRef?: React.RefObject<HTMLDivElement>,
    isEbookReader?: boolean
): TToolbarItem => {
    const itemsSize = getDownloadItemSize(items);
    const itemsFacesSize = getDownloadItemSize(filesWithFaces);
    const folderSize =
        Boolean(currentFolder) &&
        ('sizeWithoutRestricted' in currentFolder ? currentFolder.sizeWithoutRestricted : 'size' in currentFolder && currentFolder.size);

    const itemsCount = items.length;

    let hintText = '';
    let text = 'Скачать';

    if (itemsCount && itemsSize) {
        text = `Скачать ${itemsSize}`;
        hintText = isEbookReader ? text : itemsSize;
    } else if (itemsFacesSize) {
        text = `Скачать ${itemsFacesSize}`;
        hintText = itemsFacesSize;
    } else if (folderSize) {
        const size = isRoot ? '' : ` ${getSize(folderSize)}`;
        text = `Скачать${size}`;
        hintText = getSize(folderSize);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const ext = items[0]?.ext;

    if (itemsCount === 1 && ext && downloadAsJpeg.isAvailable({ ext, storage: items[0].storage })) {
        return {
            ...toolbarItems[EActions.download],
            id: 'downloadAsJpeg',
            text,
            openDropdownByArrow: true,
            dropdownPosition: EDropdownPosition.right,
            list: downloadAsJpeg.getDropdownItems({ itemOrId: items[0], gaSuffix: 'toolbar', ext }),
            dropdownTheme: DropdownTheme.jpeg,
            ref: downloadButtonRef,
        };
    }
    return { ...toolbarItems[EActions.download], text, hint: hintText, ref: downloadButtonRef };
};

export const getSelectItem = ({ itemsCount, itemsAllCount, allSelected, disabled }): TToolbarItem => {
    const text = allSelected && itemsCount ? 'Снять выделение' : 'Выделить все';
    const showCountText = Boolean(itemsCount);
    const showText = !IS_MY_TEAM;
    const collapse = !showCountText && IS_MY_TEAM;
    const itemsCountText = itemsAllCount ? `${itemsCount} из ${itemsAllCount}` : itemsCount;

    return {
        ...toolbarItems[EActions.selectAll],
        icon: itemsCount ? (
            <Icon20CheckCircleOutlineOn
                fill={isRebranding && isDarkTheme ? 'var(--vkui--color_icon_accent)' : '#005FF9'}
                data-qa-id="selectAll"
            />
        ) : (
            <Icon20CheckCircleOutline data-qa-id="selectAll" />
        ),
        collapse,
        hint: IS_MY_TEAM ? text : undefined,
        text: (
            <>
                {showCountText && <span className={styles.count}>{itemsCountText}</span>}
                {showText && text}
            </>
        ),
        disabled,
    };
};

export const getCloneItem = (showTooltip: boolean) => {
    return {
        ...toolbarItems[EActions.clone],
        tooltipId: showTooltip ? EToolbarItemTooltip.publicCreateCloud : undefined,
    };
};

export const getClearItem = (itemsCount: number): TToolbarItem => {
    const itemsCountText = itemsCount ? 'Удалить навсегда' : 'Очистить корзину';
    return {
        ...toolbarItems[EActions.clear],
        id: EActions.clear,
        icon: <Icon20DeleteOutline data-qa-id="clear" />,
        text: itemsCountText,
    };
};
