import { Spacing } from '@vkontakte/vkui';
import { xray } from 'lib/xray';
import React, { type ReactElement, memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_DEV } from 'reactApp/appHelpers/configHelpers';
import { mspProIframeBaseProductId, mspProIframeTitle } from 'reactApp/appHelpers/featuresHelpers/features/buyMspIframe';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { VkBuyModalWithSidebar } from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar';
import { EPaymentModalType } from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar.types';
import { initProducts } from 'reactApp/modules/products/products.module';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { SASS_PAYMENT_FEATURES } from 'reactApp/sections/SassLanding/SassLanding.data';
import { showSassNoTariffDialog } from 'reactApp/sections/SassLanding/SassLanding.helpers';
import { getSassQueryProduct } from 'reactApp/sections/SassLanding/SassLanding.selectors';
import type { RootState } from 'reactApp/store';
import Content from 'reactApp/ui/Content/Content';
import { sendFrameMessage } from 'reactApp/ui/IframePages/BuyDisableAds/utils';
import { isFramed } from 'reactApp/utils/isFramed';
import opener from 'reactApp/utils/opener';

import styles from './BuyMspPro.css';

interface BuyIframeMspProQueryParams {
    productId?: string;
    fchoTitle?: string;
}

export enum BuyMspPostMessageType {
    size = 'SIZE',
    close = 'CLOSE',
    success = 'SUCCESS',
}

export const BuyMspProIframe = memo((): ReactElement => {
    const { productId: productIdFromQuery, fchoTitle: fastCheckoutTitleFromQuery }: BuyIframeMspProQueryParams = getQueryParams();

    const dispatch = useDispatch();
    const prdctId = productIdFromQuery || mspProIframeBaseProductId;
    const titleText = fastCheckoutTitleFromQuery || mspProIframeTitle;
    const bottomTitleSpace = 6;
    const topTitleSpace = 18;

    const product = useSelector((state: RootState) => getSassQueryProduct(state, prdctId ?? undefined));
    const { isLoaded } = useSelector(ProductsSelectors.getLifeCycleState);

    const titleRef = useRef<HTMLDivElement>(null);

    const onSuccess = useCallback(() => {
        xray.send(`buy-msp-pr-scss`);
        sendFrameMessage({ type: BuyMspPostMessageType.success });
    }, []);

    const onDmrResizeEvent = useCallback((height) => {
        // Высота заголовка и spacing'ов вокруг него
        const titleHeight = titleRef.current ? titleRef.current.offsetHeight + topTitleSpace + bottomTitleSpace : 0;
        sendFrameMessage({ type: BuyMspPostMessageType.size, height: height + titleHeight });
    }, []);

    const onClose = useCallback(() => {
        xray.send(`buy-msp-pr-cls`);
        sendFrameMessage({ type: BuyMspPostMessageType.close });
    }, []);

    useEffect(() => {
        xray.send(`buy-msp-pr-opn`);
        dispatch(initProducts());
    }, [dispatch]);

    if (!isFramed() && !IS_DEV) {
        xray.send(`buy-msp-pro-frame_error_not_frame`);
        opener('/', true);
    }

    if (!isLoaded) {
        return <></>;
    }

    if (!product || !product?.available) {
        showSassNoTariffDialog();
        return <></>;
    }

    return (
        <>
            <Content wrapClass={styles.content}>
                <Spacing size={topTitleSpace} className={styles.spacing} />
                <div className={styles.header} ref={titleRef}>
                    <div className={styles.title}>{titleText}</div>
                </div>
                <Spacing size={bottomTitleSpace} className={styles.spacing} />
                <VkBuyModalWithSidebar
                    productId={product.id}
                    isFrame
                    paySource={'mail-msp-tags'}
                    onClose={onClose}
                    onSuccess={onSuccess}
                    type={EPaymentModalType.fastcheckout}
                    withoutSidebar={false}
                    hideOtherTariffs={true}
                    hideSwitchSidebarBlock={true}
                    featuresList={SASS_PAYMENT_FEATURES}
                    onDmrResize={onDmrResizeEvent}
                />
            </Content>
        </>
    );
});

BuyMspProIframe.displayName = 'BuyMspProIframe';
