import type { PayloadAction } from '@reduxjs/toolkit';
import { logger } from 'lib/logger';
import { weblinksEmailAccessChangeV4ApiCall } from 'reactApp/api/weblinks/WeblinksEmailAccessChangeApiCall';
import { weblinksEmailAccessDeleteV4ApiCall } from 'reactApp/api/weblinks/WeblinksEmailAccessDeleteApiCall';
import { weblinksEmailAccessListV4ApiCall } from 'reactApp/api/weblinks/WeblinksEmailAccessListApiCall';
import { AB_FEATURE_NAME } from 'reactApp/components/SharingNewBiz/SharingNew.constants';
import { sendXray } from 'reactApp/utils/ga';
import { validateEmail } from 'reactApp/utils/helpers';
import { delay, put, select, takeEvery } from 'redux-saga/effects';

import { contactsSlice } from '../contacts/contacts.module';
import { getItemById, getStorageItemById } from '../storage/storage.selectors';
import { EStorageType } from '../storage/storage.types';
import {
    weblinkAccessControlLoadStart,
    weblinkAccessControlSetError,
    weblinkDeleteAllEmailAccess,
    weblinkDeleteEmailAccess,
    weblinkSetEmailAccess,
    weblinkSetEmailAccessList,
} from './weblinkAccessControl.actions';
import { getWeblinkAccessError } from './weblinkAccessControl.helpers';
import type {
    IWeblinkAccessControlLoadStart,
    IWeblinkDeleteEmailAccessRights,
    IWeblinkSetEmailAccessRights,
} from './weblinkAccessControl.types';

function* setEmailAccessSaga(action: PayloadAction<IWeblinkSetEmailAccessRights>) {
    const { accessRight, email, weblink } = action.payload;

    if (!validateEmail(email)) {
        yield put(
            weblinkAccessControlSetError({
                error: 'Вы ввели неправильные символы. Убедитесь в правильном написании электронного адреса и попробуйте снова',
            })
        );
        return;
    }

    yield put(contactsSlice.actions.addRequest([{ emails: [email] }]));

    try {
        const { data } = yield weblinksEmailAccessChangeV4ApiCall({
            weblink,
            access: accessRight,
            email,
        });

        yield put(weblinkSetEmailAccessList({ share: data.email_accesses }));
    } catch (err) {
        logger.error(err);
        yield put(weblinkAccessControlSetError({ error: getWeblinkAccessError(err) }));
    }
}

function* deleteEmailAccessSaga(action: PayloadAction<IWeblinkDeleteEmailAccessRights>) {
    const { email, weblink } = action.payload;

    try {
        const { data } = yield weblinksEmailAccessDeleteV4ApiCall({
            weblink,
            email,
        });
        yield put(weblinkSetEmailAccessList({ share: data.email_accesses }));
    } catch (err) {
        logger.error(err);
        yield put(weblinkAccessControlSetError({ error: getWeblinkAccessError(err) }));
    }
}

function* accessControlLoadStartSaga(action: PayloadAction<IWeblinkAccessControlLoadStart>) {
    const { id, storage } = action.payload;

    let item = yield select((state) => (storage ? getStorageItemById(state, EStorageType[storage], id) : getItemById(state, id)));

    const timer = Date.now();
    while (!item) {
        if (Date.now() - timer > 10 * 1000) {
            yield put(weblinkAccessControlSetError({ error: 'Не удалось получить список пользователей' }));
            return;
        }
        yield delay(1000);
        item = yield select((state) => getItemById(state, id));
    }

    const { weblink } = item;

    try {
        const { data } = yield weblinksEmailAccessListV4ApiCall({
            weblink,
        });

        sendXray([AB_FEATURE_NAME, 'wblnk', 'usrs', 'loaded'], {
            [`count_${data?.email_accesses?.length}`]: 1,
        });

        yield put(weblinkSetEmailAccessList({ share: data.email_accesses }));
    } catch (err) {
        sendXray([AB_FEATURE_NAME, 'wblnk', 'usrs', 'err']);
        logger.error(err);
        yield put(weblinkAccessControlSetError({ error: getWeblinkAccessError(err) }));
    }
}

function* deleteAllEmailAccessSaga() {
    yield put(weblinkSetEmailAccessList({ share: [] }));
}

export function* watchWeblinkAccessControl() {
    yield takeEvery(weblinkSetEmailAccess.toString(), setEmailAccessSaga);
    yield takeEvery(weblinkDeleteEmailAccess.toString(), deleteEmailAccessSaga);

    yield takeEvery(weblinkAccessControlLoadStart.toString(), accessControlLoadStartSaga);
    yield takeEvery(weblinkDeleteAllEmailAccess.toString(), deleteAllEmailAccessSaga);
}
