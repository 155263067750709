import throttle from 'lodash.throttle';
import { useEffect, useState } from 'react';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';

const MIN_KEYBOARD_HEIGHT = 300;

export const useDetectKeyboardOpen = () => {
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
    const isMobile = EnvironmentSelectors.isMobile();

    useEffect(() => {
        const listener = () => {
            const newState = window?.visualViewport ? window.screen.height - MIN_KEYBOARD_HEIGHT > window.visualViewport.height : false;
            setIsKeyboardOpen((prevVal) => {
                return prevVal !== newState ? newState : prevVal;
            });
        };

        const throttledListener = throttle(listener, 100);

        if (window?.visualViewport && isMobile) {
            window.visualViewport.addEventListener('resize', throttledListener);
        }
        return () => {
            if (window?.visualViewport && isMobile) {
                window.visualViewport.removeEventListener('resize', throttledListener);
            }
        };
    }, []);

    return isKeyboardOpen;
};
