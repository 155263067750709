import classNames from 'clsx';
import React, { useCallback, useEffect, useRef } from 'react';
import { Portal } from 'react-portal';
import { ENABLE_FULL_RESPONSIVE, IS_B2B_BIZ_USER, IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { useShowTooltip } from 'reactApp/components/SharingWindow/SharingNew/Weblink/hooks/useShowTooltip';
import type { SnackbarItem } from 'reactApp/modules/snackbar/snackbar.types';
import { AccessRights } from 'reactApp/types/Tree';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { CopyWeblinkTooltip } from 'reactApp/ui/CopyWeblinkTooltip/CopyWeblinkTooltip';
import { ShadeRight } from 'reactApp/ui/ShadeRight/ShadeRight';
import { Icon24CopyOutline } from 'reactApp/ui/touchIcons';
import { CopyIcon, LinkIcon } from 'reactApp/ui/VKUIIcons';
import { noop } from 'reactApp/utils/helpers';

import styles from './CopyWeblink.css';
import { renderPreCopyWeblink } from './PreCopyWeblinkDialog/preCopyWeblink.helpers';
import { useCopyWeblink } from './useCopyWeblink';

interface CopyProps {
    onCopy?: (props?: any) => any;
    onClick?: (props?: any) => any;
    publicUrl?: string;
    autoCopy?: boolean;
    theme?: 'folder' | 'publish';
    type?: 'link' | 'new' | 'sharingTabs';
    isPhone?: boolean;
    isDownloadable?: boolean;
    weblinkAccessRights?: AccessRights;
    isFolder?: boolean;
    weblinkDomestic?: boolean;
    fileName?: string;
    isModifiedWindow?: boolean;
    buttonMobile?: string;
    // tempexp_17237-next-line
    tooltipEnabledByExp?: boolean;
    folderName?: string;
    successSnackbar?: SnackbarItem;
}

const sharingLinkId = 'sharing-link-fakedoor-tooltip';

// eslint-disable-next-line max-lines-per-function
export const CopyWeblink = ({
    onCopy = noop,
    onClick = noop,
    buttonMobile = 'Поделиться',
    type,
    publicUrl,
    autoCopy,
    isPhone,
    isDownloadable,
    weblinkAccessRights,
    isFolder,
    theme,
    fileName,
    isModifiedWindow,
    weblinkDomestic,
    tooltipEnabledByExp = false,
    folderName,
    successSnackbar,
}: CopyProps) => {
    const input = useRef<HTMLInputElement>(null);

    const isReadWriteWeblinkAccess = weblinkAccessRights && weblinkAccessRights === AccessRights.rw;
    const { needShowTooltip, closeTooltip, openTooltip } = useShowTooltip({
        tooltipId: sharingLinkId,
        preventShow: isReadWriteWeblinkAccess || !tooltipEnabledByExp || isPhone,
        autoShow: !isReadWriteWeblinkAccess && tooltipEnabledByExp,
    });

    const tooltipContainerRef = useRef<HTMLDivElement>(null);

    const { copy } = useCopyWeblink({
        isPhone,
        isFolder,
        isDownloadable,
        isModifiedWindow,
        isReadWriteWeblinkAccess,
        folderName,
        successSnackbar,
        onCopy,
    });

    const handleCopy = useCallback(
        (e?: React.MouseEvent<HTMLInputElement | HTMLButtonElement | SVGSVGElement>) => {
            e?.stopPropagation();
            if (!input || !input.current) {
                return;
            }

            copy(input.current.value, input.current);
        },
        [copy]
    );
    const renderPreCopyDialog = useCallback(() => {
        renderPreCopyWeblink({ onSuccess: handleCopy, fileName });
    }, [fileName, handleCopy]);

    const handleCopyButtonClick = useCallback(
        (e?: React.MouseEvent<HTMLInputElement | HTMLButtonElement | SVGSVGElement>) => {
            if (!isFolder && IS_B2B_BIZ_USER && !weblinkDomestic) {
                return renderPreCopyDialog();
            }

            handleCopy(e);
        },
        [isFolder, weblinkDomestic, renderPreCopyDialog, handleCopy]
    );

    const handleClick = useCallback(() => {
        onClick?.();
    }, [onClick]);

    const handleInputClick = useCallback(() => {
        if (!input.current) {
            return;
        }
        input.current.select();
        input.current.setSelectionRange(0, input.current.value.length);
        openTooltip();
    }, []);

    const handleCm = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
    }, []);

    useEffect(() => {
        if (!!publicUrl && autoCopy && !IS_BIZ_USER && input.current) {
            copy(input.current.value, input.current);
        }
    }, []);

    if (type === 'new') {
        return (
            <div
                className={classNames({
                    [styles[`root_type_${type}`]]: type,
                    [styles.root_phone]: isPhone,
                    [styles.root_type_new_responsive]: ENABLE_FULL_RESPONSIVE,
                })}
                onContextMenu={handleCm}
                ref={tooltipContainerRef}
            >
                <input value={publicUrl} className={styles.input} ref={input} readOnly onClick={handleInputClick} />
                {needShowTooltip && (
                    <Portal>
                        <CopyWeblinkTooltip target={tooltipContainerRef} onClose={closeTooltip} />
                    </Portal>
                )}
                <Button
                    theme={isPhone ? 'vk' : 'octavius'}
                    primary
                    middle
                    onClick={handleCopyButtonClick}
                    sizeMode={isPhone ? ButtonSizeMode.big : ButtonSizeMode.middle}
                    className={styles.button}
                >
                    {isPhone ? buttonMobile : 'Скопировать'}
                </Button>
            </div>
        );
    }

    if (type === 'sharingTabs') {
        return (
            <div
                className={classNames({
                    [styles[`root_type_${type}`]]: type,
                    [styles.root_phone]: isPhone,
                    [styles.root_type_new_responsive]: ENABLE_FULL_RESPONSIVE,
                })}
                onContextMenu={handleCm}
                ref={tooltipContainerRef}
            >
                {needShowTooltip && (
                    <Portal>
                        <CopyWeblinkTooltip target={tooltipContainerRef} onClose={closeTooltip} />
                    </Portal>
                )}
                <input value={publicUrl} className={styles.input_sharingTabs} ref={input} readOnly onClick={handleInputClick} />
                <Button
                    theme={isPhone ? 'vk' : 'octavius'}
                    primary
                    middle
                    onClick={handleCopyButtonClick}
                    sizeMode={isPhone ? ButtonSizeMode.big : ButtonSizeMode.middle}
                    className={styles.button}
                >
                    <div className={styles.sharingTabs_button}>
                        <Icon24CopyOutline data-qa-name="copy-link-icon" width={20} height={20} />
                        {isPhone ? buttonMobile : <span>Скопировать</span>}
                    </div>
                </Button>
            </div>
        );
    }

    return (
        <div
            className={classNames(styles.controls, {
                [styles[`controls_${theme}`]]: !!theme,
            })}
            onContextMenu={handleCm}
        >
            <div className={styles.link}>
                <LinkIcon height={20} width={20} />
            </div>
            <ShadeRight theme={theme}>
                <input value={publicUrl} className={styles.input} ref={input} title="Скопировать" onClick={handleCopy} readOnly />
            </ShadeRight>
            <div className={styles.copy} title="Копировать ссылку" onClick={handleClick}>
                <CopyIcon data-qa-name="copy-link-icon" onClick={handleCopy} />
            </div>
        </div>
    );
};
