import { logger } from 'lib/logger';
import { PROMO_TARIFFS } from 'reactApp/appHelpers/configHelpers';
import { getHomeItemById } from 'reactApp/modules/home/home.selectors';
import { toggleDisablePublicDownload } from 'reactApp/modules/modifying/modifying.actions';
import { PaidInfoSelectors } from 'reactApp/modules/paidInfo/paidInfo.selectors';
import { loadProductsAndOpenFastCheckout } from 'reactApp/modules/payment/payment.module';
import { productsController } from 'reactApp/modules/products/products.controller';
import type { publicDisableDownload } from 'reactApp/modules/public/public.actions';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { channel } from 'redux-saga';
import { put, select, take } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

export function* handleBuyPublicDisableDownloadSubscription() {
    try {
        yield call(productsController.loadProducts);
        // todo: заменить на новую ручку billinginfo
        const userWithoutPayment = yield select(PaidInfoSelectors.isUserWithoutPayment);

        const productId = userWithoutPayment ? PROMO_TARIFFS.default64GbTrial : PROMO_TARIFFS.publicPassword;

        const waitForPay = channel();

        yield put(
            loadProductsAndOpenFastCheckout({
                productId,
                paySource: 'public_dwnld_trial',
                title: 'Подписка Mail Space: запрет на скачивание и другие опции',
                hideOtherTariffs: true,
                onSuccess: () => waitForPay.put(true),
            })
        );

        yield take(waitForPay);
        waitForPay.close();

        yield put(
            showSnackbarAction({
                id: 'pay-success',
                text: 'Подписка подключена. Теперь вы можете ограничивать скачивания',
                closable: true,
                type: SnackbarTypes.success,
            })
        );
    } catch (error) {
        logger.error(error);
    }
}

export function* handlePublicDisableDownload(action: ReturnType<typeof publicDisableDownload>) {
    try {
        const id = action.payload.id;
        const disableDownload = action.payload.disableDownload;

        const item = yield select(getHomeItemById, id);

        // TODO: add and call new api
        // eslint-disable-next-line no-console
        console.log('TODO: вызов апи запрета скачивания паблика:', disableDownload);

        yield put(toggleDisablePublicDownload({ item, disableDownload }));
    } catch (error) {
        logger.error(error);
    }
}
