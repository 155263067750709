import browser from 'Cloud/browser';
import React, { type FC, useMemo, useRef } from 'react';
import { getIsCloudAvailabe } from 'reactApp/appHelpers/appHelpers';
import { IS_B2B_BIZ_USER, IS_BIZ_USER, IS_TABLET_BROWSER, IS_TARM } from 'reactApp/appHelpers/configHelpers';
import { seoEnable } from 'reactApp/appHelpers/featuresHelpers/features/seoEnable';
import { type AttachesItem, EAttachTypes } from 'reactApp/modules/attaches/attaches.types';
import { isEditPublicCopy } from 'reactApp/modules/features/features.helpers';
import { type CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';
import { CloneMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/CloneMenuButton/CloneMenuButton';
import { CloseMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/CloseMenuButton/CloseMenuButton';
import { DownloadMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/DownloadMenuButton/DownloadMenuButton';
import { EditMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/EditMenuButton/EditMenuButton';
import { FacesMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/FacesMenuButton/FacesMenuButton';
import { MenuInfo } from 'reactApp/ui/ReactViewer/ViewerMenu/MenuInfo/MenuInfo';
import { MenuItemName, MoreMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/MoreMenuButton/MoreMenuButton';
import { ShareMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/ShareMenuButton/ShareMenuButton';
import {
    type ViewerMenuProps,
    ViewerMenuCenter,
    ViewerMenuEnd,
    ViewerMenuStart,
    ViewerMenuWrapper,
} from 'reactApp/ui/ReactViewer/ViewerMenu/ViewerMenu';
import { Zoomer } from 'reactApp/ui/ReactViewer/ViewerMenu/Zoomer/Zoomer';
import { RegisterCloudTooltip } from 'reactApp/ui/RegisterCloudTooltip/RegisterCloudTooltip';
import { canCloneWeblink } from 'reactApp/utils/cloneHelpers';

import { MenuLogo } from '../MenuLogo/MenuLogo';

interface Props extends ViewerMenuProps {
    file: CloudFile | AttachesItem;
}

export const PublicMenu: FC<Props> = ({
    file,
    fileStorage,
    showLogo,
    faces = 0,
    embedded,
    isAuthorized,
    isArchiveItem,
    isAttachesEnabled,
    parent,
    zoom,
    decreaseZoom,
    increaseZoom,
    onShowFaceFilter,
    onPublish,
    onClone,
    showDownload,
    onDownload,
    showImageEdit,
    onImageEdit,
    isPublicCopyEditable,
    onPublicCopyEdit,
    onOpenInMyCloud,
    onMail,
    onComplain,
    showClose,
    onClose,
}) => {
    const isTempAttachType = 'attachType' in file && file.attachType === EAttachTypes.temporary;
    const showCloneWeblink = canCloneWeblink(isAuthorized, fileStorage, true);
    const showClone = !file.weblinkDownloadable && showCloneWeblink && !isTempAttachType;
    const isNotAuthTarm = IS_TARM && !isAuthorized;
    const showOpenInCloud = !showClone && !file.weblinkDownloadable && embedded && getIsCloudAvailabe();
    const showMail = isAttachesEnabled && !embedded && !isArchiveItem;
    const showComplain = seoEnable && embedded;

    const isEditable = 'weblinkAccessRights' in file && file.weblinkAccessRights === 'rw';
    const showPublicCopyEdit =
        (isEditPublicCopy || isEditable) &&
        isPublicCopyEditable &&
        showCloneWeblink &&
        !IS_BIZ_USER &&
        !(IS_TABLET_BROWSER || browser.isIpadOs());

    const shareRef = useRef<HTMLDivElement>(null);

    const moreItems = useMemo(
        () => [
            ...(showOpenInCloud ? [{ name: MenuItemName.OPEN_IN_MY, divider: true, onClick: onOpenInMyCloud }] : []),
            ...(showMail ? [{ name: MenuItemName.MAIL, divider: true, onClick: onMail }] : []),
            ...(showComplain
                ? [
                      {
                          name: MenuItemName.COMPLAIN,
                          onClick: onComplain,
                      },
                  ]
                : []),
        ],
        [showOpenInCloud, onOpenInMyCloud, showMail, onMail, showComplain, onComplain]
    );

    return (
        <ViewerMenuWrapper>
            <ViewerMenuStart>
                {((showLogo && IS_B2B_BIZ_USER) || !!parent) && <MenuLogo />}
                <MenuInfo file={file} />
            </ViewerMenuStart>
            <ViewerMenuCenter>
                {zoom && <Zoomer value={zoom} decrease={decreaseZoom} increase={increaseZoom} />}
                {faces ? <FacesMenuButton count={faces} onClick={onShowFaceFilter} /> : null}
            </ViewerMenuCenter>
            <ViewerMenuEnd>
                {!isArchiveItem && embedded && !file.weblinkDownloadable && (
                    <>
                        <ShareMenuButton
                            ref={shareRef}
                            text={showCloneWeblink ? 'Получить ссылку' : 'Отключить ссылку'}
                            onClick={() => {
                                onPublish(file);
                            }}
                        />
                        {fileStorage !== EStorageType.stock && <RegisterCloudTooltip targetRef={shareRef} closeOnScroll={true} />}
                    </>
                )}
                {showImageEdit && (
                    <EditMenuButton
                        onClick={() => {
                            onImageEdit(file);
                        }}
                    />
                )}
                {showPublicCopyEdit && (
                    <EditMenuButton
                        hint={isEditable ? 'Редактировать' : 'Редактировать копию'}
                        onClick={() => {
                            onPublicCopyEdit(file);
                        }}
                    />
                )}
                {!isArchiveItem && showClone && !isNotAuthTarm && <CloneMenuButton onClick={onClone} />}
                {showDownload && (
                    <DownloadMenuButton
                        file={file}
                        onClick={() => {
                            onDownload(file);
                        }}
                    />
                )}
                <MoreMenuButton list={moreItems} />
                {showClose && (embedded || !!parent) && (
                    <CloseMenuButton
                        onClick={() => {
                            onClose(file);
                        }}
                    />
                )}
            </ViewerMenuEnd>
        </ViewerMenuWrapper>
    );
};
