import { getFeature } from 'Cloud/Application/FeaturesEs6';
import cloudIcon from 'img/icons/rebranding-cloud.svg?url';
import type { BillingDataPartner, BillingDataPlatform } from 'reactApp/api/billing/data/BillingDataAPICall.types';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';

type LinkInfo = { icon: string; iconDark?: string; accountLink?: string };

type BilingDataLinkRecord = Partial<Record<BillingDataPlatform | BillingDataPartner, LinkInfo>>;

const billingDataLinks: BilingDataLinkRecord = getFeature('billing-data-links') || {};

function patchIcons(linkInfo: LinkInfo) {
    const isDarkTheme = isDarkThemeModeEnabled();
    const darkIcon = linkInfo.iconDark ?? linkInfo.icon;
    return { accountLink: linkInfo?.accountLink, icon: isDarkTheme ? linkInfo.icon : darkIcon };
}

export function getBillingDataLinks(type: BillingDataPlatform, partner?: BillingDataPlatform | BillingDataPartner) {
    const linkInfo = billingDataLinks[partner || type] ?? { icon: cloudIcon };
    return patchIcons(linkInfo);
}

export function getPromoIcon(partner: string) {
    const linkInfo = billingDataLinks[partner] ?? { icon: cloudIcon };
    return patchIcons(linkInfo).icon;
}
