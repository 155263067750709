import { Icon20ArrowRightOutline } from '@vkontakte/icons';
import { flatten } from 'ramda';
import React, { useMemo } from 'react';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { Link } from 'reactApp/ui/Link/Link';
import { useWindowResizer } from 'reactApp/ui/VirtualList/VirtualList.hooks';

import { AlbumsGrid } from '../AlbumsGrid/AlbumsGrid';
import type { IProps } from './AlbumSection.types';
import styles from './AlbumsSection.css';
import { getAvailableItemsNumber } from './AlbumsSection.helpers';

export function AlbumsSection(props: IProps) {
    const { children, title, sectionRouteId } = props;
    const content = flatten(children);

    const [rootRef, containerWidth] = useWindowResizer();

    const availableAlbumCount = getAvailableItemsNumber(containerWidth);

    const albums = useMemo(() => {
        return content.slice(0, availableAlbumCount);
    }, [content, availableAlbumCount]);

    return (
        <section ref={rootRef} className={styles.root} data-qa-id="albums-section" data-qa-title={title}>
            <div className={styles.title} data-qa-id="albums-section-title">
                {title}
                <div className={styles.sectionButtonWrapper} data-qa-id="section-show-all-button">
                    <Link id={sectionRouteId} className={styles.sectionButton} storage={EStorageType.albums}>
                        Посмотреть все
                        <Icon20ArrowRightOutline className={styles.icon} />
                    </Link>
                </div>
            </div>
            <div className={styles.section}>
                <AlbumsGrid>{albums}</AlbumsGrid>
            </div>
        </section>
    );
}
