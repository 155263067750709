import classNames from 'clsx';
import React, { memo } from 'react';
import { useIntersectionObserver } from 'reactApp/hooks/useInterSectionObserver';
import { Loader } from 'reactApp/ui/Loader/Loader';

import styles from './VirtualList.css';
import type { IVirtualListLoaderProps } from './VirtualList.types';

export const VirtualListLoader = memo(function VirtualListLoader(props: IVirtualListLoaderProps) {
    const loadMore = props.isLoading ? (): Promise<void> => Promise.resolve() : props.loadOnScroll;
    const intersectingRef = useIntersectionObserver({ onIntersecting: loadMore, rootMargin: '300px' });

    return (
        <div ref={intersectingRef} className={classNames(styles.loader, props.className)}>
            <Loader />
        </div>
    );
});
