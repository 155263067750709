import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_BIZ_USER, IS_MOBILE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

const value: 'a' | 'b' = getFeature('space-overquota-simplified');

if (!IS_MOBILE_BROWSER && !IS_BIZ_USER) {
    registerExperiment('space-over-smpl', value, [AnalyticEventNames.QUOTA_BLOCK_OVER_VIEW, AnalyticEventNames.QUOTA_BLOCK_OVER_CLICK]);
}

export const isSpaceOverquotaSimplified = !IS_MOBILE_BROWSER && !IS_BIZ_USER && value === 'b';
