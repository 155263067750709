import classNames from 'clsx';
import React, { type ReactElement, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { type Contact, contactsSlice } from 'reactApp/modules/contacts/contacts.module';
import { contactsSelectors } from 'reactApp/modules/contacts/contacts.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { Avatar } from 'reactApp/ui/Avatar/Avatar';
import { Button } from 'reactApp/ui/Button/Button';
import { InputSuggest } from 'reactApp/ui/InputSuggest/InputSuggest';
import { EInputSuggestTheme } from 'reactApp/ui/InputSuggest/InputSuggest.types';

import styles from './SharingNewAddUser.css';
import { useAccessRights } from './SharingNewAddUser.hooks';
import type { IProps } from './SharingNewAddUser.types';

export const SharingNewAddUser = React.memo(function SharingNewAddUser(props: IProps): ReactElement {
    const { isPhone, isMounted, handleAddUser, isLoading, error, clearError, isReadOnly = false, acList } = props;
    const dispatch = useDispatch();
    const contactSuggestions = useSelector(contactsSelectors.getContactSuggestions);
    const activeUserEmail = useSelector(UserSelectors.getEmail);
    const isInputDisabled = isLoading || Boolean(isMounted);
    const [isButtonDisabled, setButtonDisabled] = useState<boolean>(true);
    const [currentEmail, setCurrentEmail] = useState<string>('');

    const [accessRight, renderDropdown] = useAccessRights(isInputDisabled, isReadOnly);

    const filteredSuggestions = useMemo(() => {
        const excludeList: { email: string | null }[] = [...(acList ?? []), { email: activeUserEmail }];
        return contactSuggestions?.filter((suggest) => !excludeList?.find((item) => item.email === suggest.email)) ?? null;
    }, [acList, activeUserEmail, contactSuggestions]);

    const handleLoginChange = useCallback(
        (e, { value }) => {
            setCurrentEmail(value);
            setButtonDisabled(!value.length);
            clearError?.();
        },
        [clearError]
    );

    const renderItem = useCallback((user: Contact, width = 0): ReactElement | null => {
        if (!user) {
            return null;
        }

        return (
            <div className={styles.user} data-qa-email={user.email} style={{ width }}>
                <div className={styles.avatar}>
                    <Avatar email={user.email} size={36} />
                </div>
                <div className={styles.info}>
                    {user.name && <div className={styles.name}>{user.name}</div>}
                    <div className={styles.email}>{user.email}</div>
                </div>
            </div>
        );
    }, []);

    const onAddClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            handleAddUser({ currentEmail, accessRight });
            setButtonDisabled(true);
            setCurrentEmail('');
        },
        [accessRight, currentEmail, handleAddUser]
    );

    const handleGetSuggestions = useCallback(
        (filter) => {
            dispatch(contactsSlice.actions.suggestRequest(filter));
        },
        [dispatch]
    );

    const action = useMemo(() => {
        if (isReadOnly) {
            return;
        }

        return <div className={styles.dropdown}>{renderDropdown()}</div>;
    }, [isReadOnly, renderDropdown]);

    return (
        <form className={classNames(styles.root, { [styles.root_phone]: isPhone })}>
            <div className={styles.inputRow}>
                <div className={styles.inputField}>
                    <InputSuggest
                        value={currentEmail}
                        placeholder="Введите email"
                        hasError={Boolean(error)}
                        suggestions={currentEmail ? filteredSuggestions : []}
                        getSuggestionItemId={(item) => item.email}
                        onChange={handleLoginChange}
                        onRequestSuggestions={handleGetSuggestions}
                        disabled={isInputDisabled}
                        focus={false}
                        action={isPhone ? undefined : action}
                        theme={isPhone ? EInputSuggestTheme.vk : undefined}
                        renderSuggestionItem={renderItem}
                    />
                </div>
                <div className={styles.buttonWrapper}>
                    <div className={styles.action}>{isPhone ? action : undefined}</div>
                    <Button
                        theme={isPhone ? 'vk' : 'octavius'}
                        primary
                        middle
                        disabled={isInputDisabled || isButtonDisabled}
                        onClick={onAddClick}
                    >
                        Добавить
                    </Button>
                </div>
            </div>
            {Boolean(error) && <div className={styles.errorMessage} dangerouslySetInnerHTML={{ __html: error }} />}
        </form>
    );
});
