import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';
import { startSelecting } from 'reactApp/modules/selections/selections.actions';
import type { CloudItem } from 'reactApp/modules/storage/storage.types';
import { setShowUploaderAction } from 'reactApp/modules/uploadList/uploadList.module';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { getItemsSize } from 'reactApp/modules/userQuotaCleaner/helpers/getItemsSize';
import {
    deleteLettersFromQuota,
    deleteUserQuotaCleanerAllItems,
    deleteUserQuotaCleanerAllLetters,
    deleteUserQuotaCleanerItems,
    reloadGroupAfterDelete,
    setCloudRemove,
    setIsItemsDeleteError,
    setMessagesRemove,
    setNeedsGroupReload,
} from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.actions';
import { getCurrentGroup } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.selectors';
import type { QuotaCleanerGroup, QuotaCleanerItem } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.types';
import { sendQuotaCleanerGa } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { UserQuotaGroupId } from 'reactApp/types/QuotaCleaner';
import { renderDeleteConfirmationDialog } from 'reactApp/ui/DeleteConfirmationDialog/deleteConfirmationDialog.toolkit';
import { getSize } from 'reactApp/utils/sizeHelper';

interface Props {
    items: QuotaCleanerItem[] | CloudItem[];
    totalSize?: number;
    totalLength?: number;
}

/**
 * Удаление элементов квоты в рамках одной группы
 */
export const useDeleteItems = ({ items, totalSize, totalLength }: Props) => {
    const deleteAllItems = totalSize && totalLength;
    const dispatch = useDispatch();
    const currentGroup = useSelector(getCurrentGroup);
    let currentItems: QuotaCleanerItem[] | CloudItem[] = [];
    if (currentGroup) {
        currentItems = Object.values(currentGroup.list);
    }

    const isMailBinDisabled = useSelector(UserQuotaSelectors.getIsMailBinDisabled);
    const isMobile = useSelector(QuotaLandingSelectors.isMobile);

    const { groupId, groupType, totalCount, size: groupSize } = currentGroup as QuotaCleanerGroup;

    const deleteSize = useMemo(() => {
        const size = totalSize || getItemsSize(items as QuotaCleanerItem[]);
        return {
            size,
            sizeString: getSize(size),
        };
    }, [items, totalSize]);

    const onDeleteSuccess = () => {
        sendQuotaCleanerGa({
            action: 'cleaner_cleaned',
            dwh: {
                name_block: groupId,
                size_files: groupSize,
                count_files: totalCount,
                size_files_cleaned: deleteSize.size,
                count_files_cleaned: items.length,
            },
        });
    };

    const ids = useMemo(() => (deleteAllItems ? currentItems : items).map((item) => item.deleteId), [currentItems, items, deleteAllItems]);
    const itemIds = useMemo(() => (deleteAllItems ? currentItems : items).map((item) => item.id), [currentItems, items, deleteAllItems]);

    const onGroupReload = useCallback(() => {
        dispatch(reloadGroupAfterDelete({ ids, itemIds, groupId, size: deleteSize.size }));
        dispatch(setNeedsGroupReload({ value: true }));
    }, [ids, itemIds, groupId, deleteSize, dispatch]);

    const onDelete = useCallback(
        (withOutMessagesRemove: boolean, withOutCloudRemove: boolean, withSnackBar: boolean) => {
            if (deleteAllItems) {
                dispatch(
                    deleteUserQuotaCleanerAllItems({
                        size: deleteSize.size,
                        withOutMessagesRemove,
                        withOutCloudRemove,
                        withSnackBar,
                        onDeleteSuccess,
                    })
                );
            } else {
                dispatch(
                    deleteUserQuotaCleanerItems({
                        ids,
                        itemIds,
                        items,
                        groupId,
                        groupType,
                        size: deleteSize.size,
                        withOutMessagesRemove,
                        withOutCloudRemove,
                        withSnackBar,
                        onDeleteSuccess,
                    })
                );
            }
            if (isMobile) {
                dispatch(setShowUploaderAction(false));
                dispatch(startSelecting());
            }
        },
        [ids, itemIds, items, deleteSize, currentGroup, deleteAllItems, onDeleteSuccess, dispatch]
    );

    const onLettersDelete = useCallback(() => {
        if (deleteAllItems) {
            dispatch(deleteUserQuotaCleanerAllLetters({ size: deleteSize.size, onDeleteSuccess }));
        } else {
            dispatch(deleteLettersFromQuota({ ids, itemIds, groupId, groupType, size: deleteSize.size, onDeleteSuccess }));
        }
    }, [ids, deleteAllItems, itemIds, groupId, groupType, deleteSize, onDeleteSuccess, dispatch]);

    const callback = useCallback(() => {
        dispatch(setIsItemsDeleteError({ value: false })); // сбрасываем ошибки удаления, если они были

        if (groupId === UserQuotaGroupId.MailBin) {
            dispatch(setMessagesRemove({ value: true }));
        }

        if (groupId === UserQuotaGroupId.CloudBin) {
            dispatch(setCloudRemove({ value: true }));
        }

        const count = totalLength || items.length;
        renderDeleteConfirmationDialog({
            count,
            groupType,
            groupId,
            isTwoStepLettersDelete: !isMailBinDisabled,
            allSelected: deleteAllItems,
            onDelete,
            onLettersDelete,
            onGroupReload,
        });
    }, [totalLength, deleteAllItems, items, onDelete, isMailBinDisabled, onLettersDelete, onGroupReload, groupType, groupId, dispatch]);

    return {
        callback,
        deleteSize,
    };
};
