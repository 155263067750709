import { Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { type VFC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IS_IOS_MOBILE, IS_REACT_PROMO_QUOTA_TARIFFS_NEW_PAGE } from 'reactApp/appHelpers/configHelpers';
import { VK_UI_DARK_THEME } from 'reactApp/constants';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { historyPush } from 'reactApp/modules/router/router.module';
import { getIdByStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { useTariffBuyDialog } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage.hooks';
import type { RootState } from 'reactApp/store';
import { Button } from 'reactApp/ui/Button/Button';
import opener from 'reactApp/utils/opener';

import { DISCOUNTS } from './DiscountCard.constant';
import styles from './DiscountCard.css';
import { formatTitle, sendAnalytics } from './DiscountCard.helpers';
import type { Props } from './DiscountCard.types';

export const DiscountCard: VFC<Props> = ({
    title,
    text,
    type,
    buttonText,
    link,
    id,
    analyticsName,
    isRebrandingQuotaLanding,
    promoConditions,
}) => {
    const dispatch = useDispatch();

    const product = useSelector((state: RootState) => ProductsSelectors.getProductById(state, id));

    const { space, titleWithoutSpace } = formatTitle(title);

    const { openTariffBuy } = useTariffBuyDialog();

    const onClick = useCallback(() => {
        closePopupHelper(popupNames.MOBILE_DISCOUNTS);

        if (type === DISCOUNTS.promocode) {
            sendAnalytics('activate-promocode', title);
            return;
        }

        sendAnalytics('action-click', analyticsName || title);

        if (type === DISCOUNTS.autumnPromo) {
            const search = `?utm_source=cloud_touch_${
                IS_IOS_MOBILE ? 'ios' : 'and'
            }&utm_medium=touch_union_quota_landing&utm_campaign=action_autumn_action`;
            if (IS_REACT_PROMO_QUOTA_TARIFFS_NEW_PAGE) {
                opener(`/promo/quota/${search}`, true);
            } else {
                dispatch(
                    historyPush({
                        id: getIdByStorage(EStorageType.quotaCleaner),
                        search,
                    })
                );
                return;
            }
        }

        if (product) {
            const price = product?.hasTrial ? 0 : product?.discountPrice || product?.price || 0;

            openTariffBuy({ id, price, period: product?.period, paySource: 'discount_card' });
        }
    }, [id, openTariffBuy, product, title, type, analyticsName]);

    const button = useMemo(
        () => (
            <Button size="tiny" theme="vk" middle className={classNames(styles.button, styles.buttonSecondary)} onClick={onClick}>
                {buttonText}
            </Button>
        ),
        [buttonText, onClick]
    );

    if (id && id !== 'promocode' && id !== 'autumnPromo' && !product) {
        return null;
    }

    const containsDarkTheme = document.body.classList.contains(VK_UI_DARK_THEME);

    return (
        <div
            className={classNames(styles.root, styles[`root_type_${type}`], {
                [styles.root_rebrandingQuotaLanding]: isRebrandingQuotaLanding,
            })}
        >
            <div className={styles.title}>
                {space && (
                    <span
                        className={classNames(styles[`${type}_type_units`], {
                            [styles.units_darkTheme]: containsDarkTheme,
                        })}
                    >
                        {space}
                    </span>
                )}
                <span>{titleWithoutSpace}</span>
            </div>
            <Text className={styles.text}>{text}</Text>
            {link ? (
                <Link reloadDocument={IS_REACT_PROMO_QUOTA_TARIFFS_NEW_PAGE || id === DISCOUNTS.promocode} to={link}>
                    {button}
                </Link>
            ) : (
                button
            )}
            {promoConditions ? <span className={styles.promoConditions}>{promoConditions}</span> : null}
        </div>
    );
};
