import { IS_B2B_BIZ_USER } from 'reactApp/appHelpers/configHelpers';

export const getWeblinkAccessError = (error): string => {
    const status = error.status;
    const response = error.response.error || error.response;

    if (status === 404 && response === 'NOT/FOUND') {
        return 'Не удалось получить список пользователей';
    }

    if (IS_B2B_BIZ_USER && status === 404 && response === 'CONDITION/DOMAIN') {
        return 'Нельзя добавить этого пользователя';
    }

    return 'Произошла неизвестная ошибка';
};
