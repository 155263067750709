import React, { type FC, type MouseEvent, type PropsWithChildren } from 'react';
import { isAttachFile } from 'reactApp/modules/attaches/attaches.helpers';
import type { AttachesItem } from 'reactApp/modules/attaches/attaches.types';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import type { CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';
import type { ZoomState } from 'reactApp/ui/ReactViewer/ReactViewer.types';
import { AlbumsMenu } from 'reactApp/ui/ReactViewer/ViewerMenu/AlbumsMenu/AlbumsMenu';
import { AttachMenu } from 'reactApp/ui/ReactViewer/ViewerMenu/AttachMenu/AttachMenu';
import { IntegrationMenu } from 'reactApp/ui/ReactViewer/ViewerMenu/IntegrationMenu/IntegrationMenu';
import { MainMenu } from 'reactApp/ui/ReactViewer/ViewerMenu/MainMenu/MainMenu';
import { PublicMenu } from 'reactApp/ui/ReactViewer/ViewerMenu/PublicMenu/PublicMenu';
import { SharingAttachMenu } from 'reactApp/ui/ReactViewer/ViewerMenu/SharingAttachMenu/SharingAttachMenu';
import { StockMenu } from 'reactApp/ui/ReactViewer/ViewerMenu/StockMenu/StockMenu';
import { StoryMenu } from 'reactApp/ui/ReactViewer/ViewerMenu/StoryMenu/StoryMenu';

import styles from './ViewerMenu.css';

export const ViewerMenuWrapper: FC<PropsWithChildren<unknown>> = ({ children }) => {
    return <div className={styles.wrapper}>{children}</div>;
};

export const ViewerMenuStart: FC<PropsWithChildren<unknown>> = ({ children }) => {
    return <div className={styles.start}>{children}</div>;
};

export const ViewerMenuCenter: FC<PropsWithChildren<unknown>> = ({ children }) => {
    return <div className={styles.center}>{children}</div>;
};

export const ViewerMenuEnd: FC<PropsWithChildren<unknown>> = ({ children }) => {
    return <div className={styles.end}>{children}</div>;
};

export interface ViewerMenuProps {
    file?: CloudFile | AttachesItem | null;
    fileStorage?: EStorageType | null;

    showLogo?: boolean;

    onActive: ({ isActive }: { isActive: boolean }) => void;
    onFileClick: () => void;

    count: number;
    currentIdx: number;
    transitionTime?: number;
    isPaused?: boolean;
    nextBlockId?: number | null;
    onNavigation: () => void;

    zoom: ZoomState;
    decreaseZoom: (evt: MouseEvent) => void;
    increaseZoom: (evt: MouseEvent) => void;

    faces: number;
    onShowFaceFilter: () => void;

    onPublish: (file: CloudFile | AttachesItem) => void;

    showEdit?: boolean;
    onEdit: (file: CloudFile | AttachesItem) => void;

    showPdfEdit?: boolean;
    normalizedOvidiusEnabled: boolean;
    onPdfEdit: (file: CloudFile | AttachesItem) => void;
    onPdfCopyEdit: (file: CloudFile | AttachesItem) => void;

    showImageEdit: boolean;
    onImageEdit: (file: CloudFile | AttachesItem) => void;

    onAttachCopyEdit: (file: CloudFile | AttachesItem) => void;

    isPublicCopyEditable: boolean;
    onPublicCopyEdit: (file: CloudFile | AttachesItem) => void;

    onClone: () => void;

    showDownload: boolean;
    onDownload: (file: CloudFile | AttachesItem) => void;

    allowPrinting: boolean;
    onPrint: (file: CloudFile | AttachesItem) => void;

    enableFavorites: boolean;
    onToggleFavorites: (file: CloudFile | AttachesItem) => void;

    onOpenInMyCloud: () => void;
    onOpenParent: () => void;
    onCopy: () => void;
    onMove: () => void;
    onMail: () => void;
    onDelete: (file: CloudFile | AttachesItem) => void;
    onComplain: () => void;

    showClose: boolean;
    onClose: (file: CloudFile | AttachesItem) => void;

    isAuthorized: boolean;
    isArchiveItem?: boolean;
    parent?: string;
    embedded?: boolean;
    sharingAttach?: boolean;
    isAttachesEnabled?: boolean;
    topTitle?: string;
    topSubTitle?: string;
}

export const ViewerMenu: FC<ViewerMenuProps> = (props) => {
    const file = props.file;

    if (!file) {
        return null;
    }

    const { isAlbums, isAttaches, isStock, isPublic, isStory, isIntegration, isInlineIntegration } = getStorage(
        props.fileStorage || file.storage
    );

    if (props.sharingAttach) {
        return <SharingAttachMenu {...props} file={file} />;
    }

    if (isAlbums) {
        return <AlbumsMenu {...props} file={file} />;
    }

    if (isAttaches && isAttachFile(file)) {
        return <AttachMenu {...props} file={file} />;
    }

    if (isStock) {
        return <StockMenu {...props} file={file} />;
    }

    if (isPublic) {
        return <PublicMenu {...props} file={file} />;
    }

    if (isStory) {
        return <StoryMenu {...props} file={file} />;
    }

    if (isIntegration || isInlineIntegration) {
        return <IntegrationMenu {...props} file={file} />;
    }

    return <MainMenu {...props} file={file} />;
};
