import { Spacing, Text } from '@vkontakte/vkui';
import coverImage from 'img/usedSpacePostpaid/new-tariff.png';
import coverImageDark from 'img/usedSpacePostpaid/new-tariff-dark.png';
import React, { memo, useCallback, useEffect } from 'react';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';

import type { Props } from './UsedSpacePostpaidModal.types';
import styles from './UserSpacePostpaidModal.css';

export const UsedSpacePostpaidModal = memo<Props>(({ onShow, onClick, onCloseClick, onClose, onSecondary }) => {
    useEffect(() => {
        onShow();
    }, []);

    const handlePrimaryClick = useCallback(() => {
        onClick();
        onClose();
    }, [onClick, onClose]);
    const handleCloseClick = useCallback(() => {
        onCloseClick?.();
        onClose();
    }, [onClose, onCloseClick]);
    const handleSecondaryClick = useCallback(() => {
        onSecondary();
        onClose();
    }, [onClose, onSecondary]);

    const content = (
        <>
            <Spacing size={12} />
            <Text className={styles.text} weight="3">
                В новой подписке Mail Space 1 ГБ = 1 ₽. Занимайте сколько нужно и оплачивайте в конце месяца
            </Text>
            <Spacing size={24} />
        </>
    );

    const containsDarkTheme = isDarkThemeModeEnabled();

    return (
        <WhatsNewDialog
            primaryButtonText="Попробовать"
            secondaryButtonText="Неинтересно"
            title="Платите только за занятые гигабайты"
            imageUrl={containsDarkTheme ? coverImageDark : coverImage}
            content={content}
            dialogSize="average"
            buttonTheme="vk"
            onClick={handlePrimaryClick}
            onClose={handleCloseClick}
            onSecondary={handleSecondaryClick}
        />
    );
});

UsedSpacePostpaidModal.displayName = 'UsedSpacePostpaidModal';
