import { getFeatureParam } from 'Cloud/Application/FeaturesEs6';
import config from 'Cloud/config';
import escapeAdblock from 'lib/escapeAdblock';
import { EMPTY_FILE_HASH } from 'reactApp/constants/magicIdentificators';
import { getNameById } from 'reactApp/modules/file/utils';
import { JavaScriptError } from 'reactApp/modules/uploading/errors/JavaScriptError';
import { fileSystemEntryReader } from 'reactApp/modules/uploading/helpers/fs/FileSystemEntryReader';
import { readFileContent } from 'reactApp/modules/uploading/helpers/fs/readFile';
import { tryGetFileFromHandle } from 'reactApp/modules/uploading/helpers/fs/tryGetFileFromHandle';
import { UploadingDescriptor } from 'reactApp/modules/uploading/serviceClasses/UploadingDescriptor';
import { uploadingLog } from 'reactApp/modules/uploading/serviceClasses/UploadingLog';
import type { UploadingPacketConfig } from 'reactApp/modules/uploading/serviceClasses/UploadingPacketConfig';
import { EUploadReasonSource } from 'reactApp/modules/uploading/serviceClasses/UploadingReason';
import { ELogLevel } from 'reactApp/modules/uploading/uploading.types';
import { captureException } from 'reactApp/utils/tracer';
import { uid } from 'reactApp/utils/uid';

import { FileWithoutHashFail } from '../../fails/FileWithoutHashFail';
import { sendGaUploaderNew } from '../uploading.helpers';

/**
 * В Safari возникает ошибка при попытке прочитать файл более 2GB.
 * Firefox выбрасывает исключение NS_ERROR_OUT_OF_MEMORY,
 * когда размер файла превышает объем свободной памяти.
 * В Chrome может падать вкладка, когда не хватает памяти.
 * Поэтому будем пытаться читать файлы только небольшого размера.
 * @type {number}
 */
export const MAX_READABLE_SIZE = getFeatureParam('newUpload', 'maxReadableSize', 30) * 1024 * 1024;

export const FILE_WITHOUT_HASH_MAX_SIZE = config.get('ITEM_WITHOUT_HASH_MAX_SIZE');

const MAX_BLOB_SIZE_PER_READ = 21;

export const testIsInstanceOfFileSystemFileHandle = (fileHandle?: any) => {
    if (!fileHandle || typeof FileSystemFileHandle === 'undefined') {
        return false;
    }

    return fileHandle instanceof FileSystemFileHandle;
};

const testIsInstanceOfFileSystemDirectoryHandle = (fileHandle?: any) => {
    if (!fileHandle || typeof FileSystemDirectoryHandle === 'undefined') {
        return false;
    }

    return fileHandle instanceof FileSystemDirectoryHandle;
};

export async function createFileDescriptor(
    fileOrHandle: File | FileSystemFileHandle,
    uploadingPacketConfig: UploadingPacketConfig,
    parentPath = '/'
) {
    try {
        const isFileHandle = testIsInstanceOfFileSystemFileHandle(fileOrHandle);
        const fileHandle = isFileHandle ? (fileOrHandle as FileSystemFileHandle) : null;
        const file = isFileHandle ? await tryGetFileFromHandle(fileHandle as FileSystemFileHandle) : (fileOrHandle as File);

        if (!file) {
            return null;
        }

        const localName = normalizeToNfc(file.name);
        const relativePath =
            normalizeToNfc(
                file.webkitRelativePath?.replace(/\s+\//g, '/') /* Избавляемся от кейса, когда в конце названия папки есть пробел */
            ) || localName;
        const localPath = fileHandle ? parentPath + localName : `/${relativePath}`;
        const size = file.size;
        const id = uid();

        if (isIgnoredFileName(localName)) {
            sendGaUploaderNew('file', 'ignored_file');
            return null;
        }

        const descriptor = new UploadingDescriptor({
            id,
            file,
            fileHandle,
            size,
            uploadingPacketConfig,
            localName,
            localPath,
            isFile: true,
            isDirectory: false,
            isUnreadDirectory: false,
        });

        uploadingLog.info(
            {
                id,
                type: 'file',
                kind: 'file',
                size,
                localPath,
            },
            ELogLevel.LEVEL_IMPORTANT
        );

        return descriptor;
    } catch (error) {
        sendGaUploaderNew('createfiledescr', 'error');
        captureException(error, {
            issueKey: 'uploadError:createFileDescriptor',
            file: fileOrHandle?.name,
        });

        return null;
    }
}

export const createEntryDescriptor = async (
    entryOrHandle: FileSystemEntry | FileSystemDirectoryHandle,
    uploadingPacketConfig: UploadingPacketConfig,
    parentPath = '/'
): Promise<UploadingDescriptor | null> => {
    try {
        const isDirHandle = testIsInstanceOfFileSystemDirectoryHandle(entryOrHandle);
        const fileHandle = isDirHandle ? (entryOrHandle as FileSystemDirectoryHandle) : null;
        const entry = isDirHandle ? null : (entryOrHandle as FileSystemEntry);

        const localName = normalizeToNfc(entryOrHandle.name);
        const localPath = entry ? normalizeToNfc(entry.fullPath) : parentPath + localName;
        const isDirectory = fileHandle ? fileHandle.kind === 'directory' : Boolean(entry?.isDirectory);
        const id = uid();

        if (isIgnoredFileName(localName)) {
            sendGaUploaderNew('file', 'ignored_file');
            return null;
        }

        const descriptor = new UploadingDescriptor({
            id,
            entry,
            fileHandle,
            uploadingPacketConfig,
            localName,
            localPath,
            isFile: entry ? entry.isFile : false,
            isDirectory,
            isUnreadDirectory: isDirectory,
        });

        if (!isDirectory) {
            try {
                const file = await fileSystemEntryReader.getFile(entry as FileSystemFileEntry);
                // при dnd размер может придти позже setInputFilesAction
                // TODO: fix -1 pb
                descriptor.size = file?.size || -1;
            } catch (error) {
                if (error instanceof JavaScriptError) {
                    return null;
                }
                throw error;
            }
        }

        const kind = isDirectory ? 'folder' : 'file';

        uploadingLog.info(
            {
                id,
                type: 'entry',
                kind,
                localPath,
            },
            ELogLevel.LEVEL_IMPORTANT
        );

        return descriptor;
    } catch (error) {
        sendGaUploaderNew('createdirdescr', 'error');
        captureException(error, {
            issueKey: 'uploadError:createEntryDescriptor',
            file: entryOrHandle?.name,
        });

        return null;
    }
};

async function createDescriptor(child, uploadingPacketConfig: UploadingPacketConfig, directoryPath: string) {
    return testIsInstanceOfFileSystemFileHandle(child)
        ? await createFileDescriptor(child, uploadingPacketConfig, directoryPath)
        : await createEntryDescriptor(child, uploadingPacketConfig, testIsInstanceOfFileSystemDirectoryHandle(child) ? directoryPath : '/');
}

// Просто поиск сразу в глубину папки падает с переполнением стека при большом числе папок
async function findChildren(descriptor: UploadingDescriptor) {
    const result: UploadingDescriptor[] = [];
    const childUnreadDirs: UploadingDescriptor[] = [];

    if (descriptor.isDirectory || descriptor.isFile) {
        result.push(descriptor);
    }

    if (descriptor.isDirectory && descriptor.isUnreadDirectory) {
        const directoryPath = `${descriptor.localPath}/`;

        try {
            let children: (FileSystemEntry | FileSystemFileHandle | FileSystemDirectoryHandle)[] = [];
            if (descriptor.fileHandle) {
                for await (const [_, value] of (descriptor.fileHandle as FileSystemDirectoryHandle).entries()) {
                    children.push(value);
                }
            } else {
                children = await fileSystemEntryReader.readDirectory(descriptor.entry as FileSystemDirectoryEntry);
            }

            const childrenDescriptors: UploadingDescriptor[] = [];

            for (const child of children) {
                const res = await createDescriptor(child, descriptor.uploadingPacketConfig, directoryPath);

                if (!res) {
                    continue;
                }

                (res.isUnreadDirectory ? childUnreadDirs : result).push(res);
                childrenDescriptors.push(res);
            }

            addChildren(descriptor, childrenDescriptors);
        } catch (error) {
            addError(descriptor, error);
        }
    }

    return { children: result, childUnreadDirs };
}

const addChildren = (descriptor: UploadingDescriptor, descriptors: UploadingDescriptor[]) => {
    descriptors.forEach((item) => {
        item.parent = descriptor;
    });
    descriptor.isUnreadDirectory = false;
    descriptor.children = [...descriptor.children, ...descriptors];
};

const addError = (descriptor: UploadingDescriptor, reason) => {
    descriptor.isUnreadDirectory = false;
    descriptor.error = reason;
};

export function* processDescriptorsDir(descriptors: UploadingDescriptor[]) {
    // Чтобы в начале шли элементы первого уровня и попали в первый чанк на отрисовку, чтобы сразу их увидеть в UI
    const resultFiles: UploadingDescriptor[] = [];
    const resultDirs: UploadingDescriptor[] = [];
    const resultChildren: UploadingDescriptor[] = [];
    const descriptorsToProcess: UploadingDescriptor[] = [];

    for (const item of descriptors) {
        if (item.isUnreadDirectory) {
            const { children, childUnreadDirs } = yield findChildren(item);

            if (childUnreadDirs.length) {
                descriptorsToProcess.push(...childUnreadDirs);
            }

            if (children?.length) {
                resultChildren.push(...children);
            }
        } else {
            resultFiles.push(item);
        }
    }

    return { resultFiles, resultDirs, resultChildren, descriptorsToProcess };
}

/**
 * .DS_Store – параметры отображения директории в macOS
 * Desktop.ini – параметры отображения директории в Windows
 * Thumbs.db – эскизы изображений в Windows
 * ehthumbs.db – эскизы видеофайлов в Windows
 */
const ignoredFileNames = /^(\.DS_Store|(D|d)esktop\.ini|Thumbs\.db|ehthumbs\.db)$/;

const isIgnoredFileName = (name: string) => ignoredFileNames.test(name);

// macOS использует Unicode NFD в именах файлов и папок,
// а наш сервер преобразует все имена в NFC.
// Нормальзуем локальные имена и пути в NFC,
// чтобы избежать ошибок при сравнении строк.
const normalizeToNfc = (name: string) => (typeof String.prototype.normalize === 'function' ? name.normalize('NFC') : name);

export const joinPath = (...paths: string[]) => {
    let path = paths[0];
    for (let i = 1; i < paths.length; i++) {
        const pathPart = paths[i] as string;

        if (pathPart) {
            const pathEndsWithSlash = path.charAt(path.length - 1) === '/';
            const pathPartStartsWithSlash = pathPart.charAt(0) === '/';

            if (pathEndsWithSlash && pathPartStartsWithSlash) {
                path = path + pathPart.slice(1);
            } else if (!pathEndsWithSlash && !pathPartStartsWithSlash) {
                path = `${path}/${pathPart}`;
            } else {
                path = path + pathPart;
            }
        }
    }

    return path;
};

export const getVisibleNameParts = (path: string) => {
    const urlComponents = getNameParts(path);
    const name = urlComponents.name;
    const extension = urlComponents.extension;

    if (name) {
        urlComponents.name = getVisiblePath(name);
    }

    if (extension) {
        urlComponents.extension = getVisiblePath(extension);
    }

    return urlComponents;
};

const getNameParts = (path: string) => {
    const urlComponents = {
        name: '',
        extension: '',
    };

    const fullName = escapeAdblock(getNameById(path));
    const lastIndex = fullName.lastIndexOf('.');

    if (lastIndex > 0) {
        urlComponents.name = fullName.slice(0, lastIndex);
        urlComponents.extension = fullName.slice(lastIndex + 1);
    } else {
        urlComponents.name = fullName;
        urlComponents.extension = '';
    }

    return urlComponents;
};

const isMacOS = typeof navigator === 'object' && navigator.platform === 'MacIntel';

export const getVisiblePath = (path: string) => {
    let result = path;

    if (isMacOS) {
        // Finder в macOS отображает пользователю в имени файла слэш,
        // но в браузер попадает строка, в которой слэш заменен на двоеточие.
        // Создать файл с двоеточием в имени в macOS невозможно.
        // Будем использовать символ, который видит пользователь.
        result = result.replace(/:/g, '/');
    }

    return result;
};

export const isFirefox = typeof navigator === 'object' && navigator.userAgent.includes('Firefox/');

export const isEdge = typeof navigator === 'object' && navigator.userAgent.includes('Edge/');

export const getHexCode = async (file: File) => {
    if (file.size > FILE_WITHOUT_HASH_MAX_SIZE || file.size > MAX_BLOB_SIZE_PER_READ) {
        throw new Error('too large');
    }

    const arrayBuffer = await readFileContent(file, 0);

    const hexCode = convertArrayBufferToHexadecimalString(arrayBuffer);

    let cloudHexCode = EMPTY_FILE_HASH;

    // Так как байткод файла помещается в базу вместо hash,
    // необходимо дополнить байткод до 40 байт нулевыми значениями.
    // Например: строка '123' имеет байткод в шестнадцатеричном виде '313233',
    // а в API нужно отправить '3132330000000000000000000000000000000000'.
    cloudHexCode = hexCode + cloudHexCode.slice(hexCode.length);

    return cloudHexCode;
};

function convertArrayBufferToHexadecimalString(arrayBuffer: ArrayBuffer | null) {
    if (!arrayBuffer) {
        return '';
    }
    const dataView = new DataView(arrayBuffer);
    let hexCode = '';

    for (let i = 0, length = dataView.byteLength; i < length; i++) {
        let hexValue = dataView.getUint8(i).toString(16);

        if (hexValue.length < 2) {
            hexValue = `0${hexValue}`;
        }

        hexCode += hexValue;
    }

    hexCode = hexCode.toUpperCase();

    return hexCode;
}

export const getFullBody = (file: File, method: 'PUT' | 'POST' = 'PUT') => {
    if (file.size <= FILE_WITHOUT_HASH_MAX_SIZE) {
        // Для файлов размером меньше 21 байта
        // вместо hash в базу помещается байткод.
        const source = EUploadReasonSource.SOURCE_WEB_CLIENT;
        const stack = new Error('FileWithoutHashFail');
        throw new FileWithoutHashFail(stack, source);
    } else if (method === 'POST') {
        return getMultipartFormData(file);
    } else if (method === 'PUT') {
        // Для файлов размером больше 21 байт
        // сразу резолвим инстанс File,
        // так как он также является инстансом Blob,
        // а XMLHttpRequest умеет отправлять блобы.
        return file;
    } else {
        throw new TypeError('unsuported HTTP method');
    }
};

function getMultipartFormData(file: File) {
    const formData = new FormData();

    formData.append('file', file);

    return formData;
}
