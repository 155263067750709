import { type PayloadAction, createAction, createReducer } from '@reduxjs/toolkit';
import { extInfo } from 'lib/extInfo';
import { path, pathOr } from 'ramda';
import { getExtension, getItemNameWithoutExt } from 'reactApp/modules/file/utils';
import type {
    ApiFileHistoryItem,
    FileHistoryLoadRequestAction,
    FileHistoryLoadSuccessAction,
    FileHistoryRecoveryAction,
    State,
} from 'reactApp/modules/fileHistory/fileHistory.types';

const initialState: State = {
    isLoaded: false,
    isLoading: false,
    hasError: false,
    list: {},
    id: '',
};

export const fileHistoryLoadRequest = createAction<FileHistoryLoadRequestAction>('fileHistory/loadRequest');
export const fileHistoryLoadSuccess = createAction<FileHistoryLoadSuccessAction>('fileHistory/loadSuccess');
export const fileHistoryLoadFailure = createAction('fileHistory/loadFailure');
export const fileHistoryRecoveryRequest = createAction<FileHistoryRecoveryAction>('fileHistory/recoveryRequest');
export const fileHistoryRecoverySuccess = createAction('fileHistory/recoverySuccess');

export const fileHistoryReducer = createReducer(initialState, {
    [fileHistoryLoadRequest.type]: (state): void => {
        state.isLoaded = false;
        state.isLoading = true;
        state.hasError = false;
        state.list = {};
        state.id = '';
    },
    [fileHistoryLoadFailure.type]: (state): void => {
        state.isLoaded = true;
        state.isLoading = false;
        state.hasError = true;
    },
    [fileHistoryLoadSuccess.type]: (state, action: PayloadAction<FileHistoryLoadSuccessAction>): void => {
        const { item, data } = action?.payload;
        state.isLoading = false;
        state.isLoaded = true;
        state.id = item?.id;

        if (item && !data.length) {
            state.list[`${item.name}-0`] = {
                id: item.id,
                ext: item.ext,
                nameWithoutExt: item.nameWithoutExt,
                name: item.name,
                kind: item.kind,
                subKind: path(['subKind'], item),
                path: pathOr(item.id, ['home'], item),
                hash: pathOr('', ['hash'], item),
                date: pathOr(0, ['mtime'], item),
                size: item.size,
                isCurrent: true,
            };
        }

        data.forEach((item: ApiFileHistoryItem, index: number) => {
            const ext = getExtension(item);
            const nameWithoutExt = getItemNameWithoutExt(item.name, ext);
            const { kind, subKind } = extInfo.get(ext);

            const id = `${item.path}-${index}`;

            state.list[id] = {
                id,
                name: item.name,
                ext,
                nameWithoutExt,
                kind,
                subKind,
                path: item.path,
                hash: item?.hash || '',
                date: item.time * 1000,
                size: item.size,
                isCurrent: index === 0,
                actor: item.actor,
            };
        });
    },
});
