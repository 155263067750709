import { Spacing, Text } from '@vkontakte/vkui';
import image from 'img/quota-landing/tariff-poll-success.png';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { PROMO_TARIFFS } from 'reactApp/appHelpers/configHelpers';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { openTariffBuy } from 'reactApp/modules/payment/payment.module';
import { sendTariffPollAnalytics } from 'reactApp/sections/QuotaLanding/TariffPollDialog/TariffPollDialog.analytics';

import { getQuotaBillingSource } from '../QuotaLanding.helpers';
import styles from './TariffPollSuccessDialog.css';
import type { TariffPollSuccessDialogProps } from './TariffPollSuccessDialog.types';

export const TariffPollSuccessDialog = memo<TariffPollSuccessDialogProps>(({ onClose }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        emitAnalyticEvent(AnalyticEventNames.TARIFF_POLL_SUCCESS_SHOW);
        sendTariffPollAnalytics({ action: 'show', name: 'trial_survey_landing' });
    }, []);

    const content = useMemo(() => {
        return (
            <>
                <Spacing size={16} />
                <Text className={styles.text}>Оцените возможности подписки на практике. Если что, её можно отключить в любой момент</Text>
                <Spacing size={36} />
            </>
        );
    }, []);

    const handleBuyClick = useCallback(() => {
        sendTariffPollAnalytics({ action: 'click', name: 'trial_survey_landing' });
        dispatch(
            openTariffBuy({
                productId: PROMO_TARIFFS.tariffPollTrial,
                source: getQuotaBillingSource(),
                isMobile: false,
                paySource: 'trial_survey_landing',
            })
        );
        onClose?.();
    }, [onClose, dispatch]);

    const handleCancelClick = useCallback(() => {
        sendTariffPollAnalytics({ action: 'click', label: 'reject', name: 'trial_survey_landing' });
        onClose?.();
    }, [onClose]);

    const handleCloseClick = useCallback(() => {
        sendTariffPollAnalytics({ action: 'close', name: 'trial_survey_landing' });
        onClose?.();
    }, [onClose]);

    return (
        <WhatsNewDialog
            title="Спасибо! Для вас — топовый тариф за 1 ₽"
            content={content}
            imageUrl={image}
            primaryButtonText="Попробовать"
            onClick={handleBuyClick}
            secondaryButtonText="Не интересует"
            onSecondary={handleCancelClick}
            onClose={handleCloseClick}
            qaId="tariff-poll-success-modal"
            imageBgColor="var(--vkui--color_background_secondary)"
            buttonTheme="vk"
            dialogSize="tiny"
        />
    );
});

TariffPollSuccessDialog.displayName = 'TariffPollSuccessDialog';
