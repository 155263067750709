import { actionPanelReducer } from 'reactApp/modules/actionpanel/actionpanel.module';
import { activitiesReducer } from 'reactApp/modules/activities/activities.module';
import { albumReducer } from 'reactApp/modules/albums/albums.module';
import { allDocumentsReducer } from 'reactApp/modules/allDocuments/allDocuments.module';
import { attachesReducer } from 'reactApp/modules/attaches/attaches.reducer';
import { billingReducer } from 'reactApp/modules/billing/billing.module';
import { billingDataReducer } from 'reactApp/modules/billingData/billingData.module';
import { bizCategoryReducer } from 'reactApp/modules/bizCategories/bizCategories.module';
import { businessTemplatesReducer } from 'reactApp/modules/businessTemplates/businessTemplates.module';
import { buySubscriptionReducer } from 'reactApp/modules/buySubscription/buySubscription.module';
import { captchaReducer } from 'reactApp/modules/captcha/captcha.module';
import { contactsSlice } from 'reactApp/modules/contacts/contacts.module';
import { creditCardReducer } from 'reactApp/modules/creditCard/creditCard.module';
import { creditCardDmrLinkReducer } from 'reactApp/modules/creditCardDmrLink/creditCardDmrLink.module';
import { dialogReducer } from 'reactApp/modules/dialog/dialog.module';
import { domainFoldersReducer } from 'reactApp/modules/domainFolders/domainFolders.module';
import { ebookReducer } from 'reactApp/modules/ebook/ebook.module';
import { editorReducer } from 'reactApp/modules/editor/editor.module';
import { embeddedReducer } from 'reactApp/modules/embedded/embedded.module';
import { errorReducer } from 'reactApp/modules/error/error.reducer';
import { facesReducer } from 'reactApp/modules/faces/faces.module';
import { familyReducer } from 'reactApp/modules/family/family.module';
import { favoritesReducer } from 'reactApp/modules/favorites/favorites.reducer';
import { featuresReducer } from 'reactApp/modules/features/features.module';
import { feedReducer } from 'reactApp/modules/feed/feed.module';
import { fileHistoryReducer } from 'reactApp/modules/fileHistory/fileHistory.module';
import { filesFilterReducer } from 'reactApp/modules/filesFilter/filesFilter.module';
import { folderAccessControlListReduser } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.module';
import { galleryReducer } from 'reactApp/modules/gallery/gallery.module';
import { giftReducer } from 'reactApp/modules/gift/gift.reducer';
import { giftReceivedReducer } from 'reactApp/modules/giftReceived/giftReceived.reducer';
import { homeReducer } from 'reactApp/modules/home/home.module';
import { incomingReducer } from 'reactApp/modules/incoming/incoming.module';
import { incomingPublicReducer } from 'reactApp/modules/incomingPublic/incomingPublic.module';
import { integrationReducer } from 'reactApp/modules/integration/integration.module';
import { loadingReducer } from 'reactApp/modules/loading/loading.reducer';
import { mediaReducer } from 'reactApp/modules/media/media.module';
import { modifyingReducer } from 'reactApp/modules/modifying/modifying.module';
import { paidInfoReducer } from 'reactApp/modules/paidInfo/paidInfo.module';
import { paymentsReducer } from 'reactApp/modules/payments/payments.module';
import { documentsReducer } from 'reactApp/modules/personalDocuments/personalDocuments.module';
import { phReducer } from 'reactApp/modules/ph/ph.module';
import { productsReducer } from 'reactApp/modules/products/products.module';
import { profileReducer } from 'reactApp/modules/profile/profile.module';
import { promoReducer } from 'reactApp/modules/promo/promo.module';
import { promocodesReducer } from 'reactApp/modules/promocode/promocode.module';
import { promoTariffsReducer } from 'reactApp/modules/promoTariffs/promoTariffs.module';
import { publicReducer } from 'reactApp/modules/public/public.module';
import { purchasedGiftsReducer } from 'reactApp/modules/purchasedGifts/purchasedGifts.reducer';
import { quotaLandingReducer } from 'reactApp/modules/quotaLanding/quotaLanding.module';
import { routerReducer } from 'reactApp/modules/router/router.module';
import { searchReducer } from 'reactApp/modules/search/search.module';
import { selectionsReducer } from 'reactApp/modules/selections/selections.module';
import { settingsReducer } from 'reactApp/modules/settings/settings.module';
import { sharedReducer } from 'reactApp/modules/shared/shared.module';
import { sharedAutoDeleteReducer } from 'reactApp/modules/sharedAutoDelete/sharedAutoDelete.module';
import { sendSmsReducer } from 'reactApp/modules/smsSender/smsSender.module';
import { snackbarReducer } from 'reactApp/modules/snackbar/snackbar.reducer';
import { socialUserReducer } from 'reactApp/modules/socialUser/socialUser.module';
import { sortReducer } from 'reactApp/modules/sort/sort.module';
import { startReducer } from 'reactApp/modules/start/start.module';
import { stockReducer } from 'reactApp/modules/stock/stock.module';
import { storageReducer } from 'reactApp/modules/storage/storage.module';
import { storiesReducer } from 'reactApp/modules/stories/stories.module';
import { subscriptionsReducer } from 'reactApp/modules/subscriptions/subscriptions.module';
import { themeOutsourceReducer } from 'reactApp/modules/theme.outsource/theme.outsource.module';
import { themeReducer } from 'reactApp/modules/theme/theme.module';
import { themePublicReducer } from 'reactApp/modules/themePublic/themePublic.module';
import { trashbinReducer } from 'reactApp/modules/trashbin/trashbin.module';
import { treeReducer } from 'reactApp/modules/tree/tree.reducer';
import { uploadReducer } from 'reactApp/modules/upload/upload.module';
import { uploadingReducer } from 'reactApp/modules/uploading/uploading.module';
import { continueUploadReducer } from 'reactApp/modules/uploadList/continueUpload/continueUpload.module';
import { uploadListReducer } from 'reactApp/modules/uploadList/uploadList.module';
import { userReducer } from 'reactApp/modules/user/user.module';
import { userQuotaWithFamilyReducer } from 'reactApp/modules/userAndFamilyQuota/userAndFamilyQuota.module';
import { userQuotaReducer } from 'reactApp/modules/userQuota/userQuota.module';
import { userQuotaCleanerReducer } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.module';
import { viewerReducer } from 'reactApp/modules/viewer/viewer.module';
import { weblinkAccessControlReducer } from 'reactApp/modules/weblinkAccessControl/weblinkAccessControl.module';
import { welcomeReducer } from 'reactApp/modules/welcome/welcome.module';
import { makePopupReducer } from 'redux-popup';

export default {
    [contactsSlice.name]: contactsSlice.reducer,
    accessControlList: folderAccessControlListReduser,
    actionPanel: actionPanelReducer,
    activities: activitiesReducer,
    albums: albumReducer,
    allDocuments: allDocumentsReducer,
    attaches: attachesReducer,
    billing: billingReducer,
    billingData: billingDataReducer,
    bizCategories: bizCategoryReducer,
    buySubscription: buySubscriptionReducer,
    captcha: captchaReducer,
    continueUpload: continueUploadReducer,
    creditCard: creditCardReducer,
    creditCardDmrLink: creditCardDmrLinkReducer,
    dialog: dialogReducer,
    documents: documentsReducer,
    domainsFolders: domainFoldersReducer,
    ebook: ebookReducer,
    editor: editorReducer,
    errors: errorReducer,
    faces: facesReducer,
    family: familyReducer,
    favorites: favoritesReducer,
    features: featuresReducer,
    feed: feedReducer,
    fileHistory: fileHistoryReducer,
    filesFilter: filesFilterReducer,
    gallery: galleryReducer,
    gifts: giftReducer,
    home: homeReducer,
    incoming: incomingReducer,
    loaders: loadingReducer,
    media: mediaReducer,
    modifying: modifyingReducer,
    paidInfo: paidInfoReducer,
    payments: paymentsReducer,
    ph: phReducer,
    popup: makePopupReducer(),
    products: productsReducer,
    profile: profileReducer,
    promo: promoReducer,
    promoTariffs: promoTariffsReducer,
    promocodes: promocodesReducer,
    public: publicReducer,
    purchasedGifts: purchasedGiftsReducer,
    quotaLanding: quotaLandingReducer,
    receivedGifts: giftReceivedReducer,
    router: routerReducer,
    search: searchReducer,
    selections: selectionsReducer,
    settings: settingsReducer,
    shared: sharedReducer,
    sharedAutoDelete: sharedAutoDeleteReducer,
    smsSender: sendSmsReducer,
    snackbar: snackbarReducer,
    socialUser: socialUserReducer,
    sort: sortReducer,
    start: startReducer,
    stock: stockReducer,
    storage: storageReducer,
    stories: storiesReducer,
    subscriptions: subscriptionsReducer,
    theme: themeReducer,
    themeOutsource: themeOutsourceReducer,
    themePublic: themePublicReducer,
    trashbin: trashbinReducer,
    tree: treeReducer,
    upload: uploadReducer,
    uploadList: uploadListReducer,
    uploading: uploadingReducer,
    user: userReducer,
    userQuota: userQuotaReducer,
    userQuotaCleaner: userQuotaCleanerReducer,
    viewer: viewerReducer,
    welcome: welcomeReducer,
    embedded: embeddedReducer,
    integration: integrationReducer,
    userQuotaWithFamily: userQuotaWithFamilyReducer,
    incomingPublic: incomingPublicReducer,
    weblinkAccessControl: weblinkAccessControlReducer,
    businessTemplates: businessTemplatesReducer,
};
