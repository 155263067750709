import { Rubles } from 'Cloud/Application/Rubles';
import classNames from 'clsx';
import { parse, stringify } from 'qs';
import React, { type ReactElement, memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { HIDE_ADS, IS_HIDDEN_PUBLIC, IS_PUBLIC_OF_OVERQUOTA } from 'reactApp/appHelpers/configHelpers';
import { publicOverquota } from 'reactApp/appHelpers/featuresHelpers/features/publicOverquota';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { contactsSlice } from 'reactApp/modules/contacts/contacts.module';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { isPublicUploadEnabled } from 'reactApp/modules/public/public.selectors';
import { loadPublicAlbumPageRequest } from 'reactApp/modules/public/publicAlbum.actions';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { isEmptyFolder } from 'reactApp/modules/storage/storage.selectors';
import { type EStorageType, EActions } from 'reactApp/modules/storage/storage.types';
import { isUploaderVisible } from 'reactApp/modules/uploadList/uploadList.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { useRouteChangeProcessing } from 'reactApp/sections/MainPage/hooks/useRouteChangeProcessing';
import { EmptyPublicOverquota } from 'reactApp/ui/Empty/new/EmptyPublicOverquota';
import { ActionsBar } from 'reactApp/ui/Mobile/ActionsBar/ActionsBar';
import { ActionsCarousel } from 'reactApp/ui/Mobile/ActionsCarousel/ActionsCarousel';
import type { IActionItem } from 'reactApp/ui/Mobile/ActionsCarousel/useItems';
import { BreadcrumbsContainer as Breadcrumbs } from 'reactApp/ui/Mobile/Breadcrumbs/BreadcrumbsContainer';
import { Datalist } from 'reactApp/ui/Mobile/Datalist/Datalist';
import { DownloadAppButton } from 'reactApp/ui/Mobile/DownloadAppButton/DownloadAppButton';
import { ExpiresInfo } from 'reactApp/ui/Mobile/ExpiresInfo/ExpiresInfo';
import { Toolbar } from 'reactApp/ui/Mobile/Toolbar/Toolbar';
import { UploadInfo } from 'reactApp/ui/Mobile/UploadInfo/UploadInfo';
import { Password } from 'reactApp/ui/Public/Components/Password/Password';
import { RollContentAds } from 'reactApp/ui/RollContent/RollContentAds';
import { UploadBlockContainer } from 'reactApp/ui/UploadBlock/UploadBlockContainer';
import { UploadListMobileContainer } from 'reactApp/ui/UploadList/UploadListMobile';
import { sendCurrTimeRadar, sendPerfMetrics } from 'reactApp/utils/helpers';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { EQueryParams } from 'server/helpers/getRequestParams';

import styles from './MobilePublicPage.css';
import { MobilePublicThemedPageDatalist } from './MobilePublicThemedPageDatalist';
import { useToolbarOverlapDatalist } from './useToolbarOverlapDatalist';

const actionsFilter = (item: IActionItem) => [EActions.clone, EActions.download].includes(item.id);

interface MobilePublicPageProps {
    storage: EStorageType;
    isAlbum: boolean;
    supportThemedPage?: boolean;
}

export const MobilePublicPage = memo(({ storage, isAlbum = false, supportThemedPage = false }: MobilePublicPageProps): ReactElement => {
    const dispatch = useDispatch();
    const location = useLocation();

    const isAuthorized = !!useSelector(UserSelectors.getEmail);
    const isUploadEnabled = useSelector(isPublicUploadEnabled);
    const isSelecting = useSelector(SelectionsSelectors.isSelecting);
    const selectedIds = useSelector(SelectionsSelectors.getSelectedIdxs);
    const isEmpty = useSelector(isEmptyFolder);
    const showUploader = useSelector(isUploaderVisible);
    const isWebview = useSelector(EnvironmentSelectors.isWebview);
    const emptyFolderUploadAllowed = isEmpty && isUploadEnabled;
    const hasAdsAndEmptyFolderUploadAllowed = !HIDE_ADS && emptyFolderUploadAllowed;
    const showActionsBar = selectedIds.length > 0;
    const isBiz = useSelector(UserSelectors.isBizUser);

    const fromDeeplink = getQueryParams()[EQueryParams.fromDeeplink];
    const isSuperAppWebView = isWebview && fromDeeplink;

    const showDisabledForOverquotaOwner = IS_PUBLIC_OF_OVERQUOTA && publicOverquota;

    const [showUploadInfo, setShowUploadInfo] = useState(true);

    useRouteChangeProcessing({
        storage,
        path: location.pathname.replace(`/${isAlbum ? 'album' : storage}/`, ''),
        search: location.search,
    });

    useEffect(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'touch-public' });
        sendPerfMetrics('mpubpage');
        sendCurrTimeRadar('mpubpage_mnt');
    }, []);

    // @todo унести в сагу
    useEffect(() => {
        if (isUploadEnabled && isAuthorized) {
            dispatch(contactsSlice.actions.listRequest());
        }

        dispatch(loadPublicAlbumPageRequest());

        Rubles.runPhoneTopBannerUpdater();

        if (!isBiz && !isWebview) {
            Rubles.updateMobSplash();
        }
    }, []);

    useEffect(() => {
        // Удаляем query-параметр pin из урла после загрузки
        if (typeof window.history.replaceState === 'function') {
            const search = parse(window.location.search, { ignoreQueryPrefix: true }) || {};

            if (search?.pin) {
                delete search.pin;
                const query = stringify(search, { addQueryPrefix: true });
                window.history.replaceState(null, '', `${window.location.pathname}${query}`);
            }
        }
    }, []);

    const hideUploadInfo = useCallback(() => {
        setShowUploadInfo(false);
    }, []);
    const { isOverlap, toolbarRef, dataListRef } = useToolbarOverlapDatalist({ enabled: supportThemedPage });
    const uploadInfoEnabled = showUploadInfo && !isSuperAppWebView;
    const downloadEnabled = !isSelecting && !isWebview;
    if (showDisabledForOverquotaOwner) {
        return (
            <>
                <div
                    className={classNames(styles.root, {
                        [styles.root_withTopInfo]: !HIDE_ADS || emptyFolderUploadAllowed,
                        [styles.root_withTopInfoAndEmptyFolderUploadAllowed]: hasAdsAndEmptyFolderUploadAllowed,
                        [styles.with_emptyPublic]: showDisabledForOverquotaOwner,
                    })}
                >
                    <Toolbar showMenu={!isWebview} disableActions />
                    <div className={styles.overquotaEmptyStateCarousel}>
                        <ActionsCarousel noMargins actionsFilter={actionsFilter} />
                    </div>
                    <div className={styles.emptyPublic}>
                        <EmptyPublicOverquota small />
                    </div>
                </div>
                {!isUploadEnabled && !HIDE_ADS && !isEmpty && <RollContentAds />}
            </>
        );
    }

    if (!isAlbum && IS_HIDDEN_PUBLIC) {
        return (
            <div
                translate="no"
                className={classNames(styles.root, {
                    [styles.root_withTopInfo]: !HIDE_ADS || emptyFolderUploadAllowed,
                    [styles.root_withTopInfoAndEmptyFolderUploadAllowed]: hasAdsAndEmptyFolderUploadAllowed,
                    [styles.rootHidden]: IS_HIDDEN_PUBLIC,
                })}
            >
                <Toolbar showMenu={!isWebview} />
                <div className={styles.content}>
                    <Password />
                </div>
            </div>
        );
    }

    return (
        <>
            <div
                translate="no"
                className={classNames(styles.root, {
                    [styles.root_withTopInfo]: !supportThemedPage && (!HIDE_ADS || emptyFolderUploadAllowed),
                    [styles.root_withTopInfoAndEmptyFolderUploadAllowed]: hasAdsAndEmptyFolderUploadAllowed,
                    [styles.root_themedPublic]: supportThemedPage,
                })}
            >
                <Toolbar showMenu={!isWebview} supportThemedPage={supportThemedPage} ref={toolbarRef} isStickyFromProps={isOverlap} />
                <div className={classNames(styles.content, { [styles.toolbarOverlap]: isOverlap })}>
                    <Breadcrumbs className={styles.breadcrumbs} />
                    <div className={styles.expiresInfo}>
                        <ExpiresInfo className={styles.primaryColor} />
                    </div>
                    {uploadInfoEnabled && !supportThemedPage && (
                        <div className={styles.uploadInfo}>
                            <UploadInfo closable onClose={hideUploadInfo} />
                        </div>
                    )}
                    <ActionsCarousel disabled={isSelecting} supportThemedPage={supportThemedPage} />
                    {supportThemedPage ? (
                        <MobilePublicThemedPageDatalist
                            ref={dataListRef}
                            emptyFolderUploadAllowed={emptyFolderUploadAllowed}
                            downloadEnabled={downloadEnabled}
                            hideUploadInfo={hideUploadInfo}
                            uploadInfoEnabled={uploadInfoEnabled}
                        />
                    ) : (
                        <Datalist />
                    )}
                </div>
            </div>

            {showActionsBar && <ActionsBar />}
            {showUploader && <UploadListMobileContainer />}
            {!isUploadEnabled && !HIDE_ADS && !isEmpty && !isSuperAppWebView && <RollContentAds />}
            {downloadEnabled && !supportThemedPage && (
                <div className={styles.appButton}>
                    <DownloadAppButton />
                </div>
            )}
            <UploadBlockContainer />
        </>
    );
});

MobilePublicPage.displayName = 'MobilePublicPage';
