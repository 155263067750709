import { Spacing } from '@vkontakte/vkui';
import React, { type VFC } from 'react';
import type { IBaseSubscription } from 'reactApp/modules/subscriptions/subscriptions.types';

import { CommonInfo } from '../CommonInfo/CommonInfo';

export const BaseSubscription: VFC<{ subscription: IBaseSubscription; isRebrandingQuotaLanding?: boolean }> = ({
    subscription: { space },
    isRebrandingQuotaLanding,
}) => (
    <>
        <CommonInfo space={space} isBase isRebrandingQuotaLanding={isRebrandingQuotaLanding} />
        <Spacing size={44} />
    </>
);
