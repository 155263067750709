import React, { type FC } from 'react';
import { IS_B2B_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { Checkbox } from 'reactApp/ui/DataListItemCommonComponents/Checkbox/Checkbox';
import { Destination, SizeType } from 'reactApp/ui/DataListItemCommonComponents/Destination/Destination';
import { Download } from 'reactApp/ui/DataListItemCommonComponents/Download/Download';
import { Favorite } from 'reactApp/ui/DataListItemCommonComponents/Favorite/Favorite';
import { Publish } from 'reactApp/ui/DataListItemCommonComponents/Publish/Publish';
import { Date } from 'reactApp/ui/DataListItemRow/components/Date/Date';
import { ItemName } from 'reactApp/ui/DataListItemRow/components/ItemName/ItemName';
import { Size } from 'reactApp/ui/DataListItemRow/components/Size/Size';
import { TableColumn } from 'reactApp/ui/DataListItemRow/components/TableColumn/TableColumn';
import type { GalleryItemProps } from 'reactApp/ui/DataListItemRow/DataListItemRow.types';
import { FilePic } from 'reactApp/ui/FilePic/FilePic';
import { useCompactView } from 'reactApp/ui/VirtualList/VirtualList.hooks';

import styles from '../../DataListItemRow.new.css';

export const GalleryItem: FC<GalleryItemProps> = (props) => {
    const {
        home,
        onCheckboxClick,
        isSelected,
        thumbUrl,
        isVirus,
        ext,
        name,
        favoritesAvailable,
        onFavorites,
        isInFavorites,
        mtime,
        size,
        onDownload,
        isPublic,
        isSeoEnabled,
        onPublish,
        id,
        storage,
        renameAvailable,
    } = props;

    const path = home === '/' ? (IS_B2B_BIZ_USER ? 'Личные папки' : 'Облако') : home;

    const isCompact = useCompactView();

    return (
        <>
            <TableColumn>
                <Checkbox viewType={EViewMode.list} className={styles.check} onCheckboxClick={onCheckboxClick} isSelected={isSelected} />
                <FilePic isFolder={false} thumbUrl={thumbUrl} isVirus={isVirus} ext={ext || ''} isPublic={isPublic} />
            </TableColumn>

            {isCompact ? (
                <TableColumn width="100%" overflow="hidden">
                    <div className={styles.nameWrapper}>
                        <ItemName name={name} ext={ext || ''} id={id} />
                        <Destination destination={path} size={SizeType.s} noMargin />
                        <span className={styles.responsiveDateWrapper}>
                            <Size size={size} isInline />
                            {!!mtime && <>&nbsp;•&nbsp;</>}
                            <Date date={mtime} className={styles.date} />
                        </span>
                    </div>
                </TableColumn>
            ) : (
                <>
                    <TableColumn width="100%" overflow="hidden">
                        <ItemName name={name} ext={ext || ''} id={id} isRenameAvailable={renameAvailable} />
                    </TableColumn>
                    <TableColumn width="100%" overflow="hidden">
                        <Destination destination={path} />
                    </TableColumn>
                    <TableColumn flexBasis={132} flexShrink={0} justifyContent="flex-end">
                        <Date date={mtime} />
                    </TableColumn>
                    <TableColumn flexBasis={132} flexShrink={0} justifyContent="center">
                        <Size size={size} />
                    </TableColumn>
                </>
            )}

            <TableColumn flexBasis={100} flexShrink={0} justifyContent="flex-end" gap={20}>
                <Favorite
                    className={styles.favoriteAction}
                    isAvailable={favoritesAvailable}
                    isFavorite={isInFavorites}
                    onClick={onFavorites}
                    viewType={EViewMode.list}
                />
                <Publish viewType={EViewMode.list} onClick={onPublish} isShared={isPublic} isSeoEnabled={isSeoEnabled} />
                <Download onClick={onDownload} ext={ext} size={size} id={id} storage={storage} viewType={EViewMode.list} />
            </TableColumn>
        </>
    );
};
