import classNames from 'clsx';
import React, { type ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AB_FEATURE_NAME } from 'reactApp/components/SharingNewBiz/SharingNew.constants';
import { contactsSlice } from 'reactApp/modules/contacts/contacts.module';
import { snackbarController } from 'reactApp/modules/snackbar/snackbar.controller';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { loadUser } from 'reactApp/modules/user/user.thunkActions';
import {
    weblinkAccessControlLoadStart,
    weblinkAccessControlReset,
    weblinkAccessControlSetError,
    weblinkSetEmailAccess,
} from 'reactApp/modules/weblinkAccessControl/weblinkAccessControl.actions';
import { getACL, getError, isAccessControlListLoading } from 'reactApp/modules/weblinkAccessControl/weblinkAccessControl.selectors';
import { EPublicAccessRights } from 'reactApp/modules/weblinkAccessControl/weblinkAccessControl.types';
import { sendXray } from 'reactApp/utils/ga';

import { EAccessRights } from '../../../Sharing.types';
import { SharingNewAddUser } from '../../AddUser/SharingNewAddUser';
import { WeblinkUserCollapsedList } from '../../UserList/WeblinkUserCollapsedList';
import { WeblinkUserList } from '../../UserList/WeblinkUserList';
import styles from './SharingNewWeblinkList.css';
import type { IProps } from './SharingNewWeblinkList.types';

export const SharingNewWeblinkList = React.memo(function SharingNewWeblinkList({
    item,
    isPhone,
    isReadOnly,
    isTabsDesign,
    isEmbeded,
}: IProps): ReactElement | null {
    const dispatch = useDispatch();

    const { isLoaded, hasError: isUserError } = useSelector(UserSelectors.getLifeCycleState);
    const activeUserEmail = useSelector(UserSelectors.getEmail);
    const acList = useSelector(getACL);
    const isLoading = useSelector(isAccessControlListLoading);
    const error = useSelector(getError);

    const isCollapsedList = !isEmbeded && isTabsDesign && acList.length > 1;

    const isUserLoaded = isLoaded && !isUserError;

    const loadAllData = useCallback(() => {
        dispatch(contactsSlice.actions.listRequest);
        dispatch(weblinkAccessControlLoadStart({ id: item?.id || '', storage: item?.storage }));
    }, [dispatch, item?.id, item?.storage]);

    useEffect(() => {
        if (!isEmbeded) {
            if (!isUserLoaded) {
                (async () => {
                    await dispatch(loadUser);
                    await loadAllData();
                })();
            } else {
                loadAllData();
            }
        }

        return function cleanup() {
            if (!isEmbeded) {
                dispatch(weblinkAccessControlReset());
            }
        };
    }, [dispatch, isEmbeded, isUserLoaded, loadAllData]);

    const handleAddUser = useCallback(
        ({ currentEmail, accessRight }: { currentEmail: string; accessRight: EAccessRights }) => {
            const mappedAccess = accessRight === EAccessRights.READ_WRITE ? EPublicAccessRights.READ_WRITE : EPublicAccessRights.READ_ONLY;

            if (activeUserEmail === currentEmail) {
                snackbarController.showSnackbar({
                    type: SnackbarTypes.warning,
                    text: 'Вы являетесь владельцем файла',
                    id: 'ownerExistError',
                    closable: true,
                });
                return;
            }

            const existingAccount = acList.find((ac) => ac.email === currentEmail);
            if (existingAccount) {
                const needChangeAccessRights = existingAccount.accessRights !== mappedAccess;
                const text = needChangeAccessRights
                    ? 'Участник уже был добавлен. Вы изменили его права доступа'
                    : 'Участник с такими правами доступа уже добавлен';
                snackbarController.showSnackbar({
                    type: SnackbarTypes.warning,
                    text,
                    id: 'ownerExistError',
                    closable: true,
                });

                if (!needChangeAccessRights) {
                    return;
                }
            }

            dispatch(
                weblinkSetEmailAccess({
                    accessRight: mappedAccess,
                    weblink: item?.weblink || '',
                    email: currentEmail,
                })
            );
            dispatch(contactsSlice.actions.listRequest());

            sendXray([AB_FEATURE_NAME, 'wblnk', 'usr', 'add'], {
                [mappedAccess]: 1,
            });
        },
        [acList, activeUserEmail, dispatch, item?.weblink]
    );

    const clearError = useCallback(() => {
        dispatch(weblinkAccessControlSetError({ error: '' }));
    }, [dispatch]);

    return (
        <div className={classNames(styles.root, { [styles.root_phone]: isPhone })}>
            <SharingNewAddUser
                handleAddUser={handleAddUser}
                isPhone={isPhone}
                isLoading={isLoading}
                error={error}
                clearError={clearError}
                isReadOnly={isReadOnly}
                acList={acList}
            />
            {isCollapsedList ? (
                <WeblinkUserCollapsedList acList={acList} isLoading={isLoading} isPhone={isPhone} item={item} isReadOnly={isReadOnly} />
            ) : (
                <WeblinkUserList
                    acList={acList}
                    isLoading={isLoading}
                    isPhone={isPhone}
                    item={item}
                    isReadOnly={isReadOnly}
                    isEmbeded={isEmbeded}
                />
            )}
        </div>
    );
});
