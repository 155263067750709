import { getFeature } from 'Cloud/Application/FeaturesEs6';

// tempexp_18122-start Поддержка продолжения воспроизведения

/**
 * Разрешает сохранение времени воспроизведения
 */
export const enableStoreVideoPlayTime: boolean = getFeature('enable-video-play-time-store') || false;

/**
 * Минимальная длительность видео в секундах, после которой мы начинаем сохрянять время воспроизведения при закрытии плеера
 */
export const minVideoDurationToStore: number = getFeature('min-video-duration-to-store') || 900;

// tempexp_18122-end
