import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

type DiscountInOutflowAB = 'a' | 'b' | 'c' | false;

type DiscountInOutflowTariffs = {
    original: string[];
    discount30: string[];
    discount50: string[];
};

export const discountInOutflowABDesktop: DiscountInOutflowAB = getFeature('discount-in-outflow-ab');

export const discountInOutflowTariffs: DiscountInOutflowTariffs = getFeature('discount-in-outflow-tariffs') || {
    original: [],
    discount30: [],
    discount50: [],
};

export function isDiscountOutflowParticipantProductId(productId?: string) {
    return !!productId && discountInOutflowTariffs.original.includes(productId);
}

export function getDiscoutOutflowPercentByProductId(productId: string) {
    if (discountInOutflowTariffs.discount30.includes(productId)) {
        return 30;
    }
    if (discountInOutflowTariffs.discount50.includes(productId)) {
        return 50;
    }
    return;
}

function getDicountInOutflowValue() {
    if (discountInOutflowABDesktop === 'b') {
        return 50;
    }
    if (discountInOutflowABDesktop === 'c') {
        return 30;
    }
    return;
}

export const dicountInOutflowValue = getDicountInOutflowValue();

export const isDiscountABEnabled = discountInOutflowABDesktop === 'b' || discountInOutflowABDesktop === 'c';

registerExperiment(
    'discount-outflow',
    discountInOutflowABDesktop,
    [
        AnalyticEventNames.MY_TARIFF_CANCEL_SALE_SHOW,
        AnalyticEventNames.MY_TARIFF_CANCEL_SALE_GET,
        AnalyticEventNames.MY_TARIFF_CANCEL_SALE_REJECT,
        AnalyticEventNames.MY_TARIFF_CANCEL_SALE_CLOSE,
    ],
    {
        mapEventNames: {
            MY_TARIFF_CANCEL_SALE_SHOW: 'show',
            MY_TARIFF_CANCEL_SALE_GET: 'get',
            MY_TARIFF_CANCEL_SALE_REJECT: 'reject',
            MY_TARIFF_CANCEL_SALE_CLOSE: 'close',
        },
    }
);
