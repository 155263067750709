import { autoUpdate, flip, FloatingPortal, offset, useFloating } from '@floating-ui/react';
import { Icon16Cancel, Icon20Cancel } from '@vkontakte/icons';
import { Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import { ReactComponent as ArrowAside } from 'img/tooltip/arrow-aside.svg';
import { ReactComponent as ArrowCenter } from 'img/tooltip/arrow-center.svg';
import throttle from 'lodash.throttle';
import React, { memo, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { calculateOffset, getFlipFallbackPlacements } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.helpers';
import { type ITooltipProps, ETooltipPlacement, ETooltipSize } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';

import styles from './FloatingTooltip.css';

const ARROW_CENTER_PLACEMENTS = [ETooltipPlacement.top, ETooltipPlacement.bottom, ETooltipPlacement.left, ETooltipPlacement.right];

let showFaceButton: HTMLElement | null = null;

export const clickCloseTooltipButton = () => {
    if (showFaceButton) {
        showFaceButton.click();
    }
};

export const FloatingTooltip = memo<ITooltipProps>(
    // eslint-disable-next-line max-lines-per-function
    ({
        children,
        className,
        arrowClassName,
        contentClassName,
        closeClassName,
        target,
        title,
        text,
        buttonText,
        img,
        appearance,
        size,
        placement,
        closable = true,
        hideArrow = false,
        closeOnOutsideClick = true,
        closeOnScroll = false,
        withinModal = false,
        qaId,
        overAll = false,
        usePortal,
        isAlternativeButton = false,
        smallArrow = false,
        onClick,
        onShow,
        onClose,
        onTooltipClick,
        smallClose = false,
        paddingRight,
        allowFlip = true,
        belowSticky = false,
        isRebrandingQuotaLanding = false,
        buttonFitContent = false,
        imageWidth,
    }) => {
        const [isOpen, setIsOpen] = useState(true);

        const {
            x,
            y,
            strategy,
            refs,
            placement: finalPlacement,
        } = useFloating({
            open: isOpen,
            onOpenChange: setIsOpen,
            placement,
            middleware: [
                offset(calculateOffset),
                ...(allowFlip
                    ? [
                          flip({
                              fallbackPlacements: getFlipFallbackPlacements(placement),
                          }),
                      ]
                    : []),
            ],
            whileElementsMounted: autoUpdate,
        });

        const onDocumentClick = useCallback(
            ({ target }: MouseEvent) => {
                if (refs?.floating && refs.floating?.current && target instanceof Node && !refs.floating.current.contains(target)) {
                    setIsOpen(false);
                    onClose?.();
                }
            },
            [onClose, refs?.floating]
        );

        const onCountButtonClick = useCallback(
            (event: Event) => {
                if (event instanceof MouseEvent && refs?.floating && refs.floating?.current) {
                    setIsOpen(false);
                    onClose?.();
                }
            },
            [onClose, refs?.floating]
        );

        const throttledScroll = throttle(() => {
            setIsOpen(false);
            onClose?.();
        }, 100);

        const handleClick = useCallback(() => {
            onClick?.();
        }, [onClick]);

        const handleClose = useCallback(
            (event) => {
                event.stopPropagation();
                onClose?.();
                setIsOpen(false);
            },
            [onClose, setIsOpen]
        );

        useLayoutEffect(() => {
            if (target) {
                refs.setReference('current' in target ? target.current : target);
            }
        }, [refs, target]);

        useEffect(() => {
            onShow?.();

            if (closeOnOutsideClick) {
                document.addEventListener('click', onDocumentClick, { capture: true });
            } else {
                showFaceButton = document.querySelector('div[data-qa-id="showFaces"]');
                if (showFaceButton) {
                    showFaceButton.addEventListener('mousedown', onCountButtonClick, { capture: true });
                }
            }

            if (closeOnScroll) {
                document.addEventListener('scroll', throttledScroll);
            }

            return () => {
                if (closeOnOutsideClick) {
                    document.removeEventListener('click', onDocumentClick, { capture: true });
                } else if (showFaceButton) {
                    showFaceButton.removeEventListener('mousedown', onCountButtonClick, { capture: true });
                }
                document.removeEventListener('scroll', throttledScroll);
            };
        }, []);

        const showImage = Boolean(img) && (!size || size !== ETooltipSize.small);

        if (!isOpen || !refs.reference) {
            return null;
        }

        const layout = (
            <div
                className={classNames(styles.root, className, {
                    [styles.root_closable]: closable,
                    [styles.root_image]: showImage,
                    [styles[`root_size_${size}`]]: Boolean(size),
                    [styles[`root_appearance_${appearance}`]]: Boolean(appearance),
                    [styles[`root_placement_${finalPlacement}`]]: Boolean(finalPlacement),
                    [styles.root_no_arrow]: hideArrow,
                    [styles.root_withinModal]: withinModal,
                    [styles.root_customContent]: Boolean(children),
                    [styles.root_overAll]: overAll,
                    [styles.root_smallArrow]: smallArrow,
                    ['vkui--cloud--dark']: appearance === 'dark',
                    [styles.root_belowSticky]: belowSticky,
                    [styles.root_rebrandingQuotaLanding]: isRebrandingQuotaLanding,
                })}
                ref={refs.setFloating}
                style={{
                    position: strategy,
                    top: y ?? 0,
                    left: x ?? 0,
                }}
                data-qa-tooltip={qaId}
                onClick={onTooltipClick}
            >
                {!hideArrow && (
                    <div className={classNames(styles.arrow, arrowClassName)}>
                        {ARROW_CENTER_PLACEMENTS.includes(finalPlacement as ETooltipPlacement) ? <ArrowCenter /> : <ArrowAside />}
                    </div>
                )}
                <div className={classNames(styles.content, contentClassName)} style={{ paddingRight: paddingRight ?? '' }}>
                    {children ? (
                        children
                    ) : (
                        <>
                            {title && <Text className={styles.title}>{title}</Text>}
                            {text && <>{typeof text === 'string' ? <Text className={styles.text}>{text}</Text> : text}</>}
                            {buttonText && (
                                <Button
                                    theme="vk"
                                    buttonVariantprimary
                                    primary={!isAlternativeButton}
                                    secondary={isAlternativeButton}
                                    sizeMode={ButtonSizeMode.small}
                                    fluid={false}
                                    className={classNames(styles.button, {
                                        [styles.buttonFitContent]: buttonFitContent,
                                    })}
                                    onClick={handleClick}
                                >
                                    {buttonText}
                                </Button>
                            )}
                            {showImage && typeof img === 'string' && (
                                <div className={styles.image} style={{ width: imageWidth ?? '' }}>
                                    <img src={img} alt="Картинка в тултипе" />
                                </div>
                            )}
                            {showImage && typeof img !== 'string' && img}
                        </>
                    )}
                    {closable && (
                        <div className={classNames(styles.close, closeClassName)} onClick={handleClose}>
                            {smallClose ? <Icon16Cancel /> : <Icon20Cancel />}
                        </div>
                    )}
                </div>
            </div>
        );

        return usePortal ? <FloatingPortal>{layout}</FloatingPortal> : layout;
    }
);

FloatingTooltip.displayName = 'FloatingTooltip';
