import { type EnhancedStore, combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { logger } from 'lib/logger';
import { activitiesFeature } from 'reactApp/appHelpers/featuresHelpers';
import { sendXray } from 'reactApp/utils/ga';
import { captureException } from 'reactApp/utils/tracer';
import type { AnyAction, Dispatch, Reducer } from 'redux';
import createSagaMiddleware, { type Saga } from 'redux-saga';

import { actionPanelMiddleware } from './modules/actionpanel/actionpanel.module';
import reducers from './reducers';

const sagaMiddleware = createSagaMiddleware({
    onError: (error: Error) => {
        logger.error(`saga root exception: ${error?.toString()}`, error);
        sendXray(['exceptions', 'sagaroot']);
        captureException(error, { message: 'saga root exception' });
    },
});

const middleware = [
    ...getDefaultMiddleware({
        serializableCheck: false,
        // immutableCheck работает очень долго при подгрузке порции в деве
        // договорились включать руками, если надо будет дебажить
        immutableCheck: false,
    }),
    sagaMiddleware,
    ...(activitiesFeature ? [actionPanelMiddleware] : []),
];

const reducer = combineReducers(reducers);

type IState = ReturnType<typeof reducer>;
type IAsyncReducers = Record<string, Reducer>;
type IInjectSaga = (key: string, saga: Saga) => void;
type IAction = ((dispatch: Dispatch, getState: () => IState) => any) | AnyAction;

export const store = configureStore({
    reducer,
    middleware,
}) as EnhancedStore<IState> & {
    dispatch: (action: IAction) => any;
    asyncReducers: IAsyncReducers;
    injectSaga: IInjectSaga;
};

const createReducer = (asyncReducers: IAsyncReducers) => {
    return combineReducers({
        ...reducers,
        ...asyncReducers,
    });
};

store.asyncReducers = {};
store.dispatch((dispatch) => {
    dispatch({ type: '' });
});

export const injectReducer = (key: string, asyncReducer: Reducer) => {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducer(store.asyncReducers));
};

const createSagaInjector = (): IInjectSaga => {
    const injectedSagas = new Map();

    const isInjected = (key) => injectedSagas.has(key);

    return (key: string, saga: Saga) => {
        if (isInjected(key)) {
            return;
        }

        const task = sagaMiddleware.run(saga);

        injectedSagas.set(key, task);
    };
};

if (typeof window !== 'undefined') {
    // @ts-ignore
    window.dispatch = store.dispatch;
}

export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;

store.injectSaga = createSagaInjector();

export const initSaga = (rootSaga) => {
    sagaMiddleware.run(rootSaga);
};
