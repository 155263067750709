import { channel } from '@redux-saga/core';
import api from 'Cloud/Application/api';
import { ReactComponent as ErrorIcon } from 'img/icons/alert.svg';
import React from 'react';
import { IS_B2B_BIZ_USER, IS_BIZ_USER, IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { seriallyClearBinFeature } from 'reactApp/appHelpers/featuresHelpers/features/seriallyClearBin';
import { renderComfirmationDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog.helpers';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { PaymentUTM } from 'reactApp/constants/paymentUTM';
import { loadFolderFail, loadFolderSuccess, loadHomeFolderRequest } from 'reactApp/modules/home/home.actions';
import { getHomeItemById } from 'reactApp/modules/home/home.selectors';
import { snackbarController } from 'reactApp/modules/snackbar/snackbar.controller';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { getQuotaPromoUrl } from 'reactApp/sections/QuotaLanding/QuotaLanding.data';
import { store as reduxStore } from 'reactApp/store';
import { renderSelectRestoreDialog } from 'reactApp/ui/SelectFolderDialog/SelectFolderDialog.toolkit';
import { sendXray } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';
import opener from 'reactApp/utils/opener';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { filesPlural, foldersPlural, restoreMalePluralV1, restoreMalePluralV2 } from 'reactApp/utils/pluralHelpers';
import { cancel, put, select, take } from 'redux-saga/effects';

import { getCurrentStorage } from '../router/router.selectors';
import { EStorageType } from '../storage/storage.types';

const showSelectiveRestoreFromBinSnackbar = () => {
    const label = `app_trashbin_${IS_BIZ_USER ? 'biz_' : ''}restore_list`;
    snackbarController.hideSnackbar('stoppedSelectiveRestoreFromBin');
    snackbarController.showSnackbar({
        id: 'selectiveRestoreFromBin',
        text: 'Восстановление файлов',
        type: SnackbarTypes.loading,
        disableCloseTimeout: true,
        buttonText: 'Остановить',
        buttonRight: true,
        onButtonClick: () => {
            canceled = true;
            sendXray(`${label}_cancel-all`);
        },
    });
};

export function* restoreFiles({ items, tree, isDomainBin }) {
    try {
        sendXray(`app_trashbin${IS_BIZ_USER ? '_biz' : ''}`);

        let pathToRestore = items[0].parent + items[0].deletedFrom;

        items.forEach(function (item) {
            if (item.parent + item.deletedFrom !== pathToRestore) {
                pathToRestore = item.parent;
            }
        });
        pathToRestore = pathToRestore.replace('//', '/');
        pathToRestore = pathToRestore === '/' ? pathToRestore : pathToRestore.replace(/\/$/, '');

        const folder = yield select(getHomeItemById, pathToRestore);

        if (!folder) {
            yield put(loadHomeFolderRequest({ isFolder: true, id: pathToRestore }));

            const action = yield take([loadFolderFail, loadFolderSuccess]);
            if (action.type === loadFolderFail.toString()) {
                // Счиатем, что такой папки уже нет и востанавливаем в корень
                pathToRestore = ROOT_FOLDER_ID;
            }
        }

        const restoreChannel = channel();
        let foldersCount = 0;
        let filesCount = 0;

        const setCount = (folders: number, files: number): void => {
            foldersCount = folders;
            filesCount = files;
        };

        const onRestore = (destination, onEnd) => {
            // в chooseFolderToRestore нужно передавать полный путь id приманченой папки + путь удления.
            //  Чтобы предзагрузить дерево по этому пути. Но восстаналвивать надо без binId(parent),
            //  ибо там передаем treeId, и считается что короень === приманченой папке
            sendPaymentGa({
                eventCategory: ECategoryGa.basket,
                action: 'return-confirm',
            });

            destination = destination.replace(items[0].parent, '/').replace('//', '/');
            restoreTo(items, destination, tree, setCount)
                .then(() => {
                    onEnd();
                    restoreChannel.put(true);
                })
                .catch(() => restoreChannel.close());
        };

        if (isDomainBin && IS_BIZ_USER) {
            onRestore(pathToRestore, noop);
        } else {
            renderSelectRestoreDialog({
                onRestore,
                selectedItems: items.map((item) => ({ id: item.id, isFolder: item.isFolder })),
                selectedId: pathToRestore,
            });
        }

        const restoreSuccess = yield take(restoreChannel);
        return { restoreSuccess, foldersCount, filesCount };
    } catch (error) {
        yield cancel();
        return { restoreSuccess: false, foldersCount: 0, filesCount: 0 };
    }
}

let restoredFilesNumber = 0;
let needToHideSnackbar = false;

const restoreFile = (file, files, tree, destination, restoredLength: number) => {
    return new Promise(function (resolve, reject) {
        const label = `app_trashbin${IS_BIZ_USER ? '_biz' : ''}_restore_${file.isFolder ? 'folder' : 'file'}`;
        sendXray(label);

        if (canceled) {
            // если нажали Остановить
            if (seriallyClearBinFeature) {
                snackbarController.hideSnackbar('selectiveRestoreFromBin');
                snackbarController.showSnackbar({
                    type: SnackbarTypes.warning,
                    id: 'stoppedSelectiveRestoreFromBin',
                    text: `${restoredLength} ${filesPlural(restoredLength)} уже ${restoreMalePluralV2(restoredLength)}`,
                    hideCloseIcon: true,
                    closable: true,
                });
            }
            sendXray(`${label}_canceled`);
            reject('cancel');
            return;
        }

        if (!IS_PHONE_BROWSER && !seriallyClearBinFeature) {
            showDialog(files, {
                file,
                controls: [
                    {
                        name: 'cancelAll',
                        text: 'Отменить',
                        handler() {
                            canceled = true;
                            sendXray(`${label}_cancel-all`);
                        },
                    },
                ],
            });
        }

        const fullPath = `${destination}/${file.name}`.replace('//', '/');

        api.trashbin
            .restore({
                conflict: 'rename',
                restore_revision: file.rev,
                path: fullPath,
                tree,
            })
            .done(function (result) {
                sendXray(`${label}_success`);

                resolve({
                    destPath: destination,
                    fullPath,
                    name: result.name,
                    file,
                    reason: 'success',
                });
            })
            .fail(function (result, status, response) {
                if (canceled) {
                    reject('cancel');
                    return;
                }

                sendXray(`${label}_fail`);

                const dialog: any = {
                    file,
                    text: `Ошибка восстановления «${file.name}»`,
                    error: true,
                };

                if (response.isInsufficient() && result.error !== 'read_only') {
                    if (!IS_B2B_BIZ_USER) {
                        dialog.controls = [
                            {
                                name: 'buyspace',
                                text: 'Купить место',
                                mods: ['main'],
                                handler() {
                                    sendXray(`${label}_buyspace`);
                                    hideDialog();
                                    opener(getQuotaPromoUrl({ query: PaymentUTM.trashbinRestoreFail }), true);
                                    reject('payment');
                                },
                            },
                        ];
                    } else {
                        dialog.controls = [];
                    }

                    dialog.error = 'Превышен объём Облака';
                } else if (response.isInsufficient()) {
                    dialog.controls = [];

                    dialog.error = 'Папка только для чтения';
                } else {
                    dialog.controls = [
                        {
                            name: 'repeat',
                            text: 'Повторить',
                            mods: ['main'],
                            handler() {
                                sendXray(`${label}_repeat`);
                                restoreFile(file, files, tree, destination, restoredLength).then(resolve, reject);
                                if (seriallyClearBinFeature) {
                                    showSelectiveRestoreFromBinSnackbar();
                                }
                            },
                        },
                        {
                            name: 'skip',
                            text: 'Пропустить',
                            handler() {
                                sendXray(`${label}_skip`);
                                resolve({
                                    file,
                                    reason: 'skip',
                                });
                                if (seriallyClearBinFeature) {
                                    --restoredFilesNumber;
                                    if (!needToHideSnackbar) {
                                        showSelectiveRestoreFromBinSnackbar();
                                    }
                                }
                            },
                        },
                    ];
                }

                dialog.controls.push({
                    name: 'cancel',
                    text: 'Отменить',
                    handler() {
                        sendXray(`${label}_cancel`);
                        reject('cancel');
                    },
                });

                if (seriallyClearBinFeature) {
                    snackbarController.hideSnackbar('selectiveRestoreFromBin');
                }
                showDialog(files, dialog);
            });
    });
};

let canceled = false;
let progressDlg: any = null;

function hideDialog() {
    if (progressDlg) {
        progressDlg.close();
        progressDlg = null;
    }
}

function showDialog(files, options) {
    if (!options) {
        options = {};
    }

    const i = files.indexOf(options.file);

    const text = options.text || `Восстанавливается «${options.file.name}»`;

    const layerData = {
        title: options.title || `Восстановление${files.length > 1 ? ` ${i + 1} из ${files.length}` : ''}`,
        text,
        error: options.error,
        filename: options.file.name,
        progress: Math.ceil((100 * i) / files.length),
        controls: options.controls,
    };

    options.controls.forEach(function (control) {
        if (!control.name || !control.handler) {
            return;
        }

        const handler = control.handler;

        control.handler = (evt, ui) => {
            sendXray(`ui_layers_restore_${evt.type}`);

            handler(evt, ui);
        };
    });

    const props = {
        dataQAId: 'restore-progress',
        renderHeader: () => layerData.title,
        renderContent: () => `${layerData.text}${options.error && typeof options.error === 'string' ? `\n${options.error}` : ''}`,
        onClose: () => {
            if (options.controls[0].name === 'cancel' || options.controls[0].name === 'cancelAll') {
                options.controls[0].handler({ type: options.controls[0].name });
            }
            if (i >= files.length - 1) {
                hideDialog();
            }
        },
        buttons: [EButtonType.accept],
        successButtonText: options.controls[0].text,
        onSuccess: () => options.controls[0].handler({ type: options.controls[0].name }),
        icon: options.error ? <ErrorIcon width={16} height={16} /> : null,
        cancelButtonText: '',
    };

    if (options.controls.length > 1) {
        props.buttons.push(EButtonType.cancel);
        props.cancelButtonText = options.controls[1].text;
        props.onClose = () => {
            options.controls[1].handler({ type: options.controls[1].name });
            if (i >= files.length - 1) {
                hideDialog();
            }
        };
    }

    if (seriallyClearBinFeature || !progressDlg) {
        progressDlg = renderComfirmationDialog(props);
    } else {
        progressDlg.update({
            props,
        });
    }
}

const getRestoredMessage = ({ filesCounter, foldersCounter }) => {
    let message = 'Восстановлено: ';

    if (filesCounter) {
        message += `${filesCounter} ${filesPlural(filesCounter)}`;
    }

    if (filesCounter && foldersCounter) {
        message += ' и ';
    }

    if (foldersCounter) {
        message += `${foldersCounter} ${foldersPlural(foldersCounter)}`;
    }

    return message;
};

const restoreTo = async (files, destination, tree, setCount) => {
    const storage = getCurrentStorage(reduxStore.getState());
    progressDlg = null;
    canceled = false;

    const label = `app_trashbin_${IS_BIZ_USER ? 'biz_' : ''}restore_list`;

    if (storage !== EStorageType.trashbin) {
        return;
    }

    if (seriallyClearBinFeature) {
        showSelectiveRestoreFromBinSnackbar();
    }

    const results: any[] = [];
    restoredFilesNumber = 0;
    for (const file of files) {
        try {
            const res = await restoreFile(file, files, tree, destination, restoredFilesNumber);
            results.push(res);
            ++restoredFilesNumber;
        } catch (error) {
            break;
        }
    }
    needToHideSnackbar = true;

    if (seriallyClearBinFeature) {
        snackbarController.hideSnackbar('selectiveRestoreFromBin');
    } else {
        hideDialog();
    }

    let restored = 0;
    let filesCounter = 0;
    let foldersCounter = 0;

    results.forEach(function (result) {
        if (result && result.reason === 'success') {
            restored++;
            if (result.file.isFolder) {
                foldersCounter++;
            } else {
                filesCounter++;
            }
        }
    });

    if (seriallyClearBinFeature) {
        setCount(foldersCounter, filesCounter);
    }

    if (restored && ((!canceled && seriallyClearBinFeature) || !seriallyClearBinFeature)) {
        snackbarController.showSnackbar({
            id: 'restore-files-message',
            closable: true,
            text: seriallyClearBinFeature
                ? `${restored} ${filesPlural(restored)} ${restoreMalePluralV1(restored)}`
                : getRestoredMessage({ filesCounter, foldersCounter }),
            type: SnackbarTypes.success,
        });
    }

    sendXray(`${label}success`);
};
