/**
 * Компонент не завершен
 * Экспериментальная копия reactApp/components/SharingWindow/SharingContent/SharingContent.tsx
 * Изменения относительно оригинала помечены комментарием - tempexp_17779
 **/

import config from 'Cloud/config';
import classNames from 'clsx';
import React, { type ForwardedRef, forwardRef, memo, useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isAbNewSharingFolder } from 'reactApp/appHelpers/featuresHelpers';
import { type IProps, type SendSharingContentAnalytics, EFrom, ESharingType } from 'reactApp/components/SharingWindow/Sharing.types';
import type { PublishItem } from 'reactApp/modules/modifying/modifying.types';
import type { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import { isOwnPublic as isOwnPublicSelector } from 'reactApp/modules/public/public.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { isDomainFolder, isMountedFolder } from 'reactApp/modules/storage/folder.helpers';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { getItemById, getStorageItemById, isMountedOrSharedFolder } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { RootState } from 'reactApp/store';
import { TabMenu } from 'reactApp/ui/Menu/TabMenu';
import { type ITabMenuItem, ETabMenuAlign, ETabMenuMod } from 'reactApp/ui/Menu/TabMenu.types';
import { TabMenuItem } from 'reactApp/ui/Menu/TabMenuItem';
import { sendPaymentGa } from 'reactApp/utils/paymentGa';

import { getTitle } from '../Sharing.constants';
import styles from '../SharingNew/SharingNew.css';
import { SharingNewSharingTab } from '../SharingNew/SharingTab/SharingNewSharingTab';
import { SharingNewWeblinkTab } from '../SharingNew/Weblink/SharingNewWeblinkTab';
import { useTabsState } from './utils';

const TABS: ITabMenuItem[] = [
    {
        name: 'По ссылке',
        value: ESharingType.WEBLINK,
    },
    {
        name: 'Пользователям облака',
        value: ESharingType.CLOUD_USERS,
    },
];

export const SharingContentTabs = memo(
    // eslint-disable-next-line complexity
    forwardRef((props: IPropsWithPopup<IProps>, ref: ForwardedRef<HTMLDivElement>) => {
        const { itemStorage, id, from, onClose, gaSuffix, isPhone, withPromoTooltip } = props;
        const refRoot = useRef<HTMLDivElement | null>(null);

        // tempexp_17779-start
        const { copiedWeblinkOnMount, fetchedDataOnMount, activeTab, setActiveTab, setFetchedDataOnMount, setCopiedWeblinkOnMount } =
            useTabsState();
        // tempexp_17779-end

        const sendSharingContentAnalytics = useCallback<SendSharingContentAnalytics>(({ action, label }) => {
            sendPaymentGa({ eventCategory: 'enable-sharing', action, label });
        }, []);

        useEffect(() => {
            sendSharingContentAnalytics({ action: 'show' });
        }, [sendSharingContentAnalytics]);

        const getItem = useCallback(
            (state) => (itemStorage ? getStorageItemById(state, itemStorage, id) : getItemById(state, id)),
            [id, itemStorage]
        );

        const item = useSelector(getItem) as PublishItem;
        const isFolder = item?.isFolder;
        const isMounted = isMountedFolder(item);
        const isSharedOrMouted = useSelector((state: RootState) =>
            isFolder ? isMountedOrSharedFolder(state, item) : ({} as ReturnType<typeof isMountedOrSharedFolder>)
        );
        const isDomainItem: boolean = isDomainFolder(item);
        const storage = useSelector(getCurrentStorage);
        const { isSharedIncoming, isStart, isStock, isInlineIntegration } = getStorage(storage);
        const isPublic = !!config.get('PUBLIC');
        const isOwnPublic = useSelector((state: RootState) => isPublic && isOwnPublicSelector(state));
        const isAlbum = from === EFrom.ALBUMS || storage === EStorageType.albums;
        const noEdit = isStock ? false : isPublic && !isOwnPublic;

        // tempexp_15661-start
        const abSharing = Boolean(!isPhone && isAbNewSharingFolder && item?.name && (isFolder || isAlbum) && !isMounted);
        const title = getTitle({
            isPublic: isStock ? false : isPublic,
            isMounted,
            name: item?.name,
            isAbNewSharingFolder: abSharing,
            isSharingTabsFeature: true,
        });
        // tempexp_15661-end

        const showSharing =
            !isAlbum &&
            isFolder &&
            !isDomainItem &&
            !isStart &&
            (!(isSharedOrMouted.isMounted || isSharedOrMouted.isShared) || item?.id === isSharedOrMouted.folderId);

        return (
            <div className={classNames(styles.root, { [styles.root_phone]: isPhone })} ref={refRoot}>
                <div className={classNames(styles.header, styles.sharingTabs)}>{title}</div>
                {/* tempexp_17779-start */}
                <TabMenu align={ETabMenuAlign.center} mod={ETabMenuMod.folderSharing}>
                    {TABS.map(({ name, value }) => {
                        return (
                            <TabMenuItem
                                key={name}
                                name={`${name}`}
                                onClick={(value) => {
                                    setActiveTab(value);
                                    if (value === ESharingType.WEBLINK) {
                                        sendSharingContentAnalytics({ action: 'switch', label: 'click' });
                                    }
                                }}
                                value={value}
                                active={activeTab === value}
                                disabled={false}
                                changeHash={false}
                            />
                        );
                    })}
                </TabMenu>
                {/* tempexp_17779-end */}
                <div className={styles.content} ref={ref}>
                    {activeTab === ESharingType.WEBLINK && !isSharedIncoming && (
                        // tempexp_17779-start
                        <SharingNewWeblinkTab
                            // tempexp_17779-end
                            item={item}
                            from={from}
                            isSharedOrMouted={isSharedOrMouted}
                            gaSuffix={gaSuffix}
                            noEdit={noEdit}
                            isOwn={!isPublic || isOwnPublic}
                            noExpiresControl={isPublic}
                            isPhone={isPhone}
                            onClose={onClose}
                            sendSharingContentAnalytics={sendSharingContentAnalytics}
                            withPromoTooltip={withPromoTooltip}
                            showListSharing={showSharing}
                            // tempexp_17779-start
                            copiedWeblinkOnMount={copiedWeblinkOnMount}
                            setCopiedWeblinkOnMount={setCopiedWeblinkOnMount}
                            // tempexp_17779-end
                        />
                    )}

                    {/* Отключаем фичу 'Пользователям облака' в Тутории*/}
                    {!isInlineIntegration && showSharing && activeTab === ESharingType.CLOUD_USERS && (
                        // tempexp_17779-start
                        <SharingNewSharingTab
                            fetchedDataOnMount={fetchedDataOnMount}
                            setFetchedDataOnMount={setFetchedDataOnMount}
                            // tempexp_17779-end
                            onClose={onClose}
                            item={item}
                            from={from}
                            isPhone={isPhone}
                            isMounted={isMounted}
                        />
                    )}
                </div>
            </div>
        );
    })
);

SharingContentTabs.displayName = 'SharingContentTabs';
