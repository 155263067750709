import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { path } from 'ramda';
import React from 'react';
import { BASEQUOTA, IS_MBIZUSER } from 'reactApp/appHelpers/configHelpers';
import { isPromoQuotaProEnabled } from 'reactApp/appHelpers/featuresHelpers/features/sassQuotaProLanding';
import { cheapestProductIDs } from 'reactApp/modules/products/products.const';
import { ESubscriptionsTypes } from 'reactApp/modules/subscriptions/subscriptions.types';
import {
    type IContent,
    type ISubscriptionModalContent,
    SubscriptionMobileName,
} from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal.types';
import { getPeriodName } from 'reactApp/utils/Period';

export const useContent = ({ subscription, product, partner }: ISubscriptionModalContent): IContent => {
    let header = <></>;
    const { type, space } = subscription;
    const isTrial = !!path(['isTrial'], subscription);
    const isPrepaid = !!path(['isPrepaid'], subscription);
    const base = path(['base'], subscription);
    const subsPeriod: any = (isTrial && product?.trialPeriod) || path(['period'], subscription) || product?.period;
    const period = subsPeriod && getPeriodName(subsPeriod, false, true);
    const isMbizProduct = product?.isMbUser && IS_MBIZUSER && isPromoQuotaProEnabled;

    if (base) {
        header = <>Бесплатный объём Облака {space?.value}</>;
    } else if (product?.isForceDiscountTrial) {
        header = (
            <>
                + {space?.value} {period && `на\u00A0${getPeriodName(product?.period, false, true)}`}
            </>
        );
        // tempexp_16598-next-line
    } else if (product?.id && Object.values(cheapestProductIDs).includes(product.id)) {
        header = <>{bytesToNDigits(BASEQUOTA, 3).value} без рекламы</>;
    } else {
        header = (
            <>
                {isTrial && !isPrepaid ? 'Триал' : ''} + {space?.value} {period && `на\u00A0${period}`}
            </>
        );
    }

    if (isMbizProduct) {
        header = (
            <>
                Mail&nbsp;Space&nbsp;PRO + {space?.value} {period && `на\u00A0${period}`}
            </>
        );
    }

    let warning = '';

    if (type === ESubscriptionsTypes.ios || type === ESubscriptionsTypes.android || type === ESubscriptionsTypes.rustore) {
        warning = `Данная подписка может быть изменена или отменена в ${SubscriptionMobileName[subscription.type]}`;
    }

    if (partner && Boolean(path(['accountLink'], subscription))) {
        warning = 'Управление тарифом доступно в личном кабинете';
    }

    return { header, warning };
};
