import {
    Icon20CopyOutline,
    Icon20DeleteOutline,
    Icon20FolderSimpleArrowRightOutlune,
    Icon20MailOutline,
    Icon20More,
    Icon20PrinterOutline,
    Icon20WarningTriangleOutline,
    Icon24ExternalLinkOutline,
} from '@vkontakte/icons';
import Lottie, { type LottieRefCurrentProps } from 'lottie-react';
import React, { type FC, type RefObject, useEffect, useMemo, useRef } from 'react';
import type { AttachesItem } from 'reactApp/modules/attaches/attaches.types';
import type { CloudFile } from 'reactApp/modules/storage/storage.types';
import { AnimatedScale } from 'reactApp/ui/AnimatedScale/AnimatedScale';
import { DropdownItemAction } from 'reactApp/ui/DropdownItemAction/DropdownItemAction';
import { DropdownList, DropdownTheme } from 'reactApp/ui/DropdownList/DropdownList';
import { useDropdownList } from 'reactApp/ui/DropdownList/DropdownList.hooks';
import { MenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/MenuButton/MenuButton';

import animationFavorite from './animation/favorite.json';
import styles from './MoreMenuButton.css';

export const enum MenuItemName {
    OPEN_IN_MY = 'openinmy',
    OPEN_IN_PARENT = 'openinparent',
    COPY = 'copy',
    MOVE = 'move',
    MAIL = 'mail',
    FAVORITE = 'favorite',
    PRINT = 'print',
    DELETE = 'delete',
    COMPLAIN = 'complain',
}

interface MenuItem {
    name: MenuItemName;
    active?: boolean;
    divider?: boolean;
    onClick?: (file: CloudFile | AttachesItem) => void;
}

const getDropdownList = (list: MenuItem[], lottieRef: RefObject<LottieRefCurrentProps>) => {
    return list.map(({ name, active, divider, onClick }) => {
        switch (name) {
            case MenuItemName.OPEN_IN_MY:
                return {
                    id: name,
                    divider,
                    text: 'Открыть в моём Облаке',
                    onClick,
                    className: styles.itemWrapper,
                    icon: <Icon24ExternalLinkOutline />,
                };
            case MenuItemName.OPEN_IN_PARENT:
                return {
                    id: name,
                    divider,
                    text: 'Показать в папке',
                    onClick,
                    className: styles.itemWrapper,
                    icon: <Icon24ExternalLinkOutline />,
                };
            case MenuItemName.COPY:
                return {
                    id: name,
                    divider,
                    text: 'Копировать',
                    onClick,
                    className: styles.itemWrapper,
                    icon: <Icon20CopyOutline />,
                };
            case MenuItemName.MOVE:
                return {
                    id: name,
                    divider,
                    text: 'Переместить в папку',
                    onClick,
                    className: styles.itemWrapper,
                    icon: <Icon20FolderSimpleArrowRightOutlune />,
                };
            case MenuItemName.MAIL:
                return {
                    id: name,
                    divider,
                    text: 'Отправить по почте',
                    onClick,
                    className: styles.itemWrapper,
                    icon: <Icon20MailOutline />,
                };
            case MenuItemName.FAVORITE:
                return {
                    id: name,
                    divider,
                    text: active ? 'Убрать из избранного' : 'Добавить в избранное',
                    onClick: (file) => {
                        lottieRef?.current?.setDirection(active ? -1 : 1);
                        lottieRef?.current?.play();

                        onClick?.(file);
                    },
                    className: styles.itemWrapper,
                    disableCloseOnClick: true,
                    icon: (
                        <Lottie
                            className={styles.icon}
                            animationData={animationFavorite}
                            autoplay={false}
                            loop={false}
                            lottieRef={lottieRef}
                            onDOMLoaded={() => {
                                if (active) {
                                    lottieRef?.current?.goToAndPlay(60, true);
                                }
                            }}
                        />
                    ),
                };
            case MenuItemName.DELETE:
                return {
                    id: name,
                    divider,
                    text: 'Переместить в корзину',
                    onClick,
                    className: styles.itemWrapper,
                    icon: <Icon20DeleteOutline />,
                };
            case MenuItemName.PRINT:
                return {
                    id: name,
                    divider,
                    text: 'Распечатать',
                    onClick,
                    className: styles.itemWrapper,
                    icon: <Icon20PrinterOutline />,
                };
            case MenuItemName.COMPLAIN:
                return {
                    id: name,
                    divider,
                    text: 'Пожаловаться',
                    onClick,
                    className: styles.itemWrapper,
                    icon: <Icon20WarningTriangleOutline />,
                };
        }
    });
};

const MoreMenuItem = ({ text, icon }) => {
    const ref = useRef<HTMLDivElement>(null);

    return <DropdownItemAction className={styles.item} text={text} icon={<AnimatedScale target={ref}>{icon}</AnimatedScale>} ref={ref} />;
};

interface Props {
    list: MenuItem[];
    onOpen?: () => void;
    onClose?: () => void;
}

export const MoreMenuButton: FC<Props> = ({ list, onOpen, onClose }) => {
    const { controlRef, isOpen, open, close, findDropdownPosition } = useDropdownList<HTMLDivElement>({ offsetTop: 8 });

    const lottieRef = useRef<LottieRefCurrentProps>(null);

    const dropdownList = useMemo(() => getDropdownList(list, lottieRef), [list, lottieRef]);

    useEffect(() => {
        if (isOpen) {
            onOpen?.();
        } else {
            onClose?.();
        }
    }, [isOpen, onOpen, onClose]);

    return list.length > 0 ? (
        <>
            <MenuButton
                hint="Дополнительные действия"
                icon={<Icon20More />}
                active={isOpen}
                ref={controlRef}
                onClick={() => {
                    if (isOpen) {
                        close();
                    } else {
                        open();
                    }
                }}
            />
            {isOpen && (
                <DropdownList
                    gaId="viewer-more"
                    className={styles.list}
                    close={close}
                    closeOnResize
                    posX={0}
                    posY={0}
                    parentRef={controlRef}
                    theme={DropdownTheme.space}
                    calcPosition={findDropdownPosition}
                    list={dropdownList}
                    renderItem={(item) => <MoreMenuItem text={item.text} icon={item.icon} />}
                />
            )}
        </>
    ) : null;
};
