import { getErrorMsg, logger } from 'lib/logger';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import {
    dispatchNewSearchRadar,
    dispatchSearchRadar,
    dispatchSearchRadarToolbarAction,
    sendAuthDwhRequest,
} from 'reactApp/modules/dwh/dwh.module';
import { newSendSearchRadarAnalytics, sendSearchRadarAnalytics } from 'reactApp/modules/search/search.analytics';
import { getSearchAnalyticsParams } from 'reactApp/modules/search/search.selectors';
import { loadQuota } from 'reactApp/modules/userQuota/userQuota.saga';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';
import { call, cancel, put, select, takeEvery } from 'redux-saga/effects';

const { utm_source, utm_campaign, utm_term } = getQueryParams();

function* sendAuthDwh(actionData: ReturnType<typeof sendAuthDwhRequest>) {
    try {
        const { action, source = '', place = 'none' } = actionData.payload;

        let { totalSize, freeSize }: ReturnType<typeof UserQuotaSelectors.getSpace> = yield select(UserQuotaSelectors.getSpace);

        if (!freeSize) {
            yield call(loadQuota);

            const space: ReturnType<typeof UserQuotaSelectors.getSpace> = yield select(UserQuotaSelectors.getSpace);
            totalSize = space.totalSize;
            freeSize = space.freeSize;
        }

        sendDwh({
            eventCategory: ECategoryGa.onboarding,
            action,
            dwhData: {
                source,
                utm_source,
                utm_campaign,
                utm_term,
                size_quota: totalSize,
                free_quota: freeSize,
                place,
            },
        });
    } catch (error) {
        logger.error(getErrorMsg(error), error);
        yield cancel();
    }
}

function* handleToolbarAction(actionData) {
    try {
        const { payload = {} } = actionData;
        const actionsToHandle = ['move_to_folder', 'move-to-folder', 'copy', 'delete'];
        if (actionsToHandle.includes(payload?.action)) {
            const { items, ...dwhData } = payload;
            yield put(dispatchSearchRadar({ dwhData, files: items }));
        }
    } catch (error) {
        logger.error(getErrorMsg(error), error);
        yield cancel();
    }
}

function* handleDispatchSearchRadar(actionData) {
    const { dwhData, files = [] } = actionData.payload;
    const searchParams = yield select(getSearchAnalyticsParams);

    sendSearchRadarAnalytics({ ...dwhData, ...searchParams }, files);
}

function* handleDispatchNewSearchRadar(actionData) {
    const { dwhData, items, isSearchPhrase } = actionData.payload;
    const searchParams = yield select(getSearchAnalyticsParams);

    newSendSearchRadarAnalytics({ ...dwhData, searchParams, items, isSearchPhrase });
}

export function* watchDwh() {
    yield takeEvery(sendAuthDwhRequest.toString(), sendAuthDwh);
    yield takeEvery(dispatchSearchRadarToolbarAction.toString(), handleToolbarAction);
    yield takeEvery(dispatchSearchRadar.toString(), handleDispatchSearchRadar);
    yield takeEvery(dispatchNewSearchRadar.toString(), handleDispatchNewSearchRadar);
}
