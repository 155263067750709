import type { UflrType } from 'lib/uflr';
import type { GalleryItem } from 'reactApp/modules/gallery/gallery.types';
import type { IFolderFlags } from 'reactApp/modules/home/home.types';
import type { WeblinkDownloadable } from 'reactApp/modules/public/public.types';
import type { SearchItem } from 'reactApp/modules/search/search.types';
import type { CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import type { IPublicInfo, Kind, SubKind, Url, VirusScan } from 'reactApp/types/Tree';

export interface IAlbumsState {
    list: Record<string, AlbumItem>;
    currentUploadAlbum?: string;
    selectedItems: Record<string, GalleryItem | SearchItem>;
}

export type AlbumItem = IAlbum | IAlbumItem;

export interface IAlbum extends Partial<IPublicInfo>, WeblinkDownloadable {
    id: string;
    name: string;
    storage: EStorageType.albums;
    isFolder: true;
    kind: Kind;
    cursor?: string;
    isLoading?: boolean;
    isLoaded?: boolean;
    hasMoreToLoad?: boolean;
    parent: string;
    flags?: IFolderFlags;
    type: 'album';
    childs: string[];
    photosCount?: number;

    thumbnails?: Record<string, string>;

    error?: boolean;
    isReadOnly?: boolean;
    home?: string;
    ctime?: number;
    expiresTimeSize?: number;
    intraDomainShare?: boolean;
}

export interface IAlbumItem extends Partial<IPublicInfo>, WeblinkDownloadable {
    name: string;
    mtime: number;
    size: number;
    id: string;
    storage: EStorageType.albums;
    ext: string;
    nameWithoutExt: string;
    parent: string;
    virusScan: VirusScan;
    isFolder: false;
    thumbnails?: Record<string, string>;
    url?: Url;
    kind: Kind;
    subKind: SubKind;
    isInFavorites: boolean;
    uflr?: UflrType;
    path: string;
    home: string;
    type: 'album';
    isReadOnly?: boolean;
    expiresTimeSize?: number;
    intraDomainShare?: boolean;
}

export interface ICreateAlbumSuccess {
    id: string;
    name: string;
}

export interface ICreateAlbumRequest {
    albumName?: string;
    items?: CloudItem[];
}

export const isAlbum = (album): album is IAlbum => (album ? (album as IAlbum)?.type === 'album' : false);

export interface IAlbumId {
    albumId: string;
}

export interface ILoadAlbumSuccess {
    albumId: string;
    photos_count?: number;
    cursor?: string;
    list: AlbumItem[];
    title: string;
}

export interface IAddItems {
    albumId: string;
    items: AlbumItem[];
}

export interface ILoadAlbumsListSuccess {
    cursor: string;
    list: IAlbum[];
}

export interface ToogleItem {
    selectedItem: GalleryItem | SearchItem;
}

export interface SelectAllItems {
    allItems: Record<string, GalleryItem | SearchItem>;
}
