import { getErrorMsg, logger } from 'lib/logger';
import { captureException } from 'reactApp/utils/tracer';
import { cancel, put } from 'redux-saga/effects';

/*
 * @desc если нет noCancel - то выполенение всего текущего экшена стопнется на этом месте.
 * Это может быть проблемой, если мы обработали как-то ошибку, и хотим продолжить выполение экшена далее
 */
export function* loggerSaga({ error, action, noCancel = false }: { error?: any; action?: any; noCancel?: boolean }) {
    logger.error(getErrorMsg(error), error);

    captureException(error, { issueKey: 'saga error, logger' });

    if (action) {
        yield put(action);
    }

    if (!noCancel) {
        yield cancel();
    }
}
