import { logger } from 'lib/logger';
import { xray } from 'lib/xray';
import { seriallyClearQuotaCleanerFeature } from 'reactApp/appHelpers/featuresHelpers/features/quotaCleanerSeriallyClear';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';
import { hideSnackbarAction, showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { quotaCleanerRlog } from 'reactApp/modules/userQuotaCleaner/helpers/constants';
import {
    reloadGroupAfterDelete,
    setIsItemsDeleteError,
    setIsItemsDeleteProcess,
    setNeedsGroupReload,
} from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.actions';
import type { UserQuotaGroupId } from 'reactApp/types/QuotaCleaner';
import { put, select } from 'redux-saga/effects';

export function* deleteItemsError(action: {
    error;
    groupId: UserQuotaGroupId;
    ids: string[];
    itemIds: string[];
    size: number;
    isMsgRemove?: boolean;
}) {
    const { error, groupId, ids, itemIds, size, isMsgRemove } = action;
    const isMobile = yield select(QuotaLandingSelectors.isMobile);
    yield put(setIsItemsDeleteProcess({ value: false }));

    logger.error(error);
    xray.send(`quota-cln-delete-${isMsgRemove ? 'msg-' : ''}err${isMobile ? '-mob' : ''}`, {
        i: groupId,
        rlog: quotaCleanerRlog,
        rlog_message: {
            error,
            groupId,
            ids,
        },
    });

    if (seriallyClearQuotaCleanerFeature) {
        yield put(hideSnackbarAction('quotaCleanerDeleteAllItems'));
        yield put(
            showSnackbarAction({
                type: SnackbarTypes.failure,
                id: 'quotaCleanerDeleteAllItemsError',
                text: `Не получилось удалить файлы. \nПовторите попытку позже`,
                closable: true,
                wrapText: true,
            })
        );
    } else {
        yield put(
            showSnackbarAction({
                id: 'quotaCleanerDeleteError',
                type: SnackbarTypes.failure,
                closable: true,
                title: 'Ошибка удаления',
                text: 'Попробуйте повторить действие через пару минут.',
            })
        );
    }

    yield put(setNeedsGroupReload({ value: true }));
    yield put(setIsItemsDeleteError({ value: true }));
    yield put(reloadGroupAfterDelete({ ids, groupId, isGroupReset: true, size, itemIds }));
}
