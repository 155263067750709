import { sendDwh } from 'reactApp/utils/ga';

export const sendTariffPollAnalytics = ({ action, label = '', ...restData }) => {
    sendDwh({
        eventCategory: 'modal',
        action,
        label,
        dwhData: {
            ...restData,
        },
    });
};
