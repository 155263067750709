import React, { type ReactElement, memo, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import type { PublishItem } from 'reactApp/modules/modifying/modifying.types';
import type { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import { getItemById } from 'reactApp/modules/storage/storage.selectors';
import type { RootState } from 'reactApp/store';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';

import { PublicPassword } from './PublicPassword';
import type { PublicPasswordProps } from './PublicPassword.types';

export const PublicPasswordDesktopDialog = memo(function SharingNewDesktop(props: IPropsWithPopup<PublicPasswordProps>): ReactElement {
    const item = useSelector((state: RootState) => getItemById(state, props.item?.id || '')) as PublishItem;

    const handleClose = useCallback(() => {
        props.onClose();
        props.onDialogClose?.(item);
    }, [item, props]);

    return (
        <Dialog open size="middle" id="public-password-dialog" onCancel={handleClose}>
            <Content isModal>
                <PublicPassword {...props} item={item} onClose={handleClose} />
            </Content>
        </Dialog>
    );
});

export const PublicPasswordPhoneDialog = memo(function SharingNewPhone(props: IPropsWithPopup<PublicPasswordProps>): ReactElement {
    const item = useSelector((state: RootState) => getItemById(state, props.item?.id || '')) as PublishItem;

    const scrollRef = useRef(null);

    const handleClose = useCallback(() => {
        props.onClose();
        props.onDialogClose?.(item);
    }, [item, props]);

    return (
        <MobileDialog id="sharing-new" closeOnDimmerClick mod="base" topmost onClose={handleClose} scrollRef={scrollRef}>
            <PublicPassword {...props} item={item} onClose={handleClose} ref={scrollRef} />
        </MobileDialog>
    );
});

export const PublicPasswordDialog = memo(function SharingNew(props: IPropsWithPopup<PublicPasswordProps>): ReactElement {
    const isPhone = EnvironmentSelectors.isPhone();
    const Component = isPhone ? PublicPasswordPhoneDialog : PublicPasswordDesktopDialog;
    return <Component {...props} />;
});
