import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { Icon20MoreVertical, Icon20SmileAddOutline } from '@vkontakte/icons';
import { Caption, Headline, Spacing } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { type MouseEvent, type ReactElement, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { IS_ACTION_USER } from 'reactApp/appHelpers/configHelpers';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { quotaTooltip } from 'reactApp/appHelpers/featuresHelpers';
import { autumnPromo } from 'reactApp/appHelpers/featuresHelpers/features/autumnPromo';
import { blackFriday2024 } from 'reactApp/appHelpers/featuresHelpers/features/blackFriday2024';
import { buttonHideActionIcon } from 'reactApp/appHelpers/featuresHelpers/features/buttonHideActionIcon';
import { cyberMonday2025 } from 'reactApp/appHelpers/featuresHelpers/features/cyberMonday2025';
import { abSpaceButton } from 'reactApp/appHelpers/featuresHelpers/features/spaceButton';
import { VK_UI_DARK_THEME } from 'reactApp/constants';
import { SPACE_LIST } from 'reactApp/constants/spaceList';
import { useGoToSubscriptions } from 'reactApp/hooks/useGoToSubscriptions';
import { useSendSpaceMenuAnalytics } from 'reactApp/hooks/UseSendSpaceMenuAnalytics';
import { canShowUserQuota } from 'reactApp/modules/family/family.selectors';
import { getReceivedGiftsLoadingState, hasAutoupload10TbGift } from 'reactApp/modules/giftReceived/giftReceived.selectors';
import { isDefaultRootContent } from 'reactApp/modules/home/home.selectors';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { useCallbacks } from 'reactApp/ui/Space/SingleSpace/hooks/useCallbacks';
import { useContent } from 'reactApp/ui/Space/SingleSpace/hooks/useContent';
import { useQuotaInfo } from 'reactApp/ui/Space/SingleSpace/hooks/useQuotaInfo';
import { SpaceButtonAnalyticsName, useSendSpaceAnalytics } from 'reactApp/ui/Space/SingleSpace/SingleMenu.analytics';
import { SingleSpaceMenu } from 'reactApp/ui/Space/SingleSpace/SingleSpaceMenu/SingleSpaceMenu';
import { SingleSpaceProgress } from 'reactApp/ui/Space/SingleSpace/SingleSpaceProgress/SingleSpaceProgress';
import { useSpace } from 'reactApp/ui/Space/SingleSpace/SingleSpaceProgress/useSpace';
import { useUserAndFamilySpace } from 'reactApp/ui/Space/SingleSpace/SingleSpaceProgress/useUserAndFamilySpace';
import type { Props } from 'reactApp/ui/Space/Space.types';
import { SpaceCountdown } from 'reactApp/ui/Space/SpaceCountdown/SpaceCountdown';
import { SpaceLoader } from 'reactApp/ui/Space/SpaceLoader/SpaceLoader';
import { sendDwh } from 'reactApp/utils/ga';
import { noopVoid } from 'reactApp/utils/helpers';

import { QuotaTooltip } from './QuotaTooltip/QuotaTooltip';
import styles from './SingleSpace.css';

export const SingleSpace = memo<Props>(
    ({ isSubscriptionsPage = false, isMobile = false, className, isRebranding = false }): ReactElement => {
        const showQuotaWithFamily = useSelector(canShowUserQuota);

        const { goToSubscriptions } = useGoToSubscriptions();

        const { total, usedTotal, isQuotaLoaded, isFull } = useQuotaInfo(showQuotaWithFamily);

        const isBizUser = useSelector(UserSelectors.isBizUser);
        const isPaid = useSelector(UserSelectors.isPaidUser);
        const isDefaultContent = useSelector(isDefaultRootContent);
        const withTooltip = quotaTooltip && quotaTooltip !== 'a' && quotaTooltip !== 'd' && !isDefaultContent;

        // tempexp_16481-start
        const has10TbAutoupload = useSelector(hasAutoupload10TbGift);
        const totalQuota = has10TbAutoupload && total?.original ? bytesToNDigits(total.original - SPACE_LIST.tb_10, 3).value : total?.value;
        const { isLoaded: giftsLoaded } = useSelector(getReceivedGiftsLoadingState);
        const areGiftsLoaded = isPaid ? giftsLoaded : true;
        // tempexp_16481-end

        const countdownPromo = useSelector(PromoSelectors.getPromo(EPromoType.space));

        // tempexp_18363-next-line
        const isMarketingPromo = IS_ACTION_USER && !isFull && !buttonHideActionIcon;

        const isCountdownPromo = Boolean(countdownPromo) && !isSubscriptionsPage;
        const { warning, buttonText, buttonState, hideButton, isMySubscription, hideMenu, showFamilyButton, analyticsName } = useContent({
            isMarketingPromo,
            isSubscriptionsPage,
        });
        const sendAnalytics = useSendSpaceAnalytics({
            isCountdownPromo,
            isMarketingPromo,
            skipAnalytics: hideButton || isBizUser,
        });
        const sendMenuAnalytics = useSendSpaceMenuAnalytics(isMobile);
        const { onPayment, onFamilyClick, updateQuota } = useCallbacks({
            isMobile,
            countdownTariff: (isCountdownPromo && countdownPromo?.data?.productId) || '',
            goToSubscriptions,
            sendAnalytics,
            showFamilyButton,
            analyticsName,
        });

        const wasShown = useRef<boolean>(false);
        const menuRef = useRef<HTMLDivElement | null>(null);
        const buttonRef = useRef<HTMLDivElement | null>(null);
        const [isShowMenu, setShowMenu] = useState<boolean>(false);

        const showMenu = () => {
            if (isBizUser) {
                return;
            }

            sendMenuAnalytics('menu');
            emitAnalyticEvent(AnalyticEventNames.QUOTA_BLOCK_MENU_CLICK);
            setShowMenu(true);
        };

        const closeMenu = (event?: MouseEvent) => {
            event?.stopPropagation();
            setShowMenu(false);
        };

        const onButtonClick = useCallback(
            (event) => {
                event?.stopPropagation();
                closeMenu(event);
                // tempexp_17990-next-line
                if (showFamilyButton && !abSpaceButton) {
                    onFamilyClick?.();
                } else {
                    onPayment();
                }
                if (quotaTooltip) {
                    emitAnalyticEvent(AnalyticEventNames.QUOTA_BUTTON_CLICK);
                    sendDwh({ eventCategory: 'quota-tooltip_quota-button-click' });
                }
            },
            [onPayment, onFamilyClick, showFamilyButton]
        );

        // tempexp_17990-start
        const onFamilyButtonClick = useCallback(
            (event) => {
                event?.stopPropagation();
                closeMenu(event);
                sendAnalytics('click', true, SpaceButtonAnalyticsName.FAMILY);
                onFamilyClick?.();
            },
            [onFamilyClick]
        );
        // tempexp_17990-end

        const button = useMemo(
            () => (
                <div className={styles.buttonBlock}>
                    <Spacing size={16} />
                    <div
                        className={classNames(styles.buttonWrapper, {
                            // tempexp_17990-next-line
                            [styles.buttonWrapperRow]: showFamilyButton && abSpaceButton,
                        })}
                        ref={buttonRef}
                    >
                        <Button
                            {...buttonState}
                            fluid
                            theme="octavius"
                            sizeMode={isMobile ? ButtonSizeMode.big : ButtonSizeMode.small}
                            onClick={isCountdownPromo ? noopVoid : onButtonClick}
                        >
                            <div className={styles.buttonText}>{buttonText}</div>
                        </Button>
                        {/* tempexp_17990-start */}
                        {showFamilyButton && abSpaceButton && (
                            <Button
                                {...buttonState}
                                sizeMode={ButtonSizeMode.small}
                                className={styles.buttonFamily}
                                title="Поделиться местом"
                                onClick={onFamilyButtonClick}
                            >
                                <Icon20SmileAddOutline />
                            </Button>
                        )}
                        {/* tempexp_17990-end */}
                    </div>
                </div>
            ),
            [buttonState, buttonText, isCountdownPromo, isMarketingPromo, isMobile, onButtonClick]
        );

        const tooltip = (
            <QuotaTooltip
                targetRef={buttonRef}
                onClose={() => {
                    emitAnalyticEvent(AnalyticEventNames.QUOTA_TOOLTIP_CLOSE);
                    sendDwh({ eventCategory: 'quota-tooltip_quota-tooltip-close' });
                }}
                onShow={() => {
                    emitAnalyticEvent(AnalyticEventNames.QUOTA_TOOLTIP_SHOW);
                    sendDwh({ eventCategory: 'quota-tooltip_quota-tooltip-show' });
                }}
            />
        );
        useEffect(() => {
            if (isQuotaLoaded && !wasShown.current) {
                wasShown.current = true;

                sendAnalytics('view', showFamilyButton, analyticsName);

                // tempexp_17990-start
                if (analyticsName === SpaceButtonAnalyticsName.SPACE) {
                    emitAnalyticEvent(AnalyticEventNames.QUOTA_BLOCK_SPACE_BUTTON_VIEW);
                }

                if (showFamilyButton && abSpaceButton) {
                    sendAnalytics('view', true, SpaceButtonAnalyticsName.FAMILY);
                }
                // tempexp_17990-end

                if (isFull) {
                    emitAnalyticEvent(AnalyticEventNames.QUOTA_BLOCK_OVER_VIEW);
                    sendAnalytics('view', false, `overquota_space_${isPaid ? 'paid' : 'free'}`);
                }
            }
        }, [isQuotaLoaded, sendAnalytics, isFull, isPaid, showFamilyButton]);

        useEffect(() => {
            if (wasShown.current && showQuotaWithFamily) {
                updateQuota();
            }
        }, [showQuotaWithFamily]);

        useEffect(() => {
            if (isMarketingPromo && !buttonState.error && quotaTooltip) {
                emitAnalyticEvent(AnalyticEventNames.QUOTA_BUTTON_SHOW);
                sendDwh({ eventCategory: 'quota-tooltip_quota-button-show' });
            }
        }, []);
        if (!isQuotaLoaded || !areGiftsLoaded) {
            return (
                <>
                    <div className={classNames(styles.spaceBlock, styles.spaceBlock_loading)}>
                        <SpaceLoader withButton={!hideButton} isCompact />
                    </div>
                    <Spacing size={isMobile ? 4 : 8} />
                </>
            );
        }

        const containsDarkTheme = document.body.classList.contains(VK_UI_DARK_THEME);
        return (
            <div className={classNames(className)}>
                <div
                    className={classNames(styles.spaceBlock, {
                        [styles.spaceBlock_overquota]: !isRebranding && isFull,
                        [styles.spaceBlock_marketingPromo]: isMarketingPromo,
                        [styles.spaceBlock_mobile]: isMobile,
                        [styles.spaceBlock_subscriptionRebranding]: isSubscriptionsPage && isRebranding,
                        [styles.spaceBlock_autumnPromo]: autumnPromo,
                        [styles.spaceBlock_autumnPromoDarkTheme]: autumnPromo && containsDarkTheme,
                        [styles.spaceBlock_blackFriday2024]: blackFriday2024,
                        [styles.spaceBlock_blackFriday2024DarkTheme]: blackFriday2024 && containsDarkTheme,
                        [styles.spaceBlock_cyberMonday2025]: cyberMonday2025,
                    })}
                    onClick={showMenu}
                >
                    <div
                        className={classNames(styles.info, {
                            [styles.info_subscriptionRebranding]: isSubscriptionsPage && isRebranding,
                        })}
                    >
                        <div className={styles.titleBlock}>
                            <Headline level={isMobile ? '1' : '2'}>
                                Занято {usedTotal?.value} из {totalQuota}
                            </Headline>
                            {!hideMenu && (
                                <div
                                    className={classNames(styles.menuIcon, {
                                        [styles.menuIcon_active]: isShowMenu,
                                    })}
                                    ref={menuRef}
                                    onClick={isShowMenu ? closeMenu : showMenu}
                                >
                                    <Icon20MoreVertical />
                                </div>
                            )}
                        </div>
                        {/* tempexp_16481-next-line */}
                        {has10TbAutoupload && (
                            <Caption level="2" className={styles.autoupload}>
                                +10 ТБ для телефона
                            </Caption>
                        )}
                        <Spacing size={12} />
                        <SingleSpaceProgress
                            isWrap={showQuotaWithFamily}
                            isMobile={isMobile}
                            isSubscriprionRebranding={isSubscriptionsPage && isRebranding}
                            useSpaceHook={showQuotaWithFamily ? useUserAndFamilySpace : useSpace}
                            rebranding={isRebranding}
                        />
                        {Boolean(warning) && !isMobile && (
                            <>
                                <Spacing size={12} />
                                <div className={styles.warning}>{warning}</div>
                            </>
                        )}
                    </div>
                    {!hideButton && !isCountdownPromo && button}
                    {!hideButton && !isCountdownPromo && isMarketingPromo && !buttonState.error && withTooltip && tooltip}
                </div>

                {isCountdownPromo && (
                    <>
                        <Spacing size={12} />
                        <div className={styles.promo}>
                            <SpaceCountdown onClick={onButtonClick} button={button} />
                            {isMarketingPromo && !buttonState.error && withTooltip && tooltip}
                        </div>
                        <Spacing size={4} />
                    </>
                )}

                <Spacing size={isMobile ? 4 : 8} />

                {isShowMenu && (
                    <SingleSpaceMenu
                        isMobile={isMobile}
                        isSubscriptionsPage={isSubscriptionsPage}
                        parentRef={menuRef}
                        onClose={closeMenu}
                        isMySubscription={isMySubscription}
                    />
                )}
            </div>
        );
    }
);

SingleSpace.displayName = 'SingleSpace';
