import {
    Icon24ChainOutline,
    Icon24DownloadCloudOutline,
    Icon24DownloadOutline,
    Icon24InfoCircleOutline,
    Icon24PenOutline,
    Icon24Square4Outline,
    Icon24WarningTriangleOutline,
    Icon28Rectangle2Outline,
} from '@vkontakte/icons';
import { ReactComponent as AuthorsIcon } from 'img/icons/mobile/authors.svg';
import { ReactComponent as FacesIcon } from 'img/icons/mobile/faces.svg';
import { ReactComponent as RemoveIcon } from 'img/icons/mobile/remove.svg';
import { ReactComponent as RestoreIcon } from 'img/icons/mobile/restore.svg';
import { ReactComponent as ThumbsIcon } from 'img/icons/mobile/thumbs.svg';
import type { ReactComponent as SortDescIcon } from 'img/icons/sortDesc.svg';
import React from 'react';
import { IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { hasAuthorizationInfo } from 'reactApp/appHelpers/featuresHelpers/features/superAppAuthorizationInfo';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { MAIL_ATTACHES_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { VIEW_MODE_THUMBS_TEXT } from 'reactApp/constants/viewMode';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { ESortOder, ESortTypes } from 'reactApp/modules/sort/sort.types';
import { EActions, EStorageType } from 'reactApp/modules/storage/storage.types';
import { AuthorsCounter } from 'reactApp/ui/Mobile/AuthorsCounter/AuthorsCounter';
import { FacesCounter } from 'reactApp/ui/Mobile/FacesCounter/FacesCounter';
import { renderFileInfoModal } from 'reactApp/ui/Mobile/FileInfoModal/FileInfoModal.helpers';
import { renderFilterFacesModal } from 'reactApp/ui/Mobile/FilterFacesModal/FilterFacesModal.helpers';
import type { IOptionBlock, IOptionItem } from 'reactApp/ui/Mobile/OptionsModal/OptionsModal.types';
import { UploadInput } from 'reactApp/ui/Mobile/UploadInput/UploadInput';
import {
    Icon20DescendingOutline,
    Icon20SortAscendingOutline,
    Icon24Add,
    Icon24CalendarOutline,
    Icon24CopyOutline,
    Icon24DeleteOutline,
    Icon24FileSizeOutline,
    Icon24MoveOutline,
    Icon24RenameOutline,
    Icon24ShareOutline,
    Icon24TextOutline,
} from 'reactApp/ui/touchIcons';
import { noopVoid } from 'reactApp/utils/helpers';
import { PostMessageTypes, superAppPostMessage } from 'reactApp/utils/superAppWebViewHelpers';
import { EQueryParams } from 'server/helpers/getRequestParams';

import { renderFilterAuthorsModal } from '../FilterAuthorsModal/FIlterAuthorsModal.helpers';
import styles from './OptionsModal.css';

export const sortHeaderMap: Record<ESortOder, { sortHeader: string; SortIcon: typeof SortDescIcon }> = {
    asc: {
        sortHeader: 'По возрастанию',
        SortIcon: Icon20SortAscendingOutline,
    },
    desc: {
        sortHeader: 'По убыванию',
        SortIcon: Icon20DescendingOutline,
    },
};

export const optionsItems: { [key: string]: IOptionItem } = {
    viewThumbs: {
        id: 'viewThumbs',
        text: VIEW_MODE_THUMBS_TEXT,
        icon: <Icon24Square4Outline />,
        onClick: () => toolbarActions.view(EViewMode.thumbs),
    },
    viewList: {
        text: 'Списком',
        id: 'viewList',
        icon: <Icon28Rectangle2Outline height={24} width={24} />,
        onClick: () => toolbarActions.view(EViewMode.list),
    },
    viewGalleryGrid3: {
        text: '3 объекта',
        id: 'viewGalleryGrid3',
        icon: <ThumbsIcon height={24} width={24} />,
        onClick: () => toolbarActions.view(EViewMode.galleryGrid3),
    },
    sortName: {
        id: 'sortName',
        text: 'По алфавиту',
        icon: <Icon24TextOutline />,
    },
    sortSize: {
        id: 'sortSize',
        text: 'По размеру',
        icon: <Icon24FileSizeOutline />,
    },
    sortDate: {
        id: 'sortDate',
        text: 'По дате',
        icon: <Icon24CalendarOutline />,
    },
    authors: {
        id: 'authors',
        text: 'По участникам',
        icon: <AuthorsIcon height={24} width={24} />,
        rightItem: <AuthorsCounter />,
    },
    faces: {
        id: 'faces',
        text: 'Люди на фото',
        icon: <FacesIcon height={24} width={24} />,
        rightItem: <FacesCounter isOptionsModal />,
    },
    onlyMine: {
        id: 'onlyMine',
        text: 'Показывать только мои файлы',
        icon: <AuthorsIcon height={24} width={24} />,
    },
    remove: {
        id: 'remove',
        text: 'Перенести в корзину',
        icon: <Icon24DeleteOutline />,
    },
    info: {
        id: 'info',
        icon: <Icon24InfoCircleOutline />,
        text: 'Посмотреть свойства',
    },
    more: {
        id: 'more',
        icon: <Icon24InfoCircleOutline height={24} width={24} />,
        text: 'Больше действий...',
    },
    delete: {
        id: 'delete',
        icon: <RemoveIcon height={24} width={24} />,
        text: 'Удалить',
    },
    edit: {
        id: 'edit',
        icon: <Icon24PenOutline height={24} width={24} />,
        text: 'Редактировать документ',
    },
};

export const getActionsItems = ({
    isAnonymous,
    onUpload,
    onDownload,
    id,
    storage,
    weblink,
    sendAnalytics,
}): Partial<Record<EActions, IOptionItem>> => {
    const isAttaches = storage === EStorageType.attaches || storage === EStorageType.viewerAttaches;
    const fromDeeplink = getQueryParams()[EQueryParams.fromDeeplink];
    const isSuperAppWebview = IS_WEBVIEW && fromDeeplink;

    const disableButton: boolean = isSuperAppWebview && isAnonymous && hasAuthorizationInfo(isAnonymous);

    return {
        [EActions.restore]: {
            id: EActions.restore,
            text: 'Восстановить',
            icon: <RestoreIcon height={24} width={24} />,
            onClick: () => toolbarActions.restore({ id, source: 'datalist' }),
        },
        [EActions.upload]: {
            id: EActions.upload,
            text: 'Загрузить файлы',
            icon: <Icon24Add height={24} width={24} />,
            onClick: disableButton ? () => superAppPostMessage(PostMessageTypes.authorization) : onUpload,
            component: isAnonymous ? undefined : (
                <div className={styles.upload}>
                    <UploadInput inputName="public-options" />
                </div>
            ),
        },
        [EActions.shareLink]: {
            id: EActions.shareLink,
            text: 'Отправить ссылку',
            icon: <Icon24ChainOutline />,
            onClick: () => toolbarActions.shareLink({ id }),
        },
        [EActions.clone]: {
            id: EActions.clone,
            icon: <Icon24DownloadCloudOutline />,
            text: 'Сохранить в Облако',
            onClick: disableButton
                ? () => superAppPostMessage(PostMessageTypes.authorization)
                : () =>
                      toolbarActions.clone({
                          id,
                          storage,
                          source: 'modal',
                          destination: isAttaches ? MAIL_ATTACHES_FOLDER_ID : '',
                      }),
        },
        [EActions.download]: {
            id: EActions.download,
            text: 'Скачать',
            icon: <Icon24DownloadOutline />,
            onClick: onDownload,
        },
        [EActions.publish]: {
            id: EActions.publish,
            text: weblink ? 'Настроить доступ' : 'Поделиться',
            icon: weblink ? <Icon24ChainOutline /> : <Icon24ShareOutline className={styles.icon} />,
            onClick: () => {
                sendAnalytics?.({ action: 'new_public' });
                sendAnalytics?.({ action: 'open_public' });
                toolbarActions.publish('options', id, storage);
            },
        },
        [EActions.rename]: {
            id: EActions.rename,
            text: 'Переименовать',
            icon: <Icon24RenameOutline />,
            onClick: () => toolbarActions.rename(id),
        },
        [EActions.move]: {
            id: EActions.move,
            text: 'Переместить',
            icon: <Icon24MoveOutline />,
            onClick: () => {
                toolbarActions.move(id, sendAnalytics);
            },
        },
        [EActions.copy]: {
            id: EActions.copy,
            text: 'Копировать',
            icon: <Icon24CopyOutline />,
            onClick: () => {
                toolbarActions.copy(id, sendAnalytics);
            },
        },
        [EActions.complain]: {
            id: EActions.complain,
            text: 'Пожаловаться',
            icon: <Icon24WarningTriangleOutline data-qa-id="complain-more" />,
            onClick: toolbarActions.sendComplain,
        },
    };
};

export const getActionsBlock = ({ onlyActions, actionsList }): IOptionBlock => ({
    id: 'actions',
    header: onlyActions ? '' : 'Действия с папкой',
    items: actionsList,
});

export const getSortBlock = ({ changeSortOrder, sort }): IOptionBlock => {
    const { sortHeader, SortIcon } = sortHeaderMap[sort.order || ESortOder.asc];

    return {
        id: 'sort',
        header: 'Сортировка',
        items: [
            {
                ...optionsItems.sortDate,
                active: sort.type === ESortTypes.mtime,
                onClick: () => toolbarActions.sort(`${ESortTypes.mtime}-${sort.order}`),
            },
            {
                ...optionsItems.sortSize,
                active: sort.type === ESortTypes.size,
                onClick: () => toolbarActions.sort(`${ESortTypes.size}-${sort.order}`),
            },
            {
                ...optionsItems.sortName,
                active: sort.type === ESortTypes.name,
                onClick: () => toolbarActions.sort(`${ESortTypes.name}-${sort.order}`),
            },
        ],
        headerComponent: (
            <div className={styles.headerComponent} onClick={changeSortOrder} data-qa-order={sort.order}>
                {sortHeader}
                <div className={styles.sortIcon}>{<SortIcon height={20} width={20} />}</div>
            </div>
        ),
    };
};

export const getFilteredAuthorsBlock = ({ resetFilter }): IOptionBlock => ({
    id: 'filter',
    header: 'Фильтр',
    items: [
        {
            ...optionsItems.authors,
            onClick: renderFilterAuthorsModal,
        },
    ],
    headerComponent: (
        <div className={styles.headerComponent} onClick={resetFilter}>
            Сбросить
        </div>
    ),
});

export const getRemoveBlock = ({ id, sendAnalytics }): IOptionBlock => ({
    id: 'remove',
    items: [
        {
            ...optionsItems.remove,
            onClick: () => {
                toolbarActions.remove(id, sendAnalytics);
            },
        },
    ],
});

export const getDeleteBlock = ({ id, sendAnalytics }): IOptionBlock => ({
    id: 'delete',
    items: [
        {
            ...optionsItems.delete,
            onClick: () => {
                toolbarActions.remove(id, sendAnalytics);
            },
        },
    ],
});

export const getViewBlock = ({ viewMode, storage, isNewMobileGalleryGridEnabled }) => {
    const blockInfo = {
        id: 'view',
        header: 'Формат отображения',
        items: [
            {
                ...optionsItems.viewThumbs,
                active: viewMode === EViewMode.thumbs,
            },
            {
                ...optionsItems.viewList,
                active: viewMode === EViewMode.list,
            },
        ],
    };

    /**
     * TODO: CLOUDWEB-13281. Cетка 'galleryGrid3' плохо перформит.
     * Убрали под фичу.
     */
    if (storage === EStorageType.gallery && isNewMobileGalleryGridEnabled) {
        const galleryViewModes = [
            {
                ...optionsItems.viewGalleryGrid3,
                active: viewMode === EViewMode.galleryGrid3,
            },
        ];

        blockInfo.items = [...galleryViewModes, ...blockInfo.items];
    }

    return blockInfo;
};

export const getFilterBlock = ({ filtered, items, resetFilter = noopVoid }): IOptionBlock => ({
    id: 'filter',
    header: 'Фильтр',
    items,
    headerComponent: filtered ? (
        <div className={styles.headerComponent} onClick={resetFilter}>
            Сбросить
        </div>
    ) : undefined,
});

export const getFileInfoItem = ({ id, storage, isArchive, callback }): IOptionItem => ({
    ...optionsItems.info,
    onClick: () => {
        callback();
        return renderFileInfoModal({ id, storage, isArchiveInfo: isArchive });
    },
});

export const getFacesOnFileItem = ({ fileId }): IOptionItem => ({
    ...optionsItems.faces,
    rightItem: undefined,
    onClick: () => renderFilterFacesModal({ fileId }),
});

export const getMoreBlock = () => ({
    id: 'more',
    items: [
        {
            ...optionsItems.more,
            onClick: () => {
                toolbarActions.more();
            },
        },
    ],
});

export const getEditItem = (openEditor: () => void) => ({
    ...optionsItems.edit,
    onClick: openEditor,
});
