import { Icon20Stars } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { createVideoInStories } from 'reactApp/appHelpers/featuresHelpers/features/createVideoInStories';
import type { EStoryType } from 'reactApp/modules/stories/stories.types';
import type { StorySummaryItem } from 'reactApp/ui/StoriesWidgetComponent/Stories.types';
import { noop } from 'reactApp/utils/helpers';
import { getTrimmedText } from 'reactApp/utils/textHelpers';

import styles from './StoryItem.css';

interface StoryItemProps {
    item: StorySummaryItem;
    mainCreateButton: React.MutableRefObject<HTMLDivElement | null>;
    isFirst?: boolean;
    onItemClick?({ id, type }: { id: string; type: EStoryType }): void;
    storiesRefs: Map<number, React.MutableRefObject<HTMLDivElement | null>>;
    onCreateClick(idx: number): void;
    idx: number;
}

export const StoryItem = memo(function StoryItem(props: StoryItemProps): JSX.Element | null {
    const { item, mainCreateButton, isFirst = false, onItemClick = noop, idx, storiesRefs, onCreateClick } = props;
    const additionalCreateButton = useRef<HTMLDivElement>(null);

    const handleClick = useCallback(() => {
        onItemClick({ id: item.id, type: item.type });
    }, [onItemClick, item]);

    const handleCreateClick = useCallback(
        (e) => {
            e?.stopPropagation();
            onCreateClick(idx);
        },
        [onItemClick, item]
    );

    useEffect(() => {
        if (mainCreateButton || additionalCreateButton) {
            storiesRefs.set(idx, isFirst ? mainCreateButton : additionalCreateButton);
        }
    }, [storiesRefs, mainCreateButton, additionalCreateButton]);

    return (
        <div className={classNames({ [styles.outer_border]: true, [styles.outer_border_viewed]: item.viewed })}>
            <div className={classNames({ [styles.root]: true, [styles.root_viewed]: item.viewed })} onClick={handleClick}>
                <div className={styles.description}>
                    <div className={styles.title}>{getTrimmedText(item.title, 45)}</div>
                </div>
                {createVideoInStories && (
                    <div className={styles.createRoot}>
                        {isFirst ? (
                            <div onClick={handleCreateClick} ref={mainCreateButton} className={classNames(styles.createButtonMain, idx)}>
                                Создать видео
                                <Icon20Stars width={16} height={16} className={styles.icon} />
                            </div>
                        ) : (
                            <div
                                onClick={handleCreateClick}
                                ref={additionalCreateButton}
                                className={classNames(styles.createButtonAdditional, idx)}
                            >
                                <Icon20Stars width={16} height={16} />
                            </div>
                        )}
                    </div>
                )}
                <img className={styles.image} draggable={false} src={item.thumb} alt={item.title} />
            </div>
        </div>
    );
});
