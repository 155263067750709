import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

export const abTrialFastCheckout: string = getFeature('trial-fastcheckout');

registerExperiment(
    'trial-fc',
    abTrialFastCheckout,
    [
        AnalyticEventNames.BUY_FAST_CHECKOUT_VIEW,
        AnalyticEventNames.BUY_FAST_CHECKOUT_CLICK_PAY,
        AnalyticEventNames.TRIAL_TARIFF_BUY,
        AnalyticEventNames.TRIAL_TARIFF_SHOW,
    ],
    {
        sendPlatformData: false,
        mapEventNames: {
            TRIAL_TARIFF_BUY: 'click',
            TRIAL_TARIFF_SHOW: 'show',
            BUY_FAST_CHECKOUT_CLICK_PAY: 'click',
            BUY_FAST_CHECKOUT_VIEW: 'show',
        },
        sendXrayArgs: {
            TRIAL_TARIFF_BUY: ({ paySource }) => ({ cancel: paySource !== 'subscriptions' }),
            TRIAL_TARIFF_SHOW: ({ paySource }) => ({ cancel: paySource !== 'subscriptions' }),
            BUY_FAST_CHECKOUT_VIEW: ({ paySource }) => ({ cancel: paySource !== 'subscriptions' }),
            BUY_FAST_CHECKOUT_CLICK_PAY: ({ paySource }) => ({ cancel: paySource !== 'subscriptions' }),
        },
    }
);
