/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { getFeatureParam } from 'Cloud/Application/FeaturesEs6';
import browser from 'Cloud/browser';
import { EHashLibType } from 'Cloud/webWorkers/types';
import { logger } from 'lib/logger';
import throttle from 'lodash.throttle';
import { HttpErrorCodes, HttpOkCodes } from 'reactApp/api/HttpErrorCodes';
import { O2Auth } from 'reactApp/api/O2Auth';
import { UploadResumableCheckAPICall } from 'reactApp/api/UploadResumableCheckAPICall';
import { IS_B2B_BIZ_USER, IS_ONPREMISE, USER_EMAIL, X_PAGE_ID } from 'reactApp/appHelpers/configHelpers';
import { isHashCalcLibCrypto, isHashCalcLibWasm, o2UploadFeature } from 'reactApp/appHelpers/featuresHelpers';
import { PUT_MAX_CHUNK } from 'reactApp/appHelpers/featuresHelpers/features/uploadNewFileApi';
import { EMPTY_FILE_HASH } from 'reactApp/constants/magicIdentificators';
import { getDomainFolderQuotaFree } from 'reactApp/modules/domainFolders/domainFolders.selectors';
import { FILE_READ_BLOCK_LENGTH, isUploadResumeAvailable, MAX_SIZE_FOR_WASM_HASH_LIB } from 'reactApp/modules/features/features.helpers';
import { HashCalculator } from 'reactApp/modules/file/hashCalculator';
import { getMountedFolderQuotaFree } from 'reactApp/modules/home/home.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { FileConflict } from 'reactApp/modules/uploading/errors/FileConflict';
import { FileTooLargeError } from 'reactApp/modules/uploading/errors/FileTooLargeError';
import { HttpError } from 'reactApp/modules/uploading/errors/HttpError';
import { WrongResultError } from 'reactApp/modules/uploading/errors/WrongResultError';
import { ConnectionFail } from 'reactApp/modules/uploading/fails/ConnectionFail';
import { FileReaderError } from 'reactApp/modules/uploading/fails/FileReaderError';
import { FileWithoutHashFail } from 'reactApp/modules/uploading/fails/FileWithoutHashFail';
import { HashCalcError } from 'reactApp/modules/uploading/fails/HashCalcError';
import { IllegalContentFail } from 'reactApp/modules/uploading/fails/IllegalContentFail';
import { OverQuotaFail } from 'reactApp/modules/uploading/fails/OverQuotaFail';
import { PublicFileSizeLimit } from 'reactApp/modules/uploading/fails/PublicFileSizeLimit';
import { UnsupportedFolderTransferFail } from 'reactApp/modules/uploading/fails/UnsupportedFolderTransferFail';
import { UserFileSizeLimitFail } from 'reactApp/modules/uploading/fails/UserFileSizeLimitFail';
import { parseBackendResponseTextOfPost, parseBackendResponseTextOfPut } from 'reactApp/modules/uploading/helpers/backend.helpers';
import {
    checkFileSizeLessThanPublicLimit,
    checkFileSizeLessThanUserLimit,
} from 'reactApp/modules/uploading/helpers/cloudFs/cloudFs.helpers';
import {
    FILE_WITHOUT_HASH_MAX_SIZE,
    getFullBody,
    getHexCode,
    isEdge,
    isFirefox,
    MAX_READABLE_SIZE,
} from 'reactApp/modules/uploading/helpers/fs/fs.helpers';
import { checkFileExistenceByRead, readFileContent } from 'reactApp/modules/uploading/helpers/fs/readFile';
import { getWeightedMovingAverage, isWasmSupported, sendGaUploaderNew } from 'reactApp/modules/uploading/helpers/uploading.helpers';
import {
    getPerfDiffInMs,
    getWaitTimeFromHeader,
    isCancelableXhrState,
    MB,
    sendMetricsToDwh,
} from 'reactApp/modules/uploading/serviceClasses/helpers';
import { UploaderData } from 'reactApp/modules/uploading/serviceClasses/UploaderData';
import { UploadingCancel } from 'reactApp/modules/uploading/serviceClasses/UploadingCancel';
import type { UploadingDescriptor } from 'reactApp/modules/uploading/serviceClasses/UploadingDescriptor';
import { EUploadReasonSource } from 'reactApp/modules/uploading/serviceClasses/UploadingReason';
import { uploadingService } from 'reactApp/modules/uploading/serviceClasses/UploadingService';
import { EUploadingState, EUploadingType } from 'reactApp/modules/uploading/uploading.types';
import { type IUpdateInputFile, EFileError, EFileStatus } from 'reactApp/modules/uploadList/uploadList.model';
import { updateUploadFilesAction } from 'reactApp/modules/uploadList/uploadList.module';
import { UrlBuilder } from 'reactApp/modules/urlBuilder/UrlBuilder';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { store } from 'reactApp/store';
import { sendKaktamLog, sendXray } from 'reactApp/utils/ga';
import { getTrimmedText } from 'reactApp/utils/textHelpers';
import { isDocumentsDomain } from 'server/helpers/isDocumentsDomain';

import {
    setProgressStatusThrottled,
    updateUploadFilesActionBatcher,
    uploaderUploadSpeedForLowSpeedThrottled,
    uploaderUploadSpeedThrottled,
} from './batchHelpers';

const urlBuilder = new UrlBuilder();

const UPLOAD_API_POLLING_TIME = 4 * 60 * 60 * 1000;
const UPLOAD_API_PING_INTERVAL = 5000;

export const MAX_RETRIES_COUNT = 3;

const HEADER_X_UPLOAD_ERROR = 'x-upload-error';

let TEMP_MAX_FILESIZE_FOR_WASM_LIB = MAX_SIZE_FOR_WASM_HASH_LIB;
if (TEMP_MAX_FILESIZE_FOR_WASM_LIB) {
    // @ts-ignore
    const devMemory = navigator?.deviceMemory || 0;
    if (devMemory < 4) {
        TEMP_MAX_FILESIZE_FOR_WASM_LIB = 1000 * MB;
    } else if (devMemory < 6) {
        TEMP_MAX_FILESIZE_FOR_WASM_LIB = 3000 * MB;
    } else if (devMemory < 8) {
        TEMP_MAX_FILESIZE_FOR_WASM_LIB = 5000 * MB;
    }
}

/**
 * HTTP-метод загузки файла (PUT или POST).
 * CLOUDWEB-7724: в Firefox 56 обнаружились проблемы с PUT,
 * поэтому добавлен специальный параметр фичи.
 */
const HTTP_METHOD = getFeatureParam('newUpload', isFirefox ? 'firefoxMethod' : 'method', 'POST');

/**
 * Время задержки между EVENT_PROGRESS (в миллисекундах).
 */
const PROGRESS_DELAY = getFeatureParam('newUpload', 'progressDelay', 300);

const getHasDescriptorHandle = (descriptor: UploadingDescriptor) => {
    if (!descriptor) {
        return '';
    }
    return descriptor.fileHandle ? '. hasHandle' : descriptor.entry ? ', hasEntry' : '';
};

export class Uploader {
    public descriptor: UploadingDescriptor;
    public _metrics: Record<string, number> = {};

    private _o2Auth =
        o2UploadFeature && isDocumentsDomain(window.location.hostname)
            ? new O2Auth({ clientId: o2UploadFeature.clientId, login: USER_EMAIL })
            : null;

    private _axios: UploadResumableCheckAPICall | null = null;
    private _timeOutStep: string | null = '';
    private _timeOutTimerId: any;
    private _isUserCanceled = false;

    // Это нужно для связи с ивентами от xhr
    private promise?: Promise<UploaderData>;

    // Резолвит промис, который возвращает метод upload.
    private _resolve?(value: UploaderData): void;

    // Реджектит промис, который возвращает метод upload.
    private _reject?(value: UploaderData): void;

    // Для вычисления пропускной способности аплоада нам нужно знать, сколько загрузок идет параллельно
    static currentUploadingCount = 0;

    constructor(descriptor: UploadingDescriptor) {
        this.descriptor = descriptor;
    }

    /**
     * Адрес сервера для загрузки файла, полученный от балансера.
     */
    private _url = '';

    private _onXhrProgressCallTime = 0;

    public uploadStartTime = -1;

    private _xhr;

    private _getData = () => {
        const xhr = this._xhr;
        const params: any = {
            url: this._url,
        };

        if (xhr) {
            params.status = xhr.status;
            params.responseText = xhr.responseText;
        }

        return new UploaderData(params);
    };

    /// XHR event processing
    _onXhrAbort = (_: Event | null, data: UploaderData) => {
        const source = EUploadReasonSource.SOURCE_WEB_CLIENT;

        let stack;
        let reason;
        if (data?.status >= 500) {
            stack = new Error('HttpError');
            reason = new HttpError(stack, source, data.status, data.responseText);

            if (this.descriptor) {
                store.dispatch(
                    updateUploadFilesAction({
                        descriptorId: this.descriptor.id,
                        cloudPath: this.descriptor.cloudPath,
                        status: EFileStatus.ERROR,
                        error: EFileError.UNKNOWN,
                        hideError: false,
                    })
                );
            }
        } else {
            stack = new Error('UploadingCancel');
            reason = new UploadingCancel(stack, source);
        }
        data.error = reason;
        this._fail(data);
    };

    _onXhrError = (_: Event | null, data: UploaderData) => {
        const status = data.status;
        const source = EUploadReasonSource.SOURCE_BACKEND;
        let stack;
        let reason;
        let error;
        let hideErrorOnUI;
        const retry =
            !status ||
            status === HttpErrorCodes.REQUEST_TIMEOUT ||
            ((status === HttpErrorCodes.BAD_GATEWAY ||
                status === HttpErrorCodes.GATEWAY_TIMEOUT ||
                status === HttpErrorCodes.INTERNAL_SERVER_ERROR) &&
                this.descriptor.retryCount < MAX_RETRIES_COUNT);

        if (status === HttpErrorCodes.ILLEGAL) {
            stack = new Error('IllegalContentFail');
            reason = new IllegalContentFail(stack, source);
        } else if (status === HttpErrorCodes.TOO_LARGE) {
            stack = new Error('FileTooLargeError');
            reason = new FileTooLargeError(stack, source);
        } else if (status === HttpErrorCodes.CONFLICT) {
            // Такой файл с таким хешем уже грузится в данный момент. По завершению надо текущий файл попробовать еще раз
            stack = new Error('FileConflict');
            reason = new FileConflict(stack, source);
            hideErrorOnUI = true;
        } else if (status && !retry) {
            stack = new Error('HttpError');
            reason = new HttpError(stack, source, status, data.responseText);
            error = EFileError.UNKNOWN;
            hideErrorOnUI = false;
        } else {
            stack = new Error(retry ? 'ConnectionFail' : 'HttpError');
            reason = retry ? new ConnectionFail(stack, source, status) : new HttpError(stack, source, status, '');
            error = retry
                ? !status || status === HttpErrorCodes.REQUEST_TIMEOUT
                    ? EFileError.CONNECTION_ERROR
                    : null
                : EFileError.UNKNOWN;
            hideErrorOnUI = !error;
        }

        data.error = reason;

        const descriptor = this.descriptor;

        if (
            (status === 0 || status === HttpErrorCodes.REQUEST_TIMEOUT) &&
            (descriptor.state === EUploadingState.STATE_CANCEL || this._isUserCanceled)
        ) {
            return;
        }

        this._fail(data);

        sendGaUploaderNew('file', error);

        if (descriptor) {
            store.dispatch(
                updateUploadFilesAction({
                    descriptorId: descriptor.id,
                    status: error === EFileError.CONNECTION_ERROR ? EFileStatus.PAUSED : EFileStatus.ERROR,
                    error,
                    hideError: hideErrorOnUI,
                })
            );
        }
    };

    _onXhrLoad = (_: Event | null, data: UploaderData) => {
        const status = data.status;

        if (status === HttpOkCodes.OK || status === HttpOkCodes.CREATED) {
            const responseText = data.responseText;
            let responseData;

            if (HTTP_METHOD === 'POST') {
                responseData = parseBackendResponseTextOfPost(responseText);
            } else {
                responseData = parseBackendResponseTextOfPut(responseText);
                responseData.size = this.descriptor.file?.size;
            }

            Object.assign(data, responseData);

            const error = responseData.error;

            if (error) {
                this._fail(data);
            } else {
                const kilobytes = data.size / 1024;
                const seconds = (Date.now() - this.uploadStartTime) / 1000;

                data.speed = parseFloat((kilobytes / seconds).toFixed(1));
                this._done(data);
            }
        } else {
            this._onXhrError(null, data);
        }
    };

    _onXhrProgress(event: ProgressEvent, data: UploaderData) {
        const now = Date.now();

        if (now - this._onXhrProgressCallTime > PROGRESS_DELAY) {
            this._onXhrProgressCallTime = now;

            const total = data.size || event.total;
            const loaded = event.loaded + (data.startBytes || 0);
            let progress;

            if (total && loaded) {
                const loadedPercent = Math.round((loaded * 100) / total);
                progress = Math.min(loadedPercent, 100);
            } else {
                progress = 0;
            }

            data.progress = progress;
            data.loaded = loaded;

            this.handleProgress(data);
        }
    }

    handleEvent = (event: Event | ProgressEvent) => {
        const promise = new Promise((resolve) => {
            const data = this._getData();

            switch (event.type) {
                case EUploadingType.abort:
                    this._onXhrAbort(event, data);

                    break;

                case EUploadingType.error:
                    this._onXhrError(event, data);

                    break;

                case EUploadingType.load:
                    this._onXhrLoad(event, data);

                    break;

                case EUploadingType.progress:
                    this._onXhrProgress(event as ProgressEvent, data);

                    break;
            }

            resolve(null);
        });

        promise.catch((error) => {
            const data = this._getData();

            data.error = error;
            this._fail(data);
        });
    };

    _listenXhr = (xhr: XMLHttpRequest) => {
        xhr.addEventListener('abort', this);
        xhr.addEventListener('error', this);
        xhr.addEventListener('load', this);
        xhr.upload.addEventListener('progress', this);
    };

    _stopListeningXhr = (xhr: XMLHttpRequest) => {
        if (typeof xhr.removeEventListener !== 'function') {
            return;
        }

        xhr.removeEventListener('abort', this);
        xhr.removeEventListener('error', this);
        xhr.removeEventListener('load', this);
        xhr.upload.removeEventListener('progress', this);
    };

    // eslint-disable-next-line unicorn/consistent-function-scoping
    uploaderTotalProgressThrottled = throttle((item: IUpdateInputFile | IUpdateInputFile[]) => {
        // Прогресс может прилететь уже после обработки завершения аплоада, так что игнорим.
        const { state } = this.descriptor;
        if (
            uploadingService.isUserCanceled ||
            state === EUploadingState.STATE_CANCEL ||
            state === EUploadingState.STATE_DONE ||
            state === EUploadingState.STATE_PAUSED ||
            this.descriptor.hasCanceledParent()
        ) {
            this.uploaderTotalProgressThrottled.cancel();
            setProgressStatusThrottled.cancel();
            return;
        }

        // Тут дважды троттлится, потому что Uploader работают несколько штук параллельно,
        // мы троттлим ото всех инстансов Uploader, чтобы не было много частых перендеров
        // а внутри Uploader троттлим, чтобы можно было отменить запушенные экшены, когда надо, только для этого инстанса
        setProgressStatusThrottled(this.descriptor.uploadingPacketConfig.currentPacketIdForUI);

        updateUploadFilesActionBatcher.push(item);
    }, 250);

    // Events for descriptor and UI
    handleProgress(data: UploaderData) {
        this.descriptor.progress = data.progress;

        if (this.descriptor.error instanceof ConnectionFail) {
            this.descriptor.error = null;
        }

        if (this.descriptor.state === EUploadingState.STATE_CANCEL) {
            this.uploaderTotalProgressThrottled.cancel();
            return;
        }

        const diffLoaded = data.loaded - this.descriptor.loaded;

        this.descriptor.loaded = data.loaded;

        const newUploadedBytes = this.descriptor.loaded - this.descriptor.previouslyUploadedBytes;

        let timeRemain;

        const putTime = getPerfDiffInMs(this.uploadStartTime) / 1000;
        const speed = this.descriptor.loaded > 1 && putTime > 0 ? newUploadedBytes / 1024 / 1024 / putTime : 0;
        const totalUploadSpeed = (Uploader.currentUploadingCount || 1) * speed;
        if (speed > 0) {
            this.descriptor.speed = getWeightedMovingAverage(this.descriptor.speed || speed, speed);
            timeRemain = Math.round((this.descriptor.size - this.descriptor.loaded) / 1024 / 1024 / this.descriptor.speed);
        }

        this.descriptor.uploadingPacketConfig.currentProgressBytes += diffLoaded;

        const speedPacket =
            ((this.descriptor.uploadingPacketConfig.currentProgressBytes / 1024 / 1024) * 1000) /
            (Date.now() - this.descriptor.uploadingPacketConfig.startTime);

        if (
            this.descriptor.uploadingPacketConfig.currentProgressBytes &&
            speedPacket > 0 &&
            diffLoaded > 10 * 1024 &&
            this.descriptor.size > 10 * 1024
        ) {
            uploadingService.uploadPacketSpeedMbytesSec = getWeightedMovingAverage(
                uploadingService.uploadPacketSpeedMbytesSec,
                speedPacket
            );

            uploaderUploadSpeedThrottled(uploadingService.uploadPacketSpeedMbytesSec);
            uploaderUploadSpeedForLowSpeedThrottled(totalUploadSpeed);
        }

        this.uploaderTotalProgressThrottled({
            descriptorId: this.descriptor.id,
            cloudPath: this.descriptor.cloudPath,
            progress: data.progress,
            loaded: data.loaded,
            status: EFileStatus.PROGRESS,
            timeRemain,
            error: '',
        });
    }

    tryHandleStatus400(error: any) {
        return error?.status === 400 && error.headers && error.headers[HEADER_X_UPLOAD_ERROR] === 'badchecksum';
    }

    tryHandleStatus0(error: any) {
        if (error?.status === 0 && 'onLine' in navigator && navigator.onLine) {
            // Если статус 0, то это либо нет сети, либо файл был изменен.
            // onLine ненадежное, но хоть что-то, считаем, что файл был изменен и проверим это
            return true;
        }
    }

    _resumableCheckStatus = async ({ descriptor, url, size }: { descriptor: UploadingDescriptor; url: string; size: number }) => {
        let receivedBytes = 0;
        let recheckUploadCompleteTimeMs = 0;
        let newUrl;

        try {
            this._axios = new UploadResumableCheckAPICall();

            const O2Headers = o2UploadFeature ? await this._o2Auth?.getHeaters() : {};
            const res = await this._axios.makeRequest(null, {
                url,
                headers: {
                    'Content-Range': `bytes */${size}`,
                    ...O2Headers,
                },
            });

            switch (res.status) {
                case HttpOkCodes.OK: // partialy uploaded
                    receivedBytes = Number.parseInt(res.headers['x-received'] || '0');
                    descriptor.previouslyUploadedBytes = receivedBytes;
                    newUrl = res.headers['x-location'] || url;
                    sendGaUploaderNew('waspartialy-upldd');

                    break;
                case HttpOkCodes.CREATED: {
                    // continue to add file
                    this._xhr = res;
                    const data = this._getData();
                    data.responseText = this.descriptor.hash;
                    descriptor.previouslyUploadedBytes = size;
                    this._onXhrLoad(null, data);
                    sendGaUploaderNew('wasprvs-upldd');

                    sendMetricsToDwh(this, size, size);

                    return { shouldReturnStatus: false };
                }
                case HttpOkCodes.ACCEPTED: {
                    // uploaded and is moving to storage, recheck it
                    this._xhr = res;
                    const data = this._getData();
                    data.responseText = this.descriptor.hash;
                    descriptor.previouslyUploadedBytes = size;
                    recheckUploadCompleteTimeMs = getWaitTimeFromHeader(res.headers['x-wait-for']);

                    break;
                }
            }
        } catch (error: any) {
            switch (error?.status) {
                case HttpErrorCodes.NOT_FOUND: // file not found on the sever - it is ok, continue uploading
                    break;
                case HttpErrorCodes.FORBIDDEN: // O2 Token expired
                    await this._o2Auth?.refreshToken();
                    break;
                case HttpErrorCodes.BAD_REQUEST: // upload error
                    if (this.tryHandleStatus400(error)) {
                        return { shouldReturnStatus: true };
                    }
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    logger.error('upload check status error: ', 400, error.headers ? error.headers[HEADER_X_UPLOAD_ERROR] : '');
                    if (error.headers && error.headers[HEADER_X_UPLOAD_ERROR]) {
                        sendGaUploaderNew(`upld_err_${error.headers[HEADER_X_UPLOAD_ERROR]}`);
                    }
                    this._xhr = error;
                    this._onXhrAbort(null, this._getData());
                    return { shouldReturnStatus: false };

                default: {
                    logger.error('upload check status error: ', error, error?.status);

                    descriptor.putFileError = true;
                    descriptor.putErrorStatus = error?.status;
                    descriptor.putErrorStage = 'check1';

                    this._xhr = error;

                    let handler = this._onXhrAbort;

                    if (
                        (error?.status === HttpErrorCodes.GATEWAY_TIMEOUT ||
                            error?.status === HttpErrorCodes.BAD_GATEWAY ||
                            error?.status === HttpErrorCodes.INTERNAL_SERVER_ERROR) &&
                        this.descriptor.retryCount < MAX_RETRIES_COUNT
                    ) {
                        handler = this._onXhrError;
                    } else if (!error?.status) {
                        handler = this._onXhrError;
                        sendGaUploaderNew('uploadchk_err_0');
                    }
                    handler(null, this._getData());
                    return { shouldReturnStatus: false };
                }
            }
        }

        return { recheckUploadCompleteTimeMs, receivedBytes, newUrl };
    };

    _resumablePutUpload = async ({
        descriptor,
        url,
        size,
        recheckUploadCompleteTimeMs,
        receivedBytes,
        body,
        reUploadCount,
    }: {
        descriptor: UploadingDescriptor;
        url: string;
        size: number;
        recheckUploadCompleteTimeMs: number;
        receivedBytes: number;
        body: File | FormData;
        reUploadCount: number;
    }) => {
        let warning504 = false;
        let invalidToken = false;

        do {
            if (recheckUploadCompleteTimeMs <= 0) {
                this.uploadStartTime = Date.now();

                descriptor.putFileError = false;
                delete descriptor.putErrorStatus;
                descriptor.putErrorStage = '';
                Uploader.currentUploadingCount++;

                try {
                    const O2Headers = o2UploadFeature ? await this._o2Auth?.getHeaters() : {};
                    const startIdx = receivedBytes ? receivedBytes : 0;
                    let res;

                    if (PUT_MAX_CHUNK) {
                        let isFullBody;
                        for (let idx = startIdx; idx < size; idx += PUT_MAX_CHUNK) {
                            const currEnd = Math.min(idx + PUT_MAX_CHUNK, size);
                            isFullBody = idx === 0 && currEnd >= size;
                            const currSlice = isFullBody ? body : (body as File).slice(idx, currEnd);
                            try {
                                this._axios = new UploadResumableCheckAPICall();
                                const resp = await this._axios.makeRequest(currSlice, {
                                    url,
                                    headers: {
                                        'Content-Range': `bytes ${idx}-${currEnd - 1}/${size}`,
                                        ...O2Headers,
                                    },
                                    onUploadProgress: (event?) => {
                                        this._xhr = resp;
                                        const data = this._getData?.();
                                        data.startBytes = idx;
                                        data.size = size;
                                        this._onXhrProgress?.(event, data);
                                    },
                                });
                                res = resp;
                            } catch (error: any) {
                                if (error?.status === 400 && !isFullBody) {
                                    // https://cloud.pages.gitlab.corp.mail.ru/api/uploader
                                    // Апи в таком случае даже при успешной загрузке чанка, который меньше конца файла, отдает 400,
                                    // так что конкретно этот кейс считаем ок и грузим следующий чанк. Если что, то упадет на нем.
                                    continue;
                                }
                                if (this.tryHandleStatus0(error)) {
                                    return { shouldReturnStatus: true };
                                }

                                throw error;
                            }
                        }
                    } else {
                        this._axios = new UploadResumableCheckAPICall();
                        try {
                            res = await this._axios.makeRequest(startIdx ? (body as File).slice(startIdx) : body, {
                                url,
                                headers: {
                                    'Content-Range': `bytes ${startIdx}-${size - 1}/${size}`,
                                    ...O2Headers,
                                },
                                onUploadProgress: (event?) => {
                                    this._xhr = res;
                                    const data = this._getData?.();
                                    data.startBytes = receivedBytes;
                                    data.size = size;
                                    this._onXhrProgress?.(event, data);
                                },
                            });
                        } catch (error: any) {
                            if (this.tryHandleStatus0(error)) {
                                return { shouldReturnStatus: true };
                            }

                            throw error;
                        }
                    }

                    sendGaUploaderNew(`uploadfinish_succ_${res.status}`);

                    if (res?.status === HttpOkCodes.ACCEPTED) {
                        recheckUploadCompleteTimeMs = getWaitTimeFromHeader(res.headers['x-wait-for']);
                    }

                    this._xhr = res;
                    invalidToken = false;

                    Uploader.currentUploadingCount--;
                } catch (error: any) {
                    Uploader.currentUploadingCount--;
                    descriptor.putFileError = true;
                    descriptor.putErrorStatus = error?.status;
                    descriptor.putErrorStage = `upload: _isUserCanceled:${this._isUserCanceled} state:${this.descriptor.state}`;

                    if (error.status === 403 && !invalidToken) {
                        invalidToken = true;
                        await this._o2Auth?.refreshToken();
                    } else {
                        invalidToken = false;
                        if (error.status === 400) {
                            sendGaUploaderNew('uploadfinish_err');
                        }

                        sendGaUploaderNew(`uploadfinish_err_${error.status}`);

                        if (error.status === 504 || this.descriptor.size === this.descriptor.loaded) {
                            recheckUploadCompleteTimeMs = getWaitTimeFromHeader();
                            sendGaUploaderNew('warning-504');
                            warning504 = true;
                        } else if (this.tryHandleStatus400(error)) {
                            return { shouldReturnStatus: true };
                        } else {
                            logger.error('upload error: ', error.status, error.headers ? error.headers[HEADER_X_UPLOAD_ERROR] : '');
                            if (
                                error.headers &&
                                error.headers[HEADER_X_UPLOAD_ERROR] &&
                                descriptor?.state !== EUploadingState.STATE_CANCEL
                            ) {
                                sendGaUploaderNew(`upld_err2_${error?.headers[HEADER_X_UPLOAD_ERROR]}`);
                                sendKaktamLog({
                                    text: `upload error:${error.toString()}`,
                                    // @ts-ignore
                                    memoryAvailableGb: navigator?.deviceMemory,
                                    // @ts-ignore
                                    heapSize: performance?.memory?.jsHeapSizeLimit,
                                    fileSize: size,
                                    hash: this.descriptor?.hash,
                                    status: error.status,
                                    id: this.descriptor?.id,
                                    pageId: X_PAGE_ID,
                                    currentUplCnt: Uploader.currentUploadingCount,
                                });
                            }

                            this._xhr = error;
                            const data = this._getData();
                            this._onXhrError(null, data);

                            return { shouldReturnStatus: false };
                        }
                    }
                }
            } else {
                break;
            }
        } while (invalidToken);

        this._metrics.putTime = this.uploadStartTime ? getPerfDiffInMs(this.uploadStartTime) : 0;

        if (reUploadCount) {
            sendXray(['upload-tech-ok-after-reread']);
        }

        return { warning504, recheckUploadCompleteTimeMs };
    };

    _resumablePollFinish = async ({
        descriptor,
        url,
        size,
        recheckUploadCompleteTimeMs,
        warning504,
    }: {
        descriptor: UploadingDescriptor;
        url: string;
        size: number;
        recheckUploadCompleteTimeMs: number;
        warning504: boolean;
    }) => {
        const pollStartTime = Date.now();
        if (recheckUploadCompleteTimeMs > 0) {
            const pollStartTime = performance.now();
            let radarSent = false;
            let invalidToken = false;
            let badRequestCounter = 0;
            for (let i = 0; i < UPLOAD_API_POLLING_TIME / UPLOAD_API_PING_INTERVAL; i++) {
                descriptor.putFileError = false;
                delete descriptor.putErrorStatus;
                descriptor.putErrorStage = '';

                try {
                    if (performance.now() - pollStartTime > 5 * 60 * 1000 && !radarSent) {
                        radarSent = true;
                        sendGaUploaderNew('check-warning-5m');
                    }

                    this._axios = new UploadResumableCheckAPICall();
                    const O2Headers = o2UploadFeature ? await this._o2Auth?.getHeaters() : {};

                    const res = await this._axios.makeRequest(null, {
                        url,
                        headers: {
                            'Content-Range': `bytes */${size}`,
                            ...O2Headers,
                        },
                    });

                    if (res.status === HttpOkCodes.CREATED) {
                        // continue to add file
                        if (warning504) {
                            // После 504 (таймаут не беке) проверка показала, что файл все-таки был успешно загружен
                            sendGaUploaderNew('warning-504-loaded');
                        }
                        this._xhr = res;
                        break;
                    } else if (res.status === HttpOkCodes.ACCEPTED) {
                        if (i >= UPLOAD_API_POLLING_TIME / UPLOAD_API_PING_INTERVAL) {
                            sendGaUploaderNew('check-timeout');
                            logger.error(`cannot get 201 status during ${UPLOAD_API_POLLING_TIME}`);
                            this._onXhrAbort(null, new UploaderData({}));
                            descriptor.putFileError = true;
                            descriptor.putErrorStatus = res.status;
                            descriptor.putErrorStage = 'no-check-in-timeout';
                            break;
                        }
                        await new Promise((resolve) => setTimeout(resolve, UPLOAD_API_PING_INTERVAL));
                    }
                } catch (err: any) {
                    if (err.status === 403 && !invalidToken) {
                        invalidToken = true;
                        await this._o2Auth?.refreshToken();
                    } else if (
                        err.status &&
                        err.status !== HttpErrorCodes.REQUEST_TIMEOUT &&
                        err.status !== HttpErrorCodes.BAD_GATEWAY &&
                        err.status !== HttpErrorCodes.GATEWAY_TIMEOUT &&
                        err.status !== HttpErrorCodes.INTERNAL_SERVER_ERROR &&
                        err.status !== HttpErrorCodes.BAD_REQUEST
                    ) {
                        logger.error('upload check status error: ', err.status);
                        this._onXhrAbort(null, new UploaderData({}));
                        descriptor.putFileError = true;
                        descriptor.putErrorStatus = err.status;
                        descriptor.putErrorStage = `err-final-check: i=${i}, status=${err.status}`;
                        return false;
                    } else if (err.status) {
                        badRequestCounter++;
                    }
                    if (badRequestCounter > MAX_RETRIES_COUNT) {
                        break;
                    }
                    await new Promise((resolve) => setTimeout(resolve, UPLOAD_API_PING_INTERVAL));
                }
            }
        }

        this._metrics.pollTime = getPerfDiffInMs(pollStartTime);
    };

    async _makeRequest(body: File | FormData, reUploadCount = 0) {
        const descriptor = this.descriptor;
        if (descriptor) {
            descriptor.startTime = performance.now();
            descriptor.putFileError = false;
            delete descriptor.putErrorStatus;
        }

        if (!isUploadResumeAvailable || HTTP_METHOD === 'POST') {
            const xhr = new XMLHttpRequest();

            this._xhr = xhr;

            xhr.open(HTTP_METHOD, this._url);
            xhr.withCredentials = true;
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            this._listenXhr(xhr);
            this.uploadStartTime = Date.now();

            xhr.send(body);

            sendGaUploaderNew('old-api');

            this.descriptor.uploadingPacketConfig.isOldApi = true;

            return false;
        }

        // http://cloud.pages.gitlab.corp.mail.ru/api/uploader

        this._axios = null;
        const size = 'size' in body ? body.size : 0;
        let url = this._url;

        // 1. check file status on the server
        const {
            shouldReturnStatus,
            receivedBytes = 0,
            recheckUploadCompleteTimeMs = 0,
            newUrl = url,
        } = await this._resumableCheckStatus({ descriptor, url, size });

        if (typeof shouldReturnStatus === 'boolean') {
            return shouldReturnStatus;
        }

        url = newUrl;

        // 2. upload file
        const {
            warning504 = false,
            shouldReturnStatus: shouldReturnStatusPut,
            recheckUploadCompleteTimeMs: recheckUploadCompleteTimeMsNew = recheckUploadCompleteTimeMs,
        } = await this._resumablePutUpload({
            descriptor,
            url,
            size,
            body,
            receivedBytes,
            recheckUploadCompleteTimeMs,
            reUploadCount,
        });

        if (typeof shouldReturnStatusPut === 'boolean') {
            return shouldReturnStatusPut;
        }

        // 3. Check finish and continue to file add
        if (
            await this._resumablePollFinish({
                descriptor,
                url,
                size,
                recheckUploadCompleteTimeMs: recheckUploadCompleteTimeMsNew,
                warning504,
            })
        ) {
            return true;
        }

        sendMetricsToDwh(this, size, receivedBytes);

        const data = this._getData();
        data.responseText = this.descriptor.hash;
        this._onXhrLoad(this._xhr, data);

        this._axios = null;

        return false;
    }

    _timeout = () => {
        sendGaUploaderNew('timeout', 'error', { message: this._timeOutStep || '' });
        sendKaktamLog({ message: this._timeOutStep || '' });
    };

    stopTimeoutTimer = () => {
        if (this._timeOutTimerId) {
            clearTimeout(this._timeOutTimerId);
            this._timeOutTimerId = null;
            this._timeOutStep = null;
        }
    };

    _upload = async () => {
        this._timeOutTimerId = setTimeout(this._timeout.bind(this), 30000);

        try {
            let file = this.descriptor.file;
            const config = this.descriptor.uploadingPacketConfig;

            if (!file) {
                return;
            }

            this.descriptor.fileReadError = false;
            this.descriptor.fileReadErrorStatus = '';

            // Проверяет, что файл до сих пор доступен в локальной ФС.
            // в edge ограниченое кол-во считываний entry и fileread
            if (isEdge && !file.type) {
                const source = EUploadReasonSource.SOURCE_WEB_CLIENT;
                const stack = new Error('UnsupportedFolderTransferFail');
                throw new UnsupportedFolderTransferFail(stack, source);
            } else if (!isEdge && file.size < MAX_READABLE_SIZE && !(this.descriptor.fileHandle || this.descriptor.entry)) {
                // Выполняем проверку только для небольших файлов,
                // чтобы не получить исключение "out of memory".
                // Если у нас есть хендлы fileHandle/entry, а не сами файлы, то сам файл запрашиваем только при самой загрузке, потому проверка не нужна

                await checkFileExistenceByRead(file);

                this._timeOutStep = 'readFile';
            }

            // Проверяет, удовлетворяет ли файл ограничениям Облака:
            // • Не больше 32GB.
            // • Не превышает лимит пользователя (2GB для бесплатного тарифа).
            // • Не превышает значение `upload_limit` для пабликов с кошарингом
            let size;
            const isPublic = this.descriptor.uploadingPacketConfig.storage === EStorageType.public;
            if (isPublic) {
                this._timeOutStep = 'checkFileSizeLessThanPublicLimit';
                size = checkFileSizeLessThanPublicLimit(file.size, config.publicUploadLimit);
            } else {
                // Проверяет, удовлетворяет ли файл ограничениям браузера:
                // • Не больше 4GB в IE.uploader._timeOutStep = 'checkFileSizeLessThanLimit';
                size = checkFileSizeLessThanUserLimit(file.size, config.userFileSizeLimit);
            }

            // Проверяет, достаточно ли места в Облаке.
            // На пабликах проверяем квоту на бекенде, ибо файлы грузятся в облако хозяина, а доступа к ней мы не имеем
            if (!isPublic) {
                this._timeOutStep = 'checkEnoughSpace';
                this.checkEnoughSpace(size);
            }

            // Для файлов размером меньше 21 байт
            // вместо hash в базу помещается байткод.
            if (size <= FILE_WITHOUT_HASH_MAX_SIZE) {
                const source = EUploadReasonSource.SOURCE_WEB_CLIENT;
                const stack = new Error('FileWithoutHashFail');
                throw new FileWithoutHashFail(stack, source);
            }

            // Получает от балансировщика адрес сервера для загрузки файла.
            let url = this._url;
            if (!url) {
                url = urlBuilder.upload();
                this._timeOutStep = 'getUploadUrl';
            }
            this._url = url;

            // Получает данные файла.
            this._timeOutStep = 'getFullBody';

            let body = getFullBody(file, HTTP_METHOD);

            this._metrics = {
                diskReadTime: 0,
                hashCalcTime: 0,
                startTime: Date.now(),
            };

            let reUploadCount = 0;
            const maxReUploads = 5;
            while (reUploadCount < maxReUploads) {
                // Вычисляем хеш файла на клиенте для нового апи аплоада
                if (isUploadResumeAvailable && size > FILE_WITHOUT_HASH_MAX_SIZE) {
                    if (reUploadCount > 0) {
                        await new Promise((resolve) => setTimeout(resolve, 500));

                        // Так как контент файла изменился, надо его перепрочитать заново с диска
                        await this.descriptor.getFile(true);

                        file = this.descriptor.file;

                        if (!file) {
                            return;
                        }

                        size = file.size;

                        body = getFullBody(file, HTTP_METHOD);

                        url = urlBuilder.upload();

                        this.descriptor.hash = '';
                    }
                    const hash = this.descriptor.hash || (await this.calculateHash(file, size));

                    if (this._isUserCanceled) {
                        // Хеш исчитается долго для больших файлов, потому эта проверка после него
                        return;
                    }

                    this._url = url.replace('[HASH]', hash ?? '');

                    this.descriptor.hash = hash;
                }

                if (this._isUserCanceled) {
                    return;
                }

                // Выполняет загрузку файла.
                this.stopTimeoutTimer();

                const shouldRetry = await this._makeRequest(body, reUploadCount);
                if (!shouldRetry) {
                    break;
                }
                reUploadCount++;
            }
        } catch (error: any) {
            this.stopTimeoutTimer();

            const errorText = error.toString() || '';

            if (errorText.toLowerCase().includes('memory')) {
                sendXray('upld-err-hash-mem');
                // eslint-disable-next-line max-lines
                sendKaktamLog({
                    text: `hash calc exception:${error}`,
                    error, // @ts-ignore
                    memoryAvailableGb: navigator?.deviceMemory,
                    // @ts-ignore
                    heapSize: performance?.memory?.jsHeapSizeLimit,
                    fileSize: this.descriptor.size,
                    id: this.descriptor.id,
                    pageId: X_PAGE_ID,
                });
            }

            if (errorText.includes('FileReader') && errorText.includes('ReferenceError')) {
                this.descriptor.fileReadError = true;
                this.descriptor.fileReadErrorStatus = errorText + getHasDescriptorHandle(this.descriptor);
                // Мы в режиме lockdown в iOS/Mac, скорее всего
                sendXray('upld-err-flrd-ld');
                // eslint-disable-next-line max-lines
                sendKaktamLog({
                    text: `uploader: no FileReader:${error}`,
                    error, // @ts-ignore
                    memoryAvailableGb: navigator?.deviceMemory,
                    // @ts-ignore
                    heapSize: performance?.memory?.jsHeapSizeLimit,
                    fileSize: this.descriptor.size,
                    id: this.descriptor.id,
                    hasHandle: !!this.descriptor.fileHandle,
                    hasEntry: !!this.descriptor.entry,
                    pageId: X_PAGE_ID,
                });

                const stack = new Error('FileReaderError');
                const reason = new FileReaderError(stack, EUploadReasonSource.SOURCE_WEB_CLIENT);

                return Promise.reject(reason);
            }

            // Для файлов размером меньше 21 байт,
            // вместо hash возвращаем байткод в шестнадцатеричном виде,
            // дополненный нулями до длины облачного hash (40 байт).

            let hexCode;
            try {
                if (error instanceof FileWithoutHashFail) {
                    const file = this.descriptor.file;

                    if (file?.size) {
                        hexCode = await getHexCode(file);
                    } else {
                        hexCode = EMPTY_FILE_HASH;
                    }
                } else {
                    throw error;
                }

                const data = this._getData();

                data.hash = hexCode;
                data.size = this.descriptor.file?.size ?? 0;

                this._done(data);
            } catch (error: any) {
                if (error.toString().includes('NotReadableError')) {
                    sendKaktamLog({
                        text: `NotReadableError`,
                        error,
                        // @ts-ignore
                        // eslint-disable-next-line compat/compat
                        memoryAvailableGb: navigator?.deviceMemory,
                        // @ts-ignore
                        // eslint-disable-next-line compat/compat
                        heapSize: performance?.memory?.jsHeapSizeLimit,
                        fileSize: this.descriptor.size,
                        localPath: this.descriptor.localPath,
                        localName: this.descriptor.localName,
                        id: this.descriptor.id,
                        pageId: X_PAGE_ID,
                        isSafari: browser.isSafari(),
                    });

                    const stack = new Error('FileReaderError');
                    const reason = new FileReaderError(stack, EUploadReasonSource.SOURCE_WEB_CLIENT);

                    this.descriptor.fileReadError = true;
                    this.descriptor.fileReadErrorStatus =
                        (this.descriptor.fileReadErrorStatus || error.toString()) + getHasDescriptorHandle(this.descriptor);
                    return Promise.reject(reason);
                }

                this.descriptor.fileReadError = true;
                this.descriptor.fileReadErrorStatus = error.toString() + getHasDescriptorHandle(this.descriptor);
                this.descriptor.knownError =
                    error instanceof UserFileSizeLimitFail || error instanceof OverQuotaFail || error instanceof PublicFileSizeLimit;

                const data = this._getData();
                data.error = error;
                this._fail(data);

                throw error;
            }
        }
    };

    async calculateHash(file: File, size: number): Promise<string> {
        // @ts-ignore
        this.handleProgress({ progress: 0, loaded: 1 });

        let hashLib = EHashLibType.js;

        // На файле больше 16ГБ примерно падает ошибка, по памяти - надо потом чинить
        if (isHashCalcLibWasm && isWasmSupported && (!TEMP_MAX_FILESIZE_FOR_WASM_LIB || size <= TEMP_MAX_FILESIZE_FOR_WASM_LIB)) {
            hashLib = EHashLibType.wasm;
        }
        if (isHashCalcLibCrypto && size <= FILE_READ_BLOCK_LENGTH) {
            hashLib = EHashLibType.cryptoApi;
        }

        this.descriptor.fileReadError = false;
        this.descriptor.hashCalcError = false;
        this.descriptor.fileReadErrorStatus = '';

        const processHash = async (hashCalculator: HashCalculator, file: File, startIdx: number, stopIdx: number) => {
            const startRead = Date.now();

            return readFileContent(file, startIdx, stopIdx)
                .catch((err) => {
                    sendGaUploaderNew(`readfile_error${err.name ? `_${err.name}` : ''}`);
                    const stack = new Error('FileReaderError');
                    const reason = new FileReaderError(stack, EUploadReasonSource.SOURCE_WEB_CLIENT);

                    this.descriptor.fileReadError = true;
                    this.descriptor.fileReadErrorStatus = err.name + getHasDescriptorHandle(this.descriptor);
                    return Promise.reject(reason);
                })
                .then((data) => {
                    this.stopTimeoutTimer();

                    this._metrics.diskReadTime += getPerfDiffInMs(startRead);

                    const startHash = Date.now();

                    if (!data) {
                        sendGaUploaderNew('error', 'readfile');
                        return;
                    }

                    return hashCalculator.addData(data, hashLib).then(() => {
                        this._metrics.hashCalcTime += getPerfDiffInMs(startHash);
                    });
                })
                .catch((err) => {
                    this.stopTimeoutTimer();
                    sendGaUploaderNew(`hashcalc_error${err.name ? `_${err.name}` : getTrimmedText(err.toString().toLowerCase(), 20)}`);
                    // @ts-ignore
                    sendKaktamLog({
                        text: `processHash hash calc exception, ${err?.toString()}`,
                        // @ts-ignore
                        // eslint-disable-next-line compat/compat
                        memoryAvailableGb: navigator?.deviceMemory,
                        // @ts-ignore
                        // eslint-disable-next-line compat/compat
                        heapSize: performance?.memory?.jsHeapSizeLimit,
                        fileSize: this.descriptor.size,
                        id: this.descriptor.id,
                        err,
                        pageId: X_PAGE_ID,
                    });
                    logger.error('hash calc exception', err);
                    const stack = new Error('HashCalcError');
                    const reason = new HashCalcError(stack, EUploadReasonSource.SOURCE_WEB_CLIENT);

                    this.descriptor.hashCalcError = true;
                    this.descriptor.hashCalcErrorStatus = err.name;

                    return Promise.reject(reason);
                });
        };

        const start = Date.now();

        const hashCalculator = new HashCalculator();
        hashCalculator.fileSize = size;
        this._timeOutStep = 'hashCalculator.init';
        await hashCalculator.init(hashLib);

        let hashInitTime = 0;
        const startIdx = 0;
        const lastIdx = file.size - 1;
        for (let idx = startIdx; idx <= lastIdx; idx += FILE_READ_BLOCK_LENGTH) {
            if (hashInitTime === 0) {
                hashInitTime += getPerfDiffInMs(start);
            }

            this._timeOutStep = 'readFileContent';

            if (this._isUserCanceled || this.descriptor.state === EUploadingState.STATE_CANCEL) {
                return '';
            }

            await processHash(hashCalculator, file, idx, Math.min(idx + FILE_READ_BLOCK_LENGTH - 1, lastIdx));
        }

        this._timeOutStep = 'hashCalculator.addData';
        await hashCalculator.addData(size.toString(), hashLib);

        this._timeOutStep = 'hashCalculator.getHash';
        const time = Date.now();
        const hash = await hashCalculator.getHash();
        const hashLast = getPerfDiffInMs(time);
        this._metrics.hashCalcTime += hashLast + hashInitTime;

        return hash;
    }

    upload = async (): Promise<UploaderData> => {
        let fileSize = this.descriptor.file?.size || 0;

        this.promise = new Promise<UploaderData>((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        });

        await this._upload();

        let data: any = await this.promise;
        fileSize = this.descriptor.file?.size || 0;

        // Проверяет размер и hash файла после загрузки.
        const hasWrongSize = fileSize !== data.size;
        const hasWrongHash = fileSize && data?.hash === EMPTY_FILE_HASH;

        if (hasWrongSize || hasWrongHash) {
            // log initial size too
            data = {
                ...data,
                initialSize: fileSize,
            };

            sendKaktamLog({
                text: `upload wrong result`,
                initialSize: fileSize,
                size: data.size,
                hash: this.descriptor?.hash,
                id: this.descriptor?.id,
                pageId: X_PAGE_ID,
            });

            const message = JSON.stringify(data);
            const source = EUploadReasonSource.SOURCE_BACKEND;
            const stack = new Error('WrongResultError');
            throw new WrongResultError(stack, source, message);
        }

        return data;
    };

    cancel() {
        const data = this._getData();
        const xhr = this._xhr;

        this._isUserCanceled = true;

        this.uploaderTotalProgressThrottled?.cancel();

        if (xhr) {
            const readyState = xhr.readyState;
            const isCancelable = isCancelableXhrState(readyState);

            if (isCancelable) {
                xhr.abort();
            } else if (readyState !== XMLHttpRequest.DONE) {
                this._onXhrAbort(null, data);
            }
        } else {
            this._axios?.cancel();
            this._onXhrAbort(null, data);
        }
    }

    _done(data: UploaderData) {
        this._resolve?.(data);
        this._destroy();
    }

    _fail(data) {
        if (this._reject) {
            this._reject(data);
            this._destroy();
        }
    }

    _destroy() {
        const xhr = this._xhr;

        if (xhr) {
            this._stopListeningXhr(xhr);

            if (isCancelableXhrState(xhr.readyState)) {
                xhr.abort();
            }

            this._xhr = null;
        }

        this._resolve = undefined;
        this._reject = undefined;
    }

    checkEnoughSpace(size) {
        const free = this.getFreeSpace();

        if (free < size) {
            const source = EUploadReasonSource.SOURCE_WEB_CLIENT;
            const stack = new Error('OverQuotaFail');
            const err = new OverQuotaFail(stack, source);
            err.noOwnSpace = true;
            throw err;
        }

        return free;
    }

    getFreeSpace() {
        const rootDomainOrMounterFolder = this.descriptor.uploadingPacketConfig.workingDirectory.split('/').slice(0, 2).join('/');
        if (IS_ONPREMISE) {
            if (this.descriptor.uploadingPacketConfig.isDomainFolder || this.descriptor.uploadingPacketConfig.hasParentDomainFolder) {
                return getDomainFolderQuotaFree(store.getState(), rootDomainOrMounterFolder);
            }

            if (this.descriptor.uploadingPacketConfig.isMountedFolder) {
                return getMountedFolderQuotaFree(store.getState(), rootDomainOrMounterFolder);
            }
        }

        if (IS_B2B_BIZ_USER) {
            if (this.descriptor.uploadingPacketConfig.isDomainFolder || this.descriptor.uploadingPacketConfig.hasParentDomainFolder) {
                return getDomainFolderQuotaFree(store.getState(), rootDomainOrMounterFolder);
            }

            if (this.descriptor.uploadingPacketConfig.isMountedFolder) {
                return Infinity;
            }
        }

        const space = UserSelectors.getCloudSpace(store.getState());
        return space.free.original;
    }
}
