import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_BIZ_USER, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';

/**
 * alpha-team
 * CLOUDWEB-17777: Качество. Touch. Паблик. Эксперимент с шапкой
 */
export const abPublicMobileHeaderValue: 'a' | 'b' | 'c' =
    EnvironmentSelectors.isMobile() && !IS_WEBVIEW && !IS_BIZ_USER && getFeature('ab-public-mobile-header');
