import { Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { overquotaAfterClear } from 'reactApp/appHelpers/featuresHelpers/features/overquotaAfterClear';
import { overquotaNewCleared } from 'reactApp/appHelpers/featuresHelpers/features/overquotaNewCleared';
import { overquotaPayRestore } from 'reactApp/appHelpers/featuresHelpers/features/overquotaPayRestore';
import { subscriptionsForDeletedUsers } from 'reactApp/appHelpers/featuresHelpers/features/subscriptionsForDeletedUsers';
import { renderNewComfirmationDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog.helpers';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { PaymentUTM } from 'reactApp/constants/paymentUTM';
import { loadProductsAndOpenFastCheckout } from 'reactApp/modules/payment/payment.module';
import { requestDataRestore, setClearedUserProcessed, setProfileUblockUser } from 'reactApp/modules/profile/profile.module';
import { historyPush } from 'reactApp/modules/router/router.module';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import type { Product } from 'reactApp/types/Billing';
import { Button } from 'reactApp/ui/Button/Button';
import { OverquotaBannerCtrl, sendDwhOverquota, sendRecoveryFlowDwh } from 'reactApp/ui/OverquotaPopup/new/OverquotaModal.helpers';
import type { PlaceType } from 'reactApp/ui/OverquotaPopup/new/OverquotaModal.types';
import styles from 'reactApp/ui/OverquotaPopup/newSplash/OverquotaSplash.css';
import type { ModeType } from 'reactApp/ui/OverquotaPopup/newSplash/OverquotaSplash.types';
import { sendXray } from 'reactApp/utils/ga';

const QUOTA_PAGE_BASE_URL = '/promo/quota';

export const Footer: React.FC<{
    mode?: ModeType;
    onClose(): void;
    storage: EStorageType;
    product?: Product;
    productForQuota?: Product;
    place?: PlaceType;
    isMobile: boolean;
}> = memo(({ mode, storage, onClose, product, productForQuota, place, isMobile }) => {
    const dispatch = useDispatch();
    const email = useSelector(UserSelectors.getEmail);

    const onGoToCloudHandler = useCallback(() => {
        if (subscriptionsForDeletedUsers) {
            sendXray(['overquota', 'gotocloud', 'click', 'force']);
        }
        sendDwhOverquota({ action: 'click', storage, place, control: OverquotaBannerCtrl.go_cloud });
        dispatch(setClearedUserProcessed({ forceLA: subscriptionsForDeletedUsers }));
        onClose?.();
    }, [onClose, place]);

    const onUnblockHandler = useCallback(() => {
        sendDwhOverquota({ action: 'click', storage, place, control: OverquotaBannerCtrl.unblocked });
        dispatch(setProfileUblockUser());
    }, [place]);

    const onWhyWhyWhy = useCallback(() => {
        sendDwhOverquota({ action: 'click', storage, place, control: OverquotaBannerCtrl.whyWhyWhy });
    }, [place]);

    const onWhyNew = useCallback(() => {
        onWhyWhyWhy();
        open(`https://help.mail.ru/cloud_web/faq/?${PaymentUTM.overquotaSplashNewCleared}#overquota`, '_blank');
    }, [onWhyWhyWhy]);

    const onRestoreHandle = useCallback(() => {
        sendRecoveryFlowDwh({ action: 'click_buy', storage });

        if (overquotaPayRestore) {
            if (!product) {
                dispatch(historyPush({ id: QUOTA_PAGE_BASE_URL }));
                return;
            }

            dispatch(
                loadProductsAndOpenFastCheckout({
                    productId: product.id,
                    paySource: 'quota_splash_restore',
                    hideOtherTariffs: true,
                    onSuccess: () => {
                        dispatch(requestDataRestore());
                    },
                })
            );
        } else {
            dispatch(requestDataRestore());
        }
    }, [product]);

    const onAddSpaceHandler = useCallback(() => {
        if (mode === 'full_blocked') {
            sendDwhOverquota({
                action: 'click',
                storage,
                place: 'onboarding',
                control: OverquotaBannerCtrl.add_space,
                source: 'file',
                name: 'overquota_full_blocked',
            });
        } else {
            sendDwhOverquota({ action: 'click', storage, place, control: OverquotaBannerCtrl.add_space });
        }

        if (!productForQuota) {
            dispatch(historyPush({ id: QUOTA_PAGE_BASE_URL }));
            return;
        }

        dispatch(
            loadProductsAndOpenFastCheckout({
                productId: productForQuota.id,
                paySource: 'quota_splash_restore',
                hideOtherTariffs: true,
                onSuccess: () => {
                    onClose?.();
                },
            })
        );
    }, [onClose, place, productForQuota, mode]);

    const onPurgeHandler = useCallback(() => {
        sendRecoveryFlowDwh({ action: 'click_clear', storage });

        renderNewComfirmationDialog({
            renderHeader: () => 'Очистить Облако?',
            renderContent: () => <Text className={styles.dialogText}>После этого восстановить файлы не получится</Text>,
            buttons: [EButtonType.action, EButtonType.cancel],
            actionButtonText: 'Очистить навсегда',
            closeOnDimmerClick: true,
            isNegative: true,
            onAction: () => {
                sendRecoveryFlowDwh({ control: 'overquota_recovery_popup', action: 'click_clear', storage });
                dispatch(setClearedUserProcessed({ forceLA: subscriptionsForDeletedUsers }));
            },
            dataQAId: 'confirm-cloud-purge',
        });
    }, []);

    const onGoToClearHandler = useCallback(() => {
        if (mode === 'full_blocked') {
            sendDwhOverquota({
                action: 'click',
                storage,
                place: 'onboarding',
                control: OverquotaBannerCtrl.clear,
                source: 'file',
                name: 'overquota_full_blocked',
            });
        }

        dispatch(historyPush({ id: QUOTA_PAGE_BASE_URL, hash: 'cleaner' }));
    }, [onClose]);

    const handleChangeAccountClick = useCallback(() => {
        const path = storage === EStorageType.home ? '/home' : window.location.pathname;

        const successPage = `${window.location.origin}${path}`;

        window.__PH.authForm.show({
            formType: 'relogin',
            successPage,
            email,
        });
    }, [email, storage]);

    if (mode === 'cleared' && overquotaNewCleared) {
        return (
            <div className={classNames(styles.buttons, styles.buttonsNewClear)}>
                <Button className={styles.button} data-name="freeze-renewal" theme="vk" onClick={onGoToCloudHandler} primary>
                    Перейти в Облако
                </Button>
                <Button className={styles.button} onClick={onWhyNew} theme="octavius" data-name="secondary">
                    Узнать -- почему?
                </Button>
                {Boolean(isMobile) && (
                    <Button
                        className={classNames(styles.button, styles.button_tetriary)}
                        onClick={handleChangeAccountClick}
                        theme="octavius"
                        data-name="secondary"
                    >
                        Войти в другой аккаунт
                    </Button>
                )}
            </div>
        );
    }

    if (mode === 'cleared') {
        return (
            <div className={classNames(styles.buttons, styles.buttons_column)}>
                <Button className={styles.button} data-name="freeze-renewal" theme="vk" onClick={onGoToCloudHandler} primary>
                    Перейти в Облако
                </Button>
                <a
                    className={styles.link}
                    href={`https://help.mail.ru/cloud_web/faq/?${PaymentUTM.overquotaSplashNewCleared}#overquota`}
                    onClick={onWhyWhyWhy}
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    Почему это произошло?
                </a>
            </div>
        );
    }

    if (mode === 'after_clear' && overquotaAfterClear) {
        return (
            <div className={styles.buttons}>
                <Button className={styles.button} data-name="freeze-renewal-after-clear" theme="vk" onClick={onRestoreHandle} primary>
                    {overquotaPayRestore ? 'Вернуть доступ с подпиской' : 'Разблокировать'}
                </Button>
                <Button className={styles.button} onClick={onPurgeHandler} theme="white" data-name="secondary">
                    Очистить Облако
                </Button>
            </div>
        );
    }

    if (mode === 'full_blocked') {
        return (
            <div className={styles.buttons}>
                <Button className={styles.button} data-name="freeze-renewal" theme="octavius" onClick={onAddSpaceHandler} primary>
                    Вернуть доступ с подпиской
                </Button>
                <Button className={styles.button} onClick={onGoToClearHandler} theme="octavius" data-name="secondary">
                    Удалить лишнее
                </Button>
                {Boolean(isMobile) && (
                    <Button
                        className={classNames(styles.button, styles.button_tetriary)}
                        onClick={handleChangeAccountClick}
                        theme="octavius"
                        data-name="secondary"
                    >
                        Войти в другой аккаунт
                    </Button>
                )}
            </div>
        );
    }

    return (
        <div className={styles.buttons}>
            <Button className={styles.button} data-name="freeze-renewal" theme="octavius" onClick={onAddSpaceHandler} primary>
                Увеличить место
            </Button>
            <Button className={styles.button} onClick={onUnblockHandler} theme="octavius" data-name="secondary">
                Снять ограничение временно
            </Button>
            {Boolean(isMobile) && (
                <Button
                    className={classNames(styles.button, styles.button_tetriary)}
                    onClick={handleChangeAccountClick}
                    theme="octavius"
                    data-name="secondary"
                >
                    Войти в другой аккаунт
                </Button>
            )}
        </div>
    );
});

Footer.displayName = 'Footer';
