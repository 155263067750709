// FMAIL-25764 - Тариф для отключения рекламы в модальном окне в Почте - Web

import { getFeature } from 'Cloud/Application/FeaturesEs6';
import type { Key } from 'react';

/**
 * id продукта в формате W{space}{units}
 *
 * Считаем что свитч На месяЦ/На год не влияет на содержимое карточки
 *
 * @example
 * Например для W32G1m_some_tariff это будет W32GB
 */
type TariffPattern = string;

/**
 * Название готовой, шаблонной фичи
 *
 * FeatureName = keyof templateFeatures
 * @link Брать из мапки reactApp/constants/products/templateFeatures.tsx
 */
type FeatureName = string;

/**
 * Название готового перечисления фичей
 *
 * FeatureListName = keyof features
 * @link Брать из мапки reactApp/constants/products/features.tsx
 */
type FeatureListName = string;

/**
 * Название готовой иконки
 *
 * FeatureIconName = keyof templateFeatureIcons
 * @link Брать из мапки reactApp/constants/products/templateFeatureIcons.tsx
 */
type FeatureIconName = string;

/**
 * Конфиг фичи
 */
type FeatureConfig = {
    key?: Key; // Ключ для списков в реакт
    iconName?: FeatureIconName; // Название иконки
    text?: string; // Текст
    templateTextName?: FeatureName; // Взять текст из шаблонной фичи по FeatureName
    templateActionName?: string;
};

export type CardFeatures = FeatureListName | (FeatureName | FeatureConfig)[];

type CardSettings = {
    title?: string; // заголовок в карточке
    withMascot?: boolean; // Байт в карточке
    withSpace?: boolean; // Блок с квотой (+32Гб)
    withInfoBlock?: boolean; // Блок информер (Ваш объем составит или кнопка)

    /**
     * @example
     * // Нужно переиспользовать фичи как у singleQuotaPro
     *
     * "features": "singleQuotaPro"
     *
     * @example
     * // Нужно:
     * // Первую фичу переиспользовать полностью "Отмена отправки писем"
     * // Вторую фичу переиспользовать текст "Отмена отправки писем" и сделать с крестиком (типо недоступна)
     * // Третью фичу создать новую с текстом "Какая-то новая фича" и с крестиком
     * "features": [
     *     "letterCancel",
     *     {
     *         "templateTextName": "letterCancel",
     *         "iconName": "vkui16Cancel",
     *     },
     *     {
     *         "text": "Какая-то новая фича",
     *         "iconName": "vkui16Cancel",
     *     }
     * ]
     */
    features?: CardFeatures; // Список фичей
};

// tempexp_17127-start
/** CLOUDWEB-17127 заголовок модального окна с тарифами вместо фаст чекаута при отключении рекламы в Почте*/
export const tariffBuyWhenDisableAdsModalTitle: Record<string, string> = getFeature('tariff-buy-disable-ads-title') || {};
/** CLOUDWEB-17127 cписок id тарифов */
export interface DisableAdsTariffIds {
    year: string[];
    month: string[];
    recommendedPattern?: string;
    cardsSettings?: Record<TariffPattern, CardSettings>;
}
export const tariffBuyWhenDisableAdsModalTariffIds: Record<string, DisableAdsTariffIds> =
    getFeature('tariff-buy-disable-ads-tariff-ids') || {};
// tempexp_17127-end
