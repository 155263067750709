import { Icon20InfoCircleOutline } from '@vkontakte/icons';
import { once } from 'ramda';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IS_B2B_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { overquotaAfterClear } from 'reactApp/appHelpers/featuresHelpers/features/overquotaAfterClear';
import { getUserOverquotaCurrentState } from 'reactApp/modules/profile/profile.selectors';
import { sendRecoveryFlowDwh } from 'reactApp/ui/OverquotaPopup/new/OverquotaModal.helpers';

import styles from './OverquotaRestoreRequestedInfo.css';

const sendShowRadarOnce = once(() => sendRecoveryFlowDwh({ control: 'overquota_recovery_informer' }));

export const OverquotaRestoreRequestedInfo = () => {
    const state: ReturnType<typeof getUserOverquotaCurrentState> = useSelector(getUserOverquotaCurrentState);

    const show = overquotaAfterClear && !IS_B2B_BIZ_USER && state === 'restore_requested';

    useEffect(() => {
        if (!show) {
            return;
        }

        sendShowRadarOnce();
    }, [show]);

    if (!show || !overquotaAfterClear) {
        return null;
    }

    return (
        <>
            <div className={styles.info}>
                <Icon20InfoCircleOutline className={styles.infoIcon} />
                Ваши файлы будут доступны через несколько дней
            </div>
        </>
    );
};
