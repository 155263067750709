import React from 'react';
import { useSelector } from 'react-redux';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { isOwnPublic } from 'reactApp/modules/public/public.selectors';
import { UploadBlock } from 'reactApp/ui/UploadBlock/UploadBlock';

export const UploadBlockContainer = () => {
    const isOwn = useSelector(isOwnPublic);
    const isPhone = useSelector(EnvironmentSelectors.isPhone);

    return <UploadBlock showDropArea={isOwn && !isPhone} allowDnd={true} gaCategory="public" />;
};
