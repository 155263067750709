import { type PayloadAction, createAction, createReducer } from '@reduxjs/toolkit';
import { groupBy } from 'ramda';
import { publishWeblink, unPublishWeblink } from 'reactApp/modules/modifying/modifying.actions';
import { getWeightedMovingAverage } from 'reactApp/modules/uploading/helpers/uploading.helpers';
import { DEFAULT_FILE_UPLOAD_SPEED } from 'reactApp/modules/uploading/uploading.constants';
import { isProgressComplete } from 'reactApp/modules/uploadList/uploadList.getters';
import { applyPublishInfo, setGroupedByFolderInputFiles } from 'reactApp/modules/uploadList/uploadList.module.helper';

import {
    type IChangeItem,
    type IInputFile,
    type INormalizedInputFile,
    type ISelectedError,
    type ITotalProgress,
    type IUpdateInputEmptyFolder,
    type IUpdateInputFile,
    type IUploadListState,
    EInfoTypes,
    EProgressStatus,
} from './uploadList.model';

const initialListState: IUploadListState = {
    showUploader: false,
    hasInput: false,
    groupedFiles: {},
    groupedFilesById: {},
    totalCount: 0,
    totalSize: 0,
    speedMbSec: DEFAULT_FILE_UPLOAD_SPEED,
    speedForLowMbSec: 0,
    smartUploadedBytes: 0,
    hasConnectionError: false,
    hideInformers: [],
    progress: {
        percentLoaded: 0,
        uploadPacket: 0,
        status: EProgressStatus.NONE,
    },
    errors: {
        selectedFileId: '',
        highlightSelectedFile: false,
        userFileSizeLimit: 0,
    },
    hasNotAllowedExtensions: false,
};

export const setShowUploaderAction = createAction<boolean>('uploaderList/setShowUploaderAction');
export const setInputFilesAction = createAction<IInputFile[]>('uploaderList/setInputFilesAction');
export const setHasInputAction = createAction<boolean>('uploaderList/setHasInputAction');
export const updateUploadFilesAction = createAction<IUpdateInputFile | IUpdateInputEmptyFolder | IUpdateInputFile[]>(
    'uploaderList/updateUploadFilesAction'
);
export const deferredUpdateUploadFileAction = createAction<IUpdateInputFile | IUpdateInputFile[]>(
    'uploaderList/deferredUpdateUploadFileAction'
);
export const cancelAllUploadFileAction = createAction('uploaderList/cancelAllUploadFileAction');
export const removeUploadFilesAction = createAction<IChangeItem[]>('uploaderList/removeUploadFilesAction');
export const setProgressStatusAction = createAction<{
    status: EProgressStatus;
    packetId?: number;
}>('uploaderList/setProgressStatusAction');
export const setClearStateAction = createAction('uploaderList/setClearStateAction');
export const setSelectedErrorAction = createAction<ISelectedError>('uploaderList/setSelectedErrorAction');
export const setHighlightSelectedErrorAction = createAction<boolean>('uploaderList/setHighlightSelectedErrorAction');
export const setHighlightProblemFilesAction = createAction('uploaderList/setHighlightProblemFilesAction');
export const setUserFileSizeLimit = createAction<number>('uploaderList/setUserFileSizeLimit');
export const setPercentLoaded = createAction<number>('uploaderList/setPercentLoaded');
export const sortFilesByError = createAction('uploaderList/sortFilesByError');
export const addTotalCount = createAction<{ totalCount: number; totalSize: number }>('uploaderList/addTotalCount');
export const updateTotalProgress = createAction<ITotalProgress>('uploaderList/updateTotalProgress');
export const updateSpeedForLowSpeed = createAction<number>('uploaderList/updateSpeedForLowSpeed');
export const updateSmartUploadedBytes = createAction<number>('uploaderList/updateSmartUploadedBytes');
export const setHasConnectionError = createAction<boolean>('uploaderList/setHasConnectionError');
export const hideInformer = createAction<EInfoTypes>('uploaderList/hideInformer');
export const setHasNotAllowedExtensions = createAction<boolean>('uploaderList/setHasNotAllowedExtensions');

export const uploadDescriptor = createAction<{ name: string }>('uploaderList/uploadDescriptor');

export const uploadListReducer = createReducer(initialListState, {
    [setShowUploaderAction.type]: (state, action: PayloadAction<boolean>) => {
        const { payload } = action;
        state.showUploader = payload;
        if (!payload) {
            state.hasConnectionError = false;
        }
    },
    [setInputFilesAction.type]: (state, action: PayloadAction<IInputFile[]>) => {
        const { payload } = action;

        state.hasInput = true;

        setGroupedByFolderInputFiles(state, payload, true);
    },
    [updateUploadFilesAction.type]: (state, action: PayloadAction<IUpdateInputFile | IUpdateInputFile[]>) => {
        const { payload } = action;
        setGroupedByFolderInputFiles(state, Array.isArray(payload) ? payload : [payload]);
    },
    [cancelAllUploadFileAction.type]: (state) => {
        state.progress.percentLoaded = 100;
        state.hideInformers = [...state.hideInformers, EInfoTypes.complete];
    },
    [setProgressStatusAction.type]: (state, action: ReturnType<typeof setProgressStatusAction>) => {
        const { payload } = action;

        if (
            typeof payload.packetId === 'number' &&
            payload.packetId < state.progress.uploadPacket &&
            state.progress.status === EProgressStatus.COMPLETE
        ) {
            // Прилетел затроттленный уже устаревший экшен
            return;
        }

        state.progress.status = payload.status;

        if ((payload.status === EProgressStatus.STARTING || payload.status === EProgressStatus.PROGRESS) && !state.progress.startTime) {
            state.progress.startTime = Date.now();
        }
        if (isProgressComplete(state.progress.status)) {
            state.progress.uploadPacket++;
            if (state.progress.startTime) {
                state.progress.uploadTime = Date.now() - state.progress.startTime;
            }
            state.progress.startTime = null;
            state.speedForLowMbSec = 0;
        }
    },
    [addTotalCount.type]: (state, action: ReturnType<typeof addTotalCount>) => {
        const { totalCount, totalSize } = action.payload;
        if (isProgressComplete(state.progress.status)) {
            state.totalCount = 0;
            state.totalSize = 0;

            state.progress.percentLoaded = 0;
            state.smartUploadedBytes = initialListState.smartUploadedBytes;
        }
        state.totalCount += totalCount;
        state.totalSize += totalSize;
    },
    [setClearStateAction.type]: (state) => {
        state.showUploader = initialListState.showUploader;
        state.hasInput = initialListState.hasInput;
        state.groupedFiles = initialListState.groupedFiles;
        state.groupedFilesById = initialListState.groupedFilesById;
        state.progress = initialListState.progress;
        state.errors = initialListState.errors;
        state.totalCount = initialListState.totalCount;
        state.totalSize = initialListState.totalSize;
        state.hideInformers = initialListState.hideInformers;
        state.hasConnectionError = initialListState.hasConnectionError;
        state.smartUploadedBytes = initialListState.smartUploadedBytes;
        state.hasNotAllowedExtensions = initialListState.hasNotAllowedExtensions;
        state.speedForLowMbSec = 0;
    },
    [setSelectedErrorAction.type]: (state, action: PayloadAction<ISelectedError>) => {
        const { payload } = action;
        state.errors.selectedFileId = payload.fileId;
        state.errors.userClick = payload.userClick;
    },
    [setHighlightSelectedErrorAction.type]: (state, action: PayloadAction<boolean>) => {
        const { payload } = action;
        state.errors.highlightSelectedFile = payload;
    },
    [setUserFileSizeLimit.type]: (state, action: PayloadAction<number>) => {
        const { payload } = action;
        state.errors.userFileSizeLimit = payload;
    },
    [setPercentLoaded.type]: (state, action: PayloadAction<number>) => {
        const { payload } = action;

        if (Number.isFinite(payload)) {
            // 99 потому что может быть раннее округление до 100, когда в конце загрузки файлы сильно меньшего размера чем в начале
            const percentLoaded = state.progress.status === EProgressStatus.COMPLETE ? 100 : Math.min(payload, 99);
            state.progress.percentLoaded = percentLoaded;
        }
    },
    [sortFilesByError.type]: (state) => {
        const groupedFiles = state.groupedFiles || {};

        const grouppedByError = groupBy<any>((item) => (item[1].error ? '1' : '0'), Object.entries(groupedFiles));

        // нормализуем массив
        const groupedFilesNormalized = {
            ...grouppedByError[1]?.reduce<INormalizedInputFile>((acc, [key, file]) => {
                acc[key] = file;
                return acc;
            }, {}),
            ...grouppedByError[0]?.reduce<INormalizedInputFile>((acc, [key, file]) => {
                acc[key] = file;
                return acc;
            }, {}),
        };

        state.groupedFiles = groupedFilesNormalized;
        state.groupedFilesById = Object.values(groupedFilesNormalized).reduce((acc, current) => {
            acc[current.descriptorId] = current;
            return acc;
        }, {});
    },
    [updateTotalProgress.type]: (state, action: ReturnType<typeof updateTotalProgress>) => {
        state.speedMbSec = Math.round(100 * action.payload.speedMbSec) / 100;
    },
    [updateSpeedForLowSpeed.type]: (state, action: ReturnType<typeof updateSpeedForLowSpeed>) => {
        if (!action.payload) {
            return;
        }
        state.speedForLowMbSec = getWeightedMovingAverage(state.speedForLowMbSec || action.payload, action.payload);
    },
    [updateSmartUploadedBytes.type]: (state, action: ReturnType<typeof updateSmartUploadedBytes>) => {
        state.smartUploadedBytes += action.payload;
    },
    [setHasConnectionError.type]: (state, action: ReturnType<typeof setHasConnectionError>) => {
        state.hasConnectionError = action.payload;
    },
    [hideInformer.type]: (state, action: ReturnType<typeof hideInformer>) => {
        state.hideInformers = [...state.hideInformers, action.payload];
    },
    [setHasNotAllowedExtensions.type]: (state) => {
        state.hasNotAllowedExtensions = true;
    },
    [publishWeblink.type]: (state, action: ReturnType<typeof publishWeblink>): void => {
        const { id } = action.payload;

        applyPublishInfo(state, [id], true);
    },
    [unPublishWeblink.type]: (state, action: ReturnType<typeof unPublishWeblink>): void => {
        const { ids } = action.payload;

        applyPublishInfo(state, ids);
    },
});
