import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { IS_B2B_BIZ_USER, IS_BIZ_USER, IS_FREE_BIZ_SAAS_USER } from 'reactApp/appHelpers/configHelpers';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { getFeatureABCreateFolderNewFlow } from 'reactApp/modules/features/features.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { snackbarController } from 'reactApp/modules/snackbar/snackbar.controller';
import { type SnackbarItem, SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { copy as copyApiCall } from 'reactApp/utils/copyToClipboard';
import { noop } from 'reactApp/utils/helpers';
import { truncateTextMiddle } from 'reactApp/utils/textHelpers';

interface CopyWeblinkProps {
    onCopy?: (props?: any) => any;
    isPhone?: boolean;
    isDownloadable?: boolean;
    isReadWriteWeblinkAccess?: boolean;
    isFolder?: boolean;
    isModifiedWindow?: boolean;
    folderName?: string;
    successSnackbar?: SnackbarItem;
}

export const useCopyWeblink = ({
    isPhone,
    isDownloadable,
    isReadWriteWeblinkAccess,
    isFolder,
    folderName,
    successSnackbar,
    isModifiedWindow = false,
    onCopy = noop,
}: CopyWeblinkProps) => {
    const isNewFlowEnabled = useSelector(getFeatureABCreateFolderNewFlow);
    const storage = useSelector(getCurrentStorage);

    const copy = useCallback(
        (value: string, input?: HTMLInputElement): void => {
            if (!value) {
                return;
            }

            if (isPhone) {
                toolbarActions.shareLink({ url: value, successSnackbar });
                onCopy();
                return;
            }

            const processCopy = (notification): void => {
                if (notification) {
                    snackbarController.showSnackbar(notification);
                }

                if (input) {
                    input.select();
                    input.setSelectionRange(0, value.length);
                }

                onCopy(notification?.type === 'success');
            };

            const copyText = () => {
                const copyTextWithAccessBase = 'Ссылка скопирована с доступом для';

                if (isModifiedWindow) {
                    return 'Доступ по ссылке открыт';
                }

                if (!IS_B2B_BIZ_USER || isFolder) {
                    return 'Ссылка успешно скопирована';
                }

                if (isDownloadable) {
                    return `${copyTextWithAccessBase} скачивания`;
                }

                if (isReadWriteWeblinkAccess && !IS_FREE_BIZ_SAAS_USER) {
                    return `${copyTextWithAccessBase} редактирования`;
                }

                return `${copyTextWithAccessBase} просмотра`;
            };

            copyApiCall(value)
                .then((resp): void => {
                    const hasBeenCopied = resp ? resp.hasBeenCopied : true;
                    const text =
                        folderName &&
                        !IS_B2B_BIZ_USER &&
                        !IS_BIZ_USER &&
                        !IS_FREE_BIZ_SAAS_USER &&
                        isNewFlowEnabled &&
                        storage !== EStorageType.sharedAutodelete
                            ? `Папка «${truncateTextMiddle(folderName, 18)}» создана --- ссылка  на неё скопирована`
                            : copyText();
                    const snackbar = successSnackbar || {
                        type: SnackbarTypes.success,
                        text,
                        id: 'copyLink',
                        closable: true,
                    };

                    return processCopy(hasBeenCopied ? snackbar : null);
                })
                .catch((): void =>
                    processCopy({ type: SnackbarTypes.failure, text: 'Не удалось скопировать ссылку', id: 'copyLink', closable: true })
                );
        },
        [
            isPhone,
            onCopy,
            isModifiedWindow,
            isFolder,
            isDownloadable,
            isReadWriteWeblinkAccess,
            folderName,
            isNewFlowEnabled,
            storage,
            successSnackbar,
        ]
    );

    return { copy };
};
