/**
 * Во избежании конфликтов при добавлении таски в merge train НЕ ДОБАВЛЯЕМ в файл новых фич!
 *
 * Вместо этого следуем соглашению https://confluence.vk.team/pages/viewpage.action?pageId=1229289649
 *
 */

import type { MyOfficeFallbackTimeoutConfig } from 'Cloud/Application/Editor/MyOffice/myOffice.types';
import { getFeature, isFeature } from 'Cloud/Application/FeaturesEs6';
import browser from 'Cloud/browser';
import {
    ALLOW_ANONYM_DOWNLOAD_PUBLIC,
    ANONYM_USER,
    IS_ATTACHES,
    IS_BIZ_USER,
    IS_CORP_USER,
    IS_IOS_MOBILE,
    IS_MOBILE_BROWSER,
    IS_ONPREMISE,
    IS_PAID_USER,
    IS_PHONE_BROWSER,
    IS_STOCK,
    IS_THEMED_PUBLIC,
    IS_WEBVIEW,
    PROMO_TARIFFS,
    THEME,
} from 'reactApp/appHelpers/configHelpers';
import type {
    FavorableTariffsIos,
    FeatureCrossedFullPriceInTariffCard,
    FeatureQuotaBuyPlatformConfig,
    FeatureQuotaBuyWebviewConfig,
    ProfitableTariffs,
    TariffsAndVariant,
    WelcomeDefaultTariff,
    WelcomeTrialParams,
} from 'reactApp/appHelpers/featuresHelpers/features.types';
import { isPromoQuotaInAndroid } from 'reactApp/appHelpers/settingsHelpers';
import { DEFAULT_MYOFFICE_FALLBACK_TIMEOUT, TEN_MINUTES } from 'reactApp/constants';
import { SPACE_LIST } from 'reactApp/constants/spaceList';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { REQUIRED_SIGNUP_POPUP_SHOWED_C } from 'reactApp/modules/requiredAuthorization/constants';
import type { MigrateFileV2ToV4, MigrateFolderV2ToV4, MigrateWeblinksV2ToV4 } from 'reactApp/types/features';
import { SAFE_FAKEDOOR_STORE_ID } from 'reactApp/ui/SafeFakedoorModal/SafeFakedoorModal.constants';

import type { TemplateVariantString } from './types';

export const isWopiForSafari = browser.isSafari() ? isFeature('wopi-safari') : true;
export const isWopiEnabled = isWopiForSafari && isFeature('wopi');

export const attachTextFormats = getFeature('attach-text-formats') ?? [];

export const wopiFallbackConfig =
    isWopiForSafari &&
    (getFeature('wopi-fallback-config') || {
        hostReg: '^https:\\/\\/(?:docs\\-mo|docs\\-myoffice)\\.datacloudmail\\.ru',
        enableFallback: false,
        enableFallbackServiceError: false,
        enableTimeoutFallback: false,
        enableFallbackPostMsgError: false,
    });

export const wopiJsErrorsFallbackList = isWopiForSafari && (getFeature('wopi-js-errors-fallback-list') || []);

const myOfficeFallbackTimeoutConfigValue: Partial<MyOfficeFallbackTimeoutConfig> = getFeature('myoffice-fallback-timeout-config');

const isCorrectTimeoutConfig = (config: Partial<MyOfficeFallbackTimeoutConfig>): config is MyOfficeFallbackTimeoutConfig =>
    typeof config?.default === 'number' &&
    typeof config?.override === 'object' &&
    config?.override !== null &&
    Object.entries(config.override).every(([key, value]) => typeof key === 'string' && typeof value === 'number');

/**
 * CLOUDWEB-16114: Загружаем конфигурацию таймаута для фолбека CES -> SEK.
 * Конфигурация содержит дефолтное значение таймаута для всех расшиений файлов и
 * возможность указать переопределенные значения таймаутов для конкретных расширений файлов.
 */
export const myOfficeFallbackTimeoutConfig: MyOfficeFallbackTimeoutConfig = isCorrectTimeoutConfig(myOfficeFallbackTimeoutConfigValue)
    ? myOfficeFallbackTimeoutConfigValue
    : { default: DEFAULT_MYOFFICE_FALLBACK_TIMEOUT, override: {} };

export const isHashCalcLibWasm = getFeature('hash-lib')?.isWasm ?? 0;
export const isHashCalcLibCrypto = (getFeature('hash-lib')?.isCrypto && typeof window?.crypto?.subtle?.digest === 'function') ?? 0;
export const isDmrReactUrlEnabled = isFeature('ab-dmr-react');
export const isDmrReactUrlTouchEnabled = isFeature('ab-dmr-react-touch');
// DMR для реакт окна присылает неверную высоту, пока делаем коррекцию этого у себя
export const abReactDmrHeightFix = getFeature('ab-dmr-react')?.heightFix ?? 0;
// tempexp_SRCH-70037 start
export const isWebSearchContentEnabled = isFeature('new_search_by_content');
// tempexp_SRCH-70037 end
// tempexp_SRCH-70925 start
export const isTouchSearchContentEnabled = isFeature('new_search_by_content_touch');
// tempexp_SRCH-70925 end
// tempexp_SRCH-70994 start
export const isNewWebSearchHistoryEnabled = isFeature('new_search_query_history_web');
// tempexp_SRCH-70994 end
// tempexp_SRCH-70997 start
export const isTouchSearchHistoryEnabled = isFeature('new_search_query_history_touch');
// tempexp_SRCH-70997 end

// tempexp_14979-start
export const isHideQuotaNewbieFeatureEnabled = isFeature('hide-quota-newbie');
// Нужно тестировать разную дефолную квоту до начала экспа. Поэтому мокаем через фичу.
export const defaultQuotaValueMockForQA = getFeature('default-quota-mock-qa') || false;
// tempexp_14979-end

/** CLOUDWEB-14535: ЕК. Фильтр по годам. Shift лет назад от текущего года для старых групп */
export const quotaCleanerOldGroupYear = getFeature('quota-cleaner-old-group-year') || 0;
/** CLOUDWEB-15461 Аб: Новый лп Единой квоты/страница подписок в вебвью */
export const isTariffsQuotaLandingWebview = isFeature('tariffs-quota-landing-webview');
/** CLOUDWEB-13956 МойОфис. Поддержать совместимость функциональности фронтенда Облака по совместному редактированию */
export const isWopiCoEdit = isFeature('wopi-co-edit');
/** CLOUDWEB-16228: Совместное редактирование для R7-Офис */
export const isR7WopiCoEdit = isFeature('r7-wopi-co-edit');
/** CLOUDWEB-15029: МойОфис. Бизнес метрики. Интервал отправки аткуальности сессии в сек */
export const myOfficeSessionInterval = Number.parseInt(getFeature('myoffice-session-interval'));
/** CLOUDWEB-15029: МойОфис. Бизнес метрики. Включение по типу */
export const myofficeBMetricConfig = getFeature('myoffice-bmetric-config') || {};
/** B2BCLOUD-474: Включение просмотра временных аттачей-документов */
export const isTemporaryAttachView = isFeature('temporary-attach-view');
/** CLOUDWEB-15182 На странице Mail Space отсылать event в биллинг при просмотре тарифов, частота отправки события в мс */
export const sendStartEventTimer: number = getFeature('send-start-tariffs-event') || 0;
/** CLOUDWEB-15280 Поправить преимущества подписок
 * если это промо ек из приложения, то фича рабоать не должна */
export const isOnlyWebAdInTariffs = (!isPromoQuotaInAndroid && isFeature('only-web-ad-in-tariffs')) || false;
/** CLOUDWEB-14763: Конфиг АПИ миграции с v3 -> v4 */
export const apiMigrateV3ToV4 = getFeature('api-migrate-v3-to-v4') || {};
/** B2BCLOUD-1084 Миграция ручек folder/share c APIv2 -> APIv4 для биз юзеров */
export const apiMigrateFolderV2ToV4: MigrateFolderV2ToV4 = getFeature('api-migrate-v2-to-v4-folder') || {};
/** B2BCLOUD-925: Фича АПИ миграции с v2 -> v4 для ручек weblinks/* */
export const apiMigrateWeblinksV2ToV4: MigrateWeblinksV2ToV4 = getFeature('api-migrate-v2-to-v4-weblinks') || {};
/** B2BCLOUD-1004: Фича АПИ миграции с v2 -> v4 для ручки file/* */
export const apiMigrateFileV2ToV4: MigrateFileV2ToV4 = getFeature('api-migrate-v2-to-v4-file') || {};
/** CLOUDWEB-15428 Выключаем бесконечную карусель на /subs и добавляем к ней дотсы  */
export const isNotInfiniteSubsCarousel = isFeature('not-infinite-subs-carousel') || false;
// Квота рекомендованного тарифа в списке тарифов
export const recommendedTariffQuota = getFeature('recommended-tariff-quota') || SPACE_LIST.gb_256;

/** CLOUDWEB-14002-a Включение просмотра файлов из мобильной чистилки */
export const isQuotaCleanerMobViewing = isFeature('quota-cleaner-mob-viewing');

/** CLOUDWEB-15822 Включение просмотра писем из мобильной чистилки */
export const isQuotaCleanerMobLetterViewing = isFeature('quota-cleaner-mob-letter-viewing');

/** CLOUDWEB-15822 Включение просмотра писем из мобильной чистилки */
export const isQuotaCleanerMobLetterUrlToTouch = isFeature('quota-cleaner-mob-letter-url-to-touch');

/** CLOUDWEB-16076 Скрытие кнопки скачать письмо в тач чистилке */
export const isQuotaCleanerMobLetterDownloadAvailable = isFeature('quota-cleaner-mob-letter-download-available');

// Урл и id для каптчи при отмене подписки
export const subscriptionCaptchaConfig: { url: string; captchaId: number } = getFeature('subscription-captcha-config') || {
    url: 'https://c.mail.ru/',
    captchaId: 5,
};
/** CLOUDWEB-15437: МойОфис. Метрики. White list рефереров */
export const myofficeBMetricProjectList = getFeature('myoffice-bmetric-project-list') || [];
/** CLOUDWEB-15488: Переименование файлов/папок в сетке по двойному клику на их название */
export const datalistInlineRenameEnabled = getFeature('datalist-inline-rename') || false;
/** CLOUDWEB-15000 Раздел Документы. Возврат в раздел Документы в шапке */
export const isAllDocumentsReturn = isFeature('all-documents-return');
/** CLOUDWEB-17276 PDF-Редактор Возврат в Документы */
export const isPDFReturnToAllDocuments = isFeature('pdf-return-to-all-documents');
/** CLOUDWEB-15323 Раздел Документы. Заводная точка в Облаке */
export const isAllDocumentsInMenu = isFeature('all-documents-in-menu');
/** CLOUDWEB-15647 Раздел "Из Почты". Ограничить число одновременного залития аттачей в Облако. */
export const attachesCloneLimit = getFeature('attaches-clone-limit')?.limit ?? undefined;
/** CLOUDWEB-15512: 10 лет облаку разработка лендинга / frontend (тариф) */
export const happyBirthdayCloud10Tariff = getFeature('happybirthdaycloud-tariff') || {
    price: 149,
    id: 'W128G1m_single_quota_2022_cloud',
    period: '1m',
    available: true,
    isPromo: false,
    hasTrial: false,
    isProfessional: true,
    isDisko: false,
    hasDiskO: false,
    hasDiskOAutoRenewal: false,
    isVkCombo: false,
    isForceDiscountTrial: false,
    isPrepaid: false,
    hasDiscount: false,
    space: {
        original: 137438953472,
        units: 'ГБ',
        space: 128,
        value: '128 ГБ',
    },
    turbo: false,
    isSingleQuota: true,
};

/** CLOUDWEB-16879 Файлы. Перевести загрузку и скачивание файлов на o2 токены */
export const o2UploadFeature = getFeature('o2-upload');

/** CLOUDWEB-15321 Раздел Документы. Внедрить форму обратной связи */
export const isAllDocumentsHelpButton = isFeature('alldocuments-help-button');

/** CLOUDWEB-15774 Отключаем раздачу призов на лендинге ДР Облака */
export const isHappyBirthdayCloudFinished = isFeature('happybirthdaycloud10-finished') || false;

/** CLOUDWEB-15845 Уведомление о "Рекомендательных технологиях" */
export const isSuggestionsLawEnabled = isFeature('suggestions-law') || false;

/** Общая фича для включения семейно подписки  */
export const isFamilySubsDesktopAvailable = (!IS_ONPREMISE && isFeature('family-subscription')) || false;
export const isFamilySubsTouchAvailable = (!IS_ONPREMISE && isFeature('family-subscription-mobile')) || false;
export const isFamilySubsAvailable = IS_PHONE_BROWSER ? isFamilySubsTouchAvailable : isFamilySubsDesktopAvailable;
/** CLOUDWEB-15532 Семейная подписка. Фичи на карточках тарифов  */
export const isFamilySubsInTariff = (isFamilySubsAvailable && isFeature('family-subs-in-tariffs')) || false;
/** CLOUDWEB-14048: Фича показа aside promo (балуна) семейной подписки (тач) */
export const isFamilyAsidePromoEnabledMobile = isFeature('family-aside-promo-mobile') || false;
/** CLOUDWEB-15528: Фича показа aside promo (балуна) семейной подписки */
export const isFamilyAsidePromoEnabled = IS_PHONE_BROWSER ? isFamilyAsidePromoEnabledMobile : isFeature('family-aside-promo') || false;
// Кол-во возможных членов семьи
export const familyMembersCount = getFeature('family-members-count') || 4;
/** CLOUDWEB-15531: Фича включения семейной подписки на странице /promo/quota */
export const isFamilySubsLandingAvailable = isFeature('family-subscription-landing') || false;
export const isFamilySubsLandingMobileAvailable = isFeature('family-subscription-landing-mobile') || false;
/** CLOUDWEB-15747: Начать принудительный апдейт квоты для тех у кого есть флаг family раз в 10 минут */
export const familyMemberQuotaUpdateInterval = getFeature('family-quota-interval') || TEN_MINUTES;
/** CLOUDWEB-16043:  Фича включения кнопки семейной подписки в блоке квоты на мобилке */
export const isFamilyQuotaButtonMobileEnabled = isFeature('family-quota-button-mobile') || false;
/** CLOUDWEB-15529: Фича включения кнопки семейной подписки в блоке квоты */
export const isFamilyQuotaButtonEnabled = IS_PHONE_BROWSER ? isFamilyQuotaButtonMobileEnabled : isFeature('family-quota-button') || false;
/** CLOUDWEB-15530: Фича включения отображения семьи в блоке квоты  */
export const isFamilyQuotaInSpaceEnabled = isFeature('family-quota-in-space') || false;
/** CLOUDWEB-16043: Фича включения пукта меню Семья в бургер-меню на мобилке */
export const isFamilyMobileMenuItemEnabled = isFeature('family-mobile-menu-item') || false;

/* tempexp_15752-next-line */
/** CLOUDWEB-15752 Доработка окна шаринга / АБ */
export const isAbNewSharingFolder = isFeature('ab-sharing-folder');

/** CLOUDWEB-15787 Меняем место сохранение редактируемого аттача с раздела "Из почты" на папку ALL DOCUMENTS */
export const isAttachSaveToAllDocuments = isFeature('attach-save-to-alldocuments');

/** CLOUDWEB-16015 Белая шапка. Доработки UI */
export const isWhiteHeaderFavoritesEnabled = isFeature('white-header-favorites-enabled');

/** CLOUDWEB-15910 Сброс каунтера Облака  */
export const isStoryUnviewedReset = isFeature('story-unviewed-reset');

/** Дата окончания акций */
export const promotionEndDate: string = getFeature('promotion-end-date') ?? '';

// CLOUDWEB-15998 фича включения черной темы на страницах с тарифами
export const isDarkTariffsPages = isFeature('dark-tariffs-pages') || false;

/** CLOUDWEB-14445: Фича, которая оставляет только оплаты картами */
export const isHidePayMethodsMenu = isFeature('hide-pay-methods-menu');
export const isHidePayMethodsMenuTouch = isFeature('hide-pay-methods-menu-mobile');

/** CLOUDWEB-15889: Фичи, через которые задается дефотлный способ оплаты
 *  работает только с включенным левым меню */
export const defaultPayMethod = !isHidePayMethodsMenu && getFeature('default-pay-method');
export const defaultPayMethodTouch = !isHidePayMethodsMenuTouch && getFeature('default-pay-method-mobile');

/** CLOUDWEB-15705 Поддержка открытия формата *.fb2
 * (включенно, при включенной fb2 в конфиге ebook-desktop-config)
 */
export const convertFB2toEPUB = getFeature('convert-fb2-to-epub') && !ANONYM_USER && !IS_STOCK && !IS_ATTACHES;

export const ebookDesktopConfig:
    | {
          exts: string[];
          timeout: number;
      }
    | undefined = getFeature('ebook-desktop-config');

if (ebookDesktopConfig?.exts) {
    ebookDesktopConfig.exts = ebookDesktopConfig.exts.filter((ext) => {
        if (ext?.toLowerCase?.() === 'fb2') {
            return convertFB2toEPUB;
        }

        return true;
    });
}

export const ebookTouchConfig: typeof ebookDesktopConfig = getFeature('ebook-touch-config');

/** b2bcloud-1268 Добавить возможность открывать панель логов для папок там-же, где есть версионирование для файлов  */
/** Не включать в SaaS до готовности бэка B2BCLOUD-1272 */
export const isActivitiesFeatureEnabled = isFeature('activities-and-versions');
export const activitiesFeature = getFeature('activities-and-versions') || {};

/** CLOUDWEB-16194: сжимаем и центрируем баннер worm_nv в аттачах  */
export const isAbAttachesCentered = isFeature('ab-attaches-centered');

/** CLOUDWEB-15875: Новая логика обновления рекламы аттачей почты */
export const isAttachUpdateLogicV2 = isFeature('ab-attach-update-logic-v2');

/** CLOUDWEB-16338 АБ Увеличение цен на тарифы 128 и 256 гб на ВЕБ */
// tempexp_16338-next-line
export const abMonthTariffsWithIncreasedPrice = !IS_WEBVIEW ? getFeature('ab-month-tariffs-increased-price') : false;

/** CLOUDWEB-16249 web. Реализация фейкдора сейф */
export const abSafeFakeDoor = getFeature('ab-safe-fakedoor');
export const abSafeFakeDoorSelector = (): TemplateVariantString | boolean => {
    if (storeHelper.getValue(SAFE_FAKEDOOR_STORE_ID) || IS_MOBILE_BROWSER || IS_PAID_USER || IS_BIZ_USER || IS_CORP_USER) {
        return false;
    }

    if (abSafeFakeDoor === 'a') {
        return 'variant1';
    }

    if (abSafeFakeDoor === 'b') {
        return 'variant2';
    }

    return false;
};
/** CLOUDWEB-16438: Кастомизация заголовка и подзаголовка promo/quota через Омикрон */
export const promoQuotaMarketingFallbackText = getFeature('promo-quota-marketing-fallback-text') || {};

/** CLOUDWEB-16349: [WEB] АБ-тест 64ГБ */
// tempexp_16349-next-line
export const desktopTariffMinSize = getFeature('desktop-tariff-min-size') || 128;
export const touchTariffMinSize = getFeature('touch-tariff-min-size') || 64;

/** CLOUDWEB-16455: Окна оплаты. Прокидывать заголовок и описание через квери параметры */
export const tariffBuyCustomTitle: Record<number, string> = getFeature('tariff-buy-custom-title') || {};
export const tariffBuyCustomDescription: Record<number, string> = getFeature('tariff-buy-custom-description') || {};

// Включает избранное в разделе альбомов
export const isFeatureAlbumFavorites = isFeature('album-favorites');

// CLOUDWEB-16231 [ab] [touch] Обязательная регистрация при скачивании файла на таче
const abRequiredSignUpWhenDownloadingTouch = getFeature('ab-required-sign-up-when-downloading-touch');
export const abRequiredSignUpWhenDownloadingTouchSelector = (checkStore = true): TemplateVariantString | boolean => {
    if (ALLOW_ANONYM_DOWNLOAD_PUBLIC || IS_WEBVIEW || !IS_PHONE_BROWSER) {
        return false;
    }

    if (abRequiredSignUpWhenDownloadingTouch === 'b') {
        return 'variant1';
    }

    if (abRequiredSignUpWhenDownloadingTouch === 'c' && (!checkStore || !storeHelper.getValue(REQUIRED_SIGNUP_POPUP_SHOWED_C))) {
        return 'variant2';
    }

    return false;
};

/** CLOUDWEB-17227: Ребрендинг. Облако. Web. Заменить цвета основных компонентов */
export const isRebranding = isFeature('rebranding') || false;

export const isRebrandingQuotaLanding: boolean = IS_WEBVIEW ? isFeature('rebranding-quota-webview') : isFeature('rebranding-quota');

/** CLOUDWEB-16303: Внедрение  VKUI токенов (темной темы) */
export const isDarkTheme =
    (isFeature('dark-theme') || (!IS_PHONE_BROWSER && isFeature('theme-switcher') && THEME === 'dark')) && !IS_THEMED_PUBLIC;

/** CLOUDWEB-16373: [WEB] Подборка выгодных тарифов */
// tempexp_16373-next-line
export const favorableTariffs: string[] = !IS_MOBILE_BROWSER ? getFeature('favorable-tariffs') || [] : [];

/** CLOUDWEB-16799: [IOS] Подборка выгодных тарифов */
// tempexp_16799-start
const defaultFavoribleTariffsIos: FavorableTariffsIos = { tariffs: [], variant: null };
export const iosFavorableTariffs: FavorableTariffsIos =
    IS_IOS_MOBILE && IS_WEBVIEW ? getFeature('favorable-tariffs-ios') || defaultFavoribleTariffsIos : defaultFavoribleTariffsIos;
// tempexp_16799-end

/** CLOUDWEB-16510: [TOUCH] расположить карточки тарифов в обратном порядке */
// tempexp_16510-next-line
const iOsMobileTariffsOrderByQuota: number[] = IS_MOBILE_BROWSER || IS_WEBVIEW ? getFeature('tariffs-order-by-quota-mobile-ios') || [] : [];
const androidMobileTariffsOrderByQuota: number[] =
    IS_MOBILE_BROWSER || IS_WEBVIEW ? getFeature('tariffs-order-by-quota-mobile-android') || [] : [];
export const mobileTariffsOrderByQuota = IS_IOS_MOBILE ? iOsMobileTariffsOrderByQuota : androidMobileTariffsOrderByQuota;

// tempexp_16480-start
const androidCancelRenewTrialPeriod = IS_WEBVIEW || IS_MOBILE_BROWSER ? getFeature('cancel-renew-trial-period-android') : false;
const iOsCancelRenewTrialPeriod = IS_WEBVIEW || IS_MOBILE_BROWSER ? getFeature('cancel-renew-trial-period-ios') : false;
const cancelRenewTrialPeriodMobile = IS_IOS_MOBILE ? iOsCancelRenewTrialPeriod : androidCancelRenewTrialPeriod;
const cancelRenewTrialPeriodWeb = getFeature('cancel-renew-trial-period') || false;
/** CLOUDWEB-16480: Триал при отписке */
export const cancelRenewTrialPeriod = IS_WEBVIEW || IS_MOBILE_BROWSER ? cancelRenewTrialPeriodMobile : cancelRenewTrialPeriodWeb;
// tempexp_16480-end

/** CLOUDWEB-16636:  возможность конфигурить настройки поллинга zipview.mail.ru/info */
export const zipviewAttachPoll: { pollPeriodMs: number; pollCount: number; prolongPeriodMs?: number } = getFeature(
    'zipview-attach-poll'
) || {
    pollPeriodMs: 500,
    pollCount: 10,
};

/** CLOUDWEB-16865: Время показа снекбара автологина */
export const autologinTimeout: number = getFeature('autologin-timeout')?.data || 5000;

const additionalTariffsListDesktop: { tariffs: string[]; variant: string } | undefined =
    (!IS_MOBILE_BROWSER && !IS_WEBVIEW && getFeature('additional-tariffs-list')) || undefined;
export const additionalTariffsList = additionalTariffsListDesktop;

/** Зачеркивать полную цену тарифов на карточках тарифов карусели на /promo/quota */
const crossedFullPriceInTariffCardTouch: FeatureCrossedFullPriceInTariffCard | undefined = IS_IOS_MOBILE
    ? getFeature('crossed-full-price-in-tariff-card-ios')
    : getFeature('crossed-full-price-in-tariff-card-android');
const crossedFullPriceInTariffCardDesktop: FeatureCrossedFullPriceInTariffCard | undefined = !IS_MOBILE_BROWSER
    ? getFeature('crossed-full-price-in-tariff-card') || { year: false, month: false }
    : { year: false, month: false };
export const crossedFullPriceInTariffCard: FeatureCrossedFullPriceInTariffCard = (IS_WEBVIEW
    ? crossedFullPriceInTariffCardTouch
    : crossedFullPriceInTariffCardDesktop) || { year: false, month: false };
// tempexp_16612-end

/** CLOUDWEB-16898: варианты внешнего вида кнопки закрытия аттачей */
export enum AttachesCloseButtonAB {
    /** CLOUDWEB-16898: кнопка закрытия с серым фоном и иконкой */
    HighlightIcon = 1,
    /** CLOUDWEB-16898: кнопка закрытия с серым фоном и текстом */
    HighlightText = 2,
}

/** CLOUDWEB-16898: внешний вид кнопки закрытия просмотрщика аттачей */
export const attachesCloseButtonVersion = getFeature('ab-attaches-close-button-version') as AttachesCloseButtonAB | undefined;

/** CLOUDWEB-16781: единый exp_id для рекламы в облаке */
export const advExpId = (getFeature('adv-exp-id') as string | number) || undefined;

/** CLOUDWEB-16867: тормозим обновление рекламы при открытии лидформ */
export const isLeadFormLogicEnabled = isFeature('leadform-logic-enabled');

// tempexp_16790-start
/** CLOUDWEB-16790: Изменение тарифов в коммуникации оверквотникам */
export const abOverquotaPopupTariffs: 'b' | 'c' | undefined = getFeature('ab-quota-popup-tariffs');
// tempexp_16790-end

/** CLOUDWEB-16735 Переход пользователей с веб на мобилку
 CLOUDWEB-17118 Переход пользователей с веб на мобилку / Выкатываем победившие варианты */
export const promoMobileAppAttaches = getFeature('promo-app-attach') || false;
export const promoMobileAppLM = getFeature('promo-app-lm') || false;

export const quotaBuyWebviewIOSConfig: FeatureQuotaBuyWebviewConfig = getFeature('quota-buy-ios-config-webview-cloud');

// tempexp_16626-next-line
export const trialPrice: number = getFeature('trial-price') || 1;

// tempexp_16948-start
// CLOUDWEB-16948 показываем годовую цену на кнопке месячного тарифа вместо годового в вебвью ios
export const yearlyPriceOnMonthlyTariffButtonIOS: 'a' | 'b' | 'c' =
    (IS_WEBVIEW && getFeature('yearly-price-on-monthly-tariff-button-ios-webview')) || 'a';

// CLOUDWEB-16948 показываем годовую цену на кнопке месячного тарифа вместо годового в вебвью android
export const yearlyPriceOnMonthlyTariffButtonAndroid: 'a' | 'b' | 'c' =
    (IS_WEBVIEW && getFeature('yearly-price-on-monthly-tariff-button-android-webview')) || 'a';

export const yearlyPriceOnMonthlyTariffButtonTouch: 'a' | 'b' | 'c' = IS_IOS_MOBILE
    ? yearlyPriceOnMonthlyTariffButtonIOS
    : yearlyPriceOnMonthlyTariffButtonAndroid;

// tempexp-16948-end

/** B2BCLOUD-1802 Доступен ли в OnPrem продукт R7 */
export const isOnPremR7Product = isFeature.ssr('onprem-r7-product');

/** CLOUDWEB-17018 Ограничение размера загружаемого файла в МБ */
export const uploadFileSizeLimitMbAb = (getFeature('ab-upload-limit') as number) || undefined;

// tempexp_16800-start
// CLOUDWEB-16800
export const moreBenefitsInExpensiveTariffs: 'a' | 'b' | 'c' | 'd' = getFeature('more-benefits-in-expensive-tariffs') || 'a';
// tempexp_16800-end

/** CLOUDWEB-16460: [WEB] +10 ТБ на автозагрузку фото и видео tempexp_16460-next-line */
const abAutoupload10TBTouch: string[] | undefined = IS_IOS_MOBILE
    ? getFeature('ab-autoupload-promo-10-tb-ios')
    : getFeature('ab-autoupload-promo-10-tb-android');

/** CLOUDWEB-16481: [WEB] +10 ТБ на автозагрузку фото и видео tempexp_16481-next-line */
export const abAutoupload10TB: string[] = IS_MOBILE_BROWSER ? abAutoupload10TBTouch || [] : getFeature('ab-autoupload-promo-10-tb') || [];

// tempexp-16876-start
const defaultProfitableTariffs: ProfitableTariffs = { tariffs: [], variant: 'a' };
const abProfitableTariffsFirstIos: ProfitableTariffs = IS_WEBVIEW
    ? getFeature('ab-profitable-tariffs-first-ios') || defaultProfitableTariffs
    : defaultProfitableTariffs;
export const abProfitableTariffsFirstTouch = IS_IOS_MOBILE && abProfitableTariffsFirstIos;
// tempexp-16876-end

// tempext-17154-start
export const disableButtonModal = getFeature('disable-button-modal');
export const tariffBuyCustomFeatures = getFeature('tariff-buy-custom-features');
// tempext-17154-end

// CLOUDWEB-17157 Баннер в боковом меню Облака (профиль)
export const sidebarTrialPromo = getFeature('sidebar-trial-promo');
// CLOUDWEB-17145

export const quotaTooltip: 'a' | 'b' | 'c' | 'd' = getFeature('quota-tooltip');
// CLOUDWEB-17130 Добавить в просмотрщик точку входа в триал
export const mailSpaceTrialPromo: 'a' | 'b' | 'c' = getFeature('mail-space-trial-promo');
/**
 * CLOUDWEB-17007 Триал с годовым продлением на приветственном экране облака
 */
const welcomeTrialParamsNewDefaults: WelcomeTrialParams = {
    productId: PROMO_TARIFFS?.default128GbTrial ?? '',
    bannerName: 'welcome-trial',
    infoOldStyle: true,
    useMonthPrice: false,
};

const welcomeTrialParamsOverquotaDefaults: WelcomeTrialParams = {
    productId: PROMO_TARIFFS?.default128GbTrial ?? '',
    bannerName: 'welcome-trial',
    infoOldStyle: true,
    useMonthPrice: false,
};

// Параметры триала для новых юзеров
export const welcomeTrialParamsNew: WelcomeTrialParams = getFeature('welcome-trial-params-new') || welcomeTrialParamsNewDefaults;

// Параметры триала для юзеров у которых мало места (в рамках этого таска не юзается, сделано для унификации)
export const welcomeTrialParamsOverquota: WelcomeTrialParams =
    getFeature('welcome-trial-params-overquota') || welcomeTrialParamsOverquotaDefaults;
// CLOUDWEB-17007 end

// CLOUDWEB-17220 Тарифы с повышенными ценами для старых платников
const preferredTariffsDesktop: string[] = getFeature('preferred-tariffs') || [];
const preferredTariffsTouch: string[] = getFeature('preferred-tariffs-touch') || [];
export const preferredTariffs: string[] = IS_MOBILE_BROWSER ? preferredTariffsTouch : preferredTariffsDesktop;

export const quotaLandingPromoButtonTariff: string = getFeature('quota-lp-promo-button-tariff');

/* CLOUDWEB-14423 ЕК. Включение оплаты для IOS */
export const getFeatureQuotaBuyIOSConfig: FeatureQuotaBuyPlatformConfig = getFeature('quota-buy-ios-config');
/* CLOUDWEB-14423 ЕК. Включение оплаты для Android */
export const getFeatureQuotaBuyAndroidConfig: FeatureQuotaBuyPlatformConfig = getFeature('quota-buy-android-config');

/* CLODWEB-17270 Перенос историй в галерею */
export const abStoriesLocation: 'a' | 'b' = getFeature('stories-location') || 'a';
export const isStoriesInGallery = abStoriesLocation === 'b';

/* CLOUDWEB-17557 Улучшение автоматического выбора качества видео */
export const abQualitySelectionAlgorithm: 'a' | 'b' = getFeature('quality-selection-algorithm') || 'a';
export const isBBAQualitySelectionAlgorithm = abQualitySelectionAlgorithm === 'b';

/* CLOUDWEB-17256 Документы. Добавить редирект на doc.mail.ru при закрытии аттача */
export const getViewerReturnToAllDocuments: string[] = getFeature('viewer-return-to-all-documents') || [];

// tempexp-17076-start
export const threeMonthsPeriodTariffsAndroid: TariffsAndVariant =
    !IS_IOS_MOBILE && IS_WEBVIEW && getFeature('three-months-period-tariffs-android');
// tempexp-17076-end

/** CLOUDWEB-16912 Снеки для рустора на просмотрщиках apk */
export const rustoreApkSnackbar = getFeature('rustore-apk-snakbar') || false;

// tempexp_17086-next-line
export const welcomeDefaultTariff: WelcomeDefaultTariff | undefined = !IS_MOBILE_BROWSER ? getFeature('welcome-default-tariff') : undefined;

/* CLOUDWEB-17237 [Web][Desktop] - Красивая ссылка шеринга (фейкдор) */
// tempexp_17237-next-line
export const abSharingLinkFakedoor: 'a' | 'b' = (!IS_PAID_USER && getFeature('sharing-link-fakedoor')) || 'a';

// tempexp_17125-start
export const abBlockOverqueota: 'b' | 'c' | 'a' | '' =
    !IS_PAID_USER && !IS_BIZ_USER && !IS_MOBILE_BROWSER ? getFeature('ab-block-overquota') : '';
// tempexp_17125-end

/* CLOUDWEB-17438 Просмотрщик. Видео. Не отображается кнопка выбора качества в Сафари */
export const isOverrideNativeHLS: boolean = getFeature('override-native-hls') || false;

/**
 * Во избежании конфликтов при добавлении таски в merge train НЕ ДОБАВЛЯЕМ в файл новых фич!
 *
 * Вместо этого следуем соглашению https://confluence.vk.team/pages/viewpage.action?pageId=1229289649
 *
 */
