import React, { memo, useCallback } from 'react';
import type { TFaceListProps } from 'reactApp/ui/FaceFilter/FaceFilter.types';
import { VirtualListLoader } from 'reactApp/ui/VirtualList/VirtualListLoader';

import { FaceItem } from './FaceItem';
import styles from './FaceListItem.css';

export const FaceListItem = memo(function FaceListItem({
    columnIndex,
    rowIndex,
    style,
    data,
}: {
    columnIndex: number;
    rowIndex: number;
    style: any;
    data: TFaceListProps & { columnCount: number };
}) {
    const { faces, selectedFaceId, onSelect, loadMore, hasMoreToLoad, isLoading, isMobile, columnCount } = data;

    const handleOnClick = useCallback(
        (id) => {
            onSelect!(id);
        },
        [onSelect]
    );

    const idx = rowIndex * columnCount + columnIndex;
    if (!faces?.length) {
        return null;
    }

    const lastRealItemIdxPlusOne = faces?.length - (hasMoreToLoad ? 1 : 0);
    const isNonExistItemOrLoader = idx >= lastRealItemIdxPlusOne;
    if (isNonExistItemOrLoader) {
        return hasMoreToLoad && idx === lastRealItemIdxPlusOne ? (
            <div style={style}>
                <VirtualListLoader className={styles.loader} loadOnScroll={loadMore} isLoading={isLoading} />
            </div>
        ) : null;
    }

    const face = faces[idx];
    const { id, imageUrl, numOfPhotos } = face;
    const isSelected = face.id === selectedFaceId;

    return (
        <div style={style}>
            <FaceItem
                id={id}
                numOfPhotos={numOfPhotos}
                isSelected={isSelected}
                imageUrl={imageUrl}
                onClick={handleOnClick}
                isMobile={isMobile}
            />
        </div>
    );
});
