import { requestPayment } from 'Cloud/Application/Payment';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { renderBizMigrationDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog.helpers';
import { getFeatureAbSpace } from 'reactApp/modules/features/features.selectors';
import { getSubscriptionsRequest } from 'reactApp/modules/subscriptions/subscriptions.module';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { createGaSender, sendGa } from 'reactApp/utils/ga';
import { EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

const sendGaSpace = createGaSender('space');

export const useSpaceCallbacks = ({
    isBizUser,
    isSubscriptionsPage,
    countdownPromo,
    isAction,
    isB2BUser,
    isOldBizUser,
    isPaidUser,
    goToSubscriptions,
    isMobile,
}) => {
    const dispatch = useDispatch();

    const remaining = useSelector(UserQuotaSelectors.getRemaining);
    const total = useSelector(UserQuotaSelectors.getTotal);
    const abSpace = useSelector(getFeatureAbSpace);

    const sendPaymentRadar = useCallback(
        (label: string) => {
            if (isBizUser || isSubscriptionsPage) {
                return;
            }

            let action = EPaymentGa.space;

            if (countdownPromo) {
                action = EPaymentGa.spaceDiscount;
            } else if (isAction) {
                action = EPaymentGa.spaceAction;
            }

            sendPaymentGa({ action, label, size_quota: total?.original, free_quota: remaining?.original, group: abSpace });
        },
        [abSpace, countdownPromo, isAction, isBizUser, isSubscriptionsPage, remaining?.original, total?.original]
    );

    const onShow = useCallback(() => {
        if (!isBizUser) {
            dispatch(getSubscriptionsRequest());
        }

        sendPaymentRadar('view');
        sendGa('leftColBuyButton', 'show', 'all');

        if (!isB2BUser) {
            sendGa('leftColBuyButton', 'show', 'base');
            sendGaSpace('bttn', 'show');
        }
    }, [dispatch, isB2BUser, isBizUser, sendPaymentRadar]);

    const onSubscriptionClick = useCallback(() => goToSubscriptions({ inSameTab: true }), [goToSubscriptions]);

    const onPayment = useCallback(() => {
        if (isOldBizUser) {
            renderBizMigrationDialog();
            return;
        }

        sendGaSpace('bttn', 'click');
        sendPaymentRadar('click');

        if (isMobile) {
            sendGa('leftColBuyButton', 'mobile-click');
            goToSubscriptions({ inSameTab: true });
            return;
        }

        const paymentOptions: { tariff?: string; goToSubscriptions?: () => void; paySource: string } = {
            goToSubscriptions,
            paySource: 'space',
        };

        if (countdownPromo) {
            paymentOptions.tariff = countdownPromo.data.productId;
            paymentOptions.paySource = 'space_discount';
        }

        sendGa('leftColBuyButton', 'click');

        if (!isPaidUser) {
            sendGa('spacebar-free-user', 'click');
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        requestPayment({ ...paymentOptions });
    }, [isOldBizUser, sendPaymentRadar, isMobile, countdownPromo, isPaidUser, goToSubscriptions]);

    return {
        onPayment,
        onShow,
        onSubscriptionClick,
    };
};
