import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { store as reduxStore } from 'reactApp/store';
import { sendDwh } from 'reactApp/utils/ga';

import { ECategoryGa } from './paymentGa';

export enum EDiskOPromoAnalytics {
    'show-left' = 'show-left',
    'click-left' = 'click-left',
    'cancel-left' = 'cancel-left',
    'show-upgrade' = 'show-upgrade',
    'click-upgrade' = 'click-upgrade',
    'cancel-upgrade' = 'cancel-upgrade',
}

export const sendDiskOPromoAnalytics = (action: EDiskOPromoAnalytics): void => {
    const state = reduxStore.getState();
    const { totalSize, freeSize } = UserQuotaSelectors.getSpace(state);
    const isPaidUser = UserSelectors.isPaidUser(state);

    const dwhData = {
        size_quota: totalSize,
        free_quota: freeSize,
        free_user: !isPaidUser,
        paid_user: isPaidUser,
    };

    sendDwh({
        eventCategory: ECategoryGa.promoDiskO,
        action,
        dwhData,
    });
};
