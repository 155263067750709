import { PromoManager } from '@mail/3promo/manager';
import { lazyInit } from '@mail-core/std';
import { xray } from 'lib/xray';
import { createOpenPromise } from 'o-promise';
import { newPromoFeature } from 'reactApp/appHelpers/featuresHelpers/features/newPromo';
import type { NEW_PROMO_ID } from 'reactApp/modules/promoNew/defaultValues';
import { pdfAutosaveCreator } from 'reactApp/modules/promoNew/items/pdfAutosaveCreator';
import { pdfWorkWithPagesCreator } from 'reactApp/modules/promoNew/items/pdfWorkWithPagesCreator';

import { defaultReactRenderDeps } from '../render/react';

const { promise, resolve } = createOpenPromise<PromoManager>();

export const getPromoManager = () => promise;
export const updatePromoManager = async () => {
    const manager = await getPromoManager();
    return manager.update();
};

export const initPromoManager = lazyInit(async () => {
    resolve(
        new PromoManager(
            {
                defaultChecks: {
                    isModelEnabled: (id) => {
                        return !!newPromoFeature.PROMO?.[id as keyof typeof NEW_PROMO_ID]?.enabled;
                    },
                },
                globalSettings: {
                    SHOW_INTERVAL: newPromoFeature.SHOW_INTERVAL,
                    DISABLED: !!newPromoFeature.DISABLED,
                },
                debug: {
                    DEBUG: !!newPromoFeature.FORCE_SHOW,
                    SHOW_ID: newPromoFeature.FORCE_SHOW,
                    FORCE_SHOW: !!newPromoFeature.FORCE_SHOW,
                },
                stores: {},
                models: {
                    base: {
                        counter: (chain, id) => xray.send(`new-promo-show-${id}-${chain}`),
                    },
                },
                render: {
                    react: defaultReactRenderDeps,
                },
            },
            [pdfAutosaveCreator, pdfWorkWithPagesCreator]
        )
    );
});
