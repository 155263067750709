import classNames from 'clsx';
import React, { type ForwardedRef, forwardRef, memo } from 'react';
import { Datalist } from 'reactApp/ui/Mobile/Datalist/Datalist';
import { DownloadAppButton } from 'reactApp/ui/Mobile/DownloadAppButton/DownloadAppButton';
import { UploadInfo } from 'reactApp/ui/Mobile/UploadInfo/UploadInfo';

import styles from './MobilePublicPage.css';

interface MobilePublicThemedPageDatalistProps {
    downloadEnabled: boolean;
    emptyFolderUploadAllowed?: boolean;
    uploadInfoEnabled: boolean;
    hideUploadInfo?: () => void;
}

export const MobilePublicThemedPageDatalist = memo(
    forwardRef(
        (
            { uploadInfoEnabled, hideUploadInfo, downloadEnabled, emptyFolderUploadAllowed }: MobilePublicThemedPageDatalistProps,
            ref: ForwardedRef<HTMLDivElement>
        ) => {
            return (
                <div className={styles.dataListContainer} ref={ref}>
                    {uploadInfoEnabled && (
                        <div className={classNames(styles.uploadInfo, { [styles.emptyFolderUploadAllowed]: emptyFolderUploadAllowed })}>
                            <UploadInfo closable onClose={hideUploadInfo} />
                        </div>
                    )}
                    <Datalist />
                    {downloadEnabled && (
                        <div className={styles.appButton}>
                            <DownloadAppButton />
                        </div>
                    )}
                </div>
            );
        }
    )
);
