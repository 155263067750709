import { extInfo } from 'lib/extInfo';
import { DEFAULT_FILES_HASHES } from 'reactApp/constants/magicIdentificators';
import type { FeedCategoryType, FeedItemApiResponse, FeedItemBase } from 'reactApp/modules/feed/feed.types';
import { getExtension, getItemNameWithoutExt, getParent, isFolder, normalizeId, normalizePublicApiInfo } from 'reactApp/modules/file/utils';
import type { AddFile } from 'reactApp/modules/modifying/modifying.types';
import { UrlBuilder } from 'reactApp/modules/urlBuilder/UrlBuilder';
import { normalizeMtime } from 'reactApp/utils/tree.helpers';

const urlBuilder = new UrlBuilder();

export const filterOutDefaultContentAdnFoldersItems = (item) => !isFolder(item) && !(item.hash && DEFAULT_FILES_HASHES.includes(item.hash));

export const getExtensionByCategory = (category: FeedCategoryType) => {
    const otherDocumentTypes = ['pdf', 'xml', 'djvu', 'xps', 'pages'];

    const feedCategoryFnMap = {
        document: (feedCategory: FeedCategoryType) => extInfo.getExtsByKind(feedCategory).concat(otherDocumentTypes).join(','),
        all: () => undefined,
        default: (feedCategory: FeedCategoryType) => extInfo.getExtsByKind(feedCategory).join(','),
    } as const;

    return (feedCategoryFnMap[category] || feedCategoryFnMap['default'])(category);
};

export const normalizeBaseFeedItem = (feedItem: FeedItemApiResponse | AddFile, mountedFoldersIds?: Nullable<string[]>) => {
    const parentFolder = feedItem.home ? normalizeId(getParent(feedItem.home)) : '/';
    const ext = getExtension(feedItem);
    const nameWithoutExt = getItemNameWithoutExt(feedItem.name, ext);
    const { kind, subKind } = extInfo.get(ext);

    const isReadOnly = Boolean(mountedFoldersIds?.some((mountedFolderId) => feedItem.home.includes(mountedFolderId)));

    const normalizedItem: FeedItemBase = {
        isReadOnly,
        id: feedItem.home,
        isFolder: false,
        parent: parentFolder,
        ext,
        nameWithoutExt,
        name: feedItem.name,
        home: feedItem.home || '',
        size: feedItem.size,
        kind,
        subKind,
        mtime: normalizeMtime(feedItem.mtime),
        virusScan: feedItem.virus_scan,
        hash: feedItem.hash,
        uflr: 'uflr' in feedItem ? feedItem.uflr : undefined,
        isInFavorites: 'favorite' in feedItem && (feedItem.favorite === 1 || feedItem.favorite === true),
        weblink: '',
        url: urlBuilder.getUrls({
            ext,
            isPublic: false,
            id: feedItem.home,
            weblink: '',
            size: feedItem.size,
            kind,
            subKind,
            isStock: false,
            name: feedItem.name,
            path: feedItem.home,
        }),
        thumbnails: urlBuilder.getThumb({
            hash: feedItem.hash,
            ext,
            id: feedItem.home,
            size: feedItem.size,
            kind,
            name: feedItem.name,
            path: feedItem.home,
            isPublic: false,
            isStock: false,
            dwl_token: null,
            weblink: '',
        }),
    };

    if ('public' in feedItem && feedItem.public) {
        return {
            ...normalizedItem,
            ...normalizePublicApiInfo(feedItem),
        };
    }

    return normalizedItem;
};
