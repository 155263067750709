import { ServerError } from 'reactApp/errors';

export type R7Mode = 'edit' | 'view';

export type R7ErrorHandler = ({ data }: { data: unknown }) => void;

export interface IR7Data extends Record<string, unknown> {
    api: string;
    documentType: EditorDocumentType;
}

export type R7Options = {
    mode?: R7Mode;
    onDocumentReady: () => void;
    onError: R7ErrorHandler;
    r7InitData?: IR7Data;
    isAttach?: boolean;
};

export interface IR7ToolbarParams {
    isAttach?: boolean;
}

export const enum R7Theme {
    light = '1',
    dark = '2',
}

export const enum R7ErrorCode {
    /**
     * Ошибка возникает, если размер разархивированного/сконвертированного файла превышает
     * предельный размер, установленный для инсталяции Р7
     *
     * {@link
     *  https://support.r7-office.ru/document_server/api-document_server/using-api-document_server/config/common/error%D1%81odes.js/#:~:text=ConvertationOpenLimitError Документаци Р7
     * }
     */
    CONVERTATION_OPEN_LIMIT_ERROR = -84,
}

interface ClientEditorError extends ServerError {
    response: {
        fields: [string, string];
    };
}

export const isClientEditorError = (error: unknown): error is ClientEditorError =>
    error instanceof ServerError && error.status >= 400 && error.status < 500 && error?.response?.fields?.length > 1;

export enum EditorDocumentType {
    spreadsheet = 'spreadsheet',
    presentation = 'presentation',
    text = 'text',
}
