import classNames from 'clsx';
import { ReactComponent as AuthIcon } from 'img/icons/mobile/auth.svg';
import React, { type ReactElement, memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ANONYM_USER, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { hasAuthorizationInfo } from 'reactApp/appHelpers/featuresHelpers/features/superAppAuthorizationInfo';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { authPopup } from 'reactApp/modules/ph/ph.thunkActions';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { Avatar } from 'reactApp/ui/Avatar/Avatar';
import { OptionsBlock } from 'reactApp/ui/Mobile/OptionsModal/OptionsBlock/OptionsBlock';
import { renderUserChangeModal } from 'reactApp/ui/Mobile/UserChangeModal/UserChangeModal.helpers';
import { Icon20ChevronRight } from 'reactApp/ui/touchIcons';
import { noopVoid } from 'reactApp/utils/helpers';
import { PostMessageTypes, superAppPostMessage } from 'reactApp/utils/superAppWebViewHelpers';
import { EQueryParams } from 'server/helpers/getRequestParams';

import styles from './UserBlock.css';

export const UserBlock = memo(
    ({
        onClose = noopVoid,
        isLeftMenu = false,
        reloginAction = false,
        isRebrandingQuotaLanding = false,
    }: {
        onClose?: () => void;
        isLeftMenu?: boolean;
        reloginAction?: boolean;
        isRebrandingQuotaLanding?: boolean;
    }): ReactElement | null => {
        const email = useSelector(UserSelectors.getEmail);
        const login = useSelector(UserSelectors.getLogin);
        const dispatch = useDispatch();
        const fromDeeplink = getQueryParams()[EQueryParams.fromDeeplink];
        const isSuperAppWebview = IS_WEBVIEW && fromDeeplink;
        const disableButton: boolean = isSuperAppWebview && ANONYM_USER && hasAuthorizationInfo(ANONYM_USER);

        const onClick = useCallback(() => {
            renderUserChangeModal({ reloginAction, isRebrandingQuotaLanding });
        }, [reloginAction]);

        const onAuth = useCallback(() => {
            dispatch(
                authPopup({
                    closable: true,
                    loginRequest: true,
                    successPage: window.location.href,
                })
            );
        }, [dispatch]);

        const authItems = useMemo(
            () => [
                {
                    id: 'auth',
                    text: 'Войти в аккаунт',
                    icon: <AuthIcon />,
                    onClick: disableButton ? () => superAppPostMessage(PostMessageTypes.authorization) : onAuth,
                },
            ],
            [onAuth]
        );

        if (!email) {
            return <OptionsBlock id="auth" onClose={onClose} items={authItems} />;
        }

        if (isSuperAppWebview) {
            return null;
        }

        return (
            <div
                className={classNames(styles.root, {
                    [styles.root_leftMenu]: isLeftMenu,
                    [styles.root_rebrandingQuotaLanding]: isRebrandingQuotaLanding,
                })}
                onClick={onClick}
            >
                <div className={styles.avatar}>
                    <Avatar size={isLeftMenu ? 46 : 32} email={email} />
                </div>
                <div className={styles.info}>
                    <div className={styles.login}>{login}</div>
                    <div className={styles.email}>{email}</div>
                </div>
                {isLeftMenu && (
                    <div className={styles.arrow}>
                        <Icon20ChevronRight height={20} width={20} fill="currentColor" />
                    </div>
                )}
            </div>
        );
    }
);

UserBlock.displayName = 'UserBlock';
