import { X_PAGE_ID } from 'reactApp/appHelpers/configHelpers';
import {
    PUT_MAX_CHUNK,
    uploadNewFileApi,
    uploadNewFileSelectingDialogs,
} from 'reactApp/appHelpers/featuresHelpers/features/uploadNewFileApi';
import { removeFileSuccess, uploadFilesEnded } from 'reactApp/modules/modifying/modifying.actions';
import type { RemoveFileSuccessAction } from 'reactApp/modules/modifying/modifying.types';
import { ConnectionFail } from 'reactApp/modules/uploading/fails/ConnectionFail';
import { joinPath } from 'reactApp/modules/uploading/helpers/fs/fs.helpers';
import { getConnectionTypeString, sendGaUploaderNew } from 'reactApp/modules/uploading/helpers/uploading.helpers';
import type { UploadingPacketConfig } from 'reactApp/modules/uploading/serviceClasses/UploadingPacketConfig';
import { MAX_PARALLEL_UPLOAD_THREADS, uploadingService } from 'reactApp/modules/uploading/serviceClasses/UploadingService';
import { RETRY_TIMEOUT_CONNECTION_ERROR } from 'reactApp/modules/uploading/uploading.constants';
import { startFileUploading } from 'reactApp/modules/uploading/uploading.module';
import { EConflictResolution, EUploadingState } from 'reactApp/modules/uploading/uploading.types';
import { checkProgressStatus } from 'reactApp/modules/uploadList/sagas/progress.saga';
import { EProgressStatus } from 'reactApp/modules/uploadList/uploadList.model';
import { getProgressStatus } from 'reactApp/modules/uploadList/uploadList.selectors';
import { sendDwh, sendKaktamLog } from 'reactApp/utils/ga';
import { call, delay, put, select } from 'redux-saga/effects';

function* processPacketsUploadEnd() {
    const removeFromDatalistIdxs = new Set<string>();
    const uploadedFiles = new Set<string>();
    const decriptorsToRemove: RemoveFileSuccessAction['descriptors'] = {};

    const packets = uploadingService.getAllPackets();

    if (!packets.length) {
        return;
    }

    uploadingService._isActive = false;

    packets.forEach((packet: UploadingPacketConfig) => {
        if (packet.packetUploadEndProcessed) {
            return;
        }

        packet.packetUploadEndProcessed = true;

        packet.finishTime = Date.now();

        const totalTime = Math.round(packet.finishTime - packet.startTime);
        const speed = Math.round((packet.size - packet.prevUploadedBytes) / (totalTime / 1000));

        sendGaUploaderNew('packet', 'speed', speed);

        sendGaUploaderNew('packet', `process_${packet.hasNetworkError ? 'netwerr' : packet.failedCount ? 'hasfailed' : 'nofailed'}`);

        let putSpeed = 0;
        if (packet.putTime > 0) {
            putSpeed = (packet.size - packet.prevUploadedBytes) / (packet.putTime / 1000);
            sendGaUploaderNew('packet', 'putspeed', putSpeed);
        }
        if (totalTime > 0) {
            sendGaUploaderNew('packet', 'totuplspeed', ((packet.size - packet.prevUploadedBytes) * 1000) / totalTime);
        }

        const descriptors = uploadingService.getPacketDescriptors(packet.packetId);

        const maxLogItems = 10;
        let fileReadErrors = 0;
        let hashCalcErrors = 0;
        let putErrors = 0;
        let addFileErrors = 0;
        let networkErrors = 0;
        let retryCounts = 0;
        let firstReadErrors = 0;
        let failedCounter = 0;
        const firstReadErrorStatus: string[] = [];
        const fileReadErrorStatus: string[] = [];
        const hashCalcErrorStatus: string[] = [];
        const putErrorStatus: string[] = [];
        const putErrorStage: string[] = [];
        const addErrorStatus: string[] = [];
        descriptors.forEach((descriptor) => {
            if ([EUploadingState.STATE_DONE, EUploadingState.STATE_UPLOADED].includes(descriptor.state)) {
                uploadedFiles.add(descriptor.cloudPath);
            }

            if (descriptor.conflictResolution === EConflictResolution.skip) {
                return;
            }

            if (descriptor.state === EUploadingState.STATE_FAIL || descriptor.state === EUploadingState.STATE_CANCEL) {
                retryCounts += descriptor.retryCount;

                if (descriptor.firstReadError && !descriptor.knownError) {
                    firstReadErrors++;
                    failedCounter++;

                    if (firstReadErrorStatus.length < maxLogItems && descriptor.firstReadErrorStatus) {
                        firstReadErrorStatus.push(descriptor.firstReadErrorStatus);
                    }
                }
                if (descriptor.fileReadError && !descriptor.knownError) {
                    fileReadErrors++;
                    failedCounter++;

                    if (fileReadErrorStatus.length < maxLogItems && descriptor.fileReadErrorStatus) {
                        fileReadErrorStatus.push(descriptor.fileReadErrorStatus);
                    }
                }
                if (descriptor.hashCalcError) {
                    hashCalcErrors++;
                    failedCounter++;

                    if (hashCalcErrorStatus.length < maxLogItems && descriptor.hashCalcErrorStatus) {
                        hashCalcErrorStatus.push(descriptor.hashCalcErrorStatus);
                    }
                }
                if (descriptor.error instanceof ConnectionFail) {
                    networkErrors++;
                }
                if (descriptor.putFileError && !descriptor.knownError) {
                    putErrors++;
                    failedCounter++;

                    if (putErrorStatus.length < maxLogItems && descriptor.putErrorStatus) {
                        putErrorStatus.push(descriptor.putErrorStatus);
                    }

                    if (putErrorStage.length < maxLogItems && descriptor.putErrorStage) {
                        putErrorStage.push(descriptor.putErrorStage);
                    }
                }
                if (descriptor.addFileError && !descriptor.knownError) {
                    addFileErrors++;
                    failedCounter++;

                    if (addErrorStatus.length < maxLogItems && descriptor.addFileErrorStatus) {
                        addErrorStatus.push(descriptor.addFileErrorStatus);
                    }
                }
            }

            const canceled = descriptor.getTopMostCanceledDescriptor();
            if (!canceled) {
                return;
            }
            const id = canceled.cloudPath || joinPath(packet.workingDirectory, canceled.localPath);

            if (id) {
                decriptorsToRemove[id] = {
                    hash: descriptor.hash,
                    descriptorId: descriptor.id,
                    isDirectory: descriptor.isDirectory,
                };
                removeFromDatalistIdxs.add(id);
            }
        });

        sendDwh({
            eventCategory: 'uploader',
            action: 'packet-finished',
            label: 'metrics',
            dwhData: {
                totalTime,
                uploadedBytes: packet.size,
                prevUploadedBytes: packet.prevUploadedBytes,
                fileCount: packet.fileCount,
                fileFailedCount: packet.failedCount,
                folderCount: packet.folderCount,
                canceledCount: packet.cancelCount,
                hasNetworkError: packet.hasNetworkError,
                fileReadErrors,
                hashCalcErrors,
                putErrors,
                addFileErrors,
                hasError: packet.hasError,
                source: packet.storage,
                group: MAX_PARALLEL_UPLOAD_THREADS.toString(),
                description: getConnectionTypeString(),
                diskReadTime: packet.diskReadTime,
                hashCalcTime: packet.hashCalcTime,
                putTime: packet.putTime || 0,
                pollTime: packet.pollTime || 0,
                addTime: packet.addTime || 0,
                speed,
            },
        });

        if (failedCounter) {
            const logData = {
                text: `upload_packet_error`,
                size: packet.size,
                folderCount: packet.folderCount,
                canceledCount: packet.cancelCount,
                isManuallyTotalCancel: uploadingService.isUserCanceled,
                filesCount: packet.fileCount,
                successCount: packet.successCount,
                failedCount: packet.failedCount || failedCounter,
                hasNetworkError: packet.hasNetworkError,
                networkErrors,
                firstReadErrors,
                firstReadErrorStatus: firstReadErrorStatus.join(),
                fileReadErrors,
                fileReadErrorStatus: fileReadErrorStatus.join(),
                hashCalcErrors,
                hashCalcErrorStatus: hashCalcErrorStatus.join(),
                putErrors,
                putErrorStatus: putErrorStatus.join(),
                putErrorStage: putErrorStage.join(),
                addFileErrors,
                addErrorStatus: addErrorStatus.join(),
                retryCounts,
                uploadedBytes: packet.currentProgressBytes,
                prevUploadedBytes: packet.prevUploadedBytes,
                diskReadTime: packet.diskReadTime,
                hashCalcTime: packet.hashCalcTime,
                putTimeSec: Math.round(packet.putTime / 1000 || 0),
                pollTimeSec: Math.round(packet.pollTime / 1000 || 0),
                addTimeSec: Math.round(packet.addTime / 1000 || 0),
                totalTimeSec: Math.round(totalTime / 1000),
                currentProgressBytes: packet.currentProgressBytes,
                putSpeedMbSec: putSpeed / 1024 / 1024,
                // env info
                isOldApi: packet.isOldApi,
                uploadThreads: MAX_PARALLEL_UPLOAD_THREADS.toString(),
                uploadNewFileApi,
                uploadNewFileSelectingDialogs,
                PUT_MAX_CHUNK,
                connection: getConnectionTypeString(),
                bttType: packet.bttnType,
                options: packet.options,
                // @ts-ignore
                memoryAvailableGb: navigator?.deviceMemory,
                // @ts-ignore
                heapSize: performance?.memory?.jsHeapSizeLimit,
                pageId: X_PAGE_ID,
            };

            sendKaktamLog(logData);

            if (firstReadErrors) {
                sendGaUploaderNew('packerr', 'firstread', firstReadErrors);
            }
            if (hashCalcErrors) {
                sendGaUploaderNew('packerr', 'hashcalc', hashCalcErrors);
            }
            if (putErrors) {
                sendGaUploaderNew('packerr', 'putfile', putErrors);
            }
            if (addFileErrors) {
                sendGaUploaderNew('packerr', 'addfile', addFileErrors);
            }
            if (retryCounts) {
                sendGaUploaderNew('packerr', 'retires', retryCounts);
            }
        }
    });

    if (removeFromDatalistIdxs.size > 0) {
        yield put(removeFileSuccess({ ids: Array.from(removeFromDatalistIdxs), descriptors: decriptorsToRemove }));
    }
    if (uploadedFiles.size > 0) {
        yield put(uploadFilesEnded({ ids: Array.from(uploadedFiles) }));
    }
}

export function* handleProcessUploadQueue() {
    if (uploadingService.isUserCanceled) {
        yield processPacketsUploadEnd();
        return;
    }

    const descriptors = uploadingService.getDescriptorsForUpload();

    if (!descriptors.length) {
        if (uploadingService.uploadingQueue.length <= 0) {
            // Новых дескрипторов для загрузки нет, и сейчас ничего не загружается - значит все уже загружено
            yield processPacketsUploadEnd();

            const progress = yield select(getProgressStatus);
            const packets = uploadingService.getProcessingPackets();

            if (!packets.length && !uploadingService._isActive && progress === EProgressStatus.PROGRESS) {
                yield call(checkProgressStatus, true);
            }
        }

        return;
    }

    for (const descriptor of descriptors) {
        if (descriptor) {
            if (descriptor.hasCanceledParent()) {
                continue;
            }

            uploadingService._isActive = true;

            if (descriptor.state === EUploadingState.STATE_SUSPENDED) {
                yield delay(RETRY_TIMEOUT_CONNECTION_ERROR);
            }

            uploadingService.uploadingQueue.push(descriptor.id);
            yield put(startFileUploading(descriptor));
        }
    }
}
