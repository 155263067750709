import { loadUserQuotaRequest } from 'reactApp/modules/userQuota/userQuota.module';
import ping from 'reactApp/utils/ping';

import { setError, setIsLoading, setUserData } from './user.actions';
import { callApi, checkUserDataEquals } from './user.helpers';
import { getUserData, getUserIsLoaded } from './user.selectors';

// TODO: rename
// eslint-disable-next-line unicorn/consistent-function-scoping
export const updateUser = () => (dispatch, getState) => {
    const state = getState();
    const initialState = getUserData(state);

    return new Promise((resolve, reject) => {
        // @ts-ignore
        ping({ initialState, request: callApi, check: checkUserDataEquals(initialState) })
            .then((data) => {
                dispatch(loadUserQuotaRequest());
                resolve(dispatch(setUserData(data)));
            })
            .catch((error) => {
                dispatch(setIsLoading({ value: false }));
                return reject(error);
            });
    });
};

let loadUserPromise: any = null;

// eslint-disable-next-line unicorn/consistent-function-scoping
export const loadUser = () => (dispatch, getState) => {
    if (loadUserPromise) {
        return loadUserPromise;
    }
    loadUserPromise = new Promise<void>((resolve, reject) => {
        if (getUserIsLoaded(getState())) {
            return resolve();
        }

        dispatch(setIsLoading({ value: true }));

        callApi()
            .then((data) => {
                return resolve(dispatch(setUserData(data)));
            })
            .catch((error) => {
                dispatch(setError(true));
                return reject(error);
            })
            .finally(() => {
                loadUserPromise = null;

                // dispatch(setIsLoading({ value: false }));
            });
    });
    return loadUserPromise;
};
