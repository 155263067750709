import { xray } from 'lib/xray';
import React, { type ReactElement, memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IS_DEV } from 'reactApp/appHelpers/configHelpers';
import { tariffBuyCustomTitle } from 'reactApp/appHelpers/featuresHelpers';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { initProducts } from 'reactApp/modules/products/products.module';
import { isFramed } from 'reactApp/utils/isFramed';
import opener from 'reactApp/utils/opener';

import styles from './BuyDisableAds.css';
import { DisableAdsScreen } from './DisableAdsScreen/DisableAdsScreen';
import { useEmbeddedBuyScreen } from './hooks/useEmbeddedBuyScreen';

interface BuyIframeQueryParams {
    title?: string;
    tariffs?: string;
    activeTab?: 'y' | 'm';
    fastcheckoutTitle?: string;
}

const {
    title: titleFromQuery,
    tariffs: tariffsFromQuery,
    activeTab: activeTabFromQuery,
    fastcheckoutTitle: fastcheckoutTitleFromQuery,
}: BuyIframeQueryParams = getQueryParams();

export const BuyDisableAdsIframe = memo((): ReactElement => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(initProducts());
    }, []);

    if (!isFramed() && !IS_DEV) {
        xray.send(`buy-disable-ads-frame_error_not_frame`);
        opener('/', true);
    }

    const fastcheckoutTitle = fastcheckoutTitleFromQuery ? tariffBuyCustomTitle[fastcheckoutTitleFromQuery] : undefined;

    const { embeddedContent, openEmbeddedBuy } = useEmbeddedBuyScreen(fastcheckoutTitle);
    return (
        <div className={styles.root}>
            <DisableAdsScreen
                titleFromQuery={titleFromQuery}
                tariffsIdsFromQuery={tariffsFromQuery}
                activeTabFromQuery={activeTabFromQuery}
                onBuyClick={openEmbeddedBuy}
            />
            {embeddedContent}
        </div>
    );
});

BuyDisableAdsIframe.displayName = 'BuyDisableAdsIframe';
