/* eslint-disable max-lines */
import type { PayloadAction } from '@reduxjs/toolkit';
import config from 'Cloud/config';
import sha1 from 'js-sha1';
import { logger } from 'lib/logger';
import { itemV4ToV2 } from 'reactApp/api/helpers/apiV4Helpers';
import { PublicInfoAPICall } from 'reactApp/api/public/PublicInfoAPICall';
import { PublicListAPICall } from 'reactApp/api/PublicListAPICall';
import {
    IS_B2B_BIZ_USER,
    IS_HIDDEN_PUBLIC,
    IS_PHONE_BROWSER,
    IS_PUBLIC_ALBUM,
    IS_PUBLIC_FOLDER,
    PUBLIC_SHOVE,
} from 'reactApp/appHelpers/configHelpers';
import { getThemeOutsourceId } from 'reactApp/appHelpers/themeToolHelpers/utils';
import { HttpError } from 'reactApp/errors';
import { startEditor } from 'reactApp/modules/editor/editor.module';
import { handleLoadFacesList } from 'reactApp/modules/faces/faces.saga';
import { getFacesList, getFacesListRequestState } from 'reactApp/modules/faces/faces.selectors';
import { chooseVariant } from 'reactApp/modules/features/features.helpers';
import { getFeatureFaces, getFeatureRequiredSignUpWhenDownloading } from 'reactApp/modules/features/features.selectors';
import {
    fixItemHomeLowcaseBug,
    getNameById,
    getParent,
    getWeblinkFromPublicId,
    isDocument,
    isFolder as checkIsFolder,
    isPreviewable,
} from 'reactApp/modules/file/utils';
import { loadHomeFolderRequest } from 'reactApp/modules/home/home.actions';
import {
    checkPublicPin,
    deletePublicPin,
    getPublicInfo,
    getPublicSharingInfo,
    loadFolderRequest,
    loadFolderStart,
    loadMorePublicFolderRequest,
    loadMorePublicFolderSuccess,
    loadMoreToFileRequest,
    loadPublicFileSuccess,
    loadPublicPage,
    markAsSentOpenDwhRadar,
    publicBuyDownloadSubscription,
    publicDisableDownload,
    publicPinBuySubscription,
    redirectFromViewerToFolder,
    sendPublicAnalyticsAction,
    setPublicPin,
    updateCountDownloads,
    updateFolderRequest,
    updateFolderSuccess,
    updatePublicInfoPin,
} from 'reactApp/modules/public/public.actions';
import { sendActionPublicAnalytics } from 'reactApp/modules/public/public.analytics';
import { normalizePublicId } from 'reactApp/modules/public/public.helpers';
import {
    geIsOpenDwhRadarSent,
    getCurrentPublicFolder,
    getIsRedirectFromViewer,
    getPublicItemById,
    getPublicRootWeblink,
    isOwnPublic,
} from 'reactApp/modules/public/public.selectors';
import {
    handleBuyPublicDisableDownloadSubscription,
    handlePublicDisableDownload,
} from 'reactApp/modules/public/sagas/disablePublicDownload.saga';
import { requiredAuthorizationHelpers } from 'reactApp/modules/requiredAuthorization/helpers';
import { reDownloadController, ReDownloadEntry } from 'reactApp/modules/requiredAuthorization/reDownloadController';
import { changeHistory, routeChangeStart, routeChangeSuccess, routeStatusPage } from 'reactApp/modules/router/router.module';
import { getCurrentRouteId, getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import type { GetStateResponse } from 'reactApp/modules/router/router.types';
import { selectOne, selectSeveral } from 'reactApp/modules/selections/selections.actions';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { snackbarController } from 'reactApp/modules/snackbar/snackbar.controller';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { defaultSort, getSortById } from 'reactApp/modules/sort/sort.selectors';
import { getLoadMoreLimit } from 'reactApp/modules/storage/storage.helpers';
import { getItemById } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getPublicThemeSucces } from 'reactApp/modules/themePublic/themePublic.module';
import { checkViewerAtIdChange, openMobileViewer as openMobileViewerAction } from 'reactApp/modules/viewer/viewer.module';
import { handleUpdateViewerData } from 'reactApp/modules/viewer/viewer.saga';
import { EStatus } from 'reactApp/sections/ErrorPage/ErrorPage.types';
import { isReactMobilePublic } from 'reactApp/sections/MobilePublicPage/isReactMobilePublic';
import { store as reduxStore } from 'reactApp/store';
import { formatExpires } from 'reactApp/utils/formatDate';
import { sendXray } from 'reactApp/utils/ga';
import { captureException } from 'reactApp/utils/tracer';
import { cancel, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import type { IPublicApiFolder, IPublicFolder } from 'server/types/context/IPublicFolder';
import { call } from 'typed-redux-saga';

import { getIdParams } from '../modifying/modifying.helpers';
import type { IRootWeblink } from './public.types';
import { loadPublicAlbumPageRequest, loadPublicAlbumPageSuccess } from './publicAlbum.actions';
import { loadPublicAlbumMore, loadPublicAlbumPage } from './publicAlbum.saga';
import {
    deletePublicPassword,
    handleBuyPublicPasswordSubscription,
    handleCheckPublicPin,
    setPublicPassword,
} from './sagas/publicPassword.saga';
import { watchRustoreSnackbar } from './sagas/rustoreSnackbar.saga';

const serverSideFolder = config.get('serverSideFolders') as IPublicApiFolder;
const editor = config.get('EDITOR');
const getPublicInfoCall = ({ public_id, pin }: { public_id: string; pin?: string }) =>
    new PublicInfoAPICall().makeRequest({ public_id, pin });

export function* openMobileViewer() {
    const id = yield select(getCurrentRouteId);

    if (isReactMobilePublic) {
        yield put(openMobileViewerAction({ id }));
    }
}

function* sendPublicAnalytics({ payload: { id, action } }: ReturnType<typeof sendPublicAnalyticsAction>) {
    const isOwn: ReturnType<typeof isOwnPublic> = yield select(isOwnPublic);
    const item = yield select(getItemById, id);
    const isFolder = checkIsFolder(item);
    const storage = yield select(getCurrentStorage);
    const isEditor = isPreviewable(item, storage) && isDocument(item);

    const faces = yield select(getFacesList);

    sendActionPublicAnalytics({
        action,
        id_public: isOwn && item?.home ? sha1(item.home) : id,
        id_file: isFolder ? undefined : id,
        id_folder: isFolder ? id : undefined,
        type_public: isFolder ? 'folder' : 'file',
        count_objects: isFolder ? item.count.all : 1,
        extension: isFolder ? 'None' : item?.ext?.toLowerCase() || '',
        owner: isOwn,
        isStock: storage === EStorageType.stock,
        isEditor,
        have_faces: Boolean(faces?.length),
        count_faces: faces?.length,
        source: 'public',
        shove: Boolean(PUBLIC_SHOVE),
    });
}

function* getPublicFolder(id: string) {
    const isRedirectFromViewer = yield select(getIsRedirectFromViewer);

    if (id === '/') {
        return;
    }

    const loadedPublic = yield select(getCurrentPublicFolder);

    // CLOUDWEB-14416 - если паблик есть на серверсайде и еще не загружен в стор - берем из серверсайда.
    if (IS_PUBLIC_FOLDER && serverSideFolder?.weblink === id && !loadedPublic) {
        return itemV4ToV2(serverSideFolder, EStorageType.public);
    }

    // CLOUDWEB-17185 - если мы только что закрыли просмотрщик, то заново загружать контент папки нам не нужно
    if (IS_PUBLIC_FOLDER && !isRedirectFromViewer) {
        return yield loadPublicFolder(id);
    }

    if (isRedirectFromViewer) {
        yield put(redirectFromViewerToFolder({ redirectFromViewer: false }));
    }
}

let expiresShowed = false;

function* showExpires(item) {
    const expires = item?.weblinkExpires;

    if (!expires || IS_PHONE_BROWSER || expiresShowed) {
        return;
    }

    expiresShowed = true;

    yield put(
        showSnackbarAction({
            id: 'public-expires',
            closable: true,
            text: `Доступ по ссылке открыт до ${formatExpires(expires)}`,
            type: SnackbarTypes.time,
            closeTimeout: 5000,
        })
    );
}

/* eslint-disable complexity */

/* eslint-disable-next-line sonarjs/cognitive-complexity, max-lines-per-function */
function* loadFolder({ payload: { storage, id: weblinkId } }: PayloadAction<GetStateResponse>) {
    /* tempexp_15344-start */
    const isSuccessAuthWhenDownload = yield select(requiredAuthorizationHelpers.isSuccessAuthWhenDownload);

    const requiredSignUpAllVariants = chooseVariant(getFeatureRequiredSignUpWhenDownloading, {
        control: false,
        variant1: isSuccessAuthWhenDownload,
    })();

    if (requiredSignUpAllVariants) {
        /* Достаем из хранилища параметры для загрузки */
        const toolbarParams = reDownloadController.getParams(ReDownloadEntry.Toolbar);
        const appHelpersParams = reDownloadController.getParams(ReDownloadEntry.AppHelpers);

        const items = toolbarParams?.selected || (appHelpersParams?.itemOrId ? [appHelpersParams?.itemOrId] : null);

        if (items && storage) {
            /* Выделяем выбранные ранее файлы */
            reduxStore.dispatch(
                selectSeveral({
                    selectedIdxs: items.map((item) => (typeof item === 'string' ? item : item?.id ?? '')),
                    storage,
                })
            );
        }
    }
    /* tempexp_15344-end */

    if (storage !== EStorageType.public || IS_PUBLIC_ALBUM) {
        return;
    }

    weblinkId = yield select(getCurrentRouteId);

    const item = yield select(getPublicItemById, weblinkId);

    if (item?.isFolder === false) {
        if (item?.weblinkExpires) {
            yield showExpires(item);
        }

        window.history.replaceState(null, '', window.location.pathname);

        // TODO: routing: возможно, нужно более явно дергать loadFolder, а не каждому чиху на routeChangeStart CLOUDWEB-13172
        return;
    }

    try {
        const isServerSideFile = serverSideFolder?.type === 'file';
        const isWeblinkEquals = serverSideFolder?.weblink === weblinkId;

        const id = IS_PUBLIC_FOLDER && isServerSideFile && isWeblinkEquals ? getParent(weblinkId) : weblinkId;
        const folder = yield getPublicFolder(id);

        // Если это файл в папке для просмотрщика, то если его нет в папке (он дальше 500), то пока возьмем его из serverSideFolder
        const fileNotExist = isServerSideFile && folder ? !folder.list?.find((item) => item.weblink === weblinkId) : false;

        const weblinkIsEqualId = id === weblinkId;

        if (
            fileNotExist &&
            folder &&
            !weblinkIsEqualId /* Нужно для кейса, когда просмотрщик файла пришел из SSR и закрылся по клику на крестик, в этом кейсе не нужно folder.list.push */
        ) {
            folder.list?.push({ ...serverSideFolder, ghostItemFromSSR: true });
        }

        if (fileNotExist && folder?.weblink) {
            // В фоне доскролливаем до этого файла.
            yield put(loadMoreToFileRequest({ folderId: folder.weblink, fileId: id }));
        }

        if (IS_PUBLIC_FOLDER && folder) {
            const publicId = getWeblinkFromPublicId(id) ?? '';
            const publicThemeId = getThemeOutsourceId(folder.public?.theme_id);
            if (publicThemeId) {
                yield put(getPublicThemeSucces({ themeId: publicThemeId, public_id: publicId }));
            }

            yield put(loadPublicPage(folder));
            yield openMobileViewer();

            if (folder.weblinkDownloadable && !IS_HIDDEN_PUBLIC) {
                yield call(handleUpdateViewerData, {
                    payload: {
                        itemId: folder.id,
                        itemStorage: EStorageType.public,
                    },
                });
                return;
            }

            const isFacesFeature = yield select(getFeatureFaces);
            const facesRequestState = yield select(getFacesListRequestState);

            const isOpenDwhRadarSent = yield select(geIsOpenDwhRadarSent);

            if (isFacesFeature && !facesRequestState.isLoaded) {
                yield handleLoadFacesList({ id: publicId });
            }

            yield showExpires(folder);

            if (!isOpenDwhRadarSent) {
                // тк loadFolder вызывается на каждую смену роута, а лица - на весь паблик, а не на каждую папку,
                // то сделали этот флаг. Делаем отслку после получения списка лиц, так как они нужны в радаре!
                yield put(sendPublicAnalyticsAction({ id: publicId }));

                yield put(markAsSentOpenDwhRadar());
            }

            return;
        }

        // Одиночный паблик
        if (isServerSideFile) {
            const item = itemV4ToV2(serverSideFolder, EStorageType.public);
            const name = item.name || getNameById(item);

            yield showExpires(item);

            yield put(
                loadPublicFileSuccess({
                    ...item,
                    name,
                    home: item.home ? fixItemHomeLowcaseBug(item.home, name) : null,
                })
            );

            if (item.home) {
                yield put(loadHomeFolderRequest({ id: getParent(item.home), isFolder: true }));
            }

            yield call(handleUpdateViewerData, {
                payload: {
                    itemId: item.id,
                    itemStorage: EStorageType.public,
                },
            });

            /**
             * Когда открывается одиночный паблик с эдитором, сторадж - editor
             * Поэтому надо добавить в сторадж эдитора айтем
             * @link https://jira.vk.team/browse/CLOUDWEB-12524
             */
            if (editor?.file === item.name) {
                yield put(
                    startEditor({
                        item: {
                            ...item,
                            subKind: item.subkind,
                            readOnly: item.readonly,
                            weblink_access_rights: item.weblinkAccessRights,
                            weblink_expires: item.weblinkExpires,
                            weblink_domestic: item.weblinkDomestic,
                        },
                    })
                );
            }

            yield put(sendPublicAnalyticsAction({ id: item.id }));

            yield put(markAsSentOpenDwhRadar());

            yield put(
                selectOne({
                    selectedIdx: item.id,
                    storage: EStorageType.public,
                })
            );

            return;
        }
    } catch (error: any) {
        logger.error(error);
        captureException(error);
        yield cancel();
    }
}

function* updateFolderSaga({ payload }: ReturnType<typeof updateFolderRequest>) {
    const { id, fileId } = payload;

    if (IS_PUBLIC_ALBUM) {
        return;
    }

    const item = yield select(getPublicItemById, id);

    if (item?.isFolder === false) {
        return;
    }

    try {
        const folder = yield loadPublicFolder(id);

        yield put(updateFolderSuccess(folder));

        const fileNotLoaded = fileId && folder && !folder.list?.find((item) => item.weblink === fileId);

        if (folder?.weblink && fileNotLoaded) {
            // В фоне доскролливаем до этого файла.
            yield put(loadMoreToFileRequest({ folderId: folder.weblink, fileId }));
        }
    } catch (error) {
        logger.error(error);
        captureException(error);
        yield cancel();
    }
}

function* loadMoreToFile(action) {
    try {
        const { fileId, folderId } = action.payload;
        let folder = yield select(getPublicItemById, folderId);

        if (!folder.isFolder) {
            return;
        }
        let hasMoreToLoad = folder.hasMoreToLoad;
        let fileExist = false;
        while (true) {
            if (!hasMoreToLoad || fileExist) {
                break;
            }
            const offset = folder.count.loaded;
            const response: IPublicFolder = yield loadPublicFolder(folderId, offset);
            // Проверяем, есть ли этот файл в ответе.
            // Если есть, то выкидываем его из ответа, так как ранее уже добавили его.
            const list = response.list.filter((item) => item.weblink !== fileId);
            fileExist = response.list.length !== list.length;
            response.list = list;
            yield put(loadMorePublicFolderSuccess(response));
            folder = yield select(getPublicItemById, folderId);
            hasMoreToLoad = folder.hasMoreToLoad;
        }
    } catch (error) {
        yield cancel();
    }
}

let loading: string[] = [];

function* loadMore(action: ReturnType<typeof loadMorePublicFolderRequest>) {
    if (IS_PUBLIC_ALBUM) {
        yield loadPublicAlbumMore();
        return;
    }

    const { id, offset } = action.payload;

    if (loading.includes(id)) {
        return;
    }

    try {
        loading.push(id);
        const folder: IPublicFolder = yield loadPublicFolder(id, offset);
        loading = loading.filter((item) => item !== id);
        yield put(loadMorePublicFolderSuccess(folder));

        return folder;
    } catch (error) {
        yield cancel();
        loading = loading.filter((item) => item !== id);
    }
}

function* handleGetPublicInfo() {
    try {
        const rootWeblink: IRootWeblink = yield select(getPublicRootWeblink);

        if (rootWeblink.count_downloads_left && rootWeblink.count_downloads_left > 1) {
            const { data } = yield* call(getPublicInfoCall, { public_id: rootWeblink.weblink });
            const { count_downloads_total, count_downloads_left } = data;
            yield put(updateCountDownloads({ count_downloads_left, count_downloads_total }));
        } else {
            yield put(
                updateCountDownloads({
                    count_downloads_left: 0,
                    count_downloads_total: rootWeblink.count_downloads_total,
                })
            );
        }
    } catch (error) {
        if (error instanceof HttpError && error.status === EStatus.NOT_FOUND) {
            snackbarController.showSnackbar({
                id: 'download-error',
                closable: true,
                text: 'Ссылка для скачивания больше недоступна',
                type: SnackbarTypes.failure,
            });
        }

        logger.error(error);
        yield cancel();
    }
}

function* loadPublicFolder(id, offset = 0) {
    try {
        const publicId = getWeblinkFromPublicId(id);
        const publicPin = sessionStorage.getItem(`public-${publicId}-pin`);

        const sort = yield select(getSortById, id);
        const { type, order } = sort || defaultSort;
        const idParams = {
            ...getIdParams(EStorageType.public, id),
            sort: type,
            order,
            offset: offset || 0,
            limit: getLoadMoreLimit(EStorageType.public),
            version: 4,
            ...(publicPin && { pin: publicPin }),
        };

        yield put(loadFolderStart({ id }));

        const { data } = yield new PublicListAPICall().makeRequest(idParams);

        sendXray(`app_load-folder_public`);

        return itemV4ToV2(data, EStorageType.public);
    } catch (error) {
        captureException(error, {
            message: 'LOAD FOLDER',
        });

        yield put(routeStatusPage({ status: EStatus.NOT_FOUND }));
    }
}

function* changePublicHistorySaga(action: PayloadAction<string>) {
    const id = action.payload.split('?').shift() ?? '';

    let idFixed = normalizePublicId(id);
    try {
        idFixed = decodeURIComponent(idFixed);
    } catch (error) {
        logger.error(error);
    }
    let item = yield select(getPublicItemById, idFixed);

    if (!item) {
        yield take([loadPublicPage, loadPublicAlbumPageSuccess]);

        item = yield select(getPublicItemById, idFixed);

        if (!item) {
            return;
        }
    }

    yield put(checkViewerAtIdChange({ id: item?.id, storage: EStorageType.public }));
}

function* handleGetPublicSharingInfo(action: PayloadAction<{ public_id: string }>) {
    try {
        const { public_id } = action.payload;
        const { data } = yield* call(getPublicInfoCall, { public_id });
        const { theme_id, pin } = data || {};

        const themeId = getThemeOutsourceId(theme_id);
        if (themeId) {
            yield put(getPublicThemeSucces({ themeId, public_id }));
        }

        if (pin || IS_B2B_BIZ_USER) {
            yield put(updatePublicInfoPin({ pin: pin ?? '', public_id }));
        }
    } catch (error) {
        logger.error(error);
        yield cancel();
    }
}

export function* watchPublic() {
    yield takeEvery(loadMorePublicFolderRequest.toString(), loadMore);
    yield takeEvery(loadMoreToFileRequest.toString(), loadMoreToFile);
    yield takeEvery(updateFolderRequest.toString(), updateFolderSaga);
    if (IS_PHONE_BROWSER || !editor) {
        yield takeEvery(routeChangeSuccess.toString(), loadFolder);
        yield takeEvery(changeHistory.toString(), changePublicHistorySaga);
        yield takeEvery(loadPublicAlbumPageRequest.toString(), loadPublicAlbumPage);
    } else {
        yield takeEvery(routeChangeStart.toString(), loadFolder);
    }
    yield takeEvery(loadFolderRequest.toString(), loadFolder);
    yield takeEvery(getPublicInfo.toString(), handleGetPublicInfo);
    yield takeEvery(getPublicSharingInfo.toString(), handleGetPublicSharingInfo);
    yield takeEvery(sendPublicAnalyticsAction.toString(), sendPublicAnalytics);

    yield takeEvery(setPublicPin.toString(), setPublicPassword);
    yield takeEvery(deletePublicPin.toString(), deletePublicPassword);
    yield takeEvery(checkPublicPin.toString(), handleCheckPublicPin);
    yield takeEvery(publicPinBuySubscription.toString(), handleBuyPublicPasswordSubscription);
    yield takeEvery(publicBuyDownloadSubscription.toString(), handleBuyPublicDisableDownloadSubscription);
    yield takeEvery(publicDisableDownload.toString(), handlePublicDisableDownload);
    yield fork(watchRustoreSnackbar);
}
