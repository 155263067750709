import type { UploadingDescriptor } from 'reactApp/modules/uploading/serviceClasses/UploadingDescriptor';
import type { EFileError, EFileStatus, IInputFile } from 'reactApp/modules/uploadList/uploadList.model';
import type { EDocumentType } from 'reactApp/sections/PersonalDocuments/PersonalDocuments.constants';

export enum EUploadingState {
    STATE_PENDING = 'pending',
    STATE_UPLOADING = 'uploading',
    STATE_UPLOADED = 'uploaded',
    STATE_APPENDING = 'appending',
    STATE_DONE = 'done',
    STATE_FAIL = 'fail',
    STATE_PAUSED = 'paused',
    STATE_SUSPENDED = 'suspended',
    STATE_CANCEL = 'cancel',
}

export enum EConflictResolution {
    strict = 'strict',
    rewrite = 'rewrite',
    rename = 'rename',
    skip = 'skip',
}

export enum ELogLevel {
    LEVEL_IMPORTANT = 0,
    info = 2,
    fail = 1,
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    error = 0,
}

export enum ELogType {
    TYPE_INFO = 'info',
    TYPE_FAIL = 'fail',
    TYPE_ERROR = 'error',
}

export enum ELogError {
    ERROR_NO_TYPE = 'The type is not one of "info", "fail" or "error"',
    ERROR_NO_DATA = 'The data may only be an Object',
    WRONG_LEVEL = 'The level may only be 0, 1 or 2',
}

export interface IErrorFile {
    error: EFileError;
    status?: EFileStatus;
}

export enum EUploadSource {
    toolbar = 'toolbar',
    dnd = 'dnd',
    blueButton = 'blueButton',
}

export interface IDescriptorOptions {
    continueUpload?: boolean;
    albumId?: string;
    checkAllowedExtensions?: boolean;
    documentType?: EDocumentType;
    originalCloudPath?: string;
    isDrop?: boolean;
    source?: EUploadSource;
}

export interface IAddToUploadQueue {
    files: (File | FileSystemEntry | FileSystemHandle)[];
    descriptorOptions?: IDescriptorOptions;
}

export interface ISendDescriptorsToUIData {
    fileDescriptors: IInputFile[];
    descriptors: UploadingDescriptor[];
    processingId: number;
}

export interface ICancelUploading {
    fileId?: string | string[];
}

export enum ERetryErrorFileOptions {
    cancel = 'cancel',
    shouldRetry = 'shouldRetry',
    shouldRetryImmediately = 'shouldRetryImmediately',
    connectionErrorRetry = 'connectionErrorRetry',
    returnToQueue = 'returnToQueue',
    postpone = 'postpone',
}

export enum EUploadingType {
    abort = 'abort',
    error = 'error',
    load = 'load',
    progress = 'progress',
}

export enum EUploadingMakeAction {
    move = 'move',
    cancel = 'cancel',
    stopAndWaitUser = 'stopAndWaitUser',
}
