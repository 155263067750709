import React, { type RefObject, useCallback, useState } from 'react';
import { DisableSeoTooltip } from 'reactApp/ui/DisableSeoTooltip/DisableSeoTooltip';

export const useDisableSeoTooltip = (target: RefObject<HTMLElement>, itemName: string, enableTip?: boolean) => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    const handleShowTip = useCallback(() => {
        if (!enableTip) {
            return;
        }

        setShowTooltip(true);
    }, [enableTip]);

    const handleCloseTip = useCallback(() => {
        setShowTooltip(false);
    }, []);

    const tooltip = showTooltip ? <DisableSeoTooltip target={target} name={itemName} onClose={handleCloseTip} /> : null;

    return { handleShowTip, tooltip };
};
