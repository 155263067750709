import { Icon20Cancel } from '@vkontakte/icons';
import { Spacing, Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';

import { sendPromoBlockDWH } from './PromoBlock.analytics';
import styles from './PromoBlock.css';
import type { PromoBlockProps } from './PromoBlock.types';

export const PromoBlock = memo<PromoBlockProps>(
    ({ id, title, text, buttonText, backgroundColor, textColor, buttonColor, image, imageFullHeight, analyticsName, onClose, onClick }) => {
        const dispatch = useDispatch();
        const [visible, setVisible] = useState(true);
        const isCorp = useSelector(UserSelectors.isCorpUser);
        const isBiz = useSelector(UserSelectors.isBizUser);
        const isClosed = Boolean(dispatch(UserStorageActions.get(id)));
        const hideBlock = !visible || isClosed || isCorp || isBiz;

        const handleClose = () => {
            onClose?.();
            setVisible(false);
            dispatch(UserStorageActions.set(id));
            sendPromoBlockDWH('close', analyticsName, 'files');
        };

        const handleClick = () => {
            onClick();
            dispatch(UserStorageActions.set(id));
            sendPromoBlockDWH('click', analyticsName, 'files');
        };

        useEffect(() => {
            sendPromoBlockDWH('view', analyticsName, 'files');
        }, [analyticsName]);

        if (hideBlock) {
            return null;
        }

        return (
            <div
                className={classNames(styles.root, {
                    [styles[`rootBg_${backgroundColor}`]]: !!backgroundColor,
                    [styles[`rootText_${textColor}`]]: !!textColor,
                    [styles.rebranding]: isRebranding,
                })}
            >
                <div className={styles.close} onClick={handleClose}>
                    <Icon20Cancel />
                </div>
                <div className={styles.content}>
                    <Text weight="2" className={styles.title}>
                        {title}
                    </Text>
                    <Spacing size={isRebranding ? 4 : 8} />
                    <Text className={styles.text}>{text}</Text>
                    <Spacing size={12} />
                    <Button
                        sizeMode={ButtonSizeMode.small}
                        theme="vk"
                        onClick={handleClick}
                        className={classNames(styles.button, {
                            [styles[`button_${buttonColor}`]]: !!buttonColor,
                        })}
                    >
                        {buttonText}
                    </Button>
                </div>
                <img
                    src={image}
                    alt={title}
                    className={classNames(styles.image, {
                        [styles['image_fullHeight']]: imageFullHeight,
                    })}
                />
            </div>
        );
    }
);

PromoBlock.displayName = 'PromoBlock';
