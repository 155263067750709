import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { sendDwh, sendXray } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

import type { OverquotaModalProps, PlaceType } from './OverquotaModal.types';

export const renderOverquotaModal = (data: OverquotaModalProps) => openPopupHelper({ popupName: popupNames.OVERQUOTA_MODAL, data });

export enum OverquotaBannerCtrl {
    closed = 'closed',
    clear = 'clear',
    add_space = 'add_space',
    unblocked = 'unblocked',
    go_cloud = 'go_cloud',
    whyWhyWhy = 'whyWhyWhy',
}

export const sendDwhOverquota = ({
    category = ECategoryGa.splashscreen_alert,
    action,
    storage,
    control,
    place,
    name = 'overquota_splash_new',
    nameAction,
    source = 'home',
}: {
    category?: ECategoryGa.splashscreen_alert | ECategoryGa.informationAlert;
    action: 'show' | 'click';
    storage: EStorageType;
    nameAction?: 'overquota_splash_new';
    name?: 'overquota_splash_new' | 'overquota_information_alert_new' | 'overquota_splash_blocking' | 'overquota_full_blocked';
    place?: PlaceType;
    control?: OverquotaBannerCtrl;
    source?: string;
}) => {
    if (!source && storage === EStorageType.public) {
        source = 'public';
    }
    if (!source && (storage === EStorageType.documents || storage === EStorageType.alldocuments)) {
        source = 'document';
    }

    sendXray([category, name, action]);

    const nameButton = category === ECategoryGa.informationAlert ? { button: control } : { name_button: control };

    sendDwh({
        eventCategory: category,
        action,
        dwhData: {
            platform: 'web',
            place,
            source,
            name,
            nameAction,
            ...nameButton,
        },
        sendImmediately: true,
    });
};

export const sendRecoveryFlowDwh = ({
    control = 'overquota_recovery',
    action = 'show',
    storage,
}: {
    control?: 'overquota_recovery' | 'overquota_recovery_popup' | 'overquota_recovery_informer';
    action?: 'show' | 'click_buy' | 'click_clear';
    storage?: EStorageType;
}) => {
    sendDwh({
        eventCategory: control,
        action,
        dwhData: {
            platform: 'web',
            storage,
        },
        sendImmediately: true,
    });
};
