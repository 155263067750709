/* tempexp_18238_start */
import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_MOBILE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

type FamilyAfterPurchaseAB = 'a' | 'b' | 'c' | false;

const familyAfterPurchaseABFeature: FamilyAfterPurchaseAB = !IS_MOBILE_BROWSER && getFeature('family-after-purchase');

export const isFamilyAfterPurchaseCompact = familyAfterPurchaseABFeature === 'c';

export const isFamilyAfterPurchaseABEnabled = familyAfterPurchaseABFeature === 'b' || isFamilyAfterPurchaseCompact;

registerExperiment(
    'family-after-purchase',
    familyAfterPurchaseABFeature,
    [
        AnalyticEventNames.QUOTA_LANDING_SHOWN,
        AnalyticEventNames.TARIFF_BUY,
        AnalyticEventNames.FAMILY_AFTER_PURCHASE_MODAL_SHOW,
        AnalyticEventNames.FAMILY_AFTER_PURCHASE_MODAL_CLICK,
        AnalyticEventNames.FAMILY_AFTER_PURCHASE_MODAL_CANCEL,
        AnalyticEventNames.FAMILY_AFTER_PURCHASE_MODAL_CLOSE,
        AnalyticEventNames.FAMILY_INVITE_LINK_SHOW,
        AnalyticEventNames.FAMILY_INVITE_LINK_CLICK,
        AnalyticEventNames.FAMILY_INVITE_LINK_CLOSE,
    ],
    {
        mapEventNames: {
            FAMILY_AFTER_PURCHASE_MODAL_SHOW: 'modal-show',
            FAMILY_AFTER_PURCHASE_MODAL_CLICK: 'modal-click',
            FAMILY_AFTER_PURCHASE_MODAL_CANCEL: 'modal-cancel',
            FAMILY_AFTER_PURCHASE_MODAL_CLOSE: 'modal-close',
            FAMILY_INVITE_LINK_SHOW: 'link-show',
            FAMILY_INVITE_LINK_CLICK: 'link-click',
            FAMILY_INVITE_LINK_CLOSE: 'link-close',
        },
    }
);

/* tempexp_18238_end */
