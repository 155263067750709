/* eslint-disable complexity */
import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { isDomainFolder, isReadOnly } from 'reactApp/appHelpers/appHelpers';
import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { datalistInlineRenameEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { shouldPublishButtonsOnAttachList } from 'reactApp/appHelpers/featuresHelpers/features/publishButtonsOnAttachList';
import { seoEnable } from 'reactApp/appHelpers/featuresHelpers/features/seoEnable';
import { openAttachLetter } from 'reactApp/modules/attaches/attaches.helpers';
import { getStorageConfig } from 'reactApp/modules/storage/storage.config';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { cancelDescriptor } from 'reactApp/modules/uploading/uploading.module';
import { EFileError, EFileStatus } from 'reactApp/modules/uploadList/uploadList.model';
import { updateUploadFilesAction } from 'reactApp/modules/uploadList/uploadList.module';
import type { DataListItemThumbProps } from 'reactApp/ui/DataListItemThumb/DataListItemThumb.types';

import { useItemAuthor } from './useItemAuthors';

// eslint-disable-next-line max-lines-per-function
export const useThumbProps = ({
    item,
    uploadingItem,
    isSelected,
    isActive,
    isSelecting,
    handleOnCheckboxClick: onCheckboxClick,
    showCheckbox,
    thumb,
    tipData,
    id,
    onDownload,
    view,
    onFavorites,
    onPublish,
    favoritesAvailable,
    isVirus,
    isPublicDownloaded,
    isPopup,
    query,
    listOfBannedToFavoriteItems,
}): DataListItemThumbProps | undefined => {
    const dispatch = useDispatch();
    const author = useItemAuthor(item);
    const storageConfig = getStorageConfig(item.storage);
    const isFavoritesAvailable = storageConfig.favorites;

    // TODO: isReadOnly - Здесь для каждого элемента даталиста ищутся бредкрампы, много перерендеров!
    const isRenameAvailable =
        datalistInlineRenameEnabled && storageConfig.rename && !(item.isFolder && isReadOnly(item)) && !isDomainFolder(item);

    const parentItemRef = useRef<HTMLDivElement>(null);

    const onCancelUpload = useCallback(() => {
        if (!uploadingItem.descriptorId && !uploadingItem.cloudPath) {
            return;
        }

        if (uploadingItem?.files?.length || uploadingItem?.extension === 'folder') {
            dispatch(cancelDescriptor(uploadingItem.localPath));
        }

        dispatch(
            updateUploadFilesAction({
                cloudPath: uploadingItem.cloudPath,
                descriptorId: uploadingItem.descriptorId,
                status: EFileStatus.CANCEL,
                error: EFileError.CANCELLED_FILE,
                hideError: true,
            })
        );
    }, [dispatch, uploadingItem]);

    const handleOpenAttachLetter = useCallback(
        () => openAttachLetter({ message_id: item.message_id, folderId: item.folderId }),
        [item.folderId, item.message_id]
    );

    if (view !== 'thumbs' || !item) {
        return;
    }

    const basicThumbProps = {
        isSelected,
        isActive,
        isSelecting,
        onCheckboxClick,
        name: item.isFolder ? item.name : item.nameWithoutExt,
        forceCheckbox: Boolean(showCheckbox),
        onDownload,
        kind: item.storage === EStorageType.attaches ? 'file' : item.kind,
        thumbUrl: thumb,
        tipData,
        id,
        isPublicDownloaded,
        isPopup,
        /** tempexp_14729-next-line */
        mtime: item.mtime,
        isPublic: !!item.weblink,
        parentItemRef,
        isSeoEnabled: seoEnable && item.weblinkSeoEnabled,
    };

    // eslint-disable-next-line sonarjs/max-switch-cases
    switch (item.storage) {
        case EStorageType.home: {
            return {
                ...basicThumbProps,
                size: item.size,
                ext: item.isFolder ? undefined : item.ext,
                isInFavorites: item.isInFavorites,
                isAutoDelete: item?.weblinkAutoDelete,
                publicExpires: item?.weblinkExpires,
                isFolder: item.isFolder,
                onFavorites,
                onPublish,
                favoritesAvailable,
                isVirus,
                storage: item.storage,
                onCancelUpload,
                renameAvailable: isRenameAvailable,
            };
        }
        case EStorageType.sharedLinks:
        case EStorageType.sharedAutodelete: {
            return {
                ...basicThumbProps,
                size: item.size,
                ext: item.isFolder ? undefined : item.ext,
                isAutoDelete: item?.weblinkAutoDelete,
                publicExpires: item?.weblinkExpires,
                isFolder: item.isFolder,
                onPublish,
                isVirus,
                storage: item.storage,
                renameAvailable: isRenameAvailable,
            };
        }
        case EStorageType.public: {
            return {
                ...basicThumbProps,
                size: item.size,
                ext: item.isFolder ? undefined : item.ext,
                isFolder: item.isFolder,
                isVirus,
                storage: item.storage,
                owner: author,
            };
        }
        case EStorageType.favorites: {
            return {
                ...basicThumbProps,
                size: item.size,
                ext: item.ext,
                isInFavorites: true,
                isAutoDelete: item?.weblinkAutoDelete,
                publicExpires: item?.weblinkExpires,
                favoritesAvailable: true,
                isFolder: item.isFolder,
                onFavorites,
                onPublish,
                isVirus,
                storage: item.storage,
                kind: IS_BIZ_USER && item.isFolder && item.kind === 'shared' ? 'folder' : item.kind,
            };
        }
        case EStorageType.stock: {
            return {
                ...basicThumbProps,
                size: item.isFolder ? undefined : item.size,
                ext: item.isFolder ? undefined : item.ext,
                isFolder: item.isFolder,
                isVirus,
                storage: item.storage,
            };
        }
        case EStorageType.search: {
            return {
                ...basicThumbProps,
                size: item.isFolder ? undefined : item.size,
                ext: item.isFolder ? undefined : item.ext,
                isFolder: item.isFolder,
                isVirus,
                storage: item.storage,
                isAutoDelete: item?.weblinkAutoDelete,
                publicExpires: item?.weblinkExpires,
                onPublish,
                isInFavorites: item.isInFavorites,
                favoritesAvailable,
                onFavorites,
                renameAvailable: isRenameAvailable,
                srchSrc: item.srchSrc,
                query,
                listOfBannedToFavoriteItems,
            };
        }
        case EStorageType.attaches: {
            return {
                ...basicThumbProps,
                size: item.size,
                ext: item.ext,
                isFolder: false,
                storage: item.storage,
                onPublish: shouldPublishButtonsOnAttachList ? onPublish : undefined,
                owner: {
                    name: item.authorName,
                    email: item.authorEmail,
                },
                subject: item.subject,
                openAttachLetter: handleOpenAttachLetter,
            };
        }
        case EStorageType.feed: {
            return {
                ...basicThumbProps,
                size: item.size,
                ext: item.ext,
                isFolder: false,
                isVirus,
                storage: item.storage,
                onPublish,
            };
        }
        case EStorageType.gallery:
        case EStorageType.alldocuments:
        case EStorageType.documents: {
            return {
                ...basicThumbProps,
                size: item.size,
                ext: item.ext,
                isFolder: false,
                isVirus,
                favoritesAvailable: isFavoritesAvailable && !item.isMounted,
                storage: item.storage,
                isInFavorites: item.isInFavorites,
                onPublish,
                onFavorites,
                renameAvailable: isRenameAvailable,
            };
        }
        case EStorageType.albums: {
            return {
                ...basicThumbProps,
                size: item.size,
                ext: item.ext,
                isFolder: false,
                isVirus,
                storage: item.storage,
                isInFavorites: item.isInFavorites,
                onFavorites,
                favoritesAvailable: isFavoritesAvailable,
                renameAvailable: false,
            };
        }
        case EStorageType.quotaCleaner: {
            return {
                ...basicThumbProps,
                size: item.size,
                ext: item.ext,
                isFolder: item.isFolder,
                isVirus,
                storage: item.storage,
                favoritesAvailable: false,
            };
        }
        case EStorageType.incomingPublic: {
            return {
                ...basicThumbProps,
                isFolder: item.isFolder,
                storage: item.storage,
                ext: item.ext,
            };
        }
    }
};
