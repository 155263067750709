import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { Spacing, Text } from '@vkontakte/vkui';
import bonus20GbImg from 'img/quota-landing/upsale-bonus/upsale-bonus-20.png';
import bonus50GbImg from 'img/quota-landing/upsale-bonus/upsale-bonus-50.png';
import bonus100GbImg from 'img/quota-landing/upsale-bonus/upsale-bonus-100.png';
import bonusMonthImg from 'img/quota-landing/upsale-bonus/upsale-discount.png';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { upsaleBonus } from 'reactApp/appHelpers/featuresHelpers/features/upsaleBonus';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { openTariffBuy } from 'reactApp/modules/payment/payment.module';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { getQuotaBillingSource, sendQuotaGa } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import type { RootState } from 'reactApp/store';
import { formatPrice } from 'reactApp/utils/priceHelpers';

import styles from './UpsaleBonusDialog.css';
import type { UpsaleBonusDialogProps } from './UpsaleBonusDialog.types';

export const UpsaleBonusDialog = memo<UpsaleBonusDialogProps>(({ bonusProductId, monthProduct, onClose }) => {
    const dispatch = useDispatch();
    const bonusProduct = useSelector((state: RootState) => ProductsSelectors.getProductById(state, bonusProductId));
    const total = useSelector(UserQuotaSelectors.getTotal);

    const bonusSpace = (bonusProduct?.space.original || 0) - (monthProduct?.space.original || 0);
    const bonusSpaceGB = bytesToNDigits(bonusSpace, 3).space;
    const enocomy =
        (monthProduct?.discountPrice || monthProduct?.price || 0) * 12 - (bonusProduct?.discountPrice || bonusProduct?.price || 0);
    const abVariant = upsaleBonus?.variant;

    const promoTitle =
        abVariant === 'b' ? `Ещё ${bytesToNDigits(bonusSpace, 3).value} в Облаке — в годовом тарифе` : 'Месяц бесплатно — в годовом тарифе';

    let bonusImg;

    if (bonusSpaceGB === 20) {
        bonusImg = bonus20GbImg;
    } else if (bonusSpaceGB === 50) {
        bonusImg = bonus50GbImg;
    } else {
        bonusImg = bonus100GbImg;
    }

    const gaName = abVariant === 'c' ? ' month_present' : `${bonusSpaceGB}gb_present`;

    useEffect(() => {
        sendQuotaGa({ action: 'modal_year_offer', label: 'show', name: gaName, tariff: monthProduct?.id });
        emitAnalyticEvent(AnalyticEventNames.UPSALE_BONUS_SHOW);
    }, []);

    const content = useMemo(() => {
        const text =
            abVariant === 'b' ? (
                <>
                    Платить сразу за целый год — выгоднее: вы сэкономите
                    <br />
                    {formatPrice(enocomy, 'ru')} ₽, а мы добавим ещё {bytesToNDigits(bonusSpace, 3).value} к выбранному объёму
                </>
            ) : (
                <>
                    Получите месяц подписки Mail Space в подарок —<br />и сэкономьте больше {formatPrice(enocomy, 'ru')} ₽ при годовой
                    оплате
                </>
            );

        return (
            <div>
                <Spacing size={12} />
                <Text className={styles.text}>{text}</Text>
                <Spacing size={24} />
            </div>
        );
    }, [bonusSpace, enocomy, abVariant]);

    const handleBuyBonusTariff = useCallback(() => {
        onClose?.();
        sendQuotaGa({ action: 'modal_year_offer', label: 'click', name: gaName, tariff: monthProduct?.id });
        if (bonusProduct && monthProduct && total) {
            const title =
                abVariant === 'b'
                    ? `Увеличение объёма до ${bytesToNDigits(monthProduct?.space.original + total?.original, 3).value}`
                    : `Mail Space ${bytesToNDigits(monthProduct?.space.original, 3).value}, переход на годовой тариф`;
            const subTitle =
                abVariant === 'b' ? `+${bytesToNDigits(bonusSpace, 3).value} дополнительного места в Облаке` : '+ месяц подписки в подарок';
            const paymentDescription = `Экономия - ${formatPrice(enocomy, 'ru').replace(/\s/g, ' ')} ₽ в год`;

            dispatch(
                openTariffBuy({
                    productId: bonusProduct?.id,
                    source: getQuotaBillingSource(),
                    isMobile: false,
                    title,
                    subTitle,
                    paymentDescription,
                    paySource: 'modal_year_offer',
                })
            );
        }
    }, [bonusProduct?.id, monthProduct?.space.original, dispatch, total?.original, bonusSpace, enocomy, onClose, abVariant, gaName]);

    const handleBuyMonthTariff = useCallback(() => {
        onClose?.();
        sendQuotaGa({ action: 'modal_year_offer', label: 'reject', name: gaName, tariff: monthProduct?.id });
        dispatch(
            openTariffBuy({
                productId: monthProduct?.id,
                source: getQuotaBillingSource(),
                isMobile: false,
                paySource: 'modal_year_offer',
            })
        );
    }, [dispatch, monthProduct?.id, onClose, gaName]);

    const handleClose = useCallback(() => {
        onClose?.();
        sendQuotaGa({ action: 'modal_year_offer', label: 'close', name: gaName, tariff: monthProduct?.id });
        dispatch(
            openTariffBuy({
                productId: monthProduct?.id,
                source: getQuotaBillingSource(),
                isMobile: false,
                paySource: 'modal_year_offer',
            })
        );
    }, [dispatch, monthProduct?.id, onClose]);

    return (
        <WhatsNewDialog
            title={promoTitle}
            content={content}
            imageUrl={abVariant === 'c' ? bonusMonthImg : bonusImg}
            primaryButtonText="Хочу годовой тариф"
            onClick={handleBuyBonusTariff}
            secondaryButtonText={abVariant === 'b' ? 'Платить за месяц' : 'Выбрать на месяц'}
            onSecondary={handleBuyMonthTariff}
            onClose={handleClose}
            qaId="upsale-bonus-modal"
            imageBgColor="var(--vkui--color_background_secondary)"
            buttonTheme="vk"
            dialogSize="tiny"
        />
    );
});

UpsaleBonusDialog.displayName = 'UpsaleBonusDialog';
