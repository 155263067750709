import { bytesToNDigits } from '@mail/cross-sizes-utils';
import type { IProduct } from 'reactApp/modules/products/products.types';
import type { Product } from 'reactApp/types/Billing';
import type { ACTIONS_DEFS } from 'reactApp/ui/TariffSectionNew/TariffSection.constants';
import { getDiscount } from 'reactApp/utils/getDiscount';
import { getMonthes, isMonth3Period, isMonthPeriod, isYearPeriod } from 'reactApp/utils/Period';

import { TABS_MAP } from './MobileSubscriptionsPage.constants';
import type { ITariffsMobile, TabValue } from './MobileSubscriptionsPage.types';

export const getActiveTab = (hash: string) => {
    const tabs = Object.fromEntries(Object.entries(TABS_MAP).map(([k, v]) => [v, k as TabValue]));
    return tabs[hash] || tabs[TABS_MAP.special];
};

export const getAvailableHash = (hash: string, marketingPromo?: (typeof ACTIONS_DEFS)['marketingPromo']) => {
    if (hash) {
        return hash;
    }

    return marketingPromo ? TABS_MAP.special : TABS_MAP.usual;
};

export const normalizeMobileTariffList = (
    productsBySpace: Record<number, [Product, Product | undefined] | [Product, Product | undefined, Product | undefined]>,
    isMarketingPromo?: boolean
): ITariffsMobile[] => {
    return Object.entries(productsBySpace).map(([bytes, products]) => {
        const space = bytesToNDigits(Number(bytes), 3);
        const quota = space.space;
        const countSnapshotsBySpace = Math.round(Number(bytes) / (3.5 * 1024 * 1024));
        const monthProduct = products.find((p) => p?.period && isMonthPeriod(p.period));
        const yearProduct = products.find((p) => p?.period && isYearPeriod(p.period));
        const threeMonthProduct = products.find((p) => p?.period && isMonth3Period(p.period));
        const hasOldPriceYear = Boolean(yearProduct?.discountPrice);
        let saving;
        if (monthProduct && yearProduct && yearProduct?.discountPrice && isMarketingPromo) {
            const yearPriceByMonth = getMonthes(yearProduct.period) * monthProduct.price;
            const { discount } = getDiscount({
                discountPrice: yearProduct.discountPrice,
                price: yearPriceByMonth,
            });

            saving = discount;
        } else if (yearProduct && yearProduct?.discountPrice) {
            saving = Math.round(((yearProduct?.price - yearProduct.discountPrice) / yearProduct?.price) * 100);
        } else if (yearProduct && monthProduct) {
            saving = Math.round((1 - yearProduct.price / (getMonthes(yearProduct.period) * monthProduct.price)) * 100);
        }

        const updatedProducts = [
            monthProduct,
            {
                saving,
                primary: true,
                ...yearProduct,
            },
        ];

        if (threeMonthProduct) {
            updatedProducts.unshift(threeMonthProduct);
        }

        return {
            space,
            oldPrice: yearProduct?.price,
            hasOldPriceYear,
            fullPrice: yearProduct?.price,
            countSnapshotsBySpace,
            tariffTypeLabel: isMarketingPromo && quota === 256 ? 'Выгодно' : '',
            showPriceByMonth: isMarketingPromo,
            isBase: products.some((p) => p?.isBase),
            products: (updatedProducts as IProduct[]).filter(Boolean),
        };
    });
};
