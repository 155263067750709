import { bytesToNDigits } from '@mail/cross-sizes-utils';
import sha1 from 'js-sha1';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_BLOCKED, IS_MY_TEAM, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { useActionsConfig } from 'reactApp/hooks/useActionsConfig';
import { useWindowSize } from 'reactApp/hooks/useWindowSize';
import { openAttachLetter } from 'reactApp/modules/attaches/attaches.helpers';
import { AttachesSelectors } from 'reactApp/modules/attaches/attaches.selectors';
import { EAttachTypes } from 'reactApp/modules/attaches/attaches.types';
import { getWeblinkFromPublicId } from 'reactApp/modules/file/utils';
import { resetSelect, selectOne } from 'reactApp/modules/selections/selections.actions';
import { changeStorageHistory, getStorage } from 'reactApp/modules/storage/storage.helpers';
import { getStorageItemById } from 'reactApp/modules/storage/storage.selectors';
import { EActions, EStorageType } from 'reactApp/modules/storage/storage.types';
import type { RootState } from 'reactApp/store';
import { Avatar } from 'reactApp/ui/Avatar/Avatar';
import { formatExpires, formatFileInfoDate } from 'reactApp/utils/formatDate';
import { sendXray } from 'reactApp/utils/ga';
import { sendPaymentGa } from 'reactApp/utils/paymentGa';
import { EQueryParams } from 'server/helpers/getRequestParams';

import { fileInfoItems } from './useItems.data';
import { closeFileInfoModal, closeViewerModal, getFileDirection, getFileName, getShareAccess, getTextWidth } from './useItems.helpers';
import type { UseItemsHook } from './useItems.types';

const {
    filename,
    uploadDate,
    size: sizeInfo,
    direction: directionInfo,
    share,
    removeDate,
    originalPlace,
    letter,
    author,
    letterTitle,
} = fileInfoItems;

export const useItems: UseItemsHook = ({ id, storage, isArchiveInfo = false }) => {
    const [fullName, setFullName] = useState(false);
    const dispatch = useDispatch();
    const availableActions = useActionsConfig({ id, isMobile: true, storage });
    const isPublishEnabled = availableActions[EActions.publish];
    const { isTrashBin, isPublic, isGallery, isAttaches } = getStorage(storage);
    const {
        ext,
        mtime,
        nameWithoutExt,
        name,
        weblink,
        size,
        deletedAt,
        deletedFrom,
        home,
        isFolder,
        authorEmail,
        authorName,
        authorAvatar,
        folderId = '',
        message_id = '',
        attachType,
        subject,
        weblinkExpires,
    } = useSelector((state: RootState) => getStorageItemById(state, storage, id)) as any;
    const attachClonedItem = useSelector((state: RootState) => home && getStorageItemById(state, EStorageType.home, home));

    const path = isTrashBin ? deletedFrom : home;
    const direction = path ? path.split('/')?.filter(Boolean) : undefined;
    const directionWithoutLast = direction?.slice(0, direction.length - 1);

    const folderName = useSelector((state: RootState) => AttachesSelectors.getAttachFolderById(state, folderId))?.name;

    const isRootDir = path === ROOT_FOLDER_ID;

    const fromDeeplink = getQueryParams()[EQueryParams.fromDeeplink];
    const isSuperAppWebView = Boolean(IS_WEBVIEW) && fromDeeplink;

    const hasUploadDate = !isTrashBin && !isFolder && !!mtime;
    const hasShare = !isRootDir && !!isPublishEnabled && (!isAttaches || attachType !== EAttachTypes.cloud) && !IS_BLOCKED;
    const hasDirection = !isTrashBin && !isRootDir && direction && !isSuperAppWebView;
    const hasWeblink = Boolean(isAttaches ? attachClonedItem?.weblink : weblink);

    const onSelect = useCallback(() => {
        const selectedIdx = isTrashBin ? path : `/${directionWithoutLast?.join('/')}`;
        changeStorageHistory({
            id: selectedIdx,
            ...(isPublic || isGallery || isTrashBin ? { navigateToStorage: EStorageType.home } : { storage }),
        });
        closeFileInfoModal();
        closeViewerModal();
        sendPaymentGa({
            eventCategory: 'viewer',
            action: 'view_in_folder',
            source: storage,
            id_media: sha1(id),
            id_public: isPublic ? getWeblinkFromPublicId(id) : 'None',
        });
    }, [directionWithoutLast, isGallery, isPublic, isTrashBin, path, storage]);

    const onOpenLetter = useCallback(() => {
        if (attachType === EAttachTypes.temporary) {
            return;
        }

        sendXray(['fv', 'attachinfo', 'click'], null, { sendImmediately: true });

        openAttachLetter({ folderId, message_id });
    }, [folderId, message_id, attachType]);

    const onShare = useCallback(() => {
        dispatch(selectOne({ selectedIdx: id, storage }));
        toolbarActions.publish('mobile-file-info', id, storage);
        closeFileInfoModal();
        dispatch(resetSelect());
    }, [dispatch, id, storage]);

    const { windowWidth } = useWindowSize();

    const isNameToggleable = useMemo(() => {
        const width = getTextWidth(name, '16px "VKSansDisplay","Arial","sans-serif"');
        return !width || width >= windowWidth - 108;
    }, [name, windowWidth]);

    const toggleFullName = useCallback(() => {
        if (!isNameToggleable) {
            return;
        }
        setFullName((value) => !value);
    }, [isNameToggleable]);

    const nameRightIcon = useMemo(() => {
        if (!isNameToggleable) {
            return;
        }

        return fullName ? 'up' : 'down';
    }, [isNameToggleable, fullName]);
    const nameValue = useMemo(() => {
        if (!isNameToggleable) {
            return name;
        }
        return getFileName(fullName ? name : nameWithoutExt || name, fullName ? '' : ext, fullName);
    }, [isNameToggleable, fullName, name, nameWithoutExt, ext]);

    const fileInfoItemsRecord = {
        filename: {
            ...filename,
            rightIcon: nameRightIcon,
            value: nameValue,
            onClick: toggleFullName,
        },
        letterTitle: isAttaches
            ? {
                  ...letterTitle,
                  value: subject || '<Без темы>',
              }
            : null,
        author: isAttaches
            ? {
                  ...author,
                  value: authorName || authorEmail,
                  Icon: authorAvatar ? () => <Avatar size={28} email={authorEmail} name={authorName} /> : author.Icon,
              }
            : null,
        uploadDate: hasUploadDate && {
            ...uploadDate,
            value: formatFileInfoDate(mtime),
            section: isAttaches ? 'Дата получения' : uploadDate.section,
        },
        removeDate: isTrashBin && { ...removeDate, value: formatFileInfoDate(deletedAt) },
        expires: weblinkExpires && {
            Icon: uploadDate.Icon,
            id: 'expires',
            section: 'Доступ по ссылке закроется',
            value: formatExpires(weblinkExpires),
        },
        size: !IS_MY_TEAM ? size && { ...sizeInfo, value: bytesToNDigits(size, 3).value } : null,
        share: hasShare && { ...share, active: Boolean(hasWeblink), value: getShareAccess(hasWeblink), onClick: onShare },
        direction: hasDirection && { ...directionInfo, value: getFileDirection(directionWithoutLast), onClick: onSelect },
        originalPlace: isTrashBin && { ...originalPlace, value: getFileDirection(direction), onClick: onSelect },
        letter: isAttaches ? { ...letter, onClick: onOpenLetter, value: folderName } : null,
    };

    let titleName = 'о файле';
    if (isArchiveInfo) {
        titleName = 'об архиве';
    } else if (isAttaches) {
        titleName = 'о вложении';
    } else if (isFolder) {
        titleName = 'о папке';
    }

    const header = `Информация ${titleName}`;
    return {
        header,
        items: Object.values(fileInfoItemsRecord).filter(Boolean),
    };
};
