export enum ETabMenuMod {
    newCards = 'newCards',
    widget = 'widget',
    buttons = 'buttons',
    mobileSubscription = 'mobileSubscription',
    subscription = 'subscription',
    folderSharing = 'folderSharing',
}

export enum ETabMenuColor {
    white = 'white',
    darkRiver = 'darkRiver',
    secondary = 'secondary',
}

export enum ETabMenuAlign {
    left = 'left',
    right = 'right',
    center = 'center',
    between = 'between',
}

interface ITabMenu {
    isMobile?: boolean;
    bottomLine?: boolean;
    mod?: ETabMenuMod;
    color?: ETabMenuColor;
}

export interface ITabMenuProps extends ITabMenu {
    children: (JSX.Element | null)[] | JSX.Element | null;
    align?: ETabMenuAlign;
    noVerticalScroll?: boolean;
}

export interface ITabMenuItem extends ITabMenu {
    name: string;
    value: string;
    badge?: string;
    badgeHint?: string;
    active?: boolean;
    onClick?(string): void;
    changeHash?: boolean;
    disabled?: boolean;
    allowClickOnActive?: boolean;
}
