import type { ReactElement } from 'react';
import type { DemoPromoTooltipVariant } from 'reactApp/appHelpers/featuresHelpers/features/demoPromo';
import type { MobileAppPromoType } from 'reactApp/modules/promo/promoMobileApp/promoMobileApp.types';
import type { EpubPromoType } from 'reactApp/modules/promo/sagas/epub.saga';
import type { Product, Tariff } from 'reactApp/types/Billing';

export enum EPromoType {
    bindingSpherum = 'bindingSpherum',
    topBanner = 'topBanner',
    whatsNew = 'whatsNew',
    faceFilter = 'faceFilter',
    mobilePublicBanner = 'mobilePublicBanner',
    mobileSearchBanner = 'mobileSearchBanner',
    mobileSearchHistoryBanner = 'mobileSearchHistoryBanner',
    secondClick = 'secondClick',
    space = 'space',
    banner = 'banner',
    overQuota = 'overQuota',
    quota = 'quota',
    downloadApp = 'downloadApp',
    expiredDeviceSubscription = 'expiredDeviceSubscription',
    publicUpload = 'publicUpload',
    csatServey = 'csatServey',
    newFeaturesDesktop = 'newFeaturesDesktop',
    newFeaturesMobile = 'newFeaturesMobile',
    diskOAsidePromo = 'diskOAsidePromo',
    safetyAsidePromo = 'safetyAsidePromo',
    // tempexp_SRCH-70119-next-line
    searchTooltip = 'searchTooltip',
    searchFilterTooltipPromo = 'searchFilterTooltipPromo',
    defaultQuotaAsidePromo = 'defaultQuotaAsidePromo',
    autosave = 'autosave',
    epubPromo = 'epubPromo',
    suggestionsLaw = 'suggestionsLaw',
    coEditPopup = 'co-edit-share-popup',
    editorLLMButton = 'tooltip-llm-button',
    familyAsidePromo = 'familyAsidePromo',
    mobileAppPromo = 'mobileAppPromo',
    autologin = 'autologin',
    sidebarTrialPromo = 'sidebarTrialPromo',
    mailspaceTrialPromo = 'mailspaceTrialPromo',
    // tempexp_17086-next-line
    defaultTariffFork = 'defaultTariffFork',
    storiesInGalleryPromo = 'storiesInGalleryPromo',
    // tempexp_17340-next-line
    changePublicDesign = 'changePublicDesign',
    themeAsidePromo = 'themeAsidePromo',
    rebranding = 'rebranding',
    // tempexp_17532-next-line
    litresModal = 'litresModal',
    // tempexp_17439-next-line
    referralProgramPromo = 'referralProgramPromo',
    // tempexp_17846-next-line
    themedPublicPromo = 'themedPublicPromo',
    // tempexp_17821-next-line
    autumnPromo = 'autumnPromo',
    allAttaches = 'allAttaches',
    // tempexp_17905-next-line
    restoreTrashbinFiles = 'restoreTrashbinFiles',
    // tempexp_18023-next-line
    pdfWorkWithPagesPromo = 'pdfWorkWithPagesPromo',
    // tempexp_18060-next-line
    blackFriday2024 = 'blackFriday2024',
    // tempexp_18085-next-line
    usedSpacePostpaid = 'usedSpacePostpaid',
    demoPromoTooltip = 'demoPromoTooltip',
    // tempexp_18335-next-line
    gamerBundle = 'gamerBundle',
    // tempexp_18474-next-line
    cyberMonday2025 = 'cyberMonday2025',
}

export interface IBaseData {
    promoId: string;
    forceTopmost?: boolean;

    onShow();

    onClose();
}

export interface IWhatsNewData extends IBaseData {
    onClick();

    onSecondary();

    title: string;
    text: string;
    imageUrl: string;
    date?: number;
    primaryButtonText: string;
    primaryButtonHref?: string;
    primaryButtonTargetBlank?: boolean;
    secondaryButtonText?: string;
    secondaryButtonHref?: string;
    secondaryButtonTargetBlank?: boolean;
    closeAfterClick?: boolean;
    closeBySecondaryButton?: boolean;
    closeIconClassName?: string;
}

type IPromoDataWhatsNew = {
    type: EPromoType.whatsNew;
} & IWhatsNewData;

type IPromoFaceFilter = {
    type: EPromoType.faceFilter;
} & IBaseData;

type IPromoMobileSearchBanner = {
    type: EPromoType.mobileSearchBanner;
} & IBaseData;

type IPromoMobileSearchHistoryBanner = {
    type: EPromoType.mobileSearchHistoryBanner;
} & IBaseData;

type IPromoMobilePublicBanner = {
    type: EPromoType.mobilePublicBanner;
} & IBaseData;

export type IPromoSecondClick = {
    type: EPromoType.secondClick;
    onHide();
} & IBaseData;

export type ISidebarTrialPromo = {
    type: EPromoType.sidebarTrialPromo;
    onClick: () => void;
} & IBaseData;

export interface ISpacePromoConfig {
    id: string;
    icon: string;
    title: string;
    text: string;
    button: string;
    tariffId: string;
    features: string[];
}

export type IPromoBanner = {
    type: EPromoType.banner;
    config: ISpacePromoConfig;
    product: Product;
    onClick?: () => void;
} & IBaseData;

export type IPromoOverQuota = {
    type: EPromoType.overQuota;
    tariff?: Tariff;
    product?: Product;
} & IBaseData;

export type IPromoQuota = {
    type: EPromoType.quota;
    quota: number;
} & IBaseData;

export type IPromoDownloadApp = {
    type: EPromoType.downloadApp;
} & IBaseData;

export type IPromoExpiredDeviceSubscription = {
    type: EPromoType.expiredDeviceSubscription;
    product: Tariff;
} & IBaseData;

export interface IPromoSpaceData {
    id: string;
    text: string;
    img: ReactElement;
    productId: string;
}

export type IPromoSpace = {
    type: EPromoType.space;
    data: IPromoSpaceData;
    endDate: number;
} & IBaseData;

export type IPromoCSAT = {
    type: EPromoType.csatServey;
} & IBaseData;

export type IPromoPublicUpload = {
    type: EPromoType.publicUpload;
} & IBaseData;

type TSearchTooltip = {
    type: EPromoType.searchTooltip;
} & IBaseData;

type TSearchFilterTooltip = {
    type: EPromoType.searchFilterTooltipPromo;
} & IBaseData;

type TPromoNewFeatures = {
    type: EPromoType.newFeaturesDesktop;
} & IBaseData;

type TPromoNewFeaturesMobile = {
    type: EPromoType.newFeaturesMobile;
} & IBaseData;

export interface IDiskOAsidePromoModal extends IBaseData {
    type: EPromoType.diskOAsidePromo;
    onClick: () => void;
}

export interface ISafetyAsidePromoModal extends IBaseData {
    type: EPromoType.safetyAsidePromo;
    onClick: () => void;
}

export interface IBindingSpherumPromoModal extends IBaseData {
    type: EPromoType.bindingSpherum;
}

export interface IAutosaveAsidePromoModal extends IBaseData {
    type: EPromoType.autosave;
}

export type IPromoEpub = {
    type: EPromoType.epubPromo;
    tipType: EpubPromoType[];
    onMarkShown(type: EpubPromoType): void;
} & IBaseData;

export type IPromoSuggestionsLaw = {
    type: EPromoType.suggestionsLaw;
} & IBaseData;

export type IPromoCoEditPopup = {
    type: EPromoType.coEditPopup;
} & IBaseData;

export type IPromoEditorLLMButton = {
    type: EPromoType.editorLLMButton;
} & IBaseData;

export type IPromoAutologin = {
    type: EPromoType.autologin;
} & IBaseData;

export type IMailSpaceTrialPromo = {
    type: EPromoType.mailspaceTrialPromo;
    onClick: () => void;
} & IBaseData;

export interface IFamilyAsidePromoModal extends IBaseData {
    type: EPromoType.familyAsidePromo;
    onClick: () => void;
}

export type PromoMobileApp = {
    type: EPromoType.mobileAppPromo;
    tipType: MobileAppPromoType[];
    onMarkShown(type: MobileAppPromoType): void;
} & IBaseData;

// tempexp_17846-start
export interface IThemedPublicPromoModal extends IBaseData {
    type: EPromoType.themedPublicPromo;
    onClick: () => void;
}
// tempexp_17846-end

// texmpexp_17088-start
export interface IDefaultTariffFork extends IBaseData {
    type: EPromoType.defaultTariffFork;
    product: Product;
}
// texmpexp_17088-end
export interface PromoStoriesInGallery extends IBaseData {
    type: EPromoType.storiesInGalleryPromo;
}

// tempexp_17340-start
export interface IChangePublicDesign extends IBaseData {
    type: EPromoType.changePublicDesign;
}
// tempexp_17340-end

interface ThemePromoAsideModal extends IBaseData {
    type: EPromoType.themeAsidePromo;
    onClick: () => void;
}

export interface IRebranding extends IBaseData {
    type: EPromoType.rebranding;

    onDone();
}

// tempexp_17532-start
export interface LitresPromoModal extends IBaseData {
    type: EPromoType.litresModal;
    onClick: () => void;
    onCancel: () => void;
}
// tempexp_17532-end

// tempexp_17439-start
export interface ReferralProgramPromoModal extends IBaseData {
    type: EPromoType.referralProgramPromo;
    onClick: () => void;
}
// tempexp_17439-end

// tempexp_17821-start
export interface AutumnPromoModal extends IBaseData {
    type: EPromoType.autumnPromo;
    onClick: () => void;
}

// tempexp_17821-end
export interface AllAttachesTip extends IBaseData {
    type: EPromoType.allAttaches;
}

// tempexp_17905-start
export interface RestoreTrashbinFilesModal extends IBaseData {
    type: EPromoType.restoreTrashbinFiles;
    onClick: () => void;
    onSecondary: () => void;
}
// tempexp_17905-end

// tempexp_18023-start
export type IPdfWorkWithPagesPromo = {
    type: EPromoType.pdfWorkWithPagesPromo;
} & IBaseData;
// tempexp_18023-end

// tempexp_18060-start
export interface BlackFriday2024Modal extends IBaseData {
    type: EPromoType.blackFriday2024;
    onClick: () => void;
}
// tempexp_18060-end

// tempexp_18085-start
export interface UsedSpacePostpaidModal extends IBaseData {
    type: EPromoType.usedSpacePostpaid;
    onClick: () => void;
    onSecondary: () => void;
}
// tempexp_18085-end

export type DemoPromoTooltipData = IBaseData & {
    type: EPromoType.demoPromoTooltip;
    variant: DemoPromoTooltipVariant;
};

// tempexp_18335-start
export interface GamerBundleModal extends IBaseData {
    type: EPromoType.gamerBundle;
    onClick: () => void;
}
// tempexp_18335-end
// tempexp_18474-start
export interface CyberMonday2025Modal extends IBaseData {
    type: EPromoType.cyberMonday2025;
    onClick: () => void;
}
// tempexp_18474-end

export type IPromoData =
    | IPromoDataWhatsNew
    | IPromoFaceFilter
    | IPromoMobileSearchBanner
    | IPromoMobileSearchHistoryBanner
    | IPromoMobilePublicBanner
    | IPromoSecondClick
    | IPromoBanner
    | IPromoOverQuota
    | IPromoQuota
    | IPromoDownloadApp
    | IPromoExpiredDeviceSubscription
    | IPromoSpace
    | IPromoCSAT
    | IPromoPublicUpload
    | TPromoNewFeatures
    | TPromoNewFeaturesMobile
    | TSearchTooltip
    | TSearchFilterTooltip
    | IDiskOAsidePromoModal
    | ISafetyAsidePromoModal
    | IAutosaveAsidePromoModal
    | IPromoEpub
    | IFamilyAsidePromoModal
    | IBindingSpherumPromoModal
    | IPromoSuggestionsLaw
    | IPromoCoEditPopup
    | IPromoEditorLLMButton
    | PromoMobileApp
    | IPromoAutologin
    | ISidebarTrialPromo
    | IMailSpaceTrialPromo
    // texmpexp_17088-next-line
    | IDefaultTariffFork
    | PromoStoriesInGallery
    // tempexp_17340-next-line
    | IChangePublicDesign
    | ThemePromoAsideModal
    | IRebranding
    // tempexp_17532-next-line
    | LitresPromoModal
    // tempexp_17439-next-line
    | ReferralProgramPromoModal
    // tempexp_17846-next-line
    | IThemedPublicPromoModal
    // tempexp_17821-next-line
    | AutumnPromoModal
    | AllAttachesTip
    // tempexp_17905-next-line
    | RestoreTrashbinFilesModal
    // tempexp_18023-next-line
    | IPdfWorkWithPagesPromo
    // tempexp_18060-next-line
    | BlackFriday2024Modal
    // tempexp_18085-next-line
    | UsedSpacePostpaidModal
    | DemoPromoTooltipData
    | GamerBundleModal
    | CyberMonday2025Modal;

export type IGenericPromoData<T extends EPromoType> = {
    type: T;
} & IPromoData;

export function isNeedsPromo<T extends EPromoType>(promoType: T, promo?: IPromoData): promo is IGenericPromoData<T> {
    return promo?.type === promoType;
}

export interface IPromoState {
    queue: IPromoData[];
    currentPromo?: IPromoData;
    lastPromoShownTimestamp?: Date;
}
