import { Icon20PictureOnSquareOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import createPortfolioImg from 'img/createPortfolioTooltip.png';
import React, { type FC, useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { createPortfolioInFolder } from 'reactApp/appHelpers/featuresHelpers/features/createPortfolioInFolder';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { getCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';

import { FloatingTooltip } from '../FloatingTooltip/FloatingTooltip';
import { ETooltipAppearance, ETooltipPlacement } from '../FloatingTooltip/FloatingTooltip.types';
import { renderThanksModal } from '../ThanksModal/ThanksModal.helpers';
import styles from './CreatePortfolioButton.css';

const CREATE_PORTFOLIO_TOOLTIP_LOCAL_STORAGE_KEY = 'create-portfolio-tooltip';
const CREATE_PORTFOLIO_BUTTON_LOCAL_STORAGE_KEY = 'create-portfolio-button';

interface Props {
    className?: string;
}

export const CreatePortfolioButton: FC<Props> = ({ className }) => {
    const [hidden, setHidden] = useState(storeHelper.getValue(CREATE_PORTFOLIO_BUTTON_LOCAL_STORAGE_KEY));
    const [closedTooltip, setClosedTooltip] = useState(storeHelper.getValue(CREATE_PORTFOLIO_TOOLTIP_LOCAL_STORAGE_KEY));

    const currentFolder = useSelector(getCurrentFolder);

    const ref = useRef<HTMLButtonElement>(null);

    const isDarkTheme = isDarkThemeModeEnabled();

    const handleCloseTooltip = useCallback(() => {
        setClosedTooltip(true);
        storeHelper.setValue(CREATE_PORTFOLIO_TOOLTIP_LOCAL_STORAGE_KEY, true);
    }, []);

    const buttonText = createPortfolioInFolder === 'b' ? 'Собрать портфолио' : 'Доска для вдохновения';
    const tooltipText =
        createPortfolioInFolder === 'b'
            ? 'Создайте портфолио и вдохновляющие подборки прямо на VK Доске'
            : 'Собери доску с идеями: фото, вдохновляющие картинки, примеры того, что нравится в VK Доске';

    return currentFolder?.childs && currentFolder.childs.length > 0 ? (
        <>
            {!hidden && (
                <button
                    className={classNames(styles.button, className)}
                    type="button"
                    onClick={() => {
                        sendDwh({
                            eventCategory: ECategoryGa.createPortfolio,
                            action: 'click-create-portfolio',
                            dwhData: {
                                folder_id: currentFolder.id,
                            },
                        });

                        setHidden(true);
                        storeHelper.setValue(CREATE_PORTFOLIO_BUTTON_LOCAL_STORAGE_KEY, true);

                        handleCloseTooltip();

                        renderThanksModal();
                    }}
                    ref={ref}
                >
                    {buttonText}
                    <Icon20PictureOnSquareOutline height={16} width={16} />
                </button>
            )}
            {!closedTooltip && (
                <FloatingTooltip
                    target={ref}
                    appearance={isDarkTheme ? undefined : ETooltipAppearance.blue}
                    placement={ETooltipPlacement.bottom}
                    qaId="tooltip-create-portfolio"
                    className={styles.tooltip}
                    arrowClassName={styles.tooltipArrow}
                    contentClassName={styles.contentTooltip}
                    closeClassName={styles.tooltipClose}
                    onClose={handleCloseTooltip}
                    closeOnOutsideClick={false}
                >
                    <div className={styles.tooltipRoot}>
                        <div className={styles.tooltipText}>{tooltipText}</div>
                        <img className={styles.tooltipImg} src={createPortfolioImg} />
                    </div>
                </FloatingTooltip>
            )}
        </>
    ) : null;
};
