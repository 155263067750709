import React from 'react';
import { IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { abHeavyFileBuy } from 'reactApp/appHelpers/featuresHelpers/features/heavyFileBuy';
import { isPurchaseDisabled } from 'reactApp/appHelpers/featuresHelpers/features/superAppAuthorizationInfo';
import { uploadBigFileModalAb } from 'reactApp/appHelpers/featuresHelpers/features/uploadBigFileModal';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { EQueryParams } from 'server/helpers/getRequestParams';

import styles from '../UploadBigFileDialog.css';
import { type IBigFileModalContent, EShowTariffType } from '../UploadBigFileDialog.types';

export const useButtons = ({ tariffInfo, numberOfFiles, files, callbacks }: IBigFileModalContent): JSX.Element => {
    const { showTariffType, usualProduct } = tariffInfo;
    const isPhone = EnvironmentSelectors.isPhone();

    const fromDeeplink = getQueryParams()[EQueryParams.fromDeeplink];
    const isSuperAppWebView = IS_WEBVIEW && fromDeeplink;
    const isDisabledPurchase = isSuperAppWebView && isPurchaseDisabled();

    let buttonPrimaryText = 'Посмотреть тарифы';
    let buttonSecondaryText = 'Отменить загрузку';

    if (numberOfFiles > 1) {
        buttonSecondaryText = files ? 'Пропустить файлы' : 'Пропустить файл';
    }

    if (showTariffType === EShowTariffType.TRIAL) {
        buttonPrimaryText = 'Попробовать за 1 ₽';
    }

    // tempexp_18088-start
    if (abHeavyFileBuy) {
        // TODO: хардкодим цену для аб. Позже надо будет брать из продукта
        buttonPrimaryText = `Загрузить за 9 ₽`;
        buttonSecondaryText = 'Оформить подписку';

        if (abHeavyFileBuy === 'c') {
            buttonSecondaryText = 'Оформить подписку за 1 ₽';
        }
    }
    // tempexp_18088-end

    if (uploadBigFileModalAb === 'b' && showTariffType === EShowTariffType.USUAL_TARIFF && usualProduct) {
        buttonPrimaryText = `Подключить ${usualProduct?.space?.value}`;
    }

    return (
        <div className={styles.buttonWrapper}>
            {!isDisabledPurchase && (
                <Button
                    className={styles.button}
                    // tempexp_18088-next-line
                    onClick={abHeavyFileBuy ? callbacks.onRequestHeavyFile : callbacks.onRequestPayment}
                    data-name="request-payment"
                    theme="vk"
                    primary
                    fluid={isPhone}
                    sizeMode={isPhone ? ButtonSizeMode.big : ButtonSizeMode.small}
                >
                    {buttonPrimaryText}
                </Button>
            )}
            <Button
                className={styles.button}
                // tempexp_18088-next-line
                onClick={abHeavyFileBuy ? callbacks.onRequestPayment : callbacks.onSkip}
                theme="vk"
                data-name="skip"
                fluid={isPhone}
                sizeMode={isPhone ? ButtonSizeMode.big : ButtonSizeMode.small}
            >
                {buttonSecondaryText}
            </Button>
        </div>
    );
};
