import { AnalyticEvent, defineAnalyticEvents } from 'reactApp/utils/experimentAnalytic';

import { AnalyticEventNames } from './eventNames';
import type {
    BuyDisableAdsModalShownType,
    BuyDisableAdsModalSwitchPeriodType,
    BuyDisableAdsModalTariffClickType,
    BuyFastCheckoutClickArgsType,
    BuyFastCheckoutViewArgsType,
    BuyModalTrialArgs,
    DisableButtonArgsType,
    FastCheckoutShowArgsType,
    MailPopupArgs,
    MobileTariffShowEventArgsType,
    TariffBuyEventArgsType,
    TariffClickEventArgsType,
    TariffShowEventArgsType,
} from './types';

export const { emitAnalyticEvent, registerExperiment } = defineAnalyticEvents({
    [AnalyticEventNames.QUOTA_LANDING_SHOWN]: new AnalyticEvent('landing_show'),
    [AnalyticEventNames.MOBILE_LANDING_SHOWN]: new AnalyticEvent('mob_landing_show'),
    [AnalyticEventNames.TARIFFS_SHOWN]: new AnalyticEvent('tariffs_show'),
    [AnalyticEventNames.TARIFF_SHOWN]: new AnalyticEvent<TariffShowEventArgsType>('tariff_shown'),
    [AnalyticEventNames.MOBILE_TARIFF_SHOWN]: new AnalyticEvent<MobileTariffShowEventArgsType>('m_tariff_shown'),
    [AnalyticEventNames.TARIFF_ADVANTAGES_CLICK]: new AnalyticEvent('advnt_click'),
    [AnalyticEventNames.MODAL_TARIFF_ADVANTAGES_SHOW]: new AnalyticEvent('advnt_modal_show'),
    [AnalyticEventNames.TARIFF_CLICK]: new AnalyticEvent<TariffClickEventArgsType>('tariff_click'),
    [AnalyticEventNames.FAST_CHECKOUT_SHOWN]: new AnalyticEvent<FastCheckoutShowArgsType>('checkout'),
    [AnalyticEventNames.TARIFF_BUY]: new AnalyticEvent<TariffBuyEventArgsType>('buy'),
    [AnalyticEventNames.TRIAL_TARIFF_BUY]: new AnalyticEvent<BuyModalTrialArgs>('buy-trial'),
    [AnalyticEventNames.TRIAL_TARIFF_SHOW]: new AnalyticEvent<BuyModalTrialArgs>('show-trial'),

    [AnalyticEventNames.BIG_FILE_DIALOG_SHOW]: new AnalyticEvent('big-file-show'),
    [AnalyticEventNames.BIG_FILE_DIALOG_CLICK]: new AnalyticEvent('big-file-click'),
    [AnalyticEventNames.BIG_FILE_DIALOG_PAYMENT_SHOW]: new AnalyticEvent('big-file-payment-show'),
    [AnalyticEventNames.BIG_FILE_DIALOG_PAYMENT_BUY]: new AnalyticEvent<{ tariff: 'trial' | 'tariff' }>('big-file-payment-buy'),
    [AnalyticEventNames.BIF_FILE_CLICK_BUY]: new AnalyticEvent('big-file-click-buy'),
    // tempexp_180880-next-line
    [AnalyticEventNames.BIF_FILE_CLICK_BUY_ONCE]: new AnalyticEvent('big-file-click-buy-once'),

    [AnalyticEventNames.MAIL_SPACE_TRIAL_PROMO_SHOW]: new AnalyticEvent('promo_show'),
    [AnalyticEventNames.MAIL_SPACE_TRIAL_PROMO_CLICK]: new AnalyticEvent('promo_click'),
    [AnalyticEventNames.MAIL_SPACE_TRIAL_PROMO_CLOSE]: new AnalyticEvent('promo_close'),

    [AnalyticEventNames.MAIL_POPUP_SHOW]: new AnalyticEvent<MailPopupArgs>('mail-popup-show'),
    [AnalyticEventNames.MAIL_POPUP_CLICK]: new AnalyticEvent<MailPopupArgs>('mail-popup-click'),
    [AnalyticEventNames.MAIL_POPUP_PAYMENT_SHOW]: new AnalyticEvent<MailPopupArgs>('mail-popup-payment-show'),
    [AnalyticEventNames.MAIL_POPUP_PAYMENT_SUCCESS]: new AnalyticEvent<MailPopupArgs>('mail-popup-payment-buy'),
    [AnalyticEventNames.MAIL_POPUP_PAYMENT_FAILURE]: new AnalyticEvent<MailPopupArgs>('mail-popup-payment-fail'),

    [AnalyticEventNames.VIEWER_CLOSE]: new AnalyticEvent('viewer-close'),

    [AnalyticEventNames.DISABLE_BUTTON_SHOW]: new AnalyticEvent<DisableButtonArgsType>('disable_show'),
    [AnalyticEventNames.DISABLE_BUTTON_CLICK]: new AnalyticEvent<DisableButtonArgsType>('disable_click'),
    [AnalyticEventNames.DISABLE_BUTTON_SHOW_CHECKOUT]: new AnalyticEvent<DisableButtonArgsType>('disable_checkout'),
    [AnalyticEventNames.DISABLE_BUTTON_PAYMENT]: new AnalyticEvent<DisableButtonArgsType>('disable_payment'),

    [AnalyticEventNames.SIDEBAR_TRIAL_PROMO_SHOW]: new AnalyticEvent('promo-show'),
    [AnalyticEventNames.SIDEBAR_TRIAL_PROMO_CLICK]: new AnalyticEvent('promo-click'),
    [AnalyticEventNames.SIDEBAR_TRIAL_PROMO_CLOSE]: new AnalyticEvent('promo-close'),
    [AnalyticEventNames.SIDEBAR_TRIAL_BANNER_SHOW]: new AnalyticEvent('banner-show'),
    [AnalyticEventNames.SIDEBAR_TRIAL_BANNER_CLICK]: new AnalyticEvent('banner-click'),

    [AnalyticEventNames.QUOTA_TOOLTIP_SHOW]: new AnalyticEvent('quota-tooltip_show'),
    [AnalyticEventNames.QUOTA_TOOLTIP_CLOSE]: new AnalyticEvent('quota-tooltip_close'),
    [AnalyticEventNames.QUOTA_BUTTON_SHOW]: new AnalyticEvent('quota-button_show'),
    [AnalyticEventNames.QUOTA_BUTTON_CLICK]: new AnalyticEvent('quota-button_click'),

    [AnalyticEventNames.BUY_FAST_CHECKOUT_VIEW]: new AnalyticEvent<BuyFastCheckoutViewArgsType>('buy-fastcheckout-view'),
    [AnalyticEventNames.BUY_FAST_CHECKOUT_CLICK_PAY]: new AnalyticEvent<BuyFastCheckoutClickArgsType>('buy-fastcheckout-click-pay'),
    // tempexp_17127-start
    [AnalyticEventNames.BUY_DISABLE_ADS_MODAL_SHOWN]: new AnalyticEvent<BuyDisableAdsModalShownType>('buy_disable_ads_modal_shown'),
    [AnalyticEventNames.BUY_DISABLE_ADS_MODAL_PERIOD_CHANGE]: new AnalyticEvent<BuyDisableAdsModalSwitchPeriodType>(
        'buy_disable_ads_modal_period_change'
    ),
    [AnalyticEventNames.BUY_DISABLE_ADS_MODAL_TARIFF_CLICK]: new AnalyticEvent<BuyDisableAdsModalTariffClickType>(
        'buy_disable_ads_modal_tariff_click'
    ),
    // tempexp_17127-end

    [AnalyticEventNames.DATALIST_ITEM_CLICK_PUBLISH]: new AnalyticEvent('datalist_item_click_publish'),
    [AnalyticEventNames.NICE_LINK_TOOLTIP_SHOW]: new AnalyticEvent('nice-link-tooltip_show'),
    [AnalyticEventNames.NICE_LINK_TOOLTIP_CLICK]: new AnalyticEvent('nice-link-tooltip_click'),
    [AnalyticEventNames.NICE_LINK_TOOLTIP_CLOSE]: new AnalyticEvent('nice-link-tooltip_close'),
    [AnalyticEventNames.NICE_LINK_THANKS_TOOLTIP_CLICK]: new AnalyticEvent('nice-link-thanks-tooltip_click'),
    [AnalyticEventNames.NICE_LINK_THANKS_TOOLTIP_SHOW]: new AnalyticEvent('nice-link-thanks-tooltip_show'),
    // tempexp_17086-start
    [AnalyticEventNames.WELCOME_DEFAULT_TARIFFS_SHOW]: new AnalyticEvent('welcome_show'),
    [AnalyticEventNames.WELCOME_DEFAULT_TARIFFS_CLICK_FREE]: new AnalyticEvent('click_free'),
    // tempexp_17086-end

    [AnalyticEventNames.OVERQUOTA_POPUP_SHOW]: new AnalyticEvent('overquota-show'),
    [AnalyticEventNames.OVERQUOTA_POPUP_CLICK]: new AnalyticEvent('overquota-click'),
    [AnalyticEventNames.QUOTA_BLOCK_OVER_VIEW]: new AnalyticEvent('quota-over-view'),
    [AnalyticEventNames.QUOTA_BLOCK_OVER_CLICK]: new AnalyticEvent('quota-over-click'),
    // tempexp_17125-start
    [AnalyticEventNames.BLOCK_OVERQUOTA_VIEW]: new AnalyticEvent('block-over-view'),
    [AnalyticEventNames.BLOCK_OVERQUOTA_CLICK]: new AnalyticEvent('block-over-click'),
    [AnalyticEventNames.BLOCK_OVERQUOTA_FILES_VIEW]: new AnalyticEvent('block-file-view'),
    [AnalyticEventNames.BLOCK_OVERQUOTA_FILES_CLICK]: new AnalyticEvent('block-file-click'),
    // tempexp_17125-end

    // tempexp_17324-start
    [AnalyticEventNames.MODAL_CHOOSE_TARIFF_SHOW]: new AnalyticEvent('modal-choose-show'),
    [AnalyticEventNames.MODAL_CHOOSE_TARIFF_CONTINUE]: new AnalyticEvent('modal-choose-continue'),
    // tempexp_17324-end

    // tempexp_17509-start
    [AnalyticEventNames.LITRES_PROMO_SHOW]: new AnalyticEvent('litres-promo-show'),
    [AnalyticEventNames.LITRES_PROMO_TOOLTIP_SHOW]: new AnalyticEvent('litres-promo-tooltip-show'),
    [AnalyticEventNames.LITRES_PROMO_TOOLTIP_CLICK]: new AnalyticEvent('litres-promo-tooltip-click'),
    [AnalyticEventNames.LITRES_PROMO_TOOLTIP_CLOSE]: new AnalyticEvent('litres-promo-tooltip-close'),
    [AnalyticEventNames.LITRES_PROMO_SUCCESS_SHOW]: new AnalyticEvent('litres-promo-success-show'),
    [AnalyticEventNames.LITRES_PROMO_SUCCESS_CLICK_CHECK]: new AnalyticEvent('litres-promo-success-check'),
    [AnalyticEventNames.LITRES_PROMO_SUCCESS_CLICK_LATER]: new AnalyticEvent('litres-promo-success-later'),
    [AnalyticEventNames.LITRES_PROMO_SUCCESS_CLOSE]: new AnalyticEvent('litres-promo-success-close'),
    // tempexp_17509-end

    // tempexp_17532-start
    [AnalyticEventNames.LITRES_MODAL_CLOUD_SHOW]: new AnalyticEvent('litres-modal-cloud-show'),
    [AnalyticEventNames.LITRES_MODAL_CLOUD_CLICK]: new AnalyticEvent('litres-modal-cloud-click'),
    [AnalyticEventNames.LITRES_MODAL_CLOUD_CANCEL]: new AnalyticEvent('litres-modal-cloud-cancel'),
    [AnalyticEventNames.LITRES_MODAL_CLOUD_CLOSE]: new AnalyticEvent('litres-modal-cloud-close'),
    // tempexp_17532-end

    [AnalyticEventNames.POPUP_BANNER_OFFER_SHOW]: new AnalyticEvent('popup-offer-show'),
    [AnalyticEventNames.POPUP_BANNER_OFFER_CLICK]: new AnalyticEvent('popup-offer-click'),
    [AnalyticEventNames.POPUP_BANNER_OFFER_CLOSE]: new AnalyticEvent('popup-offer-close'),
    [AnalyticEventNames.POPUP_BANNER_OFFER_BUY]: new AnalyticEvent('popup-offer-buy'),

    // tempexp_17324-start
    [AnalyticEventNames.TOOLTIP_BRAND_PAGE_SHOW]: new AnalyticEvent('tooltip-brand-page-show'),
    [AnalyticEventNames.TOOLTIP_BRAND_PAGE_CLICK]: new AnalyticEvent('tooltip-brand-page-click'),
    [AnalyticEventNames.TOOLTIP_BRAND_PAGE_CLOSE]: new AnalyticEvent('tooltip-brand-page-close'),
    [AnalyticEventNames.BREADCRUMBS_SHOW]: new AnalyticEvent('breadcrumbs-show'),
    [AnalyticEventNames.TOOLBAR_ITEM_SHOW_BRAND_PAGE]: new AnalyticEvent('toolbar-item-show-brand-page'),
    [AnalyticEventNames.TOOLBAR_ITEM_CLICK_BRAND_PAGE]: new AnalyticEvent('toolbar-item-click-brand-page'),
    [AnalyticEventNames.BREADCRUMBS_PUBLISH_CLICK]: new AnalyticEvent('breadcrumbs-publish-click'),
    [AnalyticEventNames.SHARING_BRAND_PAGE_SHOW]: new AnalyticEvent('sharing-brand-page-show'),
    [AnalyticEventNames.SHARING_BRAND_PAGE_CLICK]: new AnalyticEvent('sharing-brand-page-click'),
    [AnalyticEventNames.SHARING_TOOLTIP_BRAND_PAGE_SHOW]: new AnalyticEvent('sharing-tooltip-brand-page-show'),
    [AnalyticEventNames.SHARING_TOOLTIP_BRAND_PAGE_CLOSE]: new AnalyticEvent('sharing-tooltip-brand-page-close'),
    [AnalyticEventNames.TOOLBAR_BRAND_PAGE_SHOW]: new AnalyticEvent('toolbar-brand-page-show'),
    [AnalyticEventNames.TOOLBAR_BRAND_PAGE_CLICK]: new AnalyticEvent('toolbar-brand-page-click'),
    [AnalyticEventNames.BRAND_PAGE_VIEW]: new AnalyticEvent('brand-page-view'),
    [AnalyticEventNames.BRAND_PAGE_SAVE]: new AnalyticEvent('brand-page-save'),
    [AnalyticEventNames.BRAND_PAGE_TARIFF_PLAN_CHOOSE]: new AnalyticEvent('brand-page-tariff-plan-choose'),
    [AnalyticEventNames.BRAND_PAGE_TARIFF_CLICK]: new AnalyticEvent('brand-page-tariff-click'),
    [AnalyticEventNames.BRAND_PAGE_HIDE]: new AnalyticEvent('brand-page-hide'),
    [AnalyticEventNames.BRAND_PAGE_EXIT_SHOW]: new AnalyticEvent('brand-page-exit-shown'),
    [AnalyticEventNames.BRAND_PAGE_EXIT_CHOOSE]: new AnalyticEvent('brand-page-exit-choose'),
    [AnalyticEventNames.BRAND_PAGE_EXIT_TRUE]: new AnalyticEvent('brand-page-exit-true'),
    [AnalyticEventNames.BRAND_PUBLIC_PROMO_SHOW]: new AnalyticEvent('brand-promo-show'),
    [AnalyticEventNames.BRAND_PUBLIC_PROMO_CLICK]: new AnalyticEvent('brand-promo-click'),
    [AnalyticEventNames.BRAND_PUBLIC_PROMO_CLOSE]: new AnalyticEvent('brand-promo-close'),
    // tempexp_17324-end

    [AnalyticEventNames.WELCOME_TRIAL_BANNER_SHOW]: new AnalyticEvent('banner-show'),
    [AnalyticEventNames.WELCOME_TRIAL_BANNER_CLICK]: new AnalyticEvent('banner-click'),
    [AnalyticEventNames.WELCOME_TRIAL_BANNER_BUY]: new AnalyticEvent('banner-buy'),

    [AnalyticEventNames.PUBLIC_PASSWORD_SHOW]: new AnalyticEvent('public-pswd-show'),
    [AnalyticEventNames.PUBLIC_PASSWORD_SET]: new AnalyticEvent('public-pswd-set'),
    [AnalyticEventNames.PUBLIC_PASSWORD_SAVE]: new AnalyticEvent('public-pswd-save'),
    [AnalyticEventNames.PUBLIC_PASSWORD_PROMO_SHOW]: new AnalyticEvent('public-pswd-promo-show'),
    [AnalyticEventNames.PUBLIC_PASSWORD_PROMO_CLICK]: new AnalyticEvent('public-pswd-promo-click'),
    [AnalyticEventNames.PUBLIC_PASSWORD_GUEST_SHOW]: new AnalyticEvent('public-pswd-guest-show'),
    [AnalyticEventNames.PUBLIC_PASSWORD_GUEST_INCORRECT]: new AnalyticEvent('public-pswd-guest-error'),
    [AnalyticEventNames.PUBLIC_PASSWORD_GUEST_SUCCESS]: new AnalyticEvent('public-pswd-guest-success'),

    // tempexp_17825-start
    [AnalyticEventNames.CARD_EDIT_SIDEBAR]: new AnalyticEvent('card-edit-sidebar'),
    [AnalyticEventNames.CARD_REMOVE_SIDEBAR]: new AnalyticEvent('card-remove-sidebar'),
    [AnalyticEventNames.CARD_REMOVE_CONFIRM]: new AnalyticEvent('card-remove-confirm'),
    // tempexp_17825-end

    // tempexp_17581-start
    [AnalyticEventNames.MY_TARIFF_CANCEL_SALE_SHOW]: new AnalyticEvent('my-tariff-cancel-sale-show'),
    [AnalyticEventNames.MY_TARIFF_CANCEL_SALE_GET]: new AnalyticEvent('my-tariff-cancel-sale-get'),
    [AnalyticEventNames.MY_TARIFF_CANCEL_SALE_REJECT]: new AnalyticEvent('my-tariff-cancel-sale-reject'),
    [AnalyticEventNames.MY_TARIFF_CANCEL_SALE_CLOSE]: new AnalyticEvent('my-tariff-cancel-sale-close'),
    // tempexp_17581-endf

    [AnalyticEventNames.STATUS_202_ON_BUY_SUBSCRIPTION]: new AnalyticEvent('buy-subs-202'),
    [AnalyticEventNames.REPEAT_PURCHASE_MOBILE_SHOW]: new AnalyticEvent('repeat-purchase-show'),
    [AnalyticEventNames.REPEAT_PURCHASE_MOBILE_CANCEL]: new AnalyticEvent('repeat-purchase-cancel'),
    [AnalyticEventNames.REPEAT_PURCHASE_MOBILE_CLICK]: new AnalyticEvent('repeat-purchase-click'),
    [AnalyticEventNames.REPEAT_PURCHASE_MOBILE_CLOSE]: new AnalyticEvent('repeat-purchase-close'),

    // tempexp_17360-start
    [AnalyticEventNames.UPSALE_BONUS_SHOW]: new AnalyticEvent('upsale-bonus-show'),
    // tempexp_17360-end

    // tempexp_17990-start
    [AnalyticEventNames.QUOTA_BLOCK_SPACE_BUTTON_CLICK]: new AnalyticEvent('quota-space-btn-click'),
    [AnalyticEventNames.QUOTA_BLOCK_SPACE_BUTTON_VIEW]: new AnalyticEvent('quota-space-btn-view'),
    [AnalyticEventNames.QUOTA_BLOCK_MENU_CLICK]: new AnalyticEvent('quota-menu-btn-click'),
    // tempexp_17990-end

    // tempexp_18090-start
    [AnalyticEventNames.TARIFF_POLL_SHOW]: new AnalyticEvent('tariff-poll-show'),
    [AnalyticEventNames.TARIFF_POLL_SUCCESS_SHOW]: new AnalyticEvent('tariff-poll-success-show'),
    // tempexp_18090-end

    // tempexp_18089-start
    [AnalyticEventNames.PARTNERS_FAKEDOOR_VIEW]: new AnalyticEvent('partners-fakedoor-view'),
    [AnalyticEventNames.PARTNERS_FAKEDOOR_MODAL_VIEW]: new AnalyticEvent('partners-fakedoor-modal'),
    // tempexp_18089-end

    [AnalyticEventNames.CANCEL_SUB_TARIFF]: new AnalyticEvent('cancel-sub-tariff'),
    [AnalyticEventNames.CANCEL_SUB_CAPTCHA]: new AnalyticEvent('cancel-sub-captcha'),

    // tempexp_17359-start
    [AnalyticEventNames.CANCEL_SUB_EMPTY_FAMILY]: new AnalyticEvent('cancel-sub-fam-empty'),
    [AnalyticEventNames.CANCEL_SUB_EMPTY_FAMILY_CLICK]: new AnalyticEvent('cancel-sub-fam-empty-click'),
    [AnalyticEventNames.CANCEL_SUB_FAMILY]: new AnalyticEvent('cancel-sub-fam'),
    [AnalyticEventNames.CANCEL_SUB_FAMILY_CLICK]: new AnalyticEvent('cancel-sub-fam-click'),
    // tempexp_17359-end

    // tempexp_18238-start
    [AnalyticEventNames.FAMILY_AFTER_PURCHASE_MODAL_SHOW]: new AnalyticEvent('family-after-purchase-show'),
    [AnalyticEventNames.FAMILY_AFTER_PURCHASE_MODAL_CLICK]: new AnalyticEvent('family-after-purchase-click'),
    [AnalyticEventNames.FAMILY_AFTER_PURCHASE_MODAL_CANCEL]: new AnalyticEvent('family-after-purchase-cancel'),
    [AnalyticEventNames.FAMILY_AFTER_PURCHASE_MODAL_CLOSE]: new AnalyticEvent('family-after-purchase-close'),
    [AnalyticEventNames.FAMILY_INVITE_LINK_SHOW]: new AnalyticEvent('family-invite-link-show'),
    [AnalyticEventNames.FAMILY_INVITE_LINK_CLICK]: new AnalyticEvent('family-invite-link-click'),
    [AnalyticEventNames.FAMILY_INVITE_LINK_CLOSE]: new AnalyticEvent('family-invite-link-close'),
    // tempexp_18238-end

    [AnalyticEventNames.BEFORE_PURCHASE_MESSAGE_STATUS]: new AnalyticEvent('before-purchase-status'),
    [AnalyticEventNames.BEFORE_PURCHASE_MESSAGE_SHOW]: new AnalyticEvent('before-purchase-show'),
    [AnalyticEventNames.BEFORE_PURCHASE_MESSAGE_CLICK]: new AnalyticEvent('before-purchase-click'),
    [AnalyticEventNames.BEFORE_PURCHASE_MESSAGE_CLOSE]: new AnalyticEvent('before-purchase-close'),
});
