import { Button, Text } from '@vkontakte/vkui';
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enableDocumentsRecognition } from 'reactApp/modules/personalDocuments/personalDocuments.module';
import { getDocumentsRecognitionStatus } from 'reactApp/modules/personalDocuments/personalDocuments.selectors';
import { ERecognitionStatus } from 'reactApp/modules/personalDocuments/personalDocuments.types';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';

import styles from './RecognitionButton.css';

const BUTTON_TEXT = {
    [ERecognitionStatus.DISABLED]: 'Распознавание отключено',
    [ERecognitionStatus.PROCESSING]: 'Распознаём документы',
    [ERecognitionStatus.PROCESSED]: 'Распознавание включено',
};

const TOOLTIP_TEXT = {
    [ERecognitionStatus.DISABLED]: 'Включите — Облако определит документы в файлах и покажет тут',
    [ERecognitionStatus.PROCESSING]: 'В процессе, а пока можете добавить документ вручную',
    [ERecognitionStatus.PROCESSED]: 'Документы распознаются, если появятся в файлах — будут здесь',
};

const TOOLTIP_BUTTON = {
    [ERecognitionStatus.DISABLED]: 'Включить',
    [ERecognitionStatus.PROCESSING]: '',
    [ERecognitionStatus.PROCESSED]: 'Отключить',
};

export const RecognitionButton = () => {
    const dispatch = useDispatch();
    const recognitionStatus = useSelector(getDocumentsRecognitionStatus);

    const [closedTooltip, setClosedTooltip] = useState(true);

    const ref = useRef<HTMLDivElement>(null);

    const enableRecognition = useCallback(() => {
        setClosedTooltip(true);
        dispatch(enableDocumentsRecognition());
    }, [dispatch]);
    const disableRecognition = useCallback(() => {
        setClosedTooltip(true);
        openPopupHelper({ popupName: popupNames.NEW_RECOGNITION_POPUP });
    }, []);

    return (
        <>
            <Button
                className={styles.button}
                appearance="neutral"
                mode="tertiary"
                size="m"
                hovered={!closedTooltip}
                onClick={() => {
                    setClosedTooltip(!closedTooltip);
                }}
            >
                {BUTTON_TEXT[recognitionStatus]}
            </Button>
            <div ref={ref} />
            {!closedTooltip && (
                <FloatingTooltip
                    target={ref}
                    placement={ETooltipPlacement.bottomEnd}
                    qaId="tooltip-create-portfolio"
                    className={styles.tooltip}
                    contentClassName={styles.tooltipContent}
                    text={<Text className={styles.tooltipText}>{TOOLTIP_TEXT[recognitionStatus]}</Text>}
                    buttonText={TOOLTIP_BUTTON[recognitionStatus]}
                    hideArrow
                    buttonFitContent
                    isAlternativeButton
                    closable={false}
                    usePortal
                    onClose={() => {
                        setClosedTooltip(true);
                    }}
                    onClick={recognitionStatus === ERecognitionStatus.DISABLED ? enableRecognition : disableRecognition}
                />
            )}
        </>
    );
};
