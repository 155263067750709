import React, { memo } from 'react';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { BuyDisableAds } from 'reactApp/components/BuyDisableAds/BuyDisableAds';
import { BuyModal } from 'reactApp/components/BuyModal/BuyModal';
import { CancelAutoRenewConfirmDialog } from 'reactApp/components/CancelAutoRenewConfirmDialog/CancelAutoRenewConfirmDialog';
import { PauseSubscriptionDialog } from 'reactApp/components/PauseSubscriptionDialog/PauseSubscriptionDialog';
import { PauseSubscriptionSuccessDialog } from 'reactApp/components/PauseSubscriptionSuccessDialog/PauseSubscriptionSuccessDialog';
import { VkBuyModalWithSidebar } from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { FeaturesModal } from 'reactApp/sections/MobileQuotaTariffsLanding/FeaturesModal/FeaturesModal';
import { PayInBrowser } from 'reactApp/sections/MobileSubscriptionsPage/PayInBrowser/PayInBrowser';
import { AutouploadSuccessDialog } from 'reactApp/sections/QuotaLanding/AutouploadSuccessDialog/AutouploadSuccessDialog';
import { CancelAutorenewDialog } from 'reactApp/sections/QuotaLanding/CancelAutorenewDialog/CancelAutorenewDialog';
import { DynamicFakedoorModal } from 'reactApp/sections/QuotaLanding/DynamicFakedoor/DynamicFakedoorModal/DynamicFakedoorModal';
import { LitresSuccessDialog } from 'reactApp/sections/QuotaLanding/LitresSuccessDialog/LitresSuccessDialog';
import { PartnersFakedoorModal } from 'reactApp/sections/QuotaLanding/Partners/PartnersFakedoorModal/PartnersFakedoorModal';
import { TariffPollDialog } from 'reactApp/sections/QuotaLanding/TariffPollDialog/TariffPollDialog';
import { TariffPollSuccessDialog } from 'reactApp/sections/QuotaLanding/TariffPollSuccessDialog/TariffPollSuccessDialog';
import { UpsaleBonusDialog } from 'reactApp/sections/QuotaLanding/UpsaleBonusDialog/UpsaleBonusDialog';
import { UpsaleWithTrialDialog } from 'reactApp/sections/QuotaLanding/UpsaleWithTrialDialog/UpsaleWithTrialDialog';
import { SubscriptionModal } from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal';
import { CancelAutoRenewNoAds } from 'reactApp/ui//CancelAutoRenewNoAds/CancelAutoRenewNoAds';
import { AddCardModal } from 'reactApp/ui/AddCardModal/AddCardModal';
import { MustAttachPhoneDialog } from 'reactApp/ui/AskPhoneDialog/MustAttachPhoneDialog';
import { SuccessAttachPhoneDialog } from 'reactApp/ui/AskPhoneDialog/SuccessAttachPhoneDialog';
import { CancelAutoRenew10TbModal } from 'reactApp/ui/CancelAutoRenew10TbModal/CancelAutoRenew10TbModal';
import { CancelAutoRenewAutouploadModal } from 'reactApp/ui/CancelAutoRenewAutouploadModal/CancelAutoRenewAutouploadModal';
import { CancelAutoRenewFamilyModal } from 'reactApp/ui/CancelAutoRenewFamilyModal/CancelAutoRenewFamilyModal';
import { CancelAutoRenewTrialModal } from 'reactApp/ui/CancelAutoRenewTrialModal/CancelAutoRenewTrialModal';
import { CancelSubscriptionWithCaptchaModal } from 'reactApp/ui/CancelSubscriptionWithCaptchaModal/CancelSubscriptionWithCaptchaModal';
import { ConfirmEmailMobile } from 'reactApp/ui/ConfirmEmail/ConfirmEmailMobile/ConfirmEmailMobile';
import { ConfirmEmailDialog, ConfirmEmailScreen } from 'reactApp/ui/ConfirmEmail/renderConfirmEmail';
import { CancelAutoRenewalModal } from 'reactApp/ui/Mobile/CancelAutoRenewalModal/CancelAutoRenewalModal';
import { CancelAutoRenewTrialModalMobile } from 'reactApp/ui/Mobile/CancelAutoRenewTrialModalMobile/CancelAutoRenewTrialModalMobile';
import { DiscountsModal } from 'reactApp/ui/Mobile/DiscountsModal/DiscountsModal';
import { GiftPromocodeModal } from 'reactApp/ui/Mobile/GiftPromocodeModal/GiftPromocodeModal';
import { LeftMenu } from 'reactApp/ui/Mobile/LeftMenu/LeftMenu';
import { SplashScreen } from 'reactApp/ui/Mobile/SplashScreen/SplashScreen';
import { SubscriptionInfoModal } from 'reactApp/ui/Mobile/SubscriptionInfoModal/SubscriptionInfoModal';
import { SubscriptionsModal as SubscriptionsTouchModal } from 'reactApp/ui/Mobile/SubscriptionsModal/SubscriptionsModal';
import { VirusDialog } from 'reactApp/ui/Mobile/VirusDialog/VirusDialog';
import { NoSpaceForOverquotaUserModal } from 'reactApp/ui/NoSpaceForOverquotaUserModal/NoSpaceForOverquotaUserModal';
import { PollPopup } from 'reactApp/ui/PollPopup/PollPopup';
import { RemoveCardModal } from 'reactApp/ui/RemoveCardModal/RemoveCardModal';
import { RenewSubscriptionModal } from 'reactApp/ui/RenewSubscriptionModal/RenewSubscriptionModal';
import { RepeatPurchaseMobileModal } from 'reactApp/ui/RepeatPurchaseMobileModal/RepeatPurchaseMobileModal';
import { SpecialBanner } from 'reactApp/ui/SpecialBanner/SpecialBanner';
import { TariffBuyConnected } from 'reactApp/ui/TariffBuy/TariffBuy';
import { VirusDialogComponent } from 'reactApp/ui/VirusDialog/VirusDialog';
import { WebPushBubble } from 'reactApp/ui/WebPush/WebPushBubble';
import { ReduxPopup } from 'redux-popup';

import { BeforePurchaseModal } from '../BeforePurchaseModal/BeforePurchaseModal';
import { getPopupProps } from './Popup.helpers';

export const PopupsCommon = memo(() => (
    <>
        <ReduxPopup {...getPopupProps({ name: popupNames.VIRUS_DLG, component: VirusDialogComponent })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.WEB_PUSH_TOOLTIP, component: WebPushBubble })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.UPDATE_CARD, component: AddCardModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.CANCEL_MAIL_SUBSCRIPTION, component: CancelAutorenewDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.TARIFF_BUY, component: TariffBuyConnected })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.PAY_IN_BROWSER, component: PayInBrowser })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.VKBUY_MODAL, component: VkBuyModalWithSidebar })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.BUY_DISABLE_ADS, component: BuyDisableAds })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.CONFIRM_EMAIL_SCREEN, component: ConfirmEmailScreen })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.CONFIRM_EMAIL_DIALOG, component: ConfirmEmailDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_VIRUS_DIALOG, component: VirusDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_GIFT_PROMOCODE_DIALOG, component: GiftPromocodeModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_LEFT_MENU, component: LeftMenu })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_SPLASH_SCREEN, component: SplashScreen })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.WHATS_NEW_DIALOG, component: WhatsNewDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.CONFIRM_EMAIL_MOBILE, component: ConfirmEmailMobile })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SUBSCRIPTIONS_MOBILE, component: SubscriptionsTouchModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SUBSCRIPTION_INFO_MOBILE, component: SubscriptionInfoModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SUBSCRIPTION_CANCEL_AUTO_RENEWAL_MOBILE, component: CancelAutoRenewalModal })} />
        <ReduxPopup
            {...getPopupProps({ name: popupNames.SUBSCRIPTION_CANCEL_WITH_CAPTCHA, component: CancelSubscriptionWithCaptchaModal })}
        />
        <ReduxPopup {...getPopupProps({ name: popupNames.POLL_DIALOG, component: PollPopup })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SPECIAL_BANNER, component: SpecialBanner })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MUST_ATTACH_PHONE, component: MustAttachPhoneDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SUCCESS_ATTACH_PHONE, component: SuccessAttachPhoneDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.GENERIC_CONFRIMATION_DIALOG, component: BaseConfirmDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.REMOVE_CARD, component: RemoveCardModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SUBSCRIPTION_MODAL, component: SubscriptionModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_DISCOUNTS, component: DiscountsModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.BUY_MODAL, component: BuyModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SUBSCRIPTION_CANCEL_TRIAL, component: CancelAutoRenewTrialModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SUBSCRIPTION_CANCEL_TRIAL_MOBILE, component: CancelAutoRenewTrialModalMobile })} />

        {/* tempexp_16481-start */}
        <ReduxPopup {...getPopupProps({ name: popupNames.AUTOUPLOAD_PROMO_SUCCESS, component: AutouploadSuccessDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SUBSCRIPTION_CANCEL_AUTOUPLOAD, component: CancelAutoRenewAutouploadModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SUBSCRIPTION_CANCEL_10TB, component: CancelAutoRenew10TbModal })} />
        {/* tempexp_16481-end */}
        {/*  tempexp_16598-next-line */}
        <ReduxPopup {...getPopupProps({ name: popupNames.SUBSCRIPTION_CANCEL_NO_ADS, component: CancelAutoRenewNoAds })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.UPSALE_WITH_TRIAL, component: UpsaleWithTrialDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.PAUSE_SUBSCRIPTION, component: PauseSubscriptionDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.PAUSE_SUBSCRIPTION_SUCCESS, component: PauseSubscriptionSuccessDialog })} />
        {/* tempexp_17509-next-line */}
        <ReduxPopup {...getPopupProps({ name: popupNames.LITRES_SUCCESS_DIALOG, component: LitresSuccessDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.REPEAT_PURCHASE_MOBILE, component: RepeatPurchaseMobileModal })} />
        {/* tempexp_17360-next-line */}
        <ReduxPopup {...getPopupProps({ name: popupNames.UPSALE_BONUS_MODAL, component: UpsaleBonusDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.NO_SPACE_FOR_OVERQUOTA_USER_MODAL, component: NoSpaceForOverquotaUserModal })} />
        {/* tempexp_18090-start */}
        <ReduxPopup {...getPopupProps({ name: popupNames.TARIFF_POLL_MODAL, component: TariffPollDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.TARIFF_POLL_SUCCESS_MODAL, component: TariffPollSuccessDialog })} />
        {/* tempexp_18090-end */}
        <ReduxPopup {...getPopupProps({ name: popupNames.PROMO_QUOTA_FAKEDOOR_MODAL, component: DynamicFakedoorModal })} />
        {/* tempexp_18089-next-line */}
        <ReduxPopup {...getPopupProps({ name: popupNames.PARTNERS_FAKEDOOR_MODAL, component: PartnersFakedoorModal })} />
        {/* tempexp_17359-next-line */}
        <ReduxPopup {...getPopupProps({ name: popupNames.SUBSCRIPTION_CANCEL_FAMILY, component: CancelAutoRenewFamilyModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.CANCEL_AUTO_RENEW_CONFIRM_DIALOG, component: CancelAutoRenewConfirmDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.RENEW_SUBSCRIPTION_MODAL, component: RenewSubscriptionModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.BEFORE_PURCHASE_MODAL, component: BeforePurchaseModal })} />
        {/* tempexp_17906-next-line */}
        <ReduxPopup {...getPopupProps({ name: popupNames.TARIFF_FEATURES_LIST_MOBILE, component: FeaturesModal })} />
    </>
));

PopupsCommon.displayName = 'PopupsCommon';
