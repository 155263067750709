import { Icon20SmartphoneOutline } from '@vkontakte/icons';
import mobileIcon from 'img/albums/mobileAppDownload.png';
import mobileIconDark from 'img/albums/mobileAppDownloadDark.png';
import React, { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isDarkTheme } from 'reactApp/appHelpers/featuresHelpers';
import { getAllowedMimeTypes } from 'reactApp/modules/upload/upload.selectors';
import { useUploadInputHandlers } from 'reactApp/modules/uploading/hooks/useUploadInputHandlers';

import styles from '../EmptyFolder.css';
import { EItemType } from '../EmptyFolder.types';

export const useDownloadApp = () => {
    const inputRef = useRef<HTMLInputElement>(null);
    const allowedMimeTypes = useSelector(getAllowedMimeTypes)?.join(',');

    useUploadInputHandlers({ input: inputRef });

    const DownloadApp = useMemo(
        () => (
            <div className={styles.icon}>
                <Icon20SmartphoneOutline />
            </div>
        ),
        [allowedMimeTypes]
    );

    const qrCode = (
        <div className={styles.qrCodeWrapper}>
            <img className={styles.qrCode} src={isDarkTheme ? mobileIconDark : mobileIcon} alt="QR код" />
        </div>
    );

    return useCallback(() => {
        return {
            title: 'Мобильное приложение',
            annotation: 'С приложением доступ к вашим фото будут постоянно под рукой',
            type: EItemType.DOWNLOAD_APP,
            Icon: DownloadApp,
            additionLinks: qrCode,
        };
    }, [DownloadApp]);
};
