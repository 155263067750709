import type { PayloadAction } from '@reduxjs/toolkit';
import config from 'Cloud/config';
import escapeForHtml from 'lib/escapeForHtml';
import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { publishHelper } from 'reactApp/appHelpers/publishHelper';
import { renderNewFolderModal } from 'reactApp/components/NewFolderModal/NewFolderModal.helpers';
import type { EPromoTooltip } from 'reactApp/components/SharingWindow/Sharing.types';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getFeatureABCreateFolderNewFlow } from 'reactApp/modules/features/features.selectors';
import { changeHomeHistory } from 'reactApp/modules/home/home.actions';
import { waitForFolderBeingLoaded } from 'reactApp/modules/home/home.saga';
import { getHomeItemById } from 'reactApp/modules/home/home.selectors';
import { createFolderSuccess } from 'reactApp/modules/modifying/modifying.actions';
import { addFolderHelper } from 'reactApp/modules/modifying/modifying.helpers';
import { type ICreateFolderAction, type ICreateFolderSuccess, EModifyReason } from 'reactApp/modules/modifying/modifying.types';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { selectOne, startSelecting } from 'reactApp/modules/selections/selections.actions';
import { getCurrentSharedTab } from 'reactApp/modules/shared/shared.selectors';
import { SharedTab } from 'reactApp/modules/shared/shared.type';
import { snackbarController } from 'reactApp/modules/snackbar/snackbar.controller';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { isDomainFolder } from 'reactApp/modules/storage/folder.helpers';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { scrollToItemAction } from 'reactApp/modules/storage/storage.module';
import { getCurrentFolder, hasParentsDomainFolder } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { setShowUploaderAction } from 'reactApp/modules/uploadList/uploadList.module';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { store } from 'reactApp/store';
import { openDisabledFeaturePopupHelper } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.helpers';
import { DisabledFeature } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.types';
import { noop, noopVoid } from 'reactApp/utils/helpers';
import opener from 'reactApp/utils/opener';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { truncateTextMiddle } from 'reactApp/utils/textHelpers';
import { call, put, select } from 'redux-saga/effects';

import { createFolderErrors } from '../modifying.constants';

const MAX_NAME_LENGTH = config.get('ITEM_NAME_MAX_LENGTH');

export function createFolderRequest({
    folderName,
    parent,
    skipXhr = undefined,
    conflictMode = undefined,
    publishPromoTooltip = undefined as EPromoTooltip | undefined,
    storage,
    share,
    gaPrefix,
    createdInToolbar = false,
}): Promise<{ folderName: string }> {
    return new Promise((resolve, reject) => {
        folderName = folderName.trim();

        if (!folderName) {
            return reject(createFolderErrors.getMessage('required'));
        }

        if (folderName.length > MAX_NAME_LENGTH) {
            return reject(createFolderErrors.getMessage('name_too_long'));
        }

        addFolderHelper({
            item: folderName,
            parent,
            storage,
            skipXhr,
            conflictMode,
            reason: EModifyReason.add,
        })
            .then((item: any) => {
                store.dispatch(
                    createFolderSuccess({
                        storage,
                        share,
                        folderName: item.id,
                        gaPrefix,
                        publishPromoTooltip,
                        createdInToolbar,
                    })
                );
                resolve({ folderName: item.id, ...item });
            })
            .catch((error) => {
                reject(
                    createFolderErrors.getMessage((error?.home && error?.home.error) || error, {
                        '{FOLDER_NAME}': escapeForHtml(folderName),
                    })
                );
            });
    });
}

export function* createNewFolder(action: ICreateFolderAction) {
    const {
        parentFolderId,
        showCreateButton,
        showShareButton = true,
        storage,
        gaPrefix,
        onCreateFolder,
        onClose = noopVoid,
        createdInToolbar,
    } = action;

    const isCorpUser = yield select(UserSelectors.isCorpUser);
    const { isFull: isOverQuota } = yield select(UserSelectors.getCloudSpace);

    const currentFolder = yield select(getCurrentFolder);
    const hasParentsDmnFolder = yield select(hasParentsDomainFolder, currentFolder);
    const ignoreUserOverquota = isDomainFolder(currentFolder) || hasParentsDmnFolder || isCorpUser;

    if (isOverQuota && !ignoreUserOverquota) {
        openDisabledFeaturePopupHelper({ disabledFeature: DisabledFeature.newFolder });
        return;
    }

    yield renderNewFolderModal(
        {
            createFolder: async (folderName: string, share: boolean) => {
                const { folderName: newFolder } = await createFolderRequest({
                    folderName,
                    share,
                    parent: parentFolderId,
                    storage,
                    gaPrefix,
                    createdInToolbar,
                });

                if (newFolder) {
                    onCreateFolder?.(newFolder);
                }
            },
            showShareButton,
            showCreateButton,
        },
        onClose
    );
}

export function* handleCreateNewFolder(action: PayloadAction<ICreateFolderAction>) {
    yield createNewFolder(action.payload);
}

export function* handleCreateFolderSuccess(action: PayloadAction<ICreateFolderSuccess>) {
    const { folderName, share, storage = '', publishPromoTooltip, createdInToolbar } = action.payload;
    const currentStorage = yield select(getCurrentStorage);
    const sharedTab = yield select(getCurrentSharedTab);
    const { isHome } = getStorage(storage || currentStorage);
    // tempexp-14737-start
    const isPhone = yield select(EnvironmentSelectors.isPhone);
    const isNewFlowEnabled = yield select(getFeatureABCreateFolderNewFlow);
    // tempexp-14737-end

    yield closePopupHelper(popupNames.CREATE_FOLDER);

    const folder = yield select(getHomeItemById, folderName);

    if (!folder) {
        return;
    }

    const destination = folder.id;
    if (IS_BIZ_USER || !isNewFlowEnabled) {
        yield put(changeHomeHistory({ id: destination }));
        yield call(waitForFolderBeingLoaded, destination);
        sendPaymentGa({
            eventCategory: ECategoryGa.folder,
            action: 'open-folder',
            type_folder: folder?.parent !== '/' ? 'folder_in_folder' : 'folder',
            source: 'auto',
        });
    } else {
        if (!createdInToolbar && currentStorage !== EStorageType.sharedAutodelete) {
            store.dispatch(selectOne({ selectedIdx: folder.id, storage: storage as EStorageType }));
            if (isPhone) {
                store.dispatch(setShowUploaderAction(false));
                store.dispatch(startSelecting());
            }

            store.dispatch(scrollToItemAction(folder?.id));
        }

        if ((!share || (share && currentStorage === EStorageType.sharedAutodelete)) && folder && folder.id !== '/') {
            const snackbarItemNameMaxLength = 18;

            snackbarController.showSnackbar({
                id: 'create-folder-success-with-link',
                text: `Папка «${truncateTextMiddle(folder.name, snackbarItemNameMaxLength)}» создана`,
                closable: true,
                type: SnackbarTypes.success,
                buttonText: 'Перейти в папку',
                onButtonClick: () => {
                    store.dispatch(changeHomeHistory({ id: destination }));
                    sendPaymentGa({
                        eventCategory: ECategoryGa.folder,
                        action: 'open-folder',
                        type_folder: folder?.parent !== '/' ? 'folder_in_folder' : 'folder',
                        source: 'snackbar',
                    });
                },
            });
        }
    }

    if (share && folder && folder.id !== '/') {
        publishHelper({
            item: folder,
            itemStorage: EStorageType.home,
            autoSeo: currentStorage === EStorageType.sharedLinks && sharedTab === SharedTab.indexed,
            // tempexp-14737-next-line
            onClose: () => (isHome ? noop : opener(`/home/${encodeURIComponent(destination)}`, true)),
            withPromoTooltip: publishPromoTooltip,
            folderName: !IS_BIZ_USER && folder.name,
        });
    }
}
