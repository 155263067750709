import { extInfo } from 'lib/extInfo';
import { xray } from 'lib/xray';
import type {
    FeedApiV4ResponseListItem,
    IAllDocumentsState,
    WeblinksListApiV4ResponseListItem,
} from 'reactApp/modules/allDocuments/allDocuments.types';
import { getFeatureAllDocuments } from 'reactApp/modules/features/features.selectors';
import { UrlBuilder } from 'reactApp/modules/urlBuilder/UrlBuilder';
import { store } from 'reactApp/store';
import { normalizeMtime } from 'reactApp/utils/tree.helpers';

import type { FeedItemBase } from '../feed/feed.types';
import { getExtension, getItemNameWithoutExt, getParent, normalizeId, normalizePublicApiInfo } from '../file/utils';

const radarName = 'alldocuments';
const urlBuilder = new UrlBuilder();

export const allDocumentsHideSpaceRadar = (isHideSpace: boolean) => xray.send(`${radarName}_space_${isHideSpace ? 'hidden' : 'visible'}`);

/**
 * Технические радары страницы всех документов.
 */
export const allDocumentsRadars = ({ section, action }: { section: IAllDocumentsState['currentDocType']; action: string }) =>
    ({
        onSuccess: () => xray.send(`${radarName}_${section}_${action}_success`),
        onError: () => xray.send(`${radarName}_${section}_${action}_error`),
    } as const);

/**
 * Преобразовывает все разрешенные расширения для раздела Документы в единый массив
 */
export const getAllDocumentsAlllowedExt = <T extends { [key: string]: string[] } | string[]>(docExtensions: T): string[] => {
    return Object.values(docExtensions).flat();
};

/**
 * По расширению файла определяем возможность отображения его в разделе документов.
 */
export const getAvailableSectionInAllDocuments = (extension: string) => {
    const state = store.getState();
    const feature = getFeatureAllDocuments(state);

    for (const section in feature) {
        if (feature[section].includes(extension)) {
            return section;
        }
    }

    return null;
};

export const isAvailableInDocumentSection = (extension: string) => {
    const state = store.getState();
    const feature = getFeatureAllDocuments(state);

    return feature.document.includes(extension);
};

export const normalizeBaseFeedV4Item = (feedItem: FeedApiV4ResponseListItem) => {
    const homePath = feedItem.path;
    const fileHash = feedItem.hash || '';
    const parentFolder = homePath ? normalizeId(getParent(homePath)) : '/';
    const ext = getExtension(feedItem);
    const nameWithoutExt = getItemNameWithoutExt(feedItem.name, ext);
    const { kind, subKind } = extInfo.get(ext);

    const normalizedItem: FeedItemBase = {
        // isReadOnly false потому что это мой файл, для меня он WRITE всегда
        isReadOnly: false,
        id: homePath,
        isFolder: false,
        parent: parentFolder,
        ext,
        nameWithoutExt,
        name: feedItem.name,
        home: homePath || '',
        size: feedItem.size,
        kind,
        subKind,
        mtime: normalizeMtime(feedItem.mtime),
        virusScan: feedItem.virus_scan || 'pass',
        hash: fileHash,
        // Хардкод, его нам не передают
        uflr: undefined,
        isInFavorites: !!feedItem?.flags?.favorite,
        weblink: '',
        url: urlBuilder.getUrls({
            ext,
            isPublic: false,
            id: homePath,
            weblink: '',
            size: feedItem.size,
            kind,
            subKind,
            isStock: false,
            name: feedItem.name,
            path: homePath,
        }),
        thumbnails: urlBuilder.getThumb({
            hash: fileHash,
            ext,
            id: homePath,
            size: feedItem.size,
            kind,
            name: feedItem.name,
            path: homePath,
            isPublic: false,
            isStock: false,
            dwl_token: null,
            weblink: '',
        }),
    };

    if (feedItem.public) {
        return {
            ...normalizedItem,
            ...normalizePublicApiInfo(feedItem),
        };
    }

    return normalizedItem;
};

export const normalizeBaseWeblinksListItem = (feedItem: WeblinksListApiV4ResponseListItem) => {
    const homePath = feedItem.path;
    const fileHash = feedItem.hash || '';
    const parentFolder = homePath ? normalizeId(getParent(homePath)) : '/';
    const ext = getExtension(feedItem);
    const nameWithoutExt = getItemNameWithoutExt(feedItem.name, ext);
    const { kind, subKind } = extInfo.get(ext);

    const normalizedItem: FeedItemBase = {
        // isReadOnly false потому что это мой файл, для меня он WRITE всегда
        isReadOnly: false,
        id: homePath,
        isFolder: false,
        parent: parentFolder,
        ext,
        nameWithoutExt,
        name: feedItem.name,
        home: homePath || '',
        size: feedItem.size,
        kind,
        subKind,
        mtime: normalizeMtime(feedItem.mtime),
        // virusScan - хардкод - бек нам его не передает
        virusScan: 'pass',
        hash: fileHash,
        // Хардкод, его нам не передают
        uflr: undefined,
        isInFavorites: !!feedItem?.flags?.favorite,
        weblink: '',
        url: urlBuilder.getUrls({
            ext,
            isPublic: false,
            id: homePath,
            weblink: '',
            size: feedItem.size,
            kind,
            subKind,
            isStock: false,
            name: feedItem.name,
            path: homePath,
        }),
        thumbnails: urlBuilder.getThumb({
            hash: fileHash,
            ext,
            id: homePath,
            size: feedItem.size,
            kind,
            name: feedItem.name,
            path: homePath,
            isPublic: false,
            isStock: false,
            dwl_token: null,
            weblink: '',
        }),
    };

    if (feedItem.public) {
        return {
            ...normalizedItem,
            ...normalizePublicApiInfo(feedItem),
        };
    }

    return normalizedItem;
};
