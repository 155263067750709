/* eslint-disable max-lines */
import { createSelector } from '@reduxjs/toolkit';
import { isFuture } from 'date-fns';
import { find, memoizeWith, pathOr, propEq, uniqBy } from 'ramda';
import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { getAlbumsItemById, getCurrentAlbum, getCurrentAlbumList } from 'reactApp/modules/albums/albums.selector';
import { getAllDocumentsAlllowedExt } from 'reactApp/modules/allDocuments/allDocuments.helpers';
import { getAllDocumentsItemById, getCurrentAllDocumentsFolder } from 'reactApp/modules/allDocuments/allDocuments.selectors';
import { AttachesSelectors } from 'reactApp/modules/attaches/attaches.selectors';
import {
    getBizFilteredItems,
    getBizSortedAndFilteredItems,
    getBizSortedItemsGroupedByMount,
} from 'reactApp/modules/bizCategories/bizCategories.helpers';
import { getBizCurrentCategory } from 'reactApp/modules/bizCategories/bizCategories.selectors';
import { contactsSelectors } from 'reactApp/modules/contacts/contacts.selectors';
import { getEditorItemById, isEditorStorage } from 'reactApp/modules/editor/editor.selectors';
import { getEmbeddedItemById } from 'reactApp/modules/embedded/embedded.selectors';
import { getFilesWithFaceIdxs, getItemInSelectedFace, getSelectedFaceId, hasMoreToLoad } from 'reactApp/modules/faces/faces.selectors';
import { FavoritesSelectors } from 'reactApp/modules/favorites/favorites.selectors';
import { getFeatureAllDocuments } from 'reactApp/modules/features/features.selectors';
import { getCurrentFeedCategory, getFeedItemById } from 'reactApp/modules/feed/feed.selectors';
import { getPathParts, isMediaFolderAndGalleryEnabled as isMedia } from 'reactApp/modules/file/utils';
import { getCurrentFilter } from 'reactApp/modules/filesFilter/filesFilter.selectors';
import { getCurrentGalleryCategory, getGalleryItemById } from 'reactApp/modules/gallery/gallery.selectors';
import {
    getCurrentChildIds,
    getCurrentFolderHome,
    getDomainFoldersFilterActive,
    getHomeFolderItems,
    getHomeItemById,
    getRootFolderListById,
} from 'reactApp/modules/home/home.selectors';
import type { HomeItem, Item } from 'reactApp/modules/home/home.types';
import { getHomeList } from 'reactApp/modules/home/selector/getHomeList';
import { getIncomingIds, getIncomingItemById, getIncomingStorage } from 'reactApp/modules/incoming/incoming.selectors';
import {
    getCurrentIncomingPublicFolder,
    getIncomingPublicIds,
    getIncomingPublicItemById,
} from 'reactApp/modules/incomingPublic/incomingPublic.selectors';
import {
    getCurrentRouteDocument,
    getDocumentChilds,
    getDocumentItemById,
} from 'reactApp/modules/personalDocuments/personalDocuments.selectors';
import {
    getCurrentPublicFolder,
    getCurrentPublicFolderChilds,
    getPublicItemById,
    isOwnPublic,
    isPublicUploadEnabled,
} from 'reactApp/modules/public/public.selectors';
import type { PublicItem } from 'reactApp/modules/public/public.types';
import { getCurrentRouteId, getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getCurrentSearchFolder, getSearchItemById } from 'reactApp/modules/search/search.selectors';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { getSharedIds, getSharedItemById, getSharedStorage } from 'reactApp/modules/shared/shared.selectors';
import {
    getSharedAutoDeleteFiledIds,
    getSharedAutoDeleteItemById,
    getSharedAutoDeleteStorage,
} from 'reactApp/modules/sharedAutoDelete/sharedAutoDelete.selectors';
import { sortItems } from 'reactApp/modules/sort/sort.helpers';
import { getSort } from 'reactApp/modules/sort/sort.selectors';
import { getCurrentStockFolder, getCurrentStockIds, getStockItemById } from 'reactApp/modules/stock/stock.selectors';
import { DOMAIN_FOLDER, isDomainFolder, isMountedFolder, isSharedFolder } from 'reactApp/modules/storage/folder.helpers';
import { getStorage, isIntegrationStorage } from 'reactApp/modules/storage/storage.helpers';
import { type CloudItem, type ItemMountedShared, EStorageType } from 'reactApp/modules/storage/storage.types';
import { getStoryItemById } from 'reactApp/modules/stories/stories.selectors';
import { getBinItemById, getOpenedBin } from 'reactApp/modules/trashbin/trashbin.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import {
    getCurrentGroup,
    getCurrentGroupTotalCount,
    getCurrentGroupTotalSize,
    getItemById as getQuotaItemById,
    isYearCurrentGroupFilter,
} from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.selectors';
import { getArchiveItemById } from 'reactApp/modules/viewer/viewer.selectors';
import { isMobileGalleryViewMode } from 'reactApp/sections/MobileGalleryPage/MobileGalleryPage.helpers';
import type { RootState } from 'reactApp/store';
import type { Count } from 'reactApp/types/Tree';
import { familyCrumb, galleryCrumb, trashbinCrumb } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer.data';
import { getDividerPeriodName, getPeriodNameByDate } from 'reactApp/utils/dateDivider';
import { noopUndefined } from 'reactApp/utils/helpers';

import type { FavoriteItem } from '../favorites/favorites.types';

const emptyArray = [];

const getRootState = (state: RootState) => state;

// eslint-disable-next-line complexity
const getSelectedFunc = (storage) => {
    type GetItemByID = (state: RootState, id: string) => CloudItem | undefined;
    let selectFunc: GetItemByID = noopUndefined;

    if (storage === EStorageType.home || isIntegrationStorage(storage)) {
        selectFunc = getHomeItemById;
    } else if (storage === EStorageType.start) {
        // Для загрузчика на стартовой
        selectFunc = getHomeItemById;
    } else if (storage === EStorageType.sharedLinks) {
        selectFunc = getSharedItemById;
    } else if (storage === EStorageType.sharedAutodelete) {
        selectFunc = getSharedAutoDeleteItemById;
    } else if (storage === EStorageType.favorites) {
        selectFunc = FavoritesSelectors.getFavoriteById;
    } else if (storage === EStorageType.sharedIncoming) {
        selectFunc = getIncomingItemById;
    } else if (storage === EStorageType.public) {
        selectFunc = (state: RootState, id: string) => {
            const itemInFace = getItemInSelectedFace(state, id);

            return itemInFace ? itemInFace : getPublicItemById(state, id);
        };
    } else if (storage === EStorageType.attaches) {
        selectFunc = AttachesSelectors.getAttachById;
    } else if (storage === EStorageType.viewerAttaches) {
        selectFunc = AttachesSelectors.getViewerAttachById;
    } else if (storage === EStorageType.stock) {
        selectFunc = getStockItemById;
    } else if (storage === EStorageType.search) {
        selectFunc = getSearchItemById;
    } else if (storage === EStorageType.trashbin) {
        selectFunc = getBinItemById;
    } else if (isEditorStorage(storage)) {
        selectFunc = getEditorItemById;
    } else if (storage === EStorageType.feed) {
        selectFunc = getFeedItemById;
    } else if (storage === EStorageType.gallery) {
        selectFunc = getGalleryItemById;
    } else if (storage === EStorageType.alldocuments) {
        selectFunc = getAllDocumentsItemById;
    } else if (storage === EStorageType.documents) {
        selectFunc = getDocumentItemById;
    } else if (storage === EStorageType.story) {
        selectFunc = getStoryItemById;
    } else if (storage === EStorageType.albums) {
        selectFunc = getAlbumsItemById as GetItemByID;
    } else if (storage === EStorageType.quotaCleaner) {
        selectFunc = getQuotaItemById as any;
    } else if (storage === EStorageType.archive) {
        selectFunc = getArchiveItemById as any;
    } else if (storage === EStorageType.embedded) {
        selectFunc = getEmbeddedItemById as any;
    } else if (storage === EStorageType.pdfEdit) {
        selectFunc = getHomeItemById;
    } else if (storage === EStorageType.incomingPublic) {
        selectFunc = getIncomingPublicItemById;
    }

    return selectFunc;
};

export const getItemById = createSelector(
    getCurrentStorage,
    (state: RootState) => state,
    (state, id: string) => id,
    (storage, state, id) => getSelectedFunc(storage)(state, id)
);

export const getStorageItemById = createSelector(
    (state: RootState) => state,
    (state, storage: EStorageType | null) => storage || getCurrentStorage(state),
    (state, storage: EStorageType | null, id: string) => id,
    (state, storage, id) => getSelectedFunc(storage)(state, id)
);

export const getStorageItemByIdFromStorageOrCurrentOrHome = createSelector(
    (state: RootState) => state,
    (state, storage: EStorageType | undefined) => storage,
    (state, storage: EStorageType | undefined, id: string) => id,
    (state, targetStorage: EStorageType | undefined, id) => {
        const targetStorageItem = targetStorage && getStorageItemById(state, targetStorage, id);
        const currentStorageItem = !targetStorageItem && getItemById(state, id);
        return targetStorageItem || currentStorageItem || getStorageItemById(state, EStorageType.home, id);
    }
);

export const getStorageCurrentFolder = createSelector(
    getRootState,
    (state, storage: EStorageType): EStorageType => storage,
    (state, storage: EStorageType) => {
        if (storage === EStorageType.home || isIntegrationStorage(storage)) {
            return getCurrentFolderHome(state);
        } else if (storage === EStorageType.sharedLinks) {
            return getSharedStorage(state);
        } else if (storage === EStorageType.sharedAutodelete) {
            return getSharedAutoDeleteStorage(state);
        } else if (storage === EStorageType.favorites) {
            return FavoritesSelectors.getFavoritesStore(state);
        } else if (storage === EStorageType.sharedIncoming) {
            return getIncomingStorage(state);
        } else if (storage === EStorageType.public) {
            return getCurrentPublicFolder(state);
        } else if (storage === EStorageType.attaches) {
            return AttachesSelectors.getAttachesStore(state);
        } else if (storage === EStorageType.stock) {
            return getCurrentStockFolder(state);
        } else if (storage === EStorageType.search) {
            return getCurrentSearchFolder(state);
        } else if (storage === EStorageType.trashbin) {
            return getOpenedBin(state);
        } else if (storage === EStorageType.feed) {
            return getCurrentFeedCategory(state);
        } else if (storage === EStorageType.gallery) {
            return getCurrentGalleryCategory(state);
        } else if (storage === EStorageType.albums) {
            return getCurrentAlbum(state);
        } else if (storage === EStorageType.documents) {
            return getCurrentRouteDocument(state);
        } else if (storage === EStorageType.quotaCleaner) {
            return getCurrentGroup(state);
        } else if (storage === EStorageType.alldocuments) {
            return getCurrentAllDocumentsFolder(state);
        } else if (storage === EStorageType.incomingPublic) {
            return getCurrentIncomingPublicFolder(state);
        }
    }
);

export type StorageCurrentFolder = ReturnType<typeof getStorageCurrentFolder>;

export const getCurrentFolder = createSelector(
    getRootState,
    (state) => getCurrentStorage(state) as EStorageType,
    (state, storage: EStorageType) => getStorageCurrentFolder(state, storage)
);

export const getCurrentItem = createSelector(
    getRootState,
    (state) => getCurrentRouteId(state),
    (state, id: string) => getItemById(state, id)
);

export const isCurrentLoaded = createSelector(getCurrentFolder, (folder): boolean =>
    Boolean(!!folder && 'isLoaded' in folder && folder.isLoaded)
);

export const getIds = createSelector(
    getRootState,
    (state, storage: EStorageType | undefined) => storage || getCurrentStorage(state),
    // eslint-disable-next-line complexity
    (state, storage: EStorageType | undefined): string[] => {
        let current: string[] = emptyArray;
        if (storage === EStorageType.home || isIntegrationStorage(storage)) {
            current = getCurrentChildIds(state);
        } else if (storage === EStorageType.sharedLinks) {
            current = getSharedIds(state);
        } else if (storage === EStorageType.sharedAutodelete) {
            current = getSharedAutoDeleteFiledIds(state);
        } else if (storage === EStorageType.favorites) {
            current = FavoritesSelectors.getFavoritesList(state);
        } else if (storage === EStorageType.sharedIncoming) {
            current = getIncomingIds(state);
        } else if (storage === EStorageType.public) {
            const selectedFaceId = getSelectedFaceId(state);
            if (selectedFaceId) {
                return getFilesWithFaceIdxs(state, selectedFaceId);
            }
            current = getCurrentPublicFolderChilds(state);
        } else if (storage === EStorageType.attaches) {
            return AttachesSelectors.getAttachesList(state);
        } else if (storage === EStorageType.viewerAttaches) {
            return AttachesSelectors.getViewerAttachesIds(state);
        } else if (storage === EStorageType.stock) {
            return getCurrentStockIds(state);
        } else if (storage === EStorageType.albums) {
            return getCurrentAlbumList(state);
        } else if (storage === EStorageType.search) {
            return getStorageCurrentFolder(state, storage)?.childs || emptyArray;
        } else if (storage === EStorageType.trashbin) {
            return getStorageCurrentFolder(state, storage)?.childs || emptyArray;
        } else if (storage === EStorageType.feed || storage === EStorageType.gallery || storage === EStorageType.alldocuments) {
            return getStorageCurrentFolder(state, storage)?.childs || emptyArray;
        } else if (storage === EStorageType.documents) {
            return getDocumentChilds(state) || emptyArray;
        } else if (storage === EStorageType.quotaCleaner) {
            return getCurrentGroup(state)?.childs || emptyArray;
        } else if (storage === EStorageType.incomingPublic) {
            return getIncomingPublicIds(state);
        }

        return current;
    }
);

export const getFavoritesStorageItems = createSelector(
    getRootState,
    (state: RootState) => getIds(state, EStorageType.favorites),
    (state: RootState, ids: string[]) => ids.map((id) => FavoritesSelectors.getFavoriteById(state, id) as FavoriteItem)
);

export const getOwnHomeStorageItems = createSelector(getHomeFolderItems, (items: (HomeItem | PublicItem | undefined)[]) =>
    items.filter((item) => item?.kind !== 'mounted' && item?.kind !== DOMAIN_FOLDER)
);

export const getDomainHomeStorageItems = createSelector(getHomeFolderItems, (items: (HomeItem | PublicItem | undefined)[]) =>
    items.filter((item) => item?.kind === 'mounted' || item?.kind === DOMAIN_FOLDER)
);

const getHomeItemsCount = (items: (HomeItem | PublicItem | undefined)[]) => ({
    folders: items.filter((item) => item?.isFolder).length,
    files: items.filter((item) => !item?.isFolder).length,
    all: items.length,
    loaded: 0,
});

export const getOwnHomeCount = createSelector(getOwnHomeStorageItems, getHomeItemsCount);

export const getDomainHomeCount = createSelector(getDomainHomeStorageItems, getHomeItemsCount);

export const getCurrentCount = createSelector(
    getCurrentStorage,
    getDomainFoldersFilterActive,
    getOwnHomeCount,
    getDomainHomeCount,
    getCurrentFolder,
    (storage, showOnlyDomainFolders, ownHomeCount, domainHomeCount, folder): Count => {
        if (IS_BIZ_USER && storage === EStorageType.home) {
            return showOnlyDomainFolders ? domainHomeCount : ownHomeCount;
        }

        return (
            (folder && 'count' in folder && folder.count) || {
                folders: 0,
                files: 0,
                all: 0,
                loaded: 0,
            }
        );
    }
);

const getMappedItems = createSelector(
    (state: RootState, storage: EStorageType) => storage,
    (state: RootState, _, filterOutFolder: boolean) => filterOutFolder,
    (state: RootState, storage: EStorageType): (CloudItem | undefined)[] => {
        if ([EStorageType.home, EStorageType.start].includes(storage)) {
            // Для оптимизации перерендеров! Для других стораджей надо тоже что-то придумать
            return getHomeFolderItems(state);
        }
        return getIds(state, storage).map((id) => getStorageItemById(state, storage, id));
    },
    getCurrentStorage,
    getSort,
    getBizCurrentCategory,
    getFeatureAllDocuments,
    (storage, filterOutFolder, list, currentStorage, sort, filterCategory, allDocFeature) => {
        const isTrashBinInAllDocs = currentStorage === EStorageType.alldocuments && storage === EStorageType.trashbin;

        if (!sort?.type && !isTrashBinInAllDocs && !filterCategory) {
            return uniqBy((item) => item?.id, list).map((item) => item?.id);
        }

        const items = uniqBy((item) => item.id, list.filter((item) => !!item && (!filterOutFolder || !item.isFolder)) as CloudItem[]);

        if (isTrashBinInAllDocs) {
            const docExtensions = getAllDocumentsAlllowedExt(allDocFeature);
            return items
                .filter((item) => item && 'ext' in item && docExtensions.includes(item.ext?.toLowerCase()))
                .map((item) => (item as CloudItem).id);
        }

        return items;
    }
);

const sortIds = createSelector(
    (state: RootState, storage: EStorageType, filterOutFolder: boolean) => {
        return getMappedItems(state, storage, filterOutFolder);
    },
    (state: RootState, storage: EStorageType) => storage,
    (state: RootState, storage: EStorageType, _, isLastFiles: boolean) => isLastFiles ?? false,
    (state: RootState, storage: EStorageType) => getSort(state, getStorageCurrentFolder(state, storage)),
    getDomainFoldersFilterActive,
    getBizCurrentCategory,
    (mappedItems, storage: EStorageType, isLastFiles, sort, showOnlyDomainFolders = false, filterCategory) => {
        if (mappedItems.length > 0 && typeof mappedItems[0] === 'string') {
            return mappedItems as string[];
        }

        if (!sort?.type) {
            return mappedItems.map((item) => (item as CloudItem).id);
        }

        let sortedItems: CloudItem[] = mappedItems as CloudItem[];
        // client sort is off, sort on backend
        if (storage !== EStorageType.quotaCleaner) {
            sortedItems = sortItems(mappedItems as CloudItem[], sort) as CloudItem[];
        }
        // при клике на пункт "Общие папки" в дереве, показываем только доменные папки
        if (showOnlyDomainFolders) {
            sortedItems = sortItems(mappedItems as CloudItem[], sort).filter(
                (item) => item.kind === DOMAIN_FOLDER || item.kind === 'mounted'
            ) as CloudItem[];
        }

        // для бизовых пользователей добавлены новые табы с фильтрами по типам файлов
        if (filterCategory && !isLastFiles) {
            sortedItems = getBizSortedAndFilteredItems(sortedItems, sort, filterCategory);
        }

        if (storage === EStorageType.sharedIncoming) {
            sortedItems = getBizSortedItemsGroupedByMount(sortedItems);
        }

        return sortedItems.map((item) => (item as CloudItem).id);
    }
);

export const filterIdsByAuthors = createSelector(
    getCurrentFilter,
    (state, ids: string[] | undefined, storage: EStorageType | undefined) => storage || getCurrentStorage(state),
    (state, ids: string[] | undefined) => ids || getIds(state, undefined),
    UserSelectors.getEmail,
    isOwnPublic,
    (state: RootState) => state,
    (filter, storage, ids, userEmail, isPublicOwner, state) => {
        const { isHome, isPublic } = getStorage(storage);

        if (filter !== 'all' && (isHome || isPublic)) {
            return ids.filter((id) => {
                const item = getStorageItemById(state, storage, id) as HomeItem | PublicItem | undefined;

                if (!item || (item.storage !== EStorageType.home && item.storage !== EStorageType.public)) {
                    return false;
                }

                if (item?.isFolder) {
                    return true;
                }

                if (item.author && filter.includes(item.author)) {
                    return true;
                }

                if (isHome && userEmail && !item?.author && filter.includes(userEmail)) {
                    return true;
                }

                return !!(isPublic && isPublicOwner && userEmail && !item?.author && filter.includes(userEmail));
            });
        }

        return ids;
    }
);

export const getHomeStorageItemIds = createSelector(
    (state: RootState) => state,
    (state, storage: EStorageType) => storage,
    getCurrentStorage,
    (state, storage: EStorageType) => getIds(state, storage),
    (state, storage, currentStorage, ids) => {
        const showOnlyDomainFolders = getDomainFoldersFilterActive(state);
        const currentBizCategory = getBizCurrentCategory(state);

        if (IS_BIZ_USER && storage === EStorageType.home && currentStorage === EStorageType.home) {
            ids = ids.filter((id) => {
                const item = getStorageItemById(state, storage, id) as HomeItem | PublicItem | undefined;
                return (item?.kind === 'mounted' || item?.kind === DOMAIN_FOLDER) === Boolean(showOnlyDomainFolders);
            });
        }

        if (IS_BIZ_USER && currentBizCategory) {
            const storageItems = ids.map((item) => getStorageItemById(state, storage, item)) as CloudItem[];
            const filteredBizItems = getBizFilteredItems(storageItems, currentBizCategory);
            ids = filteredBizItems.map((item) => item.id);
        }
        return ids;
    }
);
/**
 * Чинит нормализацию дат
 * В проекте во многих местах используется умножение на 1000 для дат,
 * поэтому дата переносится в будущее, чиним это в этом месте минимальными усилиями.
 */
const normalizedMtimeForListView = (mtime: number) => {
    return mtime && isFuture(mtime) ? mtime / 1_000 : mtime;
};

export const getStorageItemsGroupedByPeriod = createSelector(
    (state: RootState) => state,
    getCurrentStorage,
    (_: RootState, ids: string[]) => ids,
    (_: RootState, __: string[], showGroupByPeriod: boolean) => showGroupByPeriod,
    (state, storage, ids, showGroupByPeriod) => {
        if (!ids.length) {
            return emptyArray;
        }

        const now = Date.now();
        const firstItem = getStorageItemById(state, storage, ids[0]);
        const firstItemMtime = firstItem && 'mtime' in firstItem ? firstItem?.mtime : undefined;
        const dateFirstItem = showGroupByPeriod && firstItemMtime ? normalizedMtimeForListView(firstItemMtime) : firstItemMtime || now;
        let period = getPeriodNameByDate(dateFirstItem);

        const result = [
            {
                divider: true,
                text: getDividerPeriodName(period),
            },
        ];

        return ids.reduce((acc: any[], curr: string) => {
            const item = getStorageItemById(state, storage, curr);
            const mtime = item && 'mtime' in item ? item?.mtime : undefined;
            const date = showGroupByPeriod && mtime ? normalizedMtimeForListView(mtime) : mtime || now;
            const currPeriod = getPeriodNameByDate(date);

            if (period !== currPeriod) {
                period = currPeriod;
                acc.push({
                    divider: true,
                    text: getDividerPeriodName(currPeriod),
                });
            }
            acc.push(curr);
            return acc;
        }, result);
    }
);

export const groupedIds = createSelector(
    (state: RootState) => state,
    (state: RootState, storage: EStorageType, _?: boolean) => storage,
    (state, storage: EStorageType, filterOutFolder?: boolean) => filterOutFolder,
    (state: RootState, storage, _, isLastFiles) => isLastFiles,
    getCurrentStorage,
    (state, storage: EStorageType) =>
        SettingsSelectors.getViewByStorage(state, storage, isMedia(state, getStorageCurrentFolder(state, storage), storage)),
    getDomainFoldersFilterActive,
    (state) => (IS_BIZ_USER ? getHomeList(state) : null),
    (state) => {
        const selectedFaceId = getSelectedFaceId(state);
        if (!selectedFaceId) {
            return null;
        }
        return getFilesWithFaceIdxs(state, selectedFaceId);
    },
    (state, storage, filterOutFolder, isLastFiles, currentPage, viewMode, showOnlyDomainFolders, homeList, filesWithFaces) => {
        const { isPublic, isFeed, isGallery, isAllDocuments } = getStorage(storage);

        if (isPublic && filesWithFaces !== null) {
            return filesWithFaces;
        }

        const ids = sortIds(state, storage, Boolean(filterOutFolder), Boolean(isLastFiles));
        let resultIds: string[] = [];

        if (IS_BIZ_USER && storage === EStorageType.home && currentPage === EStorageType.home && !showOnlyDomainFolders && homeList) {
            ids.forEach((id) => {
                const itemKind = homeList[id].kind;
                if (itemKind !== 'mounted' && itemKind !== DOMAIN_FOLDER) {
                    resultIds.push(id);
                }
            });
        } else {
            resultIds = ids;
        }

        const showGroupByPeriod = (isAllDocuments && viewMode === EViewMode.list) || isFeed;
        if (
            (showGroupByPeriod && currentPage !== EStorageType.start && currentPage !== EStorageType.home && !filterOutFolder) ||
            (isGallery && isMobileGalleryViewMode(viewMode) && !filterOutFolder)
        ) {
            // TODO: refactor to remove state from selector combiner argument (cause of rerenderings!)
            return getStorageItemsGroupedByPeriod(state, resultIds, showGroupByPeriod);
        }

        return filterIdsByAuthors(state, resultIds, storage);
    }
);

export const getIdsCount = createSelector(
    (state, storage: EStorageType) =>
        !IS_BIZ_USER && storage === EStorageType.home ? getCurrentChildIds(state) : groupedIds(state, storage, false, false),
    (items) => items?.length ?? 0
);

export const hasLoadMore = createSelector(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    (state: RootState, storage: EStorageType | undefined, folder: CloudItem | undefined) => storage,
    (state: RootState, storage: EStorageType | undefined, folder: CloudItem | undefined) =>
        folder ?? getStorageCurrentFolder(state, storage || getCurrentStorage(state)),
    (state: RootState, storage: EStorageType | undefined) => {
        if (storage === EStorageType.public) {
            const selectedFaceId = getSelectedFaceId(state);
            if (selectedFaceId) {
                // @ts-ignore
                return hasMoreToLoad(state, selectedFaceId);
            }
        }
        return null;
    },
    (storage, folder, hasMoreToLoadPublic: boolean | null = false): boolean => {
        if (storage === EStorageType.public && hasMoreToLoadPublic !== null) {
            return hasMoreToLoadPublic ?? false;
        }

        return (folder && 'hasMoreToLoad' in folder && folder.hasMoreToLoad) || false;
    }
);

export const getItemsCount = createSelector(getIds, (ids) => ids.length);
export const getCurrentTotalCount = createSelector(filterIdsByAuthors, (ids) => ids.length);

export const getItemsTotalCount = createSelector(
    (state) => state,
    getCurrentStorage,
    (state, storage) => {
        if (storage === EStorageType.quotaCleaner) {
            return getCurrentGroupTotalCount(state);
        }

        return null;
    }
);

export const getItemsTotalSize = createSelector(
    (state) => state,
    getCurrentStorage,
    (state, storage) => {
        if (storage === EStorageType.quotaCleaner) {
            return getCurrentGroupTotalSize(state);
        }

        return null;
    }
);

export const getStorageSelectedItems = createSelector(
    SelectionsSelectors.getSelectedIdxs,
    (state, storage) => storage || getCurrentStorage(state),
    (state: RootState) => state,
    (selected, storage, state) => {
        const selectFunc = getSelectedFunc(storage);

        return selected.map((id) => selectFunc(state, id)).filter((_) => _);
    }
);

export const getSelectedItems = createSelector(
    (state) => state,
    getCurrentStorage,
    (state, storage) => getStorageSelectedItems(state, storage)
);

export const mapFolderAuthorsWithContacts = createSelector(
    contactsSelectors.getContacts,
    (state, authors: string[]): string[] => authors,
    (contacts = [], authors = []): { email: string; name: string; nick: string }[] =>
        authors.map((author) => ({
            email: author,
            name: pathOr(author, ['name'], find(propEq('email', author), contacts || [])),
            nick: pathOr('', ['nick'], find(propEq('email', author), contacts || [])),
        }))
);

export const getFolderAuthors = createSelector(getCurrentFolder, (folder) => {
    return folder && 'authors' in folder ? Object.keys(folder.authors || {}) : [];
});

export const isFolderWithAuthor = createSelector(getFolderAuthors, UserSelectors.getEmail, (authors, userEmail) => {
    return authors.length > 1 || (authors.length === 1 && authors[0] !== userEmail);
});

export const getItemFolder = createSelector(
    (state, item: CloudItem | undefined) => item,
    getCurrentStorage,
    (state) => state,
    (item, storage, state) => {
        if (!item) {
            return undefined;
        } else if (item.isFolder) {
            return item;
        }
        const selectFunc = getSelectedFunc(storage);
        return selectFunc?.(state, item.parent);
    }
);

const getAllDocumentsBreadcrumbs = createSelector(
    (_: RootState, from?: string) => from,
    (state) => getCurrentStorage(state),
    (state: RootState) => state,
    (from, storage, state) => {
        if (!from || from === 'document' || from === 'presentation') {
            return emptyArray;
        }

        const list: CloudItem[] = [];

        list.push(state.home.list['/']);

        from?.split('/').reduce(function (acc, current) {
            const path = acc + current;
            if (path in state.home.list) {
                list.push(state.home.list[path]);
            }

            return `${acc + current}/`;
        }, '');

        return list;
    }
);

interface MemoArgs {
    key: string;
    list: (CloudItem & ItemMountedShared)[];
}

const getMemoizedBreadcrumbs = memoizeWith(
    (args: MemoArgs) => args.key,
    (args: MemoArgs) => args.list.reverse()
);

interface SelectFuncMemoArgs {
    state;
    storage: EStorageType;
}

const getMemoizedSelectedFunc = memoizeWith(
    ({ storage }: SelectFuncMemoArgs) => storage ?? '',
    ({ state, storage }: SelectFuncMemoArgs) =>
        (id: string) =>
            getSelectedFunc(storage)(state, id)
);

const getKey = (item) => (item?.id || ' ') + (item?.weblink || ' ') + (item?.kind || ' ');

export const getBreadCrumbs = createSelector(
    (state: RootState, from?: string) => {
        const selectFunc = getSelectedFunc(getCurrentStorage(state));
        return (from ? selectFunc(state, from) : getCurrentFolder(state)) as CloudItem;
    },
    (state) => getCurrentStorage(state),
    (state: RootState) => getMemoizedSelectedFunc({ state, storage: getCurrentStorage(state) }),
    (state) => (getCurrentStorage(state) === EStorageType.sharedLinks ? getRootFolderListById(state) : emptyArray),
    (item, storage, selectFunc, rootItems) => {
        // shame on me
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const list: (CloudItem & ItemMountedShared)[] = [];

        if (!item) {
            return emptyArray;
        }

        let isMounted = false;
        let isShared = false;
        let isDomain = false;
        let is;
        let isReaOnly = false;

        let current: (CloudItem & ItemMountedShared) | undefined = item;
        let key = '';
        do {
            key += getKey(current);
            list.push({ ...current });

            isDomain = isDomainFolder(current);
            isMounted = isMountedFolder(current) || isDomain;
            isShared = isSharedFolder(current);
            is = is || isMounted || isShared;

            if (is && current.isFolder) {
                isReaOnly = Boolean(current && 'isReadOnly' in current && current.isReadOnly);
            }

            current = selectFunc(current.parent);

            if (list[list.length - 1] === current) {
                break;
            }
        } while (current);

        if (item && storage === EStorageType.sharedLinks && !isMounted) {
            const firstFolder = getPathParts(item.id)?.filter(Boolean)?.shift();

            if (item.name !== firstFolder) {
                const firstFolderId = `/${firstFolder}`.toLowerCase();
                rootItems.some((item) => {
                    if (item?.isMounted) {
                        isMounted = true;
                        is = is || item.isMounted;
                        return;
                    }

                    if (item?.id.toLowerCase() === firstFolderId) {
                        isMounted = isMountedFolder(item) || isDomainFolder(item);
                        is = is || isMounted;
                    }

                    return isMounted;
                });
            }
        }

        for (const item of list) {
            if (item.id === ROOT_FOLDER_ID) {
                continue;
            }

            item.isDomain = isDomain;
            item.isMounted = isMounted;
            item.isShared = isShared;
            item.isReadOnly = isReaOnly;
        }

        // TODO: нужно убирать state из аргументов комбайнера этого селектора, чтобы не делать такого
        return getMemoizedBreadcrumbs({ key, list });
    }
);

export const isRootCurrentFolder = createSelector(
    (state, storage) => getStorageCurrentFolder(state, storage),
    (folder) => !(folder && 'parent' in folder && folder.parent)
);

export const isMountedOrSharedFolder = createSelector(
    (state, item) => item,
    getCurrentStorage,
    getRootFolderListById,
    (state, item) => {
        let breadCrumbs;
        if (getCurrentStorage(state) === EStorageType.alldocuments) {
            // @ts-ignore
            breadCrumbs = getAllDocumentsBreadcrumbs(state, item?.id);
        } else {
            // @ts-ignore
            breadCrumbs = getBreadCrumbs(state, item?.id);
        }
        return breadCrumbs;
    },
    (item, storage, rootItems, breadCrumbs) => {
        let folderId = '';
        let isMounted = false;
        let isShared = false;
        let isDomain = false;
        let is = breadCrumbs.some((cloudItem) => {
            folderId = cloudItem?.id;
            isDomain = isDomainFolder(cloudItem);
            isMounted = isMountedFolder(cloudItem) || isDomain;
            isShared = isSharedFolder(cloudItem);

            return isMounted || isShared;
        });

        if (item && storage === EStorageType.sharedLinks && !isMounted) {
            const firstFolder = getPathParts(item.id)?.filter(Boolean)?.shift();

            if (item.name !== firstFolder) {
                const firstFolderId = `/${firstFolder}`.toLowerCase();
                rootItems.some((item) => {
                    if (item?.isMounted) {
                        isMounted = true;
                        is = is || item.isMounted;
                        return;
                    }

                    if (item?.id.toLowerCase() === firstFolderId) {
                        isMounted = isMountedFolder(item) || isDomainFolder(item);
                        is = is || isMounted;
                    }

                    return isMounted;
                });
            }
        }

        return {
            is,
            isDomain,
            isMounted,
            isShared,
            folderId,
        };
    }
);

export const isReadOnlyMountedOrShared = createSelector(
    (state: RootState) => state,
    isMountedOrSharedFolder,
    (state, { is, folderId }) => {
        if (is && folderId) {
            const item = getItemById(state, folderId);
            return Boolean(item && 'isReadOnly' in item && item.isReadOnly);
        }

        return false;
    }
);

export const getParentMountedOrSharedItems = createSelector(
    (state: RootState, _) => getRootFolderListById(state),
    (state: RootState, items: (Item | CloudItem)[]) => items,
    (folderList, items: (Item | CloudItem)[]) => {
        return items
            .filter((item) => {
                const parentFolder = ('parentFolder' in item ? item.parentFolder : item.parent)?.split('/').filter(Boolean)[0] ?? '';
                const parent = folderList.find((folder) => folder?.id === `/${parentFolder}`);
                return parent && (isMountedFolder(parent) || parent.isMounted);
            })
            .map((item) => item.id);
    }
);

export const hasParentMountedOrSharedFolder = createSelector(
    (state: RootState, items: (Item | CloudItem)[]) => getParentMountedOrSharedItems(state, items),
    (items: string[]) => {
        return items.length > 0;
    }
);

export const getMobileBreadcrumbs = createSelector(
    SettingsSelectors.getQueryParams,
    (state, storage) => storage,
    (state, storage) => getStorageCurrentFolder(state, storage),
    getDomainFoldersFilterActive,
    ({ query }, storage, currentFolder, domainFoldersFilterActive) => {
        switch (storage) {
            case EStorageType.trashbin:
                return trashbinCrumb[0];
            case EStorageType.gallery:
                return galleryCrumb[0];
            case EStorageType.search:
                if (query) {
                    return currentFolder;
                }

                return {
                    ...currentFolder,
                    name: 'Поиск',
                };
            case EStorageType.family:
                return familyCrumb[0];
            default:
                if (domainFoldersFilterActive) {
                    return { ...currentFolder, name: 'Общие' };
                }
                return currentFolder;
        }
    }
);

export const getSelectedItem = (state) => getRootState(state).storage.selectedItem;

export const isUploadEnabled = createSelector(
    (state: RootState) => state,
    (state) => getCurrentStorage(state),
    (state) => getCurrentFolder(state),
    (state, storage, folder) => {
        const isAnonym = UserSelectors.isAnonymous(state);

        if (isAnonym) {
            return false;
        }

        if (storage === EStorageType.public) {
            return isPublicUploadEnabled(state);
        }

        if (storage === EStorageType.home) {
            return !isReadOnlyMountedOrShared(state, folder);
        }
    }
);

export const isEmptyFolder = createSelector(
    (state: RootState) => state,
    (state) => getCurrentFolder(state),
    (state, folder) => !folder?.childs.length && !folder?.isLoading && folder?.isLoaded
);

export const isCurrentFolderLoading = createSelector(
    (state: RootState) => state,
    (state) => getCurrentFolder(state),
    (state, folder) => folder?.isLoading && !folder?.isLoaded && !folder?.childs.length
);

export const isFolderOwner = createSelector(getCurrentStorage, isOwnPublic, (storage, isOwnPublic) => {
    const { isHome, isPublic } = getStorage(storage);

    return isHome || (isPublic && isOwnPublic);
});

export const getIsYearFilter = createSelector(
    (state: RootState) => state,
    getCurrentStorage,
    (state, storage) => {
        const { isQuotaCleaner } = getStorage(storage);
        if (isQuotaCleaner) {
            return isYearCurrentGroupFilter(state);
        }

        return false;
    }
);
const getParentFolders = createSelector(
    (state: RootState) => state,
    (_, folder: CloudItem) => folder,
    (state: RootState, folder: CloudItem): CloudItem[] => {
        const res: CloudItem[] = [];

        const iterate = (folder: CloudItem) => {
            const parentFolder = getItemById(state, folder?.parent);
            if (parentFolder) {
                res.push(parentFolder);
                iterate(parentFolder);
            }
        };
        iterate(folder);
        return res;
    }
);

export const isFolderActionDisabled = (state: RootState, folder): boolean => {
    const isDomain = isDomainFolder(folder);
    const isMounted = isMountedFolder(folder);

    if (isDomain) {
        return true;
    }

    if (isMounted) {
        return false;
    }

    const parentFolders = getParentFolders(state, folder);
    return parentFolders.some((item) => isDomainFolder(item) || isMountedFolder(item));
};

export const getSelectedItemsByStorage = createSelector(
    (state) => state,
    (state, storage: EStorageType) => storage,
    SelectionsSelectors.getSelectedIdxs,
    (state, storage, ids) => ids.map((id) => getStorageItemById(state, storage, id)).filter(Boolean)
);

export const hasParentsDomainFolder = createSelector(
    getRootState,
    (state: RootState, folder: CloudItem) => getParentFolders(state, folder),
    (_, folders) => folders.some((folder) => folder?.kind === 'domain-folder')
);
