import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { platform } from 'reactApp/appHelpers/configHelpers';
import { getQueryParams, getUtmObject } from 'reactApp/appHelpers/settingsHelpers';
import { allDocumentsHideSpaceRadar } from 'reactApp/modules/allDocuments/allDocuments.helpers';
import {
    getAllDocumentsCurrentSection,
    getAllDocumentsPrevSection,
    getDocumentsState,
} from 'reactApp/modules/allDocuments/allDocuments.selectors';
import type { EAdditionalSections, EAllDocumentsType } from 'reactApp/modules/allDocuments/allDocuments.types';
import { isAllDocumentsHideSpace } from 'reactApp/modules/features/features.helpers';
import { sendDwh, sendXray } from 'reactApp/utils/ga';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

interface useAllDocumentsRadarsProps {
    documentType: EAllDocumentsType | EAdditionalSections.trashbin;
}

export const useAllDocumentsRadars = ({ documentType }: useAllDocumentsRadarsProps) => {
    const { isLoaded, type, count, docsSize } = useSelector(getDocumentsState);
    const [isSent, setIsSent] = useState(false);
    const prevSection = useSelector(getAllDocumentsPrevSection);
    const currentSection = useSelector(getAllDocumentsCurrentSection);

    useEffect(() => {
        sendXray(['page', 'enter', 'alldocs', documentType]);

        allDocumentsHideSpaceRadar(isAllDocumentsHideSpace);
    }, [documentType]);

    useEffect(() => {
        if (!isLoaded) {
            return;
        }

        sendDwh({
            eventCategory: ECategoryGa.entered,
            action: 'docs-section',
            dwhData: {
                platform,
                size_file: docsSize,
                cnt_file: count,
                place: type,
                tab: prevSection,
            },
        });
        // Для логгирования при загрузке страницы достаточно двух параметров
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSection, isLoaded]);

    useEffect(() => {
        if (isSent) {
            return;
        }

        const queries = getQueryParams();
        const { utm_source } = getUtmObject();

        const tab = queries?.fromPage;

        const addDwhParams = {
            ...(tab ? { tab } : {}),
            ...(utm_source ? { referal: utm_source } : {}),
        };

        setIsSent(true);

        sendPaymentGa({
            eventCategory: ECategoryGa.entered,
            action: 'page-entered',
            source: 'docs',
        });

        sendDwh({
            eventCategory: ECategoryGa.entered,
            action: 'docs',
            dwhData: {
                platform,
                size_file: docsSize,
                cnt_file: count,
                ...addDwhParams,
            },
        });
    }, [count, docsSize, isSent]);
};
