import React, { type MouseEvent, type ReactElement, type RefObject, forwardRef, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { renderDeletePublicPasswordConfirmModal } from 'reactApp/components/SharingWindow/SharingNew/Weblink/PublicPassword/DeletePublicPasswordConfirmModal/DeletePublicPasswordConfirmModal.helpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { DropdownItemAction } from 'reactApp/ui/DropdownItemAction/DropdownItemAction';
import { type ListItem, DropdownFont, DropdownList, DropdownTheme } from 'reactApp/ui/DropdownList/DropdownList';

import { type ChangePublicPasswordMenuItem, CHANGE_PUBLIC_PASSWORD_MENU_LIST, ChangePublicPasswordMenuItemId } from './Dropdown.data';

type DropdownProps = {
    onClose: () => void;
    onDeletePassword: () => void;
    onRestorePassword: (password: string) => void;
    onChangePassword: () => void;
    onCopy: (e?: MouseEvent) => void;
    password: string;
    publicId: string;
};

export const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
    ({ onClose, onDeletePassword, onRestorePassword, onChangePassword, onCopy, password, publicId }, ref) => {
        const controlRef = ref as RefObject<HTMLDivElement>;
        const dispatch = useDispatch();

        const isPaidUser = useSelector(UserSelectors.isPaidUser);
        const isCorpUser = useSelector(UserSelectors.isCorpUser);

        const onItemClick = useCallback(
            (item: ChangePublicPasswordMenuItem) => () => {
                if (item.id === ChangePublicPasswordMenuItemId.delete) {
                    renderDeletePublicPasswordConfirmModal({
                        onDelete: onDeletePassword,
                        onRestore: () => onRestorePassword(password),
                        publicId,
                    });
                } else if (item.id === ChangePublicPasswordMenuItemId.copy) {
                    onCopy();
                } else if (item.id === ChangePublicPasswordMenuItemId.change) {
                    onChangePassword();
                }
            },
            [onDeletePassword, password, dispatch, onChangePassword, onRestorePassword, onCopy, publicId]
        );

        const list = useMemo(() => {
            return CHANGE_PUBLIC_PASSWORD_MENU_LIST.filter(
                (item) => isPaidUser || isCorpUser || item.id !== ChangePublicPasswordMenuItemId.change
            ).map((item) => {
                return { ...item, onClick: onItemClick(item) };
            });
        }, [onItemClick, isPaidUser, isCorpUser]);

        const renderDropdownItem = (item: ListItem): ReactElement => {
            return (
                <DropdownItemAction
                    text={item.text}
                    icon={item.icon}
                    font={DropdownFont.VKSansDisplay}
                    mod={item.id === ChangePublicPasswordMenuItemId.delete ? 'remove' : undefined}
                />
            );
        };

        const findDropdownPosition = (dropdownWidth): { posX: number; posY: number } => {
            let posX = 0;
            let posY = 0;

            if (controlRef?.current) {
                const elRect = controlRef?.current.getBoundingClientRect();

                posY = elRect.height + window.scrollY + elRect.top;
                posX = elRect.left + elRect.width - dropdownWidth + window.scrollX;
            }

            return { posX, posY };
        };

        return (
            <DropdownList
                gaId="change-public-password"
                list={list}
                close={onClose}
                closeOnScroll
                closeOnMouseLeave
                parentRef={ref as RefObject<HTMLDivElement>}
                renderItem={renderDropdownItem}
                font={DropdownFont.VKSansDisplay}
                theme={DropdownTheme.large}
                calcPosition={findDropdownPosition}
            />
        );
    }
);

Dropdown.displayName = 'ChangePublicPasswordMenu';
