import React, { useCallback } from 'react';
import { IS_B2B_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { AccessRights } from 'reactApp/types/Tree';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { renderPreCopyWeblink } from 'reactApp/ui/CopyWeblink/PreCopyWeblinkDialog/preCopyWeblink.helpers';
import { useCopyWeblink } from 'reactApp/ui/CopyWeblink/useCopyWeblink';

interface Props {
    isPhone?: boolean;
    isFolder: boolean;
    isDownloadable?: boolean;
    fileName?: string;
    weblinkDomestic?: boolean;
    weblinkAccessRights?: AccessRights;
    publicUrl: string;
}

export const CopyLink = ({ isPhone, isFolder, isDownloadable, fileName, publicUrl, weblinkDomestic, weblinkAccessRights }: Props) => {
    const isReadWriteWeblinkAccess = weblinkAccessRights && weblinkAccessRights === AccessRights.rw;

    const { copy } = useCopyWeblink({
        isPhone,
        isFolder,
        isDownloadable,
        isReadWriteWeblinkAccess,
    });

    const renderPreCopyDialog = useCallback(() => {
        renderPreCopyWeblink({ onSuccess: () => copy(publicUrl), fileName });
    }, [copy, fileName, publicUrl]);

    const handleCopyButtonClick = useCallback(() => {
        if (!isFolder && IS_B2B_BIZ_USER && !weblinkDomestic) {
            return renderPreCopyDialog();
        }

        copy(publicUrl);
    }, [isFolder, weblinkDomestic, copy, publicUrl, renderPreCopyDialog]);

    return (
        <Button theme={isPhone ? 'vk' : 'octavius'} primary sizeMode={ButtonSizeMode.small} onClick={handleCopyButtonClick}>
            Скопировать ссылку
        </Button>
    );
};
