import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { plural } from 'lib/plural';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { abHeavyFileBuy } from 'reactApp/appHelpers/featuresHelpers/features/heavyFileBuy';
import { uploadBigFileModalAb } from 'reactApp/appHelpers/featuresHelpers/features/uploadBigFileModal';
import { useButtons } from 'reactApp/components/UploadBigFileDialog/hooks/useButtons';
import { getOwner } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.selectors';
import { getPublicOwner, getPublicUploadLimit } from 'reactApp/modules/public/public.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { NameComponent } from 'reactApp/ui/Name/Name';
import { getPriceStringWithPeriod } from 'reactApp/utils/priceHelpers';
import { truncateTextMiddle } from 'reactApp/utils/textHelpers';

import { type Content, type IBigFileModalContent, EShowTariffType, FILES_PLURAL } from '../UploadBigFileDialog.types';

export const useContent = ({ tariffInfo, numberOfFiles, file, files, callbacks }: IBigFileModalContent): Content => {
    const { uploadLimit } = useSelector(UserSelectors.getUserData);
    const publicUploadLimit = useSelector(getPublicUploadLimit);

    const currentStorage = useSelector(getCurrentStorage);
    const { isPublic } = getStorage(currentStorage);

    const publicOwner = useSelector(getPublicOwner);
    const acOwner = useSelector(getOwner);
    const owner = isPublic ? publicOwner : acOwner;

    const currentUserEmail = useSelector(UserSelectors.getEmail);

    const isStrangerOwner = !!currentUserEmail && !!owner?.email && currentUserEmail !== owner?.email;

    const { usualProduct, showTariffType } = tariffInfo;

    const annotation = useMemo(() => {
        if (isStrangerOwner && isPublic && publicUploadLimit) {
            return (
                <>
                    Файл слишком тяжёлый. Чтобы вы могли загрузить файлы больше {bytesToNDigits(publicUploadLimit, 3).value}, владелец папки
                    должен подключить один из платных тарифов.
                </>
            );
        }

        // tempexp_18088-next-line
        if (abHeavyFileBuy) {
            return (
                <>
                    Оплатите разовую загрузку или оформите подписку Mail Space, чтобы загружать много файлов. Поместятся файлы до 100 ГБ
                    каждый
                </>
            );
        }
        /* tempexp_17604-start */
        if (uploadBigFileModalAb === 'b') {
            return (
                <>
                    Файл слишком тяжёлый. Чтобы загружать файлы больше {bytesToNDigits(uploadLimit, 3).value}, подключите подписку Mail
                    Space
                    {showTariffType === EShowTariffType.USUAL_TARIFF &&
                        usualProduct &&
                        ` — ${usualProduct?.space?.value} за ${getPriceStringWithPeriod(usualProduct)}`}
                </>
            );
        }
        /* tempexp_17604-end */

        return (
            <>
                Файл слишком тяжёлый. Чтобы загружать файлы больше {bytesToNDigits(uploadLimit, 3).value}, подключите один из платных
                тарифов.
            </>
        );
    }, [isPublic, isStrangerOwner, publicUploadLimit, uploadLimit]);

    const description = useMemo(() => {
        if (files) {
            return <>{FILES_PLURAL[plural(files.number)].replace('{count}', `${files.number}`)}</>;
        }

        return (
            <>
                <span>
                    <NameComponent name={file?.name || ''} truncate />{' '}
                </span>
                не может быть загружен
            </>
        );
    }, [file?.name, files]);

    const header = useMemo(() => {
        // tempexp_18088-next-line
        if (abHeavyFileBuy) {
            return (
                <>
                    Файл «{truncateTextMiddle(file?.name || '', 22)}» весит больше {bytesToNDigits(uploadLimit, 3).value}
                </>
            );
        }

        if (files) {
            return 'Невозможно загрузить файлы';
        }

        return <>Невозможно загрузить файл «{truncateTextMiddle(file?.name || '', 22)}»</>;
    }, [file?.name, files, uploadLimit]);

    const buttonActions = useButtons({ tariffInfo, numberOfFiles, files, callbacks });

    return {
        header,
        annotation,
        actions: buttonActions,
        description,
    };
};
