import type { ReactNode } from 'react';

export interface IProps {
    status: EStatus;
    advDomIdDesktop?: string;
    children?: ReactNode;
    isDark?: boolean;
    isStatic?: boolean;
    isBiz?: boolean;

    onButtonClick?(): void;
}

export enum EStatus {
    NOT_FOUND = 404,
    TOO_MANY_REQUESTS = 429,
    GATEWAY_TIMEOUT = 504,
    INTERNAL_SERVER_ERROR = 500,
    NOT_AVAILABLE = 403,
    BAD_BROWSER = 'bad-browser',
    BAD_BROWSER_ATOM = 'bad-browser-atom',
    SOMETHING_WRONG = 'something-wrong',
    PUBLIC_BLOCKED = 'public-blocked',
}

export interface ErrorInfo {
    buttonText: string;
    buttonLink: string;
    title: string;
    imageSrc: string;
    imageSrcDark?: string;
    metaTitle: string;
    downloadLink: string;
    annotation: ReactNode;
    onClickHandler?: () => void;
    isHidePromo?: boolean;
}
