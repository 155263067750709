import { Switch } from '@vkontakte/vkui';
import React, { type ChangeEvent, type MouseEvent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styles from 'reactApp/components/SharingWindow/Weblink/SharingWeblink.css';
import { toggleSeoRequest } from 'reactApp/modules/modifying/modifying.actions';
import type { PublishItem } from 'reactApp/modules/modifying/modifying.types';

export const useSeoOption = (item: PublishItem | undefined, enableSeo = false) => {
    const dispatch = useDispatch();
    const weblinkSeoEnabled = item?.weblinkSeoEnabled;

    const handleSwitchClick = useCallback(
        (event: MouseEvent<HTMLDivElement> | ChangeEvent<HTMLInputElement>) => {
            event.preventDefault();

            event.stopPropagation();
            event.currentTarget.blur();

            if (!item) {
                return;
            }

            dispatch(toggleSeoRequest({ item }));
        },
        [weblinkSeoEnabled]
    );

    if (!enableSeo) {
        return null;
    }

    return (
        <div className={styles.rightItem} data-qa-item="on-seo-change">
            <span className={styles.rightTitle}>Открыть для поисковиков</span>
            <div className={styles.rightAction}>
                <Switch name="seo" checked={weblinkSeoEnabled} onChange={handleSwitchClick} />
            </div>
        </div>
    );
};
