import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import blackFriday2024ModalImage from 'img/aside-promo-modal/black-friday-2024-modal.png';
import { IS_PUBLIC_DOCUMENT_PROMO_PROHIBITED } from 'reactApp/appHelpers/configHelpers';
import { promotionEndDate } from 'reactApp/appHelpers/featuresHelpers';
import { blackFriday2024 } from 'reactApp/appHelpers/featuresHelpers/features/blackFriday2024';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getQuotaPromoUrl } from 'reactApp/sections/QuotaLanding/QuotaLanding.data';
import { store as reduxStore } from 'reactApp/store';
import { openAsidePromoModal } from 'reactApp/ui/AsidePromoModal/AsidePromoModal.helpers';
import opener from 'reactApp/utils/opener';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { put } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';

const BLACK_FRIDAY_2024_MODAL_ID = 'black-friday-2024-modal';
const PERIOD_IN_DAYS = 3;

export function* initBlackFriday2024Modal() {
    const storage = yield* select(getCurrentStorage);

    const periodPassed =
        storeHelper.getValue(BLACK_FRIDAY_2024_MODAL_ID) && storeHelper.isPassed(BLACK_FRIDAY_2024_MODAL_ID, { daysCount: PERIOD_IN_DAYS });

    if (storeHelper.getValue(BLACK_FRIDAY_2024_MODAL_ID) && !periodPassed) {
        return;
    }

    const isCorrectUrl = storage === EStorageType.home || storage === EStorageType.public || storage === EStorageType.gallery;

    if (!isCorrectUrl || !blackFriday2024 || IS_PUBLIC_DOCUMENT_PROMO_PROHIBITED) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.blackFriday2024,
            promoId: BLACK_FRIDAY_2024_MODAL_ID,
            onClick() {
                sendPaymentGa({
                    action: 'click',
                    eventCategory: ECategoryGa.balloon,
                    name: 'black_friday',
                    place: storage,
                });

                opener(
                    getQuotaPromoUrl({
                        query: 'utm_source=cloud&utm_medium=web_union_quota_landing&utm_campaign=balloon_black_friday',
                    }),
                    true
                );
                reduxStore.dispatch(removePromo(EPromoType.blackFriday2024));
            },
            onShow: () => {
                storeHelper.markAsShown(BLACK_FRIDAY_2024_MODAL_ID);
                sendPaymentGa({
                    action: 'show',
                    eventCategory: ECategoryGa.balloon,
                    name: 'black_friday',
                    place: storage,
                });
                reduxStore.dispatch(promoShown(EPromoType.blackFriday2024));
            },
            onClose: () => {
                reduxStore.dispatch(removePromo(EPromoType.blackFriday2024));
            },
        })
    );
}

export function* showBlackFriday2024Modal() {
    const promo = yield* select(PromoSelectors.getPromo(EPromoType.blackFriday2024));

    if (promo) {
        const { onShow, onClick, onClose } = promo;

        yield openAsidePromoModal({
            title: '5 ТБ со скидкой 85%',
            text: 'Много памяти для фото и файлов, Почта и Облако без рекламы и\u00A0другие плюсы — оформите Mail\u00A0Space',
            buttonText: 'Забрать скидку',
            imageUrl: blackFriday2024ModalImage,
            showAboveViewer: true,
            alwaysDark: true,
            imageText: `Сроки акции с 25.11.2024 по ${format(
                parseISO(promotionEndDate),
                'dd.MM.yyyy'
            )}. Подробные условия: https://vk.cc/cED5sS`,
            onShow,
            onClick,
            onClose,
            blackFriday2024Modal: true,
        });
    }
}
