import classNames from 'clsx';
import React, { type FC } from 'react';
import { blackFriday2024 } from 'reactApp/appHelpers/featuresHelpers/features/blackFriday2024';
import { cyberMonday2025 } from 'reactApp/appHelpers/featuresHelpers/features/cyberMonday2025';
import { TariffCardContentSize } from 'reactApp/ui/TariffCardNew/TariffCard.types';

import styles from './TariffLabel.css';
import { type ITariffLabelProps, ETariffLabelKind, ETariffLabelRadius } from './TariffLabel.types';

export const TariffLabel: FC<ITariffLabelProps> = ({
    dataQa,
    children,
    kind = ETariffLabelKind.blue,
    radius = ETariffLabelRadius.r24,
    contentSize = TariffCardContentSize.middle,
    isRebrandingQuotaLanding = false,
    enableTopRightBorder = false,
    subscriptionPage,
    disableAction = false,
}) => (
    <div
        data-qa={dataQa}
        data-qa-kind={dataQa && kind}
        className={classNames(styles.label, {
            [styles[`contentSize_${contentSize}`]]: !!contentSize,
            [styles[`label_${kind}`]]: !!kind,
            [styles[`label_${radius}`]]: !!radius,
            [styles.label_rebrandingQuotaLanding]: isRebrandingQuotaLanding,
            [styles.label_topRightBorder]: enableTopRightBorder,
            [styles.label_blackFriday2024]: !disableAction && blackFriday2024 && !subscriptionPage,
            [styles.label_cyberMonday2025]: !disableAction && cyberMonday2025 && !subscriptionPage,
        })}
    >
        {children}
    </div>
);
