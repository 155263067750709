import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPublicPin } from 'reactApp/modules/public/public.actions';

import { closePublicPassword, validatePassword } from './PublicPasswordDialog.helpers';

export const usePassword = ({ item, pin, inputRef, onClose, onSuccess }) => {
    const dispatch = useDispatch();

    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (pin) {
            setPassword(pin);
        }
    }, []);

    const handleChangePassowrd = useCallback(
        (event) => {
            const { value } = event.target || {};

            setPassword(value);
            setError(validatePassword(value));
        },
        [setPassword]
    );

    const clearPassword = useCallback(() => {
        setPassword('');
        setError('');
    }, []);

    const tooglePasswordVisibility = useCallback(() => {
        inputRef?.current?.focus();
        setShowPassword((prev) => !prev);
    }, [setShowPassword, inputRef]);

    const handleSavePassword = useCallback(() => {
        if (!item) {
            return;
        }

        dispatch(
            setPublicPin({
                pin: password,
                public_id: item.weblink ?? '',
                onSuccess: () => {
                    onSuccess?.();
                    closePublicPassword();
                },
                onError: () => {
                    onClose();
                },
            })
        );
    }, [dispatch, password, item, onSuccess, onClose]);

    return { error, password, showPassword, handleSavePassword, handleChangePassowrd, clearPassword, tooglePasswordVisibility };
};
