import React, { type ReactElement, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { IS_REQUIRED_B2B_PASSWORD_PUBLIC } from 'reactApp/appHelpers/configHelpers';
import { isPersonalAccessSharingEnabled } from 'reactApp/appHelpers/featuresHelpers/features/personalAccessSharing';
import type { DomesticArguments, DomesticReturnData } from 'reactApp/components/SharingNewBiz/DomesticDropdown/DomesticDropdown.types';
import { renderPublicPassword } from 'reactApp/components/SharingNewBiz/PublicPasswordDialog/PublicPasswordDialog.helpers';
import { closeSharingNew, renderSharingNew, sendGa } from 'reactApp/components/SharingNewBiz/SharingNew.helpers';
import { DomainAccess } from 'reactApp/components/SharingNewBiz/SharingNew.types';
import styles from 'reactApp/components/SharingNewBiz/Weblink/SharingNewWeblink.css';
import { renderUnpublishWeblinkDialog } from 'reactApp/components/SharingNewBiz/Weblink/SharingNewWeblink.helpers';
import { publishRequest, toggleWeblinkDomesticRequest, weblinkSetDomainAccess } from 'reactApp/modules/modifying/modifying.actions';
import { DropdownMenu } from 'reactApp/ui/Dropdown/DropdownMenu';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

export const useDomestic = ({
    checked,
    item,
    storage,
    isWeblinkOpened,
    onActionClick,
    formProps,
    pin,
    isPublicPasswordEnabled,
    domainAccessOptions,
}: DomesticArguments): DomesticReturnData => {
    const dispatch = useDispatch();

    const renderDomesticDropdown = useCallback(
        (onClick: void): ReactElement => {
            const list = Object.values(domainAccessOptions)
                .filter((option) => !option.disabled)
                .map(({ id, text }) => ({
                    id,
                    text,
                    check: id === checked,
                    qaValue: id,
                }));
            return <DropdownMenu list={list} theme="octavius" onClick={onClick} className={styles.dropdownMenu} />;
        },
        [checked, domainAccessOptions]
    );

    const onToggleDomestic = useCallback(
        (id: DomainAccess): void => {
            if (id !== checked && item) {
                sendGa('domestic');
                sendPaymentGa({
                    eventCategory: ECategoryGa.public,
                    action: 'edit-access',
                    type_for: id,
                    public_id: item.weblink,
                });

                if (!isPersonalAccessSharingEnabled || item.isFolder) {
                    dispatch(toggleWeblinkDomesticRequest({ item }));
                } else {
                    dispatch(weblinkSetDomainAccess({ domain: id, itemId: item.id, storage }));
                }
            }
        },
        [checked, item, dispatch, storage]
    );

    const showPublicPasswordDialog = (fn?: () => void) => {
        renderPublicPassword({
            item,
            pin,
            onSuccess: () => {
                fn?.();
                if (formProps) {
                    renderSharingNew(formProps);
                }
            },
            onDialogClose: (item) => {
                if (formProps) {
                    renderSharingNew(formProps);
                }
                if ((!isPersonalAccessSharingEnabled || item?.isFolder) && !isWeblinkOpened && !pin) {
                    onActionClick?.(item);
                }
            },
        });
        closeSharingNew();
    };

    const handleUpdateDomestic = useCallback(
        (id: DomainAccess): void => {
            if (
                (!isPersonalAccessSharingEnabled || item?.isFolder) &&
                id === DomainAccess.EMAIL_LIST_ACCESS &&
                isWeblinkOpened &&
                onActionClick
            ) {
                renderUnpublishWeblinkDialog({ onActionClick });
                return;
            }

            if (isWeblinkOpened === false && item) {
                sendGa('publish');
                dispatch(publishRequest({ item, respectDomesticByDefault: id === DomainAccess.DOMESTIC }));

                if (isPublicPasswordEnabled && IS_REQUIRED_B2B_PASSWORD_PUBLIC && id === DomainAccess.EXTERNAL && item?.isFolder && !pin) {
                    showPublicPasswordDialog();
                }
                return;
            }

            if (isPublicPasswordEnabled && IS_REQUIRED_B2B_PASSWORD_PUBLIC && id === DomainAccess.EXTERNAL && item?.isFolder && !pin) {
                showPublicPasswordDialog(() => onToggleDomestic(id));
            } else {
                onToggleDomestic(id);
            }
        },
        [isPublicPasswordEnabled, isWeblinkOpened, pin, onActionClick, item, onToggleDomestic, dispatch]
    );

    return {
        renderDropdownMenu: renderDomesticDropdown,
        handleUpdateDomestic,
    };
};
