import { getFeature, isFeature } from 'Cloud/Application/FeaturesEs6';

/** https://jira.vk.team/browse/CLOUDWEB-17164 Кнопка репортинга проблем */
export const BUGREPORT_BUTTON = isFeature('bugreport-button');
export const BUGREPORT_KOTLETT = isFeature('kotlett-bugreport');
export const BUGREPORT_BUTTON_ENABLED = BUGREPORT_BUTTON && !BUGREPORT_KOTLETT;
export const BUGREPORT_KOTLETT_BUTTON_ENABLED = BUGREPORT_BUTTON && BUGREPORT_KOTLETT;

// какой аналог у features.getAsString?
export const BUGREPORT_API_URL = getFeature('bugreport-api-url') || 'https://openapi.cloud.mail.ru/api/v1/private/report';
