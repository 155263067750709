import { differenceInDays, differenceInMonths } from 'date-fns';
import { ascend, find, path, propEq, sortWith, values } from 'ramda';
import { PROMO_TARIFFS } from 'reactApp/appHelpers/configHelpers';
import type { RootState } from 'reactApp/store';
import type { LoadingState } from 'reactApp/types/commonStates';
import { createSelector } from 'reselect';

import type { BillingDataState } from './billingData.types';

const getBillingDataRoot = (state: RootState) => state.billingData;
const getBilligDataSubscriptions = createSelector(getBillingDataRoot, ({ subscription }) => subscription);

const getBillindDataRaw = createSelector(getBillingDataRoot, ({ rawBillingData }) => rawBillingData);

const getSubscriptionsList = createSelector(getBilligDataSubscriptions, (list) => values(list));

const getPaidInfo = createSelector(getBillingDataRoot, (data) => data.paidInfo);

const getSubscriptionsById = createSelector(
    getBilligDataSubscriptions,
    (_: RootState, id: string | number) => id,
    (subscriptions, id) => path([id], subscriptions)
);

const getTrialSubscription = createSelector(
    getBilligDataSubscriptions,
    (subscriptions) => subscriptions && Object.values(subscriptions).find((item) => item.isTrial)
);

const getAutorenewalTrialSubscription = createSelector(getBilligDataSubscriptions, (subscriptions) => {
    const trials = subscriptions && Object.values(subscriptions).filter((subs) => subs.isTrial && subs.autorenewal);
    return trials.length && trials[0];
});

const sortSubscriptionsBySpace = createSelector(getBilligDataSubscriptions, (subscriptions) => {
    // tempexp_18088-start
    const productsToExclude: string[] = [];
    if (PROMO_TARIFFS?.heavyFile) {
        productsToExclude.push(PROMO_TARIFFS.heavyFile);
    }
    const list = Object.values(subscriptions).filter((item) => !productsToExclude.includes(item.productId));
    // tempexp_18088-end
    return sortWith([ascend(path(['space', 'original'])), ascend(path(['id']))], list);
});

const getComboSubscription = createSelector(
    getBilligDataSubscriptions,
    (subscriptions) => subscriptions && find(propEq('type', 'reward'), values(subscriptions))
);

// TODO CLOUDWEB-17484: удалить после полного перехода на биллинг дату и связанные вещи
const getProfessionalSubscription = createSelector(getBilligDataSubscriptions, () => undefined);

const getActiveSubscriptionId = createSelector(getBillingDataRoot, ({ activeSubscriptionId }) => activeSubscriptionId);

const hasMailSubscription = createSelector(getSubscriptionsList, (list) => list.some((subscription) => subscription));

const getSubscriptionsLoadingState = createSelector(
    getBillingDataRoot,
    (state: BillingDataState): LoadingState => ({
        isLoading: state.isLoading,
        isLoaded: state.isLoaded,
    })
);

const getBillingDataLoadingState = createSelector(
    getBillingDataRoot,
    (state: BillingDataState): LoadingState => ({
        isLoading: state.isLoading,
        isLoaded: state.isLoaded,
    })
);

const isUserWithoutPayment = createSelector(
    getPaidInfo,
    getSubscriptionsLoadingState,
    (paidInfo, loading) => loading.isLoaded && paidInfo.paidExpires === 0 && paidInfo.trialExpires === 0
);

const lastPaymentWasLessThan = createSelector(
    (_, periodData: { days?: number; months?: number }) => periodData,
    getPaidInfo,
    isUserWithoutPayment,
    (periodData, paidInfo, isWithoutPayment) => {
        if (isWithoutPayment) {
            return false;
        }

        const { trialExpires, paidExpires } = paidInfo;

        const lastPayment = Math.max(trialExpires, paidExpires);

        const { days, months } = periodData;

        if (months && lastPayment) {
            const diff = differenceInMonths(Date.now(), lastPayment);

            return diff < months;
        }

        if (days && lastPayment) {
            const diff = differenceInDays(Date.now(), lastPayment);

            return diff < days;
        }

        return false;
    }
);

const getSubscriptionsCount = createSelector(getBilligDataSubscriptions, (subscriptions) => {
    return Object.values(subscriptions).length;
});

export const BillingDataSelectors = {
    getBillindDataRaw,
    getSubscriptionsById,
    getTrialSubscription,
    getAutorenewalTrialSubscription,
    sortSubscriptionsBySpace,
    getComboSubscription,
    getProfessionalSubscription,
    getActiveSubscriptionId,
    hasMailSubscription,
    getSubscriptionsLoadingState,
    lastPaymentWasLessThan,
    isUserWithoutPayment,
    getBillingDataLoadingState,
    getSubscriptionsCount,
};
