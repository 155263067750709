import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { type ISortState, ESortOder, ESortTypes } from 'reactApp/modules/sort/sort.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { RootState } from 'reactApp/store';
import type { Sort } from 'reactApp/types/Tree';
import { createSelector } from 'reselect';

export const defaultSort: Sort = {
    type: ESortTypes.name,
    order: ESortOder.asc,
};

const getSortState = (state: RootState) => state.sort;

export const getSortById = createSelector(
    getSortState,
    getCurrentStorage,
    (state: RootState, id: string) => id,
    (state: ISortState, storage: EStorageType, id: string): Sort | undefined => {
        if (IS_BIZ_USER && storage === EStorageType.trashbin) {
            return state[`${storage}${id}`];
        }

        return state[id];
    }
);

export const getSort = createSelector(
    (state, _, storage?: EStorageType) => storage || getCurrentStorage(state),
    getCurrentStorage,
    (state, folder) => getSortById(state, folder?.id || ''),
    (storage, currentStorage, sort): Sort => {
        if (
            [EStorageType.start, EStorageType.feed, EStorageType.albums, EStorageType.alldocuments].includes(storage as EStorageType) ||
            (storage === EStorageType.gallery && !IS_BIZ_USER) ||
            currentStorage === EStorageType.start
        ) {
            return {
                type: ESortTypes.mtime,
                order: ESortOder.desc,
            };
        }

        if (sort) {
            return sort;
        }

        if (IS_BIZ_USER && storage === EStorageType.trashbin) {
            return {
                type: ESortTypes.deletedAt,
                order: ESortOder.asc,
            };
        }

        if (
            (storage === EStorageType.trashbin && !IS_BIZ_USER) ||
            storage === EStorageType.search ||
            (storage === EStorageType.attaches && !IS_BIZ_USER) ||
            storage === EStorageType.documents
        ) {
            return {
                type: null,
            };
        }

        if (storage === EStorageType.sharedIncoming && !IS_BIZ_USER) {
            return {
                type: ESortTypes.unmountedFirst,
                order: ESortOder.asc,
            };
        }

        if (storage === EStorageType.sharedAutodelete) {
            return {
                type: ESortTypes.publicExpires,
                order: ESortOder.asc,
            };
        }

        return defaultSort;
    }
);
