/* eslint-disable complexity */
import { parse } from 'qs';
import { createAutoDeleteFolder } from 'reactApp/appHelpers/appHelpers';
import { IS_BIZ_USER, IS_DOCUMENTS_DOMAIN, IS_ONPREMISE, IS_PUBLIC_ALBUM, platform } from 'reactApp/appHelpers/configHelpers';
import { isFileForOpenInR7Editor, openEditor } from 'reactApp/appHelpers/editorHelpers';
import { ebookDesktopConfig } from 'reactApp/appHelpers/featuresHelpers';
import { blockOverqueotaViewer } from 'reactApp/appHelpers/featuresHelpers/features/blockOverquotaViewer';
import { showUploadItemsOnDatalistContext } from 'reactApp/appHelpers/featuresHelpers/features/uploadItemsOnDatalistContext';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { openCreateCopyOfNoneditableFileModal } from 'reactApp/components/CreateCopyOfNoneditableFileModal/helper';
import { type EAllDocumentsType, AllDocumentsCategoryType } from 'reactApp/modules/allDocuments/allDocuments.types';
import { attachesOpenViewer } from 'reactApp/modules/attaches/attaches.actions';
import { dispatchNewSearchRadar } from 'reactApp/modules/dwh/dwh.module';
import { openEbookReader } from 'reactApp/modules/ebook/ebook.helpers';
import { getEditorsFor } from 'reactApp/modules/editor/editor.selectors';
import { getFeatureNoneditableFormats } from 'reactApp/modules/features/features.helpers';
import { isEBook } from 'reactApp/modules/file/isEBook';
import { isEditableDocumentInPublicFolder, isFolder } from 'reactApp/modules/file/utils';
import { changeHomeHistory, setDomainFoldersFilterActive, setLastOpenFolder } from 'reactApp/modules/home/home.actions';
import { changeHistory, historyPush } from 'reactApp/modules/router/router.module';
import { getStorage, isIntegrationStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { trashbinRestoreRequest } from 'reactApp/modules/trashbin/trashbin.module';
import { openUserQuotaItemView } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.actions';
import type { QuotaCleanerItem } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.types';
import { openViewer } from 'reactApp/modules/viewer/viewer.module';
import { EDocsAction, EDocSubKind } from 'reactApp/sections/AllDocuments/allDocuments.types';
import { store as reduxStore } from 'reactApp/store';
import { UserQuotaGroupId, UserQuotaGroupType } from 'reactApp/types/QuotaCleaner';
import { createCreateNewMenu } from 'reactApp/ui/ContextMenu/createContextMenuList';
import { renderContextMenu } from 'reactApp/ui/ContextMenu/createContextMenuToolkit';
import { logHandler } from 'reactApp/ui/Datalist/DataList.helpers';
import { DropdownTheme } from 'reactApp/ui/DropdownList/DropdownList';
import { renderOverquotaModal } from 'reactApp/ui/OverquotaPopup/new/OverquotaModal.helpers';
import { OverquotaModalMode } from 'reactApp/ui/OverquotaPopup/new/OverquotaModal.types';
import { emitUploadStart } from 'reactApp/ui/UploadInput/UploadInput';
import { sendDatalistGa } from 'reactApp/utils/datalistGa';
import { changeDocumentDomainToCloud } from 'reactApp/utils/documentsDomain';
import { sendDwh, sendGa } from 'reactApp/utils/ga';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { getEncodePath, getHomeFolderPath } from 'reactApp/utils/urlHelper';

const getItemId = (item) => {
    const { isPublic, isAlbums } = getStorage(item.storage);

    if (isAlbums) {
        return item.id;
    }

    if (isPublic) {
        return item.weblink;
    }

    return item.home || item.id;
};

const CreateTypeMap = {
    document: 'docx',
    spreadsheet: 'xlsx',
    presentation: 'pptx',
};

// eslint-disable-next-line max-lines-per-function
export const onItemClick = ({
    id,
    item,
    folder,
    dispatch,
    storage,
    viewMode,
    attachType,
    attachFolderId,
    featureFormatsData,
    blockViewer,
    currentCategory,
}) => {
    const isFile = !isFolder(item);
    const {
        isSharedIncoming,
        isTrashBin,
        isHome,
        isSearch,
        isSharedLinks,
        isSharedAutodelete,
        isFavorites,
        isQuotaCleaner,
        isAttaches,
        isAllDocuments,
        isIntegration,
        isPublic,
        isStock,
        isEmbedded,
        isIncomingPublic,
    } = getStorage(item.storage);
    const state = reduxStore.getState();
    const isIncomingItem = currentCategory === AllDocumentsCategoryType.sharedWithYou;
    const isEditablePublicFile =
        !IS_ONPREMISE && isPublic && isEditableDocumentInPublicFolder(item) && getEditorsFor(state, item).length > 0;
    const reloadPageToFolderHome = (isSearch || isSharedLinks || isSharedIncoming) && item.isFolder;
    const openFolderHome = (isFavorites || isSharedAutodelete) && item.isFolder;
    const openFolderPublicReact = (isStock || isPublic) && item.isFolder;
    const isHomeFolder = isHome && !isFile;
    const isOpenViewer = !item.isFolder;
    const inviteToken = isSharedIncoming && item.inviteToken;
    const { query } = parse(window.location.search, { ignoreQueryPrefix: true });
    const itemStorage = storage ?? item.storage;
    const isPrivate = 'home' in item && itemStorage !== EStorageType.public;
    const editingNoneditableFormats =
        !!item && !!itemStorage && isPrivate
            ? getFeatureNoneditableFormats({ file: item, storage: itemStorage }, featureFormatsData)
            : undefined;

    sendDatalistGa('open', `${item.isFolder ? 'folder' : 'file'}${viewMode}`);

    if ((isSearch || (isAttaches && query)) && item.isFolder) {
        const dwhData = {
            eventCategory: ECategoryGa.entered,
            action: 'open',
        };

        const items = [
            {
                file_name: item.nameWithoutExt,
                type: item.kind,
                pos: item.pos,
                file_id: item.id,
                mtime: item.mtime,
                size: item.size || 0,
                extension: item.isFolder ? 'folder' : item.ext,
            },
        ];

        dispatch(dispatchNewSearchRadar({ dwhData, items }));
    }

    if (isQuotaCleaner) {
        const { groupType, groupId } = item as QuotaCleanerItem;
        if (groupType === UserQuotaGroupType.Cloud && groupId === UserQuotaGroupId.CloudBin) {
            return;
        }
        dispatch(openUserQuotaItemView({ id: item.id }));
        return;
    }

    if (isIncomingPublic || isIncomingItem) {
        const host = IS_DOCUMENTS_DOMAIN ? changeDocumentDomainToCloud(window.location.origin) : window.location.origin;

        const path = `public/${item.weblink}`;

        return window.open(`${host}/${path}`);
    }

    if (inviteToken) {
        toolbarActions.mount();
    } else if (isTrashBin) {
        dispatch(
            trashbinRestoreRequest({
                ids: [item.id],
                from: 'double-click',
            })
        );
    } else if (openFolderHome) {
        dispatch(historyPush({ id: getHomeFolderPath(item.home) }));
    } else if (openFolderPublicReact) {
        dispatch(changeHistory(`/${IS_PUBLIC_ALBUM ? '' : storage}/${getEncodePath(id)}`));
    } else if (reloadPageToFolderHome) {
        // TODO: routing
        location.href = getHomeFolderPath(getEncodePath(item.home));
    } else if (isAllDocuments || IS_DOCUMENTS_DOMAIN) {
        const { subKind = '', size = 0, ext: extension = '' } = item || {};
        const path = `home/${getEncodePath(item.home)}`;
        const isEditable = getEditorsFor(state, item).length > 0;

        sendDwh({
            eventCategory: ECategoryGa.docs,
            action: EDocsAction.open,
            dwhData: {
                platform,
                extension,
                size_file: size,
                type_content: EDocSubKind[subKind] ?? '',
                source: EDocSubKind[subKind] ?? '',
                is_edit: isEditable,
                type_open: isEditable ? 'edit' : 'show',
            },
        });

        if (isEditable) {
            openEditor(item);
        } else {
            window.open(`${IS_DOCUMENTS_DOMAIN ? changeDocumentDomainToCloud(window.location.origin) : window.location.origin}/${path}`);
        }
    } else if (IS_BIZ_USER && editingNoneditableFormats) {
        openCreateCopyOfNoneditableFileModal({
            file: item,
            editingNoneEditableFormats: editingNoneditableFormats,
        });
    } else if (isFileForOpenInR7Editor(item)) {
        openEditor(item);
    } else if (isEditablePublicFile) {
        window.open(`/public/${item.id}`);
    } else if (isHomeFolder || isIntegration) {
        dispatch(changeHomeHistory({ id }));
        dispatch(setDomainFoldersFilterActive(false));

        dispatch(setLastOpenFolder(item.id));

        sendPaymentGa({
            eventCategory: ECategoryGa.folder,
            action: 'open-folder',
            type_folder: folder?.parent ? 'folder_in_folder' : 'folder',
            source: 'double-click',
        });
    } else if (isOpenViewer) {
        const shouldOpenEbookReader = isEBook(item) && ebookDesktopConfig?.exts?.includes(item?.ext?.toLowerCase?.());

        if (shouldOpenEbookReader) {
            openEbookReader(storage, item?.id, item?.ext?.toLowerCase?.());
            return;
        }

        sendGa('datalist', 'open-viewer', viewMode);

        if (isAttaches) {
            dispatch(attachesOpenViewer({ id: item.id, attachType, folderId: attachFolderId }));
        } else {
            const data = {
                itemId: getItemId(item),
                itemStorage: storage || null,
                gaSuffix: '',
                itemIds: [],
            };

            // tempexp_17125-start
            if (blockViewer && blockOverqueotaViewer && !isPublic && !isStock && !isEmbedded && !isIntegrationStorage(storage)) {
                renderOverquotaModal({
                    mode: OverquotaModalMode.viewer,
                });
            } else {
                dispatch(openViewer(data));
            }
            // tempexp_17125-end
        }
    }
};

export const onClickCreateNew = (
    e,
    { gaSuffix, storage, currentAllDocsType }: { gaSuffix: string; storage: EStorageType; currentAllDocsType?: EAllDocumentsType }
) => {
    const { isSharedLinks, isSharedAutodelete, isAllDocuments } = getStorage(storage);

    e.preventDefault();
    e.stopPropagation();

    if (isSharedAutodelete) {
        createAutoDeleteFolder();
        return;
    }

    const from = 'datalist';

    if (isSharedLinks) {
        logHandler({
            radarName: 'createnew_shared_click',
        });
        toolbarActions.create('folder', from, true, 'shared', true);

        return;
    }

    if (isAllDocuments) {
        logHandler({
            radarName: 'createnew_docs',
        });
        toolbarActions.create(currentAllDocsType ? CreateTypeMap[currentAllDocsType] : 'docx', from, true, 'docs');

        return;
    }

    logHandler({
        radarName: 'createnew_click',
    });
    renderContextMenu({
        posX: e.pageX,
        posY: e.pageY,
        menuItems: createCreateNewMenu({
            from,
            uploadFileHandler: showUploadItemsOnDatalistContext ? () => emitUploadStart({ type: 'file', from }) : undefined,
            uploadFolderHandler: showUploadItemsOnDatalistContext ? () => emitUploadStart({ type: 'folder', from }) : undefined,
        }),
        theme: DropdownTheme.medium,
        gaSuffix: `ctx_create${gaSuffix ? `_${gaSuffix}` : ''}`,
        storage,
    });
};
