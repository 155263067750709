import { Icon24HideOutline, Icon24ViewOutline } from '@vkontakte/icons';
import { Input, Spacing, Text, Title } from '@vkontakte/vkui';
import classNames from 'clsx';
import image from 'img/public/bite_password.png';
import React, { type KeyboardEvent, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_B2B_BIZ_USER, IS_BLOCKED_PUBLIC, PUBLIC_BLOCKED_FOR } from 'reactApp/appHelpers/configHelpers';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import {
    PublicPasswordEventCategory,
    sendPublicPasswordAnalytics,
} from 'reactApp/components/SharingWindow/SharingNew/Weblink/PublicPassword/PublicPassword.analytics';
import { useDetectKeyboardOpen } from 'reactApp/hooks/useDetectKeyboardOpen';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { checkPublicPin } from 'reactApp/modules/public/public.actions';
import { getPublicRootWeblink } from 'reactApp/modules/public/public.selectors';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { formatCountdown } from 'reactApp/utils/countdown';
import opener from 'reactApp/utils/opener';

import styles from './Password.css';

export const Password = memo(() => {
    const dispatch = useDispatch();

    const [password, setPassword] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [showPassword, setShowPassword] = useState(false);

    const [isBlocked, setIsBlocked] = useState(IS_BLOCKED_PUBLIC ?? false);
    const [blockTimeout, setBlockTimeout] = useState(PUBLIC_BLOCKED_FOR ?? 0);

    const { weblink } = useSelector(getPublicRootWeblink);
    const isMobile = EnvironmentSelectors.isPhone();

    const inputRef = useRef<HTMLInputElement>(null);

    const isKeyboardOpen = useDetectKeyboardOpen();

    const title = IS_B2B_BIZ_USER ? 'Папка защищена паролем' : 'Файлы защищены паролем';

    useEffect(() => {
        sendPublicPasswordAnalytics({ eventCategory: PublicPasswordEventCategory.publicFolderGuest, action: 'show' });
        emitAnalyticEvent(AnalyticEventNames.PUBLIC_PASSWORD_GUEST_SHOW);
    }, []);

    const handleChangePassowrd = useCallback(
        (event) => {
            const { value } = event.target || {};
            setPassword(value);
            if (!isValid && value) {
                setIsValid(true);
            }
        },
        [setPassword, isValid]
    );

    const tooglePasswordVisibility = useCallback(() => {
        setShowPassword((prev) => !prev);
        sendPublicPasswordAnalytics({ eventCategory: PublicPasswordEventCategory.publicFolderGuest, action: 'click_visible' });
    }, [setShowPassword]);

    const handleCheckPassword = useCallback(() => {
        dispatch(
            checkPublicPin({
                pin: password,
                onSuccess: () => {
                    opener(`${window.location.pathname}?pin=${encodeURIComponent(password)}`, true);
                    sessionStorage.setItem(`public-${weblink}-pin`, password);
                    sendPublicPasswordAnalytics({ eventCategory: PublicPasswordEventCategory.publicFolderGuest, action: 'success' });
                    emitAnalyticEvent(AnalyticEventNames.PUBLIC_PASSWORD_GUEST_SUCCESS);
                },
                onFail: ({ isBlocked, blockTimeout }) => {
                    setIsValid(false);
                    setBlockTimeout(blockTimeout);
                    setIsBlocked(isBlocked);
                    sendPublicPasswordAnalytics({
                        eventCategory: PublicPasswordEventCategory.publicFolderGuest,
                        action: 'incorrect_password',
                    });
                    emitAnalyticEvent(AnalyticEventNames.PUBLIC_PASSWORD_GUEST_INCORRECT);
                    inputRef?.current?.focus();
                },
            })
        );
        sendPublicPasswordAnalytics({ eventCategory: PublicPasswordEventCategory.publicFolderGuest, action: 'click' });
    }, [password, dispatch, setIsValid, weblink, inputRef]);

    const handleKeyUp = useCallback(
        (event: KeyboardEvent) => {
            if (password && event.key === 'Enter') {
                dispatch(
                    checkPublicPin({
                        pin: password,
                        onSuccess: () => {
                            opener(`${window.location.pathname}?pin=${password}`, true);
                            sessionStorage.setItem(`public-${weblink}-pin`, password);
                            sendPublicPasswordAnalytics({
                                eventCategory: PublicPasswordEventCategory.publicFolderGuest,
                                action: 'success',
                            });
                            emitAnalyticEvent(AnalyticEventNames.PUBLIC_PASSWORD_GUEST_SUCCESS);
                        },
                        onFail: ({ isBlocked, blockTimeout }) => {
                            setIsValid(false);
                            setBlockTimeout(blockTimeout);
                            setIsBlocked(isBlocked);
                            sendPublicPasswordAnalytics({
                                eventCategory: PublicPasswordEventCategory.publicFolderGuest,
                                action: 'incorrect_password',
                            });
                            emitAnalyticEvent(AnalyticEventNames.PUBLIC_PASSWORD_GUEST_INCORRECT);
                            inputRef?.current?.focus();
                        },
                    })
                );
            }
        },
        [password, dispatch, setIsValid, weblink, inputRef]
    );

    if (isBlocked) {
        return (
            <div
                className={classNames(styles.root, {
                    [styles.rootMobile]: isMobile,
                    [styles.rootOpenKeyboard]: isKeyboardOpen,
                    [styles.rootB2B]: IS_B2B_BIZ_USER,
                })}
            >
                <div className={styles.content}>
                    <Title level={isMobile ? '2' : '1'}>Доступ заблокирован</Title>
                    <Spacing size={isMobile ? 8 : 12} />
                    <Text className={styles.text}>
                        Вы несколько раз ввели неправильный пароль. Попробуйте снова через {formatCountdown(blockTimeout, true)}
                    </Text>
                </div>
            </div>
        );
    }

    return (
        <div
            className={classNames(styles.root, {
                [styles.rootMobile]: isMobile,
                [styles.rootOpenKeyboard]: isKeyboardOpen,
                [styles.rootB2B]: IS_B2B_BIZ_USER,
            })}
        >
            {!IS_B2B_BIZ_USER && <img src={image} alt="Файлы защищены паролем" width={236} height={176} className={styles.image} />}
            <div className={styles.content}>
                <Title level={isMobile ? '2' : '1'}>{title}</Title>
                <Spacing size={isMobile ? 8 : 12} />
                <Text className={styles.text}>Его установил владелец — свяжитесь с ним, чтобы узнать пароль</Text>
                <Spacing size={isMobile ? 20 : 24} />
                <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Введите пароль"
                    className={classNames(styles.password, {
                        [styles.passwordError]: !isValid,
                    })}
                    onChange={handleChangePassowrd}
                    onKeyUp={handleKeyUp}
                    getRef={inputRef}
                    after={
                        password && (
                            <div className={styles.passwordToogle} onClick={tooglePasswordVisibility}>
                                {showPassword ? <Icon24HideOutline /> : <Icon24ViewOutline />}
                            </div>
                        )
                    }
                />
                {!isValid && (
                    <>
                        <Spacing size={6} />
                        <Text className={styles.errorText}>Неверный пароль</Text>
                    </>
                )}
                <Spacing size={isMobile ? 12 : 20} />
                <Button
                    theme="vk"
                    primary
                    disabled={!password}
                    sizeMode={isMobile ? ButtonSizeMode.big : ButtonSizeMode.small}
                    className={styles.button}
                    onClick={handleCheckPassword}
                >
                    Готово
                </Button>
            </div>
        </div>
    );
});

Password.displayName = 'Password';
