const MIN_COLUMN_WIDTH = 228;
const COLUMN_GAP_WIDTH = 12;
const ROWS_COUNT = 3;
/**
 * Минимальная ширина альбома - 228, поэтому поделим ширину блока на 228
 * чтобы знать столько примерно надо отрисовать элементов в свернутом виде
 */
export const getAvailableItemsNumber = (width: number) => {
    return ROWS_COUNT * Math.trunc((width - MIN_COLUMN_WIDTH) / (MIN_COLUMN_WIDTH + COLUMN_GAP_WIDTH) + 1);
};
