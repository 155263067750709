/* eslint-disable max-lines-per-function */
/* eslint-disable no-var */
/* eslint-disable object-shorthand */
/**
 * Этот файл запускается при открытии файла на редактирование
 */
define(function (require, exports, module) {
    const $ = require('jquery');
    const MicroEvent = require('mrg-microevent');
    const { initAndLoadR7 } = require('Cloud/Application/r7helpers');
    const { initAndLoadMyOffice } = require('Cloud/Application/Editor/MyOffice/myOffice.helpers');
    const { MYOFFICE_VARIANTS } = require('Cloud/Application/Editor/MyOffice/myOffice.types');
    const { isWopiEnabled, wopiFallbackConfig } = require('reactApp/appHelpers/featuresHelpers');
    const { R7_WOPI_IFRAME_ID } = require('Cloud/Application/Editor/R7Wopi/constants');
    const { initR7Wopi } = require('Cloud/Application/Editor/R7Wopi');
    const { createIframe } = require('Cloud/Application/Editor/utils/createIframe');
    const { sendDwh } = require('reactApp/utils/ga');
    const { store } = require('reactApp/store');
    const { EditorMode } = require('server/helpers/editors/types');
    const { getCurrentStorage } = require('reactApp/modules/router/router.selectors');
    const { R7ErrorCode } = require('Cloud/Application/types');

    var instance;
    const SHOW_TIMEOUT = 30000;

    var Editor = function (settings) {
        this._settings = settings || {};

        if (instance) {
            return instance;
        }
    };

    Editor.prototype = {
        constructor: Editor,

        initShowIframe(iframe, hided) {
            iframe.style.opacity = '1';

            if (hided) {
                iframe.style.height = '100%';
                iframe.style.width = '100%';
            }
        },

        hideIframe(iframe) {
            iframe.style.opacity = '0';
            iframe.style.height = '0';
            iframe.style.width = '0';
            iframe.style.border = '0';
        },

        start(file, container, url, mode, params, forceAmrInit) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            var editor = this;
            var deferred = new $.Deferred();

            if (editor._officeOnlineFrame) {
                editor._officeOnlineFrame.dispose();
            }

            if (container.length) {
                container = container[0];
            }

            const reduxState = store.getState();

            const storage = getCurrentStorage(reduxState);

            const isEditor = ['r7w/edit/home', 'myoffice/edit/home'].includes(storage);

            sendDwh({
                eventCategory: 'docs_editor',
                label: 'open',
                dwhData: {
                    source: isEditor ? storage : 'public',
                    size_file: file.size,
                    public_edit: file.weblinkAccessRights === 'rw',
                    provider: editor._settings.editorId,
                    id_public: !isEditor ? file.weblink : undefined,
                    extension: file.ext,
                },
            });

            const isMyofficeForCloud = editor._settings.editorId === 'myoffice';
            const isMyofficeForAttaches = editor._settings.editorId === 'myofficeAttaches';

            if (isMyofficeForCloud || isMyofficeForAttaches) {
                let timerId = setTimeout(function () {
                    deferred.reject();
                    editor.emit('timeout');
                }, SHOW_TIMEOUT);

                // старые форматы редактировать все равно нельзя
                const myOfficeVariant = isWopiEnabled && !forceAmrInit ? MYOFFICE_VARIANTS.wopi : MYOFFICE_VARIANTS.amr;

                const iframeAmr = document.createElement('iframe');

                container.appendChild(iframeAmr);

                let iframeWopi = null;
                if (myOfficeVariant === MYOFFICE_VARIANTS.wopi) {
                    iframeWopi = document.createElement('iframe');
                    container.appendChild(iframeWopi);

                    // Скрываем amr, тк показываем wopi, но не удаляем совсем,
                    // потому что может быть сфолбечимся на него
                    this.hideIframe(iframeAmr);
                }

                const iframe = myOfficeVariant === MYOFFICE_VARIANTS.wopi && !forceAmrInit ? iframeWopi : iframeAmr;

                const amrParams = {
                    item: file,
                    initShowAmrIframe: () => this.initShowIframe(iframeAmr, true),
                };

                const wopiParams = {
                    accessParams: params,
                    url,
                    wopiFallbackConfig,
                };

                const listeners = {
                    onLoad: () => {
                        if (timerId) {
                            clearTimeout(timerId);
                            timerId = null;
                        }

                        this.initShowIframe(iframe);

                        editor.emit('load');
                        editor.emit('start', params);
                        deferred.resolve();
                    },
                    onError: () => {
                        this.initShowIframe(iframe);
                        deferred.reject();
                        editor.emit('error');
                    },
                };

                initAndLoadMyOffice({
                    iframeAmr,
                    iframeWopi,
                    documentMode: mode,
                    wopiParams,
                    amrParams,
                    myOfficeVariant,
                    listeners,
                    container,
                    isEditAttaches: isMyofficeForAttaches,
                    item: file,
                    forceAmrInit,
                });
            } else if (editor._settings.editorId === 'R7') {
                editor._officeOnlineFrame = document.createElement('div');
                editor._officeOnlineFrame.id = 'r7-placeholder';
                $(editor._officeOnlineFrame).appendTo(container);

                let timerId = setTimeout(function () {
                    deferred.reject();
                    editor.emit('timeout');
                }, SHOW_TIMEOUT);

                initAndLoadR7(url, {
                    mode,
                    placeholderId: editor._officeOnlineFrame.id,
                    container,
                    onDocumentReady: function () {
                        if (timerId) {
                            clearTimeout(timerId);
                            timerId = null;
                        }
                        deferred.resolve();
                        editor.emit('load');
                        editor.emit('start', params);
                    },
                    onError: function (event) {
                        // Если редактор отдает ошибку превышения лимита, то ничего не делаем.
                        // Р7 умеет сам обрабатывать такую ошибку и показывать соответствующее уведомление.
                        if (event.data?.errorCode === R7ErrorCode.CONVERTATION_OPEN_LIMIT_ERROR) {
                            return;
                        }

                        deferred.reject(event.data);
                        editor.emit('error');
                    },
                });
            } else if (editor._settings.editorId === 'r7wopi') {
                let timerId = setTimeout(() => {
                    deferred.reject();
                    editor.emit('timeout');
                }, SHOW_TIMEOUT);

                // Создаем необходимый для инициализации iframe
                const iframe = createIframe({
                    id: R7_WOPI_IFRAME_ID,
                    name: file.name,
                    iframe: document.createElement('iframe'),
                    container,
                });

                // Инициализируем редактор
                initR7Wopi(file, {
                    wopiParams: {
                        url,
                        accessParams: params,
                    },
                    iframe,
                    container,
                    mode: EditorMode.EDIT,
                    storage: file.storage,
                    events: {
                        // Не стал выносить в новую функцию, весь файл нужно рефакторить
                        // eslint-disable-next-line sonarjs/no-identical-functions
                        onDocumentReady: function () {
                            if (timerId) {
                                clearTimeout(timerId);
                                timerId = null;
                            }
                            deferred.resolve();
                            editor.emit('load');
                            editor.emit('start', params);
                        },
                        onError: function (event) {
                            // Если редактор отдает ошибку превышения лимита, то ничего не делаем.
                            // Р7 умеет сам обрабатывать такую ошибку и показывать соответствующее уведомление.
                            if (event?.data?.errorCode === R7ErrorCode.CONVERTATION_OPEN_LIMIT_ERROR) {
                                return;
                            }

                            deferred.reject(event?.data);
                            editor.emit('error');
                        },
                    },
                });
            }

            return deferred;
        },
    };

    MicroEvent.mixin(Editor.prototype);

    module.exports = Editor;
});
