import classNames from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { IS_B2B_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { overquotaAfterClear } from 'reactApp/appHelpers/featuresHelpers/features/overquotaAfterClear';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { setIsFromAttaches } from 'reactApp/modules/attaches/attaches.actions';
import { AttachesSelectors } from 'reactApp/modules/attaches/attaches.selectors';
import { getFeatureShowStories } from 'reactApp/modules/features/features.selectors';
import { feedLoadRequest } from 'reactApp/modules/feed/feed.module';
import { getCurrentFolderHome, getDomainFoldersFilterActive, isDefaultRootContent } from 'reactApp/modules/home/home.selectors';
import { checkUpdateLicenseRequest } from 'reactApp/modules/profile/profile.module';
import { getIsUserOverquotaProfileLoaded, getUserOverquotaCurrentState } from 'reactApp/modules/profile/profile.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getStoryListRequest } from 'reactApp/modules/stories/stories.module';
import { getStorySummaryCovers } from 'reactApp/modules/stories/stories.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { AutoDeleteFolderInformer } from 'reactApp/ui/AutoDeleteFolderInformer/AutoDeleteFolderInformer';
import { BizOverquotaInformer } from 'reactApp/ui/BizOverquotaInformer/BizOverquotaInformer';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import BizPageTabMenu from 'reactApp/ui/DataListItemRow/components/BizPageTabMenu/BizPageTabMenu';
import { Footer } from 'reactApp/ui/Footer/Footer';
import { StoriesWidget } from 'reactApp/ui/StoriesWidget/StoriesWidget';
import { WelcomeNewPromo } from 'reactApp/ui/WelcomePromo/WelcomeNewPromo/WelcomeNewPromo';
import { WelcomeOverquotaPromo } from 'reactApp/ui/WelcomePromo/WelcomeNewPromo/WelcomeOverquotaPromo';
import { WelcomePromo } from 'reactApp/ui/WelcomePromo/WelcomePromo';
import { scrollToTop } from 'reactApp/utils/helpers';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './HomePage.css';

const AB_MAX_USED_SPACE_PERCENT = 80;
const WAIT_FOR_PROFILE_LOAD_MS = 1000;

// eslint-disable-next-line complexity, max-lines-per-function
export const HomePage: React.VFC<{ initialItemId?: string }> = ({ initialItemId = '' }) => {
    const location = useLocation();
    const timerId = useRef<number | undefined | null>();
    const dispatch = useDispatch();
    const [showInformer, setShowInformer] = useState(true);

    const item = useSelector(getCurrentFolderHome);
    const { id, isFolder } = item || {};
    const isDefaultContent = useSelector(isDefaultRootContent);
    const isShowStoryEnabled = useSelector(getFeatureShowStories);
    const storeStories = useSelector(getStorySummaryCovers);
    const domainFoldersFilterActive = useSelector(getDomainFoldersFilterActive);
    const { usedPercent } = useSelector(UserQuotaSelectors.getUserQuotaState);
    const { isLoaded } = useSelector(UserQuotaSelectors.getLoadingState);
    const isFromAttaches = useSelector(AttachesSelectors.getIsFromAttaches);
    const isRestoreRequested = useSelector(getUserOverquotaCurrentState) === 'restore_requested';
    const isUserOverquotaProfileLoaded = useSelector(getIsUserOverquotaProfileLoaded);
    const [isShowEmptyDataList, setIsShowEmptyDataList] = useState<boolean | null>(null);

    const userHasStories = storeStories?.length > 0;
    const isRoot = isFolder && id === ROOT_FOLDER_ID;
    const showWidgets = isRoot && isShowStoryEnabled && !IS_B2B_BIZ_USER;
    const showBizPageTabMenu = IS_B2B_BIZ_USER && !domainFoldersFilterActive && item?.isFolder && item?.childs.length > 0;

    const canShowEmptyDatalist = overquotaAfterClear && isDefaultContent && !IS_B2B_BIZ_USER && usedPercent < AB_MAX_USED_SPACE_PERCENT;

    useEffect(() => {
        if (!canShowEmptyDatalist || !isLoaded) {
            return;
        }

        if (isUserOverquotaProfileLoaded || isRestoreRequested) {
            if (timerId.current) {
                clearTimeout(timerId.current);
                timerId.current = null;
            }
            setIsShowEmptyDataList(isRestoreRequested);
            return;
        }

        if (!timerId.current) {
            // сначала ждем прогрузки профиля либо таймаут, чтобы не моргали велком промо
            timerId.current = window.setTimeout(() => setIsShowEmptyDataList(false), WAIT_FOR_PROFILE_LOAD_MS);
        }
    }, [canShowEmptyDatalist, isRestoreRequested, isLoaded, isUserOverquotaProfileLoaded]);

    useEffect(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'home' });
        sendPaymentGa({
            eventCategory: ECategoryGa.entered,
            action: 'page-entered',
            source: isFromAttaches ? 'from-mail' : 'home',
            source_to: 'home',
        });

        return () => {
            if (isFromAttaches) {
                dispatch(setIsFromAttaches(false));
            }
        };
    }, [isFromAttaches]);

    useEffect(() => {
        if (isDefaultContent) {
            dispatch(checkUpdateLicenseRequest());
        }
    }, [isDefaultContent]);

    useEffect(() => {
        // TODO: routing
        // Без preventPush в историю браузера добавляется лишние айтемы с тем же адресом.
        // Но с ним баг гораздо хуже - при подьеме из внутренней папки через кнопку Бек браузера,
        // урл в строке не соответствует содержимому даталиста
        // if (preventPush) {
        //     console.log('dispatch change hist from home: revert preventPush ', location.pathname, location);
        //     dispatch(preventHistoryPush(false));
        //     return;
        // }
    }, [location.pathname]);

    useEffect(() => {
        if (showWidgets) {
            if (!userHasStories) {
                dispatch(getStoryListRequest());
            }
            dispatch(feedLoadRequest());
        }
    }, [dispatch, showWidgets]);

    useEffect(() => {
        scrollToTop();
    }, [id]);

    const hideInformer = useCallback(() => {
        setShowInformer(false);
    }, []);

    // Если canShowEmptyDatalist, то сначала ждем прогрузки профиля либо таймаут, чтобы не моргали велком промо
    const finalIsShowEmptyDataList = isShowEmptyDataList || (canShowEmptyDatalist && isShowEmptyDataList === null);

    if (isDefaultContent && !IS_B2B_BIZ_USER && !finalIsShowEmptyDataList) {
        if (usedPercent < AB_MAX_USED_SPACE_PERCENT) {
            return (
                <div className={styles.promo}>
                    <WelcomeNewPromo />
                    <Footer storage={EStorageType.home} isAbsolutePos={true} withMargins />
                </div>
            );
        }

        if (usedPercent >= AB_MAX_USED_SPACE_PERCENT) {
            return (
                <div className={styles.promo}>
                    <WelcomeOverquotaPromo />
                    <Footer storage={EStorageType.home} isAbsolutePos={true} withMargins />
                </div>
            );
        }

        if (!isLoaded) {
            return null;
        }
    }

    if (isDefaultContent && !finalIsShowEmptyDataList) {
        return (
            <div className={styles.promo}>
                <WelcomePromo />
                <Footer storage={EStorageType.home} isAbsolutePos={true} withMargins />
            </div>
        );
    }

    return (
        <div className={styles.root}>
            {showWidgets && userHasStories && IS_B2B_BIZ_USER && <StoriesWidget />}

            <BizOverquotaInformer />

            {IS_B2B_BIZ_USER && (
                <BreadcrumbsContainer
                    className={classNames(styles.breadcrumbs, { [styles.breadcrumbs_without_tab_menu]: !showBizPageTabMenu })}
                />
            )}

            {showBizPageTabMenu && (
                <div className={styles.bizPageTabMenu}>
                    <BizPageTabMenu />
                </div>
            )}

            {showInformer && item && isFolder && (
                <AutoDeleteFolderInformer className={styles.autoDeleteInformer} onClose={hideInformer} item={item} />
            )}
            <DataList initialItemId={initialItemId} showBreadcrumbs={!IS_B2B_BIZ_USER} />
        </div>
    );
};
