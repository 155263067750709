import { ReactComponent as CancelIcon } from 'img/icons/cancel_benefit.svg';
import { ReactComponent as DiskoIcon } from 'img/icons/disko.svg';
import { ReactComponent as DoneIcon } from 'img/icons/done_benefit.svg';
import { ReactComponent as VkMusicIcon } from 'img/icons/vk_music.svg';
import { plural } from 'lib/plural';
import React from 'react';
import { isFamilySubsInTariff, isOnlyWebAdInTariffs, isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { PaymentUTM } from 'reactApp/constants/paymentUTM';
import type { IFeatureData } from 'reactApp/constants/products/features.types';
import { templateFeatureActions } from 'reactApp/constants/products/templateFeatureActions';
import { templateFeatureIcons } from 'reactApp/constants/products/templateFeatureIcons';
import { templateFeatures } from 'reactApp/constants/products/templateFeatures';
import { getQuotaPromoUrl } from 'reactApp/sections/QuotaLanding/QuotaLanding.data';
import type { Product } from 'reactApp/types/Billing';

import { benefitsBySpace } from './benefits.bySpace';
import { type BenefitInfoType, BenefitKeyType, benefitsInfo } from './benefits.info';
import styles from './quotaFeatures.css';

const withoutAd = isOnlyWebAdInTariffs ? templateFeatures.noadsWeb : templateFeatures.noadsMailCloud;
const { vkui12Cancel } = templateFeatureIcons;

const baseQuotaFeatures: IFeatureData[] = [
    {
        ...withoutAd,
        textJsx: !isOnlyWebAdInTariffs && (
            <>
                <a target="_blank" href={getQuotaPromoUrl({ query: PaymentUTM.featuresTariffsCard })} rel="noreferrer">
                    Без рекламы
                </a>
                {'\u00A0'}в Почте и Облаке
            </>
        ),
    },
    templateFeatures.upload100Cloud,
    templateFeatures.send100Mail,
    ...(isRebranding
        ? [templateFeatures.familyShare, templateFeatures.supportPriority]
        : [templateFeatures.foldersAutodelete, isFamilySubsInTariff ? templateFeatures.familyShare : templateFeatures.supportPriority]),
];
const baseQuotaProFeatures: IFeatureData[] = [
    {
        text: 'Лицензия Диск-О: на год в подарок',
        key: 'disko',
        icon: <DiskoIcon width={16} height={16} />,
        onClick: templateFeatureActions.openDisko,
        delimiters: true,
    },
    {
        text: 'VK Музыка бесплатно на 3\u00A0месяца',
        key: 'combo',
        icon: <VkMusicIcon width={16} height={16} viewBox="0 0 18 18" />,
        delimiters: true,
    },
];

const baseQuotaProFeatures_6m = [
    {
        ...baseQuotaProFeatures[0],
        text: 'Лицензия ДискО: на 6\u00A0месяцев в подарок',
    },
    {
        ...baseQuotaProFeatures[1],
        text: 'VK Музыка бесплатно на 1\u00A0месяц',
    },
];

const baseQuotaCompact: IFeatureData[] = [
    withoutAd,
    templateFeatures.upload100,
    isFamilySubsInTariff ? templateFeatures.familyShare : templateFeatures.supportFastAnswer,
    templateFeatures.otherBenefits,
];

const baseFreeQuotaCompact: IFeatureData[] = [
    {
        ...withoutAd,
        icon: vkui12Cancel,
    },
    {
        text: 'Нет загрузки больших файлов',
        key: 'upload',
        icon: vkui12Cancel,
    },
    isFamilySubsInTariff
        ? {
              text: 'Делиться местом нельзя',
              key: 'family',
              icon: vkui12Cancel,
          }
        : {
              text: 'Нет быстрой поддержки',
              key: 'support',
              icon: vkui12Cancel,
          },
    {
        text: 'Нет дополнительных возможностей',
        textJsx: (
            <>
                Нет{'\u00A0'}дополнительных
                <br />
                <a href="#benefits">возможностей</a>
            </>
        ),
        key: 'benefits',
        icon: vkui12Cancel,
    },
];

const singleQuotaCheapest: IFeatureData[] = [
    withoutAd,
    {
        text: 'Нет загрузки больших файлов',
        key: 'upload',
        icon: vkui12Cancel,
    },
    isFamilySubsInTariff
        ? {
              text: 'Делиться местом нельзя',
              key: 'family',
              icon: vkui12Cancel,
          }
        : {
              text: 'Нет быстрой поддержки',
              key: 'support',
              icon: vkui12Cancel,
          },
    {
        text: 'Нет дополнительных возможностей',
        textJsx: (
            <>
                Нет{'\u00A0'}дополнительных
                <br />
                <a href="#benefits">возможностей</a>
            </>
        ),
        key: 'benefits',
        icon: vkui12Cancel,
    },
];

/* tempexp_17509-start */
const litresFeatures: IFeatureData[] = [
    {
        text: 'Подписка на Литрес — 2 месяца бесплатно',
        textJsx: (
            <>
                Подписка на Литрес — 2 месяца
                <br />
                бесплатно
            </>
        ),
        key: 'litres',
    },
    withoutAd,
    isFamilySubsInTariff ? templateFeatures.familyAdd3 : templateFeatures.supportFastAnswer,
    templateFeatures.otherBenefits,
];
/* tempexp_17509-end */

export const quotaFeatures: Record<string, IFeatureData[]> = {
    quotaTariffsLanding: baseQuotaFeatures,
    freeQuotaCompact: baseFreeQuotaCompact,
    quotaRegular: baseQuotaFeatures,
    quotaCompact: baseQuotaCompact,
    quotaProfessional: [...baseQuotaFeatures, ...baseQuotaProFeatures],
    quotaProfessional_6m: [...baseQuotaFeatures, ...baseQuotaProFeatures_6m],
    singleQuotaCheapest,
    litresFeatures,
};

// tempexp_16800-start
const featureIds = [benefitsInfo[BenefitKeyType.Ad].key, benefitsInfo[BenefitKeyType.Upload].key, benefitsInfo[BenefitKeyType.Family].key];
const expandedFeatureIds = [
    ...featureIds,
    benefitsInfo[BenefitKeyType.Support].key,
    benefitsInfo[BenefitKeyType.Calendar].key,
    benefitsInfo[BenefitKeyType.Safety].key,
    benefitsInfo[BenefitKeyType.Mobile].key,
    benefitsInfo[BenefitKeyType.Folders].key,
];

const quotaFeaturesBySpace: Record<string, IFeatureData[]> = {};

export function createQuotaFeaturesListByProductSpace(product: Product, variant: 'all' | 'all-active', shortText: boolean) {
    const cacheKey = `${product.space.original}-${variant}-${shortText ? 'short' : 'key'}`;
    const result: IFeatureData[] = quotaFeaturesBySpace[cacheKey] ?? [];

    if (product.isSingleQuota && !result.length) {
        expandedFeatureIds.forEach((featureKey) => {
            const benefit: BenefitInfoType | undefined = benefitsBySpace[product.space.original]?.[featureKey];
            if (benefit && variant === 'all-active') {
                result.push({
                    key: featureKey,
                    text: shortText ? benefit.shortText : benefit.fullText,
                });
            }
            if (variant === 'all') {
                if (benefit) {
                    const text = shortText ? benefit.shortText : benefit.fullText;
                    result.push({
                        key: featureKey,
                        text,
                        icon: <DoneIcon />,
                    });
                } else {
                    const text = shortText ? benefitsInfo[featureKey].shortText : benefitsInfo[featureKey].fullText;
                    result.push({
                        key: featureKey,
                        text,
                        textJsx: <span className={styles.cancelText}>{text}</span>,
                        icon: <CancelIcon />,
                    });
                }
            }
        });
        quotaFeaturesBySpace[cacheKey] = result;
    }
    return result;
}

export function getAdditionalBenefitsCountText(product: Product) {
    const pluralTitles = ['возможность', 'возможности', 'возможностей'];
    const activeBenefits = Object.values(benefitsBySpace[product.space.original] ?? {}).filter((b) => !!b);
    const count = activeBenefits.length - featureIds.length;
    return `${count} ${pluralTitles[plural(count)]}`;
}

// tempexp_16800-end
