import debounce from 'lodash.debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { getFolderPath, isMediaFolder } from 'reactApp/modules/file/utils';
import { checkUpdateLicenseRequest } from 'reactApp/modules/profile/profile.module';
import { historyPush } from 'reactApp/modules/router/router.module';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getSearchRequestParams } from 'reactApp/modules/search/search.selectors';
import type { SearchItem } from 'reactApp/modules/search/search.types';
import { setQueryParams } from 'reactApp/modules/settings/settings.module';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { scrollToItemAction } from 'reactApp/modules/storage/storage.module';
import { getItemById, getStorageItemById } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { openViewer } from 'reactApp/modules/viewer/viewer.module';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import type { RootState } from 'reactApp/store';
import { logHandler } from 'reactApp/ui/Datalist/DataList.helpers';
import { ESearchOptionSource, ESearchOptionType } from 'reactApp/ui/WebSearch/WebSearch.data';
import { sendDatalistGa } from 'reactApp/utils/datalistGa';
import { sendDwh, sendGa } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';
import { decodeURIComponentHelper, getEncodePath, getHomeFolderPath } from 'reactApp/utils/urlHelper';

import { EAligment } from '../VirtualList/VirtualList.types';

type LoadToItemReturnType = [number | null, boolean, (id: number | null) => void];

export const useLoadToItem = (items: string[], loadOnScroll: () => void, isLoading?: boolean): LoadToItemReturnType => {
    const [activeIndex, setActiveIndex] = useState<number | null>(-1);
    const [isElementLoaded, setElementLoaded] = useState<boolean>(false);
    const queryParams = useSelector(SettingsSelectors.getQueryParams);
    const loadMore = useMemo(() => (isLoading ? (): Promise<void> => Promise.resolve() : loadOnScroll), [isLoading, loadOnScroll]);
    const action = queryParams?.action;
    const itemRef = useRef<string>(decodeURIComponentHelper(queryParams?.item || ''));
    const item: string = decodeURIComponentHelper(queryParams?.item || '');

    useEffect(() => {
        if (!item || action !== 'scroll-to') {
            setElementLoaded(true);
            return;
        }

        if (activeIndex === null || isElementLoaded) {
            return;
        }

        const itemIndex = items.map((item) => item.toLowerCase()).indexOf(item.toLowerCase());

        if (itemIndex === -1) {
            loadMore();
            return;
        }

        setElementLoaded(true);
        setActiveIndex(itemIndex);
    }, [isLoading, loadMore, activeIndex, item, isElementLoaded, action, items.length]);

    /**
     * В виде тамбами и списком в элементы допушивается фейковый элемент,
     * а при виде галлереей - нет
     * Получается, элемент может иметь разный индекс при виде галереей и виде списком
     * Этот хук вызывается чтобы перенайти индекс в новой конфигурации
     */
    useEffect(() => {
        if (activeIndex === -1 || activeIndex === null || !itemRef.current) {
            return;
        }

        const itemIndex = items.map((item) => item.toLowerCase()).indexOf(itemRef.current.toLowerCase());

        if (itemIndex === -1 || itemIndex === activeIndex) {
            return;
        }

        setActiveIndex(itemIndex);
    }, [items.length, activeIndex, item]);

    return [activeIndex, isElementLoaded, setActiveIndex];
};

export const useScrollToItem = ({ activeIndex, dispatch, listRef, isElementLoaded }) => {
    const { item: itemParam, ...queryParams } = useSelector(SettingsSelectors.getQueryParams) || {};

    useEffect(() => {
        if (activeIndex === null) {
            dispatch(setQueryParams(queryParams));
            dispatch(scrollToItemAction(''));
            return;
        }

        const item = decodeURIComponentHelper(itemParam || '');

        if (item) {
            const path = getFolderPath(item);
            dispatch(historyPush({ id: getEncodePath(getHomeFolderPath(path === ROOT_FOLDER_ID ? path : `${path}/`)) }));
        }

        if (isElementLoaded && activeIndex >= 0) {
            dispatch(setQueryParams(queryParams));
            listRef.current?.scrollToItem(activeIndex, EAligment.START);
        }
    }, [activeIndex, dispatch, isElementLoaded, listRef, itemParam]);
};

export const useSetActiveIndex = ({ selectedItem, items, setActiveIndex, storage }) => {
    useEffect(() => {
        const { isHome, isPublic, isFeed, isFavorites, isAlbums } = getStorage(storage);
        if (!selectedItem || !items.includes(selectedItem) || !(isHome || isPublic || isFeed || isFavorites || isAlbums)) {
            return;
        }

        const selectedIndex = items.indexOf(selectedItem);

        setActiveIndex(selectedIndex);
    }, [selectedItem, storage]);
};

export const useSetInitialViewerId = ({ isLoaded, loadOnScroll, initialViewerId, setInitialViewerId, items, storage, dispatch }) => {
    const initialViewerItem = useSelector((state: RootState) => getItemById(state, initialViewerId));
    const [updateLicenseCheck, setUpdateLicenseCheck] = useState<boolean>(false);
    const isViewerOpening = useSelector(ViewerSelectors.isViewerOpening);

    useEffect(() => {
        if (isLoaded && !initialViewerId && !updateLicenseCheck) {
            setUpdateLicenseCheck(true);
            dispatch(checkUpdateLicenseRequest());
            return;
        }

        if (isLoaded && initialViewerId && initialViewerItem?.isFolder) {
            setInitialViewerId('');
            setUpdateLicenseCheck(true);
            dispatch(checkUpdateLicenseRequest());
            return;
        }

        if (isLoaded && initialViewerId && !items.includes(initialViewerId)) {
            loadOnScroll();
            return;
        }

        if (isLoaded && initialViewerId && items.includes(initialViewerId)) {
            const data = {
                itemId: initialViewerId,
                itemStorage: storage || null,
                gaSuffix: '',
                itemIds: [],
            };

            if (!isViewerOpening) {
                dispatch(openViewer(data));
            }

            setInitialViewerId('');
        }
    }, [items?.length, initialViewerId, isLoaded, initialViewerItem, isViewerOpening, loadOnScroll, setInitialViewerId]);
};

export const useHandleStopDrag = ({ isDragging, handleStopDrag, selectedItems, storage }) => {
    useEffect(() => {
        if (storage !== EStorageType.home) {
            // Перетаскивание доступно только в хомяке.
            // В корне хомяка может показываться второй даталист с последними файлами
            return;
        }

        if (isDragging) {
            document.addEventListener('mouseup', handleStopDrag);
        }

        return () => {
            document.removeEventListener('mouseup', handleStopDrag);
        };
    }, [isDragging, selectedItems, storage]);
};

export const useHandleMouse = ({ handleMouseMove, handleMouseUp }) => {
    useEffect(() => {
        return function cleanup(): void {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, []);
};

export const useHandlerOnDocumentClick = ({ handleOnDocumentClick }) => {
    useEffect(() => {
        document.addEventListener('click', handleOnDocumentClick, { capture: true });

        return () => {
            document.removeEventListener('click', handleOnDocumentClick, { capture: true });
        };
    }, [handleOnDocumentClick]);
};

export const useLogHandler = ({ items, viewMode, startTime, folder }) => {
    useEffect(() => {
        if (items.length) {
            logHandler({
                radarName: 'datalist_render',
                radars: [`${viewMode}=${Date.now() - startTime}`],
            });
        }
        if (folder?.id === ROOT_FOLDER_ID) {
            logHandler({
                radarName: 'createnew_render',
            });
        }
    }, [folder?.id, viewMode]);
};

export const useGaSelectSend = ({ selectedCount }) => {
    const isGaSelectSend = useRef<boolean>(false);

    useEffect(() => {
        if (selectedCount === 0 && isGaSelectSend.current) {
            isGaSelectSend.current = false;
        }
        if (selectedCount > 1 && !isGaSelectSend.current) {
            sendDatalistGa('start-selection');
            isGaSelectSend.current = true;
        }
    }, [selectedCount, isGaSelectSend]);
};

export const useGaShowGallery = ({ viewMode }) => {
    useEffect(() => {
        if (viewMode === EViewMode.gallery) {
            sendGa('datalist', 'show', viewMode);
        }
    }, [viewMode]);
};

export const useSendGaStat = ({ folder }) => {
    const isMedia = isMediaFolder(folder);

    useEffect(() => {
        if (isMedia) {
            sendGa('datalist', 'ismedia');
        }
    }, [folder?.id, isMedia]);
};

const sendSearchDwh = ({ xPageId, xReqId, start, data, query, count, filters_placement }) => {
    const isPhone = Boolean(IS_PHONE_BROWSER);
    const platform = isPhone ? 'touch' : 'web';
    sendDwh({
        eventCategory: ECategoryGa.entered,
        action: 'result-search',
        dwhData: {
            page_id: xPageId,
            req_id: xReqId,
            search_phrase: query,
            res_count: count,
            place: platform,
            filters_placement,
            show_files: data.map((item) => item.srchSrc),
            results: data.map((item, index) => ({
                pos: start + index,
                file_id: item.id,
                file_name: item.nameWithoutExt,
                type: !item.isFolder ? item.ext : undefined,
                mtime: !item.isFolder ? item.mtime : undefined,
                size: !item.isFolder ? item.size : undefined,
            })),
        },
    });
};

export const useSendDwh = <T>({
    storage,
    isSearchActive,
    isLoaded = false,
    isError,
    items,
    isPopup = false,
}: {
    storage: EStorageType;
    isSearchActive: boolean;
    isLoaded?: boolean;
    isError: boolean;
    items: T[];
    isPopup?: boolean;
}) => {
    // хранилище дипазона индексов отпралвенных / готовых к отправке элементов
    // result используется как кранилище id элементов готовых к отправке
    const [range, setRange] = useState<{ start: number; stop: number; results: T[]; notFound: boolean }>({
        start: 0,
        stop: 0,
        results: [],
        notFound: false,
    });
    const data = useSelector((state: RootState) => range.results.map((id) => getStorageItemById(state, storage, id))) as SearchItem[];
    const { query, xPageId, xReqId, type, source } = useSelector(getSearchRequestParams);

    const idRef = useRef({ data, query, xPageId, xReqId, type, source });

    // полчаем id ещё не отправленных элементов при их первом рендере в virtual list
    // при повторном рендере - скролл вверх списка - статистика отправляться не будет
    const onItemsRendered = useCallback(
        ({ stop }) => {
            if (!isSearchActive) {
                return;
            }

            setRange((state) => {
                const wasEmpty = state.stop === 0;
                const firstSingleResult = wasEmpty && items.length === 1;

                if (stop > state.stop || firstSingleResult) {
                    const start = wasEmpty ? 0 : state.stop + 1;
                    return { ...state, start, stop, results: items.slice(start, stop + 1), notFound: false };
                }

                return state;
            });
        },
        [items?.length, isSearchActive]
    );

    // обновляем id
    useEffect(() => {
        idRef.current = { data, query, xPageId, xReqId, type, source };
    }, [data, query, xPageId, xReqId, type, source]);

    // сброс счётчика при изменении запроса
    useEffect(
        () => setRange((state) => ({ ...state, start: 0, stop: 0, results: [], notFound: false })),
        [query, type, isSearchActive, source]
    );

    // реакция на рендер новых элементов в virtual list
    useEffect(() => {
        if (isSearchActive && isLoaded && (range.results.length || range.notFound) && !isPopup) {
            const { data, query, xPageId, xReqId, source = ESearchOptionSource.all } = idRef.current;
            sendSearchDwh({
                xPageId,
                xReqId,
                start: range.start,
                data,
                query,
                count: items.length,
                filters_placement: source,
            });
            // очищаем очередь результатов на отправку
            setRange((state) => ({ ...state, results: [], notFound: false }));
        }
    }, [isSearchActive, isLoaded, range, items.length, source]);

    useEffect(() => {
        if (isSearchActive && isLoaded && !isError && items.length === 0) {
            const { query, type } = idRef.current;
            if (!!query || (type && type !== ESearchOptionType.all)) {
                setRange((state) => ({ ...state, notFound: true }));
            }
        }
    }, [isSearchActive, isLoaded, isError, items.length]);

    return { onItemsRendered };
};

const createRenderSnackbarDebounced = (dispatch) =>
    debounce((onSuccess) => {
        dispatch(
            showSnackbarAction({
                id: 'changeViewmode1',
                text: 'Файлы теперь отображаются по-новому из-за разных форматов',
                closable: true,
                type: SnackbarTypes.warning,
            })
        );
        onSuccess();
    }, 500);

export const useAutoSwitchFromGallery = ({
    folderId,
    isMediaFolder,
    viewMode,
    storage,
}: {
    folderId;
    isMediaFolder;
    viewMode;
    storage;
}) => {
    const [prevFolderId, setPrevFolderId] = useState<string>(folderId);
    const [isPopupDelayed, setIsPopupDelayed] = useState(false);

    const newMediaView = isMediaFolder && viewMode === EViewMode.gallery;
    const [isMediaView, setIsMediaView] = useState(newMediaView);
    const currentStorage = useSelector(getCurrentStorage);

    const dispatch = useDispatch();

    const renderSnackbarDebounced = createRenderSnackbarDebounced(dispatch);

    // Папка была медиа и был включен режим галерея, а стала вдруг не медиа, тогда попап
    if (
        currentStorage !== EStorageType.start &&
        isMediaView &&
        !isMediaFolder &&
        prevFolderId === folderId &&
        !isPopupDelayed &&
        (storage === EStorageType.home || storage === EStorageType.public)
    ) {
        renderSnackbarDebounced(() => setIsPopupDelayed(false));
        setIsPopupDelayed(true);
    }

    useEffect(() => {
        if (isPopupDelayed && prevFolderId !== folderId) {
            setIsPopupDelayed(false);
            renderSnackbarDebounced.cancel();
        }
    }, [isPopupDelayed, prevFolderId, folderId, renderSnackbarDebounced]);

    useEffect(() => {
        if (prevFolderId !== folderId) {
            setPrevFolderId(folderId);
        }
        if (isMediaView !== newMediaView) {
            setIsMediaView(newMediaView);
        }
    }, [folderId, newMediaView, prevFolderId, isMediaView]);
};
