/* eslint-disable complexity */
import { ACTION_SETTINGS } from 'reactApp/appHelpers/actionSettings';
import { IS_AUTO_TEST_HIDE } from 'reactApp/appHelpers/configHelpers';
import { offerBannerTimeout } from 'reactApp/appHelpers/featuresHelpers/features/offerBanner';
import { isPublicPasswordLinkEnabled, isThemedPublicLinkEnabled } from 'reactApp/appHelpers/featuresHelpers/features/promoLinks';
import { UNMODIFIED_CLOUD_SPACE } from 'reactApp/constants/magicIdentificators';
import { isDialogVisible } from 'reactApp/modules/dialog/dialog.selectors';
import { TRIAL_OFFER_CONFIG } from 'reactApp/modules/home/home.constants';
import { productsController } from 'reactApp/modules/products/products.controller';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { store } from 'reactApp/store';
import { renderBannerOffer } from 'reactApp/ui/BannerOffer/BannerOffer.helpers';
import { put } from 'redux-saga/effects';
import { all, call, select } from 'typed-redux-saga';

export function* initBannerPromo() {
    const storage = yield* select(getCurrentStorage);
    const { isHome } = getStorage(storage);
    const isPaidUser = yield* select(UserSelectors.isPaidUser);
    const isOldBizUser = yield* select(UserSelectors.isOldBizUser);
    const isB2BUser = yield* select(UserSelectors.isB2BUser);
    const isBizUser = yield* select(UserSelectors.isBizUser);
    const isCorpUser = yield* select(UserSelectors.isCorpUser);
    const { usedBytes } = yield* select(UserSelectors.getCloudSpace);
    const isOverQuotaUser = yield* select(UserSelectors.isOverQuotaUser);
    const isNewbie = yield* select(UserSelectors.isNewbie);
    const { action = null } = yield* select(SettingsSelectors.getQueryParams);

    if (
        ACTION_SETTINGS.hideBannerPromo ||
        isNewbie ||
        isPaidUser ||
        !isHome ||
        isOldBizUser ||
        isB2BUser ||
        isBizUser ||
        isCorpUser ||
        isOverQuotaUser ||
        usedBytes === UNMODIFIED_CLOUD_SPACE || // Не загружал еще ни одного файла
        usedBytes === 0 ||
        IS_AUTO_TEST_HIDE ||
        action === 'request-payment' ||
        isPublicPasswordLinkEnabled ||
        isThemedPublicLinkEnabled
    ) {
        return;
    }

    if (storeHelper.getValue(TRIAL_OFFER_CONFIG.id) && !storeHelper.isPassed(TRIAL_OFFER_CONFIG.id, { daysCount: offerBannerTimeout })) {
        return;
    }

    const { tariff, isUserWithoutPayment } = yield* all({
        tariff: call(productsController.getTariffById, TRIAL_OFFER_CONFIG.tariffId),
        isUserWithoutPayment: call(productsController.isUserWithoutPayment),
    });

    if (!tariff || !isUserWithoutPayment) {
        return;
    }

    const product = tariff?.products?.[0];

    yield put(
        addPromoToShowQueue({
            type: EPromoType.banner,
            promoId: TRIAL_OFFER_CONFIG.id,
            onShow: () => {
                store.dispatch(promoShown(EPromoType.banner));
                storeHelper.markAsShown(TRIAL_OFFER_CONFIG.id);
            },
            onClose: () => {
                store.dispatch(removePromo(EPromoType.banner));
                storeHelper.markAsShown(TRIAL_OFFER_CONFIG.id);
            },
            config: TRIAL_OFFER_CONFIG,
            product,
        })
    );
}

export function* showBannerPromo() {
    const bannerPromo = yield* select(PromoSelectors.getPromo(EPromoType.banner));
    const isAnyDialogShown = yield* select(isDialogVisible);

    if (!bannerPromo || isAnyDialogShown) {
        return;
    }

    yield call(renderBannerOffer, bannerPromo);
}
