/* eslint-disable sonarjs/no-duplicate-string */
import { plural } from 'lib/plural';
import { UserQuotaGroupId, UserQuotaGroupType } from 'reactApp/types/QuotaCleaner';

const pluralTitle = {
    [UserQuotaGroupType.Letter]: ['письмо', 'письма', 'писем'],
    [UserQuotaGroupType.Cloud]: ['файл', 'файла', 'файлов'],
};

const pluralGroupTitle = {
    [UserQuotaGroupId.Mounted]: ['папка', 'папки', 'папок'],
};

const pluralDeleteText = {
    [UserQuotaGroupType.Letter]: ['удалено', 'удалено', 'удалено'],
    [UserQuotaGroupType.Cloud]: ['удален', 'удалено', 'удалено'],
};

const pluralGroupDeleteText = {
    [UserQuotaGroupId.Mounted]: ['удалена', 'удалено', 'удалено'],
};

const pluralRemoveText = {
    [UserQuotaGroupType.Letter]: ['перемещено', 'перемещено', 'перемещено'],
    [UserQuotaGroupType.Cloud]: ['перемещён', 'перемещены', 'перемещены'],
};

const pluralGroupRemoveText = {
    [UserQuotaGroupId.Mounted]: ['перемещена', 'перемещены', 'перемещены'],
};

export const getDeleteSnackbarText = (count: number, groupType: UserQuotaGroupType, groupId: UserQuotaGroupId): string => {
    const deleteTextSource = pluralGroupDeleteText[groupId] || pluralDeleteText[groupType];
    const titleSource = pluralGroupTitle[groupId] || pluralTitle[groupType];

    return `${count}\xA0${titleSource[plural(count)]} ${deleteTextSource[plural(count)]}`;
};

export const getRemoveSnackbarText = (count: number, groupType: UserQuotaGroupType, groupId: UserQuotaGroupId): string => {
    const itemName = pluralGroupTitle[groupId] || pluralTitle[groupType];
    const itemAction = pluralGroupRemoveText[groupId] || pluralRemoveText[groupType];
    return `${count} ${itemName[plural(count)]} ${itemAction[plural(count)]} в корзину`;
};
