import { EStatus } from 'reactApp/sections/ErrorPage/ErrorPage.types';
import type { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

import type { IPdfEditor } from './pdfEditor.module';

// @ts-ignore
const getPdfEditorStore = (state: RootState) => state.pdfEditor ?? {};

const getError = createSelector(getPdfEditorStore, (store: IPdfEditor) => (store.isError ? store.error || EStatus.NOT_FOUND : ''));
const getIsLoaded = createSelector(getPdfEditorStore, (store: IPdfEditor) => store.isLoaded);
const getPassword = createSelector(getPdfEditorStore, (store: IPdfEditor) => store.password);
const getIsEncrypted = createSelector(getPdfEditorStore, (store: IPdfEditor) => store.isEncrypted);
const getContent = createSelector(getPdfEditorStore, (store: IPdfEditor) => store.fileContent);
const getFileId = createSelector(getPdfEditorStore, (store: IPdfEditor) => store.fileId);
const getFileHash = createSelector(getPdfEditorStore, (store: IPdfEditor) => store.hash);
const getSavedEditQueueIndex = createSelector(getPdfEditorStore, (store: IPdfEditor) => store.savedEditQueueIndex);
const getSavedFileEditQueueIndex = createSelector(getPdfEditorStore, (store: IPdfEditor) => store.savedFileEditQueueIndex);
const getOpenWorkWithPages = createSelector(getPdfEditorStore, (store: IPdfEditor) => store.openWorkWithPages);

export const pdfEditorSelectors = {
    getError,
    getIsLoaded,
    getPassword,
    getIsEncrypted,
    getContent,
    getFileId,
    getFileHash,
    getSavedEditQueueIndex,
    getSavedFileEditQueueIndex,
    getOpenWorkWithPages,
};
