/* eslint-disable complexity */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines-per-function */
import classNames from 'clsx';
import React, { type ReactElement, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isPublicPasswordEnabled } from 'reactApp/appHelpers/featuresHelpers/features/publicPassword';
import { SharingNewSection } from 'reactApp/components/SharingNewBiz/Section/SharingNewSection';
import { AutoDelete } from 'reactApp/components/SharingNewBiz/Weblink/AutoDelete/AutoDelete';
import { EFrom } from 'reactApp/components/SharingWindow/Sharing.types';
import { getFeaturePublicAutoDeletePromo, getFeaturePublicDomestic } from 'reactApp/modules/features/features.selectors';
import { getPublicSharingInfo } from 'reactApp/modules/public/public.actions';
import { getPublicPin } from 'reactApp/modules/public/public.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { isAutoDeleteEnabled } from 'reactApp/modules/sharedAutoDelete/sharedAutoDelete.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import type { RootState } from 'reactApp/store';
import { CopyWeblink } from 'reactApp/ui/CopyWeblink/CopyWeblink';
import { getPublicUrl } from 'reactApp/utils/urlHelper';

import { AccessRightsDropdown } from '../AccessRightsDropdown/AccessRightsDropdown';
import AdditionalSettings from '../AdditionalSettings/AdditionalSettings';
import { DomesticDropdown } from '../DomesticDropdown/DomesticDropdown';
import { WeblinkPublicPassword } from '../WeblinkPublicPassword/WeblinkPublicPassword';
import styles from './SharingNewWeblink.css';
import type { IProps } from './SharingNewWeblink.types';

export const SharingNewWeblink = memo(function SharingNewWeblink(props: IProps): ReactElement | null {
    const {
        item,
        from,
        isSharedOrMouted,
        gaSuffix,
        noExpiresControl = false,
        isPhone,
        fromEditor,
        formProps,
        isWeblinkOpened,
        setWeblinkOpened,
        onActionClick,
    } = props;

    const dispatch = useDispatch();
    const publicUrl = getPublicUrl(item);
    const storage = useSelector(getCurrentStorage);
    const isAlbum = from === EFrom.ALBUMS || storage === EStorageType.albums;

    const isBizUser = useSelector(UserSelectors.isBizUser);
    const isCorpUser = useSelector(UserSelectors.isCorpUser);
    const domain = useSelector(UserSelectors.getDomain);
    const isDomesticFeatureEnabled = useSelector(getFeaturePublicDomestic);
    const showDomesticSettings = !isAlbum && (isBizUser || isCorpUser) && Boolean(isDomesticFeatureEnabled);
    const isAutodeleteFeatureEnabled = Boolean(useSelector(isAutoDeleteEnabled));
    const isAutodeletePromoFeatureEnabled = Boolean(useSelector(getFeaturePublicAutoDeletePromo));
    const showExpires = !isAlbum && !noExpiresControl;
    const showAccessRights = !isAlbum;
    const isFolder = item?.isFolder;
    const isDownloadable = item?.weblinkDownloadable;
    const showAutoDeleteSettings =
        !isAlbum && isFolder && !isSharedOrMouted.isMounted && (isAutodeleteFeatureEnabled || isAutodeletePromoFeatureEnabled);
    const pin = useSelector((state: RootState) => getPublicPin(state, item?.weblink || ''));
    const showChangePassword = isWeblinkOpened && isPublicPasswordEnabled && item?.isFolder;
    const showCopyWeblink = !isPublicPasswordEnabled && isWeblinkOpened;

    useEffect(() => {
        setWeblinkOpened(Boolean(publicUrl));
    }, [publicUrl]);

    useEffect(() => {
        if (item?.weblink) {
            dispatch(getPublicSharingInfo({ public_id: item.weblink }));
        }
    }, []);

    return (
        <SharingNewSection isOpened name="weblink" isPhone={isPhone}>
            <div className={classNames(styles.root, { [styles.root_phone]: isPhone })}>
                <div className={classNames(styles.innerContent, { [styles.short]: !isWeblinkOpened })}>
                    {showCopyWeblink && (
                        <div className={styles.copyWeblink}>
                            <CopyWeblink
                                type="new"
                                publicUrl={publicUrl}
                                autoCopy={!isPhone}
                                isPhone={isPhone}
                                isFolder={isFolder}
                                isDownloadable={item?.weblinkDownloadable}
                                weblinkAccessRights={item?.weblinkAccessRights}
                                fileName={item?.name}
                                weblinkDomestic={item?.weblinkDomestic}
                            />
                        </div>
                    )}

                    {showAccessRights && isWeblinkOpened && (
                        <AccessRightsDropdown fromEditor={fromEditor} isSharedOrMouted={isSharedOrMouted} item={item} />
                    )}
                    {showDomesticSettings && (
                        <DomesticDropdown
                            isWeblinkOpened={isWeblinkOpened}
                            pin={pin}
                            formProps={formProps}
                            isPublicPasswordEnabled={isPublicPasswordEnabled}
                            storage={storage}
                            item={item}
                            onActionClick={onActionClick}
                            domain={domain}
                        />
                    )}

                    {showChangePassword && <WeblinkPublicPassword pin={pin} item={item} formProps={formProps} />}

                    <AdditionalSettings
                        item={item}
                        isWeblinkOpened={isWeblinkOpened}
                        showExpires={showExpires}
                        isDownloadable={isDownloadable}
                        isPhone={isPhone}
                    />
                    {showAutoDeleteSettings && (
                        <AutoDelete
                            item={item}
                            disabled={!isAutodeleteFeatureEnabled && isAutodeletePromoFeatureEnabled && isWeblinkOpened}
                            gaSuffix={gaSuffix}
                        />
                    )}
                </div>
            </div>
        </SharingNewSection>
    );
});
