import { Spacing, Text } from '@vkontakte/vkui';
import img from 'img/tooltip/change-public-design.png';
import React, { type RefObject, forwardRef, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { getCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement, ETooltipSize } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';
import { sendDwh } from 'reactApp/utils/ga';
import opener from 'reactApp/utils/opener';
import { getPublicUrl } from 'reactApp/utils/urlHelper';

import styles from './ChangePublicDesignTooltip.css';

type ChangePublicDesignTooltipProps = {
    onClose?: () => void;
    onShow?: () => void;
};
const eventCategory = 'tooltip-brand-page';

export const ChangePublicDesignTooltip = forwardRef<HTMLDivElement, ChangePublicDesignTooltipProps>(({ onClose, onShow }, ref) => {
    const [show, setShow] = useState(true);

    const currentForlder = useSelector(getCurrentFolder);
    const url = getPublicUrl(currentForlder);

    useEffect(() => {
        emitAnalyticEvent(AnalyticEventNames.TOOLTIP_BRAND_PAGE_SHOW);
        sendDwh({ eventCategory, action: 'show' });
        onShow?.();
    }, []);

    const handleClick = useCallback(() => {
        emitAnalyticEvent(AnalyticEventNames.TOOLTIP_BRAND_PAGE_CLICK);
        sendDwh({ eventCategory, action: 'click' });
        const urlObject = new URL(url);
        urlObject.searchParams.append('themeChooser', 'true');
        urlObject.searchParams.append('source', 'tooltip');
        opener(urlObject.toString());
        onClose?.();
        setShow(false);
    }, [url, onClose, setShow]);

    const handleClose = useCallback(() => {
        emitAnalyticEvent(AnalyticEventNames.TOOLTIP_BRAND_PAGE_CLOSE);
        sendDwh({ eventCategory, action: 'close' });
        onClose?.();
    }, [onClose]);

    if (!show) {
        return null;
    }

    return (
        <FloatingTooltip
            target={ref as RefObject<HTMLDivElement>}
            placement={ETooltipPlacement.top}
            size={ETooltipSize.large}
            closeOnOutsideClick
            closeOnScroll
            closable
            onClose={handleClose}
            qaId="change-public-design-toolbar-tooltip"
        >
            <div className={styles.root}>
                <Text className={styles.title}>Выбирайте оформление для папок</Text>
                <Spacing size={4} />
                <Text>С подпиской Mail Space можно устанавливать&nbsp;фон в папках, которыми&nbsp;делитесь</Text>
                <Spacing size={8} />
                <Text className={styles.infoText}>Функция доступна только на компьютере</Text>
                <Spacing size={8} />
                <Button onClick={handleClick} sizeMode={ButtonSizeMode.small} theme="vk" className={styles.button}>
                    Попробовать
                </Button>
                <img src={img} alt="Выбирайте оформление для папок" className={styles.image} />
            </div>
        </FloatingTooltip>
    );
});

ChangePublicDesignTooltip.displayName = 'ChangePublicDesignTooltip';
