import { Icon20CopyOutline, Icon20DocumentOutline, Icon20WriteOutline } from '@vkontakte/icons';
import React, { type FC } from 'react';
import { DropdownItemAction } from 'reactApp/ui/DropdownItemAction/DropdownItemAction';
import { DropdownList, DropdownTheme } from 'reactApp/ui/DropdownList/DropdownList';
import { useDropdownList } from 'reactApp/ui/DropdownList/DropdownList.hooks';
import { MenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/MenuButton/MenuButton';

import styles from './PdfEditMenuButton.css';

interface Props {
    onPdfEdit: () => void;
    onPdfCopyEdit: () => void;
}

export const PdfEditMenuButton: FC<Props> = ({ onPdfEdit, onPdfCopyEdit }) => {
    const { controlRef, isOpen, open, close, findDropdownPosition } = useDropdownList<HTMLDivElement>({ offsetTop: 8 });

    return (
        <>
            <MenuButton
                hint="Редактировать"
                icon={<Icon20WriteOutline />}
                hasDropdown
                isDropdownOpen={isOpen}
                ref={controlRef}
                onClick={onPdfEdit}
                onDropdownClick={() => {
                    if (isOpen) {
                        close();
                    } else {
                        open();
                    }
                }}
            />
            {isOpen && (
                <DropdownList
                    gaId="viewer-pdf-edit"
                    className={styles.list}
                    close={close}
                    closeOnResize
                    closeOnScroll
                    posX={0}
                    posY={0}
                    parentRef={controlRef}
                    theme={DropdownTheme.large}
                    calcPosition={findDropdownPosition}
                    list={[
                        {
                            text: 'Редактировать оригинал',
                            id: 'editOriginal',
                            className: styles.itemWrapper,
                            onClick: onPdfEdit,
                            icon: <Icon20DocumentOutline />,
                        },
                        {
                            text: 'Редактировать копию',
                            id: 'editCopy',
                            className: styles.itemWrapper,
                            onClick: onPdfCopyEdit,
                            icon: <Icon20CopyOutline />,
                        },
                    ]}
                    renderItem={(item) => <DropdownItemAction className={styles.item} text={item.text} />}
                />
            )}
        </>
    );
};
