import { useSelector } from 'react-redux';
import { hideDownloadItemSize, isEmptyFolder } from 'reactApp/appHelpers/appHelpers';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { attachesCloneLimit } from 'reactApp/appHelpers/featuresHelpers';
import { enableNewPersonalDocuments } from 'reactApp/appHelpers/featuresHelpers/features/newPersonalDocuments';
import { seriallyClearBinFeature } from 'reactApp/appHelpers/featuresHelpers/features/seriallyClearBin';
import {
    isMobileTrashbinSelectiveActive,
    isTrashbinSelectiveActive,
} from 'reactApp/appHelpers/featuresHelpers/features/tashbinSelectiveDeletion';
import { isThemedSharedToobarItem, isThemedSharedToobarItemMore } from 'reactApp/appHelpers/featuresHelpers/features/themedPublic';
import { BREAKPOINT_SM } from 'reactApp/constants/breakpoints';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { useActionsConfig } from 'reactApp/hooks/useActionsConfig.new';
import { useWindowSize } from 'reactApp/hooks/useWindowSize';
import { getCurrentAlbum } from 'reactApp/modules/albums/albums.selector';
import { getAllDocumentsCategory } from 'reactApp/modules/allDocuments/allDocuments.selectors';
import { AllDocumentsCategoryType } from 'reactApp/modules/allDocuments/allDocuments.types';
import { getFilesWithSelectedFace } from 'reactApp/modules/faces/faces.selectors';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { isPublicUploadEnabled } from 'reactApp/modules/public/public.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import {
    getCurrentTotalCount,
    getIdsCount,
    getItemsTotalCount,
    getStorageCurrentFolder,
    getStorageSelectedItems,
    isFolderWithAuthor,
} from 'reactApp/modules/storage/storage.selectors';
import { type CloudItem, EActions, EStorageType } from 'reactApp/modules/storage/storage.types';
import { getOpenedBinCountAll } from 'reactApp/modules/trashbin/trashbin.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { getIsLetterGroup } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.selectors';
import type { RootState } from 'reactApp/store';
import type { ListItem } from 'reactApp/ui/DropdownList/DropdownList';
import {
    actionsOrder,
    actionsOrder_EbookReader,
    actionsOrder_PdfEdit,
    moreListOrder,
    TOOLBAR_CONFIG,
} from 'reactApp/ui/Toolbar/Toolbar.config.new';
import {
    getClearItem,
    getCloneItem,
    getDownloadItem,
    getSelectItem,
    toolbarDropdownItems,
    toolbarItems,
    toolbarStartPageItems,
} from 'reactApp/ui/Toolbar/Toolbar.data.new';
import { type TToolbarItem, EToolbarItemTooltip, ToolbarContextEnum } from 'reactApp/ui/Toolbar/Toolbar.types';

const getPriority = (item) => (typeof item == 'number' ? item : undefined);

const checkAttachesCloneLimit = (storage: EStorageType, selectedCount: number) =>
    storage !== EStorageType.attaches || !attachesCloneLimit || selectedCount <= attachesCloneLimit;

// eslint-disable-next-line max-lines-per-function, complexity
export const useActions = ({
    storage,
    toolbarType,
    downloadButtonRef,
    isEbookReader,
    onDownload,
    isPublicOfOverquota,
}: {
    storage: EStorageType;
    toolbarType?: ToolbarContextEnum;
    downloadButtonRef?: React.RefObject<HTMLDivElement>;
    isEbookReader?: boolean;
    onDownload?(): void;
    isPublicOfOverquota?: boolean;
}): TToolbarItem[] => {
    const currentCategory = useSelector(getAllDocumentsCategory);
    const isIncomingItem = currentCategory === AllDocumentsCategoryType.sharedWithYou;
    const storageForToolbar = isIncomingItem ? EStorageType.incomingPublic : storage;

    const settings = TOOLBAR_CONFIG[storageForToolbar];

    const itemsCount = useSelector((state: RootState) => getIdsCount(state, storage as EStorageType));
    const selectedItems: CloudItem[] = useSelector((state: RootState) => getStorageSelectedItems(state, storage)) as CloudItem[];
    const filesWithFaces = useSelector(getFilesWithSelectedFace);
    const quotaCleanerIsLetterGroup = useSelector((state: RootState) => getIsLetterGroup(state));
    const currentFolder = useSelector((state: RootState) => getStorageCurrentFolder(state, storage));

    const { isHome, isQuotaCleaner, isPublic, isStock, isAlbums, isDocuments, isFavorites, isTemplates, isStart } = getStorage(storage);
    const isRootHome = isHome && currentFolder?.id === ROOT_FOLDER_ID;
    const totalItemsCount = useSelector((state: RootState) => getCurrentTotalCount(state, undefined, undefined));
    const selectedCount = selectedItems.length;
    const allSelected = !!selectedCount && selectedCount === totalItemsCount;
    const currentAlbum = useSelector(getCurrentAlbum);
    const isPublicUpload = useSelector(isPublicUploadEnabled);
    const folderWithAuthors = useSelector(isFolderWithAuthor);
    const isAnonymous = useSelector(UserSelectors.isAnonymous);
    const facesIsOff = !filesWithFaces.length;
    const binItemsTotalLength = useSelector((state: RootState) => getOpenedBinCountAll(state));

    // tempexp_17340-start
    const currentFolderIsShared = !selectedCount && currentFolder && 'weblink' in currentFolder && currentFolder.weblink;
    const changeDesignTooltipPromo = useSelector(PromoSelectors.getPromo(EPromoType.changePublicDesign));
    // tempexp_17340-end

    const itemsAllCount = useSelector(getItemsTotalCount);

    const showAuthorsFilter = settings?.authorSort && (folderWithAuthors || (isPublic && isPublicUpload)) && facesIsOff && !isAnonymous;

    const availableActions = useActionsConfig();

    const { windowWidth } = useWindowSize();

    if (!settings || toolbarType === ToolbarContextEnum.pageContentContext) {
        return [];
    }

    let filterHandler = (action: EActions) =>
        availableActions[action] &&
        Boolean(toolbarItems[action] || (isStart && toolbarStartPageItems[action])) &&
        Boolean(settings[action]);

    const hasActionInEmptyState = (action: EActions) => {
        let available = [EActions.selectAll];
        if (isAlbums) {
            available = [EActions.createAlbum];
        }

        if ((isDocuments && !enableNewPersonalDocuments) || isTemplates) {
            return false;
        }

        if (isHome && !isRootHome) {
            available = [...available, EActions.publish, EActions.share, EActions.remove];

            if (availableActions[EActions.changeDesign]) {
                available.push(EActions.changeDesign);
            }
        }

        return available.includes(action);
    };

    if (itemsCount === 0 && !currentAlbum && !showAuthorsFilter) {
        filterHandler = hasActionInEmptyState;
    }

    if (isPublicOfOverquota) {
        filterHandler = (action: EActions) => {
            return [EActions.clone, EActions.download].includes(action);
        };
    }

    const actionsOrderList = isEbookReader
        ? actionsOrder_EbookReader
        : storage === EStorageType.pdfEdit
        ? actionsOrder_PdfEdit
        : actionsOrder;

    const actions: TToolbarItem[] = actionsOrderList
        .filter(filterHandler)
        // eslint-disable-next-line complexity
        .map((action) => {
            let item: TToolbarItem = toolbarItems[action] || toolbarStartPageItems[action];

            if (!isFavorites && !isStart && [EActions.addToFavorites, EActions.removeFromFavorites].includes(action)) {
                return null;
            }

            if (action === EActions.download) {
                const isDownloadOff = isQuotaCleaner && quotaCleanerIsLetterGroup;
                const { isIncomingPublic } = getStorage(storage);
                if (
                    isDownloadOff ||
                    (!isIncomingPublic &&
                        !isIncomingItem &&
                        !isPublicOfOverquota &&
                        ((selectedItems?.length > 0 && hideDownloadItemSize(selectedItems)) || isEmptyFolder(currentFolder)))
                ) {
                    return null;
                }

                item = getDownloadItem(
                    selectedItems,
                    filesWithFaces,
                    currentFolder as CloudItem,
                    isRootHome,
                    downloadButtonRef,
                    isEbookReader
                );
                if (onDownload) {
                    const onClick = item.onClick;
                    item.onClick = (...args) => {
                        onDownload();
                        onClick?.(...args);
                    };
                }
            } else if (action === EActions.selectAll) {
                item = getSelectItem({
                    allSelected,
                    itemsAllCount,
                    itemsCount:
                        seriallyClearBinFeature && allSelected && storage === EStorageType.trashbin ? binItemsTotalLength : selectedCount,
                    disabled: itemsCount === 0 && !showAuthorsFilter,
                });
            } else if (action === EActions.clone) {
                if (!checkAttachesCloneLimit(storage, selectedCount)) {
                    return null;
                }
                item = getCloneItem(isPublic || isStock);
                // tempexp_17340-next-line
            } else if (action === EActions.changeDesign && currentFolderIsShared && isThemedSharedToobarItem) {
                item.tooltipId = changeDesignTooltipPromo ? EToolbarItemTooltip.changeDesign : undefined;
            } else if ((isTrashbinSelectiveActive || isMobileTrashbinSelectiveActive) && action === EActions.clear) {
                item = getClearItem(selectedCount);
            }

            if (
                ENABLE_FULL_RESPONSIVE &&
                windowWidth <= BREAKPOINT_SM &&
                (action === EActions.remove || action === EActions.publish || action === EActions.share)
            ) {
                return null;
            }

            return {
                ...item,
                priority: getPriority(settings[action]),
            };
        })
        .filter((el) => !!el) as TToolbarItem[];

    let moreListOrderFiltered = moreListOrder.filter((action) => availableActions[action] && Boolean(toolbarDropdownItems[action]));
    if (isFavorites) {
        moreListOrderFiltered = moreListOrderFiltered.filter(
            (o) => isFavorites && ![EActions.addToFavorites, EActions.removeFromFavorites].includes(o)
        );
    }
    if (isStart) {
        moreListOrderFiltered = moreListOrderFiltered.filter((action) => !toolbarStartPageItems[action]);
    }

    const moreList: ListItem[] = moreListOrderFiltered.map((action) => toolbarDropdownItems[action]);

    if (moreList.length) {
        if (ENABLE_FULL_RESPONSIVE && windowWidth <= BREAKPOINT_SM) {
            if (availableActions[EActions.publish]) {
                moreList.push(toolbarItems.publish as ListItem);
            }

            if (availableActions[EActions.share]) {
                moreList.push(toolbarItems.share as ListItem);
            }
        }

        actions.push({
            ...toolbarItems[EActions.more],
            // tempexp_17340-next-line
            ...(currentFolderIsShared &&
                isThemedSharedToobarItemMore &&
                changeDesignTooltipPromo && { tooltipId: EToolbarItemTooltip.changeDesign }),
            list: moreList,
        });
    }

    return actions;
};
