import classNames from 'clsx';
import React, { type ReactElement, memo } from 'react';
import type { IInlineInformerProps } from 'reactApp/ui/Informer/Informer.types';

import styles from './InlineInformer.css';

export const InlineInformer = memo(({ color, children, className, noPadding, newRoot }: IInlineInformerProps): ReactElement => {
    return (
        <div
            className={classNames(className, newRoot && styles.newRoot, styles.root, {
                [styles[`root_color_${color}`]]: color,
                [styles[`root_noPadding`]]: noPadding,
            })}
        >
            {children}
        </div>
    );
});

InlineInformer.displayName = 'InlineInformer';
