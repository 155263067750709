import { Icon16Cancel, Icon16CheckOutline } from '@vkontakte/icons';
import theme from '@vkontakte/vkui-tokens/themes/cloud/cssVars/theme';
import React from 'react';
import type { IFeatureData } from 'reactApp/constants/products/features.types';

const base: IFeatureData[] = [
    {
        text: 'Реклама в Почте и Облаке',
        key: 'ad',
        icon: <Icon16Cancel fill={theme.colorIconSecondary.normal.value} />,
    },
    {
        text: 'Загрузка файлов до 1 ГБ',
        key: 'upload',
        icon: <Icon16Cancel fill={theme.colorIconSecondary.normal.value} />,
    },
];

const paid: IFeatureData[] = [
    {
        text: 'Без рекламы в Почте и Облаке',
        key: 'ad',
        icon: <Icon16CheckOutline fill={theme.colorIconAccent.normal.value} />,
    },
    {
        text: 'Загрузка файлов до 100 ГБ',
        key: 'upload',
        icon: <Icon16CheckOutline fill={theme.colorIconAccent.normal.value} />,
    },
    {
        text: 'Возможность делиться местом',
        key: 'share',
        icon: <Icon16CheckOutline fill={theme.colorIconAccent.normal.value} />,
    },
    {
        text: 'Свободная память в телефоне с\u00A0автозагрузкой',
        key: 'autoupload',
        icon: <Icon16CheckOutline fill={theme.colorIconAccent.normal.value} />,
    },
];

export const WelcomeDefaultTariffsFeatures: Record<'base' | 'paid', IFeatureData[]> = {
    base,
    paid,
};
