import { useSelector } from 'react-redux';
import { IS_USER_WITH_ONLY_CLOUD } from 'reactApp/appHelpers/configHelpers';
import { isFamilyQuotaButtonEnabled, isFamilySubsAvailable, quotaTooltip } from 'reactApp/appHelpers/featuresHelpers';
import { cyberMonday2025 } from 'reactApp/appHelpers/featuresHelpers/features/cyberMonday2025';
import { abSpaceButton } from 'reactApp/appHelpers/featuresHelpers/features/spaceButton';
import { isFamilyEmpty, isUserFamilyOwner, userCanCreateFamily } from 'reactApp/modules/family/family.selectors';
import { isDefaultRootContent } from 'reactApp/modules/home/home.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { SpaceButtonAnalyticsName } from 'reactApp/ui/Space/SingleSpace/SingleMenu.analytics';

export const useContent = ({ isMarketingPromo, isSubscriptionsPage }) => {
    const isAlmostFull = useSelector(UserQuotaSelectors.isAlmostFull);
    const isOverQuota = useSelector(UserQuotaSelectors.isOverquota);
    const isFull = useSelector(UserQuotaSelectors.isFull);
    const is100PercentFull = useSelector(UserQuotaSelectors.is100PercentFull);
    const isPaidUser = useSelector(UserSelectors.isPaidUser);
    const isB2BUser = useSelector(UserSelectors.isB2BUser);
    const isBiz = useSelector(UserSelectors.isBizUser);
    const isDefaultContent = useSelector(isDefaultRootContent);

    const isFamilyOwner = useSelector(isUserFamilyOwner);
    const canCreateFamily = useSelector(userCanCreateFamily);
    const emptyFamily = useSelector(isFamilyEmpty);

    const isOverquota = isAlmostFull || isFull;

    let warning = '';

    if (isAlmostFull) {
        warning = 'Хранилище почти заполнено';
    }
    if (isOverQuota) {
        warning = 'Хранилище переполнено';
    }
    if (is100PercentFull) {
        warning = 'Хранилище заполнено';
    }

    let buttonText = 'Увеличить место';
    let analyticsName = SpaceButtonAnalyticsName.SPACE;

    const isMySubscription = isPaidUser && !isOverquota;
    // tempexp_17990-next-line
    if (isMySubscription && !abSpaceButton) {
        buttonText = 'Мои подписки';
        analyticsName = SpaceButtonAnalyticsName.SUBS;
    }

    // tempexp_17990-next-line
    const showFamilyButton =
        !isOverquota && isFamilySubsAvailable && isFamilyQuotaButtonEnabled && (canCreateFamily || (isFamilyOwner && emptyFamily));

    // tempexp_17990-next-line
    if (showFamilyButton && !abSpaceButton) {
        buttonText = 'Поделиться местом';
        analyticsName = SpaceButtonAnalyticsName.FAMILY;
    }

    if (isMarketingPromo) {
        buttonText = 'Увеличить место';
        analyticsName = SpaceButtonAnalyticsName.SPACE;
    }

    if (isMarketingPromo && !isOverquota && !isDefaultContent) {
        if (quotaTooltip === 'b') {
            buttonText = '+1 ТБ за 87 ₽ в месяц';
        } else if (quotaTooltip === 'c' || quotaTooltip === 'd') {
            buttonText = '+256 ГБ за 54 ₽ в месяц';
        }
    }

    if (cyberMonday2025) {
        analyticsName = SpaceButtonAnalyticsName.CYBER_MONDAY_2025;
    }

    const buttonState = {
        primary: !isMySubscription && !isOverquota,
        // tempexp_17990-next-line
        secondary: isMySubscription && !abSpaceButton,
        error: isOverquota,
    };

    return {
        buttonText,
        warning,
        buttonState,
        hideButton: isSubscriptionsPage || isB2BUser,
        // tempexp_17990-next-line
        isMySubscription: !abSpaceButton && isMySubscription && !showFamilyButton,
        hideMenu: (isSubscriptionsPage && IS_USER_WITH_ONLY_CLOUD) || isBiz,
        showFamilyButton,
        analyticsName,
    };
};
