import { xray } from 'lib/xray';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { sendPostMessage } from 'reactApp/utils/windowHelper';
import { EQueryParams } from 'server/helpers/getRequestParams';

export const enum PostMessageTypes {
    authorization = 'auth',
}

export interface SuperAppAuthorizationMessage {
    action: PostMessageTypes;
}

export const superAppPostMessage = (message: PostMessageTypes) => {
    if (message === PostMessageTypes.authorization) {
        sendPostMessage({
            action: message,
        });
        xray.send('public', {
            p: 'cloud-integration',
            i: 'send-post-message',
        });
    }
};

/**
 * Проверяет наличие query праметра для анонимов
 */
export const hasSuperAppVersion = (): boolean => {
    // CLOUDWEB-18049: Берем параметры из стейта, потому что они пропадают в просмотрщике
    return getQueryParams()[EQueryParams.version];
};
