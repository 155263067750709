/* eslint-disable max-lines-per-function */
import classNames from 'clsx';
import { parse } from 'qs';
import { equals } from 'ramda';
import React, { type ReactElement, memo, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { dispatchNewSearchRadar } from 'reactApp/modules/dwh/dwh.module';
/** tempexp_14729-next-line */
import { FeatureSelector } from 'reactApp/modules/features/components/FeatureSelector';
/** tempexp_14729-next-line */
import { getFeatureNewContentTable } from 'reactApp/modules/features/features.selectors';
import { isVirusItem } from 'reactApp/modules/file/utils';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { logHandler } from 'reactApp/ui/Datalist/DataList.helpers';
import { DataListGalleryItem } from 'reactApp/ui/DatalistGalleryItem/DataListGalleryItem';
import type { DataListItemType } from 'reactApp/ui/DataListItem/DataListItem.types';
import { useCalculatedOptions } from 'reactApp/ui/DataListItem/hooks/useCalculatedOptions';
import { useHandlers } from 'reactApp/ui/DataListItem/hooks/useHandlers';
import { DataListItemRow } from 'reactApp/ui/DataListItemRow/DataListItemRow';
import { DataListItemSquares } from 'reactApp/ui/DataListItemSquares/DataListItemSquares';
import { DataListItemThumb } from 'reactApp/ui/DataListItemThumb/DataListItemThumb';
import { DropNotify } from 'reactApp/ui/DropNotify/DropNotify';
import { noop } from 'reactApp/utils/helpers';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

import styles from './DataListItem.css';
import { getThumbUrl } from './DataListItem.helpers';
import { useGalleryProps } from './hooks/useGalleryProps';
import { useRowProps } from './hooks/useRowProps';
import { useThumbProps } from './hooks/useThumbProps';

// eslint-disable-next-line complexity
export const DataListItemComponent = ({
    id,
    selectableKey,
    onSelect,
    register,
    unregister,
    onContextMenu,
    showCheckbox,
    onClick,
    view,
    storage,
    isMobile,
    isFavoritesEnabled,
    isDragOver,
    isDragging,
    isSelected,
    isSelecting,
    onDraggingOver,
    tipData,
    onMouseLeave,
    onMouseDown,
    isActive,
    thumbType,
    isPopup,
    isPublicDownloaded,
    listOfBannedToFavoriteItems,
    item,
}: DataListItemType): ReactElement | null => {
    const starTime = Date.now();
    const ref = useRef<HTMLAnchorElement>(null);
    const favoritesAvailable = Boolean(isFavoritesEnabled);
    const isVirus = isVirusItem(item);
    const descriptorId = item && 'descriptorId' in item && item.descriptorId;
    const dispatch = useDispatch();
    const { query } = parse(window.location.search, { ignoreQueryPrefix: true });

    const { denyDoAction, skipDoubleClick, noThumbs, isDraggable, isDroppable, uploadingItem } = useCalculatedOptions({
        descriptorId,
        isDragOver,
        view,
        isMobile,
        storage,
        item,
    });

    const {
        onDownload,
        onFavorites,
        onPublish,
        handleOnClick,
        handleOnSelect,
        handleOnContextMenu,
        handleOnCheckboxClick,
        handleOnMouseDown,
        handleMouseLeave,
        handleMouseMove,
    } = useHandlers({
        item,
        id,
        uploadingItem,
        storage,
        denyDoAction,
        isMobile,
        isSelected,
        isDraggable,
        onClick,
        onSelect,
        onContextMenu,
        onMouseDown,
        onDraggingOver,
        onMouseLeave,
    });

    const baseProps = {
        view,
        item,
        uploadingItem,
        handleOnCheckboxClick,
        isSelected,
        isSelecting,
        isActive,
        tipData,
        id,
        favoritesAvailable,
        onFavorites,
        onPublish,
        onDownload,
        isVirus,
        thumb: noThumbs ? '' : getThumbUrl(item, isVirus, view, thumbType),
        isPopup,
        isPublicDownloaded,
        query,
        listOfBannedToFavoriteItems,
    };

    const rowProps = useRowProps({ ...baseProps, storage });
    const thumbProps = useThumbProps({ ...baseProps, showCheckbox });
    const galleryProps = useGalleryProps({ ...baseProps });

    useEffect(() => {
        if (ref.current) {
            register(selectableKey, ref.current);
        }

        return () => unregister(selectableKey);
    }, [selectableKey]);

    useEffect(() => {
        const endDate = Date.now();

        logHandler({ radarName: 'item_render', radars: [`${view}=${endDate.valueOf() - starTime.valueOf()}`] });
    }, []);

    if (!item) {
        return null;
    }

    const renderItem = () => {
        const progress = uploadingItem && 'progress' in uploadingItem ? Math.round(uploadingItem?.progress ?? 0) : undefined;

        if (view === EViewMode.list) {
            return rowProps && <DataListItemRow {...rowProps} denyDoAction={denyDoAction} progress={progress} isMobile={isMobile} />;
        }

        if (view === EViewMode.gallery) {
            return galleryProps && <DataListGalleryItem {...galleryProps} />;
        }

        if (view === EViewMode.squares || view === EViewMode.squares180) {
            return (
                <DataListItemSquares
                    isFolder={item.isFolder}
                    kind={item.kind}
                    thumbUrl={getThumbUrl(item, isVirus, view, thumbType)}
                    ext={item.isFolder ? undefined : item.ext}
                    isVirus={isVirus}
                    isSelected={isSelected}
                    id={id}
                    onCheckboxClick={handleOnCheckboxClick}
                    viewMode={view}
                />
            );
        }

        return (
            thumbProps && (
                /** tempexp_14729-next-line */
                <FeatureSelector
                    selector={getFeatureNewContentTable}
                    control={<DataListItemThumb {...thumbProps} denyDoAction={denyDoAction} progress={progress} />}
                    variant1={<DataListItemThumb.New {...thumbProps} denyDoAction={denyDoAction} progress={progress} />}
                />
            )
        );
    };

    const skipUseDoubleClick = skipDoubleClick || isPopup;

    const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
        if (storage === EStorageType.search || (storage === EStorageType.attaches && query)) {
            const dwhData = {
                eventCategory: ECategoryGa.entered,
                action: 'result-search-click-real',
            };
            const items = [
                {
                    file_name: 'nameWithoutExt' in item && item.nameWithoutExt,
                    type: item.kind,
                    pos: 'pos' in item && item.pos,
                    file_id: item.id,
                    extension: item.isFolder ? 'folder' : item.ext,
                    mtime: 'mtime' in item && item.mtime,
                    size: ('size' in item && item.size) || 0,
                },
            ];

            dispatch(
                dispatchNewSearchRadar({
                    dwhData,
                    items,
                })
            );
        }

        if (skipDoubleClick) {
            handleOnClick(event);
        } else {
            handleOnSelect(event);
        }
    };

    return (
        <a
            // TODO: строить ссылки, основываясь на storage. home - /home/:id, public - /:id
            href="#"
            tabIndex={-1}
            ref={ref}
            draggable={false}
            onMouseMove={isMobile ? noop : handleMouseMove}
            onMouseLeave={isMobile ? noop : handleMouseLeave}
            onMouseDown={isMobile ? noop : handleOnMouseDown}
            onContextMenu={isPopup ? noop : handleOnContextMenu}
            onClick={handleClick}
            onDoubleClick={skipUseDoubleClick ? noop : handleOnClick}
            data-id={id}
            data-qa-id={id}
            data-qa-type={item.isFolder ? 'folder' : 'file'}
            data-qa-name={item.name}
            data-draggable={true}
            className={classNames({
                [styles.root]: true,
                [styles.rootGrab]: isDragging,
                [styles.rootSelecting]: isSelecting,
                [styles.root_row]: view === EViewMode.list,
                [styles.root_gallery]: view === EViewMode.gallery,
                [styles.root_drag]: isDragging && isSelected,
                [styles.root_drop]: isDroppable,
            })}
        >
            {renderItem()}
            {isDroppable && item.isFolder && <DropNotify dragOverName={item.name} isAllowedToDrop={isDroppable} target={ref} />}
        </a>
    );
};

const areEqual = (prevProps: DataListItemType, nextProps: DataListItemType): boolean => {
    return (
        prevProps.id === nextProps.id &&
        prevProps.view === nextProps.view &&
        prevProps.showCheckbox === nextProps.showCheckbox &&
        prevProps.storage === nextProps.storage &&
        prevProps.onClick === nextProps.onClick &&
        prevProps.isFavoritesEnabled === nextProps.isFavoritesEnabled &&
        prevProps.isDragging === nextProps.isDragging &&
        prevProps.thumbType === nextProps.thumbType &&
        prevProps.isDragOver === nextProps.isDragOver &&
        prevProps.isSelected === nextProps.isSelected &&
        prevProps.isActive === nextProps.isActive &&
        prevProps.isSelecting === nextProps.isSelecting &&
        prevProps.selectableKey === nextProps.selectableKey &&
        // selectedIdxs - Это вместо проверки на колбеки. Если изменилось выделение - нужно получить новые колбеки для айтема, для этого обновить этот айтем
        // если просто проверять колбеки - то не оптимально и постоянно перерендеринг
        equals(prevProps.selectedIdxs, nextProps.selectedIdxs) &&
        prevProps.tipData === nextProps.tipData &&
        prevProps.item?.weblink === nextProps.item?.weblink
    );
};

export const DataListItem = memo(DataListItemComponent, areEqual);

DataListItem.whyDidYouRender = true;
