import classNames from 'clsx';
import React, { type ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { Portal } from 'react-portal';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { noop } from 'reactApp/utils/helpers';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';

import styles from './Hint.css';
import { calculatePosition } from './Hint.helpers';

const isMobile = EnvironmentSelectors.isMobile() || EnvironmentSelectors.isIpadOs();
const isPhone = EnvironmentSelectors.isPhone();

type Theme = '' | 'dark' | 'auto';

const getCurrentTheme = (theme: Theme) => {
    if (theme === 'auto') {
        return isDarkThemeModeEnabled(true) ? 'dark' : '';
    }

    return theme;
};

export enum HintPosition {
    relativeToElement,
    relativeToCursor,
}

export const Hint = ({
    children,
    text,
    theme = 'auto',
    delay = 1000,
    showDelay = false,
    onShow = noop,
    isPopupView,
    positionStrategy = HintPosition.relativeToElement,
}: {
    children: ReactElement;
    text: string | ReactElement;
    theme?: Theme;
    delay?: number;
    showDelay?: boolean;
    onShow?: () => void;
    isPopupView?: boolean;
    positionStrategy?: HintPosition;
}) => {
    const hintRef = useRef<HTMLDivElement | null>(null);
    const childrenRef = useRef<HTMLElement | null>(null);
    const [show, setShow] = useState(false);
    const [position, setPoison] = useState({ left: 0, top: 0 });
    const timerRef = useRef<number | null>(null);
    const currentTheme = getCurrentTheme(theme);

    const clearTimer = () => {
        if (timerRef.current) {
            window.clearTimeout(timerRef.current);
        }
        timerRef.current = null;
    };

    const showHint = useCallback(
        (e) => {
            if (!text) {
                return;
            }

            setPoison({
                left: e.pageX,
                top: e.pageY,
            });

            const show = () => {
                setShow(true);
                onShow();
            };

            if (showDelay) {
                clearTimer();
                timerRef.current = window.setTimeout(show, delay);
            } else {
                show();
            }
        },
        [text, showDelay, clearTimer]
    );

    const hideHint = useCallback(() => {
        setShow(false);
        clearTimer();
    }, []);

    useEffect(() => {
        window.addEventListener('fullscreenchange', hideHint);

        return (): void => {
            window.removeEventListener('fullscreenchange', hideHint);
            clearTimer();
        };
    }, []);

    const onClick = useCallback(
        (e) => {
            setShow(false);
            if (children.props.onClick) {
                children.props.onClick(e);
            }
            clearTimer();
        },
        [children]
    );

    useEffect(() => {
        if (hintRef.current) {
            calculatePosition(hintRef.current, position, positionStrategy, childrenRef?.current);
        }
    }, [show]);

    if (!text) {
        return children;
    }

    return (
        <div
            style={{ display: 'contents' }}
            onMouseEnter={isMobile ? noop : showHint}
            onMouseLeave={isMobile ? noop : hideHint}
            onClick={isPhone ? noop : onClick}
        >
            {show && text && (
                <Portal>
                    <div
                        ref={hintRef}
                        // isPopupView требуется для показа Hintа в попапах, z-index задается на 1 больше чем у всех попапов
                        style={isPopupView ? { zIndex: 100001 } : undefined}
                        className={classNames({ [styles.root]: true, [styles[`root_${currentTheme}`]]: !!currentTheme })}
                    >
                        {text}
                    </div>
                </Portal>
            )}
            {positionStrategy === HintPosition.relativeToElement ? React.cloneElement(children, { ref: childrenRef }) : children}
        </div>
    );
};
