import type { PayloadAction } from '@reduxjs/toolkit';
import { logger } from 'lib/logger';
import { xray } from 'lib/xray';
import { copyFileApiCall } from 'reactApp/api/FileCopyAPICall';
import { getAllDocumentsCurrentType, getExtensionsForType } from 'reactApp/modules/allDocuments/allDocuments.selectors';
import { EAllDocumentsType } from 'reactApp/modules/allDocuments/allDocuments.types';
import { getParent, isFolder } from 'reactApp/modules/file/utils';
import { isThisOrParentMounted } from 'reactApp/modules/home/home.selectors';
import { getNewItem } from 'reactApp/modules/modifying/getNewItem';
import { addFilesSuccess, copyRequestEnded, modifyingStart, modifyingStop } from 'reactApp/modules/modifying/modifying.actions';
import { errorsCopy } from 'reactApp/modules/modifying/modifying.constants';
import { getSnackbarButton, getSnackbarText } from 'reactApp/modules/modifying/modifying.helpers';
import { type EditPdfCopyItem, type ICopyItems, EModifyReason, ESnackbarType } from 'reactApp/modules/modifying/modifying.types';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { type CloudFile, type CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { updateUser } from 'reactApp/modules/user/user.thunkActions';
import { EConflictMode } from 'reactApp/types/EConflictMode';
import { openDisabledFeaturePopupHelper } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.helpers';
import { DisabledFeature } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.types';
import { captureException } from 'reactApp/utils/tracer';
import { all, put, select } from 'redux-saga/effects';

const copyApiCall = ({ id, folder, conflict = EConflictMode.RENAME }) => copyFileApiCall({ id, folder, conflict });

function copyRequest(id, folder) {
    return copyApiCall({ id, folder })
        .then((response) => ({ id: response?.data?.body }))
        .catch((error) => ({ error: error?.response?.body }));
}

export function* processCopyItems(oldItems: any[], newIds: any, destination: string) {
    let foldersCount = 0;
    let filesCount = 0;

    const storage: EStorageType = yield select(getCurrentStorage);
    const snackbarItems: { name: string }[] = [];
    const isMounted: boolean = yield select(isThisOrParentMounted, destination);
    let extensions: undefined | string[];

    if (storage === EStorageType.alldocuments) {
        const documentType = yield select(getAllDocumentsCurrentType) || EAllDocumentsType.document;
        extensions = yield select(getExtensionsForType, documentType);
    }

    const newItems = oldItems.reduce((result, item, index) => {
        const newId = newIds[index]?.id;

        if (!newId) {
            return result;
        }

        const isFolderItem = isFolder(item);
        foldersCount += Number(isFolderItem);
        filesCount += Number(!isFolderItem);

        const newItem: CloudItem = {
            ...getNewItem({ item, newId, isMounted }),
            weblink: '',
            isInFavorites: false,
            kind: isFolderItem ? 'folder' : item.kind,
            mtime: Date.now(),
            author: undefined,
        };

        result.push(newItem);

        snackbarItems.push({ name: item.name });

        return result;
    }, []);

    const { error } = newIds.find((item) => item.error) || {};

    if (error) {
        yield put(
            showSnackbarAction({
                id: 'copy-items-error',
                type: SnackbarTypes.failure,
                text: errorsCopy.getMessage(error),
                closable: true,
            })
        );

        captureException(error, { message: 'MOVE' });
    }

    if (!newItems.length) {
        yield put(modifyingStop({ storage, reason: EModifyReason.add }));

        return;
    }

    yield put(addFilesSuccess({ items: newItems, parent: destination, storage, reason: EModifyReason.copy, allowedExt: extensions }));

    yield closePopupHelper(popupNames.SELECT_FOLDER_DIALOG);
    yield put(modifyingStop({ storage, reason: EModifyReason.add }));

    yield put(copyRequestEnded({ ids: newIds.map((item) => item.id) }));

    yield put(
        showSnackbarAction({
            closable: true,
            id: 'copy-items-success',
            type: SnackbarTypes.success,
            ...getSnackbarButton({ items: newItems, destination }),
            text: getSnackbarText({ items: snackbarItems, foldersCount, filesCount, type: ESnackbarType.COPY }),
        })
    );

    // @ts-ignore
    yield put(updateUser());
}

export function* handleCopyItems(action: PayloadAction<ICopyItems>) {
    const { items, destination } = action.payload;

    yield put(modifyingStart());

    try {
        const newIds = yield all(items.map((item) => copyRequest(item.id, destination)));

        yield processCopyItems(items, newIds, destination);
    } catch (error) {
        logger.error(error);
        captureException(error);
        yield put(modifyingStop({ reason: EModifyReason.copy }));
    }
}

export function* handeEditPdfCopy(action: PayloadAction<EditPdfCopyItem>) {
    const { item, onPdfEdit } = action.payload;
    const isMounted: boolean = yield select(isThisOrParentMounted, item.parent);
    const storage: EStorageType = yield select(getCurrentStorage);
    const { isFull: isOverquota } = yield select(UserSelectors.getCloudSpace);
    yield put(modifyingStart());

    try {
        const newId = yield copyRequest(item.id, getParent(item.id));

        const newItem: CloudFile = {
            ...getNewItem({ item, newId: newId?.id, isMounted }),
            weblink: '',
            isInFavorites: false,
            kind: item.kind,
            mtime: Date.now(),
            author: undefined,
        };

        yield put(modifyingStop({ storage, reason: EModifyReason.add }));
        yield put(copyRequestEnded({ ids: [newId?.id] }));

        onPdfEdit(newItem);
    } catch (error) {
        xray.send(`pdf_edit_copy_error`);
        logger.error(error);
        yield put(modifyingStop({ reason: EModifyReason.copy }));
        if (isOverquota) {
            openDisabledFeaturePopupHelper({ disabledFeature: DisabledFeature.newFile });
            return;
        }
        yield put(
            showSnackbarAction({
                id: 'copy-items-error',
                type: SnackbarTypes.failure,
                text: errorsCopy.getMessage(error),
                closable: true,
            })
        );
    }
}
