import { logger } from 'lib/logger';
import { DigestAPICall } from 'reactApp/api/DigestAPICall';
import { itemMapper, itemV4ToV2 } from 'reactApp/api/helpers/apiV4Helpers';
import { PrivateListAPICall } from 'reactApp/api/PrivateListAPICall';
import { CAMERA_UPLOADS_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { favoritesFetchCountSuccess, favoritesFetchSuccess } from 'reactApp/modules/favorites/favorites.actions';
import { feedLoadSuccess } from 'reactApp/modules/feed/feed.module';
import { FeedCategoryType } from 'reactApp/modules/feed/feed.types';
import { cameraUploadsStatisticsSuccess, homeStatisticsSuccess, loadFolderSuccess } from 'reactApp/modules/home/home.actions';
import { loadIncomingSuccess } from 'reactApp/modules/incoming/incoming.module';
import { loggerSaga } from 'reactApp/modules/logger/logger.saga';
import { routeChangeStart, routeStatusPage } from 'reactApp/modules/router/router.module';
import {
    loadSharedLinksSuccess,
    updateSharedIncomingCountSuccess,
    updateSharedLinksCountSuccess,
} from 'reactApp/modules/shared/shared.module';
import { defaultSort } from 'reactApp/modules/sort/sort.selectors';
import { DEFAULT_API_LIMIT } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { loadUser } from 'reactApp/modules/user/user.thunkActions';
import { EStatus } from 'reactApp/sections/ErrorPage/ErrorPage.types';
import type { Sort } from 'reactApp/types/Tree';
import { sendGa } from 'reactApp/utils/ga';
import { all, call, cancel, put, select, takeEvery } from 'redux-saga/effects';

import { digestRequest, loadStartRequest, loadStartSuccess } from './start.module';

const callDigestLoadApi = (): any => new DigestAPICall().makeRequest();
export const callPrivateListLoadApi = (path: string, limit: number, offset: number, sort?: Sort): any =>
    new PrivateListAPICall().makeRequest({ path, limit, offset: offset >= 0 ? offset : 0, sort: sort?.type, order: sort?.order });

export function* getPrivateList(path = '/', limit = DEFAULT_API_LIMIT, offset = 0, sort = defaultSort) {
    const { data } = yield call(callPrivateListLoadApi, path, limit, offset, sort);

    try {
        return itemV4ToV2(data);
    } catch (error) {
        yield call(loggerSaga, { error, action: routeStatusPage({ status: EStatus.NOT_FOUND }) });
    }
}

function* loadDigest() {
    const startTime = Date.now();

    const [{ data }, homeData] = yield all([callDigestLoadApi(), getPrivateList()]);

    sendGa('startsg', 'apicall', 'time', { ms: Date.now() - startTime });

    const sharedIncoming = data?.imports?.list?.map(itemMapper) || [];
    const invites = data?.invites?.list?.map(itemMapper) || [];

    const mountedFoldersIds = sharedIncoming.map((item) => (item.home ? `${item.home}/` : null)).filter((home) => !!home);
    const feedItems = data?.feed?.list?.map(itemMapper);

    const cameraUploadFolder = homeData?.list?.find(({ home }) => home === CAMERA_UPLOADS_FOLDER_ID) || {};

    yield all([
        put(
            favoritesFetchSuccess({
                favorites: {
                    objects: data?.favorites?.list?.map(itemMapper) || [],
                },
                sharedIncoming: [...sharedIncoming],
            })
        ),
        put(
            favoritesFetchCountSuccess({
                count: data?.favorites?.count,
            })
        ),
        put(
            loadIncomingSuccess({
                // incoming api  has no tree field, but it is used as id in reducer
                list: [...sharedIncoming, ...invites.map((item) => ({ ...item, invite_token: item.token }))],
            })
        ),
        put(
            updateSharedIncomingCountSuccess({
                count: data?.imports?.count,
            })
        ),
        put(
            loadSharedLinksSuccess({
                list: [...(data?.public?.list?.map(itemMapper) || [])],
            })
        ),
        put(
            updateSharedLinksCountSuccess({
                ...data?.public?.count,
            })
        ),
        put(
            feedLoadSuccess({
                category: FeedCategoryType.all,
                data: {
                    count: data?.feed?.list?.length,
                    objects: feedItems,
                },
                mountedFoldersIds,
            })
        ),
        put(
            homeStatisticsSuccess({
                photos: data?.count?.photos,
                videos: data?.count?.videos,
                size: data?.count?.size,
            })
        ),
        put(loadFolderSuccess(homeData)),
        put(
            loadFolderSuccess({
                ...cameraUploadFolder,
                kind: 'camera-upload',
                type: 'folder',
                home: CAMERA_UPLOADS_FOLDER_ID,
                name: CAMERA_UPLOADS_FOLDER_ID.slice(1),
                count: data?.gallery.count,
                rev: 0,
                grev: 0,
                tree: 'none',
                size: 1,
                list: data?.gallery?.list?.map(itemMapper),
            })
        ),
        put(
            cameraUploadsStatisticsSuccess({
                photos: data?.gallery?.count?.photos,
                videos: data?.gallery?.count?.videos,
                size: data?.gallery?.count?.size,
            })
        ),
    ]);
}

function* loadAllDataForStartPage(action) {
    try {
        const startTime = Date.now();

        yield put(routeChangeStart({ id: '/', storage: EStorageType.start, params: {}, __isFolder: true, url: '', __parent: '' }));

        const state = yield select();

        yield loadUser()(action.payload.dispatch, () => state);

        yield loadDigest();

        yield put(loadStartSuccess());

        sendGa('startsg', 'load-new', 'time', { ms: Date.now() - startTime });
    } catch (error) {
        logger.error(error);
        yield cancel();
    }
}

function* handleLoadDataForStartPageRequest(action) {
    yield loadAllDataForStartPage(action);
}

export function* watchLoadStart() {
    yield takeEvery(loadStartRequest.toString(), handleLoadDataForStartPageRequest);
    yield takeEvery(digestRequest.toString(), loadDigest);
}
