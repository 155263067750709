import React, { type ReactElement, type RefObject, forwardRef, useCallback, useMemo } from 'react';
import { IS_REQUIRED_B2B_PASSWORD_PUBLIC } from 'reactApp/appHelpers/configHelpers';
import { DropdownItemAction } from 'reactApp/ui/DropdownItemAction/DropdownItemAction';
import { type ListItem, DropdownFont, DropdownList, DropdownTheme } from 'reactApp/ui/DropdownList/DropdownList';
import { Hint } from 'reactApp/ui/Hint/Hint';

import { type ChangePublicPasswordMenuItem, CHANGE_PUBLIC_PASSWORD_MENU_LIST, ChangePublicPasswordMenuItemId } from './Dropdown.data';

type DropdownProps = {
    isExternalAccess: boolean;
    onClose: () => void;
    onDeletePassword: () => void;
    onChangePassword: () => void;
};

const getTooltip = (disabled: boolean) => {
    if (disabled) {
        return 'Чтобы удалить пароль, выберите «Только для пользователей домена» в настройках доступа';
    }

    return '';
};

export const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
    ({ isExternalAccess, onClose, onDeletePassword, onChangePassword }, ref) => {
        const controlRef = ref as RefObject<HTMLDivElement>;

        const onItemClick = useCallback(
            (item: ChangePublicPasswordMenuItem) => () => {
                if (item.id === ChangePublicPasswordMenuItemId.delete) {
                    onDeletePassword();
                } else if (item.id === ChangePublicPasswordMenuItemId.change) {
                    onChangePassword();
                }
            },
            [onDeletePassword, onChangePassword]
        );

        const list = useMemo(() => {
            return CHANGE_PUBLIC_PASSWORD_MENU_LIST.map((item) => {
                return {
                    ...item,
                    disabled: item.id === ChangePublicPasswordMenuItemId.delete && isExternalAccess && IS_REQUIRED_B2B_PASSWORD_PUBLIC,
                    onClick: onItemClick(item),
                };
            });
        }, [isExternalAccess, onItemClick]);

        const renderDropdownItem = (item: ListItem): ReactElement => {
            const tooltip = getTooltip(Boolean(item.disabled));
            return (
                <Hint text={tooltip} showDelay>
                    <DropdownItemAction
                        text={item.text}
                        icon={item.icon}
                        font={DropdownFont.VKSansDisplay}
                        mod={item.id === ChangePublicPasswordMenuItemId.delete ? 'remove' : undefined}
                    />
                </Hint>
            );
        };

        const findDropdownPosition = (dropdownWidth): { posX: number; posY: number } => {
            let posX = 0;
            let posY = 0;

            if (controlRef?.current) {
                const elRect = controlRef?.current.getBoundingClientRect();

                posY = elRect.height + window.scrollY + elRect.top;
                posX = elRect.left + elRect.width - dropdownWidth + window.scrollX;
            }

            return { posX, posY };
        };

        return (
            <DropdownList
                gaId="change-public-password"
                list={list}
                close={onClose}
                closeOnScroll
                parentRef={ref as RefObject<HTMLDivElement>}
                renderItem={renderDropdownItem}
                font={DropdownFont.VKSansDisplay}
                theme={DropdownTheme.small}
                calcPosition={findDropdownPosition}
            />
        );
    }
);

Dropdown.displayName = 'ChangePublicPasswordMenu';
