import addDays from 'date-fns/addDays';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { getUserOverquotaDates } from 'reactApp/modules/profile/profile.selectors';
import styles from 'reactApp/ui/OverquotaPopup/newSplash/OverquotaSplash.css';
import { formatDefaultDate } from 'reactApp/utils/formatDate';

export const Letters: React.FC = memo(() => {
    const { clearTime, blockLetterDate, startPipeline }: ReturnType<typeof getUserOverquotaDates> = useSelector(getUserOverquotaDates);

    const startDate = startPipeline ? addDays(new Date(startPipeline), 1).valueOf() : 0;

    return (
        <div className={styles.letters}>
            {!!startDate && (
                <div className={styles.lettersRow}>
                    <div className={styles.letterText}>
                        <div>Письмо</div>
                        <span> о переполненной памяти</span>
                    </div>
                    <div>{formatDefaultDate(startDate)}</div>
                </div>
            )}
            {!!blockLetterDate && (
                <div className={styles.lettersRow}>
                    <div className={styles.letterText}>
                        <div>Письмо</div>
                        <span> о блокировке доступа</span>
                    </div>
                    <div>{formatDefaultDate(blockLetterDate)}</div>
                </div>
            )}
            {!!clearTime && (
                <div className={styles.lettersRow}>
                    <span>Автоматическое удаление файлов</span>
                    <div>{formatDefaultDate(clearTime)}</div>
                </div>
            )}
        </div>
    );
});

Letters.displayName = 'Letters';
