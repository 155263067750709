export const DESKTOP_REWIND_TIME = 5; // в секундах
export const MOBILE_REWIND_TIME = 10; // в секундах

export const enum Sides {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
    CENTER = 'CENTER',
}

export const enum WaitDelay {
    toSecondClick = 300,
    beforeForget = 1000,
}

export const MIN_TIME_TO_REWIND = WaitDelay.beforeForget / 1000; // Приводим к секундам деля на 1000
