import classNames from 'clsx';
import { ReactComponent as AutodeleteIcon } from 'img/icons/mobile/autodelete.svg';
import { ReactComponent as TimeIcon } from 'mrg-icons/src/mailru/status/time_outline.svg';
import React, { type ReactElement, memo } from 'react';
import { useSelector } from 'react-redux';
import { getPublicExpires, isPublicAutodelete } from 'reactApp/modules/public/public.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { isRootCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import type { RootState } from 'reactApp/store';
import { formatAutoDeleteExpires, formatExpires } from 'reactApp/utils/formatDate';

import styles from './ExpiresInfo.css';

export const ExpiresInfo = memo(({ colored, className = '' }: { colored?: boolean; className?: string }): ReactElement | null => {
    const storage = useSelector(getCurrentStorage);
    const isRoot = useSelector((state: RootState) => isRootCurrentFolder(state, storage));

    const isAutodelete = useSelector(isPublicAutodelete);
    const expires = useSelector(getPublicExpires);

    if (!isRoot || !expires) {
        return null;
    }

    return (
        <div
            className={classNames({
                [styles.root]: true,
                [styles.root_colored]: colored,
                [className]: !!className,
            })}
        >
            <div className={styles.icon}>
                {isAutodelete ? (
                    <AutodeleteIcon height={18} width={18} fill="currentColor" />
                ) : (
                    <TimeIcon height={16} width={16} fill="currentColor" />
                )}
            </div>
            {isAutodelete ? `Папка будет удалена ${formatAutoDeleteExpires(expires)}` : `Папка будет доступна до ${formatExpires(expires)}`}
        </div>
    );
});

ExpiresInfo.displayName = 'ExpiresInfo';
