import classNames from 'clsx';
import { ReactComponent as CelebrationIcon } from 'img/icons/celebration.svg';
import { ReactComponent as PhotoIcon } from 'img/icons/photo.svg';
import React, { memo } from 'react';
import { EStoryType } from 'reactApp/modules/stories/stories.types';

import styles from './StoryType.css';

interface Props {
    type?: EStoryType;
    shortMode?: boolean;
    size?: 'small';
}

const config = {
    [EStoryType.selectionStory]: {
        icon: (size = 20) => <CelebrationIcon width={size} height={size} />,
        text: 'Подборки',
    },
    [EStoryType.historyStory]: {
        icon: (size = 20) => <PhotoIcon width={size} height={size} />,
        text: 'День в истории',
    },
};

export const StoryType = memo(function StoryType({ type, size, shortMode = false }: Props): JSX.Element | null {
    if (!type) {
        return null;
    }

    const getIconSize = size === 'small' ? 16 : shortMode ? 12 : 20;

    return (
        <div className={classNames({ [styles.root]: true, [styles[`root_${size}`]]: !!size, [styles.root_short]: shortMode })}>
            <div>{config[type]?.icon(getIconSize) ?? null}</div>
            {!shortMode && <div className={styles.text}>{config[type]?.text ?? null}</div>}
        </div>
    );
});
