import { Spacing } from '@vkontakte/vkui';
import React, { memo } from 'react';
import { Placeholder } from 'reactApp/ui/Placeholder/Placeholder';

import styles from './Loader.css';

type LoaderProp = {
    withButton: boolean;
};

export const Loader = memo<LoaderProp>(({ withButton }) => {
    return (
        <div className={styles.root}>
            <div className={styles.title}>
                <Placeholder />
            </div>
            <Spacing size={12} />
            <div className={styles.quota}>
                <Placeholder />
            </div>
            {withButton && (
                <>
                    <Spacing size={16} />
                    <div className={styles.button}>
                        <Placeholder />
                    </div>
                </>
            )}
        </div>
    );
});

Loader.displayName = 'SpaceOverquotaSimplifiedLoader';
