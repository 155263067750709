import { Spacing, Text } from '@vkontakte/vkui';
import { xray } from 'lib/xray';
import throttle from 'lodash.throttle';
import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_MOBILE_BROWSER, PROMO_TARIFFS } from 'reactApp/appHelpers/configHelpers';
import { type PromoQuotaFakedoorModal, promoQuotaFakedoor } from 'reactApp/appHelpers/featuresHelpers/features/promoQuotaFakedoor';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { openTariffBuy } from 'reactApp/modules/payment/payment.module';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';

import styles from './DynamicFakedoorModal.css';
import type { DynamicFakedoorModalProps } from './DynamicFakedoorModal.types';

export const DynamicFakedoorModal = memo<DynamicFakedoorModalProps>(({ onClose }) => {
    const {
        modalTitle,
        modalDescription,
        modalImageUrl,
        modalPrimaryButtonText,
        modalSecondaryButtonText,
        modalSecondaryButtonEnable,
    }: PromoQuotaFakedoorModal = promoQuotaFakedoor;

    const productId = PROMO_TARIFFS.partnersFakedoor;
    const dispatch = useDispatch();

    const isPaidUser = useSelector(UserSelectors.isPaidUser);
    const { usedSize, freeSize } = useSelector(UserQuotaSelectors.getSpace);
    const dwhData = {
        platform: IS_MOBILE_BROWSER ? 'mobile' : 'desktop',
        paid_user: isPaidUser,
        free_quota: freeSize,
        size_quota: usedSize,
        project: 'landing_ms',
        name: 'landing_block_special_offer',
    };

    useEffect(() => {
        xray.send('click', {
            p: 'fakedoor',
            dwh: {
                ...dwhData,
                type_element: 'modal',
                name_element: 'modal_thanks',
            },
        });
    }, []);

    const handlePrimaryClick = useCallback(() => {
        xray.send('click', {
            p: 'fakedoor',
            dwh: {
                ...dwhData,
                type_element: 'button',
                name_element: 'button_on_modal_1',
            },
        });
        onClose?.();
        if (!isPaidUser) {
            dispatch(
                openTariffBuy({
                    productId,
                    isMobile: false,
                    paySource: 'promo_quota_fakedoor',
                })
            );
        }
    }, [onClose, isPaidUser, productId]);

    const handleSecondaryClick = useCallback(() => {
        xray.send('click', {
            p: 'fakedoor',
            dwh: {
                ...dwhData,
                type_element: 'button',
                name_element: 'button_on_modal_2',
            },
        });
        onClose?.();
    }, [onClose]);

    const handleCloseClick = useCallback(() => {
        xray.send('click', {
            p: 'fakedoor',
            dwh: {
                ...dwhData,
                type_element: 'cross',
                name_element: 'cross_on_modal',
            },
        });
        onClose?.();
    }, [onClose]);

    const onPrimaryButtonHover = useCallback(() => {
        xray.send('hover', {
            p: 'fakedoor',
            dwh: {
                ...dwhData,
                type_element: 'button',
                name_element: 'button_on_modal_1',
            },
        });
    }, [dwhData]);

    const onSecondaryButtonHover = useCallback(() => {
        xray.send('hover', {
            p: 'fakedoor',
            dwh: {
                ...dwhData,
                type_element: 'button',
                name_element: 'button_on_modal_2',
            },
        });
    }, [dwhData]);

    const content = (
        <>
            <Spacing size={12} />
            <Text className={styles.text}>{modalDescription}</Text>
            <Spacing size={24} />
        </>
    );

    return (
        <WhatsNewDialog
            title={modalTitle}
            content={content}
            imageUrl={modalImageUrl}
            primaryButtonText={modalPrimaryButtonText}
            onClick={handlePrimaryClick}
            secondaryButtonText={modalSecondaryButtonEnable ? modalSecondaryButtonText : undefined}
            onSecondary={modalSecondaryButtonEnable ? handleSecondaryClick : undefined}
            onClose={handleCloseClick}
            qaId="promo-quota-fakedoor-modal"
            imageBgColor="var(--vkui--color_background_secondary)"
            buttonTheme="vk"
            dialogSize="tiny"
            onPrimaryButtonHover={throttle(onPrimaryButtonHover, 3000)}
            onSecondaryButtonHover={throttle(onSecondaryButtonHover, 3000)}
        />
    );
});

DynamicFakedoorModal.displayName = 'DynamicContainerFakedoorModal';
