import type { AxiosResponse, Method } from 'lib/axios';
import { APICallV4 } from 'reactApp/api/APICall';
import type { WeblinksIncomingApiResponse } from 'reactApp/modules/incomingPublic/incomingPublic.types';

const method = 'weblinks/email/access/available/links';

class WeblinksIncomingAPIV4Call extends APICallV4 {
    _method = method;
    _type: Method = 'post';
}

export const weblinksIncomingApiCall = (params: Record<string, any>): Promise<AxiosResponse<WeblinksIncomingApiResponse>> => {
    return new WeblinksIncomingAPIV4Call().makeRequest({
        limit: params.limit,
        cursor: params.cursor,
        exts: params.exts,
    });
};
