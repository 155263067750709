import React from 'react';
import { getTypeOfPeriodName } from 'reactApp/utils/Period';

import styles from './PersonalDiscountPrice.css';

interface PersonalDiscountPriceProps {
    period: string;
    newPrice: number;
    percent?: number;
}

export function PersonalDiscountBanner({ compact = false, percent }: { compact?: boolean; percent?: number }) {
    return percent ? (
        <div className={styles.discount}>
            {compact ? '-' : 'скидка'}&nbsp;
            {percent}%
        </div>
    ) : null;
}

export function PersonalDiscountPrice({ percent, newPrice, period }: PersonalDiscountPriceProps) {
    return (
        <div className={styles.root}>
            <div className={styles.priceContainer}>
                <span className={styles.priceContainer}>
                    {newPrice} ₽ в {getTypeOfPeriodName(period)}
                </span>
            </div>
            <PersonalDiscountBanner percent={percent} />
        </div>
    );
}
