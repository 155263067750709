import addDays from 'date-fns/addDays';
import isAfter from 'date-fns/isAfter';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { getFeatureAskPhoneDomains } from 'reactApp/modules/features/features.selectors';
import { closePopupHelper, openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { getCurrentStorage, isQuotaPageWithoutPromos } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserStorageSelectors } from 'reactApp/modules/user/userStorage';
import { ASK_PHONE_DIALOG } from 'reactApp/ui/AskPhoneDialog/AskPhoneDialog.constants';
import { isFramed } from 'reactApp/utils/isFramed';
import { select } from 'redux-saga/effects';

export function* watchAskPhone() {
    if (isQuotaPageWithoutPromos()) {
        return;
    }

    const isVerifiedAccount = yield select(UserSelectors.isVerifiedAccount);
    const userDomain = yield select(UserSelectors.getDomain);
    const settings = yield select(getFeatureAskPhoneDomains);
    const queryParams = getQueryParams();

    if (!settings?.[userDomain]) {
        return;
    }

    const freq = settings[userDomain].showFreqDays;
    if (freq && !isVerifiedAccount) {
        const shownDate = yield select(UserStorageSelectors.get, ASK_PHONE_DIALOG);
        const isDate = parseInt(shownDate);

        if (isDate && !isAfter(new Date(), addDays(shownDate, freq))) {
            return;
        }
    }

    if (!isVerifiedAccount) {
        const storage = yield select(getCurrentStorage);

        if (
            [
                EStorageType.quotaTariffs,
                EStorageType.quotaCleaner,
                EStorageType.quotaPartner,
                EStorageType.subscriptions,
                EStorageType.promocodes,
                EStorageType.attaches,
            ].includes(storage) ||
            queryParams?.action === 'request-payment' ||
            isFramed()
        ) {
            return;
        }

        openPopupHelper({
            popupName: popupNames.MUST_ATTACH_PHONE,
            onClose: () => closePopupHelper(popupNames.MUST_ATTACH_PHONE),
            data: {
                text: settings[userDomain].text,
                closable: settings[userDomain].closable,
            },
        });
    } else if (queryParams?.action === ASK_PHONE_DIALOG && queryParams.success === '1') {
        openPopupHelper({
            popupName: popupNames.SUCCESS_ATTACH_PHONE,
        });
    }
}
