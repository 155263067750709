import type { TooltipModelProps } from '@mail/3promo/models/tooltip';
import type { ReactRendererComponent } from '@mail/3promo/render/react';
import React from 'react';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement, ETooltipSize } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';

import styles from './PdfAutosavePromo.css';

export const PdfAutosavePromo: ReactRendererComponent<TooltipModelProps<unknown>> = ({ getTarget, close }) => {
    const moreButton = getTarget();

    return (
        <FloatingTooltip
            target={moreButton}
            placement={ETooltipPlacement.bottomEnd}
            qaId="tooltip-more-button"
            size={ETooltipSize.large}
            onClose={close}
        >
            <div className={styles.tooltip}>
                <div className={styles.tooltipTitle}>Автосохранение файла</div>
                <div className={styles.tooltipText}>Вы можете включать или выключить автосохранение изменений в PDF</div>
            </div>
        </FloatingTooltip>
    );
};
