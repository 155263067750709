import { getSegmentByQuota, getUsableSpaceRatio, Segments } from '@mail/cross-quota-utils';
import { type Space, bytesToNDigits } from '@mail/cross-sizes-utils';
import { BASEQUOTA, IS_FAMILY_USER, IS_ONPREMISE, USER_EMAIL } from 'reactApp/appHelpers/configHelpers';
import { isFamilySubsAvailable } from 'reactApp/appHelpers/featuresHelpers';
import { SPACE_LIST } from 'reactApp/constants/spaceList';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { IS_USER_AND_FAMILY_QUOTA_ENABLED } from 'reactApp/modules/userAndFamilyQuota/userAndFamilyQuota.helpers';
import { getOverQuota, UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import type { RootState } from 'reactApp/store';
import type { LoadingState } from 'reactApp/types/commonStates';
import { createSelector } from 'reselect';

import type { FamilyPlan, FamilyState, FamilyUser } from './family.types';

const getFamilyState = (state: RootState): FamilyState => state.family;
export const getFamilyList = (state: RootState) => getFamilyState(state).list;
export const getFamilyPlan = (state: RootState): FamilyPlan | undefined => getFamilyState(state).familyPlan;

export const getFamilyLoadingState = createSelector(
    getFamilyState,
    (state: FamilyState): LoadingState => ({
        isLoading: state.isLoading,
        isLoaded: state.isLoaded,
        hasError: state.hasError,
    })
);

export const getFamilyLinkLoadingState = createSelector(getFamilyState, (state): LoadingState => state.loadingLinkState);

export const isUserWithFamily = createSelector(getFamilyList, (list): boolean => {
    return Boolean(Object.values(list).length);
});

export const canShowUserQuota = createSelector(
    isUserWithFamily,
    (withFamily) => IS_USER_AND_FAMILY_QUOTA_ENABLED && (IS_FAMILY_USER || withFamily)
);

export const getFamilyQuotaPlan = createSelector(getFamilyPlan, (familyPlan) => familyPlan?.family_quota || 0);

export const isUserFamilyOwner = createSelector(
    getFamilyList,
    isUserWithFamily,
    UserSelectors.getEmail,
    (list, isUserWithFamily, email): boolean => {
        return isUserWithFamily && Object.values(list)[0].owner === email;
    }
);

export const isUserFamilyMember = createSelector(
    getFamilyList,
    isUserWithFamily,
    UserSelectors.getEmail,
    (list, isUserWithFamily, email): boolean => {
        return isUserWithFamily && Object.values(list)[0].owner !== email;
    }
);

export const userCanCreateFamily = createSelector(
    isUserWithFamily,
    UserSelectors.isCorpUser,
    UserSelectors.isBizUser,
    UserSelectors.isPaidUser,
    getFamilyQuotaPlan,
    UserSelectors.getCloudSpace,
    (isUserWithFamily, isCorpUser, isBizUser, isPaidUser, familyQuotaPlan, space) => {
        return (
            !isUserWithFamily &&
            !isCorpUser &&
            !isBizUser &&
            isPaidUser &&
            space.total.original > SPACE_LIST.gb_9 &&
            !IS_ONPREMISE &&
            isFamilySubsAvailable &&
            Boolean(familyQuotaPlan)
        );
    }
);

export const userCanJoinFamily = createSelector(UserSelectors.isBizUser, UserSelectors.isCorpUser, (isBizUser, isCorpUser) => {
    return !isBizUser && !isCorpUser && isFamilySubsAvailable;
});

export const getFamilyInviteLink = createSelector(getFamilyList, (list) => {
    return Object.values(list)[0]?.invite_link;
});

export const getFamilyTotalQuotaInfo = createSelector(getFamilyList, (list): Space => {
    const { quota = 0 } = Object.values(list)[0] || {};
    return bytesToNDigits(quota, 3);
});

export const getFamilyOwnerInfo = createSelector(
    (state) => state,
    getFamilyList,
    getFamilyPlan,
    (state, list, familyPlan): FamilyUser | undefined => {
        const family = Object.values(list)[0];

        // если нет семьи, берем данные из familyPlan для будущего овнера
        if (!family && familyPlan) {
            const usedQuota = UserQuotaSelectors.getOwnerUsedQuotaInFamily(state, familyPlan.family_quota);

            return {
                user: USER_EMAIL,
                used: usedQuota,
                link: '',
                img: familyPlan.user_img,
                name: familyPlan.user_name,
            };
        }

        if (!family) {
            return undefined;
        }

        const { users, owner } = family;

        return users.find((user) => user.user === owner);
    }
);

export const getFamilyQuotaInfo = createSelector(
    (state) => state,
    getFamilyList,
    getFamilyQuotaPlan,
    (state, list, familyQuotaPlan) => {
        const family = Object.values(list)[0];

        let { quota = 0, used = 0 } = family || {};

        let currentUserUsed = family?.user_used || 0;

        // если нет семьи, берем данные из familyPlan
        if (!family) {
            const usedQuota = UserQuotaSelectors.getOwnerUsedQuotaInFamily(state, familyQuotaPlan);

            used = usedQuota;
            currentUserUsed = usedQuota;
            quota = familyQuotaPlan;
        }

        const { total: familyUsedPercents, cloud: currentUserUsedPercents } = getUsableSpaceRatio({
            quota,
            used: {
                total: used,
                cloud: currentUserUsed,
            },
        });

        const segment = getSegmentByQuota(quota, used);

        return {
            total: bytesToNDigits(quota, 3),
            used: bytesToNDigits(used, 3),
            remaining: bytesToNDigits(Math.max(quota - used, 0), 3),
            usedCurrentUser: bytesToNDigits(currentUserUsed, 3),
            usedFamily: bytesToNDigits(used - currentUserUsed, 3),
            familyUsedPercents,
            currentUserUsedPercents,
            isAlmostFull: segment === Segments.almostFull,
            isFull: [Segments.full, Segments.overflow].includes(segment),
        };
    }
);

export const getFamilyMemberList = createSelector(getFamilyList, (list): FamilyUser[] => {
    const family = Object.values(list)[0];

    if (!family) {
        return [];
    }

    const { users, owner } = family;

    return (users || []).filter((user) => user?.user !== owner);
});

export const getFamilyMember = createSelector(
    getFamilyMemberList,
    (state, email) => email,
    (list, email) => {
        return list.find((user) => user.user === email);
    }
);

export const familyPromoAvailable = createSelector(
    isUserWithFamily,
    UserSelectors.isBizUser,
    UserSelectors.isCorpUser,
    (isUserWithFamily, isBizUser, isCorp) => {
        return !isUserWithFamily && !isBizUser && !isCorp && !IS_ONPREMISE && isFamilySubsAvailable;
    }
);

export const isFamilyEmpty = createSelector(getFamilyList, (list) => {
    // В семье только создатель.
    return Object.values(list)[0]?.count === 1;
});

export const getFamilyId = createSelector(getFamilyList, (list) => {
    return Object.keys(list)?.[0];
});

export const getCurrentFamily = createSelector(getFamilyList, (list) => {
    return Object.values(list)?.[0];
});

export const getBaseUserQuota = createSelector(getFamilyList, UserSelectors.getBasicSpace, (list, userBaseQuota) => {
    const family = Object.values(list)?.[0];
    const quota = family?.base_quota || userBaseQuota || BASEQUOTA || SPACE_LIST.gb_8;
    return bytesToNDigits(quota, 3);
});

export const getFamilyOverquota = createSelector(getCurrentFamily, getOverQuota, (family, userOverquota) => {
    if (family) {
        const { quota, used } = family;
        return bytesToNDigits(used - quota, 3);
    }

    return userOverquota;
});
