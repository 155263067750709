import { ascend, filter, find, path, propEq, sortWith, values } from 'ramda';
import { PARTNER_LIST } from 'reactApp/modules/subscriptions/subscriptions.data';
import { createSelector } from 'reselect';

const getState = (state) => state.promoTariffs;
const getList = (state) => state.promoTariffs.list;
const isDiscount = (item) => item.type === 'discount';
const isQuota = (item) => item.type === 'quota';
const isRustore = (item) => item.type === 'rustore';
const getProductId = (state, productId) => productId;
const getId = (state, id) => id;
const getPromiTarrifstState = (state) => state.promoTariffs;

export const getRawList = (state) => getState(state).rawList;
export const isPromoTariffsLoaded = (state) => getState(state).isLoaded;

export const getPromoById = createSelector(getList, getId, (list, id) => path([id], list));

// discount
export const getDiscountPromo = createSelector(getList, filter(isDiscount));

export const sortDiscountPromoBySpace = createSelector(getDiscountPromo, (list) =>
    sortWith([ascend(path(['unlocked', 'space', 'original'])), ascend(path(['id']))], values(list))
);

/** Выкидываем триальные тарифы из сапискока промо-тарифов, так как не надо отображать триалы в общей сетке тарифов */
export const getPromoTariffsForDisplay = createSelector(
    sortDiscountPromoBySpace,
    (list) => list && list.filter((item) => item.products && !item.products.find((p) => p.isTrial))
);

export const getDiscountByProductId = createSelector(getDiscountPromo, getProductId, (list, productId) =>
    find(propEq('productId', productId), values(list))
);

// quota
export const getQuotaPromo = createSelector(getList, filter(isQuota));
export const sortQuotaPromoBySpace = createSelector(getQuotaPromo, (list) =>
    sortWith([ascend(path(['space', 'original'])), ascend(path(['id']))], values(list))
);

// показываем подписки с типом quota и подписки рустора, тк они не приходят в ручке /subs/list
export const getPromoListToShow = createSelector(
    getList,
    filter((item) => isQuota(item) || isRustore(item))
);

export const sortPromoListToShow = createSelector(getPromoListToShow, (list) =>
    sortWith([ascend(path(['space', 'original'])), ascend(path(['id']))], values(list))
);

export const hasPartnersPromo = createSelector(getList, (list) => {
    return Object.values(list).some((item) => Boolean(item?.partner));
});

export const getPartnersPromoQuota = createSelector(getRawList, (list) => {
    return list.reduce((acc, item) => {
        const partner = item.source;
        return PARTNER_LIST.includes(partner) ? acc + item?.space || 0 : acc;
    }, 0);
});

export const getPartnersLoadingState = createSelector(getPromiTarrifstState, (state) => ({
    isLoaded: state.isLoaded,
    isLoading: state.isLoading,
}));
