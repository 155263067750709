import { Icon16Done } from '@vkontakte/icons';
import theme from '@vkontakte/vkui-tokens/themes/cloud/cssVars/theme';
import classNames from 'clsx';
import React, { type VFC } from 'react';
import { compactFirstScroll } from 'reactApp/appHelpers/featuresHelpers/features/compactFirstScroll';
import { TariffCardContentSize } from 'reactApp/ui/TariffCardNew/TariffCard.types';

import styles from './TariffFeatureList.css';
import type { ITariffFeatureListProps } from './TariffFeatureList.types';

export const TariffFeatureList: VFC<ITariffFeatureListProps> = ({
    dataQa,
    items,
    showIcon = false,
    large = false,
    accent = false,
    contentSize = TariffCardContentSize.middle,
    isRebrandingQuotaLanding = false,
    featuresSmallSize,
    isRebranding = false,
}) => (
    <ul
        className={classNames(styles.featureList, {
            [styles[`contentSize_${contentSize}`]]: !!contentSize,
            [styles.featureList_rebrandingQuotaLanding]: isRebrandingQuotaLanding,
            [styles.featureList_smallSize]: featuresSmallSize,
            [styles.featureList_compactScroll]: compactFirstScroll && isRebrandingQuotaLanding,
        })}
        data-qa={dataQa}
    >
        {items.map((item) => (
            <li
                key={item.key}
                className={classNames(styles.featureListItem, {
                    [styles.featureListItem_large]: large,
                    [styles.featureListItem_accent]: accent,
                    [styles.featureListItem_pointer]: !!item.onClick,
                    [styles.featureListItem_rebranding]: isRebranding,
                })}
                onClick={item.onClick}
            >
                {showIcon && (
                    <div className={styles.featureListItemIcon}>
                        {item.icon || (
                            <Icon16Done
                                fill={isRebrandingQuotaLanding ? theme.colorIconSecondary.normal.value : theme.colorIconAccent.normal.value}
                            />
                        )}
                    </div>
                )}
                <div data-qa={`${dataQa}_text`}>{item.text}</div>
            </li>
        ))}
    </ul>
);
