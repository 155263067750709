import classNames from 'clsx';
import React, { type VFC, memo, useRef } from 'react';
import { FixedSizeList } from 'react-window';
import type { IArchiveItem } from 'reactApp/modules/viewer/viewer.types';
import { Loader } from 'reactApp/ui/Loader/Loader';
import { MOBILE_ROW_HEIGHT } from 'reactApp/ui/Mobile/Datalist/Datalist.constants';
import { EmptyFolder } from 'reactApp/ui/Mobile/MobileViewer/MobileViewerArchive/EmptyFolder';
import { ItemRow } from 'reactApp/ui/Mobile/MobileViewer/MobileViewerArchive/ItemRow';
import styles from 'reactApp/ui/Mobile/MobileViewer/MobileViewerArchive/MobileViewerArchive.css';
import { filesPlural, foldersPlural } from 'reactApp/utils/pluralHelpers';
import useResizeObserver from 'use-resize-observer/polyfilled';

interface IMobileViewerArchiveListProps {
    isLoaded?: boolean;
    items: IArchiveItem[];
    error?: string;
    disableDownload?: boolean;

    handleClick(item: IArchiveItem);

    handleDownload(item: IArchiveItem);
}

const getArchiveInfo = (items: IArchiveItem[]) => {
    const foldersCount = items.filter((item) => item.isFolder).length;
    const filesCount = items.length - foldersCount;

    let archiveInfo = '';

    if (foldersCount > 0) {
        archiveInfo += `${foldersCount} ${foldersPlural(foldersCount)}`;
    }

    if (foldersCount > 0 && filesCount > 0) {
        archiveInfo += ', ';
    }

    if (filesCount > 0) {
        archiveInfo += `${filesCount} ${filesPlural(filesCount)}`;
    }

    return archiveInfo;
};

export const MobileViewerArchiveList: VFC<IMobileViewerArchiveListProps> = memo(
    ({ isLoaded = false, items, handleClick, handleDownload, error = '', disableDownload = false }) => {
        const ref = useRef<HTMLDivElement | null>(null);

        const { height } = useResizeObserver<HTMLDivElement>({ ref });

        return (
            <div
                className={classNames(styles.archiveContent, { [styles.archiveContent_scrolled]: isLoaded && items.length })}
                ref={ref}
                // eslint-disable-next-line react/no-unknown-property
                body-scroll-lock-ignore="true"
            >
                {isLoaded ? (
                    items.length ? (
                        <FixedSizeList
                            className={styles.archiveItemsList}
                            height={height ?? 0}
                            width="100%"
                            itemCount={items.length + 1}
                            itemSize={MOBILE_ROW_HEIGHT}
                        >
                            {({ index, style }) => {
                                if (index === items.length) {
                                    return (
                                        <div style={style} className={styles.archiveTextItem}>
                                            <span className={styles.text}>{getArchiveInfo(items)}</span>
                                        </div>
                                    );
                                }

                                return (
                                    <div style={style}>
                                        <ItemRow
                                            key={items[index].path}
                                            item={items[index]}
                                            onClick={handleClick}
                                            onDownload={handleDownload}
                                            disableDownload={disableDownload}
                                        />
                                    </div>
                                );
                            }}
                        </FixedSizeList>
                    ) : (
                        <div className={styles.errorWrapper}>
                            <EmptyFolder error={error} />
                        </div>
                    )
                ) : (
                    <Loader centered isModal />
                )}
            </div>
        );
    }
);

MobileViewerArchiveList.displayName = 'MobileViewerArchiveList';
