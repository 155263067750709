import { usePrevious } from '@vkontakte/vkui/dist/hooks/usePrevious';
import classNames from 'clsx';
import throttle from 'lodash.throttle';
import { equals } from 'ramda';
import React, { type ReactElement, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { isUseO2Token } from 'reactApp/api/O2AuthClient';
import { useElementSize } from 'reactApp/hooks/useElementSize';
import type { IFaceWithCoordinates } from 'reactApp/modules/faces/faces.types';
import { isImage } from 'reactApp/modules/file/utils';
import { ImageCancelable, preventDragHandler } from 'reactApp/ui/ImageCancelable/ImageCancelable';
import { useO2AuthedImage } from 'reactApp/ui/ReactViewer/ViewerImage/ViewerImage.helpers';
import { Spinner } from 'reactApp/ui/Spinner/Spinner';
import { noop } from 'reactApp/utils/helpers';

import { type IImageSize, FaceRectangle } from './FaceRectangle';
import styles from './ViewerImage.css';

const SCALE_DURATION = 200;

const getPos = (event) => {
    const touches = event.nativeEvent.touches?.[0];
    if (touches) {
        return { x: touches.pageX, y: touches.pageY };
    }
    return { x: event.nativeEvent.pageX, y: event.nativeEvent.pageY };
};

export interface Props {
    isError: boolean;
    isLoaded: boolean;
    isRetry: boolean;
    enableAnimationPrev: boolean;
    src: string;
    srcPrev: string;
    file: any;
    filePrev: any;
    onReloadImage(srcUrl: any, id: any);
    onImageError(src, id);
    onImageLoad({ srcUrl: src, id, event });
    scale?: number;
    embedded?: boolean;
    enableViewerMenu?: boolean;
    showFacesFilter?: boolean;
    contentRect?: {
        width: number;
        height: number;
        y: number;
        x: number;
        right: number;
        bottom: number;
        top: number;
        left: number;
    } | null;
    faces?: IFaceWithCoordinates[];
    facesScale?: number;
    clickOnFace?(faceId: string);
    renderSpinner?: () => ReactElement;
}

interface PositionOffset {
    x: number;
    y: number;
}

export const ViewerImage = memo(
    ({
        src,
        srcPrev,
        file,
        filePrev,
        isError,
        isLoaded,
        isRetry,
        onReloadImage,
        onImageError,
        onImageLoad,
        scale = 1,
        enableAnimationPrev = true,
        embedded = false,
        enableViewerMenu,
        contentRect = {
            width: 0,
            height: 0,
            x: 0,
            y: 0,
            right: 0,
            bottom: 0,
            top: 0,
            left: 0,
        },
        faces = [],
        clickOnFace = noop,
        showFacesFilter = false,
        renderSpinner,
    }: Props): ReactElement => {
        const id = file?.id;
        const transitionScaleRAFId = useRef<number | null>(null);

        const [positionInitial, setPositionInitial] = useState<{ x: number; y: number } | null>(null);
        const [transitionScale, setTransitionScale] = useState(1);
        const prevTransitionScale = usePrevious(transitionScale);
        const [offset, setOffset] = useState<PositionOffset>({ x: 0, y: 0 });
        const [imageRect, setImageRect] = useState<IImageSize | null>(null);

        const isScaleMode = useMemo(() => transitionScale !== 1 && isLoaded && !isError, [transitionScale, isLoaded, isError]);

        const [setImageRef, imageRef, size] = useElementSize<HTMLImageElement>();

        const o2AuthedImage = useO2AuthedImage(src, file.ext);
        const authedSrc = isUseO2Token() ? o2AuthedImage : src;

        const handleReloadImage = useCallback(() => {
            onReloadImage(authedSrc, id);
        }, [authedSrc, id, onReloadImage]);

        const handleImageError = useCallback(() => {
            onImageError(authedSrc, id);
        }, [authedSrc, id, onImageError]);

        const handleImageLoad = useCallback(
            (event) => {
                onImageLoad({ srcUrl: authedSrc, id, event });
            },
            [authedSrc, id, onImageLoad]
        );

        const handleStartDrag = useCallback(
            (event) => {
                if (!isScaleMode) {
                    return;
                }
                const cursorPos = getPos(event);

                setPositionInitial({
                    x: Math.round(cursorPos.x / scale) - offset.x,
                    y: Math.round(cursorPos.y / scale) - offset.y,
                });
            },
            [isScaleMode, setPositionInitial, offset, scale]
        );

        const handleStopDrag = useCallback(() => {
            setPositionInitial(null);
        }, [setPositionInitial]);

        const getViewPortal = useCallback(() => {
            return {
                height: embedded ? contentRect?.height ?? window.innerHeight : window.innerHeight || document.documentElement.clientHeight,
                width: embedded ? contentRect?.width ?? window.innerHeight : window.innerWidth || document.documentElement.clientWidth,
                top: contentRect?.y,
                left: contentRect?.x,
            };
        }, [contentRect, embedded]);

        const calculateNewOffset = useCallback(
            ({ offset, oldOffset, scale }: { offset: PositionOffset; oldOffset: PositionOffset; scale: number }) => {
                const rect = imageRef?.getBoundingClientRect();
                const viewPortal = getViewPortal();
                const newOffset = {
                    x: offset.x,
                    y: offset.y,
                };

                if (rect) {
                    const yOffsetDiff = newOffset.y - oldOffset.y;
                    const xOffsetDiff = newOffset.x - oldOffset.x;

                    const outerTopOffset = contentRect?.y ?? 0;
                    const outerLeftOffset = contentRect?.x ?? 0;
                    const outerRightOffset = embedded ? 0 : (contentRect?.right ?? 0) - window.outerWidth ?? 0;

                    const yTopNew = rect.top + yOffsetDiff;
                    const yBottomNew = rect.bottom + yOffsetDiff;
                    const rectRight = embedded ? rect.right - outerLeftOffset : rect.right;
                    const xLeftNew = rect.left + xOffsetDiff;
                    const xRightNew = rectRight + xOffsetDiff;

                    if (rect.height > viewPortal.height) {
                        if (yBottomNew <= viewPortal.height) {
                            newOffset.y = oldOffset.y - Math.round(rect.bottom - viewPortal.height) / scale;
                        }
                        if (yTopNew > outerTopOffset) {
                            newOffset.y = oldOffset.y - Math.round(rect.top - outerTopOffset) / scale;
                        }
                    } else {
                        newOffset.y = oldOffset.y;
                    }

                    if (rect.width > viewPortal.width) {
                        if (xRightNew <= viewPortal.width) {
                            newOffset.x = oldOffset.x - Math.round(rectRight - viewPortal.width - outerRightOffset) / scale;
                        }
                        if (xLeftNew > outerLeftOffset) {
                            newOffset.x = oldOffset.x - Math.round(rect.left - outerLeftOffset) / scale;
                        }
                    } else {
                        newOffset.x = oldOffset.x;
                    }
                }

                return newOffset;
            },
            [getViewPortal, contentRect, imageRef, embedded]
        );

        const updateOffset = ({ offset, oldOffset, scale }: { offset: PositionOffset; oldOffset: PositionOffset; scale: number }) => {
            const updatedOffset = calculateNewOffset({ offset, oldOffset, scale });
            if (!equals(updatedOffset, oldOffset)) {
                return setOffset(updatedOffset);
            }
        };

        const handleDragMove = useCallback(
            (event) => {
                if (!positionInitial) {
                    return;
                }

                const cursorPos = getPos(event);

                updateOffset({
                    offset: {
                        x: Math.round(cursorPos.x / scale - positionInitial.x),
                        y: Math.round(cursorPos.y / scale - positionInitial.y),
                    },
                    oldOffset: offset,
                    scale,
                });
            },
            [positionInitial, scale, offset, calculateNewOffset]
        );

        useEffect(() => {
            setOffset({ x: 0, y: 0 });
            setPositionInitial(null);
        }, [id, setOffset, setPositionInitial, isScaleMode]);

        const throttledResize = throttle(() => {
            const rect = imageRef?.getBoundingClientRect();
            if (rect && rect.width > 0 && rect.height > 0) {
                setImageRect({
                    top: rect.top,
                    // Почему-то координаты картинки приходят не от парента (с position: fixed), а от его парента
                    left: rect.left + (showFacesFilter ? -100 : 0),
                    width: rect.width,
                    height: rect.height,
                    naturalWidth: imageRef?.naturalWidth,
                    naturalHeight: imageRef?.naturalHeight,
                });
            }
        }, 50);

        useEffect(() => {
            throttledResize();
        }, [size]);

        useEffect(() => {
            throttledResize();
        }, [showFacesFilter]);

        useEffect(() => {
            let prev;
            let firstDiff;

            const calcScale: FrameRequestCallback = () => {
                const time = Date.now();
                const timePast = time - prev;

                prev = time;
                setTransitionScale((transitionScale) => {
                    const diff = Math.abs(transitionScale - scale);

                    firstDiff = firstDiff || diff;
                    const scaleTimeDiff = (timePast / SCALE_DURATION) * firstDiff;

                    if (diff <= scaleTimeDiff) {
                        transitionScaleRAFId.current = null;
                        return scale;
                    }
                    if (transitionScale > scale) {
                        transitionScaleRAFId.current = requestAnimationFrame(calcScale);
                        return transitionScale - scaleTimeDiff;
                    }

                    transitionScaleRAFId.current = requestAnimationFrame(calcScale);
                    return transitionScale + scaleTimeDiff;
                });
            };

            prev = Date.now();
            transitionScaleRAFId.current = requestAnimationFrame(calcScale);

            return () => {
                if (transitionScaleRAFId.current) {
                    cancelAnimationFrame(transitionScaleRAFId.current);
                }
            };
        }, [scale]);

        useEffect(() => {
            // Корректируем позицию картики если она сдвинута только в конце анимации и только если zoom уменьшается
            if (prevTransitionScale && transitionScale < prevTransitionScale) {
                updateOffset({ scale: transitionScale, offset, oldOffset: offset });
            }
        }, [transitionScale]);

        const styleMove = useMemo(
            () =>
                isScaleMode
                    ? {
                          transform: `translate(${offset.x}px,${offset.y}px)`,
                      }
                    : undefined,
            [isScaleMode, offset]
        );

        const style = useMemo(
            () =>
                isScaleMode
                    ? {
                          position: embedded ? ('relative' as const) : ('fixed' as const),
                          transform: `scale(${transitionScale})`,
                      }
                    : undefined,
            [isScaleMode, embedded, transitionScale]
        );

        if (!src || isError) {
            return (
                <div className={styles.errorBlock}>
                    <div className={styles.errorTitle}>Не удалось загрузить изображение</div>
                    <div className={styles.button} onClick={handleReloadImage}>
                        Повторить попытку
                    </div>
                </div>
            );
        }

        return (
            <>
                <div
                    className={classNames({
                        [styles.root]: true,
                        [styles.root_embedded]: embedded,
                        [styles.root_menu]: enableViewerMenu,
                    })}
                    style={style}
                >
                    {/* грузим картинку в фоне */}
                    {!isLoaded && !isError && (
                        <ImageCancelable
                            id={`${authedSrc}back`}
                            key={`${authedSrc}back`}
                            className={classNames(styles.image, styles.image_backgroundLoad)}
                            src={authedSrc}
                            alt={file.name}
                            onError={handleImageError}
                            onLoad={handleImageLoad}
                        />
                    )}
                    {/* если загрузили и есть предыдущая, то предыдущую анимируем в 0 и... */}
                    {enableAnimationPrev && (
                        <CSSTransition
                            in={!!srcPrev && isLoaded && isImage(filePrev)}
                            appear
                            unmountOnExit
                            exit={true}
                            key={`${srcPrev}prev`}
                            timeout={250}
                            classNames={{
                                appear: styles.animatePrev_enter,
                                appearActive: styles.animatePrev_enter_active,
                                enter: styles.animatePrev_enter,
                                enterActive: styles.animatePrev_enter_active,
                            }}
                        >
                            <ImageCancelable
                                id={`${filePrev?.id}prev`}
                                key={`${filePrev?.id}prev`}
                                className={classNames(styles.image, styles.imagePrev)}
                                src={srcPrev}
                                alt={filePrev?.name}
                            />
                        </CSSTransition>
                    )}
                    {/* ... если успешно загрузили, анимируем ее появление иначе ... */}
                    <CSSTransition
                        in={isLoaded && !isError}
                        appear
                        unmountOnExit
                        exit={true}
                        key={authedSrc}
                        timeout={250}
                        classNames={{
                            appear: styles.animate_enter,
                            appearActive: styles.animate_enter_active,
                            enter: styles.animate_enter,
                            enterActive: styles.animate_enter_active,
                        }}
                    >
                        <>
                            <img
                                style={styleMove}
                                ref={setImageRef}
                                onMouseDown={handleStartDrag}
                                onTouchStart={handleStartDrag}
                                onMouseUp={handleStopDrag}
                                onTouchEnd={handleStopDrag}
                                onTouchMove={handleDragMove}
                                onMouseMove={handleDragMove}
                                onDragStart={preventDragHandler}
                                key={`${authedSrc}img`}
                                className={classNames({
                                    [styles.image]: true,
                                    [styles.zoomable]: isScaleMode,
                                    [styles.dragging]: positionInitial !== null,
                                    [styles.image_backgroundLoad]: !isLoaded,
                                })}
                                src={authedSrc}
                                alt={file.name}
                            />
                            {Boolean(imageRect) &&
                                faces?.map((face) =>
                                    face.coordinates?.map((coordinate, idx) => {
                                        return (
                                            <FaceRectangle
                                                key={coordinate.face + idx}
                                                coordinate={coordinate}
                                                imageRect={imageRect}
                                                clickOnFace={clickOnFace}
                                                offset={offset}
                                                showOnHoverOnly={!showFacesFilter}
                                            />
                                        );
                                    })
                                )}
                        </>
                    </CSSTransition>
                    {/* ... если еще не загрузили и есть предыдущая, то пока показываем ее с лоадером (если конечно не ретрай и пред точно картинка) */}
                    {!!srcPrev && !isLoaded && !isError && !isRetry && isImage(filePrev) && (
                        <ImageCancelable
                            key={`${srcPrev}back2`}
                            id={`${srcPrev}back2`}
                            className={classNames(styles.image)}
                            src={srcPrev}
                            alt={file.name}
                        />
                    )}
                    {!isLoaded && (typeof renderSpinner === 'function' ? renderSpinner() : <Spinner />)}
                </div>
            </>
        );
    }
);

ViewerImage.displayName = 'ViewerImage';

ViewerImage.whyDidYouRender = true;
