export { ReactComponent as Icon24FolderOutline } from 'img/icons/mobile/folderOutline24.svg';
export { ReactComponent as Icon24UsersOutline } from 'img/icons/mobile/users3Outline24.svg';
export { ReactComponent as Icon20ChevronRight } from 'img/icons/mobile/chevronRight20.svg';
export { ReactComponent as Icon20ChevronLeft } from 'img/icons/mobile/chevronLeft20.svg';
export { ReactComponent as Icon20ChevronDown } from 'img/icons/mobile/chevronDown20.svg';
export { ReactComponent as Icon20DownloadOutline } from 'img/icons/mobile/downloadOutline20.svg';
export { ReactComponent as Icon20AddOutline } from 'img/icons/mobile/fileAddOutline20.svg';
export { ReactComponent as Icon20FolderCreateOutline } from 'img/icons/mobile/folderCreateOutline20.svg';
export { ReactComponent as Icon20MenuOutline } from 'img/icons/mobile/menuОutline20.svg';
export { ReactComponent as Icon20ChainOutline } from 'img/icons/mobile/chainOutline20.svg';
export { ReactComponent as Icon24ChainOutline } from 'img/icons/mobile/chainOutline24.svg';
export { ReactComponent as Icon20MoreDots } from 'img/icons/mobile/moreDots20.svg';
export { ReactComponent as Icon20MoreDotsVertical } from 'img/icons/mobile/moreDotsVertical20.svg';
export { ReactComponent as Icon24ShareOutline } from 'img/icons/mobile/shareExternalOutline24.svg';
export { ReactComponent as Icon24DownloadOutline } from 'img/icons/mobile/downloadOutline24.svg';
export { ReactComponent as Icon24CopyOutline } from 'img/icons/mobile/copyOutline24.svg';
export { ReactComponent as Icon24MoveOutline } from 'img/icons/mobile/moveOutline24.svg';
export { ReactComponent as Icon24DeleteOutline } from 'img/icons/mobile/deleteOutline24.svg';
export { ReactComponent as Icon24RenameOutline } from 'img/icons/mobile/renameOutline24.svg';
export { ReactComponent as Icon24InfoOutline } from 'img/icons/mobile/infoOutline24.svg';
export { ReactComponent as Icon20DeleteClockOutline } from 'img/icons/mobile/deleteClockOutline20.svg';
export { ReactComponent as Icon20ArrowReturnLeftOutline } from 'img/icons/mobile/arrowReturnLeftOutline20.svg';
export { ReactComponent as Icon20DeleteOutline } from 'img/icons/mobile/deleteOutline20.svg';
export { ReactComponent as Icon24ArrowReturnLeftOutline } from 'img/icons/mobile/arrowReturnLeftOutline24.svg';
export { ReactComponent as Icon24AlbumShareOutline } from 'img/icons/mobile/albumShareOutline24.svg';
export { ReactComponent as Icon24Check } from 'img/icons/mobile/check24.svg';
export { ReactComponent as Icon20ArrowLeft } from 'img/icons/mobile/arrowLeft20.svg';
export { ReactComponent as Icon20ThreeDotsVertical } from 'img/icons/mobile/threeDotsVertical20.svg';
export { ReactComponent as Icon28DocumentOutline } from 'img/icons/mobile/documentOutline28.svg';
export { ReactComponent as Icon28CalendarOutline } from 'img/icons/mobile/calendarOutline28.svg';
export { ReactComponent as Icon28CloudOutline } from 'img/icons/mobile/cloudOutline28.svg';
export { ReactComponent as Icon28ChainOutline } from 'img/icons/mobile/chainOutline28.svg';
export { ReactComponent as Icon28FolderOutline } from 'img/icons/mobile/folderOutline28.svg';
export { ReactComponent as Icon16ChevronRight } from 'img/icons/mobile/chevronRight16.svg';
export { ReactComponent as Icon24Add } from 'img/icons/mobile/add24.svg';
export { ReactComponent as Icon24CalendarOutline } from 'img/icons/mobile/calendarOutline24.svg';
export { ReactComponent as Icon24FileSizeOutline } from 'img/icons/mobile/fileSizeOutline24.svg';
export { ReactComponent as Icon24TextOutline } from 'img/icons/mobile/textOutline24.svg';
export { ReactComponent as Icon20SortAscendingOutline } from 'img/icons/mobile/sortAscendingOutline20.svg';
export { ReactComponent as Icon20DescendingOutline } from 'img/icons/mobile/sortDescendingOutline20.svg';
export { ReactComponent as Icon20UploadOutline } from 'img/icons/mobile/uploadOutline20.svg';
export { ReactComponent as Icon24CloudBlue } from 'img/icons/mobile/cloudBlue.svg';
