import type { Params } from '@mail/xray';
import { Config, Connect } from '@vkontakte/superappkit';
import { xray } from 'lib/xray';
import { oid } from 'reactApp/appHelpers/featuresHelpers/features/oid';
import { vKAppID, vkConnectDomain, vkLoginDomain, vkOauthDomain } from 'reactApp/appHelpers/featuresHelpers/features/vkidSdk';

const sendXray = ({ t, i, dwh }: Pick<Params, 'i' | 'dwh'> & { t: string }) => {
    xray.send(t, { i, dwh });
};

const initVkIdConfig = () => {
    Config.init({
        appId: vKAppID,
        loginDomain: vkLoginDomain,
        oauthDomain: vkOauthDomain,
        connectDomain: vkConnectDomain,
        appSettings: {
            service_groups: {
                oid,
            },
        },
    });
};

const silentAuth = async () => {
    const data = await Connect.silentAuth();
    return data?.payload;
};

export const doFakeSilentVkAuth = (email: string) => {
    sendXray({ t: 'silent-vkc-auth-try', dwh: { email } });

    initVkIdConfig();
    silentAuth()
        .then((payload) => {
            const { auth } = payload;
            sendXray({ t: 'silent-vkc-auth-ok', i: { [auth ? 'p' : 'a']: 1 }, dwh: { email } });
        })
        .catch(() => {
            sendXray({ t: 'silent-vkc-auth-err', dwh: { email } });
        });
};
