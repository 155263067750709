import { tariffCardPersonal } from 'reactApp/appHelpers/featuresHelpers/features/tariifCardPersonal';
import { SPACE_LIST } from 'reactApp/constants/spaceList';
import type { Product } from 'reactApp/types/Billing';
import { ETariffLabelKind } from 'reactApp/ui/TariffCardNew/components/TariffLabel.types';
import { tariffPromoLabelText } from 'reactApp/ui/TariffSectionNew/TariffSection.data';
import type { TariffPromoLabelType } from 'reactApp/ui/TariffSectionNew/TariffsSection.types';
import { getDiscount } from 'reactApp/utils/getDiscount';

export const useTariffLabel = ({
    product,
    isSpecial,
    isBase,
    isRecommended,
    skipDefaultLabel,
    primaryLabel,
    showDiscount = true,
    tariffPromoLabelType,
    isRebranding = false,
    /* tempexp_17509-start */
    isFromLitresSecondary = false,
    isFromLitresPrimary = false,
    isBlackFriday2024Tariff = false,
    isCyberMonday2025Tariff = false,
    /* tempexp_18084-next-line */
    isTariffCardPersonal = false,
}: /* tempexp_17509-end */
{
    product: Product;
    isSpecial?: boolean;
    isBase?: boolean;
    isRecommended?: boolean;
    skipDefaultLabel?: boolean;
    primaryLabel?: string;
    showDiscount?: boolean;
    tariffPromoLabelType?: TariffPromoLabelType;
    isRebranding?: boolean;
    /* tempexp_17509-start */
    isFromLitresSecondary?: boolean;
    isFromLitresPrimary?: boolean;
    /* tempexp_17509-end */
    isBlackFriday2024Tariff?: boolean;
    isCyberMonday2025Tariff?: boolean;
    /* tempexp_18084-next-line */
    isTariffCardPersonal?: boolean;
}) => {
    const {
        space: { original: quota },
        discountPrice,
        price,
        isProfessional,
        isDisko,
    } = product;

    let label = '';
    let labelKind = ETariffLabelKind.blue;

    switch (quota) {
        case SPACE_LIST.gb_64:
            labelKind = ETariffLabelKind.blue;
            break;
        case SPACE_LIST.gb_128:
            label = 'Популярный';
            labelKind = ETariffLabelKind.green;
            break;
        case SPACE_LIST.gb_256:
            label = 'Оптимальный';
            labelKind = ETariffLabelKind.blue;
            break;
        case SPACE_LIST.gb_512:
            label = isSpecial ? 'Популярный' : '';
            labelKind = ETariffLabelKind.yellow;
            break;
        case SPACE_LIST.tb_1:
            label = isSpecial ? 'Специальный' : '';
            labelKind = ETariffLabelKind.violet;
            break;
        case SPACE_LIST.tb_2:
            label = isSpecial ? 'Выгодный' : '';
            labelKind = ETariffLabelKind.blue;
            break;
        case SPACE_LIST.tb_4:
            label = isSpecial ? 'Выгодный' : '';
            labelKind = ETariffLabelKind.pink;
            break;
    }

    if (skipDefaultLabel) {
        label = '';
    }

    if (isProfessional && isDisko) {
        label = 'PRO';
        labelKind = ETariffLabelKind.blue;
    }

    if (isRecommended) {
        label = 'Рекомендуем';
        labelKind = ETariffLabelKind.blue;
    }

    if (discountPrice && showDiscount) {
        const { discount } = getDiscount({ discountPrice, price });
        if (discount) {
            label = `Скидка ${discount}%`;
        }
    }

    if (isBase) {
        labelKind = ETariffLabelKind.white;
    }

    if (primaryLabel) {
        label = primaryLabel;
        labelKind = ETariffLabelKind.blue;
    }

    if (tariffPromoLabelType) {
        label = tariffPromoLabelText[tariffPromoLabelType];
        labelKind = ETariffLabelKind.tariffPromo;
    }

    if (isRebranding) {
        labelKind = ETariffLabelKind.tarrifRebranding;
    }
    /* tempexp_17509-start */
    const litresLabel = 'Литрес на 2 месяца';
    if (isFromLitresSecondary) {
        label = litresLabel;
        labelKind = ETariffLabelKind.fromLitresSecondary;
    }
    if (isFromLitresPrimary) {
        label = litresLabel;
        labelKind = ETariffLabelKind.fromLitresPrimary;
    }
    /* tempexp_17509-end */

    if (isBlackFriday2024Tariff) {
        label = 'Специальный тариф';
    }

    if (isCyberMonday2025Tariff) {
        label = 'Хватит на всё';
    }

    // tempexp_18084-start
    if (isTariffCardPersonal) {
        if (quota === SPACE_LIST.gb_512) {
            label = 'Для учёбы';
            labelKind = ETariffLabelKind.tariffPersonalPink;
        }
        if (quota === SPACE_LIST.tb_1) {
            label = 'Для личного';
            labelKind = ETariffLabelKind.tariffPersonalBlue;
        }
        if (quota === SPACE_LIST.tb_2) {
            label = 'Для работы';
            labelKind = ETariffLabelKind.tariffPersonalGreen;
        }

        if (tariffCardPersonal === 'c') {
            labelKind = ETariffLabelKind.tariffPersonal;
        }
    }
    // tempexp_18084-start

    return [!!label, label, labelKind] as const;
};
