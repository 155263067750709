import { closePopupHelper, openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { noopVoid } from 'reactApp/utils/helpers';

export const openSubscriptionModal = ({ onClose = noopVoid, ...data }) => {
    openPopupHelper({
        popupName: popupNames.SUBSCRIPTION_MODAL,
        data,
        onClose,
    });
};

export const closeSubscriptionModal = () => closePopupHelper(popupNames.SUBSCRIPTION_MODAL);
