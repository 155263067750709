import hotkeys from 'hotkeys-js';
import { extInfo } from 'lib/extInfo';
import { type Dispatch, type MutableRefObject, type RefObject, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isUseO2Token } from 'reactApp/api/O2AuthClient';
import { ENABLE_FULL_RESPONSIVE, IS_MOBILE_BROWSER, IS_PUBLIC_ALBUM } from 'reactApp/appHelpers/configHelpers';
import { isBBAQualitySelectionAlgorithm, isOverrideNativeHLS } from 'reactApp/appHelpers/featuresHelpers';
import { enableStoreVideoPlayTime, minVideoDurationToStore } from 'reactApp/appHelpers/featuresHelpers/features/storeVideoPlayTime';
import { watchMedia } from 'reactApp/hooks/responsiveness/useMinWidthBreakpoint';
import { getPublicRootWeblink } from 'reactApp/modules/public/public.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import type { EStorageType } from 'reactApp/modules/storage/storage.types';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import type { Kind } from 'reactApp/types/Tree';
import { usePlayerO2Header } from 'reactApp/ui/ReactViewer/VideoPlayer/usePlayerO2Header';
import { sendXray } from 'reactApp/utils/ga';
import videojs from 'video.js';

import { adInit } from './AD';
import { addCustomNavigation } from './CustomNavigation';
import { DESKTOP_REWIND_TIME, MOBILE_REWIND_TIME } from './CustomNavigation/constants';
import { getFastRewindFunctions, stimulateNav, togglePlay } from './CustomNavigation/helpers';
import type { QualityValue } from './hlsQualitySelector/plugin';
import { bufferBasedSelectPlaylist } from './qualitySelector';
import { getVideoPlayTime, storeVideoPlayTime } from './utils/helpers';
import type { VideoErrorHandler, VideoGaErrorData, VideoMetaData, VideoPlayerOptions, VideoPlayerWithPlugins } from './VideoPlayer.types';
import { type GaData, VideoPlayerGa } from './VideoPlayerGa';

interface Options {
    streamUrl?: string;
    posterUrl: string;
    kind: Kind;
    autoplay: boolean;
    controls: boolean;
    startQuality: QualityValue;
    nativeUrl: string;
    size: number | undefined;
    isArchive: boolean;
    isAttaches: boolean;
    isVideo: boolean;
    isPhone: boolean;
    hotkeysScope: string;
    onCanPlay?: () => void;
    onWaiting?: () => void;
    onLoadStart?: () => void;
    onLoadedMetaData?: (data: VideoMetaData) => void;
    onNavigateLeft?: () => void;
    onNavigateRight?: () => void;
    onClose?: () => void;
    onDelete?: () => void;
    onError?: VideoErrorHandler;
    name: string;
    id?: string;
    ext: string;
    isMediaQueryHit: boolean;
    setAdm: Dispatch<any>;
    mediaBreakpoint: number;
}

export const errorsMap = {
    'You aborted the media playback': 'Вы прервали воспроизведение мультимедиа',
    'A network error caused the media download to fail part-way.': 'Ошибка сети привела к частичному сбою загрузки мультимедиа.',
    'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.':
        'Воспроизведение мультимедиа было прервано из-за проблемы с повреждением или из-за того, что мультимедиа использовало функции, которые не поддерживал ваш браузер.',
    'The media could not be loaded, either because the server or network failed or because the format is not supported.':
        'Не удалось загрузить файл из-за отказа сервера или сети или из-за того, что формат не поддерживается.',
    'The media is encrypted and we do not have the keys to decrypt it.': 'Носитель зашифрован, и у нас нет ключей для его расшифровки.',
    'No compatible source was found for this media.': 'Ошибка воспроизведения. Неизвестный кодек видео.',
};

const errorsMapByCode = {
    1: 'Вы прервали воспроизведение мультимедиа',
    2: 'Ошибка сети привела к частичному сбою загрузки мультимедиа.',
    3: 'Воспроизведение мультимедиа было прервано из-за проблемы с повреждением или из-за того, что мультимедиа использовало функции, которые не поддерживал ваш браузер.',
    4: 'Не удалось загрузить файл из-за отказа сервера или сети или из-за того, что формат не поддерживается.',
    5: 'Носитель зашифрован, и у нас нет ключей для его расшифровки.',
    6: 'Ошибка воспроизведения. Неизвестный кодек видео.',
};

const LEFT_NAVIGATION_HOTKEYS = 'left, backspace';
const DELETE_HOTKEYS = 'del, delete';

const getControlbarOption = (isVideo: boolean, isPhone: boolean): videojs.ControlBarOptions => ({
    currentTimeDisplay: true,
    timeDivider: true,
    // Ломает перемещение трека ProgressControl вслед за курсором при перетасковании
    // прячем через CSS
    // remainingTimeDisplay: false,
    fullscreenToggle: isVideo,
    pictureInPictureToggle: false,
    volumePanel: !isPhone,
    playToggle: !isPhone,
});

const getPlayerOptions = (
    isVideo: boolean,
    autoplay: boolean,
    controls: boolean,
    controlBarSettings: videojs.ControlBarOptions,
    overrideNative: boolean
): VideoPlayerOptions => ({
    language: 'ru',
    bigPlayButton: false,
    controls,
    playbackRates: isVideo ? [0.5, 1, 1.25, 1.5, 2] : undefined,
    autoplay,
    controlBar: controlBarSettings,
    fill: true,
    html5: {
        vhs: {
            overrideNative: isOverrideNativeHLS || overrideNative || !videojs.browser.IS_SAFARI,
            withCredentials: true,
        },
        nativeAudioTracks: !isOverrideNativeHLS && videojs.browser.IS_SAFARI,
        nativeVideoTracks: !isOverrideNativeHLS && videojs.browser.IS_SAFARI,
    },
});

const handleError = (
    player: VideoPlayerWithPlugins,
    onError: VideoErrorHandler | undefined,
    error: { code?: number; message?: string } | null
) => {
    const { code = 0, message = 'Что-то пошло не так' } = error || {};

    if (!player?.error()) {
        const errorData: VideoGaErrorData = {
            bandwidth: player.tech(true).vhs?.bandwidth,
            throughput: player.tech(true).vhs?.throughput,
        };

        onError?.(errorsMap[message] || errorsMapByCode[code] || message, errorData);
        setTimeout(() => {
            player?.error(errorsMap[message] || errorsMapByCode[code] || message);
            player?.removeClass('vjs-waiting');
        }, 0);
    }
};

export const updateControlBarButtons = (player: VideoPlayerWithPlugins | undefined, isHit: boolean) => {
    if (!ENABLE_FULL_RESPONSIVE) {
        return;
    }

    player?.ready(() => {
        const controlBar = player?.getChild('ControlBar');
        if (controlBar) {
            ['TimeDivider', 'DurationDisplay', 'LoopButton', 'PlaybackRateMenuButton', 'QualityButton'].forEach((name) => {
                const child = controlBar.getChild(name);
                if (isHit) {
                    child?.removeAttribute('style');
                } else {
                    child?.setAttribute('style', 'display:none;');
                }
            });
        }
    });
};

const initPlayerGA = (storage: EStorageType, publicId: string, player: VideoPlayerWithPlugins, options: Options) => {
    const { kind, isArchive, isAttaches, size, name, id, ext } = options;

    const gaData: GaData = {
        source: IS_PUBLIC_ALBUM ? 'album' : storage,
        type_content: kind,
        is_stories: false,
        have_face: false,
        id_public: publicId,
        extension: ext?.toLowerCase(),
        is_archive: isArchive,
        is_attache: isAttaches,
        size_files: size,
        name,
        id_media: id,
        is_touch: IS_MOBILE_BROWSER,
    };

    const ga = new VideoPlayerGa(player, gaData);
    ga.subscribe();

    return { ga, gaData };
};

export const useVideoPlayer = (
    videoRef: RefObject<HTMLVideoElement>,
    playerRef: MutableRefObject<VideoPlayerWithPlugins | undefined>,
    options: Options
) => {
    const storage = useSelector(getCurrentStorage);
    const videoAdEnabled = useSelector((state) => ViewerSelectors.isVideoAdEnabled(state, storage));
    const { weblink: publicId } = useSelector(getPublicRootWeblink);

    usePlayerO2Header();

    useEffect(() => {
        if (!videoRef.current) {
            return;
        }

        const {
            name,
            isVideo,
            isPhone,
            autoplay,
            controls,
            startQuality,
            ext,
            streamUrl,
            posterUrl,
            nativeUrl,
            hotkeysScope,
            onCanPlay,
            onWaiting,
            onLoadStart,
            onLoadedMetaData,
            onNavigateLeft,
            onNavigateRight,
            onClose,
            onError,
            onDelete,
            setAdm,
            mediaBreakpoint,
        } = options;

        const controlBarSettings = getControlbarOption(isVideo, isPhone);
        const player = videojs(
            videoRef.current,
            getPlayerOptions(isVideo, autoplay, controls, controlBarSettings, isUseO2Token())
        ) as VideoPlayerWithPlugins;

        playerRef.current = player;

        const gaPlayerInstance = initPlayerGA(storage, publicId, player, options);

        // disable SwipingComponent logic for controlBar
        player.controlBar.el().addEventListener('pointerdown', (event) => event.stopPropagation());

        const info = extInfo.get(ext);

        if (streamUrl && streamUrl.includes('.m3u8') && info.isStreamingSupported) {
            player.src({
                src: streamUrl,
                type: extInfo.get('m3u8').mimeType,
            });
        } else if (info.isNativeSupported) {
            player.src({
                src: nativeUrl,
                type: info.mimeType,
            });
        } else {
            sendXray(['video-player', 'error', 'not-supported']);
            handleError(player, onError, { message: 'Данный формат не потдерживается' });
        }

        player.load();
        player.poster(posterUrl);

        player.on('canplay', () => {
            onCanPlay?.();
        });

        player.on('loadstart', () => {
            onLoadStart?.();
        });

        player.on('loadedmetadata', () => {
            onLoadedMetaData?.({ duration: player.duration() });
        });

        player.on('waiting', () => {
            onWaiting?.();
        });

        if (streamUrl && info.isStreamingSupported && info.isNativeSupported) {
            // fallback logic
            let streamUrlError = false;
            player.on('error', () => {
                const error = player.error();

                if (error && [4].includes(error.code) && !streamUrlError) {
                    streamUrlError = true;
                    player.error(null);
                    player.src({
                        src: nativeUrl,
                        type: info.mimeType,
                    });

                    return;
                }

                // Чистим ошибку чтобы выбросить кастомную
                if (error?.code) {
                    player.error(null);
                }
                handleError(player, onError, error);
            });
        } else {
            player.on('error', () => {
                const error = player.error();

                // Чистим ошибку чтобы выбросить кастомную
                if (error?.code) {
                    player.error(null);
                }
                handleError(player, onError, error);
            });
        }

        const rewindTime = isPhone ? MOBILE_REWIND_TIME : DESKTOP_REWIND_TIME;

        const { goForward, goBackward } = getFastRewindFunctions(rewindTime);

        const goForwardCallback = (e: Event) => {
            e.preventDefault();
            goForward(player);
        };

        const goBackwardCallback = (e: Event) => {
            e.preventDefault();
            goBackward(player);
        };

        const togglePlayCallback = (e: Event) => {
            e.preventDefault();
            stimulateNav(player);
            togglePlay(player);
        };

        const navigateLeftCallback = (e: Event) => {
            e.preventDefault();
            onNavigateLeft?.();
        };

        const navigateRightCallback = (e: Event) => {
            e.preventDefault();
            onNavigateRight?.();
        };

        const closePlayerCallback = (e: Event) => {
            e.preventDefault();
            onClose?.();
        };

        const fullscreenToggleCallback = (e: Event) => {
            e.preventDefault();

            // Запрещаем полноэкранный режим для аудио
            if (!isVideo) {
                return;
            }

            if (!player.isFullscreen()) {
                player.requestFullscreen();
            } else {
                player.exitFullscreen();
            }
        };

        const deleteCallback = (e: Event) => {
            e.preventDefault();
            onDelete?.();
        };

        const switchHotkeysToVideo = () => {
            hotkeys.unbind(LEFT_NAVIGATION_HOTKEYS, hotkeysScope, navigateLeftCallback);
            hotkeys.unbind('right', hotkeysScope, navigateRightCallback);

            hotkeys('left', hotkeysScope, goBackwardCallback);
            hotkeys('right', hotkeysScope, goForwardCallback);
        };
        const switchHotkeysToNavigation = () => {
            hotkeys.unbind('left', hotkeysScope, goBackwardCallback);
            hotkeys.unbind('right', hotkeysScope, goForwardCallback);

            hotkeys(LEFT_NAVIGATION_HOTKEYS, hotkeysScope, navigateLeftCallback);
            hotkeys('right', hotkeysScope, navigateRightCallback);
        };

        /**
         * Состояние, при котором на стрелочках находится переключение файлов в просмотрщике
         */
        let isNavigation = true;

        /**
         * Здесь и в onpause проверяем, что не находимся в полноэкранном режиме,
         * так как в нем на стрелочках всегда находится перемотка
         */
        player.on('play', () => {
            if (player.isFullscreen()) {
                return;
            }

            switchHotkeysToVideo();
            isNavigation = false;
        });
        player.on('pause', () => {
            if (player.isFullscreen()) {
                return;
            }

            switchHotkeysToNavigation();
            isNavigation = true;
        });

        /**
         * На вход/выход из полноэкранного режима генерируется одно и то же событие,
         * поэтому используем ветвление, чтобы понять что конкретно произошло.
         * Сначала происходит переход между полным и "неполным" экраном, а только потом возникает событие.
         * То есть, если в этом обработчике player.isFullscreen() === true, то мы перешли в полноэкранный режим.
         */

        /**
         * Обработчик изменения навигации по стрелочкам
         */
        player.on('fullscreenchange', () => {
            if (!player.isFullscreen() && player.paused()) {
                switchHotkeysToNavigation();
                isNavigation = true;
            } else if (player.isFullscreen() && isNavigation) {
                switchHotkeysToVideo();
                isNavigation = false;
            }
        });

        /**
         * Обработчик изменения бинда кнопки del
         */
        player.on('fullscreenchange', () => {
            if (player.isFullscreen()) {
                hotkeys.unbind(DELETE_HOTKEYS, hotkeysScope, deleteCallback);
            } else {
                hotkeys(DELETE_HOTKEYS, hotkeysScope, deleteCallback);
            }
        });

        const disposePlayerCallback = () => {
            if (isVideo && player.duration() > minVideoDurationToStore) {
                // Уменьшаем на одну секунду, чтобы не было резкого разрыва в восприятии видео
                storeVideoPlayTime(name, publicId, player.currentTime() - 1);
            }
        };

        if (enableStoreVideoPlayTime) {
            player.on('dispose', disposePlayerCallback);

            /**
             * Вешаем обработчик на beforeunload, чтобы время сохранилось при закрытии вкладки
             */
            window.addEventListener('beforeunload', disposePlayerCallback);
        }

        player.ready(() => {
            addCustomNavigation(player, { isPhone, isVideo, rewindTime, goBackward, goForward, stimulateNav, togglePlay });

            hotkeys.setScope(hotkeysScope);

            hotkeys('space', hotkeysScope, togglePlayCallback);
            hotkeys(LEFT_NAVIGATION_HOTKEYS, hotkeysScope, navigateLeftCallback);
            hotkeys('right', hotkeysScope, navigateRightCallback);
            hotkeys('esc', hotkeysScope, closePlayerCallback);
            hotkeys('f', hotkeysScope, fullscreenToggleCallback);
            hotkeys(DELETE_HOTKEYS, hotkeysScope, deleteCallback);

            if (isBBAQualitySelectionAlgorithm) {
                const playerTech = player.tech(true);
                if (playerTech.vhs) {
                    const lastBandwidthPlaylistSelector = playerTech.vhs.selectPlaylist;
                    playerTech.vhs.selectPlaylist = bufferBasedSelectPlaylist(lastBandwidthPlaylistSelector);
                }
            }

            player.loopPlugin();

            if (isVideo) {
                if (enableStoreVideoPlayTime) {
                    const videoTime = getVideoPlayTime(name, publicId);

                    if (videoTime) {
                        player.currentTime(videoTime);
                    }
                }

                player.hlsQualitySelector({
                    displayCurrentQuality: true,
                    startQuality,
                });

                player.$('.vjs-fullscreen-control').addEventListener('click', function (this: HTMLElement) {
                    this.blur();
                });

                // отключаем фиксацию меню по клику
                setTimeout(() => {
                    const qualitySelector = playerRef.current?.$('.vjs-quality-selector');
                    qualitySelector?.addEventListener('mouseleave', () => {
                        (player.$('.vjs-quality-selector .vjs-menu-item') as HTMLElement)?.blur();
                    });
                }, 0);
            }

            const stopWatchMedia = watchMedia(mediaBreakpoint, (hit) => updateControlBarButtons(playerRef.current, hit));
            stopWatchMedia();
        });

        if (isVideo && videoAdEnabled) {
            const initAd = () => {
                const { adm } = adInit({
                    player,
                    controlBarSettings,
                    isPhone,
                    isVideo,
                    gaData: gaPlayerInstance.gaData,
                });
                setAdm(adm);
                player?.off('playing', initAd);

                document.addEventListener('visibilitychange', () => {
                    if (document.visibilityState !== 'visible') {
                        adm.pause();
                    } else {
                        adm.play();
                    }
                });
            };

            player?.on('playing', initAd);
        }

        return () => {
            // dispose также удаляет из ДОМ <video, что может быть проблемой при повторном использовании
            // https://github.com/videojs/video.js/issues/4970
            player.dispose();
            gaPlayerInstance.ga.unsubscribe();

            if (enableStoreVideoPlayTime) {
                window.removeEventListener('beforeunload', disposePlayerCallback);
            }
        };
    }, []);
};
