import { closePopupHelper, openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';

import type { PublicPasswordProps } from './PublicPassword.types';

export const renderPublicPassword = (data: PublicPasswordProps) =>
    openPopupHelper<PublicPasswordProps>({ popupName: popupNames.BIZ_PUBLIC_PASSWORD_DIALOG, data });

export const closePublicPassword = () => closePopupHelper(popupNames.BIZ_PUBLIC_PASSWORD_DIALOG);

export const validatePassword = (pin: string) => {
    if (pin.length > 0 && pin.length < 4) {
        return 'Минимальная длина — 4 символа';
    }

    if (pin.length > 40) {
        return 'Максимальная длина — 40 символов';
    }

    if (pin.length > 0 && !/^[A-Za-zА-Яа-я0-9.,?""!@#$%^&*()-_=+;:<>/\\|}{[\]`~]*$/.test(pin)) {
        return 'Можно использовать только буквы, цифры и спецсимволы';
    }

    return '';
};
