import kasperskyLogo from 'img/quota-landing/partners/kaspersky.png';
import litresLogo from 'img/quota-landing/partners/litres.svg?url';
import uchiRuLogo from 'img/quota-landing/partners/uchi.svg?url';
import vkMusicLogo from 'img/quota-landing/partners/vk-music.svg?url';

import type { PartnerInfo } from './Partners.types';

export const PartnersData: PartnerInfo[] = [
    {
        id: 'fake_offer_vk_music',
        title: 'VK Музыка',
        text: 'Скидка 15% на подписку',
        textPaid: 'Слушайте музыку без ограничений — скидка 15%',
        logo: vkMusicLogo,
    },
    {
        id: 'fake_offer_litres',
        title: 'Литрес',
        text: 'Скидка 20% на подписку',
        textPaid: 'Читайте «Железное пламя» и\u00A0другие книги со скидкой 20%',
        logo: litresLogo,
    },
    {
        id: 'fake_offer_kaspersky',
        title: 'Касперский',
        text: 'Скидка 30% на Premium',
        textPaid: 'Защита компьютера антивирусом — скидка 30% ',
        logo: kasperskyLogo,
    },
    {
        id: 'fake_offer_uchi_ru',
        title: 'Учи.ру',
        text: 'Скидка 10% на все курсы',
        textPaid: 'Математика, Химия, Физика — все курсы со скидкой 10%',
        logo: uchiRuLogo,
    },
];

export const PartnersBlockId = 'partners-fakedoor';
