import type { WeblinksListApiV4Response } from 'reactApp/modules/allDocuments/allDocuments.types';

import { APICallV4Post } from '../APICall';

class WeblinksLinksApiCall extends APICallV4Post<WeblinksListApiV4Response> {
    _method = 'weblinks/list';
}

interface IWeblinksLinksApiCall {
    limit: number;
    cursor?: string;
    exts?: string;
}

export const weblinksLinksApiCall = async ({ limit, cursor = '', exts = '' }: IWeblinksLinksApiCall) =>
    (await new WeblinksLinksApiCall().makeRequest({ limit, cursor, exts }))?.data;
