import { SafetySessionStorage } from '@mail/storage';
import classNames from 'clsx';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NEW_PORTAL_HEADER } from 'reactApp/appHelpers/configHelpers';
import { loadDocumentsSectionRequest } from 'reactApp/modules/personalDocuments/personalDocuments.module';
import { getSectionLoadedState, isNotEmptyDocumentList } from 'reactApp/modules/personalDocuments/personalDocuments.selectors';
import { checkUpdateLicenseRequest } from 'reactApp/modules/profile/profile.module';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { BlurButton } from 'reactApp/sections/NewPersonalDocuments/BlurButton/BlurButton';
import { Empty } from 'reactApp/sections/NewPersonalDocuments/Empty/Empty';
import { List } from 'reactApp/sections/NewPersonalDocuments/List/List';
import { PERSONAL_DOCUMENTS_HIDDEN_KEY } from 'reactApp/sections/NewPersonalDocuments/NewPersonalDocuments.constants';
import { RecognitionButton } from 'reactApp/sections/NewPersonalDocuments/RecognitionButton/RecognitionButton';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { Footer } from 'reactApp/ui/Footer/Footer';
import { Loader } from 'reactApp/ui/Loader/Loader';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './NewPersonalDocumentsMainPage.css';

const storage = new SafetySessionStorage({});

export const NewPersonalDocumentsMainPage = memo(function PersonalDocuments() {
    const dispatch = useDispatch();
    const isLoaded = useSelector(getSectionLoadedState);
    const isNotEmpty = useSelector(isNotEmptyDocumentList);

    const [hidden, setHidden] = useState(storage.getItem(PERSONAL_DOCUMENTS_HIDDEN_KEY) === 'true');

    useEffect(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'documents' });
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'page-entered', source: 'documents' });
        sendPaymentGa({
            eventCategory: ECategoryGa.entered,
            action: 'personal-documents-state',
        });
    }, []);

    useEffect(() => {
        dispatch(loadDocumentsSectionRequest());
        dispatch(checkUpdateLicenseRequest());
    }, [dispatch]);

    if (!isLoaded) {
        return <Loader centered />;
    }

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_new_portal_header]: NEW_PORTAL_HEADER,
            })}
        >
            <div className={styles.title}>
                <BreadcrumbsContainer />
                <div className={styles.tools}>
                    <RecognitionButton />
                    {isNotEmpty && (
                        <BlurButton
                            active={hidden}
                            onClick={() => {
                                storage.setItem(PERSONAL_DOCUMENTS_HIDDEN_KEY, hidden ? 'false' : 'true');
                                setHidden(!hidden);
                            }}
                        />
                    )}
                </div>
            </div>

            {isNotEmpty ? <List hidden={hidden} /> : <Empty />}

            <Footer bottom={8} storage={EStorageType.documents} />
        </div>
    );
});
