/* eslint-disable max-lines-per-function */
import features from 'Cloud/Application/Features';
import { confirmActionWithSuspiciousItems } from 'Cloud/Application/Suspicious';
import config from 'Cloud/config';
import axios from 'lib/axios';
import { xray } from 'lib/xray';
import { path } from 'ramda';
import { O2Auth } from 'reactApp/api/O2Auth';
import {
    ANONYM_USER,
    IS_B2B_BIZ_USER,
    IS_FAMILY_USER,
    IS_MOBILE_BROWSER,
    IS_PHONE_BROWSER,
    IS_PUBLIC,
    IS_PUBLIC_ALBUM,
    USER_EMAIL,
} from 'reactApp/appHelpers/configHelpers';
import { abRequiredSignUpWhenDownloadingTouchSelector, o2UploadFeature } from 'reactApp/appHelpers/featuresHelpers';
import { chooseVariant } from 'reactApp/appHelpers/featuresHelpers/utils';
import { HttpError } from 'reactApp/errors';
import { allDocumentsRadars } from 'reactApp/modules/allDocuments/allDocuments.helpers';
import { AllDocumentsCategoryType } from 'reactApp/modules/allDocuments/allDocuments.types';
import { EAttachTypes } from 'reactApp/modules/attaches/attaches.types';
import { dispatchNewSearchRadar } from 'reactApp/modules/dwh/dwh.module';
import { getSelectedFaceId } from 'reactApp/modules/faces/faces.selectors';
import { chooseVariant as chooseVariantOld } from 'reactApp/modules/features/features.helpers';
// tempexp_15947-next-line
import { getFeatureRequiredSignUpWhenDownloading } from 'reactApp/modules/features/features.selectors';
import { fileController } from 'reactApp/modules/file/file.controller';
import multiDownload, {
    downloadByIframe,
    downloadByLink,
    getIsItemVerifiedByKaspersky,
    isBlockedItem,
    isVirusItem,
} from 'reactApp/modules/file/utils';
import { getHomeItemById } from 'reactApp/modules/home/home.selectors';
import { authPopup } from 'reactApp/modules/ph/ph.thunkActions';
import { showVirusDlg } from 'reactApp/modules/popup/popup.module';
import { getPublicInfo } from 'reactApp/modules/public/public.actions';
import { getPublicRootWeblink } from 'reactApp/modules/public/public.selectors';
import { requiredAuthorizationHelpers } from 'reactApp/modules/requiredAuthorization/helpers';
import { reDownloadController } from 'reactApp/modules/requiredAuthorization/reDownloadController';
import { showAsideModalReqAuth } from 'reactApp/modules/requiredAuthorization/showAsideModal';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getSearchRequestParams } from 'reactApp/modules/search/search.selectors';
import { snackbarController } from 'reactApp/modules/snackbar/snackbar.controller';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { getPageSubtitle } from 'reactApp/modules/storage/storage.helpers';
import { getStorageItemById } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { EDocSubKind } from 'reactApp/sections/AllDocuments/allDocuments.types';
import { EStatus } from 'reactApp/sections/ErrorPage/ErrorPage.types';
import { store as reduxStore } from 'reactApp/store';
import { showCloneAsideModal } from 'reactApp/ui/AsidePromoModal/ShowCloneAsideModal';
import { renderRequiredSignUpDialogData } from 'reactApp/ui/Mobile/RequiredSignUpDialog/RequiredSignUpDialog.helpers.ts';
import { ActionName } from 'reactApp/ui/VirusDialog/VirusDialog.types';
import { sendXray } from 'reactApp/utils/ga';
import { noop, noopVoid, parseURL } from 'reactApp/utils/helpers';
import { sendKasperskiSnackAnalitics } from 'reactApp/utils/kasperskiSnackGa';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { captureException } from 'reactApp/utils/tracer';
import { isDocumentsDomain } from 'server/helpers/isDocumentsDomain';

const maxFilesCount = features.isFeature('multi-dwnld') && features.getFeatureParam('multi-dwnld', 'maxFilesCount', 10);

const auth =
    o2UploadFeature && typeof window !== 'undefined' && isDocumentsDomain(window.location.hostname)
        ? new O2Auth({ clientId: o2UploadFeature.clientId, login: USER_EMAIL })
        : undefined;

const getDowloadUrl = async ({ url, secondTry, isZip }) => {
    // Скачивание ZIPов не знают про параметр no_redirect, поэтому для них код идущий ниже не работает
    // В планах перенести данный функционал на новый O2 токен и isZip в этом случае не потребуется
    if (auth && !isZip) {
        try {
            const token = await auth.getToken();

            if (url.includes('getattach')) {
                return url;
            }

            const newUrl = new URL(url);
            newUrl.searchParams.append('no_redirect', 'true');

            const res = await axios.get(newUrl.href, { headers: O2Auth.prepareAuthHeader(token) });

            return res?.data?.redirect || url;
        } catch (error) {
            if (error.status === 403 && !secondTry) {
                auth.refreshToken();
                return await getDowloadUrl({ url: url, secondTry: true });
            }

            throw new Error('Getting dowload url by O2 failed.', { cause: error });
        }
    }

    return url;
};

const getSnackbarErrorText = (storage, error) => {
    const isAlbum = storage === EStorageType.albums || IS_PUBLIC_ALBUM;

    if (isAlbum && error === 'CONDITION/NOENT') {
        return 'Невозможно скачать пустой альбом';
    }

    return 'Ошибка сервера';
};

function getSearchParams(item = {}, storage, isFolder) {
    const state = reduxStore.getState();
    const { query, xPageId, xReqId } = getSearchRequestParams(state);

    return {
        platform_place: IS_PHONE_BROWSER ? 'touch' : 'desktop',
        section: storage,
        search_phrase: query,
        name_files: item?.nameWithoutExt,
        type_content: item?.kind,
        page_id: xPageId,
        req_id: xReqId,
        pos: item.pos,
        hash: !isFolder ? item?.hash : '',
        size: !isFolder ? item?.size : null,
    };
}

// не даем скачивать убитый веблинк, который был доступен только на скачивание и счетчик скачиваний которого ушел в 0
const downloadIsNotAvaliable = () => {
    const state = reduxStore.getState();
    const rootWeblink = getPublicRootWeblink(state);
    const storage = getCurrentStorage(state);

    return (
        IS_B2B_BIZ_USER &&
        storage === EStorageType.public &&
        rootWeblink.count_downloads_left === 0 &&
        rootWeblink.count_downloads_total &&
        rootWeblink.count_downloads_total > 0 &&
        rootWeblink.weblinkDownloadable
    );
};

const showUnavaliableDownloadSnackbar = () => {
    snackbarController.showSnackbar({
        id: 'download-error',
        closable: true,
        text: 'Ссылка для скачивания больше недоступна',
        type: SnackbarTypes.failure,
    });
};

const IS_MOBILE_CHROME = config.get('IS_MOBILE_CHROME');
const MOBILE_BROWSER = config.get('MOBILE_BROWSER');
const IS_IOS = config.get('IS_IOS');

function downloadLocal(url, downloadName, async, isForceDownloadByLink = false) {
    const state = reduxStore.getState();
    const storage = getCurrentStorage(state);
    const isZip = downloadName.endsWith('.zip');

    function postDownload() {
        // идем в ручку /public/info, за получение актуальных значений счетчиков на скачивание
        if ((IS_B2B_BIZ_USER || ANONYM_USER) && storage === EStorageType.public) {
            reduxStore.dispatch(getPublicInfo());
        }
    }

    getDowloadUrl({ url: url, isZip: isZip }).then((truthUrl) => {
        // в chrome на ios не работает скачивание просматриваемых файлов через iframe, поэтому скачиваем через ссылку
        if ((!async && IS_MOBILE_CHROME && (MOBILE_BROWSER === 'IOS' || IS_IOS)) || isForceDownloadByLink) {
            if (!downloadName) {
                downloadName = parseURL(url).pathname.split('/').pop();
            }
            downloadByLink(truthUrl, downloadName).finally(() => postDownload());
        } else {
            downloadByIframe(truthUrl).finally(() => postDownload());
        }
    });
}

export const downloadItem = function (url, name, storage, item, isForceDownloadByLink = false, fromViewer = false) {
    const state = reduxStore.getState();
    const isFaceFilterActive = Boolean(getSelectedFaceId(state));

    if (downloadIsNotAvaliable()) {
        return showUnavaliableDownloadSnackbar();
    }

    /* tempexp_15344-start */
    const email = UserSelectors.getEmail(state);
    const isAuthorized = !!email;
    const authorizationRequired = IS_PUBLIC && !isAuthorized;

    const requiredSignUpForAllVariants = chooseVariantOld(getFeatureRequiredSignUpWhenDownloading, {
        control: false,
        variant1: authorizationRequired,
    })();

    const requiredSignUpForTouch = chooseVariant(abRequiredSignUpWhenDownloadingTouchSelector, {
        control: false,
        variant1: authorizationRequired,
        variant2: authorizationRequired,
    });

    if (requiredSignUpForTouch) {
        renderRequiredSignUpDialogData();
    } else if (requiredSignUpForAllVariants) {
        const successPage = requiredAuthorizationHelpers.getSuccessPage();

        reduxStore.dispatch(authPopup({ successPage, requiredAuthWhenDownload: 1 }));
        reDownloadController.resetDownloadHappened();
    } else {
        /* tempexp_15344-end */
        downloadLocal(url, name, false, isForceDownloadByLink);

        sendPaymentGa({
            eventCategory: ECategoryGa.download,
            action: 'download-files',
            type_downloads: 'one-file',
            source: IS_PUBLIC_ALBUM ? 'album' : storage,
            place: isFaceFilterActive ? 'face_list' : '',
            extension: item?.ext?.toLowerCase?.(),
            public_id: storage === EStorageType.public || storage === EStorageType.stock ? getPublicRootWeblink(state)?.weblink : undefined,
        });

        if (storage === 'search') {
            const dwhData = {
                eventCategory: ECategoryGa.downloadSearch,
                action: 'download-files',
                count_files: 1,
                source: IS_PUBLIC_ALBUM ? 'album' : storage,
            };
            const items = [
                {
                    file_name: item.nameWithoutExt,
                    file_id: item.id,
                    pos: item.pos,
                    type: item.kind,
                    placement: item.srchSrc,
                },
            ];
            reduxStore.dispatch(dispatchNewSearchRadar({ dwhData, items }));
        }

        sendXray(`app_download_${storage}`);

        let isTempOrCloudAttachType = false;
        if (item && 'attachType' in item) {
            isTempOrCloudAttachType = item.attachType === EAttachTypes.temporary || item.attachType === EAttachTypes.cloud;
        }
        const isAttach = [EStorageType.viewerAttaches, EStorageType.attaches].includes(storage);
        const isPublicOrAttach = IS_PUBLIC || isAttach;

        const SHOW_CLONE_BANNER_ID = 'SHOW_CLONE_BANNER_ID';
        if (
            isAttach &&
            !isTempOrCloudAttachType &&
            fromViewer &&
            !UserSelectors.isBizUser(state) &&
            !sessionStorage.getItem(`${email}|${SHOW_CLONE_BANNER_ID}`)
        ) {
            sessionStorage.setItem(`${email}|${SHOW_CLONE_BANNER_ID}`, 'true');
            const hideAdsUsersNonCorp = (UserSelectors.isPaidUser(state) || IS_FAMILY_USER) && !UserSelectors.isCorpUser(state);
            const hideAdsbyMailFlag = UserSelectors.isCorpUser(state) && UserSelectors.isHideAdInMail(state);
            const aboveBanner = !(hideAdsbyMailFlag || hideAdsUsersNonCorp);
            showCloneAsideModal(item.id, item.storage, aboveBanner ? 64 : undefined);
            return;
        }

        if (
            /* tempexp_15344-next-line */
            chooseVariantOld(getFeatureRequiredSignUpWhenDownloading, {
                control: isPublicOrAttach && getIsItemVerifiedByKaspersky(state, item),
                variant1: isPublicOrAttach && isAuthorized,
            })()
        ) {
            /* tempexp_15344-next-line */
            const runVariant = chooseVariantOld(
                getFeatureRequiredSignUpWhenDownloading,
                {
                    control: () => {
                        /**
                         * Показываем снэк только для пабликов и для аттачей(CLOUDWEB-14706)
                         * и самым последним этапом скачивания, что бы не мешался другим модалкам на тача
                         */
                        snackbarController.showSnackbar({
                            id: 'protected-download',
                            closable: true,
                            title: 'Скачивание началось',
                            text: IS_MOBILE_BROWSER
                                ? 'Файл проверен и безопасен для вашего смартфона'
                                : 'Файл проверен антивирусом и безопасен для вашего компьютера',
                            type: SnackbarTypes.protect,
                            onShow: () => {
                                sendKasperskiSnackAnalitics({ action: 'view' });
                            },
                            onClose: () => {
                                sendKasperskiSnackAnalitics({ action: 'close' });
                            },
                        });
                    },
                    variant1: () => {
                        reDownloadController.setDownloadHappened();
                        showAsideModalReqAuth();
                    },
                },
                {
                    skipCondition: {
                        forAll: () => !requiredAuthorizationHelpers.isSuccessAuthWhenDownload(state),
                    },
                }
            )();

            runVariant();
        }
    }
};

export const download = (idOrItem, forceDownload, useDownloadUrl, storage, fromViewer) => {
    const id = typeof idOrItem === 'object' ? idOrItem.id : idOrItem;

    let item = typeof idOrItem === 'object' ? idOrItem : null;

    storage = storage || item?.storage;

    const isAllDocuments = storage === EStorageType.alldocuments;

    const storageForRadar = storage?.replace('/', '-');

    sendXray(`app_download-try_${storageForRadar}`);

    if (isAllDocuments) {
        const documentType = EDocSubKind[item?.subKind] ?? '';

        xray.send(`alldocuments_${documentType}_download_try`);
    }

    if (storage) {
        item = getStorageItemById(reduxStore.getState(), storage, id);
    } else if (!item) {
        item = getHomeItemById(reduxStore.getState(), id);
    }

    if (isBlockedItem(item)) {
        snackbarController.showSnackbar({
            id: 'blocked',
            text: 'Данный контент нарушает закон РФ и запрещён к распространению и использованию',
            type: SnackbarTypes.failure,
            closable: true,
        });
        return;
    }

    const isVirus = isVirusItem(item);

    if (isVirus && !forceDownload) {
        reduxStore.dispatch(showVirusDlg({ items: [item], doNotChangeRoute: fromViewer }));
        sendXray(`app_download_virus_${storageForRadar}`);
    } else {
        const download = item?.url?.download;
        const get = item?.url?.get;
        const isAttaches = storage === EStorageType.attaches || storage === EStorageType.viewerAttaches;
        const isForceDownloadByLink = storage === EStorageType.quotaCleaner;

        let url = ((useDownloadUrl || isAttaches) && download) || get;

        const isCloudAttachType = item?.type === EAttachTypes.cloud || item?.type === EAttachTypes.cloudStock;

        if (isCloudAttachType && item?.kind === 'image') {
            url = (useDownloadUrl && download) || get;
            sendXray(`download_attach_${item?.type}_type_${useDownloadUrl ? 'jpeg' : 'original'}`);
        }

        if (isVirus) {
            // пользователь подтвердил скачивание
            // зараженного файла из приватного облака
            downloadItem(url, item.name, storage, item, isForceDownloadByLink);
        } else {
            confirmActionWithSuspiciousItems(item, 'download')
                .then(function () {
                    downloadItem(url, item.name, storage, item, isForceDownloadByLink, fromViewer);
                    // Отмена скачивания одного файла - информер не нужен
                })
                .catch((_) => noop);
        }
    }
};

export const downloadItems = (name, idsOrItems, forceDownload) => {
    const state = reduxStore.getState();
    const storage = getCurrentStorage(state);
    const {
        allDocuments: { currentCategory: documentCurrentCategory },
    } = state;
    const downloadingFilesStorage =
        documentCurrentCategory === AllDocumentsCategoryType.sharedWithYou ? EStorageType.incomingPublic : storage;
    const isAllDocuments = storage === EStorageType.alldocuments;
    const documentType = EDocSubKind[idsOrItems[0]?.subKind] ?? '';

    /* tempexp_15344-start */
    const isAuthorized = !!UserSelectors.getEmail(state);
    const authorizationRequired = IS_PUBLIC && !isAuthorized;

    const requiredSignUpForTouch = chooseVariant(abRequiredSignUpWhenDownloadingTouchSelector, {
        control: false,
        variant1: authorizationRequired,
        variant2: authorizationRequired,
    });

    if (requiredSignUpForTouch) {
        renderRequiredSignUpDialogData();
    } else if (
        chooseVariantOld(getFeatureRequiredSignUpWhenDownloading, {
            control: false,
            variant1: authorizationRequired,
        })()
    ) {
        const successPage = requiredAuthorizationHelpers.getSuccessPage();

        reduxStore.dispatch(
            authPopup({
                successPage: successPage.toString(),
                requiredAuthWhenDownload: 1,
            })
        );
        reDownloadController.resetDownloadHappened();
    } else {
        /* tempexp_15344-end */
        const storageForRadar = storage.replace('/', '-');
        const isFaceFilterActive = Boolean(getSelectedFaceId(state));
        sendXray(`app_zip-try_${storageForRadar}`);

        if (!Array.isArray(idsOrItems)) {
            idsOrItems = [idsOrItems];
        }

        sendPaymentGa({
            eventCategory: ECategoryGa.download,
            action: 'download-files',
            type_downloads: idsOrItems.length === 1 ? 'one-folder' : 'files',
            source: IS_PUBLIC_ALBUM ? 'album' : storage,
            place: isFaceFilterActive ? 'face_list' : '',
            placement: idsOrItems.map((item) => item.srchSrc),
            extension: 'none',
            public_id: storage === EStorageType.public || storage === EStorageType.stock ? getPublicRootWeblink(state)?.weblink : undefined,
            ...getSearchParams({ nameWithoutExt: name, kind: 'folder' }, storage, true),
        });

        if (storage === 'search') {
            const dwhData = {
                eventCategory: ECategoryGa.downloadSearch,
                action: 'download-files',
                count_files: idsOrItems.length,
                source: IS_PUBLIC_ALBUM ? 'album' : storage,
            };
            const items = idsOrItems.map(({ nameWithoutExt, id, pos, kind, srchSrc }) => ({
                file_name: nameWithoutExt,
                file_id: id,
                pos,
                type: kind,
                placement: srchSrc,
            }));
            reduxStore.dispatch(dispatchNewSearchRadar({ dwhData, items }));
        }

        const originalItems = idsOrItems;

        if (storage === EStorageType.stock) {
            name = getPageSubtitle();
        }

        const isVirus = idsOrItems.some(isVirusItem);

        function showVirusDialog() {
            reduxStore.dispatch(
                showVirusDlg({
                    items: originalItems,
                    actionName: ActionName.zip,
                    name,
                })
            );
        }

        const allDocumentsXray = allDocumentsRadars({
            section: documentType,
            action: 'zip-download',
        });

        if (isVirus && !forceDownload) {
            showVirusDialog();
            sendXray(`app_zip_virus_${storageForRadar}`);
        } else {
            const makeZip = function (ids) {
                fileController
                    .downloadFiles(ids, name, downloadingFilesStorage)
                    .then((url) => {
                        sendXray([`zip-download${storageForRadar}`, 'ok']);
                        downloadLocal(url, `${name}.zip`, true);
                        if (isAllDocuments) {
                            allDocumentsXray.onSuccess();
                        }
                    })
                    .catch((error) => {
                        sendXray([`zip-download${storageForRadar}`, 'error']);
                        if (error instanceof HttpError && error.status === EStatus.NOT_FOUND) {
                            showUnavaliableDownloadSnackbar();
                        } else {
                            snackbarController.showSnackbar({
                                id: 'apierror',
                                text: getSnackbarErrorText(storage, error?.error),
                                type: SnackbarTypes.failure,
                                closable: true,
                            });
                        }
                        if (isAllDocuments) {
                            allDocumentsXray.onError();
                        }
                    });
            };

            const items = originalItems;

            const originalItemCount = items.length;

            /* tempexp_15344-next-line */
            const runVariant = chooseVariantOld(
                getFeatureRequiredSignUpWhenDownloading,
                {
                    control: noopVoid,
                    variant1: () => {
                        reDownloadController.setDownloadHappened();
                        showAsideModalReqAuth();
                    },
                },
                { skipCondition: { forAll: () => !requiredAuthorizationHelpers.isSuccessAuthWhenDownload(state) } }
            )();

            confirmActionWithSuspiciousItems(items, 'download')
                .then(function (items) {
                    if (IS_MOBILE_BROWSER && originalItemCount < maxFilesCount) {
                        const urlData = items
                            .map((item) => ({
                                url: path(['url', 'get'], item),
                                name: path(['name'], item),
                            }))
                            .filter((item) => !!item.url);

                        sendXray(['download', 'multi', 'start']);

                        multiDownload(urlData)
                            .then(() => {
                                sendXray(['download', 'multi', 'ok']);

                                if (isAllDocuments) {
                                    allDocumentsXray.onSuccess();
                                }

                                /* tempexp_15344-next-line */
                                runVariant();
                            })
                            .catch((error) => {
                                sendXray(['download', 'multi', 'error']);
                                snackbarController.showSnackbar({
                                    id: 'cancel',
                                    text: 'Не удалось скачать файлы',
                                    type: SnackbarTypes.failure,
                                    closable: true,
                                });
                                if (isAllDocuments) {
                                    allDocumentsXray.onError();
                                }
                                captureException(error, { message: 'download multi exception', extra: error });
                            });
                    } else {
                        if (downloadIsNotAvaliable()) {
                            return showUnavaliableDownloadSnackbar();
                        }

                        const ids = items.map((item) => item?.id || '').filter(Boolean);

                        makeZip(ids);

                        /* tempexp_15344-next-line */
                        runVariant();
                    }
                })
                .catch(function () {
                    if (originalItemCount > 1) {
                        snackbarController.showSnackbar({
                            id: 'cancel',
                            text: 'Вы отменили скачивание всех файлов',
                            type: SnackbarTypes.failure,
                            closable: true,
                        });
                    }
                });
        }
    }
};
