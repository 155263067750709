import classNames from 'clsx';
import React, { type ReactElement, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { seriallyClearQuotaCleanerFeature } from 'reactApp/appHelpers/featuresHelpers/features/quotaCleanerSeriallyClear';
import { seriallyClearBinFeature } from 'reactApp/appHelpers/featuresHelpers/features/seriallyClearBin';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getSelectedItems } from 'reactApp/modules/selections/selections.items.selectors';
import { getCurrentTotalCount } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getOpenedBinCountAll } from 'reactApp/modules/trashbin/trashbin.selectors';
import type { RootState } from 'reactApp/store';
import { useSendSearchActionBarAnalytics } from 'reactApp/ui/Mobile/ActionsBar/ActionsBar.analytics';
import { useCustomActions } from 'reactApp/ui/Mobile/ActionsBar/customActions/useCustomActions';
import type { TToolbarItem } from 'reactApp/ui/Toolbar/Toolbar.types';
import { sendDwh } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

import styles from './ActionsBar.css';
import { useItems } from './useItems';

export enum EActionsMode {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
}

export const ActionsBar = memo(
    ({ mode, isPopup, totalCount }: { mode?: EActionsMode; isPopup?: boolean; totalCount?: number }): ReactElement => {
        const storage = useSelector(getCurrentStorage);
        const selectedItems = useSelector(getSelectedItems);
        const actionsBarItems = useItems();
        const { customActions } = useCustomActions({ storage });
        const totalItemsCount = useSelector((state: RootState) => getCurrentTotalCount(state, undefined, undefined));
        const selectedCount = selectedItems.length;
        const allSelected = !!selectedCount && selectedCount === totalItemsCount;
        const binItemsTotalLength = useSelector((state: RootState) => getOpenedBinCountAll(state));
        const sendSearchAnalytics = useSendSearchActionBarAnalytics(storage);

        const sendAnalytics = useCallback(
            ({ action, source, ...data }) => {
                sendDwh({
                    eventCategory: ECategoryGa.toolbar,
                    action,
                    dwhData: {
                        ...data,
                        source: `touch-${source}`,
                    },
                });

                sendSearchAnalytics(action);
            },
            [sendSearchAnalytics]
        );

        const renderCustomItem = useCallback(({ Item, itemProps }: TToolbarItem, index) => {
            if (Item) {
                return <Item key={index} {...itemProps} />;
            }
        }, []);

        return (
            <div
                className={classNames({
                    [styles.root]: true,
                    [styles[`root_${mode}`]]: true,
                    [styles.rootPopup]: isPopup,
                })}
            >
                <div className={styles.counter}>
                    {seriallyClearBinFeature && allSelected && storage === EStorageType.trashbin
                        ? binItemsTotalLength
                        : seriallyClearQuotaCleanerFeature && totalCount && storage === EStorageType.quotaCleaner
                        ? totalCount
                        : selectedItems.length}
                </div>
                <div className={styles.bar}>
                    {actionsBarItems.map((item) => (
                        <div
                            key={item.id}
                            onClick={item.disabled ? noop : () => item.onClick(null, sendAnalytics)}
                            className={classNames({
                                [styles.barItem]: true,
                                [styles[`barItem_${item.id}`]]: Boolean(item.id),
                                [styles.disabled]: item.disabled,
                                [styles.barItem_rebranding]: isRebranding,
                            })}
                            data-qa-action={item.id}
                        >
                            {item.icon}
                            {item.text}
                        </div>
                    ))}
                    {Boolean(customActions.length) && customActions.map(renderCustomItem)}
                </div>
            </div>
        );
    }
);

ActionsBar.displayName = 'ActionsBar';
