import type { TariffCardContentSize } from 'reactApp/ui/TariffCardNew/TariffCard.types';

export enum ETariffLabelKind {
    blue = 'blue',
    green = 'green',
    pink = 'pink',
    turquoise = 'turquoise',
    violet = 'violet',
    yellow = 'yellow',
    grey = 'grey',
    white = 'white',
    actionBlack = 'action-black',
    tariffPromo = 'tariff-promo',
    tarrifRebranding = 'tariff-rebranding',
    /* tempexp_17509-start */
    fromLitresPrimary = 'fromLitresPrimary',
    fromLitresSecondary = 'fromLitresSecondary',
    /* tempexp_17509-end */
    /* tempexp_18084-start */
    tariffPersonalGreen = 'tariffPersonalGreen',
    tariffPersonalBlue = 'tariffPersonalBlue',
    tariffPersonalPink = 'tariffPersonalPink',
    tariffPersonal = 'tariffPersonal',
    /* tempexp_18084-end */
}

export enum ETariffLabelRadius {
    r24 = 'radius24',
    r22 = 'radius22',
}

export interface ITariffLabelProps {
    contentSize?: TariffCardContentSize;
    kind?: ETariffLabelKind;
    radius?: ETariffLabelRadius;
    dataQa?: string;
    isRebrandingQuotaLanding?: boolean;
    enableTopRightBorder?: boolean;
    subscriptionPage?: boolean;
    disableAction?: boolean;
}
