import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import type { QueryParams } from 'reactApp/modules/settings/settings.types';

const { action, source = 'promo', utm_campaign, utm_medium, utm_source, utm_term } = getQueryParams() as QueryParams;

export function appendUtmsToPromoLink(searchParams: URLSearchParams) {
    searchParams.append('source', source);
    if (utm_campaign) {
        searchParams.append('utm_campaign', utm_campaign);
    }
    if (utm_medium) {
        searchParams.append('utm_medium', utm_medium);
    }
    if (utm_source) {
        searchParams.append('utm_source', utm_source);
    }
    if (utm_term) {
        searchParams.append('utm_term', utm_term);
    }
}

export const isThemedPublicLinkEnabled = getFeature('themed-public-link') && action === 'themed-public';

export const isPublicPasswordLinkEnabled = getFeature('public-password-link') && action === 'public-password';
