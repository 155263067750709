import { Icon24HideOutline, Icon24ViewOutline } from '@vkontakte/icons';
import { Caption, Input, Spacing, Text, Title } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { renderSharingNew } from 'reactApp/components/SharingWindow/Sharing.helpers';
import { EFrom } from 'reactApp/components/SharingWindow/Sharing.types';
import { sendPublicPasswordAnalytics } from 'reactApp/components/SharingWindow/SharingNew/Weblink/PublicPassword/PublicPassword.analytics';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { setPublicPin } from 'reactApp/modules/public/public.actions';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { sanitizePasswordValue } from 'reactApp/utils/textHelpers';

import { renderPublicPasswordDeleteModal } from '../PublicPasswordDeleteModal/PublicPasswordDeleteModal.helpers';
import styles from './PublicPasswordSetModal.css';
import type { PublicPasswordSetModalProps } from './PublicPasswordSetModal.types';

export const PublicPasswordSetModal = memo<PublicPasswordSetModalProps>(({ onClose, pin, publicId, itemId }) => {
    const dispatch = useDispatch();
    const scrollRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isValid, setIsValid] = useState(true);

    const storage = useSelector(getCurrentStorage);

    useEffect(() => {
        if (pin) {
            setPassword(pin);
            sendPublicPasswordAnalytics({ action: 'show_delete_touch' });
        }
    }, []);

    const handleClose = useCallback(() => {
        onClose?.();
        renderSharingNew({
            id: itemId,
            itemStorage: storage,
            from: EFrom.WEBLINK,
        });
    }, [onClose, itemId, storage]);

    const handleChangePassowrd = useCallback(
        (event) => {
            let { value } = event.target || {};

            // Удаляем unicode-символы, эмодзи и прочее
            // eslint-disable-next-line no-control-regex
            value = sanitizePasswordValue(value);

            setPassword(value);
            if (!isValid && value.length <= 40) {
                setIsValid(true);
            }
        },
        [setPassword, isValid]
    );

    const tooglePasswordVisibility = useCallback(() => {
        inputRef?.current?.focus();
        setShowPassword((prev) => !prev);
        sendPublicPasswordAnalytics({ action: 'click_visible' });
    }, [setShowPassword, inputRef]);

    const handleSavePassword = useCallback(() => {
        if (password.length > 40) {
            setIsValid(false);
            inputRef?.current?.focus();
            sendPublicPasswordAnalytics({ action: 'error_long' });
        } else {
            dispatch(
                setPublicPin({
                    pin: password,
                    public_id: publicId,
                    onSuccess: () => {
                        onClose?.();
                        renderSharingNew({
                            id: itemId,
                            itemStorage: storage,
                            from: EFrom.WEBLINK,
                        });
                    },
                })
            );
        }

        sendPublicPasswordAnalytics({ action: 'save' });
        emitAnalyticEvent(AnalyticEventNames.PUBLIC_PASSWORD_SAVE);
    }, [password, setIsValid, dispatch, publicId, onClose, storage, itemId]);

    const handleOpenRemovePassword = useCallback(() => {
        closePopupHelper(popupNames.SHARING_DIALOG);
        onClose?.();
        renderPublicPasswordDeleteModal({
            itemId,
            publicId,
            password: pin || '',
        });
        sendPublicPasswordAnalytics({ action: 'click_delete_touch' });
    }, [onClose, itemId, publicId, pin]);

    return (
        <MobileDialog
            id="mobile-public-password-set"
            onClose={handleClose}
            closeOnDimmerClick
            topmost
            scrollRef={scrollRef}
            mod="mobileV4"
            contextPadding="zero"
        >
            <div ref={scrollRef} className={styles.root}>
                <Title level="2">Пароль для папки</Title>
                <Spacing size={8} />
                <Text className={styles.text}>Файлы увидят только с паролем — отправьте его вместе со ссылкой, которой делитесь</Text>
                <Spacing size={16} />
                <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Придумайте пароль"
                    className={classNames(styles.password, {
                        [styles.passwordError]: !isValid,
                    })}
                    value={password}
                    onChange={handleChangePassowrd}
                    getRef={inputRef}
                    after={
                        <>
                            <div className={styles.passwordToogle} onClick={tooglePasswordVisibility}>
                                {showPassword ? <Icon24HideOutline /> : <Icon24ViewOutline />}
                            </div>
                        </>
                    }
                />
                {!isValid && (
                    <>
                        <Spacing size={6} />
                        <Caption level="1" className={styles.errorText}>
                            Пароль не должен быть длиннее 40 символов
                        </Caption>
                    </>
                )}
                <Spacing size={16} />
                <Button theme="vk" primary sizeMode={ButtonSizeMode.big} disabled={password.length < 4} onClick={handleSavePassword}>
                    Сохранить
                </Button>
                {pin && (
                    <>
                        <Spacing size={12} />
                        <Button theme="vk" sizeMode={ButtonSizeMode.big} onClick={handleOpenRemovePassword}>
                            Сбросить пароль
                        </Button>
                    </>
                )}
            </div>
        </MobileDialog>
    );
});

PublicPasswordSetModal.displayName = 'PublicPasswordSetModal';
