import { useSelector } from 'react-redux';
import { PROMO_TARIFFS } from 'reactApp/appHelpers/configHelpers';
import { mailPopupUploadAb, mailPopupUploadTariffs } from 'reactApp/appHelpers/featuresHelpers/features/mailPopup';
import { PaidInfoSelectors } from 'reactApp/modules/paidInfo/paidInfo.selectors';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import type { RootState } from 'reactApp/store';
import type { Product } from 'reactApp/types/Billing';

export const useTariffs = ({
    quota,
    isUserWithoutPayment,
    isUserFreeTb,
    isPaidUser,
    productId,
    isRestrictedUploadLimit,
}: {
    quota: number;
    isUserWithoutPayment: boolean;
    isUserFreeTb: boolean;
    isPaidUser: boolean;
    productId: string | undefined;
    isRestrictedUploadLimit: boolean;
}): Product | undefined => {
    const forcedTariff = useSelector((state: RootState) => ProductsSelectors.getAvailableProductById(state, productId));
    const month = useSelector((state: RootState) => ProductsSelectors.getMonthProductByQuota(state, quota));
    const discountProduct = useSelector((state: RootState) => ProductsSelectors.getProductById(state, PROMO_TARIFFS?.discountMailPopup));
    const trial = useSelector((state: RootState) => ProductsSelectors.getMailTrialByQuota(state, quota));

    /* tempexp_17604-start */
    const newTrial = useSelector((state: RootState) => ProductsSelectors.getProductById(state, mailPopupUploadTariffs.trial));
    const forceTrial = useSelector((state: RootState) => ProductsSelectors.getProductById(state, mailPopupUploadTariffs.forceTrial));
    const defaultProduct = useSelector((state: RootState) => ProductsSelectors.getProductById(state, mailPopupUploadTariffs.default));

    const paidLessThan3Month = useSelector((state: RootState) => PaidInfoSelectors.lastPaymentWasLessThan(state, { months: 3 }));

    let newTariff;

    if (isRestrictedUploadLimit && mailPopupUploadAb === 'b') {
        // если никогда не платил - триал
        // платил меньше 3ех месяцев назад - обычный тариф
        // платил больше 3ех месяцев назад - форс триал
        newTariff = isUserWithoutPayment ? newTrial : paidLessThan3Month ? defaultProduct : forceTrial;
    }

    if (newTariff && newTariff.space.original > quota) {
        return newTariff;
    }
    /* tempexp_17604-end */

    // если найден тариф, который стоит зафорсить, то предлагаем его, если с ним можно будет загрузить все файлы
    if (forcedTariff && forcedTariff.space.original > quota) {
        return forcedTariff;
    }

    // если пользователь никогда не совершал оплат в Облаке, не бесплатный ТБ и ему доступен триал - возвращаем триал
    if (isUserWithoutPayment && !isUserFreeTb && trial) {
        return trial;
    }

    // если у пользователя были оплаты в облаке, но он бесплатник(но не 1ТБ)
    // - годовый скидочный тариф, при условии что квота влезет в квоту тарифа
    if (!isPaidUser && !isUserWithoutPayment && !isUserFreeTb && discountProduct && discountProduct.space.original > quota) {
        return discountProduct;
    }

    // платникам, бесплатным ТБ - обычный месячный тариф
    return month;
};
