import { Spacing, Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { memo, useCallback } from 'react';
import { useIntersecObsrWithParams } from 'reactApp/sections/QuotaLanding/hooks/useIntersecObsrWithParams';
import { sendQuotaBlockViewDWH } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './FeaturesCarousel.css';
import { getFeaturesCarouselData } from './FeaturesCarousel.data';

export const FeaturesCarousel = memo(() => {
    const slides = getFeaturesCarouselData();

    const onIntersecting = useCallback(() => {
        sendQuotaBlockViewDWH('advantages', 'view');
    }, []);

    const refIntersection = useIntersecObsrWithParams({
        onIntersecting,
        isMobile: true,
        headerHeight: 0,
    });

    return (
        <div className={styles.root} id="benefits" ref={refIntersection}>
            <Spacing size={60} />
            <Text className={styles.title}>Что есть в подписке Mail&nbsp;Space</Text>
            <Spacing size={20} />
            <Swiper
                spaceBetween={12}
                centeredSlides
                slidesPerView={1.2}
                pagination={{
                    clickable: true,
                    bulletClass: styles.bullet,
                    bulletActiveClass: styles.bulletActive,
                    clickableClass: styles.bulletWrapper,
                }}
                breakpoints={{
                    500: {
                        slidesPerView: 1.5,
                    },
                    700: {
                        slidesPerView: 2.1,
                    },
                }}
                modules={[Pagination]}
                initialSlide={3}
                loop
            >
                {slides.map(({ icon, title, text, id }, index) => {
                    return (
                        <SwiperSlide key={id} virtualIndex={index}>
                            <div className={styles.slide}>
                                <div
                                    className={classNames(styles.slideIcon, {
                                        [styles[`slideIcon_${id}`]]: id,
                                    })}
                                >
                                    {icon}
                                </div>
                                <Spacing size={32} />
                                <Text className={styles.slideTitle}>{title}</Text>
                                <Spacing size={16} />
                                <Text className={styles.slideText}>{text}</Text>
                            </div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <Spacing size={72} />
        </div>
    );
});

FeaturesCarousel.displayName = 'FeaturesCarousel';
