import { Icon28CloudOutline, Icon28DeleteClockOutline, Icon28MessageCrossOutline, Icon28ServicesOutline } from '@vkontakte/icons';
import React, { type ReactElement } from 'react';
import { Icon28DocumentArrowUpOutline, Icon28MailStackOutline } from 'reactApp/ui/VKUIIcons';

type FeatureCarouselItem = {
    id: string;
    title: string;
    text: string;
    icon: ReactElement;
};

const FeatureCarouselItems: FeatureCarouselItem[] = [
    {
        id: 'widgets',
        title: 'Удобные виджеты',
        text: 'Календарь, Заметки и другие сервисы\u00A0—\u00A0с\u00A0вами в Почте',
        icon: <Icon28ServicesOutline />,
    },
    {
        id: 'upload',
        title: 'Загрузка до 100 ГБ',
        text: 'Сохраняйте и отправляйте большие файлы, даже игры',
        icon: <Icon28DocumentArrowUpOutline />,
    },
    {
        id: 'cloud',
        title: 'Больше места',
        text: 'Гигабайты и даже терабайты — место увеличится по тарифу',
        icon: <Icon28CloudOutline />,
    },
    {
        id: 'timer',
        title: 'Таймер автоудаления',
        text: 'Файлы из совместной папки вовремя освободят место',
        icon: <Icon28DeleteClockOutline />,
    },
    {
        id: 'cancel-mail',
        title: 'Отмена отправки',
        text: '30 секунд, чтобы передумать и\u00A0остановить письмо',
        icon: <Icon28MailStackOutline />,
    },
    {
        id: 'ad',
        title: 'Ноль рекламы',
        text: 'Теперь ничто не будет мешать в\u00A0Почте и Облаке',
        icon: <Icon28MessageCrossOutline />,
    },
];

// Добавлена функция на случай если в будущем нужно будет как-то модицицировать список по разным условиям.
export const getFeaturesCarouselData = () => FeatureCarouselItems;
