import classNames from 'clsx';
import React, { type VFC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useHandlers } from 'reactApp/sections/MobileSubscriptionsPage/hooks/useHandlers';
import { TABS_MAP } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage.constants';
import { useTariffBuyDialog } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage.hooks';
import { TariffCard } from 'reactApp/sections/MobileSubscriptionsPage/TariffCard/TariffCard';
import { type ACTIONS_DEFS, ETabsName } from 'reactApp/ui/TariffSectionNew/TariffSection.constants';

import styles from './Tariff.css';

type Props = {
    marketingPromo?: (typeof ACTIONS_DEFS)['marketingPromo'];
    tariffs: any[];
    isRebranding?: boolean;
};

export const Tariffs: VFC<Props> = ({ marketingPromo, tariffs, isRebranding = false }) => {
    const { handleFAQClick, handleAllTariffsClick } = useHandlers();
    const { openTariffBuy } = useTariffBuyDialog();

    const onClick = useCallback(
        (params) => {
            openTariffBuy({ ...params, paySource: 'subs_card' });
        },
        [openTariffBuy]
    );

    return (
        <>
            <div className={styles.wrapper}>
                {tariffs.map((tariff, index) => (
                    <div className={classNames(styles.tariff, { [styles.tariff_action]: marketingPromo })} key={tariff.products?.[0].id}>
                        <TariffCard
                            {...tariff}
                            onClick={onClick}
                            // tempexp_13600-next-line
                            openAbCard={index === 0}
                            showPriceByMonth={false}
                            isRebrandingSubscription={isRebranding}
                        />
                    </div>
                ))}
            </div>

            {marketingPromo && (
                <div className={styles.actionLinks}>
                    <Link to={TABS_MAP[ETabsName.usual]} className={classNames(styles.buttonLink)} onClick={handleAllTariffsClick}>
                        Все тарифы
                    </Link>
                    <a className={classNames(styles.buttonLink)} href={marketingPromo.conditions} target="_blank" rel="noreferrer">
                        Полные условия акции
                    </a>
                </div>
            )}

            {tariffs.length === 0 && <div className={styles.noTariff}>Нет доступных тарифов</div>}

            <div
                className={classNames(styles.commonLinks, {
                    [styles.commonLinks_actionPromo]: !!marketingPromo,
                })}
            >
                <a
                    className={styles.link}
                    href="https://help.mail.ru/cloud_web/size/increase"
                    onClick={handleFAQClick}
                    target="_blank"
                    rel="noreferrer"
                    data-name="cancel-subscription"
                >
                    Как отменить подписку?
                </a>
            </div>
        </>
    );
};
