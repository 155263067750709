import type { Space } from '@mail/cross-sizes-utils';
import type { EStatus } from 'reactApp/modules/subscriptions/subscriptions.types';

export enum EPromoSubscriptionType {
    quota = 'quota',
    discount = 'discount',
    android = 'android',
    ios = 'ios',
    rustore = 'rustore',
}

export interface IState {
    list: Record<string, IPromo>;
    rawList: IPromoApi[];
    isLoading: boolean;
    isLoaded: boolean;
}

export interface IPromoApi {
    unlocked: Record<string, string>;
    icon: string;
    expire_at: number;
    start_at: number;
    id: string;
    space: number;
    account_link?: string;
    period: string;
    source: string;
    productId: string;
}

export interface IGetPromoAction {
    list: IPromoApi[];
}

export interface IFlagsProduct {
    upload?: boolean;
    paidFeatures?: boolean;
    pro?: boolean;
}

export interface IPromo {
    expires?: number;
    id: string;
    start: number;
    icon?: string;
    productId: string;
    space?: Space;
    type: EPromoSubscriptionType;
    period: string;
    promoId: string;
    isProfessional: boolean;
    flags: IFlagsProduct;
    accountLink?: string;
    products: IPromoProduct[];
    partner?: string;
    status: EStatus;
}

export interface IPromoQuotaSubscription extends IPromo {
    type: EPromoSubscriptionType.quota;
    space: Space;
    icon: string;
}

export interface IPromoProduct {
    id: string;
    price: number;
    period: string;
    discountPrice?: number;
    space: Space;
    isTrial: boolean;
    flags: IFlagsProduct;
}

export interface IUnlockedProduct {
    product_id: string;
    price: number;
    discount_price: number;
    trial: boolean;
    period: string;
    space: number;
}

export interface IInitPromoTariffs {
    onSuccess?();
    onError?();
}

export type TInitPromoTariffs = IInitPromoTariffs | undefined;

export const isPromoQuotaSubscription = (subscription): subscription is IPromoQuotaSubscription => {
    return (subscription as IPromoQuotaSubscription).type === EPromoSubscriptionType.quota;
};
