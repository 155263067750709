import { Icon20Cancel, Icon20ErrorCircleOutline, Icon20HideOutline, Icon20ViewOutline } from '@vkontakte/icons';
import { Input, Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { sendPublicPasswordAnalytics } from 'reactApp/components/SharingWindow/SharingNew/Weblink/PublicPassword/PublicPassword.analytics';
import { setPublicPin } from 'reactApp/modules/public/public.actions';
import { ButtonLink } from 'reactApp/ui/ButtonLink/ButtonLink';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';
import { sanitizePasswordValue } from 'reactApp/utils/textHelpers';

import styles from './Password.css';

type PasswordProps = {
    onClose: (sendRadar?: boolean) => void;
    onSave: (password: string) => void;
    defaultPassword?: string;
    publicId: string;
};

export const Password = memo<PasswordProps>(({ onClose, onSave, defaultPassword, publicId }) => {
    const dispatch = useDispatch();
    const tooltipRef = useRef<HTMLDivElement | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        if (defaultPassword) {
            setPassword(defaultPassword);
        }
    }, []);

    const tooglePasswordVisibility = useCallback(() => {
        setShowPassword((prev) => !prev);
        sendPublicPasswordAnalytics({ action: 'click_visible' });
    }, [setShowPassword]);

    const handleChangePassowrd = useCallback(
        (event) => {
            let { value } = event.target || {};

            // Удаляем unicode-символы, эмодзи и прочее
            // eslint-disable-next-line no-control-regex
            value = sanitizePasswordValue(value);

            setPassword(value);
            if (!isValid && value.length <= 40) {
                setIsValid(true);
            }
        },
        [setPassword, isValid]
    );

    const handleSavePassword = useCallback(() => {
        if (password.length > 40) {
            setIsValid(false);
            setShowTooltip(true);
            inputRef?.current?.focus();
        } else {
            dispatch(
                setPublicPin({
                    pin: password,
                    public_id: publicId,
                    onSuccess: () => {
                        onSave(password);
                        onClose(false);
                    },
                })
            );
        }
        sendPublicPasswordAnalytics({ action: 'save' });
        emitAnalyticEvent(AnalyticEventNames.PUBLIC_PASSWORD_SAVE);
    }, [password, onClose, onSave, dispatch, setIsValid, setShowTooltip, publicId, inputRef]);

    const closeTooltip = useCallback(() => {
        setShowTooltip(false);
    }, [setShowTooltip]);

    const onErrorTooltipShow = useCallback(() => {
        sendPublicPasswordAnalytics({ action: 'error_long' });
    }, []);

    return (
        <>
            <Input
                type={showPassword ? 'text' : 'password'}
                placeholder="Придумайте пароль"
                className={classNames(styles.password, {
                    [styles.passwordError]: !isValid,
                })}
                value={password}
                onChange={handleChangePassowrd}
                getRef={inputRef}
                after={
                    <>
                        {!isValid ? (
                            <>
                                <div className={styles.error} ref={tooltipRef} onClick={() => setShowTooltip(true)}>
                                    <Icon20ErrorCircleOutline />
                                </div>
                                {showTooltip && (
                                    <FloatingTooltip
                                        target={tooltipRef}
                                        placement={ETooltipPlacement.top}
                                        onClose={closeTooltip}
                                        onShow={onErrorTooltipShow}
                                        closable
                                        closeOnOutsideClick
                                        withinModal
                                    >
                                        <div className={styles.tooltip}>
                                            <Text className={styles.tooltipText}>Пароль не должен быть длиннее 40 символов</Text>
                                        </div>
                                    </FloatingTooltip>
                                )}
                            </>
                        ) : (
                            <div className={styles.passwordToogle} onClick={tooglePasswordVisibility}>
                                {showPassword ? <Icon20HideOutline /> : <Icon20ViewOutline />}
                            </div>
                        )}
                        <ButtonLink
                            hoverUnderline={false}
                            className={styles.savePassword}
                            onClick={handleSavePassword}
                            disabled={password.length < 4}
                        >
                            Сохранить
                        </ButtonLink>
                    </>
                }
                autoFocus
            />
            <div className={styles.passwordCancel} onClick={() => onClose(false)}>
                <Icon20Cancel />
            </div>
        </>
    );
});

Password.displayName = 'ChangePublicPasswordInput';
