import type { PayloadAction } from '@reduxjs/toolkit';
import { logger } from 'lib/logger';
import { BusinessTemplatesAPICall } from 'reactApp/api/BusinessTemplatesAPICall';
import { addFileApiCall } from 'reactApp/api/FileAddAPICall';
import { BUSINESS_TEMPLATES_ENABLED } from 'reactApp/appHelpers/featuresHelpers/features/businessTemplates';
import { openFastCheckout } from 'reactApp/modules/payment/payment.module';
import type { OpenFastCheckoutAction } from 'reactApp/modules/payment/payment.types';
import { initProducts, loadProductsSuccess } from 'reactApp/modules/products/products.module';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { applicationStart } from 'reactApp/modules/router/router.module';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { channel } from 'redux-saga';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { take } from 'typed-redux-saga';

import {
    businessTemplatesCreateFileFromTemplateAction,
    businessTemplatesCreateFileFromTemplateDoneAction,
    businessTemplatesCreateFileFromTemplateErrorAction,
    businessTemplatesLoadedAction,
    businessTemplatesLoadErrorAction,
    businessTemplatesLoadingAction,
    businessTemplatesOpenFC,
} from './businessTemplates.actions';
import type { BusinessTemplateItem } from './businessTemplates.types';

const callGetAllTemplates = async () => (await new BusinessTemplatesAPICall().makeRequest()).data.templates;

const callAddFileFromTemplate = async (template: BusinessTemplateItem) =>
    addFileApiCall({
        hash: template.hash,
        size: template.size,
        home: `/${template.filename}`,
        conflict: 'rename',
    }) as Promise<string>;

function* loadBusinessTemplatesOnStart() {
    if (!BUSINESS_TEMPLATES_ENABLED) {
        return;
    }

    yield put(businessTemplatesLoadingAction());
}

function* handleLoadAllBusinessTemplates() {
    try {
        const data: Awaited<ReturnType<typeof callGetAllTemplates>> = yield call(callGetAllTemplates);

        yield put(businessTemplatesLoadedAction(data));
    } catch (error) {
        logger.error(error);
        yield put(businessTemplatesLoadErrorAction());
    }
}

function* handleFileCreateFromTemplate(action: ReturnType<typeof businessTemplatesCreateFileFromTemplateAction>) {
    const { template, onSuccess, onError } = action.payload;

    try {
        const { isFull } = yield select(UserSelectors.getCloudSpace);

        if (isFull) {
            yield put(businessTemplatesCreateFileFromTemplateErrorAction());
            return yield call(onError, 'quota');
        }

        const resp: Awaited<ReturnType<typeof callAddFileFromTemplate>> = yield call(callAddFileFromTemplate, template);

        yield put(businessTemplatesCreateFileFromTemplateDoneAction());
        yield call(onSuccess, resp);
    } catch (error) {
        logger.error(error);
        yield put(businessTemplatesCreateFileFromTemplateErrorAction());
        yield call(onError, 'unk');
    }
}

function handleCloseAfterSuccess() {
    window.location.reload();
}

function* handleOpenFC({ payload }: PayloadAction<OpenFastCheckoutAction>) {
    const afterUpdateChannel = channel();
    const closeChannel = channel();

    const isLoaded = yield select(ProductsSelectors.isLoaded);

    if (!isLoaded) {
        yield put(initProducts());
        yield take(loadProductsSuccess);
    }

    yield put(
        openFastCheckout({
            ...payload,
            afterUpdate: () => {
                payload.afterUpdate?.();
                afterUpdateChannel.put(true);
            },
            onClose: () => {
                payload.onClose?.();
                closeChannel.put(true);
            },
        })
    );

    yield take(afterUpdateChannel);
    yield take(closeChannel);

    afterUpdateChannel.close();
    closeChannel.close();
    handleCloseAfterSuccess();
}

export function* watchLoadAllBusinessTemplates() {
    yield takeEvery(businessTemplatesOpenFC.toString(), handleOpenFC);
    yield takeEvery(businessTemplatesLoadingAction.toString(), handleLoadAllBusinessTemplates);
    yield takeEvery(applicationStart.toString(), loadBusinessTemplatesOnStart);
    yield takeLatest(businessTemplatesCreateFileFromTemplateAction.toString(), handleFileCreateFromTemplate);
}
