/* eslint-disable max-lines */
import { type PayloadAction, createAction, createReducer } from '@reduxjs/toolkit';
import { extInfo } from 'lib/extInfo';
import { path } from 'ramda';
import { IS_DOCUMENTS_DOMAIN } from 'reactApp/appHelpers/configHelpers';
import { isTouchSearchContentEnabled, isWebSearchContentEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { getExtension, getItemNameWithoutExt, getParent, isFolder, normalizeId, normalizePublicApiInfo } from 'reactApp/modules/file/utils';
import {
    addFilesSuccess,
    addToFavoritesSuccess,
    moveItemsSuccess,
    publishWeblink,
    removeFileSuccess,
    removeFromFavoritesSuccess,
    renameItemSuccess,
    resetWeblinkCountDownloads,
    shareFolderSuccess,
    toggleWeblinkAccessRights,
    toggleWeblinkDomestic,
    toggleWeblinkDownloadable,
    unPublishWeblink,
    unshareFolderSuccess,
    updateWeblinkAutoDelete,
    updateWeblinkCountDownloads,
    updateWeblinkExpires,
    weblinkSetDomainAccess,
} from 'reactApp/modules/modifying/modifying.actions';
import type {
    AddFileSuccessAction,
    AddItem,
    IAddToFavoritesSuccess,
    IRemoveFromFavoritesSuccess,
    IRenameItemSuccess,
    IShareFolderSuccess,
    IUnshareFolderSuccess,
    IWeblinkSetDomainAccess,
    MoveItemsSuccessAction,
    RemoveFileSuccessAction,
    ResetWeblinkCountDownloads,
    ToggleWeblinkAccessRights,
    ToggleWeblinkDomestic,
    ToggleWeblinkDownloadable,
    UnpublishWeblinkAction,
    UpdateWeblinkAction,
    UpdateWeblinkAutoDelete,
    UpdateWeblinkCountDownloads,
    UpdateWeblinkExpires,
} from 'reactApp/modules/modifying/modifying.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UrlBuilder } from 'reactApp/modules/urlBuilder/UrlBuilder';
import { AccessRights } from 'reactApp/types/Tree';
import type { ESearchOptionSource } from 'reactApp/ui/WebSearch/WebSearch.data';
import { normalizeMtime, renameCameraUploadFolder } from 'reactApp/utils/tree.helpers';

import { ALL_DOCUMENTS_DOMAIN_ROUTES } from '../allDocuments/allDocuments.constants';
import { SEARCH_PER_PAGE_LIMIT } from './search.helpers';
import type {
    ApiSearchFolderResponseFile,
    ApiSearchResponceList,
    ApiSearchResponseFile,
    ApiSearchResponseFolder,
    fakeLoadSuccessParams,
    SearchItem,
    SearchLoadParams,
    SearchLoadSuccessParams,
    SearchRequestParams,
    SearchState,
    VirusScanResponce,
} from './search.types';

const urlBuilder = new UrlBuilder();

const initialState: SearchState = {
    [ROOT_FOLDER_ID]: {
        id: '/',
        isLoading: false,
        isLoaded: false,
        count: {
            all: 0,
            loaded: 0,
            files: 0,
            folders: 0,
        },
        hasMoreToLoad: false,
        error: false,
        childs: [],
        name: 'Результаты поиска',
    },
    list: {},
    listFull: [],
    listFullOrigin: { filename: [], text_content: [] },
    searchSection: null,
    requestParams: {
        query: '',
        xPageId: 'initial_empty_value',
        xReqId: 'initial_empty_value',
        path: '',
        type: '',
    },
    searchHistoryRequestParams: {
        xPageId: 'initial_empty_value',
        xReqId: 'initial_empty_value',
    },
    offset: 0,
    searchStartLocation: { id: IS_DOCUMENTS_DOMAIN ? ALL_DOCUMENTS_DOMAIN_ROUTES.document : '/home', search: '' },
    searchHistory: [],
    emptyFilters: false,
    contentIndexed: isTouchSearchContentEnabled || isWebSearchContentEnabled ? undefined : false,
    webSearchContentEnabled: false,
    touchSearchContentEnabled: false,
};

export const searchLoadRequestStart = createAction<SearchLoadParams | undefined>('search/load/requestStart');
export const searchLoadRequest = createAction('search/load/request');
export const searchLoadStart = createAction<ApiSearchResponceList>('search/load/start');
export const searchFakeLoadStart = createAction<SearchLoadParams | undefined>('search/load/fakeLoad');
export const searchReset = createAction('search/load/reset');
export const searchLoadSuccess = createAction('search/load/success');
export const searchFakeLoadSuccess = createAction<fakeLoadSuccessParams>('search/load/fakeSuccess');
export const searchLoadError = createAction('search/load/error');
export const searchLoadHistorySuccess = createAction<SearchLoadSuccessParams>('search/load/historySuccess');
export const searchLoadHistoryError = createAction('search/load/historyError');
export const searchLoadMoreRequest = createAction('search/loadMore/request');
export const searchLoadMoreSuccess = createAction<number>('search/loadMore/success');
export const searchLoadHistory = createAction('search/load/history');
export const searchHistoryRemoveItem = createAction<string>('search/remove/history');
export const searchHistoryClear = createAction('search/clear/history');
export const searchHistoryHide = createAction('search/hide/history');
export const searchRequestParamsUpdate = createAction<SearchRequestParams>('search/update/params');
export const searchUpdateDomainFolders = createAction<string[]>('search/update/domainFolders');
export const searchUpdateVirusScan = createAction<VirusScanResponce>('search/update/virusScan');
export const searchUpdateStartLocation = createAction<{ id: string; search: string }>('search/update/startLocation');
export const searchLoadInFolderRequest = createAction<{ id: string }>('search/load/folder/request');
export const searchParamsUpdatePath = createAction<{ id: string }>('search/update/path');
export const searchFilterExactFolder = createAction<{ id: string }>('search/update/exactFolder');
export const searchSetEmptyFilters = createAction<boolean>('search/update/emptyFilters');
export const searchContentIndexed = createAction<boolean>('search/update/contentIndexed');

// Проверяем что файл лежит строго в указанной папке, а не в подпапках.
const filterFilesInExactFolder = (item: ApiSearchResponseFolder | ApiSearchFolderResponseFile, folder: string) => {
    if (!folder || folder === ROOT_FOLDER_ID) {
        return true;
    }

    return item.home === `${folder}/${item.name}`;
};

const normalize = (
    item: ApiSearchResponseFolder | ApiSearchFolderResponseFile | ApiSearchResponseFile | AddItem,
    index?: number
): SearchItem => {
    if ('__reduxTree' in item && item.__reduxTree) {
        return item;
    }

    item.name = item.name ?? normalizeId(item.home).split('/').pop();
    const ext = getExtension(item);
    const nameWithoutExt = getItemNameWithoutExt(item.name, ext);
    const { home, name, size } = item;

    const normalizedItem = {
        pos: index,
        home,
        parentFolder: getParent(home || '') || '',
        id: home || '',
        name,
        nameWithoutExt,
        nameParts: 'name_parts' in item ? item.name_parts : undefined,
        size,
        srchSrc: 'srch_src' in item ? (item.srch_src as ESearchOptionSource) : undefined,
        ...normalizePublicApiInfo(item),
    };

    if (isFolder(item)) {
        return {
            ...normalizedItem,
            kind: item.kind || 'folder',
            name: renameCameraUploadFolder(item),
            __reduxTree: true,
            parent: '/',
            isFolder: true,
            storage: EStorageType.search,
        };
    }

    const { subKind, kind } = extInfo.get(ext);
    const { illegal, blocked, hash, mtime, virus_scan } = item as ApiSearchFolderResponseFile;

    return {
        ...normalizedItem,
        isFolder: false,
        storage: EStorageType.search,
        isIllegal: !!illegal,
        isBlocked: !!blocked,
        isInFavorites: !!path(['favorite'], item) || path(['isInFavorites'], item),
        hash,
        parent: '/',
        kind,
        subKind,
        __reduxTree: true,
        mtime: normalizeMtime(mtime) || Date.now(),
        virusScan: virus_scan,
        ext,
        thumbnails: urlBuilder.getThumb({
            hash,
            ext,
            id: normalizedItem.id,
            size,
            kind,
            name,
            path: home,
            isPublic: false,
            isStock: false,
            dwl_token: null,
            weblink: '',
        }),
        url: urlBuilder.getUrls({
            ext,
            isPublic: false,
            id: normalizedItem.id,
            weblink: '',
            size,
            kind,
            subKind,
            isStock: false,
            name,
            path: home,
            dwl_token: null,
        }),
    };
};

export const searchReducer = createReducer(initialState, {
    [searchUpdateStartLocation.type]: (state, action: PayloadAction<{ id: string; search: string }>) => {
        state.searchStartLocation = action.payload;
    },
    [searchLoadRequest.type]: (state) => {
        state[ROOT_FOLDER_ID].isLoading = true;
        state[ROOT_FOLDER_ID].isLoaded = false;
        state[ROOT_FOLDER_ID].error = false;
    },
    [searchLoadStart.type]: (state, action: PayloadAction<ApiSearchResponceList>) => {
        state.listFull = action.payload;

        const splitBySource = action.payload.reduce(
            (acc, item) => {
                acc[item.srch_src].push(item);
                return acc;
            },
            { filename: [], text_content: [] }
        );
        state.listFullOrigin = splitBySource;
    },

    [searchFakeLoadSuccess.type]: (state, action: PayloadAction<fakeLoadSuccessParams>) => {
        const { origin, filtered, searchSection } = action.payload;
        state.listFullOrigin = origin;
        state.listFull = filtered;
        state.searchSection = searchSection ? searchSection : null;
    },

    [searchUpdateDomainFolders.type]: (state, action: PayloadAction<string[]>) => {
        const domainFoldersIDs = action.payload;
        state.listFull.forEach((item) => {
            if (domainFoldersIDs.includes(item.home)) {
                item.kind = 'domain-folder';
            }
        });
    },
    [searchUpdateVirusScan.type]: (state, action: PayloadAction<VirusScanResponce>) => {
        const virusScanResults = action.payload;
        state.listFull.forEach((item) => {
            if ('hash' in item && item?.hash && virusScanResults[item.hash]) {
                item = {
                    ...item,
                    ...virusScanResults[item.hash],
                };
            }
        });
    },
    [searchReset.type]: (state) => {
        state[ROOT_FOLDER_ID].childs = [];
        state[ROOT_FOLDER_ID].isLoading = false;
        state[ROOT_FOLDER_ID].isLoaded = true;
        state[ROOT_FOLDER_ID].count = {
            all: 0,
            loaded: 0,
            files: 0,
            folders: 0,
        };
        state[ROOT_FOLDER_ID].hasMoreToLoad = false;
        state[ROOT_FOLDER_ID].error = false;
        state.list = {};
        state.listFull = [];
        state.listFullOrigin = { filename: [], text_content: [] };
        state.searchSection = null;
        state.requestParams = {
            query: '',
            xPageId: 'initial_empty_value',
            xReqId: 'initial_empty_value',
            path: '',
            type: '',
        };
        state.searchHistory = [];
        state.emptyFilters = false;
    },
    [searchLoadSuccess.type]: (state) => {
        state[ROOT_FOLDER_ID].childs = [];
        state[ROOT_FOLDER_ID].isLoading = false;
        state[ROOT_FOLDER_ID].isLoaded = true;

        /**
         * У текущей ручки поиска нет возможности для пагинации (offset или cursor), есть только limit.
         * Грузим весь список результатов и сохраняемм в сторе. Это нужно будет для loadMore.
         * Изначально показываем первую пачку результатов (см. SEARCH_PER_PAGE_LIMIT).
         */
        const limitedList = state.listFull.slice(0, SEARCH_PER_PAGE_LIMIT);

        let folders = 0;
        let files = 0;

        // Добавляем кол-во файлов и папок в результаты поиска (нужно для пагинации).
        state.listFull.forEach((item) => {
            if (item.kind === 'file') {
                files++;
            } else {
                folders++;
            }
        });

        const count = {
            folders,
            files,
            all: state.listFull.length,
            loaded: limitedList.length,
        };

        state[ROOT_FOLDER_ID].count = count;
        state[ROOT_FOLDER_ID].hasMoreToLoad = count.loaded < count.all;

        state.offset = limitedList.length;

        limitedList.forEach((item, index) => {
            const normalizedItem = normalize({ ...item }, index);
            state.list[normalizedItem.id] = normalizedItem;
            state[ROOT_FOLDER_ID].childs.push(normalizedItem.id);
        });
    },
    [searchLoadError.type]: (state) => {
        state[ROOT_FOLDER_ID].childs = [];
        state[ROOT_FOLDER_ID].isLoading = false;
        state[ROOT_FOLDER_ID].isLoaded = true;
        state[ROOT_FOLDER_ID].error = true;
    },
    [searchLoadMoreRequest.type]: (state) => {
        state[ROOT_FOLDER_ID].isLoading = true;
    },
    [searchLoadMoreSuccess.type]: (state, action: PayloadAction<number>) => {
        const prevCount = state[ROOT_FOLDER_ID].count.loaded;
        const loaded = prevCount + action.payload;
        state[ROOT_FOLDER_ID].isLoading = false;
        state[ROOT_FOLDER_ID].count.loaded = loaded;
        state[ROOT_FOLDER_ID].hasMoreToLoad = loaded < state[ROOT_FOLDER_ID].count.all;

        const nextList = state.listFull.slice(state.offset, state.offset + SEARCH_PER_PAGE_LIMIT);

        nextList.forEach((item, index) => {
            const normalizedItem = normalize({ ...item }, prevCount + index);
            state.list[normalizedItem.id] = normalizedItem;
            state[ROOT_FOLDER_ID].childs.push(normalizedItem.id);
        });

        const newOffset = state.offset + SEARCH_PER_PAGE_LIMIT;
        state.offset = newOffset < state.listFull.length ? newOffset : state.listFull.length;
    },
    [searchRequestParamsUpdate.type]: (state, action: PayloadAction<SearchRequestParams>) => {
        state.requestParams = {
            ...action.payload,
        };
    },
    [addFilesSuccess.type]: (state, action: PayloadAction<AddFileSuccessAction>) => {
        const { items, storage } = action.payload;

        if (storage !== EStorageType.search) {
            return;
        }

        items.forEach((item) => {
            const normalizedItem = normalize({ ...item });

            if (state.list[normalizedItem.id]) {
                return;
            }

            state[ROOT_FOLDER_ID].childs.push(normalizedItem.id);
            state.list[normalizedItem.id] = normalizedItem;
        });
    },
    [renameItemSuccess.type]: (state, action: PayloadAction<IRenameItemSuccess>) => {
        const { newItem, oldId } = action.payload;
        const file = newItem as SearchItem;

        if (!file || !oldId) {
            return;
        }

        state[ROOT_FOLDER_ID].childs = state[ROOT_FOLDER_ID].childs.filter((child) => child !== oldId);
        delete state.list[oldId];

        state[ROOT_FOLDER_ID].childs.push(file.id);
        state.list[file.id] = file;
    },
    [moveItemsSuccess.type]: (state, action: PayloadAction<MoveItemsSuccessAction>) => {
        const { newItems, oldIds, storage } = action.payload;

        if (storage !== EStorageType.search) {
            return;
        }

        newItems.forEach((newItem, index) => {
            const file = newItem as SearchItem;
            const oldItemId = oldIds[index];
            const oldItem = state.list[oldItemId];

            if (!file?.id || !oldItem || state[file?.id]) {
                return;
            }

            state.list[file.id] = file;
            state[ROOT_FOLDER_ID].childs.push(file.id);

            state[ROOT_FOLDER_ID].childs = state[ROOT_FOLDER_ID].childs.filter((child) => child !== oldItemId);
            delete state.list[oldItemId];
        });
    },
    [removeFileSuccess.type]: (state, action: PayloadAction<RemoveFileSuccessAction>) => {
        const { ids } = action.payload;

        state[ROOT_FOLDER_ID].childs = state[ROOT_FOLDER_ID].childs.filter((child) => !ids.includes(child));

        ids.forEach((id) => {
            delete state.list[id];
        });
    },
    [publishWeblink.type]: (state, action: PayloadAction<UpdateWeblinkAction>) => {
        const { weblink, id } = action.payload;

        if (state.list[id]) {
            state.list[id].weblink = weblink;
        }
    },
    [unPublishWeblink.type]: (state, action: PayloadAction<UnpublishWeblinkAction>) => {
        const { ids } = action.payload;

        ids.forEach((id) => {
            const item = state.list[id];

            if (!item) {
                return;
            }

            item.weblink = undefined;
            item.weblinkAccessRights = AccessRights.r;
            item.weblinkDomestic = false;
            item.weblinkExpires = 0;

            if (item.isFolder) {
                item.weblinkAutoDelete = false;
            }
        });
    },
    [toggleWeblinkAccessRights.type]: (state, action: PayloadAction<ToggleWeblinkAccessRights>): void => {
        const { id, type } = action.payload;
        if (state.list[id]) {
            state.list[id].weblinkAccessRights = type;
        }
    },
    [toggleWeblinkDownloadable.type]: (state, action: PayloadAction<ToggleWeblinkDownloadable>): void => {
        const { id, downloadable } = action.payload;

        if (state.list[id]) {
            state.list[id].weblinkDownloadable = downloadable;
        }
    },
    [updateWeblinkCountDownloads.type]: (state, action: PayloadAction<UpdateWeblinkCountDownloads>): void => {
        const { id, count_downloads } = action.payload;
        const item = state.list[id];
        if (item) {
            item.count_downloads_left = count_downloads;
            item.count_downloads_total = count_downloads;
        }
    },
    [resetWeblinkCountDownloads.type]: (state, action: PayloadAction<ResetWeblinkCountDownloads>): void => {
        const { id } = action.payload;

        if (state.list[id]) {
            state.list[id].count_downloads_total = undefined;
            state.list[id].count_downloads_left = undefined;
        }
    },
    [toggleWeblinkDomestic.type]: (state, action: PayloadAction<ToggleWeblinkDomestic>): void => {
        const { id, domestic } = action.payload;

        if (state.list[id]) {
            state.list[id].weblinkDomestic = domestic;
        }
    },
    [weblinkSetDomainAccess.type]: (state, action: PayloadAction<IWeblinkSetDomainAccess>): void => {
        const { domain, itemId } = action.payload;

        if (state.list[itemId]) {
            state.list[itemId].weblinkDomainAccess = domain;
        }
    },
    [updateWeblinkExpires.type]: (state, action: PayloadAction<UpdateWeblinkExpires>): void => {
        const { id, expires } = action.payload;

        if (state.list[id]) {
            state.list[id].weblinkExpires = expires;
        }
    },
    [updateWeblinkAutoDelete.type]: (state, action: PayloadAction<UpdateWeblinkAutoDelete>): void => {
        const { id, autoDelete } = action.payload;
        const item = state.list[id];

        if (item && item.isFolder) {
            item.weblinkAutoDelete = autoDelete;
        }
    },
    [unshareFolderSuccess.type]: (state, action: PayloadAction<IUnshareFolderSuccess>) => {
        const { id } = action.payload;
        const item = state.list[id];

        if (!item || !isFolder(item)) {
            return;
        }

        item.kind = 'folder';
    },
    [shareFolderSuccess.type]: (state, action: PayloadAction<IShareFolderSuccess>) => {
        const { id } = action.payload;
        const item = state.list[id];

        if (!item || !isFolder(item)) {
            return;
        }

        item.kind = 'shared';
    },
    [addToFavoritesSuccess.type]: (state, action: PayloadAction<IAddToFavoritesSuccess>): void => {
        const { ids } = action.payload;

        ids.forEach((id) => {
            const item = state.list[id];

            if (!item) {
                return;
            }

            item.isInFavorites = true;
        });
    },
    [removeFromFavoritesSuccess.type]: (state, action: PayloadAction<IRemoveFromFavoritesSuccess>): void => {
        const { ids } = action.payload;

        ids.forEach((id) => {
            const item = state.list[id];

            if (!item) {
                return;
            }

            item.isInFavorites = false;
        });
    },
    [searchLoadHistorySuccess.type]: (state, action: PayloadAction<SearchLoadSuccessParams>): void => {
        state.searchHistory = action.payload.history;
        state.searchHistoryRequestParams = action.payload.searchHistoryParams;
    },
    [searchLoadHistoryError.type]: (state): void => {
        state.searchHistory = [];
    },
    [searchHistoryRemoveItem.type]: (state, action: PayloadAction<string>): void => {
        state.searchHistory = state.searchHistory.filter((item) => item.text !== action.payload);
    },
    [searchHistoryClear.type]: (state): void => {
        state.searchHistory = [];
    },
    [searchHistoryHide.type]: (state): void => {
        state.searchHistory = [];
        state.searchHistoryRequestParams = {
            xPageId: 'initial_empty_value',
            xReqId: 'initial_empty_value',
        };
    },
    [searchParamsUpdatePath.type]: (state, action: PayloadAction<{ id: string }>): void => {
        const { id } = action.payload;
        state.requestParams.path = id;
    },
    [searchFilterExactFolder.type]: (state, action: PayloadAction<{ id: string }>): void => {
        const { id } = action.payload;
        state.listFull = state.listFull.filter((item) => filterFilesInExactFolder(item, id));
    },
    [searchSetEmptyFilters.type]: (state, action: PayloadAction<boolean>): void => {
        state.emptyFilters = action.payload;
    },
    [searchContentIndexed.type]: (state, action: PayloadAction<boolean>): void => {
        state.contentIndexed = action.payload;
        state.webSearchContentEnabled = isWebSearchContentEnabled && action.payload;
        state.touchSearchContentEnabled = isTouchSearchContentEnabled && action.payload;
    },
});
