import classNames from 'clsx';
import React, { type ReactElement } from 'react';

import styles from './User.css';
import { useAccessRights } from './User.hooks';
import type { IProps } from './User.types';

export const User = React.memo(function User(props: IProps): ReactElement | null {
    const { user, isOwner = false, item, isMounted, currentUserEmail, onClose, publicId, isPhone, isTabs } = props;

    const userBlock = useAccessRights({ user, item, isMounted, onClose, isPhone, isOwner, publicId });

    if (!user || (isMounted && user.email !== currentUserEmail)) {
        return null;
    }

    return (
        <div
            className={classNames(styles.root, {
                [styles[`root_status_${user.status}`]]: Boolean(user.status),
                [styles.root_phone]: isPhone,
                [styles.root_withDropdown]: !isOwner,
                [styles.root_tab]: isTabs,
            })}
            data-qa-email={user.email}
            data-qa-status={user.status}
        >
            <div style={{ backgroundImage: `url(${user.avatarUrl})` }} className={styles.avatar} />
            <div className={styles.userContainer}>
                <div className={styles.info}>
                    {user.name && <div className={styles.name}>{user.name}</div>}
                    <div className={styles.email}>{user.email}</div>
                </div>
                {userBlock}
            </div>
        </div>
    );
});
