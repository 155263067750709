import { Icon20QuestionOutline } from '@vkontakte/icons';
import { Button } from '@vkontakte/vkui';
import React, { type ReactElement, memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { platform } from 'reactApp/appHelpers/configHelpers';
import type { IProps } from 'reactApp/components/HelpButton/HelpButton.types';
import { getDocumentsState } from 'reactApp/modules/allDocuments/allDocuments.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

import styles from './HelpButton.css';

type MappingType = Partial<Record<EStorageType, string>> & { default: string };

const feedBackText = 'Обратная связь';
const feedbackUrl = 'https://help.mail.ru/cloud_web/docs/feedback';

const text: MappingType = {
    [EStorageType.incomingPublic]: feedBackText,
    [EStorageType.alldocuments]: feedBackText,
    default: 'Помощь',
};

const url: MappingType = {
    [EStorageType.incomingPublic]: feedbackUrl,
    [EStorageType.alldocuments]: feedbackUrl,
    default: 'https://help.mail.ru/cloud_web',
};

export const HelpButton = memo(({ storage }: IProps): ReactElement => {
    /** Данные для радара в разделе документы */
    const { isLoaded, type, count } = useSelector(getDocumentsState);

    const dwhData = useMemo(
        () =>
            isLoaded
                ? {
                      platform,
                      cnt_file: count,
                  }
                : null,
        [isLoaded, platform, count]
    );

    const handleClick = useCallback(() => {
        if ((storage === EStorageType.alldocuments || storage === EStorageType.incomingPublic) && dwhData) {
            sendDwh({ eventCategory: ECategoryGa.docs, action: 'click_feedback', dwhData: { ...dwhData, place: type } });
        }
        window.open((storage && url[storage]) || url.default);
    }, [dwhData, storage, type]);

    return (
        <div className={styles.container} data-qa-id={'help-button'}>
            <Button onClick={handleClick} align="left" appearance="neutral" size="m" mode="tertiary" before={<Icon20QuestionOutline />}>
                {(storage && text[storage]) || text.default}
            </Button>
        </div>
    );
});

HelpButton.displayName = 'HelpButton';
