import { Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import React from 'react';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { EMPTY_ITEMS } from 'reactApp/sections/NewPersonalDocuments/NewPersonalDocuments.constants';
import { UploadItem } from 'reactApp/sections/NewPersonalDocuments/UploadItem/UploadItem';
import { Icon20AddOutline } from 'reactApp/ui/touchIcons';

import styles from './Empty.css';

export const Empty = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.root}>
                <div className={styles.content}>
                    <Text className={styles.title}>В вашем облаке нет документов</Text>
                    <Text className={styles.text}>Добавьте их вручную — выберите категорию</Text>
                    <ul className={styles.list}>
                        {EMPTY_ITEMS.map(({ icon, title, type }) => (
                            <UploadItem key={type} className={styles.item} icon={icon} title={title} type={type} />
                        ))}
                        <li
                            className={classNames(styles.item, styles.other)}
                            tabIndex={0}
                            onClick={() => {
                                openPopupHelper({ popupName: popupNames.PERSONAL_DOCUMENT_GROUPS });
                            }}
                        >
                            <Icon20AddOutline height={26} width={26} />
                            Добавить
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
