import { useCallback } from 'react';
import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import type { ISubscription } from 'reactApp/modules/subscriptions/subscriptions.types';
import { sendXrayWithPlatform } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

// https://docs.google.com/spreadsheets/d/1oxESW-0Ne7WtP7gKejV6CJYSv-Q9ssFDC8MY1_chDT0/edit#gid=1290046406

export enum ESubscriptionModalAction {
    open = 'open',
    addSpace = 'add-space',
    openCancelAutopayment = 'cancel-autopay',
    notCancelAutopayment = 'cancel-autopay-save',
    cancelAutopayment = 'cancel-autopay-true',
    turnOnAutopayment = 'turnon-autopay',
    changeCard = 'change-card',
    addAnotherCard = 'change-card-true',
    cancelAnotherCard = 'change-card-false',
    addNewCard = 'add-card-true',
    cancelNewCard = 'add-card-false',
    partnerClick = 'partner-click',
    prolongSubs = 'prolong-sub',
    captchaView = 'cancel-capcha-view',
    captchaSaveSubs = 'cancel-capcha-save',
    captchaCancelSubs = 'cancel-capcha-cancel',
    captchaCancelSubsSuccess = 'cancel-capcha-success',
    captchaCancelSubsFail = 'cancel-capcha-fail',
    cancelAutopaymentRestore = 'cancel-autopay-restore',
    // tempexp_16480-start
    trialCancelSubs = 'cancel-trial-cancel',
    trialCancelSubsSave = 'cancel-trial-save',
    trialCancelSubsCancel = 'cancel-trial-true',
    // tempexp_16480-end
    showCancelAutopayment = 'cancel-autopay-show',
    showTrialSubs = 'cancel-show-trial',
    // tempexp_16481-start
    showCancelAutoupload = 'cancel-autopay-lost-show',
    notCancelAutoupload = 'cancel-autopay-lost-save',
    cancelAutouploadCancel = 'cancel-autopay-lost-true',
    showCancel10Tb = 'cancel-autopay-10tb-show',
    notCancel10Tb = 'cancel-autopay-10tb-save',
    cancel10tbCancel = 'cancel-autopay-10tb-true',
    // tempexp_16481-end
    // tempexp_16598-start
    showNoAds = 'cancel-no-ads-show',
    notCancelNoAds = 'cancel-no-ads-save',
    cancelNoAds = 'cancel-no-ads-true',
    // tempexp_16598-end

    // tempexp_17096-start
    showPauseModal = 'autopay-pause-show',
    cancelPauseSub = 'autopay-pause-true',
    clickPauseSub = 'autopay-pause-save',
    showPauseSuccessModal = 'autopay-paused-show',
    // tempexp_17096-end

    // tempexp_17581-start
    cancelSaleShow = 'cancel-sale-show',
    cancelSaleGet = 'cancel-sale-get',
    cancelSaleReject = 'cancel-sale-reject',
    cancelSaleClose = 'cancel-sale-close',
    // tempexp_17581-end

    // tempexp_17359-start
    familyEmpty = 'cancel-family-empty',
    familyEmptyAdd = 'cancel-family-empty-add',
    familyEmptyCancel = 'cancel-family-empty-cancel',
    family = 'cancel-family',
    familySave = 'cancel-family-save',
    familyCancel = 'cancel-family-cancel',
    // tempexp_17359-end
}

interface ISubscriptionModalAnalytics {
    action: ESubscriptionModalAction;
    product: string;
    tariff: string;
    start_date: number;
    expired_date: number;
    source: 'touch' | 'desktop';
    type: string;
    partner_name?: string;
    platform: string;
}

export type TSendAnalytics = (action: ESubscriptionModalAction) => void;

export const sendSubscriptionModalAnalytics = ({ action, ...rest }: ISubscriptionModalAnalytics): void => {
    sendDwh({
        eventCategory: ECategoryGa.payment,
        action: `my-tariff-${action}`,
        dwhData: rest,
    });
};

export const useSendSubscriptionModalAnalytics = (subscription: ISubscription): TSendAnalytics => {
    return useCallback(
        (action: ESubscriptionModalAction) => {
            const product = subscription.productId || subscription.promoId || 'None';
            sendSubscriptionModalAnalytics({
                action,
                type: subscription.type,
                product,
                tariff: product,
                start_date: subscription?.start || 0,
                expired_date: subscription?.expires || 0,
                partner_name: subscription?.partner || 'None',
                source: IS_PHONE_BROWSER ? 'touch' : 'desktop',
                platform: IS_PHONE_BROWSER ? String(EnvironmentSelectors.getMobileType()).toLowerCase() : 'web',
            });

            sendXrayWithPlatform(['my-tariff', action]);
        },
        [subscription]
    );
};
