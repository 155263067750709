import { IS_ACTION_USER, IS_SOCIAL_USER } from 'reactApp/appHelpers/configHelpers';
import { abReferralProgram } from 'reactApp/appHelpers/featuresHelpers/features/referralProgram';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { store as reduxStore } from 'reactApp/store';
import { referralProgramPromoModalAnalyticsMap } from 'reactApp/ui/ReferralProgramPromoModal/ReferralProgramPromoModal.analytics';
import { renderReferralProgramPromoModal } from 'reactApp/ui/ReferralProgramPromoModal/ReferralProgramPromoModal.helpers';
import { renderThanksModal } from 'reactApp/ui/ThanksModal/ThanksModal.helpers';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { put } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';

const REFERRAL_PROGRAM_PROMO_ID = 'referral-program-promo';

function markPromoShown() {
    reduxStore.dispatch(promoShown(EPromoType.referralProgramPromo));
    storeHelper.markAsShown(REFERRAL_PROGRAM_PROMO_ID);
    reduxStore.dispatch(removePromo(EPromoType.referralProgramPromo));
}

export function* initReferralProgramPromoModal() {
    const storage = yield* select(getCurrentStorage);

    const isCorrectUrl = storage === EStorageType.home;

    if (
        !IS_ACTION_USER ||
        IS_SOCIAL_USER ||
        storeHelper.getValue(REFERRAL_PROGRAM_PROMO_ID) ||
        !isCorrectUrl ||
        !abReferralProgram ||
        abReferralProgram === 'a'
    ) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.referralProgramPromo,
            promoId: REFERRAL_PROGRAM_PROMO_ID,
            onClick() {
                markPromoShown();
                sendPaymentGa({
                    action: 'click',
                    eventCategory: ECategoryGa.modalReferral,
                    offer: referralProgramPromoModalAnalyticsMap[abReferralProgram],
                });

                renderThanksModal({
                    onClick: () => {
                        sendPaymentGa({
                            action: 'click',
                            eventCategory: ECategoryGa.modalReferralThanks,
                        });
                    },
                    onShow: () => {
                        sendPaymentGa({
                            action: 'show',
                            eventCategory: ECategoryGa.modalReferralThanks,
                        });
                    },
                    onClose: () => {
                        sendPaymentGa({
                            action: 'close',
                            eventCategory: ECategoryGa.modalReferralThanks,
                        });
                    },
                });
            },
            onShow: () => {
                markPromoShown();
                sendPaymentGa({
                    action: 'show',
                    eventCategory: ECategoryGa.modalReferral,
                    offer: referralProgramPromoModalAnalyticsMap[abReferralProgram],
                });
            },
            onClose: () => {
                markPromoShown();
                sendPaymentGa({
                    action: 'close',
                    eventCategory: ECategoryGa.modalReferral,
                    offer: referralProgramPromoModalAnalyticsMap[abReferralProgram],
                });
            },
        })
    );
}

export function* showReferralProgramPromoModal() {
    const promo = yield* select(PromoSelectors.getPromo(EPromoType.referralProgramPromo));

    if (promo) {
        const { onShow, onClick, onClose } = promo;
        yield call(renderReferralProgramPromoModal, { onShow, onClick, onClose });
    }
}
