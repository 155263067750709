import { Icon16MoreVertical, Icon20CopyOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { type MouseEvent, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { QA_VALUE } from 'reactApp/components/SharingWindow/Sharing.constants';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import type { PublishItem } from 'reactApp/modules/modifying/modifying.types';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { setPublicPin } from 'reactApp/modules/public/public.actions';
import { getPublicPin } from 'reactApp/modules/public/public.selectors';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import type { RootState } from 'reactApp/store';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { ButtonLink } from 'reactApp/ui/ButtonLink/ButtonLink';
import { renderPublicPasswordDeleteModal } from 'reactApp/ui/Mobile/PublicPasswordDeleteModal/PublicPasswordDeleteModal.helpers';
import { renderPublicPasswordSetModal } from 'reactApp/ui/Mobile/PublicPasswordSetModal/PublicPasswordSetModal.helpers';
import { renderPublicPasswordTariffModal } from 'reactApp/ui/Mobile/PublicPasswordTariffModal/PublicPasswordTariffModal.helpers';

import { Dropdown } from './Dropdown/Dropdown';
import { Password } from './Password/Password';
import { sendPublicPasswordAnalytics } from './PublicPassword.analytics';
import styles from './PublicPassword.css';
import { Tooltip } from './Tooltip/Tooltip';

type PublicPasswordProps = {
    item?: PublishItem;
    forcePromo: boolean;
};

export const PublicPassword = memo<PublicPasswordProps>(({ item, forcePromo = false }) => {
    const dispatch = useDispatch();

    const tooltipRef = useRef<HTMLDivElement | null>(null);
    const menuRef = useRef<HTMLDivElement | null>(null);

    const isPaidUser = useSelector(UserSelectors.isPaidUser);
    const isCorpUser = useSelector(UserSelectors.isCorpUser);

    const [password, setPassword] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [isShowMenu, setShowMenu] = useState(false);

    const publicId = (item && 'weblink' in item && item.weblink) || '';
    const pin = useSelector((state: RootState) => getPublicPin(state, publicId));

    const isPhone = EnvironmentSelectors.isMobile();

    const { isLoading } = useSelector(UserSelectors.getLifeCycleState);

    useEffect(() => {
        if (forcePromo) {
            setShowTooltip(forcePromo);
        }
    }, [forcePromo]);

    useEffect(() => {
        sendPublicPasswordAnalytics({ action: 'show' });
        emitAnalyticEvent(AnalyticEventNames.PUBLIC_PASSWORD_SHOW);

        if (isPhone && forcePromo && !isPaidUser) {
            renderPublicPasswordTariffModal();
        }
    }, []);

    useEffect(() => {
        if (pin) {
            setPassword(pin);
        }
    }, [pin]);

    const handleSetPasswordClick = useCallback(() => {
        if (isPaidUser || isCorpUser) {
            setIsActive(true);
        } else {
            setShowTooltip(true);
        }
        sendPublicPasswordAnalytics({ action: 'click' });
        emitAnalyticEvent(AnalyticEventNames.PUBLIC_PASSWORD_SET);
    }, [setShowTooltip, setIsActive, isPaidUser, isCorpUser]);

    const handleToogleMenu = useCallback(() => {
        if (!isShowMenu) {
            sendPublicPasswordAnalytics({ action: 'click_menu' });
        }
        setShowMenu((prev) => !prev);
    }, [setShowMenu, isShowMenu]);

    const handleCloseTooltip = useCallback(() => {
        setShowTooltip(false);
    }, [setShowTooltip]);

    const handleClosePassword = useCallback(
        (sendRadar = true) => {
            setIsActive(false);
            if (sendRadar) {
                sendPublicPasswordAnalytics({ action: 'close' });
            }
        },
        [setIsActive]
    );

    const handleSavePassword = useCallback(
        (newPassword: string) => {
            setPassword(newPassword);
        },
        [setPassword]
    );

    const handleDeletePassword = useCallback(() => {
        setPassword('');
        sendPublicPasswordAnalytics({ action: 'delete' });
    }, [setPassword]);

    const handleChangePassword = useCallback(() => {
        setIsActive(true);
        sendPublicPasswordAnalytics({ action: 'change' });
    }, [setIsActive]);

    const handleRestorePassword = useCallback(
        (oldPassword: string) => {
            dispatch(
                setPublicPin({
                    pin: oldPassword,
                    public_id: publicId,
                    onSuccess: () => {
                        setPassword(oldPassword);
                    },
                })
            );
        },
        [setPassword, dispatch, publicId]
    );

    const handleCopyPassword = useCallback(
        (e?: MouseEvent) => {
            navigator.clipboard.writeText(password);
            dispatch(
                showSnackbarAction({
                    id: 'public-password-copy-success',
                    text: 'Пароль скопирован',
                    type: SnackbarTypes.success,
                    closable: true,
                })
            );
            sendPublicPasswordAnalytics({ action: 'copy' });
            if (e) {
                e.stopPropagation();
            }
        },
        [dispatch, password]
    );

    const handleSetPasswordClickMobile = useCallback(() => {
        if (pin) {
            sendPublicPasswordAnalytics({ action: 'touch_change' });
        } else {
            sendPublicPasswordAnalytics({ action: 'click' });
            emitAnalyticEvent(AnalyticEventNames.PUBLIC_PASSWORD_SET);
        }

        // Дать возможность юзер ставшему бесплатником удалить пароль
        if (!isPaidUser && pin) {
            renderPublicPasswordDeleteModal({
                itemId: item?.id || '',
                publicId,
                password: pin,
            });

            return;
        }

        if (isPaidUser || isCorpUser) {
            closePopupHelper(popupNames.SHARING_DIALOG);
            renderPublicPasswordSetModal({ pin, publicId, itemId: item?.id || '' });
        } else {
            renderPublicPasswordTariffModal();
        }
    }, [isPaidUser, isCorpUser, pin, publicId, item?.id]);

    const ButtonsDesktop = useMemo(() => {
        if (isLoading && !pin) {
            return <div className={styles.skeleton} />;
        }

        return (
            <>
                {!pin ? (
                    <ButtonLink hoverUnderline={false} className={styles.buttonLink} onClick={handleSetPasswordClick}>
                        Установить
                    </ButtonLink>
                ) : (
                    <div className={classNames(styles.menuIcon, { [styles.opened]: isShowMenu })} ref={menuRef} onClick={handleToogleMenu}>
                        <Icon16MoreVertical />
                    </div>
                )}
            </>
        );
    }, [pin, handleSetPasswordClick, isShowMenu, menuRef, handleToogleMenu, isLoading]);

    const ButtonsMobile = useMemo(() => {
        if (isLoading && !pin) {
            return <div className={styles.skeleton} />;
        }

        return (
            <>
                {!pin ? (
                    <Button sizeMode={ButtonSizeMode.small} className={styles.buttonMobile} onClick={handleSetPasswordClickMobile}>
                        Установить
                    </Button>
                ) : (
                    <div className={styles.buttonCopyMobile} onClick={handleCopyPassword}>
                        <Icon20CopyOutline />
                    </div>
                )}
            </>
        );
    }, [pin, handleCopyPassword, handleSetPasswordClickMobile, isLoading]);

    if (!publicId) {
        return null;
    }

    return (
        <div
            className={classNames(styles.root, {
                [styles.rootMobile]: isPhone,
            })}
            data-qa-item={QA_VALUE.publicPassword}
            onClick={isPhone && (isPaidUser || pin) ? handleSetPasswordClickMobile : undefined}
        >
            {isActive ? (
                <Password onClose={handleClosePassword} onSave={handleSavePassword} defaultPassword={password} publicId={publicId} />
            ) : (
                <>
                    <span>
                        Пароль для папки:{' '}
                        {pin ? (
                            <span className={styles.passwordSet}>установлен</span>
                        ) : (
                            <span className={styles.passwordNotSet}>не установлен</span>
                        )}
                    </span>
                    <div className={styles.dropdown} ref={tooltipRef}>
                        <div className={styles.actions}>{isPhone ? ButtonsMobile : ButtonsDesktop}</div>
                    </div>
                    {showTooltip && <Tooltip ref={tooltipRef} onClose={handleCloseTooltip} />}
                    {isShowMenu && (
                        <Dropdown
                            ref={menuRef}
                            onClose={handleToogleMenu}
                            password={password}
                            onDeletePassword={handleDeletePassword}
                            onRestorePassword={handleRestorePassword}
                            onChangePassword={handleChangePassword}
                            onCopy={handleCopyPassword}
                            publicId={publicId}
                        />
                    )}
                </>
            )}
        </div>
    );
});

PublicPassword.displayName = 'PublicPassword';
