import { TooltipModel, TooltipModelProps } from '@mail/3promo/models/tooltip';
import { ReactRenderer } from '@mail/3promo/render/react';
import { LocalStorageStore } from '@mail/3promo/stores/localStorage';
import type { ModelCreator } from '@mail/3promo/types';
import { xray } from 'lib/xray';
import { newPromoFeature } from 'reactApp/appHelpers/featuresHelpers/features/newPromo';
import { PdfAutosavePromo } from 'reactApp/modules/promoNew/components/PdfAutosave/PdfAutosavePromo';
import { NEW_PROMO_ID, PromoLocalStoreDeps } from 'reactApp/modules/promoNew/defaultValues';

export const pdfAutosaveCreator: ModelCreator = ({ models, render }) =>
    new TooltipModel(
        NEW_PROMO_ID.PDF_AUTOSAVE_PROMO,
        models.base,
        new TooltipModelProps(NEW_PROMO_ID.PDF_AUTOSAVE_PROMO, {
            store: new LocalStorageStore(NEW_PROMO_ID.PDF_AUTOSAVE_PROMO, PromoLocalStoreDeps),
            renderer: new ReactRenderer(NEW_PROMO_ID.PDF_AUTOSAVE_PROMO, PdfAutosavePromo, render.react),
            closable: true,
            closeLimit: newPromoFeature.PROMO?.[NEW_PROMO_ID.PDF_AUTOSAVE_PROMO]?.closeLimit,
            showLimit: newPromoFeature.PROMO?.[NEW_PROMO_ID.PDF_AUTOSAVE_PROMO]?.showLimit,
            showInterval: newPromoFeature.PROMO?.[NEW_PROMO_ID.PDF_AUTOSAVE_PROMO]?.showInterval,
            hideOnOutsideClick: true,
            templateData: {
                title: 'Автосохранение файла',
                text: 'Вы можете включать или выключить автосохранение изменений в PDF',
            },
            getTarget: () => {
                return document.querySelector("[data-qa-id='more-button-pdf']");
            },
            onShow: () => {
                xray.send(`pdf-autosave-promo-show`);
            },
            onClose: () => {
                xray.send(`pdf-autosave-promo-close`);
            },
        })
    );
