import { Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { type FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getDocumentItemById } from 'reactApp/modules/personalDocuments/personalDocuments.selectors';
import type { IDocumentSection } from 'reactApp/modules/personalDocuments/personalDocuments.types';
import { ITEMS } from 'reactApp/sections/NewPersonalDocuments/NewPersonalDocuments.constants';
import type { RootState } from 'reactApp/store';

import styles from './Item.css';

interface Props extends IDocumentSection {
    className?: string;
}

export const Item: FC<Props> = ({ className, id, avatar }) => {
    const item = useMemo(() => ITEMS[id], [id]);
    const file = useSelector((state: RootState) => getDocumentItemById(state, avatar));

    return avatar ? (
        <Link className={classNames(styles.wrapper, className)} to={`/documents/${item.type}`}>
            {file ? <img className={styles.image} src={file.thumbnails?.['1000x1000']} /> : null}
            <Text className={styles.label}>{item.title}</Text>
        </Link>
    ) : null;
};
