import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { allDocumentsAdditionalLoadRequest } from 'reactApp/modules/allDocuments/allDocuments.module';
import { EAdditionalSections } from 'reactApp/modules/allDocuments/allDocuments.types';
import { isAllDocumentsHideSpace } from 'reactApp/modules/features/features.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { MainPage } from 'reactApp/sections/MainPage/MainPage';
import { TrashbinPage } from 'reactApp/sections/TrashbinPage/TrashbinPage';

export const AllDocumentsTrashbinPage: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(allDocumentsAdditionalLoadRequest(EAdditionalSections.trashbin));
    }, [dispatch]);

    return (
        <MainPage storage={EStorageType.alldocuments} renderWorm showUploader={false} showSpace={!isAllDocumentsHideSpace}>
            <TrashbinPage />
        </MainPage>
    );
};
