import { BillingDataPartner } from 'reactApp/api/billing/data/BillingDataAPICall.types';

export const emptyBillingData = {
    data: {
        android: [],
        ios: [],
        web: [],
        bonus: [],
        rustore: [],
        gift_receiver: [],
        gift_sender: [],
        huawei: [],
        promo: [],
        reward: [],
        purchase: {},
    },
    services: {},
    paid_info: {},
};

export type Partner =
    | BillingDataPartner.rostelecom
    | BillingDataPartner.mail
    | BillingDataPartner.tele2
    | BillingDataPartner.megafon
    | BillingDataPartner.sferum;

export const PARTNER_TITLE: Record<Partner, string> = {
    [BillingDataPartner.mail]: 'VK\u00A0Combo',
    [BillingDataPartner.rostelecom]: 'Ростелеком',
    [BillingDataPartner.tele2]: 'Tele2',
    [BillingDataPartner.megafon]: 'Мегафон',
    [BillingDataPartner.sferum]: 'Сферум',
};

export const PARTNER_LIST = Object.keys(PARTNER_TITLE);
