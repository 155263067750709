import { Spacing, Text } from '@vkontakte/vkui';
import image30 from 'img/restoreTrashbinFiles/restore-files-30.png';
import image30Dark from 'img/restoreTrashbinFiles/restore-files-30-dark.png';
import image60 from 'img/restoreTrashbinFiles/restore-files-60.png';
import image60Dark from 'img/restoreTrashbinFiles/restore-files-60-dark.png';
import React, { memo, useEffect } from 'react';
import { restoreTrashbinFiles } from 'reactApp/appHelpers/featuresHelpers/features/restoreTrashbinFiles';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';

import styles from './RestoreTrashbinFilesModal.css';
import type { Props } from './RestoreTrashbinFilesModal.types';

export const RestoreTrashbinFilesModal = memo<Props>(({ onShow, onClick, onClose, onSecondary }) => {
    useEffect(onShow, []);

    const content = (
        <>
            <Spacing size={12} />
            <Text className={styles.text} weight="3">
                Сейчас файлы хранятся в корзине 14 дней. С подпиской у&nbsp;вас будет больше — точно успеете вернуть важное
            </Text>
            <Spacing size={24} />
        </>
    );

    const containsDarkTheme = isDarkThemeModeEnabled();

    let imageUrl = image60;
    if (restoreTrashbinFiles === 'c') {
        if (containsDarkTheme) {
            imageUrl = image30Dark;
        } else {
            imageUrl = image30;
        }
    } else if (containsDarkTheme) {
        imageUrl = image60Dark;
    }

    return (
        <WhatsNewDialog
            primaryButtonText="Попробовать за 1 ₽"
            secondaryButtonText="Неинтересно"
            title={`${restoreTrashbinFiles === 'b' ? 60 : 30} дней на восстановление файлов
                    из\u00A0корзины с подпиской Mail Space`}
            imageUrl={imageUrl}
            content={content}
            dialogSize="average"
            buttonTheme="vk"
            onClick={onClick}
            onClose={onClose}
            closeAfterClick
            onSecondary={onSecondary}
        />
    );
});

RestoreTrashbinFilesModal.displayName = 'RestoreTrashbinFiles';
