import { Spacing, Text } from '@vkontakte/vkui';
import image from 'img/usedSpacePostpaid/processing.png';
import imageDark from 'img/usedSpacePostpaid/processing-dark.png';
import React, { memo, useCallback, useEffect } from 'react';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';

import styles from './UsedSpacePostpaidModalThanks.css';
import type { Props } from './UsedSpacePostpaidModalThanks.types';

export const UsedSpacePostpaidModalThanks = memo<Props>(({ onShow, onClick, onClose, onCloseClick }) => {
    useEffect(() => {
        onShow();
    }, []);

    const handlePrimaryClick = useCallback(() => {
        onClick();
        onClose();
    }, [onClick, onClose]);
    const handleCloseClick = useCallback(() => {
        onCloseClick?.();
        onClose();
    }, [onClose, onCloseClick]);

    const content = (
        <>
            <Spacing size={12} />
            <Text className={styles.text} weight="3">
                Мы пока работаем над этой функцией. Сообщим сразу, как&nbsp;появится
            </Text>
            <Spacing size={24} />
        </>
    );

    const containsDarkTheme = isDarkThemeModeEnabled();

    return (
        <WhatsNewDialog
            primaryButtonText="Хорошо"
            title="Спасибо за интерес"
            imageUrl={containsDarkTheme ? imageDark : image}
            content={content}
            dialogSize="average"
            buttonTheme="vk"
            onClick={handlePrimaryClick}
            onClose={handleCloseClick}
        />
    );
});

UsedSpacePostpaidModalThanks.displayName = 'UsedSpacePostpaidModalThanks';
