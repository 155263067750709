import { Caption, Spacing, Text, Title } from '@vkontakte/vkui';
import img from 'img/tooltip/public-password.png';
import React, { type RefObject, forwardRef, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import {
    PublicPasswordEventCategory,
    sendPublicPasswordAnalytics,
} from 'reactApp/components/SharingWindow/SharingNew/Weblink/PublicPassword/PublicPassword.analytics';
import { PaidInfoSelectors } from 'reactApp/modules/paidInfo/paidInfo.selectors';
import { publicPinBuySubscription } from 'reactApp/modules/public/public.actions';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';

import styles from './Tooltip.css';

type TooltipProps = {
    onClose: () => void;
};

export const PUBLIC_PASSWORD_TOOLTIP_ID = 'publicPasswordTooltipId';

export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(({ onClose }, ref) => {
    const dispatch = useDispatch();

    const isUserWithoutPayment = useSelector(PaidInfoSelectors.isUserWithoutPayment);

    useEffect(() => {
        sendPublicPasswordAnalytics({ eventCategory: PublicPasswordEventCategory.tooltipPassword, action: 'show' });
        emitAnalyticEvent(AnalyticEventNames.PUBLIC_PASSWORD_PROMO_SHOW);
        dispatch(UserStorageActions.set(PUBLIC_PASSWORD_TOOLTIP_ID, true));
    }, []);

    const handleBuyClick = useCallback(() => {
        dispatch(publicPinBuySubscription());
        onClose();
        sendPublicPasswordAnalytics({ eventCategory: PublicPasswordEventCategory.tooltipPassword, action: 'click' });
        emitAnalyticEvent(AnalyticEventNames.PUBLIC_PASSWORD_PROMO_CLICK);
    }, [dispatch, onClose]);

    const handleClose = useCallback(() => {
        sendPublicPasswordAnalytics({ eventCategory: PublicPasswordEventCategory.tooltipPassword, action: 'close' });
        onClose();
    }, [onClose]);

    return (
        <FloatingTooltip
            onClose={handleClose}
            target={ref as RefObject<HTMLDivElement>}
            placement={ETooltipPlacement.right}
            qaId="public-password"
            withinModal
            closable
            closeOnOutsideClick
            usePortal
        >
            <div className={styles.tooltip}>
                <Title className={styles.tooltipTitle}>Пароль — с подпиской Mail Space</Title>
                <Spacing size={4} />
                <Text className={styles.tooltipText}>
                    Скройте от случайных глаз файлы, <br /> которыми делитесь по ссылке
                </Text>
                <Spacing size={8} />
                <Caption level="2" className={styles.caption}>
                    Функция доступна только на компьютере
                </Caption>
                <Spacing size={8} />
                <Button theme="vk" sizeMode={ButtonSizeMode.small} className={styles.tooltipButton} onClick={handleBuyClick}>
                    {isUserWithoutPayment ? 'Попробовать за 1 ₽' : 'Попробовать'}
                </Button>
                <img src={img} alt="Пароль — с подпиской Mail Space" className={styles.image} />
            </div>
        </FloatingTooltip>
    );
});

Tooltip.displayName = 'ChangePublicPasswordTooltip';
