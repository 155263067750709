import { Icon20InfoCircleOutline } from '@vkontakte/icons';
import { Button as VkuiButton } from '@vkontakte/vkui';
import classNames from 'clsx';
import overquotaTooltipImage from 'img/tooltip/tooltip-overquota-upload.png';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { IS_PUBLIC_OF_OVERQUOTA, NEW_PORTAL_HEADER } from 'reactApp/appHelpers/configHelpers';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getPublicOwner } from 'reactApp/modules/public/public.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { getQuotaPromoUrl } from 'reactApp/sections/QuotaLanding/QuotaLanding.data';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement, ETooltipSize } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';
import { emitUploadStart, UploadInput } from 'reactApp/ui/UploadInput/UploadInput';
import { loginShow } from 'reactApp/utils/auth';
import { createAvatarUrl } from 'reactApp/utils/avatar';
import opener from 'reactApp/utils/opener';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './PublicUploadHeader.css';

export const PublicUploadHeader: React.FC = () => {
    const publicUploadPromo = useSelector(PromoSelectors.getPromo(EPromoType.publicUpload));
    const publicOwner = useSelector(getPublicOwner);
    const isAnonymous = useSelector(UserSelectors.isAnonymous);
    const isOverQuotaUser = useSelector(UserSelectors.isOverQuotaUser);
    const { email = '', firstName, lastName, nick } = publicOwner || {};
    const ref = useRef<HTMLDivElement>(null);
    const avatar = createAvatarUrl(email, email, 40);
    const [overqoutaToolipShow, setOverqoutaToolipShow] = useState(false);

    const name = `${firstName ? firstName : ''} ${lastName ? lastName : ''}`.trim() || nick || email;

    useEffect(() => {
        if (isOverQuotaUser) {
            sendPaymentGa({
                action: 'view',
                eventCategory: ECategoryGa.banner,
                name_banner: 'block_public_upload',
            });
        }
    }, [isOverQuotaUser]);

    useEffect(() => {
        if (publicUploadPromo) {
            publicUploadPromo.onShow();
        }
    }, [publicUploadPromo]);

    const onUploadClick = useCallback(() => {
        if (isAnonymous) {
            loginShow({
                closable: true,
            });
        } else {
            emitUploadStart({ type: 'file' });
        }
    }, [isAnonymous]);

    const onAddSpaceClick = useCallback(() => {
        sendPaymentGa({
            action: 'click',
            eventCategory: ECategoryGa.banner,
            name_banner: 'block_public_upload',
        });
        opener(
            getQuotaPromoUrl({
                query: 'utm_source=cloud&utm_medium=web_union_quota_landing&utm_campaign=block_public_banner&utm_term=17978',
            }),
            true
        );
    }, []);

    const onCleanSpaceClick = useCallback(() => {
        sendPaymentGa({
            action: 'click_cleaner',
            eventCategory: ECategoryGa.banner,
            name_banner: 'block_public_upload',
        });
        opener(
            getQuotaPromoUrl({
                query: 'utm_source=cloud&utm_medium=web_union_quota_landing&utm_campaign=block_public_banner_cleaner&utm_term=17978',
                hash: 'cleaner',
            }),
            true
        );
    }, []);

    const onOverquotaTooltipClick = useCallback(() => {
        sendPaymentGa({
            action: 'click',
            eventCategory: ECategoryGa.tooltip,
            name: 'block_public_upload',
        });
        opener(
            getQuotaPromoUrl({
                query: 'utm_source=cloud&utm_medium=web_union_quota_landing&utm_campaign=block_public_tooltip&utm_term=17978',
            }),
            true
        );
    }, []);

    const onOverquotaTooltipClose = useCallback(() => {
        sendPaymentGa({
            action: 'close',
            eventCategory: ECategoryGa.tooltip,
            name: 'block_public_upload',
        });
        setOverqoutaToolipShow(false);
    }, []);

    const onButtonWrapperClick = useCallback(() => {
        if (IS_PUBLIC_OF_OVERQUOTA) {
            setOverqoutaToolipShow(true);
            sendPaymentGa({
                action: 'show',
                eventCategory: ECategoryGa.tooltip,
                name: 'block_public_upload',
            });
        }
    }, []);

    if (isOverQuotaUser) {
        return (
            <div className={styles.root_withOverQuota}>
                <div className={styles.overQuotaContent}>
                    <Icon20InfoCircleOutline className={styles.overQoutaIcon} />
                    <div>
                        <div className={styles.overQuotaTitle}>Пользователи не могут загружать файлы в эту папку</div>
                        <div className={styles.overQuotaText}>Увеличьте место с подпиской Mail Space или очистите от лишнего</div>
                    </div>
                </div>
                <div className={styles.overQuotaButtons}>
                    <VkuiButton mode="primary" appearance="negative" size="m" onClick={onAddSpaceClick}>
                        Увеличить место
                    </VkuiButton>
                    <VkuiButton mode="secondary" appearance="neutral" size="m" onClick={onCleanSpaceClick}>
                        Очистить
                    </VkuiButton>
                </div>
            </div>
        );
    }

    return (
        <div className={classNames(styles.root, { [styles.root_newHeader]: NEW_PORTAL_HEADER })}>
            <div className={styles.content}>
                <UploadInput />
                <div className={styles.avatar} style={{ backgroundImage: `url(${avatar})` }} />
                {!!name && (
                    <div>
                        <div className={styles.name}>{name}</div>
                        <div className={styles.text}>папка доступна для загрузки ваших файлов</div>
                    </div>
                )}
                <div className={styles.button}>
                    <div ref={ref} onClick={onButtonWrapperClick}>
                        <Button
                            primary
                            theme="octavius"
                            sizeMode={ButtonSizeMode.small}
                            onClick={onUploadClick}
                            disabled={IS_PUBLIC_OF_OVERQUOTA}
                        >
                            <span className={styles.buttonText}>Загрузить файлы</span>
                        </Button>
                    </div>
                    {overqoutaToolipShow && (
                        <FloatingTooltip
                            target={ref}
                            placement={ETooltipPlacement.bottom}
                            size={ETooltipSize.large}
                            title="Загружать пока нельзя"
                            text="У владельца закончилось место. Когда он добавит место с подпиской, всё получится"
                            onClose={onOverquotaTooltipClose}
                            qaId="tooltip-overquota-upload"
                            buttonText="О подписке Mail Space"
                            img={overquotaTooltipImage}
                            isAlternativeButton
                            buttonFitContent
                            imageWidth={140}
                            paddingRight={20}
                            onClick={onOverquotaTooltipClick}
                        />
                    )}
                    {!!publicUploadPromo && (
                        <FloatingTooltip
                            target={ref}
                            placement={ETooltipPlacement.bottomStart}
                            size={ETooltipSize.medium}
                            title="Загружайте файлы вместе"
                            text="В папку может грузить файлы любой, у кого есть ссылка"
                            onClose={publicUploadPromo?.onClose}
                            qaId="tooltip-public-upload"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
