import { Icon20Dropdown } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { type MouseEvent, type ReactElement, forwardRef, useRef } from 'react';
import { AnimatedScale } from 'reactApp/ui/AnimatedScale/AnimatedScale';
import { Hint, HintPosition } from 'reactApp/ui/Hint/Hint';

import styles from './MenuButton.css';

interface Props {
    className?: string;
    icon: ReactElement;
    text?: string;
    textOnIcon?: string;
    hint?: string;
    active?: boolean;
    disabled?: boolean;
    animated?: boolean;
    hasDropdown?: boolean;
    isDropdownOpen?: boolean;
    onClick?: (evt: MouseEvent) => void;
    onDropdownClick?: (evt?: MouseEvent) => void;
    onMouseEnter?: (evt?: MouseEvent) => void;
}

export const MenuButton = forwardRef<HTMLDivElement, Props>(
    (
        {
            className,
            icon,
            text,
            textOnIcon,
            hint = '',
            active,
            disabled,
            animated = true,
            onClick,
            onDropdownClick,
            onMouseEnter,
            hasDropdown,
            isDropdownOpen,
        },
        ref
    ) => {
        const contentRef = useRef<HTMLDivElement>(null);
        const dropdownButtonRef = useRef<HTMLDivElement>(null);

        return (
            <Hint text={hint} theme="dark" delay={500} showDelay positionStrategy={HintPosition.relativeToCursor}>
                <div
                    className={classNames(styles.button, className, {
                        [styles.active]: active,
                        [styles.dropdownActive]: isDropdownOpen,
                        [styles.disabled]: disabled,
                    })}
                    ref={ref}
                >
                    <div
                        className={classNames(styles.content, {
                            [styles.contentWithText]: !!text,
                        })}
                        onClick={onClick}
                        onMouseEnter={onMouseEnter}
                        ref={contentRef}
                    >
                        {animated ? <AnimatedScale target={contentRef}>{icon}</AnimatedScale> : icon}
                        {textOnIcon && <div className={styles.textOnIcon}>{textOnIcon}</div>}
                        {text && <p className={styles.text}>{text}</p>}
                    </div>
                    {hasDropdown && (
                        <div
                            className={classNames(styles.dropdownButton, {
                                [styles.active]: isDropdownOpen,
                            })}
                            onClick={onDropdownClick}
                            ref={dropdownButtonRef}
                        >
                            <AnimatedScale target={dropdownButtonRef}>
                                <Icon20Dropdown className={classNames(styles.dropdownIcon, { [styles.dropdownIconUp]: isDropdownOpen })} />
                            </AnimatedScale>
                        </div>
                    )}
                </div>
            </Hint>
        );
    }
);

MenuButton.displayName = 'MenuButton';
