import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { EStorageType } from 'reactApp/modules/storage/storage.types';

/** CLOUDWEB-18070 Отображать ли новый хедер вьювера */
export const hasViewerMenu = (storage?: EStorageType) => {
    if (!storage || storage === EStorageType.embedded) {
        return false;
    }

    const storages: EStorageType[] | 'all' = getFeature('viewer-menu-storages') || [];

    if (storages === 'all') {
        return true;
    }

    return Array.isArray(storages) && storages.includes(storage);
};
