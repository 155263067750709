import { useSelector } from 'react-redux';
import { ENABLE_FULL_RESPONSIVE, IS_BIZ_USER, IS_CHOSEN_PUBLIC_FILE } from 'reactApp/appHelpers/configHelpers';
import { isWebSearchContentEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { CUSTOM_ALBUMS_ID } from 'reactApp/modules/albums/albums.constants';
import { isAlbum } from 'reactApp/modules/albums/albums.types';
import { getFilesWithSelectedFace } from 'reactApp/modules/faces/faces.selectors';
import { isMediaFolderAndGalleryEnabled } from 'reactApp/modules/file/utils';
import { getCurrentFolderHome, getDomainFoldersFilterActive } from 'reactApp/modules/home/home.selectors';
import { isPublicUploadEnabled } from 'reactApp/modules/public/public.selectors';
import { getSearchContentIndexed } from 'reactApp/modules/search/search.selectors';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { getSort } from 'reactApp/modules/sort/sort.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { getStorageCurrentFolder, isFolderWithAuthor } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { getGroupViewModes } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.selectors';
import type { RootState } from 'reactApp/store';
import { getResetWebSearchFiltersItem } from 'reactApp/ui/Toolbar/customActions/getResetWebSearchFiltersItem';
import { TOOLBAR_CONFIG } from 'reactApp/ui/Toolbar/Toolbar.config';
import { getSortItem, getViewItem, getViewItemByModes } from 'reactApp/ui/Toolbar/Toolbar.data';
import { type TToolbarItem, ToolbarContextEnum } from 'reactApp/ui/Toolbar/Toolbar.types';
import { FilterAuthorsDropdown } from 'reactApp/ui/Toolbar/ToolbarItem/FilterAuthorsDropdown/FilterAuthorsDropdown';

export const useFilterActions = ({ storage, toolbarType }: { storage: EStorageType; toolbarType?: ToolbarContextEnum }): TToolbarItem[] => {
    const filterActions: TToolbarItem[] = [];

    const settings = TOOLBAR_CONFIG[storage];

    const isPublicUpload = useSelector(isPublicUploadEnabled);
    const isSelectingMode = useSelector(SelectionsSelectors.isAnyIndexSelected);
    const currentFolder = useSelector((state: RootState) => getStorageCurrentFolder(state, storage));
    const isMediaFolder = useSelector((state: RootState) => isMediaFolderAndGalleryEnabled(state, currentFolder, storage));
    const filesWithFaces = useSelector(getFilesWithSelectedFace);
    const folderWithAuthors = useSelector(isFolderWithAuthor);
    const isAnonymous = useSelector(UserSelectors.isAnonymous);
    const facesIsOff = !filesWithFaces.length;
    const { isGallery, isQuotaCleaner, isPublic, isStart } = getStorage(storage);
    const homeFolder = useSelector(getCurrentFolderHome);
    const isContentIndexed = useSelector(getSearchContentIndexed);
    const isStartSort = isStart && IS_BIZ_USER;
    const sortFolder = isStartSort ? homeFolder : currentFolder;
    const sortStorage = isStartSort ? EStorageType.home : storage;
    const sort = useSelector((state: RootState) => getSort(state, sortFolder, sortStorage));
    const viewMode = useSelector((state: RootState) => SettingsSelectors.getViewByStorage(state, storage, isMediaFolder));

    const quotaCleanerGroupViewModes = useSelector((state: RootState) => getGroupViewModes(state));

    const isDomainFoldersFilterActive = useSelector(getDomainFoldersFilterActive);
    const showAuthorsFilter =
        settings?.authorSort &&
        (folderWithAuthors || (isPublic && isPublicUpload)) &&
        facesIsOff &&
        !isAnonymous &&
        !isDomainFoldersFilterActive;
    const isRootAlbum = isAlbum(currentFolder) && currentFolder?.id === ROOT_FOLDER_ID;
    const isCustomAlbums = isAlbum(currentFolder) && currentFolder?.id === CUSTOM_ALBUMS_ID;

    if ((isSelectingMode || IS_CHOSEN_PUBLIC_FILE) && toolbarType !== ToolbarContextEnum.pageContentContext) {
        return [];
    }

    if (showAuthorsFilter) {
        filterActions.push({
            id: 'author',
            Item: FilterAuthorsDropdown,
        });
    }

    if (
        isWebSearchContentEnabled &&
        isContentIndexed &&
        toolbarType === ToolbarContextEnum.pageContentContext &&
        storage === EStorageType.search
    ) {
        filterActions.push(getResetWebSearchFiltersItem());
    }

    if (settings?.view && !isRootAlbum && !isCustomAlbums && !ENABLE_FULL_RESPONSIVE) {
        const viewItem: TToolbarItem = isQuotaCleaner
            ? getViewItemByModes(viewMode, quotaCleanerGroupViewModes)
            : getViewItem({ viewMode, isGalleryAvailable: isGallery || isMediaFolder });

        // фильтр имеет смысл при > 1 вариантах отображения
        if ((viewItem.list?.length ?? 0) > 1) {
            filterActions.push(viewItem);
        }
    }

    if (settings?.sort && sort.type && sort.order && facesIsOff) {
        filterActions.push(getSortItem({ sortType: sort.type, sortOrder: sort.order, storage }));
    }

    return filterActions;
};
