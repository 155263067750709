import { Spacing, Text } from '@vkontakte/vkui';
import coverImage from 'img/subscriptions/family-after-purchase-modal.png';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { inviteToFamilyOrCreate } from 'reactApp/modules/family/family.actions';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { ESplashScreenAction, sendSplashScreenAnalytics } from 'reactApp/ui/Mobile/SplashScreen/SplashScreen.analytics';

import styles from './FamilyAfterPurchaseModal.css';

export interface FamilyAfterPurchaseModalProps {
    compact?: boolean;
    onClose?: () => void;
}

export function FamilyAfterPurchaseModal({ compact = false, onClose }: FamilyAfterPurchaseModalProps) {
    const dispatch = useDispatch();
    const storage = useSelector(getCurrentStorage);
    const source = `${storage}_ab`;

    useEffect(() => {
        sendSplashScreenAnalytics({
            action: ESplashScreenAction.show,
            storage,
            source,
            name: 'family_one_price',
        });
        emitAnalyticEvent(AnalyticEventNames.FAMILY_AFTER_PURCHASE_MODAL_SHOW);
    }, []);

    const handleClick = useCallback(() => {
        sendSplashScreenAnalytics({
            action: ESplashScreenAction.click,
            storage,
            source,
            name: 'family_one_price',
            button_name: 'Добавить участника',
        });
        emitAnalyticEvent(AnalyticEventNames.FAMILY_AFTER_PURCHASE_MODAL_CLICK);
        dispatch(inviteToFamilyOrCreate());
        onClose?.();
    }, [onClose, dispatch]);

    const handleSecondaryClick = useCallback(() => {
        sendSplashScreenAnalytics({
            action: ESplashScreenAction.click,
            storage,
            source,
            name: 'family_one_price',
            button_name: 'Не сейчас',
        });
        emitAnalyticEvent(AnalyticEventNames.FAMILY_AFTER_PURCHASE_MODAL_CANCEL);
        onClose?.();
    }, [onClose]);

    const handleClose = useCallback(() => {
        sendSplashScreenAnalytics({
            action: ESplashScreenAction.close,
            storage,
            source,
            name: 'family_one_price',
        });
        emitAnalyticEvent(AnalyticEventNames.FAMILY_AFTER_PURCHASE_MODAL_CLOSE);
        onClose?.();
    }, [onClose]);

    const content = useMemo(() => {
        return (
            <>
                <Spacing size={16} />
                <Text className={styles.text}>
                    Добавьте в&nbsp;подписку до&nbsp;3&nbsp;участников&nbsp;&mdash; цена при этом не&nbsp;изменится. Место станет общим,
                    но&nbsp;у&nbsp;каждого будет доступ только к&nbsp;своим файлам
                </Text>
                <Spacing size={24} />
            </>
        );
    }, []);

    return (
        <WhatsNewDialog
            title="Одна цена на четверых"
            content={content}
            hideImage={compact}
            imageUrl={coverImage}
            primaryButtonText="Добавить участника"
            onClick={handleClick}
            secondaryButtonText="Не сейчас"
            onSecondary={handleSecondaryClick}
            onClose={handleClose}
            imageBgColor="transparent"
            buttonTheme="vk"
            dialogSize="tiny"
            qaId="family-after-purchase-modal"
        />
    );
}
