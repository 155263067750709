import type { Space } from '@mail/cross-sizes-utils';
import type { BillingDataPartner, BillingDataPlatform, BillingDataResponse } from 'reactApp/api/billing/data/BillingDataAPICall.types';

export type BillingDataSubscriptionRecord = Record<string, BillingDataSubscriptionInfo>;

export interface GetBillingDataRequestData {
    billingData: BillingDataResponse;
}
export interface UpdateBillingDataAction {
    showSnackbar?: boolean;
}

export interface BillingPaidInfo {
    trialExpires: number;
    paidExpires: number;
}

export interface BillingDataState {
    activeSubscriptionId?: string;
    subscription: BillingDataSubscriptionRecord;
    paidInfo: BillingPaidInfo;
    rawBillingData: BillingDataResponse;
    isLoading: boolean;
    isLoaded: boolean;
}

export enum BillingDataSubscriptionInfoStatus {
    ACTIVE = 'ACTIVE',
    FINISHED = 'FINISHED',
    SUSPENDED = 'SUSPENDED',
    GRACE = 'GRACE',
    HOLD = 'HOLD', // Пока нет
    LAPSED = 'LAPSED',
    WAITING = 'WAITING',
}

export interface BillingDataSubscriptionInfo {
    id: string;
    type: BillingDataPlatform;
    start: number;
    status: BillingDataSubscriptionInfoStatus;
    expires: number;
    renewAt?: number;
    icon: string;
    productId: string;
    period?: string;
    space: Space;
    autorenewal?: boolean;
    // Цена покупки подписки (для подписки со скидкой тут будет указана скидочная цена, для подписки с триалом будет 0)
    purchaseCost?: number;
    // Цена продления подписки
    renewalCost?: number;
    renewable: boolean;
    isTrial: boolean;
    isPrepaid: boolean;
    flags: Record<string, boolean>;
    accountLink?: string;
    partner?: BillingDataPlatform | BillingDataPartner;
    isMail: boolean;
}

export enum BillingDataNotifications {
    updateSuccess = 'BILLING_DATA_UPDATE_SUCCESS',
    updateFailure = 'BILLING_DATA_UPDATE_FAILURE',
}
