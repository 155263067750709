import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { EAttachesType } from 'reactApp/modules/attaches/attaches.constants';
import { FeedCategoryType } from 'reactApp/modules/feed/feed.types';
import { getGalleryCategory } from 'reactApp/modules/gallery/gallery.selectors';
import { GalleryCategoryType } from 'reactApp/modules/gallery/gallery.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { store as reduxStore } from 'reactApp/store';
import { sendDwh } from 'reactApp/utils/ga';

import { ECategoryGa } from './paymentGa';

type CategoryType = FeedCategoryType | GalleryCategoryType | EAttachesType | string | undefined;
type Action =
    /* Групповые эвенты > */
    | 'show'
    | 'download'
    /* < Групповые эвенты */
    | 'shared-links'
    | 'shared-incoming'
    | 'shared-indexed'
    | 'favorites-click'
    | 'album-click'
    | 'autodelete-create'
    | 'autodelete-more'
    | 'files-from-more'
    | 'all-files-state-download';
type Config = string;

interface Params {
    action: Action;
}

const configIsMap = (config: Config | Map<CategoryType | undefined, Config> | undefined): config is Map<CategoryType | undefined, Config> =>
    !!config && config instanceof Map;

export const showActionConfigByStorage = new Map<EStorageType, Config | Map<CategoryType | undefined, Config>>()
    .set(EStorageType.sharedLinks, 'shared-links-state')
    .set(EStorageType.sharedIncoming, 'shared-incoming-state')
    .set(
        EStorageType.feed,
        new Map<CategoryType | undefined, Config>()
            .set(undefined, 'last-files-all-view')
            .set(FeedCategoryType.all, 'last-files-all-view')
            .set(FeedCategoryType.image, 'last-files-image-view')
            .set(FeedCategoryType.document, 'last-files-docs-view')
            .set(FeedCategoryType.audio, 'last-files-music-view')
            .set(FeedCategoryType.video, 'last-files-video-view')
    )
    .set(EStorageType.favorites, 'favorites-view')
    .set(
        EStorageType.gallery,
        new Map<GalleryCategoryType | undefined, Config>()
            .set(GalleryCategoryType.all, 'gallery-all-view')
            .set(GalleryCategoryType.desktop, 'gallery-pc-view')
            .set(GalleryCategoryType.mobile, 'gallery-phone-view')
    )
    .set(EStorageType.albums, 'album-view')
    .set(
        EStorageType.attaches,
        new Map<EAttachesType | 'all' | undefined, Config>()
            // eslint-disable-next-line sonarjs/no-duplicate-string
            .set('all', 'files-from-mail-all')
            .set(EAttachesType.all, 'files-from-mail-all')
            .set(EAttachesType.music, 'files-from-mail-music')
            .set(EAttachesType.docs, 'files-from-mail-docs')
            .set(EAttachesType.photo, 'files-from-mail-photo')
            .set(EAttachesType.video, 'files-from-mail-video')
            .set(EAttachesType.other, 'files-from-mail-other')
    )
    .set(EStorageType.trashbin, 'basket-state')
    .set(EStorageType.sharedAutodelete, 'autodelete-view')
    .set(EStorageType.home, 'all-files-state-view');

const feedDownloadActionMap = new Map<CategoryType | undefined, string>()
    .set(undefined, 'last-files-all-download')
    .set(FeedCategoryType.all, 'last-files-all-download')
    .set(FeedCategoryType.image, 'last-files-image-download')
    .set(FeedCategoryType.document, 'last-files-docs-download')
    .set(FeedCategoryType.audio, 'last-files-music-download')
    .set(FeedCategoryType.video, 'last-files-video-download');

const galleryDownloadActionMap = new Map()
    .set(GalleryCategoryType.all, 'gallery-all-download')
    .set(GalleryCategoryType.desktop, 'gallery-phone-download')
    .set(GalleryCategoryType.mobile, 'gallery-pc-download');

export const sendEmptyStatesAnalitics = ({ action = 'show' }: Params) => {
    const state = reduxStore.getState();

    const storage = getCurrentStorage(state);
    const { type: attachType } = SettingsSelectors.getQueryParams(state);
    const galleryCategory = getGalleryCategory(state);

    const categoryType = attachType || galleryCategory;

    let resultAction: string | Map<CategoryType, string> | undefined = action;

    if (action === 'show') {
        resultAction = showActionConfigByStorage.get(storage);

        if (configIsMap(resultAction) && categoryType) {
            resultAction = resultAction.get(categoryType || 'all');
        }
    } else if (action === 'download' && storage === EStorageType.feed) {
        resultAction = feedDownloadActionMap.get(categoryType);
    } else if (action === 'download' && storage === EStorageType.gallery) {
        resultAction = galleryDownloadActionMap.get(categoryType);
    }

    const isPaidUser = UserSelectors.isPaidUser(state);
    const source = IS_PHONE_BROWSER ? 'touch' : 'desktop';

    const dwhData = {
        version: 'new',
        source,
        // trial_user: false,
        free_user: !isPaidUser,
        paid_user: isPaidUser,
    };

    sendDwh({
        eventCategory: ECategoryGa.entered,
        action: resultAction,
        dwhData,
    });
};
