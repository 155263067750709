import type { FaviconsInterface } from 'lib/getFaviconByExtension';

export function replaceFaviconTags(favicons: FaviconsInterface): void {
    const head = document.querySelector('head');
    const icons = document.querySelectorAll<HTMLLinkElement>('link[rel="icon"], link[rel="apple-touch-icon"]');

    const headContent = head?.outerHTML;
    if (!headContent) {
        return;
    }

    icons.forEach((icon) => {
        const type = icon.getAttribute('type');
        const rel = icon.getAttribute('rel');
        const sizes = icon.getAttribute('sizes');
        // svg иконка
        if (type === 'image/svg+xml') {
            icon.href = favicons.svg as string;
        }
        if (rel === 'apple-touch-icon') {
            icon.href = favicons.appleTouchIcon as string;
        }
        // дефолтная фав иконка
        if (sizes === 'any') {
            icon.href = favicons.ico as string;
        }
    });
}
