import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

const paidUserPricesValue: 'a' | 'b' = !IS_WEBVIEW ? getFeature('paid-user-prices') : getFeature('paid-user-prices-webview');

registerExperiment('paid-usr-prices', paidUserPricesValue, [
    AnalyticEventNames.QUOTA_LANDING_SHOWN,
    AnalyticEventNames.MOBILE_LANDING_SHOWN,
    AnalyticEventNames.TARIFF_CLICK,
    AnalyticEventNames.FAST_CHECKOUT_SHOWN,
    AnalyticEventNames.TARIFF_BUY,
]);

export const isPaidUserPrices = paidUserPricesValue === 'b';
