import { document } from '@perf-tools/keeper';
import b2clogo from 'img/doc_logo.png';
import b2blogo from 'img/workdisk_logo.png';
import { xray } from 'lib/xray';
import { mergeDeepRight } from 'ramda';
import { R7ConvertAPICall } from 'reactApp/api/r7/R7ConvertAPICall';
import { R7EditApiAPICall } from 'reactApp/api/r7/R7EditAPICall';
import { R7ViewApiAPICall } from 'reactApp/api/r7/R7ViewAPICall';
import { IS_B2B_BIZ_USER, IS_MOBILE_BROWSER, IS_PUBLIC, IS_STOCK, USER_EMAIL } from 'reactApp/appHelpers/configHelpers';
import { isDefaultR7LoaderForB2b } from 'reactApp/appHelpers/featuresHelpers/features/defaultR7LoaderForB2b';
import { EAllDocumentsType } from 'reactApp/modules/allDocuments/allDocuments.types';
import { readyEditor } from 'reactApp/modules/editor/editor.module';
import { store as reduxStore } from 'reactApp/store';
import { changeCloudToDocumentDomain } from 'reactApp/utils/documentsDomain';
import { isDocumentsDomain } from 'server/helpers/isDocumentsDomain';

import { getGroupVal, sendR7KaktamLog, sendR7LifecycleRadar, sendR7LoadTimeRadar } from './Editor/helpers';
import { type IR7Data, type IR7ToolbarParams, type R7ErrorHandler, type R7Mode, type R7Options, EditorDocumentType } from './types';

const getData = (itemPath: string) => {
    if (IS_STOCK) {
        return { stock: itemPath };
    }

    if (IS_PUBLIC) {
        return { public: itemPath };
    }

    return { home: itemPath };
};

const callR7ApiViewAPICall = (itemPath: string) => new R7ViewApiAPICall().makeRequest(getData(itemPath));

const callR7ApiEditAPICall = (itemPath: string) => new R7EditApiAPICall().makeRequest(getData(itemPath));

export const callApiR7Convert = (itemPath: string) => new R7ConvertAPICall().makeRequest(getData(itemPath));

const apiR7 = (url: string, mode: R7Mode) => (mode === 'edit' ? callR7ApiEditAPICall(url) : callR7ApiViewAPICall(url));

const getCurrentDocumentPath = (documentType: EditorDocumentType) => {
    switch (documentType) {
        case EditorDocumentType.spreadsheet:
            return EAllDocumentsType.spreadsheet;

        case EditorDocumentType.presentation:
            return EAllDocumentsType.presentation;

        default:
            return EAllDocumentsType.document;
    }
};

const r7ToolbarOptions = ({ isAttach }: IR7ToolbarParams) => {
    if (isAttach) {
        return {
            plugins: false,
        };
    }
};

const initR7Doc = async (url: string, { r7InitData, mode = 'view', onDocumentReady, onError, isAttach }: R7Options) => {
    try {
        let data: IR7Data;
        if (r7InitData) {
            data = r7InitData;
        } else {
            const res = await apiR7(url, mode);
            data = res.data;
        }

        const documentType = data?.documentType;
        const currDocumentPath = getCurrentDocumentPath(documentType);

        const handleOnDocumentReady = () => {
            onDocumentReady();
            reduxStore.dispatch(readyEditor());
        };

        const backLink = (() => {
            if (isDocumentsDomain(window.location.host)) {
                return `${window.location.origin}/${currDocumentPath}`;
            }
            return changeCloudToDocumentDomain(`${window.location.origin}/${currDocumentPath}`);
        })();
        const loaderName = (() => {
            if (IS_B2B_BIZ_USER && !isDefaultR7LoaderForB2b) {
                return 'WorkDisk';
            }
            // Важно передавать непустую строку, тк иначе на мобиле появляется логотип Р7
            if (IS_MOBILE_BROWSER) {
                return 'VK';
            }
            // Важно передавать undefined, тк иначе на десктопе появится крутилка от Р7
            return undefined;
        })();

        return mergeDeepRight(data, {
            editorConfig: {
                customization: {
                    layout: {
                        toolbar: r7ToolbarOptions({ isAttach }),
                    },
                    macros: true,
                    macrosMode: 'warn',
                    loaderName,
                    logo: {
                        image: IS_B2B_BIZ_USER ? b2blogo : b2clogo,
                        url: backLink,
                    },
                    anonymous: {
                        request: mode === 'edit',
                    },
                },
            },
            type: IS_MOBILE_BROWSER ? 'mobile' : 'desktop',
            events: {
                onDocumentReady: handleOnDocumentReady,
                onError,
            },
        });
    } catch (error) {
        onError({ data: error });
    }
};

let r7EditorApi: null | ReturnType<DocsAPI['DocEditor']> = null;

const loadR7Doc = <T>(
    placeholderId: string,
    {
        data,
        onError,
    }: {
        data: T;
        onError: R7ErrorHandler;
    }
) => {
    try {
        if (r7EditorApi) {
            r7EditorApi.destroyEditor();
        }

        // eslint-disable-next-line new-cap
        r7EditorApi = window.DocsAPI.DocEditor(placeholderId, data);
    } catch (error) {
        onError({ data: error });
    }
};

/**
 * Удаляет параметры из пути
 * @example /Новый%20документ.docx?weblink=W7PE/Ew7agqNHD -> /Новый%20документ.docx
 */
const getPathFromUrl = (url: string): string => {
    return url.split('?')[0];
};

export async function initAndLoadR7(
    url: string,
    {
        mode,
        placeholderId,
        container,
        onDocumentReady,
        r7InitData,
        isAttach,
        onError,
    }: {
        placeholderId: string;
        container: HTMLElement;
    } & R7Options
) {
    const initStart = performance.now();

    const sendInitFinishRadar = (withLoadingApi: boolean) => {
        const loadingTime = performance.now() - initStart;

        const groupVal = getGroupVal(loadingTime);

        // Время отображения редактора - cloudweb-17887
        sendR7LoadTimeRadar('init2edtr-load', groupVal, loadingTime, Number(withLoadingApi));

        // Успешная загрузка iframe - cloudweb-17887
        sendR7LifecycleRadar('edtr-load-ok');
    };

    try {
        const path = getPathFromUrl(url);

        const fileExtension = path.split('.').at(-1);

        // Расширение файла которое открывается через R7 - cloudweb-17887
        sendR7LifecycleRadar('file-ext-load', fileExtension?.toLowerCase());

        const onDocumentReadyWithRadar = () => {
            const loadingTime = performance.now() - initStart;

            const groupVal = getGroupVal(loadingTime);

            // Время от начала инициализации до загрузки документа - cloudweb-17887
            sendR7LoadTimeRadar('init2doc-load', groupVal, loadingTime);

            onDocumentReady();
        };

        const onDocumentErrorWithRadar: R7ErrorHandler = (data) => {
            // Ошибка загрузки документа - cloudweb-17887
            sendR7LifecycleRadar('init-doc-err');
            sendR7KaktamLog(data);

            onError(data);
        };

        const onEditorErrorWithRadar: R7ErrorHandler = (data) => {
            // Ошибка инициализации редактора - cloudweb-17887
            sendR7LifecycleRadar('init-edtr-err');
            sendR7KaktamLog(data);

            onError(data);
        };

        const data = await initR7Doc(path, {
            r7InitData,
            isAttach,
            mode,
            onDocumentReady: onDocumentReadyWithRadar,
            onError: onDocumentErrorWithRadar,
        });

        if (mode === 'edit') {
            const radarData = USER_EMAIL ? { dwh: { current_email: USER_EMAIL } } : undefined;

            xray.send(USER_EMAIL ? 'r7-editing-load-ok' : 'r7-editing-anon-load-ok', radarData);
        }

        const loadR7Props = {
            data,
            onError: onEditorErrorWithRadar,
        };

        if (typeof window.DocsAPI === 'undefined') {
            const scriptObj = document.createElement('script');
            scriptObj.type = 'text/javascript';
            scriptObj.src = `${data && data.api && !data.api.startsWith('https://') ? 'https://' : ''}${
                (data && data.api) || '/r7'
            }/web-apps/apps/api/documents/api.js`;
            container.appendChild(scriptObj);
            scriptObj.addEventListener?.('load', () => {
                sendInitFinishRadar(true);

                loadR7Doc(placeholderId, loadR7Props);
            });
        } else {
            sendInitFinishRadar(false);

            loadR7Doc(placeholderId, loadR7Props);
        }
    } catch (error) {
        // Ошибка загрузки редактора - cloudweb-17887
        sendR7KaktamLog({ data: error });
        sendR7LifecycleRadar('edtr-load-err');

        onError({ data: error });
    }
}
