import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_IOS_MOBILE, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

const crossedOutPriceValue: 'a' | 'b' = getFeature('crossed-out-price');

if (IS_WEBVIEW && !IS_IOS_MOBILE) {
    registerExperiment('crossed-out-prc', crossedOutPriceValue, [
        AnalyticEventNames.MOBILE_LANDING_SHOWN,
        AnalyticEventNames.TARIFF_CLICK,
        AnalyticEventNames.FAST_CHECKOUT_SHOWN,
        AnalyticEventNames.TARIFF_BUY,
    ]);
}

export const crossedOutPriceAlternate: boolean = IS_WEBVIEW && !IS_IOS_MOBILE && crossedOutPriceValue === 'b';
