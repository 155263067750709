/* eslint-disable max-lines */
import classNames from 'clsx';
import { xray } from 'lib/xray';
import throttle from 'lodash.throttle';
import React, { type ReactElement, forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_TABLET_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import {
    crossedFullPriceInTariffCard,
    favorableTariffs,
    isRebrandingQuotaLanding,
    moreBenefitsInExpensiveTariffs,
    preferredTariffs,
    sendStartEventTimer,
    yearlyPriceOnMonthlyTariffButtonTouch,
} from 'reactApp/appHelpers/featuresHelpers';
import { blackFriday2024 } from 'reactApp/appHelpers/featuresHelpers/features/blackFriday2024';
import { cheapestTariff } from 'reactApp/appHelpers/featuresHelpers/features/cheapestTariff';
import { compactFirstScroll } from 'reactApp/appHelpers/featuresHelpers/features/compactFirstScroll';
import { cyberMonday2025 } from 'reactApp/appHelpers/featuresHelpers/features/cyberMonday2025';
import { isPromoQuotaWithLitress } from 'reactApp/appHelpers/featuresHelpers/features/litres';
import { isPaidUserPrices } from 'reactApp/appHelpers/featuresHelpers/features/paidUserPrices';
import { tariffPollDelay } from 'reactApp/appHelpers/featuresHelpers/features/tariffPoll';
import { upsaleWithTrial } from 'reactApp/appHelpers/featuresHelpers/features/upsaleWithTrial';
import { yearPriceInMonthEnabled } from 'reactApp/appHelpers/featuresHelpers/features/yearPriceInMonth';
import { DEFAULT_ACTION_NAME, START_EVENT_TIMER_MS } from 'reactApp/constants';
import { useMinWidthBreakpoint } from 'reactApp/hooks/responsiveness/useMinWidthBreakpoint';
import { MediaSelectors } from 'reactApp/modules/media/media.selectors';
import { loadPaidInfoRequest } from 'reactApp/modules/paidInfo/paidInfo.module';
import { PaidInfoSelectors } from 'reactApp/modules/paidInfo/paidInfo.selectors';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { showLandingTariffsAction } from 'reactApp/modules/quotaLanding/quotaLanding.module';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { TariffCard } from 'reactApp/sections/MobileSubscriptionsPage/TariffCard/TariffCard';
import { MobileTariffCardTheme } from 'reactApp/sections/MobileSubscriptionsPage/TariffCard/TariffCard.types';
import { Container } from 'reactApp/sections/QuotaLanding/Container/Container';
import { useIntersecObsrWithParams } from 'reactApp/sections/QuotaLanding/hooks/useIntersecObsrWithParams';
import { ETabs } from 'reactApp/sections/QuotaLanding/QuotaLanding.data';
import { sendQuotaBlockViewDWH, sendQuotaGa } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { renderTariffPollModal, TARIFF_POLL_MODAL_ID } from 'reactApp/sections/QuotaLanding/TariffPollDialog/TariffPollDialog.helpers';
import { TariffPeriodSwitch } from 'reactApp/sections/QuotaLanding/TariffsList/TariffPeriodSwitch/TariffPeriodSwitch';
import type { MobileBuyClick } from 'reactApp/sections/QuotaLanding/TariffsList/TariffsList.types';
import { TariffPlansCard } from 'reactApp/sections/QuotaLanding/TariffsPlans/TariffPlansCard/TariffPlansCard';
import { useBuyClick } from 'reactApp/sections/QuotaLanding/TariffsPlans/TariffPlansCard/useBuyClick';
import {
    getMaxTariffDiscount,
    getTariffListPaidUsers,
    getTariffListPaidUsersMobile,
    getTariffsList,
} from 'reactApp/sections/QuotaLanding/TariffsPlans/TariffsPlans.selectors';
import type { RootState } from 'reactApp/store';
import type { Product } from 'reactApp/types/Billing';
import type { SwiperNavigator } from 'reactApp/types/SwiperNavigator';
import { DiscountLabel } from 'reactApp/ui/DiscountLabel/DiscountLabel';
import { ETabsName } from 'reactApp/ui/TariffSectionNew/TariffSection.constants';
import { sendIosFavorableTariffsAnalytics, sendProfitableTariffsFirstAnalytics } from 'reactApp/utils/analytics';
import { isMonthPeriod } from 'reactApp/utils/Period';
import { sendTmrGoal, TMR3473039 } from 'reactApp/utils/tmr';
import { Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { MobileTariffCardLoader } from './MobileTariffCardLoader/MobileTariffCardLoader';
import { NavButtons } from './NavButtons/NavButtons';
import { TariffCardLoader } from './TariffCardLoader/TariffCardLoader';
import styles from './TariffsList.css';

interface TariffsPlansProps {
    changeTab?: (tab) => void;
    isIOSPromo?: boolean;
    isAndroidPromo?: boolean;
    setCarouselLoaded?: (state: boolean) => void;
    headerHeight?: number;
    isMobilePayAvailable: boolean;
    isMobilePayFromGetParam: boolean;
    isAnonymous: boolean;
    isTariffsPage?: boolean;
    isPhone?: boolean;
    autouploadPromo?: boolean;
    activeInitTab?: ETabsName;
}

const ACTION_PROMO_MAX_DISCOUNT = 80;

export const TariffsList = forwardRef<HTMLDivElement | null, TariffsPlansProps>(
    // eslint-disable-next-line max-lines-per-function
    (
        {
            isAnonymous,
            changeTab,
            isIOSPromo,
            isAndroidPromo,
            setCarouselLoaded,
            headerHeight = 0,
            isMobilePayAvailable,
            isMobilePayFromGetParam,
            isTariffsPage,
            isPhone = false,
            // tempexp_16481-next-line
            autouploadPromo = false,
            activeInitTab,
        },
        ref
    ): ReactElement => {
        const dispatch = useDispatch();

        const isMobileSelector = useSelector(QuotaLandingSelectors.isMobile);
        const isMobile = isPhone || isMobileSelector;
        const isTablet = useSelector(MediaSelectors.isTablet);

        const isPaid = useSelector(UserSelectors.isPaidUser);
        const maxTariffDiscount = useSelector((state: RootState) => getMaxTariffDiscount(state, DEFAULT_ACTION_NAME, isMobile));
        let discount = maxTariffDiscount;
        // tempexp_16373-next-line
        const isFavorableTariffs = favorableTariffs.length;

        // tempexp_16373-next-line
        if (!isFavorableTariffs) {
            discount = ACTION_PROMO_MAX_DISCOUNT;
        }

        const [activeTab, setActiveTab] = useState<ETabsName>(activeInitTab || ETabsName.year);

        const tariffList = useSelector((state: RootState) => {
            // tempexp_17380-next-line // tempexp_17815-next-line
            if (isPaidUserPrices) {
                return isMobile ? getTariffListPaidUsersMobile(state) : getTariffListPaidUsers(state);
            }
            return getTariffsList(state, DEFAULT_ACTION_NAME, autouploadPromo, isMobile);
        });
        const { isLoading, isLoaded } = useSelector(ProductsSelectors.getLifeCycleState);
        const firstLoad = useRef<boolean>(false);

        const [navState, setNavState] = useState({ isStart: true, isLast: false });
        const [navigator, setNavigator] = useState<SwiperNavigator | null>(null);

        // tempexp_18090-start
        const tariffPollTimer = useRef<number | undefined>();
        // todo: заменить на новую ручку billinginfo
        const userWithoutPayment = useSelector(PaidInfoSelectors.isUserWithoutPayment);
        const { isLoaded: isPaidInfoLoaded } = useSelector(PaidInfoSelectors.getLoadingState);
        // tempexp_18090-end

        const onBuyClick = useBuyClick({
            isMobilePayAvailable,
            isMobilePayFromGetParam,
            isIOSPromo,
            isAndroidPromo,
            enabledMidasPayment: true,
            isRebrandingQuotaLanding,
        });

        const onMobileBuyClick: MobileBuyClick = useCallback(
            (selectedProduct, yearProduct, yearSavingDiscount, paySource) => {
                const sendAnalytics = () => {
                    sendIosFavorableTariffsAnalytics(['buy_click']);
                    sendProfitableTariffsFirstAnalytics(['buy_click']);
                };

                if (
                    !blackFriday2024 &&
                    !cyberMonday2025 &&
                    !isPaid &&
                    isTariffsPage &&
                    upsaleWithTrial?.tariffs?.length &&
                    isMonthPeriod(selectedProduct.period) &&
                    yearProduct
                ) {
                    openPopupHelper({
                        popupName: popupNames.UPSALE_WITH_TRIAL,
                        data: {
                            isPhone: true,
                            monthProduct: selectedProduct,
                            yearProduct,
                            yearSavingDiscount,
                            activePeriod: upsaleWithTrial.activePeriod,
                            resolve: (product: Product) => {
                                onBuyClick({
                                    product,
                                    paySource: 'modal_choose_tariff',
                                    onClick: sendAnalytics,
                                    space: selectedProduct.space.space,
                                });
                            },
                        },
                    });
                    sendTmrGoal({ id: TMR3473039, goal: 'tariff-click' });

                    if (isAnonymous) {
                        sendTmrGoal({ id: TMR3473039, goal: 'login-view' });
                    }

                    return;
                }

                onBuyClick({
                    product: { ...selectedProduct, price: selectedProduct.price || 0 },
                    onClick: sendAnalytics,
                    // tempexp_16481-next-line
                    space: selectedProduct.space.space,
                    paySource,
                });
            },
            [onBuyClick, isMobile, isTariffsPage, isPaid]
        );

        const updateNavState = useCallback(() => {
            setNavState({ isStart: navigator?.isFirst() as boolean, isLast: navigator?.isLast() as boolean });
        }, [navigator, setNavState]);

        const onClickSwitch = useCallback((tab) => {
            sendQuotaGa({ action: 'change', label: 'period', name_button: tab });
            sendTmrGoal({ id: TMR3473039, goal: 'change-period' });
            setActiveTab(tab);
        }, []);

        const handleSetNavigator = useCallback(
            (navigator) => {
                setNavigator(navigator);
            },
            [setNavigator, updateNavState]
        );

        // отправляем событие с периодом из фичи или раз в 10 минут
        const sendStartViewEvent = throttle(() => {
            dispatch(showLandingTariffsAction());
        }, sendStartEventTimer || START_EVENT_TIMER_MS);

        const onIntersecting = useCallback(() => {
            if (sendStartEventTimer) {
                sendStartViewEvent();
            }

            emitAnalyticEvent(AnalyticEventNames.TARIFFS_SHOWN);
            sendQuotaGa({ action: 'tariff', label: 'view' });
            sendTmrGoal({ id: TMR3473039, goal: 'tariff-view' });
            sendQuotaBlockViewDWH('tariffs');
            changeTab?.(ETabs.tariffs);
        }, [changeTab]);

        const refIntersection = useIntersecObsrWithParams({
            onIntersecting,
            isMobile,
            headerHeight,
        });

        useEffect(() => {
            xray.send('quota_show_tariffs_list');
        }, []);

        useEffect(() => {
            // tempexp_18090-start
            if (!isPaid && !isPaidInfoLoaded && !isAnonymous) {
                dispatch(loadPaidInfoRequest());
            }

            if (!isPaid && isPaidInfoLoaded && userWithoutPayment && !tariffPollTimer?.current && tariffPollDelay) {
                const id = window.setTimeout(() => {
                    const tariffPollShown = Boolean(dispatch(UserStorageActions.get(TARIFF_POLL_MODAL_ID)));
                    if (!tariffPollShown) {
                        renderTariffPollModal();
                    }
                }, tariffPollDelay);

                tariffPollTimer.current = id;
            }

            return () => {
                if (tariffPollTimer?.current) {
                    window.clearInterval(tariffPollTimer.current);
                }
            };
            // tempexp_18090-end
        }, [userWithoutPayment, isPaidInfoLoaded, isAnonymous, dispatch]);

        useEffect(() => {
            if (isLoaded && !firstLoad.current) {
                firstLoad.current = true;
            }
        }, [isLoaded]);

        useEffect(() => {
            updateNavState();
        }, [navigator, updateNavState, tariffList?.length]);

        const cardRef = useRef<HTMLDivElement>(null);
        /**
         * Под анонимом список тарифов захардкожен (в онлайнконфе) и сразу имеет длину (не загружается из стора)
         * При этом рефка на начальной загрузке еще не установлена
         * из-за этого происходит не верный рассчет переменной на начальной загрузке и получается 4 * (0 + 24) = 96
         *
         * Если аноним и рефка еще не установлена, устанавливаем переменную в 0. Как только рефка загрузится, произойдет перерасчет.
         */
        const lgBreackPoint = cardRef?.current
            ? tariffList.length * ((cardRef.current?.scrollWidth ?? 0) + (isRebrandingQuotaLanding ? 24 : 16))
            : 0;
        const [lgBreakpointHit, breackPoint, setBreackPoint] = useMinWidthBreakpoint(lgBreackPoint);
        useEffect(() => {
            if (!breackPoint) {
                setBreackPoint(lgBreackPoint);
            }
        }, [lgBreackPoint]);
        const displayInSwiper = !isFavorableTariffs || !lgBreakpointHit;

        /* tempexp_16800-start */
        const calcCardStyles = (style: string, isSwiperSlot?: boolean) =>
            classNames(style, {
                [styles.tariffCard_large]: moreBenefitsInExpensiveTariffs === 'c' || moreBenefitsInExpensiveTariffs === 'd',
                [isSwiperSlot ? styles.slide_rebranding : styles.favorableTariffCard_rebranding]: isRebrandingQuotaLanding,
            });
        /* tempexp_16800-end */

        // tempexp_18090-start
        const onTariffClick = useCallback(() => {
            if (tariffPollDelay && tariffPollTimer?.current) {
                window.clearInterval(tariffPollTimer.current);
            }
        }, [tariffPollTimer]);
        // tempexp_18090-end

        if (isMobile) {
            return (
                <div ref={refIntersection}>
                    <div ref={ref}>
                        <Container isNarrow>
                            <div className={styles.mobileList}>
                                {tariffList.map((tariff, index) => (
                                    <div
                                        className={classNames(styles.mobileTariff, {
                                            [styles.mobileTariff_rebranding]: isRebrandingQuotaLanding,
                                        })}
                                        key={tariff.products?.[0].id}
                                    >
                                        <TariffCard
                                            {...tariff}
                                            onClick={onMobileBuyClick}
                                            /* tempexp_17509-next-line */
                                            isTopCard={index === 0}
                                            openAbCard={index === 0 || isTablet || IS_TABLET_BROWSER}
                                            alwaysExpand={isTablet || IS_TABLET_BROWSER}
                                            theme={isTariffsPage ? MobileTariffCardTheme.grey : MobileTariffCardTheme.white}
                                            isSingleQuota
                                            showPriceByMonth={
                                                yearlyPriceOnMonthlyTariffButtonTouch === 'c' || yearPriceInMonthEnabled === 'b'
                                            }
                                            skipBenefitsFeature={isTariffsPage}
                                            setCarouselLoaded={setCarouselLoaded}
                                            crossFullPrice={crossedFullPriceInTariffCard}
                                            // tempexp_17020-start
                                            showPriceByYear={yearlyPriceOnMonthlyTariffButtonTouch === 'b'}
                                            // tempexp_17020-start
                                            skipDiscountPrice={Boolean(preferredTariffs.length)}
                                            isRebrandingQuotaLanding={isRebrandingQuotaLanding}
                                            // tempexp_17509-next-line
                                            isFromLitres={isPromoQuotaWithLitress && !isPaid}
                                            // tempexp_17406-next-line
                                            autouploadTooltip={index === 0}
                                        />
                                    </div>
                                ))}
                                {isLoading &&
                                    !firstLoad.current &&
                                    Array.from(new Array(6)).map((_, idx) => (
                                        <div className={styles.mobileTariff} key={`mobileCard_${idx}`}>
                                            <MobileTariffCardLoader isExpand={idx === 0 || Boolean(isTablet)} isDark={isTariffsPage} />
                                        </div>
                                    ))}
                            </div>
                        </Container>
                    </div>
                </div>
            );
        }

        let desktopLoadersCount = favorableTariffs.length;
        if (cheapestTariff !== 'a') {
            desktopLoadersCount++;
        }

        return (
            <div ref={refIntersection}>
                <div className={classNames(styles.root, { [styles.root_anonymous]: isAnonymous })} ref={ref}>
                    <div className={styles.header}>
                        <div className={styles.switch}>
                            {Boolean(discount) && (
                                <div
                                    className={classNames(styles.discountLabel, {
                                        [styles.discountLabel_compact]: compactFirstScroll,
                                    })}
                                >
                                    <DiscountLabel
                                        discount={discount}
                                        isAction
                                        isRebrandingQuotaLanding={isRebrandingQuotaLanding}
                                        // tempexp_17509-start
                                        isFromLitres={isPromoQuotaWithLitress && !isPaid}
                                        // tempexp_17509-end
                                    />
                                </div>
                            )}
                            <TariffPeriodSwitch checkedOption={activeTab} onClickSwitch={onClickSwitch} />
                        </div>
                        {/* tempexp_16373-next-line */}
                        {displayInSwiper && (
                            <div className={styles.navButtons}>
                                <NavButtons
                                    isLast={navState.isLast}
                                    isStart={navState.isStart}
                                    onNext={navigator?.navigateToNext}
                                    onPrev={navigator?.navigateToPrev}
                                    updateNavState={updateNavState}
                                />
                            </div>
                        )}
                    </div>

                    {/* tempexp_16373-next-line */}
                    {!displayInSwiper ? (
                        <div
                            className={classNames(styles.favorableTariffsList, {
                                [styles.favorableTariffsList_rebranding]: isRebrandingQuotaLanding,
                            })}
                        >
                            {tariffList.map((tariff, idx) => (
                                <div ref={cardRef} className={calcCardStyles(styles.favorableTariffCard)} key={idx}>
                                    <TariffPlansCard
                                        activeTab={activeTab}
                                        tariff={tariff}
                                        isIOSPromo={isIOSPromo}
                                        isAndroidPromo={isAndroidPromo}
                                        isMobilePayAvailable={isMobilePayAvailable}
                                        isMobilePayFromGetParam={isMobilePayFromGetParam}
                                        setCarouselLoaded={setCarouselLoaded}
                                        // tempexp_18090-next-line
                                        onTariffClick={onTariffClick}
                                    />
                                </div>
                            ))}
                            {isLoading &&
                                !firstLoad.current &&
                                Array.from(new Array(desktopLoadersCount)).map((_, idx) => (
                                    <div ref={cardRef} className={calcCardStyles(styles.favorableTariffCard)} key={`tariffCard_${idx}`}>
                                        <TariffCardLoader />
                                    </div>
                                ))}
                        </div>
                    ) : (
                        <div className={classNames(styles.list, { [styles.list_rebranding]: isRebrandingQuotaLanding })}>
                            <Swiper
                                slidesPerView={'auto'}
                                spaceBetween={isRebrandingQuotaLanding ? 24 : 16}
                                threshold={20}
                                pagination={{
                                    clickable: true,
                                }}
                                onSwiper={(swiper) => {
                                    handleSetNavigator({
                                        navigateToNext: () => swiper?.slideTo(swiper?.activeIndex + 2),
                                        navigateToPrev: () => swiper?.slideTo(swiper?.activeIndex - 2),
                                        isFirst: () => swiper?.isBeginning,
                                        isLast: () => swiper?.isEnd,
                                    });
                                }}
                                onActiveIndexChange={updateNavState}
                                direction="horizontal"
                                mousewheel={{
                                    forceToAxis: true,
                                    sensitivity: 2,
                                }}
                                modules={[Mousewheel]}
                            >
                                {tariffList.map((tariff, idx) => (
                                    <SwiperSlide key={tariff.tariffId} virtualIndex={idx} className={calcCardStyles(styles.slide, true)}>
                                        {() => {
                                            return (
                                                <div ref={cardRef} className={calcCardStyles(styles.carouselCard, true)}>
                                                    <TariffPlansCard
                                                        activeTab={activeTab}
                                                        tariff={tariff}
                                                        isIOSPromo={isIOSPromo}
                                                        isAndroidPromo={isAndroidPromo}
                                                        isMobilePayAvailable={isMobilePayAvailable}
                                                        isMobilePayFromGetParam={isMobilePayFromGetParam}
                                                        setCarouselLoaded={setCarouselLoaded}
                                                        // tempexp_18090-next-line
                                                        onTariffClick={onTariffClick}
                                                    />
                                                </div>
                                            );
                                        }}
                                    </SwiperSlide>
                                ))}
                                {isLoading &&
                                    !firstLoad.current &&
                                    Array.from(new Array(6)).map((_, idx) => (
                                        <SwiperSlide
                                            key={`tariffCard_${idx}`}
                                            virtualIndex={idx + 1}
                                            className={calcCardStyles(styles.slide, true)}
                                        >
                                            {() => {
                                                return (
                                                    <div ref={cardRef} className={calcCardStyles(styles.carouselCard, true)}>
                                                        <TariffCardLoader />
                                                    </div>
                                                );
                                            }}
                                        </SwiperSlide>
                                    ))}
                            </Swiper>
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

TariffsList.displayName = 'TariffsList';
