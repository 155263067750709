import { differenceInDays, isToday } from 'date-fns';
import { getFeatureAbAutoDelete, getFeaturePublicAutoDelete } from 'reactApp/modules/features/features.selectors';
import type {
    IAutoDeleteFoldersInfo,
    SharedAutoDeleteFolder,
    SharedAutoDeleteState,
    SharedAutoDeleteStorage,
} from 'reactApp/modules/sharedAutoDelete/sharedAutoDelete.type';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import type { RootState } from 'reactApp/store';
import type { LoadingState } from 'reactApp/types/commonStates';
import { createSelector } from 'reselect';

const getSharedAutoDeleteState = (state: RootState): SharedAutoDeleteState => state.sharedAutoDelete as SharedAutoDeleteState;

export const getSharedAutoDeleteStorage = createSelector(getSharedAutoDeleteState, (state): SharedAutoDeleteStorage => state['/']);

export const getSharedAutoDeleteIds = createSelector(getSharedAutoDeleteState, (state): string[] => state['/'].childs);
export const getSharedAutoDeleteList = createSelector(getSharedAutoDeleteState, (state) => state.list);
export const getSharedAutoDeleteFiledIds = createSelector(getSharedAutoDeleteList, (list = {}) => {
    return Object.values(list)
        .filter((item) => item.weblinkAutoDelete)
        .map((item) => item.id);
});

export const isAutoDeleteEnabled = createSelector(
    getFeaturePublicAutoDelete,
    getFeatureAbAutoDelete,

    UserSelectors.isCorpUser,
    UserSelectors.isPaidUser,
    (enabled, ab, isCorp, isPaid) => {
        if (!enabled) {
            return false;
        }

        const type = ab?.data;

        return isCorp || (type === 'paid' && isPaid);
    }
);

export const getSharedAutoDeleteItemById = createSelector(
    getSharedAutoDeleteState,
    (state, id): string => id,
    // CLOUDWEB-11253 в хомяке пути могут быть с upperCase, а в SharedLinks бек присылает все в лоу кейс. toLowerCase актуально при переходе из хомяка.
    (state, id): undefined | SharedAutoDeleteFolder => state.list[id?.toLowerCase()]
);

export const getAutoDeleteFoldersInfo = createSelector(
    getSharedAutoDeleteIds,
    getSharedAutoDeleteList,
    (ids, list): IAutoDeleteFoldersInfo => {
        const autoDeleteList = ids.map((id) => list[id.toLowerCase()]);

        // кол-во папок, которые удалятся сегодня
        const deleteTodayCount = autoDeleteList.filter((item) => isToday(item?.weblinkExpires || 0)).length;

        // кол-во папок, которые удалятся в течении недели
        const deleteWeek = autoDeleteList.filter((item) => differenceInDays(item?.weblinkExpires || 0, Date.now()) <= 7);
        const deleteWeekCount = deleteWeek.length;

        if (!deleteWeekCount) {
            return { folderCount: 0 };
        }

        if (deleteWeekCount === 1) {
            return { folderCount: 1, expires: deleteWeek[0]?.weblinkExpires, allFolders: autoDeleteList.length };
        }

        return { folderCount: deleteWeekCount, isToday: deleteTodayCount === deleteWeekCount, allFolders: autoDeleteList.length };
    }
);

export const getSharedAutoDeleteLoadingState = createSelector(getSharedAutoDeleteStorage, (shared) => {
    return {
        isLoading: shared.isLoading,
        isLoaded: shared.isLoaded,
    } as LoadingState;
});
