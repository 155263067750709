const FEATURES_MODAL_DATA = [
    'Больше места для файлов',
    'Никакой рекламы в Почте и Облаке',
    'Загрузка файлов до 100 ГБ',
    'Добавление в подписку до 3 близких',
    'Автоудаление отправленных файлов',
    'Автосохранение файлов с телефона',
    'Онлайн-доступ с любого устройства',
    'Отмена письма в течение 30 секунд',
    'Виджеты Календаря и Задач на виду',
    'Проверка вложений антивирусом',
    'Отдельные папки для рассылок и\u00A0чеков',
    'Приоритетная поддержка',
];

export const getFeaturesModalList = () => FEATURES_MODAL_DATA;
