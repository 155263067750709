import classNames from 'clsx';
import React, { memo, useCallback, useRef, useState } from 'react';
import { AllDocumentsXrayTypes } from 'reactApp/modules/allDocuments/allDocuments.types';
import { useGetAllDocumentsXray } from 'reactApp/sections/AllDocuments/hooks/useGetAllDocumentsXray';
import { logHandler } from 'reactApp/ui/Datalist/DataList.helpers';
import { FileIcon } from 'reactApp/ui/FileIcon/FileIcon';
import { getIconType } from 'reactApp/ui/FileIcon/getIconType';
import { ImageCancelable } from 'reactApp/ui/ImageCancelable/ImageCancelable';

import styles from '../DataListItemThumb.css';
import type { PicFileProps, PicFolderProps } from '../DataListItemThumb.types';

export const Pic = memo(function Pic(props: PicFileProps | PicFolderProps) {
    const iconType = getIconType({
        isFolder: props.isFolder,
        kind: props.isFolder ? props.kind : undefined,
        ext: props.isFolder ? undefined : props.ext.toLowerCase(),
        isVirus: props.isFolder ? false : props.isVirus,
        isAutoDelete: props.isFolder ? props.isAutoDelete : false,
    });
    const sendAllDocumentsXray = useGetAllDocumentsXray('thumb_load');
    const startTime = useRef(Date.now());
    const [isLoaded, setLoaded] = useState<boolean>(false);
    const isLoadingFolder = props.isFolder ? props.isLoading : false;
    const handleThumbLoad = useCallback(() => {
        setLoaded(true);

        logHandler({
            radarName: `thumb_load_success`,
            radars: [`all=${Date.now() - startTime.current}`],
        });
        if (props?.kind) {
            logHandler({
                radarName: `thumb_load_success`,
                radars: [`${props.kind}=${Date.now() - startTime.current}`],
            });
        }

        sendAllDocumentsXray(AllDocumentsXrayTypes.onSuccess);
    }, [props?.kind]);

    const handleThumbError = useCallback(() => {
        logHandler({
            radarName: `thumb_load_fail`,
            radars: [`all=${Date.now() - startTime.current}`],
        });
        if (props?.kind) {
            logHandler({
                radarName: `thumb_load_fail`,
                radars: [`${props.kind}=${Date.now() - startTime.current}`],
            });
        }

        sendAllDocumentsXray(AllDocumentsXrayTypes.onError);
    }, [props?.kind]);

    return (
        <div className={styles.container}>
            {!isLoaded && (
                <div
                    className={classNames({
                        [styles.icon]: true,
                        [styles.loading_folder]: isLoadingFolder,
                    })}
                >
                    <FileIcon type={iconType} size="l" />
                </div>
            )}
            {!props.isFolder && props.thumbUrl?.src && (
                <ImageCancelable
                    className={classNames({
                        [styles.thumb]: true,
                        [styles.thumb_loaded]: isLoaded,
                        [styles.thumb_short]: props.short,
                    })}
                    draggable={false}
                    src={props.thumbUrl?.src}
                    srcSet={props.thumbUrl?.srcSet ? `${props.thumbUrl?.srcSet} 2x` : ''}
                    alt=""
                    onError={handleThumbError}
                    onLoad={handleThumbLoad}
                />
            )}
        </div>
    );
});
