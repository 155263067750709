import classNames from 'clsx';
import { ReactComponent as IconClose } from 'img/close.svg';
import React, { type ReactNode, PureComponent } from 'react';
import { connect } from 'react-redux';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { hideDialog, showDialog } from 'reactApp/modules/dialog/dialog.module';
import { Button } from 'reactApp/ui/Button/Button';
import { sendXray } from 'reactApp/utils/ga';
import { scrollLock, scrollUnlock } from 'reactApp/utils/scrollLock';
import { Loader, Modal } from 'semantic-ui-react';

import styles from './Dialog.css';

let openedDialogCount = 0;
let isElMounted = false;

export type DialogSize = 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen' | 'middle' | 'average' | 'big' | 'larger';

export interface IProps {
    closeIconClassName?: string;
    id?: string; // Используется для data-qa-modal в автотестах
    qaId?: string;
    children: ReactNode | ReactNode[] | null;
    open: boolean;
    accept?: string;
    cancel?: string;
    footer?: ReactNode | null;
    header?: ReactNode | null;
    sidebar?: ReactNode | null;
    img?: ReactNode | null;
    loading?: boolean;
    size?: DialogSize;
    className?: string;
    scrollingContent?: boolean;
    mod?: string;
    closeOnDimmerClick?: boolean;
    closeOnEscape?: boolean;
    dimmer?: boolean;
    closable?: boolean;

    showDialog?(id?: string): void;

    hideDialog?(id?: string): void;

    onAccept?(event?): void;

    onCancel?(event?): void;

    onShow?(): void;

    whiteCloseIcon?: boolean;
    bigCloseIcon?: boolean;
    theme?: '' | 'octavius' | 'dark';
    showCloseIcon?: boolean;
    grayBackground?: boolean;
    disableScrollOnBody?: boolean;
    closeOnDocumentClick?: boolean;
    disableDarkTheme?: boolean;
    isWindowSmall?: boolean;
    isRebrandingQuotaLanding?: boolean;
}

export class DialogComponent extends PureComponent<IProps> {
    public static defaultProps = {
        open: false,
        accept: '',
        cancel: '',
        footer: null,
        header: null,
        loading: false,
        className: '',
        id: '',
        scrollingContent: false,
        mod: '',
        closeOnDimmerClick: true,
        dimmer: true,
        whiteCloseIcon: false,
        grayBackground: false,
        closable: true,
        closeOnEscape: true,
        showCloseIcon: true,
        disableScrollOnBody: true,
        sidebar: null,
        bigCloseIcon: false,
        closeOnDocumentClick: false,
        disableDarkTheme: false,
        isWindowSmall: false,
        isRebrandingQuotaLanding: false,
    };

    el: HTMLElement | null = document.querySelector('#react-modals');

    componentDidMount() {
        const { id, onShow, showDialog, disableScrollOnBody, qaId } = this.props;

        if (!isElMounted) {
            isElMounted = true;
            if (this.el != null) {
                document.body.appendChild(this.el);
                if (disableScrollOnBody) {
                    scrollLock(this.el, { allowTouchMove: true });
                }
            }
        }

        sendXray(['dlgshow', qaId || id || 'noid']);

        openedDialogCount += 1;
        showDialog?.(id);
        onShow?.();
    }

    componentWillUnmount() {
        const { id, hideDialog, disableScrollOnBody } = this.props;

        openedDialogCount -= 1;

        if (openedDialogCount === 0) {
            isElMounted = false;
            if (disableScrollOnBody) {
                scrollUnlock(this.el);
            }
        }

        hideDialog?.(id);
    }

    handleAccept = (event) => {
        this.props.onAccept?.(event);
    };

    handleCancel = (event) => {
        this.props.onCancel?.(event);
    };

    render() {
        const {
            closeIconClassName,
            header,
            loading,
            accept,
            cancel,
            footer,
            sidebar,
            size,
            children,
            open,
            mod,
            dimmer,
            closeOnEscape,
            scrollingContent,
            closeOnDimmerClick,
            id,
            qaId,
            closable,
            showCloseIcon,
            whiteCloseIcon,
            theme,
            grayBackground,
            bigCloseIcon,
            className,
            closeOnDocumentClick,
            disableDarkTheme,
            img = null,
            isWindowSmall,
            isRebrandingQuotaLanding,
        } = this.props;

        let internalLayout = (
            <>
                {img}
                {header && (
                    <Modal.Header
                        className={classNames({
                            [styles.header]: true,
                            [styles.headerSidebar]: !!sidebar,
                            [styles[`header_${theme}`]]: !!theme,
                            // [styles.header_tutoria]: isWindowSmall,
                        })}
                    >
                        {header}
                    </Modal.Header>
                )}
                {loading && <Loader />}
                {children && <Modal.Content scrolling={scrollingContent}>{children}</Modal.Content>}
                {(accept || cancel || footer) && (
                    <Modal.Actions>
                        {accept && (
                            <Button primary onClick={this.handleAccept}>
                                {accept}
                            </Button>
                        )}
                        {cancel && <Button onClick={this.handleCancel}>{cancel}</Button>}
                        {footer}
                    </Modal.Actions>
                )}
            </>
        );

        if (sidebar) {
            internalLayout = (
                <>
                    {sidebar}
                    <div className={styles.main}>{internalLayout}</div>
                </>
            );
        }

        return (
            <Modal
                open={open}
                dimmer={dimmer}
                closeIcon={
                    closable &&
                    showCloseIcon && (
                        <div className={classNames(styles.close, 'closeIcon')} data-qa-id="closeIcon">
                            <IconClose className={classNames(styles.closeIcon, closeIconClassName)} key="closeIcon" />
                        </div>
                    )
                }
                closeOnEscape={closeOnEscape}
                closeOnDimmerClick={closeOnDimmerClick}
                closeOnDocumentClick={closeOnDocumentClick}
                mountNode={this.el}
                // @ts-ignore
                size={size}
                onClose={this.handleCancel}
                className={classNames({
                    [styles.root]: true,
                    [styles[`root_${mod}`]]: mod,
                    [styles.root_sidebar]: !!sidebar,
                    [styles.root_whiteCloseIcon]: whiteCloseIcon,
                    [styles.root_bigCloseIcon]: bigCloseIcon,
                    [styles.root_grayBackground]: grayBackground,
                    [styles[`root_${theme}`]]: !!theme,
                    [className ?? '']: className,
                    [styles.root_responsive]: ENABLE_FULL_RESPONSIVE,
                    [styles.root_onlyWhite]: disableDarkTheme,
                    [styles.root_tutoria]: isWindowSmall,
                    [styles.root_rebrandingQuotaLanding]: isRebrandingQuotaLanding,
                })}
                centered
                data-qa-modal={qaId || id}
            >
                {internalLayout}
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    showDialog: (id) => dispatch(showDialog(id)),
    hideDialog: (id) => dispatch(hideDialog(id)),
});

export const Dialog = connect(null, mapDispatchToProps)(DialogComponent);
