import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { IS_BLOCKED, IS_DOCUMENTS_DOMAIN, IS_FROZEN } from 'reactApp/appHelpers/configHelpers';
import { ALL_DOCUMENTS_DOMAIN_ROUTES } from 'reactApp/modules/allDocuments/allDocuments.constants';
import { getCurrentStorage, selectStatusPage } from 'reactApp/modules/router/router.selectors';
import { searchUpdateStartLocation } from 'reactApp/modules/search/search.module';
import { getSearchEmptyFilters } from 'reactApp/modules/search/search.selectors';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { store } from 'reactApp/store';
import { storages } from 'server/constants/storages';

import { type IProps, WebSearch } from './WebSearch';

export const renderSearchBlock = (target: Element, props: IProps) => {
    ReactDOM.render(
        React.createElement(
            Provider,
            {
                store,
            },
            React.createElement(WebSearch, props)
        ),
        target
    );
};

const openSearchContainer = (open = false) => {
    const classAction = open ? 'add' : 'remove';

    /* Костыль, пока не выкатится фикс - https://jira.vk.team/browse/HOME-14237 */
    document.querySelector('#ph-whiteline')?.classList[classAction]('ph-container_search-extend');
    document.querySelector('.ph-container')?.classList[classAction]('ph-container_search-extend');
    document.querySelector('.ph-searchplaceholder')?.classList[classAction]('ph-searchplaceholder_search-extend');
};

export const useWebSearchProps = (): IProps => {
    const storage = useSelector(getCurrentStorage);
    const { isSearch, isHome, isAttaches } = getStorage(storage);

    const emptyFilters = useSelector(getSearchEmptyFilters);
    const queryParams = useSelector(SettingsSelectors.getQueryParams);
    const hasSearchQuery = Boolean(queryParams?.query);

    const searchOnlyExpanded =
        isSearch ||
        (IS_DOCUMENTS_DOMAIN && hasSearchQuery) ||
        (isHome && emptyFilters) ||
        (isHome && hasSearchQuery) ||
        (isAttaches && hasSearchQuery);

    const [isSearchExpanded, setSearchExpanded] = useState<boolean>(searchOnlyExpanded);
    const toggleSearch = useCallback((toggleState: boolean) => {
        setSearchExpanded(toggleState);
    }, []);

    const isDropdownAvailable = !isAttaches && !IS_DOCUMENTS_DOMAIN;

    return {
        isExpanded: isSearchExpanded,
        toggleSearch,
        onlyExpanded: searchOnlyExpanded,
        dropdownAvailable: isDropdownAvailable,
        storage,
    };
};

export const usePHSearchBlock = () => {
    const { isExpanded, toggleSearch, onlyExpanded, dropdownAvailable, storage } = useWebSearchProps();

    const dispatch = useDispatch();
    const { isAttaches, isStart, isQuotaCleaner, isSubscription } = getStorage(storage);

    const isSelectingMode = useSelector(SelectionsSelectors.isAnyIndexSelected);
    const isErrorPage = useSelector(selectStatusPage);
    const searchAvailable =
        /* !isEbookReader TODO: Нужно достать этот флаг && */ Boolean(storages[storage]?.isSearchAvailable) &&
        !IS_FROZEN &&
        !IS_BLOCKED &&
        !isErrorPage;

    const { pathname: id } = location;
    const openSearch = isExpanded || onlyExpanded;

    useEffect(() => {
        if (!onlyExpanded) {
            toggleSearch(false);
        }
    }, [onlyExpanded, storage, toggleSearch]);

    useEffect(() => {
        openSearchContainer(openSearch);
        dispatch(searchUpdateStartLocation({ id: IS_DOCUMENTS_DOMAIN ? ALL_DOCUMENTS_DOMAIN_ROUTES.document : id, search: '' }));
    }, [openSearch]);

    useEffect(() => {
        (async () => {
            if (!window.__PH) {
                return;
            }

            const searchTarget = await window.__PH?.getSearchPlaceholder?.();

            if (searchTarget && ((!isSelectingMode && searchAvailable && !(isQuotaCleaner || isSubscription)) || isStart)) {
                renderSearchBlock(searchTarget, {
                    toggleSearch,
                    isExpanded,
                    dropdownAvailable,
                    storage,
                    onlyExpanded,
                    inPortalHeader: true,
                });
            }
        })();
    }, [
        isAttaches,
        isQuotaCleaner,
        isExpanded,
        isSelectingMode,
        isStart,
        isSubscription,
        searchAvailable,
        onlyExpanded,
        storage,
        toggleSearch,
    ]);
};
