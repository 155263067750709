import { Icon20WriteOutline } from '@vkontakte/icons';
import React, { type FC, type MouseEvent } from 'react';
import { MenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/MenuButton/MenuButton';

interface Props {
    hint?: string;
    onClick?: (evt: MouseEvent) => void;
}

export const EditMenuButton: FC<Props> = ({ hint = 'Редактировать', onClick }) => {
    return <MenuButton hint={hint} icon={<Icon20WriteOutline />} onClick={onClick} />;
};
