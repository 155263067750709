import { HttpErrorCodes } from 'reactApp/api/HttpErrorCodes';
import { UploadFail } from 'reactApp/modules/uploading/fails/UploadFail';

export class ConnectionFail extends UploadFail {
    radarName = 'fail_{SOURCE}_connection';
    message = 'Разрыв соединения.';
    status = 0;

    constructor(stack: Error, source: string, status = 0) {
        super(stack, source);

        this.status = status;
    }

    isGatewayProblem() {
        return this.status === HttpErrorCodes.GATEWAY_TIMEOUT;
    }

    isBadGateway() {
        return this.status === HttpErrorCodes.BAD_GATEWAY;
    }

    isServerError() {
        return this.status === HttpErrorCodes.INTERNAL_SERVER_ERROR;
    }

    isRetryableError() {
        return this.isBadGateway() || this.isGatewayProblem() || this.isServerError();
    }
}
