import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { disableDocumentsRecognition } from 'reactApp/modules/personalDocuments/personalDocuments.module';

interface Props {
    onClose?: () => void;
}

export const NewRecognitionPopup = memo<Props>(({ onClose }) => {
    const dispatch = useDispatch();

    const disableRecognition = useCallback(() => {
        dispatch(disableDocumentsRecognition());
        onClose?.();
    }, [dispatch, onClose]);

    return (
        <BaseConfirmDialog
            renderHeader={() => <p style={{ whiteSpace: 'nowrap' }}>Отключить распознавание?</p>}
            successButtonText="Отключить"
            actionButtonText="Не отключать"
            buttons={[EButtonType.accept, EButtonType.action]}
            onSuccess={disableRecognition}
            onAction={onClose}
            onClose={onClose}
            renderContent={() => 'В папке останутся только те документы, которые вы загрузили вручную'}
        />
    );
});

NewRecognitionPopup.displayName = 'NewRecognitionPopup';
