import { seoEnable } from 'reactApp/appHelpers/featuresHelpers/features/seoEnable';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import type { DataListGalleryItemProps } from 'reactApp/ui/DatalistGalleryItem/DataListGalleryItem.types';

export const useGalleryProps = ({
    item,
    isSelected,
    isActive,
    isSelecting,
    handleOnCheckboxClick: onCheckboxClick,
    thumb,
    id,
    onDownload,
    view,
    onFavorites,
    onPublish,
    favoritesAvailable,
    isPopup,
    isPublicDownloaded,
}): DataListGalleryItemProps | undefined => {
    if (item?.isFolder || view !== EViewMode.gallery || !item) {
        return;
    }

    const basicGalleryItemProps = {
        name: item.nameWithoutExt,
        kind: item.kind,
        onCheckboxClick,
        isSelected,
        isActive,
        isSelecting,
        thumbUrl: thumb,
        onDownload,
        id,
        favoritesAvailable,
        isPublicDownloaded,
        isSeoEnabled: seoEnable && item.weblinkSeoEnabled,
    };

    return {
        ...basicGalleryItemProps,
        size: item.size,
        ext: item.ext,
        isInFavorites: item.isInFavorites,
        isPublic: !!item.weblink,
        onPublish,
        onFavorites,
        storage: item.storage,
        isPopup,
    };
};
