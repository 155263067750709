import { Icon24AddOutline } from '@vkontakte/icons';
import { Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { type FC } from 'react';
import { useSelector } from 'react-redux';
import { getDocumentsList } from 'reactApp/modules/personalDocuments/personalDocuments.selectors';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { Item } from 'reactApp/sections/NewPersonalDocuments/Item/Item';

import styles from './List.css';

interface Props {
    hidden: boolean;
}

export const List: FC<Props> = ({ hidden }) => {
    const items = useSelector(getDocumentsList);

    return (
        <div className={styles.wrapper}>
            <div
                className={styles.button}
                onClick={() => {
                    openPopupHelper({ popupName: popupNames.PERSONAL_DOCUMENT_GROUPS });
                }}
                tabIndex={0}
            >
                <div className={styles.icon}>
                    <Icon24AddOutline width={20} height={20} />
                </div>
                <Text className={styles.text}>Добавить документ</Text>
            </div>
            {items.map((item) => (
                <Item className={classNames({ [styles.hiddenItem]: hidden })} key={item.id} {...item} />
            ))}
        </div>
    );
};
