import type { PayloadAction } from '@reduxjs/toolkit';
import { logger } from 'lib/logger';
import { PublicInfoAPICall } from 'reactApp/api/public/PublicInfoAPICall';
import { PublicPinDeleteAPICall } from 'reactApp/api/public/PublicPinDeleteAPICall';
import { PublicPinSetAPICall } from 'reactApp/api/public/PublicPinSetAPICall';
import { PROMO_TARIFFS } from 'reactApp/appHelpers/configHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { PaidInfoSelectors } from 'reactApp/modules/paidInfo/paidInfo.selectors';
import { openTariffBuy } from 'reactApp/modules/payment/payment.module';
import { productsController } from 'reactApp/modules/products/products.controller';
import { getProductById } from 'reactApp/modules/products/products.selectors';
import { updatePublicInfoPin } from 'reactApp/modules/public/public.actions';
import { getPublicRootWeblink } from 'reactApp/modules/public/public.selectors';
import type { IRootWeblink, PublicCheckPin, PublicDeletePin, PublicSetPin } from 'reactApp/modules/public/public.types';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { setIsLoading } from 'reactApp/modules/user/user.actions';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { store as reduxStore } from 'reactApp/store';
import { getPeriodName } from 'reactApp/utils/Period';
import { cancel, put, select } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

const setPublicPinCall = ({ public_id, pin }) => new PublicPinSetAPICall().makeRequest({ public_id, pin });
const deletePublicPinCall = ({ public_id }) => new PublicPinDeleteAPICall().makeRequest({ public_id });
const getPublicInfoCall = ({ public_id, pin }: { public_id: string; pin?: string }) =>
    new PublicInfoAPICall().makeRequest({ public_id, pin });

export function* setPublicPassword(action: PayloadAction<PublicSetPin>) {
    const { pin, public_id, onSuccess, onError } = action.payload;

    try {
        yield call(setPublicPinCall, { pin, public_id });
        yield put(
            showSnackbarAction({
                id: 'public-password-set',
                text: 'Пароль установлен',
                type: SnackbarTypes.success,
                closable: true,
            })
        );
        yield put(updatePublicInfoPin({ pin, public_id }));
        yield call(onSuccess);
    } catch (error) {
        logger.error(error);
        if (onError) {
            yield call(onError);
        }
        yield put(
            showSnackbarAction({
                id: 'public-password-set-failure',
                text: 'Не удалось установить пароль',
                type: SnackbarTypes.failure,
                closable: true,
            })
        );
    }
}

export function* deletePublicPassword(action: PayloadAction<PublicDeletePin>) {
    const { public_id, onSuccess, onRestore } = action.payload;
    const isPaidUser = yield select(UserSelectors.isPaidUser);
    const isCorpUser = yield select(UserSelectors.isCorpUser);

    const isMobile = EnvironmentSelectors.isMobile();

    try {
        yield call(deletePublicPinCall, { public_id });
        yield put(updatePublicInfoPin({ pin: '', public_id }));
        yield call(onSuccess);
        yield put(
            showSnackbarAction({
                id: 'public-password-delete-success',
                text: isMobile ? 'Пароль сброшен' : 'Пароль удалён',
                type: SnackbarTypes.success,
                closable: true,
                ...((isPaidUser || isCorpUser) && { buttonText: 'Отменить' }),
                ...((isPaidUser || isCorpUser) && { onButtonClick: onRestore }),
            })
        );
    } catch (error) {
        logger.error(error);
        yield put(
            showSnackbarAction({
                id: 'public-password-delete-failure',
                text: isMobile ? 'Не удалось сбросить пароль' : 'Не удалось удалить пароль',
                type: SnackbarTypes.failure,
                closable: true,
            })
        );
    }
}

export function* handleCheckPublicPin(action: PayloadAction<PublicCheckPin>) {
    const rootWeblink: IRootWeblink = yield select(getPublicRootWeblink);
    const { pin, onFail, onSuccess } = action.payload;

    try {
        const { data } = yield* call(getPublicInfoCall, { public_id: rootWeblink.weblink, pin });
        const { hidden, pin: password, is_blocked, is_blocked_for } = data || {};

        if (hidden) {
            yield call(onFail, { isBlocked: is_blocked ?? false, blockTimeout: is_blocked_for ?? 0 });
            yield cancel();
        }

        if (password) {
            yield call(onSuccess);
            yield cancel();
        }
    } catch (error) {
        logger.error(error);
    }
}

export function* handleBuyPublicPasswordSubscription() {
    try {
        const isMobile = EnvironmentSelectors.isMobile();

        yield call(productsController.loadProducts);
        // todo: заменить на новую ручку billinginfo
        const userWithoutPayment = yield select(PaidInfoSelectors.isUserWithoutPayment);

        const productId = userWithoutPayment ? PROMO_TARIFFS.publicPasswordTrial : PROMO_TARIFFS.publicPassword;
        const product = yield select(getProductById, productId);

        yield put(
            openTariffBuy({
                productId,
                paySource: 'tooltip_password',
                title: `Подписка Mail Space: пароли для папок и ${product.space.value}`,
                paymentDescription: `+${product.space.space} ${product.space.units} на ${getPeriodName(
                    product.period
                )} и пароли для папок в веб-версии Облака`,
                isMobile,
                onSuccess: () => {
                    reduxStore.dispatch(setIsLoading({ value: true }));
                },
            })
        );
    } catch (error) {
        logger.error(error);
    }
}
