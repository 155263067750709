import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { initProducts } from 'reactApp/modules/products/products.module';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { ETabsName } from 'reactApp/ui/TariffSectionNew/TariffSection.constants';
import { TariffsModalContent } from 'reactApp/ui/TariffsModal/TariffsModalContent';
import { sendDwh } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';

import { useBuyClick } from './hooks/useBuyClick';
import { useSharingPublicThemeTariffs } from './hooks/useSharingPublicThemeTariffs';
import styles from './SharingPublicThemeTariffsModal.css';
export interface SharingPublicThemeTariffsModalProps {
    onBuySuccess: () => void;
    onShow: () => void;
    onClose?: () => void;
    onBuyError?: () => void;
    source?: string;
}

export function SharingPublicThemeTariffsModal({
    source,
    onShow = noop,
    onClose = noop,
    onBuySuccess = noop,
    onBuyError = noop,
}: SharingPublicThemeTariffsModalProps) {
    const id = 'sharing-public-theme-tariffs-modal';
    const dispatch = useDispatch();
    useEffect(() => {
        emitAnalyticEvent(AnalyticEventNames.BRAND_PAGE_TARIFF_PLAN_CHOOSE);
        sendDwh({
            eventCategory: 'brand_page',
            action: 'tariff_plan_choose',
            dwhData: {
                source,
            },
        });
        dispatch(initProducts());
        onShow();
    }, []);
    const { tariffIds, tariffs } = useSharingPublicThemeTariffs();
    const [activeTab, setActiveTab] = useState<ETabsName>(ETabsName.year);
    const onBuyClick = useBuyClick({ source, paySource: `brand_page_${source}`, onSuccess: onBuySuccess, onError: onBuyError });
    return (
        <Dialog id={id} className={styles.root} open dimmer size="big" onCancel={onClose}>
            <TariffsModalContent
                tariffs={tariffs}
                yearTariffsCount={tariffIds.year.length}
                monthTariffsCount={tariffIds.month.length}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                onBuyClick={(product) => {
                    onClose();
                    onBuyClick(product);
                }}
                title="Выбирайте оформление совместных папок с подпиской Mail Space"
                titleClassName={styles.title}
            />
        </Dialog>
    );
}
