import { classNames } from '@vkontakte/vkui';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useIsTabsDesign } from 'reactApp/components/SharingWindow/Sharing.helpers';
import type { IACListItem } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { Loader } from 'reactApp/ui/Loader/Loader';
import { createAvatarUrl } from 'reactApp/utils/avatar';

import { WeblinkUser } from '../User/WeblinkUser';
import styles from './UserList.css';
import type { IProps } from './WeblinkUserList.types';

export const WeblinkUserList = React.memo(function WeblinkUserList({ acList, isLoading, isPhone, item, isReadOnly, isEmbeded }: IProps) {
    const email = useSelector(UserSelectors.getEmail);
    const isTabs = useIsTabsDesign({ item, id: item?.id || '' });

    const owner = email
        ? {
              name: 'Вы',
              email,
              avatarUrl: createAvatarUrl(email),
          }
        : null;

    const renderItem = useCallback(
        (user) => (
            <div className={classNames(styles.user, { [styles.user_embeded]: isEmbeded })} key={user.email}>
                <WeblinkUser
                    isEmbeded={isEmbeded}
                    user={user}
                    item={item}
                    publicId={item?.weblink}
                    isPhone={isPhone}
                    isReadOnly={isReadOnly}
                    isTabs={isTabs}
                />
            </div>
        ),
        [isEmbeded, item, isPhone, isReadOnly, isTabs]
    );

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_phone]: isPhone,
                [styles.root_embeded]: isEmbeded,
            })}
        >
            <div
                className={classNames(styles.usersList, {
                    [styles.usersList_scroll]: acList.length > 2,
                    [styles.usersList_embeded]: isEmbeded,
                })}
            >
                {isLoading ? (
                    <div className={styles.loading}>
                        <Loader centered isModal />
                    </div>
                ) : (
                    <>
                        {Boolean(owner) && (
                            <div className={classNames(styles.user, { [styles.user_embeded]: isEmbeded })}>
                                <WeblinkUser
                                    user={owner as IACListItem}
                                    isPhone={isPhone}
                                    isOwner
                                    isReadOnly={isReadOnly}
                                    isTabs={isTabs}
                                    isEmbeded={isEmbeded}
                                />
                            </div>
                        )}
                        {acList.map((user) => renderItem(user))}
                    </>
                )}
            </div>
        </div>
    );
});
