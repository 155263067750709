import { Icon20HideOutline, Icon20ViewOutline } from '@vkontakte/icons';
import { IconButton } from '@vkontakte/vkui';
import React, { type FC } from 'react';
import { Hint, HintPosition } from 'reactApp/ui/Hint/Hint';

import styles from './BlurButton.css';

interface Props {
    active: boolean;
    onClick: () => void;
}

export const BlurButton: FC<Props> = ({ active, onClick }) => {
    return (
        <Hint
            text={active ? 'Показать превью документов' : 'Скрыть превью документов'}
            delay={500}
            showDelay
            positionStrategy={HintPosition.relativeToCursor}
        >
            <IconButton className={styles.button} onClick={onClick}>
                {active ? <Icon20HideOutline /> : <Icon20ViewOutline />}
            </IconButton>
        </Hint>
    );
};
