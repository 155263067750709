import { useState } from 'react';

import { ESharingType } from '../Sharing.types';

export const useTabsState = () => {
    const [copiedWeblinkOnMount, setCopiedWeblinkOnMount] = useState(false);
    const [fetchedDataOnMount, setFetchedDataOnMount] = useState(false);

    const [activeTab, setActiveTab] = useState<ESharingType>(ESharingType.WEBLINK);

    return {
        copiedWeblinkOnMount,
        fetchedDataOnMount,
        activeTab,
        setCopiedWeblinkOnMount,
        setFetchedDataOnMount,
        setActiveTab,
    };
};
