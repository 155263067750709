import { Icon16LockOutline as LockIcon } from '@vkontakte/icons';
import { Switch } from '@vkontakte/vkui';
import React, { type ChangeEvent, type MouseEvent, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'reactApp/components/SharingWindow/SharingNew/Weblink/DisablePublicDownload/Tooltip/Tooltip';
import { useTooltipShort } from 'reactApp/components/SharingWindow/SharingNew/Weblink/DisablePublicDownload/useTooltipShort';
import styles from 'reactApp/components/SharingWindow/Weblink/SharingWeblink.css';
import { isDocument } from 'reactApp/modules/file/utils';
import type { PublishItem } from 'reactApp/modules/modifying/modifying.types';
import { publicBuyDownloadSubscription, publicDisableDownload } from 'reactApp/modules/public/public.actions';
import { sendDisableDownloadDwh } from 'reactApp/ui/DisableSeoTooltip/sendDisableDownloadDwh.helper';

interface Args {
    item: PublishItem | undefined;
    show: boolean;
    disabled?: boolean;
    forcePromo?: boolean;
    disableDownload?: boolean;
}

export const DisablePublicDownload = memo(({ item, show = false, disabled = true, forcePromo = false, disableDownload = false }: Args) => {
    const dispatch = useDispatch();
    const tooltipRef = useRef<HTMLDivElement | null>(null);
    const [showTooltip, setShowTooltip] = useState(false);

    const handleBuyClick = useCallback(() => {
        if (!item) {
            return;
        }

        dispatch(publicBuyDownloadSubscription());
    }, [item]);

    const { handleShowTip, tooltipShort } = useTooltipShort(handleBuyClick, item?.storage, !disabled);

    const handleClickLock = useCallback(
        (event) => {
            if (!disabled) {
                return;
            }

            handleShowTip(event);

            sendDisableDownloadDwh({ storage: item?.storage, action: 'click_lock', isPaid: !disabled });
        },
        [handleShowTip, disabled]
    );

    const handleSwitchClick = useCallback(
        (event: MouseEvent<HTMLDivElement> | ChangeEvent<HTMLInputElement>) => {
            event.preventDefault();

            event.stopPropagation();
            event.currentTarget.blur();

            if (disabled) {
                handleShowTip(event);

                sendDisableDownloadDwh({ storage: item?.storage, action: 'click', isPaid: false });
                return;
            }

            if (!item) {
                return;
            }

            sendDisableDownloadDwh({ storage: item?.storage, action: 'click', isPaid: true });

            dispatch(publicDisableDownload({ id: item.id, disableDownload: !disableDownload }));
        },
        [disableDownload, disabled, handleShowTip]
    );

    const handleCloseTooltip = useCallback(() => {
        setShowTooltip(false);
    }, [setShowTooltip]);

    useEffect(() => {
        if (!show) {
            return;
        }
        setShowTooltip(forcePromo);
        sendDisableDownloadDwh({ storage: item?.storage, isPaid: !disabled });
        if (forcePromo) {
            sendDisableDownloadDwh({ eventCategory: 'ban-tooltip', storage: item?.storage, isPaid: !disabled });
        }
    }, [show]);

    if (!show) {
        return null;
    }

    return (
        <div className={styles.rightItem} data-qa-item="on-disable-download-change">
            <span className={styles.rightTitle}>Запретить скачивание</span>
            {disabled && !showTooltip && (
                <div className={styles.rightItem_leftIcon} onClick={handleClickLock}>
                    <LockIcon />
                </div>
            )}
            <div className={styles.rightAction} ref={tooltipRef} onClick={handleSwitchClick}>
                <Switch name="disable-download" checked={disableDownload} disabled={disabled} />
            </div>
            {showTooltip && (
                <Tooltip
                    ref={tooltipRef}
                    onClose={handleCloseTooltip}
                    newFeatureText={!disabled}
                    isFolder={Boolean(item?.isFolder)}
                    isDocument={isDocument(item)}
                    onClick={handleBuyClick}
                    storage={item?.storage}
                />
            )}
            {tooltipShort}
        </div>
    );
});

DisablePublicDownload.displayName = 'DisablePublicDownload';
