/**
 * @file Содержит служебные фукнции для наследования через прототипы
 */

type FunctionType = (...args: any[]) => any;

type VideoJSComponentType = {
    new (...args: any[]): any;
    prototype: any;
};

type InferFunctionType<T> = T extends (...args: infer A) => infer R ? (...args: A) => R : unknown;

type InferVideoJsComponentType<T> = T extends {
    new (...args: infer A): infer R;
    prototype: infer R;
}
    ? {
          new (...args: A): R;
          prototype: R;
      }
    : never;

/**
 * Проверяет наличие Reflect.construct
 * @returns Наличие Reflect.construct
 */
function isNativeReflectConstructor(): boolean {
    if (typeof Reflect === 'undefined' || !Reflect.construct) {
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (Reflect.construct?.sham) {
        return false;
    }
    if (typeof Proxy === 'function') {
        return true;
    }
    try {
        Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {}));
        return true;
    } catch (error) {
        return false;
    }
}

/**
 * Проверка на инициализацию this
 * @param self - this, он же объект класса
 * @returns Инициализирован ли this
 */
function assertThisInitialized<Self>(self: Self): Self {
    if (!self) {
        throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }
    return self;
}

/**
 * Возвращает конструктор либо самого объекта, либо его прототипа
 * @param self - Исходный объект
 * @param call - Конструктор прототипа
 * @returns Возможный конструктор
 */
function possibleConstructorReturn<Self, Call>(self: Self, call: Call) {
    if (call && (typeof call === 'object' || typeof call === 'function')) {
        return call;
    }
    return assertThisInitialized(self);
}

/**
 * Эмулирует наследование. Устанавливает цепочку наследования
 * @param subClass - Производный класс
 * @param superClass - Базовый класс
 */
export function _inherits<T extends FunctionType, R extends VideoJSComponentType>(
    subClass: InferFunctionType<T>,
    superClass: InferVideoJsComponentType<R>
) {
    if (typeof superClass !== 'function' && superClass !== null) {
        throw new TypeError('Super expression must either be null or a function');
    }
    subClass.prototype = Object.create(superClass && superClass.prototype, {
        constructor: {
            value: subClass,
            writable: true,
            configurable: true,
        },
    });
    if (superClass) {
        Object.setPrototypeOf(subClass, superClass);
    }
}

/**
 * Создает объект super
 * @param Derived - Производный класс
 * @returns - Объект super
 */
export function _createSuper<Derived>(Derived: InferFunctionType<Derived>) {
    const hasNativeReflectConstruct = isNativeReflectConstructor();
    return function _createSuperInternal<Params>(this: any, ...params: Params[]) {
        const Super = Object.getPrototypeOf(Derived);
        let result;
        if (hasNativeReflectConstruct) {
            const NewTarget = Object.getPrototypeOf(this).constructor;
            result = Reflect.construct(Super, params, NewTarget);
        } else {
            result = Super.apply(this, params);
        }
        return possibleConstructorReturn(this, result);
    };
}

/**
 * Проверяет, что конструктор вызван с new
 * @param instance - Объект
 * @param constructor - Конструктор
 */
export function _classCallCheck<Instance>(instance: Instance, constructor: any) {
    if (!(instance instanceof constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}

/**
 * Создает super для вызова в методах производного класса
 * @param Derived - Объект производного класса
 * @param field - Поле
 * @param receiver - Контекст
 * @returns Значение поля из цепочки наследования
 */
export function _super<Derived extends FunctionType, P extends PropertyKey>(
    Derived: InferFunctionType<Derived>,
    field: P,
    receiver?: unknown
) {
    const protoField = Reflect.get(Object.getPrototypeOf(Derived.prototype), field, receiver);

    if (typeof protoField === 'function') {
        protoField.bind(receiver);
    }

    return protoField;
}
