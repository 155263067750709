import { Spaces } from '@mail/cross-sizes-utils';
import classNames from 'clsx';
import React, { type ReactElement, memo, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { overquotaNewCleared } from 'reactApp/appHelpers/featuresHelpers/features/overquotaNewCleared';
import { overquotaAfterClearTariffId } from 'reactApp/appHelpers/featuresHelpers/features/overquotaPayRestore';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { getUserOverquotaState } from 'reactApp/modules/profile/profile.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import type { RootState } from 'reactApp/store';
import { sendDwhOverquota, sendRecoveryFlowDwh } from 'reactApp/ui/OverquotaPopup/new/OverquotaModal.helpers';
import type { PlaceType } from 'reactApp/ui/OverquotaPopup/new/OverquotaModal.types';
import { Icon } from 'reactApp/ui/OverquotaPopup/newSplash/Icon';
import { Letters } from 'reactApp/ui/OverquotaPopup/newSplash/Letters';
import { Screen } from 'reactApp/ui/Screen/Screen';
import { sendXray } from 'reactApp/utils/ga';

import { Content } from './Content';
import { Footer } from './Footer';
import styles from './OverquotaSplash.css';
import type { Props } from './OverquotaSplash.types';
import { Title } from './Title';

const isMobile = EnvironmentSelectors.isMobile();

export const OverquotaSplash = memo(({ mode, onClose }: Props): ReactElement | null => {
    const { clearTime, state }: ReturnType<typeof getUserOverquotaState> = useSelector(getUserOverquotaState);
    const storage = useSelector(getCurrentStorage);
    const overquota = useSelector(UserQuotaSelectors.getOverQuota);
    const isOverQuota = useSelector(UserQuotaSelectors.isOverquota);

    const productAny = useSelector((state: RootState) => ProductsSelectors.getMonthProductByQuota(state, 128 * Spaces.ONE_GB - 1));
    const productForQuota = useSelector((state: RootState) =>
        ProductsSelectors.getMonthProductByQuota(state, overquota?.space || 128 * Spaces.ONE_GB - 1)
    );
    const product128 = useSelector((state: RootState) => ProductsSelectors.getProductById(state, overquotaAfterClearTariffId));
    const product = product128 ?? productAny;

    const clearNewMod = overquotaNewCleared && mode === 'cleared';

    const place = useMemo(() => `overquota_${mode}` as PlaceType, [mode]);

    const temporaryHide = storage === EStorageType.quotaCleaner && mode === 'full_blocked';

    const onCloseScreen = useCallback(() => {
        onClose();
    }, [onClose]);

    useEffect(() => {
        sendXray(['overquota', 'splashnew', 'show', mode]);

        if (mode === 'after_clear') {
            sendRecoveryFlowDwh({ storage });
        } else if (mode === 'full_blocked') {
            sendDwhOverquota({
                action: 'show',
                storage,
                place: 'onboarding',
                name: 'overquota_splash_blocking',
                source: 'overquota_full_blocked',
            });
        } else {
            sendDwhOverquota({ action: 'show', storage, place });
        }
    }, [mode, clearNewMod]);

    // Закрываем окно в случае некоторых изменений состояния стейта оверквоты
    useEffect(() => {
        if (mode === 'blocked' && state !== 'blocked') {
            onClose?.();
        }
        if (mode === 'after_clear' && (!state || state === 'restore_requested')) {
            onClose?.();
        }
        if (mode === 'full_blocked' && (state !== 'full_blocked' || !isOverQuota)) {
            onClose?.();
        }
    }, [mode, state, onClose, isOverQuota]);

    if (temporaryHide) {
        // Окно показывается через флоу промоконтроллера и после первого показа оно вылетает из очереди промок.
        // Потому на странице чистилки/тарифов не закрываем его, а просто временно прячем, чтобы посл возврата опять показать его
        return null;
    }

    return (
        <Screen
            onClose={onCloseScreen}
            withLogo={false}
            closable={false}
            marginForHeader={!isMobile}
            aboveAll={false}
            className={styles.backgroundPortal}
            contentClassName={classNames({ [styles.screenContentMobile]: isMobile })}
        >
            <div className={classNames(styles.root, { [styles.rootMobile]: isMobile, [styles.rootNewClear]: clearNewMod })}>
                <div className={classNames(styles.content, { [styles.contentBack]: !isMobile })}>
                    <Icon mode={mode} storage={storage} isMobile={isMobile} />
                    <div className={classNames(styles.title, { [styles.title_withMargin]: state !== 'cleared' })}>
                        <Title mode={mode} />
                    </div>
                    <div className={styles.description}>
                        <Content mode={mode} clearTime={clearTime} overquota={overquota} product={product} isMobile={isMobile} />
                    </div>
                    {Boolean(clearNewMod) && <Letters />}
                    <Footer
                        mode={mode}
                        onClose={onClose}
                        storage={storage}
                        place={place}
                        product={product}
                        productForQuota={productForQuota}
                        isMobile={isMobile}
                    />
                </div>
            </div>
        </Screen>
    );
});

OverquotaSplash.displayName = 'OverquotaSplash';
