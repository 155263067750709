import { type PayloadAction, createAction, createReducer } from '@reduxjs/toolkit';
import { extInfo } from 'lib/extInfo';
import { getExtension, getItemNameWithoutExt } from 'reactApp/modules/file/utils';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UrlBuilder } from 'reactApp/modules/urlBuilder/UrlBuilder';
import { normalizeMtime } from 'reactApp/utils/tree.helpers';

import type {
    ILoadIncomingPublicRequest,
    IncomingPublic,
    IncomingPublicItem,
    IncomingPublicState,
    WeblinksIncomingApiResponse,
} from './incomingPublic.types';

const initialState: IncomingPublicState = {
    cursor: '',
    hasMoreToLoad: false,
    isLoaded: false,
    isLoading: false,
    error: false,
    category: null,
    list: {},
};

const urlBuilder = new UrlBuilder();

export const normalizeIncomingPublicItem = (item: IncomingPublic) => {
    const ext = getExtension(item);
    const nameWithoutExt = getItemNameWithoutExt(item.name, ext);
    const { kind, subKind } = extInfo.get(ext);

    const normalizedItem: IncomingPublicItem = {
        id: item.id,
        parent: '/',
        ext,
        nameWithoutExt,
        name: item.name,
        home: '',
        author: item.owner.email,
        isFolder: false,
        kind,
        subKind,
        ctime: normalizeMtime(item.share_time),
        hash: item.id,
        storage: EStorageType.incomingPublic,
        isReadOnly: true,
        weblinkDownloadable: true,
        weblink: item.id,
        url: urlBuilder.getUrls({
            ext,
            isPublic: true,
            id: item.id,
            weblink: item.id,
            size: 0,
            kind,
            subKind,
            isStock: false,
            name: item.name,
            path: '',
        }),
    };

    return normalizedItem;
};

export const loadIncomingPublicRequest = createAction<ILoadIncomingPublicRequest>('incomingPublic/loadRequest');
export const loadMoreIncomingPublicRequest = createAction('incomingPublic/loadMoreRequest');
export const loadIncomingPublicSuccess = createAction<WeblinksIncomingApiResponse>('incomingPublic/loadSuccess');

export const incomingPublicReducer = createReducer(initialState, {
    [loadIncomingPublicRequest.type]: (state, action: PayloadAction<ILoadIncomingPublicRequest>) => {
        state.isLoaded = false;
        state.isLoading = true;
        state.category = action.payload.category;
    },
    [loadIncomingPublicSuccess.type]: (state, action: PayloadAction<WeblinksIncomingApiResponse>) => {
        state.isLoaded = true;
        state.isLoading = false;
        state.cursor = action.payload.cursor || '';

        state.list =
            action.payload.links?.reduce((acc, cur) => {
                const normalizedItem = normalizeIncomingPublicItem(cur);
                acc[normalizedItem.id] = normalizedItem;

                return acc;
            }, {}) ?? {};
    },
});
