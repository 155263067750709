import { type PayloadAction, createAction, createReducer } from '@reduxjs/toolkit';

import { emptyBillingData } from './billing.data';
import { mapBillingDataSubscription, normalizeBillingPaidInfo } from './billingData.helpers';
import type { BillingDataState, GetBillingDataRequestData, UpdateBillingDataAction } from './billingData.types';

const initialState: BillingDataState = {
    activeSubscriptionId: '',
    subscription: {},
    paidInfo: {
        trialExpires: 0,
        paidExpires: 0,
    },
    rawBillingData: emptyBillingData,
    isLoading: false,
    isLoaded: false,
};

export const getBillingDataRequest = createAction('billingData/getBillingDataRequest');
export const getBillingDataSuccess = createAction<GetBillingDataRequestData>('billingData/getBillingDataSuccess');
export const updateBillingDataRequest = createAction<UpdateBillingDataAction>('billingData/updateBillingDataRequest');
export const updateBillingDataSuccess = createAction<GetBillingDataRequestData>('billingData/updateBillingDataSuccess');
export const setActiveSubscriptionId = createAction<string>('billingData/setActiveSubscriptionId');

const handleBillingDataSuccessAction = (
    state: any,
    { payload: { billingData } }: PayloadAction<GetBillingDataRequestData>
): BillingDataState => ({
    ...state,
    subscription: mapBillingDataSubscription(billingData),
    paidInfo: normalizeBillingPaidInfo(billingData),
    rawBillingData: billingData,
    isLoading: false,
    isLoaded: true,
});

export const billingDataReducer = createReducer(initialState, {
    [getBillingDataSuccess.type]: handleBillingDataSuccessAction,
    [updateBillingDataSuccess.type]: handleBillingDataSuccessAction,
    [setActiveSubscriptionId.type]: (state, action: PayloadAction<string>) => {
        state.activeSubscriptionId = action.payload;
    },
    [getBillingDataRequest.type]: (state) => {
        state.isLoading = true;
    },
    [updateBillingDataRequest.type]: (state) => {
        state.isLoading = true;
    },
});
