import { Spacing, Text, Title } from '@vkontakte/vkui';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { renderSharingNew } from 'reactApp/components/SharingWindow/Sharing.helpers';
import { EFrom } from 'reactApp/components/SharingWindow/Sharing.types';
import {
    PublicPasswordEventCategory,
    sendPublicPasswordAnalytics,
} from 'reactApp/components/SharingWindow/SharingNew/Weblink/PublicPassword/PublicPassword.analytics';
import { deletePublicPin, setPublicPin } from 'reactApp/modules/public/public.actions';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { noopVoid } from 'reactApp/utils/helpers';

import styles from './PublicPasswordDeleteModal.css';
import type { PublicPasswordDeleteModalProps } from './PublicPasswordDeleteModal.types';

export const PublicPasswordDeleteModal = memo<PublicPasswordDeleteModalProps>(({ onClose, itemId, publicId, password }) => {
    const dispatch = useDispatch();
    const scrollRef = useRef<HTMLDivElement>(null);
    const storage = useSelector(getCurrentStorage);

    useEffect(() => {
        sendPublicPasswordAnalytics({ eventCategory: PublicPasswordEventCategory.deletePassword, action: 'show_touch' });
    }, []);

    const handleClose = useCallback(() => {
        onClose?.();
        renderSharingNew({
            id: itemId,
            itemStorage: storage,
            from: EFrom.WEBLINK,
        });
    }, [onClose, storage, itemId]);

    const handleKeepPassword = useCallback(() => {
        onClose?.();
        renderSharingNew({
            id: itemId,
            itemStorage: storage,
            from: EFrom.WEBLINK,
        });
        sendPublicPasswordAnalytics({ eventCategory: PublicPasswordEventCategory.deletePassword, action: 'close_touch' });
    }, [onClose, storage, itemId]);

    const handleDeletePassword = useCallback(() => {
        dispatch(
            deletePublicPin({
                public_id: publicId,
                onSuccess: () => {
                    onClose?.();
                },
                onRestore: () => {
                    dispatch(
                        setPublicPin({
                            public_id: publicId,
                            pin: password,
                            onSuccess: noopVoid,
                        })
                    );
                },
            })
        );
        sendPublicPasswordAnalytics({ eventCategory: PublicPasswordEventCategory.deletePassword, action: 'delete_touch' });
    }, [onClose, itemId, storage, publicId, password, dispatch]);

    return (
        <MobileDialog
            id="mobile-public-password-delete"
            onClose={handleClose}
            closeOnDimmerClick
            topmost
            scrollRef={scrollRef}
            mod="mobileV4"
            contextPadding="zero"
        >
            <div ref={scrollRef} className={styles.root}>
                <Title level="2">Сбросить пароль?</Title>
                <Spacing size={8} />
                <Text className={styles.text}>Файлы будут видны всем, у кого есть ссылка</Text>
                <Spacing size={24} />
                <Button theme="vk" error sizeMode={ButtonSizeMode.big} onClick={handleDeletePassword}>
                    Сбросить
                </Button>
                <Spacing size={12} />
                <Button theme="vk" sizeMode={ButtonSizeMode.big} onClick={handleKeepPassword}>
                    Оставить
                </Button>
            </div>
        </MobileDialog>
    );
});

PublicPasswordDeleteModal.displayName = 'PublicPasswordDeleteModal';
