import { litresTermsLink } from 'reactApp/appHelpers/featuresHelpers/features/litres';

export interface IActionDefs {
    title: string;
    mobileTitle?: string;
    text: string;
    conditions: string;
}

export interface ITabs {
    name: string;
    fullName: string;
    id: ETabsName;
    badge?: string;
}

export const ACTIONS_DEFS: Record<string, IActionDefs> = {
    marketingPromo: {
        title: '',
        mobileTitle: '',
        text: '',
        conditions: 'https://promoimages.hb.ru-msk.vkcs.cloud/mailspace_sale.pdf',
    },
    specialDiscounts: {
        title: '',
        text: '',
        conditions: 'https://promoimages.hb.bizmrg.com/cloud_sale.pdf',
    },
    // tempexp_17509-start
    litresPromo: {
        title: '',
        text: '',
        conditions: litresTermsLink,
    },
    // tempexp_17509-end
    // tempexp_17509-start
    autumnPromo: {
        title: '',
        text: '',
        conditions: 'https://vk.cc/cCBra0',
    },
    // tempexp_17509-end
    // tempexp_18060-start
    blackFriday2024: {
        title: '',
        text: '',
        conditions: 'https://vk.cc/cED5sS',
    },
    // tempexp_18060-end
    // tempexp_18474-start
    cyberMonday2025: {
        title: '',
        text: '',
        conditions: 'https://vk.cc/cHnVUa',
    },
    // tempexp_18474-end
};

export enum ETabsName {
    month = 'month',
    year = 'year',
    pro = 'pro',
    gift = 'gift',
    special = 'special',
    usual = 'usual',
}

export const MONTHS_3_TAB = {
    name: 'На 3 месяца ',
    fullName: 'Тарифы на 3 месяца',
    id: ETabsName.month,
};

export const MONTHS_1_TAB = {
    name: 'На месяц',
    fullName: 'Тарифы на месяц',
    id: ETabsName.month,
};

export const TABS: ITabs[] = [
    MONTHS_1_TAB,
    {
        name: 'На год',
        fullName: 'Тарифы на год',
        id: ETabsName.year,
    },
    {
        name: 'Профессионалам',
        fullName: 'Профессионалам',
        id: ETabsName.pro,
        badge: 'новинка',
    },
    {
        name: 'В подарок',
        fullName: 'Подарить Облако',
        id: ETabsName.gift,
    },
];

export const TABS_NEW: ITabs[] = [
    {
        name: 'Тарифы',
        fullName: 'Тарифы',
        id: ETabsName.usual,
    },
    {
        name: 'PRO тарифы',
        fullName: 'PRO тариф',
        id: ETabsName.pro,
    },
    {
        name: 'Спецпредложения',
        fullName: 'Спецпредложения',
        id: ETabsName.special,
    },
];

export const SPECIAL_OFFER = 'Спецпредложение';

export const SPECIAL_TAB = {
    name: SPECIAL_OFFER,
    fullName: SPECIAL_OFFER,
    id: ETabsName.special,
};

export const DISKO_MONTH_PRICE = 149;

// 507 - цена за 3 месяца
export const VK_COMBO_MONTH_PRICE = 169;

export const VK_COMBO_YEAR_FREE_MONTHS_PERIOD = 3;

export const VK_COMBO_FREE_MONTHS_PERIOD = 1;
