import { applicationStart } from 'reactApp/modules/router/router.module';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { setViewerStorage } from 'reactApp/modules/viewer/viewer.module';
import { put, takeEvery } from 'redux-saga/effects';

function* updateViewerStorage() {
    if (/^\/(embedded|test-embedded)(|\/.*)$/.test(window.location.pathname)) {
        yield put(setViewerStorage({ itemStorage: EStorageType.embedded }));
    }
}

export function* watchEmbedded() {
    yield takeEvery(applicationStart.toString(), updateViewerStorage);
}
