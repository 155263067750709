import React, { type ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useMinWidthBreakpoint } from 'reactApp/hooks/responsiveness/useMinWidthBreakpoint';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import type { Product } from 'reactApp/types/Billing';
import type { ETabsName } from 'reactApp/ui/TariffSectionNew/TariffSection.constants';
import { SwiperTariffList } from 'reactApp/ui/TariffsModal/SwiperTariffList/SwiperTariffList';
import { TariffCardLoader } from 'reactApp/ui/TariffsModal/TariffCardLoader/TariffCardLoader';
import { TariffListCard } from 'reactApp/ui/TariffsModal/TariffListCard/TariffListCard';
import type { TariffByModalTab } from 'reactApp/ui/TariffsModal/TariffsModalContent.types';

import styles from './TariffList.css';

interface TariffListProps {
    tariffs: TariffByModalTab[];
    tariffsCount: number;
    activeTab: ETabsName;
    onBuyClick: (product: Product) => void;
}

const smBreackPoint = 768;

export const TariffList = ({ activeTab, tariffs, tariffsCount, onBuyClick }: TariffListProps): ReactElement => {
    const { isLoading, isLoaded } = useSelector(ProductsSelectors.getLifeCycleState);
    const [lgBreakpointHit] = useMinWidthBreakpoint(smBreackPoint);
    const displayInSwiper = !lgBreakpointHit;

    return displayInSwiper ? (
        <SwiperTariffList
            tariffs={tariffs}
            tariffsCount={tariffsCount}
            isLoading={isLoading}
            activeTab={activeTab}
            isLoaded={isLoaded}
            onBuyClick={onBuyClick}
        />
    ) : (
        <div className={styles.root}>
            {isLoaded &&
                tariffs.map((tariff, index) => (
                    <TariffListCard key={index} activeTab={activeTab} tariff={tariff} onBuyClick={onBuyClick} />
                ))}
            {isLoading && Array.from(new Array(tariffsCount)).map((_, idx) => <TariffCardLoader key={idx} />)}
        </div>
    );
};

TariffList.displayName = 'TariffList';
