import videojs from 'video.js';

import { _classCallCheck, _createSuper, _inherits } from '../utils/polyfills';
import type { VideoPlayer } from '../VideoPlayer.types';
import type { ConcreteMenuItemType } from './ConcreteMenuItem';

const VideoJsButtonClass = videojs.getComponent('MenuButton');
const VideoJsMenuClass = videojs.getComponent('Menu');
const VideoJsMenuItem = videojs.getComponent('MenuItem');
const Dom = videojs.dom;

type MenuButtonType = InstanceType<typeof VideoJsButtonClass>;

function capitalizeFirstLetter(string?: string) {
    if (typeof string !== 'string') {
        return string;
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
}

export type ConcreteButtonType = MenuButtonType & {
    hideThreshold_?: number;
    items: ConcreteMenuItemType[];

    setItems: (item: ConcreteMenuItemType[]) => void;
    createItems: () => ConcreteMenuItemType[];
    selected?: (s: boolean) => void;
    createMenu: () => InstanceType<typeof VideoJsMenuClass>;
};

/**
 * Наслеюуем через прототипы из-за babel. См. комментарии к задаче
 * @link https://jira.vk.team/browse/CLOUDWEB-17571
 */
export const ConcreteButton = (function (VideoJsButtonClass) {
    _inherits(ConcreteButton, VideoJsButtonClass);
    const _super = _createSuper(ConcreteButton);
    function ConcreteButton(this: MenuButtonType, player: VideoPlayer, options: videojs.ComponentOptions & { name?: string }): void {
        _classCallCheck(this, ConcreteButton);
        const _this = _super.call(this, player, {
            ...(options || {}),
            ...{
                title: player.localize('Quality'),
            },
        });
        _this.hideThreshold_ = 0;
        _this.items = [];
        return _this;
    }
    return ConcreteButton;
})(VideoJsButtonClass);

ConcreteButton.prototype.setItems = function (items: ConcreteMenuItemType[]) {
    this.items = items;
};

ConcreteButton.prototype.createItems = function (this: ConcreteButtonType): ConcreteMenuItemType[] {
    return this.items as unknown as ConcreteMenuItemType[];
};

ConcreteButton.prototype.createMenu = function (this: ConcreteButtonType) {
    const menu = new VideoJsMenuClass(this.player_, { menuButton: this });

    this.hideThreshold_ = 0;

    // Add a title list item to the top
    if (this.options_.title) {
        const titleEl = Dom.createEl('li', {
            className: 'vjs-menu-title',
            innerHTML: capitalizeFirstLetter(this.options_.title),
            tabIndex: -1,
        }) as HTMLElement;
        const titleComponent = new VideoJsMenuItem(this.player_, { el: titleEl });

        this.hideThreshold_ += 1;

        menu.addItem(titleComponent);
    }

    this.items = this.createItems() as unknown as ConcreteMenuItemType[];

    if (this.items) {
        // Add menu items to the menu
        for (let i = 0; i < this.items.length; i++) {
            menu.addItem(this.items[i]);
        }
    }

    return menu;
};
