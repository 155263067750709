import { Icon20MoreVertical } from '@vkontakte/icons';
import { Spacing, Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { IS_USER_WITH_ONLY_CLOUD } from 'reactApp/appHelpers/configHelpers';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { PaymentUTM } from 'reactApp/constants/paymentUTM';
import { useGoToSubscriptions } from 'reactApp/hooks/useGoToSubscriptions';
import { useSendSpaceMenuAnalytics } from 'reactApp/hooks/UseSendSpaceMenuAnalytics';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { useSendSpaceAnalytics } from 'reactApp/ui/Space/SingleSpace/SingleMenu.analytics';
import { SingleSpaceMenu } from 'reactApp/ui/Space/SingleSpace/SingleSpaceMenu/SingleSpaceMenu';

import { Loader } from './Loader/Loader';
import styles from './SpaceOverquotaSimplified.css';

type SpaceOverquotaSimplifiedProps = {
    isSubscriptionsPage?: boolean;
    isMobile?: boolean;
};

export const SpaceOverquotaSimplified = memo<SpaceOverquotaSimplifiedProps>(({ isSubscriptionsPage, isMobile = false }) => {
    const isOverQuota = useSelector(UserQuotaSelectors.isOverquota);
    const is100PercentFull = useSelector(UserQuotaSelectors.is100PercentFull);
    const { isLoaded } = useSelector(UserQuotaSelectors.getLoadingState);
    const isBizUser = useSelector(UserSelectors.isBizUser);
    const isPaid = useSelector(UserSelectors.isPaidUser);

    const [showMenu, setShowMenu] = useState(false);
    const menuRef = useRef<HTMLDivElement | null>(null);
    const wasShown = useRef<boolean>(false);

    const sendAnalytics = useSendSpaceAnalytics({
        isCountdownPromo: false,
        isMarketingPromo: false,
        skipAnalytics: isBizUser,
    });
    const sendMenuAnalytics = useSendSpaceMenuAnalytics(isMobile);
    const { goToSubscriptions } = useGoToSubscriptions();

    const hideMenuButton = (isSubscriptionsPage && IS_USER_WITH_ONLY_CLOUD) || isBizUser;
    const hideButton = isSubscriptionsPage || isBizUser;

    let titleText = 'Облако почти заполнено';

    if (isOverQuota) {
        titleText = 'Облако переполнено';
    }

    if (is100PercentFull) {
        titleText = 'Облако заполнено';
    }

    const handleShowMenu = useCallback(() => {
        sendMenuAnalytics('menu');
        setShowMenu(true);
    }, [setShowMenu, sendMenuAnalytics]);

    const handleCloseMenu = useCallback(() => {
        setShowMenu(false);
    }, [setShowMenu]);

    const handleSpaceClick = useCallback(() => {
        sendAnalytics('click', false, 'overquota_add_space');
        emitAnalyticEvent(AnalyticEventNames.QUOTA_BLOCK_OVER_CLICK);
        const query = isPaid ? PaymentUTM.spacePaidOverquota : PaymentUTM.spaceFreeOverquota;
        goToSubscriptions({
            isNewTariffsPage: true,
            query: `${query}&utm_term=17991`,
        });
    }, [isPaid, sendAnalytics, goToSubscriptions]);

    useEffect(() => {
        if (isLoaded && !wasShown.current) {
            wasShown.current = true;
            emitAnalyticEvent(AnalyticEventNames.QUOTA_BLOCK_OVER_VIEW);
            sendAnalytics('view', false, 'overquota_add_space');
        }
    }, [isLoaded, wasShown, sendAnalytics]);

    if (!isLoaded) {
        return <Loader withButton={!hideButton} />;
    }

    return (
        <div className={styles.root}>
            <div className={styles.title}>
                <Text className={styles.text}>{titleText}</Text>
                {!hideMenuButton && (
                    <div
                        className={classNames(styles.menu, {
                            [styles.menuActive]: showMenu,
                        })}
                        ref={menuRef}
                        onClick={showMenu ? handleCloseMenu : handleShowMenu}
                    >
                        <Icon20MoreVertical />
                    </div>
                )}
            </div>
            <Spacing size={12} />
            <div className={styles.progress}>
                <div
                    className={classNames(styles.progressFilled, {
                        [styles.progressFull]: isOverQuota || is100PercentFull,
                    })}
                />
            </div>
            {!hideButton && (
                <>
                    <Spacing size={16} />
                    <Button error sizeMode={ButtonSizeMode.small} theme="vk" className={styles.button} onClick={handleSpaceClick}>
                        Увеличить место
                    </Button>
                </>
            )}
            {showMenu && (
                <SingleSpaceMenu
                    isMobile={isMobile}
                    isSubscriptionsPage={isSubscriptionsPage}
                    parentRef={menuRef}
                    onClose={handleCloseMenu}
                    isMySubscription={false}
                />
            )}
        </div>
    );
});

SpaceOverquotaSimplified.displayName = 'SpaceOverquotaSimplified';
