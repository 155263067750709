import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { MB } from 'reactApp/modules/uploading/serviceClasses/helpers';

const checkNotFrame = (() => {
    try {
        return window.self === window.top;
    } catch {
        return false;
    }
})();

export const uploadNewFileApi: boolean | undefined = getFeature('upload-new-file-api') && checkNotFrame;

// showOpenFilePicker не поддерживается в FF Safari
export const uploadNewFileSelectingDialogs = uploadNewFileApi && checkNotFrame && 'showOpenFilePicker' in window;

export const inputType = uploadNewFileSelectingDialogs ? '' : 'file';

export const uploadResumableChunk: boolean | number | undefined = getFeature('upload-resumable-chunk');

export const PUT_MAX_CHUNK = uploadResumableChunk ? (typeof uploadResumableChunk === 'number' ? uploadResumableChunk : 100 * MB) : 0;
