import { sendGaUploaderNew } from 'reactApp/modules/uploading/helpers/uploading.helpers';

async function verifyPermission(fileHandle?: FileSystemFileHandle | null) {
    if (!fileHandle) {
        return true;
    }

    const options = {};
    // Check if permission was already granted. If so, return true.
    if ((await fileHandle.queryPermission(options)) === 'granted') {
        return true;
    }
    // Request permission. If the user grants permission, return true.
    return (await fileHandle.requestPermission(options)) === 'granted';
}

export async function tryGetFileFromHandle(fileHandle?: FileSystemFileHandle | null) {
    try {
        return (await fileHandle?.getFile()) ?? null;
    } catch (error: any) {
        if ((error.name === 'NotAllowedError' || error.name === 'NotFoundError') && (await verifyPermission(fileHandle))) {
            try {
                sendGaUploaderNew(`getfile-after-req-perm-${error.name}`);

                return await fileHandle?.getFile();
            } catch (error2: any) {
                sendGaUploaderNew(`getfile-error-final-${error2.name}`);

                throw error2;
            }
        }

        throw error;
    }
}
