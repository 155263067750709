import { logger } from 'lib/logger';
import { type IDocumentsApiArgs, DocumentsApiCall, DocumentsApiCallV4, SharedIncomingApiCall } from 'reactApp/api/DocumentsApiCall';
import { weblinksIncomingApiCall } from 'reactApp/api/weblinks/WeblinksIncomingApiCall';
import { weblinksLinksApiCall } from 'reactApp/api/weblinks/WeblinksLinksApiCall';
import { IS_ALL_DOC_FEED_V4_ENABLED } from 'reactApp/appHelpers/featuresHelpers/features/alldocumentsFeedV4Enabled';
import {
    allDocumentsRadars,
    normalizeBaseFeedV4Item,
    normalizeBaseWeblinksListItem,
} from 'reactApp/modules/allDocuments/allDocuments.helpers';
import {
    allDocumentsLoadError,
    allDocumentsLoadRequest,
    allDocumentsLoadSuccess,
    allDocumentsMoreRequest,
    changeAllDocumentsCategory,
} from 'reactApp/modules/allDocuments/allDocuments.module';
import {
    getAllDocumentsCategory,
    getAllDocumentsCurrentType,
    getAllDocumentsCursor,
    getDocumentsState,
    getExtensionsForType,
} from 'reactApp/modules/allDocuments/allDocuments.selectors';
import {
    type FeedApiV4Response,
    type WeblinksListApiV4Response,
    AllDocumentsCategoryType,
    AllDocumentsXrayTypes,
    EAllDocumentsType,
} from 'reactApp/modules/allDocuments/allDocuments.types';
import { normalizeBaseFeedItem } from 'reactApp/modules/feed/feed.helpers';
import type { FeedApiResponse, SharedFolderApiResponse } from 'reactApp/modules/feed/feed.types';
import { addHomeItemsToStore } from 'reactApp/modules/home/home.actions';
import type { HomeFile } from 'reactApp/modules/home/home.types';
import { makeNewDocumentError, makeNewDocumentSuccess } from 'reactApp/modules/modifying/modifying.actions';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getLoadMoreLimit } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { sendKaktamLog, sendXray } from 'reactApp/utils/ga';
import { put, takeEvery } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';

import { normalizeIncomingPublicItem } from '../incomingPublic/incomingPublic.module';
import type { WeblinksIncomingApiResponse } from '../incomingPublic/incomingPublic.types';

const LOAD_LIMIT = getLoadMoreLimit(EStorageType.alldocuments);

const getAllDocumentsList = async ({ limit = LOAD_LIMIT, cursor = '', exts = '' }: IDocumentsApiArgs) =>
    (await new DocumentsApiCall().makeRequest({ limit, cursor, exts }))?.data?.body;

const getAllDocumentsListApiV4 = async ({ limit = LOAD_LIMIT, cursor = '', exts = '', ignore_mounts = false }: IDocumentsApiArgs) =>
    (await new DocumentsApiCallV4().makeRequest({ limit, cursor, exts, ignore_mounts }))?.data;

const getMountedFolders = async () => (await new SharedIncomingApiCall().makeRequest())?.data?.body;

const getIncomingDocumentsList = async ({ limit = LOAD_LIMIT, cursor = '', exts = '' }: IDocumentsApiArgs) =>
    (await weblinksIncomingApiCall({ limit, cursor, exts })).data;

function* handleLoadAllDocuments(
    action:
        | ReturnType<typeof allDocumentsLoadRequest>
        | ReturnType<typeof makeNewDocumentSuccess>
        | ReturnType<typeof changeAllDocumentsCategory>
) {
    const currentDocType = yield* select(getAllDocumentsCurrentType);
    const documentType =
        (action.payload && 'docType' in action.payload && action.payload.docType) || currentDocType || EAllDocumentsType.document;
    const extensions = yield* select(getExtensionsForType, documentType);
    const category =
        (action.payload && 'category' in action.payload && action.payload.category) || (yield* select(getAllDocumentsCategory));
    const isIgnoreMountsDocs = category === AllDocumentsCategoryType.onlyYours;

    if (extensions.length === 0) {
        return;
    }

    try {
        // Для фильтра "Вы поделились" запрашиваем личные веблинки и только
        if (category === AllDocumentsCategoryType.youShared) {
            const data = yield* call(weblinksLinksApiCall, { exts: extensions.join(','), limit: LOAD_LIMIT });
            yield* call(handleLoadListApiResponse, {
                documentType,
                dataWeblinksList: data,
                category,
            });
            return;
        }

        if (category === AllDocumentsCategoryType.sharedWithYou) {
            const data = yield* call(getIncomingDocumentsList, { exts: extensions.join(','), limit: LOAD_LIMIT });
            yield* call(handleLoadListApiResponse, {
                documentType,
                dataWeblinksIncomingList: data,
                category,
            });
            return;
        }

        let feedV2Data: FeedApiResponse | undefined;
        let feedV4Data: FeedApiV4Response | undefined;
        if (IS_ALL_DOC_FEED_V4_ENABLED) {
            feedV4Data = yield* call(getAllDocumentsListApiV4, { exts: extensions.join(','), ignore_mounts: isIgnoreMountsDocs });
        } else {
            feedV2Data = yield* call(getAllDocumentsList, { exts: extensions.join(',') });
        }

        const folders = yield* call(getMountedFolders);

        yield* call(handleLoadListApiResponse, {
            documentType,
            dataFeedV2: feedV2Data,
            dataFeedV4: feedV4Data,
            mountedFolders: folders,
            category,
        });
    } catch (error: any) {
        logger.error(error);

        sendXray(['page', 'error', 'alldocs'], documentType);
        sendKaktamLog(error, `page-error-alldocs-${documentType}`);

        yield put(allDocumentsLoadError({ type: documentType, category, error: error.toString() }));
    }
}

function* handleLoadMoreAllDocuments() {
    const documentType = yield* select(getAllDocumentsCurrentType);
    const category = yield* select(getAllDocumentsCategory);
    const extensions = yield* select(getExtensionsForType, documentType);
    const cursor = yield* select(getAllDocumentsCursor);
    const isIgnoreMountsDocs = category === AllDocumentsCategoryType.onlyYours;

    if (extensions.length === 0) {
        return;
    }

    try {
        // Для фильтра "Вы поделились" запрашиваем личные веблинки и только
        if (category === AllDocumentsCategoryType.youShared) {
            const data = yield* call(weblinksLinksApiCall, { exts: extensions.join(','), limit: LOAD_LIMIT, cursor });
            yield* call(handleLoadListApiResponse, {
                documentType: documentType || EAllDocumentsType.document,
                dataWeblinksList: data,
                category,
            });
            return;
        }

        if (category === AllDocumentsCategoryType.sharedWithYou) {
            const data = yield* call(getIncomingDocumentsList, { exts: extensions.join(','), limit: LOAD_LIMIT, cursor });
            yield* call(handleLoadListApiResponse, {
                documentType: documentType || EAllDocumentsType.document,
                dataWeblinksIncomingList: data,
                category,
            });
            return;
        }

        let feedV2Data: FeedApiResponse | undefined;
        let feedV4Data: FeedApiV4Response | undefined;
        if (IS_ALL_DOC_FEED_V4_ENABLED) {
            feedV4Data = yield* call(getAllDocumentsListApiV4, { exts: extensions.join(','), cursor, ignore_mounts: isIgnoreMountsDocs });
        } else {
            feedV2Data = yield* call(getAllDocumentsList, { exts: extensions.join(','), cursor });
        }

        yield* call(handleLoadListApiResponse, {
            documentType: documentType || EAllDocumentsType.document,
            dataFeedV2: feedV2Data,
            dataFeedV4: feedV4Data,
            category,
        });
    } catch (error: any) {
        logger.error(error);
        yield put(allDocumentsLoadError({ type: documentType, category, error: error.toString() }));
    }
}

function* handleLoadListApiResponse({
    dataFeedV2,
    dataFeedV4,
    documentType,
    category,
    dataWeblinksList,
    dataWeblinksIncomingList,
    mountedFolders,
}: {
    documentType: EAllDocumentsType;
    category: AllDocumentsCategoryType;
    dataFeedV2?: FeedApiResponse;
    dataFeedV4?: FeedApiV4Response;
    dataWeblinksList?: WeblinksListApiV4Response;
    dataWeblinksIncomingList?: WeblinksIncomingApiResponse;
    mountedFolders?: SharedFolderApiResponse;
}) {
    const items: HomeFile[] = [];
    const idxs: string[] = [];
    let docsSize = 0;

    dataWeblinksIncomingList?.links?.forEach((apiItem) => {
        const item = {
            ...normalizeIncomingPublicItem(apiItem),
            storage: EStorageType.incomingPublic,
            isMounted: false,
        };

        items.push(item as HomeFile);
        idxs.push(item.id);
    });

    dataWeblinksList?.list?.forEach((apiItem) => {
        const item = {
            ...normalizeBaseWeblinksListItem(apiItem),
            storage: EStorageType.home,
            isMounted: false,
        };
        docsSize += item.size;
        items.push(item as HomeFile);
        idxs.push(item.id);
    });

    dataFeedV4?.list?.forEach((apiItem) => {
        const item = {
            ...normalizeBaseFeedV4Item(apiItem),
            storage: EStorageType.home,
            isMounted: false,
        };
        docsSize += item.size;
        items.push(item as HomeFile);
        idxs.push(item.id);
    });

    dataFeedV2?.objects?.forEach((apiItem) => {
        const item = {
            ...normalizeBaseFeedItem(apiItem),
            storage: EStorageType.home,
            isMounted: false,
        };
        docsSize += item.size;
        items.push(item as HomeFile);
        idxs.push(item.id);
    });

    mountedFolders?.list?.forEach((apiItem) => {
        const item = {
            ...normalizeBaseFeedItem(apiItem),
            storage: EStorageType.home,
            isMounted: true,
            isFolder: true,
            kind: 'mounted',
            isReadOnly: apiItem.access === 'read_only',
        };
        items.push(item as HomeFile);
    });

    if (items.length) {
        yield put(addHomeItemsToStore(items));
    }

    yield put(
        allDocumentsLoadSuccess({
            type: documentType,
            data: dataFeedV4 || dataFeedV2 || dataWeblinksList || dataWeblinksIncomingList,
            idxs,
            docsSize,
            category,
        })
    );
}

/**
 * Генератор для отправки технических метрик для раздела документов.
 *
 * @param {string} action Наименование действия.
 * @param {AllDocumentsXrayTypes} xrayType Тип радара.
 * @param {documentType} [documentType] Кастомный тип документа, который можно прокинуть вручную. По умолчанию используется тип в зависимости от текущего раздела документов.
 */
export function* handleSendAllDocumentsXray({
    action,
    xrayType,
    documentType = null,
}: {
    action: string;
    xrayType: AllDocumentsXrayTypes;
    documentType?: EAllDocumentsType | null;
}) {
    const storage = yield select(getCurrentStorage);

    if (storage === EStorageType.alldocuments) {
        const { currentDocType } = yield select(getDocumentsState);

        const radars = allDocumentsRadars({ section: documentType ?? currentDocType, action });

        yield radars[xrayType]();
    }
}

export function* handleSendCreatedDocumentSuccessXray() {
    const { currentDocType } = yield select(getDocumentsState);

    yield handleSendAllDocumentsXray({
        action: 'create_file',
        xrayType: AllDocumentsXrayTypes.onSuccess,
        documentType: currentDocType,
    });
}

export function* handleSendCreatedDocumentErrorXray() {
    const { currentDocType } = yield select(getDocumentsState);

    yield handleSendAllDocumentsXray({
        action: 'create_file',
        xrayType: AllDocumentsXrayTypes.onError,
        documentType: currentDocType,
    });
}

export function* watchAllDocuments() {
    yield takeEvery(allDocumentsLoadRequest.toString(), handleLoadAllDocuments);
    yield takeEvery(allDocumentsMoreRequest.toString(), handleLoadMoreAllDocuments);
    yield takeEvery(changeAllDocumentsCategory.toString(), handleLoadAllDocuments);
    yield takeEvery(makeNewDocumentSuccess.toString(), handleLoadAllDocuments);
    yield takeEvery(makeNewDocumentSuccess.toString(), handleSendCreatedDocumentSuccessXray);
    yield takeEvery(makeNewDocumentError.toString(), handleSendCreatedDocumentErrorXray);
}
