import { user } from 'Cloud/Application/User';
import classNames from 'clsx';
import { parse } from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import useLocalModal from 'reactApp/hooks/useLocalModal';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { Button } from 'reactApp/ui/Button/Button';
import { UnfreezedDialog } from 'reactApp/ui/FrozenWarning/UnfreezedDialog/UnfreezedDialog';
import { useContent } from 'reactApp/ui/FrozenWarning/useContent';
import opener from 'reactApp/utils/opener';
import { getVerifyNumberUrl } from 'reactApp/utils/urlHelper';

import styles from './FrozenWarning.css';

const { action } = parse(window.location.search, {
    ignoreQueryPrefix: true,
});

export const FrozenWarning: React.FC = () => {
    const dispatch = useDispatch();
    const isVerified = useSelector(UserSelectors.isVerifiedAccount);
    const isPhone = useSelector(EnvironmentSelectors.isPhone);
    const [isShowUnfreezeDialog, showUnfreezeDialog, hideUnfreezeDialog, unfreezePortalElement] = useLocalModal('unfreezeDialog');
    const storage = useSelector(getCurrentStorage);
    const isAttaches = storage === EStorageType.attaches;

    const [noPhone, setNoPhone] = useState(false);
    const [loading, setLoading] = useState(false);

    const { title, buttonText, description } = useContent({ isVerified, noPhone });

    const handleNoPhoneClick = useCallback(() => {
        setNoPhone(true);
    }, []);

    const handleUnFreeze = useCallback(() => {
        setLoading(true);
        user.defrost()
            .then(showUnfreezeDialog)
            .catch(() => {
                dispatch(
                    showSnackbarAction({
                        type: SnackbarTypes.failure,
                        id: 'unfreeze-error',
                        text: 'Произошла ошибка',
                        closable: true,
                    })
                );
            })
            .then(() => {
                setLoading(false);
            });
    }, [dispatch, showUnfreezeDialog]);

    const handleButtonClick = useCallback(() => {
        if (isVerified) {
            handleUnFreeze();
        } else {
            const url = getVerifyNumberUrl();
            opener(url, true);
        }
    }, [isVerified, handleUnFreeze]);

    useEffect(() => {
        if (isVerified && action === 'unfreeze') {
            handleUnFreeze();
        }
    }, [isVerified, handleUnFreeze]);

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_mobile]: isPhone,
            })}
        >
            {isShowUnfreezeDialog &&
                createPortal(
                    <UnfreezedDialog onSuccess={() => window.location.reload()} onClose={hideUnfreezeDialog} />,
                    unfreezePortalElement
                )}
            <div className={classNames(styles.title, { [styles.white_text]: isAttaches })}>{title}</div>
            <div className={classNames(styles.description, { [styles.white_text]: isAttaches })}>{description}</div>
            <div className={styles.button}>
                <Button primary theme="octavius" onClick={handleButtonClick} disabled={loading}>
                    {buttonText}
                </Button>
            </div>
            {!isVerified && !noPhone && (
                <div className={styles.link} onClick={handleNoPhoneClick}>
                    У меня нет телефонного номера
                </div>
            )}
        </div>
    );
};
