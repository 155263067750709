import classNames from 'clsx';
import React from 'react';
import { isPublicPasswordEnabled } from 'reactApp/appHelpers/featuresHelpers/features/publicPassword';
import { Button, ButtonSizeMode, ButtonVariant } from 'reactApp/ui/Button/Button';

import styles from './CloseSharing.css';

interface Props {
    showSharing?: boolean;
    isOwner?: boolean;
    onClick(): void;
}

export const CloseSharing: React.FC<Props> = ({ showSharing, onClick, isOwner }) => {
    const buttonProps = isPublicPasswordEnabled
        ? {
              sizeMode: ButtonSizeMode.small,
          }
        : {
              variant: ButtonVariant.contrast,
          };

    return (
        <Button
            className={classNames(styles.root, {
                [styles.contrast]: buttonProps.variant === ButtonVariant.contrast,
            })}
            {...buttonProps}
            onClick={onClick}
        >
            {showSharing && isOwner ? 'Закрыть доступ для всех' : 'Закрыть доступ'}
        </Button>
    );
};
