/* eslint-disable max-lines */
import '@vkontakte/vkui-tokens/themes/vkCom/cssVars/declarations/onlyVariablesLocal.css';

import { bytesToNDigits } from '@mail/cross-sizes-utils';
import classNames from 'clsx';
import hotkeys, { type KeyHandler } from 'hotkeys-js';
import { isNil } from 'ramda';
import React, { type MouseEvent, type ReactElement, memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { type IViewContext, useViewContext } from 'reactApp/hocs/withViewContext';
import { useHotKeyScope } from 'reactApp/hooks/useHotkeyScope';
import { useSingleAndDoubleClick } from 'reactApp/hooks/useSingleAndDoubleClick';
import { dispatchNewSearchRadar } from 'reactApp/modules/dwh/dwh.module';
import { isAudio, isDocument, isImage, isPdf, isPlainSource } from 'reactApp/modules/file/utils';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { type CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import {
    downloadArchiveItemRequest,
    getArchiveItemPreviewRequest,
    requestArchiveInfo,
    setArchiveCurrentFolder,
    setArchiveItemActive,
} from 'reactApp/modules/viewer/viewer.module';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import type { IArchiveItem } from 'reactApp/modules/viewer/viewer.types';
import type { RootState } from 'reactApp/store';
import { BreadCrumbs } from 'reactApp/ui/Breadcrumbs/Breadcrumbs';
import type { BreadcrumbItem } from 'reactApp/ui/Breadcrumbs/Breadcrumbs.types';
import { DataListItemRow } from 'reactApp/ui/DataListItemRow/DataListItemRow';
import { Empty } from 'reactApp/ui/Empty/Empty';
import { FileIcon } from 'reactApp/ui/FileIcon/FileIcon';
import { getIconType } from 'reactApp/ui/FileIcon/getIconType';
import { FilesCounter } from 'reactApp/ui/FilesCounter/FilesCounter';
import { ImageCancelable } from 'reactApp/ui/ImageCancelable/ImageCancelable';
import { hotKeysArchiveScope } from 'reactApp/ui/ReactViewer/ReactViewer.constants';
import { type IViewerDwhData, getPosterUrl } from 'reactApp/ui/ReactViewer/ReactViewer.helpers';
import { VideoPlayer } from 'reactApp/ui/ReactViewer/VideoPlayer/VideoPlayer';
import { ViewerDoc } from 'reactApp/ui/ReactViewer/ViewerDoc/ViewerDoc';
import { ViewerPdf } from 'reactApp/ui/ReactViewer/ViewerPdf/ViewerPdf';
import { ViewerTxt } from 'reactApp/ui/ReactViewer/ViewerTxt/ViewerTxt';
import { Spinner } from 'reactApp/ui/Spinner/Spinner';
import { noop, noopVoid } from 'reactApp/utils/helpers';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

import styles from './ViewerArchive.css';

interface IProps {
    items: IArchiveItem[];
    foldersCount: number;
    filesCount: number;
    zipName: string;
    error?: string;
    isLoaded: boolean;
    isPhone?: boolean;
    embedded?: boolean;
    enableViewerMenu?: boolean;
    storage?: EStorageType;
    breadcrumbs?: BreadcrumbItem[];
    onDownload?(item: IArchiveItem);
    onSelect?(items: string[]);
    onDblClick?(path: string);
    onActive?(item: IArchiveItem | null);
    getArchiveItems?();
    onParent?();
    onClose?();
    onDelete?();
    onNavigate?(left?: boolean);
    sendRadar?: (data: IViewerDwhData) => void;
    viewContext: IViewContext;
}

interface IClickEventData {
    path: string;
    isFolder?: boolean;
}

export const ViewerArchive = memo(
    // eslint-disable-next-line max-lines-per-function
    ({
        items,
        filesCount,
        foldersCount,
        zipName,
        isLoaded,
        error,
        breadcrumbs = [],
        isPhone = false,
        embedded = false,
        enableViewerMenu,
        onDownload = noopVoid,
        onDblClick = noopVoid,
        onSelect = noopVoid,
        getArchiveItems = noopVoid,
        onActive = noopVoid,
        onParent = noopVoid,
        onNavigate = noopVoid,
        onDelete = noopVoid,
        storage,
        viewContext,
        sendRadar,
    }: IProps): ReactElement | null => {
        const [selectedIdxs, setSelectedIdxs] = useState<string[]>([]);
        const [isPreviewError, setPreviewError] = useState(false);
        const selectedIdx = items.findIndex((item: IArchiveItem) => item.path === selectedIdxs[0]);
        const activeItem = selectedIdx >= 0 ? items[selectedIdx] : null;
        const hasElements = items?.length > 0;
        const isAttaches = storage === EStorageType.attaches || storage === EStorageType.viewerAttaches;
        const dispatch = useDispatch();

        const handleClick = useCallback(
            (event: MouseEvent, data?: IClickEventData) => {
                if (!data?.path) {
                    return;
                }

                if (selectedIdxs[0] === data.path) {
                    setSelectedIdxs([]);
                    onSelect([]);
                } else {
                    setSelectedIdxs([data.path]);
                    onSelect(selectedIdxs);
                }
            },
            [setSelectedIdxs, selectedIdxs, onDblClick, onSelect]
        );

        const handleGoTo = useCallback(
            (event: MouseEvent, data?: IClickEventData) => {
                const { path, isFolder } = data || {};

                if (!path && !isFolder) {
                    return;
                }

                if (isFolder) {
                    if (storage === EStorageType.search && path) {
                        const currentFolderIndex = items.findIndex((item) => item.path === path);
                        const currentFolder = items[currentFolderIndex];
                        dispatch(
                            dispatchNewSearchRadar({
                                dwhData: { eventCategory: ECategoryGa.archiveSearch, action: 'folder-open' },
                                items: [{ file_name: currentFolder?.nameWithoutExt, pos: currentFolderIndex }],
                            })
                        );
                    }
                    onDblClick(path ?? '');
                } else {
                    handleClick(event, { path: path ?? '' });
                }
            },
            [onDblClick, handleClick, storage, items, dispatch]
        );

        const handleDownload = useCallback(
            (ev, item) => {
                ev.stopPropagation();

                onDownload(item);
            },
            [onDownload]
        );

        const handleCheck = useCallback(
            (ev, item) => {
                ev.stopPropagation();

                /// multiselect - пока убираем
                // const idx = selectedIdxs.indexOf(item.path);
                // if (idx >= 0) {
                //     selectedIdxs.splice(idx, 1);
                // } else {
                //     selectedIdxs.push(item.path);
                // }
                // setSelectedIdxs([...selectedIdxs]);
                /// single select
                if (selectedIdxs[0] === item.path) {
                    setSelectedIdxs([]);
                } else {
                    setSelectedIdxs([item.path]);
                }
                /// /

                onSelect(selectedIdxs);
            },
            [setSelectedIdxs, selectedIdxs]
        );

        const { handleSingleOrDblClick } = useSingleAndDoubleClick<IClickEventData>(handleClick, handleGoTo);

        const handleEmptyClick = useCallback(() => {
            setSelectedIdxs([]);
            onSelect([]);
        }, [setSelectedIdxs, selectedIdxs]);

        const handleImageError = useCallback(() => {
            setPreviewError(true);
        }, [setPreviewError]);

        useEffect(() => {
            setPreviewError(false);
        }, [setPreviewError, activeItem?.path]);

        useEffect(() => {
            onActive(activeItem);
        }, [activeItem?.path]);

        useEffect(() => {
            getArchiveItems();
            setSelectedIdxs([]);
        }, [zipName, storage]);

        useHotKeyScope(hotKeysArchiveScope);

        useEffect(() => {
            const onKeySelect: KeyHandler = (e) => {
                e.preventDefault();

                const newIdx = selectedIdx + (e.key === 'ArrowDown' ? 1 : -1);
                if (newIdx >= 0 && newIdx < items.length) {
                    const selectedIds = [items[newIdx].path];
                    setSelectedIdxs(selectedIds);
                    onSelect(selectedIds);
                }
            };
            const onKeyDblClick: KeyHandler = (e) => {
                e.preventDefault();

                if (activeItem?.isFolder) {
                    onDblClick(activeItem.path);
                }
            };
            const onKeyParent: KeyHandler = (e) => {
                e.preventDefault();

                onParent();
            };
            const onKeyNavigate: KeyHandler = (e) => {
                e.preventDefault();

                onNavigate(e.key === 'ArrowLeft');
            };
            const handleOnDelete: KeyHandler = (e) => {
                e.preventDefault();

                onDelete();
            };

            hotkeys('down, up', hotKeysArchiveScope, onKeySelect);
            hotkeys('enter', hotKeysArchiveScope, onKeyDblClick);
            hotkeys('backspace, escape', hotKeysArchiveScope, onKeyParent);
            hotkeys('right, left', hotKeysArchiveScope, onKeyNavigate);
            hotkeys('del, delete', hotKeysArchiveScope, handleOnDelete);

            return () => {
                hotkeys.unbind('down, up', hotKeysArchiveScope, onKeySelect);
                hotkeys.unbind('enter', hotKeysArchiveScope, onKeyDblClick);
                hotkeys.unbind('backspace, escape', hotKeysArchiveScope, onKeyParent);
                hotkeys.unbind('right, left', hotKeysArchiveScope, onKeyNavigate);
                hotkeys.unbind('del, delete', hotKeysArchiveScope, handleOnDelete);
            };
        }, [setSelectedIdxs, selectedIdx, items.length, onSelect, activeItem, onDblClick, onNavigate, items, onParent]);

        const renderStubIcon = (item) => {
            const iconType = getIconType({ isFolder: item.isFolder, ext: item.ext, kind: item.kind });

            return (
                <div className={styles.previewIcon}>
                    <FileIcon size="l" type={iconType} />
                </div>
            );
        };

        // eslint-disable-next-line complexity
        const renderItemPreview = (item) => {
            if (error) {
                return (
                    <div className={classNames(styles.message, { [styles.vkMessage]: viewContext.isVKEmbedded })}>
                        Не удалось получить структуру архива
                    </div>
                );
            }

            if (!item && !hasElements) {
                return null;
            }

            if (!item && hasElements) {
                return (
                    <div className={classNames(styles.message, { [styles.vkMessage]: viewContext.isVKEmbedded })}>
                        Выберите файл в архиве для предпросмотра
                    </div>
                );
            }

            if (item.isEncrypted) {
                return (
                    <div className={classNames(styles.message, { [styles.vkMessage]: viewContext.isVKEmbedded })}>
                        На этот файл установлен пароль
                    </div>
                );
            }

            const showImage = isImage({ kind: item?.kind }) && !isPreviewError;
            const isNonPreviewable = item.previewUrl === null;

            let content;
            let description: ReactElement | false = (
                <div className={styles.description}>
                    <div
                        className={classNames({
                            [styles.name]: true,
                            [styles.name_preview]: showImage,
                            [styles.vkName]: viewContext.isVKEmbedded,
                        })}
                    >
                        {item.name}
                    </div>
                    <div className={classNames(styles.size, { [styles.vkSize]: viewContext.isVKEmbedded })}>
                        {!isNil(item.size) && bytesToNDigits(item.size, 3).value}
                    </div>
                </div>
            );

            if (showImage && !isNonPreviewable) {
                content = (
                    <div className={classNames(styles.imageWrapper)}>
                        <ImageCancelable
                            id={item.previewUrl}
                            key={item.previewUrl}
                            src={item.previewUrl}
                            alt={item.name}
                            className={classNames(styles.image)}
                            onError={handleImageError}
                            showLoader
                        />
                    </div>
                );
            } else if (isPdf(item) && !isNonPreviewable) {
                content = <ViewerPdf url={item.previewUrl} fillParent enableViewerMenu={enableViewerMenu} />;
                description = false;
            } else if (isPlainSource(item) && !isNonPreviewable) {
                content = <ViewerTxt url={item.previewUrl} embedded isArchive enableViewerMenu={enableViewerMenu} />;
                description = false;
            } else if (isDocument(item) && item.previewUrl && isAttaches) {
                content = (
                    <ViewerDoc
                        file={item}
                        attachDocUrl={item.previewUrl}
                        renderError={() => renderStubIcon(item)}
                        embedded
                        isArchive
                        enableViewerMenu={enableViewerMenu}
                    />
                );
                description = false;
            } else if (isAudio(item) && !isNonPreviewable) {
                const isAudioVar = Boolean(isAudio(item));
                const useDefaultContent = !isAudioVar && isAttaches;

                if (item.previewUrl === '') {
                    content = null;
                } else if (!useDefaultContent) {
                    content = (
                        <VideoPlayer
                            nativeUrl={item?.previewUrl}
                            posterUrl={getPosterUrl(item)}
                            kind={item.kind}
                            ext={item.ext}
                            name={item.nameWithoutExt || item.name}
                            size={item?.size}
                            isArchive={true}
                            sendRadar={sendRadar}
                        />
                    );
                    description = false;
                }
            } else {
                content = renderStubIcon(item);
            }

            return (
                <>
                    {content}
                    {description}
                </>
            );
        };

        const renderItem = (item) => {
            return (
                <div
                    onClick={(event) => handleSingleOrDblClick(event, { path: item.path, isFolder: item.isFolder })}
                    data-qa-id={item.path}
                    data-qa-type={item.isFolder ? 'folder' : 'file'}
                    data-qa-name={item.name}
                    key={item.path}
                >
                    <DataListItemRow
                        {...item}
                        storage={EStorageType.archive}
                        onDownload={(event) => handleDownload(event, item)}
                        onCheckboxClick={(event) => handleCheck(event, item)}
                        isActive={activeItem?.path === item.path}
                        isSelected={selectedIdxs.includes(item.path)}
                        isVKEmbedded={viewContext.isVKEmbedded}
                    />
                </div>
            );
        };

        const renderContent = () => {
            if (!isLoaded) {
                return (
                    <div className={styles.spinnerWrapper}>
                        <Spinner />
                    </div>
                );
            }

            return (
                <div className={styles.list} onClick={handleEmptyClick}>
                    {hasElements && items.map(renderItem)}
                    {!hasElements && !error && (
                        <Empty
                            viewMode={viewContext.isVKEmbedded ? EViewMode.vkEmbeddedThumbs : undefined}
                            type={'folder'}
                            storage={EStorageType.home}
                            canUpload={false}
                            id={zipName}
                        />
                    )}
                </div>
            );
        };

        return (
            <div
                className={classNames({
                    'vkui--vkCom--light': viewContext.isVKEmbedded,
                    vkuivkcom_light: viewContext.isVKEmbedded,
                    [styles.root]: true,
                    [styles.root_phone]: isPhone,
                    [styles.root_embedded]: embedded,
                    [styles.root_vk_embedded]: viewContext.isVKEmbedded,
                })}
            >
                <div className={styles.listWrapper}>
                    <div className={styles.top}>
                        <div className={styles.breadcrumbs}>
                            <BreadCrumbs
                                onClick={(event, data) => handleGoTo(event, { path: data?.id, isFolder: true })}
                                breadcrumbs={breadcrumbs}
                                mode="viewer"
                                breadcrumbsCount={2}
                            />
                        </div>
                        <div className={styles.counter}>
                            <FilesCounter
                                className={classNames({
                                    [styles.vkFileCounter]: viewContext.isVKEmbedded,
                                })}
                                folders={foldersCount}
                                files={filesCount}
                                all={filesCount + foldersCount}
                                isLoaded={isLoaded}
                            />
                        </div>
                    </div>
                    {renderContent()}
                </div>
                <div
                    className={classNames(styles.viewWrapper, {
                        [styles.vkEmbeddedViewWrapper]: viewContext.isVKEmbedded,
                    })}
                >
                    {renderItemPreview(activeItem)}
                </div>
            </div>
        );
    }
);

export const ViewerArchiveConnected = memo(
    ({
        file,
        storage: originalStorage,
        sendGa,
        isPhone = false,
        embedded: originalEmbedded = false,
        enableViewerMenu,
        onClose = noop,
        onNavigate = noop,
        onDelete = noop,
        sendRadar = noop,
        renderStub,
        renderContent,
        onOpen,
        onError,
    }: {
        file: CloudItem;
        storage: EStorageType;
        sendGa(action, label);
        isPhone?: boolean;
        embedded?: boolean;
        enableViewerMenu?: boolean;
        onClose?();
        onNavigate?(left?: boolean);
        onDelete?(item: CloudItem);
        sendRadar?: (data: IViewerDwhData) => void;
        renderContent?: (content: ReactElement) => ReactElement;
        renderStub?: () => ReactElement;
        onOpen?: () => void;
        onError?: () => void;
    }): ReactElement | null => {
        const zipName = file?.name;
        const items: IArchiveItem[] = useSelector(ViewerSelectors.getCurrentFolderArchiveItems);
        const breadcrumbs = useSelector((state: RootState) => ViewerSelectors.getArchiveBreadcrumbs(state, zipName));
        const error = useSelector(ViewerSelectors.getArchiveError);
        const isLoaded = useSelector(ViewerSelectors.getArchiveIsLoaded);
        const count = useSelector(ViewerSelectors.getArchiveCount);
        const ext = (file && 'ext' in file && file?.ext) || '';
        const dispatch = useDispatch();
        const viewContext = useViewContext();
        const embedded = originalEmbedded || viewContext.isVKEmbedded;
        let storage = originalStorage;

        if (viewContext.isVKEmbedded) {
            storage ||= EStorageType.embedded;
        }

        const id = storage === EStorageType.public ? file['weblink'] : file.id;

        useEffect(() => {
            if (!isLoaded) {
                return;
            }
            if (error) {
                return onError?.();
            }
            onOpen?.();
        }, [error, isLoaded, onOpen, onError, viewContext.isVKEmbedded]);

        const getArchiveItems = useCallback(() => {
            if (!storage) {
                return;
            }

            if (id) {
                dispatch(requestArchiveInfo({ storage, id, ext }));
            }
        }, [storage, id, ext]);

        const handleOnActive = useCallback(
            (item) => {
                if (item) {
                    sendGa('archive', 'preview');
                }

                dispatch(setArchiveItemActive(item?.path));

                if (!item || item.previewUrl || item.isFolder || item.isEncrypted) {
                    return;
                }

                if (!isPhone && id) {
                    dispatch(
                        getArchiveItemPreviewRequest({
                            storage,
                            archiveId: storage === EStorageType.attaches ? `${id}/${zipName}` : id,
                            itemId: item.path,
                        })
                    );
                }
            },
            [storage, isPhone, id, zipName]
        );

        const handleDblClick = useCallback((path: string) => {
            sendGa('archive', 'navigate');

            dispatch(setArchiveCurrentFolder(path?.replace(/^\//, '')));
        }, []);

        const handleGoParentFolder = useCallback(() => {
            if (breadcrumbs.length > 1) {
                dispatch(setArchiveCurrentFolder(breadcrumbs[breadcrumbs.length - 2].id.replace(/^\//, '')));
            } else {
                onClose();
            }
        }, [breadcrumbs, onClose]);

        const handleDownload = useCallback(
            (item: IArchiveItem) => {
                if (item.isEncrypted) {
                    return;
                }

                sendGa('archive', 'filedwnld');

                if (id) {
                    dispatch(downloadArchiveItemRequest({ storage, archiveId: id, itemId: item.path }));
                }
            },
            [id]
        );

        const handleOnDelete = useCallback(() => onDelete(file), [file]);

        if (viewContext.isVKEmbedded && isLoaded && error && renderStub) {
            return renderStub();
        }

        const content = (
            <ViewerArchive
                items={items}
                foldersCount={count?.folders ?? 0}
                filesCount={count?.files ?? 0}
                zipName={zipName}
                isLoaded={isLoaded}
                error={error}
                breadcrumbs={breadcrumbs}
                getArchiveItems={getArchiveItems}
                onActive={handleOnActive}
                onDblClick={handleDblClick}
                onDownload={handleDownload}
                onParent={handleGoParentFolder}
                isPhone={isPhone}
                embedded={embedded}
                enableViewerMenu={enableViewerMenu}
                storage={storage}
                onNavigate={onNavigate}
                onDelete={handleOnDelete}
                viewContext={viewContext}
                sendRadar={sendRadar}
            />
        );

        if (renderContent) {
            return renderContent(content);
        }

        return content;
    }
);

ViewerArchive.displayName = 'ViewerArchive';
ViewerArchiveConnected.displayName = 'ViewerArchiveConnected';
ViewerArchive.whyDidYouRender = true;
