import { APICallV2Get, APICallV2Post, APICallV4Post } from 'reactApp/api/APICall';
import type { FeedApiV4Response } from 'reactApp/modules/allDocuments/allDocuments.types';
import type { FeedApiResponse, SharedFolderApiResponse } from 'reactApp/modules/feed/feed.types';

export interface IDocumentsApiArgs {
    limit?: number;
    cursor?: string;
    exts?: string;
    ignore_mounts?: boolean;
}

export class DocumentsApiCall extends APICallV2Post<{ body: FeedApiResponse }> {
    _method = 'feed';
}

export class DocumentsApiCallV4 extends APICallV4Post<FeedApiV4Response> {
    _method = 'private/feed';
}

export class SharedIncomingApiCall extends APICallV2Get<{ body: SharedFolderApiResponse }> {
    _method = 'folder/shared/incoming';
}
