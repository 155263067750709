import classNames from 'clsx';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ThemeOutsourceSelectors } from 'reactApp/modules/theme.outsource/theme.outsource.selectors';

import styles from './ThemePreview.css';

export interface ThemePreviewProps {
    className?: string;
    isSharing?: boolean;
}

const customThemePreviews = {
    default: '#F3F3F5',
    t5000: '#000',
};

export function ThemePreview({ themeId, className, isSharing = false }: ThemePreviewProps & { themeId: string }) {
    const themeModels = useSelector(ThemeOutsourceSelectors.getAvailableThemes);
    const themeModel = themeModels[themeId] as { preview?: string; colorized?: string };
    const { colorized, preview } = themeModel;
    const colorizedExt = !isSharing && (colorized || customThemePreviews[themeId]);
    const inlineStyle = useMemo<React.CSSProperties>(
        () => (colorizedExt ? { backgroundColor: `${colorizedExt}` } : preview ? { backgroundImage: `url(${preview})` } : {}),
        [colorizedExt, preview]
    );
    return <div className={classNames(className, styles.root)} style={inlineStyle} />;
}
