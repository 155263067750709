import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_ANDROID_MOBILE, IS_IOS_MOBILE, IS_MOBILE_BROWSER } from 'reactApp/appHelpers/configHelpers';

interface IWatchInCloud {
    ios: string[];
    android: string[];
    default: string[];
}

const watchInCloudExt: IWatchInCloud | undefined = getFeature('watch-in-cloud-button') || {};

export const getWatchInCloudButton = () => {
    if (IS_MOBILE_BROWSER) {
        if (IS_IOS_MOBILE) {
            return watchInCloudExt?.ios || [];
        } else if (IS_ANDROID_MOBILE) {
            return watchInCloudExt?.android || [];
        }
        return watchInCloudExt?.default || [];
    }
    return [];
};
