import React, { memo } from 'react';
import { overquotaAfterClear } from 'reactApp/appHelpers/featuresHelpers/features/overquotaAfterClear';
import { overquotaNewCleared } from 'reactApp/appHelpers/featuresHelpers/features/overquotaNewCleared';
import { overquotaPayRestore } from 'reactApp/appHelpers/featuresHelpers/features/overquotaPayRestore';
import type { ModeType } from 'reactApp/ui/OverquotaPopup/newSplash/OverquotaSplash.types';

export const Title: React.FC<{ mode?: ModeType }> = memo(({ mode }) => {
    if (mode === 'cleared') {
        return overquotaNewCleared ? <>Файлы в Облаке удалены </> : <>Ваши файлы в Облаке были удалены</>;
    }

    if (mode === 'after_clear' && overquotaAfterClear && overquotaPayRestore) {
        return <>Ваше пространство переполнено</>;
    }

    if (mode === 'full_blocked') {
        return <>Доступ к файлам заблокирован</>;
    }

    return <>Доступ к файлам ограничен</>;
});

Title.displayName = 'Title';
