import { showCentralRewindButtons } from 'reactApp/appHelpers/featuresHelpers/features/centralRewindButtons';
import type { VideoPlayer } from 'reactApp/ui/ReactViewer/VideoPlayer/VideoPlayer.types';

import { addAnimatedBackground } from './components/animatedBG';
import { addCentralNavigation } from './components/centralNavigation';
import { addControlBarShadow, addControlBarSpacer, addControlBarWrapper } from './components/nativeNavigation/nativeNavigation';
import { addRewindScreens } from './components/rewindScreen';
import { Sides } from './constants';
import { hideNav, toggleNav } from './helpers';
import styles from './styles.css';
interface IParams {
    player: VideoPlayer;
    side: Sides;
    count: number;
    goForward: (player: VideoPlayer) => boolean;
    goBackward: (player: VideoPlayer) => boolean;
    showLeft?: (count: number) => void;
    showRight?: (count: number) => void;
    clearCounter?: () => void;
}

function mobileNavigationLogic({ player, side, count, goForward, goBackward, showLeft, showRight, clearCounter }: IParams) {
    if (side === Sides.LEFT) {
        const works = goBackward(player);
        if (works) {
            showLeft?.(count);
            hideNav(player);
        } else {
            clearCounter?.();
        }
    } else if (side === Sides.RIGHT) {
        const works = goForward(player);
        if (works) {
            showRight?.(count);
            hideNav(player);
        } else {
            clearCounter?.();
        }
    } else if (side === Sides.CENTER) {
        hideNav(player);
    }
}

interface CustomNavigationOptions {
    isPhone: boolean;
    isVideo: boolean;
    rewindTime: number;
    goForward: (player: VideoPlayer) => boolean;
    goBackward: (player: VideoPlayer) => boolean;
    stimulateNav: (player: VideoPlayer) => void;
    togglePlay: (player: VideoPlayer) => void;
}

export function addCustomNavigation(player: VideoPlayer, options: CustomNavigationOptions) {
    const { isPhone, isVideo, rewindTime, goForward, goBackward, stimulateNav, togglePlay } = options;

    addControlBarWrapper(player);
    addControlBarShadow(player);

    let showRight, showLeft;
    if (isPhone) {
        addControlBarSpacer(player);
        const animation = addAnimatedBackground(player, rewindTime);
        showRight = animation.showRight;
        showLeft = animation.showLeft;
    }
    if (isVideo) {
        const { showPlayButton, showPauseButton, showReplayButton } = addCentralNavigation({
            player,
            withSeeking: showCentralRewindButtons && !isPhone,
            className: styles.playerCentredNavigation,
            togglePlay: () => {
                stimulateNav(player);
                togglePlay(player);
            },
            seekingForward: () => {
                stimulateNav(player);
                goForward(player);
            },
            seekingBackward: () => {
                stimulateNav(player);
                goBackward(player);
            },
        });

        player.on('ended', showReplayButton);
        player.on('pause', showPlayButton);
        player.on('play', showPauseButton);
        player.on('seeked', () => {
            if (player.ended()) {
                showReplayButton();
            } else if (player.paused()) {
                showPlayButton();
            } else {
                showPauseButton();
            }
        });

        addRewindScreens(
            player,
            () => {
                toggleNav(player);
            },
            (side, count, clearCounter) => {
                if (isPhone) {
                    mobileNavigationLogic({ player, side, count, goForward, goBackward, showLeft, showRight, clearCounter });
                } else {
                    toggleNav(player);
                }
            }
        );
    } else {
        let skipTogglePlay = false;

        addRewindScreens(
            player,
            () => {
                if (player.userActive() || player.paused()) {
                    togglePlay(player);
                    skipTogglePlay = false;
                } else {
                    stimulateNav(player);
                    skipTogglePlay = true;
                }
            },
            (side, count, clearCounter) => {
                if (count === 1 && !skipTogglePlay) {
                    togglePlay(player);
                }
                if (isPhone) {
                    mobileNavigationLogic({ player, side, count, goForward, goBackward, showLeft, showRight, clearCounter });
                }
            }
        );
    }
}
