import browser from 'Cloud/browser';
import { path } from 'ramda';
import {
    IS_BIZ_USER,
    IS_FREE_BIZ_SAAS_USER,
    IS_MOBILE_OR_TABLET_BROWSER,
    IS_ONPREMISE,
    IS_TABLET_BROWSER,
} from 'reactApp/appHelpers/configHelpers';
import { showEditPdfButtonForAttach } from 'reactApp/appHelpers/featuresHelpers/features/showPdfEditButtonForAttach';
import { isFileAvailableForCreateEditableCopy } from 'reactApp/components/CreateCopyOfNoneditableFileModal/helper';
import { EAttachTypes } from 'reactApp/modules/attaches/attaches.types';
import { getEditorsFor } from 'reactApp/modules/editor/editor.selectors';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import {
    getFeatureEditingNoneditableFormats,
    getFeaturePdfEdit,
    getFeatureShowEditBtnForSharedFolder,
    getFeatureShowEditButtonForAttach,
} from 'reactApp/modules/features/features.selectors';
import { getParent, isDocument, isPdf, isPdfEditable } from 'reactApp/modules/file/utils';
import { isReadOnly } from 'reactApp/modules/home/home.selectors';
import { isIntegrationStorage } from 'reactApp/modules/storage/storage.helpers';
import { getItemById, isMountedOrSharedFolder } from 'reactApp/modules/storage/storage.selectors';
import { type CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import type { IStoryFileItem } from 'reactApp/modules/stories/stories.types';
import type { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

import { ViewerSelectors } from './viewer.selectors';

/**
 * Можно ли редактировать итем
 *
 * Проверяет наличие доступных редакторов, тип пользователя (бесплатные бизы редактировать не могут), аттачи и пр
 */
export const selectIsItemEditable = createSelector(
    (state: RootState) => state,
    (_state: RootState, props: { file: IStoryFileItem | CloudItem | null; itemStorage?: EStorageType | null }) => ({
        ...props,
        itemStorage: props.itemStorage || props.file?.storage,
    }),
    // eslint-disable-next-line complexity
    (state: RootState, props: { file: IStoryFileItem | CloudItem | null; itemStorage?: EStorageType | null }) => {
        const { file, itemStorage } = props;
        const editingNoneEditableFormats =
            !!file && !!itemStorage && isFileAvailableForCreateEditableCopy(file) && !(browser.isIpadOs() || IS_TABLET_BROWSER)
                ? getFeatureEditingNoneditableFormats(state, { file, storage: itemStorage })
                : undefined;
        const isEditable =
            getEditorsFor(
                state,
                editingNoneEditableFormats
                    ? {
                          ...file,
                          ext: editingNoneEditableFormats.ext,
                      }
                    : file
            )?.length > 0;

        const isAttaches = itemStorage === EStorageType.attaches || itemStorage === EStorageType.viewerAttaches;
        const isDoc = isDocument(file);
        const isPdfFile = isPdf(file);
        const isPublic = itemStorage === EStorageType.public;
        const isStock = itemStorage === EStorageType.stock;
        const isSearch = itemStorage === EStorageType.search;
        const attachType = file && 'type' in file && file.type;
        const isAttachesCloudStock = isAttaches && attachType === EAttachTypes.cloudStock;
        const isAttachesCloud = isAttaches && attachType === EAttachTypes.cloud;

        // Не показываем кнопку редактирования на аттачах типа cloud и cloud_stock
        const isAttachType = attachType === EAttachTypes.attach;
        const showEditButtonAttachCondition = isAttaches ? isAttachType && getFeatureShowEditButtonForAttach(state) : true;
        const showEditPdfButtonAttachCondition = isAttaches ? isAttachType && showEditPdfButtonForAttach : true;
        const showEditButtonAttach = isPdfFile ? showEditPdfButtonAttachCondition : showEditButtonAttachCondition;

        // Условие показа кнопки редактирования для файлов в шаренных папках
        const sharedFolderState = isMountedOrSharedFolder(state, file);
        const sharedFolderFile = sharedFolderState.isMounted;
        const sharedFolderFileBtnFeature = getFeatureShowEditBtnForSharedFolder(state);

        /**
         * Показ кнопки редактирования на mounted и (public не для владельцев) папках всегда регулируется фичей,
         * а для шареных папок показываем всегда (шареных значит для владельцев показываем всегда)
         */
        const showEditButtonForFileInSharedFolder = sharedFolderFile ? sharedFolderFileBtnFeature : true;

        // B2BCLOUD-416 скрываем кнопку для файлов из поиска, в случае, если они находятся в readonly папке
        const homeFileFolder = getParent(file?.home || '/');
        const isReadonlyHomeFolder = isReadOnly(state, homeFileFolder);
        const showEditButtonInRoFolderFromSearch = IS_BIZ_USER && isSearch ? !isReadonlyHomeFolder : true;
        // Выключаем кнопку на ONPREMISE, если папка readOnly
        const { folderId, isMounted } = isMountedOrSharedFolder(state, file);
        const folder = folderId && getItemById(state, folderId);
        const disableButtonInReadOnlyFolder = isMounted && folder && 'isReadOnly' in folder && folder.isReadOnly && IS_ONPREMISE;

        /**
         * От этого параметра зависит показ кнопки "Редактировать" поэтому отображаем ее, даже когда просмотр файла
         * не поддерживается (отображается заглушка), но этот файл является конвертируемым.
         */
        const allowEdit =
            !isPublic &&
            !isStock &&
            !isIntegrationStorage(itemStorage) &&
            !path(['isReadOnly'], file) &&
            showEditButtonAttach &&
            showEditButtonForFileInSharedFolder &&
            showEditButtonInRoFolderFromSearch &&
            !disableButtonInReadOnlyFolder &&
            !IS_FREE_BIZ_SAAS_USER;

        /**
         * TODO Убрать костыль-проверку на editingNoneEditableFormats, когда будем рефакторить овидиус.
         * Сейчас мы не можем добавить расширение, которое не является документом, например, .txt
         * На данном этапе добавлена эта проверка, чтобы отображать кнопку редактирования для недокументов для дальнейшей конвертации.
         */
        const showEditButton = allowEdit && isEditable && (!!isDoc || !!editingNoneEditableFormats);

        const showPdfEditButton =
            allowEdit &&
            isPdfEditable(file) &&
            getFeaturePdfEdit(state) &&
            !(IS_MOBILE_OR_TABLET_BROWSER || EnvironmentSelectors.isIpadOs());

        return {
            showEditButton,
            showPdfEditButton,

            itemStorage,
            isDoc,
            isAttachType,
            isAttachesCloud,
            isAttachesCloudStock,
            isAttaches,
            isPublic,
            isEditable,

            editingNoneEditableFormats,
            sharedFolderFile,
            sharedFolderFileBtnFeature,
        };
    }
);

/**
 * Можно ли редактировать итем из `viewer` стора
 *
 * Расположен НЕ во `ViewerSelectors`, тк из-за циклических импортов возникает тайперрор
 *
 * @see selectIsItemEditable
 */
export const selectIsViewerItemEditable = createSelector(
    (state: RootState) => state,
    ViewerSelectors.getViewerItem,
    ViewerSelectors.getViewerItemStorage,
    // eslint-disable-next-line complexity
    (
        state,
        file: ReturnType<typeof ViewerSelectors.getViewerItem>,
        viewerItemStorage: ReturnType<typeof ViewerSelectors.getViewerItemStorage>
    ) => {
        const itemStorage = viewerItemStorage ?? file?.storage;

        return selectIsItemEditable(state, { file, itemStorage });
    }
);
