import {
    Icon20CarOutline,
    Icon20CheckShieldOutline,
    Icon20GovernmentOutline,
    Icon20HealthOutline,
    Icon20HorseToyOutline,
    Icon20PaymentCardOutline,
    Icon20PlaneOutline,
    Icon20RectangleInfoOutline,
    Icon20UserSquareOutline,
    Icon28BillSeparatedOutline,
} from '@vkontakte/icons';
import React from 'react';
import { DOCUMENT_ID, EDocumentType } from 'reactApp/sections/PersonalDocuments/PersonalDocuments.constants';

export const PERSONAL_DOCUMENTS_HIDDEN_KEY = 'PERSONAL_DOCUMENTS_HIDDEN_KEY';

export const ITEMS = {
    [DOCUMENT_ID[EDocumentType.PASSPORT]]: {
        icon: <Icon20GovernmentOutline height={20} width={20} />,
        title: 'Паспорт',
        type: EDocumentType.PASSPORT,
    },
    [DOCUMENT_ID[EDocumentType.INTERNATIONAL_PASSPORT]]: {
        icon: <Icon20PlaneOutline height={20} width={20} />,
        title: 'Загранпаспорт',
        type: EDocumentType.INTERNATIONAL_PASSPORT,
    },
    [DOCUMENT_ID[EDocumentType.CERTIFICATE]]: {
        icon: <Icon20RectangleInfoOutline height={20} width={20} />,
        title: 'Свидетельство',
        type: EDocumentType.CERTIFICATE,
    },
    [DOCUMENT_ID[EDocumentType.BIRTH_CERTIFICATE]]: {
        icon: <Icon20HorseToyOutline height={20} width={20} />,
        title: 'Свидетельство о рождении',
        type: EDocumentType.BIRTH_CERTIFICATE,
    },
    [DOCUMENT_ID[EDocumentType.DRIVING_LICENSE]]: {
        icon: <Icon20UserSquareOutline height={20} width={20} />,
        title: 'Водительские права',
        type: EDocumentType.DRIVING_LICENSE,
    },
    [DOCUMENT_ID[EDocumentType.VEHICLE_TITLE]]: {
        icon: <Icon20CarOutline height={20} width={20} />,
        title: 'ПТС',
        type: EDocumentType.VEHICLE_TITLE,
    },
    [DOCUMENT_ID[EDocumentType.VEHICLE_REGISTRATION_DOCUMENT]]: {
        icon: <Icon20RectangleInfoOutline height={20} width={20} />,
        title: 'Регистрация ТС',
        type: EDocumentType.VEHICLE_REGISTRATION_DOCUMENT,
    },
    [DOCUMENT_ID[EDocumentType.HEALTH_INSURANCE_POLICY]]: {
        icon: <Icon20HealthOutline height={20} width={20} />,
        title: 'Медицинский полис',
        type: EDocumentType.HEALTH_INSURANCE_POLICY,
    },
    [DOCUMENT_ID[EDocumentType.TAXPAYER_IDENTIFICATION_NUMBER]]: {
        icon: <Icon20RectangleInfoOutline height={20} width={20} />,
        title: 'ИНН',
        type: EDocumentType.TAXPAYER_IDENTIFICATION_NUMBER,
    },
    [DOCUMENT_ID[EDocumentType.INDIVIDUAL_INSURANCE_ACCOUNT_NUMBER]]: {
        icon: <Icon20CheckShieldOutline height={20} width={20} />,
        title: 'СНИЛС',
        type: EDocumentType.INDIVIDUAL_INSURANCE_ACCOUNT_NUMBER,
    },
    [DOCUMENT_ID[EDocumentType.BANK_CARD]]: {
        icon: <Icon20PaymentCardOutline height={20} width={20} />,
        title: 'Банковская карта',
        type: EDocumentType.BANK_CARD,
    },
    [DOCUMENT_ID[EDocumentType.INVOICE]]: {
        icon: <Icon28BillSeparatedOutline height={20} width={20} />,
        title: 'Чек',
        type: EDocumentType.INVOICE,
    },
};

export const GROUPED_ITEMS = [
    {
        name: 'main',
        title: 'Личный документ',
        childs: [
            ITEMS[DOCUMENT_ID[EDocumentType.PASSPORT]],
            ITEMS[DOCUMENT_ID[EDocumentType.INTERNATIONAL_PASSPORT]],
            ITEMS[DOCUMENT_ID[EDocumentType.CERTIFICATE]],
            ITEMS[DOCUMENT_ID[EDocumentType.BIRTH_CERTIFICATE]],
            ITEMS[DOCUMENT_ID[EDocumentType.TAXPAYER_IDENTIFICATION_NUMBER]],
            ITEMS[DOCUMENT_ID[EDocumentType.INDIVIDUAL_INSURANCE_ACCOUNT_NUMBER]],
        ],
    },
    {
        name: 'transport',
        title: 'Транспорт',
        childs: [
            ITEMS[DOCUMENT_ID[EDocumentType.DRIVING_LICENSE]],
            ITEMS[DOCUMENT_ID[EDocumentType.VEHICLE_TITLE]],
            ITEMS[DOCUMENT_ID[EDocumentType.VEHICLE_REGISTRATION_DOCUMENT]],
        ],
    },
    {
        name: 'health',
        title: 'Здоровье',
        childs: [ITEMS[DOCUMENT_ID[EDocumentType.HEALTH_INSURANCE_POLICY]]],
    },
    {
        name: 'payments',
        title: 'Платежи',
        childs: [ITEMS[DOCUMENT_ID[EDocumentType.BANK_CARD]], ITEMS[DOCUMENT_ID[EDocumentType.INVOICE]]],
    },
];

export const EMPTY_ITEMS = [
    {
        icon: <Icon20GovernmentOutline height={26} width={26} />,
        title: 'Паспорт',
        type: EDocumentType.PASSPORT,
    },
    {
        icon: <Icon20HealthOutline height={26} width={26} />,
        title: 'ОМС',
        type: EDocumentType.HEALTH_INSURANCE_POLICY,
    },
    {
        icon: <Icon20CarOutline height={26} width={26} />,
        title: 'Права',
        type: EDocumentType.DRIVING_LICENSE,
    },
    {
        icon: <Icon20PaymentCardOutline height={26} width={26} />,
        title: 'Карта',
        type: EDocumentType.BANK_CARD,
    },
    {
        icon: <Icon20RectangleInfoOutline height={26} width={26} />,
        title: 'ИНН',
        type: EDocumentType.TAXPAYER_IDENTIFICATION_NUMBER,
    },
];
