export enum OverquotaModalMode {
    enter = 'enter',
    viewer = 'viewer',
}

export type OverquotaModalProps = {
    onClose?: () => void;
    mode?: OverquotaModalMode;
};

export type PlaceType =
    | 'overquota_started'
    | 'overquota_blocked'
    | 'overquota_cleared'
    | 'overquota_after_clear'
    | undefined
    | 'onboarding';
