import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_IOS_MOBILE, IS_MOBILE_BROWSER, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

const mailSpaceBenifitsValue: 'a' | 'b' | 'c' = IS_WEBVIEW && IS_MOBILE_BROWSER && getFeature('mail-space-benifits');
const mailSpaceBenifitsIosValue: 'a' | 'b' | 'c' = IS_WEBVIEW && IS_MOBILE_BROWSER && getFeature('mail-space-benifits-ios');

registerExperiment(
    'mail-space-benifits',
    mailSpaceBenifitsValue,
    [AnalyticEventNames.TARIFF_BUY, AnalyticEventNames.MOBILE_LANDING_SHOWN],
    {
        sendXrayArgs: {
            [AnalyticEventNames.TARIFF_BUY]: ({ paySource }, { eventName }) => ({
                eventName: paySource?.startsWith('modal_benefits') ? 'benefits_buy' : eventName,
            }),
        },
    }
);

export const mailSpaceBenifits = (IS_IOS_MOBILE ? mailSpaceBenifitsIosValue : mailSpaceBenifitsValue) || 'a';
