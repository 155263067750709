import format from 'date-fns/format';
import React, { type FC, type MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import type { AttachesItem } from 'reactApp/modules/attaches/attaches.types';
import { getIsViewerFileVerifiedByKaspersky } from 'reactApp/modules/file/utils';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { NameComponent } from 'reactApp/ui/Name/Name';
import { ProtectedHint } from 'reactApp/ui/ProtectedHint/ProtectedHint';
import { AllAttachesButton } from 'reactApp/ui/ReactViewer/AttachInfo/components/allAttachesButton';
import { getSize } from 'reactApp/utils/sizeHelper';

import styles from './AttachMenuInfo.css';

interface Props {
    file: AttachesItem;
    onClick: (evt: MouseEvent) => void;
    onActive: ({ isActive }: { isActive: boolean }) => void;
}

export const AttachMenuInfo: FC<Props> = ({ file, onClick, onActive }) => {
    const size = getSize(file.size || 0);
    const isViewerFileVerifiedByKaspersky = useSelector(getIsViewerFileVerifiedByKaspersky);

    return (
        <div className={styles.wrapper} onClick={onClick}>
            <div className={styles.header}>
                {file.authorName && (
                    <Hint text={file.authorName} theme="dark" delay={500} showDelay>
                        <div className={styles.author}>{file.authorName}</div>
                    </Hint>
                )}
                {file.mtime && <div>{format(file.mtime, 'dd.MM.yy, HH:mm')}</div>}
            </div>
            <div className={styles.content}>
                {file.subject && (
                    <>
                        <Hint text={file.subject} theme="dark" delay={500} showDelay>
                            <div className={styles.name}>{file.subject}</div>
                        </Hint>
                        <div className={styles.divider}>•</div>
                    </>
                )}
                <Hint text={`${file.nameWithoutExt}.${file.ext}`} theme="dark" delay={500} showDelay>
                    <NameComponent className={styles.file} name={file.nameWithoutExt} extension={file.ext} truncate />
                </Hint>
                {size && <div data-qa-id="attach-info-size">{size}</div>}
                <AllAttachesButton className={styles.allAttachesButton} forceActive={onActive} />
                {isViewerFileVerifiedByKaspersky && <ProtectedHint text="Файл проверен антивирусом" />}
            </div>
        </div>
    );
};
