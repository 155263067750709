import { Text } from '@vkontakte/vkui';
import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { deletePublicPin } from 'reactApp/modules/public/public.actions';

import styles from './DeletePublicPasswordDialog.css';
import type { DeletePublicPasswordDialogProps } from './DeletePublicPasswordDialog.types';

const BUTTONS = [EButtonType.action, EButtonType.cancel];

export const DeletePublicPasswordDialog = memo<DeletePublicPasswordDialogProps>(
    ({ onDelete, onRestore, onClose, onDialogClose, publicId }) => {
        const dispatch = useDispatch();

        const renderHeader = useCallback(() => <>Удалить пароль?</>, []);
        const renderContent = useCallback(() => {
            return <Text className={styles.text}>Доступ к файлу по ссылке больше не&nbsp;будет защищён</Text>;
        }, []);

        const handleClose = useCallback(() => {
            onClose?.();
            onDialogClose?.();
        }, [onClose, onDialogClose]);

        const handleConfirmDeletion = useCallback(() => {
            dispatch(
                deletePublicPin({
                    public_id: publicId,
                    onSuccess: () => {
                        onDelete();
                        handleClose();
                    },
                    onRestore: () => {
                        onRestore();
                    },
                })
            );
        }, [dispatch, publicId, onDelete, handleClose, onRestore]);

        return (
            <BaseConfirmDialog
                className={styles.dialog}
                dataQAId="delete-public-password-confirm"
                renderHeader={renderHeader}
                renderContent={renderContent}
                buttons={BUTTONS}
                actionButtonText="Удалить"
                onAction={handleConfirmDeletion}
                cancelButtonText="Отмена"
                onClose={handleClose}
                isNegative
                buttonTheme="octavius"
            />
        );
    }
);

DeletePublicPasswordDialog.displayName = 'DeletePublicPasswordDialog';
