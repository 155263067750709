import { Icon24DownloadOutline, Icon24InfoCircleOutline } from '@vkontakte/icons';
import React, { type VFC, useCallback, useRef } from 'react';
import { EActions, EStorageType } from 'reactApp/modules/storage/storage.types';
import type { IArchiveItem } from 'reactApp/modules/viewer/viewer.types';
import { renderFileInfoModal } from 'reactApp/ui/Mobile/FileInfoModal/FileInfoModal.helpers';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { ItemInfo } from 'reactApp/ui/Mobile/OptionsModal/ItemInfo/ItemInfo';
import { OptionsBlock } from 'reactApp/ui/Mobile/OptionsModal/OptionsBlock/OptionsBlock';
import styles from 'reactApp/ui/Mobile/OptionsModal/OptionsModal.css';
import type { IOptionItem } from 'reactApp/ui/Mobile/OptionsModal/OptionsModal.types';

interface IOptionsModalArchiveProps {
    item: IArchiveItem;
    storage: EStorageType;
    disableDownload?: boolean;

    onClose: () => void;
    handleDownload: () => void;
}

export const OptionsModalArchive: VFC<IOptionsModalArchiveProps> = ({
    item,
    onClose,
    handleDownload,
    storage,
    disableDownload = false,
}) => {
    const ref = useRef<HTMLDivElement>(null);

    const getItems = useCallback(() => {
        const items: IOptionItem[] = [];

        if (!disableDownload) {
            items.push({
                id: EActions.download,
                text: 'Скачать',
                icon: <Icon24DownloadOutline />,
                onClick: () => handleDownload(),
            });
        }

        items.push({
            id: 'info',
            icon: <Icon24InfoCircleOutline />,
            text: `Информация ${item ? 'о файле' : 'об архиве'}`,
            onClick: () =>
                renderFileInfoModal({
                    id: item.path,
                    storage: EStorageType.archive,
                    isArchiveInfo: !item,
                }),
        });

        return items;
    }, [disableDownload]);

    return (
        <MobileDialog id="mobile-archive-options-modal" onClose={onClose} closeOnDimmerClick mod="base" topmost scrollRef={ref}>
            <div className={styles.root}>
                {item && (
                    <div className={styles.itemInfo}>
                        <ItemInfo
                            isFolder={item.isFolder}
                            name={item.nameWithoutExt || ''}
                            size={item.size}
                            kind={item.kind}
                            storage={storage}
                            isVirus={false}
                            ext={item.ext}
                        />
                    </div>
                )}

                {!item.isEncrypted && (
                    <div className={styles.content} ref={ref}>
                        <div className={styles.option}>
                            <OptionsBlock id="actions" onClose={onClose} items={getItems()} />
                        </div>
                    </div>
                )}
            </div>
        </MobileDialog>
    );
};
