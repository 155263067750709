import React, { type ReactElement, memo, useMemo } from 'react';
import { isFamilySubsInTariff, tariffBuyCustomFeatures, welcomeDefaultTariff } from 'reactApp/appHelpers/featuresHelpers';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';

import styles from './FastCheckoutSidebar.css';
import type { FastCheckoutSidebarQueryParams } from './FastCheckoutSidebar.types';

interface FeaturesListProps {
    customList?: string[];
    productId: string;
}

export const FeaturesList = memo(({ customList, productId }: FeaturesListProps): ReactElement => {
    const {
        cancelSending: cancelSendingFromQuery,
        freeSpace: freeSpaceFromQuery,
        features: featuresFromQuery,
    }: FastCheckoutSidebarQueryParams = getQueryParams();

    const cancelSending = cancelSendingFromQuery === 'true';
    const freeSpace = freeSpaceFromQuery === 'true';

    const queryFeatures = useMemo(() => (featuresFromQuery && tariffBuyCustomFeatures[featuresFromQuery]) || [], [featuresFromQuery]);

    const list = useMemo(() => {
        if (customList?.length) {
            return customList;
        }
        if (queryFeatures?.length) {
            return queryFeatures;
        }
        const autodeleteText = 'Автоудаление папок';
        const cancelSendingText = 'Отмена отправки писем';
        const additionalFeatures = cancelSending ? [cancelSendingText] : [];

        const base = [
            'Без рекламы в\u00A0Почте\u00A0и\u00A0Облаке',
            'Загрузка файлов до 100 ГБ в\u00A0Облако',
            'Отправка файлов до 100 ГБ в\u00A0письмах',
        ];

        // tempexp_17086-start
        if (freeSpace || welcomeDefaultTariff?.tariff === productId) {
            return [...base, 'Делитесь местом с близкими', 'Свободная память в телефоне c автозагрузкой'];
        }
        // tempexp_17086-end

        return isFamilySubsInTariff
            ? [...base, 'Делитесь местом с близкими', ...additionalFeatures, autodeleteText]
            : [...base, ...additionalFeatures, autodeleteText, 'Приоритетная поддержка'];
    }, [customList, productId, cancelSending, freeSpace, queryFeatures]);

    return (
        <ol className={styles.features}>
            {list.map((text, index) => (
                <li key={index}>{text}</li>
            ))}
        </ol>
    );
});

FeaturesList.displayName = 'FeaturesList';
