import classNames from 'clsx';
import React, { type ReactElement, memo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { HOT_KEY_SCOPE_NAME } from 'reactApp/components/SharingWindow/Sharing.constants';
import type { IProps } from 'reactApp/components/SharingWindow/Sharing.types';
import { useHotKeyScope } from 'reactApp/hooks/useHotkeyScope';
import { useIsWindowIntegrationSmall } from 'reactApp/hooks/useIsWindowIntegrationSmall';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import type { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';

import { useIsTabsDesign } from '../Sharing.helpers';
import { SharingContent } from '../SharingContent/SharingContent';
import { SharingContentTabs } from '../SharingContentTabs/SharingContentTabs';
import styles from './SharingNew.css';

export const SharingNewDesktop = memo(function SharingNewDesktop(props: IPropsWithPopup<IProps>): ReactElement {
    const isWindowSmall = useIsWindowIntegrationSmall();

    const { itemStorage, id } = props;

    const isTabsDesign = useIsTabsDesign({ id, itemStorage });

    return (
        <Dialog
            open
            id="sharing-new"
            onCancel={props.onClose}
            closeOnDocumentClick={props.closeOnDocumentClick}
            className={classNames(styles.modal, { [styles.tutoria]: isWindowSmall, [styles.sharingTabs]: isTabsDesign })}
        >
            <Content isModal>{isTabsDesign ? <SharingContentTabs {...props} /> : <SharingContent {...props} />}</Content>
        </Dialog>
    );
});

export const SharingNewPhone = memo(function SharingNewPhone(props: IPropsWithPopup<IProps>): ReactElement {
    const scrollRef = useRef(null);

    const { itemStorage, id } = props;

    const isTabsDesign = useIsTabsDesign({ id, itemStorage });

    return (
        <MobileDialog id="sharing-new" closeOnDimmerClick mod="base" topmost onClose={props.onClose} scrollRef={scrollRef}>
            {isTabsDesign ? (
                <SharingContentTabs {...props} isPhone ref={scrollRef} />
            ) : (
                <SharingContent {...props} isPhone ref={scrollRef} />
            )}
        </MobileDialog>
    );
});

export const SharingNew = memo(function SharingNew(props: IPropsWithPopup<IProps>): ReactElement {
    const isPhone = useSelector(EnvironmentSelectors.isPhone);
    useHotKeyScope(HOT_KEY_SCOPE_NAME);

    return isPhone ? <SharingNewPhone {...props} /> : <SharingNewDesktop {...props} />;
});
