import format from 'date-fns/format';
import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BillingActions } from 'reactApp/modules/billing/billing.module';
import { BillingSelectors } from 'reactApp/modules/billing/billing.selectors';
import { PaymentWindowType } from 'reactApp/modules/payment/payment.analytics';
import type { Product } from 'reactApp/types/Billing';
import { DMR } from 'reactApp/ui/DMRFrame/DMR';
import styles from 'reactApp/ui/MailPopup/Payment/Payment.css';
import { PaymentFooter } from 'reactApp/ui/PaymentFooter/PaymentFooter';
import { defaultDateFormat } from 'reactApp/utils/formatDate';
import { noop } from 'reactApp/utils/helpers';
import { getTrialOrDiscountDateEnd } from 'reactApp/utils/Period';
import { formatPrice } from 'reactApp/utils/priceHelpers';

interface Props {
    product: Product;
    onResize: () => void;
    onCancel: () => void;
    onSuccess: () => void;
    onError: () => void;
}

export const Payment = memo<Props>(({ product, onResize = noop, onCancel = noop, onSuccess = noop, onError = noop }) => {
    const dispatch = useDispatch();

    const { isLoading, hasError } = useSelector(BillingSelectors.getLoadingState);
    const buyLink = useSelector(BillingSelectors.getPaymentLink);

    const handleBuySubscriptions = useCallback(
        (id: string) => {
            dispatch(BillingActions.buySubscription({ id, isQuick: true, source: 'mail-upload', xraySource: 'mail-upload' }));
        },
        [dispatch]
    );

    const handleResetSubscriptions = useCallback(() => {
        dispatch(BillingActions.resetLink());
    }, [dispatch]);

    const DMRform = useMemo(
        () => (
            <DMR
                buySubscription={handleBuySubscriptions}
                resetSubscription={handleResetSubscriptions}
                subscription={product}
                isLoading={isLoading}
                hasError={hasError}
                checkoutLink={buyLink}
                onCancel={onCancel}
                onSuccess={onSuccess}
                onResize={onResize}
                className={styles.dmr}
                qaId="payment-form"
                onError={onError}
                windowType={PaymentWindowType.mail}
            />
        ),
        [buyLink, handleBuySubscriptions, handleResetSubscriptions, hasError, isLoading, onCancel, onError, onResize, onSuccess, product]
    );

    if (!product) {
        return null;
    }

    if (product.hasTrial) {
        return (
            <>
                <div className={styles.paymentTrial}>
                    <div className={styles.paymentHeader}>Введите данные карты</div>
                    <div className={styles.paymentContent}>{DMRform}</div>
                </div>
                <div className={styles.la}>
                    Стоимость тарифа {formatPrice(product.price)}&nbsp;₽, начиная с&nbsp;
                    {format(getTrialOrDiscountDateEnd(product.trialPeriod), defaultDateFormat)}. Подписка продлевается автоматически, её
                    можно отменить в любой момент.
                </div>
            </>
        );
    }

    return (
        <div className={styles.payment}>
            <div className={styles.paymentHeader}>Подписка Mail Space</div>
            {DMRform}
            <PaymentFooter productId={product.id} />
        </div>
    );
});

Payment.displayName = 'Payment';
