import { lazyInit } from '@mail-core/std';
import config from 'Cloud/config';
import classNames from 'clsx';
import throttle from 'lodash.throttle';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { O2Auth } from 'reactApp/api/O2Auth';
import { IS_BIZ_USER, IS_MY_TEAM, IS_PHONE_BROWSER, IS_PUBLIC_OF_OVERQUOTA, USER_EMAIL } from 'reactApp/appHelpers/configHelpers';
import {
    BUGREPORT_API_URL,
    BUGREPORT_BUTTON_ENABLED,
    BUGREPORT_KOTLETT_BUTTON_ENABLED,
} from 'reactApp/appHelpers/featuresHelpers/features/bugreportButton';
import { inputType } from 'reactApp/appHelpers/featuresHelpers/features/uploadNewFileApi';
import { ActionPanelSelectors } from 'reactApp/modules/actionpanel/actionpanel.selectors';
import { addItemsFromCloudToAlbumRequest } from 'reactApp/modules/albums/albums.actions';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { chooseVariant } from 'reactApp/modules/features/features.helpers';
import { getFeatureHideCreateUploadInRoFolders, getFeatureNewEmptyStatesTouch } from 'reactApp/modules/features/features.selectors';
import { isReadOnly } from 'reactApp/modules/home/home.selectors';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorageCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { TUploadTypeClick } from 'reactApp/modules/upload/upload.model';
import {
    setButtonTypeClickAction,
    setIsDragging as setIsDraggingAction,
    setShowInputAction,
    setUploadFromAction,
} from 'reactApp/modules/upload/upload.module';
import { getAllowedMimeTypes, getShowInput, isUploaderVisible } from 'reactApp/modules/upload/upload.selectors';
import { useUploadInputHandlers } from 'reactApp/modules/uploading/hooks/useUploadInputHandlers';
import { showUploadButton } from 'reactApp/modules/uploadList/uploadList.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import type { RootState } from 'reactApp/store';
import { B2BUploadButton } from 'reactApp/ui/UploadDropArea/B2BUploadButton/B2BUploadButton';
import { UploadButton } from 'reactApp/ui/UploadDropArea/Upload/Upload';
import { UploadInput } from 'reactApp/ui/UploadInput/UploadInput';
import { sendDatalistGa } from 'reactApp/utils/datalistGa';
import { sendEmptyStatesAnalitics } from 'reactApp/utils/emptyStatesGa';
import { createGaSender } from 'reactApp/utils/ga';
import { sendNewUserGa } from 'reactApp/utils/newUserGa';

import { KotlettBugreport } from './KotlettBugreport/KotlettBugreport';
import styles from './UploadDropArea.css';

const lazyBugreportButton = lazyInit(() => import('@mail/bugreport').then((module) => module.default));

const auth = new O2Auth({ clientId: config.get('CORS_API_CLIENT_ID'), login: USER_EMAIL });

export const initBugreportButton = async (container: HTMLElement | null) => {
    const accessToken = await auth.getToken();

    if (!accessToken) {
        return;
    }

    const BugreportButton = await lazyBugreportButton();
    BugreportButton.init({
        container,
        accessToken,
        url: BUGREPORT_API_URL,
    });
};

let isOpenVar = false;

export const getOpenState = () => isOpenVar;

export interface IUploadDropAreaProps {
    gaCategory?: string;
    showDropArea?: boolean;
    // tempexp-17235-start
    rightOffset?: number;
    // tempexp-17235-end
    isOwnerInViewMode?: boolean;
}

export const UploadDropArea: React.FC<IUploadDropAreaProps> = ({
    gaCategory = 'upload-accent',
    showDropArea = true,
    rightOffset,
    isOwnerInViewMode,
}) => {
    const dispatch = useDispatch();

    const isVisible = useSelector(isUploaderVisible);
    const showInput = useSelector(getShowInput);
    const isMobile = useSelector(EnvironmentSelectors.isMobile);
    const isNewUser = useSelector(UserSelectors.isNewUser);
    const showUpload = useSelector(showUploadButton);
    const allowedMimeTypes = useSelector((state: RootState) => getAllowedMimeTypes(state)?.join(','));
    const storage = useSelector(getCurrentStorage);
    const folder = useSelector((state: RootState) => getStorageCurrentFolder(state, storage as EStorageType));
    const isReadOnlyFolder = useSelector((state: RootState) => isReadOnly(state, folder?.id));
    const hideCreateUploadInRoFolders = useSelector(getFeatureHideCreateUploadInRoFolders);
    const isActionPanelOpened = useSelector(ActionPanelSelectors.isActionPanelOpened);

    const [isOpen, setIsOpen] = useState(false);
    const [isDrag, setIsDrag] = useState(false);
    const [uploadFolder, setUploadFolder] = useState(false);
    const [forceClick, setForceClick] = useState(false);

    const inputRef = useRef<any>(null);
    const bugreportRef = useRef<HTMLDivElement>(null);
    const sendGa = createGaSender(gaCategory);

    const renderInput = isVisible || showInput;

    const { processDrop } = useUploadInputHandlers({ input: renderInput ? inputRef : null, disableDrop: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const throttledOpenState = useCallback(
        throttle((isDrag) => {
            if (isDrag) {
                setIsOpen(true);
            } else {
                setIsOpen(false);
            }
            isOpenVar = isDrag;
            setIsDrag(isDrag);
            dispatch(setIsDraggingAction(isDrag));
        }, 100),
        []
    );

    const handleDrag = useCallback(
        (e) => {
            if (!e.dataTransfer || !e.dataTransfer.types || !e.dataTransfer.types.includes('Files')) {
                return;
            }
            e.preventDefault();
            e.stopPropagation();

            if (e.type === 'dragleave') {
                throttledOpenState(false);
            } else {
                e.dataTransfer.dropEffect = 'copy';

                throttledOpenState(true);
            }
        },
        [throttledOpenState]
    );

    const handleDrop = useCallback(
        (e) => {
            dispatch(setIsDraggingAction(false));
            isOpenVar = false;
            e?.preventDefault();
            setIsDrag(false);
            setIsOpen(false);
            sendGa('drop', !isOpen ? 'opened' : 'default');

            if (!e.dataTransfer || !e.dataTransfer.types || !e.dataTransfer.types.includes('Files')) {
                return;
            }

            throttledOpenState.cancel();

            processDrop?.(e.dataTransfer);
        },
        [throttledOpenState, processDrop, sendGa, isOpen]
    );

    const handleOnClick = useCallback(() => {
        sendDatalistGa('upload');

        sendEmptyStatesAnalitics({ action: 'download' });

        if (isNewUser) {
            sendNewUserGa('upload', 'drop-area');
        }

        sendGa('click', !isOpen ? 'opened' : 'default');

        dispatch(setUploadFromAction('upload-area'));

        inputRef.current?.click();
    }, [isNewUser, sendGa, dispatch, isOpen]);

    const setButtonType = useCallback(
        (type: TUploadTypeClick) => {
            dispatch(setButtonTypeClickAction(type));
        },
        [dispatch]
    );

    const onFolderClick = useCallback(
        (e) => {
            e.stopPropagation();

            if (IS_PUBLIC_OF_OVERQUOTA) {
                openPopupHelper({
                    popupName: popupNames.NO_SPACE_FOR_OVERQUOTA_USER_MODAL,
                });
                return;
            }

            setButtonType('folder');

            setUploadFolder(true);
            if (inputRef.current) {
                inputRef.current.setAttribute('webkitdirectory', true);
            }
            handleOnClick();
        },
        [setButtonType, handleOnClick]
    );

    const onFileClick = useCallback(
        (e) => {
            e.stopPropagation();

            if (IS_PUBLIC_OF_OVERQUOTA) {
                openPopupHelper({
                    popupName: popupNames.NO_SPACE_FOR_OVERQUOTA_USER_MODAL,
                });
                return;
            }

            setButtonType('file');

            setUploadFolder(false);
            if (inputRef.current) {
                inputRef.current.removeAttribute('webkitdirectory');
            }
            handleOnClick();
        },
        [setButtonType, handleOnClick]
    );

    const onSelectFromCloud = useCallback(
        (e) => {
            e.stopPropagation();

            dispatch(addItemsFromCloudToAlbumRequest());
        },
        [dispatch]
    );

    const onAreaClick = useCallback(
        (e) => {
            e.stopPropagation();

            if (IS_PUBLIC_OF_OVERQUOTA) {
                openPopupHelper({
                    popupName: popupNames.NO_SPACE_FOR_OVERQUOTA_USER_MODAL,
                });
                return;
            }

            setButtonType('area');

            setUploadFolder(false);
            if (inputRef.current) {
                inputRef.current.removeAttribute('webkitdirectory', '');
            }
            handleOnClick();
        },
        [setButtonType, handleOnClick]
    );

    const open = useCallback(() => {
        setIsOpen(true);
        isOpenVar = true;
    }, []);

    const close = useCallback(() => {
        setIsOpen(false);
        isOpenVar = false;
    }, []);

    const getText = useCallback(() => {
        if (storage === EStorageType.albums) {
            return 'Выберите, что вы хотите загрузить в\u00A0альбом';
        }

        if (isMobile) {
            return 'Нажмите на\u00A0эту кнопку для быстрой загрузки файлов в\u00A0Облако';
        }

        return 'Выберите, что вы хотите загрузить в Облако';
    }, [storage, isMobile]);

    const getTitle = useCallback(() => {
        if (storage === EStorageType.albums) {
            return 'Добавить в альбом';
        }

        return 'Загрузить';
    }, [storage]);

    const getInputProps = useCallback(() => {
        const inputProps = {
            type: inputType,
            multiple: true,
            className: styles.input,
            accept: allowedMimeTypes,
            ref: inputRef,
        };

        if (uploadFolder) {
            // @ts-ignore
            inputProps.webkitdirectory = `${uploadFolder}`;
        }

        return inputProps;
    }, [allowedMimeTypes, uploadFolder]);

    useEffect(() => {
        sendGa('show', 'default');

        if (BUGREPORT_BUTTON_ENABLED) {
            initBugreportButton(bugreportRef.current);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('dragover', handleDrag, true);
        document.addEventListener('dragenter', handleDrag);
        document.addEventListener('dragleave', handleDrag);
        document.addEventListener('drop', handleDrop);

        return () => {
            document.removeEventListener('dragover', handleDrag, true);
            document.removeEventListener('dragenter', handleDrag);
            document.removeEventListener('dragleave', handleDrag);
            document.removeEventListener('drop', handleDrop);
        };
    }, [handleDrag, handleDrop]);

    useEffect(() => {
        if (!showInput) {
            return;
        }

        setForceClick(true);
    }, [showInput, renderInput]);

    useEffect(() => {
        if (forceClick) {
            handleOnClick();
            dispatch(setShowInputAction(false));
            setForceClick(false);
        }
    }, [forceClick]);

    if (IS_BIZ_USER && isReadOnlyFolder && hideCreateUploadInRoFolders) {
        return null;
    }

    if (IS_MY_TEAM && !IS_PHONE_BROWSER) {
        return (
            <>
                {(isVisible || showInput) && <input {...getInputProps()} />}
                {isOwnerInViewMode && <UploadInput />}
                {showUpload && <B2BUploadButton {...{ storage, isActionPanelOpened }} />}
            </>
        );
    }

    const openIt = Boolean(isOpen && !IS_PHONE_BROWSER && storage !== EStorageType.alldocuments && storage !== EStorageType.incomingPublic);

    return (
        <div
            className={classNames({
                [styles.root]: true,
                [styles.root_isDrag]: isDrag,
                [styles.root_hide]: !isVisible || !showDropArea,
                [styles.root_phone]: IS_PHONE_BROWSER,
                [styles.root_upload]: showUpload,
                [styles.root_album]: storage === EStorageType.albums,
                [styles.shifted_by_panel]: isActionPanelOpened,
                /* tempexp_14812-next-line */
                [styles.new_empty_states]: chooseVariant((state) => getFeatureNewEmptyStatesTouch(state) && IS_PHONE_BROWSER, {
                    control: false,
                    variant1: true,
                })(),
            })}
            style={{ ...(rightOffset && { right: `${rightOffset}px` }) }}
        >
            {renderInput && <input {...getInputProps()} />}

            {BUGREPORT_BUTTON_ENABLED && <div className={styles.bugreportBlock} ref={bugreportRef} />}

            {BUGREPORT_KOTLETT_BUTTON_ENABLED && <KotlettBugreport className={styles.bugreportBlock} />}

            {showUpload && !isDrag && (
                <UploadButton
                    isOpen={openIt}
                    text={getText()}
                    title={getTitle()}
                    onMouseEnter={open}
                    onMouseLeave={close}
                    onClick={onAreaClick}
                    onFileClick={onFileClick}
                    onFolderClick={onFolderClick}
                    onSelectFromCloud={onSelectFromCloud}
                    isPhone={IS_PHONE_BROWSER}
                    // tempexp-17235-start
                    isCentered={!!rightOffset}
                    // tempexp-17235-end
                />
            )}
        </div>
    );
};
