import { Icon16KeyOutline, Icon16MoreHorizontal } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { renderPublicPassword } from 'reactApp/components/SharingNewBiz/PublicPasswordDialog/PublicPasswordDialog.helpers';
import { closeSharingNew, renderSharingNew } from 'reactApp/components/SharingNewBiz/SharingNew.helpers';
import type { IProps as SharingNewProps } from 'reactApp/components/SharingNewBiz/SharingNew.types';
import styles from 'reactApp/components/SharingNewBiz/Weblink/SharingNewWeblink.css';
import type { PublishItem } from 'reactApp/modules/modifying/modifying.types';
import { setPublicPin } from 'reactApp/modules/public/public.actions';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { ButtonLink } from 'reactApp/ui/ButtonLink/ButtonLink';

import { renderDeletePublicPasswordDialog } from './DeletePublicPasswordDialog/DeletePublicPasswordDialog.helpers';
import { Dropdown } from './Dropdown/Dropdown';

export interface Props {
    pin?: string;
    item?: PublishItem;
    formProps: SharingNewProps;
}

export const WeblinkPublicPassword = ({ pin, item, formProps }: Props) => {
    const dispatch = useDispatch();
    const menuRef = useRef<HTMLDivElement | null>(null);
    const [isShowMenu, setShowMenu] = useState(false);
    const [password, setPassword] = useState<string>('');

    const handleToogleMenu = useCallback(() => {
        setShowMenu((prev) => !prev);
    }, []);

    useEffect(() => {
        setPassword(pin ?? '');
    }, [pin]);

    const handleRestorePassword = useCallback(
        (oldPassword: string) => {
            if (!item?.weblink) {
                return;
            }

            dispatch(
                setPublicPin({
                    pin: oldPassword,
                    public_id: item.weblink,
                    onSuccess: () => {
                        setPassword(oldPassword);
                    },
                })
            );
        },
        [dispatch, item?.weblink]
    );

    const handleCopyPassword = useCallback(() => {
        navigator.clipboard.writeText(password);
        dispatch(
            showSnackbarAction({
                id: 'public-password-copy-success',
                text: 'Пароль скопирован',
                type: SnackbarTypes.success,
                closable: true,
            })
        );
    }, [dispatch, password]);

    const handleSetPassword = useCallback(() => {
        renderPublicPassword({
            item,
            pin: password,
            onSuccess: () => renderSharingNew(formProps),
            onDialogClose: () => renderSharingNew(formProps),
        });
        closeSharingNew();
    }, [formProps, item, password]);

    const handleSetOrCopyPassword = useCallback(() => {
        if (password) {
            handleCopyPassword();
        } else {
            handleSetPassword();
        }
    }, [password]);

    const handleDeletePassword = useCallback(() => {
        if (!item?.weblink) {
            return;
        }

        renderDeletePublicPasswordDialog({
            onDelete: () => setPassword(''),
            onRestore: () => handleRestorePassword(password),
            onDialogClose: () => renderSharingNew(formProps),
            publicId: item.weblink,
        });
        closeSharingNew();
    }, [password, item, formProps]);

    return (
        <div className={styles.rightsItem}>
            <div className={styles.titleWrapper}>
                <span className={styles.icon}>
                    <Icon16KeyOutline />
                </span>
                <span className={styles.rightsTitle}>{password ? 'Установлен пароль для доступа' : 'Пароль для доступа по ссылке'}</span>
                {password && (
                    <div className={classNames(styles.menuIcon, { [styles.opened]: isShowMenu })} ref={menuRef} onClick={handleToogleMenu}>
                        <Icon16MoreHorizontal />
                    </div>
                )}
                {isShowMenu && (
                    <Dropdown
                        ref={menuRef}
                        isExternalAccess={!item?.weblinkDomestic}
                        onClose={handleToogleMenu}
                        onDeletePassword={handleDeletePassword}
                        onChangePassword={handleSetPassword}
                    />
                )}
            </div>
            <div className={styles.dropdown}>
                <ButtonLink primary hoverUnderline={false} onClick={handleSetOrCopyPassword}>
                    {password ? 'Скопировать пароль' : 'Установить'}
                </ButtonLink>
            </div>
        </div>
    );
};
