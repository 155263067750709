import { createSelector } from '@reduxjs/toolkit';
import { IS_PUBLIC_ALBUM } from 'reactApp/appHelpers/configHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getDomainFoldersFilterActive } from 'reactApp/modules/home/home.selectors';
import { isOwnPublic } from 'reactApp/modules/public/public.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import {
    getDescriptorId,
    getFileSize,
    hasProblemFile,
    isFileAutoFixError,
    isFileComplete,
    isFileDone,
    isFileExist,
    isFileIgnored,
    isFileProgress,
    isFolder,
    isProgressing,
} from 'reactApp/modules/uploadList/uploadList.getters';
import { folderName } from 'reactApp/modules/uploadList/uploadList.helpers';
import {
    type IGetInputFile,
    type IInputFile,
    type IUploaderInformerItem,
    EFileError,
    EFileStatus,
    EInfoTypes,
    EProgressStatus,
} from 'reactApp/modules/uploadList/uploadList.model';
import { findFullItem } from 'reactApp/modules/uploadList/uploadList.module.helper';
import type { RootState } from 'reactApp/store';

import {
    LOW_SPEED_LIMIT,
    MAX_NUM_OF_CANCELED_FILE_WARNINGS,
    SMART_UPLOADED_BYTES_LOW_LIMIT,
    SMART_UPLOADED_BYTES_PERCENT_LIMIT,
} from './uploadList.constants';

const getUploadState = (state: RootState) => state.uploadList;

const getUploadErrors = (state: RootState) => getUploadState(state).errors;

const getUploadProgress = (state: RootState) => getUploadState(state).progress;

export const getGroupedFiles = (state: RootState) => getUploadState(state)?.groupedFiles || {};

export const getLastUploadTime = (state: RootState) => getUploadProgress(state)?.uploadTime;

export const getTotalCount = createSelector(
    (state: RootState) => getUploadState(state)?.totalSize,
    (state: RootState) => getUploadState(state)?.totalCount,
    (totalSize, totalCount) => ({
        totalSize,
        totalCount,
    })
);

export const getGroupedByFolderInputFiles = createSelector(getGroupedFiles, (files) => Object.values(files));

export const getInputFiles = createSelector(getGroupedByFolderInputFiles, (groupedByFolderInputFiles): IInputFile[] => {
    const files: IInputFile[] = [];

    groupedByFolderInputFiles.forEach((groupedFile) => {
        if (groupedFile.files) {
            Object.values(groupedFile.files).forEach((file) => {
                files.push(file);
            });
        } else if (!isFolder(groupedFile)) {
            files.push(groupedFile);
        }
    });

    return files;
});

export const getFilterFilesInProgress = createSelector(
    getInputFiles,
    (_, ids: string[]) => ids,
    (files, ids): string[] => {
        const filesMap = files.reduce((acc, curr) => {
            if (!isFileProgress(curr)) {
                return acc;
            }
            acc[curr.cloudPath] = true;
            return acc;
        }, {});

        return ids?.filter((id) => !filesMap[id]);
    }
);

export const findInputFile = (files: IInputFile[], { cloudPath, descriptorId }: IGetInputFile): IInputFile | undefined =>
    files.find(
        (item) =>
            (item.cloudPath === cloudPath && item.descriptorId === descriptorId) ||
            (item.cloudPath === cloudPath && !descriptorId) ||
            (item.descriptorId === descriptorId && !cloudPath)
    );

export const getInputFile = createSelector(
    getInputFiles,
    (state: RootState, { cloudPath, descriptorId }: IGetInputFile): IGetInputFile => ({
        cloudPath,
        descriptorId,
    }),
    (files, { cloudPath, descriptorId }: IGetInputFile): IInputFile | undefined => findInputFile(files, { cloudPath, descriptorId })
);

export const getProgressUploadPacket = (state: RootState) => getUploadProgress(state).uploadPacket;

export const getInputFilesCurrentPacket = createSelector(
    getProgressUploadPacket,
    (_, files: IInputFile[]) => files,
    (progressUploadPacket, files): IInputFile[] => files.filter((file) => file.uploadPacket === progressUploadPacket)
);

export const getPercentLoaded = (state: RootState) => getUploadProgress(state).percentLoaded;

export const getCountLeft = createSelector(
    [getInputFiles, getProgressUploadPacket, getTotalCount],
    (files, progressUploadPacket, { totalCount }) => {
        let countInCurrentPacket = 0;
        let countInDoneInCurrentPacket = 0;

        files.forEach((item) => {
            if (progressUploadPacket !== item.uploadPacket) {
                return;
            }

            countInCurrentPacket++;

            if (isFileComplete(item)) {
                countInDoneInCurrentPacket++;
            }
        });

        return (totalCount || countInCurrentPacket) - countInDoneInCurrentPacket;
    }
);

export const getProgressStatus = (state: RootState) => getUploadProgress(state).status;

export const getUploadSpeed = (state: RootState) => getUploadState(state).speedMbSec;

export const getSpeedForLowMbSec = (state: RootState) => getUploadState(state).speedForLowMbSec;

export const getsmartUploadedBytes = (state: RootState) => getUploadState(state).smartUploadedBytes;

export const hasConnectionError = (state: RootState) => getUploadState(state).hasConnectionError;

const getHiddenInformers = (state: RootState) => getUploadState(state).hideInformers;

export const getRemainTime = createSelector(getUploadState, getPercentLoaded, (state, loaded) => {
    return Math.round((state.totalSize - (loaded * state.totalSize) / 100) / 1024 / 1024 / state.speedMbSec);
});

export const isProgressingStatus = (state: RootState) => isProgressing(getProgressStatus(state));

export const hasWarningFiles = createSelector(getInputFiles, (files) => files.some((item) => item.status === EFileStatus.WARNING));

export const hasErrorFiles = createSelector(getInputFiles, (files) => files.some((item) => item.status === EFileStatus.ERROR));

export const getFolder = (state: RootState, folder: { localPath?: string; descriptorId?: string }) => {
    const groupedFiles = getGroupedFiles(state);
    let folderItem;

    if (folder.descriptorId) {
        folderItem = Object.values(groupedFiles).find((groupedFile) => groupedFile.descriptorId === folder.descriptorId);
    }

    if (!folderItem && folder.localPath) {
        folderItem = groupedFiles[folderName(folder.localPath) || ''];
    }

    return folderItem;
};

const getCommonCountFiles = createSelector(getInputFiles, getTotalCount, (files, { totalCount }) => totalCount || files?.length || 0);

export const getCommonSizeForCurrentPacket = createSelector(
    getProgressUploadPacket,
    (_, files: IInputFile[]) => files,
    (progressUploadPacket, files) =>
        files.reduce((acc, file) => (file.uploadPacket === progressUploadPacket ? acc + getFileSize(file) : acc), 0)
);

export const getProblemFiles = createSelector(getInputFiles, (files) =>
    files.filter(
        (item) =>
            (item.status === EFileStatus.ERROR ||
                item.status === EFileStatus.CANCEL ||
                item.status === EFileStatus.INFO ||
                item.status === EFileStatus.PAUSED) &&
            !item.hideError &&
            item.error !== EFileError.GATEWAY_ERROR
    )
);

export const hasConnectionErrorFiles = createSelector(getInputFiles, (files) =>
    files.some((item) => {
        return item.status === EFileStatus.PAUSED && item.error === EFileError.CONNECTION_ERROR && !item.hideError;
    })
);

export const getUploaderInformers = createSelector(
    getProblemFiles,
    hasConnectionError,
    hasConnectionErrorFiles,
    getProgressStatus,
    getHiddenInformers,
    getSpeedForLowMbSec,
    getsmartUploadedBytes,
    getTotalCount,
    getProgressUploadPacket,
    (
        problemFiles,
        hasConnectionError,
        hasConnectionErrorFiles,
        status,
        hiddenInformers,
        speed,
        smartUploadedBytes,
        { totalSize },
        uploadPacket
    ) => {
        let numOfSkipped = 0;
        let numOfOldSkipped = 0;

        let res = problemFiles.map<IUploaderInformerItem>((file) => {
            if (file.error === EFileError.CANCELLED_FILE) {
                numOfSkipped++;
                numOfOldSkipped += file.uploadPacket === uploadPacket ? 0 : 1;
            }
            return {
                id: getDescriptorId(file) ?? '',
                type: EInfoTypes.problemFile,
                file,
            };
        });

        if (numOfSkipped > MAX_NUM_OF_CANCELED_FILE_WARNINGS) {
            res = res.filter((item) => item.file?.error !== EFileError.CANCELLED_FILE);
            if (status === EProgressStatus.COMPLETE && numOfSkipped - numOfOldSkipped > MAX_NUM_OF_CANCELED_FILE_WARNINGS) {
                res.push({
                    type: EInfoTypes.canceled,
                    id: EInfoTypes.canceled,
                });
            }
        }

        const hasImportantErrors = problemFiles.filter(
            (file) =>
                file.error &&
                [
                    EFileError.OVER_QUOTA_CLOUD_B2B,
                    EFileError.OVER_QUOTA,
                    EFileError.OVER_QUOTA_CLOUD,
                    EFileError.OVER_QUOTA_CLOUD_AT_OWNER,
                    EFileError.OVER_QUOTA_LIMIT_DOWNLOAD,
                    EFileError.USER_FILE_SIZE_LIMIT,
                    EFileError.USER_FILE_SIZE_LIMIT_OVER_100_GB,
                ].includes(file.error)
        );

        if (speed && speed < LOW_SPEED_LIMIT && !hiddenInformers.includes(EInfoTypes.lowSpeed) && status !== EProgressStatus.COMPLETE) {
            res.push({
                type: EInfoTypes.lowSpeed,
                id: EInfoTypes.lowSpeed,
            });
        }

        if (hasConnectionError) {
            const fileOne = res.find((file) => file.file?.error === EFileError.CONNECTION_ERROR);
            res = res.filter((file) => fileOne === file || file.file?.error !== EFileError.CONNECTION_ERROR);
        }

        if (hasConnectionError && !hasConnectionErrorFiles) {
            res.push({
                type: EInfoTypes.networkRestored,
                id: EInfoTypes.networkRestored,
            });
        }

        const smartUploadedPercent = smartUploadedBytes / totalSize;

        if (
            totalSize > SMART_UPLOADED_BYTES_LOW_LIMIT &&
            smartUploadedPercent > SMART_UPLOADED_BYTES_PERCENT_LIMIT &&
            status === EProgressStatus.COMPLETE &&
            !hiddenInformers.includes(EInfoTypes.complete)
        ) {
            const item = {
                type: EInfoTypes.complete,
                id: EInfoTypes.complete,
            };
            if (hasImportantErrors) {
                res.unshift(item);
            } else {
                res.push(item);
            }
        }

        return res;
    }
);

export const getProblemItemsGroupedByFolder = createSelector(getGroupedByFolderInputFiles, (groupedByFolderInputFiles) =>
    groupedByFolderInputFiles.filter(
        (item) =>
            item.status === EFileStatus.ERROR ||
            item.status === EFileStatus.CANCEL ||
            item.status === EFileStatus.INFO ||
            item.status === EFileStatus.PAUSED ||
            item.error === EFileError.ERROR_IN_FOLDER ||
            item.error === EFileError.INFO_IN_FOLDER
    )
);

export const getWarningFile = createSelector(getInputFiles, (files) =>
    files.find((item) => item.status === EFileStatus.WARNING && !item.hideError)
);

export const getSuccessInputFiles = (state: RootState, file: { cloudPath?: string; descriptorId?: string }) =>
    getInputFiles(state).filter(
        (item) =>
            !hasProblemFile(item) && (item.cloudPath === file.cloudPath || (item.descriptorId && item.descriptorId === file.descriptorId))
    );

const getCountDoneFiles = createSelector(getInputFiles, (files) => files.filter((item) => isFileDone(item))?.length);

const getCountErrorFiles = createSelector(
    getInputFiles,
    (files) => files.filter((item) => !isFileDone(item) && !isFileAutoFixError(item))?.length
);

const getCountFileExistFiles = createSelector(
    getInputFiles,
    (files) => files.filter((item) => !isFileDone(item) && !isFileAutoFixError(item) && isFileExist(item))?.length
);

const getCountIgnoredFiles = createSelector(
    getInputFiles,
    (files) => files.filter((item) => !isFileDone(item) && !isFileAutoFixError(item) && isFileIgnored(item))?.length
);

const getCountAutoFixFiles = createSelector(getInputFiles, (files) => files.filter((item) => isFileAutoFixError(item))?.length);

export const getCountStatistic = createSelector(
    getCountDoneFiles,
    getCommonCountFiles,
    getCountAutoFixFiles,
    getCountErrorFiles,
    getCountIgnoredFiles,
    getCountFileExistFiles,
    getCountLeft,
    getCommonCountFiles,
    (successCount, commonCount, autoFixCount, errorCount, ignoredCount, fileExistCount, leftCount, totalCount) => ({
        successCount,
        commonCount,
        autoFixCount,
        errorCount,
        ignoredCount,
        fileExistCount,
        leftCount,
        totalCount,
    })
);

export const isUploaderVisible = (state: RootState) => getUploadState(state).showUploader;

export const getSelectedErrorId = (state: RootState) => getUploadErrors(state).selectedFileId;
export const getSelectedErrorIsUserClick = (state: RootState) => getUploadErrors(state).userClick;

export const getHighlightSelectedErrorFile = (state: RootState) => getUploadErrors(state).highlightSelectedFile;

export const getUserFileSizeLimit = (state: RootState) => getUploadErrors(state).userFileSizeLimit;

export const showUploadButton = createSelector(
    isOwnPublic,
    getCurrentStorage,
    getDomainFoldersFilterActive,
    (isOwn, storage, domainFoldersFilterActive) => {
        const { isStart, isGallery, isFeed, isFavorites, isHome, isPublic, isDocuments, isAlbums, isAllDocuments, isIncomingPublic } =
            getStorage(storage);

        if (EnvironmentSelectors.isPhone()) {
            return isHome || isGallery || (isPublic && isOwn);
        }

        return (
            !IS_PUBLIC_ALBUM &&
            (isStart ||
                isGallery ||
                isFeed ||
                isFavorites ||
                (isHome && !domainFoldersFilterActive) ||
                (isPublic && isOwn) ||
                isDocuments ||
                isAlbums ||
                isAllDocuments ||
                isIncomingPublic)
        );
    }
);

export const getHasInput = (state: RootState) => getUploadState(state).hasInput;

export const getUploadingItem = createSelector(
    (state, descriptorId, name, isFolder) => (isFolder ? getFolder(state, { descriptorId, localPath: `/${name}` } as IInputFile) : null),
    (_state, descriptorId) => descriptorId,
    (_state, _descriptorId, _name, isFolder) => isFolder,
    getUploadState,
    (findItem, descriptorId, isFolder, uploadList) => {
        if (isFolder) {
            return findItem;
        }

        if (!findItem && descriptorId) {
            findItem = findFullItem(uploadList, { descriptorId } as IInputFile);
        }

        return findItem;
    }
);

export const getHasNotAllowedExt = (state: RootState) => getUploadState(state).hasNotAllowedExtensions;
