import { useMemo } from 'react';
import { tariffBuyWhenDisableAdsModalTitle } from 'reactApp/appHelpers/featuresHelpers/features/disableAdsScreen';

export function useDisableAdsTitle(titleFromQuery?: string) {
    return useMemo(() => {
        if (titleFromQuery && tariffBuyWhenDisableAdsModalTitle?.hasOwnProperty(titleFromQuery)) {
            return tariffBuyWhenDisableAdsModalTitle[titleFromQuery];
        }
        return 'Почта без рекламы — и другие опции с подпиской Mail Space';
    }, [titleFromQuery, tariffBuyWhenDisableAdsModalTitle]);
}
