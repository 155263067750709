import { resetSelect } from 'reactApp/modules/selections/selections.actions';
import { IDS_NO_RESET_WHITE_LIST, TAGNAME_NO_RESET_WHITE_LIST } from 'reactApp/ui/Datalist/DataList.constants';

export const onDocumentClick = ({ event, isModalOpen, setActiveIndex, dispatch, isInlineEditing }) => {
    if (isModalOpen) {
        return;
    }

    let el = event.target as HTMLElement;

    while (el && el !== document.body) {
        const tagName = el.tagName?.toLowerCase?.();

        if (TAGNAME_NO_RESET_WHITE_LIST[tagName] || IDS_NO_RESET_WHITE_LIST[el.id]) {
            return;
        }

        el = el.parentNode as HTMLElement;

        // Предположительно клик по элементу внутри Dropdown, который по клику удаляется из DOM. eg: выпадающий список в Toolbar
        if (el === null) {
            return;
        }
    }

    if (!isInlineEditing) {
        dispatch(resetSelect());
    }

    setActiveIndex(null);
};
