import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_DOCUMENTS_DOMAIN, IS_MBIZUSER, IS_PHONE_BROWSER, USER_BILLING_PARAMS } from 'reactApp/appHelpers/configHelpers';

/**
 * {@link https://jira.vk.team/browse/PDSM-3462 PDSM-3462}
 *
 * Микробизнесы. Демо шаблонов документов. Триал тариф и оплата
 */
type DemoBusinessTemplateInfo = {
    enabled: boolean;
    productId: string;
    endDate: string;
};

const demoBusinessTemplateInfo: DemoBusinessTemplateInfo = getFeature('demo-business-template-info');

export const DEMO_BUSINESS_TEMPLATES_PRODUCT_ID = demoBusinessTemplateInfo?.productId;
export const DEMO_BUSINESS_TEMPLATES_END_DATE = demoBusinessTemplateInfo?.endDate;
export const DEMO_BUSINESS_TEMPLATES_ENABLED = demoBusinessTemplateInfo?.enabled && !IS_MBIZUSER;

const businessTemplates: boolean = getFeature('business-templates');

/**
 * {@link https://jira.vk.team/browse/PDSM-2692 PDSM-2692}
 *
 * Микробизнесы. Разделы в облаке "Селлерам" и "Сфера красоты"
 *
 * Раздел шаблонов показывается если включена фича `business-templates` И
 *
 *  - ИЛИ в биллинг маске есть 8ой бит
 *  - ИЛИ включена фича демо
 */
export const BUSINESS_TEMPLATES_ENABLED =
    businessTemplates &&
    (DEMO_BUSINESS_TEMPLATES_ENABLED || USER_BILLING_PARAMS.HAS_MBIZ_TEMPLATES) &&
    !IS_DOCUMENTS_DOMAIN &&
    !IS_PHONE_BROWSER;

const businessTemplatesDemoPromo: boolean = getFeature('business-templates-demo-promo');
/** Можно ли показывать демо промо для шаблонов документов */
export const BUSINESS_TEMPLATES_DEMO_PROMO = BUSINESS_TEMPLATES_ENABLED && DEMO_BUSINESS_TEMPLATES_ENABLED && businessTemplatesDemoPromo;

const businessTemplatesDemoPromoTooltip: boolean = getFeature('business-templates-demo-tooltip');
/** Можно ли показывать демо промо тултип для шаблонов документов */
export const BUSINESS_TEMPLATES_DEMO_PROMO_TOOLTIP = BUSINESS_TEMPLATES_DEMO_PROMO && businessTemplatesDemoPromoTooltip;
