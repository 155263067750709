import { closePopupHelper, openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';

import type { Props } from './RestoreTrashbinFilesModal.types';

export const renderRestoreTrashbinFilesModal = (data: Props) => {
    openPopupHelper({
        popupName: popupNames.RESTORE_TRASHBIN_FILES_MODAL,
        data,
        onClose: data.onClose,
    });
};

export const closeRestoreTrashbinFilesModal = () => closePopupHelper(popupNames.RESTORE_TRASHBIN_FILES_MODAL);
