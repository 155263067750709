import classNames from 'clsx';
import React, { type ReactElement, memo, useMemo } from 'react';
import type { Product } from 'reactApp/types/Billing';
import {
    getFirstSomePeriodsAsWord,
    getPeriodName,
    isMonth6Period,
    isMonthPeriod,
    isYearPeriod,
    periodToDaysPeriod,
} from 'reactApp/utils/Period';
import { formatPrice, getMonthProductPrice } from 'reactApp/utils/priceHelpers';

import styles from './FastCheckoutSidebar.css';

export const PriceBlock = memo(({ product }: { product: Product }): ReactElement => {
    const {
        discountPeriod,
        period,
        discountPrice,
        price,
        hasDiscount,
        hasTrial,
        trialPeriod,
        isMbUser,
        isForceDiscountTrial,
        discountPeriodCount,
    } = product;

    const content = useMemo(() => {
        if (isMbUser && hasTrial && trialPeriod) {
            return (
                <>
                    <div className={styles.price}>Бесплатно</div>
                    <div className={classNames(styles.priceInfo, styles.trialPriceInfo)}>
                        {getPeriodName(periodToDaysPeriod(trialPeriod))} бесплатно, далее {formatPrice(price)}&nbsp;₽ в{' '}
                        {getPeriodName(period)}
                    </div>
                </>
            );
        }

        if (isForceDiscountTrial && trialPeriod && discountPrice && discountPeriod) {
            const trialPeriodName = getPeriodName(trialPeriod === '1m' ? periodToDaysPeriod(trialPeriod) : trialPeriod);
            const discountPeriodName = getFirstSomePeriodsAsWord(discountPeriodCount || 1, isYearPeriod(discountPeriod) ? 'y' : 'm');

            return (
                <>
                    <div className={styles.price}>Бесплатно</div>
                    <div className={classNames(styles.priceInfo, styles.trialPriceInfo)}>
                        {trialPeriodName} за 0&nbsp;₽, далее <span className={styles.crossedPrice}>{formatPrice(price)}&nbsp;₽</span>{' '}
                        {formatPrice(discountPrice)}&nbsp;₽ в {discountPeriodName}
                    </div>
                </>
            );
        }

        if (hasTrial && trialPeriod) {
            const trialPeriodName = getPeriodName(trialPeriod === '1m' ? periodToDaysPeriod(trialPeriod) : trialPeriod);

            return (
                <>
                    <div className={styles.price}>Бесплатно</div>
                    <div className={classNames(styles.priceInfo, styles.trialPriceInfo)}>
                        {trialPeriodName} за 0&nbsp;₽, далее {formatPrice(price)}&nbsp;₽ в {getPeriodName(period)}
                    </div>
                </>
            );
        }

        if (hasDiscount && discountPrice && discountPeriod) {
            return (
                <div className={styles.discount}>
                    <div className={styles.oldPrice}>{formatPrice(price)}&nbsp;₽</div>
                    <div className={styles.price}>
                        {formatPrice(discountPrice)}&nbsp;₽ в {getPeriodName(discountPeriod)}
                    </div>
                </div>
            );
        }

        if (isYearPeriod(period) || isMonth6Period(period)) {
            return (
                <>
                    <div className={styles.priceInfo}>
                        Подписка на {getPeriodName(period)} по цене {formatPrice(discountPrice || price)}&nbsp;₽
                    </div>
                    <div className={styles.price}>{formatPrice(getMonthProductPrice(product))}&nbsp;₽ в месяц</div>
                </>
            );
        }

        if (isMonthPeriod(period)) {
            return (
                <>
                    <div className={styles.priceInfo}>Подписка на месяц по цене</div>
                    <div className={styles.price}>{formatPrice(price)}&nbsp;₽ в месяц</div>
                </>
            );
        }
    }, [
        discountPeriodCount,
        discountPeriod,
        discountPrice,
        hasDiscount,
        period,
        price,
        hasTrial,
        trialPeriod,
        isForceDiscountTrial,
        isMbUser,
        product,
    ]);

    return <div className={styles.priceBlock}>{content}</div>;
});

PriceBlock.displayName = 'PriceBlock';
