import { createSelector } from '@reduxjs/toolkit';
import { sort } from 'ramda';
import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { CAMERA_UPLOADS_FOLDER_ID, ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import type { FilesState, HomeFile, HomeFolder, HomeItem, MediaStatistics } from 'reactApp/modules/home/home.types';
import { getHomeList } from 'reactApp/modules/home/selector/getHomeList';
import { getCurrentRouteId, getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getSharedIds } from 'reactApp/modules/shared/shared.selectors';
import { intlStringCompare } from 'reactApp/modules/sort/sort.helpers';
import { isDomainFolder, isMountedFolder, isPublicFolder, isSharedFolder } from 'reactApp/modules/storage/folder.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { RootState } from 'reactApp/store';
import type { Kind } from 'reactApp/types/Tree';
import type { BreadcrumbsListItem } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { ESelectFolderDialogMod } from 'reactApp/ui/SelectFolderDialog/SelectFolderDialog.types';
import type { TreeNodeData } from 'reactApp/ui/TreeComponent/TreeNode.types';
import { renameCameraUploadFolder } from 'reactApp/utils/tree.helpers';

const getFilesState = (state: RootState): FilesState => state.home;
const getId = (state, id) => id;

export const getHomeItemById = createSelector(
    getFilesState,
    getId,
    (filesState, id): HomeFolder | HomeFile | undefined => filesState.list[id]
);

const getHomeItemByIdLocal = createSelector(
    (filesState: FilesState) => filesState,
    getId,
    (filesState, id): HomeFolder | HomeFile | undefined => filesState.list[id]
);

export const getHomeStatistics = createSelector(getFilesState, (filesState) => filesState.homeStatistics);
export const getCameraUploadsStatistics = createSelector(getFilesState, (filesState) => filesState.cameraUploadsStatistics);

export const getIntraDomainShare = createSelector(getFilesState, (filesState) => !!filesState.intraDomainShare);

const getFolderListByIdLocal = createSelector(
    (state: FilesState) => state,
    getHomeItemByIdLocal,
    (state: FilesState, _itemId, foldersOnly = false) => foldersOnly,
    (state: FilesState, _itemId, _foldersOnly = false, sorted = true) => sorted,
    (state: FilesState, _itemId, _foldersOnly = false, _sorted = true, skipRo = false) => skipRo,
    (filesState, folder, foldersOnly = false, sorted = true, skipRo = false) => {
        if (folder?.isFolder && Array.isArray(folder.childs)) {
            let list = folder.childs.map((child) => getHomeItemByIdLocal(filesState, child));
            if (foldersOnly) {
                list = list.filter((item) => {
                    if (!item?.isFolder) {
                        return false;
                    }

                    if (skipRo) {
                        const isMounted = isMountedFolder(item) || isDomainFolder(item);
                        const isShared = isSharedFolder(item);

                        if (isMounted || isShared) {
                            return !(item && 'isReadOnly' in item && item.isReadOnly);
                        }
                    }

                    return true;
                });
            }
            if (sorted) {
                return sort((a, b) => (a && b ? intlStringCompare(a.name, b.name) : 0), list);
            }

            return list;
        }
        return [];
    }
);

export const getFolderListById = createSelector(
    getFilesState,
    (_, itemId: string) => itemId,
    (state: RootState, _itemId, foldersOnly = false) => foldersOnly,
    (state: RootState, _itemId, _foldersOnly = false, sorted = true) => sorted,
    (state: RootState, _itemId, _foldersOnly = false, _sorted = true, skipRo = false) => skipRo,
    (filesState, itemId, foldersOnly = false, sorted = true, skipRo = false) => {
        return getFolderListByIdLocal(filesState, itemId, foldersOnly, sorted, skipRo);
    }
);

export const getRootFolderListById = createSelector(getFilesState, (filesState) => {
    return getFolderListByIdLocal(filesState, ROOT_FOLDER_ID, true, false, false);
});

export const getFolderListLengthById = createSelector(getHomeItemById, (folder) => {
    if (folder?.isFolder && Array.isArray(folder.childs)) {
        return folder.childs?.length;
    }
    return 0;
});

export const getCurrentFolderHome = createSelector(
    getCurrentRouteId,
    (state) => state,
    (routeId, state): HomeFolder | undefined => {
        if (!routeId) {
            return;
        }

        const item = getHomeItemById(state, routeId);

        if (!item) {
            return;
        }

        if (item.isFolder) {
            return item;
        }

        return getHomeItemById(state, item.parent) as HomeFolder;
    }
);

export const getCurrentChildIds = createSelector(getCurrentFolderHome, (folder): string[] => {
    if (folder) {
        return folder.childs;
    }

    return [];
});

export const getHomeFolderItems = createSelector(getFilesState, getCurrentChildIds, (filesState, ids: string[]) =>
    ids.map((id) => getHomeItemByIdLocal(filesState, id) as HomeItem | undefined)
);

export const isReadOnly = createSelector(
    getFilesState,
    (state, id): string => id,
    (state, id): boolean => {
        let folder = state.list[id];
        while (folder && folder.isFolder && !folder.isReadOnly && folder.parent) {
            folder = state.list[folder.parent];
        }

        return folder?.isFolder ? Boolean(folder.isReadOnly) : false;
    }
);

export const isThisOrParentMounted = createSelector(getHomeItemById, getHomeList, (item, list) => {
    if (!item) {
        return false;
    }

    if (item.isMounted) {
        return true;
    }

    if (item.isFolder && (isMountedFolder(item) || isDomainFolder(item))) {
        return true;
    }

    let result = false;

    let parent = list[item.parent || ''] as HomeFolder;

    while (parent) {
        if (isMountedFolder(parent) || isDomainFolder(parent)) {
            result = true;
            break;
        }

        parent = list[parent.parent || ''] as HomeFolder;
    }

    return result;
});

export const isDefaultRootContent = createSelector(
    (state: RootState) => getFolderListLengthById(state, '/'),
    (state: RootState) => getHomeItemById(state, '/'),
    (state: RootState) => getCurrentStorage(state),
    (length, folder, storage) => {
        if (storage !== EStorageType.home) {
            return false;
        }

        return length === 0 && (folder as HomeFolder)?.isLoaded;
    }
);

export const getMountedFolderQuotaFree = createSelector(
    (state: RootState) => state,
    (_state: RootState, folderId: string) => folderId,
    (state: RootState, folderId: string) => {
        const folder = getHomeItemById(state, folderId) as HomeFolder;

        return folder?.quota ?? 0;
    }
);

export const getCameraUploadsStatisticsPrev = createSelector(
    getSharedIds,
    (state) => state,
    (idxs, state) => {
        const list = getFolderListById(state, CAMERA_UPLOADS_FOLDER_ID, false, false) as HomeItem[];

        let photos = 0;
        let videos = 0;

        (Array.isArray(list) ? list : []).forEach((item) => {
            const fileItem = item as HomeFile;
            if (!fileItem) {
                return;
            }
            if (fileItem?.kind === 'image') {
                photos++;
            }
            if (fileItem?.kind === 'video') {
                videos++;
            }
        });

        return {
            photos,
            videos,
        } as MediaStatistics;
    }
);

export const getRootFolderList = createSelector(getHomeList, (home) => home[ROOT_FOLDER_ID]);

export const getDomainFoldersFilterActive = createSelector(getFilesState, (state) => state.domainFoldersFilterActive);

export const getHomeBreadcrumbs = createSelector(
    getCurrentFolderHome,
    getFilesState,
    getDomainFoldersFilterActive,
    (currentFolder, filesState, isDomainFoldersFilterActive): BreadcrumbsListItem[] => {
        if (!currentFolder) {
            return [];
        }

        const showDomainTitle = currentFolder.kind === 'domain-folder' || currentFolder.isMounted || isDomainFoldersFilterActive;

        const breadCrumbs: BreadcrumbsListItem[] = [
            {
                name: IS_BIZ_USER ? `${showDomainTitle ? 'Общие папки' : 'Личные папки'}` : 'Все файлы',
                id: ROOT_FOLDER_ID,
                kind: 'folder' as Kind,
                storage: EStorageType.home,
            },
        ];

        const pathParts = currentFolder.home.split('/').filter(Boolean);
        const isRoot = currentFolder.id === ROOT_FOLDER_ID;
        let isPrevShared = false;

        let isMounted = false;
        let isShared = false;
        let isDomain = false;
        let is = false;
        let isReaOnly = false;

        pathParts.reduce((fullPath, path) => {
            const currentPath = `${fullPath}/${path}`;
            // Для вложенных папок монтированной папки приходит isMounted, но kind - не mounted
            const isPrevMounted = currentFolder.isMounted && !isMountedFolder(currentFolder);

            const item = getHomeItemByIdLocal(filesState, currentPath);

            breadCrumbs.push({
                name: renameCameraUploadFolder({ name: path }),
                id: currentPath,
                kind: currentFolder.kind,
                storage: EStorageType.home,
                weblink: currentFolder.weblink,
                showWeblink: !isRoot,
                showShare: !isRoot && !isPrevShared && !isPrevMounted && !isDomainFolder(currentFolder),
                parent: item?.parent,
            });

            isDomain = isDomainFolder(item);
            isMounted = isMountedFolder(item) || isDomain;
            isShared = isSharedFolder(item);
            is = is || isMounted || isShared;

            if (is) {
                isReaOnly = Boolean(item && 'isReadOnly' in item && item.isReadOnly);
            }

            isPrevShared = isPrevShared || isShared;

            return currentPath;
        }, '');

        for (const item of breadCrumbs) {
            if (item.id === ROOT_FOLDER_ID) {
                continue;
            }

            item.isDomain = isDomain;
            item.isMounted = isMounted;
            item.isShared = isShared;
            item.isReadOnly = isReaOnly;
        }

        return breadCrumbs;
    }
);

const hideChildren = (item, dialogMod) =>
    dialogMod === ESelectFolderDialogMod.createShare && (isMountedFolder(item) || isSharedFolder(item) || isDomainFolder(item));

export const getHomeFoldersTree = createSelector(
    getFilesState,
    (state, dialogMod?: ESelectFolderDialogMod) => dialogMod ?? null,
    (state, dialogMod?: ESelectFolderDialogMod, skipRoFolders = false) => skipRoFolders || false,
    (state, _?: ESelectFolderDialogMod, skipRoFolders = false) => getFolderListById(state, ROOT_FOLDER_ID, true, true, skipRoFolders),
    // Не добавлять в аргументы комбайнера state!
    (filesState, dialogMod: ESelectFolderDialogMod | null, skipRoFolders: boolean, rootFolders): TreeNodeData[] => {
        const mapFolderItemToTreeNodeData = (list) =>
            list
                ? list
                      .map((item) => {
                          return {
                              id: item.id,
                              href: item.href,
                              parent: item.parent,
                              isFolder: item.isFolder,
                              title: item.name,
                              storage: EStorageType.home,
                              folderCounter: !!item.count?.folders && !hideChildren(item, dialogMod) ? item.count?.folders : 0,
                              children: mapFolderItemToTreeNodeData(getFolderListByIdLocal(filesState, item.id, true, true, skipRoFolders)),
                              isMountedOrShared: item.kind === 'mounted' || isDomainFolder(item) || item.kind === 'shared',
                              kind: item.kind,
                              isPublic: isPublicFolder(item),
                          };
                      })
                      .filter(Boolean)
                : [];

        return mapFolderItemToTreeNodeData(rootFolders);
    }
);

export const getIsRedirectFromViewer = createSelector(getFilesState, (state): string | undefined | null => state.redirectFromViewerItem);

export const getLastOpenFolder = createSelector(getFilesState, (state): string | undefined | null => state.lastOpenFolder);
