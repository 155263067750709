import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { BuySubscriptionActions } from 'reactApp/modules/buySubscription/buySubscription.module';
import { BillingBuyTemplates } from 'reactApp/modules/buySubscription/buySubscription.types';
import { CardActions } from 'reactApp/modules/creditCard/creditCard.module';
import { CreditCardDmrLinkActions } from 'reactApp/modules/creditCardDmrLink/creditCardDmrLink.module';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { SubscriptionRenewalActions } from 'reactApp/modules/subscriptionRenewal/subscriptionRenewal.module';
import { updateSubscriptionsRequest } from 'reactApp/modules/subscriptions/subscriptions.module';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { SUBSCRIPTION_ANALYTICS } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscription.analytics';
import { store } from 'reactApp/store';
import { renderCardModal } from 'reactApp/ui/AddCardModal/AddCardModal.helpers';
import { renderCancelAutoRenewalModal } from 'reactApp/ui/Mobile/CancelAutoRenewalModal/CancelAutoRenewalModal.helpers';
import { sendDwh, sendXray } from 'reactApp/utils/ga';
import { call, cancel, put, putResolve, select, takeEvery } from 'redux-saga/effects';

import { logError } from './subscriptionRenewal.helpers';

function* handleCancelAutoRenewMobile({
    payload: { subscription, closeSubscriptionCard, isRebrandingQuotaLanding, isRebranding, dwhData },
}: ReturnType<typeof SubscriptionRenewalActions.cancelAutoRenewMobile>) {
    sendDwh({
        ...SUBSCRIPTION_ANALYTICS.MY_TARIFF_CANCEL_AUTOPAYMENT,
        dwhData,
    });

    closeSubscriptionCard();

    const { id, expires, space } = subscription;

    const userQuota = yield select(UserQuotaSelectors.getTotal);
    const userQuotaSpace = userQuota ? userQuota.original : 0;
    const availableSpace = bytesToNDigits(Math.abs(space.original - userQuotaSpace), 3).value;

    yield renderCancelAutoRenewalModal({
        id,
        currentSpace: space.value,
        availableSpace,
        expires,
        dwhData,
        isRebrandingQuotaLanding,
        isRebranding,
    });
}

function* handleRenewMobile({
    payload: { dwhData, closeSubscriptionCard, subscription },
}: ReturnType<typeof SubscriptionRenewalActions.renewMobile>) {
    sendDwh({
        ...SUBSCRIPTION_ANALYTICS.MY_TARIFF_TURNON_AUTOPAYMENT,
        dwhData,
    });
    const { id } = subscription;
    try {
        yield putResolve(yield call(BuySubscriptionActions.addAutorenew, id));
        yield put(updateSubscriptionsRequest());
        closeSubscriptionCard();
        yield put(
            showSnackbarAction({
                id: 'renew-subscription',
                closable: true,
                text: 'Подписка восстановлена',
                type: SnackbarTypes.success,
            })
        );
    } catch (error) {
        sendXray(['touch', 'subscription', 'autorenew', 'error']);
        logError(error);
        yield put(
            showSnackbarAction({
                id: 'renew-subscription',
                closable: true,
                text: 'Не удалось восстановить подписку. \nПопробуйте изменить способ оплаты',
                type: SnackbarTypes.failure,
            })
        );
        yield cancel();
    }
}

function* handleAddOrUpdateCardMobile({
    payload: { dwhData, closeSubscriptionCard },
}: ReturnType<typeof SubscriptionRenewalActions.addOrUpdateCardMobile>) {
    sendDwh({
        ...SUBSCRIPTION_ANALYTICS.MY_TARIFF_CHANGE_CARD,
        dwhData,
    });

    closeSubscriptionCard();

    yield renderCardModal({
        onClose() {
            store.dispatch(CreditCardDmrLinkActions.resetLink());
        },
        onSuccess() {
            store.dispatch(CardActions.updateCard({ showNotifications: true }));
        },
        onUnlinkCard() {
            store.dispatch(CardActions.updateCard({ isSilent: true }));
        },
        size: 'tiny',
        isMobile: true,
        checkoutSkin: BillingBuyTemplates.CLOUD_B2C_MOBILE,
    });
}

export function* watchSubscriptionsRenewalMobile() {
    yield takeEvery(SubscriptionRenewalActions.cancelAutoRenewMobile.toString(), handleCancelAutoRenewMobile);
    yield takeEvery(SubscriptionRenewalActions.renewMobile.toString(), handleRenewMobile);
    yield takeEvery(SubscriptionRenewalActions.addOrUpdateCardMobile.toString(), handleAddOrUpdateCardMobile);
}
