import React, { type FC, useMemo } from 'react';
import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { shouldShowCloneButtonForCloudAttach } from 'reactApp/appHelpers/featuresHelpers/features/cloneButtonForCloudAttach';
import { type AttachesItem, EAttachTypes } from 'reactApp/modules/attaches/attaches.types';
import { AttachMenuInfo } from 'reactApp/ui/ReactViewer/ViewerMenu/AttachMenuInfo/AttachMenuInfo';
import { CloneMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/CloneMenuButton/CloneMenuButton';
import { CloseMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/CloseMenuButton/CloseMenuButton';
import { DownloadMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/DownloadMenuButton/DownloadMenuButton';
import { EditMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/EditMenuButton/EditMenuButton';
import { MenuLogo } from 'reactApp/ui/ReactViewer/ViewerMenu/MenuLogo/MenuLogo';
import { MenuItemName, MoreMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/MoreMenuButton/MoreMenuButton';
import { ShareMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/ShareMenuButton/ShareMenuButton';
import {
    type ViewerMenuProps,
    ViewerMenuCenter,
    ViewerMenuEnd,
    ViewerMenuStart,
    ViewerMenuWrapper,
} from 'reactApp/ui/ReactViewer/ViewerMenu/ViewerMenu';
import { Zoomer } from 'reactApp/ui/ReactViewer/ViewerMenu/Zoomer/Zoomer';
import { canCloneWeblink } from 'reactApp/utils/cloneHelpers';

interface Props extends ViewerMenuProps {
    file: AttachesItem;
}

export const AttachMenu: FC<Props> = ({
    file,
    fileStorage,
    isAuthorized,
    showImageEdit,
    isArchiveItem,
    zoom,
    decreaseZoom,
    increaseZoom,
    onFileClick,
    onActive,
    showDownload,
    onDownload,
    onPublish,
    onClone,
    onImageEdit,
    showEdit,
    onAttachCopyEdit,
    allowPrinting,
    onPrint,
    showClose,
    onClose,
}) => {
    const showClone = canCloneWeblink(isAuthorized, fileStorage);
    const isTemporary = file.attachType === EAttachTypes.temporary;
    const isCloudAttach = file.attachType === EAttachTypes.cloud;
    const showAttachEditButton = showEdit && !isTemporary && !isCloudAttach && !IS_BIZ_USER;
    const showAttachCloneButton = showClone && !isArchiveItem && !isTemporary && (!isCloudAttach || shouldShowCloneButtonForCloudAttach);
    const showAttachShareButton = showClone && !isArchiveItem && !isTemporary && !isCloudAttach;

    const moreItems = useMemo(
        () => [
            ...(allowPrinting
                ? [
                      {
                          name: MenuItemName.PRINT,
                          onClick: () => {
                              onPrint(file);
                          },
                      },
                  ]
                : []),
        ],
        [allowPrinting, onPrint]
    );

    return (
        <ViewerMenuWrapper>
            <ViewerMenuStart>
                <MenuLogo />
                <AttachMenuInfo file={file} onClick={onFileClick} onActive={onActive} />
            </ViewerMenuStart>
            <ViewerMenuCenter>{zoom && <Zoomer value={zoom} decrease={decreaseZoom} increase={increaseZoom} />}</ViewerMenuCenter>
            <ViewerMenuEnd>
                {showAttachShareButton && (
                    <>
                        <ShareMenuButton
                            onClick={() => {
                                onPublish(file);
                            }}
                        />
                        {showImageEdit && (
                            <EditMenuButton
                                onClick={() => {
                                    onImageEdit(file);
                                }}
                            />
                        )}
                    </>
                )}
                {showAttachEditButton && (
                    <EditMenuButton
                        hint="Редактировать копию"
                        onClick={() => {
                            onAttachCopyEdit(file);
                        }}
                    />
                )}
                {showAttachCloneButton && <CloneMenuButton onClick={onClone} />}
                {showDownload && (
                    <DownloadMenuButton
                        file={file}
                        onClick={() => {
                            onDownload(file);
                        }}
                    />
                )}
                <MoreMenuButton list={moreItems} />
                {showClose && (
                    <CloseMenuButton
                        onClick={() => {
                            onClose(file);
                        }}
                    />
                )}
            </ViewerMenuEnd>
        </ViewerMenuWrapper>
    );
};
