import type { Space } from '@mail/cross-sizes-utils';

export enum EPartner {
    rostelecom = 'rostelecom',
    mail = 'ru.mail.special',
    tele2 = 'tele2',
    megafon = 'megafon',
    sferum = 'sferum',
}

export type Partner = EPartner.rostelecom | EPartner.mail | EPartner.tele2 | EPartner.megafon | EPartner.sferum;

export enum EStatus {
    ACTIVE = 'ACTIVE',
    FINISHED = 'FINISHED',
    SUSPENDED = 'SUSPENDED',
    // Таких подписок пока что нет
    // RENEWING = 'RENEWING',
}

export enum ESubscriptionsTypes {
    web = 'web',
    ios = 'ios',
    android = 'android',
    reward = 'reward',
    base = 'base',
    rustore = 'rustore',
}

export const SUBSCRIPTION_TYPE_LIST = [
    ESubscriptionsTypes.web,
    ESubscriptionsTypes.ios,
    ESubscriptionsTypes.android,
    ESubscriptionsTypes.reward,
    ESubscriptionsTypes.rustore,
] as const;

export interface IBaseSubscription {
    base: true;
    id: 'base';
    icon: string;
    space: Space;
    type: ESubscriptionsTypes.base;
}

export interface ISubscription {
    id: string;
    type:
        | ESubscriptionsTypes.web
        | ESubscriptionsTypes.ios
        | ESubscriptionsTypes.android
        | ESubscriptionsTypes.reward
        | ESubscriptionsTypes.rustore;
    start: number;
    status: string;
    expires: number;
    renewAt: number;
    icon: string;
    productId: string;
    promoId?: string;
    renewalCost?: number;
    period?: string;
    space: Space;
    autorenewal?: boolean;
    renewable: boolean;
    isTrial: boolean;
    isPrepaid: boolean;
    isProfessional: boolean;
    flags: Record<string, boolean>;
    accountLink?: string;
    partner?: Partner;
    santa?: string;
    isDisko: boolean;
    isMail: boolean;
    diskoKey?: string;
}

export enum ESubscriptionsNotifications {
    updateSuccess = 'SUBSCRIPTIONS_UPDATE_SUCCESS',
    updateFailure = 'SUBSCRIPTIONS_UPDATE_FAILURE',
}

type SubscriptionList = Record<string, any>;

export interface ISubscriptionsState {
    activeSubscriptionId?: string;
    list: SubscriptionList;
    rawList: SubscriptionList;
    isLoading: boolean;
    isLoaded: boolean;
}

export interface IGetSubscriptionsRequestData {
    list: SubscriptionList;
}

export interface IUpdateSubscriptionsAction {
    showSnackbar?: boolean;
}

export const isBaseSubscription = (subscription): subscription is IBaseSubscription => {
    return (subscription as IBaseSubscription).type === ESubscriptionsTypes.base;
};

export const isPaymentSubscription = (subscription): subscription is ISubscription => {
    return SUBSCRIPTION_TYPE_LIST.includes((subscription as ISubscription).type);
};

export const isAndroidSubscription = (subscription): subscription is ISubscription => {
    return (subscription as ISubscription).type === ESubscriptionsTypes.android;
};

export const isIosSubscription = (subscription): subscription is ISubscription => {
    return (subscription as ISubscription).type === ESubscriptionsTypes.ios;
};

export const isMobileSubscription = (subscription): subscription is ISubscription => {
    return isAndroidSubscription(subscription) || isIosSubscription(subscription);
};
