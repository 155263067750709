import { requestPayment } from 'Cloud/Application/Payment';
import blockedCloud from 'img/icons/cloud-blocked.svg?url';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getProductById } from 'reactApp/modules/products/products.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import type { RootState } from 'reactApp/store';
import { type UseContentHook, ESplashScreenMod } from 'reactApp/ui/Mobile/SplashScreen/SplashScreen.types';
import { getPeriodName } from 'reactApp/utils/Period';

export const useExpiredSubscriptionScreen: UseContentHook = ({ hookParams = {} }) => {
    const { total } = useSelector(UserSelectors.getCloudSpace);
    const product = useSelector((state: RootState) => getProductById(state, hookParams?.productId));

    const footerText = product?.trialPeriod ? `Первые ${getPeriodName(product.trialPeriod, false, false, true)} бесплатно!` : '';

    const onClick = useCallback(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        requestPayment({ isMobile: true, tariff: product?.id, paySource: 'expired_subs_splash' });
    }, [product?.id]);

    return {
        id: 'mobile-canceled-subs',
        title: 'Ваша подписка\nзакончилась',
        description: `Размер Облака уменьшился до ${total?.value}. Чтобы вернуть объём, верните подписку в\u00A0веб-версии.`,
        buttonText: `Подключить ${product?.space?.value}`,
        onClick,
        img: blockedCloud,
        mod: ESplashScreenMod.rebranding,
        footerText,
    };
};
