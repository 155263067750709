import React, { type ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BuyModal } from 'reactApp/components/BuyModal/BuyModal';
import { BillingActions } from 'reactApp/modules/billing/billing.module';
import { BillingSelectors } from 'reactApp/modules/billing/billing.selectors';
import { SubscriptionsSelector } from 'reactApp/modules/subscriptions/subscriptions.selectors';
import type { ISubscription } from 'reactApp/modules/subscriptions/subscriptions.types';
import type { RootState } from 'reactApp/store';
import { createGaSender } from 'reactApp/utils/ga';

interface Props {
    subscriptionId: string;
    onClose?: () => void;
    onSuccess?: () => void;
    isMobile?: boolean;
}

const sendBillingGa = createGaSender('billing');

export const RenewSubscriptionModal = ({ subscriptionId, onClose, onSuccess, isMobile }: Props): ReactElement | null => {
    const dispatch = useDispatch();

    const subscription = useSelector((state: RootState) =>
        SubscriptionsSelector.getSubscriptionsById(state, subscriptionId)
    ) as ISubscription;
    const renewLink = useSelector(BillingSelectors.getPaymentRenewLink);

    const renewSubscriptions = useCallback((): void => {
        dispatch(BillingActions.renewSubscription({ id: subscriptionId }));
    }, [dispatch, subscriptionId]);

    const handleOnRenewClose = useCallback((): void => {
        sendBillingGa('renew-close');
        dispatch(BillingActions.resetLink());
        onClose?.();
    }, [dispatch, onClose]);

    const handleOnSuccessRenew = useCallback((): void => {
        sendBillingGa('renew-success');

        onSuccess?.();
    }, [onSuccess]);

    useEffect(() => {
        sendBillingGa('renew-show');
        dispatch(BillingActions.renewSubscription({ id: subscriptionId }));
    }, []);

    if (!subscription) {
        return null;
    }

    return (
        <BuyModal
            onClose={handleOnRenewClose}
            onSuccess={handleOnSuccessRenew}
            id={subscription?.productId}
            link={renewLink}
            retry={renewSubscriptions}
            renew
            isMobile={isMobile}
        />
    );
};
