import classNames from 'clsx';
import { ReactComponent as IconClose } from 'img/icons/close_s__2.svg';
import React, { memo, useCallback } from 'react';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { Hint } from 'reactApp/ui/Hint/Hint';

import styles from './FaceFilterComponent.css';

type FaceProps =
    | { id: string; url: string; isSelected?: boolean; onSelect(faceId: string | null) }
    | { id?: string; count: number; isSelected?: false; onCounter() };

export const FaceFilterItem = memo(function FaceFilterItem(props: FaceProps) {
    const { isSelected, id = '' } = props;

    const handleOnClick = useCallback(() => {
        if ('url' in props) {
            props.onSelect(isSelected ? null : id);
        }
    }, [isSelected, id]);

    if ('url' in props) {
        return (
            <Hint text="Все фото с этим человеком" showDelay>
                <div className={classNames({ [styles.face]: true, [styles.face_selected]: isSelected })} onClick={handleOnClick}>
                    <img src={props.url} />
                    <div className={styles.cancel}>
                        <IconClose width={11} height={11} />
                    </div>
                </div>
            </Hint>
        );
    }

    if (props.count) {
        return (
            <div className={classNames(styles.face, styles.counter)} onClick={props.onCounter}>
                {props.count}
                {isRebranding && '+'}
            </div>
        );
    }

    return null;
});
