export const CAMERA_UPLOADS_FOLDER_ID = '/Camera Uploads';
export const UNMODIFIED_CLOUD_SPACE = 492119223; // размер дефолтного контента
export const DEFAULT_FILES_HASHES = [
    '8B57D61B651F8AF2696E756CB310F320DC7838B9',
    'C2AD142BDF1E4F9FD50E06026BCA578198BFC36E',
    '1D1EC8CBB4A1128148108D5932EC76C42256F543',
    'F6EFA34BFFD20A300F440B9C88245DA048DEEE89',
    'FC2BFEBBF99E1B9518BED1E3B0FDBCF934276A4A',
    '9101AB34F2348576EE9225AECF4DC17674D1EB17',
];
export const ROOT_FOLDER_ID = '/';
export const TOP_TOOLBAR_ID = 'TOP_TOOLBAR_ID';
export const ADD_TOOLBAR_ID = 'ADD_TOOLBAR_ID';
export const REACT_VIEWER_ID = 'REACT_VIEWER_ID';
export const HEADER_ID = 'portal-menu';
export const MAIL_ATTACHES_FOLDER_ID = '/Почтовые вложения';
export const ALL_DOCUMENTS_FOLDER_ID = 'Документы';
export const MAX_EMAIL_URL_LENGTH = 8000;
export const ACTION_PANEL_ID = 'ACTION_PANEL';

export const STORAGE_REGEXP =
    /^\/(attaches\/documents|attaches\/error|home|trashbin|search|public|album|albums|files|stock|documents|alldocuments|attaches|favorites|start|gallery|recommend|feed|links|shared(?:\/links|\/incoming|\/autodelete)|office\/edit\/home?|r-seven\/edit|myoffice\/edit\/home?|myoffice\/edit\/attaches?|subscriptions|embedded|pdf\/edit|integration|inline-integration||promocodes|promo\/quota|r7w\/edit\/home?)(\/.*)?$/;
export const EDITORS_REGEXP =
    /^\/(office\/edit\/home?|r-seven\/edit|myoffice\/edit\/home?|myoffice\/edit\/attaches?|r7w\/edit\/home?)(\/.*)?$/;
export const ANONYMOUS_USER_NAME = 'anonymous';
export const ENABLED_KINDS_AS_PLAIN_TEXT = ['plain-text', 'source', 'json', 'html', 'exec', 'markdown'];
export const NEW_FOLDER_DEFAULT_NAME = 'Новая папка';

export const EMPTY_FILE_HASH = '0000000000000000000000000000000000000000';
