import classNames from 'clsx';
import React, { type ReactElement, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_SOCIAL_USER } from 'reactApp/appHelpers/configHelpers';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { BillingSelectors } from 'reactApp/modules/billing/billing.selectors';
import { CardSelectors } from 'reactApp/modules/creditCard/creditCard.selectors';
import { getLoadingState } from 'reactApp/modules/loading/loading.selectors';
import { openRenewSubscription } from 'reactApp/modules/payment/payment.module';
import { SubscriptionRenewalActions } from 'reactApp/modules/subscriptionRenewal/subscriptionRenewal.module';
import { updateSubscriptionsRequest } from 'reactApp/modules/subscriptions/subscriptions.module';
import type { ISubscription } from 'reactApp/modules/subscriptions/subscriptions.types';
import {
    type TSendAnalytics,
    ESubscriptionModalAction,
} from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal.analytics';
import type { RootState } from 'reactApp/store';
import type { Product } from 'reactApp/types/Billing';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { createGaSender } from 'reactApp/utils/ga';
import { isYear2Period } from 'reactApp/utils/Period';
import type { AnyAction } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';

import styles from './SubscriptionFooter.css';

interface IProps {
    subscription: ISubscription;
    product: Product;
    sendAnalytics: TSendAnalytics;
}

const sendBillingGa = createGaSender('billing');

export const SubscriptionFooter = memo(({ subscription, product, sendAnalytics }: IProps): ReactElement | null => {
    const { id, autorenewal, status, renewable } = subscription;
    const dispatch: ThunkDispatch<RootState, Record<string, unknown>, AnyAction> = useDispatch();

    const { isLoading: isRenewLoading } = useSelector(BillingSelectors.getLoadingState);
    const { card, isLoading: isCardLoading } = useSelector(CardSelectors.getCard);

    const isRenewable = status === 'ACTIVE' && renewable && !isYear2Period(product?.period);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isSubscriptionsLoading = useSelector((state: RootState) => getLoadingState(state, [updateSubscriptionsRequest.toString()]));

    const handleOnRenewClick = useCallback((): void => {
        sendBillingGa('renew-click');
        sendAnalytics(ESubscriptionModalAction.prolongSubs);
        dispatch(openRenewSubscription({ subscriptionId: id }));
    }, [sendAnalytics, dispatch, id]);

    const handleOnAutoRenewCancelClick = useCallback((): void => {
        dispatch(SubscriptionRenewalActions.cancelAutoRenew({ subscription, product, sendAnalytics }));
    }, [sendAnalytics, product, subscription, dispatch]);

    const handleOnAutoRenewAddClick = useCallback(() => {
        dispatch(SubscriptionRenewalActions.addAutoRenew({ sendAnalytics, card, subscription }));
    }, [sendAnalytics, dispatch, card, subscription]);

    return (
        <div
            className={classNames({
                [styles.root]: true,
            })}
        >
            {isRenewable && (
                <Button
                    data-name="renew"
                    theme="vk"
                    primary
                    sizeMode={ButtonSizeMode.small}
                    onClick={handleOnRenewClick}
                    disabled={isRenewLoading || isSubscriptionsLoading || isCardLoading}
                    className={styles.button}
                >
                    Продлить подписку
                </Button>
            )}
            {autorenewal && (
                <Button
                    data-name="renew-cancel"
                    theme="vk"
                    middle
                    onClick={handleOnAutoRenewCancelClick}
                    disabled={isCardLoading}
                    loading={isRenewLoading || isSubscriptionsLoading}
                    sizeMode={ButtonSizeMode.small}
                    className={classNames(styles.button, {
                        [styles.button_rebranding]: isRebranding,
                    })}
                >
                    Отключить автоплатёж
                </Button>
            )}
            {!autorenewal && !IS_SOCIAL_USER && (
                <Button
                    data-name="renew-enable"
                    theme="vk"
                    middle
                    primary
                    onClick={handleOnAutoRenewAddClick}
                    disabled={isCardLoading}
                    loading={isRenewLoading || isSubscriptionsLoading}
                    sizeMode={ButtonSizeMode.small}
                    className={styles.button}
                >
                    Включить автоплатёж
                </Button>
            )}
        </div>
    );
});

SubscriptionFooter.displayName = 'SubscriptionFooter';
