import classNames from 'clsx';
import React, { type ReactElement, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { seoEnable } from 'reactApp/appHelpers/featuresHelpers/features/seoEnable';
import { SharingDropdown } from 'reactApp/components/SharingWindow/Dropdown/SharingDropdown';
import { EXPIRES_OPTIONS, QA_VALUE, TOOLTIP_OFFSET } from 'reactApp/components/SharingWindow/Sharing.constants';
import { sendGa } from 'reactApp/components/SharingWindow/Sharing.helpers';
import { ExpiryPeriod } from 'reactApp/components/SharingWindow/Sharing.types';
import styles from 'reactApp/components/SharingWindow/Weblink/SharingWeblink.css';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getItemTypeName } from 'reactApp/modules/file/utils';
import { deleteWeblinkExpiresRequest, updateWeblinkExpiresRequest } from 'reactApp/modules/modifying/modifying.actions';
import { useDisableSeoTooltip } from 'reactApp/ui/DisableSeoTooltip/hooks/useDisableSeoTooltip';
import { Dropdown } from 'reactApp/ui/Dropdown/Dropdown';
import { DropdownMenu } from 'reactApp/ui/Dropdown/DropdownMenu';
import { tooltipPlacements } from 'reactApp/ui/Tooltip/Tooltip.constants';
import { formatExpiresDropdown } from 'reactApp/utils/formatDate';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

export const useExpires = (item) => {
    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const isPhone = useSelector(EnvironmentSelectors.isPhone);
    const expires = item?.weblinkExpires;
    const weblinkSeoEnabled = Boolean(item?.weblinkSeoEnabled && seoEnable);
    const itemName = getItemTypeName(item);

    const { tooltip, handleShowTip } = useDisableSeoTooltip(ref, itemName, weblinkSeoEnabled);

    const renderExpiresDropdown = useCallback(
        (onClick): ReactElement => {
            const list = Object.values(EXPIRES_OPTIONS).map((item) => ({
                id: item.id,
                text: item.text,
                check: !expires && item.id === ExpiryPeriod.Unlimited,
                qaValue: item.id,
            }));

            // После установки TTL, он показывается в выпадающем списке 1м элементом
            if (expires) {
                list.unshift({
                    text: `До ${formatExpiresDropdown(expires)}`,
                    id: ExpiryPeriod.Expiry,
                    check: true,
                    qaValue: ExpiryPeriod.Expiry,
                });
            }

            return <DropdownMenu list={list} theme="octavius" onClick={onClick} className={styles.dropdownMenu} />;
        },
        [expires]
    );

    const handleUpdateExpires = useCallback(
        (id): void => {
            const expires = EXPIRES_OPTIONS[id];

            if (id === ExpiryPeriod.Expiry) {
                return;
            }

            sendGa('expires');
            sendPaymentGa({
                eventCategory: ECategoryGa.public,
                action: 'edit-ttl',
                time_ttl: id,
                public_id: item?.weblink,
            });

            if (expires.id === ExpiryPeriod.Unlimited) {
                dispatch(deleteWeblinkExpiresRequest({ item }));

                return;
            }

            dispatch(updateWeblinkExpiresRequest({ item, expires: expires.getValue() }));
        },
        [dispatch, item]
    );

    return useCallback((): ReactElement => {
        const item = EXPIRES_OPTIONS[ExpiryPeriod.Unlimited];
        const text = expires ? `До ${formatExpiresDropdown(expires)}` : item?.text;

        return (
            <div
                className={classNames(styles.rightItem, { [styles.rightItem_disabled]: weblinkSeoEnabled })}
                data-qa-item={QA_VALUE.expiresItem}
                onClick={handleShowTip}
            >
                <span className={styles.rightTitle}>Ссылка действует</span>
                <div className={styles.dropdown} ref={ref}>
                    <Dropdown
                        withinDialog
                        theme="octavius"
                        qaValue={item.id}
                        as={SharingDropdown}
                        value={isPhone ? text : text.toLowerCase()}
                        content={renderExpiresDropdown}
                        onClickItem={handleUpdateExpires}
                        placement={tooltipPlacements.BOTTOM_LEFT}
                        qaValueContent={QA_VALUE.expiresDropdownContent}
                        tooltipOffset={TOOLTIP_OFFSET}
                        animatedTooltip
                        description="Ссылка действует"
                        tooltipClassName={isPhone ? styles.tooltip : undefined}
                        isPhone={isPhone}
                        noPageOffset
                        disabled={weblinkSeoEnabled}
                    />
                </div>
                {tooltip}
            </div>
        );
    }, [expires, handleUpdateExpires, isPhone, renderExpiresDropdown, weblinkSeoEnabled, Boolean(tooltip)]);
};
