// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { createSelector } from '@reduxjs/toolkit';
import {
    Icon20CalendarOutline,
    Icon20HomeArrowDownOutline,
    Icon20MailOutline,
    Icon20MailStackOutline,
    Icon20ReplyOutline,
} from '@vkontakte/icons';
import React, { type ReactElement } from 'react';
import { IS_B2B_BIZ_USER, IS_DOCUMENTS_DOMAIN, IS_MY_TEAM } from 'reactApp/appHelpers/configHelpers';
import { BUSINESS_TEMPLATES_ENABLED } from 'reactApp/appHelpers/featuresHelpers/features/businessTemplates';
import { IS_DOCUMENTS_ICONS_REDESIGN_ENABLED } from 'reactApp/appHelpers/featuresHelpers/features/documentIconsRedesign';
import { attachFoldersRequest } from 'reactApp/modules/attaches/attaches.actions';
import { AttachesSelectors } from 'reactApp/modules/attaches/attaches.selectors';
import { selectAllBusinessTemplates } from 'reactApp/modules/businessTemplates/businessTemplates.selectors';
import {
    getFeatureAlbumsPage,
    getFeatureAttachesEnabled,
    getFeatureDocuments,
    getFeaturePublicAutoDeletePromo,
} from 'reactApp/modules/features/features.selectors';
import { getDiscountGiftList } from 'reactApp/modules/giftReceived/giftReceived.selectors';
import { loadHomeFolderRequest } from 'reactApp/modules/home/home.actions';
import { getHomeFoldersTree } from 'reactApp/modules/home/home.selectors';
import { sortDiscountPromoBySpace } from 'reactApp/modules/promoTariffs/promoTariffs.selectors';
import { getCurrentRouteId, getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { isDomainFolder } from 'reactApp/modules/storage/folder.helpers';
import { isIntegrationStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { RootState } from 'reactApp/store';
import type { ESelectFolderDialogMod } from 'reactApp/ui/SelectFolderDialog/SelectFolderDialog.types';
import { EDocumentsDomainTreeRootIds, ETreeNodeMode, ETreeRootIds } from 'reactApp/ui/TreeComponent/TreeComponent.constants';
import {
    treeItemsFlatten,
    treeRootAllDocumentsConfig,
    treeRootAllDocumentsDomainConfig,
} from 'reactApp/ui/TreeComponent/TreeComponent.data';
import {
    DocumentRedesignIcon,
    DocumentRedesignIconDark,
    PdfRedesignIcon,
    PdfRedesignIconDark,
    PresRedesignIcon,
    PresRedesignIconDark,
    TableRedesignIcon,
    TableRedesignIconDark,
} from 'reactApp/ui/VKUIIcons';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';

import type { TreeNodeData } from './TreeNode.types';

export const getCurrentTreeState = (state): { id: string; storage: EStorageType } => {
    const currentStorage = getCurrentStorage(state) as EStorageType;
    const currentRouteId = getCurrentRouteId(state);
    let currentId = '';

    if (currentStorage) {
        if (
            currentStorage === EStorageType.favorites ||
            currentStorage === EStorageType.trashbin ||
            currentStorage === EStorageType.sharedIncoming ||
            currentStorage === EStorageType.sharedLinks ||
            currentStorage === EStorageType.recommend ||
            currentStorage === EStorageType.start ||
            currentStorage === EStorageType.gallery ||
            currentStorage === EStorageType.sharedAutodelete ||
            currentStorage === EStorageType.feed ||
            currentStorage === EStorageType.documents ||
            currentStorage === EStorageType.albums ||
            currentStorage === EStorageType.incomingPublic
        ) {
            currentId = `/${currentStorage}`;
        } else if (currentStorage === EStorageType.attaches) {
            currentId = SettingsSelectors.getQueryParams(state)?.folderId || `/${EStorageType.attaches}`;
        } else {
            currentId = currentRouteId;
        }

        currentId = currentStorage + currentId;
    }

    return { id: currentId, storage: currentStorage };
};

const getAttachesFolderIcon = ({ type }: { type: string }): ReactElement | null => {
    switch (type) {
        case 'inbox':
            return <Icon20MailOutline />;
        case 'sent':
            return <Icon20ReplyOutline />;
        case 'newsletters':
            return <Icon20MailStackOutline />;
        case 'tomyself':
            return <Icon20HomeArrowDownOutline />;
        case 'user':
            return <Icon20CalendarOutline />;
        default:
            return null;
    }
};

export const getFoldersTree = createSelector(
    (state, items: TreeNodeData[]): TreeNodeData[] => items,
    // @ts-ignore
    (state, _, dialogMod?: ESelectFolderDialogMod, skipRoFolders = false) => getHomeFoldersTree(state, dialogMod, skipRoFolders),
    getFeatureAttachesEnabled,
    (state) => IS_B2B_BIZ_USER && AttachesSelectors.getAttachesFoldersTreeNodes(state, getAttachesFolderIcon),
    getCurrentStorage,
    (state) => {
        if (!IS_B2B_BIZ_USER) {
            return null;
        }
        const incomingFolders = IS_B2B_BIZ_USER && getHomeFoldersTree(state).filter((item) => item.kind === 'mounted');
        return getHomeFoldersTree(state).filter(isDomainFolder).concat(incomingFolders);
    },
    getFeaturePublicAutoDeletePromo,
    getFeatureDocuments,
    getFeatureAlbumsPage,
    (state) => !sortDiscountPromoBySpace(state).length && !getDiscountGiftList(state).length,
    // eslint-disable-next-line complexity
    (
        items: TreeNodeData[],
        homeTree,
        isFeatureAttachesEnabled,
        attachItems,
        storage,
        incomingDomainFolders,
        isFeaturePublicAutoDeletePromo,
        isFeatureDocuments,
        isFeatureAlbumsPage,
        showDiscount
        // НЕ добавлять в аргументы state!
    ): TreeNodeData[] => {
        items = [...items];

        let idx = items.findIndex((item) => item?.id === ETreeRootIds.home);
        if (idx >= 0) {
            items[idx] = {
                ...items[idx],
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                children: homeTree,
            };
            if (IS_B2B_BIZ_USER) {
                items[idx].title = 'Личные папки';
                items[idx].children = items[idx].children?.filter((item) => !isDomainFolder(item) && item.kind !== 'mounted');
            }
        }

        // у storage === integration отображаем только хомяк
        if (isIntegrationStorage(storage)) {
            return [items[idx]];
        }

        idx = items.findIndex((item) => item?.id === ETreeRootIds.domain);
        if (idx >= 0) {
            if (IS_B2B_BIZ_USER) {
                if (incomingDomainFolders && incomingDomainFolders.length > 0) {
                    items[idx] = {
                        ...items[idx],
                        children: incomingDomainFolders,
                    };
                }
            } else {
                items.splice(idx, 1);
            }
        }

        idx = items.findIndex((item) => item?.id === ETreeRootIds.attaches);

        if (idx >= 0 && IS_B2B_BIZ_USER) {
            if (isFeatureAttachesEnabled) {
                items[idx] = {
                    ...items[idx],
                    children: attachItems,
                };
            } else {
                items = items.filter((item) => item?.id !== ETreeRootIds.attaches);
            }
        }

        idx = items.findIndex((item) => item?.id === ETreeRootIds.recommend);
        if (idx >= 0 && IS_B2B_BIZ_USER) {
            items = items.filter((item) => item?.id !== ETreeRootIds.recommend);
        }

        idx = items.findIndex((item) => item?.id === ETreeRootIds.gallery);
        if (idx >= 0 && IS_MY_TEAM) {
            items = items.filter((item) => item?.id !== ETreeRootIds.gallery);
        }

        idx = items.findIndex((item) => item?.id === ETreeRootIds.start);

        if (idx >= 0 && !!items[idx].children && IS_MY_TEAM) {
            items[idx].children = items[idx]?.children?.filter((item) => item?.id !== ETreeRootIds.gallery);
        }

        if (idx >= 0 && !!items[idx].children && !isFeaturePublicAutoDeletePromo) {
            items[idx].children = items[idx]?.children?.filter((item) => item?.id !== ETreeRootIds.sharedAutodelete);
        }

        if (idx >= 0 && !!items[idx].children && !isFeatureDocuments) {
            items[idx].children = items[idx]?.children?.filter((item) => item?.id !== ETreeRootIds.documents);
        }

        if (idx >= 0 && !!items[idx].children && !isFeatureAlbumsPage) {
            items[idx].children = items[idx]?.children?.filter((item) => item?.id !== ETreeRootIds.albums);
        }

        idx = items.findIndex((item) => item?.id === ETreeRootIds.promocodes);

        if (idx >= 0 && showDiscount) {
            items = items.filter((item) => item?.id !== ETreeRootIds.promocodes);
        }

        if (IS_MY_TEAM) {
            items = items.filter((item) => item.id !== ETreeRootIds.recommend);
        }

        return items;
    }
);

export const loadFolder = async ({ id, storage, dispatch }): Promise<void> => {
    if (storage === EStorageType.attaches) {
        dispatch(attachFoldersRequest());
        return;
    }

    if (id === ETreeRootIds.domain || id === ETreeRootIds.promocodes) {
        return;
    }

    if (storage === EStorageType.home) {
        dispatch(loadHomeFolderRequest({ id, isFolder: true }));
    }
};

/**
 * Сторы доступные для открытия бокового меню по прямому пути
 */
const AVAILABLE_TO_FORCE_OPEN_STORAGE_FROM_START_AND_INCOMING = [
    EStorageType.sharedLinks,
    EStorageType.sharedIncoming,
    EStorageType.feed,
    EStorageType.favorites,
    EStorageType.gallery,
    EStorageType.albums,
    EStorageType.sharedAutodelete,
    EStorageType.documents,
];

export const checkForceOpenFoldersOnThePath = (
    id: string,
    selectedStorage: EStorageType,
    selectedId: string,
    children?: TreeNodeData[],
    mod?: ETreeNodeMode
) => {
    const { pathname, search } = window?.location || {};
    const _selectedId = selectedId.match(/(\d+)/)?.[0];

    /**
     * Раскрываем аттачи (Из почты) в случае присутствия search в URI.
     * @example /attaches/?folderId=0
     */
    if (selectedStorage === EStorageType.attaches && search && selectedId.includes(EStorageType.attaches) && _selectedId) {
        return children?.some((c) => c.id === _selectedId) || children?.some((c) => c.children?.some((cc) => cc.id === _selectedId));
    }
    /**
     * Раскрываем аттачи (Из почты) только в случае отсутсвия search в URI.
     * @example /attaches
     */
    if (selectedStorage === EStorageType.attaches && !search && id === ETreeRootIds.attaches) {
        return true;
    }

    /**
     * Раскрываем папку в хомяке внутри диалога выбора файлов для альбома.
     */
    if (mod === ETreeNodeMode.albumsDlg && selectedStorage === EStorageType.home) {
        return selectedId.startsWith(`${selectedStorage}${id}`);
    }

    if (
        /**
         * Раскрываем Хомяк (Все файлы) только в случае содержании в pathname минимум одной 1 папку после /home.
         * @example /home/folder
         */
        selectedStorage === EStorageType.home &&
        pathname?.split('/').filter(Boolean).length > 1
    ) {
        return decodeURIComponent(`${pathname}${search}`).includes(id);
    }

    /**
     * Раскрываем стартовую страницу (Быстрый доступ или /start) только для определенных хранилищ.
     * Частный случай это путь /incoming он нужен для корректной работы shared/incoming и shared/links
     */
    return (
        (id === ETreeRootIds.start || id === ETreeRootIds.incoming) &&
        AVAILABLE_TO_FORCE_OPEN_STORAGE_FROM_START_AND_INCOMING.includes(selectedStorage)
    );
};

const updateIconsForRedesign = (treeConfig: TreeNodeData[]): TreeNodeData[] => {
    if (!IS_DOCUMENTS_ICONS_REDESIGN_ENABLED) {
        return treeConfig;
    }

    const isDarkTheme = isDarkThemeModeEnabled();

    return treeConfig.map((item) => {
        switch (item.id) {
            case EDocumentsDomainTreeRootIds.alldocumentsDomainDocument:
                return {
                    ...item,
                    icon: isDarkTheme ? <DocumentRedesignIconDark /> : <DocumentRedesignIcon />,
                };
            case EDocumentsDomainTreeRootIds.alldocumentsDomainSpreadsheet:
                return {
                    ...item,
                    icon: isDarkTheme ? <TableRedesignIconDark /> : <TableRedesignIcon />,
                };
            case EDocumentsDomainTreeRootIds.alldocumentsDomainPresentation:
                return {
                    ...item,
                    icon: isDarkTheme ? <PresRedesignIconDark /> : <PresRedesignIcon />,
                };
            case EDocumentsDomainTreeRootIds.alldocumentsDomainPdf:
                return {
                    ...item,
                    icon: isDarkTheme ? <PdfRedesignIconDark /> : <PdfRedesignIcon />,
                };
            default:
                return item;
        }
    });
};

/**
 * Функция создания конфига для страницы документов.
 *
 * @param {string[]} hiddenSections Секции, которые нужно скрыть.
 *
 * @return {TreeNodeData[]} Итоговый конфиг.
 */
export const getAllDocumentsTreeConfig = (hiddenSections: string[]): TreeNodeData[] => {
    const defaultConfig = IS_DOCUMENTS_DOMAIN
        ? [...updateIconsForRedesign(treeRootAllDocumentsDomainConfig)]
        : [...treeRootAllDocumentsConfig];

    return hiddenSections.length
        ? defaultConfig.filter(({ id }) => !hiddenSections.includes(id.replace('/alldocuments/', '').replace(/^\//, '')))
        : defaultConfig;
};

/**
 * Добавляет в дерево в сайдбаре итем бизнесовых шаблонов если нужно
 * @param tree дерево в сайдбаре
 * @param businessTemplates стор бизнесовых шаблонов
 */
export const insertBusinessTemplatesItemIfNeeded = (tree: TreeNodeData[], state: RootState) => {
    if (!BUSINESS_TEMPLATES_ENABLED || !selectAllBusinessTemplates(state).length) {
        return;
    }

    const trashbinIndex = tree.findIndex(({ id }) => id === ETreeRootIds.trashbin);
    const templatesIndex = tree.findIndex(({ id }) => id === ETreeRootIds.templates);
    const insertPosition = trashbinIndex < 0 ? tree.length - 1 : trashbinIndex;

    const item = {
        ...treeItemsFlatten[ETreeRootIds.templates],
        children: state.businessTemplates.categoriesList.map((category) => ({
            id: `${ETreeRootIds.templates}/${category.id}`,
            storage: EStorageType.templates,
            title: category.name,
            href: `/templates/${category.id}`,
            parent: '/templates',
            hideIcon: true,
            hideHint: true,
            clickDwh: {
                section: `templates/${category.id}`,
            },
        })),
    };

    if (templatesIndex >= 0) {
        tree[templatesIndex] = {
            ...tree[templatesIndex],
            ...item,
        };
    } else {
        tree.splice(insertPosition, 0, item);
    }
};
