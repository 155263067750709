import { createAction } from '@reduxjs/toolkit';
import type {
    ILoadMoreRequestData,
    PublicCheckPin,
    PublicDeletePin,
    PublicSetPin,
    PublicUpdatePin,
    WeblinkDownloadsCounts,
} from 'reactApp/modules/public/public.types';
import type { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { EPaymentGa } from 'reactApp/utils/paymentGa';
import type { IPublicApiFolder } from 'server/types/context/IPublicFolder';

export const loadPublicPage = createAction<IPublicApiFolder>('public/load/page');
export const loadPublicFileSuccess = createAction<IPublicApiFolder>('public/load/loadFileSuccess');
export const loadPublicFolder = createAction<IPublicApiFolder>('public/load/folder');
export const loadMorePublicFolderRequest = createAction<ILoadMoreRequestData>('public/loadMore/request');
export const loadMoreToFileRequest = createAction<{ folderId: string; fileId: string }>('public/loadMoreToFile/request');
export const loadPublicFolderFail = createAction<{ id: string; error: string }>('public/load/folderFail');
export const loadMorePublicFolderSuccess = createAction<IPublicApiFolder>('public/loadMore/success');
export const loadFolderStart = createAction<{ id: string }>('public/load/loadFolderStart');
export const loadFolderRequest = createAction<{ id: string; storage: EStorageType }>('public/load/loadFolderRequest');
export const updateFolderRequest = createAction<{ id: string; fileId?: string }>('public/load/updateFolderRequest');
export const updateFolderSuccess = createAction<IPublicApiFolder>('public/load/updateFolderSuccess');

export const forceShowDownloadAppButton = createAction('public/show/forceDownloadAppButton');
export const getPublicInfo = createAction('public/get/info');
export const updateCountDownloads = createAction<WeblinkDownloadsCounts>('public/updateCountDownloads');
export const markAsSentOpenDwhRadar = createAction('public/markAsSentOpenDwhRadar');
export const redirectFromViewerToFolder = createAction<{ redirectFromViewer: boolean }>('public/show/redirectFromViewerToFolder');
export const sendPublicAnalyticsAction = createAction<{ id: string; action?: EPaymentGa }>('public/show/sendPublicAnalyticsAction');
export const getPublicSharingInfo = createAction<{ public_id: string }>('public/get/sharing/info');

export const setPublicPin = createAction<PublicSetPin>('public/pin/set');
export const deletePublicPin = createAction<PublicDeletePin>('public/pin/delete');
export const updatePublicInfoPin = createAction<PublicUpdatePin>('public/info/pin/update');
export const checkPublicPin = createAction<PublicCheckPin>('public/pin/check');
export const publicPinBuySubscription = createAction('public/buy');
export const publicBuyDownloadSubscription = createAction('public/buy/download');
export const publicDisableDownload = createAction<{ id: string; disableDownload: boolean }>('public/disable/download');
