import { isFeature } from 'Cloud/Application/FeaturesEs6';
import { sendAppReady } from 'lib/app-ready';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    ANONYM_USER,
    EMBEDDED,
    IS_B2B_BIZ_USER,
    IS_MOBILE_BROWSER,
    IS_MY_TEAM,
    IS_VKT_WEBVIEW,
    IS_WEBVIEW,
    PLATFORM,
    X_PAGE_ID,
} from 'reactApp/appHelpers/configHelpers';
import { logUnknownFrameOrigins } from 'reactApp/appHelpers/featuresHelpers/features/logUnknownFrameOrigins';
import { loadDomainFoldersStartRequest } from 'reactApp/modules/domainFolders/domainFolders.actions';
import { setPhListner } from 'reactApp/modules/ph/ph.thunkActions';
import { initPromoController } from 'reactApp/modules/promo/promo.module';
import { applicationStart } from 'reactApp/modules/router/router.module';
import { loadSettingsRequest } from 'reactApp/modules/settings/settings.module';
import { loadUser } from 'reactApp/modules/user/user.thunkActions';
import { loadUserQuotaRequest } from 'reactApp/modules/userQuota/userQuota.module';
import { showWelcome } from 'reactApp/modules/welcome/welcome.module';
import { sendKaktamLog } from 'reactApp/utils/ga';

const initAuthGate = () => {
    const isVkSignInEnabled = isFeature('cloud-vk-signin-enable');
    const authGate = window.__AG;

    if (isVkSignInEnabled && authGate) {
        authGate.setSettings({ cloud_vk_sign_in: 'true' });
    }
};

export const useInit = ({ setIsLoading }: { setIsLoading: (isLoading: boolean) => void }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        sendAppReady();
        dispatch(applicationStart());
        initAuthGate();

        (dispatch(loadUser()) as any).finally(() => {
            dispatch(initPromoController());
            dispatch(setPhListner());
            dispatch(loadSettingsRequest());
            dispatch(loadUserQuotaRequest());
            dispatch(loadDomainFoldersStartRequest());

            setIsLoading(false);

            dispatch(showWelcome());
        });

        try {
            if (
                logUnknownFrameOrigins &&
                typeof window.location?.ancestorOrigins !== 'undefined' &&
                window.location?.ancestorOrigins?.length > 0
            ) {
                const top = window.location.ancestorOrigins.item(0);

                const knownFrames = [
                    'https://e.mail.ru',
                    'https://mail.ru',
                    'https://cloud.mail.ru',
                    'https://doc.mail.ru',
                    'https://datacloudmail.cloud.mail.ru',
                    'https://vk.com',
                    'https://vk.ru',
                    'https://m.vk.com',
                    'https://away.vk.com',
                    'https://tutoria.vk.com',
                    'https://edu-web.sferum.ru',
                    'https://sferum.ru',
                    'https://teams.vk.com',
                    '.vkuserphoto.ru',
                    '.minivk.ru',
                    '.userapi.com',
                    '.eljur.ru',
                ];

                if (knownFrames.some((domain) => top?.includes(domain))) {
                    return;
                }

                sendKaktamLog(
                    {
                        text: `we are framed`,
                        origins: Array.from(window.location?.ancestorOrigins).join(),
                        top,
                        location: location.pathname,
                        pageId: X_PAGE_ID,
                        isPhone: IS_MOBILE_BROWSER,
                        isB2B: IS_B2B_BIZ_USER,
                        IS_MY_TEAM,
                        IS_VKT_WEBVIEW,
                        IS_WEBVIEW,
                        ANONYM_USER,
                        EMBEDDED,
                        PLATFORM,
                    },
                    'cloud_frame'
                );
            }
        } catch (_) {}
    }, []);
};
