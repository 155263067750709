import debounce from 'lodash.debounce';
import type { Kind } from 'reactApp/types/Tree';
import { type IRadarOptions, sendKaktamLog, sendXray } from 'reactApp/utils/ga';
import type QualityLevelList from 'videojs-contrib-quality-levels/src/quality-level-list';

import { sendViewerDwh } from '../ReactViewer.helpers';
import type { VideoPlayerResponse, VideoPlayerWithPlugins, VideoPlayerXhrOptions, VideoPlayerXMLHttpRequest } from './VideoPlayer.types';

function sendVideoplayerXray(
    names: string | Array<string | undefined>,
    iData: Record<string, number> | string | null = null,
    options: IRadarOptions = {}
) {
    sendXray(['video-player', ...names], iData, options);
}

export type GaData = {
    source: string;
    is_stories: boolean;
    have_face: boolean;
    id_public: string;
    type_content: Kind;
    extension: string;
    is_archive: boolean;
    is_attache: boolean;
    name: string;
    id_media?: string;
    is_touch: boolean;
    size_files?: number;
} & Record<string, any>;

export interface FirstSegmentLoadingData {
    video_master_pl_loading_time?: number;
    video_media_pl_loading_time?: number;
    video_first_segments_loading_time?: number;
    video_prepare_waiting_time?: number;
}

type IAction = 'on' | 'off';

type ISubscriber = (action: IAction) => void;

export class VideoPlayerGa {
    wasStarted = false;
    wasSeeked = false;
    waiting = false;
    waitingStarted: number | null;
    masterPlaylistLoadingStart: number;
    mediaPlaylistLoadingStart: number;
    segmentLoadingStart: number;
    videoLoadingStart: number;
    firstSegmentLoadingInfo: FirstSegmentLoadingData;
    isUserChangeQuality = false;
    lastQualityHeight = 0;
    qualityLevels: QualityLevelList;
    lastVolume: number;
    progress: Element;
    playbackRateNative: { (rate: number): void; (): number };
    lastRate = 1;
    subscribers: ISubscriber[];

    constructor(private player: VideoPlayerWithPlugins, private data: GaData) {
        this.wasStarted = this.player.hasStarted();
        this.waitingStarted = null;
        this.masterPlaylistLoadingStart = 0;
        this.mediaPlaylistLoadingStart = 0;
        this.segmentLoadingStart = 0;

        /**
         * Событие loadstart возникает только после загрузки мастер-плейлиста, что не позволяет корректно измерить общее время загрузки.
         * Поэтому считаем, что загрузка видео начинается в момент создания объекта аналитики плеера
         */
        this.videoLoadingStart = Date.now();

        this.firstSegmentLoadingInfo = {};
        this.qualityLevels = this.player.qualityLevels();
        this.lastVolume = this.player.volume();
        this.progress = this.player.controlBar.$('.vjs-progress-control');
        this.playbackRateNative = this.player.playbackRate.bind(this.player);

        this.subscribers = [
            this.qualitySubscriptions.bind(this),
            this.networkSubscriptions.bind(this),
            this.playSubscriptions.bind(this),
            this.volumeSubscriptions.bind(this),
            this.loopSubscriptions.bind(this),
            this.pipSubscriptions.bind(this),
            this.rewindSubscriptions.bind(this),
            this.playbackSubscriptions.bind(this),
            this.errorSubscriptions.bind(this),
            this.disposeSubscriptions.bind(this),
            this.freezingSubscriptions.bind(this),
            this.stallingSubscriptions.bind(this),
        ];

        sendVideoplayerXray(['render']);
    }

    subscribe() {
        this.wasStarted = this.player.hasStarted();
        this.lastVolume = this.player.volume();
        this.lastRate = this.player.playbackRate();

        this.subscribers.forEach((sub) => sub('on'));
    }

    unsubscribe() {
        this.subscribers.forEach((sub) => sub('off'));
    }

    private onUserChangeQuality = () => {
        this.isUserChangeQuality = true;
    };

    private getCurrentQuality = () => {
        return this.qualityLevels[this.qualityLevels.selectedIndex]?.height;
    };

    private onQualityChanged = () => {
        const previoseQuality = this.lastQualityHeight;
        const selectedQuality = this.getCurrentQuality();
        if (!selectedQuality) {
            return;
        }
        this.lastQualityHeight = selectedQuality;

        sendViewerDwh({
            ...this.data,
            action: 'quality-change',
            type_change: this.isUserChangeQuality ? 'manual' : 'auto',
            quality_last: previoseQuality,
            quality_now: selectedQuality,
            bandwidth: this.player.tech(true).vhs?.bandwidth,
            throughput: this.player.tech(true).vhs?.throughput,
        });
        this.isUserChangeQuality = false;
    };

    private onStart = () => {
        this.onQualityChanged();
        this.qualityLevels.on('change', this.onQualityChanged);
        this.player.off('play', this.onStart);
    };

    private qualitySubscriptions(action: IAction) {
        this.player[action]('changequality', this.onUserChangeQuality);

        if (action === 'off') {
            this.player.off('play', this.onStart);
            this.qualityLevels.off('change', this.onQualityChanged);
        } else if (this.wasStarted) {
            this.onQualityChanged();
            this.qualityLevels.on('change', this.onQualityChanged);
        } else {
            this.player.on('play', this.onStart);
        }
    }

    private isMasterPlaylistRequest(url: string) {
        return Boolean(url.match(/.+\/0p\/.+/));
    }

    private isVideoMediaPlaylistRequest(url: string) {
        return Boolean(url.match(/.+\/(240|360|480|720|1080)p_1\/.+/));
    }

    private isAudioMediaPlaylistRequest(url: string) {
        return Boolean(url.match(/.+\/audio\/.+/));
    }

    private isVideoSegmentRequest(url: string) {
        return Boolean(url.match(/.+\/(240|360|480|720|1080)p_.+_1\/.+/));
    }

    private isAudioSegmentRequest(url: string) {
        return Boolean(url.match(/.+\/audio_.+_0\/.+/));
    }

    private beforeSend = (xhr: VideoPlayerXMLHttpRequest) => {
        const { url } = xhr;

        if (this.isMasterPlaylistRequest(url)) {
            this.masterPlaylistLoadingStart = Date.now();
        } else if (this.isVideoMediaPlaylistRequest(url) || this.isAudioMediaPlaylistRequest(url)) {
            this.mediaPlaylistLoadingStart = Date.now();
        } else if (this.isVideoSegmentRequest(url) || this.isAudioSegmentRequest(url)) {
            this.segmentLoadingStart = Date.now();
        }
    };

    private requestHook = (options: VideoPlayerXhrOptions) => {
        options.beforeSend = this.beforeSend;
        return options;
    };

    private masterPlaylistResponseHook = () => {
        const query_time = Date.now() - this.masterPlaylistLoadingStart;
        const query_type = 'master_pl';
        this.firstSegmentLoadingInfo = {
            ...this.firstSegmentLoadingInfo,
            video_master_pl_loading_time: query_time,
        };

        sendVideoplayerXray(['loading', 'master_pl'], {
            'loading-time': query_time,
        });
        sendViewerDwh({
            ...this.data,
            action: 'query',
            query_time,
            query_type,
            bandwidth: this.player.tech(true).vhs?.bandwidth,
            throughput: this.player.tech(true).vhs?.throughput,
        });
    };

    private mediaPlaylistResponseHook = () => {
        const query_time = Date.now() - this.mediaPlaylistLoadingStart;
        const query_type = 'media_pl';

        this.firstSegmentLoadingInfo = {
            ...this.firstSegmentLoadingInfo,
            video_media_pl_loading_time: query_time,
        };

        sendVideoplayerXray(['loading', 'media_pl'], {
            'loading-time': query_time,
        });
        sendViewerDwh({
            ...this.data,
            action: 'query',
            query_time,
            query_type,
            bandwidth: this.player.tech(true).vhs?.bandwidth,
            throughput: this.player.tech(true).vhs?.throughput,
        });
    };

    private segmentResponseHook = () => {
        const query_time = Date.now() - this.segmentLoadingStart;
        const query_type = 'segment';
        this.firstSegmentLoadingInfo = {
            ...this.firstSegmentLoadingInfo,
            video_first_segments_loading_time: query_time,
        };
        const quality = this.getCurrentQuality();

        sendVideoplayerXray(['loading', 'segment'], {
            'loading-time': query_time,
        });
        sendViewerDwh({
            ...this.data,
            action: 'query',
            query_time,
            query_type,
            quality,
            bandwidth: this.player.tech(true).vhs?.bandwidth,
            throughput: this.player.tech(true).vhs?.throughput,
        });
    };

    private responseHook = (request: VideoPlayerXMLHttpRequest, _error: Error | null, _response: VideoPlayerResponse) => {
        const { url } = request;

        if (this.isMasterPlaylistRequest(url)) {
            this.masterPlaylistResponseHook();
        } else if (this.isVideoMediaPlaylistRequest(url) || this.isAudioMediaPlaylistRequest(url)) {
            this.mediaPlaylistResponseHook();
        } else if (this.isVideoSegmentRequest(url) || this.isAudioSegmentRequest(url)) {
            this.segmentResponseHook();
        }
    };

    private onXhrHooksReady = () => {
        this.player.tech(true).vhs?.xhr.onRequest(this.requestHook);
        this.player.tech(true).vhs?.xhr.onResponse(this.responseHook);
    };

    private onLoadedData = () => {
        /**
         * Так как на мобилках везде есть автовоспроизведение видео, а на вебе нет,
         * то для того, чтобы отправлять метрики в кейсе "загрузился первый сегмент, но пользователь не нажал play",
         * переносим событие media-start на момент готовности к воспроизведению
         */

        /* tempexp_18347-start */
        this.firstSegmentLoadingInfo = {
            ...this.firstSegmentLoadingInfo,
            video_prepare_waiting_time: Date.now() - this.videoLoadingStart,
        };

        const startTime = this.player.currentTime();

        // Добавляем погрешность на секунду
        if (startTime <= 1) {
            sendVideoplayerXray(['started', 'beginning']);
            sendViewerDwh({
                ...this.data,
                ...this.firstSegmentLoadingInfo,
                action: 'media-start',
                start_from: 'beginning',
                bandwidth: this.player.tech(true).vhs?.bandwidth,
                throughput: this.player.tech(true).vhs?.throughput,
            });
        } else {
            sendVideoplayerXray(['started', 'middle'], {
                'start-time': startTime,
            });
            sendViewerDwh({
                ...this.data,
                ...this.firstSegmentLoadingInfo,
                action: 'media-start',
                start_from: 'middle',
                start_time: startTime,
                bandwidth: this.player.tech(true).vhs?.bandwidth,
                throughput: this.player.tech(true).vhs?.throughput,
            });
        }
        /* tempexp_18347-end */
    };

    private networkSubscriptions(action: IAction) {
        this.player[action]('xhr-hooks-ready', this.onXhrHooksReady);
        this.player[action]('loadeddata', this.onLoadedData);
    }

    private onPlay = () => {
        if (this.wasSeeked) {
            this.wasSeeked = false;
            return;
        }

        if (this.wasStarted) {
            sendViewerDwh({ ...this.data, action: 'media-continue' });
        } else {
            this.wasStarted = true;
        }
    };

    private onPause = () => {
        if (this.player.seeking()) {
            this.wasSeeked = true;
            return;
        }

        const ended = this.player.ended();

        sendViewerDwh({
            ...this.data,
            action: 'media-stop',
            type_stop: ended ? 'end' : 'user',
            bandwidth: this.player.tech(true).vhs?.bandwidth,
            throughput: this.player.tech(true).vhs?.throughput,
        });
    };

    private onSeeked = () => {
        const seekTime = this.player.currentTime();

        sendVideoplayerXray(['seeked'], {
            'seek-time': seekTime,
        });
        sendViewerDwh({
            ...this.data,
            action: 'seeked',
            seek_time: seekTime,
        });
    };

    private playSubscriptions(action: IAction) {
        this.player[action]('play', this.onPlay);
        this.player[action]('pause', this.onPause);
        this.player[action]('seeked', this.onSeeked);
    }

    private volumeChangeLog = () => {
        sendViewerDwh({
            ...this.data,
            action: 'sound-level-change',
            sound_level_last: this.lastVolume,
            sound_level_now: this.player.volume(),
        });
        this.lastVolume = this.player.volume();
    };

    private onVolumeChange = debounce(this.volumeChangeLog, 300);

    private volumeSubscriptions(action: IAction) {
        this.player[action]('volumechange', this.onVolumeChange);
    }

    private onLoopChange = () => {
        sendViewerDwh({ ...this.data, action: 'reload-change' });
    };

    private loopSubscriptions(action: IAction) {
        this.player[action]('loopchange', this.onLoopChange);
    }

    private onEnterPIP = () => {
        sendViewerDwh({ ...this.data, action: 'pip-open' });
    };

    private onLeavePIP = () => {
        sendViewerDwh({ ...this.data, action: 'pip-close' });
    };

    private pipSubscriptions(action: IAction) {
        this.player[action]('enterpictureinpicture', this.onEnterPIP);
        this.player[action]('leavepictureinpicture', this.onLeavePIP);
    }

    private onRewindBackward = () => {
        sendViewerDwh({ ...this.data, action: 'quickly-move', type_move: 'minus' });
    };

    private onRewindForward = () => {
        sendViewerDwh({ ...this.data, action: 'quickly-move', type_move: 'plus' });
    };

    private onProgressChange = () => {
        sendViewerDwh({ ...this.data, action: 'rewind-change' });
        this.progress.removeEventListener('pointerup', this.onProgressChange);
    };

    private onProgressPointerDown = () => {
        this.progress.addEventListener('pointerup', this.onProgressChange);
    };

    private rewindSubscriptions = (action: IAction) => {
        this.player[action]('rewindbackward', this.onRewindBackward);
        this.player[action]('rewindforward', this.onRewindForward);
        if (action === 'off') {
            this.progress.removeEventListener('pointerdown', this.onProgressPointerDown);
            this.progress.removeEventListener('pointerup', this.onProgressChange);
        } else {
            this.progress.addEventListener('pointerdown', this.onProgressPointerDown);
        }
    };

    private onPlaybackRate = (rate?: number) => {
        if (rate && rate !== this.lastRate) {
            sendViewerDwh({
                ...this.data,
                action: 'speed-change',
                speed_last: this.lastRate,
                speed_now: rate,
            });
            this.lastRate = rate;
        }

        if (rate !== undefined) {
            return this.playbackRateNative(rate);
        }
        return this.playbackRateNative();
    };

    private playbackSubscriptions = (action: IAction) => {
        if (action === 'off') {
            this.player.playbackRate = this.playbackRateNative;
        } else {
            this.playbackRateNative = this.player.playbackRate.bind(this.player);

            // @ts-ignore
            this.player.playbackRate = this.onPlaybackRate;
        }
    };

    private onError = () => {
        const error = this.player.error();

        if (!error?.code) {
            // Кастомные ошибки (не из самого плеера)
            return;
        }

        sendViewerDwh({
            ...this.data,
            action: 'media-error',
            type_error: error?.code.toString(),
            error_message: error?.message,
        });
        sendVideoplayerXray([
            'error',
            this.data.extension,
            error?.code.toString(),
            this.data.is_attache ? 'attache' : '',
            this.data.is_archive ? 'archive' : '',
        ]);
        sendKaktamLog(
            {
                ...this.data,
                action: 'media-error',
                label: error?.code.toString(),
                error_message: error?.message,
            },
            'cloud_video-player'
        );
    };

    private errorSubscriptions = (action: IAction) => {
        this.player[action]('error', this.onError);
    };

    private onDispose = () => {
        if (!this.player.hasStarted()) {
            return;
        }

        const ranges = this.player.played();
        let allTime = 0;

        for (let i = 0; i < ranges.length; i++) {
            allTime += ranges.end(i) - ranges.start(i);
        }

        const duration = this.player.duration();

        let watch_percent, percent_close;
        if (duration && duration !== Infinity) {
            watch_percent = Math.floor((allTime / duration) * 100);
            percent_close = Math.floor((this.player.currentTime() / duration) * 100);
        }

        sendViewerDwh({
            ...this.data,
            action: 'mediafile-close',
            percent_close,
            watch_percent,
            bandwidth: this.player.tech(true).vhs?.bandwidth,
            throughput: this.player.tech(true).vhs?.throughput,
        });
    };

    private disposeSubscriptions = (action: IAction) => {
        this.player[action]('dispose', this.onDispose);
    };

    private onWaiting = () => {
        this.waiting = true;
        this.waitingStarted = Date.now();
    };

    private onCanPlay = () => {
        if (!this.waiting || !this.waitingStarted) {
            return;
        }

        const waiting_time = Date.now() - this.waitingStarted;

        sendVideoplayerXray(['waiting'], {
            'waiting-time': waiting_time,
        });

        sendViewerDwh({
            ...this.data,
            action: 'video-segment-waiting',
            waiting_time,
            bandwidth: this.player.tech(true).vhs?.bandwidth,
            throughput: this.player.tech(true).vhs?.throughput,
        });

        this.waiting = false;
        this.waitingStarted = null;
    };

    private freezingSubscriptions(action: IAction) {
        this.player[action]('waiting', this.onWaiting);
        this.player[action]('canplay', this.onCanPlay);
    }

    private onStalled = () => {
        sendViewerDwh({
            ...this.data,
            action: 'video-stalled',
        });

        sendVideoplayerXray(['stalled']);
    };

    private stallingSubscriptions(action: IAction) {
        this.player[action]('stalled', this.onStalled);
    }
}
