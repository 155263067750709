import { plural } from 'lib/plural';

const names = {
    day: ['день', 'дня', 'дней'],
    hour: ['час', 'часа', 'часов'],
    minute: ['минута', 'минуты', 'минут'],
    second: ['секунда', 'секунды', 'секунд'],
};

const namesGenetive = {
    day: ['день', 'дня', 'дней'],
    hour: ['час', 'часа', 'часов'],
    minute: ['минуту', 'минуты', 'минут'],
    second: ['секунду', 'секунды', 'секунд'],
};

export const formatCountdown = (seconds: number, genetive = false) => {
    const day = seconds / 86400;
    const hour = seconds / 3600;
    const minute = seconds / 60;

    const time = {
        day: Math.ceil(day < 1 ? 0 : day),
        hour: Math.ceil(hour < 1 ? 0 : hour) % 24,
        minute: Math.ceil(minute < 1 ? 0 : minute) % 60,
        second: Math.ceil(seconds) % 60,
    };

    const formatUnitName = (unit, value) => {
        return (genetive ? namesGenetive : names)[unit][plural(value)];
    };

    // Оставляем только самую бОльшую часть времени.
    // Например, если есть минуты и секунды, то оставляем только минуты.
    const biggestUnit = Object.entries(time).filter((value) => value[1] !== 0)[0];

    if (!biggestUnit) {
        return `0 ${formatUnitName('second', 0)}`;
    }

    return `${biggestUnit[1]} ${formatUnitName(...biggestUnit)}`;
};
