import { _classCallCheck, _createSuper, _inherits, _super } from 'reactApp/ui/ReactViewer/VideoPlayer/utils/polyfills';
import type { VideoPlayer } from 'reactApp/ui/ReactViewer/VideoPlayer/VideoPlayer.types';
import videojs from 'video.js';

const Component = videojs.getComponent('Component');
const Button = videojs.getComponent('Button');

export type LoopButtonType = InstanceType<typeof Button>;

/**
 * Наслеюуем через прототипы из-за babel. См. комментарии к задаче
 * @link https://jira.vk.team/browse/CLOUDWEB-17571
 */
export const LoopButton = (function (Button) {
    _inherits(LoopButton, Button);
    const _super = _createSuper(LoopButton);
    function LoopButton(this: LoopButtonType, player: VideoPlayer, options?: videojs.ComponentOptions & { name?: string }): void {
        _classCallCheck(this, LoopButton);
        const _this = _super.call(this, player, options);
        _this.controlText(player.localize('Loop'));
        return _this;
    }
    return LoopButton;
})(Button);

LoopButton.prototype.buildCSSClass = function (this: LoopButtonType) {
    return 'vjs-loop-control '.concat(_super(LoopButton, 'buildCSSClass', this)());
};

LoopButton.prototype.handleClick = function handleClick(this: LoopButtonType) {
    if (!this.player_.loop()) {
        const ended = this.player_.ended();
        this.player_.trigger('loopchange');
        this.player_.loop(true);
        this.controlText(this.player_.localize('Non-Loop'));
        if (ended) {
            this.player_.play();
        }
        this.toggleClass('vjs-loop-enabled', true);
    } else {
        this.player_.trigger('loopchange');
        this.player_.loop(false);
        this.controlText(this.player_.localize('Loop'));
        this.toggleClass('vjs-loop-enabled', false);
    }
};

Component.registerComponent('LoopButton', LoopButton);
