import type { MYOFFICE_VARIANTS } from 'Cloud/Application/Editor/MyOffice/myOffice.types';
import { xray } from 'lib/xray';
import { last } from 'ramda';
import { ADS_TIMER_MS } from 'reactApp/constants';
import { getExtension } from 'reactApp/modules/file/helpers/getExtention';

const RE_EMAIL = new RegExp("^[\\w.\\-+!#$%&'*\\/=?^`{|}~]+@[\\w.\\-]+\\.[\\w]+$");

export const noop = (): null => null;
export const noopPromise = (_?: any) => Promise.resolve();
export const noopUndefined = (): undefined => undefined;
export const noopTrue = () => true;
// eslint-disable-next-line no-empty-function
export const noopVoid = () => {};

export const emptyArray = [];

export function shallowDiffers(a, b): boolean {
    for (const i in a) {
        if (!(i in b)) {
            return true;
        }
    }
    for (const i in b) {
        if (a[i] !== b[i]) {
            return true;
        }
    }
    return false;
}

/**
 * Проверяет, является ли строка допустимым емейлом
 *
 * @param {string} email - email
 * @return {boolean} true, если строка валидный емейл
 */
export const validateEmail = (email: string): boolean => {
    if (!email) {
        return false;
    }

    email = email.replace(/(^\s+|\s+$)/g, '');

    if (!email.length || email.includes(' ')) {
        return false;
    }

    return RE_EMAIL.test(email);
};

/**
 * Проверяет, находится ли домен емейла в белом списке
 *
 * @param {string} email - email
 * @param {string[]} whiteList - domains list
 * @return {boolean} true, если домен емейла в белом списке
 */
export const validateEmailWhiteListDomain = (email, whiteList: string[]): boolean => {
    if (!email || !Array.isArray(whiteList)) {
        return false;
    }

    const domain = last(email.toLowerCase().split('@'));

    return whiteList.includes(domain);
};

export const scrollToTop = (): void => window.scrollTo(0, 0);

export const pathJoin = (url = '', ...rest) => {
    rest.forEach((arg) => {
        if (!arg) {
            return;
        }

        const urlEndsWithSlash = url.charAt(url.length - 1) === '/';
        const argStartsWithSlash = arg.charAt(0) === '/';

        if (urlEndsWithSlash && argStartsWithSlash) {
            url = `${url}${arg.slice(1)}`;
        } else if (!urlEndsWithSlash && !argStartsWithSlash) {
            url = `${url}/${arg}`;
        } else {
            url = `${url}${arg}`;
        }
    });

    return url;
};

const parseURL = (function () {
    let URLsupport;
    try {
        URLsupport = location.origin && new URL('//cloud.mail.ru/a/', location.origin);
        URLsupport = URLsupport.pathname === '/a/' && URLsupport.origin === `${location.protocol}//cloud.mail.ru`;
    } catch (error) {
        URLsupport = false;
    }

    let element;

    return URLsupport
        ? function (url) {
              const urlObj = new URL(url, location.origin);

              return {
                  origin: urlObj.origin,
                  search: urlObj.search,
                  pathname: urlObj.pathname.replace(/\/{2,}/g, '/'),
              };
          }
        : function (url) {
              if (!element) {
                  element = document.createElement('a');
              }

              element.href = url;

              return {
                  origin: `${element.protocol}//${element.hostname}`,
                  search: element.search,
                  pathname: `/${element.pathname}`.replace(/\/{2,}/g, '/'),
              };
          };
})();

export const getClipboadText = (event) => {
    if (event && (event.clipboardData || event.originalEvent.clipboardData)) {
        return (event.originalEvent || event).clipboardData.getData('text/plain');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
    } else if (window.clipboardData) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return window.clipboardData.getData('Text');
    }
    return '';
};

export const execCommand = (command, data) => document.execCommand(command, false, data);

export const promisifyDeferredCall = (apiFunc, ...args) =>
    new Promise(function (resolve, reject) {
        apiFunc(...args)
            .done(function (data) {
                resolve(data);
            })
            .fail(function (...err) {
                reject(err);
            });
    });

export const generateUuidV4 = (): string => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

export { parseURL };

export const isPromise = (obj: any): obj is Promise<any> => {
    return obj && typeof (obj as Promise<any>).then === 'function';
};

/* Находит ближайщее снизу к val значение из массива values */
export const roundCustom = (val, values: any[], labels: any[] = []) => {
    let res = 0;
    for (let i = 0; i < values?.length; i++) {
        const item = values[i];
        if (val >= item) {
            res = labels[i] ?? item;
        } else {
            break;
        }
    }

    return res;
};

export function normalizeSymbols(name: string, replacementSymbol = '-') {
    return name?.toString()?.replace(/[^a-zA-Z0-9-]/gi, replacementSymbol);
}

export const sendPerfMetrics = (nameCategory) => {
    nameCategory = normalizeSymbols(nameCategory);

    const sendTime = (name, val) => {
        const ms = Math.round(val - performance.timing.connectStart);
        if (ms < 60000 && ms >= 0) {
            xray.send(`${nameCategory}_${name}_time`, { i: { ms } });
            const groupVal = roundCustom(Math.round(ms), [0, 500, 1000, 2000, 3000, 4000, 5000, 7500, 10000, 20000]);
            xray.send(`${nameCategory}_${name}_hist_${groupVal}`);
        }
    };

    if (performance) {
        sendTime('conEnd', performance.timing.connectEnd);
        sendTime('resEnd', performance.timing.responseEnd);
        sendTime('domLoading', performance.timing.domLoading);
        sendTime('domloaded', performance.timing.domContentLoadedEventEnd);
        sendTime('domint', performance.timing.domInteractive);
        sendTime('dom', performance.timing.domComplete);
        sendTime('pageload', performance.timing.loadEventStart);
    }
};

export const sendCurrTimeRadar = (name) => {
    if (window.performance?.timing?.connectStart) {
        const ms = Math.min(Math.round(Date.now().valueOf() - window.performance.timing.connectStart), 60000);
        if (ms >= 0) {
            xray.send(`${name}_time`, { i: { ms } });
        }
    }
};

export const promiseTimeout = (timeoutMs: number, returnValue = false, rejectOnTimeout = false) =>
    new Promise((resolve, reject) => {
        const id = setTimeout(() => {
            clearTimeout(id);
            if (rejectOnTimeout) {
                reject(returnValue);
            } else {
                resolve(returnValue);
            }
        }, timeoutMs);
    });

export const startAdsUpdateTime = (updateWorm: () => void, timeInterval: number = ADS_TIMER_MS) => {
    if (!updateWorm) {
        return;
    }

    let timerId;

    const updateAds = () => {
        if (document.visibilityState === 'visible') {
            updateWorm();
        }

        timerId = setTimeout(updateAds, timeInterval);
    };

    updateAds();

    return () => timerId && clearTimeout(timerId);
};

export const DOC_PLACEHOLDER_ID = 'DOC_PLACEHOLDER_ID';

export const getMyOfficeIframeId = (docId: string, officeVariant: MYOFFICE_VARIANTS): string => `${docId}-${officeVariant}`;

export function escapeForRegExp(value: string) {
    if (!value) {
        return value;
    }

    // eslint-disable-next-line no-useless-escape
    return value.replace(/([?!^$.(){}:|=[\]+\-\/\\*])/g, '\\$1');
}

export function filterFiles(files: File[]) {
    return files.filter((file) => file && file.name !== '' && file.type !== '' && isFile(file.name));
}

export function isFile(item: string) {
    return getExtension(item) !== '';
}
