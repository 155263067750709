import type { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { Kind, SubKind, Url } from 'reactApp/types/Tree';

export enum EIncomingPublicCategory {
    allDocuments = 'allDocuments',
}

export interface IncomingPublic {
    type: string;
    name: string;
    id: string;
    share_time: number;
    owner: {
        email: string;
    };
}

export interface IncomingPublicItem {
    id: string;
    parent: string;
    ext: string;
    nameWithoutExt: string;
    name: string;
    home: string;
    author?: string;
    isFolder: boolean;
    kind: Kind;
    subKind: SubKind;
    ctime: number;
    hash: string;
    storage: EStorageType.incomingPublic;
    isReadOnly: boolean;
    weblinkDownloadable: boolean;
    weblink: string;
    url?: Url;
    thumbnails?: {
        [key: string]: string;
    };
}

export interface WeblinksIncomingApiResponse {
    cursor?: string;
    links: IncomingPublic[];
}

export interface LoadIncomingPublicOpts {
    limit?: number;
    cursor?: string;
    exts?: string;
}

export interface IncomingPublicState {
    cursor: string;
    hasMoreToLoad: boolean;
    error: boolean;
    isLoaded: boolean;
    isLoading: boolean;
    category: EIncomingPublicCategory | null;
    list: Record<string, IncomingPublicItem>;
}

export interface ILoadIncomingPublicRequest {
    category: EIncomingPublicCategory;
}
