import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { FileConflict } from 'reactApp/modules/uploading/errors/FileConflict';
import { FileTooLargeError } from 'reactApp/modules/uploading/errors/FileTooLargeError';
import { ConnectionFail } from 'reactApp/modules/uploading/fails/ConnectionFail';
import { FileExistsFail } from 'reactApp/modules/uploading/fails/FileExistsFail';
import { HashCalcError } from 'reactApp/modules/uploading/fails/HashCalcError';
import { OverQuotaFail } from 'reactApp/modules/uploading/fails/OverQuotaFail';
import { PublicFileSizeLimit } from 'reactApp/modules/uploading/fails/PublicFileSizeLimit';
import { ReadOnlyDirectoryFail } from 'reactApp/modules/uploading/fails/ReadOnlyDirectoryFail';
import { RetryUploadFileFail } from 'reactApp/modules/uploading/fails/RetryUploadFileFail';
import { UserFileSizeLimitFail } from 'reactApp/modules/uploading/fails/UserFileSizeLimitFail';
import { ViolatedFilenameFail } from 'reactApp/modules/uploading/fails/ViolatedFilenameFail';
import { sendGaUploaderNew } from 'reactApp/modules/uploading/helpers/uploading.helpers';
import { handleFileExistsError } from 'reactApp/modules/uploading/sagas/handleErrors/handleFileExistsError';
import { handleFileSizeLimitModal } from 'reactApp/modules/uploading/sagas/handleErrors/handleFileSizeLimitModal';
import { handleOverQuotaError } from 'reactApp/modules/uploading/sagas/handleErrors/handleOverQuotaError';
import { handleReadOnlyDirectory } from 'reactApp/modules/uploading/sagas/handleErrors/handleReadOnlyDirectory';
import { MAX_RETRIES_COUNT } from 'reactApp/modules/uploading/serviceClasses/Uploader';
import type { UploadingDescriptor } from 'reactApp/modules/uploading/serviceClasses/UploadingDescriptor';
import { uploadingLog } from 'reactApp/modules/uploading/serviceClasses/UploadingLog';
import { uploadingService } from 'reactApp/modules/uploading/serviceClasses/UploadingService';
import { ERetryErrorFileOptions, EUploadingState } from 'reactApp/modules/uploading/uploading.types';
import { EFileError, EFileStatus } from 'reactApp/modules/uploadList/uploadList.model';
import { setUserFileSizeLimit, updateUploadFilesAction } from 'reactApp/modules/uploadList/uploadList.module';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { call, put, select } from 'redux-saga/effects';

import { handleViolatedFileNameError } from './handleErrors/handleViolatedFileNameError';

export function* processFileUploadError(error: Error, descriptor: UploadingDescriptor) {
    const isPaidUser = yield select(UserSelectors.isPaidUser);
    const isCorp = yield select(UserSelectors.isCorpUser);

    const isExeption = error instanceof Error;
    const isConnectionFail = error instanceof ConnectionFail;
    const isUploadApiFileConflict = error instanceof FileConflict;
    const isGatewayFail = isConnectionFail && error.isGatewayProblem();
    const isBadGateway = isConnectionFail && error.isBadGateway();
    const isServerError = isConnectionFail && error.isServerError();
    const isOverQuotaFail = error instanceof OverQuotaFail;
    const noOwnSpace = error instanceof OverQuotaFail && error.noOwnSpace;
    const isFileExistsFail = error instanceof FileExistsFail;
    const isHashCalcFail = error instanceof HashCalcError;
    const isUserFileSizeLimitFail = error instanceof UserFileSizeLimitFail;
    const isFileTooLargeError = error instanceof FileTooLargeError;
    const isReadOnlyDirectoryFail = error instanceof ReadOnlyDirectoryFail;
    const isPublicFileSizeLimit = error instanceof PublicFileSizeLimit;
    const isViolatedFilenameFail = error instanceof ViolatedFilenameFail;
    const cloudPath = descriptor.cloudPath;
    const descriptorId = descriptor.id;

    let retryResult: ERetryErrorFileOptions = ERetryErrorFileOptions.cancel;

    const limitedRetryError = isBadGateway || isGatewayFail || isHashCalcFail || isServerError;

    const stopLimitedRetries = limitedRetryError && descriptor.retryCount >= MAX_RETRIES_COUNT;

    if (
        error instanceof RetryUploadFileFail ||
        isUploadApiFileConflict ||
        (limitedRetryError && descriptor.retryCount < MAX_RETRIES_COUNT)
    ) {
        descriptor.shouldRetry = true;
        sendGaUploaderNew(`retry-${descriptor.retryCount}`);
        if (limitedRetryError) {
            sendGaUploaderNew(`retry-limited-${descriptor.retryCount}`);
        }
        return ERetryErrorFileOptions.shouldRetry;
    }

    if (stopLimitedRetries) {
        sendGaUploaderNew('retry-max-limit');
    }

    if (isUserFileSizeLimitFail) {
        yield put(setUserFileSizeLimit(error.limit));
    }

    const isUserFileSizeLimit =
        isUserFileSizeLimitFail && !isOverQuotaFail && !(descriptor.uploadingPacketConfig.storage === EStorageType.public || isCorp);

    const isOverQuota =
        (isPublicFileSizeLimit && descriptor.uploadingPacketConfig.isOwnPublic) ||
        isOverQuotaFail ||
        ((isFileTooLargeError || isUserFileSizeLimitFail) && !isPaidUser);

    if (isExeption) {
        // Остальные ошибки уже залогировал descriptor.
        uploadingLog.error(error);
    }

    const isFileSizeOverPublicLimit = (descriptor.file?.size || 0) > descriptor.uploadingPacketConfig.publicUploadLimit;

    if (isPublicFileSizeLimit && !descriptor.uploadingPacketConfig.isOwnPublic) {
        yield put(
            updateUploadFilesAction({
                descriptorId,
                cloudPath,
                status: EFileStatus.ERROR,
                error: EFileError.OVER_QUOTA_CLOUD_AT_OWNER,
            })
        );
    }

    if (isUserFileSizeLimit || (isPublicFileSizeLimit && isFileSizeOverPublicLimit)) {
        let uploadLimit = descriptor.uploadingPacketConfig.userFileSizeLimit;

        if (isFileSizeOverPublicLimit) {
            uploadLimit = descriptor.uploadingPacketConfig.publicUploadLimit;
        }

        const filesOverLimit = uploadingService.getDescriptorsOverLimit(uploadLimit);
        retryResult = yield call(handleFileSizeLimitModal, { descriptor, filesOverLimit, uploadLimit });
    } else if (isOverQuota) {
        retryResult = yield call(handleOverQuotaError, descriptor, noOwnSpace);
    } else if (isFileTooLargeError || isUserFileSizeLimitFail) {
        descriptor.failPaused();
        yield put(
            updateUploadFilesAction({
                descriptorId,
                cloudPath,
                status: EFileStatus.ERROR,
                error: EFileError.USER_FILE_SIZE_LIMIT,
            })
        );
    } else if (isFileExistsFail) {
        retryResult = yield call(handleFileExistsError, descriptor);
    } else if (isReadOnlyDirectoryFail) {
        retryResult = yield call(handleReadOnlyDirectory, descriptor);
    } else if (descriptor.state === EUploadingState.STATE_CANCEL) {
        yield put(
            showSnackbarAction({
                text: 'Загрузка отменена',
                id: 'uploaderror',
                type: SnackbarTypes.failure,
                closable: true,
            })
        );
    } else if (isViolatedFilenameFail) {
        retryResult = yield call(handleViolatedFileNameError, descriptor);
    } else if (stopLimitedRetries || (!isGatewayFail && !isServerError && !isBadGateway && !isConnectionFail)) {
        yield put(
            showSnackbarAction({
                text: 'Ошибка загрузки',
                id: 'uploaderror',
                type: SnackbarTypes.failure,
                closable: true,
            })
        );
    }

    if (isConnectionFail && !stopLimitedRetries) {
        retryResult = ERetryErrorFileOptions.connectionErrorRetry;
    }

    return retryResult;
}
