import { getFeature, isFeature } from 'Cloud/Application/FeaturesEs6';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

export const beforePurchaseEnabled = isFeature('before-purchase-enabled');
export const beforePurchaseHeader: string | undefined = getFeature('before-purchase-header');
export const beforePurchaseMessage: string | undefined = getFeature('before-purchase-message');
export const beforePurchaseButtonText: string | undefined = getFeature('before-purchase-button');

registerExperiment(
    'before-purchase',
    beforePurchaseEnabled ? 'on' : 'off',
    [
        AnalyticEventNames.BEFORE_PURCHASE_MESSAGE_CLICK,
        AnalyticEventNames.BEFORE_PURCHASE_MESSAGE_CLOSE,
        AnalyticEventNames.BEFORE_PURCHASE_MESSAGE_SHOW,
        AnalyticEventNames.BEFORE_PURCHASE_MESSAGE_STATUS,
    ],
    {
        mapEventNames: {
            BEFORE_PURCHASE_MESSAGE_SHOW: 'show',
            BEFORE_PURCHASE_MESSAGE_CLICK: 'click',
            BEFORE_PURCHASE_MESSAGE_CLOSE: 'close',
            BEFORE_PURCHASE_MESSAGE_STATUS: 'status',
        },
    }
);
