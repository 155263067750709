import React, { type FC, useMemo } from 'react';
import type { AttachesItem } from 'reactApp/modules/attaches/attaches.types';
import type { CloudFile } from 'reactApp/modules/storage/storage.types';
import { CloseMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/CloseMenuButton/CloseMenuButton';
import { DownloadMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/DownloadMenuButton/DownloadMenuButton';
import { MenuInfo } from 'reactApp/ui/ReactViewer/ViewerMenu/MenuInfo/MenuInfo';
import { MenuLogo } from 'reactApp/ui/ReactViewer/ViewerMenu/MenuLogo/MenuLogo';
import { MenuItemName, MoreMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/MoreMenuButton/MoreMenuButton';
import {
    type ViewerMenuProps,
    ViewerMenuCenter,
    ViewerMenuEnd,
    ViewerMenuStart,
    ViewerMenuWrapper,
} from 'reactApp/ui/ReactViewer/ViewerMenu/ViewerMenu';
import { Zoomer } from 'reactApp/ui/ReactViewer/ViewerMenu/Zoomer/Zoomer';

interface Props extends ViewerMenuProps {
    file: CloudFile | AttachesItem;
}

export const AlbumsMenu: FC<Props> = ({
    file,
    showLogo,
    zoom,
    decreaseZoom,
    increaseZoom,
    showDownload,
    onDownload,
    enableFavorites,
    onToggleFavorites,
    onDelete,
    showClose,
    onClose,
}) => {
    const isInFavorites = 'isInFavorites' in file && !!file.isInFavorites;
    const moreItems = useMemo(
        () => [
            ...(enableFavorites
                ? [
                      {
                          name: MenuItemName.FAVORITE,
                          active: isInFavorites,
                          divider: true,
                          onClick: () => {
                              onToggleFavorites(file);
                          },
                      },
                  ]
                : []),
            {
                name: MenuItemName.DELETE,
                onClick: () => {
                    onDelete(file);
                },
            },
        ],
        [isInFavorites, enableFavorites, onToggleFavorites, onDelete]
    );

    return (
        <ViewerMenuWrapper>
            <ViewerMenuStart>
                {showLogo && <MenuLogo />}
                <MenuInfo file={file} />
            </ViewerMenuStart>
            <ViewerMenuCenter>{zoom && <Zoomer value={zoom} decrease={decreaseZoom} increase={increaseZoom} />}</ViewerMenuCenter>
            <ViewerMenuEnd>
                {showDownload && (
                    <DownloadMenuButton
                        file={file}
                        onClick={() => {
                            onDownload(file);
                        }}
                    />
                )}
                <MoreMenuButton list={moreItems} />
                {showClose && (
                    <CloseMenuButton
                        onClick={() => {
                            onClose(file);
                        }}
                    />
                )}
            </ViewerMenuEnd>
        </ViewerMenuWrapper>
    );
};
