import classnames from 'clsx';
import React, { type ChangeEvent, type MouseEvent, memo, useCallback, useRef } from 'react';
import { cyberMonday2025 } from 'reactApp/appHelpers/featuresHelpers/features/cyberMonday2025';

import styles from './Switch.css';
import type { SwitchProps } from './Switch.types';

export const Switch = memo<SwitchProps>(
    ({
        name,
        value,
        disabled,
        checked,
        theme,
        onChange,
        isAwaysBlue = false,
        dark = false,
        gray = false,
        isAction = false,
        isAlwaysWhite = false,
        isRebrandingQuotaLanding = false,
        isRebranding = false,
        themeSlider,
        fromLanding,
    }) => {
        const input = useRef<HTMLInputElement>(null);

        const onClick = useCallback(
            (event: MouseEvent<HTMLDivElement> | ChangeEvent<HTMLInputElement>) => {
                if (disabled || !onChange) {
                    return;
                }

                event.stopPropagation();

                onChange();
            },
            [disabled, onChange]
        );

        const className = classnames({
            [styles.base]: true,
            [styles.base_disabled]: disabled,
            [styles[`base_${theme}`]]: !!theme,
            [styles.base_checked]: checked,
            [styles.base_blue]: isAwaysBlue,
            [styles.base_white]: isAlwaysWhite,
            [styles.base_dark]: dark,
            /* tempexp_17127-next-line */
            [styles.base_gray]: gray,
            [styles.base_action]: isAction,
            [styles.base_rebrandingQuotaLanding]: isRebrandingQuotaLanding,
            [styles.base_rebranding]: isRebranding,
            [styles[`theme_slider_${themeSlider}`]]: Boolean(themeSlider),
            [styles.base_cyberMonday2025]: cyberMonday2025 && fromLanding,
        });

        return (
            <div className={className} onClick={onClick}>
                <input
                    className={styles.input}
                    ref={input}
                    type="checkbox"
                    name={name}
                    value={value}
                    disabled={disabled}
                    checked={checked}
                    onChange={onClick}
                />
                <div className={styles.label}>
                    <div className={styles.slider} />
                </div>
            </div>
        );
    }
);

Switch.displayName = 'Switch';
