import { Icon20CloudArrowUpOutline } from '@vkontakte/icons';
import { ReactComponent as DropImage } from 'img/albums/drop.svg';
import React, { memo, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isDarkTheme } from 'reactApp/appHelpers/featuresHelpers';
import { inputType } from 'reactApp/appHelpers/featuresHelpers/features/uploadNewFileApi';
import { setUploadFromAction } from 'reactApp/modules/upload/upload.module';
import { getAllowedMimeTypes } from 'reactApp/modules/upload/upload.selectors';
import { useUploadInputHandlers } from 'reactApp/modules/uploading/hooks/useUploadInputHandlers';
import { sendGa } from 'reactApp/utils/ga';

import styles from '../EmptyFolder.css';
import { EItemType, gaSuffix } from '../EmptyFolder.types';

const getText = (isAlbum?: boolean) => {
    if (isAlbum) {
        return {
            title: 'Загрузить фото и видео',
            annotation: 'Нажмите или перетащите файлы для загрузки в этот альбом',
        };
    }

    return {
        title: 'Загрузить файлы',
        annotation: 'Вы также можете перетащить файлы для загрузки в эту папку',
    };
};

export const UploadComponent = memo(() => {
    const allowedMimeTypes = useSelector(getAllowedMimeTypes)?.join(',');
    const inputRef = useRef<HTMLInputElement>(null);

    useUploadInputHandlers({ input: inputRef });

    return (
        <div className={styles.icon}>
            <input type={inputType} multiple className={styles.input} ref={inputRef} accept={allowedMimeTypes} />
            <Icon20CloudArrowUpOutline />
        </div>
    );
});

UploadComponent.displayName = 'UploadComponent';

export const useUpload = (isAlbum?: boolean) => {
    const dispatch = useDispatch();

    const handleUpload = useCallback(() => {
        sendGa(gaSuffix, 'upload');
        dispatch(setUploadFromAction('empty-folder'));
    }, []);

    return useCallback(() => {
        return {
            ...getText(isAlbum),
            onClick: handleUpload,
            type: EItemType.UPLOAD,
            Icon: <UploadComponent />,
            additionLinks: isAlbum ? (
                <div className={styles.uploadArea}>
                    <DropImage
                        className={styles.uploadAreaDrop}
                        stroke={isDarkTheme ? '#616161' : '#000'}
                        stroke-opacity={isDarkTheme ? '1' : '0.2'}
                    />
                    <span className={styles.uploadAreaText}>
                        <a className={styles.link} onClick={handleUpload}>
                            Нажмите
                        </a>{' '}
                        или&nbsp;перенесите файлы для&nbsp;загрузки
                    </span>
                </div>
            ) : undefined,
        };
    }, [handleUpload, isAlbum]);
};
