import type { SpaceRecord } from './hooks/types';

export const getSpaceText = ({
    isMobile,
    used,
    remaining,
    userTotalSpace,
    isOverQuota,
}: { isMobile?: boolean } & Omit<SpaceRecord, 'usedPercent'>) => {
    const quota = isOverQuota ? `Занято ${used?.value || 0}` : `Свободно ${remaining?.value || 0}`;
    const from = isMobile ? ` из ${userTotalSpace?.value}` : '';

    return `${quota}${from}`;
};

export const getSubscriptionText = (isPaidUser: boolean, isMobile?: boolean) => {
    if (!isPaidUser) {
        return 'Бесплатный';
    }

    return isMobile ? '' : 'Мои подписки';
};

export const getSpaceState = (isAction?: boolean, isOverQuota?: boolean) => {
    if (isAction) {
        return 'action';
    }

    return isOverQuota ? 'overquota' : 'default';
};
