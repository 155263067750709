import { Text, Title } from '@vkontakte/vkui';
import React, { type ReactElement, memo, useCallback, useEffect } from 'react';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { getQuotaPromoUrl } from 'reactApp/sections/QuotaLanding/QuotaLanding.data';
import opener from 'reactApp/utils/opener';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';

import styles from './NoSpaceForOverquotaUserModal.css';

interface Props {
    onClose: () => void;
}

export const NoSpaceForOverquotaUserModal = memo(function NoSpaceForOverquotaUserModal(props: Props): ReactElement {
    useEffect(() => {
        sendPaymentGa({
            action: 'open',
            eventCategory: ECategoryGa.popup,
            name_banner: 'block_public_upload',
        });
    }, []);

    const renderHeader = useCallback(() => <Title className={styles.title}>Нет места для новых файлов</Title>, []);

    const renderContent = useCallback(
        () => (
            <Text className={styles.text}>Пространство переполнено. Увеличьте его с подпиской Mail Space или очистите от&nbsp;лишнего</Text>
        ),
        []
    );

    const onAddSpaceClick = useCallback(() => {
        sendPaymentGa({
            action: 'click',
            eventCategory: ECategoryGa.popup,
            name_banner: 'block_public_upload',
        });
        opener(
            getQuotaPromoUrl({
                query: 'utm_source=cloud&utm_medium=web_union_quota_landing&utm_campaign=block_public_popup&utm_term=17978',
            }),
            true
        );
    }, []);

    const onCleanSpaceClick = useCallback(() => {
        sendPaymentGa({
            action: 'click_cleaner',
            eventCategory: ECategoryGa.popup,
            name_banner: 'block_public_upload',
        });
        opener(
            getQuotaPromoUrl({
                query: 'utm_source=cloud&utm_medium=web_union_quota_landing&utm_campaign=block_public_popup_cleaner&utm_term=17978',
                hash: 'cleaner',
            }),
            true
        );
    }, []);

    const onClose = useCallback(() => {
        sendPaymentGa({
            action: 'close',
            eventCategory: ECategoryGa.popup,
            name_banner: 'block_public_upload',
        });
        props.onClose();
    }, [props]);

    const containsDarkTheme = isDarkThemeModeEnabled();

    return (
        <BaseConfirmDialog
            dataQAId="no-space-for-overquota-modal"
            renderHeader={renderHeader}
            successButtonText="Выбрать тариф"
            actionButtonText="Очистить место"
            buttons={[EButtonType.accept, EButtonType.action]}
            onClose={onClose}
            onSuccess={onAddSpaceClick}
            onAction={onCleanSpaceClick}
            renderContent={renderContent}
            closeOnDimmerClick
            isDarkTheme={containsDarkTheme}
            buttonTheme="vk"
        />
    );
});
