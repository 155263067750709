import { Button, classNames } from '@vkontakte/vkui';
import OverquotaIcon from 'img/overquota/public_overquota.png';
import OverquotaDarkIcon from 'img/overquota/public_overquota_dark.png';
import React, { type FC, memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isCurrentDarkTheme } from 'reactApp/modules/theme/theme.selectors';
import { EmptyComponent } from 'reactApp/ui/Empty/new/EmptyComponent';

import styles from './Empty.css';

const GoToButton: FC = () => {
    const handleGoTo = useCallback(() => open('/home', '_self'), []);

    return (
        <Button className={styles.button} mode="secondary" appearance="neutral" size="m" onClick={handleGoTo}>
            Перейти на главную
        </Button>
    );
};

GoToButton.displayName = 'GoToButton';

export const EmptyPublicOverquota = memo(({ forceDark = false, small = false }: { forceDark?: boolean; small?: boolean }) => {
    const isDark = useSelector(isCurrentDarkTheme) || forceDark;
    const Icon = isDark ? OverquotaDarkIcon : OverquotaIcon;

    const IconComponent = useMemo<FC>(() => {
        const Comp = () => {
            return (
                <img
                    src={Icon}
                    className={classNames(styles.icon, styles.iconPublicOverquota, {
                        [styles.smallIcon]: small,
                    })}
                />
            );
        };

        Comp.displayName = 'IconComponent';

        return Comp;
    }, [Icon, small]);

    return (
        <EmptyComponent
            title={<div className={styles.title_overquota}>Посмотреть файлы пока не&nbsp;получится</div>}
            description={
                <div className={styles.description_overquota}>
                    Облако переполнено — обратитесь&nbsp;к&nbsp;владельцу пространства, чтобы получить&nbsp;доступ
                </div>
            }
            IconComponent={IconComponent}
            BottomContent={GoToButton}
        />
    );
});

EmptyPublicOverquota.displayName = 'EmptyPublicOverquota';
