import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { openTariffBuy } from 'reactApp/modules/payment/payment.module';
import { authPopup } from 'reactApp/modules/ph/ph.thunkActions';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { sendWebviewAnalytics } from 'reactApp/sections/MobileQuotaTariffsLanding/MobileQuotaTariffsLanding.helpers';
import { EPostMessageBuy } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage.types';
import { getQuotaBillingSource, sendQuotaGa } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { revealPlatformPopup } from 'reactApp/sections/QuotaLanding/TariffsPlans/TariffPlansCard/TariffPlansCard.helpers';
import { showNoTariffDialog } from 'reactApp/sections/SubscriptionsPage/SubscriptionsPage.helpers';
import type { RootState } from 'reactApp/store';
import { sendAdmitadProduct } from 'reactApp/utils/admitadHelper';
import { sendTmrGoal, TMR3473039 } from 'reactApp/utils/tmr';
import { sendPostMessage } from 'reactApp/utils/windowHelper';

interface MobileInfo {
    isIOSPromo?: boolean;
    isAndroidPromo?: boolean;
    isMobilePayAvailable?: boolean;
}

type ProductFromQueryAgs = {
    mobileInfo?: MobileInfo;
    forceMobile?: boolean;
    availableAdmitad?: boolean;
};

export const useProductFromQuery = (props?: ProductFromQueryAgs, isRebrandingQuotaLanding?: boolean) => {
    const { mobileInfo = {}, forceMobile = false, availableAdmitad = false } = props || {};
    const dispatch = useDispatch();
    const isAnonymous = useSelector(UserSelectors.isAnonymous);
    const isMobile = useSelector(QuotaLandingSelectors.isMobile) || forceMobile;
    const queryParams = getQueryParams();
    const productFromQuery = useSelector((state: RootState) =>
        ProductsSelectors.getProductById(state, queryParams?.productId || queryParams?.tariffId)
    );

    const productsState = useSelector(ProductsSelectors.getLifeCycleState);

    const isFromAction = queryParams?.action === 'request-payment';
    const id = isFromAction ? productFromQuery?.id : '';
    const isFromActionAndLoaded = isFromAction && productsState.isLoaded;

    useEffect(() => {
        if (isFromAction && isAnonymous) {
            dispatch(
                authPopup({
                    closable: true,
                    loginRequest: true,
                    successPage: window.location.href,
                })
            );
            sendTmrGoal({ id: TMR3473039, goal: 'login-view' });
        }

        if (!isFromActionAndLoaded) {
            return;
        }

        if (!productFromQuery?.available || !id) {
            showNoTariffDialog();
            return;
        }

        const { isAndroidPromo, isIOSPromo, isMobilePayAvailable } = mobileInfo;

        if (mobileInfo && isMobile && (isAndroidPromo || isIOSPromo) && !isMobilePayAvailable) {
            revealPlatformPopup(id, Boolean(isIOSPromo), Boolean(isAndroidPromo), isRebrandingQuotaLanding);
            return;
        }

        const onSuccess = () => {
            if (availableAdmitad) {
                sendAdmitadProduct(productFromQuery);
            }

            const price = productFromQuery?.hasTrial ? 0 : productFromQuery?.discountPrice || productFromQuery?.price || 0;

            sendQuotaGa({ action: 'tariff', label: 'buy', tariff: id, amount: price });
            sendTmrGoal({ id: TMR3473039, goal: 'tariff-buy' });
            sendWebviewAnalytics('payok', Boolean(isMobilePayAvailable));

            if (isMobile) {
                sendPostMessage({
                    paymentResult: EPostMessageBuy.SUCCESS,
                    productId: id,
                    price,
                });
            }
        };

        dispatch(openTariffBuy({ productId: id, source: getQuotaBillingSource(), onSuccess, isMobile, paySource: 'query' }));
    }, [id, isFromActionAndLoaded]);
};
