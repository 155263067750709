import config from 'Cloud/config';
import $ from 'jquery';
import { sendXray } from 'reactApp/utils/ga';
import { captureException } from 'reactApp/utils/tracer';

export default function whatsUp() {
    const BUILD_URLS = config.get('BUILD_URLS');
    const INTERVAL = 5 * 60 * 1000;

    const handler = () => {
        $.getScript(`${BUILD_URLS.staticPath}js/WhatsUp.js?${Math.random()}`, function () {
            const whatsUp = window.WhatsUp;

            if (whatsUp instanceof Function && whatsUp.doc === 'ok') {
                sendXray('whatsup_ok');

                whatsUp();
            } else {
                sendXray('whatsup_error');

                captureException(null, {
                    message: 'WhatsUp',
                    issueKey: 'WhatsUp',
                    arguments: whatsUp.toString(),
                });
            }
        });

        window.setTimeout(handler, INTERVAL);
    };

    // раз в 5 минут проверяем WhatsUp
    window.setTimeout(handler, INTERVAL);
}
