import React, { type ReactElement, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ALLOWED_INDEX_TITLE } from 'reactApp/appHelpers/configHelpers';
import { seoEnable } from 'reactApp/appHelpers/featuresHelpers/features/seoEnable';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { changeStorageHistory, getStorage } from 'reactApp/modules/storage/storage.helpers';
import { getMobileBreadcrumbs, isRootCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import type { RootState } from 'reactApp/store';
import { Breadcrumbs } from 'reactApp/ui/Mobile/Breadcrumbs/Breadcrumbs';

interface BreadcrumbsContainerProps {
    className?: string;
}

export const BreadcrumbsContainer = memo(function BreadcrumbsContainer({ className }: BreadcrumbsContainerProps): ReactElement {
    const storage = useSelector(getCurrentStorage);
    const { isPublicOrStock } = getStorage(storage);
    const folder = useSelector((state: RootState) => getMobileBreadcrumbs(state, storage));
    const isRoot = useSelector((state: RootState) => isRootCurrentFolder(state, storage));

    const name = (folder && 'name' in folder && folder.name) || '';
    const parent = folder && 'parent' in folder ? folder?.parent : undefined;

    const useH1 = (folder && !!ALLOWED_INDEX_TITLE) || (seoEnable && folder && 'weblinkSeoEnabled' in folder && folder?.weblinkSeoEnabled);

    const onBack = useCallback(
        () =>
            changeStorageHistory({
                id: parent,
                storage,
            }),
        [parent, storage]
    );

    return (
        <Breadcrumbs
            className={className}
            showBackButton={!isRoot}
            showProtectedHint={isPublicOrStock}
            onClickBackButton={onBack}
            name={name}
            useH1={useH1}
        />
    );
});
