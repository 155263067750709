import { requestPayment } from 'Cloud/Application/Payment';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { quotaTooltip } from 'reactApp/appHelpers/featuresHelpers';
import { autumnPromo } from 'reactApp/appHelpers/featuresHelpers/features/autumnPromo';
import { blackFriday2024 } from 'reactApp/appHelpers/featuresHelpers/features/blackFriday2024';
import { cyberMonday2025 } from 'reactApp/appHelpers/featuresHelpers/features/cyberMonday2025';
import { abSpaceButton } from 'reactApp/appHelpers/featuresHelpers/features/spaceButton';
import { renderBizMigrationDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog.helpers';
import { PaymentUTM } from 'reactApp/constants/paymentUTM';
import { initFamilyInviteModal, initFamilyOnboarding } from 'reactApp/modules/family/family.actions';
import { isUserFamilyOwner } from 'reactApp/modules/family/family.selectors';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { loadUserQuotaRequest } from 'reactApp/modules/userQuota/userQuota.module';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { type TSendAnalytics, SpaceButtonAnalyticsName } from 'reactApp/ui/Space/SingleSpace/SingleMenu.analytics';
import type { TGoToSubs } from 'reactApp/ui/Space/Space.types';
import { sendXray } from 'reactApp/utils/ga';

type TUseCallbacks = (props: {
    isMobile: boolean;
    countdownTariff?: string;
    goToSubscriptions: TGoToSubs;
    sendAnalytics: TSendAnalytics;
    showFamilyButton?: boolean;
    analyticsName: string;
}) => {
    onPayment: () => void;
    onFamilyClick?: () => void;
    updateQuota: () => void;
};

export const useCallbacks: TUseCallbacks = ({ isMobile, countdownTariff, goToSubscriptions, sendAnalytics, analyticsName }) => {
    const isOldBizUser = useSelector(UserSelectors.isOldBizUser);
    const isPaidUser = useSelector(UserSelectors.isPaidUser);
    const isAlmostFull = useSelector(UserQuotaSelectors.isAlmostFull);
    const isFull = useSelector(UserQuotaSelectors.isFull);

    const dispatch = useDispatch();
    const isFamilyOwner = useSelector(isUserFamilyOwner);

    const onPayment = useCallback(() => {
        if (isOldBizUser) {
            renderBizMigrationDialog();
            return;
        }
        const isOverquota = isAlmostFull || isFull;
        const isPaid = isPaidUser && !isOverquota;
        let query = '';
        if (quotaTooltip && !isOverquota) {
            query = 'utm_source=cloud&utm_medium=web_union_quota_landing&utm_campaign=space_free_special_offer';
        }
        sendAnalytics('click', false, isOverquota ? `overquota_space_${isPaidUser ? 'paid' : 'free'}` : analyticsName);

        // tempexp_17990-start
        if (analyticsName === SpaceButtonAnalyticsName.SPACE) {
            emitAnalyticEvent(AnalyticEventNames.QUOTA_BLOCK_SPACE_BUTTON_CLICK);
        }
        // tempexp_17990-end

        if (isOverquota) {
            emitAnalyticEvent(AnalyticEventNames.QUOTA_BLOCK_OVER_CLICK);
            const query = isPaidUser ? PaymentUTM.spacePaidOverquota : PaymentUTM.spaceFreeOverquota;
            goToSubscriptions({
                isNewTariffsPage: true,
                // tempexp_17990-next-line
                query: `${query}${abSpaceButton ? '&utm_term=17990' : ''}`,
            });
            return;
        }

        // для мобилок пока открываем /subs
        if (isMobile) {
            let query = cyberMonday2025 ? PaymentUTM.cyberMonday2025SpaceMobile : PaymentUTM.spaceMobile;
            if (isPaid) {
                query = `${PaymentUTM.spacePaidMobile}&my_subscriptions=1`;
            }

            goToSubscriptions({
                isNewTariffsPage: !isPaid,
                query,
            });
            closePopupHelper(popupNames.MOBILE_LEFT_MENU);
            return;
        }

        if (autumnPromo) {
            goToSubscriptions({
                isNewTariffsPage: true,
                query: `utm_source=cloud&utm_medium=web_union_quota_landing&utm_campaign=space_free_autumn_action`,
            });
            return;
        }

        if (blackFriday2024) {
            goToSubscriptions({
                isNewTariffsPage: true,
                query: `utm_source=cloud&utm_medium=web_union_quota_landing&utm_campaign=space_free_black_friday`,
            });
            return;
        }

        if (cyberMonday2025) {
            goToSubscriptions({
                isNewTariffsPage: true,
                query: `utm_source=cloud&utm_medium=web_union_quota_landing&utm_campaign=space_free_cyber_monday`,
            });
            return;
        }

        // если есть countdown promo, то поднимаем оплату
        if (countdownTariff) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            requestPayment({
                tariff: countdownTariff,
                goToSubscriptions,
                paySource: 'space_discount',
            });
            return;
        }

        query = query || isPaid ? PaymentUTM.spacePaid : PaymentUTM.spaceFree;

        // tempexp_17990-next-line
        goToSubscriptions({
            isNewTariffsPage: !isPaid || abSpaceButton,
            // tempexp_17990-next-line
            query: `${query}${abSpaceButton ? '&utm_term=17990' : ''}`,
        });
    }, [isOldBizUser, sendAnalytics, isMobile, countdownTariff, isPaidUser, isAlmostFull, isFull, goToSubscriptions, analyticsName]);

    const onFamilyClick = useCallback(() => {
        sendAnalytics('click', true, SpaceButtonAnalyticsName.FAMILY);

        closePopupHelper(popupNames.MOBILE_LEFT_MENU);

        if (isFamilyOwner) {
            dispatch(initFamilyInviteModal());
        } else {
            dispatch(initFamilyOnboarding());
        }
    }, [isFamilyOwner, dispatch, sendAnalytics]);

    const updateQuota = useCallback(() => {
        sendXray(['space', 'reload', 'family', 'quota']);
        dispatch(loadUserQuotaRequest());
    }, []);

    return { onPayment, onFamilyClick, updateQuota };
};
