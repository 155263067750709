import { parse, stringify } from 'qs';
import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { getAlbumPath } from 'reactApp/modules/albums/albums.selector';
import { setIsFromAttaches } from 'reactApp/modules/attaches/attaches.actions';
import { getIntegrationQueryString } from 'reactApp/modules/integration/integration.selectors';
import { historyPush } from 'reactApp/modules/router/router.module';
import { getCurrentStorage, getParams } from 'reactApp/modules/router/router.selectors';
import { getSearchQueryString, getSearchQueryStringMobile } from 'reactApp/modules/search/search.helpers';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { getStorage, isIntegrationStorage } from 'reactApp/modules/storage/storage.helpers';
import { getCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import { type CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import { store } from 'reactApp/store';
import { ESearchOptionSource, ESearchOptionType } from 'reactApp/ui/WebSearch/WebSearch.data';
import { getEncodePath, getHomeFolderPath, getPathName } from 'reactApp/utils/urlHelper';
import { call, put, select } from 'redux-saga/effects';

export const getPathForHistory = (path?: string, storage?: EStorageType) => {
    if (path) {
        const encodedPath = getEncodePath(path);
        storage = storage === EStorageType.viewerAttaches ? EStorageType.attaches : storage;
        const { isAttaches, isPublicOrStock } = getStorage(storage || EStorageType.home);
        return storage === EStorageType.home
            ? getHomeFolderPath(encodedPath)
            : `/${storage}${isAttaches || isPublicOrStock ? '/' : ''}${encodedPath}`;
    }

    return getPathName();
};

function* pushSearchParams(path: string) {
    const isPhone = Boolean(IS_PHONE_BROWSER);
    const { query, searchType, searchSource, searchSection } = yield select(SettingsSelectors.getQueryParams);
    const id = getPathForHistory(path, EStorageType.search);
    let search = isPhone
        ? getSearchQueryStringMobile(query, { searchType, searchSection })
        : getSearchQueryString(query, { searchType, searchSource });

    const searchObj = parse(window.location.search, {
        ignoreQueryPrefix: true,
    });

    if (searchObj?.dialog) {
        search += `&dialog=${searchObj?.dialog}`;
    }
    yield put(historyPush({ id, search }));
}

export function* pushWeblink({
    path,
    weblink,
    storage = EStorageType.home,
}: { path?: string; weblink?: string; storage?: EStorageType } = {}) {
    if (storage === EStorageType.documents) {
        return;
    }

    const id = getPathForHistory(path, storage);

    const searchObj = parse(window.location.search, {
        ignoreQueryPrefix: true,
    });

    if (weblink) {
        searchObj.weblink = weblink;
    } else {
        delete searchObj.weblink;
    }
    const search = stringify(searchObj, { addQueryPrefix: true });

    yield put(historyPush({ id, search, replace: true }));
}

export function* navigateToItem(item: CloudItem | null | undefined) {
    if (!item) {
        return;
    }
    const routerParams: ReturnType<typeof getParams> = yield select(getParams) || {};
    const currentStorage: ReturnType<typeof getCurrentStorage> = yield select(getCurrentStorage);
    const isItemHome = item.storage === EStorageType.home;
    const weblink = 'weblink' in item && item.weblink;
    // TypeError: Cannot add property weblink, object is not extensible
    // Селектор возвращает не простой объект, а защищенный от модификации, поэтому делаем его копию
    const params = { ...routerParams };
    if (isItemHome && weblink) {
        params.weblink = weblink;
    } else {
        delete params.weblink;
    }

    if (
        currentStorage === EStorageType.start ||
        item.storage === EStorageType.story ||
        (currentStorage === EStorageType.home && item.storage !== currentStorage)
    ) {
        // TODO: пока пропускаем это для виджетов
        return;
    }

    const storage: ReturnType<typeof getCurrentStorage> = yield select(getCurrentStorage);
    const { isHome, isAlbums, isSearch, isPublicOrStock } = getStorage(storage);
    const { id } = item;
    const itemPath = (!isPublicOrStock && 'home' in item && item?.home) || id;

    if (isSearch) {
        return yield call(pushSearchParams, itemPath);
    }

    const albumPath = yield select((state) => getAlbumPath(state, id));

    yield call(pushWeblink, {
        path: isAlbums ? `/${albumPath}` : itemPath,
        weblink: isHome ? params.weblink : '',
        storage: item.storage,
    });
}

export const gotoAfterClose = () => {
    const state = store.getState();
    const currentFolder = getCurrentFolder(state);
    const storage = getCurrentStorage(state);
    const { isHome, isDocuments, isAlbums, isSearch, isAttaches, isPublicOrStock } = getStorage(storage);
    const {
        query = '',
        searchType = ESearchOptionType.all,
        searchSource = ESearchOptionSource.all,
        fromCloud = false,
        folderId,
        type,
        searchSection,
    } = SettingsSelectors.getQueryParams(state);
    const integrationQueryString = getIntegrationQueryString(state);

    if (!currentFolder) {
        return;
    }

    // TODO: routing
    if (isHome) {
        store.dispatch(historyPush({ id: getEncodePath(getHomeFolderPath(currentFolder.id)) }));
    } else if (isDocuments) {
        // @ts-ignore
        store.dispatch(historyPush({ id: `${storage}/${currentFolder.name}` }));
    } else if (isAlbums) {
        store.dispatch(historyPush({ id: `${storage}/${getAlbumPath(store.getState(), currentFolder.id)}` }));
    } else if (isSearch) {
        const isPhone = Boolean(IS_PHONE_BROWSER);
        const search = isPhone
            ? getSearchQueryStringMobile(query, { searchType, searchSection })
            : getSearchQueryString(query, { searchType, searchSource });
        store.dispatch(historyPush({ id: `${storage}/`, search }));
    } else if (isAttaches && !fromCloud) {
        store.dispatch(setIsFromAttaches(true));
        store.dispatch(historyPush({ id: ROOT_FOLDER_ID }));
    } else if (isAttaches) {
        const search = stringify(
            { query, fromCloud, folderId, type },
            {
                addQueryPrefix: true,
                skipNulls: true,
            }
        );
        store.dispatch(historyPush({ id: `${storage}/`, search }));
    } else if (storage === EStorageType.feed) {
        store.dispatch(historyPush({ id: `${storage}/`, search: `?type=${type}` }));
    } else if (isPublicOrStock) {
        store.dispatch(historyPush({ id: getEncodePath(currentFolder.id) }));
    } else if (isIntegrationStorage(storage)) {
        store.dispatch(historyPush({ id: `${storage}${currentFolder.id || '/'}`, search: integrationQueryString }));
    } else {
        store.dispatch(historyPush({ id: storage }));
    }
};
