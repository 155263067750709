import { Icon24DownloadOutline } from '@vkontakte/icons';
import { Spacing } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { type ReactElement, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { ACTION_SETTINGS } from 'reactApp/appHelpers/actionSettings';
import {
    DOWNLOAD_DISK_O_PROMO_CONFIG,
    ENABLE_FULL_RESPONSIVE,
    IS_BLOCKED,
    IS_DOCUMENTS_DOMAIN,
    IS_ONPREMISE,
    NEW_PORTAL_HEADER,
} from 'reactApp/appHelpers/configHelpers';
import { abSafeFakeDoorSelector, isAllDocumentsHelpButton } from 'reactApp/appHelpers/featuresHelpers';
import { DEMO_PROMO_VARIANT } from 'reactApp/appHelpers/featuresHelpers/features/demoPromo';
import { isHomeSidebar } from 'reactApp/appHelpers/featuresHelpers/features/homeSidebarAd';
import { chooseVariant } from 'reactApp/appHelpers/featuresHelpers/utils';
import { HelpButton } from 'reactApp/components/HelpButton/HelpButton';
import { BREAKPOINT_MD } from 'reactApp/constants/breakpoints';
import { useMinWidthBreakpoint } from 'reactApp/hooks/responsiveness/useMinWidthBreakpoint';
import { useResponsiveEnabled } from 'reactApp/hooks/responsiveness/useResponsiveEnabled';
import { useIsWindowWidthSmall } from 'reactApp/hooks/useNormalizedOvidius/useIsWindowWidthSmall';
import { usePortal } from 'reactApp/hooks/usePortal';
import { ActionPanelSelectors } from 'reactApp/modules/actionpanel/actionpanel.selectors';
import { isAlertForOverquota } from 'reactApp/modules/features/features.helpers';
import { getSlicedHome } from 'reactApp/modules/file/utils';
import { changeHomeHistory } from 'reactApp/modules/home/home.actions';
import { getIntegrationClient } from 'reactApp/modules/integration/integration.selectors';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { historyPush } from 'reactApp/modules/router/router.module';
import { getCurrentStorage, selectStatusPage } from 'reactApp/modules/router/router.selectors';
import { sidebarToggle } from 'reactApp/modules/settings/settings.module';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { getIdByStorage, getStorage } from 'reactApp/modules/storage/storage.helpers';
import { getIdsCount, isRootCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getAllowedMimeTypes, getIsDragging } from 'reactApp/modules/upload/upload.selectors';
import { addToUploadQueue } from 'reactApp/modules/uploading/uploading.module';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { ErrorPage } from 'reactApp/sections/ErrorPage/ErrorPage';
import { useMobileAppPromo } from 'reactApp/sections/MainPage/hooks/useMobileAppPromo';
import { useRouteChangeProcessing } from 'reactApp/sections/MainPage/hooks/useRouteChangeProcessing';
import type { RootState } from 'reactApp/store';
import { DemoPromo } from 'reactApp/ui/DemoPromo/DemoPromo';
import { EHeaderColor } from 'reactApp/ui/Header/HeaderWrapper';
import { OverquotaBanner } from 'reactApp/ui/OverquotaBanner/OverquotaBanner';
import { TreePromoDesktopApp } from 'reactApp/ui/ReactViewer/TreePromoMobileApp/TreePromoDesktopApp';
import { SAFE_FAKEDOOR_STORE_ID } from 'reactApp/ui/SafeFakedoorModal/SafeFakedoorModal.constants';
import { SidebarBanner } from 'reactApp/ui/SidebarBanner/SidebarBanner';
import { Space } from 'reactApp/ui/Space/Space';
import { AddToolbar } from 'reactApp/ui/Toolbar/AddToolbar';
import { ToolbarNew } from 'reactApp/ui/Toolbar/Toolbar.new';
import { ToolbarItem } from 'reactApp/ui/Toolbar/ToolbarItem/ToolbarItem';
import { TreeConnected } from 'reactApp/ui/TreeComponent/TreeComponent';
import { ETreeRootIds } from 'reactApp/ui/TreeComponent/TreeComponent.constants';
import { loadFolder } from 'reactApp/ui/TreeComponent/TreeComponent.helpers';
import { TreePromo } from 'reactApp/ui/TreePromo/TreePromo';
import { TrialPromo } from 'reactApp/ui/TrialPromo/TrialPromo';
import { UploadBlock } from 'reactApp/ui/UploadBlock/UploadBlock';
import { UploadInput } from 'reactApp/ui/UploadInput/UploadInput';
import { ChevronLeftOutlineIcon } from 'reactApp/ui/VKUIIcons';
import { usePHSearchBlock } from 'reactApp/ui/WebSearch/helpers';
import { useShowAd } from 'reactApp/ui/Worm/useShowAd';
import { Worm } from 'reactApp/ui/Worm/Worm';
import { changeCloudToDocumentDomain } from 'reactApp/utils/documentsDomain';
import { createGaSender, sendGa as sendGaHelper } from 'reactApp/utils/ga';
import { filterFiles, scrollToTop } from 'reactApp/utils/helpers';
import opener from 'reactApp/utils/opener';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { ESafeFakedoorAnalytics, safeFakedoorAnalytics } from 'reactApp/utils/safeFakedoorGa';

import { useDiskOTreePromo } from './hooks/useDiskOTreePromo';
import { SidebarAdSize, useSidebarAdSize } from './hooks/useSidebarAdSize';
import styles from './MainPage.css';

const sendGa = createGaSender('main');

interface Props {
    children: ReactElement | null;
    storage?: EStorageType;
    showUploader?: boolean;
    allowDnd?: boolean;
    renderWorm?: boolean;
    showToolbar?: boolean;
    showSpace?: boolean;
    showTree?: boolean;
    showBackHomeButton?: boolean;
}

export const MainPage = memo(
    // eslint-disable-next-line max-lines-per-function, complexity
    ({
        children,
        storage,
        showUploader = true,
        allowDnd = true,
        renderWorm = false,
        showToolbar = true,
        showSpace = true,
        showTree = true,
        showBackHomeButton = false,
    }: Props): ReactElement | null => {
        const dispatch = useDispatch();
        const location = useLocation();
        const params = useParams();
        const [largeLBreakpointHit] = useMinWidthBreakpoint(BREAKPOINT_MD);
        const isResponsiveEnable = useResponsiveEnabled();

        const isNewbie = useSelector(UserSelectors.isNewbie);
        const isAnonymous = useSelector(UserSelectors.isAnonymous);
        const isBizUser = useSelector(UserSelectors.isBizUser);
        const isOverquota = useSelector(UserSelectors.isOverQuotaUser);
        const isFrozen = useSelector(UserSelectors.isFrozen);
        const statusPage = useSelector(selectStatusPage);
        const isSidebarOpened = useSelector(SettingsSelectors.isSidebarOpened);
        const allowedMimeTypes = useSelector(getAllowedMimeTypes)?.join(',');
        const isActionPanelOpened = useSelector(ActionPanelSelectors.isActionPanelOpened);
        const currentStorage = useSelector(getCurrentStorage);
        const isDragging = useSelector(getIsDragging);

        const uploadPortalTarget = usePortal('uploadAreaRoot');
        const helpButtonPortalTarget = usePortal('helpButtonRoot');

        const leftBlockWrapperRef = useRef<HTMLDivElement | null>(null);

        const { showDiskOTreePromo, handleDiskOTreePromoShow, handleDiskOTreePromoClick, handleDiskOTreePromoClose } = useDiskOTreePromo();
        const { showMobileAppTreePromo, handleTreePromoMobileAppClick } = useMobileAppPromo();

        const {
            isHome,
            isAttaches,
            isIntegration,
            isAllDocuments,
            isIncomingPublic,
            isDocuments,
            isInlineIntegration,
            isFamilyPage,
            isTemplates,
        } = getStorage(storage as EStorageType);

        const { isTutoria } = useSelector(getIntegrationClient);
        const isTutoriaIntegration = isIntegration && isTutoria;
        const windowWidthSmall = useIsWindowWidthSmall();

        const closeSidebarHandler = useCallback(() => dispatch(sidebarToggle(false)), [dispatch]);

        usePHSearchBlock();

        useEffect(() => {
            closeSidebarHandler();
        }, [largeLBreakpointHit, closeSidebarHandler]);

        const handleMenuClick = useCallback(
            ({ id, storage, level }) => {
                const domainBizFoldersSection = id === ETreeRootIds.domain && level === 0;
                const isAllDocuments = id === ETreeRootIds.alldocuments;

                if (isAllDocuments) {
                    sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'docs-from-menu' });
                    const path = `${changeCloudToDocumentDomain(window.location.origin)}?fromPage=${currentStorage}`;
                    opener(path);
                    return;
                }

                if (domainBizFoldersSection) {
                    return;
                }

                if (
                    chooseVariant(
                        abSafeFakeDoorSelector,
                        { control: false, variant2: id === ETreeRootIds.safe },
                        { skipCondition: { variant1: true } }
                    )
                ) {
                    safeFakedoorAnalytics(ESafeFakedoorAnalytics.CLICK, { entity: 'chapter' });

                    openPopupHelper({
                        popupName: popupNames.SAFE_FAKEDOOR,
                    });

                    return;
                }

                const { isHome, isIntegration } = getStorage(storage);

                if (isHome || isIntegration || isInlineIntegration) {
                    // для storage === integration || inline-integration критически важно не терять гет параметры при навигации
                    dispatch(changeHomeHistory({ id: getSlicedHome(id), search: location.search }));
                } else {
                    dispatch(historyPush({ id }));
                }
            },
            [location, currentStorage]
        );

        const handleOnMenuExpand = useCallback(({ id, storage, dispatch }) => {
            loadFolder({ id, storage, dispatch });
        }, []);

        useEffect(() => {
            scrollToTop();

            sendGa('show');
            sendGaHelper('lm', 'show');
        }, []);

        useRouteChangeProcessing({ storage, path: location.pathname, search: location.search });

        useEffect(() => {
            if (
                !isHome &&
                !isIntegration &&
                !isInlineIntegration &&
                !isAttaches &&
                !isAllDocuments &&
                !isIncomingPublic &&
                (isAnonymous || isFrozen || isNewbie) &&
                !IS_DOCUMENTS_DOMAIN
            ) {
                open(isAnonymous ? '/promo' : '/home', '_self');
            }
        }, [isFrozen, isAnonymous, storage, isHome, isAttaches, isIntegration, isAllDocuments, isIncomingPublic, isNewbie]);

        useEffect(() => {
            const classes = classNames(styles.container, {
                [styles.responsive]: ENABLE_FULL_RESPONSIVE,
            }).split(' ');
            uploadPortalTarget.classList.remove(...classes);
            if (isActionPanelOpened) {
                uploadPortalTarget.classList.add(...classes);
            }
        }, [uploadPortalTarget, isActionPanelOpened]);

        useEffect(() => {
            const uploadFilesFromClipboard = async (e: ClipboardEvent) => {
                if (!e.clipboardData?.files.length) {
                    return;
                }

                const files = filterFiles(Array.from(e.clipboardData.files));

                if (!files.length) {
                    return;
                }

                e.preventDefault();

                dispatch(
                    addToUploadQueue({
                        files,
                        descriptorOptions: {
                            checkAllowedExtensions: true,
                        },
                    })
                );
            };

            document.addEventListener('paste', uploadFilesFromClipboard);
            return () => document.removeEventListener('paste', uploadFilesFromClipboard);
        }, [currentStorage]);

        const showLeftBlock = () => {
            if (isTutoriaIntegration) {
                return !IS_BLOCKED && !windowWidthSmall;
            }

            return !IS_BLOCKED && !isInlineIntegration;
        };

        const leftBlock = useMemo(
            () => (
                <div
                    className={classNames(styles.leftBlock, {
                        [styles.responsive]: ENABLE_FULL_RESPONSIVE,
                    })}
                >
                    {showToolbar && (
                        <div
                            className={classNames(styles.toolbar, {
                                [styles.responsive]: ENABLE_FULL_RESPONSIVE,
                                [styles.toolbar_new_portal_header]: NEW_PORTAL_HEADER,
                            })}
                        >
                            {ENABLE_FULL_RESPONSIVE && (
                                <ToolbarItem
                                    id="toolbar-collapse-item"
                                    text="Свернуть"
                                    icon={<ChevronLeftOutlineIcon />}
                                    onClick={closeSidebarHandler}
                                />
                            )}
                            <AddToolbar />
                        </div>
                    )}
                    {showBackHomeButton && (
                        <div className={styles.backButtonBlock}>
                            <RouterLink to={getIdByStorage(EStorageType.home)} reloadDocument>
                                <ToolbarItem className={styles.backButton} id="back" text="Вернуться" icon={<ChevronLeftOutlineIcon />} />
                            </RouterLink>
                        </div>
                    )}

                    {showSpace && <Space className={classNames({ [styles.spacePromo_new_portal_header]: NEW_PORTAL_HEADER })} />}
                    {DEMO_PROMO_VARIANT ? (
                        <DemoPromo />
                    ) : (
                        !ACTION_SETTINGS.hide1TbTrialPromo && (
                            <TrialPromo text="+1 ТБ на месяц бесплатно" href={DOWNLOAD_DISK_O_PROMO_CONFIG?.treePromoLink} />
                        )
                    )}

                    {showDiskOTreePromo && DOWNLOAD_DISK_O_PROMO_CONFIG?.treePromoLink && (
                        <TreePromo
                            text="Скачать Облако для ПК"
                            icon={<Icon24DownloadOutline />}
                            href={DOWNLOAD_DISK_O_PROMO_CONFIG?.treePromoLink}
                            onShow={handleDiskOTreePromoShow}
                            onClick={handleDiskOTreePromoClick}
                            onClose={handleDiskOTreePromoClose}
                        />
                    )}
                    {/* если не отображаем ничего из вышеперечисленного, то хотя бы добавим отступ */}
                    {!showToolbar && !showSpace && !showDiskOTreePromo && <Spacing size={24} />}
                    {showTree && (
                        <div
                            className={classNames(styles.treeWrapper, {
                                [styles.responsive]: ENABLE_FULL_RESPONSIVE,
                                [styles.treeWrapper_new_portal_header]: NEW_PORTAL_HEADER,
                            })}
                        >
                            <TreeConnected
                                key={storeHelper.getValue(SAFE_FAKEDOOR_STORE_ID)}
                                onClick={handleMenuClick}
                                onExpand={handleOnMenuExpand}
                                gaSuffix="sp"
                                search={location?.search}
                            />
                        </div>
                    )}
                    {showMobileAppTreePromo && <TreePromoDesktopApp onClick={handleTreePromoMobileAppClick} />}
                </div>
            ),
            [
                location?.search,
                storage,
                handleMenuClick,
                showDiskOTreePromo,
                handleDiskOTreePromoShow,
                handleDiskOTreePromoClick,
                handleDiskOTreePromoClose,
                closeSidebarHandler,
                storeHelper.getValue(SAFE_FAKEDOOR_STORE_ID),
            ]
        );

        const handleLeftBlockWrapperOverlayClick = useCallback(
            (evt) => {
                if (ENABLE_FULL_RESPONSIVE && leftBlockWrapperRef.current === evt.target) {
                    closeSidebarHandler();
                }
            },
            [closeSidebarHandler]
        );

        const itemsCount = useSelector((state: RootState) => getIdsCount(state, storage as EStorageType));
        const isRootFolder = useSelector((state: RootState) => isRootCurrentFolder(state, storage));

        const hideToolbar =
            (itemsCount === 0 && (isHome || isFamilyPage) && isRootFolder) ||
            (isDocuments && !isResponsiveEnable && !params.documentType) ||
            isInlineIntegration ||
            isTemplates;

        const showAD = useShowAd();
        const adSidebarSize = useSidebarAdSize();

        if (isFrozen) {
            return null;
        }

        if (statusPage) {
            return <ErrorPage status={statusPage} />;
        }

        const isAdWorm = renderWorm && showAD && !isHomeSidebar;
        const isAdSidebar = renderWorm && showAD && isHomeSidebar;

        const isAdSidebarCollapsed = adSidebarSize === SidebarAdSize.None;
        const isAdSidebarSmall = adSidebarSize === SidebarAdSize.Small;
        const isAdSidebarBig = adSidebarSize === SidebarAdSize.Big;

        let uploadButtonOffset: number | undefined;
        if (isAdSidebar) {
            if (isAdSidebarSmall) {
                uploadButtonOffset = 300;
            } else if (isAdSidebarBig) {
                uploadButtonOffset = 380;
            }
        }

        const showOverquotaBanner = Boolean(isOverquota && isAlertForOverquota && !isBizUser && !IS_ONPREMISE && !isAllDocuments);

        return (
            <div
                className={classNames(styles.root, {
                    [styles.root_new_portal_header]: NEW_PORTAL_HEADER,
                    [styles.alignLeft]: isInlineIntegration,
                    // tempexp-17235-start
                    [styles.root_sidebar_banner]: isAdSidebar && isAdSidebarBig,
                    [styles.root_sidebar_banner__small]: isAdSidebar && isAdSidebarSmall,
                    // tempexp-17235-end
                    [styles.root_integration]: isTutoriaIntegration,
                    [styles.root_new_portal_header_rightBlock_notFullHeight]: isFamilyPage,
                    [styles.root_uploader]: isDragging,
                })}
            >
                {showLeftBlock() && (
                    <div
                        ref={leftBlockWrapperRef}
                        onClick={handleLeftBlockWrapperOverlayClick}
                        className={classNames(styles.leftBlockWrapper, {
                            [styles.responsive]: ENABLE_FULL_RESPONSIVE,
                            [styles.visible]: isSidebarOpened,
                        })}
                    >
                        {leftBlock}
                    </div>
                )}
                {!IS_BLOCKED && (
                    <div
                        className={classNames(styles.rightBlock, {
                            [styles.responsive]: ENABLE_FULL_RESPONSIVE,
                            [styles.rightBlock_new_portal_header]: NEW_PORTAL_HEADER || isTutoriaIntegration,
                        })}
                    >
                        {showOverquotaBanner && <OverquotaBanner />}
                        <div className={styles.rightBlockContent}>
                            {isAdWorm && (
                                <div className={styles.wormWrapper}>
                                    <Worm />
                                </div>
                            )}
                            {NEW_PORTAL_HEADER && !hideToolbar && !(isTutoriaIntegration && windowWidthSmall) && (
                                <div className={styles.toolbarWrap}>
                                    <ToolbarNew color={EHeaderColor.WHITE} isResponsiveEnable={isResponsiveEnable} />
                                </div>
                            )}
                            {NEW_PORTAL_HEADER || isTutoriaIntegration ? (
                                <div className={styles.rightBlockContentWrapper}>{children}</div>
                            ) : (
                                children
                            )}
                        </div>
                    </div>
                )}
                {!IS_BLOCKED && isAdSidebar && !isAdSidebarCollapsed && <SidebarBanner isSmall={isAdSidebarSmall} />}

                {!IS_BLOCKED &&
                    createPortal(
                        <UploadBlock
                            showDropArea={showUploader}
                            allowDnd={allowDnd}
                            gaCategory="fast"
                            // tempexp-17235-start
                            rightOffset={uploadButtonOffset}
                            // tempexp-17235-end
                        />,
                        uploadPortalTarget
                    )}

                {(isAllDocuments || isIncomingPublic) &&
                    isAllDocumentsHelpButton &&
                    createPortal(<HelpButton storage={storage} />, helpButtonPortalTarget)}

                <UploadInput allowedMimeTypes={allowedMimeTypes} />
            </div>
        );
    }
);

MainPage.displayName = 'MainPage';

MainPage.whyDidYouRender = true;
