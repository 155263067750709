import classNames from 'clsx';
import errorImageDark from 'img/errors/dark/error.png';
import errorImage from 'img/errors/error.png';
import React from 'react';
import { IS_B2B_BIZ_USER, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { EQueryParams } from 'server/helpers/getRequestParams';

import styles from './ErrorPage.css';
import { type ErrorInfo, EStatus } from './ErrorPage.types';

const errorImagesB2B = {
    [EStatus.NOT_AVAILABLE]: {
        imageSrc: require('img/errors-b2b/error-403.png'),
        imageSrcDark: require('img/errors-b2b/dark/error-403.png'),
    },
    [EStatus.NOT_FOUND]: {
        imageSrc: require('img/errors-b2b/error-404.png'),
        imageSrcDark: require('img/errors-b2b/dark/error-404.png'),
    },
    [EStatus.INTERNAL_SERVER_ERROR]: {
        imageSrc: require('img/errors-b2b/error-500.png'),
        imageSrcDark: require('img/errors-b2b/dark/error-500.png'),
    },
};

export const isErrorPageStatus = (error: any): error is EStatus => {
    return Object.values(EStatus).includes(error);
};

const commonData = {
    buttonText: 'Перейти на главную',
    buttonLink: '/',
};

const serverErrorDownloadLink = 'https://trk.mail.ru/c/dpaf96';

const fromDeeplink = getQueryParams()[EQueryParams.fromDeeplink];

export const ERROR_STATUS_MAP: Record<EStatus, ErrorInfo> = {
    [EStatus.NOT_AVAILABLE]: {
        ...commonData,
        title: 'Доступ к файлам ограничен',
        annotation: !IS_B2B_BIZ_USER && (
            <>
                Попросите владельца открыть его или войдите в другой аккаунт
                <span
                    className={classNames({ ['errorPageDisableElement']: process.env.SSR || (IS_WEBVIEW && fromDeeplink) })}
                    id="error-page__text-with-link"
                >
                    . Узнать причины ограниченного доступа можно{' '}
                    <a className={styles.helpUrl} target="_blank" href="https://help.mail.ru/cloud_web" rel="noreferrer">
                        тут
                    </a>
                </span>
            </>
        ),
        imageSrc: errorImage,
        imageSrcDark: errorImageDark,
        metaTitle: '403',
        downloadLink: 'https://trk.mail.ru/c/v2nah4',
    },
    [EStatus.NOT_FOUND]: {
        ...commonData,
        title: 'Такой страницы нет',
        annotation: 'Возможно, её удалили или в ссылку\nзакралась ошибка',
        imageSrc: errorImage,
        imageSrcDark: errorImageDark,
        metaTitle: '404',
        // eslint-disable-next-line sonarjs/no-duplicate-string
        downloadLink: 'https://trk.mail.ru/c/gi7h46',
    },
    [EStatus.TOO_MANY_REQUESTS]: {
        ...commonData,
        title: 'Превышено количество запросов',
        annotation: 'Попробуйте обновить страницу через несколько минут или вернитесь на\u00A0главную.',
        imageSrc: errorImage,
        imageSrcDark: errorImageDark,
        metaTitle: '429',
        downloadLink: 'https://trk.mail.ru/c/d8q806',
    },
    [EStatus.INTERNAL_SERVER_ERROR]: {
        ...commonData,
        title: 'Технические работы',
        annotation: 'Мы знаем о проблеме и уже решаем её.\nПопробуйте обновить страницу позже',
        imageSrc: errorImage,
        imageSrcDark: errorImageDark,
        metaTitle: '500',
        downloadLink: serverErrorDownloadLink,
    },
    [EStatus.GATEWAY_TIMEOUT]: {
        ...commonData,
        title: 'Сервис недоступен',
        annotation: 'Попробуйте обновить страницу через несколько минут или вернитесь на\u00A0главную.',
        imageSrc: errorImage,
        imageSrcDark: errorImageDark,
        metaTitle: '504',
        downloadLink: 'https://trk.mail.ru/c/kh6my3',
    },
    [EStatus.BAD_BROWSER]: {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        title: 'Браузер устарел',
        annotation: 'К\u00A0сожалению, ваш браузер не\u00A0поддерживает доступ к\u00A0сервису. Обновите браузер и\u00A0попробуйте снова.',
        imageSrc: errorImage,
        imageSrcDark: errorImageDark,
        metaTitle: 'Браузер устарел',
        downloadLink: 'https://trk.mail.ru/c/t9xnk8',
        ...commonData,
    },
    [EStatus.BAD_BROWSER_ATOM]: {
        title: 'Браузер устарел',
        annotation:
            'К\u00A0сожалению, ваш браузер не\u00A0поддерживает доступ к\u00A0сервису. Попробуйте Atom от\u00A0Mail.ru — там\u00A0заработает.',
        imageSrc: errorImage,
        imageSrcDark: errorImageDark,
        buttonText: 'Установить браузер',
        buttonLink: 'https://browser.ru',
        metaTitle: 'Браузер устарел',
        downloadLink: 'https://trk.mail.ru/c/f39zz4',
    },
    [EStatus.SOMETHING_WRONG]: {
        title: 'Что-то сломалось',
        imageSrc: errorImage,
        imageSrcDark: errorImageDark,
        metaTitle: '500',
        downloadLink: serverErrorDownloadLink,
        buttonText: 'Обновить',
        buttonLink: '',
        annotation: (
            <>
                Мы очень скоро всё починим. Пожалуйста,
                <br />
                попробуйте повторить позже.
            </>
        ),
        onClickHandler: () => {
            window.location.reload();
        },
        isHidePromo: true,
    },
    [EStatus.PUBLIC_BLOCKED]: {
        title: 'Страница была удалена',
        imageSrc: errorImage,
        imageSrcDark: errorImageDark,
        metaTitle: '404',
        downloadLink: 'https://trk.mail.ru/c/gi7h46',
        buttonText: 'Перейти на главную',
        buttonLink: '',
        annotation: (
            <>
                Материалы на странице были удалены из-за жалоб
                <br />
                пользователей на нарушения
            </>
        ),
        onClickHandler: () => {
            window.open(window.location.origin, '_self');
        },
    },
} as const;
export const ERROR_STATUS_MAP_BIZ = {
    [EStatus.NOT_FOUND]: {
        ...commonData,
        title: <span style={{ display: 'inline-block', marginTop: '24px' }}>Такой страницы нет</span>,
        annotation: 'Возможно, вы\u00A0ввели неправильный адрес или страница была удалена.',
        ...errorImagesB2B[EStatus.NOT_FOUND],
        metaTitle: '404',
        downloadLink: 'https://trk.mail.ru/c/gi7h46',
    },
    [EStatus.NOT_AVAILABLE]: {
        ...commonData,
        title: 'Доступ к файлам ограничен',
        annotation: <>Попросите владельца открыть его или войдите в другой аккаунт</>,
        ...errorImagesB2B[EStatus.NOT_AVAILABLE],
        metaTitle: '403',
        downloadLink: 'https://trk.mail.ru/c/v2nah4',
    },
    [EStatus.INTERNAL_SERVER_ERROR]: {
        ...commonData,
        title: 'Технические работы',
        annotation: 'Мы знаем о проблеме и уже решаем её.\nПопробуйте обновить страницу позже',
        ...errorImagesB2B[EStatus.INTERNAL_SERVER_ERROR],
        metaTitle: '500',
        downloadLink: serverErrorDownloadLink,
    },
} as const;
export const disableAdvButtonHref = '/promo/quota?utm_source=cloud&utm_medium=web_union_quota_landing&utm_campaign=error_page';
