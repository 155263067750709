import classNames from 'clsx';
import React, { type ReactElement, memo, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { IS_PUBLIC_ALBUM, IS_PUBLIC_FOLDER } from 'reactApp/appHelpers/configHelpers';
import { abRequiredSignUpWhenDownloadingTouchSelector } from 'reactApp/appHelpers/featuresHelpers';
import { FeatureSelector } from 'reactApp/appHelpers/featuresHelpers/FeatureSelector';
import { getFacesListRequestState } from 'reactApp/modules/faces/faces.selectors';
import { RequiredSignUpTouchSource } from 'reactApp/modules/requiredAuthorization/constants';
import { requiredAuthorizationHelpers } from 'reactApp/modules/requiredAuthorization/helpers';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import type { RootState } from 'reactApp/store';
import { type IActionItem, useItems } from 'reactApp/ui/Mobile/ActionsCarousel/useItems';
import { Placeholder } from 'reactApp/ui/Placeholder/Placeholder';
import { DownloadTooltip } from 'reactApp/ui/ReactViewer/ViewerHeader/DownloadTooltip';

import styles from './ActionsCarousel.css';

const defaultActionsFilter = () => true;

export const ActionsCarousel = memo(
    ({
        disabled = false,
        noMargins = false,
        actionsFilter = defaultActionsFilter,
        supportThemedPage = false,
    }: {
        disabled?: boolean;
        noMargins?: boolean;
        actionsFilter?(item: IActionItem): boolean;
        supportThemedPage?: boolean;
    }): ReactElement => {
        const [showTip, setShowTip] = useState(false);
        const storage = useSelector(getCurrentStorage);
        const downloadBtnRef = useRef<HTMLDivElement>(null);
        const items = useItems({ storage, downloadBtnRef }).filter(actionsFilter);
        const { isLoading } = useSelector(getFacesListRequestState);
        const isRequiredSingUpTouchSource = useSelector((state: RootState) =>
            requiredAuthorizationHelpers.isRequiredSingUpTouchSource(state, RequiredSignUpTouchSource.PUBLIC_TOOLBAR)
        );
        const isViewerActive = useSelector(ViewerSelectors.isViewerActive);

        const requiredSingUpTooltip = useMemo(() => {
            return <DownloadTooltip btnText="Скачать" downloadRef={downloadBtnRef.current} isWhite closeOnOutsideClick />;
        }, [downloadBtnRef.current]);

        useEffect(() => {
            if (downloadBtnRef?.current && isRequiredSingUpTouchSource && IS_PUBLIC_FOLDER && !isViewerActive) {
                setShowTip(true);
            }
        }, [downloadBtnRef?.current, isRequiredSingUpTouchSource, isViewerActive]);

        if (IS_PUBLIC_FOLDER && isLoading && !IS_PUBLIC_ALBUM) {
            return (
                <div className={classNames(styles.root, styles.root_loading)}>
                    <Placeholder />
                </div>
            );
        }

        return (
            <div
                className={classNames({
                    [styles.root]: true,
                    [styles.root_disabled]: disabled,
                    [styles.themedPage]: supportThemedPage,
                    [styles.no_margins]: noMargins,
                })}
            >
                <div className={styles.wrapper}>
                    {items.map((item) => (
                        <div
                            ref={item.ref}
                            className={classNames({
                                [styles.item]: true,
                                [styles.item_active]: item.active,
                                [styles[`item_${item.id}`]]: Boolean(item.id),
                            })}
                            key={item.id}
                            onClick={disabled ? undefined : item.onClick}
                            data-qa-action={item.id}
                        >
                            {item.component}
                            {item.icon && <div className={styles.icon}>{item.icon}</div>}
                            {item.text}
                        </div>
                    ))}
                </div>
                {showTip && (
                    <FeatureSelector
                        selector={() => abRequiredSignUpWhenDownloadingTouchSelector(false)}
                        control={<></>}
                        variant1={requiredSingUpTooltip}
                        variant2={requiredSingUpTooltip}
                    />
                )}
            </div>
        );
    }
);

ActionsCarousel.displayName = 'ActionsCarousel';
