import type { PayloadAction } from '@reduxjs/toolkit';
import { logger } from 'lib/logger';
import { PublicThemeSetApiCall } from 'reactApp/api/public/PublicThemeSetApiCall';
import { getThemeId } from 'reactApp/appHelpers/themeToolHelpers/utils';
import { applyThemeRequest } from 'reactApp/modules/theme.outsource/theme.outsource.module';
import { call, cancel, put, select, takeEvery } from 'redux-saga/effects';

import { getPublicThemeSucces, setPublicTheme } from './themePublic.module';
import { ThemePublicSelectors } from './themePublic.selectors';
import type { SetPublicThemeActionParams } from './themePublic.types';

const setPublicThemeApiCall = ({ themeId, publicId }: SetPublicThemeActionParams) =>
    new PublicThemeSetApiCall().makeRequest({ public_id: publicId, theme_id: getThemeId(themeId) });

function* setPublicThemeHandler(action: PayloadAction<SetPublicThemeActionParams>) {
    try {
        const { publicId, themeId } = action.payload;
        const isNewTheme = yield select(ThemePublicSelectors.isNewPublicThemeSelected);
        if (isNewTheme) {
            yield put(applyThemeRequest(themeId));
            yield call(setPublicThemeApiCall, { themeId, publicId });
            yield put(getPublicThemeSucces({ themeId, public_id: publicId }));
        }
    } catch (error) {
        logger.error(error);
        yield cancel();
    }
}

export function* watchThemePublic() {
    yield takeEvery(setPublicTheme.toString(), setPublicThemeHandler);
}
