import { createSelector } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    type CardFeatures,
    type DisableAdsTariffIds,
    tariffBuyWhenDisableAdsModalTariffIds,
} from 'reactApp/appHelpers/featuresHelpers/features/disableAdsScreen';
import { features } from 'reactApp/constants/products/features';
import { templateFeatureActions } from 'reactApp/constants/products/templateFeatureActions';
import { templateFeatureIcons } from 'reactApp/constants/products/templateFeatureIcons';
import { templateFeatures } from 'reactApp/constants/products/templateFeatures';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import type { RootState } from 'reactApp/store';
import type { TariffFeatureListItemType } from 'reactApp/ui/TariffCardNew/components/TariffFeatureList.types';
import { useFeatureData } from 'reactApp/ui/TariffSectionNew/hooks/useFeatures';
import type { TariffByModalTab } from 'reactApp/ui/TariffsModal/TariffsModalContent.types';

const getProductFeatures = (featuresConfig?: CardFeatures): TariffFeatureListItemType[] | null | undefined => {
    if (!featuresConfig) {
        return null;
    }

    // Получение готового списка по FeatureListName
    if (typeof featuresConfig === 'string') {
        return features[featuresConfig];
    }

    // Составление списка по конфигу
    const newFeatureList: TariffFeatureListItemType[] = [];

    featuresConfig.forEach((featureConfig, index) => {
        if (typeof featureConfig === 'string') {
            // Получение готовой фичи из шаблонов по FeatureName
            if (templateFeatures[featureConfig]) {
                newFeatureList.push(templateFeatures[featureConfig]);
            }
        } else {
            // Составление фичи по конфигу FeatureConfig
            const { text: rawText, templateTextName, iconName, key, templateActionName } = featureConfig;

            // если задано templateTextName получаем текст из шаблонной фичи, причем отдаем предпочение JSX
            const text = templateTextName
                ? templateFeatures[templateTextName]?.textJsx || templateFeatures[templateTextName]?.text
                : rawText;
            const onClick = templateActionName ? templateFeatureActions[templateActionName] : undefined;
            const icon = iconName ? templateFeatureIcons[iconName] : undefined;

            if (text) {
                newFeatureList.push({
                    key: key || index,
                    onClick,
                    icon,
                    text,
                });
            }
        }
    });

    return newFeatureList;
};

export const getTariffsList = createSelector(
    [
        (state: RootState, tariffIds: DisableAdsTariffIds, _: TariffFeatureListItemType[]) =>
            ProductsSelectors.getNormalizedTariffsListByProductIds(state, tariffIds, 'buy-disable-ads'),
        (_: RootState, __: DisableAdsTariffIds, defaultFeatureList: TariffFeatureListItemType[]) => defaultFeatureList,
        (_: RootState, tariffIds: DisableAdsTariffIds, ___: TariffFeatureListItemType[]) => ({
            cardsSettings: tariffIds.cardsSettings || {},
            recommendedPattern: tariffIds.recommendedPattern || 'W64',
        }),
    ],
    (tariffs, defaultFeatures, { cardsSettings, recommendedPattern }): TariffByModalTab[] => {
        const withRecommendations = recommendedPattern !== 'none';

        return tariffs.map((t) => {
            const isRecommended = withRecommendations && t.tariffId.includes(recommendedPattern);
            const tariffPattern = t.tariffId.match(/^(W[0-9]+(TB|GB|MB|KB)+)/)?.[0];
            const settings = tariffPattern && cardsSettings ? cardsSettings[tariffPattern] : undefined;
            const features = getProductFeatures(settings?.features) || defaultFeatures;

            return {
                ...t,
                ...settings,
                isRecommended,
                features,
            };
        });
    }
);

export function useDisableAdsTariffs(tariffsFromQuery?: string) {
    const [defaultFeatureList] = useFeatureData(features.disableAds);
    const tariffIds = useMemo(() => {
        if (tariffsFromQuery && tariffBuyWhenDisableAdsModalTariffIds?.hasOwnProperty(tariffsFromQuery)) {
            return tariffBuyWhenDisableAdsModalTariffIds[tariffsFromQuery];
        }
        return { year: [], month: [] };
    }, [tariffsFromQuery]);

    return { tariffIds, tariffs: useSelector((state: RootState) => getTariffsList(state, tariffIds, defaultFeatureList)) };
}
