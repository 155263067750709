// tempexp_17581-start
import { APICallV3Post } from 'reactApp/api/APICall';
import { dicountInOutflowValue } from 'reactApp/appHelpers/featuresHelpers/features/discountInOutflow';

export interface SubscriptionDiscountAPICallParams {
    result: 'fail' | 'success';
    discount_price?: number;
}

export interface SubscriptionDiscountQueryParams {
    sub_id: string | number;
    discount_percent?: 30 | 50;
    action?: 'set';
}

export class SubscriptionDiscountAPICall extends APICallV3Post<SubscriptionDiscountAPICallParams> {
    _method = 'billing/subscription/discount';
}

export const checkSubscriptionDiscount = (sub_id: string | number) =>
    new SubscriptionDiscountAPICall().makeRequest({ sub_id, discount_percent: dicountInOutflowValue });

export const setSubscriptionDiscount = (sub_id: string | number) =>
    new SubscriptionDiscountAPICall().makeRequest({ sub_id, action: 'set', discount_percent: dicountInOutflowValue });
// tempexp_17581-end
