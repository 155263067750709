import type { Kind, VirusScan } from 'reactApp/types/Tree';

export enum UserQuotaGroupType {
    Cloud = 'cloud',
    Letter = 'letter',
}

// @see https://confluence.vk.team/pages/viewpage.action?pageId=752307989
export enum UserQuotaGroupId {
    Mounted = 'mounted',
    Backup = 'backup',
    CloudLarge = 'cloudLarge',
    Video = 'video',
    CloudOld = 'cloudOld',
    CameraUploads = 'cameraUploads',
    CloudBin = 'cloudBin',
    MailSpam = 'mailSpam',
    MailBin = 'mailBin',
    Newsletters = 'newsletters',
    LargeSent = 'largeSent',
    LargeInbox = 'largeInbox',
    OldSent = 'oldSent',
    Read = 'read',
    WithAttaches = 'withAttaches',
    OldInbox = 'oldInbox',
}

type Correspondent = {
    email: string;
    name: string;
};

export type Correspondents = {
    to: Correspondent[];
    from: Correspondent[];
};

export type UserQuotaCleanerItem = {
    kind: Kind;
    name: string;
    size: number;
    type: 'file' | 'folder';
    view_path?: string;
    hash?: string;
    date: number;
    correspondents?: Correspondents;
    virus_scan: VirusScan;
    delete_id: string;
    download_url?: string;
};

// response format
export type UserQuotaCleanerGroup = {
    group_type: UserQuotaGroupType;
    group_id: UserQuotaGroupId;
    size: number;
    total_count: number;
    list: UserQuotaCleanerItem[];
    year_config?: {
        year: number;
        size: number;
        total_count: number;
    }[];
};

export enum Sort {
    size = 'size',
    mtime = 'mtime',
}

export enum Order {
    asc = 'asc',
    desc = 'desc',
}

// request params
export type UserQuotaCleanerListParams = {
    group_type: UserQuotaGroupType;
    group_id: UserQuotaGroupId;
    limit: number;
    offset?: number;
    folders?: string[];
    newsletter?: boolean;
    mark_read?: boolean;
    send_by?: boolean;
    with_attach?: boolean;
    older_than?: number;
    newer_than?: number;
    min_size?: number;
    mark_flagged?: boolean;
    mounted?: boolean;
    extensions?: string[];
    year_view?: boolean;
    sort?: Sort;
    order?: Order;
};

export enum Folders {
    Backups = 'Backups',
    CameraUploads = 'Camera Uploads',
    LetterInbox = '0',
    LetterSpam = '950',
    LetterSent = '500000',
    LetterTrashbin = '500002',
    Social = '500011',
    NewsLetters = '500013',
    ToMySelf = '500015',
    News = '500016',
    Official = '500019', // госписьма
    CloudBin = ':trash',
}

export type UserQuotaCleanerDelete = {
    ids: string[];
};
