import { flatten } from 'ramda';
import React, { memo } from 'react';

import styles from './AlbumsGrid.css';

export interface AlbumsGridProps {
    children: any;
}

export const AlbumsGrid = memo(function AlbumsGrid({ children }: AlbumsGridProps) {
    const albums = flatten(children);

    return <div className={styles.root}>{albums}</div>;
});
