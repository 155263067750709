import { Button } from '@vkontakte/vkui';
import SharedAutodeleteIconDark from 'img/empty-state/dark/shared_autodelete.png';
import SharedAutodeleteIcon from 'img/empty-state/shared_autodelete.png';
import React from 'react';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { onClickCreateNew } from 'reactApp/ui/Datalist/UserActions/onClickAction';
import styles from 'reactApp/ui/Empty/new/Empty.css';
import { sendEmptyStatesAnalitics } from 'reactApp/utils/emptyStatesGa';

export const emptyIndexedConfig = {
    icon: SharedAutodeleteIcon,
    title: 'Пока пусто',
    iconDark: SharedAutodeleteIconDark,
    description: (
        <>
            Здесь будут все ваши файлы и папки,
            <br />
            доступные в поисковиках
        </>
    ),
    BottomContent: () => (
        <Button
            mode="secondary"
            appearance="neutral"
            size="m"
            className={styles.button}
            Component="a"
            href="/home"
            onClick={(e) => {
                sendEmptyStatesAnalitics({ action: 'shared-indexed' });

                onClickCreateNew(e, { gaSuffix: '', storage: EStorageType.sharedLinks });
            }}
        >
            Создать папку для публикации
        </Button>
    ),
};
