import { TooltipModel, TooltipModelProps } from '@mail/3promo/models/tooltip';
import { ReactRenderer } from '@mail/3promo/render/react';
import { LocalStorageStore } from '@mail/3promo/stores/localStorage';
import type { ModelCreator } from '@mail/3promo/types';
import { xray } from 'lib/xray';
import { newPromoFeature } from 'reactApp/appHelpers/featuresHelpers/features/newPromo';
import { PdfWorkWithPagesPromo } from 'reactApp/modules/promoNew/components/PdfWorkWithPages/PdfWorkWithPagesPromo';
import { NEW_PROMO_ID, PromoLocalStoreDeps } from 'reactApp/modules/promoNew/defaultValues';

export const pdfWorkWithPagesCreator: ModelCreator = ({ models, render }) =>
    new TooltipModel(
        NEW_PROMO_ID.PDF_WORK_WITH_PAGES_PROMO,
        models.base,
        new TooltipModelProps(NEW_PROMO_ID.PDF_WORK_WITH_PAGES_PROMO, {
            store: new LocalStorageStore(NEW_PROMO_ID.PDF_WORK_WITH_PAGES_PROMO, PromoLocalStoreDeps),
            renderer: new ReactRenderer(NEW_PROMO_ID.PDF_WORK_WITH_PAGES_PROMO, PdfWorkWithPagesPromo, render.react),
            closable: true,
            closeLimit: newPromoFeature.PROMO?.[NEW_PROMO_ID.PDF_WORK_WITH_PAGES_PROMO]?.closeLimit,
            showLimit: newPromoFeature.PROMO?.[NEW_PROMO_ID.PDF_WORK_WITH_PAGES_PROMO]?.showLimit,
            showInterval: newPromoFeature.PROMO?.[NEW_PROMO_ID.PDF_WORK_WITH_PAGES_PROMO]?.showInterval,
            hideOnOutsideClick: true,
            templateData: {
                title: 'Работайте со страницами',
                text: 'Измените расположение, добавьте или удалите лишние страницы',
                buttons: {
                    complete: 'Попробовать',
                },
            },
            getTarget: () => {
                return document.querySelector("[data-qa-id='pdf-work-with-pages-new-promo-target']");
            },
            onShow: () => {
                xray.send(`pdf_work_with_pages_promo_show`);
            },
            onClose: () => {
                xray.send(`1-pdf_work_with_pages_promo_close_button`);
            },
            onComplete: () => {
                xray.send(`2-pdf_work_with_pages_promo_close_сomplete`);
            },
        })
    );
