import settings from 'Cloud/settings';
import { UserFullQuotaAPICall } from 'reactApp/api/user/FullQuotaAPICall';
import { IS_AUTO_TEST_HIDE, IS_BLOCKED } from 'reactApp/appHelpers/configHelpers';
import { abBlockOverqueota } from 'reactApp/appHelpers/featuresHelpers';
import { overquotaAfterClear } from 'reactApp/appHelpers/featuresHelpers/features/overquotaAfterClear';
import { overquotaNewFullBlocked } from 'reactApp/appHelpers/featuresHelpers/features/overquotaNewFullBlocked';
import { overquotaUseQuotaForProfileApi } from 'reactApp/appHelpers/featuresHelpers/features/overquotaUseProfileApiAndQuota';
import { overquotaUseProfileAPi } from 'reactApp/appHelpers/featuresHelpers/features/overquotaUseState';
import { overStatesCheckedByQuota } from 'reactApp/appHelpers/featuresHelpers/features/overStatesCheckedByQuota';
import { isPublicPasswordLinkEnabled, isThemedPublicLinkEnabled } from 'reactApp/appHelpers/featuresHelpers/features/promoLinks';
import { subscriptionsForDeletedUsers } from 'reactApp/appHelpers/featuresHelpers/features/subscriptionsForDeletedUsers';
import { tempHideOverquotaBanner } from 'reactApp/appHelpers/featuresHelpers/features/tempHideOverquotaBanner';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { isNewOverquotaModal } from 'reactApp/modules/features/features.helpers';
import { productsController } from 'reactApp/modules/products/products.controller';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { EProductPeriod } from 'reactApp/modules/products/products.types';
import { setProfileOverquotaState } from 'reactApp/modules/profile/profile.module';
import { handleLoadUserFullProfile } from 'reactApp/modules/profile/profile.saga';
import { getUserOverquotaState } from 'reactApp/modules/profile/profile.selectors';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage, isReactLandingPage } from 'reactApp/modules/router/router.selectors';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { loadUserQuotaSuccess } from 'reactApp/modules/userQuota/userQuota.module';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { store } from 'reactApp/store';
import { renderMobileSplashScreen } from 'reactApp/ui/Mobile/SplashScreen/helpers/SplashScreen.helpers';
import { useOverquotaSplashScreen } from 'reactApp/ui/Mobile/SplashScreen/hooks/useOverquotaSplashScreen';
import { renderOverquotaModal } from 'reactApp/ui/OverquotaPopup/new/OverquotaModal.helpers';
import { OverquotaModalMode } from 'reactApp/ui/OverquotaPopup/new/OverquotaModal.types';
import { renderOverquotaSplash } from 'reactApp/ui/OverquotaPopup/newSplash/OverquotaSplash.helpers';
import { renderOverquotaBanner } from 'reactApp/ui/OverquotaPopup/OverquotaPopup.helpers';
import { sendKaktamLog, sendXray } from 'reactApp/utils/ga';
import { delay, put, race } from 'redux-saga/effects';
import type { quotaStates } from 'server/types/context/IUser';
import { call, select, take } from 'typed-redux-saga';

export const QUOTA_TIP_ID = 'overQuotaTip';

const sendShowQuotaBannerForNonOverQuotaUser = (text: string) => sendXray(['overqbanner', 'show-nonquota', text || 'none']);

const statesMobileAsDesktop = ['blocked', 'full_blocked'];

const statesForSplash: quotaStates[] = ['blocked', 'cleared', 'after_clear'];

const backOverquotaStates: quotaStates[] = ['started', 'blocked', 'unblocked', 'cleared', 'after_clear'];

const WAIT_QUOTA_API_TIMEOUT = 5000;

if (overquotaNewFullBlocked) {
    backOverquotaStates.push('full_blocked');
    statesForSplash.push('full_blocked');
}

const getOverquotaFromProfileApi = (state: quotaStates | undefined, isOverQuota: boolean) => {
    let isOverquotaFromProfileApi = state ? backOverquotaStates.includes(state) : false;
    if (isOverquotaFromProfileApi && state && overStatesCheckedByQuota.includes(state) && overquotaUseQuotaForProfileApi && !isOverQuota) {
        // Юзер не в оверквоте, но бек скорее всего еще не обработал состояние после удаления файлов юзером
        isOverquotaFromProfileApi = false;
    }
    return isOverquotaFromProfileApi;
};

export function* initOverQuotaPromo() {
    const isAnonymous = yield* select(UserSelectors.isAnonymous);
    const storage = yield* select(getCurrentStorage);
    const { isHome, isPrivatePage, isAllDocuments } = getStorage(storage);
    const isPhone = yield* select(EnvironmentSelectors.isPhone);
    const isWebview = yield select(EnvironmentSelectors.isWebview);
    const isLP = isReactLandingPage();
    // Оверквотер по почте + облаку.
    let isOverQuota = yield* select(UserQuotaSelectors.isOverquota);

    if (isAnonymous || (IS_BLOCKED && !subscriptionsForDeletedUsers)) {
        return;
    }

    let state;
    let isOverquotaFromProfileApi = false;
    let stateCleared;
    if (overquotaUseProfileAPi) {
        yield* call(handleLoadUserFullProfile);

        state = (yield* select(getUserOverquotaState))?.state;

        stateCleared = (state === 'cleared' || state === 'after_clear') && subscriptionsForDeletedUsers;

        if (!stateCleared) {
            yield race({
                success: take([loadUserQuotaSuccess.toString()]),
                timeout: delay(WAIT_QUOTA_API_TIMEOUT),
            });

            // Оверквотер по почте + облаку.
            isOverQuota = yield* select(UserQuotaSelectors.isOverquota);
        }

        isOverquotaFromProfileApi = getOverquotaFromProfileApi(state, isOverQuota);
    }

    const showOverquotaInPrivatePages = isHome || (isPrivatePage && state === 'full_blocked' && overquotaNewFullBlocked && !isAllDocuments);

    if (state === 'after_clear' && !overquotaAfterClear) {
        return;
    }

    if (state === 'full_blocked' && !overquotaNewFullBlocked) {
        return;
    }

    const { action = null } = yield* select(SettingsSelectors.getQueryParams);

    if (!stateCleared && (action === 'request-payment' || isPublicPasswordLinkEnabled || isThemedPublicLinkEnabled)) {
        return;
    }

    if (!stateCleared && !!isPhone && (isWebview || settings?.request?.action || isLP)) {
        return;
    }

    if (!stateCleared && isPhone && (storage === EStorageType.subscriptions || storage === EStorageType.family)) {
        return;
    }

    // Форс-включение промки для отдельных автотестов.
    const force = yield put(UserStorageActions.get(`force_${QUOTA_TIP_ID}`)) || false;

    if (!stateCleared && !isPhone && (!showOverquotaInPrivatePages || (IS_AUTO_TEST_HIDE && !force))) {
        return;
    }

    // Оверквоте только по облаку.
    const isOverQuotaCloud = yield* select(UserSelectors.isOverQuotaUser);
    const { over } = yield* select(UserSelectors.getCloudSpace);

    // tempexp_17125-start
    if (abBlockOverqueota && ((isOverQuota && !overquotaUseProfileAPi) || isOverquotaFromProfileApi)) {
        let product;
        let tariff;

        if (!overquotaUseQuotaForProfileApi) {
            sendXray(['overquota', 'nofeature-1']);
        }

        if (abBlockOverqueota === 'b') {
            yield productsController.loadProducts();
            const overQuota = yield* select(UserQuotaSelectors.getOverQuota);

            const { product: productForOverquota, tariff: tariffForOverquota } = yield select(
                ProductsSelectors.getProductAndTariffByQuota,
                overQuota.original,
                EProductPeriod.year,
                false
            );

            if (!productForOverquota || !tariffForOverquota) {
                return;
            }

            product = productForOverquota;
            tariff = tariffForOverquota;
        }

        yield put(
            addPromoToShowQueue({
                type: EPromoType.overQuota,
                promoId: QUOTA_TIP_ID,
                onShow: () => store.dispatch(promoShown(EPromoType.overQuota)),
                onClose: () => store.dispatch(removePromo(EPromoType.overQuota)),
                ...(abBlockOverqueota === 'b' && { tariff }),
                ...(abBlockOverqueota === 'b' && { product }),
            })
        );

        sendKaktamLog(
            {
                text: 'put overquota modal',
                state,
                isOverQuota,
                isOverquotaFromProfileApi,
                abBlockOverqueota,
                overquotaUseQuotaForProfileApi,
                time: Date.now().toLocaleString(),
            },
            'overquotacloud'
        );

        return;
    }
    // tempexp_17125-end

    if (!isOverQuotaCloud && !isOverquotaFromProfileApi) {
        return;
    }

    yield productsController.loadProducts();
    const { product, tariff } = yield select(ProductsSelectors.getProductAndTariffByQuota, over.original, EProductPeriod.year, false);

    if (!isNewOverquotaModal && (!product || !tariff)) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.overQuota,
            promoId: QUOTA_TIP_ID,
            onShow: () => store.dispatch(promoShown(EPromoType.overQuota)),
            onClose: () => store.dispatch(removePromo(EPromoType.overQuota)),
            tariff,
            product,
        })
    );
}

export function* showOverquotaPopup() {
    const overquotaPromo = yield* select(PromoSelectors.getPromo(EPromoType.overQuota));
    const isPhone = yield* select(EnvironmentSelectors.isPhone);
    const isViewerActive = yield* select(ViewerSelectors.isViewerActive);

    if (!overquotaPromo) {
        return;
    }

    overquotaPromo.onShow();

    if (isViewerActive) {
        return;
    }

    const isOverQuota = yield* select(UserQuotaSelectors.isOverquota);

    // tempexp_17125-start
    if (abBlockOverqueota === 'b') {
        yield call(renderOverquotaBanner, { tariff: overquotaPromo.tariff }, overquotaPromo.onClose);

        if (!isOverQuota) {
            sendShowQuotaBannerForNonOverQuotaUser('abblock-b');
        }

        return;
    }

    if (abBlockOverqueota === 'c') {
        yield call(renderOverquotaModal, {
            onClose: overquotaPromo.onClose,
            mode: OverquotaModalMode.enter,
        });

        if (!isOverQuota) {
            sendShowQuotaBannerForNonOverQuotaUser('abblock-c');
        }

        sendKaktamLog(
            {
                text: 'show overquota modal-1',
                overquotaUseQuotaForProfileApi,
                abBlockOverqueota,
                time: Date.now().toLocaleString(),
                isOverQuota,
            },
            'overquotacloud'
        );

        return;
    }
    // tempexp_17125-end
    let quotaState = yield* select(getUserOverquotaState);
    const stateCleared = (quotaState.state === 'cleared' || quotaState.state === 'after_clear') && subscriptionsForDeletedUsers;

    /** https://jira.vk.team/browse/CLOUDWEB-18411
     * Вынес в отдельный флоу чтобы исключить срабатывание ложных промок на мобилке, но гарантированно показать */
    if (stateCleared) {
        if (isNewOverquotaModal) {
            yield call(
                renderOverquotaSplash,
                {
                    mode: !overquotaAfterClear && quotaState.state === 'after_clear' ? 'cleared' : quotaState.state,
                },
                overquotaPromo.onClose
            );

            if (!isOverQuota) {
                sendShowQuotaBannerForNonOverQuotaUser(`fixed_${quotaState.state}`);
            }
        }
    } else if (isPhone && !(quotaState.state && statesMobileAsDesktop.includes(quotaState.state))) {
        yield call(
            renderMobileSplashScreen,
            { contentHook: useOverquotaSplashScreen, hookParams: { productId: overquotaPromo?.product?.id || '' } },
            overquotaPromo.onClose
        );
    } else {
        if (isNewOverquotaModal) {
            if (!overquotaUseProfileAPi) {
                // Второй раз не дерагем апи, так как выше уже его вызывали
                yield* call(handleLoadUserFullProfile);
                quotaState = yield* select(getUserOverquotaState);
            }

            // Оверквотер по почте + облаку.
            const isOverquotaFromProfileApi = getOverquotaFromProfileApi(quotaState.state, isOverQuota);

            sendXray(['overquota', 'state', quotaState.state || 'none']);

            let clearTrash, trashSize;
            try {
                const { data } = yield new UserFullQuotaAPICall().makeRequest({ trash: true });

                if (data.status === 200) {
                    const { over }: ReturnType<typeof UserSelectors.getCloudSpace> = yield select(UserSelectors.getCloudSpace);

                    trashSize = data.body.trash_size || 0;
                    clearTrash = over.original < trashSize;
                }
            } catch (_) {}

            if (clearTrash) {
                yield put(setProfileOverquotaState({ trashSize }));
            }

            if (quotaState.state && statesForSplash.includes(quotaState.state)) {
                yield call(
                    renderOverquotaSplash,
                    {
                        mode: !overquotaAfterClear && quotaState.state === 'after_clear' ? 'cleared' : quotaState.state,
                    },
                    overquotaPromo.onClose
                );

                if (!isOverQuota) {
                    sendShowQuotaBannerForNonOverQuotaUser(`splash_${quotaState.state}`);
                }
            } else if (isOverquotaFromProfileApi && !tempHideOverquotaBanner) {
                yield call(renderOverquotaModal, {
                    onClose: overquotaPromo.onClose,
                });

                if (!isOverQuota) {
                    sendShowQuotaBannerForNonOverQuotaUser(`modal_${quotaState.state}`);
                }

                sendKaktamLog(
                    {
                        text: 'show overquota modal-2',
                        state: quotaState.state,
                        quotaState,
                        isOverQuota,
                        isOverquotaFromProfileApi,
                        overquotaUseQuotaForProfileApi,
                        time: Date.now().toLocaleString(),
                    },
                    'overquotacloud'
                );
            }

            return;
        }

        yield call(renderOverquotaBanner, { tariff: overquotaPromo.tariff }, overquotaPromo.onClose);

        if (!isOverQuota) {
            sendShowQuotaBannerForNonOverQuotaUser(`others_${quotaState.state}`);
        }
    }
}
