import { DownloadZipV3APICall } from 'reactApp/api/DownloadZipV3APICall';
import { getAlbumIdsParams } from 'reactApp/modules/albums/albums.selector';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { store } from 'reactApp/store';

const callApiDownload = (data: any, urlSuffix): any => new DownloadZipV3APICall().makeRequest(data, urlSuffix);

function getStorageApiUrl(storage: string) {
    const { isStock, isPublic, isAlbums, isIncomingPublic } = getStorage(storage);

    if (isStock) {
        return '/stock';
    }

    if (isAlbums) {
        return '/album';
    }

    if (isIncomingPublic) {
        return '/weblink/multiple';
    }

    if (isPublic) {
        return '/weblink';
    }

    return '/home';
}

class FileController {
    public downloadFiles = (idxs: string[], zipFileName: string, storage: string): Promise<string> => {
        let _idParams;

        switch (storage) {
            case EStorageType.home:
            case EStorageType.search:
            case EStorageType.sharedLinks:
            case EStorageType.sharedAutodelete:
            case EStorageType.sharedIncoming:
            case EStorageType.r7:
            case EStorageType.myoffice:
            case EStorageType.myofficeAttaches:
            case EStorageType.favorites:
            case EStorageType.recommend:
            case EStorageType.feed:
            case EStorageType.start:
            case EStorageType.gallery:
            case EStorageType.documents:
            case EStorageType.alldocuments:
            case EStorageType.attaches:
            case EStorageType.story:
            case EStorageType.quotaCleaner:
            case EStorageType.r7wopi:
                _idParams = { home_list: idxs };
                break;
            case 'links':
            case 'public':
            case EStorageType.incomingPublic:
                _idParams = { weblink_list: idxs };
                break;

            case 'files':
                _idParams = { files_list: idxs };
                break;

            case 'stock':
                _idParams = { stock_list: idxs };
                break;

            case 'trashbin':
                _idParams = { trashbin_list: idxs };
                break;
            case 'albums':
                _idParams = getAlbumIdsParams(store.getState(), idxs);
                break;

            default:
                break;
        }

        return callApiDownload(
            {
                ..._idParams,
                name: zipFileName,
            },
            {
                url: getStorageApiUrl(storage),
            }
        ).then(({ status, data: { key } }): Promise<string> => {
            if (status === 200 && Boolean(key)) {
                return key;
            }
            return Promise.reject();
        });
    };
}

export const fileController = new FileController();
