import { Spacing, Text } from '@vkontakte/vkui';
import cover from 'img/subscriptions/cancel-autorenew-family.png';
import coverEmpty from 'img/subscriptions/cancel-autorenew-family-empty.png';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { BuySubscriptionActions } from 'reactApp/modules/buySubscription/buySubscription.module';
import { inviteToFamilyOrCreate } from 'reactApp/modules/family/family.actions';
import { isFamilyEmpty, isUserFamilyOwner } from 'reactApp/modules/family/family.selectors';
import { getFeatureAbCancelSubsWithCaptcha } from 'reactApp/modules/features/features.selectors';
import {
    ESubscriptionModalAction,
    useSendSubscriptionModalAnalytics,
} from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal.analytics';
import { renderCancelSubscriptionWithCaptchaModal } from 'reactApp/ui/CancelSubscriptionWithCaptchaModal/CancelSubscriptionWithCaptchaModal.helpers';

import styles from './CancelAutoRenewFamilyModal.css';
import type { CancelAutoRenewFamilyModalProps } from './CancelAutoRenewFamilyModal.types';

export const CancelAutoRenewFamilyModal = memo<CancelAutoRenewFamilyModalProps>(({ subscription, onClose }) => {
    const dispatch = useDispatch();

    const isFamilyOwner = useSelector(isUserFamilyOwner);
    const isEmptyFamily = useSelector(isFamilyEmpty);
    const notEmptyFamilyOwner = isFamilyOwner && !isEmptyFamily;
    // tempexp_13984-next-line
    const abWithCaptcha = useSelector(getFeatureAbCancelSubsWithCaptcha);
    const sendAnalytics = useSendSubscriptionModalAnalytics(subscription);

    const image = notEmptyFamilyOwner ? cover : coverEmpty;

    useEffect(() => {
        emitAnalyticEvent(notEmptyFamilyOwner ? AnalyticEventNames.CANCEL_SUB_FAMILY : AnalyticEventNames.CANCEL_SUB_EMPTY_FAMILY);
        sendAnalytics(notEmptyFamilyOwner ? ESubscriptionModalAction.family : ESubscriptionModalAction.familyEmpty);
    }, []);

    const content = useMemo(() => {
        return (
            <>
                <Spacing size={16} />
                <Text className={styles.text}>
                    {notEmptyFamilyOwner
                        ? 'После отключения они тоже не смогут ей пользоваться и вернутся к бесплатным аккаунтам'
                        : 'Добавьте в подписку до 3 участников — цена при этом не изменится. Место станет общим, но у каждого будет доступ только к своим файлам'}
                </Text>
                <Spacing size={24} />
            </>
        );
    }, [notEmptyFamilyOwner]);

    const handleAddMember = useCallback(() => {
        emitAnalyticEvent(AnalyticEventNames.CANCEL_SUB_EMPTY_FAMILY_CLICK);
        sendAnalytics(ESubscriptionModalAction.familyEmptyAdd);
        dispatch(inviteToFamilyOrCreate());
        onClose?.();
    }, [onClose, dispatch, sendAnalytics]);

    const handleKeepSubscription = useCallback(() => {
        emitAnalyticEvent(AnalyticEventNames.CANCEL_SUB_FAMILY_CLICK);
        sendAnalytics(ESubscriptionModalAction.familySave);
        onClose?.();
    }, [onClose, sendAnalytics]);

    const handleCancelSubscription = useCallback(() => {
        sendAnalytics(notEmptyFamilyOwner ? ESubscriptionModalAction.familyCancel : ESubscriptionModalAction.familyEmptyCancel);

        // tempexp_13984-start
        if (abWithCaptcha === 'b') {
            onClose?.();
            renderCancelSubscriptionWithCaptchaModal({ subscriptionId: subscription.id });
            return;
        }
        // tempexp_13984-end

        dispatch(BuySubscriptionActions.cancelAutorenewSubscription({ subscriptionId: subscription.id, sendAnalytics }));
        onClose?.();
    }, [onClose, dispatch, abWithCaptcha, subscription, sendAnalytics, notEmptyFamilyOwner]);

    const handleClose = useCallback(() => {
        onClose?.();
    }, [onClose]);

    return (
        <WhatsNewDialog
            title={notEmptyFamilyOwner ? 'В вашей подписке есть участники' : 'Одна цена на четверых'}
            content={content}
            imageUrl={image}
            primaryButtonText={notEmptyFamilyOwner ? 'Сохранить подписку' : 'Добавить участника'}
            onClick={notEmptyFamilyOwner ? handleKeepSubscription : handleAddMember}
            secondaryButtonText="Отключить"
            onSecondary={handleCancelSubscription}
            onClose={handleClose}
            imageBgColor="transparent"
            buttonTheme="vk"
            dialogSize="tiny"
            qaId="cancel-autorenew-family-modal"
        />
    );
});

CancelAutoRenewFamilyModal.displayName = 'CancelAutoRenewFamilyModal';
