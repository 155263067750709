import { downloadAsJpeg } from 'Cloud/Application/DownloadAsJpeg';
import Lottie, { type LottieRefCurrentProps } from 'lottie-react';
import React, { type FC, type MouseEvent, useRef } from 'react';
import type { AttachesItem } from 'reactApp/modules/attaches/attaches.types';
import type { CloudFile } from 'reactApp/modules/storage/storage.types';
import { DropdownItemAction } from 'reactApp/ui/DropdownItemAction/DropdownItemAction';
import { DropdownList, DropdownTheme } from 'reactApp/ui/DropdownList/DropdownList';
import { useDropdownList } from 'reactApp/ui/DropdownList/DropdownList.hooks';
import { DownloadTooltip } from 'reactApp/ui/ReactViewer/ViewerHeader/DownloadTooltip';
import { MenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/MenuButton/MenuButton';

import animation from './animation.json';
import styles from './DownloadMenuButton.css';

interface Props {
    file: CloudFile | AttachesItem;
    onClick: (evt: MouseEvent) => void;
}

export const DownloadMenuButton: FC<Props> = ({ file, onClick }) => {
    const { controlRef, isOpen, open, close, findDropdownPosition } = useDropdownList<HTMLDivElement>({ offsetTop: 8 });

    const allowDownloadAsJpeg = downloadAsJpeg.isAvailable({
        ext: file.ext,
        itemType: 'type' in file ? file.type : '',
        storage: file.storage,
    });

    const lottieRef = useRef<LottieRefCurrentProps>(null);

    const text = file.isFolder ? 'Скачать' : 'Скачать файл';

    return (
        <>
            <DownloadTooltip btnText={text} downloadRef={controlRef.current} />
            <MenuButton
                hint={text}
                animated={false}
                icon={<Lottie className={styles.icon} animationData={animation} autoplay={false} loop={false} lottieRef={lottieRef} />}
                hasDropdown={allowDownloadAsJpeg}
                isDropdownOpen={isOpen}
                ref={controlRef}
                onClick={(evt) => {
                    onClick(evt);
                }}
                onDropdownClick={() => {
                    if (isOpen) {
                        close();
                    } else {
                        open();
                    }
                }}
                onMouseEnter={() => {
                    lottieRef?.current?.goToAndPlay(0);
                }}
            />
            {isOpen && (
                <DropdownList
                    gaId="viewer-dnld"
                    className={styles.list}
                    titleClassName={styles.listTitle}
                    close={close}
                    closeOnResize
                    closeOnScroll
                    posX={0}
                    posY={0}
                    parentRef={controlRef}
                    theme={DropdownTheme.jpeg}
                    title="Скачать в формате"
                    calcPosition={findDropdownPosition}
                    list={downloadAsJpeg.getDropdownItems({
                        itemOrId: file,
                        gaSuffix: 'viewer',
                        ext: file.ext,
                        className: styles.itemWrapper,
                    })}
                    renderItem={(item) => <DropdownItemAction text={item.text} />}
                />
            )}
        </>
    );
};
