import React, { memo, useEffect } from 'react';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import {
    beforePurchaseButtonText,
    beforePurchaseHeader,
    beforePurchaseMessage,
} from 'reactApp/appHelpers/featuresHelpers/features/beforePurchaseMessage';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';

import styles from './BeforePurchaseModal.css';

export type BeforePurchaseModalProps = {
    onClose?: () => void;
    onClick: () => void;
};

export const BeforePurchaseModal = memo<BeforePurchaseModalProps>(({ onClose, onClick }) => {
    useEffect(() => {
        emitAnalyticEvent(AnalyticEventNames.BEFORE_PURCHASE_MESSAGE_SHOW);
    }, []);

    return (
        <BaseConfirmDialog
            dataQAId="before-purchase-modal"
            className={styles.dialog}
            onSuccess={onClick}
            onShow={() => {
                emitAnalyticEvent(AnalyticEventNames.BEFORE_PURCHASE_MESSAGE_SHOW);
            }}
            onClose={onClose}
            renderHeader={() => beforePurchaseHeader || 'Обработка платежей может занять несколько дней'}
            renderContent={() => (
                <>
                    {beforePurchaseMessage ? (
                        <div className={styles.content}>{beforePurchaseMessage}</div>
                    ) : (
                        <div className={styles.content}>
                            В&nbsp;праздники платежи обрабатываются с&nbsp;задержкой. Подписка Mail Space включится после
                            оплаты&nbsp;&mdash; но&nbsp;не&nbsp;сразу
                        </div>
                    )}
                </>
            )}
            size="average"
            buttonTheme="vk"
            buttons={[EButtonType.accept]}
            successButtonText={beforePurchaseButtonText || 'Понятно'}
        />
    );
});

BeforePurchaseModal.displayName = 'BeforePurchaseModal';
