import React, { type FC, type PropsWithChildren, type RefObject, useCallback, useEffect } from 'react';
import { animated, easings, useSpring } from 'react-spring';

import styles from './AnimatedScale.css';

interface Props {
    target: RefObject<HTMLElement> | null;
}

export const AnimatedScale: FC<PropsWithChildren<Props>> = ({ target, children }) => {
    const [springs, api] = useSpring(() => ({
        from: { transform: 'scale(1)' },
        config: {
            duration: 130,
            easing: easings.easeOutQuad,
        },
    }));

    const handleMouseEnter = useCallback(() => {
        api.start({
            to: async (next) => {
                await next({ transform: 'scale(1.2)' });
                await next({ transform: 'scale(1.1)' });
                await next({ transform: 'scale(1)' });
            },
        });
    }, [api]);

    useEffect(() => {
        if (target?.current) {
            target.current.addEventListener('mouseenter', handleMouseEnter);
        }

        return () => {
            if (target?.current) {
                target.current.removeEventListener('mouseenter', handleMouseEnter);
            }
        };
    }, [target, handleMouseEnter]);

    return (
        <animated.div className={styles.wrapper} style={springs}>
            {children}
        </animated.div>
    );
};
