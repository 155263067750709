import classNames from 'clsx';
import { ReactComponent as IconArrowUp } from 'img/icons/arrow_up.svg';
import React, { useCallback, useRef, useState } from 'react';
import type { EStorageType } from 'reactApp/modules/storage/storage.types';
import { AnimatedScale } from 'reactApp/ui/AnimatedScale/AnimatedScale';
import styles from 'reactApp/ui/ScrollTopButton/ScrollTopButton.css';
import { sendPaymentGa } from 'reactApp/utils/paymentGa';

interface Props {
    bottomOffset: number;
    isScrolledToTop: boolean;
    scrollToTop: () => void;
    storage: EStorageType;
    isAlbum: boolean;
    parentFolder?: string | undefined | null | false;
}

export const ScrollTopButton = ({ bottomOffset = 0, scrollToTop, isScrolledToTop = true, storage, isAlbum, parentFolder }: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const timerRef = useRef<number | null>(null);

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        scrollToTop();
        sendPaymentGa({
            eventCategory: 'scroll-top',
            action: 'click',
            source: storage,
            tab: storage,
            type_folder: isAlbum ? 'album' : parentFolder ? 'folder_in_folder' : 'folder',
        });
    };

    const clearTimer = () => {
        if (timerRef.current) {
            window.clearTimeout(timerRef.current);
        }
        timerRef.current = null;
    };

    const handleShowTooltip = useCallback(() => {
        sendPaymentGa({
            eventCategory: 'scroll-top',
            action: 'hover',
            source: storage,
            tab: storage,
            type_folder: isAlbum ? 'album' : parentFolder ? 'folder_in_folder' : 'folder',
        });

        const show = () => {
            setShowTooltip(true);
        };

        clearTimer();
        timerRef.current = window.setTimeout(show, 100);
    }, [clearTimer]);

    const handleHideTooltip = useCallback(() => {
        setShowTooltip(false);
        clearTimer();
    }, [setShowTooltip, clearTimer]);

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_hidden]: isScrolledToTop,
            })}
            onClick={handleClick}
            onMouseEnter={handleShowTooltip}
            onMouseLeave={handleHideTooltip}
            ref={ref}
            style={{
                bottom: bottomOffset,
            }}
            data-qa-id="scroll-top-button"
        >
            <div className={classNames(styles.tooltip, { [styles.tooltip_hidden]: !showTooltip })}>
                <span>Наверх</span>
            </div>
            <AnimatedScale target={ref}>
                <IconArrowUp className={styles.arrow} fill={'var(--vkui--color_text_primary)'} />
            </AnimatedScale>
        </div>
    );
};
