import { EditorID } from 'Cloud/Application/Editor/types';
import { EDITORS_CONFIG, IS_AUTO_TEST_HIDE, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { isWopiEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { startEditor } from 'reactApp/modules/editor/editor.module';
import { getEditorState } from 'reactApp/modules/editor/editor.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { store as reduxStore } from 'reactApp/store';
import { take } from 'redux-saga/effects';
import { put, select } from 'typed-redux-saga';

import { storeHelper } from '../promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from '../promo.module';
import { EPromoType } from '../promo.types';

export const PROMO_ID = 'co-edit-share-popup';

export function* initCoEditPopupPromo() {
    const alreadyShown = storeHelper.getValue(PROMO_ID);
    const storage = yield* select(getCurrentStorage);
    const isPublic = storage === EStorageType.public;
    const isMyOfficeEditor =
        storage === EStorageType.myoffice || (storage === EStorageType.editor && EDITORS_CONFIG[0]?.id === EditorID.MYOFFICE);

    if (!isMyOfficeEditor || alreadyShown || IS_WEBVIEW || IS_AUTO_TEST_HIDE || !isWopiEnabled || isPublic) {
        return;
    }

    yield take(startEditor);

    const editorState = yield* select(getEditorState);

    if (editorState.item?.weblinkAccessRights && editorState.item?.weblinkAccessRights === 'rw') {
        return;
    }

    yield* put(
        addPromoToShowQueue({
            type: EPromoType.coEditPopup,
            promoId: PROMO_ID,
            onShow: () => {
                reduxStore.dispatch(promoShown(EPromoType.coEditPopup));
                storeHelper.markAsShown(PROMO_ID);
            },
            onClose: () => {
                reduxStore.dispatch(removePromo(EPromoType.coEditPopup));
            },
        })
    );
}
