import throttle from 'lodash.throttle';
import { useCallback, useEffect, useRef, useState } from 'react';

export function useToolbarOverlapDatalist({ enabled }: { enabled: boolean }) {
    const toolbarRef = useRef<HTMLDivElement>(null);
    const dataListRef = useRef<HTMLDivElement>(null);
    const [isOverlap, setIsOverlap] = useState(false);
    const onScroll = useCallback(() => {
        const toolbarRect = toolbarRef.current?.getBoundingClientRect() ?? { top: 0, height: 0 };
        const dataListRect = dataListRef.current?.getBoundingClientRect() ?? { top: 0, height: 0 };

        setIsOverlap(toolbarRect?.top + toolbarRect?.height > dataListRect?.top);
    }, []);
    const throttledScroll = throttle(onScroll, 100);
    useEffect(() => {
        if (enabled) {
            document.addEventListener('scroll', throttledScroll);
        }

        return () => {
            if (enabled) {
                document.removeEventListener('scroll', throttledScroll);
            }
        };
    }, [throttledScroll, enabled]);
    return { isOverlap, toolbarRef, dataListRef };
}
