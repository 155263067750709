import { Spacing, Text, Title } from '@vkontakte/vkui';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';

import styles from './RepeatPurchaseMobileModal.css';

export type RepeatPurchaseMobileModalProps = {
    onClose?: () => void;
    onCancel: () => void;
    onContinuePurchase: () => void;
};

export const RepeatPurchaseMobileModal = memo<RepeatPurchaseMobileModalProps>(({ onClose, onCancel, onContinuePurchase }) => {
    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        emitAnalyticEvent(AnalyticEventNames.REPEAT_PURCHASE_MOBILE_SHOW);
    }, []);

    const handleClose = useCallback(() => {
        onClose?.();
    }, [onClose]);

    return (
        <MobileDialog
            id="repeat-purchase-mobile"
            onClose={handleClose}
            closeOnDimmerClick
            topmost
            scrollRef={scrollRef}
            mod="base"
            contextPadding="zero"
        >
            <div ref={scrollRef} className={styles.root}>
                <Title level="2">Вы уже оплатили подписку</Title>
                <Spacing size={12} />
                <Text className={styles.text}>
                    Ваш тариф Mail Space уже действует&nbsp;&mdash; скоро он&nbsp;появится в&nbsp;списке активных подписок
                </Text>
                <Spacing size={24} />
                <Button theme="vk" primary sizeMode={ButtonSizeMode.big} onClick={onCancel}>
                    Отлично
                </Button>
                <Spacing size={12} />
                <Button theme="vk" className={styles.transparent} sizeMode={ButtonSizeMode.big} onClick={onContinuePurchase}>
                    Хочу ещё одну
                </Button>
            </div>
        </MobileDialog>
    );
});

RepeatPurchaseMobileModal.displayName = 'RepeatPurchaseMobileModal';
