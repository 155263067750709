import { SafetySessionStorage } from '@mail/storage';
import { Icon16Cancel } from '@vkontakte/icons';
import { Link, Text } from '@vkontakte/vkui';
import React, { useCallback, useEffect, useState } from 'react';
import { SHARE_SPACE_CONSUMPTION_HREF } from 'reactApp/appHelpers/featuresHelpers/features/shareSpaceConsumptionWarning';
import { sendGa } from 'reactApp/utils/ga';

import styles from './SpaceConsumptionWarning.css';

const storage = new SafetySessionStorage({});

const SPACE_CONSUMPTION_WARNING_CLOSED_KEY = 'SPACE_CONSUMPTION_WARNING_CLOSED_KEY';

const sendRadar = (action: string) => sendGa('shr-cnswrn', action);

const SpaceConsumptionWarningInner = () => {
    const [isVisible, setIsVisible] = useState(() => storage.getItem(SPACE_CONSUMPTION_WARNING_CLOSED_KEY) !== 'true');

    useEffect(() => {
        sendRadar('shw');
    }, []);

    const handleClose = useCallback(() => {
        setIsVisible(false);
        storage.setItem(SPACE_CONSUMPTION_WARNING_CLOSED_KEY, 'true');
        sendRadar('cls');
    }, []);

    const handleHelp = useCallback(() => sendRadar('hlp'), []);

    if (!isVisible) {
        return null;
    }

    return (
        <div className={styles.root} data-qa-id="share-space-consumption-warning">
            <div className={styles.close} onClick={handleClose} data-qa-id="close">
                <Icon16Cancel />
            </div>
            <Text>
                С таким доступом расходуется место в Облаке
                <br /> пользователя.{' '}
                <Link href={SHARE_SPACE_CONSUMPTION_HREF} target="_blank" onClick={handleHelp} data-qa-id="link">
                    Подробнее
                </Link>
            </Text>
        </div>
    );
};

export const SpaceConsumptionWarning = React.memo(SpaceConsumptionWarningInner);
