import { useSelector } from 'react-redux';
import { getDiscoutOutflowPercentByProductId } from 'reactApp/appHelpers/featuresHelpers/features/discountInOutflow';
import { BillingSelectors } from 'reactApp/modules/billing/billing.selectors';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { cheapestProductIDs } from 'reactApp/modules/products/products.const';
import { getPretextPeriodForProduct } from 'reactApp/modules/products/products.helpers';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import type { RootState } from 'reactApp/store';
import type { Product } from 'reactApp/types/Billing';
import { getPeriodName, getPeriodNameAsWord, isHourPeriod, isMonthPeriod, isYear2Period, isYearPeriod } from 'reactApp/utils/Period';
import { formatPrice } from 'reactApp/utils/priceHelpers';

export const useProduct = ({ productId, product: incomingProduct }: { productId: string; product?: Product }) => {
    const renewalCost = useSelector(BillingSelectors.getRenewalCost);

    const discountOutflowPercent = getDiscoutOutflowPercentByProductId(productId);

    // TODO: если id невалидный, то product будет undefined и всё посыпется. Стоит предусмотреть и убрать as Product
    const product = useSelector((state: RootState) => incomingProduct || ProductsSelectors.getProductById(state, productId)) as Product;
    const price = product?.discountPrice || product?.price || 0;
    const renewalPrice = renewalCost || price;
    const space = product?.space?.value || '';
    let promoPeriod = (!product?.isForceDiscountTrial && product?.trialPeriod) || product?.discountPeriod;

    const formattedRenewalPrice = `${formatPrice(renewalPrice)}\u00A0₽`;
    const fullPrice = `${formatPrice(product?.price)}\u00A0₽`;

    const isMonth = isMonthPeriod(product.period);
    const isYear = isYearPeriod(product.period);

    const isMbiz = product?.isMbUser;

    if ((isMonth || isYear) && product?.discountPeriodCount) {
        promoPeriod = `${product.discountPeriodCount}${isMonth ? 'm' : 'y'}`;
    }

    const isPhone = useSelector(EnvironmentSelectors.isPhone);

    const trialString =
        product?.hasTrial && Boolean(product?.trialPeriod) && !isPhone ? `Для проверки карты мы спишем и сразу же вернем 1 ₽. ` : '';

    if (promoPeriod && price && !discountOutflowPercent) {
        if (isMbiz) {
            return {
                text: `${trialString} Первый ${getPeriodName(promoPeriod)} тариф ${product.space.space} ${
                    product.space.units
                } бесплатно, далее ${fullPrice} в ${getPeriodName(
                    product.period
                )},  продление —  автоматическое. Отменить платёж можно в настройках подписки.`,
                isMbiz,
            };
        }

        return {
            text: `${trialString}${getPretextPeriodForProduct(promoPeriod)} ${getPeriodNameAsWord(
                promoPeriod,
                true
            )} оплата составит ${fullPrice} ${isYear2Period(product.period) ? 'за' : 'в'} ${getPeriodName(product.period)}.`,
            isMbiz,
        };
    }

    // tempexp_16598-start
    if (Object.values(cheapestProductIDs).includes(productId)) {
        return {
            text: 'Подписка «Без рекламы» продлевается автоматически.',
            isMbiz,
        };
    }
    // tempexp_16598-end

    if (isHourPeriod(product.period)) {
        return {
            text: 'Это оплата загрузки одного файла — подписка Mail Space не подключится.',
            isMbiz,
            isOneTime: true,
        };
    }

    return {
        text: `${trialString}Подписка на ${space} ${
            renewalPrice && `за ${formattedRenewalPrice}`
        } продлевается автоматически через ${getPeriodName(product.period)} на аналогичный период.`,
        isMbiz,
    };
};
