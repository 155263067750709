import { Spacing, Text, Title } from '@vkontakte/vkui';
import cover from 'img/public/public-password-tariff-modal.png';
import coverDark from 'img/public/public-password-tariff-modal-dark.png';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import {
    PublicPasswordEventCategory,
    sendPublicPasswordAnalytics,
} from 'reactApp/components/SharingWindow/SharingNew/Weblink/PublicPassword/PublicPassword.analytics';
import { VK_UI_DARK_THEME } from 'reactApp/constants';
import { PaidInfoSelectors } from 'reactApp/modules/paidInfo/paidInfo.selectors';
import { publicPinBuySubscription } from 'reactApp/modules/public/public.actions';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';

import styles from './PublicPasswordTariffModal.css';
import type { PublicPasswordTariffModalProps } from './PublicPasswordTariffModal.types';

export const PublicPasswordTariffModal = memo<PublicPasswordTariffModalProps>(({ onClose }) => {
    const dispatch = useDispatch();
    const scrollRef = useRef<HTMLDivElement>(null);

    const isDarkMode = document.body.classList.contains(VK_UI_DARK_THEME);
    const image = isDarkMode ? coverDark : cover;

    const isUserWithoutPayment = useSelector(PaidInfoSelectors.isUserWithoutPayment);

    useEffect(() => {
        sendPublicPasswordAnalytics({ eventCategory: PublicPasswordEventCategory.tooltipPassword, action: 'show' });
        emitAnalyticEvent(AnalyticEventNames.PUBLIC_PASSWORD_PROMO_SHOW);
    }, []);

    const handleClose = useCallback(() => {
        onClose?.();
        sendPublicPasswordAnalytics({ eventCategory: PublicPasswordEventCategory.tooltipPassword, action: 'close' });
    }, [onClose]);

    const handleClick = useCallback(() => {
        dispatch(publicPinBuySubscription());
        onClose?.();
        sendPublicPasswordAnalytics({ eventCategory: PublicPasswordEventCategory.tooltipPassword, action: 'click' });
        emitAnalyticEvent(AnalyticEventNames.PUBLIC_PASSWORD_PROMO_CLICK);
    }, [dispatch, onClose]);

    return (
        <MobileDialog
            id="mobile-public-password-tariff"
            onClose={handleClose}
            closeOnDimmerClick
            topmost
            scrollRef={scrollRef}
            mod="base"
            contextPadding="zero"
        >
            <div ref={scrollRef} className={styles.root}>
                <img src={image} alt="Оформите подписку Mail Space" className={styles.image} />
                <Spacing size={24} />
                <div className={styles.content}>
                    <Title level="1">Оформите подписку Mail&nbsp;Space</Title>
                    <Spacing size={12} />
                    <Text>Добавите место для файлов — и сможете устанавливать пароль для самых важных</Text>
                </div>
                <Spacing size={24} />
                <Button theme="vk" primary sizeMode={ButtonSizeMode.extraBig} className={styles.button} onClick={handleClick}>
                    {isUserWithoutPayment ? 'Попробовать за 1 ₽' : 'Попробовать'}
                </Button>
            </div>
        </MobileDialog>
    );
});

PublicPasswordTariffModal.displayName = 'PublicPasswordTariffModal';
