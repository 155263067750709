import thanksImage from 'img/aside-promo-modal/thanks-balloon/gamer-bundle-thanks.png';
import thanksImageDark from 'img/aside-promo-modal/thanks-balloon/gamer-bundle-thanks-dark.png';
import React, { memo } from 'react';
import { AsidePromoModal } from 'reactApp/ui/AsidePromoModal/AsidePromoModal';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';

import type { ThanksBalloonProps } from './ThanksBalloon.types';

export const ThanksBalloon = memo<ThanksBalloonProps>(({ onShow, onClick, onClose }) => {
    const isDarkTheme = isDarkThemeModeEnabled();

    return (
        <AsidePromoModal
            title="Спасибо за интерес"
            text={'Мы пока работаем над этим тарифом. Сообщим сразу, как\u00A0появится'}
            buttonText="Хорошо"
            imageUrl={isDarkTheme ? thanksImageDark : thanksImage}
            showAboveViewer={true}
            onShow={onShow}
            onClick={onClick}
            onClose={onClose}
        />
    );
});

ThanksBalloon.displayName = 'ThanksBalloon';
