import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { promoDestopApp } from 'reactApp/appHelpers/featuresHelpers/features/promoDestopApp';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { sendDwh } from 'reactApp/utils/ga';

export const useMobileAppPromo = () => {
    const storage = useSelector(getCurrentStorage);

    const showMobileAppTreePromo =
        storage !== EStorageType.integration &&
        storage !== EStorageType.inlineIntegration &&
        storage !== EStorageType.family &&
        promoDestopApp;

    const handleTreePromoMobileAppClick = useCallback(() => {
        sendDwh({
            eventCategory: 'app_block',
            label: 'install_click',
        });
    }, []);

    return {
        showMobileAppTreePromo,
        handleTreePromoMobileAppClick,
    };
};
