import type { PayloadAction } from '@reduxjs/toolkit';
import { logger } from 'lib/logger';
import { WeblinksSeoIndexedDisableAPIV4Call } from 'reactApp/api/weblinks/WeblinksSeoIndexedDisableAPIV4Call';
import { WeblinksSeoIndexedEnableAPIV4Call } from 'reactApp/api/weblinks/WeblinksSeoIndexedEnableAPIV4Call';
import { isAlbum } from 'reactApp/modules/albums/albums.types';
import { getItemTypeName } from 'reactApp/modules/file/utils';
import { toggleSeo as toggleSeoAction } from 'reactApp/modules/modifying/modifying.actions';
import type { ToggleSeoData } from 'reactApp/modules/modifying/modifying.types';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { getStorageItemById } from 'reactApp/modules/storage/storage.selectors';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';
import { channel } from 'redux-saga';
import { put, select, take } from 'redux-saga/effects';

export function* toggleSeo(action: PayloadAction<ToggleSeoData>) {
    const currentStorage = yield select(getCurrentStorage);
    const storage = action.payload.item.storage || currentStorage;

    const item = yield select((state) => getStorageItemById(state, storage, action.payload.item.id));

    const actionChannel = channel();

    const onActionClick = () => actionChannel.put(true);
    const onClose = () => actionChannel.put(false);

    const itemType = isAlbum(item) ? 'album' : item?.isFolder ? 'folder' : 'file';
    const itemName = isAlbum(item) ? 'Альбом' : item?.isFolder ? 'Папка' : 'Файл';
    const itemNameGen = getItemTypeName(item);
    const itemNameAccess = item?.isFolder && !isAlbum(item) ? 'доступна' : 'доступен';
    const itemNameAccessClosed = item?.isFolder && !isAlbum(item) ? 'закрыта' : 'закрыт';
    const itemNameVis = item?.isFolder && !isAlbum(item) ? 'видна' : 'виден';

    // eslint-disable-next-line no-irregular-whitespace
    const nbps = ' ';

    const seoTextEnabled = `${itemName} «${item?.name}» не будет ${itemNameVis} в${nbps}поисковиках. Но${nbps}доступ по${nbps}ссылке будет открыт${nbps}— ${itemNameGen} увидят все, у${nbps}кого есть ссылка`;
    const seoDisabled = `${itemName} будет ${itemNameAccess} всем в${nbps}интернете${nbps}— ссылка появится в${nbps}выдаче поисковиков, все${nbps}смогут её${nbps}смотреть и${nbps}сохранять`;

    try {
        openPopupHelper({
            popupName: popupNames.NO_WARNING,
            data: {
                onActionClick,
                onClose,
                id: `weblink-warning-dialog-seo${item?.weblinkSeoEnabled ? '-close' : '-open'}`,
                title: `${item?.weblinkSeoEnabled ? 'Закрыть' : 'Открыть'} для поисковиков?`,
                description: item?.weblinkSeoEnabled ? seoTextEnabled : seoDisabled,
                buttonAcceptText: item?.weblinkSeoEnabled ? 'Закрыть' : 'Открыть',
                buttonRejectText: item?.weblinkSeoEnabled ? 'Не закрывать' : 'Не открывать',
            },
        });

        const res = yield take(actionChannel);

        if (!res) {
            return;
        }

        const arg = { weblink_id: item.weblink };
        if (item?.weblinkSeoEnabled) {
            yield new WeblinksSeoIndexedDisableAPIV4Call().makeRequest(arg);
        } else {
            yield new WeblinksSeoIndexedEnableAPIV4Call().makeRequest(arg);
        }

        sendDwh({
            eventCategory: ECategoryGa.public,
            action: item?.weblinkSeoEnabled ? 'seo-disallow' : 'seo-allow',
            dwhData: {
                count_objects: item?.isFolder ? item?.count?.all ?? 0 : 1,
                type_public: itemType,
                id_folder: item?.isFolder ? item.weblink : '',
                id_file: !item?.isFolder ? item.weblink : '',
                place: 'cloud',
                source: storage,
                extension: item?.ext,
            },
        });

        yield put(toggleSeoAction({ item }));

        yield put(
            showSnackbarAction({
                id: 'seo-open-success',
                title: item?.weblinkSeoEnabled
                    ? `${itemName} ${itemNameAccessClosed} для поисковиков`
                    : `${itemName} ${itemNameAccess} в поисковиках`,
                text: item?.weblinkSeoEnabled ? 'Ссылка не будет появляться в поисковой выдаче' : 'Ссылка открыта для всех в интернете',
                type: SnackbarTypes.success,
                closable: true,
            })
        );
    } catch (error) {
        yield put(
            showSnackbarAction({
                id: 'seo-open-failure',
                text: `Не удалось ${item?.weblinkSeoEnabled ? 'закрыть' : 'открыть'} ${itemNameGen} для поисковиков`,
                type: SnackbarTypes.failure,
                closable: true,
            })
        );

        logger.error(error);
    }
}
