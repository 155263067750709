import { xray } from 'lib/xray';

export const NEW_PROMO_ID = {
    PDF_AUTOSAVE_PROMO: 'pdf-autosave-promo',
    PDF_WORK_WITH_PAGES_PROMO: 'pdf-work-with-pages-promo',
};

export const PromoLocalStoreDeps = {
    keyPattern: (key) => `new_promo_data_${key}`,
    getAsJSON: (key) => {
        const item = localStorage.getItem(key);
        try {
            return item ? JSON.parse(item) : undefined;
        } catch {
            xray.send(`parse-err-promo-${key}`);
            return undefined;
        }
    },
    setAsJSON: (key, value) => {
        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            xray.send(`set-err-promo-${key}`);
        }
    },
    removeItem: (key) => {
        localStorage.removeItem(key);
    },
};
