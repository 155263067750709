import { Icon20Cancel } from '@vkontakte/icons';
import React, { type FC, type MouseEvent } from 'react';
import { MenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/MenuButton/MenuButton';

import styles from './CloseMenuButton.css';

interface Props {
    onClick?: (evt: MouseEvent) => void;
}

export const CloseMenuButton: FC<Props> = ({ onClick }) => {
    return <MenuButton className={styles.button} hint="Закрыть" icon={<Icon20Cancel />} onClick={onClick} />;
};
