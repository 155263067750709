import { getFeature } from 'Cloud/Application/FeaturesEs6';
import browser from 'Cloud/browser';
import config from 'Cloud/config';
import classNames from 'clsx';
import React, { type ReactElement, memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_ONPREMISE, IS_TABLET_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { abSharingLinkFakedoor } from 'reactApp/appHelpers/featuresHelpers';
import { disablePublicDownload } from 'reactApp/appHelpers/featuresHelpers/features/disablePublicDownload';
import { isPublicPasswordEnabled } from 'reactApp/appHelpers/featuresHelpers/features/publicPassword';
import { seoEnable } from 'reactApp/appHelpers/featuresHelpers/features/seoEnable';
import { isThemedSharedModalItem } from 'reactApp/appHelpers/featuresHelpers/features/themedPublic';
import { unPublishHelper } from 'reactApp/appHelpers/publishHelper';
import { sendGa } from 'reactApp/components/SharingWindow/Sharing.helpers';
import { EFrom, EPromoTooltip } from 'reactApp/components/SharingWindow/Sharing.types';
import { SharingNewSection } from 'reactApp/components/SharingWindow/SharingNew/Section/SharingNewSection';
import { AutoDelete } from 'reactApp/components/SharingWindow/SharingNew/Weblink/AutoDelete/AutoDelete';
import { DISABLE_PUBLIC_DOWNLOAD_TOOLTIP_ID } from 'reactApp/components/SharingWindow/SharingNew/Weblink/DisablePublicDownload/Tooltip/Tooltip';
import { useSeoOption } from 'reactApp/components/SharingWindow/SharingNew/Weblink/hooks/useSeoOption';
import { PUBLIC_PASSWORD_TOOLTIP_ID } from 'reactApp/components/SharingWindow/SharingNew/Weblink/PublicPassword/Tooltip/Tooltip';
import styles from 'reactApp/components/SharingWindow/Weblink/SharingWeblink.css';
import { useIsWindowIntegrationSmall } from 'reactApp/hooks/useIsWindowIntegrationSmall';
import { getEditorsFor, getIsFileForCoEditing } from 'reactApp/modules/editor/editor.selectors';
import {
    getFeatureEditingNoneditableFormats,
    getFeaturePublicAutoDeletePromo,
    getFeaturePublicDomestic,
} from 'reactApp/modules/features/features.selectors';
import { getIntegrationClient } from 'reactApp/modules/integration/integration.selectors';
import { publishRequest } from 'reactApp/modules/modifying/modifying.actions';
import { loadPaidInfoRequest } from 'reactApp/modules/paidInfo/paidInfo.module';
import { getPublicSharingInfo } from 'reactApp/modules/public/public.actions';
import { getPublicPin } from 'reactApp/modules/public/public.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { isAutoDeleteEnabled } from 'reactApp/modules/sharedAutoDelete/sharedAutoDelete.selectors';
import { getItemById } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserStorageSelectors } from 'reactApp/modules/user/userStorage';
import type { RootState } from 'reactApp/store';
import { CopyWeblink } from 'reactApp/ui/CopyWeblink/CopyWeblink';
import { getPublicUrl } from 'reactApp/utils/urlHelper';

import { useDomestic } from '../../Weblink/hooks/useDomestic';
import { useExpires } from '../../Weblink/hooks/useExpires';
import { renderUnpublishWeblinkDialog } from '../../Weblink/SharingWeblink.helpers';
import type { IProps } from '../../Weblink/SharingWeblink.types';
import { ChangeDesign, PUBLIC_DESIGN_TOOLTIP_ID } from './ChangeDesign/ChangeDesign';
import { DisablePublicDownload } from './DisablePublicDownload/DisablePublicDownload';
import { useAccessRights } from './hooks/useAccessRights';
import { PublicPassword } from './PublicPassword/PublicPassword';
import { SharingNewWeblinkList } from './WeblinkList/SharingNewWeblinkList';

const showWeblinkListFileSharing = getFeature('weblink-list-file-sharing') || false;
const IS_FREE_B2B_BIZ_EDITORS_USER = config.get('FREE_B2B_BIZ_EDITORS_USER');

let lastShownTooltip;

/* eslint-disable complexity */
export const SharingNewWeblink = memo(function SharingNewWeblink(props: IProps): ReactElement | null {
    const {
        isOwn,
        item,
        from,
        isSharedOrMouted,
        gaSuffix,
        noEdit = false,
        noExpiresControl = false,
        isPhone,
        onClose,
        sendSharingContentAnalytics,
        withPromoTooltip,
        showListSharing,
        folderName,
    } = props;
    const dispatch = useDispatch();
    const itemStorage = useSelector(getCurrentStorage);
    const storage = item?.storage || itemStorage;
    const isAlbum = from === EFrom.ALBUMS || storage === EStorageType.albums;
    const isWindowSmall = useIsWindowIntegrationSmall();
    const { isTutoria } = useSelector(getIntegrationClient);
    const isBizUser = useSelector(UserSelectors.isBizUser);
    const isCorpUser = useSelector(UserSelectors.isCorpUser);
    const isPublic = Boolean(item?.weblink);
    const isPaidUser = useSelector(UserSelectors.isPaidUser);
    // Исключаем стоки, у которых них нет публичной ссылки
    const publicUrl = (isPublic && getPublicUrl(item, isAlbum)) || '';
    const disableDownload = item?.weblinkEnabledDisabledDownload;

    const isFromWeblink = from === EFrom.WEBLINK || isPublic;
    const isStock = itemStorage === EStorageType.stock;

    const [isWeblinkOpened, setWeblinkOpened] = useState<boolean>(isFromWeblink);

    const seoOption = useSeoOption(item, !isBizUser && !IS_ONPREMISE && !isSharedOrMouted.isMounted && seoEnable);

    const showDisablePublicDownload =
        isWeblinkOpened && !isBizUser && !IS_ONPREMISE && !isSharedOrMouted.isMounted && disablePublicDownload;

    const isDomesticFeatureEnabled = useSelector(getFeaturePublicDomestic);
    const canShareForDomestic = isBizUser || isCorpUser;
    const showDomesticSettings =
        (!isAlbum || !isStock) &&
        ((canShareForDomestic && Boolean(isDomesticFeatureEnabled)) || (showWeblinkListFileSharing && !isAlbum)) &&
        isOwn;

    const editingNoneEditableFormatsFeature = useSelector((state: RootState) => {
        if (!item) {
            return;
        }
        return getFeatureEditingNoneditableFormats(state, {
            file: {
                ext: typeof item === 'object' && 'ext' in item ? item.ext : undefined,
                size: typeof item === 'object' && 'size' in item ? item.size : undefined,
            },
            storage,
        });
    });
    const convertExt = editingNoneEditableFormatsFeature?.ext || '';
    const isFolder = item?.isFolder;

    const isConvertFileForCoEditing = useSelector((state: RootState) => getIsFileForCoEditing(state, { ...item, ext: convertExt }));
    const isFileForCoEditing = useSelector((state: RootState) => getIsFileForCoEditing(state, item));
    const isEditable = !!useSelector((state: RootState) => getEditorsFor(state, convertExt ? { ...item, ext: convertExt } : item))?.length;

    const isDocumentForEdit =
        (item?.kind === 'document' && IS_FREE_B2B_BIZ_EDITORS_USER) ||
        isFileForCoEditing ||
        (isConvertFileForCoEditing && !(IS_TABLET_BROWSER || browser.isIpadOs()) && isEditable);
    const isReadOnlyItem = !!item?.isReadOnly;
    const parent = useSelector((state: RootState) => getItemById(state, item?.parent || ''));

    const isReadOnlyParent = !!(parent && 'isReadOnly' in parent && parent?.isReadOnly);
    const isReadOnlyProperty = !isReadOnlyItem && isSharedOrMouted.isMounted ? isReadOnlyParent : isReadOnlyItem;
    const isReadOnly = isFolder ? isReadOnlyProperty : isReadOnlyProperty || !isDocumentForEdit;

    const renderExpires = useExpires(item);
    const renderDomestic = useDomestic(item, { storage, canShareForDomestic });
    const renderAccessRights = useAccessRights(item, {
        hidden: isReadOnly,
        isReadOnlyProperty,
        onClose,
        disableDownload: disableDownload && disablePublicDownload,
    });

    const isAutodeleteFeatureEnabled = Boolean(useSelector(isAutoDeleteEnabled));
    const isAutodeletePromoFeatureEnabled = Boolean(useSelector(getFeaturePublicAutoDeletePromo));
    const showExpires = isStock || (!isAlbum && !noExpiresControl && isOwn);
    const showAccessRights = !isAlbum && isOwn;
    // Отключаем платную фичу в Тутории
    const showAutoDeleteSettings =
        !isTutoria &&
        isOwn &&
        !isAlbum &&
        item?.isFolder &&
        !isSharedOrMouted.isMounted &&
        (isAutodeleteFeatureEnabled || isAutodeletePromoFeatureEnabled);
    // tempexp_17341-next-line
    const showChangeDesign = isWeblinkOpened && isThemedSharedModalItem && !isAlbum && isOwn && item?.isFolder && !isTutoria && !isPhone;
    // tempexp_17605-start
    const pin = useSelector((state: RootState) => getPublicPin(state, item?.weblink || ''));
    const showChangePassword =
        isWeblinkOpened && (isPublicPasswordEnabled || Boolean(pin)) && !isAlbum && isOwn && item?.isFolder && !isTutoria;
    // tempexp_17605-end

    const tooltipPasswordShown = useSelector((state) => UserStorageSelectors.get(state, PUBLIC_PASSWORD_TOOLTIP_ID));
    const showPublicPasswordTip =
        showChangePassword &&
        (!lastShownTooltip || lastShownTooltip === EPromoTooltip.PublicPassword) &&
        !isPhone &&
        !isPaidUser &&
        (withPromoTooltip === EPromoTooltip.PublicPassword || (!tooltipPasswordShown && !isCorpUser));

    const tooltipDesignShown = Boolean(useSelector((state) => UserStorageSelectors.get(state, PUBLIC_DESIGN_TOOLTIP_ID)));
    const showPublicDesignTip =
        showChangeDesign &&
        (!lastShownTooltip || lastShownTooltip === EPromoTooltip.ChangeDesign) &&
        (withPromoTooltip === EPromoTooltip.ChangeDesign || (!tooltipDesignShown && !showPublicPasswordTip));

    const tooltipDisableDownloadShown = Boolean(
        useSelector((state) => UserStorageSelectors.get(state, DISABLE_PUBLIC_DOWNLOAD_TOOLTIP_ID))
    );
    const showDisableDownloadTip =
        showDisablePublicDownload &&
        (!lastShownTooltip || lastShownTooltip === EPromoTooltip.DisablePublicDownload) &&
        (withPromoTooltip === EPromoTooltip.DisablePublicDownload ||
            (!tooltipDisableDownloadShown && !showPublicDesignTip && !showPublicPasswordTip));

    if (!lastShownTooltip) {
        lastShownTooltip = showPublicPasswordTip && EPromoTooltip.PublicPassword;
        lastShownTooltip = lastShownTooltip || (showPublicDesignTip && EPromoTooltip.ChangeDesign);
        lastShownTooltip = lastShownTooltip || (showDisableDownloadTip && EPromoTooltip.DisablePublicDownload);
    }

    const onActionClick = useCallback(() => {
        sendGa('unpublish');
        if (storage === EStorageType.attaches) {
            sendGa('attaches-unpublish');
        }

        if (!item) {
            return;
        }
        unPublishHelper({ items: [item] });
        setWeblinkOpened(false);
    }, [storage, item]);

    const onChangeWeblink = useCallback(() => {
        if (!item) {
            return;
        }

        sendSharingContentAnalytics({ action: 'switch', label: 'click' });

        if (isWeblinkOpened) {
            renderUnpublishWeblinkDialog({ onActionClick, item, isAlbum });
        } else {
            sendGa('publish');

            dispatch(publishRequest({ item }));
            setWeblinkOpened(true);
        }
    }, [dispatch, isAlbum, isWeblinkOpened, item, onActionClick, sendSharingContentAnalytics]);

    const onDropdownClick = useCallback(() => {
        sendSharingContentAnalytics({ action: 'auto-delete', label: 'click' });
    }, [sendSharingContentAnalytics]);

    useEffect(() => {
        if (!publicUrl) {
            setWeblinkOpened(false);
        }
    }, [publicUrl]);

    useEffect(() => {
        if (item?.weblink) {
            dispatch(getPublicSharingInfo({ public_id: item.weblink }));
            // todo: заменить на новую ручку.
            dispatch(loadPaidInfoRequest());
        }

        if (showChangePassword && !isPaidUser) {
            dispatch(loadPaidInfoRequest());
        }
    }, []);

    return (
        <SharingNewSection
            onChange={onChangeWeblink}
            isOpened={isWeblinkOpened}
            title={isFolder ? 'По ссылке' : 'Открыть доступ к файлу по ссылке'}
            name="weblink"
            noSwitch={noEdit}
            isPhone={isPhone}
            titleBig={isFolder}
        >
            <>
                <div className={classNames(styles.root, { [styles.root_phone]: isPhone, [styles.root_tutoria]: isWindowSmall })}>
                    <div className={styles.copyWeblink}>
                        <CopyWeblink
                            type="new"
                            publicUrl={publicUrl}
                            autoCopy={!isPhone}
                            isPhone={isPhone}
                            // tempexp_17341-next-line
                            tooltipEnabledByExp={abSharingLinkFakedoor === 'b' && !isThemedSharedModalItem}
                            folderName={folderName}
                        />
                    </div>

                    {(showDomesticSettings || showAccessRights || showExpires || showAutoDeleteSettings) && (
                        <div className={styles.headline}>Общий доступ</div>
                    )}
                    {/* tempexp_17605-next-line */}
                    {showChangePassword && (
                        <PublicPassword
                            forcePromo={lastShownTooltip === EPromoTooltip.PublicPassword && Boolean(showPublicPasswordTip)}
                            item={item}
                        />
                    )}
                    {/* tempexp_17341-next-line */}
                    {showChangeDesign && (
                        <ChangeDesign item={item} forcePromo={lastShownTooltip === EPromoTooltip.ChangeDesign && showPublicDesignTip} />
                    )}
                    <DisablePublicDownload
                        show={showDisablePublicDownload}
                        item={item}
                        forcePromo={lastShownTooltip === EPromoTooltip.DisablePublicDownload && showDisableDownloadTip}
                        disabled={!isPaidUser}
                        disableDownload={disableDownload}
                    />
                    {seoOption}
                    {showDomesticSettings && renderDomestic()}
                    {showAccessRights && renderAccessRights()}
                    {showExpires && renderExpires()}
                    {showAutoDeleteSettings && (
                        <AutoDelete
                            item={item}
                            disabled={!isAutodeleteFeatureEnabled && isAutodeletePromoFeatureEnabled && isWeblinkOpened}
                            gaSuffix={gaSuffix}
                            onDropdownClick={onDropdownClick}
                            disabledBySeo={item?.weblinkSeoEnabled && seoEnable}
                        />
                    )}
                </div>
                {showWeblinkListFileSharing && !isAlbum && !noEdit && showListSharing && (
                    <div data-qa-id="public-personal-access">
                        <div className={styles.headline} data-qa-id="public-personal-access-header">
                            Персональный доступ
                        </div>
                        <SharingNewWeblinkList item={item} from={from} isPhone={isPhone} isReadOnly={isReadOnly} />
                    </div>
                )}
            </>
        </SharingNewSection>
    );
});
