import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_MOBILE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

const value: 'a' | 'b' | 'c' = getFeature('tariff-card-personal');

if (!IS_MOBILE_BROWSER) {
    registerExperiment('tariff-personal', value, [
        AnalyticEventNames.QUOTA_LANDING_SHOWN,
        AnalyticEventNames.TARIFF_CLICK,
        AnalyticEventNames.FAST_CHECKOUT_SHOWN,
        AnalyticEventNames.TARIFF_BUY,
    ]);
}

export const tariffCardPersonal = !IS_MOBILE_BROWSER ? value : undefined;
