import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import cyberMonday2025ModalImage from 'img/aside-promo-modal/cyber-monday-2025-modal.png';
import { IS_PUBLIC_DOCUMENT_PROMO_PROHIBITED } from 'reactApp/appHelpers/configHelpers';
import { promotionEndDate } from 'reactApp/appHelpers/featuresHelpers';
import { cyberMonday2025 } from 'reactApp/appHelpers/featuresHelpers/features/cyberMonday2025';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getQuotaPromoUrl } from 'reactApp/sections/QuotaLanding/QuotaLanding.data';
import { store as reduxStore } from 'reactApp/store';
import { openAsidePromoModal } from 'reactApp/ui/AsidePromoModal/AsidePromoModal.helpers';
import opener from 'reactApp/utils/opener';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { put } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';

const CYBER_MONDAY_2025_MODAL_ID = 'cyber-monday-2025-modal';
const PERIOD_IN_DAYS = 3;

export function* initCyberMonday2025Modal() {
    const storage = yield* select(getCurrentStorage);

    const periodPassed =
        storeHelper.getValue(CYBER_MONDAY_2025_MODAL_ID) && storeHelper.isPassed(CYBER_MONDAY_2025_MODAL_ID, { daysCount: PERIOD_IN_DAYS });

    if (storeHelper.getValue(CYBER_MONDAY_2025_MODAL_ID) && !periodPassed) {
        return;
    }

    const isCorrectUrl = storage === EStorageType.home || storage === EStorageType.public || storage === EStorageType.gallery;

    if (!isCorrectUrl || !cyberMonday2025 || IS_PUBLIC_DOCUMENT_PROMO_PROHIBITED) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.cyberMonday2025,
            promoId: CYBER_MONDAY_2025_MODAL_ID,
            onClick() {
                sendPaymentGa({
                    action: 'click',
                    eventCategory: ECategoryGa.balloon,
                    name: 'cyber_monday_mnz',
                    place: storage,
                });

                opener(
                    getQuotaPromoUrl({
                        query: 'utm_source=cloud&utm_medium=web_union_quota_landing&utm_campaign=balloon_cyber_monday',
                    }),
                    true
                );
                reduxStore.dispatch(removePromo(EPromoType.cyberMonday2025));
            },
            onShow: () => {
                storeHelper.markAsShown(CYBER_MONDAY_2025_MODAL_ID);
                sendPaymentGa({
                    action: 'show',
                    eventCategory: ECategoryGa.balloon,
                    name: 'cyber_monday_mnz',
                    place: storage,
                });
                reduxStore.dispatch(promoShown(EPromoType.cyberMonday2025));
            },
            onClose: () => {
                reduxStore.dispatch(removePromo(EPromoType.cyberMonday2025));
            },
        })
    );
}

export function* showCyberMonday2025Modal() {
    const promo = yield* select(PromoSelectors.getPromo(EPromoType.cyberMonday2025));

    if (promo) {
        const { onShow, onClick, onClose } = promo;

        yield openAsidePromoModal({
            title: 'Работать и\u00A0учиться лучше с Mail\u00A0Space',
            text: 'Скидка до\u00A085% на\u00A0гигабайты в\u00A0Облаке и\u00A0отключение рекламы в\u00A0Почте',
            buttonText: 'Забрать скидку',
            imageUrl: cyberMonday2025ModalImage,
            showAboveViewer: true,
            alwaysDark: true,
            imageText: `Сроки акции с 27.01.2025 по ${format(
                parseISO(promotionEndDate),
                'dd.MM.yyyy'
            )}. Подробные условия: https://vk.cc/cHnVUa`,
            onShow,
            onClick,
            onClose,
            cyberMonday2025Modal: true,
        });
    }
}
