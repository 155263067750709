import { getFeature, isFeature } from 'Cloud/Application/FeaturesEs6';

/** CLOUDWEB-17902 */

// Фича для включения
export const enableFakeSilentVkAuth: boolean = isFeature('enable-cloud-fake-silent-vk-auth') || false;

// Адреса для настройки @vkontakte/superappkit
export const vkLoginDomain: string = getFeature('vk-login-domain') || 'login.vk.com';
export const vkOauthDomain: string = getFeature('vk-oauth-domain') || 'oauth.vk.com';
export const vkConnectDomain: string = getFeature('vk-connect-domain') || 'connect.vk.com';

// App id
export const vKAppID: number = getFeature('vk-app-id') || 51414545;
