import { getFeature, isFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_IOS_MOBILE, IS_MOBILE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

type MidasFeatureType = 'a' | 'b' | undefined;

/** CLOUDWEB-16083: Фича включения оплаты через мидас в карточках тарифов на /promo/quota */
export const dmrMidasUrlWebEnabled: MidasFeatureType = getFeature('dmr-midas-web');
/** CLOUDWEB-17111: Фича включения оплаты через мидас на таче через мидас в карточках тарифов на /promo/quota */
export const dmrMidasUrlTouchIosEnabled: MidasFeatureType = getFeature('dmr-midas-ios');
export const dmrMidasUrlToucAndroidhEnabled: MidasFeatureType = getFeature('dmr-midas-and');
export const dmrMidasTouchEnabled: MidasFeatureType = IS_IOS_MOBILE ? dmrMidasUrlTouchIosEnabled : dmrMidasUrlToucAndroidhEnabled;

/** CLOUDWEB-18349: Фича включения оплаты через мидас в TariffBuy на вебе */
export const dmrMidasBuyWeb: boolean = isFeature('dmr-midas-buy');
/** CLOUDWEB-18349: Фича включения оплаты через мидас в TariffBuy на тачу */
export const dmrMidasBuyTouch: boolean = isFeature('dmr-midas-buy-touch');
export const dmrMidasBuyEnabled: boolean = IS_MOBILE_BROWSER ? dmrMidasBuyTouch : dmrMidasBuyWeb;

if (dmrMidasBuyEnabled) {
    registerExperiment('midas-buy', dmrMidasBuyEnabled, [
        AnalyticEventNames.TARIFF_CLICK,
        AnalyticEventNames.FAST_CHECKOUT_SHOWN,
        AnalyticEventNames.TARIFF_BUY,
    ]);
}
