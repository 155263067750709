import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_IOS_MOBILE, IS_MOBILE_BROWSER, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

type FeatureType = 'a' | 'b' | undefined;

/** CLOUDWEB-17323: По кнопке в годовом тарифе (в мес) поднимать окно оплаты с заголовком, где цена годового указана в месяц */
const yearPriceInMonthTouchEnabled: FeatureType = IS_IOS_MOBILE ? getFeature('year-price-month-ios') : getFeature('year-price-month-and');
const yearPriceInMonthWebTouchEnabled: FeatureType =
    IS_MOBILE_BROWSER && !IS_WEBVIEW ? getFeature('year-price-month-web-touch') : undefined;
export const yearPriceInMonthEnabled: FeatureType = IS_WEBVIEW ? yearPriceInMonthTouchEnabled : yearPriceInMonthWebTouchEnabled;

// tempexp_17323-start
registerExperiment(
    'y-price-m',
    yearPriceInMonthEnabled,
    [
        AnalyticEventNames.TARIFFS_SHOWN,
        AnalyticEventNames.TARIFF_CLICK,
        AnalyticEventNames.FAST_CHECKOUT_SHOWN,
        AnalyticEventNames.TARIFF_BUY,
    ],
    {
        sendXrayArgs: {
            TARIFF_BUY: ({ enabledMidasPayment }) => ({
                cancel: !enabledMidasPayment,
            }),
            FAST_CHECKOUT_SHOWN: ({ enabledMidasPayment }) => ({
                cancel: !enabledMidasPayment,
            }),
        },
    }
);
// tempexp_17323-end
