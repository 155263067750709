import { Headline } from '@vkontakte/vkui';
import { requestPayment } from 'Cloud/Application/Payment';
import classNames from 'clsx';
import React, { type FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_ACTION_USER } from 'reactApp/appHelpers/configHelpers';
import { buttonHideActionIcon } from 'reactApp/appHelpers/featuresHelpers/features/buttonHideActionIcon';
import { getFeatureMarketingPromoButton } from 'reactApp/modules/features/features.selectors';
import { UserStorageActions, UserStorageSelectors } from 'reactApp/modules/user/userStorage';
import type { RootState } from 'reactApp/store';
import { ESplashScreenAction, useSplashScreenAnalytics } from 'reactApp/ui/Mobile/SplashScreen/SplashScreen.analytics';

import styles from './MarketingPromoButton.css';

const PROMO_ID = 'attaches-marketing-promo-bf23';

interface Props {
    onVisibilityChanged: (isVisible: boolean) => void;
}

export const MarketingPromoButton: FC<Props> = ({ onVisibilityChanged }) => {
    const dispatch = useDispatch();

    const featureData = useSelector(getFeatureMarketingPromoButton);
    const showCount = useSelector((state: RootState) => UserStorageSelectors.get(state, PROMO_ID));
    const productId = featureData?.productId;

    const maxShowCount = featureData?.showCount || 5;

    const [visible, setVisible] = useState(() => IS_ACTION_USER && Boolean(featureData) && (!showCount || showCount < maxShowCount));
    useEffect(() => {
        onVisibilityChanged(visible);
    }, [onVisibilityChanged, visible]);

    const sendSplashScreenAnalytics = useSplashScreenAnalytics({ name: 'attaches-marketing-btn', button_name: featureData?.text || '' });

    useEffect(() => {
        if (visible) {
            sendSplashScreenAnalytics(ESplashScreenAction.show);
            dispatch(UserStorageActions.set(PROMO_ID, showCount ? showCount + 1 : 1));
        }
    }, []);

    const onClick = useCallback(() => {
        sendSplashScreenAnalytics(ESplashScreenAction.click);
        dispatch(UserStorageActions.set(PROMO_ID, maxShowCount));
        if (productId) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            requestPayment({
                tariff: productId,
                forceFastCheckout: true,
                onClose: () => setVisible(false),
                paySource: 'viewer_btn_+128gb',
            });
        }
    }, [dispatch, productId, maxShowCount]);

    return visible ? (
        <div
            className={classNames(styles.button, {
                // tempexp_18363-next-line
                [styles.hideIcon]: buttonHideActionIcon,
            })}
            onClick={onClick}
        >
            <Headline level="2" weight="1">
                {featureData?.text}
            </Headline>
        </div>
    ) : null;
};
