import {
    defaultPayMethod,
    defaultPayMethodTouch,
    isDmrReactUrlEnabled,
    isDmrReactUrlTouchEnabled,
} from 'reactApp/appHelpers/featuresHelpers';
import { dicountInOutflowValue } from 'reactApp/appHelpers/featuresHelpers/features/discountInOutflow';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';

import { BuyNotifications } from './buySubscription.types';

export const getABReactPaymentLink = (url: string, isMobile = false) => {
    const isNewUrlEnabled = (isMobile && isDmrReactUrlTouchEnabled) || (!isMobile && isDmrReactUrlEnabled);

    if (!isNewUrlEnabled) {
        return url;
    }

    return url?.replace(/\/\/pw\.money\.mail\.ru\/pw\/[\d-]+\//, '//checkout.vkpay.io/');
};

export const getPayMethod = (isMobile) => {
    const { pay_method: payMethod } = getQueryParams();

    if (payMethod) {
        return payMethod;
    }

    if (defaultPayMethod && !isMobile) {
        return defaultPayMethod;
    }

    if (defaultPayMethodTouch && isMobile) {
        return defaultPayMethodTouch;
    }
};

// tempexp_17581-start
export const showSetDiscountOutflowSuccesSnackbar = () =>
    showSnackbarAction({
        text: `Постоянная скидка ${dicountInOutflowValue}% активирована`,
        type: SnackbarTypes.success,
        id: BuyNotifications.setDiscountInOutflowSuccess,
        closable: true,
    });

export const showSetDiscountOutflowFailureSnackbar = () =>
    showSnackbarAction({
        text: `Не удалось активировать скидку ${dicountInOutflowValue}%`,
        type: SnackbarTypes.failure,
        id: BuyNotifications.setDiscountInOutflowFailure,
        closable: true,
    });
// tempexp_17581-end
