import type { TooltipModelProps } from '@mail/3promo/models/tooltip';
import type { ReactRendererComponent } from '@mail/3promo/render/react';
import { xray } from 'lib/xray';
import React from 'react';
import { setOpenWorkWithPages } from 'reactApp/modules/pdfEditor/pdfEditor.module';
import { store as reduxStore } from 'reactApp/store';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipAppearance, ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';

import styles from './PdfWorkWithPagesPromo.css';

export const PdfWorkWithPagesPromo: ReactRendererComponent<TooltipModelProps<unknown>> = ({ getTarget, close, complete }) => {
    const pdfWorkWithPagesButton = getTarget();

    return (
        <FloatingTooltip
            target={pdfWorkWithPagesButton}
            title="Работайте со страницами"
            text={
                <span data-qa-id="tip-text" className={styles.text}>
                    Измените расположение, добавьте
                    <br />
                    или удалите лишние страницы
                </span>
            }
            buttonText="Попробовать"
            placement={ETooltipPlacement.bottom}
            closeOnOutsideClick={false}
            qaId="goto-pdf-work-with-pages"
            appearance={ETooltipAppearance.contrast}
            className={styles.tip}
            onClick={() => {
                reduxStore.dispatch(setOpenWorkWithPages({ openWorkWithPages: true }));
                xray.send('pdf_work_with_pages_promo_button');
                complete();
            }}
            onClose={close}
            isAlternativeButton
            onTooltipClick={(event) => event.stopPropagation()}
        />
    );
};
