import { Icon20CopyOutline, Icon20DeleteOutline, Icon20WriteOutline } from '@vkontakte/icons';
import React, { type ReactElement } from 'react';

export enum ChangePublicPasswordMenuItemId {
    change = 'change',
    copy = 'copy',
    delete = 'delete',
}

export interface ChangePublicPasswordMenuItem {
    id: ChangePublicPasswordMenuItemId;
    icon: ReactElement;
    text: string;
}

export const CHANGE_PUBLIC_PASSWORD_MENU_LIST: ChangePublicPasswordMenuItem[] = [
    {
        id: ChangePublicPasswordMenuItemId.change,
        icon: <Icon20WriteOutline />,
        text: 'Изменить',
    },
    {
        id: ChangePublicPasswordMenuItemId.copy,
        icon: <Icon20CopyOutline />,
        text: 'Скопировать',
    },
    {
        id: ChangePublicPasswordMenuItemId.delete,
        icon: <Icon20DeleteOutline />,
        text: 'Удалить',
    },
];
