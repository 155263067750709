import { createAction, createReducer } from '@reduxjs/toolkit';

export enum WhitelineTheme {
    default = 'default',
    dark = 'dark',
    system = 'system',
    light = 'light',
}

const initialState: { currentTheme: string } = {
    currentTheme: '',
};

export const storeCurrentTheme = createAction<'' | 'dark' | 'light'>('theme/storeCurrentTheme');
export const changeTheme = createAction<WhitelineTheme>('theme/changeTheme');

export const themeReducer = createReducer(initialState, {
    [storeCurrentTheme.type]: (state, action: ReturnType<typeof storeCurrentTheme>) => {
        state.currentTheme = action.payload;
    },
});
