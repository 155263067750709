/* eslint-disable max-lines */
/* eslint-disable complexity */
import { social } from 'Cloud/Application/Social';
import { confirmActionWithSuspiciousItems } from 'Cloud/Application/Suspicious';
import sha1 from 'js-sha1';
import { logger } from 'lib/logger';
import { clone as cloneRamda, path } from 'ramda';
import { downloadItem, downloadItemsZip } from 'reactApp/appHelpers/appDownload';
import { openShareDialog } from 'reactApp/appHelpers/appHelpers';
import {
    IS_BIZ_USER,
    IS_DOCUMENTS_DOMAIN,
    IS_MOBILE_BROWSER,
    IS_MOBILE_OR_TABLET_BROWSER,
    IS_ONPREMISE,
    IS_WEBVIEW,
} from 'reactApp/appHelpers/configHelpers';
import { isActivitiesFeatureEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { sendSuperAppXray, superAppSubmetrics, superAppTakeItemAndSendXray } from 'reactApp/appHelpers/sendSuperAppAnalytics';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { renderRenameDialog } from 'reactApp/components/BaseConfirmDialog/BaseInputDialog.helpers';
import { renderEmptyTrashBinDialog } from 'reactApp/components/EmptyTrashBin/EmptyTrashBin.helpers';
import { closeSharingNew } from 'reactApp/components/SharingNewBiz/SharingNew.helpers';
import { renderUnpublishWeblinkDialog } from 'reactApp/components/SharingNewBiz/Weblink/SharingNewWeblink.helpers';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { toggleActionPanel } from 'reactApp/modules/actionpanel/actionpanel.module';
import {
    addItemsFromCloudToAlbumRequest,
    createAlbumRequest,
    createAndPublishAlbumRequest,
    selectAlbumToAddItemsRequest,
} from 'reactApp/modules/albums/albums.actions';
import { isCreatePublicAlbumsEnabled } from 'reactApp/modules/albums/albums.selector';
import { isAlbum } from 'reactApp/modules/albums/albums.types';
import { setIsFromAttaches } from 'reactApp/modules/attaches/attaches.actions';
import {
    downloadAttachesByIds,
    downloadAttachItem,
    forwardItems,
    getSendViaMailruLink,
    sendAttachesGa,
} from 'reactApp/modules/attaches/attaches.helpers';
import { AttachesSelectors } from 'reactApp/modules/attaches/attaches.selectors';
import { type AttachesItem, EAttachTypes } from 'reactApp/modules/attaches/attaches.types';
import { getBizFilteredItems } from 'reactApp/modules/bizCategories/bizCategories.helpers';
import { getBizCurrentCategory } from 'reactApp/modules/bizCategories/bizCategories.selectors';
import { dispatchNewSearchRadar } from 'reactApp/modules/dwh/dwh.module';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getFacesList, getFilesWithFaceIdxs, getFileWithFaceById, getSelectedFaceId } from 'reactApp/modules/faces/faces.selectors';
import { getIsViewerFileVerifiedByKaspersky } from 'reactApp/modules/file/utils';
import { deleteAllUsers } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.actions';
import { expandRequest } from 'reactApp/modules/integration/integration.actions';
import {
    addToFavorites,
    cloneRequest,
    createNewItem,
    downloadMobileItem as downloadMobileItemAction,
    mountRequest,
    removeFromFavorites,
    removeRequest,
} from 'reactApp/modules/modifying/modifying.actions';
import { getGoToAndScrollUrl } from 'reactApp/modules/modifying/modifying.helpers';
import type { PublishItem } from 'reactApp/modules/modifying/modifying.types';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { sendPublicAnalyticsAction } from 'reactApp/modules/public/public.actions';
import { getCurrentPublicItem, getPublicRootWeblink, isOwnPublic } from 'reactApp/modules/public/public.selectors';
import type { PublicFile, PublicFolder } from 'reactApp/modules/public/public.types';
import { isPublicAlbumTemporaryUrl } from 'reactApp/modules/public/publicAlbum.helper';
import { reDownloadController, ReDownloadEntry } from 'reactApp/modules/requiredAuthorization/reDownloadController';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { resetSelect, scrollToHomeItemAction, toggleAll } from 'reactApp/modules/selections/selections.actions';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { settingsController } from 'reactApp/modules/settings/settings.controller';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { snackbarController } from 'reactApp/modules/snackbar/snackbar.controller';
import { type SnackbarItem, SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { setSort } from 'reactApp/modules/sort/sort.module';
import type { ESortOder, ESortTypes } from 'reactApp/modules/sort/sort.types';
import { getLoadMoreLimit, getStorage, isIntegrationStorage } from 'reactApp/modules/storage/storage.helpers';
import {
    filterIdsByAuthors,
    getCurrentFolder as getCurrentFolderAction,
    getHomeStorageItemIds,
    getItemById,
    getStorageItemById,
    getStorageSelectedItems,
} from 'reactApp/modules/storage/storage.selectors';
import { type CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import { trashbinRestoreAllRequest, trashbinRestoreRequest } from 'reactApp/modules/trashbin/trashbin.module';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { sendQuotaCleanerGa } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { store as reduxStore } from 'reactApp/store';
import type { Sort } from 'reactApp/types/Tree';
import { FilesFoldersLogViewer } from 'reactApp/ui/FilesFoldersLogViewer/FilesFolderLogViewer';
import { renderAuthModalDialog } from 'reactApp/ui/Mobile/AuthModal/AuthModal.helpers';
import { o2AuthedSourceLoader } from 'reactApp/ui/ReactViewer/O2AuthedSourceLoader';
import { renderCopyDialog, renderCreateShareDialog, renderMoveDialog } from 'reactApp/ui/SelectFolderDialog/SelectFolderDialog.toolkit';
import { ToolbarDownloadFrom } from 'reactApp/ui/Toolbar/Toolbar.types';
import { showAuth } from 'reactApp/utils/auth';
import { copy as copyToClipboard } from 'reactApp/utils/copyToClipboard';
import { changeDocumentDomainToCloud } from 'reactApp/utils/documentsDomain';
import { sendGa, sendXray } from 'reactApp/utils/ga';
import { sendKasperskiSnackAnalitics } from 'reactApp/utils/kasperskiSnackGa';
import { openComplain } from 'reactApp/utils/openComplain';
import opener from 'reactApp/utils/opener';
import { openLink } from 'reactApp/utils/openHelpers';
import { ECategoryGa, EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { getEncodePath, getPublicUrl } from 'reactApp/utils/urlHelper';
import { EQueryParams } from 'server/helpers/getRequestParams';

import { emitAnalyticEvent } from './experimentAnalytic';
import { AnalyticEventNames } from './experimentAnalytic/eventNames';
import { seriallyClearBinFeature } from './featuresHelpers/features/seriallyClearBin';
import { publishHelper, unPublishHelper } from './publishHelper';

const getSelectedItems = (state) => {
    const selectedIdxs = SelectionsSelectors.getSelectedIdxs(state);
    const selectedFace = getSelectedFaceId(state);
    if (selectedFace && !selectedIdxs.length) {
        return getFilesWithFaceIdxs(state, selectedFace)
            .map((id) => getFileWithFaceById(state, id))
            .filter((item): item is PublicFile => !!item);
    }
    const selectedStorage = SelectionsSelectors.getStorageSelected(state);
    return selectedIdxs
        .map((id) => getStorageItemById(state, selectedStorage || getCurrentStorage(state), id))
        .filter((item): item is CloudItem => !!item);
};

const getCurrentFolder = (state) => {
    const folder = getCurrentFolderAction(state);

    return folder?.id && folder?.id !== ROOT_FOLDER_ID ? folder : null;
};

export const getSelectedItemsOrCurrentFolder = (id?: string, storage?: EStorageType) => {
    const state = reduxStore.getState();
    const currentStorage = getCurrentStorage(state);
    let selectedItems = getSelectedItems(state);
    const item = id && getStorageItemById(state, storage || currentStorage, id);

    if (item) {
        return [item];
    }

    if (!Array.isArray(selectedItems) || selectedItems.length === 0) {
        const folder = getCurrentFolder(state);
        if (folder) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            selectedItems = [folder];
        } else if (currentStorage === EStorageType.public) {
            const item = getCurrentPublicItem(state);
            if (item) {
                selectedItems = [item];
            }
        }
    }

    return selectedItems;
};

const create = (
    type: string,
    from: string,
    showShareOnlyButton?: boolean,
    gaPrefix = '',
    inHomeRoot = false,
    createdInToolbar?: boolean
): void => {
    reduxStore.dispatch(
        createNewItem({
            type,
            from,
            showShareOnlyButton,
            gaPrefix,
            inHomeRoot,
            createdInToolbar,
        })
    );
};

const view = (type: string) => {
    const state = reduxStore.getState();
    const currentStorage = getCurrentStorage(state);

    sendPaymentGa({
        eventCategory: ECategoryGa.toolbar,
        action: 'change_view',
        source: currentStorage,
    });

    settingsController.setViewMode(type);
};

const sort = (name: string) => {
    const sortName = name.split('-');
    const sort: Sort = { type: sortName[0] as ESortTypes, order: sortName[1] as ESortOder };
    const state = reduxStore.getState();
    const folder = getCurrentFolderAction(state);
    const storage = getCurrentStorage(state);
    const id = folder?.id || ROOT_FOLDER_ID;
    const sortId = IS_BIZ_USER && storage === EStorageType.trashbin ? `${storage}${id}` : id;

    reduxStore.dispatch(setSort({ sort, id: sortId }));
};

const rename = (id?: string): void => {
    const state = reduxStore.getState();
    const currentStorage = getCurrentStorage(state);
    const selectedItems = getSelectedItemsOrCurrentFolder(id);

    if (selectedItems?.length !== 1 || !selectedItems?.[0]) {
        return;
    }

    const onSuccess = () => {
        if (currentStorage === EStorageType.search) {
            const item = selectedItems[0];
            const dwhData = { eventCategory: ECategoryGa.toolbar_search, action: 'rename' };
            const items = [
                {
                    file_name: 'nameWithoutExt' in item && item.nameWithoutExt,
                    type: item.kind,
                    pos: 'pos' in item && item.pos,
                    file_id: item.id,
                    placement: 'srchSrc' in item && item.srchSrc,
                },
            ];
            reduxStore.dispatch(
                dispatchNewSearchRadar({
                    dwhData,
                    items,
                })
            );
        }
    };

    renderRenameDialog(selectedItems[0], onSuccess);
};

const toggleFavorites = (): void => {
    const state = reduxStore.getState();
    const selectedItems = getSelectedItems(state);

    if (!selectedItems?.length) {
        return;
    }

    const isAllInFav = selectedItems.every((item) => path(['isInFavorites'], item));

    if (isAllInFav) {
        reduxStore.dispatch(removeFromFavorites({ items: selectedItems }));
        return;
    }

    reduxStore.dispatch(addToFavorites({ items: selectedItems, from: 'toolbar' }));
};

const copy = (id?: string, sendAnalytics?: (data?: any) => void): void => {
    const state = reduxStore.getState();
    const currentStorage = getCurrentStorage(state);
    const items = getSelectedItemsOrCurrentFolder(id);

    if (!items?.length) {
        return;
    }

    const onSuccess = () => {
        sendAnalytics?.({
            action: 'copy',
            source: currentStorage,
            count_files: items.length,
            items,
        });
    };

    renderCopyDialog({ items, onSuccess });
};

const download = ({
    useDownloadUrl = false,
    id,
    storage,
    from,
}: { useDownloadUrl?: boolean; id?: string; storage?: EStorageType; from?: ToolbarDownloadFrom } = {}): void => {
    const state = reduxStore.getState();
    const currentFolder = getCurrentFolderAction(state);
    let selected = getSelectedItems(state);

    /* tempexp_15344-start */
    if (from === ToolbarDownloadFrom.reDownload) {
        const reDownloadParams = reDownloadController.getParams(ReDownloadEntry.Toolbar);

        if (reDownloadParams) {
            selected = reDownloadParams.selected;
        }
    }
    /* tempexp_15344-end */

    const currentStorage = getCurrentStorage(state);
    const item = id && getStorageItemById(state, storage || currentStorage, id);

    if (item && !selected.length) {
        selected = [item];
    }

    /* tempexp_15344-next-line */
    reDownloadController.setFutureParams(ReDownloadEntry.Toolbar, {
        useDownloadUrl,
        id,
        storage,
        selected,
    });

    const { isAttaches, isFavorites, isSearch, isHome, isPublic, isAlbums } = getStorage(currentStorage);

    if (!currentFolder && !selected?.length) {
        return;
    }

    sendPaymentGa({
        eventCategory: ECategoryGa.toolbar,
        action: 'download_content',
        source: currentStorage,
        count_files: selected.length || 1,
    });

    if (isSearch && from === ToolbarDownloadFrom.toolbar) {
        const dwhData = {
            eventCategory: ECategoryGa.toolbar_search,
            action: 'download-files',
            count_files: selected.length || 1,
        };
        const items = selected.map((item) => ({
            file_name: 'nameWithoutExt' in item && item.nameWithoutExt,
            file_id: item.id,
            pos: 'pos' in item && item.pos,
            type: item.kind,
        }));

        reduxStore.dispatch(dispatchNewSearchRadar({ dwhData, items }));
    }

    if (isAttaches && selected?.length) {
        sendAttachesGa('download-from-toolbar', selected.length.toString());

        selected.forEach((item) => sendAttachesGa('download', 'ext' in item ? item.ext : ''));

        if (selected.length === 1) {
            const attachType: string | undefined = path(['attachType'], selected[0]);
            const isCloudAttachType = attachType === EAttachTypes.cloud || attachType === EAttachTypes.cloudStock;

            if (isCloudAttachType) {
                downloadItem({ itemOrId: selected[0], useDownloadUrl, storage: storage || currentStorage });
            } else {
                downloadAttachItem(selected[0] as AttachesItem).then(() => {
                    if (getIsViewerFileVerifiedByKaspersky(state)) {
                        /**
                         * Показываем снэк только для пабликов и для аттачей(CLOUDWEB-14706)
                         * и самым последним этапом скачивания, что бы не мешался другим модалкам на тача
                         * (Пришлось его продублировать тут, т.к. аттачи на таче скачиваются иначе, чем всё остальное)
                         */
                        snackbarController.showSnackbar({
                            id: 'protected-download',
                            closable: true,
                            title: 'Скачивание началось',
                            text: IS_MOBILE_BROWSER
                                ? 'Файл проверен и безопасен для вашего смартфона'
                                : 'Файл проверен антивирусом и безопасен для вашего компьютера',
                            type: SnackbarTypes.protect,
                            onShow: () => {
                                sendKasperskiSnackAnalitics({ action: 'view' });
                            },
                            onClose: () => {
                                sendKasperskiSnackAnalitics({ action: 'close' });
                            },
                        });
                    }
                });
            }
        } else {
            sendAttachesGa('download-multiple');
            confirmActionWithSuspiciousItems(cloneRamda(selected), 'download').then(function () {
                downloadAttachesByIds(selected.map((item) => item.id));
            });
        }

        return;
    }

    if ((isFavorites || isSearch) && !selected.length && currentFolder && 'list' in currentFolder) {
        const items = Object.values(currentFolder.list);
        const bizCurrentCategory = getBizCurrentCategory(state);
        const bizFilteredItems = getBizFilteredItems(items, bizCurrentCategory);
        downloadItemsZip({
            items: IS_BIZ_USER ? bizFilteredItems : items,
            name: isFavorites ? 'favorites' : 'archive',
        });
        return;
    }

    if (isIntegrationStorage(currentStorage) && selected.length === 1) {
        const [cloudItem] = selected;

        if (cloudItem.isFolder) {
            downloadItemsZip({ items: selected, name: cloudItem.name });
            return;
        }

        if (!cloudItem.url?.get) {
            logger.error('[Integration::download] No download url');
            return;
        }

        o2AuthedSourceLoader
            .saveFile({
                ...cloudItem,
                url: {
                    get: cloudItem.url.get,
                },
            })
            .catch((error) => {
                logger.error('[Integration::download]', error);
            });

        return;
    }

    if (selected.length === 1) {
        if (selected[0].isFolder) {
            downloadItemsZip({ items: selected, name: selected[0].name });
        } else {
            downloadItem({ itemOrId: selected[0], useDownloadUrl, storage: currentStorage });
        }

        return;
    }

    const name = (isHome || isPublic || isAlbums) && currentFolder && 'name' in currentFolder ? currentFolder?.name : 'archive';
    downloadItemsZip({ items: selected.length ? selected : [currentFolder], name });
};

const viewHistory = (contextMenu?: boolean): void => {
    const state = reduxStore.getState();
    const selectedItem = getSelectedItems(state)[0];
    const currentStorage = getCurrentStorage(state);
    if (currentStorage === EStorageType.search) {
        const dwhData = {
            eventCategory: contextMenu ? ECategoryGa.context_search : ECategoryGa.toolbar_search,
            action: 'open-history',
        };

        reduxStore.dispatch(
            dispatchNewSearchRadar({
                dwhData,
                items: [
                    {
                        file_name: 'nameWithoutExt' in selectedItem && selectedItem.nameWithoutExt,
                        file_id: selectedItem.id,
                        pos: 'pos' in selectedItem && selectedItem.pos,
                        type: selectedItem.kind,
                    },
                ],
            })
        );
    }

    if (isActivitiesFeatureEnabled) {
        reduxStore.dispatch(toggleActionPanel({ open: true, component: FilesFoldersLogViewer }));
    } else {
        openPopupHelper({ popupName: popupNames.FILE_HISTORY, data: { itemId: selectedItem?.id } });
    }
};

const move = (id?: string, sendAnalytics?: (data?: any) => void): void => {
    const state = reduxStore.getState();
    const currentStorage = getCurrentStorage(state);
    const items = getSelectedItemsOrCurrentFolder(id);

    if (!items.length) {
        return;
    }

    const onSuccess = () => {
        sendAnalytics?.({
            action: 'move_to_folder',
            source: currentStorage,
            count_files: items.length,
            items,
        });
    };

    renderMoveDialog({ items, onSuccess });
};

const remove = (id?: string, sendAnalytics?: (data?: any) => void): void => {
    const state = reduxStore.getState();
    const currentStorage = getCurrentStorage(state);
    const items = getSelectedItemsOrCurrentFolder();
    const item = id && getItemById(state, id);

    const onSuccess = () => {
        sendAnalytics?.({
            action: 'delete',
            source: currentStorage,
            count_files: items.length,
            items,
        });
    };

    reduxStore.dispatch(removeRequest({ items: item ? [item] : items, withRemoveDialog: true, onSuccess }));
};

const selectAll = (ownStorage?: EStorageType): void => {
    const state = reduxStore.getState();
    const storage = ownStorage || getCurrentStorage(state);

    const ids = getHomeStorageItemIds(state, storage);
    const allIdxs = filterIdsByAuthors(state, ids, storage);
    const allSelectedIdx = getStorageSelectedItems(state, storage);
    const isDeselectAll = allIdxs?.length && allIdxs?.length === allSelectedIdx?.length;

    reduxStore.dispatch(toggleAll({ allIdxs, storage }));

    if (storage === EStorageType.quotaCleaner) {
        const prefix = isDeselectAll ? 'all-files-select-cancel' : 'all-files-select';
        sendQuotaCleanerGa({ action: prefix, dwh: { count_select_files: allIdxs?.length } });
    }

    if (!isDeselectAll) {
        sendPaymentGa({
            eventCategory: ECategoryGa.toolbar,
            action: 'select_all',
            source: storage,
        });
    }
};

const share = (id?: string): void => {
    const state = reduxStore.getState();
    const selectedItems = getSelectedItemsOrCurrentFolder();
    const item = (id && getItemById(state, id)) || (selectedItems.length === 1 && selectedItems[0]);

    if ((item && item.id === '/') || !selectedItems.length) {
        renderCreateShareDialog({
            onSuccess: (item) => openShareDialog({ item }),
        });
        return;
    }

    emitAnalyticEvent(AnalyticEventNames.BREADCRUMBS_PUBLISH_CLICK);
    sendXray('breadcrumbs_publish-click');
    openShareDialog({ item });
};

const publish = (publishFrom = '', id?: string, storage?: EStorageType): void => {
    const state = reduxStore.getState();
    const currentStorage = storage || getCurrentStorage(state);
    const selectedItems = getSelectedItemsOrCurrentFolder(id, storage);
    const item = selectedItems.length === 1 ? selectedItems[0] : undefined;
    const isPublicAlbumEnabled = isCreatePublicAlbumsEnabled(state, selectedItems);

    if (currentStorage === EStorageType.search && item && publishFrom === 'toolbar') {
        const dwhData = { eventCategory: ECategoryGa.toolbar_search, action: 'created-public' };
        const items = [
            {
                file_name: 'nameWithoutExt' in item && item.nameWithoutExt,
                type: item.kind,
                pos: 'pos' in item && item.pos,
                file_id: item.id,
                public_id: ('weblink' in item && item.weblink) || '',
                placement: 'srchSrc' in item && item.srchSrc,
            },
        ];
        reduxStore.dispatch(
            dispatchNewSearchRadar({
                dwhData,
                items,
            })
        );
    }

    if (!selectedItems.length || item?.id === '/') {
        return;
    }

    if (isPublicAlbumEnabled && !selectedItems.every(isAlbum)) {
        reduxStore.dispatch(createAndPublishAlbumRequest({ items: selectedItems }));
        return;
    }

    publishHelper({ item, publishFrom, itemStorage: storage });
};

const unPublish = (): void => {
    const state = reduxStore.getState();
    const selectedItems = IS_BIZ_USER
        ? (getSelectedItems(state) as PublishItem[])
        : (getSelectedItems(state).filter((item) => path(['weblink'], item)) as PublishItem[]);

    if (!selectedItems.length) {
        return;
    }

    const onActionClick = () => {
        unPublishHelper({ items: selectedItems });
    };

    if (IS_BIZ_USER) {
        const onActionBizClick = () => {
            onActionClick();
            selectedItems
                .filter((item) => item.isFolder)
                .forEach((item) => {
                    reduxStore.dispatch(deleteAllUsers({ item }));
                });
        };
        renderUnpublishWeblinkDialog({ onActionClick: onActionBizClick });
        return;
    }

    onActionClick();
};

interface RestoreParams {
    id?: string;
    source?: string;
}

const restore = (params: RestoreParams = {}): void => {
    const { id, source } = params;
    const state = reduxStore.getState();
    const ids = id ? [id] : SelectionsSelectors.getSelectedIdxs(state);
    const storage = getCurrentStorage(state);
    const LOAD_LIMIT = getLoadMoreLimit(EStorageType.trashbin);
    const allIds = getHomeStorageItemIds(state, storage);
    const allIdxs = filterIdsByAuthors(state, allIds, storage);
    const allSelectedIdx = getStorageSelectedItems(state, storage);
    const allSelected = allIdxs?.length && allIdxs?.length === allSelectedIdx?.length;
    if (seriallyClearBinFeature && allSelected && ids?.length && (ids.length > LOAD_LIMIT || state?.trashbin?.bins?.[0]?.hasMoreToLoad)) {
        reduxStore.dispatch(trashbinRestoreAllRequest());
    } else {
        reduxStore.dispatch(trashbinRestoreRequest({ ids, from: source || 'toolbar' }));
    }
};

const restoreAll = (): void => {
    reduxStore.dispatch(trashbinRestoreAllRequest());
};

const clear = (): void => {
    renderEmptyTrashBinDialog();
};

const forward = ({ items }: { items: CloudItem[] | null } = { items: null }): void => {
    const state = reduxStore.getState();
    const storage = SelectionsSelectors.getStorageSelected(state) || getCurrentStorage(state);

    if (storage === EStorageType.attaches) {
        const ids = SelectionsSelectors.getSelectedIdxs(state);
        const items = ids.map((id) => AttachesSelectors.getAttachById(state, id));
        reduxStore.dispatch(
            dispatchNewSearchRadar({
                dwhData: { eventCategory: ECategoryGa.viewer_search, action: 'send-mail' },
                items: items.map((item) => ({
                    file_name: item.nameWithoutExt,
                    type: item.kind,
                    pos: item.pos,
                    file_id: item.id,
                    extension: item.isFolder ? 'None' : item.ext,
                })),
            })
        );

        items.forEach((item) => sendAttachesGa('forward', item.ext));

        if (items?.length > 1) {
            sendAttachesGa('forward-multiple');
        }

        forwardItems(ids);
    } else {
        if (!items) {
            items = getSelectedItems(state);
        }

        const item = items.length ? items[0] : getCurrentFolderAction(state);

        if (storage === EStorageType.search && item) {
            reduxStore.dispatch(
                dispatchNewSearchRadar({
                    dwhData: { eventCategory: ECategoryGa.viewer_search, action: 'send-mail' },
                    items: [
                        {
                            file_name: 'nameWithoutExt' in item && item.nameWithoutExt,
                            type: 'kind' in item && item.kind,
                            pos: 'pos' in item && item.pos,
                            file_id: item.id,
                            extension: 'isFolder' in item && item.isFolder ? 'None' : 'ext' in item && item.ext,
                        },
                    ],
                })
            );
        }

        if ((storage === EStorageType.public || storage === EStorageType.stock) && (!items || items.length <= 1)) {
            social.publishItem('email', item);
            return;
        }

        if (Array.isArray(items)) {
            const url = getSendViaMailruLink(items, storage);
            openLink(url, '_blank');
        }
    }
};

const mount = (): void => {
    const state = reduxStore.getState();
    const selectedItems = getSelectedItems(state);
    const item = selectedItems.length === 1 && selectedItems[0];

    if (!item) {
        return;
    }

    // @ts-ignore
    reduxStore.dispatch(mountRequest({ item }));
};

interface IToolbarClone {
    // source для dwh
    source: string;
    id?: string;
    destination?: string;
    autoClone?: boolean;
    storage?: EStorageType;
    // Не показывать снекбар об успехе клонирования
    noOkSnackbar?: boolean;
    fromReDownload?: boolean;
}

const clone = ({ id, source, destination, autoClone, storage, noOkSnackbar, fromReDownload }: IToolbarClone): void => {
    const state = reduxStore.getState();
    const currentStorage = getCurrentStorage(state);
    let items = getSelectedItemsOrCurrentFolder();
    let item = id && getStorageItemById(state, storage || currentStorage, id);
    const isAnonymous = UserSelectors.isAnonymous(state);
    const isPhone = EnvironmentSelectors.isPhone();

    const isOwn = isOwnPublic(state);
    const faces = getFacesList(state);

    const fromDeeplink = getQueryParams()[EQueryParams.fromDeeplink];
    const isSuperAppWebView = Boolean(IS_WEBVIEW) && fromDeeplink;

    /* tempexp_15344-start */
    if (fromReDownload) {
        const reDownloadParams = reDownloadController.getParams(ReDownloadEntry.Toolbar);

        if (reDownloadParams) {
            items = reDownloadParams.selected;

            if (reDownloadParams.selected?.length > 0) {
                item = undefined;
            }
        } else if (items?.length > 0) {
            item = undefined;
        }
    }
    /* tempexp_15344-end */

    if (isAnonymous && isPhone) {
        renderAuthModalDialog();
        return;
    }

    if (storage === EStorageType.viewerAttaches || storage === EStorageType.attaches) {
        reduxStore.dispatch(setIsFromAttaches(true));
    }
    if (IS_ONPREMISE && isAnonymous) {
        showAuth({});
        return;
    }

    reduxStore.dispatch(
        cloneRequest({
            items: item ? [item] : items,
            source,
            destination,
            autoClone,
            noOkSnackbar,
        })
    );

    if (isSuperAppWebView) {
        const selected = getSelectedItems(state);
        const item = getCurrentPublicItem(state);
        if (selected.length > 0 && item?.isFolder) {
            sendSuperAppXray(superAppSubmetrics.saveInCloud, {
                id_public: isOwn && item?.home ? sha1(item.home) : item?.id,
                id_folder: item?.id,
                type_public: 'folder',
                count_objects: selected.length,
                owner: isOwn,
                have_faces: Boolean(faces?.length),
                count_faces: faces?.length,
            });
        } else {
            if (!item) {
                return;
            }
            superAppTakeItemAndSendXray(superAppSubmetrics.saveInCloud, item, isOwn, faces);
        }
    }
};

const shareLink = ({
    id,
    url,
    useNativeShare = true,
    successSnackbar,
}: {
    id?: string;
    url?: string;
    useNativeShare?: boolean;
    successSnackbar?: SnackbarItem;
}): void => {
    const state = reduxStore.getState();
    const storage = getCurrentStorage(state);

    const isNativeShareSupported = IS_MOBILE_OR_TABLET_BROWSER && 'share' in navigator && typeof navigator.share === 'function';

    const currentStorage = isPublicAlbumTemporaryUrl() ? 'album' : storage;

    const href = id ? `${window.location.origin}/${currentStorage}/${id}` : window.location.href;
    const copyUrl = url || href;

    const fromDeeplink = getQueryParams()[EQueryParams.fromDeeplink];
    const isSuperAppWebView = Boolean(IS_WEBVIEW) && fromDeeplink;

    const item = getCurrentPublicItem(state) as PublicFolder;
    const isOwn = isOwnPublic(state);
    const faces = getFacesList(state);

    if (isSuperAppWebView) {
        sendSuperAppXray(superAppSubmetrics.sharePublic, {
            id_public: isOwn && item?.home ? sha1(item.home) : item?.id,
            id_folder: item?.id,
            type_public: 'folder',
            count_objects: item?.count?.all,
            owner: isOwn,
            have_faces: Boolean(faces?.length),
            count_faces: faces?.length,
        });
    }

    if (isNativeShareSupported && useNativeShare) {
        navigator.share({ url: copyUrl }).catch(() => {});
        return;
    }

    copyToClipboard(copyUrl)
        .then(({ hasBeenCopied }) => {
            if (hasBeenCopied) {
                const snackbar = successSnackbar || {
                    id: 'copy-link',
                    closable: true,
                    text: 'Ссылка успешно скопирована',
                    type: SnackbarTypes.success,
                };

                reduxStore.dispatch(showSnackbarAction(snackbar));
            }
        })
        .catch(() => {
            reduxStore.dispatch(
                showSnackbarAction({
                    id: 'copy-link',
                    closable: true,
                    text: 'Не удалось скопировать ссылку',
                    type: SnackbarTypes.failure,
                })
            );
        });
};

const downloadMobileItem = () => {
    const state = reduxStore.getState();
    const currentStorage = getCurrentStorage(state);
    const selected = getSelectedItems(state);

    reduxStore.dispatch(downloadMobileItemAction());

    if (currentStorage === 'search') {
        const dwhData = {
            eventCategory: ECategoryGa.toolbar_search,
            action: 'download-files',
            count_files: selected.length || 1,
        };
        const items = selected.map((item) => ({
            file_name: 'nameWithoutExt' in item && item.nameWithoutExt,
            file_id: item.id,
            pos: 'pos' in item && item.pos,
            type: item.kind,
            placement: 'srchSrc' in item && item.srchSrc,
        }));

        reduxStore.dispatch(dispatchNewSearchRadar({ dwhData, items }));
    }
};

const showInParentFolder = ({ folderId, itemId }: { folderId: string; itemId?: string }) => {
    const state = reduxStore.getState();
    const storage = getCurrentStorage(state);

    const { isPublic, isStock } = getStorage(storage);

    if (isStock || isPublic || IS_DOCUMENTS_DOMAIN) {
        const selectedFolderId = folderId === ROOT_FOLDER_ID ? '' : getEncodePath(folderId);
        const path = getGoToAndScrollUrl(selectedFolderId, itemId);
        const host = IS_DOCUMENTS_DOMAIN ? changeDocumentDomainToCloud(window.location.origin) : window.location.origin;
        const url = `${host}${path}`;

        opener(url, true);
        return;
    }

    reduxStore.dispatch(scrollToHomeItemAction({ parentFolderId: folderId, itemId }));
    closeSharingNew();
};

const addToAlbum = () => {
    const state = reduxStore.getState();
    const storage = getCurrentStorage(state);
    const items = getSelectedItems(state) || [];

    const selectedItems = getSelectedItemsOrCurrentFolder();
    const isAlbumItem = selectedItems.length === 1 && isAlbum(selectedItems?.[0]);

    if (storage === EStorageType.albums && isAlbumItem) {
        reduxStore.dispatch(addItemsFromCloudToAlbumRequest({ id: selectedItems?.[0]?.id }));
        return;
    }

    if (!items?.length) {
        return;
    }

    reduxStore.dispatch(selectAlbumToAddItemsRequest({ items }));
};

const createAndPublishAlbum = () => {
    const state = reduxStore.getState();
    const items = getSelectedItems(state);

    reduxStore.dispatch(createAndPublishAlbumRequest({ items }));
};

const back = () => {
    reduxStore.dispatch(resetSelect());
};

const createAlbum = () => {
    reduxStore.dispatch(createAlbumRequest());
};

const more = () => {
    reduxStore.dispatch(expandRequest());
};

const moreAction = () => {
    sendGa('breadcrumbs', 'click');
};

// tempexp_17340-start

const getChangeDesignUrl = () => {
    const state = reduxStore.getState();
    const folder = getCurrentFolder(state);
    const url = new URL(getPublicUrl(folder));
    url.searchParams.append('themeChooser', 'true');
    return url;
};

const changeDesignToolbar = () => {
    emitAnalyticEvent(AnalyticEventNames.TOOLBAR_BRAND_PAGE_CLICK);
    sendXray('toolbar-brand-page-click');
    const url = getChangeDesignUrl();
    url.searchParams.append('source', 'toolbar');
    opener(url.toString());
};

const changeDesignBreadcrumbs = () => {
    emitAnalyticEvent(AnalyticEventNames.TOOLBAR_ITEM_CLICK_BRAND_PAGE);
    sendXray('toolbar_item-click_brand_page');
    const url = getChangeDesignUrl();
    url.searchParams.append('source', 'breadcrumbs');
    opener(url.toString());
};
// tempexp_17340-end

const sendComplain = () => {
    const id = getPublicRootWeblink(reduxStore.getState())?.weblink;

    reduxStore.dispatch(
        sendPublicAnalyticsAction({
            id,
            action: EPaymentGa.seoComplaint,
        })
    );
    openComplain();
};

export const toolbarActions = {
    create,
    view,
    sort,
    rename,
    toggleFavorites,
    copy,
    download,
    viewHistory,
    move,
    remove,
    selectAll,
    share,
    publish,
    unPublish,
    restore,
    restoreAll,
    clear,
    forward,
    mount,
    clone,
    shareLink,
    downloadMobileItem,
    showInParentFolder,
    addToAlbum,
    createAndPublishAlbum,
    back,
    createAlbum,
    more,
    // tempexp_17340-start
    moreAction,
    changeDesignToolbar,
    changeDesignBreadcrumbs,
    // tempexp_17340-end
    sendComplain,
};
