/* eslint-disable max-lines-per-function */
import { Icon20ChevronRightOutline, Icon24Done } from '@vkontakte/icons';
import classNames from 'clsx';
import { ReactComponent as FolderIcon } from 'img/icons/folder.svg';
import { ReactComponent as FolderFadeIcon } from 'img/icons/folderFade.svg';
import { ReactComponent as FolderPeopleIcon } from 'img/icons/folderPeople.svg';
import { equals } from 'ramda';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IS_B2B_BIZ_USER, IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { abSafeFakeDoorSelector } from 'reactApp/appHelpers/featuresHelpers';
import { allFilesExpandOnlyByArrow } from 'reactApp/appHelpers/featuresHelpers/features/allFilesExpand';
import { IS_DOCUMENTS_ICONS_REDESIGN_ENABLED } from 'reactApp/appHelpers/featuresHelpers/features/documentIconsRedesign';
import { printPhotoAlbum } from 'reactApp/appHelpers/featuresHelpers/features/printPhotoAlbum';
import { FeatureSelector } from 'reactApp/appHelpers/featuresHelpers/FeatureSelector';
import { chooseVariant } from 'reactApp/appHelpers/featuresHelpers/utils';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { setDomainFoldersFilterActive, setLastOpenFolder } from 'reactApp/modules/home/home.actions';
import { type CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import { DropNotify } from 'reactApp/ui//DropNotify/DropNotify';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { Link } from 'reactApp/ui/Link/Link';
import { PrintPhotoAlbumBadge } from 'reactApp/ui/PrintPhotoAlbumBadge/PrintPhotoAlbumBadge';
import { TrashNodeLabel } from 'reactApp/ui/TreeComponent/TrashNodeLabel';
import { ETreeNodeMode, ETreeRootIds } from 'reactApp/ui/TreeComponent/TreeComponent.constants';
import { checkForceOpenFoldersOnThePath } from 'reactApp/ui/TreeComponent/TreeComponent.helpers';
import type { ITreeNodeClickData, TreeNodeData } from 'reactApp/ui/TreeComponent/TreeNode.types';
import { sendDwh, sendXray } from 'reactApp/utils/ga';
import { noop, noopPromise } from 'reactApp/utils/helpers';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { ESafeFakedoorAnalytics, safeFakedoorAnalytics } from 'reactApp/utils/safeFakedoorGa';

import { useDragAndDrop } from './hooks/useDragAndDrop';
import styles from './TreeNode.css';

interface Props {
    selectedId: string;
    selectedStorage?: EStorageType;
    mod?: ETreeNodeMode;
    data: TreeNodeData;
    onExpand: ({ id, gaId, storage, isOpen, level, selectedStorage }) => Promise<void>;
    onClick: (data: ITreeNodeClickData) => void;
    level?: number;
    forceOpen?: boolean;
    isMobile?: boolean;
    // Ref на выбранный элемент
    handleScrollIntoViewRef?: (RefObject) => void;
    maxTreeLevel?: number;
    search?: string;
    domainFoldersFilterActive?: boolean;
    isParentSharedOrMounted?: boolean;
    isParentPublic?: boolean;
    isDragging: boolean;
    itemsToDrop: (CloudItem | undefined)[];
}

const areEqual = (prevProps: Props, nextProps: Props): boolean =>
    prevProps.selectedId === nextProps.selectedId &&
    prevProps.search === nextProps.search &&
    prevProps.data.itemsCounter === nextProps.data.itemsCounter &&
    prevProps.isDragging === nextProps.isDragging &&
    prevProps.isParentSharedOrMounted === nextProps.isParentSharedOrMounted &&
    prevProps.isParentPublic === nextProps.isParentPublic &&
    prevProps.itemsToDrop?.length === nextProps.itemsToDrop?.length &&
    equals(prevProps.data, nextProps.data);

const getTreeMargin = (level: number) => `${22 * level}px`;

const iconComponentMap = {
    mountFolder: <FolderPeopleIcon width={20} height={20} data-qa-id="mountFolder" />,
    folder: <FolderIcon width={20} height={20} data-qa-id="folder" />,
};

/* eslint-disable complexity */
export const TreeNode = memo<Props>(
    ({
        data,
        selectedId,
        mod,
        onExpand = noopPromise,
        onClick = noop,
        level = 0,
        forceOpen = false,
        isMobile = false,
        selectedStorage = EStorageType.home,
        handleScrollIntoViewRef = noop,
        maxTreeLevel,
        search = '',
        domainFoldersFilterActive,
        isParentSharedOrMounted = false,
        isParentPublic = false,
        isDragging = false,
        itemsToDrop,
    }) => {
        const dispatch = useDispatch();

        const {
            id,
            gaId,
            folderCounter,
            title,
            children,
            itemsCounter,
            icon,
            storage,
            href,
            component,
            isMountedOrShared,
            divider,
            isFolder,
            hideIcon,
            hideHint,
            isBeta,
            clickDwh,
            isNewChip,
        } = data;
        const isSharedOrMounted = isMountedOrShared || isParentSharedOrMounted;
        const isThisOrParentPublic = Boolean(isParentPublic || data.isPublic);

        const forceOpenFoldersOnThePath = checkForceOpenFoldersOnThePath(id, selectedStorage, selectedId, children, mod);

        const [isOpen, setOpen] = useState<boolean | null>(forceOpen || forceOpenFoldersOnThePath || null);
        const [isLoading, setLoading] = useState(false);

        const levelMargin = getTreeMargin(level);
        const levelMarginNext = getTreeMargin(level + 1);
        const isExpandable = folderCounter;
        const storageId = `${storage}${id}`;
        const isSelected = domainFoldersFilterActive && id === '/domain' ? true : storageId === selectedId;

        const { isDraggingOver, handleMouseMove, handleMouseLeave, handleStopDrag, isAllowedToDrop } = useDragAndDrop(
            data,
            isSelected,
            isDragging,
            itemsToDrop
        );

        // В этом и нижележащих компонентах, в том числе хуках, нельзя лазить в стор!
        // Иначе тут очень много перерендеров при массивных/частых обновлениях стора
        // Все из стора прокидывать снаружи из TreeComponent (либо в data из TreeComponent.helpers)
        // Если надо, то не забывать добавлять новые свойства в ф-ию areEqual выше

        const isSelectDialog = mod === ETreeNodeMode.selectDlg || mod === ETreeNodeMode.selectCheck || mod === ETreeNodeMode.albumsDlg;
        const folderType = isMountedOrShared ? 'mountFolder' : 'folder';

        const loaderItems =
            (isOpen || forceOpenFoldersOnThePath) && isLoading && folderCounter ? Array.from(new Array(folderCounter).keys()) : null;

        const noLink = id === ETreeRootIds.domain || isSelectDialog || id === ETreeRootIds.alldocuments;

        const toggleDomainFoldersFilter = useCallback(() => {
            if (!IS_BIZ_USER || isSelectDialog) {
                return;
            }

            if (id !== '/domain' || level > 0) {
                return dispatch(setDomainFoldersFilterActive(false));
            }

            dispatch(setDomainFoldersFilterActive(true));
        }, [dispatch, id, level, isSelectDialog]);
        const treeNodeRef = useRef<HTMLDivElement | null>(null);

        const handleExpand = useCallback(
            (e?: React.MouseEvent<HTMLElement>, forceOpen?: boolean) => {
                e?.stopPropagation();

                if (maxTreeLevel && level >= maxTreeLevel) {
                    return;
                }

                const newIsOpen = forceOpen ? true : !isOpen;

                setOpen(newIsOpen);

                if (newIsOpen) {
                    setLoading(true);
                }

                onExpand({ isOpen: newIsOpen, id, storage, gaId, level, selectedStorage }).finally(() => {
                    setLoading(false);
                });
            },
            [maxTreeLevel, id, gaId, storage, level, isOpen, setOpen, setLoading, selectedStorage, onExpand]
        );

        useEffect(() => {
            if (id === ETreeRootIds.safe) {
                safeFakedoorAnalytics(ESafeFakedoorAnalytics.SHOW, { entity: 'chapter' });
            }
        }, []);

        useEffect(() => {
            const folderToSelected = selectedId.startsWith(`${storage}${id}/`);
            const childrensLoaded = children?.length;
            if ((isOpen && !childrensLoaded) || (folderToSelected && !childrensLoaded && !isOpen)) {
                handleExpand(undefined, true);
            }
            if (folderToSelected && childrensLoaded && !isOpen) {
                setOpen(true);
            }
        }, [selectedId]);

        const handleClick = useCallback(
            (e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();

                toggleDomainFoldersFilter();
                if (!isOpen && isExpandable) {
                    /* CLOUDWEB-18216 Эксперимент: разворачивание / сворачивание пункта "Все файлы" только по стрелке */
                    if (!level && allFilesExpandOnlyByArrow && !IS_B2B_BIZ_USER) {
                        return;
                    }

                    handleExpand(e);
                }

                if (storage !== EStorageType.home || (storage === EStorageType.home && id === ROOT_FOLDER_ID)) {
                    sendDwh({
                        eventCategory: ECategoryGa.entered,
                        action: 'section',
                        dwhData: {
                            section: storage,
                        },
                    });
                }

                if (storage === EStorageType.templates) {
                    sendDwh({
                        eventCategory: ECategoryGa.leftnav,
                        action: 'tmpl-click',
                        dwhData: clickDwh,
                    });
                }
                if (data?.isFolder) {
                    sendPaymentGa({
                        eventCategory: ECategoryGa.folder,
                        action: 'open-folder',
                        type_folder: data.parent !== '/' ? 'folder_in_folder' : 'folder',
                        source: 'menu',
                    });

                    dispatch(setLastOpenFolder(data.id));
                }
            },
            [toggleDomainFoldersFilter, isOpen, isExpandable, storage, id, handleExpand, clickDwh]
        );

        const handleGoTo = useCallback(
            (e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();

                let newId = id;
                if (id === ETreeRootIds.domain) {
                    newId = isSelectDialog ? id : ROOT_FOLDER_ID;
                }

                if (!isSelectDialog) {
                    toggleDomainFoldersFilter();
                }

                onClick({ id: newId, gaId, storage, href, level, isSharedOrMounted, isThisOrParentPublic });

                if (!isOpen && isExpandable) {
                    handleExpand(e);
                }
            },
            [
                id,
                gaId,
                href,
                storage,
                level,
                isOpen,
                isSharedOrMounted,
                isThisOrParentPublic,
                onClick,
                handleExpand,
                isExpandable,
                toggleDomainFoldersFilter,
                isSelectDialog,
            ]
        );

        const handleDoubleClick = useCallback(
            (e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();

                onClick({ id, gaId, storage, href, level, isSharedOrMounted, isThisOrParentPublic });

                if (isExpandable) {
                    handleExpand(e);
                }
            },
            [id, gaId, href, storage, level, onClick, isExpandable, isSharedOrMounted, isThisOrParentPublic, handleExpand]
        );

        const handleExpandClick = useCallback(
            (e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault();
                handleExpand(e);
            },
            [handleExpand]
        );

        useEffect(() => {
            if (!loaderItems) {
                sendXray([`${isSelectDialog ? 'dlg' : 'lm'}-tree-folder`, 'show', level.toString(), (!!isExpandable).toString()]);
            }
        }, [loaderItems, level, maxTreeLevel, isExpandable, mod]);

        useEffect(() => {
            if (isSelected && treeNodeRef.current) {
                handleScrollIntoViewRef(treeNodeRef.current);
            }
        }, [handleScrollIntoViewRef, isSelected, treeNodeRef.current]);

        const handleRef = useCallback((ref: HTMLDivElement) => {
            treeNodeRef.current = ref;
        }, []);

        if (maxTreeLevel && level > maxTreeLevel) {
            return null;
        }

        const renderChildren = () => {
            if (component) {
                return <>{component}</>;
            }

            return loaderItems
                ? loaderItems.map((item) => (
                      <div className={classNames(styles.item, styles.loaderItem)} key={item}>
                          <div className={styles.space} style={{ width: levelMarginNext }} />
                          <div className={styles.expandControl} />
                          <div className={styles.folderIcon}>
                              <FolderFadeIcon width={20} height={20} />
                          </div>
                          <div className={styles.titlePlaceholder} />
                      </div>
                  ))
                : !!children &&
                      children.map((item) => (
                          <TreeNode
                              key={item.id}
                              selectedId={selectedId}
                              data={item}
                              mod={mod}
                              search={search}
                              onExpand={onExpand}
                              onClick={onClick}
                              level={level + 1}
                              selectedStorage={selectedStorage}
                              handleScrollIntoViewRef={handleScrollIntoViewRef}
                              maxTreeLevel={maxTreeLevel}
                              isParentSharedOrMounted={isSharedOrMounted}
                              isParentPublic={isThisOrParentPublic}
                              itemsToDrop={itemsToDrop}
                              isDragging={isDragging}
                          />
                      ));
        };

        return (
            <>
                <div className={classNames({ [styles.root]: true, [styles[`root_${mod}`]]: !!mod })} data-name={id}>
                    <Link
                        id={id}
                        href={href}
                        storage={storage}
                        handleGoTo={handleGoTo}
                        handleClick={handleClick}
                        noLink={noLink}
                        search={search}
                    >
                        <div
                            className={classNames(styles.item, {
                                [styles.selected]: isSelected,
                                [styles.drop]: !isSelectDialog && isDraggingOver && isAllowedToDrop,
                                [styles.dropDelete]: !isSelectDialog && isDraggingOver && isAllowedToDrop && id === ETreeRootIds.trashbin,
                            })}
                            onDoubleClick={isSelectDialog && !isMobile ? handleDoubleClick : noop}
                            ref={handleRef}
                            onMouseMove={isSelectDialog ? noop : handleMouseMove}
                            onMouseLeave={isSelectDialog ? noop : handleMouseLeave}
                            onMouseUp={isSelectDialog ? noop : handleStopDrag}
                            onMouseOver={!isSelectDialog && isExpandable && isDraggingOver && !isOpen ? handleExpand : noop}
                            data-id={id}
                        >
                            <div className={styles.space} style={{ width: levelMargin }} />
                            <div
                                className={classNames(styles.expandControl, {
                                    [styles.expandControlExpandable]: isExpandable,
                                    [styles.hideExpandControl]: !isExpandable && !isFolder,
                                })}
                                onClick={isExpandable ? handleExpandClick : noop}
                            >
                                {Boolean(folderCounter) && (
                                    <Icon20ChevronRightOutline
                                        className={classNames(styles.expandIcon, {
                                            [styles.expandIconExpanded]:
                                                (!maxTreeLevel || level < maxTreeLevel) &&
                                                (isOpen || (forceOpenFoldersOnThePath && isOpen !== false)),
                                        })}
                                    />
                                )}
                            </div>
                            {!hideIcon && (
                                <div
                                    className={classNames(styles.folderIcon, {
                                        [styles.folderIconRedesign]:
                                            IS_DOCUMENTS_ICONS_REDESIGN_ENABLED &&
                                            (storage === EStorageType.alldocuments || storage === EStorageType.incomingPublic),
                                    })}
                                >
                                    {icon || iconComponentMap[folderType]}
                                </div>
                            )}
                            <Hint text={!hideHint && level > 0 && !isDraggingOver ? title : ''}>
                                <div
                                    className={chooseVariant(
                                        abSafeFakeDoorSelector,
                                        {
                                            control: classNames(styles.title),
                                            variant2: classNames(styles.title, { [styles.safeAb]: id === ETreeRootIds.safe }),
                                        },
                                        { skipCondition: { variant1: true } }
                                    )}
                                >
                                    {title}
                                </div>
                            </Hint>
                            {!!itemsCounter && (
                                <div className={styles.counter}>
                                    <div className={styles.counterVal}>{itemsCounter}</div>
                                </div>
                            )}
                            {mod === ETreeNodeMode.selectCheck && isSelected && (
                                <div className={styles.check}>
                                    <Icon24Done />
                                </div>
                            )}

                            {!isSelectDialog && isDraggingOver && (
                                <DropNotify
                                    dragOverName={title}
                                    isAllowedToDrop={Boolean(isAllowedToDrop)}
                                    target={treeNodeRef}
                                    specialText={storage === EStorageType.trashbin ? 'Удалить' : undefined}
                                />
                            )}
                            {isBeta && <div className={styles.beta}>beta</div>}
                            {isNewChip && <div className={styles.newChip}>новое</div>}
                            {id === ETreeRootIds.trashbin && <TrashNodeLabel />}
                            {id === ETreeRootIds.safe && (
                                <FeatureSelector
                                    selector={abSafeFakeDoorSelector}
                                    control={<></>}
                                    variant2={<div className={styles.newTag}>новое</div>}
                                />
                            )}
                            {id === ETreeRootIds.albums && printPhotoAlbum === 'b' && (
                                <PrintPhotoAlbumBadge
                                    className={styles.badge}
                                    onClick={(evt) => {
                                        evt.stopPropagation();
                                    }}
                                />
                            )}
                        </div>
                    </Link>
                    <div className={styles.children}>{(isOpen || (forceOpenFoldersOnThePath && isOpen !== false)) && renderChildren()}</div>
                </div>
                {divider && <div className={styles.divider} />}
            </>
        );
    },
    areEqual
);

TreeNode.displayName = 'TreeNode';

TreeNode.whyDidYouRender = true;
