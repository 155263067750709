import { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { RootState } from 'reactApp/store';
import type { BreadcrumbsListItem } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { createSelector } from 'reselect';

import type { IncomingPublicState } from './incomingPublic.types';

const INCOMING_PUBLIC = 'incoming-public';

export const getIncomingPublicState = (state: RootState): IncomingPublicState => state.incomingPublic;

export const getIncomingPublics = createSelector(getIncomingPublicState, (state) => state.list);

export const getIncomingPublicCursor = createSelector(getIncomingPublicState, (state) => state.cursor);

export const getIncomingPublicCategory = createSelector(getIncomingPublicState, (state) => state.category);

export const getIncomingPublicItemById = createSelector(
    getIncomingPublics,
    (state, id) => id,
    (list, id) => {
        return list[id];
    }
);

export const getIncomingPublicIds = createSelector(getIncomingPublicState, (state) => Object.keys(state.list));

export const getIncomingPublicBreadcrumbs = () => {
    const result: BreadcrumbsListItem[] = [
        {
            name: 'Доступные мне',
            id: '/incoming',
            kind: 'storage' as const,
            storage: EStorageType.incomingPublic,
        },
    ];

    return result;
};

export const getCurrentIncomingPublicFolder = createSelector(getIncomingPublicState, (state) => {
    return {
        id: INCOMING_PUBLIC,
        value: INCOMING_PUBLIC,
        name: 'Доступные мне',
        isLoaded: state.isLoaded,
        isLoading: state.isLoading,
        cursor: state.cursor,
        hasMoreToLoad: state.hasMoreToLoad,
        error: state.error,
        childs: Object.keys(state.list),
    };
});
