import { Icon20CloudOutline, Icon20PicturePlusOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { addItemsFromCloudToAlbumRequest } from 'reactApp/modules/albums/albums.actions';

import styles from '../EmptyFolder.css';

export const useSelectFromCloud = (id: string, isAlbum?: boolean) => {
    const dispatch = useDispatch();

    const Create = useMemo(
        () => (
            <div className={styles.icon}>
                <Icon20CloudOutline />
            </div>
        ),
        []
    );

    const aditionalContent = useMemo(
        () => (
            <div className={styles.saveInCloudAditional}>
                <div className={classNames(styles.saveInCloudAditionalIcon, styles.backgroudWhite)}>
                    <Icon20PicturePlusOutline />
                </div>
                <div className={classNames(styles.saveInCloudAditionalText, styles.backgroudWhite)}>Добавить в альбом</div>
            </div>
        ),
        []
    );

    const handleClickCreateNew = useCallback(() => {
        dispatch(addItemsFromCloudToAlbumRequest({ id }));
    }, [id]);

    const annotation = isAlbum
        ? 'Добавьте в альбом фото и видео, уже загруженные в Облако'
        : 'Добавьте в альбом уже загруженные в Облако фотографии и видео';
    return useCallback(() => {
        return {
            title: 'Выбрать из Облака',
            annotation,
            onClick: handleClickCreateNew,
            Icon: Create,
            additionLinks: isAlbum ? aditionalContent : undefined,
        };
    }, [Create, handleClickCreateNew]);
};
