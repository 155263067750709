import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { Icon16CheckOutline } from '@vkontakte/icons';
import welcomeDialogImage from 'img/welcome/welcomeDialog.jpg';
import welcomeDialogDarkImage from 'img/welcome/welcomeDialogDark.jpg';
import React, { type FC, useCallback, useMemo } from 'react';
import { BASEQUOTA } from 'reactApp/appHelpers/configHelpers';
import { isDarkTheme } from 'reactApp/appHelpers/featuresHelpers';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { LicenseBlock } from 'reactApp/ui/LicenseBlock/LicenseBlock';
import { createGaSender, sendXray } from 'reactApp/utils/ga';

import styles from './WelcomeDialog.css';

const sendGa = createGaSender('welcome');

export interface WelcomeDialogProps {
    onSuccess: () => void;
    onShow: () => void;
    onClose: () => void;
}

export const WelcomeDialog: FC<WelcomeDialogProps> = ({ onSuccess, onShow, onClose }) => {
    const coverImage = useMemo(() => (isDarkTheme ? welcomeDialogDarkImage : welcomeDialogImage), []);

    const handleShow = useCallback(() => {
        sendGa('show', 'step_0');

        onShow();
    }, [onShow]);

    const handleClick = useCallback(() => {
        sendGa('close', 'step_0');
        sendXray(['ui_welcome_start']);

        onSuccess();
        onClose();
    }, [onSuccess, onClose]);

    return (
        <WhatsNewDialog
            titleClassName={styles.title}
            textClassName={styles.text}
            footerClassName={styles.footer}
            primaryButtonText="Продолжить"
            onShow={handleShow}
            onClick={handleClick}
            onClose={onClose}
            title="Вы в Облаке Mail — здесь можно"
            text={
                <ul className={styles.list}>
                    <li className={styles.item}>
                        <Icon16CheckOutline className={styles.icon} />
                        Хранить всё, что считаете нужным. {bytesToNDigits(BASEQUOTA, 3).value} на старте, с подпиской будет больше
                    </li>
                    <li className={styles.item}>
                        <Icon16CheckOutline className={styles.icon} />
                        Экономить на покупке нового телефона с функцией автозагрузки
                    </li>
                    <li className={styles.item}>
                        <Icon16CheckOutline className={styles.icon} />
                        Работать с PDF-файлами в онлайн-редакторе
                    </li>
                </ul>
            }
            imageUrl={coverImage}
            buttonTheme="vk"
            closable={false}
            footerText={<LicenseBlock />}
        />
    );
};
