import { stringify } from 'qs';
import { compareAttachFolderItems, filterAttaches, normalizeAttachId } from 'reactApp/modules/attaches/attaches.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { RootState } from 'reactApp/store';
import type { TreeNodeData } from 'reactApp/ui/TreeComponent/TreeNode.types';
import { createSelector } from 'reselect';

import { SettingsSelectors } from '../settings/settings.selectors';
import type { AttachesBreadcrumb, AttachesFolderItem, AttachesItem, AttachFolderTreeItem, SearchParams, State } from './attaches.types';

const getAttachesStore = (state: RootState): State => state.attaches;
const getAttaches = (state: RootState): Record<string, AttachesItem> => getAttachesStore(state).attaches;
const getViewerAttaches = (state: RootState): Record<string, AttachesItem> => getAttachesStore(state).viewerAttaches;
const getViewerAttachesIds = (state: RootState): string[] => Object.keys(getAttachesStore(state).viewerAttaches || {});
const getLoadedMessagesIds = (state: RootState): string[] => getAttachesStore(state).loadedMessagesIds;
const getAttachesList = (state: RootState): string[] => getAttachesStore(state).childs;
const hasMoreToLoad = (state: RootState): boolean => getAttachesStore(state).hasMoreToLoad;
const getSearchState = (state: RootState): SearchParams | null => getAttachesStore(state).search;
const getAttachFolders = (state: RootState): Record<string, AttachesFolderItem> => getAttachesStore(state).folders;
const getFolderOpenedState = (state: RootState): Record<string, boolean> => getAttachesStore(state).openedFoldersIdxs;
const getAttachesBreadcrumbs = createSelector(
    getAttachFolders,
    SettingsSelectors.getQueryParams,
    (state: RootState, id: string): string => id,
    (state: RootState, id: string, isSearch?: boolean): boolean => !!isSearch,
    (folders, queryParams, currentFolder, isSearch): AttachesBreadcrumb[] => {
        const breadcrumbs: AttachesBreadcrumb[] = [];

        const { query: _query, folderId, ...rest } = queryParams;

        if (isSearch) {
            breadcrumbs.push({
                name: 'Результаты поиска',
                kind: 'folder',
                id: '',
                storage: EStorageType.attaches,
                href: `/attaches?folderId=${folderId}`,
                search: stringify(queryParams, { skipNulls: true, addQueryPrefix: true }),
            });
        }

        let node: AttachesFolderItem = folders[currentFolder];

        while (node && node.parent) {
            breadcrumbs.push({
                name: node.name,
                kind: 'folder',
                id: node.id,
                storage: EStorageType.attaches,
                href: `/attaches?folderId=${node.id}`,
                search: stringify(rest, { skipNulls: true, addQueryPrefix: true }),
            });

            node = folders[node.parent];
        }

        breadcrumbs.push({
            kind: 'storage',
            name: 'Из почты',
            id: '/',
            storage: EStorageType.attaches,
            href: '/attaches',
        });

        return breadcrumbs.reverse();
    }
);
const getAttachesItems = createSelector(getAttachesList, getAttaches, (list, attaches): AttachesItem[] =>
    filterAttaches(list.map((id: string): AttachesItem => attaches[id]))
);
const getAttachById = createSelector(
    getAttaches,
    (state: RootState, id: string): string => normalizeAttachId(id),
    (attaches, id): AttachesItem => attaches[id]
);
const getViewerAttachById = createSelector(
    getViewerAttaches,
    (state: RootState, id: string): string => normalizeAttachId(id),
    (attaches, id): AttachesItem => attaches[id]
);
const getAttachesFoldersTree = createSelector(
    getAttachFolders,
    getFolderOpenedState,
    (state: any, activeFolderId: string): string => activeFolderId,
    (folders, foldersState, activeFolderId): AttachFolderTreeItem[] => {
        if (!folders) {
            return [];
        }

        const list = Array.from(Object.values(folders))
            .filter((folder: AttachesFolderItem): any => !['spam', 'drafts', 'templates', 'trash'].includes(folder.type))
            .filter(
                (folder): boolean =>
                    !folder.security &&
                    (folder.messages_with_attachments > 0 || folder.children || (folder.type === 'inbox' && folder.system))
            )
            .sort(compareAttachFolderItems);

        const foldersWithAttaches = list.map(
            ({ name, parent, id, type, child }): AttachFolderTreeItem => ({
                href: `/attaches/?folderId=${id}`,
                text: name,
                icon: 'ico_folder_user',
                id,
                parent,
                storage: EStorageType.attaches,
                items: [],
                active: id === activeFolderId,
                type,
                child,
            })
        );
        const foldersWithAttachesIndex = new Map<string, AttachFolderTreeItem>(foldersWithAttaches.map((obj): any => [obj.id, obj]));
        const deleteList = {};
        foldersWithAttaches.forEach((folder): void => {
            if (folder.parent !== '-1') {
                const parent = foldersWithAttachesIndex.get(folder.parent);
                if (parent) {
                    parent.items.push(folder);
                    parent.foldings = foldersState[parent.id] ? 'open' : 'close';
                    deleteList[folder.id] = true;
                }
            }
        });

        // TODO: можно теперь пробежаться по дереву DFS и проставить всем папкам, сколько у них messages_with_attachments
        // с учетом детей и потом выпилить все пустые ветки.

        return foldersWithAttaches.filter((item): boolean => !deleteList[item.id]);
    }
);

const getAttachFolderById = createSelector(
    getAttachFolders,
    (state: RootState, id: string): string => id,
    (folders, id): AttachesFolderItem => folders[id]
);

const getAttachLoadingState = createSelector(getAttachesStore, (state) => ({
    isLoading: state.isLoading,
    isLoaded: state.isLoaded,
    error: state.error,
}));

const getAllMessagesTotalCount = (state: RootState): number => getAttachesStore(state).allMessagesTotalCount;

const getIsFromAttaches = (state: RootState): boolean => getAttachesStore(state).isFromAttaches;

const getAttachesIdViewed = (state: RootState) => getAttachesStore(state).attachesIdViewed;

const getAttachesFoldersTreeNodes = createSelector(
    (state: RootState) => getAttachesFoldersTree(state, ''),
    (state: RootState, getAttachesFolderIcon) => getAttachesFolderIcon,
    (folders, getAttachesFolderIcon): TreeNodeData[] => {
        const mapAttachItemToTreeNodeData = (list) =>
            list
                ? list.map((item) => {
                      return {
                          id: item.id,
                          href: item.href,
                          title: item.text,
                          storage: EStorageType.attaches,
                          folderCounter: item.items ? item.items.length : 0,
                          children: mapAttachItemToTreeNodeData(item.items),
                          icon: getAttachesFolderIcon(item),
                      };
                  })
                : [];

        return mapAttachItemToTreeNodeData(Array.from(Object.values(folders)));
    }
);

export const AttachesSelectors = {
    getAttaches,
    getViewerAttaches,
    getViewerAttachesIds,
    getAttachesList,
    getAttachById,
    getViewerAttachById,
    getLoadedMessagesIds,
    getAttachesStore,
    hasMoreToLoad,
    getSearchState,
    getAttachFolders,
    getAttachesBreadcrumbs,
    getAttachesItems,
    getAttachFolderById,
    getAttachesFoldersTree,
    getAllMessagesTotalCount,
    getAttachLoadingState,
    getIsFromAttaches,
    getAttachesIdViewed,
    getAttachesFoldersTreeNodes,
};
