import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_MOBILE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

const cancelAutopayFamilyValue: 'a' | 'b' = getFeature('cancel-autopay-family');

if (!IS_MOBILE_BROWSER) {
    registerExperiment('autopay-family', cancelAutopayFamilyValue, [
        AnalyticEventNames.CANCEL_SUB_TARIFF,
        AnalyticEventNames.CANCEL_SUB_CAPTCHA,
        AnalyticEventNames.CANCEL_SUB_EMPTY_FAMILY,
        AnalyticEventNames.CANCEL_SUB_EMPTY_FAMILY_CLICK,
        AnalyticEventNames.CANCEL_SUB_FAMILY,
        AnalyticEventNames.CANCEL_SUB_FAMILY_CLICK,
    ]);
}

export const cancelAutopayFamily = !IS_MOBILE_BROWSER && cancelAutopayFamilyValue === 'b';
