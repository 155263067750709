import classNames from 'clsx';
import React, { type ForwardedRef, forwardRef, memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isPersonalAccessSharingEnabled } from 'reactApp/appHelpers/featuresHelpers/features/personalAccessSharing';
import { isPublicPasswordEnabled } from 'reactApp/appHelpers/featuresHelpers/features/publicPassword';
import { getACL, getOwner } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.selectors';
import type { IACListItem } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.types';
import type { PublishItem } from 'reactApp/modules/modifying/modifying.types';
import type { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { isDomainFolder, isMountedFolder } from 'reactApp/modules/storage/folder.helpers';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { getStorageItemByIdFromStorageOrCurrentOrHome, isMountedOrSharedFolder } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { getACL as getWeblinkACL } from 'reactApp/modules/weblinkAccessControl/weblinkAccessControl.selectors';
import type { RootState } from 'reactApp/store';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { getPublicUrl } from 'reactApp/utils/urlHelper';

import { useSharingNew } from '../SharingWindow/SharingNew/hooks/sharingNew.hook';
import { CloseSharing } from './CloseSharing/CloseSharing';
import { CopyLink } from './CopyLink/CopyLink';
import { SharingNewSharing } from './Sharing/SharingNewSharing';
import { getTitle } from './SharingNew.constants';
import styles from './SharingNew.css';
import { type IProps, DomainAccess, EFrom } from './SharingNew.types';
import { SharingNewWeblink } from './Weblink/SharingNewWeblink';

export const SharingNewContent = memo(
    forwardRef((props: IPropsWithPopup<IProps>, ref: ForwardedRef<HTMLDivElement>) => {
        const { itemStorage, id, from, onClose, onRendered, gaSuffix, fromEditor, isPhone } = props;
        const [showUsersScreen, setShowUsersScreen] = useState<boolean>(false);
        const storage = useSelector(getCurrentStorage);
        const { isSharedIncoming, isPublic, isStart } = getStorage(storage);
        const item = useSelector((state: RootState) => getStorageItemByIdFromStorageOrCurrentOrHome(state, itemStorage, id)) as PublishItem;
        const isFromWeblink = from === EFrom.WEBLINK || isPublic;
        const isFromSharing = from === EFrom.SHARING;
        const isFolder = item?.isFolder;
        const isMounted = isMountedFolder(item);
        const isSharedOrMouted = useSelector((state: RootState) =>
            isFolder ? isMountedOrSharedFolder(state, item) : ({} as ReturnType<typeof isMountedOrSharedFolder>)
        );
        const isDomainItem: boolean = isDomainFolder(item);
        const isAlbum = from === EFrom.ALBUMS || storage === EStorageType.albums;
        const currentUserEmail = useSelector(UserSelectors.getEmail);
        const owner = useSelector(getOwner);

        const acList = useSelector((state: RootState) =>
            !isPersonalAccessSharingEnabled || isFolder ? getACL(state) : getWeblinkACL(state)
        ) as IACListItem[];

        const isOwner = currentUserEmail === owner?.email;
        const showSharing =
            (isFolder || isPersonalAccessSharingEnabled) &&
            !isAlbum &&
            !isDomainItem &&
            !isStart &&
            (!(isSharedOrMouted.isMounted || isSharedOrMouted.isShared) || item?.id === isSharedOrMouted.folderId);

        const { isCloudUsersOpened, isWeblinkOpened, setWeblinkOpened, setCloudUsersOpened, onActionClick, onChangeWeblink } =
            useSharingNew({ storage, isFromSharing, item, acList, isFromWeblink, isOwner });

        const showCloseButton = isWeblinkOpened && !showUsersScreen;
        const showCopyLink = isPublicPasswordEnabled && !showUsersScreen && isWeblinkOpened;

        useEffect(() => {
            if (!item && onClose) {
                onClose();
            }
        }, [item, onClose]);

        useEffect(() => {
            onRendered?.();
        }, []);

        return (
            <div className={classNames(styles.root, { [styles.root_phone]: isPhone })}>
                <div className={styles.header}>{getTitle({ isPublic, isMounted, name: item?.name })}</div>
                <div
                    className={classNames(styles.content, {
                        [styles.content_pb]: !showCloseButton && !isOwner,
                        [styles.content_old]: !showCopyLink,
                    })}
                    ref={ref}
                >
                    {!isSharedIncoming && !showUsersScreen && (
                        <SharingNewWeblink
                            item={item}
                            from={from}
                            isSharedOrMouted={isSharedOrMouted}
                            gaSuffix={gaSuffix}
                            noExpiresControl={isPublic}
                            isPhone={isPhone}
                            fromEditor={fromEditor}
                            formProps={props}
                            isWeblinkOpened={isWeblinkOpened}
                            setWeblinkOpened={setWeblinkOpened}
                            onActionClick={onActionClick}
                        />
                    )}
                    {showSharing && (
                        <SharingNewSharing
                            onClose={onClose}
                            item={item}
                            from={from}
                            isPhone={isPhone}
                            isMounted={isMounted}
                            showUsersScreen={showUsersScreen}
                            setShowUsersScreen={setShowUsersScreen}
                            acList={acList}
                            isCloudUsersOpened={isCloudUsersOpened}
                            setCloudUsersOpened={setCloudUsersOpened}
                            owner={owner}
                            storage={storage}
                            isAlbum={isAlbum}
                            currentUserEmail={currentUserEmail}
                            onActionClick={onActionClick}
                        />
                    )}
                    <div className={classNames(styles.footer, { [styles.footer_old]: !showCopyLink })}>
                        {showCopyLink && (
                            <CopyLink
                                isPhone={isPhone}
                                isFolder={isFolder}
                                publicUrl={getPublicUrl(item)}
                                isDownloadable={item?.weblinkDownloadable}
                                fileName={item?.name}
                                weblinkDomestic={
                                    item && 'weblinkDomainAccess' in item && item.weblinkDomainAccess === DomainAccess.DOMESTIC
                                }
                                weblinkAccessRights={item?.weblinkAccessRights}
                            />
                        )}
                        {showUsersScreen && (
                            <Button
                                className={styles.backButton}
                                sizeMode={isPublicPasswordEnabled ? ButtonSizeMode.small : ButtonSizeMode.middle}
                                onClick={() => setShowUsersScreen(false)}
                            >
                                Назад
                            </Button>
                        )}
                        {showCloseButton && <CloseSharing onClick={onChangeWeblink} showSharing={showSharing} isOwner={isOwner} />}
                    </div>
                </div>
            </div>
        );
    })
);

SharingNewContent.displayName = 'SharingNewContent';
