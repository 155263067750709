import classNames from 'clsx';
import React, { type ReactElement, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_B2B_BIZ_USER, IS_MY_TEAM, IS_PUBLIC_OF_OVERQUOTA } from 'reactApp/appHelpers/configHelpers';
import { publicOverquota } from 'reactApp/appHelpers/featuresHelpers/features/publicOverquota';
import { useResponsiveEnabled } from 'reactApp/hooks/responsiveness/useResponsiveEnabled';
import { showMobileAppPromo } from 'reactApp/modules/promo/promo.module';
import { isOwnPublic, isPublicUploadEnabled } from 'reactApp/modules/public/public.selectors';
import { getIsDragging } from 'reactApp/modules/upload/upload.selectors';
import { BackToRoot } from 'reactApp/ui/BackToRoot/BackToRoot';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import { EmptyPublicOverquota } from 'reactApp/ui/Empty/new/EmptyPublicOverquota';
import { ToolbarNew } from 'reactApp/ui/Toolbar/Toolbar.new';
import { UploadBlock } from 'reactApp/ui/UploadBlock/UploadBlock';
import { useShowAd } from 'reactApp/ui/Worm/useShowAd';
import { Worm } from 'reactApp/ui/Worm/Worm';
import { noop } from 'reactApp/utils/helpers';

import styles from './Folder.css';

interface Props {
    noTopBanner?: boolean;
    isShove?: boolean;
    supportThemedPage?: boolean;
    showUploadBlock?: boolean;
}

export const Folder = memo(function Folder({
    noTopBanner = true,
    isShove = false,
    supportThemedPage = false,
    showUploadBlock = true,
}: Props): ReactElement {
    const dispatch = useDispatch();
    const [visible, setVisible] = useState<boolean>(false);
    const rootRef = useRef<HTMLDivElement>(null);
    const isUploadEnabled = useSelector(isPublicUploadEnabled);
    const isOwner = useSelector(isOwnPublic);
    const isDragging = useSelector(getIsDragging);

    const showDisabledForOverquotaOwner = IS_PUBLIC_OF_OVERQUOTA && publicOverquota;

    const isResponsiveEnable = useResponsiveEnabled();

    /**
     * В этом месте нужен перерендер чтобы в DataList передался уже существующий customScrollElement
     */
    useEffect(() => setVisible(true), []);

    const onDownload = useCallback(() => {
        dispatch(showMobileAppPromo({}));
    }, []);

    const showAD = useShowAd();

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_shove]: isShove,
                [styles.root_team]: IS_MY_TEAM,
                [styles.root_uploader]: isDragging && (isUploadEnabled || isOwner),
                [styles.with_scroll]: showDisabledForOverquotaOwner,
            })}
        >
            {!noTopBanner && showAD && !IS_B2B_BIZ_USER && (
                <div className={styles.wormWrapper}>
                    <Worm />
                </div>
            )}
            {IS_MY_TEAM && (
                <div className={styles.backToCloud}>
                    <BackToRoot />
                </div>
            )}
            {showDisabledForOverquotaOwner && (
                <div className={styles.emptyOverquota}>
                    <div ref={rootRef} className={classNames(styles.toolbar_wrapper, styles.toolbar_wrapper_newHeader)}>
                        <ToolbarNew onDownload={onDownload} />
                    </div>
                    <div className={styles.wrapper_overquota}>
                        <EmptyPublicOverquota />
                    </div>
                </div>
            )}
            {!showDisabledForOverquotaOwner && (
                <>
                    <div ref={rootRef} className={classNames(styles.toolbar_wrapper, styles.toolbar_wrapper_newHeader)}>
                        <ToolbarNew onDownload={onDownload} isResponsiveEnable={isResponsiveEnable} />
                    </div>
                    <div ref={rootRef} className={classNames(styles.datalist, styles.datalist_newHeader)}>
                        {visible && (
                            <DataList
                                goTo={noop}
                                customScrollElement={isShove ? rootRef.current : undefined}
                                showBreadcrumbs={!supportThemedPage}
                            />
                        )}
                    </div>
                    {showUploadBlock && (
                        <UploadBlock
                            showDropArea={isOwner}
                            allowDnd={true}
                            gaCategory="public"
                            isOwnerInViewMode={isOwner && !isUploadEnabled}
                        />
                    )}
                </>
            )}
        </div>
    );
});
