import classNames from 'clsx';
import React, { type ReactElement, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { type ListChildComponentProps, areEqual } from 'react-window';
import { getCurrentAlbum } from 'reactApp/modules/albums/albums.selector';
import { GALLERY_TYPES_COUNT } from 'reactApp/ui/VirtualList/VirtualList.constants';
import { getThumbTypeForGallery } from 'reactApp/ui/VirtualList/VirtualList.helpers.new';

import {
    DATA_LIST_BREADCRUMBS_ID,
    DATA_LIST_EXTRABLOCKS_ID,
    DATA_LIST_GALLERY_TAB_MENU_ID,
    DATA_LIST_PUBLIC_UPLOAD_HEADER_ID,
    DATA_LIST_STORIES_WIDGET_ID,
} from '../Datalist/DataList';
import styles from './VirtualList.new.css';

export const VirtualListGalleryRow = memo(function VirtualListGridRow(props: ListChildComponentProps): ReactElement | null {
    const { index, style, data } = props;
    const { list, itemActiveIndex, indexes, renderItem } = data;

    const extraBlocksInHead = list.filter((block) =>
        [DATA_LIST_BREADCRUMBS_ID, DATA_LIST_STORIES_WIDGET_ID, DATA_LIST_GALLERY_TAB_MENU_ID, DATA_LIST_PUBLIC_UPLOAD_HEADER_ID].includes(
            block
        )
    ).length;

    const startIndex = indexes[index].start;
    const lastIndex = Math.min(indexes[index].end, list.length);
    const handleRef = index === itemActiveIndex ? data.handleRef : null;
    const gridType = (extraBlocksInHead ? index - extraBlocksInHead : index) % GALLERY_TYPES_COUNT;

    const singleRowItem =
        startIndex === lastIndex &&
        [
            DATA_LIST_EXTRABLOCKS_ID,
            DATA_LIST_BREADCRUMBS_ID,
            DATA_LIST_STORIES_WIDGET_ID,
            DATA_LIST_GALLERY_TAB_MENU_ID,
            DATA_LIST_PUBLIC_UPLOAD_HEADER_ID,
        ].includes(list[startIndex]);

    const currentAlbum = useSelector(getCurrentAlbum);
    const isAlbumExtraBlocks = currentAlbum && DATA_LIST_EXTRABLOCKS_ID === list[startIndex];

    const isBreadCrumbs = startIndex === lastIndex && list[startIndex] === DATA_LIST_BREADCRUMBS_ID;

    const content: (JSX.Element | null)[] = [];

    const renderItemHelper = useCallback(
        (index, gridIndex) => {
            const key = String(typeof list[index] === 'string' ? list[index] : list[index].id);

            return (
                <div
                    key={key + index}
                    className={classNames(styles.colItem, {
                        [styles.singleRow]: singleRowItem,
                        // не применяем стиль для хлебных крошек, т.к заголовок начинает "прыгать" по вертикали для разных видов отображения
                        [styles.compensateScroll]: singleRowItem && !isBreadCrumbs,
                    })}
                >
                    {renderItem(list[index], { index: index - extraBlocksInHead, thumbType: getThumbTypeForGallery(gridType, gridIndex) })}
                </div>
            );
        },
        [list[index], renderItem, gridType]
    );

    for (let i = startIndex; i <= lastIndex; i++) {
        content.push(renderItemHelper(i, i - startIndex));
    }

    // CLOUDWEB-18008: Выравниваем блок с футером в низ экрана для Альбомов
    return (
        <div
            className={classNames({
                [styles.extraBlockRow]: isAlbumExtraBlocks,
            })}
            style={style}
            ref={handleRef}
        >
            <div
                className={classNames(styles.galleryRow, styles.compensateScroll, {
                    [styles[`galleryRow_grid_${gridType}`]]: true,
                    [styles.singleRow]: singleRowItem,
                    [styles.extraBlockGallaryRow]: isAlbumExtraBlocks,
                })}
            >
                {content}
            </div>
        </div>
    );
}, areEqual);
