import { moreBenefitsInExpensiveTariffs } from 'reactApp/appHelpers/featuresHelpers';
import { litresTariffIdsListCommon } from 'reactApp/appHelpers/featuresHelpers/features/litres';
import { features } from 'reactApp/constants/products/features';
import type { IFeatureData } from 'reactApp/constants/products/features.types';
import { createQuotaFeaturesListByProductSpace } from 'reactApp/constants/products/quotaFeatures';
import type { Product } from 'reactApp/types/Billing';
import type { TariffFeatureListItemType } from 'reactApp/ui/TariffCardNew/components/TariffFeatureList.types';
import { isMonth6Period } from 'reactApp/utils/Period';

// tempexp_16800-start
type FeaturesBySpace = { variant: 'all' | 'all-active'; shortText: boolean };

export const getFeaturesBySpace = (): FeaturesBySpace | undefined => {
    if (moreBenefitsInExpensiveTariffs === 'c' || moreBenefitsInExpensiveTariffs === 'd') {
        return { shortText: true, variant: 'all' };
    }
    return;
};
// tempexp_16800-end

const getProductFeatures = (product: Product, compactList: boolean, featuresBySpace?: FeaturesBySpace) => {
    if (product.isMbUser) {
        return features.quotaProBaseLanding;
    }

    if (product.isBase) {
        return compactList ? features.freeQuotaCompact : features.singleQuotaBase;
    }

    if (product.isCheapest) {
        return features.singleQuotaCheapest;
    }

    /* tempexp_17509-start */
    if (litresTariffIdsListCommon.includes(product.id)) {
        return features.litresFeatures;
    }
    /* tempexp_17509-end */

    if (product.isSingleQuota) {
        if (product.isProfessional && product.isDisko) {
            if (isMonth6Period(product.period)) {
                return features.quotaProfessional_6m;
            }
            return features.quotaProfessional;
        }
        // tempexp_16800-start
        if (featuresBySpace) {
            return createQuotaFeaturesListByProductSpace(product, featuresBySpace.variant, featuresBySpace.shortText);
        }
        // tempexp_16800-end
        return compactList ? features.quotaCompact : features.quotaRegular;
    } else if (product.isProfessional) {
        if (isMonth6Period(product.period)) {
            return features.professional_new6M;
        }
        return features.professional_new;
    }
    return features.paidWithIcon;
};

/**
 * Хук для определения какой список фичей будут отображаться в карточке продукта
 * @param product
 */
export const useFeatures = (product: Product, enableJsxText = true, compactList = false, featuresBySpace?: FeaturesBySpace) => {
    const productFeatures = getProductFeatures(product, compactList, featuresBySpace);

    return useFeatureData(productFeatures, enableJsxText);
};

export const useFeatureData = (featureData: IFeatureData[], enableJsxText = true) => {
    const { featureList, footerFeatureList } = featureData.reduce(
        (acc, feature) => {
            const listItem = {
                key: feature.key,
                text: enableJsxText && feature.textJsx ? feature.textJsx : feature.text,
                icon: feature.icon,
                onClick: feature.onClick,
            };

            if (feature.delimiters) {
                acc.footerFeatureList.push(listItem);
            } else {
                acc.featureList.push(listItem);
            }

            return acc;
        },
        {
            featureList: <TariffFeatureListItemType[]>[],
            footerFeatureList: <TariffFeatureListItemType[]>[],
        }
    );

    return [featureList, footerFeatureList.length ? footerFeatureList : undefined] as const;
};
