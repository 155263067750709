import { getFeature } from 'Cloud/Application/FeaturesEs6';

interface PromoInstance {
    enabled: boolean;
    closeLimit: number;
    showLimit: number;
    showInterval: number;
}

interface NewPromo {
    PROMO: Record<string, PromoInstance>;
    SHOW_INTERVAL: number;
    DISABLED: boolean;
    FORCE_SHOW?: string; // PROMO ID
}

export const newPromoFeature: NewPromo = getFeature('new-promo-features');
