/* eslint-disable sonarjs/no-duplicate-string */
import { Icon16Done, Icon16KeyOutline, Icon16ShoppingCartOutline } from '@vkontakte/icons';
import { ReactComponent as ArrowBlueDownIcon } from 'img/icons/arrow-blue-down.svg';
import { ReactComponent as ArrowBlueUpIcon } from 'img/icons/arrow-blue-up.svg';
import { ReactComponent as DiskoIcon } from 'img/icons/disko.svg';
import { ReactComponent as HistoryIcon } from 'img/icons/history.svg';
import { ReactComponent as NoadsIcon } from 'img/icons/noads.svg';
import { ReactComponent as UploadIcon } from 'img/icons/uploads2.svg';
import { ReactComponent as VkMusicIcon } from 'img/icons/vk_music.svg';
import React from 'react';
import { isFamilySubsInTariff } from 'reactApp/appHelpers/featuresHelpers';
import type { IFeatureData } from 'reactApp/constants/products/features.types';
import { templateFeatureIcons } from 'reactApp/constants/products/templateFeatureIcons';
import { templateFeatures } from 'reactApp/constants/products/templateFeatures';
import { EPartner } from 'reactApp/modules/subscriptions/subscriptions.types';
import { MailIcon, TrashbinIcon } from 'reactApp/ui/VKUIIcons';

import { templateFeatureActions } from './templateFeatureActions';

const { vkui16Done, vkui16Cancel, mark12x11, mark13x11 } = templateFeatureIcons;

export const baseFeatures: Record<string, IFeatureData[]> = {
    noads: [
        {
            text: 'Без рекламы',
            key: 'noads',
            icon: mark12x11,
        },
    ],
    noadsPro: [
        {
            text: 'Без рекламы',
            key: 'noads',
            icon: <NoadsIcon width={16} height={16} />,
        },
    ],
    paidFeatures: [
        {
            text: 'Версионирование',
            key: 'versions',
            icon: mark12x11,
        },
    ],
    upload: [
        {
            text: 'Загрузка файлов до\u00A0100\u00A0ГБ',
            key: 'upload',
            icon: mark12x11,
        },
    ],
    autodelete: [
        {
            text: 'Автоудаление папок',
            key: 'autodelete',
            icon: mark12x11,
        },
    ],
    baseUpload: [
        {
            text: 'Загрузка файлов до\u00A02\u00A0ГБ',
            key: 'baseUpload',
            icon: mark12x11,
        },
    ],
    corpUpload: [
        {
            text: 'Загрузка файлов до\u00A0100\u00A0ГБ',
            key: 'corpUpload',
            icon: mark12x11,
        },
    ],
    [EPartner.sferum]: [
        {
            text: 'Загрузка файлов до\u00A032\u00A0ГБ',
            key: 'sferumUpload',
            icon: mark12x11,
        },
    ],
    [EPartner.mail]: [
        {
            text: 'Загрузка файлов до\u00A032\u00A0ГБ',
            key: 'vkComboUpload',
            icon: mark12x11,
        },
    ],
    giftsOpen: [
        {
            text: 'Плюс подарки',
            key: 'giftsOpen',
            icon: <ArrowBlueDownIcon width={11} height={7} />,
        },
    ],
    giftsClose: [
        {
            text: 'Плюс подарки',
            key: 'giftsClose',
            icon: <ArrowBlueUpIcon width={11} height={7} />,
        },
    ],
    disko: [
        templateFeatures.noads,
        {
            text: 'Загрузка файлов до\u00A032\u00A0ГБ',
            key: 'upload',
            icon: mark12x11,
        },
        {
            text: 'Автоудаление папок',
            key: 'autodelete',
            icon: mark12x11,
        },
        {
            text: '30 дней истории изменений файлов',
            key: 'hist',
            icon: <HistoryIcon width={22} height={22} />,
        },
        {
            text: 'Доступ ко\u00A0всем возможностям Диск&#8209;О:',
            key: 'disko',
            icon: <DiskoIcon width={23} height={17} />,
        },
    ],
    modalPro: [
        templateFeatures.noads,
        templateFeatures.upload100,
        templateFeatures.foldersAutodelete,
        templateFeatures.history60,
        templateFeatures.diskoAllColored,
        templateFeatures.bonuses,
    ],
    singleQuota: [
        templateFeatures.noadsMailCloud,
        templateFeatures.supportFast,
        templateFeatures.upload100Cloud,
        templateFeatures.send100Mail,
        templateFeatures.foldersAutodelete,
    ],
    singleQuotaFamily: [
        templateFeatures.noadsMailCloud,
        templateFeatures.familyShare,
        templateFeatures.upload100Cloud,
        templateFeatures.send100Mail,
        templateFeatures.foldersAutodelete,
        templateFeatures.supportPriority,
    ],
    singleQuotaBase: [
        {
            text: 'Без рекламы в Почте и Облаке',
            key: 'ad',
            icon: vkui16Cancel,
        },
        {
            text: 'Загрузка файлов до\u00A0100\u00A0ГБ в Облако',
            key: 'upload',
            icon: vkui16Cancel,
        },
        {
            text: 'Отправка файлов до\u00A0100\u00A0ГБ в письмах',
            key: 'sending',
            icon: vkui16Cancel,
        },
        {
            text: 'Автоудаление папок',
            key: 'autodelete',
            icon: vkui16Cancel,
        },
        {
            text: 'Приоритетная поддержка',
            key: 'support',
            icon: vkui16Cancel,
        },
    ],
    singleQuotaPro: [
        templateFeatures.noadsMailCloud,
        isFamilySubsInTariff && templateFeatures.familyShare,
        templateFeatures.supportFast,
        templateFeatures.upload100Cloud,
        templateFeatures.send100Mail,
        templateFeatures.foldersAutodelete,
        templateFeatures.history60,
        templateFeatures.diskoAll,
        templateFeatures.bonuses,
    ],
    // TODO выпилить когда полностью перейдем на TariffSectionNew
    professional: [
        {
            text: 'Без рекламы',
            key: 'noads',
            icon: <NoadsIcon width={18} height={18} />,
        },
        {
            text: 'Загружайте файлы до\u00A0100\u00A0ГБ\nбез\u00A0ограничений',
            key: 'gb100',
            icon: <UploadIcon width={20} height={21} />,
        },
        {
            text: 'Автоудаление папок',
            key: 'autodelete',
            icon: <TrashbinIcon />,
        },
        {
            text: '60 дней истории изменений файлов',
            key: 'hist',
            icon: <HistoryIcon width={22} height={22} />,
        },
        {
            text: 'Доступ ко\u00A0всем возможностям <span style="color: #0470ff">Диск&#8209;О:</span>',
            key: 'disko',
            icon: <DiskoIcon width={23} height={17} />,
            onClick: templateFeatureActions.openDisko,
        },
        {
            text: 'VK Музыка на 3\u00A0месяца\u00A0в подарок!',
            key: 'combo',
            icon: <VkMusicIcon width={24} height={24} />,
        },
    ],
    // TODO выпилить когда полностью перейдем на TariffSectionNew
    professional6M: [
        {
            text: 'Без рекламы',
            key: 'noads',
            icon: <NoadsIcon width={18} height={18} />,
        },
        {
            text: 'Загружайте файлы до\u00A0100\u00A0ГБ<br />без\u00A0ограничений',
            key: 'gb100',
            icon: <UploadIcon width={20} height={21} />,
        },
        {
            text: '60 дней истории изменений файлов',
            key: 'hist',
            icon: <Icon16Done fill="var(--vkui--color_background_accent)" width={22} height={22} />,
        },
        {
            text: 'Доступ ко\u00A0всем возможностям <span style="color: #0470ff">Диск-О:</span>',
            key: 'disko',
            icon: <DiskoIcon width={23} height={17} />,
            onClick: templateFeatureActions.openDisko,
        },
        {
            text: 'VK Музыка на 1\u00A0месяц\u00A0в подарок!',
            key: 'combo',
            icon: <VkMusicIcon width={24} height={24} />,
        },
    ],
    professional_new: [
        {
            text: 'Без рекламы',
            key: 'noads',
            icon: <Icon16Done fill="var(--vkui--color_background_accent)" width={16} height={16} />,
        },
        {
            text: 'Загружайте файлы до\u00A0100\u00A0ГБ',
            key: 'gb100',
            icon: <Icon16Done fill="var(--vkui--color_background_accent)" width={16} height={16} />,
        },
        {
            text: '60 дней истории изменений файлов',
            key: 'hist',
            icon: <Icon16Done fill="var(--vkui--color_background_accent)" width={16} height={16} />,
        },
        {
            text: 'Автоудаление папок',
            key: 'autodelete',
            icon: <Icon16Done fill="var(--vkui--color_background_accent)" width={16} height={18} />,
        },
        {
            key: 'disko',
            text: 'Лицензия Диск-О: на год в подарок',
            icon: <DiskoIcon width={16} height={16} />,
            onClick: templateFeatureActions.openDisko,
        },
        {
            text: 'VK Музыка бесплатно на 3\u00A0месяца',
            key: 'combo',
            icon: <VkMusicIcon width={24} height={24} />,
            delimiters: true,
        },
    ],
    professional_new6M: [
        {
            text: 'Без рекламы',
            key: 'noads',
            icon: <Icon16Done fill="var(--vkui--color_background_accent)" width={16} height={16} />,
        },
        {
            text: 'Загружайте файлы до\u00A0100\u00A0ГБ',
            key: 'gb100',
            icon: <Icon16Done fill="var(--vkui--color_background_accent)" width={16} height={16} />,
        },
        {
            text: '60 дней истории изменений файлов',
            key: 'hist',
            icon: <Icon16Done fill="var(--vkui--color_background_accent)" width={16} height={16} />,
        },
        {
            text: 'Автоудаление папок',
            key: 'autodelete',
            icon: <Icon16Done fill="var(--vkui--color_background_accent)" width={16} height={18} />,
        },
        {
            text: 'Лицензия Диск-О: на 6\u00A0месяцев в подарок',
            key: 'disko',
            icon: <DiskoIcon width={16} height={16} />,
            onClick: templateFeatureActions.openDisko,
        },
        {
            text: 'VK Музыка бесплатно на 1\u00A0месяц',
            key: 'combo',
            icon: <VkMusicIcon width={24} height={24} />,
            delimiters: true,
        },
    ],
    capsule1st: [
        {
            text: '1 ТБ в Облаке на год',
            key: 'year1',
            icon: vkui16Done,
        },
        {
            text: 'Без рекламы',
            key: 'noads',
            icon: vkui16Done,
        },
        {
            text: 'Загружайте файлы до 32 ГБ',
            key: 'big',
            icon: vkui16Done,
        },
        {
            text: '30 дней истории изменений',
            key: 'hist',
            icon: vkui16Done,
        },
        {
            text: 'Автоудаление папок',
            key: 'autodelete',
            icon: vkui16Done,
        },
        {
            text: 'Звонки с помощью Капсулы',
            key: 'vk',
            icon: vkui16Done,
        },
        {
            text: 'Персональные плейлисты',
            key: 'playlist',
            icon: vkui16Done,
        },
        {
            text: 'Детские сказки от голосового помощника Маруси',
            key: 'music',
            icon: vkui16Done,
        },
    ],

    capsule2nd: [
        {
            text: 'Покупка осуществляется на сайте<br />capsula.mail.ru',
            key: 'buy',
            icon: <Icon16ShoppingCartOutline />,
        },
        {
            text: 'После покупки на указанную почту<br />придёт письмо с промокодом на 1 ТБ<br />в Облаке',
            key: 'promo',
            icon: <MailIcon />,
        },
        {
            text: 'При активации промокода привязка<br />карты обязательна!',
            key: 'card',
            icon: <Icon16KeyOutline />,
        },
    ],

    business: [
        {
            text: 'Оплата по счету для юрлиц',
            key: 'business',
            icon: mark13x11,
        },
        {
            text: 'Любой объем Облака по запросу',
            key: 'space',
            icon: mark13x11,
        },
        {
            text: 'Без рекламы',
            key: 'noads',
            icon: mark13x11,
        },
        {
            text: 'Автоудаление папок',
            key: 'autodelete',
            icon: mark13x11,
        },
        {
            text: 'Загружайте файлы любого размера без ограничений',
            key: 'nolimits',
            icon: mark13x11,
        },
        {
            text: '60 дней истории изменения файлов',
            key: 'hist',
            icon: mark13x11,
        },
    ],

    paidWithIcon: [
        {
            text: 'Без рекламы',
            key: 'noads',
            icon: <Icon16Done fill="var(--vkui--color_background_accent)" width={16} height={16} />,
        },
        {
            text: 'Загрузка файлов до\u00A032\u00A0ГБ',
            key: 'upload',
            icon: <Icon16Done fill="var(--vkui--color_background_accent)" width={14} height={15} />,
        },
        {
            text: 'Автоудаление папок',
            key: 'autodelete',
            icon: <Icon16Done fill="var(--vkui--color_background_accent)" width={16} height={18} />,
        },
        {
            text: 'Версионирование',
            key: 'version',
            icon: <Icon16Done fill="var(--vkui--color_background_accent)" width={16} height={16} />,
        },
    ],

    action: [
        {
            text: 'Без рекламы',
            key: 'noads',
            icon: vkui16Done,
        },
        {
            text: 'Загрузка файлов до\u00A032\u00A0ГБ',
            key: 'upload',
            icon: vkui16Done,
        },
        {
            text: 'Автоудаление папок',
            key: 'autodelete',
            icon: vkui16Done,
        },
        {
            text: 'Версионирование',
            key: 'version',
            icon: vkui16Done,
        },
    ],
    overquota: [
        {
            text: 'Ваши файлы остаются в сохранности',
            key: 'save',
            icon: vkui16Done,
        },
        {
            text: 'Загрузка файлов до\u00A0100\u00A0ГБ',
            key: 'upload',
            icon: vkui16Done,
        },
        {
            text: 'Автоудаление папок',
            key: 'autodelete',
            icon: vkui16Done,
        },
        {
            text: 'Никакой рекламы',
            key: 'noads',
            icon: vkui16Done,
        },
    ],
    // tempexp_17127-start
    disableAds: [templateFeatures.noadsMailCloud, templateFeatures.send100, templateFeatures.letterCancel],
    // tempexp_17127-end
    sharedPublicTheme: [templateFeatures.noadsMailCloud, templateFeatures.send100, templateFeatures.familyAdd3],
    // tempexp_16598-start
    cheapest: [templateFeatures.noadsMailCloud, templateFeatures.upload2Cloud],
    // tempexp_16598-end
};
