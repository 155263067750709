import { createReducer } from '@reduxjs/toolkit';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getHasMoreToLoad } from 'reactApp/modules/userQuotaCleaner/helpers/getHasMoreToLoad';
import { mergeItemsList } from 'reactApp/modules/userQuotaCleaner/helpers/mergeItemsList';
import { type State, QuotaCleanerYears } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.types';

import { transformGroup, transformList, transformYearGroupConfig } from './helpers/transformList';
import {
    deleteUserQuotaCleanerItemsFromStore,
    loadUserQuotaCleanerListError,
    loadUserQuotaCleanerListSuccess,
    loadUserQuotaCleanerStart,
    loadUserQuotaGroupError,
    loadUserQuotaGroupSuccess,
    prepareUserQuotaCleanerParams,
    resetUserQuotaCurrentGroup,
    setCloudRemove,
    setCurrentGroup,
    setIsItemsDeleteError,
    setIsItemsDeleteProcess,
    setMessagesRemove,
    setNeedsGroupReload,
    setSuccessMovedToTrash,
    setTrashFilesIds,
    setYearGroupConfig,
    setYearGroupsConfig,
    startLoadingGroup,
    toggleYearFilterOpen,
} from './userQuotaCleaner.actions';

const initialState: State = {
    groups: [],
    groupsParams: null,
    currentGroup: null,
    isGroupsLoading: false,
    isGroupsLoaded: false,
    isGroupsLoadingError: false,
    needGroupsReload: false,
    isMessagesRemoveNeeded: false,
    isCloudRemoveNeeded: false,
    trashFilesIds: [],
    isSuccessMovedToTrash: false,
    yearGroupsConfig: {},
    isToggledYearFilterOpen: false,
    isItemsDeleteProcess: false,
    isItemsDeleteError: false,
};

export const userQuotaCleanerReducer = createReducer(initialState, {
    [loadUserQuotaCleanerListSuccess.type]: (state, action: ReturnType<typeof loadUserQuotaCleanerListSuccess>) => {
        return {
            ...state,
            groups: transformList(action.payload),
            yearGroupsConfig: transformYearGroupConfig(action.payload),
            isGroupsLoading: false,
            isGroupsLoaded: true,
        };
    },
    [prepareUserQuotaCleanerParams.type]: (state, action) => {
        return {
            ...state,
            groupsParams: action.payload,
        };
    },
    [loadUserQuotaCleanerStart.type]: (state) => {
        return {
            ...state,
            isGroupsLoading: true,
            isGroupsLoaded: false,
            isGroupsLoadingError: false,
            needGroupsReload: false,
        };
    },
    [loadUserQuotaCleanerListError.type]: (state) => {
        return {
            ...state,
            groups: [],
            isGroupsLoading: false,
            isGroupsLoadingError: true,
            isGroupsLoaded: true,
        };
    },
    [startLoadingGroup.type]: (state, action: ReturnType<typeof startLoadingGroup>) => {
        const { currentGroup } = state;
        const {
            payload: { groupId, groupType, hasYearChanged },
        } = action;
        return {
            ...state,
            currentGroup: {
                totalCount: null,
                size: null,
                list: {},
                childs: [],
                groupId,
                id: groupId,
                groupType,
                storage: EStorageType.quotaCleaner,
                ...(hasYearChanged ? {} : currentGroup || {}),
                isLoading: true,
                isLoaded: false,
            },
        };
    },
    [loadUserQuotaGroupSuccess.type]: (state, action: ReturnType<typeof loadUserQuotaGroupSuccess>) => {
        const { currentGroup, yearGroupsConfig } = state;
        const {
            payload: { data, offset, limit, maxOffset, isGroupReset },
        } = action;

        const oldGroup = isGroupReset ? null : currentGroup;
        const newGroup = transformGroup(data);
        const { list, childs, totalCount, groupId, size } = newGroup;
        const newList = { ...(oldGroup?.list ?? {}), ...list };

        // сливаем текущий список элементов с новым
        const mergedChilds = mergeItemsList(oldGroup?.childs ?? [], childs, { offset, limit });
        const mergedList = mergedChilds.reduce(
            (acc, id) => ({
                ...acc,
                [id]: newList[id],
            }),
            {}
        );

        const copyYearGroupsConfig = JSON.parse(JSON.stringify(yearGroupsConfig));
        if (copyYearGroupsConfig[groupId]) {
            const { currentYear } = copyYearGroupsConfig[groupId];
            if (currentYear === QuotaCleanerYears.All) {
                copyYearGroupsConfig[groupId][currentYear] = {
                    size,
                    totalCount,
                };
            } else {
                copyYearGroupsConfig[groupId].config[currentYear] = {
                    size,
                    totalCount,
                };
            }
        }

        return {
            ...state,
            currentGroup: {
                ...newGroup,
                list: mergedList,
                childs: mergedChilds,
                hasMoreToLoad: getHasMoreToLoad({ currentCount: mergedChilds.length, totalCount, maxOffset, limit }),
                isLoading: false,
                isLoaded: true,
            },
            yearGroupsConfig: copyYearGroupsConfig,
        };
    },
    [loadUserQuotaGroupError.type]: (state, action: ReturnType<typeof loadUserQuotaGroupError>) => {
        const { currentGroup } = state;
        const {
            payload: { groupId, groupType },
        } = action;

        return {
            ...state,
            currentGroup: {
                totalCount: null,
                size: null,
                list: {},
                childs: [],
                groupId,
                id: groupId,
                groupType,
                storage: EStorageType.quotaCleaner,
                ...(currentGroup || {}),
                isLoaded: true,
                isLoading: false,
            },
        };
    },
    [deleteUserQuotaCleanerItemsFromStore.type]: (state, action: ReturnType<typeof deleteUserQuotaCleanerItemsFromStore>) => {
        const {
            payload: { deleteIds },
        } = action;
        const { currentGroup } = state;
        if (!currentGroup) {
            return state;
        }

        const { childs, list } = currentGroup;

        // собираем id элементов по deleteId
        const ids = currentGroup.childs.reduce((acc: string[], id) => {
            if (deleteIds.includes(list[id].deleteId)) {
                acc.push(id);
            }

            return acc;
        }, []);

        // удаляем из модели удаленные ранее элементы
        const newChilds = childs.filter((item) => !ids.includes(item));
        const newList = newChilds.reduce((acc, child) => ({ ...acc, [child]: list[child] }), {});

        return {
            ...state,
            currentGroup: {
                ...currentGroup,
                childs: newChilds,
                list: newList,
            },
        };
    },
    [resetUserQuotaCurrentGroup.type]: (state) => {
        const { yearGroupsConfig, currentGroup } = state;
        const resetedCurrentGroupYearConfig = {};

        if (currentGroup) {
            const { groupId } = currentGroup;
            const yearCurrentGroupConfig = yearGroupsConfig[groupId];

            if (yearCurrentGroupConfig) {
                resetedCurrentGroupYearConfig[groupId] = {
                    ...yearCurrentGroupConfig,
                    currentYear: QuotaCleanerYears.All,
                };
            }
        }

        return {
            ...state,
            currentGroup: null,
            yearGroupsConfig: {
                ...yearGroupsConfig,
                ...resetedCurrentGroupYearConfig,
            },
        };
    },
    [setNeedsGroupReload.type]: (state, action: ReturnType<typeof setNeedsGroupReload>) => {
        return {
            ...state,
            needGroupsReload: action.payload.value,
        };
    },
    [setMessagesRemove.type]: (state, action: ReturnType<typeof setMessagesRemove>) => {
        return {
            ...state,
            isMessagesRemoveNeeded: action.payload.value,
        };
    },
    [setCloudRemove.type]: (state, action: ReturnType<typeof setCloudRemove>) => {
        return {
            ...state,
            isCloudRemoveNeeded: action.payload.value,
        };
    },
    [setTrashFilesIds.type]: (state, action: ReturnType<typeof setTrashFilesIds>) => {
        return {
            ...state,
            trashFilesIds: action.payload.value,
        };
    },
    [setSuccessMovedToTrash.type]: (state, action: ReturnType<typeof setSuccessMovedToTrash>) => {
        return {
            ...state,
            isSuccessMovedToTrash: action.payload.value,
        };
    },
    [setCurrentGroup.type]: (state, action: ReturnType<typeof setCurrentGroup>) => {
        return {
            ...state,
            currentGroup: action.payload.value,
        };
    },
    [setYearGroupsConfig.type]: (state, action: ReturnType<typeof setYearGroupsConfig>) => {
        return {
            ...state,
            yearGroupsConfig: action.payload.value,
        };
    },
    [setYearGroupConfig.type]: (state, action: ReturnType<typeof setYearGroupConfig>) => {
        const {
            payload: { groupId, value },
        } = action;
        return {
            ...state,
            yearGroupsConfig: {
                ...state.yearGroupsConfig,
                [groupId]: value,
            },
        };
    },
    [toggleYearFilterOpen.type]: (state, action: ReturnType<typeof toggleYearFilterOpen>) => {
        return {
            ...state,
            isToggledYearFilterOpen: action.payload.value ?? !state.isToggledYearFilterOpen,
        };
    },
    [setIsItemsDeleteProcess.type]: (state, action: ReturnType<typeof setIsItemsDeleteProcess>) => {
        return {
            ...state,
            isItemsDeleteProcess: action.payload.value,
        };
    },
    [setIsItemsDeleteError.type]: (state, action: ReturnType<typeof setIsItemsDeleteError>) => {
        return {
            ...state,
            isItemsDeleteError: action.payload.value,
        };
    },
});
