import React, { type FC } from 'react';

import styles from './StoryMenuInfo.css';

interface Props {
    title?: string;
    subTitle?: string;
}

export const StoryMenuInfo: FC<Props> = ({ title, subTitle }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>{title}</div>
            <div className={styles.subTitle}>{subTitle}</div>
        </div>
    );
};
