import React, { memo, useCallback, useEffect } from 'react';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './UnlinkCardConfirmModal.css';
import type { UnlinkCardConfirmModalProps } from './UnlinkCardConfirmModal.types';

const BUTTONS = [EButtonType.accept, EButtonType.action];

const renderHeader = () => 'Без карты подписка не продлится';
const renderContent = () => {
    return (
        <ul className={styles.list}>
            <li>Пропадут дополнительные гигабайты</li>
            <li>Нельзя будет загружать тяжёлые файлы</li>
            <li>Вернётся реклама в Почте и Облаке</li>
        </ul>
    );
};

const POPUP_ANALYTICS_ACTION = 'modal-stop-delete-card';

export const UnlinkCardConfirmModal = memo<UnlinkCardConfirmModalProps>(({ onUnlink, onClose }) => {
    useEffect(() => {
        sendPaymentGa({ action: POPUP_ANALYTICS_ACTION, label: 'show' });
    }, []);

    const handleSuccess = useCallback(() => {
        sendPaymentGa({ action: POPUP_ANALYTICS_ACTION, label: 'continue' });
        onClose?.();
    }, [onClose]);

    const handleAction = useCallback(() => {
        sendPaymentGa({ action: POPUP_ANALYTICS_ACTION, label: 'delete' });
        emitAnalyticEvent(AnalyticEventNames.CARD_REMOVE_CONFIRM);
        onUnlink();
        onClose?.();
    }, [onUnlink, onClose]);

    const handleClose = useCallback(() => {
        sendPaymentGa({ action: POPUP_ANALYTICS_ACTION, label: 'close' });
        onClose?.();
    }, [onClose]);

    return (
        <BaseConfirmDialog
            renderHeader={renderHeader}
            renderContent={renderContent}
            successButtonText="Оставить карту"
            onSuccess={handleSuccess}
            onAction={handleAction}
            actionButtonText="Отвязать"
            onClose={handleClose}
            dataQAId="unlink-card-confirm-modal"
            buttons={BUTTONS}
            buttonTheme="vk"
            isDarkTheme={false}
            size="tiny"
        />
    );
});

UnlinkCardConfirmModal.displayName = 'UnlinkCardConfirmModal';
