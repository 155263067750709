import type { Method } from 'lib/axios';
import { APICallV4 } from 'reactApp/api/APICall';

export interface PublicInfoApiCallData {
    count_downloads_total?: number;
    count_downloads_left?: number;
    pin?: string;
    hidden?: boolean;
    theme_id?: number;
    is_blocked?: boolean;
    is_blocked_for?: number;
}

export class PublicInfoAPICall extends APICallV4<PublicInfoApiCallData> {
    _method = 'public/info';
    _type: Method = 'get';
    _retriesCount = 0;
}
