import { bytesToNDigits } from '@mail/cross-sizes-utils';
import classNames from 'clsx';
import { ReactComponent as ConnectRestoredIcon } from 'img/icons/uploader/connectOk.svg';
import { ReactComponent as UploadOkIcon } from 'img/icons/uploader/uploadOk.svg';
import { ReactComponent as UploadSlowIcon } from 'img/icons/uploader/uploadSlow.svg';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { UPLOAD_PROMO_LINK } from 'reactApp/modules/features/features.helpers';
import { EInfoTypes } from 'reactApp/modules/uploadList/uploadList.model';
import { getLastUploadTime, getTotalCount } from 'reactApp/modules/uploadList/uploadList.selectors';
import styles from 'reactApp/ui/UploadList/Problems/Problems.css';
import { formatDuration } from 'reactApp/utils/duration';

enum EBackColors {
    green = 'green',
    yellow = 'yellow',
}

export const InfoComponent = memo(({ type }: { type: EInfoTypes }) => {
    let icon, title, description, linkText, backColor;

    const { totalSize } = useSelector(getTotalCount);
    const uploadTime = useSelector(getLastUploadTime) || 0;
    const linkUrl = UPLOAD_PROMO_LINK;

    switch (type) {
        case EInfoTypes.networkRestored:
            icon = <ConnectRestoredIcon fill={isRebranding ? 'var(--vkui--color_icon_accent)' : '#005FF9'} />;
            title = 'На чём мы остановились?';
            description = 'Загрузка продолжается с того момента, на котором пришлось остановиться';
            linkText = 'Подробнее об умной загрузке';
            break;
        case EInfoTypes.complete:
            backColor = EBackColors.green;
            icon = <UploadOkIcon />;
            title = 'Теперь ещё быстрее';
            description = `Мы загрузили ${bytesToNDigits(totalSize, 3).value} за ${formatDuration(
                Math.max(uploadTime, 1000) / 1000,
                false,
                true
            )} благодаря умной загрузке`;
            linkText = 'Как работает умная загрузка';
            break;
        case EInfoTypes.canceled:
            backColor = EBackColors.yellow;
            icon = <UploadOkIcon />;
            title = `Загрузка файлов была отменена`;
            break;
        case EInfoTypes.lowSpeed:
            backColor = EBackColors.yellow;
            icon = <UploadSlowIcon />;
            title = 'Медленное соединение';
            description = `Файлы могут загружаться дольше обычного`;
            linkText = 'Подробнее об умной загрузке';
            break;
    }

    return (
        <div className={classNames(styles.problem, styles.problem_info, { [styles[`problem_${backColor}`]]: !!backColor })}>
            <div className={styles.icon}>{icon}</div>
            <div className={classNames({ [styles.wrapperText]: true })}>
                <div>{title}</div>
                <div>{description}</div>
                {!!linkText && !!linkUrl && (
                    <div className={styles.infoLink}>
                        <a href={linkUrl} rel="noopener noreferrer" target="_blank">
                            {linkText}
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
});

InfoComponent.displayName = 'InfoComponent';
