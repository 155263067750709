import { Icon20CheckAlt } from '@vkontakte/icons';
import { Caption, Headline, Spacing } from '@vkontakte/vkui';
import classNames from 'clsx';
import { once } from 'ramda';
import React, { type ReactElement, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { threeMonthsPeriodTariffsAndroid, touchTariffMinSize } from 'reactApp/appHelpers/featuresHelpers';
import { blackFriday2024 } from 'reactApp/appHelpers/featuresHelpers/features/blackFriday2024';
import { cheapTariffOnlyYearAndroid } from 'reactApp/appHelpers/featuresHelpers/features/cheapTariffOnlyYear';
import { crossedOutPriceAlternate } from 'reactApp/appHelpers/featuresHelpers/features/crossedOutOrice';
import { cyberMonday2025 } from 'reactApp/appHelpers/featuresHelpers/features/cyberMonday2025';
import { useMergeRefs } from 'reactApp/hooks/useMergeRefs';
import { getFeatureABSubNewCard } from 'reactApp/modules/features/features.selectors';
import { getPaySource } from 'reactApp/modules/payment/payment.analytics';
import { AUTOUPLOAD_10TB_REGEX, SPACE_SIZES } from 'reactApp/modules/products/products.helpers';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { TABS_MAP } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage.constants';
import { AUTOUPLOAD_TOOLTIP_ID_MOBILE, AutouploadTooltip } from 'reactApp/sections/QuotaLanding/AutouploadTooltip/AutouploadTooltip';
import { useIntersecObsrWithParams } from 'reactApp/sections/QuotaLanding/hooks/useIntersecObsrWithParams';
import { useLitresTooltip } from 'reactApp/sections/QuotaLanding/LitresTooltip/hooks/useLitresTooltip';
import { LITRES_TOOLTIP_ID_MOBILE, LitresTooltip } from 'reactApp/sections/QuotaLanding/LitresTooltip/LitresTooltip';
import { sendQuotaBlockViewDWH, sendXrayWithPlatform } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { TariffInfoBlock } from 'reactApp/sections/QuotaLanding/TariffsPlans/TariffPlansCard/TariffPlansCard';
import { Button } from 'reactApp/ui/Button/Button';
import { useYearSaving } from 'reactApp/ui/TariffSectionNew/hooks/useYearSaving';
import { ChevronDownIcon } from 'reactApp/ui/VKUIIcons';
import { sendIosFavorableTariffsAnalytics, sendProfitableTariffsFirstAnalytics } from 'reactApp/utils/analytics';
import { getFirstSomePeriodsAsWord, getPeriodName, isMonth3Period, isMonthPeriod, isYearPeriod, PERIODS } from 'reactApp/utils/Period';
import { formatPrice } from 'reactApp/utils/priceHelpers';

import styles from './TariffCard.css';
import { getButtonText, getDiscountText, getFeatureList, getYearPriceWithMonthProduct } from './TariffCard.helpers';
import type { IProps, ITariffCardProduct } from './TariffCard.types';

export const TariffCard = memo(function TariffCard(props: IProps): ReactElement | null {
    // tempexp_13600-next-line countSnapshotsBySpace, openAbCard
    const {
        countSnapshotsBySpace,
        openAbCard,
        space,
        products,
        onClick,
        fullPrice,
        oldPrice,
        fullPricePeriod,
        hasOldPriceYear,
        isOldCard = false,
        giftSpace,
        infoText,
        theme,
        isSingleQuota,
        alwaysExpand,
        showPriceByMonth,
        skipBenefitsFeature,
        setCarouselLoaded,
        headerHeight = 0,
        crossFullPrice,
        // tempexp_17020-start
        showPriceByYear = false,
        // tempexp_17020-end
        isCardActive,
        horizontalMobile,
        skipDiscountPrice,
        isRebrandingQuotaLanding,
        isRebrandingSubscription = false,
        isFromLitres,
        isTopCard,
        // tempexp_17406-next-line
        autouploadTooltip = false,
    } = props;

    const isOneProduct = products.length === 1;

    // tempexp_13600-start
    const isAbExpNewCards13600 = useSelector(getFeatureABSubNewCard) && !isOldCard;
    const [openSpoiler, setOpenSpoiler] = useState(openAbCard || alwaysExpand);

    // tempexp_17906-next-line
    const yearProduct = products.find((p) => isYearPeriod(p.period));

    const features = getFeatureList(
        countSnapshotsBySpace,
        isSingleQuota,
        skipBenefitsFeature,
        isRebrandingQuotaLanding,
        isRebrandingSubscription,
        // tempexp_17509-next-line
        isFromLitres,
        // tempexp_17906-start
        yearProduct,
        onClick
        // tempexp_17906-end
    );
    const storage = useSelector(getCurrentStorage);
    const { isSubscription } = getStorage(storage);

    const expandCard = openSpoiler || alwaysExpand;

    const location = useLocation();
    const isAction = !location.hash || location.hash === TABS_MAP.special || (location.hash && !isSubscription);

    // tempexp_13600-end

    const monthProduct = products.find((p) => isMonthPeriod(p.period));
    const [, yearSavingDiscount] = useYearSaving({
        monthProduct,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        yearProduct: products.find((p) => isYearPeriod(p.period))!,
    });

    const { isLoaded: isQuotaLoaded } = useSelector(UserQuotaSelectors.getLoadingState);

    const userQuota = useSelector(UserQuotaSelectors.getTotalOrBase);

    const isAnonymous = useSelector(UserSelectors.isAnonymous);

    // tempexp_16460-start
    const dispatch = useDispatch();
    const isAutouploadTariff = AUTOUPLOAD_10TB_REGEX.test(products[0].id) && AUTOUPLOAD_10TB_REGEX.test(products[1].id);
    const tooltipRef = useRef<HTMLDivElement | null>(null);
    const tooltipShown = dispatch(UserStorageActions.get(AUTOUPLOAD_TOOLTIP_ID_MOBILE));
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const initTooltip = showTooltip && isAutouploadTariff;
    // tempexp_16460-end

    // tempexp_17372-next-line
    const isOnlyYearTariff = cheapTariffOnlyYearAndroid && space.space === touchTariffMinSize;

    // tempexp_18276_next-line
    const yearCrossedOutPrice = crossedOutPriceAlternate && monthProduct?.price ? monthProduct?.price * 12 : oldPrice;

    const onButtonClick = useCallback(
        (product: ITariffCardProduct, products: ITariffCardProduct[]) => () => {
            sendIosFavorableTariffsAnalytics(['tariff_click']);
            sendProfitableTariffsFirstAnalytics(['tariff_click']);

            if (isMonthPeriod(product.period)) {
                const yearProduct = products?.find((p) => p.period === PERIODS.year);
                onClick(product, yearProduct, yearSavingDiscount, getPaySource('tariff_card'));
            } else {
                onClick(product, undefined, undefined, getPaySource('tariff_card'));
            }
        },
        [onClick, yearSavingDiscount]
    );

    useEffect(() => {
        /** Это сделано именно здесь для того, чтобы дожидаться рендера тарифов со всем контентом внутри
         * для корректного скролла по якорю из ссылки на лендинг единой квоты
         * В родительских элементах useEffect срабатывает слишком рано, до формирования листа тарифов */
        setCarouselLoaded?.(true);
    }, [setCarouselLoaded]);

    // tempexp_16460-start
    useEffect(() => {
        if (!tooltipShown && autouploadTooltip && isAutouploadTariff) {
            setTimeout(() => {
                setShowTooltip(true);
            }, 1500);
        }
    }, []);
    // tempexp_16460-end

    const emitEvent = useCallback(
        once(() => emitAnalyticEvent(AnalyticEventNames.MOBILE_TARIFF_SHOWN, { space })),
        []
    );
    const onIntersecting = useCallback(() => {
        if (!isSubscription) {
            sendQuotaBlockViewDWH(`tariff-${space.space}`);
            emitEvent();
            if (isAutouploadTariff) {
                sendXrayWithPlatform(['autoupload-10tb', `tariff-view-${space.space}`]);
            }
        }
    }, [isSubscription, space.space, isAutouploadTariff, emitEvent]);

    const refIntersection = useIntersecObsrWithParams({
        onIntersecting,
        isMobile: false,
        headerHeight,
    });

    /* tempexp_17509-start */
    const isRecommendedLitresCard = isFromLitres && isTopCard;
    const { litresTooltipRef, initLitresTooltip, setShowLitresTooltip } = useLitresTooltip(
        LITRES_TOOLTIP_ID_MOBILE,
        isRecommendedLitresCard
    );
    /* tempexp_17509-end */

    /* tempexp_18060-next-line */
    const isRecommendedBlackFriday2024Card = blackFriday2024 && isTopCard;
    /* tempexp_18474-next-line */
    const isRecommendedCyberMonday2025Card = cyberMonday2025 && products[0].space.original === SPACE_SIZES._4096;

    return (
        <div
            className={classNames({
                [styles.root]: true,
                [styles[`root_space_${space.space}`]]: Boolean(space.space),
                [styles[`root_theme_${theme}`]]: Boolean(theme),
                [styles[`root_old_price_year`]]: hasOldPriceYear,
                // tempexp_13600-start
                [styles.root_ab_new_cards]: isAbExpNewCards13600,
                // tempexp_13600-end
                [styles.root_action]: isAction,
                [styles.card_horizontalMobile]: horizontalMobile,
                [styles.card_active]: isCardActive,
                [styles.card_active_rebranding]: isCardActive && isRebrandingQuotaLanding,
                [styles.root_rebrandingQuotaLanding]: isRebrandingQuotaLanding,
                [styles.root_rebrandingSubscriptions]: isRebrandingSubscription,
                /* tempexp_17509-next-line */
                [styles.root_recommended]: isRecommendedLitresCard,
                [styles.root_blackFriday2024]: blackFriday2024,
                [styles.root_recommendedBlackFriday2024]: isRecommendedBlackFriday2024Card,
                [styles.root_recommendedCyberMonday2025]: isRecommendedCyberMonday2025Card,
            })}
            // tempexp_16460-next-line, tempexp_17509-next-line
            ref={useMergeRefs(refIntersection, tooltipRef, litresTooltipRef)}
        >
            {/* tempexp_17372-start */}
            {isOnlyYearTariff && (
                <div className={styles.onlyYearTariffLabel}>
                    <Headline level="2" weight="2">
                        Годовой тариф
                    </Headline>
                </div>
            )}
            {/* tempexp_17372-end */}
            {/* tempexp_13600-next-line */}
            {isAbExpNewCards13600 && !isSingleQuota && !isRebrandingSubscription && <div className={styles.bgicon_ab_new_cards} />}
            {/* tempexp_16460-start */}
            {initTooltip && <AutouploadTooltip ref={tooltipRef} onClose={setShowTooltip} />}
            {isAutouploadTariff && (
                <div className={styles.lables}>
                    <div className={styles.autouploadLabel} onClick={() => setShowTooltip(true)}>
                        +10 ТБ для телефона
                    </div>
                </div>
            )}
            {/* tempexp_16460-end */}
            {/* tempexp_17509-start */}
            {initLitresTooltip && <LitresTooltip ref={litresTooltipRef} onClose={setShowLitresTooltip} />}
            {isFromLitres && (
                <div className={styles.lables}>
                    <div
                        className={classNames(styles.litresLabel, { [styles.isRecommended]: isRecommendedLitresCard })}
                        onClick={() => setShowLitresTooltip(true)}
                    >
                        Литрес на 2 месяца
                    </div>
                </div>
            )}
            {/* tempexp_17509-end */}
            {isRecommendedBlackFriday2024Card && (
                <div className={styles.lables}>
                    <div className={styles.litresLabel}>Специальный тариф</div>
                </div>
            )}
            {isRecommendedCyberMonday2025Card && (
                <div className={styles.lables}>
                    <div className={styles.litresLabel}>Хватит на всё</div>
                </div>
            )}
            {/* tempexp_13600-next-line isAbExpNewCards13600 В эксперименте показываем новый вариант заголовка*/}
            {isAbExpNewCards13600 && (horizontalMobile || !alwaysExpand) ? (
                <div
                    onClick={() => setOpenSpoiler(!openSpoiler)}
                    className={classNames(styles.title, styles.title_ab_new_cards, {
                        [styles.title_ab_new_cards_opened]: expandCard,
                        // tempexp_17509-next-line
                        [styles.fromLitres]: isFromLitres,
                    })}
                >
                    + {space.space} {space.units}
                    {!horizontalMobile ? <ChevronDownIcon width={30} height={30} /> : null}
                </div>
            ) : (
                <div
                    className={classNames(styles.title, {
                        [styles.title_withGift]: Boolean(giftSpace),
                    })}
                >
                    <div className={styles.space}>{space.space}</div>
                    <div className={styles.unit}>{space.units}</div>
                    {giftSpace && (
                        <div className={styles.gift}>
                            <div className={styles.plus}>+</div>
                            <div className={styles.space}>{giftSpace.space}</div>
                            <div className={styles.unit}>* {giftSpace.units}</div>
                        </div>
                    )}
                </div>
            )}
            {isAbExpNewCards13600 && expandCard && isSingleQuota && (
                <TariffInfoBlock isMobile newQuota={userQuota + space.original} isSkeleton={!isAnonymous && !isQuotaLoaded} />
            )}
            {/* tempexp_13600-start Добавляем список фичей */}
            {isAbExpNewCards13600 && expandCard && (
                <div className={styles.features_ab_new_cards}>
                    {features.map((value, index) => (
                        <div
                            key={index}
                            className={classNames(styles.features_li, {
                                [styles[`features_li_space_${space.space}`]]: !!space.space,
                            })}
                        >
                            <Icon20CheckAlt />
                            <div>{value}</div>
                        </div>
                    ))}
                </div>
            )}
            {/* tempexp_13600-end */}
            {Boolean(fullPrice) && Boolean(fullPricePeriod) && fullPrice && fullPricePeriod && (
                <div className={styles.discount}>{getDiscountText(fullPrice, fullPricePeriod)}</div>
            )}
            {infoText && <div className={styles.infoText}>{infoText}</div>}
            <div className={styles.buttons}>
                {products.map((product) => {
                    // tempexp_17372-start
                    if (isOnlyYearTariff && !isYearPeriod(product.period)) {
                        return null;
                    }
                    // tempexp_17372-end

                    if (product.period === PERIODS.month && products.some((p) => p.period === PERIODS.threeMonths)) {
                        return null;
                    }

                    return (
                        <div className={styles.buttonWrapper} key={product.id}>
                            <Button
                                className={classNames(styles.button, {
                                    // tempexp_13600-next-line Делаем кнопку более белой
                                    [styles.button_ab_new_cards]: isAbExpNewCards13600,
                                    [styles.button_column]: isSingleQuota,
                                    [styles.button_action]: isAction,
                                })}
                                theme="octavius"
                                primary={product.primary}
                                onClick={onButtonClick(product, products)}
                            >
                                <div>
                                    {product.period === PERIODS.month &&
                                        crossFullPrice?.month &&
                                        product.discountPrice &&
                                        product.discountPeriod && (
                                            <span className={styles.crossedOldPrice}>{formatPrice(product.price, 'ru')} ₽</span>
                                        )}

                                    {isMonth3Period(product.period) &&
                                        threeMonthsPeriodTariffsAndroid.variant === 'b' &&
                                        !!monthProduct && (
                                            <span className={styles.crossedOldPrice}>{formatPrice(monthProduct.price, 'ru')} ₽</span>
                                        )}

                                    {getButtonText(
                                        product.discountPrice && product.discountPeriod ? product.discountPrice : product.price,
                                        product.discountPrice && product.discountPeriod ? product.discountPeriod : product.period,
                                        isOneProduct,
                                        product,
                                        showPriceByMonth ||
                                            product.period === PERIODS.threeMonths ||
                                            (isMonthPeriod(product.period) && crossFullPrice?.month) ||
                                            (Boolean(product.discountPrice) && product.period === PERIODS.year && crossFullPrice?.year)
                                    )}
                                </div>

                                {/* tempexp_17020-start */}
                                {oldPrice &&
                                    hasOldPriceYear &&
                                    !product?.hasTrial &&
                                    product.period === PERIODS.month &&
                                    !showPriceByMonth &&
                                    !crossFullPrice?.year &&
                                    showPriceByYear && (
                                        <span className={styles.oldPriceMonth}>или {getYearPriceWithMonthProduct(products)}</span>
                                    )}
                                {/* tempexp_17020-end */}

                                {yearCrossedOutPrice &&
                                    hasOldPriceYear &&
                                    !product?.hasTrial &&
                                    product.period === PERIODS.year &&
                                    (showPriceByMonth || crossFullPrice?.year ? (
                                        <>
                                            <Caption level="2">при покупке на год</Caption>
                                        </>
                                    ) : (
                                        // tempexp_16948-next-line добавлено условие !yearPriceOnMonth &&
                                        !showPriceByYear && (
                                            <span className={styles.oldPrice}>
                                                {getButtonText(yearCrossedOutPrice, product.period, isOneProduct, product)}
                                            </span>
                                        )
                                    ))}
                                {product.period === PERIODS.month &&
                                    crossFullPrice?.month &&
                                    product.discountPrice &&
                                    product.discountPeriodCount && (
                                        <>
                                            <Spacing size={2} />
                                            <Caption className={styles.priceInfo} level="2">
                                                {getFirstSomePeriodsAsWord(product.discountPeriodCount, 'm')}
                                            </Caption>
                                        </>
                                    )}

                                {isSingleQuota &&
                                    !skipDiscountPrice &&
                                    product.period === PERIODS.year &&
                                    !product.discountPrice &&
                                    !product?.hasTrial &&
                                    (showPriceByMonth ? (
                                        <>
                                            <Spacing size={2} />
                                            <Spacing size={2} />
                                            <Caption level="2">при покупке на год</Caption>
                                        </>
                                    ) : (
                                        <>
                                            <Spacing size={2} />
                                            <Caption level="2" className={styles.yearPrice}>
                                                {getYearPriceWithMonthProduct(products)}
                                            </Caption>
                                        </>
                                    ))}
                                {isMonth3Period(product.period) && (
                                    <>
                                        <Caption className={styles.threeMonthDescription} level="2">
                                            покупка на 3 месяца
                                        </Caption>
                                    </>
                                )}
                                {product.hasTrial && product.trialPeriod && (
                                    <>
                                        <Spacing size={2} />
                                        <Caption level="2">
                                            далее {formatPrice(product.discountPrice || product.price, 'ru')} ₽ в{' '}
                                            {getPeriodName(product.discountPeriod || product.period)}
                                        </Caption>
                                    </>
                                )}
                            </Button>
                            {product.saving && (
                                <div
                                    className={classNames(styles.saving, {
                                        [styles.saving_action]: isAction,
                                    })}
                                >
                                    -{product.saving}%
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
});
