import type { Product } from 'reactApp/types/Billing';
import type { ETabsName } from 'reactApp/ui/TariffSectionNew/TariffSection.constants';
import type { ITariffsByTab } from 'reactApp/ui/TariffSectionNew/TariffsSection.types';

export interface IMobilePayProps {
    isIOSPromo?: boolean;
    isAndroidPromo?: boolean;
    isMobilePayAvailable: boolean;
    isMobilePayFromGetParam: boolean;
    enabledMidasPayment?: boolean;
    isRebrandingQuotaLanding?: boolean;
}

// tempexp_16481-next-lines
export type TBuyClick = (props: { product: Product; paySource?: string; onClick?: () => void; space: number }) => void;

export enum TariffBorder {
    /* tempexp_18084-start */
    tariffPersonalGreen = 'tariffPersonalGreen',
    tariffPersonalBlue = 'tariffPersonalBlue',
    tariffPersonalPink = 'tariffPersonalPink',
    /* tempexp_18084-end */
}

export type Props = IMobilePayProps & {
    activeTab?: ETabsName;
    tariff: ITariffsByTab;
    isVisible?: boolean;
    setCarouselLoaded?: (state: boolean) => void;
    // tempexp_18090-next-line
    onTariffClick?: () => void;
};
