import { uploadingLog } from 'reactApp/modules/uploading/serviceClasses/UploadingLog';
import { uploadingService } from 'reactApp/modules/uploading/serviceClasses/UploadingService';
import type { cancelUploading } from 'reactApp/modules/uploading/uploading.module';
import { clearContinueUploadFilesAction } from 'reactApp/modules/uploadList/continueUpload/continueUpload.module';
import { sortFilesByError } from 'reactApp/modules/uploadList/uploadList.module';
import { put } from 'redux-saga/effects';

export function cancelUploadingFile(id: string | string[]) {
    uploadingService.cancel(id);
}

export function* handleCancelUploading(action: ReturnType<typeof cancelUploading>) {
    if (action.payload?.fileId) {
        cancelUploadingFile(action.payload.fileId);
        return;
    }

    const cancelInfo = uploadingService.cancelAll();

    yield put(sortFilesByError());

    yield put(clearContinueUploadFilesAction());

    uploadingLog.info({
        event: 'cancel all',
        details: cancelInfo.canceled,
        radarName: 'cancel_web-client_all',
        radarValue: cancelInfo.canceled,
    });
}
