import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_B2B_BIZ_USER, IS_ONPREMISE } from 'reactApp/appHelpers/configHelpers';
import { seoEnable } from 'reactApp/appHelpers/featuresHelpers/features/seoEnable';
import { historyPush } from 'reactApp/modules/router/router.module';
import { setCurrentSharedTab } from 'reactApp/modules/shared/shared.module';
import { getCurrentSharedTab } from 'reactApp/modules/shared/shared.selectors';
import { SharedTab } from 'reactApp/modules/shared/shared.type';
import { PageTabMenu } from 'reactApp/ui/PageTabMenu/PageTabMenu';

import styles from './SharedTabs.css';

const TABS = [
    {
        value: SharedTab.links,
        name: 'Вы поделились',
    },
    {
        value: SharedTab.incoming,
        name: 'С вами поделились',
    },
    {
        value: SharedTab.autodelete,
        name: 'Автоудаляемые',
    },
];

const INDEXED_TAB = {
    value: SharedTab.indexed,
    name: 'В поисковиках',
};

export const SharedTabs = memo(({ tab = SharedTab.links }: { tab?: SharedTab }) => {
    const dispatch = useDispatch();
    const currentTab = useSelector(getCurrentSharedTab);

    useEffect(() => {
        if (currentTab === tab) {
            return;
        }

        dispatch(setCurrentSharedTab(tab));
    }, [currentTab, tab]);

    const handleOnClickTab = useCallback(
        (value: SharedTab) => {
            dispatch(historyPush({ id: `shared/${value}` }));
            dispatch(setCurrentSharedTab(value));
        },
        [dispatch]
    );

    return (
        <div className={styles.root}>
            <PageTabMenu
                current={tab}
                tabs={seoEnable && !IS_ONPREMISE && !IS_B2B_BIZ_USER ? [...TABS, INDEXED_TAB] : TABS}
                onClick={handleOnClickTab}
                changeHash={false}
            />
        </div>
    );
});

SharedTabs.displayName = 'SharedTabs';
