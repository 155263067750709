import pausedSubLogoRebranding from 'img/subscriptions/pause-subscription-logo-rebranding.png';
import { cheapestProductIDs } from 'reactApp/modules/products/products.const';
import { EPromoSubscriptionType } from 'reactApp/modules/promoTariffs/promoTariffs.types';
import { type ESubscriptionsTypes, type ISubscription, EStatus } from 'reactApp/modules/subscriptions/subscriptions.types';
import type { Product } from 'reactApp/types/Billing';
import { formatDefaultDate, formatFileHistoryDate } from 'reactApp/utils/formatDate';
import { getPeriodName } from 'reactApp/utils/Period';

export const getDescription = ({
    expires,
    autorenewal,
    renewAt,
    status,
    paused,
}: {
    expires?: number;
    autorenewal?: boolean;
    renewAt?: number;
    status?: string;
    paused?: boolean;
}) => {
    if (paused && renewAt) {
        return `Оплата на паузе до ${formatFileHistoryDate(renewAt)}`;
    }

    if (status === EStatus.FINISHED) {
        return 'Завершена';
    }

    if (autorenewal && renewAt) {
        return `Списание ${formatDefaultDate(renewAt)}`;
    } else if (expires) {
        return `Действует до ${formatDefaultDate(expires)}`;
    }

    return 'Подключено';
};

export const composeContent = ({
    subscription,
    product,
    pausedSubscription,
}: {
    subscription: ISubscription;
    product?: Product;
    pausedSubscription: boolean;
}) => {
    const { autorenewal, renewAt, expires, isTrial, space, partner, status, icon } = subscription;
    const period = (isTrial && !product?.isForceDiscountTrial && product?.trialPeriod) || subscription?.period || product?.period;
    const periodText = period ? ` на ${getPeriodName(period, false, true)}` : '';
    const subscriptionType = subscription.type as EPromoSubscriptionType | ESubscriptionsTypes;
    const showWaiting = [EStatus.FINISHED, EStatus.SUSPENDED].includes(status as EStatus);

    let title = `${space.value}${periodText}`;

    if (subscriptionType === EPromoSubscriptionType.quota) {
        title = `Подарок ${space.value}${periodText}`;
        // tempexp_16598-next-line
    } else if (product?.id && Object.values(cheapestProductIDs).includes(product.id)) {
        title = 'Тариф: Без рекламы';
    }

    return {
        title,
        description: getDescription({
            autorenewal,
            expires,
            renewAt,
            status,
            paused: pausedSubscription,
        }),
        icon: pausedSubscription ? pausedSubLogoRebranding : icon,
        partner,
        showWaiting,
    };
};
