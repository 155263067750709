import React, { type FC } from 'react';
import type { AttachesItem } from 'reactApp/modules/attaches/attaches.types';
import type { CloudFile } from 'reactApp/modules/storage/storage.types';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { NameComponent } from 'reactApp/ui/Name/Name';
import { getSize } from 'reactApp/utils/sizeHelper';

import styles from './MenuInfo.css';

interface Props {
    className?: string;
    file: CloudFile | AttachesItem;
}

export const MenuInfo: FC<Props> = ({ file }) => {
    return (
        <div className={styles.wrapper}>
            <Hint text={`${file.nameWithoutExt}.${file.ext}`} theme="dark" delay={500} showDelay>
                <NameComponent className={styles.file} name={file.nameWithoutExt} extension={file.ext} truncate />
            </Hint>
            {typeof file.size === 'number' && file.size > 0 ? getSize(file.size) : null}
        </div>
    );
};
