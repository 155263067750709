import { Icon16Attach } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { type MouseEvent, type ReactElement, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { allAttachesBttn } from 'reactApp/appHelpers/featuresHelpers/features/allAttachesBttn';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { historyPush } from 'reactApp/modules/router/router.module';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { closeViewer } from 'reactApp/modules/viewer/viewer.module';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipAppearance, ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { sendDwh } from 'reactApp/utils/ga';

import styles from './allAttachesButton.css';

interface Props {
    className?: string;
    forceActive({ isActive: boolean }): void;
}

export const AllAttachesButton = memo(({ className, forceActive }: Props): ReactElement | null => {
    const elementRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const [showTip, setShowTip] = useState(false);

    const promoData = useSelector(PromoSelectors.pullPromo(EPromoType.allAttaches));

    const handleClick = useCallback((event?: MouseEvent<HTMLDivElement>) => {
        sendDwh({ eventCategory: 'attaches-icon-click', sendImmediately: true });

        event?.stopPropagation();

        // Делаем так, а не прокидыванием handleClose сюда, так как это не закрытие просм-ка, а навигация в раздел аттачей
        // Тут нужно пропустить всякие редиректы и другие сложные флоу с навигацией, инициируемые юзером при явном закрытии просм-ка кнопкой Закрыть.
        // А closeViewer вызываем для закрытия его интерфейса и разных очисток
        dispatch(closeViewer({ noGoto: true }));
        dispatch(historyPush({ id: `${EStorageType.attaches}/` }));
    }, []);

    useEffect(() => {
        if (!allAttachesBttn || !promoData || !elementRef?.current) {
            return;
        }

        setTimeout(() => setShowTip(true), 1000);
    }, [allAttachesBttn, promoData, elementRef?.current]);

    if (!allAttachesBttn) {
        return null;
    }

    return (
        <div className={classNames(styles.root, className)} onClick={handleClick} ref={elementRef}>
            <Hint text={showTip ? '' : 'Все вложения из почты'} theme="dark">
                <Icon16Attach className={styles.icon} />
            </Hint>
            {showTip && (
                <FloatingTooltip
                    target={elementRef?.current}
                    title="Вложения из почты — в Облаке"
                    text={
                        <span data-qa-id="tip-text" className={styles.text}>
                            Все файлы из ваших писем удобно
                            <br />
                            находить, чтобы скачивать нужные
                        </span>
                    }
                    buttonText="Перейти"
                    placement={ETooltipPlacement.bottom}
                    closeOnOutsideClick={false}
                    qaId="goto-attaches-promo-tip"
                    appearance={ETooltipAppearance.dark}
                    className={styles.tip}
                    onClick={() => {
                        promoData?.onClose();
                        handleClick();
                        setShowTip(false);
                    }}
                    onShow={() => {
                        promoData?.onShow();
                        forceActive?.({ isActive: true });
                    }}
                    isAlternativeButton
                    onClose={() => {
                        promoData?.onClose();
                        forceActive?.({ isActive: false });
                        setShowTip(false);
                    }}
                    onTooltipClick={(event) => event.stopPropagation()}
                />
            )}
        </div>
    );
});

AllAttachesButton.displayName = 'AllAttachesButton';
