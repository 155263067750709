import type { VideoPlayer } from 'reactApp/ui/ReactViewer/VideoPlayer/VideoPlayer.types';
import videojs from 'video.js';

import styles from './styles.css';

const Component = videojs.getComponent('Component');

function animate(el: HTMLElement, rewindTime: number, count: number) {
    el.setAttribute('data-content', `${count * rewindTime} сек.`);
    el.classList.add(styles.click);
    window.requestAnimationFrame(() => {
        el.classList.remove(styles.click);
    });
}

// Анимация промотки влево и вправо
export function addAnimatedBackground(player: VideoPlayer, rewindTime: number) {
    const wrapperEl = document.createElement('div');
    wrapperEl.className = styles.animationWrapper;

    const elLeftAnimation = document.createElement('div');
    elLeftAnimation.className = `${styles.leftAnimationScreen} ${styles.click}`;
    wrapperEl.appendChild(elLeftAnimation);

    const elRightAnimation = document.createElement('div');
    elRightAnimation.className = `${styles.rightAnimationScreen} ${styles.click}`;
    wrapperEl.appendChild(elRightAnimation);

    const wrapper = new Component(player, { el: wrapperEl });

    player.addChild(wrapper);

    return {
        showLeft: (count: number) => animate(elLeftAnimation, rewindTime, count),
        showRight: (count: number) => animate(elRightAnimation, rewindTime, count),
    };
}
