import { getNameById } from 'reactApp/modules/file/utils';
import { HttpError } from 'reactApp/modules/uploading/errors/HttpError';
import { ConnectionFail } from 'reactApp/modules/uploading/fails/ConnectionFail';
import { FileExistsFail } from 'reactApp/modules/uploading/fails/FileExistsFail';
import { OverQuotaFail } from 'reactApp/modules/uploading/fails/OverQuotaFail';
import { UserFileSizeLimitFail } from 'reactApp/modules/uploading/fails/UserFileSizeLimitFail';
import { ViolatedFilenameFail } from 'reactApp/modules/uploading/fails/ViolatedFilenameFail';
import { addFile } from 'reactApp/modules/uploading/helpers/cloudFs/addFile';
import { addFolder } from 'reactApp/modules/uploading/helpers/cloudFs/addFolder';
import { getVisibleNameParts, getVisiblePath } from 'reactApp/modules/uploading/helpers/fs/fs.helpers';
import { getThumbnail, sendGaUploaderNew } from 'reactApp/modules/uploading/helpers/uploading.helpers';
import { updateUploadFilesActionBatcher } from 'reactApp/modules/uploading/serviceClasses/batchHelpers';
import { MAX_RETRIES_COUNT } from 'reactApp/modules/uploading/serviceClasses/Uploader';
import type { UploadingDescriptor } from 'reactApp/modules/uploading/serviceClasses/UploadingDescriptor';
import { EUploadingState } from 'reactApp/modules/uploading/uploading.types';
import { EFileStatus } from 'reactApp/modules/uploadList/uploadList.model';

function* _addToUserCloud(descriptor: UploadingDescriptor, workingDirectory = '') {
    const isDirectory = descriptor.isDirectory;

    if (descriptor.state === EUploadingState.STATE_CANCEL || descriptor.hasCanceledParent()) {
        return;
    }

    try {
        let cloudPath;

        descriptor.addFileError = false;
        delete descriptor.addFileErrorStatus;

        if (isDirectory) {
            cloudPath = yield addFolder(descriptor);
        } else {
            cloudPath = yield addFile(descriptor, workingDirectory);
        }

        const nameParts = getVisibleNameParts(cloudPath);
        descriptor.cloudName = getNameById(cloudPath);
        descriptor.cloudPath = cloudPath;
        descriptor.visibleName = getVisiblePath(cloudPath);
        descriptor.visiblePath = getVisiblePath(cloudPath);
        descriptor.nameParts = nameParts;
        descriptor.state = EUploadingState.STATE_DONE;

        if (descriptor.hasInvalidCharAutoFix) {
            sendGaUploaderNew('auto_fix', 'invalid_char_done');
        } else if (descriptor.hasNameTooLongAutoFix) {
            sendGaUploaderNew('auto_fix', 'name_too_long_done');
        }

        if (!isDirectory) {
            updateUploadFilesActionBatcher.push({
                descriptorId: descriptor.id,
                cloudPath,
                currentUpload: false,
                status: EFileStatus.DONE,
                loaded: descriptor.size,
                progress: 100,
                error: '',
                thumb: getThumbnail(descriptor),
                // @ts-ignore
                name: nameParts?.name ? nameParts.name : '', // имя может изменится после сохранения обоих файлов в случае file_exist
            });
        }
    } catch (error: any) {
        let state;
        let reason = error;

        descriptor.knownError = false;

        const stopRetries = error instanceof ConnectionFail && error.isRetryableError() && descriptor.retryCount >= MAX_RETRIES_COUNT;

        if (
            error instanceof OverQuotaFail ||
            error instanceof UserFileSizeLimitFail ||
            error instanceof FileExistsFail ||
            (error instanceof ConnectionFail && !stopRetries) ||
            error instanceof ViolatedFilenameFail
        ) {
            state = EUploadingState.STATE_PAUSED;
            descriptor.knownError = true;
        } else {
            state = EUploadingState.STATE_FAIL;
            if (error instanceof ConnectionFail || error instanceof HttpError) {
                descriptor.addFileError = true;
                descriptor.addFileErrorStatus = error.status;

                if (error instanceof ConnectionFail) {
                    const stack = new Error('HttpError');
                    reason = new HttpError(stack, error.source, error.status, '');
                }
            }
        }

        descriptor.error = reason;
        descriptor.state = state;
        throw error;
    }
}

export function* addToUserCloud(descriptor: UploadingDescriptor, workingDirectory = '') {
    const state = descriptor.state;
    if (state === EUploadingState.STATE_UPLOADED) {
        descriptor.state = EUploadingState.STATE_APPENDING;
        yield _addToUserCloud(descriptor, workingDirectory);
    } else {
        throw new Error('addToUserCloud() called in wrong state');
    }
}
