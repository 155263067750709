import { Icon16Done } from '@vkontakte/icons';
import { Spacing, Text } from '@vkontakte/vkui';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { sendQuotaGa } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { formatPrice } from 'reactApp/utils/priceHelpers';

import styles from './FeaturesModal.css';
import { getFeaturesModalList } from './FeaturesModal.data';
import type { FeaturesModalProps } from './FeaturesModal.types';

export const FeaturesModal = memo<FeaturesModalProps>(({ onClose, product, onBuyClick }) => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const features = getFeaturesModalList();

    useEffect(() => {
        sendQuotaGa({ action: 'modal-tariff-benefits', label: 'view' });
    }, []);

    const handleClose = useCallback(() => {
        onClose?.();
    }, [onClose]);

    const handleBuyClick = useCallback(() => {
        onClose?.();
        sendQuotaGa({ action: 'modal-tariff-benefits', label: 'click' });
        if (product) {
            onBuyClick?.(product, undefined, undefined, 'modal_benefits');
        }
    }, [onBuyClick, onClose, product]);

    return (
        <MobileDialog
            id="mobile-public-password-tariff"
            onClose={handleClose}
            closeOnDimmerClick
            topmost
            scrollRef={scrollRef}
            mod="base"
            contextPadding="zero"
        >
            <div className={styles.root}>
                <Text className={styles.title}>12 возможностей с&nbsp;Mail&nbsp;Space</Text>
                <Spacing size={20} />
                <ul className={styles.list}>
                    {features.map((item, index) => {
                        return (
                            <li key={`feature-${index}`}>
                                <Icon16Done fill="var(--vkui--color_icon_accent)" />
                                {item}
                            </li>
                        );
                    })}
                </ul>
                <Spacing size={36} />
                <Button theme="vk" primary sizeMode={ButtonSizeMode.extraBig} className={styles.button} onClick={handleBuyClick}>
                    {`${product?.space.value} за ${formatPrice(product?.discountPrice || product?.price || 0)} ₽ в год`}
                </Button>
            </div>
        </MobileDialog>
    );
});

FeaturesModal.displayName = 'FeaturesModal';
