import { xray } from 'lib/xray';
import { litresTariffIdsListCommon } from 'reactApp/appHelpers/featuresHelpers/features/litres';
import { yearPriceInMonthEnabled } from 'reactApp/appHelpers/featuresHelpers/features/yearPriceInMonth';
import { Platforms } from 'reactApp/modules/environment/constants';
import { cheapestProductIDs } from 'reactApp/modules/products/products.const';
import { AUTOUPLOAD_10TB_REGEX } from 'reactApp/modules/products/products.helpers';
import { openPayInBrowserDialog } from 'reactApp/sections/MobileSubscriptionsPage/PayInBrowser/PayInBrowser.helpers';
import type { Product } from 'reactApp/types/Billing';
import { isYearPeriod } from 'reactApp/utils/Period';
import { formatPrice, getMonthProductPrice } from 'reactApp/utils/priceHelpers';

export const revealPlatformPopup = (id: string, isIOSPromo: boolean, isAndroidPromo: boolean, isRebrandingQuotaLanding?: boolean) => {
    const platform = isIOSPromo ? Platforms.IOS : Platforms.Android;

    const onOpen = () => {
        xray.send('pay_browser_popup_open', { i: platform });
    };

    const onClose = () => {
        xray.send('pay_browser_popup_close', { i: platform });
    };

    const onCopy = () => {
        xray.send('pay_browser_popup_copy', { i: platform });
    };

    openPayInBrowserDialog({ onOpen, onCopy, onClose, id, isAndroidPromo, isIOSPromo, isRebrandingQuotaLanding });
};

interface PaymentData {
    title: string | undefined;
    subTitle: string | undefined;
    paymentDescription: string | undefined;
}

export const getPaymentData = (product: Product, isMidasPayment?: boolean): PaymentData => {
    const data: PaymentData = {
        title: undefined,
        subTitle: undefined,
        paymentDescription: undefined,
    };

    if (!product) {
        return data;
    }

    // tempexp_16481-start
    if (AUTOUPLOAD_10TB_REGEX.test(product.id)) {
        return {
            ...data,
            subTitle: '+10 ТБ на автозагрузку фото и видео',
        };
    }
    // tempexp_16481-end

    // tempexp_16598-start
    if (product.id && Object.values(cheapestProductIDs).includes(product.id)) {
        return {
            ...data,
            title: 'Отключить рекламу с подпиской Mail Space',
            paymentDescription: 'Тариф - Без рекламы',
        };
    }
    // tempexp_16598-end

    // tempexp_17509-start
    if (product.id && litresTariffIdsListCommon.includes(product.id)) {
        return {
            ...data,
            title: `Подписка Mail Space: Литрес на 2 месяца бесплатно + ${product.space.value}`,
        };
    }
    // tempexp_17509-end

    // tempexp_17323-start
    const period = product.discountPeriod || product.period;

    if (isMidasPayment && yearPriceInMonthEnabled === 'b' && period && isYearPeriod(period)) {
        return {
            ...data,
            title: `Оплата тарифа ${product.space.value} на\u00A0год за\u00A0${formatPrice(
                getMonthProductPrice(product)
            )}\u00A0₽ в\u00A0месяц`,
        };
    }
    // tempexp_17323-end

    return data;
};
