import sha1 from 'js-sha1';
import { xray } from 'lib/xray';
import type { IFace } from 'reactApp/modules/faces/faces.types';
import type { PublicItem } from 'reactApp/modules/public/public.types';

export const enum superAppSubmetrics {
    openPublic = 'open-public',
    uploadFiles = 'upload-files',
    sharePublic = 'share-public',
    saveInCloud = 'save-in-cloud',
    authorizationOrInstallation = 'authorization-or-installation',
    necessarilyDownloadApp = 'necessarily-download-app',
    necessarilyAuthorization = 'necessarily-authorization',
}

export interface superAppWebViewDWH {
    id_public: string;
    id_file?: string;
    id_folder?: string;
    type_public: 'folder' | 'file' | 'album';
    count_objects: number;
    extension?: string;
    owner: boolean;
    have_faces: boolean;
    count_faces: number;
}

export const superAppTakeItemAndSendXray = (i: superAppSubmetrics, item: PublicItem, isOwn: boolean, faces: IFace[]): void => {
    if (item.isFolder) {
        sendSuperAppXray(i, {
            id_public: isOwn && item?.home ? sha1(item.home) : item?.id,
            id_folder: item?.id,
            type_public: 'folder',
            count_objects: item?.count?.all,
            owner: isOwn,
            have_faces: Boolean(faces?.length),
            count_faces: faces?.length,
        });
        return;
    }
    sendSuperAppXray(i, {
        id_public: isOwn && item?.home ? sha1(item.home) : item?.id,
        id_file: item?.id,
        type_public: 'file',
        count_objects: 1,
        owner: isOwn,
        have_faces: false,
        count_faces: 0,
        extension: item.ext,
    });
};

export const sendSuperAppXray = (i: superAppSubmetrics, dwh: superAppWebViewDWH) => {
    xray.send('public', {
        p: 'cloud-integration',
        i,
        dwh: {
            type_access: 'url',
            app: 'Mail App',
            place: 'Mail App',
            shove: false,
            isPhone: true,
            isEditor: false,
            isStock: false,
            ...dwh,
        },
    });
};
