import { EStorageType } from 'reactApp/modules/storage/storage.types';

export const onMouseDownOnItem = ({ event, storage, dragParams, item, handleMouseMove, handleMouseUp }) => {
    if (storage !== EStorageType.home) {
        return;
    }

    dragParams.current = {
        dragItem: item,
        startX: event.clientX,
        startY: event.clientY,
        isDragging: false,
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
};

export const onMouseMove = ({ e, dragParams, handleStartDrag, setDragPosX, setDragPosY }) => {
    if (e?.target?.nodeType !== Node.ELEMENT_NODE) {
        return;
    }

    if (e?.target?.getAttribute('contenteditable')) {
        return;
    }

    const moveX = Math.abs(e.clientX - dragParams.current.startX);
    const moveY = Math.abs(e.clientY - dragParams.current.startY);

    if (!dragParams.current.isDragging) {
        if (moveX > 3 || moveY > 3) {
            handleStartDrag();
        } else {
            return;
        }
    }

    setDragPosX(e.clientX);
    setDragPosY(e.clientY);
};

export const onMouseLeave = ({ isDragging, setDragOverItem }) => {
    if (!isDragging) {
        return;
    }

    setDragOverItem(null);
};
