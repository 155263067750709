/**
 * Компонент не завершен
 * Экспериментальная копия reactApp/components/SharingWindow/SharingNew/Weblink/SharingNewWeblink.tsx
 * Изменения относительно оригинала помечены комментарием - tempexp_17779
 **/

import { ReactComponent as LockIcon } from '@vkontakte/icons/src/svg/20/lock_outline_20.svg';
import { ReactComponent as UnlockIcon } from '@vkontakte/icons/src/svg/20/unlock_outline_20.svg';
import { getFeature } from 'Cloud/Application/FeaturesEs6';
import browser from 'Cloud/browser';
import config from 'Cloud/config';
import classNames from 'clsx';
import React, { type ReactElement, memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_ONPREMISE, IS_TABLET_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { abSharingLinkFakedoor } from 'reactApp/appHelpers/featuresHelpers';
import { isPublicPasswordEnabled } from 'reactApp/appHelpers/featuresHelpers/features/publicPassword';
import { seoEnable } from 'reactApp/appHelpers/featuresHelpers/features/seoEnable';
import { isThemedSharedModalItem } from 'reactApp/appHelpers/featuresHelpers/features/themedPublic';
import { unPublishHelper } from 'reactApp/appHelpers/publishHelper';
import { sendGa } from 'reactApp/components/SharingWindow/Sharing.helpers';
import { EFrom, EPromoTooltip } from 'reactApp/components/SharingWindow/Sharing.types';
import { AutoDelete } from 'reactApp/components/SharingWindow/SharingNew/Weblink/AutoDelete/AutoDelete';
import { useSeoOption } from 'reactApp/components/SharingWindow/SharingNew/Weblink/hooks/useSeoOption';
import { useIsWindowIntegrationSmall } from 'reactApp/hooks/useIsWindowIntegrationSmall';
import { getEditorsFor, getIsFileForCoEditing } from 'reactApp/modules/editor/editor.selectors';
import {
    getFeatureEditingNoneditableFormats,
    getFeaturePublicAutoDeletePromo,
    getFeaturePublicDomestic,
} from 'reactApp/modules/features/features.selectors';
import { getIntegrationClient } from 'reactApp/modules/integration/integration.selectors';
import { publishRequest } from 'reactApp/modules/modifying/modifying.actions';
import { loadPaidInfoRequest } from 'reactApp/modules/paidInfo/paidInfo.module';
import { getPublicSharingInfo } from 'reactApp/modules/public/public.actions';
import { getPublicPin } from 'reactApp/modules/public/public.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { isAutoDeleteEnabled } from 'reactApp/modules/sharedAutoDelete/sharedAutoDelete.selectors';
import { getItemById } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import type { RootState } from 'reactApp/store';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { CopyWeblink } from 'reactApp/ui/CopyWeblink/CopyWeblink';
import { getPublicUrl } from 'reactApp/utils/urlHelper';

import { useDomestic } from '../../Weblink/hooks/useDomestic';
import { useExpires } from '../../Weblink/hooks/useExpires';
import { renderUnpublishWeblinkTabDialog } from '../../Weblink/SharingWeblink.helpers';
import type { IProps } from '../../Weblink/SharingWeblink.types';
import { ChangeDesign } from './ChangeDesign/ChangeDesign';
import { useAccessRights } from './hooks/useAccessRights';
import { PublicPassword } from './PublicPassword/PublicPassword';
import styles from './SharingNewWeblinkTab.css';
import { SharingNewWeblinkList } from './WeblinkList/SharingNewWeblinkList';

const showWeblinkListFileSharing = getFeature('weblink-list-file-sharing') || false;
const IS_FREE_B2B_BIZ_EDITORS_USER = config.get('FREE_B2B_BIZ_EDITORS_USER');

/* eslint-disable complexity */
export const SharingNewWeblinkTab = memo(function SharingNewWeblink(props: IProps): ReactElement | null {
    const {
        isOwn,
        item,
        from,
        isSharedOrMouted,
        gaSuffix,
        noEdit = false,
        noExpiresControl = false,
        isPhone,
        onClose,
        sendSharingContentAnalytics,
        withPromoTooltip,
        showListSharing,
        // tempexp_17779-start
        copiedWeblinkOnMount,
        setCopiedWeblinkOnMount,
        // tempexp_17779-end
    } = props;
    const dispatch = useDispatch();
    const itemStorage = useSelector(getCurrentStorage);
    const storage = item?.storage || itemStorage;
    const isAlbum = from === EFrom.ALBUMS || storage === EStorageType.albums;
    const isWindowSmall = useIsWindowIntegrationSmall();
    const { isTutoria } = useSelector(getIntegrationClient);
    const isBizUser = useSelector(UserSelectors.isBizUser);
    const isCorpUser = useSelector(UserSelectors.isCorpUser);
    const isPublic = Boolean(item?.weblink);
    // Исключаем стоки, у которых них нет публичной ссылки
    const publicUrl = (isPublic && getPublicUrl(item, isAlbum)) || '';

    const isFromWeblink = from === EFrom.WEBLINK || isPublic;
    const isStock = itemStorage === EStorageType.stock;

    const seoOption = useSeoOption(item, !isBizUser && !IS_ONPREMISE && !isSharedOrMouted.isMounted && seoEnable);

    const isDomesticFeatureEnabled = useSelector(getFeaturePublicDomestic);
    const canShareForDomestic = isBizUser || isCorpUser;
    const showDomesticSettings =
        (!isAlbum || !isStock) && ((canShareForDomestic && Boolean(isDomesticFeatureEnabled)) || showWeblinkListFileSharing) && isOwn;

    const [isWeblinkOpened, setWeblinkOpened] = useState<boolean>(isFromWeblink);

    const editingNoneEditableFormatsFeature = useSelector((state: RootState) => {
        if (!item) {
            return;
        }
        return getFeatureEditingNoneditableFormats(state, {
            file: {
                ext: typeof item === 'object' && 'ext' in item ? item.ext : undefined,
                size: typeof item === 'object' && 'size' in item ? item.size : undefined,
            },
            storage,
        });
    });
    const convertExt = editingNoneEditableFormatsFeature?.ext || '';
    const isFolder = item?.isFolder;

    const isConvertFileForCoEditing = useSelector((state: RootState) => getIsFileForCoEditing(state, { ...item, ext: convertExt }));
    const isFileForCoEditing = useSelector((state: RootState) => getIsFileForCoEditing(state, item));
    const isEditable = !!useSelector((state: RootState) => getEditorsFor(state, convertExt ? { ...item, ext: convertExt } : item))?.length;

    const isDocumentForEdit =
        (item?.kind === 'document' && IS_FREE_B2B_BIZ_EDITORS_USER) ||
        isFileForCoEditing ||
        (isConvertFileForCoEditing && !(IS_TABLET_BROWSER || browser.isIpadOs()) && isEditable);
    const isReadOnlyItem = !!item?.isReadOnly;
    const parent = useSelector((state: RootState) => getItemById(state, item?.parent || ''));

    const isReadOnlyParent = !!(parent && 'isReadOnly' in parent && parent?.isReadOnly);
    const isReadOnlyProperty = !isReadOnlyItem && isSharedOrMouted.isMounted ? isReadOnlyParent : isReadOnlyItem;
    const isReadOnly = isFolder ? isReadOnlyProperty : isReadOnlyProperty || !isDocumentForEdit;

    const renderExpires = useExpires(item);
    const renderDomestic = useDomestic(item, { storage, canShareForDomestic });
    const renderAccessRights = useAccessRights(item, { hidden: isReadOnly, isReadOnlyProperty, onClose });

    const isAutodeleteFeatureEnabled = Boolean(useSelector(isAutoDeleteEnabled));
    const isAutodeletePromoFeatureEnabled = Boolean(useSelector(getFeaturePublicAutoDeletePromo));
    const showExpires = isStock || (!isAlbum && !noExpiresControl && isOwn);
    const showAccessRights = !isAlbum && isOwn;
    // Отключаем платную фичу в Тутории
    const showAutoDeleteSettings =
        !isTutoria &&
        isOwn &&
        !isAlbum &&
        item?.isFolder &&
        !isSharedOrMouted.isMounted &&
        (isAutodeleteFeatureEnabled || isAutodeletePromoFeatureEnabled);
    // tempexp_17341-next-line
    const showChangeDesign = isWeblinkOpened && isThemedSharedModalItem && !isAlbum && isOwn && item?.isFolder && !isTutoria && !isPhone;
    // tempexp_17605-start
    const pin = useSelector((state: RootState) => getPublicPin(state, item?.weblink || ''));
    const showChangePassword = isWeblinkOpened && (isPublicPasswordEnabled || pin) && !isAlbum && isOwn && item?.isFolder && !isTutoria;
    const isPaidUser = useSelector(UserSelectors.isPaidUser);
    // tempexp_17605-end

    // tempexp_17779-start
    const onActionClick = useCallback(() => {
        sendGa('unpublish');
        if (storage === EStorageType.attaches) {
            sendGa('attaches-unpublish');
        }

        if (!item) {
            return;
        }
        unPublishHelper({ items: [item] });
        setWeblinkOpened(false);
    }, [storage, item]);

    const onCloseAccessClick = useCallback(() => {
        renderUnpublishWeblinkTabDialog({ onActionClick });
    }, [onActionClick]);
    // tempexp_17779-end

    const onDropdownClick = useCallback(() => {
        sendSharingContentAnalytics({ action: 'auto-delete', label: 'click' });
    }, [sendSharingContentAnalytics]);

    useEffect(() => {
        if (!publicUrl) {
            setWeblinkOpened(false);
        }
    }, [publicUrl]);

    useEffect(() => {
        if (item?.weblink) {
            dispatch(getPublicSharingInfo({ public_id: item.weblink }));
            // todo: заменить на новую ручку.
            dispatch(loadPaidInfoRequest());
        }

        if (showChangePassword && !isPaidUser) {
            dispatch(loadPaidInfoRequest());
        }
    }, [dispatch, isPaidUser, item, showChangePassword]);

    // tempexp_17779-start
    useEffect(() => {
        setCopiedWeblinkOnMount?.(true);
    }, [setCopiedWeblinkOnMount]);

    const onOpenAccessClick = useCallback(() => {
        if (!item) {
            return;
        }

        sendGa('publish');
        dispatch(publishRequest({ item }));
        setWeblinkOpened(true);
    }, [dispatch, item]);

    if (!isWeblinkOpened) {
        return (
            <div className={styles.emptyContainer}>
                <div className={styles.emptyImage} />
                <h3 className={styles.emptyHeader}>Доступ по ссылке закрыт</h3>
                <p className={styles.emptyDescription}>
                    Если передумаете — создадим новую ссылку,
                    <br />
                    ей можно будет поделиться
                </p>
                <Button theme={'base'} middle onClick={onOpenAccessClick} sizeMode={isPhone ? ButtonSizeMode.big : ButtonSizeMode.middle}>
                    <div className={styles.closeAccessButton}>
                        <UnlockIcon width={20} height={20} />
                        <span>Открыть доступ</span>
                    </div>
                </Button>
            </div>
        );
    }
    // tempexp_17779-end
    return (
        <div>
            <div className={classNames(styles.root, { [styles.root_phone]: isPhone, [styles.root_tutoria]: isWindowSmall })}>
                {/* tempexp_17605-next-line */}
                {showChangePassword && <PublicPassword forcePromo={withPromoTooltip === EPromoTooltip.PublicPassword} item={item} />}
                {/* tempexp_17341-next-line */}
                {showChangeDesign && <ChangeDesign item={item} forcePromo={withPromoTooltip === EPromoTooltip.ChangeDesign} />}
                {seoOption}
                {showDomesticSettings && renderDomestic()}
                {showAccessRights && renderAccessRights()}
                {showExpires && renderExpires()}
                {showAutoDeleteSettings && (
                    <AutoDelete
                        item={item}
                        disabled={!isAutodeleteFeatureEnabled && isAutodeletePromoFeatureEnabled && isWeblinkOpened}
                        gaSuffix={gaSuffix}
                        onDropdownClick={onDropdownClick}
                        disabledBySeo={item?.weblinkSeoEnabled && seoEnable}
                    />
                )}
            </div>
            {showWeblinkListFileSharing && !noEdit && showListSharing && (
                <div className={styles.persAccessContainer}>
                    <SharingNewWeblinkList item={item} from={from} isPhone={isPhone} isReadOnly={isReadOnly} isTabsDesign />
                </div>
            )}
            {/* tempexp_17779-start */}
            <div className={styles.copyWeblink}>
                <CopyWeblink
                    type="sharingTabs"
                    publicUrl={publicUrl}
                    autoCopy={!copiedWeblinkOnMount && !isPhone}
                    isPhone={isPhone}
                    // tempexp_17341-next-line
                    tooltipEnabledByExp={abSharingLinkFakedoor === 'b' && !isThemedSharedModalItem}
                />
                <Button theme={'base'} middle onClick={onCloseAccessClick} sizeMode={isPhone ? ButtonSizeMode.big : ButtonSizeMode.middle}>
                    <div className={styles.closeAccessButton}>
                        <LockIcon width={20} height={20} />
                        <span>Закрыть доступ по ссылке</span>
                    </div>
                </Button>
            </div>
            {/* tempexp_17779-end */}
        </div>
    );
});
