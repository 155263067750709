import classNames from 'clsx';
import React, { type ReactElement, PureComponent } from 'react';
import { connect } from 'react-redux';
import { IS_BIZ_USER, IS_ONPREMISE, NEW_PORTAL_HEADER } from 'reactApp/appHelpers/configHelpers';
import { isStoriesInGallery } from 'reactApp/appHelpers/featuresHelpers';
import { getFeatureShowStories } from 'reactApp/modules/features/features.selectors';
import { galleryLoadRequest } from 'reactApp/modules/gallery/gallery.module';
import {
    getGalleryCategory,
    getPreviewableMediaForGallery,
    isGalleryCategoryLoaded,
    isGalleryCategoryLoading,
} from 'reactApp/modules/gallery/gallery.selectors';
import { GalleryCategoryType } from 'reactApp/modules/gallery/gallery.types';
import { StartSelectors } from 'reactApp/modules/start/start.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getStoryListRequest } from 'reactApp/modules/stories/stories.module';
import { getStorySummaryCovers, getSummariesStatus } from 'reactApp/modules/stories/stories.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import type { LoadingState } from 'reactApp/types/commonStates';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import { Spinner } from 'reactApp/ui/Spinner/Spinner';
import { sendStoryWidgetDwh } from 'reactApp/ui/StoriesWidget/StoriesWidget.helpers';
import type { StorySummaryItem } from 'reactApp/ui/StoriesWidgetComponent/Stories.types';
import { StoriesWidgetComponent } from 'reactApp/ui/StoriesWidgetComponent/StoriesWidgetComponent';
import { createGaSender } from 'reactApp/utils/ga';
import opener from 'reactApp/utils/opener';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './GalleryNewPage.css';
import { GalleryPageTabMenu } from './GalleryPageTabMenu';

const sendGa = createGaSender('gallery');

const mapDispatchToProps = (dispatch) => ({
    loadAll: (category) => {
        dispatch(galleryLoadRequest({ category }));
    },
    loadStories: () => {
        dispatch(getStoryListRequest());
    },
});

const mapStateToProps = (state) => {
    const startLoadingState = StartSelectors.getLoadingState(state);

    const usedSpace = UserSelectors.getCloudSpace(state)?.used?.value || '';
    const isStoriesEnabled = getFeatureShowStories(state);
    const stories = getStorySummaryCovers(state);

    const currentCategory = getGalleryCategory(state);
    const itemsLoadingState = {
        isLoading: isGalleryCategoryLoading(state, currentCategory) && (isStoriesInGallery ? getSummariesStatus(state).isLoading : true),
        isLoaded: isGalleryCategoryLoaded(state, currentCategory) && (isStoriesInGallery ? getSummariesStatus(state).isLoaded : true),
    };
    const items = itemsLoadingState.isLoading ? [] : getPreviewableMediaForGallery(state, null);

    return { isStoriesEnabled, stories, startLoadingState, items, itemsLoadingState, usedSpace, currentCategory };
};

const DEFAULT_CATEGORY = IS_ONPREMISE || IS_BIZ_USER ? GalleryCategoryType.images : GalleryCategoryType.all;

interface IMapToStateProps {
    isStoriesEnabled: boolean;
    stories: StorySummaryItem[];
    startLoadingState: LoadingState;
    items: string[];
    itemsLoadingState: LoadingState;
    usedSpace: string;
    currentCategory: GalleryCategoryType;
}

interface IDispatchToProps {
    loadAll: (category: GalleryCategoryType) => void;
    loadStories: () => void;
}

interface IProps extends IMapToStateProps, IDispatchToProps {
    initialItemId?: string;
}

export class GalleryNewPage extends PureComponent<IProps> {
    componentDidMount() {
        const { loadAll, loadStories, stories } = this.props;

        let hash = window?.location?.hash;
        if (hash) {
            hash = hash.replace('#', '');
        }

        const category = GalleryCategoryType[hash as keyof typeof GalleryCategoryType] || DEFAULT_CATEGORY;
        sendGa('show');
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'gallery' });
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'page-entered', source: 'gallery' });

        loadAll(category);

        if (isStoriesInGallery && stories.length === 0) {
            loadStories();
        }

        if (isStoriesInGallery && stories.length > 0) {
            sendStoryWidgetDwh({
                action: 'view-stories-open',
                source: EStorageType.gallery,
                tab: EStorageType.gallery,
            });
        }
    }

    componentDidUpdate(prevProps: IProps) {
        if (isStoriesInGallery && prevProps.stories.length === 0 && this.props.stories.length > 0) {
            sendStoryWidgetDwh({
                action: 'view-stories-open',
                source: EStorageType.gallery,
                tab: EStorageType.gallery,
            });
        }

        if (prevProps.currentCategory !== this.props.currentCategory) {
            sendGa('show', this.props.currentCategory);
        }
    }

    private handleClick = ({ id }) => {
        sendGa('clickitem');

        // TODO: routing
        opener(`/home${encodeURIComponent(id)}`, !id);
    };

    private renderItems(): ReactElement | null {
        const { initialItemId } = this.props;

        return <DataList goTo={this.handleClick} storage={EStorageType.gallery} initialItemId={initialItemId} />;
    }

    render(): ReactElement {
        const { startLoadingState, items, stories } = this.props;

        if (startLoadingState.isLoading) {
            return <Spinner />;
        }

        return (
            <div
                className={classNames(styles.root, {
                    [styles.root_new_portal_header]: NEW_PORTAL_HEADER,
                })}
            >
                {isStoriesInGallery && stories.length > 0 && items.length === 0 && (
                    <div className={styles.stories_home_page_header}>
                        <StoriesWidgetComponent stories={stories} />
                    </div>
                )}
                {(!NEW_PORTAL_HEADER || !isStoriesInGallery || items.length === 0) && <GalleryPageTabMenu isInDataList={false} />}
                {this.renderItems()}
            </div>
        );
    }
}

export const GalleryNewPageConnected = connect(mapStateToProps, mapDispatchToProps)(GalleryNewPage);
