import { Icon20ChevronLeftOutline } from '@vkontakte/icons';
import React, { type ClipboardEvent, type KeyboardEvent, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLongLoad } from 'reactApp/hooks/useLongLoad';
import { AttachesSelectors } from 'reactApp/modules/attaches/attaches.selectors';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { historyPush } from 'reactApp/modules/router/router.module';
import { searchHistoryHide, searchSetEmptyFilters } from 'reactApp/modules/search/search.module';
import { getSearchContentStatus, getSearchRequestParams } from 'reactApp/modules/search/search.selectors';
import { EActionSearch } from 'reactApp/modules/search/search.types';
import { setQueryParams } from 'reactApp/modules/settings/settings.module';
import { getIdByStorage, getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { RootState } from 'reactApp/store';
import { Button } from 'reactApp/ui/Button/Button';
import { type IFilters, ESearchOptionSource, ESearchOptionType } from 'reactApp/ui/WebSearch/WebSearch.data';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

import { CategoryFilters } from './CategoryFilters/CategoryFilters';
import { ClearButton } from './ClearButton/ClearButton';
import { Filters } from './Filters/Filters';
import { useWebSearchContent } from './hooks/useWebSearchContent';
import { SearchButton } from './SearchButton/SearchButton';
import { SearchInput } from './SearchInput/SearchInput';
import { SearchSuggests } from './SearchSuggests/SearchSuggests';
import { SearchTooltip } from './SearchTooltip/SearchTooltip';
import type { SearchTooltipTypes } from './SearchTooltip/SearchTooltip.data';
import { getSearchFilterTooltipType, getSearchTooltipType } from './SearchTooltip/SearchTooltip.helpers';
import { useTooltipState } from './SearchTooltip/SearchTooltip.hooks';
import { TransitionWrapper } from './TransitionWrapper/TransitionWrapper';
import { TypeSelectButton } from './TypeSelectButton/TypeSelectButton';
import styles from './WebSearch.css';

const inputBorderRadius = {
    '--vkui--size_border_radius--regular': '8px 0 0 8px',
} as React.CSSProperties;

export interface IProps {
    dropdownAvailable: boolean;
    toggleSearch: (state: boolean) => void;
    isExpanded: boolean;
    onlyExpanded?: boolean;
    inPortalHeader?: boolean;
    storage: EStorageType;
}

// eslint-disable-next-line complexity, max-lines-per-function
export const WebSearch = memo(
    ({ dropdownAvailable, toggleSearch, isExpanded, storage, onlyExpanded = false, inPortalHeader = false }: IProps) => {
        const rootRef = useRef<HTMLDivElement>(null);
        const filtersButtonRef = useRef<HTMLButtonElement>(null);
        const searchRootRef = useRef<HTMLDivElement>(null);
        const searchInputRef = useRef<HTMLInputElement>(null);
        const pasteData = useRef<{ phrase_before?: string; paste?: string; wasPasted?: boolean }>({});

        const isFullyExpanded = isExpanded || onlyExpanded;
        // tempexp_SRCH-70119-start
        const [isSearchFullyExpanded, setSearchFullyExpanded] = useState(isFullyExpanded);

        const dispatch = useDispatch();
        const searchTooltipType = getSearchTooltipType();
        const searchFilterTooltipType = getSearchFilterTooltipType();
        const searchTooltipPromo = useSelector(PromoSelectors.getPromo(EPromoType.searchTooltip));
        const searchFilterTooltipPromo = useSelector(PromoSelectors.getPromo(EPromoType.searchFilterTooltipPromo));
        const { webSearchContentEnabled } = useSelector(getSearchContentStatus);
        const { isAttaches, isSearch } = getStorage(storage);
        const attachesSearch = useSelector((state: RootState) => AttachesSelectors.getSearchState(state));
        const searchRequestParams = useSelector(getSearchRequestParams);

        const [isSearchTooltipVisible, onSearchTooltipClose] = useTooltipState({
            visibilityValue: !isSearchFullyExpanded && !isFullyExpanded && !!searchInputRef.current,
            tooltipType: searchTooltipType,
            promo: searchTooltipPromo,
        });
        const [isSearchFilterTooltipVisible, onSearchFilterTooltipClose] = useTooltipState({
            visibilityValue: isSearchFullyExpanded && isFullyExpanded && !!filtersButtonRef.current,
            tooltipType: searchFilterTooltipType,
            promo: searchFilterTooltipPromo,
        });
        // tempexp_SRCH-70119-end

        const {
            isFocus,
            isOpen,
            value,
            type,
            source,
            placeholder,
            onSearchClick,
            onInputClick,
            onClickAway,
            onInputChange,
            onInputClear,
            onInputBlur,
            onKeyUp,
            onToggleFilters,
            searchValue,
            setActiveInput,
            setFilters,
            setFiltersClose,
            historyItems,
            onSelect,
            onReset,
            closeSearch,
        } = useWebSearchContent({ toggleSearch, isExpanded, onlyExpanded, storage, inPortalHeader });
        const { longLoad } = useLongLoad();
        const isDefaultType = type === ESearchOptionType.all;
        const isDefaultSource = source === ESearchOptionSource.all;
        const disabledSearch = !value && isDefaultSource && isDefaultType;

        const onTransitionEntered = useCallback(() => {
            setActiveInput();
            onSearchTooltipClose();
            setSearchFullyExpanded(true);
        }, [setActiveInput, onSearchTooltipClose]);

        const onTransitionExited = useCallback(() => {
            setSearchFullyExpanded(false);
            dispatch(searchSetEmptyFilters(false));
        }, [dispatch]);

        const handleKeyUp = useCallback(
            (event: KeyboardEvent) => {
                if (disabledSearch) {
                    return;
                }

                onKeyUp(event);
            },
            [disabledSearch, onKeyUp]
        );

        const handleInputClick = useCallback(() => {
            onInputClick();
            onSearchFilterTooltipClose();
        }, [onInputClick, onSearchFilterTooltipClose]);

        const handleToggleFilters = useCallback(() => {
            onToggleFilters();
            onSearchFilterTooltipClose();
        }, [onToggleFilters, onSearchFilterTooltipClose]);

        const handleOnClickAway = useCallback(() => {
            dispatch(searchHistoryHide());
            onClickAway();
        }, [dispatch, onClickAway]);

        const cancelSearch = useCallback(() => {
            dispatch(setQueryParams());

            let dwhData: {
                place: string;
                search_phrase?: string;
                page_id?: string;
                req_id?: string;
            } = { place: 'web' };

            if ((isAttaches && attachesSearch) || isSearch) {
                dwhData = {
                    ...dwhData,
                    search_phrase: attachesSearch ? attachesSearch.query : searchRequestParams.query,
                    page_id: searchRequestParams.xPageId,
                    req_id: searchRequestParams.xReqId,
                };
            }

            sendDwh({
                eventCategory: ECategoryGa.search,
                action: EActionSearch.searchClose,
                dwhData,
            });

            if (isAttaches) {
                dispatch(historyPush({ id: getIdByStorage(EStorageType.attaches) }));
            } else {
                closeSearch();
            }

            toggleSearch(false);
        }, [isAttaches, toggleSearch, dispatch, closeSearch, isSearch, searchRequestParams, attachesSearch]);

        const handleCloseSearch = useCallback(() => {
            cancelSearch();

            if (type !== ESearchOptionType.all) {
                onReset(true, true);
                setFiltersClose();
            }
        }, [cancelSearch, toggleSearch, onReset, setFiltersClose, type]);

        const handleSetFilters = useCallback(
            (newFilters: IFilters) => {
                const { type: filters, source: filters_placement } = newFilters;
                const dwh: {
                    search_phrase: string;
                    section: EStorageType;
                    place: string;
                    action_place: string;
                    filters?: ESearchOptionType;
                    filters_placement?: ESearchOptionSource;
                } = {
                    search_phrase: value,
                    section: storage,
                    place: 'web',
                    action_place: 'popup',
                };
                const isReset = filters && filters_placement;
                if (isReset) {
                    dwh.filters = type;
                    dwh.filters_placement = source;
                } else if (filters) {
                    dwh.filters = filters;
                } else if (filters_placement) {
                    dwh.filters_placement = filters_placement;
                }
                sendDwh({
                    eventCategory: ECategoryGa.search,
                    action: isReset || filters_placement ? EActionSearch.filtersPlacement : EActionSearch.filters,
                    dwhData: {
                        ...dwh,
                    },
                });
                setFilters(newFilters);
            },
            [value, storage, setFilters, type, source]
        );

        const handleOnInputClear = useCallback(() => {
            if (value) {
                sendDwh({
                    eventCategory: ECategoryGa.search,
                    action: EActionSearch.searchClear,
                    dwhData: { search_phrase: value, page: storage, place: 'web' },
                });
            }
            onInputClear();
        }, [value, onInputClear, storage]);

        const onInputPaste = useCallback(
            (event: ClipboardEvent<HTMLInputElement>) => {
                pasteData.current = {
                    phrase_before: value,
                    paste: event.clipboardData.getData('text'),
                };
                pasteData.current.wasPasted = true;
            },
            [value]
        );

        useEffect(() => {
            if (pasteData.current.wasPasted) {
                const { phrase_before, paste } = pasteData.current;
                sendDwh({
                    eventCategory: ECategoryGa.search,
                    action: EActionSearch.copyPastText,
                    dwhData: {
                        phrase_before,
                        paste,
                        place: 'web',
                        result_after: value,
                    },
                });
            }
            pasteData.current = {};
        }, [value]);

        return (
            <TransitionWrapper
                ref={rootRef}
                isExpanded={isExpanded}
                onEntered={onTransitionEntered}
                onExited={onTransitionExited}
                data-qa-id="search"
            >
                {isFullyExpanded && (
                    <Button theme="vk" className={styles.button} onClick={handleCloseSearch} qaId="search-reset">
                        <Icon20ChevronLeftOutline />
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-expect-error */}
                        <span style={{ textWrap: 'nowrap' }}>Сбросить поиск</span>
                    </Button>
                )}
                <SearchInput
                    placeholder={placeholder}
                    onClick={handleInputClick}
                    onChange={onInputChange}
                    onPaste={onInputPaste}
                    onBlur={onInputBlur}
                    value={value}
                    onKeyUp={handleKeyUp}
                    style={isExpanded ? inputBorderRadius : undefined} // TODO убрать инлайн-стиль после перехода на 5-ю версию VKUI
                    getRef={searchInputRef}
                    getRootRef={searchRootRef}
                    after={isExpanded && value && <ClearButton onClick={handleOnInputClear} />}
                />
                {isExpanded && (
                    <>
                        {dropdownAvailable && (
                            <TypeSelectButton
                                type={type}
                                source={source}
                                onClick={handleToggleFilters}
                                ref={filtersButtonRef}
                                isOpen={isOpen}
                            />
                        )}
                        <SearchButton loading={longLoad} onClick={onSearchClick} disabled={disabledSearch} />
                        {!isOpen && isFocus && historyItems.length > 0 && (
                            <SearchSuggests
                                rootRef={rootRef}
                                targetRef={searchRootRef}
                                items={historyItems}
                                onClick={searchValue}
                                onClickAway={handleOnClickAway}
                            />
                        )}
                        {dropdownAvailable &&
                            isOpen &&
                            (webSearchContentEnabled ? (
                                <Filters
                                    targetRef={filtersButtonRef}
                                    type={type}
                                    source={source}
                                    setFilters={handleSetFilters}
                                    onClickAway={setFiltersClose}
                                />
                            ) : (
                                <CategoryFilters
                                    targetRef={filtersButtonRef}
                                    query={value}
                                    onClickAway={setFiltersClose}
                                    type={type}
                                    onSelect={onSelect}
                                    onReset={onReset}
                                />
                            ))}
                    </>
                )}
                {isSearchTooltipVisible && searchInputRef?.current && (
                    <SearchTooltip
                        targetRef={searchInputRef}
                        type={searchTooltipType as SearchTooltipTypes}
                        onClose={onSearchTooltipClose}
                    />
                )}
                {isSearchFilterTooltipVisible && isSearchFullyExpanded && filtersButtonRef?.current && (
                    <SearchTooltip
                        targetRef={filtersButtonRef}
                        type={searchFilterTooltipType as SearchTooltipTypes}
                        onClose={onSearchFilterTooltipClose}
                    />
                )}
            </TransitionWrapper>
        );
    }
);

WebSearch.displayName = 'WebSearch';
