import { Spacing, Text, Title } from '@vkontakte/vkui';
import classNames from 'clsx';
import { plural } from 'lib/plural';
import { xray } from 'lib/xray';
import React, { type FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MAIL_HOST } from 'reactApp/appHelpers/configHelpers';
import { getFeatureQuotaCleanerDeletePromo } from 'reactApp/modules/features/features.selectors';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';
import { historyPush } from 'reactApp/modules/router/router.module';
import { startSelecting } from 'reactApp/modules/selections/selections.actions';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { SubscriptionsSelector } from 'reactApp/modules/subscriptions/subscriptions.selectors';
import { setShowUploaderAction } from 'reactApp/modules/uploadList/uploadList.module';
import { getRemoveSnackbarText } from 'reactApp/modules/userQuotaCleaner/helpers/getDeleteSnackbarText';
import { setSuccessMovedToTrash } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.actions';
import {
    getIsItemsDeleteError,
    getIsItemsDeleteProcess,
    isCloudRemoveNeeded,
    isMeesagesRemoveNeeded,
    isSuccessMovedToTrash,
} from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.selectors';
import { store } from 'reactApp/store';
import { UserQuotaGroupId, UserQuotaGroupType } from 'reactApp/types/QuotaCleaner';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { useBuyCleanerTariff } from 'reactApp/ui/QuotaCleanerPage/hooks/useBuyCleanerTariff';
import { QuotaSpacePromo } from 'reactApp/ui/QuotaSpacePromo/QuotaSpacePromo';
import opener from 'reactApp/utils/opener';

import styles from './DeleteConfirmationDialog.css';

export interface DeleteConfirmationDialogProps {
    count: number;
    groupType: UserQuotaGroupType;
    groupId: UserQuotaGroupId;
    isTwoStepLettersDelete: boolean;
    allSelected?: boolean;
    onDelete: (withOutMessagesRemove: boolean, withOutCloudRemove: boolean, withSnackBar: boolean) => void;
    onLettersDelete: () => void;
    onClose: () => void;
    onGroupReload: () => void;
}

const pluralMessage = {
    [UserQuotaGroupType.Letter]: ['письмо', 'письма', 'оно'],
    [UserQuotaGroupType.Cloud]: ['файл', 'файлы', 'он'],
};

const pluralGroupTitle = {
    [UserQuotaGroupId.Mounted]: ['общей папки', 'общих папок', 'общих папок'],
};

const pluralGroupMessage = {
    [UserQuotaGroupId.Mounted]: ['общую папку', 'общие папки', 'общие папки'],
};

const getTitle = (
    groupId: UserQuotaGroupId,
    groupType: UserQuotaGroupType,
    count: number,
    isMessagesRemove: boolean,
    isCloudRemove: boolean
): string => {
    if (groupId === UserQuotaGroupId.Mounted && isCloudRemove) {
        return `Удалить ${count > 1 ? 'папки' : 'папку'} навсегда?`;
    }

    if (groupId === UserQuotaGroupId.Mounted && !isCloudRemove) {
        return `Отключение ${pluralGroupTitle[groupId][plural(count)]}`;
    }

    if (isMessagesRemove || isCloudRemove) {
        return `Удалить ${pluralMessage[groupType][count > 1 ? 1 : 0]} навсегда?`;
    }

    return 'Переместить в корзину\n или удалить?';
};

const getMessage = (
    groupId: UserQuotaGroupId,
    groupType: UserQuotaGroupType,
    count: number,
    isMessagesRemove: boolean,
    isCloudRemove: boolean
) => {
    if (groupId === UserQuotaGroupId.Mounted && isCloudRemove) {
        return `После этого ${count > 1 ? 'их' : 'её'} нельзя будет восстановить`;
    }

    if (groupId === UserQuotaGroupId.Mounted && !isCloudRemove) {
        return `Вы действительно хотите отключить ${pluralGroupMessage[groupId][plural(count)]} от своего Облака?`;
    }

    if (isMessagesRemove || isCloudRemove) {
        return `После этого ${count > 1 ? 'их' : 'его'} нельзя будет восстановить`;
    }

    return `Удалите ${pluralMessage[groupType][count > 1 ? 1 : 0]} сейчас и освободите место. Через ${
        groupType === UserQuotaGroupType.Letter ? '30' : '14'
    } дней ${count > 1 ? 'они удалятся' : `${pluralMessage[groupType][2]} удалится`} из корзины автоматически`;
};

const getDeleteText = (isMessagesRemove: boolean, isCloudRemove: boolean): string => {
    if (isMessagesRemove || isCloudRemove) {
        return `Удалить навсегда`;
    }

    return 'Удалить';
};

const getDeleteCancelText = (isMessagesRemove: boolean, isCloudRemove: boolean): string => {
    if (isMessagesRemove || isCloudRemove) {
        return `Оставить в корзине`;
    }

    return 'Переместить в корзину';
};

export const DeleteConfirmationDialog: FC<DeleteConfirmationDialogProps> = ({
    count,
    groupType,
    groupId,
    isTwoStepLettersDelete,
    allSelected,
    onDelete,
    onLettersDelete,
    onClose,
    onGroupReload,
}) => {
    const dispatch = useDispatch();
    const isPaidUser = useSelector(SubscriptionsSelector.hasMailSubscription);
    const isMessagesRemove = useSelector(isMeesagesRemoveNeeded);
    const isCloudRemove = useSelector(isCloudRemoveNeeded);
    const isMovedToTrash = useSelector(isSuccessMovedToTrash);
    const isItemsDeleteProcess = useSelector(getIsItemsDeleteProcess);
    const isItemsDeleteError = useSelector(getIsItemsDeleteError);

    const isMobile = useSelector(QuotaLandingSelectors.isMobile);

    const { tariffPrice, tariffValue, onBuy } = useBuyCleanerTariff();
    const isQuotaPromo = useSelector(getFeatureQuotaCleanerDeletePromo);

    const title = getTitle(groupId, groupType, count, isMessagesRemove, isCloudRemove);
    const message = getMessage(groupId, groupType, count, isMessagesRemove, isCloudRemove);
    const deleteText = getDeleteText(isMessagesRemove, isCloudRemove);
    const cancelText = getDeleteCancelText(isMessagesRemove, isCloudRemove);

    const prefix = isMessagesRemove || isCloudRemove ? 'rmv' : 'trash';

    const onSubmit = useCallback(() => {
        if (isMessagesRemove) {
            onLettersDelete();
        } else {
            onDelete(false, false, false);
        }

        if ((isTwoStepLettersDelete && isMessagesRemove) || !isTwoStepLettersDelete || isCloudRemove || allSelected) {
            onClose();
        }

        xray.send(`quota-cln-dlte-dlg-${prefix}-sbmt${isMobile ? '-mob' : ''}`, { i: groupId });
    }, [groupId, groupType, onDelete, onClose, isMessagesRemove, isCloudRemove, isTwoStepLettersDelete, onLettersDelete]);

    const onCloseModal = useCallback(() => {
        if (isMovedToTrash) {
            store.dispatch(
                showSnackbarAction({
                    id: 'quotaCleanerDeleteSuccess',
                    type: SnackbarTypes.trashbin,
                    text: getRemoveSnackbarText(count, groupType, groupId),
                    closable: true,
                    buttonText: isMobile ? 'Перейти' : 'Перейти в корзину',
                    onButtonClick: () => {
                        if (groupType === UserQuotaGroupType.Letter) {
                            opener(`https://${MAIL_HOST}/trash`);
                            return;
                        }
                        store.dispatch(historyPush({ id: `/${EStorageType.trashbin}` }));
                    },
                    buttonRight: isMobile,
                })
            );
            store.dispatch(setSuccessMovedToTrash({ value: false }));
        }

        if (
            (isMessagesRemove && isTwoStepLettersDelete && groupId !== UserQuotaGroupId.MailBin) ||
            (isCloudRemove && groupId !== UserQuotaGroupId.CloudBin)
        ) {
            onGroupReload();
            if (isMobile) {
                dispatch(setShowUploaderAction(false));
                dispatch(startSelecting());
            }
        }

        xray.send(`quota-cln-dlte-dlg-${prefix}-cls${isMobile ? '-mob' : ''}`, { i: groupId });
        onClose();
    }, [onClose, isMessagesRemove, isCloudRemove, isTwoStepLettersDelete, isMovedToTrash]);

    const onCancel = useCallback(() => {
        if (!isMessagesRemove && groupId !== UserQuotaGroupId.MailBin && !isCloudRemove && groupId !== UserQuotaGroupId.CloudBin) {
            xray.send(`quota-cln-dlte-dlg-${prefix}-trash-sbmt${isMobile ? '-mob' : ''}`, { i: groupId });
            onDelete(true, true, true);
        }
        onCloseModal();
    }, [isMessagesRemove, isCloudRemove, onCloseModal, onDelete]);

    useEffect(() => {
        xray.send(`quota-cln-dlte-dlg-${prefix}-shw${isMobile ? '-mob' : ''}`, { i: groupId });
    }, [isMessagesRemove]);

    useEffect(() => {
        if (isItemsDeleteError) {
            onClose();
        }
    }, [isItemsDeleteError]);

    const content = (
        <div
            className={classNames(styles.wrapper, {
                [styles.wrapper_mobile]: isMobile,
                [styles.wrapper_remove]: (isMessagesRemove || isCloudRemove) && !isMobile,
            })}
        >
            <div className={styles.title}>
                <Title level={isMobile ? '2' : '1'} weight="2">
                    {title}
                </Title>
                <Spacing size={12} />
                <Text Component="h6" weight="3">
                    {message}
                </Text>
                <Spacing size={24} />
            </div>
            {isQuotaPromo && !isPaidUser && tariffPrice && (
                <>
                    <QuotaSpacePromo tariffPrice={tariffPrice} tariffValue={tariffValue} onBuy={onBuy} />
                    <Spacing size={32} />
                </>
            )}
            <div className={classNames(styles.buttons, { [styles.buttons_mobile]: isMobile })}>
                <Button
                    theme="octavius"
                    sizeMode={isMobile ? ButtonSizeMode.big : ButtonSizeMode.small}
                    error
                    onClick={onSubmit}
                    qaId="delete-btn"
                    disabled={isItemsDeleteProcess}
                >
                    <Text Component="h6" weight="2" style={{ color: 'var(--vkui--color_button_contrast)' }}>
                        {deleteText}
                    </Text>
                </Button>
                <div className={classNames(styles.cancel, { [styles.cancel_mobile]: isMobile })}>
                    <Button
                        style={{ width: '100%' }}
                        theme="octavius"
                        sizeMode={isMobile ? ButtonSizeMode.big : ButtonSizeMode.small}
                        secondary
                        onClick={onCancel}
                        qaId="cancel-btn"
                        disabled={isItemsDeleteProcess}
                    >
                        <Text Component="h6" weight="2">
                            {cancelText}
                        </Text>
                    </Button>
                </div>
            </div>
        </div>
    );

    return isMobile ? (
        <MobileDialog
            id={`quota-delete-confirm:${groupType}:${prefix}`}
            closeOnDimmerClick={!(isMessagesRemove || isCloudRemove)}
            closable={!(isMessagesRemove || isCloudRemove)}
            mod="base"
            topmost
            onClose={onCloseModal}
            disableScrolling={false}
        >
            {content}
        </MobileDialog>
    ) : (
        <Dialog
            open
            id={`quota-delete-confirm:${groupType}:${prefix}`}
            size="small"
            closable={!(isMessagesRemove || isCloudRemove)}
            closeOnDimmerClick={!(isMessagesRemove || isCloudRemove)}
            closeOnEscape={!(isMessagesRemove || isCloudRemove)}
            onCancel={onCloseModal}
        >
            {content}
        </Dialog>
    );
};
