import classNames from 'clsx';
import React, { type ReactElement, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isUseO2Token } from 'reactApp/api/O2AuthClient';
import type { CloudFile } from 'reactApp/modules/storage/storage.types';
import { CONTENT_LOAD_TIMEOUT } from 'reactApp/ui/ReactViewer/ReactViewer.constants';
import { useO2AuthedSource } from 'reactApp/ui/ReactViewer/ViewerPdf/ViewerPdf.helper';
import { Spinner } from 'reactApp/ui/Spinner/Spinner';
import { useStandardAnalytics } from 'reactApp/utils/analytics';
import { PdfError, PdfEvents, StandardPrefix } from 'reactApp/utils/analytics/types';
import { getAttachType } from 'reactApp/utils/downloadDocumentHelpers';

import styles from './ViewerPdf.css';

export interface ViewerPdfProps {
    url: string;
    renderError?: () => ReactElement | null;
    fillParent?: boolean;
    file?: CloudFile;
    enableViewerMenu?: boolean;
    onError?: (msg: string) => void;
    onOpen?: () => void;
}

export const ViewerPdf = memo<ViewerPdfProps>(({ url, file, enableViewerMenu, onError, onOpen, renderError = () => null, fillParent }) => {
    const ref = useRef<number | undefined>();

    const [errorMsg, setErrorMsg] = useState<PdfError | null>(null);
    const [isLoaded, setLoaded] = useState(false);
    const attachType = useMemo(() => getAttachType(file), [file]);

    const showSpinner = !isLoaded;

    const handleError = useCallback(
        (error: PdfError) => {
            setErrorMsg(error);
            setLoaded(true);
        },
        [setErrorMsg, setLoaded]
    );

    const handleIframeError = useCallback(() => {
        handleError(PdfError.IFRAME);
    }, [handleError]);

    const { sendRadar } = useStandardAnalytics(StandardPrefix.PDF, file);

    const handleLoad = useCallback(() => {
        if (ref.current) {
            clearTimeout(ref.current);
        }

        if (!isLoaded) {
            sendRadar(PdfEvents.LOAD_TIME, true);
        }

        onOpen?.();
        setLoaded(true);
    }, [isLoaded, onOpen, sendRadar]);

    useEffect(() => {
        if (ref.current) {
            clearTimeout(ref.current);
        }

        ref.current = window.setTimeout(() => handleError(PdfError.TIMEOUT), CONTENT_LOAD_TIMEOUT);

        return () => {
            if (ref.current) {
                clearTimeout(ref.current);
            }
        };
    }, [url, handleError]);

    useEffect(() => {
        if (errorMsg) {
            sendRadar(errorMsg, false, true);
            onError?.(errorMsg);
        }
    }, [attachType, errorMsg, onError, sendRadar]);

    const o2AuthedPdf = useO2AuthedSource(url, handleIframeError);
    const authedSrc = isUseO2Token() ? o2AuthedPdf : url;

    if (errorMsg) {
        return renderError();
    }

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_fillParent]: fillParent,
                [styles.root_menu]: enableViewerMenu,
            })}
            data-qa-id="react-pdf-viewer"
        >
            {authedSrc && (
                <iframe
                    src={authedSrc}
                    className={styles.pdf}
                    allowFullScreen
                    allow="autoplay; fullscreen"
                    frameBorder={0}
                    onError={handleIframeError}
                    onLoad={handleLoad}
                />
            )}
            {showSpinner && <Spinner />}
        </div>
    );
});

ViewerPdf.displayName = 'ViewerPdf';

ViewerPdf.whyDidYouRender = true;
