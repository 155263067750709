import React, { type FC, type ReactElement, useRef } from 'react';
import { DocumentUpload } from 'reactApp/sections/NewPersonalDocuments/DocumentUpload/DocumentUpload';
import type { EDocumentType } from 'reactApp/sections/PersonalDocuments/PersonalDocuments.constants';

interface Props {
    className?: string;
    icon: ReactElement;
    title: string;
    type: EDocumentType;
    onUpload?: () => void;
}

export const UploadItem: FC<Props> = ({ className, icon, title, type, onUpload }) => {
    const ref = useRef<HTMLLIElement>(null);
    const uploadRef = useRef<HTMLInputElement>(null);

    return (
        <li
            className={className}
            tabIndex={0}
            ref={ref}
            onClick={() => {
                uploadRef.current?.click();
                ref.current?.blur();
            }}
        >
            {icon}
            {title}
            <DocumentUpload type={type} ref={uploadRef} onUpload={onUpload} />
        </li>
    );
};
