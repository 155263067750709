import React, { useMemo } from 'react';
import { FastCheckoutSidebar } from 'reactApp/components/VkBuyModalWithSidebar/FastCheckoutSidebar/FastCheckoutSidebar';
import { TrialPromoSidebar } from 'reactApp/components/VkBuyModalWithSidebar/TrialPromoSidebar/TrialPromoSidebar';
import styles from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar.css';
import { type Options, EPaymentModalType } from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar.types';
import type { Product, Tariff } from 'reactApp/types/Billing';
import { PaymentFooter } from 'reactApp/ui/PaymentFooter/PaymentFooter';

interface HookProps {
    type: EPaymentModalType;
    product: Product;
    changeProduct: (id: string) => void;
    onClickTariffs: () => void;
    tariff: Tariff;
    options?: Options;
    featuresList?: string[];
    isFrame?: boolean;
    title?: string;
    hideOtherTariffs?: boolean;
    hideSwitchSidebarBlock?: boolean;
}

export const useContent = ({
    type,
    product,
    changeProduct,
    onClickTariffs,
    tariff,
    options,
    featuresList,
    isFrame,
    title,
    hideOtherTariffs = false,
    hideSwitchSidebarBlock = false,
}: HookProps) => {
    const sidebar = useMemo(() => {
        if (type === EPaymentModalType.trial || type === EPaymentModalType.attachesTrial) {
            return <TrialPromoSidebar product={product} options={options} />;
        }

        if (type === EPaymentModalType.fastcheckout) {
            return (
                <FastCheckoutSidebar
                    product={product}
                    onProductChange={changeProduct}
                    onClickTariffs={onClickTariffs}
                    tariff={tariff}
                    featuresList={featuresList}
                    isBuy={isFrame}
                    hideOtherTariffs={hideOtherTariffs}
                    hideSwitchSidebarBlock={hideSwitchSidebarBlock}
                />
            );
        }
    }, [type, product, changeProduct, onClickTariffs, tariff, isFrame, hideOtherTariffs]);

    const header = useMemo(() => {
        if (title) {
            return <div className={styles.header}>{title}</div>;
        }

        if (type === EPaymentModalType.fastcheckout) {
            return <div className={styles.header}>Подписка Mail Space</div>;
        }
    }, [type, title]);

    const footer = useMemo(() => {
        if (type === EPaymentModalType.fastcheckout) {
            return <PaymentFooter productId={product?.id} />;
        }
    }, [product?.id, type]);

    return {
        sidebar,
        header,
        footer,
    };
};
