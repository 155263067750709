/* eslint-disable max-lines */
import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { Icon20CloudOutline } from '@vkontakte/icons';
import { Caption, Spacing, Title } from '@vkontakte/vkui';
import classNames from 'clsx';
import tariffPersonal from 'img/quota-landing/tariff-personal.png';
import tariffPersonal256 from 'img/quota-landing/tariff-personal-256.png';
import tariffStudy from 'img/quota-landing/tariff-study.png';
import tariffWork from 'img/quota-landing/tariff-work.png';
import { once } from 'ramda';
import React, { type ReactEventHandler, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import {
    crossedFullPriceInTariffCard,
    desktopTariffMinSize,
    isRebrandingQuotaLanding,
    moreBenefitsInExpensiveTariffs,
} from 'reactApp/appHelpers/featuresHelpers';
import { autumnPromo } from 'reactApp/appHelpers/featuresHelpers/features/autumnPromo';
import { blackFriday2024 } from 'reactApp/appHelpers/featuresHelpers/features/blackFriday2024';
import { cheapTariffOnlyYearDesktop } from 'reactApp/appHelpers/featuresHelpers/features/cheapTariffOnlyYear';
import { compactFirstScroll } from 'reactApp/appHelpers/featuresHelpers/features/compactFirstScroll';
import { cyberMonday2025 } from 'reactApp/appHelpers/featuresHelpers/features/cyberMonday2025';
import { isPromoQuotaWithLitress } from 'reactApp/appHelpers/featuresHelpers/features/litres';
import { tariffCardPersonal } from 'reactApp/appHelpers/featuresHelpers/features/tariifCardPersonal';
import { upsaleBonus } from 'reactApp/appHelpers/featuresHelpers/features/upsaleBonus';
import { upsaleWithTrial } from 'reactApp/appHelpers/featuresHelpers/features/upsaleWithTrial';
import { checkAdditionalBenefitsInSpace } from 'reactApp/constants/products/benefits.bySpace';
import { getAdditionalBenefitsCountText } from 'reactApp/constants/products/quotaFeatures';
import { SPACE_LIST } from 'reactApp/constants/spaceList';
import { useMergeRefs } from 'reactApp/hooks/useMergeRefs';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getPaySource } from 'reactApp/modules/payment/payment.analytics';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { AUTOUPLOAD_10TB_REGEX } from 'reactApp/modules/products/products.helpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { AUTOUPLOAD_TOOLTIP_ID, AutouploadTooltip } from 'reactApp/sections/QuotaLanding/AutouploadTooltip/AutouploadTooltip';
import { MODAL_TARIFF_BENEFITS_PAY_SOURCE } from 'reactApp/sections/QuotaLanding/DetailedTariffBenefitsPopup/DetailedTariffBenefitsPopup.constants';
import { useIntersecObsrWithParams } from 'reactApp/sections/QuotaLanding/hooks/useIntersecObsrWithParams';
import { useLitresTooltip } from 'reactApp/sections/QuotaLanding/LitresTooltip/hooks/useLitresTooltip';
import { LITRES_TOOLTIP_ID, LitresTooltip } from 'reactApp/sections/QuotaLanding/LitresTooltip/LitresTooltip';
import { sendQuotaBlockViewDWH, sendQuotaGa, sendXrayWithPlatform } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { useBuyClick } from 'reactApp/sections/QuotaLanding/TariffsPlans/TariffPlansCard/useBuyClick';
import { renderUpsaleBonusModal } from 'reactApp/sections/QuotaLanding/UpsaleBonusDialog/UpsaleBonusDialog.helpers';
import type { Product } from 'reactApp/types/Billing';
import { ButtonLink } from 'reactApp/ui/ButtonLink/ButtonLink';
import { TariffCard } from 'reactApp/ui/TariffCardNew/TariffCard';
import { ETariffCardSizeButtonSize, ETariffCardWidth } from 'reactApp/ui/TariffCardNew/TariffCard.types';
import { useButtonAdditionalText } from 'reactApp/ui/TariffSectionNew/hooks/useButtonAdditionalText';
import { getFeaturesBySpace, useFeatures as getFeatures } from 'reactApp/ui/TariffSectionNew/hooks/useFeatures';
import { usePrice } from 'reactApp/ui/TariffSectionNew/hooks/usePrice';
import { useSwitchProducts } from 'reactApp/ui/TariffSectionNew/hooks/useSwitchProducts';
import { useTariffLabel } from 'reactApp/ui/TariffSectionNew/hooks/useTariffLabel';
import { ETabsName } from 'reactApp/ui/TariffSectionNew/TariffSection.constants';
import { noopVoid } from 'reactApp/utils/helpers';
import { isMonthPeriod, isYearPeriod } from 'reactApp/utils/Period';

import { useTariffBorder } from './hooks/useTariffBorder';
import styles from './TariffPlansCard.css';
import type { Props } from './TariffPlansCard.types';

export const TariffInfoBlock = ({
    newQuota,
    isBase,
    isCheapest = false,
    isMobile,
    isSkeleton = false,
}: {
    newQuota: number;
    isBase?: boolean;
    isMobile?: boolean;
    isSkeleton?: boolean;
    isCheapest?: boolean;
}) => {
    // tempexp_16800-next_line
    let size = moreBenefitsInExpensiveTariffs === 'd' ? 12 : 24;
    size = isRebrandingQuotaLanding ? 36 : isCheapest ? 18 : size;
    if (compactFirstScroll) {
        size = 26;
    }
    return (
        <>
            {!isMobile && <Spacing size={size} />}
            {isCheapest ? (
                <Title level="1" weight="2">
                    Без рекламы
                </Title>
            ) : isBase ? (
                <Spacing size={isRebrandingQuotaLanding ? 36 : 32} />
            ) : (
                <div className={classNames(styles.infoBlock, { [styles.infoBlock_rebranding]: isRebrandingQuotaLanding })}>
                    {isSkeleton ? (
                        <div className={styles.skeleton} />
                    ) : (
                        <>
                            <Icon20CloudOutline />{' '}
                            <Caption
                                level={'1'}
                                weight={isRebrandingQuotaLanding ? '3' : '2'}
                                className={classNames({
                                    [styles.caption_rebranding]: isRebrandingQuotaLanding,
                                    [styles.caption_rebrandingMobile]: isRebrandingQuotaLanding && isMobile,
                                })}
                            >
                                {`${isRebrandingQuotaLanding ? 'Общий объём составит' : 'Ваш объём составит'} ${
                                    bytesToNDigits(newQuota, 3).value
                                }`}
                            </Caption>
                        </>
                    )}
                </div>
            )}
            {isMobile && <Spacing size={isRebrandingQuotaLanding ? 24 : 30} />}
        </>
    );
};

export const TariffPlansCard = memo<Props>(
    ({
        tariff,
        isVisible = true,
        isIOSPromo = false,
        isAndroidPromo = false,
        setCarouselLoaded,
        isMobilePayAvailable,
        isMobilePayFromGetParam,
        activeTab,
        onTariffClick,
    }) => {
        const { isLoaded: isQuotaLoaded } = useSelector(UserQuotaSelectors.getLoadingState);
        const userQuota = useSelector(UserQuotaSelectors.getTotalOrBase);
        const isAnonymous = useSelector(UserSelectors.isAnonymous);
        const isMobile = EnvironmentSelectors.isMobile();
        const isPaidUser = useSelector(UserSelectors.isPaidUser);

        const { tariffId, products, isBase, isCheapest, isRecommended, tariffPromoLabelType } = tariff;

        const { showSwitch, product, onClickSwitch, switchChecked, switchOptions, yearSavingDiscount, monthSavingDiscount } =
            useSwitchProducts({
                products,
                activeTab,
                withSwitch: false,
            });
        const monthDiscountPercent = crossedFullPriceInTariffCard.month && isMonthPeriod(product.period) ? monthSavingDiscount : 0;

        // tempexp_16481-start
        const dispatch = useDispatch();
        const [showTooltip, setShowTooltip] = useState<boolean>(false);
        const tooltipRef = useRef<HTMLDivElement | null>(null);
        const isProductTooltip = AUTOUPLOAD_10TB_REGEX.test(product.id);
        const initTooltip = showTooltip && isProductTooltip && !isMobile;
        const tooltipShown = dispatch(UserStorageActions.get(AUTOUPLOAD_TOOLTIP_ID));
        // tempexp_16481-end

        const [_, discount, __, productMonthPriceAsText, defaultMonthPrice] = usePrice({
            product,
            periodDivider: 'в',
        });
        const primaryLabel = isCheapest ? 'Тариф для Почты' : 'Выгодно';
        const [showLabel, label, labelKind] = useTariffLabel({
            product,
            skipDefaultLabel: true,
            isRecommended: isRecommended && !discount,
            primaryLabel: isRecommended ? primaryLabel : '',
            isBase,
            showDiscount: false,
            tariffPromoLabelType,
            /* tempexp_17509-start */
            isFromLitresSecondary: isPromoQuotaWithLitress && !isPaidUser && !isRecommended,
            isFromLitresPrimary: isPromoQuotaWithLitress && !isPaidUser && isRecommended,
            /* tempexp_17509-end */
            /* TODO: Добавить универсальный параметр для акционного тарифа */
            isBlackFriday2024Tariff: blackFriday2024 && isRecommended,
            isCyberMonday2025Tariff: cyberMonday2025 && isRecommended,
            /* tempexp_18084-next-line */
            isTariffCardPersonal: tariffCardPersonal && (tariffCardPersonal === 'b' || tariffCardPersonal === 'c'),
        });

        let [featureList] = (() => {
            const [features] = getFeatures(
                product,
                true,
                true,
                /* tempexp_16800-start */
                !isMobile ? getFeaturesBySpace() : undefined
                /* tempexp_16800-end */
            );

            return isRebrandingQuotaLanding
                ? [features.map((feature) => (feature.key === 'family' ? { ...feature, text: 'Добавление ещё 3 участников' } : feature))]
                : [features];
        })();

        const buttonAdditionalText = useButtonAdditionalText(product, crossedFullPriceInTariffCard);

        const showCrossedPrice =
            (isYearPeriod(product.period) && crossedFullPriceInTariffCard.year && product?.discountPrice) ||
            (isMonthPeriod(product.period) && crossedFullPriceInTariffCard.month && product?.discountPrice);

        // tempexp_17371-next-line
        const isOnlyYearTariff = cheapTariffOnlyYearDesktop === 'b' && tariff.space.space === desktopTariffMinSize;

        const onBuyClick = useBuyClick({
            isMobilePayAvailable,
            isMobilePayFromGetParam,
            isIOSPromo,
            isAndroidPromo,
            enabledMidasPayment: true,
        });

        const borderType = useTariffBorder({ quota: tariff.space.original });

        const onButtonClick = useCallback(
            (event, paySource) => {
                event.stopPropagation();

                // tempexp_18090-next-line
                onTariffClick?.();

                // tempexp_17360-start
                if (upsaleBonus?.variant && isMonthPeriod(product.period) && !autumnPromo && !isPaidUser) {
                    const monthProductSpace = product.space.space;
                    const bonusProductId = upsaleBonus.tariffs[monthProductSpace];
                    if (bonusProductId) {
                        renderUpsaleBonusModal({
                            monthProduct: product,
                            bonusProductId,
                        });

                        return;
                    }
                }
                // tempexp_17360-end

                if (
                    !blackFriday2024 &&
                    !cyberMonday2025 &&
                    !autumnPromo &&
                    upsaleWithTrial?.tariffs?.length &&
                    !isPaidUser &&
                    isMonthPeriod(product.period)
                ) {
                    openPopupHelper({
                        popupName: popupNames.UPSALE_WITH_TRIAL,
                        data: {
                            monthProduct: products.monthProduct,
                            yearProduct: products.yearProduct,
                            yearSavingDiscount,
                            activePeriod: upsaleWithTrial.activePeriod,
                            resolve: (product: Product) => {
                                // tempexp_16481-next-line
                                onBuyClick({ product, paySource: 'modal_choose_tariff', space: product.space.space });
                            },
                        },
                    });

                    return;
                }

                onBuyClick({
                    product,
                    // tempexp_16481-next-line
                    space: product.space.space,
                    paySource,
                });
            },
            [
                isPaidUser,
                product.period,
                product.space.space,
                product.id,
                product.price,
                products.monthProduct,
                products.yearProduct,
                yearSavingDiscount,
                onBuyClick,
                onTariffClick,
            ]
        );

        const onTariffCardClick: ReactEventHandler = useCallback(
            (event) => {
                const source = getPaySource('tariff_card');
                onButtonClick(event, source);
            },
            [onButtonClick]
        );

        const sendAdvantagesClickAnalytic = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (!e || e.target instanceof HTMLAnchorElement) {
                emitAnalyticEvent(AnalyticEventNames.TARIFF_ADVANTAGES_CLICK);
                sendQuotaGa({
                    action: 'tariff',
                    label: 'benefits_click',
                    tariff: product.id,
                    platform: 'web',
                });
            }
        };
        if (!isMobile && moreBenefitsInExpensiveTariffs === 'a') {
            featureList = featureList.map((feature) => {
                if (feature.key === 'benefits') {
                    return {
                        ...feature,
                        text: <div onClick={sendAdvantagesClickAnalytic}>{feature.text}</div>,
                    };
                }
                return feature;
            });
        }
        const hasAdditionalBenefits = checkAdditionalBenefitsInSpace(product.space.original);
        // tempexp_16800-start
        if (!isMobile && moreBenefitsInExpensiveTariffs === 'b' && hasAdditionalBenefits) {
            const clickHandler = () => {
                sendAdvantagesClickAnalytic();
                const popupHelper = openPopupHelper({
                    popupName: popupNames.DETAILED_TARIFF_BENEFITS,
                    data: {
                        product,
                        buttonText: `Купить за ${productMonthPriceAsText}`,
                        onButtonClick: (e) => {
                            onButtonClick(e, MODAL_TARIFF_BENEFITS_PAY_SOURCE);
                            popupHelper?.closeDialog();
                        },
                    },
                });
            };
            featureList = featureList.map((feature) => {
                if (feature.key === 'benefits') {
                    return {
                        ...feature,
                        text: (
                            <>
                                Дополнительно ещё
                                <br />
                                <ButtonLink
                                    onClick={clickHandler}
                                    hoverUnderline={false}
                                    fontInherit
                                    primary
                                    className={styles.benefitsLink}
                                >
                                    {getAdditionalBenefitsCountText(product)}
                                </ButtonLink>
                            </>
                        ),
                    };
                }
                return feature;
            });
        }
        const buttonInFooter = !isBase && moreBenefitsInExpensiveTariffs !== 'c';
        // tempexp_16800-end

        useEffect(() => {
            /** Это сделано именно здесь для того, чтобы дожидаться рендера тарифов со всем контентом внутри
             * для корректного скролла по якорю из ссылки на лендинг единой квоты
             * В родительских элементах useEffect срабатывает слишком рано, до формирования листа тарифов */
            if (isMobile) {
                return;
            }

            // tempexp_16481-start
            if (!tooltipShown && product.space.original === SPACE_LIST.tb_2) {
                setTimeout(() => {
                    setShowTooltip(true);
                }, 3000);
            }

            if (isProductTooltip) {
                sendQuotaBlockViewDWH(product.id);
                sendXrayWithPlatform(['autoupload-10tb', `tariff-view-${product.space.space}`]);
            }
            // tempexp_16481-end

            setCarouselLoaded?.(true);
        }, []);

        const onIntersecting = useCallback(
            once(() => emitAnalyticEvent(AnalyticEventNames.TARIFF_SHOWN, { product })),
            [product.id]
        );

        const refIntersection = useIntersecObsrWithParams({
            onIntersecting,
            isMobile,
        });

        const { space, units } = isCheapest ? tariff.space : product.space;

        let buttonInFooterGap = 36;
        if (buttonInFooter) {
            if (moreBenefitsInExpensiveTariffs === 'b') {
                buttonInFooterGap = hasAdditionalBenefits ? 36 : 54;
            }
            if (moreBenefitsInExpensiveTariffs === 'd') {
                buttonInFooterGap = 23;
            }
        }
        if (isCheapest) {
            buttonInFooterGap = 28;
        }
        if (isRebrandingQuotaLanding) {
            buttonInFooterGap = 0;
        }
        /* tempexp_17509-start */
        const { litresTooltipRef, initLitresTooltip, setShowLitresTooltip } = useLitresTooltip(
            LITRES_TOOLTIP_ID,
            product.space.original === SPACE_LIST.tb_2
        );
        /* tempexp_17509-end */

        /* tempexp_18084-start */
        const personalImage = useMemo(() => {
            const quota = tariff.space.original;

            if (quota === SPACE_LIST.gb_256) {
                return tariffPersonal256;
            }

            if (quota === SPACE_LIST.gb_512) {
                return tariffStudy;
            }

            if (quota === SPACE_LIST.tb_1) {
                return tariffPersonal;
            }

            if (quota === SPACE_LIST.tb_2) {
                return tariffWork;
            }

            return;
        }, [tariff.space.space]);
        /* tempexp_18084-end */

        return (
            <div
                className={classNames(styles.tariffCard, {
                    [styles.tariffCard_invisible]: !isVisible,
                    [styles.tariffCard_rebranding]: isRebrandingQuotaLanding,
                })}
                ref={useMergeRefs(litresTooltipRef, tooltipRef, refIntersection)}
            >
                {/* tempexp_18084-start */}
                {personalImage && tariffCardPersonal === 'c' && <img src={personalImage} alt="Тариф" className={styles.personalImage} />}
                {/* tempexp_18084-start */}
                {/* tempexp_16481-next-line */}
                {initTooltip && <AutouploadTooltip ref={tooltipRef} onClose={setShowTooltip} />}
                {/* tempexp_17509-start */}
                {initLitresTooltip && <LitresTooltip ref={litresTooltipRef} onClose={setShowLitresTooltip} />}
                {/* tempexp_17509-end */}
                <TariffCard
                    onClick={noopVoid}
                    dataQa="QuotaLpTariffCard"
                    tariffId={tariffId}
                    width={ETariffCardWidth.fluid}
                    /* tempexp_18084-next-line */
                    focused={isRecommended && (!tariffCardPersonal || tariffCardPersonal === 'a')}
                    /* tempexp_17509-next-line */
                    isFromLitres={isPromoQuotaWithLitress && !isPaidUser}
                    /* tempexp_18084-next-line */
                    buttonPrimary={isRecommended && (!tariffCardPersonal || tariffCardPersonal === 'a')}
                    buttonDisabled={isBase}
                    buttonText={
                        <div>
                            {showCrossedPrice && (
                                <span
                                    className={classNames(styles.crossedOldPrice, {
                                        [styles.crossedOldPriceFocuced]: isRecommended,
                                    })}
                                >
                                    {defaultMonthPrice}
                                </span>
                            )}
                            {productMonthPriceAsText}
                        </div>
                    }
                    onClickButton={onTariffCardClick}
                    space={{
                        space,
                        units,
                    }}
                    featureListOptions={featureList}
                    showSpaceWithPlus={!isCheapest}
                    isRebrandingQuotaLanding={isRebrandingQuotaLanding}
                    compact
                    accentFeature
                    buttonPrimaryLight
                    disable={Boolean(isBase)}
                    withoutButton={Boolean(isBase)}
                    //   tempexp_16800-start
                    compactFeatureList={moreBenefitsInExpensiveTariffs === 'c' || moreBenefitsInExpensiveTariffs === 'd'}
                    stretchInfoBlock={moreBenefitsInExpensiveTariffs === 'c' || moreBenefitsInExpensiveTariffs === 'd'}
                    buttonInInfoBlock={moreBenefitsInExpensiveTariffs === 'c'}
                    //   tempexp_16800-end
                    buttonInFooter={buttonInFooter}
                    buttonInFooterGap={buttonInFooterGap}
                    buttonSize={ETariffCardSizeButtonSize.big}
                    buttonAdditionalText={buttonAdditionalText}
                    footerText={
                        isBase ? (
                            <div>
                                <Spacing size={42} />
                                <Title level="3">Бесплатно</Title>
                                <Spacing size={20} />
                            </div>
                        ) : (
                            <></>
                        )
                    }
                    infoBlock={
                        <TariffInfoBlock
                            isCheapest={isCheapest}
                            isBase={isBase}
                            newQuota={userQuota + product.space.original}
                            isSkeleton={!isAnonymous && !isQuotaLoaded}
                        />
                    }
                    savingInButton={isMonthPeriod(product.period) ? monthDiscountPercent : yearSavingDiscount}
                    headerPrimaryText=""
                    {...(isRecommended
                        ? {
                              action: true,
                          }
                        : null)}
                    {...(isBase
                        ? {
                              headerPrimaryText: 'Бесплатный',
                              headerPrimaryTextLarge: true,
                              showSpaceWithPlus: false,
                          }
                        : null)}
                    {...(showSwitch
                        ? {
                              onClickSwitch,
                              switchChecked,
                              switchOptions,
                          }
                        : null)}
                    {...(showLabel
                        ? {
                              label,
                              labelKind,
                          }
                        : null)}
                    {...(isProductTooltip && { onLabelClick: setShowTooltip })}
                    {...(isPromoQuotaWithLitress && !isPaidUser && { onLabelClick: setShowLitresTooltip })}
                    hideButtonInMonthTariff={isOnlyYearTariff && activeTab === ETabsName.month}
                    borderType={borderType}
                    /* tempexp_18084-next-line */
                    withInfoBlock={!(tariffCardPersonal === 'c')}
                />
            </div>
        );
    }
);

TariffPlansCard.displayName = 'TariffPlansCard';
