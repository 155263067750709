import { Spacing, Text } from '@vkontakte/vkui';
import React, { type RefObject, memo } from 'react';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';

import styles from './TooltipShort.css';

interface Props {
    target: RefObject<HTMLDivElement | HTMLInputElement> | null;

    onClick(): void;

    onClose(): void;
}

export const TooltipShort = memo(({ target, onClick, onClose }: Props) => {
    return (
        <FloatingTooltip
            onClose={onClose}
            target={target}
            placement={ETooltipPlacement.rightStart}
            qaId="public-download-disable"
            withinModal
            closeOnOutsideClick
            usePortal
            closable={false}
        >
            <div className={styles.root}>
                <Text className={styles.tooltipText}>
                    Настройка доступна с<br />
                    подпиской Mail Space
                </Text>
                <Spacing size={8} />
                <Button theme="vk" sizeMode={ButtonSizeMode.small} className={styles.tooltipButton} onClick={onClick}>
                    {'Попробовать за 1 ₽'}
                </Button>
            </div>
        </FloatingTooltip>
    );
});

TooltipShort.displayName = 'TooltipShort';
