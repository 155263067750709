import { isFeature } from 'Cloud/Application/FeaturesEs6';
import config from 'Cloud/config';
import { logger } from 'lib/logger';
import { saveMainPageSettings } from 'reactApp/api/axios.corsapi';
import { IS_PHONE_BROWSER, platform, THEME } from 'reactApp/appHelpers/configHelpers';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { changeTheme, storeCurrentTheme, WhitelineTheme } from 'reactApp/modules/theme/theme.module';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { sendDwh, sendXray } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';
import { channel } from 'redux-saga';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';

const isPaidUser = config.get('PAID_USER');

function* handleChangeTheme(action: ReturnType<typeof changeTheme>) {
    const theme = action.payload;
    const { totalSize, freeSize } = yield select(UserQuotaSelectors.getSpace);

    const isAnonymous = yield select(UserSelectors.isAnonymous);
    const email = yield select(UserSelectors.getEmail);

    sendDwh({
        action: 'switching',
        eventCategory: 'topic',
        dwhData: {
            condition: theme,
            platform,
            paid_user: isPaidUser,
            free_quota: freeSize,
            size_quota: totalSize,
        },
    });

    if (!isAnonymous && email) {
        try {
            yield saveMainPageSettings({ email, settings: { theme } });
        } catch (error) {
            logger.error('Ошибка переключения темы: ', error);
        }
    }

    /**
     * Если темы в THEME будут равны,
     * то перезагружать нет смысла,
     * значит со стороны SSR пришла уже корректная версия темы
     */
    if (THEME !== theme) {
        window.location.reload();
    }
}

export function* initThemeSaga() {
    if (IS_PHONE_BROWSER || !isFeature('theme-switcher')) {
        return;
    }

    const isSystemDark = window.matchMedia('(prefers-color-scheme: dark)')?.matches;
    const storage = yield select(getCurrentStorage);

    sendDwh({
        eventCategory: ECategoryGa.entered,
        action: 'page-entered-theme',
        dwhData: {
            condition: THEME,
            current_theme: THEME === 'dark' || (THEME === 'system' && isSystemDark) ? 'dark' : 'light',
            source: storage,
        },
    });

    sendXray(['selected-theme', THEME]);

    yield call(checkThemeFromQuery);

    if (THEME !== 'system') {
        return;
    }

    try {
        if (isSystemDark) {
            yield put(storeCurrentTheme('dark'));
        }

        const chan = channel();

        window.matchMedia('(prefers-color-scheme: dark)')?.addEventListener('change', (data) => {
            chan.put(data?.matches);

            // TODO: https://jira.vk.team/browse/HOME-14922
            // Порталка пока не принимает на клиенте system для Облака, потому оно ставится на SSR в порталке через PH_ColorTheme
            // а при смене темы в системе делаем пока рефреш
            window.location.reload();
        });

        while (true) {
            const isDark = yield take(chan);

            yield put(storeCurrentTheme(isDark ? 'dark' : ''));
        }
    } catch (_) {}
}

function* checkThemeFromQuery() {
    const search = new URLSearchParams(window.location.search);
    const themeFromQuery = search.get('theme');
    if (themeFromQuery === WhitelineTheme.light || themeFromQuery === WhitelineTheme.dark || themeFromQuery === WhitelineTheme.system) {
        window.history.replaceState(null, '', window.location.pathname);
        if (window.cloudSettings.params.user.theme_main_page !== themeFromQuery) {
            yield put(changeTheme(themeFromQuery));
        }
    }
}

export function* watchTheme() {
    yield takeLatest(changeTheme.toString(), handleChangeTheme);
}
