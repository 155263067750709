import { editor } from 'Cloud/Application/Editor';
import { getExt } from 'Cloud/Application/Editor/helpers';
import { EditorID } from 'Cloud/Application/Editor/types';
import { isReadOnly, isSharedOrMountedStatus } from 'reactApp/appHelpers/appHelpers';
import { IS_FREE_BIZ_SAAS_USER, IS_PUBLIC } from 'reactApp/appHelpers/configHelpers';
import { getEditorsFor } from 'reactApp/modules/editor/editor.selectors';
import { isOnPremR7Product } from 'reactApp/modules/features/features.helpers';
import { getFeaturePdfEdit } from 'reactApp/modules/features/features.selectors';
import { getOwner } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.selectors';
import { isDomainFolder } from 'reactApp/modules/storage/folder.helpers';
import { getItemFolder } from 'reactApp/modules/storage/storage.selectors';
import type { CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import { store as reduxStore } from 'reactApp/store';
import { FileType } from 'reactApp/types/FileType';
import opener from 'reactApp/utils/opener';

type OpenInEditorItem = Partial<CloudItem> & Pick<CloudItem, 'id'>;

/** Открыть документоподобный файл на редактирование */
export const openEditor = (item: OpenInEditorItem, editorId = '') => editor?.openEditor(item, editorId);

/** Открыть пдф на редактирование */
export const openPdfEditor = ({ id, storage }: OpenInEditorItem) =>
    opener(`/pdf/edit?id=${encodeURIComponent(id)}${storage ? `&storage=${encodeURIComponent(storage)}` : ''}`);

/** Открыть документоподобный или пдф файл в редакторе */
export const openAnyFileEditor = (item: OpenInEditorItem & { ext?: string }) => {
    const ext = item.ext || getExt(item.id);
    if (ext === FileType.PDF) {
        return openPdfEditor(item);
    }

    return openEditor(item);
};

type OpenInEditorIfCanItem = Partial<CloudItem> & Pick<CloudItem, 'id'> & { ext: string; size: number; storage: EStorageType };

/** Открыть документоподобный или пдф файл в редакторе ТОЛЬКО если есть доступные редакторы */
export const openAnyFileEditorIfCan = (item: OpenInEditorIfCanItem) => {
    const state = reduxStore.getState();
    if (item.ext === FileType.PDF) {
        const canEditPdf = getFeaturePdfEdit(state);

        return canEditPdf ? openPdfEditor(item) : null;
    }

    const isEditable = getEditorsFor(state, item).length > 0;

    if (isEditable) {
        openEditor(item);
    }
};

export const isFileForOpenInR7Editor = (item) => {
    const state = reduxStore.getState();
    const folder = getItemFolder(state, item);
    const owner = getOwner(state);
    const isEditFileInSharedFolder =
        IS_FREE_BIZ_SAAS_USER &&
        !item.isFolder &&
        !isReadOnly(item.parent) &&
        isSharedOrMountedStatus(item.parent) &&
        !isDomainFolder(folder) &&
        owner?.is_paid;

    if (!isEditFileInSharedFolder && (IS_FREE_BIZ_SAAS_USER || IS_PUBLIC || item.isFile || item.isReadOnly || isReadOnly(item))) {
        return false;
    }

    const editors = editor?.getEditorsFor(item);

    if (isOnPremR7Product) {
        // В OnPrem R7 работает через МойОфис на фронте
        return editors?.some((editor) => [EditorID.MYOFFICE].includes(editor.id));
    }

    return editors?.some((editor) => [EditorID.R7].includes(editor.id));
};
