/**
 * Компонент не завершен
 * Экспериментальная копия reactApp/components/SharingWindow/SharingNew/Sharing/SharingNewSharing.tsx
 * Изменения относительно оригинала помечены комментарием - tempexp_17779
 **/

import { ReactComponent as LockIcon } from '@vkontakte/icons/src/svg/20/lock_outline_20.svg';
import classNames from 'clsx';
import React, { type ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_MOBILE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { SHARE_SPACE_CONSUMPTION_WARNING_ENABLED } from 'reactApp/appHelpers/featuresHelpers/features/shareSpaceConsumptionWarning';
import { sendGa } from 'reactApp/components/SharingWindow/Sharing.helpers';
import { type EAccessRights, EFrom } from 'reactApp/components/SharingWindow/Sharing.types';
import { SharingNewAddUser } from 'reactApp/components/SharingWindow/SharingNew/AddUser/SharingNewAddUser';
import { contactsSlice } from 'reactApp/modules/contacts/contacts.module';
import {
    accessControlListLoadStart,
    accessControlListReset,
    deleteAllUsers,
    setAccessRight,
} from 'reactApp/modules/folderAccessControlList/folderAccessControlList.actions';
import {
    getACL,
    getError,
    getOwner,
    isAccessControlListLoading,
} from 'reactApp/modules/folderAccessControlList/folderAccessControlList.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { loadUser } from 'reactApp/modules/user/user.thunkActions';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import { SpaceConsumptionWarning } from '../SpaceConsumptionWarning/SpaceConsumptionWarning';
import { UserList } from '../UserList/UserList';
import styles from './SharingNewSharingTab.css';
import { renderDeleteUsersDialog } from './SharingNewSharingTab.helpers';
import type { SharingNewSharingTabProps } from './SharingNewSharingTab.types';
// eslint-disable-next-line max-lines-per-function
export const SharingNewSharingTab = React.memo(function SharingNewSharingTab(props: SharingNewSharingTabProps): ReactElement | null {
    const dispatch = useDispatch();

    const { isLoaded, hasError: isUserError } = useSelector(UserSelectors.getLifeCycleState);
    const acList = useSelector(getACL);
    const owner = useSelector(getOwner);
    const currentUserEmail = useSelector(UserSelectors.getEmail);
    const isLoading = useSelector(isAccessControlListLoading);
    const error = useSelector(getError);

    const storage = useSelector(getCurrentStorage);
    const { isSharedIncoming } = getStorage(storage);

    // Блок раскрыт если есть приглашенные пользователи и если пользователь пришел из блока "Настроить доступ"
    const isFromSharing = props.from === EFrom.SHARING;

    const [isCloudUsersOpened, setCloudUsersOpened] = useState<boolean>(isFromSharing);

    const isUserLoaded = isLoaded && !isUserError;
    const { item, onClose, isMounted: mounted, isPhone, fetchedDataOnMount, setFetchedDataOnMount } = props;

    // FIXME: CLOUDWEB-13574. Костыль на случай если у монтированной папки не флага "mounted"
    const isMounted = mounted || currentUserEmail !== owner?.email;

    const loadAllData = useCallback(() => {
        dispatch(contactsSlice.actions.listRequest);
        dispatch(accessControlListLoadStart({ id: item?.id || '', storage: item?.storage }));
    }, [dispatch, item?.id, item?.storage]);

    useEffect(() => {
        // tempexp_17779-start
        if (fetchedDataOnMount) {
            return;
        }
        // tempexp_17779-end

        if (!isUserLoaded) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dispatch(loadUser).then(() => loadAllData());
        } else {
            loadAllData();
        }

        setFetchedDataOnMount?.(true);

        return function cleanup() {
            dispatch(accessControlListReset());
        };
    }, [dispatch, fetchedDataOnMount, isUserLoaded, loadAllData, setFetchedDataOnMount]);

    useEffect(() => {
        if (isFromSharing) {
            return;
        }

        const needOpen = Boolean(acList.length);

        // Если в списке есть юзеры, а он скрыт - надо открыть. Для первого открытия со списком.
        if (!isCloudUsersOpened && needOpen && !isLoading) {
            setCloudUsersOpened(true);
        }
    }, [acList.length, isCloudUsersOpened, isFromSharing, isLoading]);

    const onActionClick = useCallback(() => {
        if (!item) {
            return;
        }

        sendGa('delete-all');
        dispatch(deleteAllUsers({ item }));
        setCloudUsersOpened(false);
    }, [dispatch, item]);

    const handleAddUser = useCallback(
        ({ currentEmail, accessRight }: { currentEmail: string; accessRight: EAccessRights }) => {
            dispatch(
                setAccessRight({
                    accessRight,
                    folderId: item?.id || '',
                    email: currentEmail,
                })
            );
            dispatch(contactsSlice.actions.listRequest());

            sendGa('add-user');
            sendPaymentGa({
                eventCategory: item?.weblink ? ECategoryGa.public : ECategoryGa.sharedFolder,
                action: 'created-sharing',
                public_id: item?.weblink,
            });
        },
        [dispatch, item?.id, item?.weblink]
    );

    const onCloseAccessClick = useCallback(() => {
        renderDeleteUsersDialog({ onActionClick });
    }, [onActionClick]);

    const List = () => {
        return (
            <UserList
                acList={acList}
                isLoading={isLoading}
                isMounted={isMounted}
                isPhone={isPhone}
                isSharedIncoming={isSharedIncoming}
                onClose={onClose}
                item={item}
                owner={owner}
            />
        );
    };

    if (isMounted) {
        return <List />;
    }

    return (
        <div className={classNames(styles.root, { [styles.root_phone]: isPhone })} data-qa-id="cloud-users-sharing">
            {SHARE_SPACE_CONSUMPTION_WARNING_ENABLED && !IS_MOBILE_BROWSER && <SpaceConsumptionWarning />}
            <SharingNewAddUser error={error} isLoading={isLoading} handleAddUser={handleAddUser} isMounted={isMounted} isPhone={isPhone} />
            <List />
            <Button theme={'base'} middle onClick={onCloseAccessClick} sizeMode={isPhone ? ButtonSizeMode.big : ButtonSizeMode.middle}>
                <div className={styles.closeAccessButton}>
                    <LockIcon width={20} height={20} />
                    <span>Закрыть доступ</span>
                </div>
            </Button>
        </div>
    );
});
