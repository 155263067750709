import { SubscriptionRenewAPICall } from 'reactApp/api/billing/subscription/SubscriptionRenewAPICall';
import { getBuyApiParams, getRequestId } from 'reactApp/modules/billing/billing.helpers';
import { BillingActions } from 'reactApp/modules/billing/billing.module';
import type { RenewApiParams } from 'reactApp/modules/billing/billing.types';
import { loggerSaga } from 'reactApp/modules/logger/logger.saga';
import { getPromoById } from 'reactApp/modules/promoTariffs/promoTariffs.selectors';
import { SubscriptionsSelector } from 'reactApp/modules/subscriptions/subscriptions.selectors';
import { sendXray } from 'reactApp/utils/ga';
import { put, select } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

export const subscriptionRenewAPICall = (params: RenewApiParams) => new SubscriptionRenewAPICall().makeRequest(params);

export function* handleRenewSubscription(action: ReturnType<typeof BillingActions.renewSubscription>) {
    const requestId = getRequestId();

    yield put(BillingActions.startLoading({ requestId }));

    const sub_id = action.payload.id;
    const regularSubscription = yield select(SubscriptionsSelector.getSubscriptionsById, sub_id);
    // @ts-ignore
    const promoSubscription = yield select(getPromoById, sub_id);
    const subscription = regularSubscription || promoSubscription;

    if (subscription) {
        yield put(BillingActions.setRenewalCost({ renewalCost: subscription.renewalCost, requestId }));
    }

    const params = getBuyApiParams(action.payload);

    try {
        const { data } = yield* call(subscriptionRenewAPICall, {
            ...params,
            sub_id,
        });

        yield put(BillingActions.setRenewLink({ link: data.pw, requestId }));

        sendXray(['blng', 'renew', 'link', 'success']);
    } catch (error: any) {
        if (error?.status === 406) {
            sendXray(['blng', 'renew', 'link', 'unable']);
        }
        sendXray(['blng', 'renew', 'link', 'error']);
        yield put(BillingActions.hasError({ requestId }));
        yield loggerSaga({ error });
    }
}
