import { useCallback, useRef, useState } from 'react';

interface Config {
    offsetTop?: number;
}

export const useDropdownList = <RefEl extends HTMLElement>(config?: Config) => {
    const controlRef = useRef<RefEl | null>(null);

    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const open = useCallback(() => {
        setDropdownOpen(true);
    }, []);

    const close = useCallback(() => {
        setDropdownOpen(false);
    }, []);

    const findDropdownPosition = (dropdownWidth: number): { posX: number; posY: number } => {
        let posX = 0;
        let posY = 0;

        if (controlRef?.current) {
            const elRect = controlRef.current.getBoundingClientRect();

            posY = elRect.height + window.scrollY + elRect.top + (config?.offsetTop || 0);
            posX = elRect.left + elRect.width - dropdownWidth + window.scrollX;
        }

        return { posX, posY };
    };

    return {
        isOpen: isDropdownOpen,
        open,
        close,
        findDropdownPosition,
        controlRef,
    };
};
