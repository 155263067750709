import { createErrorList } from 'Cloud/Application/ErrorList';

export const ALBUMS_FOLDER_NAME = 'Альбомы';

export const CUSTOM_ALBUMS_ID = 'custom';

export const albumErrors = createErrorList({
    name_too_long: 'Превышено количество символов в названии',
    required: 'Название не может быть пустым',
    unknown: 'Ошибка на сервере',
    create_error: 'Не удалось создать альбом',
    add_error: 'Не удалось добавить в альбом',
    'CONDITION/BADNAME':
        'Недопустимое название. В названии нельзя использовать символы «{INVALID_CHARACTERS}». Также название не может состоять только из точки «.» или из двух точек «..»',
});
