import { usedSpacePostpaid } from 'reactApp/appHelpers/featuresHelpers/features/usedSpacePostpaid';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { store as reduxStore } from 'reactApp/store';
import { renderUserSpacePostpaidModal } from 'reactApp/ui/UsedSpacePostpaidModal/UsedSpacePostpaidModal.helpers';
import { renderUsedSpacePostpaidModalThanks } from 'reactApp/ui/UsedSpacePostpaidModalThanks/UsedSpacePostpaidModalThanks.helpers';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { put } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';

const USED_SPACE_POSTPAID_MODAL_ID = 'used-space-postpaid-modal';

export function* initUsedSpacePostpaidModal() {
    const storage = yield* select(getCurrentStorage);

    const isCorrectUrl = storage === EStorageType.home;

    if (storeHelper.getValue(USED_SPACE_POSTPAID_MODAL_ID) || !isCorrectUrl || !usedSpacePostpaid || usedSpacePostpaid === 'a') {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.usedSpacePostpaid,
            promoId: USED_SPACE_POSTPAID_MODAL_ID,
            onClick() {
                reduxStore.dispatch(removePromo(EPromoType.usedSpacePostpaid));
                sendPaymentGa({
                    action: 'click',
                    eventCategory: ECategoryGa.fakedoorPostpaid,
                });

                renderUsedSpacePostpaidModalThanks({
                    onClick: () => {
                        sendPaymentGa({
                            action: 'click',
                            eventCategory: ECategoryGa.fakedoorPostpaidThanks,
                        });
                    },
                    onShow: () => {
                        sendPaymentGa({
                            action: 'show',
                            eventCategory: ECategoryGa.fakedoorPostpaidThanks,
                        });
                    },
                    onClose: () => {
                        sendPaymentGa({
                            action: 'close',
                            eventCategory: ECategoryGa.fakedoorPostpaidThanks,
                        });
                    },
                });
            },
            onShow: () => {
                storeHelper.markAsShown(USED_SPACE_POSTPAID_MODAL_ID);
                reduxStore.dispatch(promoShown(EPromoType.usedSpacePostpaid));
                sendPaymentGa({
                    action: 'show',
                    eventCategory: ECategoryGa.fakedoorPostpaid,
                });
            },
            onClose: () => {
                reduxStore.dispatch(removePromo(EPromoType.usedSpacePostpaid));
                sendPaymentGa({
                    action: 'close',
                    eventCategory: ECategoryGa.fakedoorPostpaid,
                });
            },
            onSecondary: () => {
                reduxStore.dispatch(removePromo(EPromoType.usedSpacePostpaid));
                sendPaymentGa({
                    action: 'click-reject',
                    eventCategory: ECategoryGa.fakedoorPostpaid,
                });
            },
        })
    );
}

export function* showUsedSpacePostpaidModal() {
    const promo = yield* select(PromoSelectors.getPromo(EPromoType.usedSpacePostpaid));

    if (promo) {
        const { onShow, onClick, onClose, onSecondary } = promo;
        yield call(renderUserSpacePostpaidModal, { onShow, onClick, onClose, onSecondary });
    }
}
