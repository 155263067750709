import { JavaScriptError } from 'reactApp/modules/uploading/errors/JavaScriptError';
import { LocalFileNotFoundFail } from 'reactApp/modules/uploading/fails/LocalFileNotFoundFail';
import { LocalFileNotReadableFail } from 'reactApp/modules/uploading/fails/LocalFileNotReadableFail';
import { UnsupportedFolderTransferFail } from 'reactApp/modules/uploading/fails/UnsupportedFolderTransferFail';
import { MAX_READABLE_SIZE } from 'reactApp/modules/uploading/helpers/fs/fs.helpers';
import { sendGaUploaderNew } from 'reactApp/modules/uploading/helpers/uploading.helpers';
import { EUploadReasonSource } from 'reactApp/modules/uploading/serviceClasses/UploadingReason';

/**
 * чтение используется
 * для проверки существования файла, поэтому чтобы не блокировать
 * возможность аплоада, игнорируем ошибку "Out of Memory".
 */
export const checkFileExistenceByRead = async (file: File) => {
    if (file.size > MAX_READABLE_SIZE) {
        throw new Error('too large');
    }

    const blob = file.slice(0, 10);

    try {
        await blob.arrayBuffer();

        return file;
    } catch (error: any) {
        if (error && error.name === 'NS_ERROR_OUT_OF_MEMORY') {
            sendGaUploaderNew('check-exist-outofmem');
            return file;
        }

        sendGaUploaderNew('check-exist-err');

        throw error;
    }
};

// startIdx - для чтения не всего файла, а блока байт из него
export const readFileContent = (file: File, startIdx: number, stopIdx: number | null = null): Promise<ArrayBuffer | null> => {
    try {
        const blob = startIdx >= 0 ? file.slice(startIdx, stopIdx ? stopIdx + 1 : file.size) : file;

        return blob.arrayBuffer();
    } catch (error: any) {
        return Promise.reject(handleError(error, file));
    }
};

function handleError(error: DOMException | null, file: File) {
    const source = EUploadReasonSource.SOURCE_WEB_CLIENT;
    let stack;
    let reason;

    if (LocalFileNotFoundFail.isNotFoundError(error)) {
        stack = new Error('LocalFileNotFoundFail');
        reason = new LocalFileNotFoundFail(stack, source);
    } else if (!file.type && UnsupportedFolderTransferFail.isNotReadableError(error)) {
        stack = new Error('UnsupportedFolderTransferFail');
        reason = new UnsupportedFolderTransferFail(stack, source);
    } else if (LocalFileNotReadableFail.isNotReadableError(error)) {
        stack = new Error('LocalFileNotReadableFail');
        reason = new LocalFileNotReadableFail(stack, source);
    } else {
        stack = new Error('JavaScriptError');
        reason = new JavaScriptError(stack, source, error);
    }

    return reason;
}
