import { IS_BIZ_USER, IS_PAID_USER, IS_SOCIAL_USER } from 'reactApp/appHelpers/configHelpers';
import { restoreTrashbinFiles } from 'reactApp/appHelpers/featuresHelpers/features/restoreTrashbinFiles';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { store as reduxStore } from 'reactApp/store';
import {
    closeRestoreTrashbinFilesModal,
    renderRestoreTrashbinFilesModal,
} from 'reactApp/ui/RestoreTrashbinFiles/RestoreTrashbinFilesModal.helpers';
import { renderRestoreTrashbinFilesThanksModal } from 'reactApp/ui/RestoreTrashbinFilesThanksModal/RestoreTrashbinFilesThanksModal.helpers';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { put } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';

const RESTORE_TRASHBIN_FILES_MODAL_ID = 'restore-trashbin-files-modal';

export function* initRestoreTrashbinFilesModal() {
    const storage = yield* select(getCurrentStorage);

    const isCorrectUrl = storage === EStorageType.home;

    if (
        IS_PAID_USER ||
        IS_BIZ_USER ||
        IS_SOCIAL_USER ||
        storeHelper.getValue(RESTORE_TRASHBIN_FILES_MODAL_ID) ||
        !isCorrectUrl ||
        !restoreTrashbinFiles ||
        restoreTrashbinFiles === 'a'
    ) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.restoreTrashbinFiles,
            promoId: RESTORE_TRASHBIN_FILES_MODAL_ID,
            onClick() {
                reduxStore.dispatch(removePromo(EPromoType.restoreTrashbinFiles));
                sendPaymentGa({
                    action: 'click',
                    eventCategory: ECategoryGa.modalFileRecovery,
                });

                renderRestoreTrashbinFilesThanksModal({
                    onClick: () => {
                        sendPaymentGa({
                            action: 'click',
                            eventCategory: ECategoryGa.modalFileRecoveryThanks,
                        });
                    },
                    onShow: () => {
                        sendPaymentGa({
                            action: 'show',
                            eventCategory: ECategoryGa.modalFileRecoveryThanks,
                        });
                    },
                    onClose: () => {
                        sendPaymentGa({
                            action: 'close',
                            eventCategory: ECategoryGa.modalFileRecoveryThanks,
                        });
                    },
                });
            },
            onShow: () => {
                storeHelper.markAsShown(RESTORE_TRASHBIN_FILES_MODAL_ID);
                reduxStore.dispatch(promoShown(EPromoType.restoreTrashbinFiles));
                sendPaymentGa({
                    action: 'show',
                    eventCategory: ECategoryGa.modalFileRecovery,
                });
            },
            onClose: () => {
                reduxStore.dispatch(removePromo(EPromoType.restoreTrashbinFiles));
                sendPaymentGa({
                    action: 'close',
                    eventCategory: ECategoryGa.modalFileRecovery,
                });
            },
            onSecondary: () => {
                reduxStore.dispatch(removePromo(EPromoType.restoreTrashbinFiles));
                sendPaymentGa({
                    action: 'stop',
                    eventCategory: ECategoryGa.modalFileRecovery,
                });
                closeRestoreTrashbinFilesModal();
            },
        })
    );
}

export function* showRestoreTrashbinFilesModal() {
    const promo = yield* select(PromoSelectors.getPromo(EPromoType.restoreTrashbinFiles));

    if (promo) {
        const { onShow, onClick, onClose, onSecondary } = promo;
        yield call(renderRestoreTrashbinFilesModal, { onShow, onClick, onClose, onSecondary });
    }
}
