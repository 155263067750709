import { Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { memo } from 'react';
import { GROUPED_ITEMS } from 'reactApp/sections/NewPersonalDocuments/NewPersonalDocuments.constants';
import { UploadItem } from 'reactApp/sections/NewPersonalDocuments/UploadItem/UploadItem';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';

import styles from './PersonalDocumentGroups.css';

interface Props {
    onClose?: () => void;
}

export const PersonalDocumentGroups = memo<Props>(({ onClose }) => {
    return (
        <Dialog className={styles.root} open dimmer size="average" scrollingContent header={<>Добавление документа</>} onCancel={onClose}>
            <Content isModal>
                {GROUPED_ITEMS.map(({ name, title, childs }) => (
                    <div className={styles.group} key={name}>
                        <Text className={styles.title}>{title}</Text>
                        <ul className={classNames(styles.list, styles[`list_${name}`])}>
                            {childs.map(({ icon, title, type }) => (
                                <UploadItem key={type} className={styles.item} icon={icon} title={title} type={type} onUpload={onClose} />
                            ))}
                        </ul>
                    </div>
                ))}
            </Content>
        </Dialog>
    );
});

PersonalDocumentGroups.displayName = 'PersonalDocumentGroups';
