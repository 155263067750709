import { Icon24GiftOutline } from '@vkontakte/icons';
import { Spacing, Text } from '@vkontakte/vkui';
import coverImage from 'img/discount-in-outflow.png';
import React, { useCallback, useEffect, useMemo } from 'react';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { dicountInOutflowValue } from 'reactApp/appHelpers/featuresHelpers/features/discountInOutflow';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import type { ISubscription } from 'reactApp/modules/subscriptions/subscriptions.types';
import {
    ESubscriptionModalAction,
    useSendSubscriptionModalAnalytics,
} from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal.analytics';
import type { Product } from 'reactApp/types/Billing';

import styles from './DiscountInOutflowModal.css';

export interface DiscountInOutflowModalProps {
    discountPrice?: number;
    product: Product;
    subscription: ISubscription;
    onShow?: () => void;
    onClose?: () => void;
    onCancel: () => void;
    onSuccess: () => void;
}

const decoration = (
    <svg fill="none" height="35px" width="37px" viewBox="0 0 37 35" xmlns="http://www.w3.org/2000/svg">
        <path d="m18.835 18.152c-3.0127-2.4225-6.122-4.7936-10.06-7.9581" stroke="#B8FC75" strokeLinecap="square" strokeWidth="5" />
        <path d="m13.838 28.974-8.9275 0.3594" stroke="#B8FC75" strokeLinecap="square" strokeWidth="5" />
        <path d="m28.384 10.533-0.2934-7.2975" stroke="#B8FC75" strokeLinecap="square" strokeWidth="5" />
    </svg>
);

export function DiscountInOutflowModal({
    onClose,
    onCancel,
    onSuccess,
    onShow,
    product,
    subscription,
    discountPrice,
}: DiscountInOutflowModalProps) {
    const sendAnalytic = useSendSubscriptionModalAnalytics(subscription);

    useEffect(() => {
        emitAnalyticEvent(AnalyticEventNames.MY_TARIFF_CANCEL_SALE_SHOW);
        sendAnalytic(ESubscriptionModalAction.cancelSaleShow);
        onShow?.();
    }, []);

    const onCloseAction = useCallback(() => {
        emitAnalyticEvent(AnalyticEventNames.MY_TARIFF_CANCEL_SALE_CLOSE);
        sendAnalytic(ESubscriptionModalAction.cancelSaleClose);
        onClose?.();
    }, [onClose]);

    const onCancelAction = useCallback(() => {
        emitAnalyticEvent(AnalyticEventNames.MY_TARIFF_CANCEL_SALE_REJECT);
        sendAnalytic(ESubscriptionModalAction.cancelSaleReject);
        onCancel?.();
    }, [onCancel]);

    const onSuccessAction = useCallback(() => {
        emitAnalyticEvent(AnalyticEventNames.MY_TARIFF_CANCEL_SALE_GET);
        sendAnalytic(ESubscriptionModalAction.cancelSaleGet);
        onSuccess?.();
    }, [onSuccess]);

    const content = useMemo(() => {
        return (
            <div className={styles.content}>
                <Spacing size={12} />
                <Text className={styles.text}>
                    Она будет действовать для тарифа, который подключен
                    <br />
                    сейчас, и отключится только вместе с ним
                </Text>
                <Spacing size={24} />
            </div>
        );
    }, []);
    const imgBanner = useMemo(() => {
        return (
            <div className={styles.imageBanner}>
                <div className={styles.decoration}>{decoration}</div>
                <div className={styles.discountInfo}>
                    <Icon24GiftOutline />
                    <Text className={styles.discountInfoText}>-{dicountInOutflowValue}%</Text>
                </div>
                <Spacing size={24} />
                <div className={styles.priceContainer}>
                    <Text className={styles.priceText}>
                        <Text className={styles.price}>{discountPrice} ₽</Text>
                        <Text className={styles.period}>&nbsp;в мес</Text>
                    </Text>
                    <Text className={styles.oldPriceText}>вместо {product.price} ₽</Text>
                </div>
            </div>
        );
    }, []);
    return (
        <WhatsNewDialog
            imageUrl={coverImage}
            imageClassName={styles.image}
            title={`Закрепим за вами скидку ${dicountInOutflowValue}%`}
            content={content}
            imageAdditionalContent={imgBanner}
            primaryButtonText="Получить скидку"
            secondaryButtonText="Всё равно отключить"
            onSecondary={onCancelAction}
            onClick={onSuccessAction}
            onClose={onCloseAction}
            imageBgColor="var(--vkui--color_background_modal)"
            buttonTheme="vk"
            dialogSize="tiny"
            qaId="discount-in-outflow-modal"
            isRebranding
        />
    );
}
