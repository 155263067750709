import { coreLoggerDispatchError, initTracerSdk } from '@mail-core/logger';
import * as TracerSDK from '@tracer/sdk';
import config from 'Cloud/config';
import { xray } from 'lib/xray';

let tracer: TracerSDK.ITracerError;

const lazyInitFixed =
    typeof window !== 'undefined'
        ? (func) => {
              // Иначе это падает в SSR - нет clearTimeout где-то в либе mail-core/std
              const { lazyInit } = require('@mail-core/std');
              return lazyInit(func);
          }
        : (func) => func;

export const initTracer = lazyInitFixed(() => {
    try {
        tracer = initTracerSdk(
            {
                token: process.env.TRACER_TOKEN!,
                build: process.env.BUILD!,
                pipelineId: +process.env.PIPELINE_ID!,
                user: config.get('user.email') || 'unknown',
                environment: process.env.NODE_ENV || 'dev',
                crashFree: true,
            },
            {
                TracerSDK,
                xray,
                features: config.get('FEATURES'),
            }
        );
    } catch (error) {
        coreLoggerDispatchError(error, { category: 'tracer-init' });
    }
});

const module = process.env.NODE_ENV || 'dev';

// https://apptracer.ru/doc/js/extra/#идентификатор-ошибки
// По issueKey в трейсере делается группировка, чтобы выделить эти ошибки с таким issueKey в списке в отдельную группу
export const captureException = (
    error: any,
    data: Record<string, string | number | boolean> & { message?: string; issueKey?: string } = {}
) => {
    const errorTyped = error instanceof Error ? error : new Error(`unknown error: ${error?.toString?.()}`);

    const { issueKey: issueKeyTemp, ...keys } = data;

    const issueKey = issueKeyTemp || keys?.message || errorTyped.toString() || 'unknown';

    tracer?.error?.(
        errorTyped, // можно передать дополнительные данные, если необходимо
        {
            keys,
            issueKey,
            module,
        }
    );
};
