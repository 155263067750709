import { logger } from 'lib/logger';

export const TMR3473039 = '3473039';

export function sendTmrGoal({ id = '2435029', goal, value }: { id?: string; goal: string; value?: number }) {
    logger.info('_tmr reachGoal', id, goal, value);

    if (window._tmr) {
        window._tmr.push({ id, type: 'reachGoal', goal, value });
    }
}
