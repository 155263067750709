import settings from 'Cloud/settings';
import {
    IS_AUTO_TEST_HIDE,
    IS_B2B_BIZ_USER,
    IS_MY_TEAM,
    IS_PHONE_BROWSER,
    IS_PUBLIC,
    IS_VKT_WEBVIEW,
    IS_WEBVIEW,
} from 'reactApp/appHelpers/configHelpers';
import { promoMobileAppAttaches, promoMobileAppLM } from 'reactApp/appHelpers/featuresHelpers';
import { isViewContentOnly } from 'reactApp/appHelpers/settingsHelpers';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { MobileAppPromoType } from 'reactApp/modules/promo/promoMobileApp/promoMobileApp.types';
import { isQuotaPageWithoutPromos } from 'reactApp/modules/router/router.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { store } from 'reactApp/store';
import { put, select } from 'redux-saga/effects';

const PROMO_TIP_KEY = 'mobile-app-promo';
const PERIOD_TO_SHOW_DAYS = 30 * 3;

const isEnabledPromo = {
    [MobileAppPromoType.attaches]: promoMobileAppAttaches,
    [MobileAppPromoType.lm]: promoMobileAppLM,
};

const getKey = (type: MobileAppPromoType) => `${PROMO_TIP_KEY}|${type}`;

export function* initInitMobAppPromo() {
    // Форс-включение промки для отдельных автотестов.
    const force = yield put(UserStorageActions.get(`force_${PROMO_TIP_KEY}`)) || false;

    if (
        isQuotaPageWithoutPromos() ||
        settings?.request?.action ||
        IS_PHONE_BROWSER ||
        isViewContentOnly ||
        IS_WEBVIEW ||
        IS_MY_TEAM ||
        (IS_AUTO_TEST_HIDE && !force) ||
        IS_B2B_BIZ_USER ||
        IS_VKT_WEBVIEW ||
        IS_PUBLIC
    ) {
        return;
    }

    const isNewbie = yield select(UserSelectors.isNewbie);

    if (isNewbie) {
        return;
    }

    const key = getKey(MobileAppPromoType.attaches);
    const showPromo = !storeHelper.getValue(key) || storeHelper.isPassed(key, { daysCount: PERIOD_TO_SHOW_DAYS });

    if (!showPromo || !isEnabledPromo[MobileAppPromoType.attaches]) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.mobileAppPromo,
            promoId: PROMO_TIP_KEY,
            tipType: [MobileAppPromoType.attaches],
            onShow: () => store.dispatch(promoShown(EPromoType.mobileAppPromo)),
            onMarkShown: (type: MobileAppPromoType) => storeHelper.markAsShown(getKey(type)),
            onClose: () => store.dispatch(removePromo(EPromoType.mobileAppPromo)),
        })
    );
}
