import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

const publicPasswordValue: 'a' | 'b' = getFeature('public-password');

registerExperiment(
    'public-pswd',
    publicPasswordValue,
    [
        AnalyticEventNames.PUBLIC_PASSWORD_SHOW,
        AnalyticEventNames.PUBLIC_PASSWORD_SET,
        AnalyticEventNames.PUBLIC_PASSWORD_SAVE,
        AnalyticEventNames.PUBLIC_PASSWORD_PROMO_SHOW,
        AnalyticEventNames.PUBLIC_PASSWORD_PROMO_CLICK,
        AnalyticEventNames.FAST_CHECKOUT_SHOWN,
        AnalyticEventNames.TARIFF_BUY,
        AnalyticEventNames.TRIAL_TARIFF_BUY,
        AnalyticEventNames.PUBLIC_PASSWORD_GUEST_SHOW,
        AnalyticEventNames.PUBLIC_PASSWORD_GUEST_INCORRECT,
        AnalyticEventNames.PUBLIC_PASSWORD_GUEST_SUCCESS,
    ],
    {
        sendXrayArgs: {
            [AnalyticEventNames.TARIFF_BUY]: ({ paySource }, { eventName }) => ({
                eventName: paySource?.startsWith('tooltip_password') ? 'pswd_buy' : eventName,
            }),
            [AnalyticEventNames.FAST_CHECKOUT_SHOWN]: ({ paySource }, { eventName }) => ({
                eventName: paySource?.startsWith('tooltip_password') ? 'pswd_checkout' : eventName,
            }),
        },
    }
);

export const isPublicPasswordEnabled = publicPasswordValue === 'b';
