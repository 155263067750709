import { Spacing } from '@vkontakte/vkui';
import React from 'react';
import { Placeholder } from 'reactApp/ui/Placeholder/Placeholder';

import styles from './SassTariffCardLoader.css';

export const SassTariffCardLoader = () => (
    <div className={styles.root}>
        <Spacing size={40} />
        <div className={styles.quota}>
            <div className={styles.space}>
                <Placeholder />
            </div>
            <div className={styles.units}>
                <Placeholder />
            </div>
        </div>
        <Spacing size={18} />

        {Array.from(new Array(4)).map(() => (
            <>
                <div className={styles.feature}>
                    <Placeholder />
                </div>
                <Spacing size={12} />
            </>
        ))}

        <Spacing size={18} />

        <div className={styles.button}>
            <Placeholder />
        </div>
    </div>
);
