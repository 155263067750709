import classNames from 'clsx';
import CloudDarkIcon from 'img/portal-header/cloud_dark.svg?url';
import CloudIcon from 'img/portal-header/cloud_light.svg?url';
import CloudFullWhite from 'img/portal-header/cloud_logo_full_white.svg?url';
import CloudDocumentsIcon from 'img/portal-header/cloud-documents.svg?url';
import NewDocDark from 'img/portal-header/doc_dark.svg?url';
import NewDoc from 'img/portal-header/doc_light.svg?url';
import React, { type ReactElement, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ENABLE_FULL_RESPONSIVE, IS_CLOUD_LOCKED } from 'reactApp/appHelpers/configHelpers';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import type { PublicFile } from 'reactApp/modules/public/public.types';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';
import { EQueryParams } from 'server/helpers/getRequestParams';

import styles from './Logo.css';

export enum ELogoSize {
    m = 'm',
    s = 's',
}

interface IProps {
    className?: string;
    isBiz: boolean;
    href?: string;
    isPhone: boolean;
    size?: ELogoSize;
    bizLogo?: string;
    bizBrandName?: string;
    white?: boolean;
    useRouterLink?: boolean;
    isDocuments?: boolean;
    errorPage?: boolean;
    isWebView?: boolean;
    isPublic?: boolean;
    publicItem?: PublicFile;
}

export const LogoComponent: React.FC<IProps> = ({
    className,
    href = '/',
    isDocuments,
    isPhone,
    isBiz,
    size = ELogoSize.m,
    bizLogo,
    bizBrandName,
    white,
    useRouterLink,
    errorPage = false,
    isWebView = false,
    isPublic = false,
    publicItem = null,
}): ReactElement => {
    const whiteLogo = white || isPhone;
    const logoSize = isPhone ? ELogoSize.s : size;

    const fromDeeplink = getQueryParams()[EQueryParams.fromDeeplink];
    const isSuperAppWebView = isWebView && fromDeeplink;

    const icon = useMemo(() => {
        let src = CloudIcon;
        let srcDarkTheme = CloudDarkIcon;
        if (isDocuments) {
            src = CloudDocumentsIcon;
            srcDarkTheme = CloudDocumentsIcon;
        }

        if (isDocuments) {
            src = NewDoc;
            srcDarkTheme = NewDocDark;
        }

        if (isSuperAppWebView) {
            srcDarkTheme = CloudFullWhite;
        }

        if (isPublic) {
            srcDarkTheme = CloudFullWhite;
        }

        /* @ts-ignore */
        return <img className={styles.logo} src={whiteLogo ? srcDarkTheme : src} importance="high" />;
    }, [whiteLogo, isDocuments]);

    const logoLinkProps = {
        title: isDocuments ? 'Документы Mail' : 'Облако Mail',
        className: classNames(styles.cloud, { [styles.logoDisablePointerEvents]: isSuperAppWebView }),
        children: icon,
    };

    /* tempexp_17777-start */
    const sendRadar = useCallback(() => {
        if (isPublic && publicItem && publicItem.ext) {
            sendDwh({
                eventCategory: ECategoryGa.public,
                action: 'go-to-cloud_touch',
                dwhData: {
                    type: 'logo',
                    extenstion: publicItem.ext,
                },
            });
        }
    }, [isPublic, publicItem]);
    /* tempexp_17777-end */

    return (
        <div
            className={classNames(className, {
                [styles.root]: true,
                [styles[`size_${logoSize}`]]: !!logoSize,
                [styles.root_my_team]: ENABLE_FULL_RESPONSIVE,
                [styles.bizLocked]: ENABLE_FULL_RESPONSIVE && IS_CLOUD_LOCKED,
            })}
        >
            {isBiz ? (
                <a
                    href={href}
                    className={classNames({
                        [styles.biz]: true,
                        [styles.hidden_logo]: errorPage,
                        [styles.logoDisablePointerEvents]: isSuperAppWebView,
                    })}
                    title={bizBrandName}
                >
                    {/* @ts-ignore */}
                    <img className={styles.logo} src={bizLogo} height={32} width={164} importance="high" />
                </a>
            ) : (
                <>{useRouterLink ? <Link to={href} {...logoLinkProps} /> : <a onClick={sendRadar} href={href} {...logoLinkProps} />}</>
            )}
        </div>
    );
};
