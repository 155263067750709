/* eslint-disable max-lines-per-function */
import hotkeys, { type KeyHandler } from 'hotkeys-js';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IS_FREE_BIZ_SAAS_USER } from 'reactApp/appHelpers/configHelpers';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { KeyCodes } from 'reactApp/constants/KeyCodes';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { getOwner } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.selectors';
import { changeHomeHistory } from 'reactApp/modules/home/home.actions';
import { selectOne, selectSome } from 'reactApp/modules/selections/selections.actions';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { getStorageConfig } from 'reactApp/modules/storage/storage.config';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getFilterFilesInProgress } from 'reactApp/modules/uploadList/uploadList.selectors';
import type { RootState } from 'reactApp/store';
import { gaHotkeyCategory, HOT_KEY_SCOPE_NAME, leaderKey } from 'reactApp/ui/Datalist/DataList.constants';
import { getSelectedCount } from 'reactApp/ui/Datalist/DataList.helpers';
import { EAligment } from 'reactApp/ui/VirtualList/VirtualList.types';
import { sendGa } from 'reactApp/utils/ga';

export const useHotkeyHandlers = ({
    folder,
    selectedIdxs,
    storage,
    dispatch,
    items,
    handleOnClick,
    viewMode,
    isPublicUpload,
    selectedItems,
    hasAddedItem,
    listRef,
}) => {
    const lastSelectedId = useSelector(SelectionsSelectors.getLastSelected);
    const selectedRange = useSelector(SelectionsSelectors.getSelectedRange);
    const owner = useSelector(getOwner);
    const filteredItems = useSelector((state: RootState) => getFilterFilesInProgress(state, items));

    const isRootOrNoSelected = (folder?.id === ROOT_FOLDER_ID && !selectedIdxs.length) || !selectedIdxs.length;
    const storageConfig = getStorageConfig(storage as EStorageType);

    const isUserAllowedToCreateDocument =
        IS_FREE_BIZ_SAAS_USER && folder?.isMounted && owner?.is_paid && !folder?.readOnly && folder.kind !== 'domain-folder';

    const allDocumentsPageFileCreationHotKeys = 'shift+t,shift+s';
    const homePageFileCreationHotKeys = `shift+f,shift+h${
        IS_FREE_BIZ_SAAS_USER && !isUserAllowedToCreateDocument ? '' : ',shift+t,shift+s,shift+p'
    }`;

    const creationHotKeyListByPage = storage === EStorageType.home ? homePageFileCreationHotKeys : allDocumentsPageFileCreationHotKeys;

    const onKeySelectAll: KeyHandler = useCallback(
        (e) => {
            e.preventDefault();
            sendGa(gaHotkeyCategory, 'select-all');
            toolbarActions.selectAll(storage);
        },
        [storage]
    );

    const onKeyCreate: KeyHandler = useCallback(
        (e) => {
            if (storage !== EStorageType.alldocuments && storage !== EStorageType.home) {
                return;
            }
            e.preventDefault();
            const actionTypes = {
                [KeyCodes.DOM_VK_F]: 'folder',
                [KeyCodes.DOM_VK_T]: 'docx',
                [KeyCodes.DOM_VK_P]: 'pptx',
                [KeyCodes.DOM_VK_S]: 'xlsx',
            };
            const type = actionTypes[e.which || e.keyCode];
            toolbarActions.create(type, 'datalist', false);
            sendGa(gaHotkeyCategory, `shift+${type}`);
            sendGa('hotkey', `shift+${type}`);
        },
        [storage]
    );

    const onKeyCopy: KeyHandler = useCallback(
        (e) => {
            if (isRootOrNoSelected || !storageConfig.copy) {
                return;
            }
            e.preventDefault();
            sendGa(gaHotkeyCategory, 'copy');
            toolbarActions.copy();
        },
        [isRootOrNoSelected]
    );

    const onKeyMove: KeyHandler = useCallback(
        (e) => {
            if (isRootOrNoSelected || !storageConfig.move) {
                return;
            }
            e.preventDefault();
            sendGa(gaHotkeyCategory, 'move');
            toolbarActions.move();
        },
        [isRootOrNoSelected]
    );

    const onKeyRemove: KeyHandler = useCallback(
        (e) => {
            if (isRootOrNoSelected || (!storageConfig.remove && !isPublicUpload)) {
                return;
            }
            e.preventDefault();
            sendGa(gaHotkeyCategory, 'remove');
            toolbarActions.remove();
        },
        [isRootOrNoSelected, isPublicUpload]
    );

    const onKeyChangeHomeHistory: KeyHandler = useCallback(
        (e) => {
            e.preventDefault();
            sendGa(gaHotkeyCategory, 'back');
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (!folder?.parent) {
                return;
            }

            dispatch(changeHomeHistory({ id: folder.parent }));
        },
        [folder?.parent]
    );

    const firstItem = selectedItems[0];
    const onKeyClick: KeyHandler = useCallback(
        (e) => {
            if (!(selectedItems.length === 1 && firstItem)) {
                return;
            }
            e.preventDefault();
            sendGa(gaHotkeyCategory, 'enter');
            handleOnClick(firstItem.id, firstItem);
        },
        [selectedItems.length, firstItem?.id]
    );

    const onKeySelectOne: KeyHandler = useCallback(
        (e) => {
            if (viewMode === EViewMode.list && ['ArrowLeft', 'ArrowRight'].includes(e.key)) {
                return;
            }

            const indexToSelect = hasAddedItem ? 1 : 0;

            const lastSelectedIndex = items.indexOf(lastSelectedId);
            const columnCount = listRef.current?.getColumnsCount() || 0;
            // Надо выделить первый элемент если ничего не выделено
            const index = lastSelectedIndex === -1 ? indexToSelect : lastSelectedIndex + getSelectedCount(e.key, columnCount);

            if (index < indexToSelect || index > items.length - 1) {
                return;
            }

            e.preventDefault();

            const idToSelect = items[index] || lastSelectedId;

            dispatch(selectOne({ selectedIdx: idToSelect, storage: storage as EStorageType }));
            listRef.current?.scrollToItem(index, EAligment.SMART);
            sendGa(gaHotkeyCategory, e.key);
        },
        [viewMode, hasAddedItem, lastSelectedId, items.length, storage]
    );

    const onKeySelectSome: KeyHandler = useCallback(
        (e) => {
            if (selectedRange === null || (viewMode === 'list' && ['ArrowLeft', 'ArrowRight'].includes(e.key))) {
                return;
            }

            e.preventDefault();
            const indexToSelect = hasAddedItem ? 1 : 0;

            if (!selectedItems.length && filteredItems.length) {
                e.preventDefault();

                dispatch(selectOne({ selectedIdx: filteredItems[indexToSelect], storage: storage as EStorageType }));
                listRef.current?.scrollToItem(indexToSelect, EAligment.SMART);
                return;
            }

            const columnCount = listRef.current?.getColumnsCount() || 0;
            const offset = selectedRange.offset + getSelectedCount(e.key, columnCount);
            const start = filteredItems.indexOf(selectedRange.rangeStartId);
            const end = start + offset;
            const [firstIndex, lastIndex] = start <= end ? [start, end] : [end, start];
            const selectedIdxs = filteredItems.slice(firstIndex, lastIndex + 1);

            if (firstIndex < indexToSelect || lastIndex > filteredItems.length - 1) {
                return;
            }

            dispatch(
                selectSome({
                    selectedIdxs,
                    storage: storage as EStorageType,
                    selectionRange: { offset, rangeStartId: selectedRange.rangeStartId },
                })
            );

            const indexToScroll = ['ArrowUp', 'ArrowLeft'].includes(e.key) ? firstIndex : lastIndex;

            listRef.current?.scrollToItem(indexToScroll, EAligment.SMART);

            sendGa(gaHotkeyCategory, `shift+${e.key}`);
        },
        [selectedRange, hasAddedItem, selectedItems.length, filteredItems.length, storage]
    );

    useEffect(() => {
        hotkeys(`${leaderKey}+a`, HOT_KEY_SCOPE_NAME, onKeySelectAll);
        hotkeys(creationHotKeyListByPage, HOT_KEY_SCOPE_NAME, onKeyCreate);

        return (): void => {
            hotkeys.unbind(`${leaderKey}+a`, HOT_KEY_SCOPE_NAME, onKeySelectAll);
            hotkeys.unbind(creationHotKeyListByPage, HOT_KEY_SCOPE_NAME, onKeyCreate);
        };
    }, [onKeySelectAll, onKeyCreate, creationHotKeyListByPage]);

    useEffect(() => {
        hotkeys(`${leaderKey}+c`, HOT_KEY_SCOPE_NAME, onKeyCopy);
        hotkeys(`${leaderKey}+x`, HOT_KEY_SCOPE_NAME, onKeyMove);
        hotkeys(`delete,${leaderKey}+backspace`, HOT_KEY_SCOPE_NAME, onKeyRemove);

        return (): void => {
            hotkeys.unbind(`${leaderKey}+c`, HOT_KEY_SCOPE_NAME, onKeyCopy);
            hotkeys.unbind(`${leaderKey}+x`, HOT_KEY_SCOPE_NAME, onKeyMove);
            hotkeys.unbind(`delete,${leaderKey}+backspace`, HOT_KEY_SCOPE_NAME, onKeyRemove);
        };
    }, [onKeyCopy, onKeyMove, onKeyRemove]);

    useEffect(() => {
        const key = `backspace`;

        hotkeys(key, HOT_KEY_SCOPE_NAME, onKeyChangeHomeHistory);

        return (): void => {
            hotkeys.unbind(key, HOT_KEY_SCOPE_NAME, onKeyChangeHomeHistory);
        };
    }, [onKeyChangeHomeHistory]);

    useEffect(() => {
        const key = 'enter';

        hotkeys(key, HOT_KEY_SCOPE_NAME, onKeyClick);

        return (): void => {
            hotkeys.unbind(key, HOT_KEY_SCOPE_NAME, onKeyClick);
        };
    }, [onKeyClick]);

    useEffect(() => {
        const key = 'down,up,left,right';

        hotkeys(key, HOT_KEY_SCOPE_NAME, onKeySelectOne);

        return (): void => {
            hotkeys.unbind(key, HOT_KEY_SCOPE_NAME, onKeySelectOne);
        };
    }, [onKeySelectOne]);

    useEffect(() => {
        const key = 'shift+down,shift+up,shift+left,shift+right';

        hotkeys(key, HOT_KEY_SCOPE_NAME, onKeySelectSome);

        return (): void => {
            hotkeys.unbind(key, HOT_KEY_SCOPE_NAME, onKeySelectSome);
        };
    }, [onKeySelectSome]);
};
