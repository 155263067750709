import { Icon20Chain, Icon20GlobeOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { memo } from 'react';
import type { EViewMode } from 'reactApp/modules/settings/settings.types';
import { DataListControl } from 'reactApp/ui/DataListControl/DataListControl';
import type { PublishControlItemProps } from 'reactApp/ui/DataListItem/DataListItem.types';
import { Hint } from 'reactApp/ui/Hint/Hint';

import styles from './Publish.css';

interface PublishProps extends PublishControlItemProps {
    accent?: boolean;
    isShared?: boolean;
    viewType: EViewMode;
    className?: string;
}

export const Publish = memo<PublishProps>(function Publish({
    accent = false,
    isShared = false,
    isSeoEnabled = false,
    onClick,
    viewType,
    className,
}) {
    return (
        <Hint text={isShared ? 'Настроить доступ' : 'Поделиться ссылкой'}>
            <DataListControl.New
                id="publish-icon"
                type={isSeoEnabled ? 'seo' : 'publish'}
                viewType={viewType}
                className={classNames(className, {
                    [styles.shared]: isShared,
                    [styles.accent]: accent || isSeoEnabled,
                })}
                onClick={onClick}
            >
                {isSeoEnabled ? <Icon20GlobeOutline width={20} height={20} /> : <Icon20Chain />}
            </DataListControl.New>
        </Hint>
    );
});
