import config from 'Cloud/config';
import React, { useEffect } from 'react';
import { O2Auth } from 'reactApp/api/O2Auth';
import { USER_EMAIL } from 'reactApp/appHelpers/configHelpers';
import { BUGREPORT_API_URL } from 'reactApp/appHelpers/featuresHelpers/features/bugreportButton';

import { lazyKotlett } from './KotlettBugreport.helpers';
import styles from './KotlettBugreportFeature.css';
const auth = new O2Auth({ clientId: config.get('CORS_API_CLIENT_ID'), login: USER_EMAIL });

async function initKotlett() {
    const { WebKotlett, runtime } = await lazyKotlett();

    return WebKotlett.init({
        runtime: runtime.get(),
        lookup: (point: string) => {
            if (point === 'bugreport') {
                return 'bugreport';
            }

            return null;
        },
        factories: {
            configServiceFactory: () => {
                return {
                    getIntOrDefault(key, defaultVal) {
                        if (key === 'bugreport_max_file_size_mb') {
                            return 10;
                        }
                        return defaultVal;
                    },
                    getBoolOrDefault(_, defaultVal) {
                        return defaultVal;
                    },
                    getStringOrDefault(key, defaultVal) {
                        if (key === 'bugreport_request_url') {
                            return BUGREPORT_API_URL;
                        }

                        return defaultVal;
                    },
                };
            },
        },
        requestPlaceholderInterceptor: {
            async fill(placeholder: string) {
                if (placeholder === 'PLACEHOLDER_ACCESS_TOKEN') {
                    const token = await auth.getToken();
                    if (!token) {
                        return null;
                    }
                    return token;
                }
            },
        },
    });
}

interface Navigation {
    goBack(): void;
}

interface KotlettInstance {
    mount(moduleId: string, navigation?: Navigation): void;
    unmount(moduleId: string): void;
}

interface Props {
    onClose(): void;
}

export function KotlettBugreportFeature({ onClose }: Props) {
    useEffect(() => {
        let kotlett: KotlettInstance | null = null;

        async function initialize() {
            kotlett = await initKotlett();
            if (!kotlett) {
                return;
            }

            kotlett.mount('bugreport', { goBack: () => onClose() });
        }

        initialize();

        return () => {
            if (kotlett) {
                kotlett.unmount('bugreport');
            }
        };
    }, [onClose]);

    return <div className={styles.container} id="bugreport" />;
}
