import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import {
    closeCancelAutoRenewConfirmDialog,
    renderCancelAutoRenewConfirmDialog,
} from 'reactApp/components/CancelAutoRenewConfirmDialog/CancelAutoRenewConfirmDialog.helpers';
import { BuySubscriptionActions, BuySubscriptionSelectors } from 'reactApp/modules/buySubscription/buySubscription.module';
import { BuyNotifications } from 'reactApp/modules/buySubscription/buySubscription.types';
import { CardActions } from 'reactApp/modules/creditCard/creditCard.module';
import { getFeatureAbCancelSubsWithCaptcha } from 'reactApp/modules/features/features.selectors';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { SubscriptionRenewalActions } from 'reactApp/modules/subscriptionRenewal/subscriptionRenewal.module';
import { updateSubscriptionsRequest } from 'reactApp/modules/subscriptions/subscriptions.module';
import { ESubscriptionModalAction } from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal.analytics';
import { store } from 'reactApp/store';
import { closeCardModal, renderCardModal } from 'reactApp/ui/AddCardModal/AddCardModal.helpers';
import { renderCancelAutoRenewFamilyModal } from 'reactApp/ui/CancelAutoRenewFamilyModal/CancelAutoRenewFamilyModal.helpers';
import { renderCancelSubscriptionWithCaptchaModal } from 'reactApp/ui/CancelSubscriptionWithCaptchaModal/CancelSubscriptionWithCaptchaModal.helpers';
import {
    closeDiscountInOutflowModal,
    renderDiscountInOutflowModal,
} from 'reactApp/ui/DiscountInOutflowModal/DiscountInOutflowModal.helpers';
import { createGaSender, sendXray } from 'reactApp/utils/ga';
import { channel } from 'redux-saga';
import { call, cancel, put, putResolve, select, take, takeEvery } from 'redux-saga/effects';

import { canShowFamilyModal, logError } from './subscriptionRenewal.helpers';

const sendBillingGa = createGaSender('billing');

function* handleCancelAutoRenew({
    payload: { sendAnalytics, product, subscription },
}: ReturnType<typeof SubscriptionRenewalActions.cancelAutoRenew>) {
    const { isTrial, isPrepaid } = subscription;
    sendBillingGa('autorenew-cancel-click');
    // tempexp_16481-next-line
    sendAnalytics?.(ESubscriptionModalAction.openCancelAutopayment);

    const outflowChannel = channel();

    yield renderCancelAutoRenewConfirmDialog({
        subscription,
        isTrial: isTrial && !isPrepaid,
        onAction() {
            sendAnalytics?.(ESubscriptionModalAction.cancelAutopayment);
            outflowChannel.put(true);
            closeCancelAutoRenewConfirmDialog();
        },
        onClose() {
            outflowChannel.close();
            closeCancelAutoRenewConfirmDialog();
        },
        onSuccess() {
            sendAnalytics?.(ESubscriptionModalAction.notCancelAutopayment);
            outflowChannel.close();
            closeCancelAutoRenewConfirmDialog();
        },
    });

    // tempexp_17359-start
    const showFamilyModal = yield canShowFamilyModal();
    if (showFamilyModal) {
        const isSuccess = yield take(outflowChannel);
        if (isSuccess) {
            yield renderCancelAutoRenewFamilyModal({ subscription });
            outflowChannel.close();
        }
    }
    // tempexp_17359-end

    // tempexp_17581-start
    const canDiscountInOutflow = yield select(BuySubscriptionSelectors.getCanSetDiscountInOutflow);
    if (canDiscountInOutflow) {
        const isSuccess = yield take(outflowChannel);
        if (isSuccess) {
            const discountInOutflow = yield select(BuySubscriptionSelectors.getDiscountInOutflow);
            yield renderDiscountInOutflowModal({
                subscription,
                product,
                discountPrice: discountInOutflow,
                onCancel() {
                    outflowChannel.put(true);
                    closeDiscountInOutflowModal();
                },
                onClose() {
                    outflowChannel.close();
                    closeDiscountInOutflowModal();
                },
                onSuccess() {
                    store.dispatch(
                        BuySubscriptionActions.setDiscountInOutflow({
                            subscriptionId: subscription.id,
                        })
                    );
                    outflowChannel.close();
                    closeDiscountInOutflowModal();
                },
            });
        }
    }
    // tempexp_17581-end

    // tempexp_13984-start
    const abWithCaptcha = yield select(getFeatureAbCancelSubsWithCaptcha);
    if (abWithCaptcha === 'b') {
        const isSuccess = yield take(outflowChannel);
        if (isSuccess) {
            yield renderCancelSubscriptionWithCaptchaModal({ subscriptionId: subscription.id, isRebranding });
            outflowChannel.close();
        }
    }
    // tempexp_13984-end

    if (yield take(outflowChannel)) {
        yield put(BuySubscriptionActions.cancelAutorenewSubscription({ subscriptionId: subscription.id, sendAnalytics }));
    }

    outflowChannel.close();
}

function* handleAddAutoRenew({
    payload: { sendAnalytics, card, subscription },
}: ReturnType<typeof SubscriptionRenewalActions.addAutoRenew>) {
    sendBillingGa('autorenew-click');
    sendAnalytics?.(ESubscriptionModalAction.turnOnAutopayment);

    if (!card) {
        yield addCard(subscription.id, sendAnalytics);
    } else {
        yield addAutoRenew(subscription.id);
    }
}

function* addCard(subscriptionId: string, sendAnalytics?: (action: ESubscriptionModalAction) => void) {
    const autoRenewChannel = channel();
    yield renderCardModal({
        onSuccess() {
            sendBillingGa('addCardClick', 'subscriptionModal');
            sendAnalytics?.(ESubscriptionModalAction.addNewCard);
            store.dispatch(
                CardActions.updateCard({
                    showNotifications: true,
                    onSuccess: () => autoRenewChannel.put(true),
                })
            );
        },
        onClose: () => {
            autoRenewChannel.close();
            closeCardModal();
        },
        onCance() {
            autoRenewChannel.close();
            sendAnalytics?.(ESubscriptionModalAction.cancelNewCard);
        },
        onUnlinkCard() {
            autoRenewChannel.close();
            store.dispatch(CardActions.updateCard({ isSilent: true }));
        },
    });

    if (yield take(autoRenewChannel)) {
        yield addAutoRenew(subscriptionId);
    }
}

function* addAutoRenew(subscriptionId: string) {
    const autoRenewChannel = channel();
    try {
        yield putResolve(yield call(BuySubscriptionActions.addAutorenew, subscriptionId));
        sendBillingGa('autorenew-success');
        autoRenewChannel.put(true);
    } catch (error) {
        sendXray(['web', 'subscription', 'autorenew', 'error']);
        logError(error);
        yield put(
            showSnackbarAction({
                text: 'Не удалось подключить автоплатёж',
                type: SnackbarTypes.failure,
                id: BuyNotifications.autoRenewFailure,
                closable: true,
            })
        );
        autoRenewChannel.close();
        yield cancel();
    }

    if (yield take(autoRenewChannel)) {
        yield put(updateSubscriptionsRequest());
    }

    autoRenewChannel.close();
}

export function* watchSubscriptionsRenewal() {
    yield takeEvery(SubscriptionRenewalActions.cancelAutoRenew.toString(), handleCancelAutoRenew);
    yield takeEvery(SubscriptionRenewalActions.addAutoRenew.toString(), handleAddAutoRenew);
}
