export const processExtInfo = (ext, extInfo, override) => {
    const maxPreviewableSize = 78643200;
    const maxEditableSize = 52428800;

    const overridedExts = ['xls', 'xlsb', 'xlsm', 'xlsx', 'doc', 'docx', 'txt'];
    const result = { ...extInfo, ...override?.[ext] };
    if (overridedExts.includes(ext)) {
        return { ...result, maxPreviewableSize, maxEditableSize };
    }

    return result;
};
