import { Headline, Radio, RadioGroup, Spacing, Text, Title } from '@vkontakte/vkui';
import classNames from 'clsx';
import { capitalize } from 'lodash-es';
import React, { type ReactElement, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { VK_UI_DARK_THEME } from 'reactApp/constants';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { sendQuotaGa } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { getFirstSomePeriodsAsWord } from 'reactApp/utils/Period';
import { formatPrice, getMonthProductPrice } from 'reactApp/utils/priceHelpers';
import { sendTmrGoal, TMR3473039 } from 'reactApp/utils/tmr';

import styles from './UpsaleWithTrialDialog.css';
import type { Props } from './UpsaleWithTrialDialog.types';
import { useYearProduct } from './useYearProduct';

export const UpsaleWithTrialDialog = memo(function UpsaleWithTrialDialog(props: Props): ReactElement {
    const isAnonymous = useSelector(UserSelectors.isAnonymous);

    const { monthProduct, yearSavingDiscount, activePeriod, resolve, isPhone } = props;

    const yearProduct = useYearProduct({
        monthProduct,
        yearProduct: props.yearProduct,
    });

    const [chosenPeriod, setChosenPeriod] = useState(activePeriod);

    const sendUpsaleWithTrialDialogAnalytics = useCallback(
        (label: string) => {
            sendQuotaGa({
                action: 'modal-choose-tariff',
                label,
                selectedPeriod: chosenPeriod === 'm' ? 'month' : 'year',
            });

            if (isAnonymous) {
                sendTmrGoal({ id: TMR3473039, goal: 'login-view' });
            }

            sendTmrGoal({ id: TMR3473039, goal: 'tariff-click' });
        },
        [chosenPeriod]
    );

    const onClose = useCallback(() => {
        sendUpsaleWithTrialDialogAnalytics('close');
        props.onClose();
    }, [props, sendUpsaleWithTrialDialogAnalytics]);

    useEffect(() => {
        sendUpsaleWithTrialDialogAnalytics('show');
        emitAnalyticEvent(AnalyticEventNames.MODAL_CHOOSE_TARIFF_SHOW);
    }, []);

    const onConfirm = useCallback(() => {
        props.onClose();

        sendUpsaleWithTrialDialogAnalytics('continue');
        emitAnalyticEvent(AnalyticEventNames.MODAL_CHOOSE_TARIFF_CONTINUE);

        if (chosenPeriod === 'm') {
            resolve(monthProduct);
        } else if (yearProduct) {
            resolve(yearProduct);
        }
    }, [props, chosenPeriod, monthProduct, yearProduct, resolve, sendUpsaleWithTrialDialogAnalytics]);

    const renderHeader = useCallback(
        () => (
            <Title
                className={classNames({
                    [styles.headerTitle]: isPhone,
                })}
            >
                Годовой тариф &mdash; выгоднее
            </Title>
        ),
        [isPhone]
    );

    const yearMonthProductPrice = useMemo(() => getMonthProductPrice(yearProduct), [yearProduct]);
    const monthProductPrice = useMemo(() => getMonthProductPrice(monthProduct), [monthProduct]);

    const switchPeriod = useCallback(
        (period) => {
            sendUpsaleWithTrialDialogAnalytics('switch');
            setChosenPeriod(period);
        },
        [setChosenPeriod, sendUpsaleWithTrialDialogAnalytics]
    );

    const monthDescription = useMemo(() => {
        if (monthProduct.discountPeriodCount) {
            return capitalize(getFirstSomePeriodsAsWord(monthProduct.discountPeriodCount, 'm'));
        }

        return 'При покупке на месяц';
    }, [monthProduct.discountPeriodCount]);

    const monthCrossedPrice = useMemo(() => {
        if (monthProduct.discountPeriodCount) {
            return (
                <>
                    <span className={styles.crossedOldPrice}>{formatPrice(getMonthProductPrice(monthProduct, false))} ₽</span>{' '}
                </>
            );
        }
        return '';
    }, [monthProduct]);

    const yearDescription = useMemo(() => {
        if (yearProduct.hasTrial) {
            return <>Затем {formatPrice(yearMonthProductPrice)}&nbsp;₽ в&nbsp;месяц при покупке на&nbsp;год</>;
        }

        return 'При покупке на год';
    }, []);

    const renderContent = useCallback(() => {
        return (
            <>
                <RadioGroup
                    className={classNames(styles.radioGroup, {
                        [styles.radioGroup_compact]: isPhone,
                    })}
                >
                    <Radio
                        className={classNames(styles.radio, {
                            [styles['radio_active']]: chosenPeriod === 'y',
                        })}
                        name="activePeriod"
                        value="year"
                        description={
                            <Text className={styles.radioDescription} weight="2">
                                {yearDescription}
                            </Text>
                        }
                        checked={chosenPeriod === 'y'}
                        onClick={() => switchPeriod('y')}
                    >
                        <Title
                            level="2"
                            className={classNames({
                                [styles.title_compact]: isPhone,
                            })}
                        >
                            {yearProduct.hasTrial ? 'Первый месяц за 0 ₽' : `${formatPrice(yearMonthProductPrice)} ₽ в месяц`}
                        </Title>
                        {yearSavingDiscount ? (
                            <div className={styles.saving}>
                                <Headline
                                    className={classNames({
                                        [styles.savingText]: isPhone,
                                    })}
                                    weight="2"
                                >
                                    -{yearSavingDiscount}%
                                </Headline>
                            </div>
                        ) : null}
                    </Radio>

                    <Spacing size={isPhone ? 8 : 12} />

                    <Radio
                        className={classNames(styles.radio, {
                            [styles['radio_active']]: chosenPeriod === 'm',
                        })}
                        name="activePeriod"
                        value="month"
                        description={
                            <Text className={styles.radioDescription} weight="3">
                                {monthDescription}
                            </Text>
                        }
                        checked={chosenPeriod === 'm'}
                        onClick={() => switchPeriod('m')}
                    >
                        <Title
                            className={classNames({
                                [styles.title_compact]: isPhone,
                            })}
                            level="2"
                        >
                            {monthCrossedPrice}
                            {formatPrice(monthProductPrice)}&nbsp;₽ в&nbsp;месяц
                        </Title>
                    </Radio>
                </RadioGroup>
            </>
        );
    }, [
        chosenPeriod,
        yearMonthProductPrice,
        yearSavingDiscount,
        monthDescription,
        monthCrossedPrice,
        monthProductPrice,
        switchPeriod,
        yearDescription,
        yearProduct,
    ]);

    const containsDarkTheme = document.body.classList.contains(VK_UI_DARK_THEME);

    return (
        <BaseConfirmDialog
            dataQAId="upsale-month-tariff"
            renderHeader={renderHeader}
            size="average"
            successButtonText="Продолжить"
            buttons={[EButtonType.accept]}
            onClose={onClose}
            onSuccess={onConfirm}
            renderContent={renderContent}
            closeOnDimmerClick
            isDarkTheme={containsDarkTheme}
            buttonTheme="vk"
            className={classNames({
                [styles.root_mobile]: isPhone,
            })}
        />
    );
});
