import { xray } from 'lib/xray';
import { PaymentUTM } from 'reactApp/constants/paymentUTM';
import { historyPush, routeChangeSuccess } from 'reactApp/modules/router/router.module';
import { resetSelect } from 'reactApp/modules/selections/selections.actions';
import { setViewMode } from 'reactApp/modules/settings/settings.module';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { getIdByStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import {
    type goToCleanerAndShowQuotaCleaner,
    getUserQuotaGroup,
    loadUserQuotaCleanerListSuccess,
    loadUserQuotaGroupFromList,
    resetUserQuotaCurrentGroup,
    setCloudRemove,
    setMessagesRemove,
    setSuccessMovedToTrash,
    setTrashFilesIds,
} from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.actions';
import { sendQuotaCleanerGa } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { quotaCleanerPageManager } from 'reactApp/ui/QuotaCleanerPage/quotaCleanerPage.toolkit';
import { put, take } from 'redux-saga/effects';

export function* handleGoToCleanerAndShowQuotaCleaner(action: ReturnType<typeof goToCleanerAndShowQuotaCleaner>) {
    const { groupId, size, from } = action.payload;

    yield put(
        historyPush({
            id: getIdByStorage(EStorageType.quotaCleaner),
            search: from ? `?${PaymentUTM[from]}` : '',
            hash: 'cleaner',
        })
    );

    yield take(loadUserQuotaCleanerListSuccess);

    quotaCleanerPageManager.render(false, true);

    yield put(
        getUserQuotaGroup({
            groupId,
        })
    );

    yield take(loadUserQuotaGroupFromList);

    yield put(setViewMode({ viewMode: EViewMode.list, storage: EStorageType.home }));

    sendQuotaCleanerGa({
        action: 'cleaner_click',
        dwh: { name_block: groupId, size_block: size },
    });
    xray.send(`quota-cln-wdgt-clk`, { i: groupId });

    const result = yield take([routeChangeSuccess, resetUserQuotaCurrentGroup]);

    // Если не закрыли попап, а нажали кнопку Назад браузера, то закроем попап ручками
    if (result.type === routeChangeSuccess.toString()) {
        quotaCleanerPageManager.close();
        yield put(resetUserQuotaCurrentGroup());
        yield put(resetSelect());
        yield put(setMessagesRemove({ value: false }));
        yield put(setCloudRemove({ value: false }));
        yield put(setTrashFilesIds({ value: [] }));
        yield put(setSuccessMovedToTrash({ value: false }));
    }
}
