import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { IS_MBIZUSER, IS_PAID_USER } from 'reactApp/appHelpers/configHelpers';
import type { BusinessTemplateItem } from 'reactApp/modules/businessTemplates/businessTemplates.types';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

export const useAnalytics = (templatesCategory: string) => {
    useEffect(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.entered, section: templatesCategory });
    }, [templatesCategory]);

    const sendFileCreated = useCallback(
        (template: BusinessTemplateItem, ext: string) =>
            sendDwh({
                eventCategory: ECategoryGa.created,
                action: 'template',
                dwhData: {
                    size_file: template.size,
                    extension: ext,
                    id_media: template.filename,
                    template: template.title,
                },
            }),
        []
    );

    return { sendFileCreated };
};

export const useInformerAnalytics = () => {
    const { templatesCategory } = useParams();
    const templatePage = templatesCategory ? `templates/${templatesCategory}` : 'tempaltes/root';
    const dwh = useMemo(
        () => ({
            template_page: templatePage,
            is_mailspace_pro: IS_MBIZUSER,
            is_mailspace: IS_PAID_USER,
        }),
        [templatePage]
    );

    const clickConnectButton = useCallback(() => {
        sendPaymentGa({
            eventCategory: 'click_template_notification',
            ...dwh,
            button: 'подключить',
        });
    }, [dwh]);

    useEffect(() => {
        sendPaymentGa({
            eventCategory: 'show_template_notification',
            ...dwh,
        });
    }, [dwh]);

    return {
        clickConnectButton,
    };
};
