import { requestPayment } from 'Cloud/Application/Payment';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { IS_PAID_USER, PROMO_TARIFFS } from 'reactApp/appHelpers/configHelpers';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { uploadFileSizeLimitMbAb } from 'reactApp/appHelpers/featuresHelpers';
import { PaidInfoSelectors } from 'reactApp/modules/paidInfo/paidInfo.selectors';
import type { RootState } from 'reactApp/store';
import { EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import { type IModalCallbacks, type TariffInfo, EShowTariffType } from '../UploadBigFileDialog.types';

export const useBigFileModalCallbacks = ({
    closeModal,
    skipFileLoading,
    continueFileLoading,
    sendGa,
    tariffInfo,
    fileSizeBytes,
    paySource,
}): IModalCallbacks => {
    const paidLessThan3Month = useSelector((state: RootState) => PaidInfoSelectors.lastPaymentWasLessThan(state, { months: 3 }));

    const { trial, usualProduct, showTariffType } = <TariffInfo>tariffInfo;

    const sendPayment = useCallback(
        (label: string) => {
            sendPaymentGa({
                action: EPaymentGa.bigFileModal,
                label,
                size_offer: fileSizeBytes,
                user_status: IS_PAID_USER ? 'paid' : paidLessThan3Month ? 'new_free' : 'free',
            });
        },
        [fileSizeBytes, paidLessThan3Month]
    );

    const onSkip = useCallback(() => {
        sendGa('skip', showTariffType);
        sendPayment('cancel');
        skipFileLoading();
        closeModal();
    }, [showTariffType]); // closeModal передается из старого кода, коллбек пересоздавать не нужно при его изменении

    const onClose = useCallback(() => {
        sendGa('close', showTariffType);
        sendPayment('close');
        skipFileLoading();
        closeModal();
    }, [showTariffType]);

    const onSuccess = (gaLabel: 'buy-trial' | 'buy-tariff') => () => {
        closeModal();
        sendGa(gaLabel);

        emitAnalyticEvent(AnalyticEventNames.BIG_FILE_DIALOG_PAYMENT_BUY, {
            tariff: gaLabel === 'buy-trial' ? 'trial' : 'tariff',
        });
    };

    const onRequestPayment = useCallback(() => {
        emitAnalyticEvent(AnalyticEventNames.BIG_FILE_DIALOG_CLICK);

        const query = uploadFileSizeLimitMbAb
            ? 'utm_source=cloud&utm_medium=web_union_quota_landing&utm_campaign=popup_heavy_file&utm_term=17018'
            : undefined;

        sendGa('request-payment', showTariffType);
        emitAnalyticEvent(AnalyticEventNames.BIG_FILE_DIALOG_PAYMENT_SHOW);
        sendPayment('click');
        // Пользователю с неактивированным триалом в 128ГБ
        // показываем окно с предложением оплатить триал.
        // Оверквотеру триал не показываем.
        if (showTariffType === EShowTariffType.TRIAL) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            requestPayment({
                productId: trial.id,
                forceFastCheckout: true,
                onSuccess: onSuccess('buy-trial'),
                afterUpdate: continueFileLoading,
                paySource,
                query,
            });

            return;
        }

        // Бесплатному пользователю
        // показываем окно фастчекаута
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        requestPayment({
            forceFastCheckout: true,
            onSuccess: onSuccess('buy-tariff'),
            afterUpdate: continueFileLoading,
            tariff: usualProduct?.id,
            paySource,
            query,
        });
    }, [showTariffType, usualProduct?.id, trial?.id, onSuccess]);

    // tempexp_18088-start
    const onRequestHeavyFile = useCallback(() => {
        sendPayment('click_onetime');
        emitAnalyticEvent(AnalyticEventNames.BIF_FILE_CLICK_BUY_ONCE);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        requestPayment({
            forceFastCheckout: true,
            onSuccess: onSuccess('buy-tariff'),
            afterUpdate: continueFileLoading,
            productId: PROMO_TARIFFS.heavyFile,
            paySource: 'cloud_popup_heavy_file_onetime',
            title: 'Оплата загрузки одного файла',
            description: `Загрузка одного файла до 100 ГБ в пределах свободного места`,
        });
    }, [onSuccess]);
    // tempexp_18088-end

    return {
        onSkip,
        onRequestPayment,
        onClose,
        sendAnalytics: sendPayment,
        // tempexp_18088-next-line
        onRequestHeavyFile,
    };
};
