import React, { type VFC } from 'react';
import { Icon16ChevronRight } from 'reactApp/ui/touchIcons';

import styles from './FileDirection.css';

type Props = {
    direction: string[];
};

export const FileDirection: VFC<Props> = ({ direction }) => (
    <div className={styles.root}>
        <span>Облако</span>
        {direction.length > 0 && (
            <>
                {direction.length > 1 && (
                    <>
                        <Icon16ChevronRight className={styles.directionIcon} />
                        <span>{'\u2026'}</span>
                    </>
                )}
                <Icon16ChevronRight className={styles.directionIcon} />
                <span className={styles.folder}>{direction.slice(-1)[0]}</span>
            </>
        )}
    </div>
);
