import React, { type ReactElement, memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { toggleBin, trashbinLoad } from 'reactApp/modules/trashbin/trashbin.module';
import { getBins, getOpenedBinId } from 'reactApp/modules/trashbin/trashbin.selectors';
import { useRouteChangeProcessing } from 'reactApp/sections/MainPage/hooks/useRouteChangeProcessing';
import { ActionsBar } from 'reactApp/ui/Mobile/ActionsBar/ActionsBar';
import { ActionsCarousel } from 'reactApp/ui/Mobile/ActionsCarousel/ActionsCarousel';
import { BreadcrumbsContainer as Breadcrumbs } from 'reactApp/ui/Mobile/Breadcrumbs/BreadcrumbsContainer';
import { Datalist } from 'reactApp/ui/Mobile/Datalist/Datalist';
import { Informer } from 'reactApp/ui/Mobile/Informer/Informer';
import { Toolbar } from 'reactApp/ui/Mobile/Toolbar/Toolbar';
import { PageTabMenu } from 'reactApp/ui/PageTabMenu/PageTabMenu';
import { Icon20DeleteClockOutline } from 'reactApp/ui/touchIcons';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './MobileTrashbinPage.css';

export const MobileTrashbinPage = memo(function MobileTrashbinPage(): ReactElement {
    const dispatch = useDispatch();

    const isSelecting = useSelector(SelectionsSelectors.isSelecting);
    const selectedIds = useSelector(SelectionsSelectors.getSelectedIdxs);
    const bins = useSelector(getBins);
    const openedBin = useSelector(getOpenedBinId);
    const tabs = useMemo(() => bins.map((bin) => ({ value: bin.id, name: bin.name })), [bins]);
    const currentTabName = tabs.find((tab) => tab.value === openedBin)?.name || '';

    const showTabs = bins.length > 1;
    const showActionsBar = selectedIds.length > 0;

    useRouteChangeProcessing({ storage: EStorageType.trashbin, path: ROOT_FOLDER_ID, search: location.search });

    const setCurrentBin = useCallback(
        (id) => {
            if (openedBin !== id) {
                dispatch(toggleBin({ id }));
            }
        },
        [dispatch, openedBin]
    );

    useEffect(() => {
        dispatch(trashbinLoad());

        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'touch-trashbin' });
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'page-entered', source: 'touch-trashbin' });
    }, []);

    return (
        <>
            <div className={styles.root}>
                <Toolbar showOptions={false} showMenu />
                <div className={styles.content} data-qa-name={currentTabName}>
                    <Breadcrumbs />
                    <Informer
                        icon={<Icon20DeleteClockOutline width={20} height={20} />}
                        text="Файлы автоматически удаляются через 14 дней"
                    />
                    {showTabs && (
                        <div className={styles.menu}>
                            <PageTabMenu current={openedBin} tabs={tabs} onClick={setCurrentBin} noVerticalScroll isMobile />
                        </div>
                    )}
                    <ActionsCarousel disabled={isSelecting} />
                    <Datalist />
                </div>
            </div>
            {showActionsBar && <ActionsBar />}
        </>
    );
});
