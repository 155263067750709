import classNames from 'clsx';
import React, { type VFC } from 'react';
import { tariffCardPersonal } from 'reactApp/appHelpers/featuresHelpers/features/tariifCardPersonal';

import styles from './TariffSpace.css';
import type { ITariffSpaceProps } from './TariffSpace.types';

export const TariffSpace: VFC<ITariffSpaceProps> = ({
    dataQa,
    space,
    units,
    giftSpace,
    giftKind,
    showSpaceWithPlus,
    contentSize = 'middle',
    isRebrandingQuotaLanding = false,
    isRebranding = false,
}) => (
    <div
        className={classNames(styles.space, {
            [styles.space_withGift]: Boolean(giftSpace),
            [styles.space_rebrandingQuotaLanding]: isRebrandingQuotaLanding,
            // tempexp_18084-next-line
            [styles.space_personal]: tariffCardPersonal === 'c',
        })}
        data-qa={dataQa}
    >
        <div
            className={classNames(styles.spaceName, {
                [styles[`contentSize_${contentSize}`]]: contentSize,
                [styles.spaceName_rebranding]: isRebranding,
            })}
            data-qa={dataQa && 'volume'}
        >
            {!giftSpace && showSpaceWithPlus && '+'}
            <span className={styles.spaceSize}>{space}</span>
        </div>
        <div
            className={classNames(styles.spaceUnits, {
                [styles[`contentSize_${contentSize}`]]: contentSize,
                [styles.spaceUnits_rebranding]: isRebranding,
            })}
            data-qa={dataQa && 'units'}
        >
            {units}
        </div>
        {giftSpace && (
            <div
                className={classNames(styles.gift, {
                    [styles[`gift_${giftKind}`]]: Boolean(giftKind),
                })}
            >
                <div className={styles.plus}>+</div>
                <div className={styles.spaceName} data-qa={dataQa && 'gift-volume'}>
                    {giftSpace.space}
                </div>
                <div className={styles.spaceUnits} data-qa={dataQa && 'gift-units'}>
                    * {giftSpace.units}
                </div>
            </div>
        )}
    </div>
);
