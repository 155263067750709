import React, { type FC } from 'react';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { Checkbox } from 'reactApp/ui/DataListItemCommonComponents/Checkbox/Checkbox';
import { Download } from 'reactApp/ui/DataListItemCommonComponents/Download/Download';
import { Date } from 'reactApp/ui/DataListItemRow/components/Date/Date';
import { ItemName } from 'reactApp/ui/DataListItemRow/components/ItemName/ItemName';
import { TableColumn } from 'reactApp/ui/DataListItemRow/components/TableColumn/TableColumn';
import type { IncomingPublicItemProps } from 'reactApp/ui/DataListItemRow/DataListItemRow.types';
import { useCompactView } from 'reactApp/ui/VirtualList/VirtualList.hooks';

import styles from '../../DataListItemRow.new.css';

export const IncomingPublic: FC<IncomingPublicItemProps> = (props) => {
    const { id, storage, name, ext, isSelected, ctime, onCheckboxClick, onDownload } = props;
    const isCompact = useCompactView();

    return (
        <>
            <TableColumn>
                <Checkbox viewType={EViewMode.list} className={styles.check} onCheckboxClick={onCheckboxClick} isSelected={isSelected} />
            </TableColumn>

            {isCompact ? (
                <TableColumn width="100%" overflow="hidden">
                    <div className={styles.nameWrapper}>
                        <ItemName name={name} ext={ext || ''} id={id} />
                        <span className={styles.responsiveDateWrapper}>
                            <Date date={ctime} className={styles.date} />
                        </span>
                    </div>
                </TableColumn>
            ) : (
                <>
                    <TableColumn width="100%" overflow="hidden">
                        <ItemName name={name} ext={ext || ''} id={id} />
                    </TableColumn>
                    <TableColumn flexBasis={132} flexShrink={0} justifyContent="flex-end">
                        <Date date={ctime} />
                    </TableColumn>
                </>
            )}

            <TableColumn flexBasis={100} flexShrink={0} justifyContent="flex-end" gap={20}>
                <Download onClick={onDownload} ext={ext} id={id} storage={storage} viewType={EViewMode.list} />
            </TableColumn>
        </>
    );
};
