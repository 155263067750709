import { Icon20Chain } from '@vkontakte/icons';
import React, { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { IS_REQUIRED_B2B_PASSWORD_PUBLIC } from 'reactApp/appHelpers/configHelpers';
import { isPublicPasswordEnabled } from 'reactApp/appHelpers/featuresHelpers/features/publicPassword';
import { publishHelper } from 'reactApp/appHelpers/publishHelper';
import { renderPublicPassword } from 'reactApp/components/SharingNewBiz/PublicPasswordDialog/PublicPasswordDialog.helpers';
import { AccessStatus, DomainAccess } from 'reactApp/components/SharingNewBiz/SharingNew.types';
import type { PublishItem } from 'reactApp/modules/modifying/modifying.types';
import { getPublicPin } from 'reactApp/modules/public/public.selectors';
import { snackbarController } from 'reactApp/modules/snackbar/snackbar.controller';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { getItemById } from 'reactApp/modules/storage/storage.selectors';
import type { RootState } from 'reactApp/store';
import { copy } from 'reactApp/utils/copyToClipboard';
import { sendGa } from 'reactApp/utils/ga';

import styles from '../EmptyFolder.css';
import { EItemType, gaSuffix } from '../EmptyFolder.types';

const TITLES_AND_DESCRIPTIONS: Record<AccessStatus, { title: string; annotation: string }> = {
    [AccessStatus.Upload]: {
        title: 'Совместная загрузка включена',
        annotation: 'Теперь другие пользователи могут смотреть и загружать файлы в эту папку',
    },
    [AccessStatus.ReadOnly]: {
        title: 'Открыт доступ по ссылке',
        annotation: 'Теперь другие пользователи могут просматривать файлы в этой папке',
    },
    [AccessStatus.Download]: {
        title: 'Открыт доступ по ссылке',
        annotation: 'Теперь другие пользователи могут скачать эту папку',
    },
    [AccessStatus.Edit]: {
        title: '',
        annotation: '',
    },
};

export const useCoshare = (id: string, publicUrl?: string) => {
    const coshareRef = useRef<HTMLInputElement>(null);
    const item = useSelector((state: RootState) => getItemById(state, id));
    const pin = useSelector((state: RootState) => getPublicPin(state, item?.weblink || ''));

    const Coshare = useMemo(
        () => (
            <div className={styles.icon} ref={coshareRef}>
                <Icon20Chain />
            </div>
        ),
        [coshareRef]
    );

    const handlePublishing = useCallback(() => {
        sendGa(gaSuffix, 'publish');

        publishHelper({
            item: { id },
            // При нажатии на "Включить совместную загрузку", надо выставлять права на загрузку
            defaultAccessRights: publicUrl ? undefined : AccessStatus.Upload,
            publishFrom: 'empty-folder',
        });
    }, [id, publicUrl]);

    const onCopyClick = useCallback(
        (event) => {
            event.stopPropagation();
            event.nativeEvent?.stopImmediatePropagation?.();

            if (!publicUrl) {
                return;
            }

            const copyHandler = () =>
                copy(publicUrl)
                    .then(({ hasBeenCopied }): void => {
                        if (hasBeenCopied) {
                            snackbarController.showSnackbar({
                                type: SnackbarTypes.success,
                                text: 'Ссылка успешно скопирована',
                                id: 'copyLink',
                                closable: true,
                            });
                        }
                    })
                    .catch(() =>
                        snackbarController.showSnackbar({
                            type: SnackbarTypes.failure,
                            text: 'Не удалось скопировать ссылку',
                            id: 'copyLink',
                            closable: true,
                        })
                    );

            if (
                isPublicPasswordEnabled &&
                IS_REQUIRED_B2B_PASSWORD_PUBLIC &&
                item &&
                'weblinkDomainAccess' in item &&
                item.weblinkDomainAccess === DomainAccess.EXTERNAL &&
                !pin
            ) {
                renderPublicPassword({
                    item: item as PublishItem,
                    onSuccess: () => {
                        copyHandler();
                    },
                });
            } else {
                copyHandler();
            }
        },
        [item, pin, publicUrl]
    );

    const additionLinks = useMemo(() => {
        if (!publicUrl) {
            return;
        }

        return (
            <div className={styles.buttonGroup}>
                <div className={styles.button} onClick={onCopyClick}>
                    Скопировать ссылку
                </div>
                <div className={styles.button}>
                    <span className={styles.textOverflow}>Открыть{String.fromCharCode(160)}настройки доступа</span>
                </div>
            </div>
        );
    }, [onCopyClick, publicUrl]);

    return useCallback(
        (accessRights?: AccessStatus) => {
            const {
                title = 'Включить совместную загрузку',
                annotation = 'Другие пользователи смогут смотреть и загружать файлы в эту папкy',
            } = (publicUrl && accessRights && TITLES_AND_DESCRIPTIONS[accessRights]) || {};

            const defaultValue = {
                title,
                annotation,
                onClick: handlePublishing,
                type: EItemType.COSHARING,
                Icon: Coshare,
            };

            return publicUrl ? { ...defaultValue, additionLinks } : defaultValue;
        },
        [Coshare, additionLinks, handlePublishing, publicUrl]
    );
};
