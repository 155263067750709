import { Icon20InfoCircleOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import { ReactComponent as CloudRebrandingIcon } from 'img/icons/cloud_rebranding.svg';
import { ReactComponent as ExclamationAnotherIcon } from 'img/icons/exclamationAnother.svg';
import overquotaClearedImage from 'img/overquota/maskot.png';
import overquotaClearedDarkImage from 'img/overquota/maskotDark.png';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { overquotaNewCleared } from 'reactApp/appHelpers/featuresHelpers/features/overquotaNewCleared';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import type { EStorageType } from 'reactApp/modules/storage/storage.types';
import { isCurrentDarkTheme } from 'reactApp/modules/theme/theme.selectors';
import styles from 'reactApp/ui/OverquotaPopup/newSplash/OverquotaSplash.css';
import type { ModeType } from 'reactApp/ui/OverquotaPopup/newSplash/OverquotaSplash.types';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';

export const Icon: React.FC<{ mode?: ModeType; storage: EStorageType; isMobile?: boolean }> = memo(({ mode, storage, isMobile }) => {
    const { isSubscription, isQuotaCleaner, isQuotaPartner, isQuotaTariffs } = getStorage(storage);
    const isDarkTheme = useSelector(isCurrentDarkTheme);
    const isDark = isSubscription || isQuotaCleaner || isQuotaPartner || isQuotaTariffs ? isDarkThemeModeEnabled() : isDarkTheme;

    if (mode === 'cleared' && overquotaNewCleared) {
        return <Icon20InfoCircleOutline className={styles.iconInfo} width={38} height={38} />;
    }

    if (mode === 'cleared') {
        return (
            <img
                src={isDark ? overquotaClearedDarkImage : overquotaClearedImage}
                alt="Ваши файлы были удалены"
                width={isMobile ? '100%' : '480'}
            />
        );
    }

    return (
        <div className={classNames(styles.icon, styles.iconRenranding)}>
            <CloudRebrandingIcon width={141} height={141} />
            <div className={styles.iconExclClear}>
                <ExclamationAnotherIcon width={7} height={33} />
            </div>
        </div>
    );
});

Icon.displayName = 'Icon';
