import { Button, ModalPage, ModalRoot, PlatformProvider, SplitLayout } from '@vkontakte/vkui';
import React, { useCallback, useState } from 'react';

import styles from './KotlettBugreport.css';
import { KotlettBugreportFeature } from './KotlettBugreportFeature';

interface Props {
    className?: string;
}

export function KotlettBugreport({ className }: Props) {
    const [isOpened, setIsOpened] = useState(false);

    const handleClose = useCallback(() => {
        setIsOpened(false);
    }, []);

    const onClick = useCallback(() => {
        setIsOpened(true);
    }, []);

    const modal = (
        <ModalRoot activeModal="bugreportKotlettModal">
            <ModalPage id="bugreportKotlettModal" onClose={handleClose} hideCloseButton size={480}>
                <KotlettBugreportFeature onClose={handleClose} />
            </ModalPage>
        </ModalRoot>
    );

    return (
        <div className={className}>
            <Button
                style={{
                    background: 'var(--vkui--color_background_secondary_alpha)',
                    color: 'var(--vkui--color_text_primary)',
                    fontFamily: 'var(--vkui--font_headline2--font_family--regular)',
                    backdropFilter: 'blur(12px)',
                }}
                onClick={onClick}
                before={
                    <img
                        src={'https://imgs2.imgsmail.ru/static/icons/bugreport/bug.png'}
                        srcSet={
                            'https://imgs2.imgsmail.ru/static/icons/bugreport/bug.png 1x, https://imgs2.imgsmail.ru/static/icons/bugreport/bug2x.png 2x'
                        }
                        className={styles.image}
                    />
                }
            >
                Нашли ошибку?
            </Button>
            {isOpened && (
                <PlatformProvider value="vkcom">
                    <SplitLayout modal={modal} />
                </PlatformProvider>
            )}
        </div>
    );
}
