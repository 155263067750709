import { BUSINESS_TEMPLATES_DEMO_PROMO, BUSINESS_TEMPLATES_DEMO_PROMO_TOOLTIP } from './businessTemplates';

/** Варианты промо-баннера о демо фичах в левом сайдбаре */
export type DemoPromoVariant = 'business-templates';

/** Варианты промо-тултипа рядом с баннером в левом сайдбаре */
export type DemoPromoTooltipVariant = 'business-templates-tooltip';

/** Вариант промо-баннера о демо фичах в левом сайдбаре, который надо показать */
export const DEMO_PROMO_VARIANT = ((): DemoPromoVariant | null => {
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (true) {
        case BUSINESS_TEMPLATES_DEMO_PROMO:
            return 'business-templates';
        default:
            return null;
    }
})();

/** Вариант промо-тултипа рядом с баннером в левом сайдбаре, который надо показать */
export const DEMO_PROMO_TOOLTIP_VARIANT = ((): DemoPromoTooltipVariant | null => {
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (true) {
        case DEMO_PROMO_VARIANT === 'business-templates' && BUSINESS_TEMPLATES_DEMO_PROMO_TOOLTIP:
            return 'business-templates-tooltip';
        default:
            return null;
    }
})();
