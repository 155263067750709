/* eslint-disable max-lines */
import type { PayloadAction } from '@reduxjs/toolkit';
import api from 'Cloud/Application/api';
import { MYOFFICE_VARIANTS } from 'Cloud/Application/Editor/MyOffice/myOffice.types';
import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { logger } from 'lib/logger';
import { ShareAlbumAPICall } from 'reactApp/api/albums/ShareAlbumAPICall';
import { UnshareAlbumAPICall } from 'reactApp/api/albums/UnshareAlbumAPICall';
import { publishFileApiCall } from 'reactApp/api/publish/PublishFileApiCall';
import { unpublishFileApiCall } from 'reactApp/api/publish/UnPublishFileApiCall';
import { weblinksAutodeletecOffApiCall } from 'reactApp/api/weblinks/WeblinksAutodeleteOffApiCall';
import { weblinksAutodeletecOnApiCall } from 'reactApp/api/weblinks/WeblinksAutodeleteOnApiCall';
import { weblinksDomesticOffApiCall } from 'reactApp/api/weblinks/WeblinksDomesticOffApiCall';
import { weblinksDomesticOnApiCall } from 'reactApp/api/weblinks/WeblinksDomesticOnApiCall';
import { weblinksEditableOffApiCall } from 'reactApp/api/weblinks/WeblinksEditableOffApiCall';
import { weblinksEditableOnApiCall } from 'reactApp/api/weblinks/WeblinksEditableOnApiCall';
import { expiresWeblinksApiCall } from 'reactApp/api/weblinks/WeblinksExpiresApiCall';
import { weblinksReadonlyApiCall } from 'reactApp/api/weblinks/WeblinksReadonlyApiCall';
import { weblinksWritableApiCall } from 'reactApp/api/weblinks/WeblinksWritableApiCall';
import { IS_B2B_BIZ_USER, IS_BIZ_USER, IS_ONPREMISE, IS_REQUIRED_B2B_PASSWORD_PUBLIC } from 'reactApp/appHelpers/configHelpers';
import { isPersonalAccessSharingEnabled } from 'reactApp/appHelpers/featuresHelpers/features/personalAccessSharing';
import { isPublicPasswordEnabled } from 'reactApp/appHelpers/featuresHelpers/features/publicPassword';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { renderPublicPassword } from 'reactApp/components/SharingNewBiz/PublicPasswordDialog/PublicPasswordDialog.helpers';
import { renderSharingNew } from 'reactApp/components/SharingNewBiz/SharingNew.helpers';
import { AccessStatus, EFrom } from 'reactApp/components/SharingNewBiz/SharingNew.types';
import { DomainAccess } from 'reactApp/components/SharingWindow/Sharing.types';
import { MAIL_ATTACHES_FOLDER_ID, ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { handleSendAllDocumentsXray } from 'reactApp/modules/allDocuments/allDocuments.saga';
import { AllDocumentsXrayTypes } from 'reactApp/modules/allDocuments/allDocuments.types';
import type { AttachesItem } from 'reactApp/modules/attaches/attaches.types';
import type { EditorItem } from 'reactApp/modules/editor/editor.types';
import { getFeatureABCreateFolderNewFlow, getFeatureByName, getFeatureWopi } from 'reactApp/modules/features/features.selectors';
import { isFolder, isVirusItem } from 'reactApp/modules/file/utils';
import { loadHomeFolder } from 'reactApp/modules/home/home.saga';
import { getHomeItemById, getIntraDomainShare } from 'reactApp/modules/home/home.selectors';
import {
    addFilesSuccess,
    cloneToCloudFailure,
    cloneToCloudSuccess,
    publishWeblink,
    resetWeblinkCountDownloads,
    toggleSeoRequest,
    toggleWeblinkAccessRights,
    toggleWeblinkDomestic,
    toggleWeblinkDownloadable,
    toggleWeblinkDownloadableRequest,
    unPublishWeblink,
    updateWeblinkAutoDelete,
    updateWeblinkAutoDeleteRequest,
    updateWeblinkCountDownloads,
    updateWeblinkExpires,
    updateWeblinkExpiresRequest,
    weblinkSetDomainAccess,
} from 'reactApp/modules/modifying/modifying.actions';
import { publishErrors } from 'reactApp/modules/modifying/modifying.constants';
import { getIdParams } from 'reactApp/modules/modifying/modifying.helpers';
import type {
    DeleteWeblinkExpiresRequest,
    IOpenPublishDialog,
    IPublishAndToggleUploadRequest,
    IPublishRequest,
    IUnPublishRequest,
    ToggleWeblinkDomesticRequest,
    ToggleWeblinkDownloadableRequest,
    ToggleWeblinkEditableRequest,
    ToggleWeblinkUploadRequest,
    UpdateWeblinkAutoDeleteRequest,
    UpdateWeblinkCountDownloadsRequest,
    UpdateWeblinkExpiresRequest,
} from 'reactApp/modules/modifying/modifying.types';
import { showVirusDlg } from 'reactApp/modules/popup/popup.module';
import { getPublicSharingInfo, updatePublicInfoPin } from 'reactApp/modules/public/public.actions';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { newSendSearchRadarAnalytics } from 'reactApp/modules/search/search.analytics';
import { getSearchRequestParams } from 'reactApp/modules/search/search.selectors';
import { type SearchItem, EActionSearch } from 'reactApp/modules/search/search.types';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { isDomainFolder, isMountedFolder, isSharedFolder } from 'reactApp/modules/storage/folder.helpers';
import { getItemById, getStorageItemById, isMountedOrSharedFolder } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { store } from 'reactApp/store';
import { AccessRights } from 'reactApp/types/Tree';
import { openDisabledFeaturePopupHelper } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.helpers';
import { DisabledFeature } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.types';
import { ActionName } from 'reactApp/ui/VirusDialog/VirusDialog.types';
import { callSagaFromAction } from 'reactApp/utils/callSagaFromAction';
import { isEditingAttach, isEditingStorage } from 'reactApp/utils/isEditingFile';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { sendEditRadar } from 'reactApp/utils/sendEditRadar';
import { sendTmrGoal } from 'reactApp/utils/tmr';
import { all, call, cancel, put, select, take } from 'redux-saga/effects';

const CREATE_PUBLIC_ACTION_NAME = 'created-public';

const showWeblinkListFileSharing = getFeature('weblink-list-file-sharing') || false;
const publicEmailACByDefault = getFeature('public-email-ac-by-default') || false;

const publishFile = (idParams) => publishFileApiCall(idParams);
const unPublishFile = (idParams) => unpublishFileApiCall(idParams);
const shareAlbumAPICall = ({ id }) => new ShareAlbumAPICall().makeRequest({ album_id: id });
export const unshareAlbumAPICall = ({ id }) => new UnshareAlbumAPICall().makeRequest({ album_id: id });

async function unpublishRequest(item) {
    if (!item.weblink) {
        return;
    }

    try {
        if (item.storage === EStorageType.albums) {
            await unshareAlbumAPICall({ id: item.id });
            return;
        }

        await unPublishFile({ weblink: item.weblink });

        if (IS_B2B_BIZ_USER) {
            store.dispatch(
                showSnackbarAction({
                    id: 'unpublish-success',
                    text: `Доступ к ${item.isFolder ? 'папке' : 'файлу'} «${item.name}» по ссылке закрыт`,
                    type: SnackbarTypes.success,
                    closable: true,
                })
            );
        }
    } catch ({ error }: any) {
        return { error };
    }
}

export function* toggleDownloadable(action: PayloadAction<ToggleWeblinkDownloadableRequest>) {
    const { item, isUnpublishRequest } = action.payload;

    if (item.weblinkDownloadable) {
        yield put(toggleWeblinkDownloadable({ id: item.id }));
        yield put(resetWeblinkCountDownloads({ id: item.id }));
    } else if (!isUnpublishRequest) {
        try {
            yield Promise.resolve(api.weblinks.downloadable({ weblink: item.weblink }));
            yield put(toggleWeblinkDownloadable({ id: item.id, downloadable: true }));
            yield put(toggleWeblinkAccessRights({ id: item.id }));
        } catch (error) {
            logger.error(error);

            yield put(
                showSnackbarAction({
                    closable: true,
                    id: 'toggle-weblink-downloadable-on-error',
                    type: SnackbarTypes.failure,
                    text: 'Не удалось разрешить загрузку',
                })
            );
        }
    }
}

export function* handleUnPublish({ items }: IUnPublishRequest) {
    const files = yield all(items.map((item) => unpublishRequest(item)));
    const { error } = files.find((item) => item?.error) || {};

    yield put(unPublishWeblink({ ids: items.map((item) => item.id) }));

    yield all(
        items.map((item) =>
            toggleDownloadable({ type: toggleWeblinkDownloadableRequest.toString(), payload: { item, isUnpublishRequest: true } })
        )
    );
    const storage = yield select(getCurrentStorage);

    if (storage === EStorageType.search) {
        const { xPageId, xReqId, query } = yield select(getSearchRequestParams);
        newSendSearchRadarAnalytics({
            eventCategory: ECategoryGa.search,
            action: EActionSearch.closePublic,
            searchParams: { search_phrase: query, page_id: xPageId, req_id: xReqId },
            items: (items as SearchItem[]).map((item) => ({
                file_name: 'nameWithoutExt' in item && item.nameWithoutExt,
                type: item.kind,
                pos: item.pos,
                file_id: item.id,
                extension: item.isFolder ? 'None' : item.ext,
                public_id: ('weblink' in item && item.weblink) || '',
                placement: 'srchSrc' in item && item.srchSrc,
            })),
        });
    }

    if (!error) {
        return;
    }

    yield put(
        showSnackbarAction({
            id: 'unpublish-error',
            text: 'Не удалось убрать ссылку',
            type: SnackbarTypes.failure,
            closable: true,
        })
    );
}

function* sendPublicCreatedDwh(publishedItem, public_id: string = '', itemStorage, publishFrom) {
    if (publishedItem.weblink) {
        return;
    }

    sendTmrGoal({ goal: 'publish' });

    const currentStorage = yield select(getCurrentStorage);
    const { xPageId, xReqId, query } = yield select(getSearchRequestParams);
    const storage = itemStorage || currentStorage;
    const item = yield select((state) => getStorageItemById(state, storage, publishedItem.id));

    const isWopiFeatureEnabled = yield select(getFeatureWopi);
    if (item && isEditingStorage(currentStorage)) {
        sendEditRadar({
            i: 'new-public',
            storage: currentStorage,
            item: item as EditorItem,
            version: isWopiFeatureEnabled ? MYOFFICE_VARIANTS.wopi : MYOFFICE_VARIANTS.amr,
            isAttach: isEditingAttach(currentStorage),
            dwh: {
                id_public: (item as EditorItem).weblink,
            },
        });
    }

    if (!publishFrom) {
        return;
    }

    sendPaymentGa({
        eventCategory: ECategoryGa.public,
        action: CREATE_PUBLIC_ACTION_NAME,
        tab: currentStorage,
        public_id,
        type_object: item.kind,
        extension: 'ext' in item ? item.ext : '',
        type_for: item.weblinkDomestic ? 'domestic' : 'external',
        type_access: item.weblinkAccessRights || 'r',
        time_ttl: item.weblinkExpires === 0 ? 'unlimited' : 'time',
        source: publishFrom,
    });

    if (storage === EStorageType.search) {
        newSendSearchRadarAnalytics({
            eventCategory: ECategoryGa.publicSearch,
            action: CREATE_PUBLIC_ACTION_NAME,
            searchParams: {
                search_phrase: query,
                page_id: xPageId,
                req_id: xReqId,
            },
            items: [
                {
                    file_name: 'nameWithoutExt' in item && item.nameWithoutExt,
                    type: item.kind,
                    pos: item.pos,
                    file_id: item.id,
                    public_id,
                    placement: item.srchSrc,
                },
            ],
        });
    }
}

export function* publishAlbum({ item, itemStorage = EStorageType.albums, publishFrom = 'album' }: IPublishRequest) {
    const { data: weblink } = yield call(shareAlbumAPICall, { id: item.id });

    yield sendPublicCreatedDwh(item, weblink.id, itemStorage, publishFrom);

    yield put(publishWeblink({ weblink: weblink.id, id: item.id }));
}

export function* updateExpires(action: PayloadAction<UpdateWeblinkExpiresRequest>) {
    const { item, expires } = action.payload;

    try {
        yield call(expiresWeblinksApiCall, {
            weblink: item.weblink,
            expires: Math.trunc(expires / 1000),
        });
        yield put(updateWeblinkExpires({ id: item.id, expires, expiresTimeSize: action.payload.expiresTimeSize }));
    } catch (error) {
        yield cancel();
    }
}

export function* deleteExpires(action: PayloadAction<DeleteWeblinkExpiresRequest>) {
    const { item } = action.payload;
    try {
        yield call(expiresWeblinksApiCall, {
            weblink: item.weblink,
        });

        yield put(updateWeblinkExpires({ id: item.id, expires: 0 }));
        yield put(updateWeblinkAutoDeleteRequest({ item, expires: 0 }));
    } catch (error) {
        yield cancel();
    }
}

function* toggleDomesticRequest({ item: publishItem }: ToggleWeblinkDomesticRequest) {
    const currentStorage = yield select(getCurrentStorage);
    const storage = publishItem.storage || currentStorage;

    const item = yield select((state) => getStorageItemById(state, storage, publishItem.id));

    try {
        if (item.weblinkDomestic) {
            yield weblinksDomesticOffApiCall({ weblink: item.weblink });
        } else {
            yield weblinksDomesticOnApiCall({ weblink: item.weblink });
        }

        yield put(toggleWeblinkDomestic({ id: item.id, domestic: !item.weblinkDomestic }));
    } catch (error) {
        logger.error(error);
        yield cancel();
    }
}

export function* updateAutoDelete(action: PayloadAction<UpdateWeblinkAutoDeleteRequest>) {
    const { item, expires } = action.payload;

    try {
        if (expires) {
            yield updateExpires(updateWeblinkExpiresRequest({ item, expires }));

            yield call(weblinksAutodeletecOnApiCall, {
                weblink: item.weblink,
            });
        } else {
            yield call(weblinksAutodeletecOffApiCall, {
                weblink: item.weblink,
            });
        }

        yield put(updateWeblinkAutoDelete({ id: item.id, autoDelete: Boolean(expires) }));
    } catch (error) {
        logger.error(error);
        yield cancel();
    }
}

export function* toggleUploadRequest({ item: publishItem, id }: ToggleWeblinkUploadRequest) {
    const currentStorage = yield select(getCurrentStorage);
    const storage = publishItem.storage || currentStorage;

    const item = yield select((state) => getStorageItemById(state, storage, publishItem.id));

    if ((id && id === AccessStatus.ReadOnly) || item.weblinkAccessRights === AccessRights.rw) {
        try {
            yield call(weblinksReadonlyApiCall, { weblink: item.weblink });
            yield put(toggleWeblinkAccessRights({ id: item.id, type: AccessRights.r }));
        } catch (error) {
            yield put(
                showSnackbarAction({
                    closable: true,
                    id: 'toggle-weblink-readonly-error',
                    type: SnackbarTypes.failure,
                    text: 'Не удалось запретить загрузку в папку',
                })
            );
        }
    } else {
        try {
            yield call(weblinksWritableApiCall, { weblink: item.weblink });
            yield put(toggleWeblinkAccessRights({ id: item.id, type: AccessRights.rw }));
        } catch (error) {
            logger.error(error);

            yield put(
                showSnackbarAction({
                    closable: true,
                    id: 'toggle-weblink-writable-error',
                    type: SnackbarTypes.failure,
                    text: 'Не удалось разрешить загрузку в папку',
                })
            );
        }
    }
}

export function* toggleEditable(action: PayloadAction<ToggleWeblinkEditableRequest>) {
    const { item, id } = action.payload;

    if ((id && id === AccessStatus.ReadOnly) || item.weblinkAccessRights === AccessRights.rw) {
        try {
            yield call(weblinksEditableOffApiCall, { weblink: item.weblink });
            yield put(toggleWeblinkAccessRights({ id: item.id, type: AccessRights.r }));
        } catch (error) {
            logger.error(error);

            yield put(
                showSnackbarAction({
                    closable: true,
                    id: 'toggle-weblink-editable-off-error',
                    type: SnackbarTypes.failure,
                    text: 'Не удалось запретить совместное редактирование',
                })
            );
        }
    } else {
        try {
            yield call(weblinksEditableOnApiCall, { weblink: item.weblink });
            yield put(toggleWeblinkAccessRights({ id: item.id, type: AccessRights.rw }));
        } catch (error) {
            logger.error(error);

            yield put(
                showSnackbarAction({
                    closable: true,
                    id: 'toggle-weblink-editable-on-error',
                    type: SnackbarTypes.failure,
                    text: 'Не удалось разрешить совместное редактирование',
                })
            );
        }
    }
}

export function* updateCount(action: PayloadAction<UpdateWeblinkCountDownloadsRequest>) {
    const { item, count_downloads } = action.payload;

    try {
        yield Promise.resolve(api.weblinks.count_downloads({ weblink: item.weblink, count_downloads }));

        if (count_downloads === 0) {
            yield put(resetWeblinkCountDownloads({ id: item.id }));
        } else {
            yield put(updateWeblinkCountDownloads({ id: item.id, count_downloads }));
        }
    } catch (error) {
        logger.error(error);

        yield put(
            showSnackbarAction({
                closable: true,
                id: 'toggle-weblink-downloadable-off-error',
                type: SnackbarTypes.failure,
                text: 'Не удалось обновить счетчик загрузок',
            })
        );
    }
}

export function* publishRequest({ item: publishItem, itemStorage, publishFrom, respectDomesticByDefault = true }: IPublishRequest) {
    const currentStorage = yield select(getCurrentStorage);
    const intraDomainShareEnabled = yield select(getIntraDomainShare);
    const storage = publishItem.storage || itemStorage || (currentStorage as EStorageType);
    const isAlbum = storage === EStorageType.albums;
    const isDomesticFeatureEnabled = yield select((state) => getFeatureByName(state, 'public-domestic-by-default'));
    const item = yield select((state) => getStorageItemById(state, storage, publishItem.id));

    if (!item) {
        return;
    }

    const idParams = getIdParams(storage, item.id);

    const domesticByDefault =
        respectDomesticByDefault &&
        !item?.weblink &&
        !item?.weblinkDomestic &&
        (isDomesticFeatureEnabled || (IS_BIZ_USER && intraDomainShareEnabled));

    if (item.weblink) {
        return;
    }

    try {
        if (isAlbum) {
            yield call(publishAlbum, { item, itemStorage, publishFrom });
            return;
        }

        const { data } = yield call(publishFile, idParams);

        yield handleSendAllDocumentsXray({ action: 'publish', xrayType: AllDocumentsXrayTypes.onSuccess });

        yield sendPublicCreatedDwh(item, data.body, storage, publishFrom);

        yield put(publishWeblink({ id: item.id, weblink: data.body, item }));

        if (IS_B2B_BIZ_USER && (!isPersonalAccessSharingEnabled || item.isFolder)) {
            if (domesticByDefault) {
                yield toggleDomesticRequest({ item });
            }
            return;
        }

        if (domesticByDefault) {
            yield put(
                weblinkSetDomainAccess({
                    itemId: item.id,
                    domain: DomainAccess.DOMESTIC,
                    storage,
                })
            );
        } else if (showWeblinkListFileSharing && publicEmailACByDefault) {
            // Если есть возможность шарить на список,
            // по-умолчанию паблик доступен только пользователям из списка
            yield put(
                weblinkSetDomainAccess({
                    itemId: item.id,
                    domain: DomainAccess.EMAIL_LIST_ACCESS,
                    storage,
                })
            );
        } else {
            yield put(
                weblinkSetDomainAccess({
                    itemId: item.id,
                    domain: DomainAccess.EXTERNAL,
                    storage,
                })
            );
        }
    } catch (error) {
        logger.error(error);

        yield handleSendAllDocumentsXray({ action: 'publish', xrayType: AllDocumentsXrayTypes.onError });

        yield put(
            showSnackbarAction({
                id: 'error',
                text: publishErrors.getMessage(error),
                type: SnackbarTypes.failure,
                closable: true,
            })
        );
    }
}

export function* handlePublishRequest(action: PayloadAction<IPublishRequest>) {
    const { item, itemStorage, respectDomesticByDefault } = action.payload;

    yield publishRequest({ item, itemStorage, respectDomesticByDefault });
}

export function* prepareHomeForAttachesClone(item: AttachesItem, storage: EStorageType) {
    yield loadHomeFolder({ id: ROOT_FOLDER_ID, isFolder: true });

    const attachFolder: ReturnType<typeof getHomeItemById> = yield select(getHomeItemById, MAIL_ATTACHES_FOLDER_ID);
    if (attachFolder) {
        yield loadHomeFolder({ id: MAIL_ATTACHES_FOLDER_ID, isFolder: true });
    }

    const homeItemId = item.home ?? `${MAIL_ATTACHES_FOLDER_ID}/${item.name}`;
    let newItem = (yield select(getHomeItemById, homeItemId)) as ReturnType<typeof getHomeItemById>;

    if (!newItem || newItem.size !== item.size) {
        toolbarActions.clone({
            id: item.id,
            source: 'attaches-autoclone',
            destination: MAIL_ATTACHES_FOLDER_ID,
            autoClone: true,
            storage,
        });

        let action = yield take([addFilesSuccess, cloneToCloudFailure]);

        if (action.type !== addFilesSuccess.toString()) {
            return;
        }

        if (isFolder(action.payload?.items?.[0])) {
            action = yield take(addFilesSuccess);
        }

        if (storage === EStorageType.stock) {
            // Обновляем дерево папок после клонирования нового файла)
            yield loadHomeFolder({ id: MAIL_ATTACHES_FOLDER_ID, isFolder: true, force: true });
        }

        newItem = action.payload?.items?.[0];
    } else if (!item.home) {
        yield put(cloneToCloudSuccess({ idx: [item.id], home: [homeItemId], storageFrom: storage }));
    }

    return newItem;
}

export function* checkPublicPasswordDialogShowing(item: IOpenPublishDialog['item'], sharingData: any) {
    const publicItem = yield select(getItemById, item.id);
    yield put(getPublicSharingInfo({ public_id: publicItem.weblink }));
    const action = yield take(updatePublicInfoPin);
    const pin = action.payload?.pin;

    if (publicItem.isFolder && !publicItem.weblinkDomestic && !pin) {
        yield call(renderPublicPassword, {
            item: publicItem,
            pin,
            onSuccess: () => {
                renderSharingNew(sharingData);
            },
        });
        return;
    }

    yield call(renderSharingNew, sharingData);
}

export function* publishAndToggleUploadRequest(data: IPublishAndToggleUploadRequest) {
    const { item, publishFrom, autoPublish, defaultAccessRights, itemStorage } = data;

    if (autoPublish) {
        yield publishRequest({ item, itemStorage, publishFrom });
    }

    if (defaultAccessRights) {
        yield toggleUploadRequest({ item });
    }
}

export function* openPublishDialogSaga(data: IOpenPublishDialog) {
    const {
        item,
        publishFrom,
        autoPublish = true,
        fromEditor,
        defaultAccessRights: accessRights,
        itemStorage,
        from = EFrom.WEBLINK,
        withPromoTooltip,
        autoSeo = false,
        folderName,
        closeOnDocumentClick,
    } = data;

    const defaultAccessRights = accessRights === AccessStatus.Upload && item.weblinkAccessRights !== AccessRights.rw;
    const { isFull: isOverquota } = yield select(UserSelectors.getCloudSpace);
    const currentStorage = yield select(getCurrentStorage);
    const isB2B = yield select(UserSelectors.isB2BUser) || IS_ONPREMISE;
    const isFromPublish = from === EFrom.WEBLINK;
    const isNewFlowEnabled = yield select(getFeatureABCreateFolderNewFlow);

    let storage = item.storage || itemStorage;
    let newItem = item;

    const sharingData = {
        id: item.id,
        itemStorage: storage,
        fromEditor,
        from,
        withPromoTooltip,
        autoSeo,
        folderName,
        closeOnDocumentClick,
    };

    if (isVirusItem(item)) {
        yield put(showVirusDlg({ items: [item], actionName: ActionName.publish, doNotChangeRoute: true }));
        return;
    }

    if (isOverquota && !isB2B) {
        openDisabledFeaturePopupHelper({ disabledFeature: DisabledFeature.publish });
        return;
    }

    if (!isFromPublish) {
        const { isShared }: ReturnType<typeof isMountedOrSharedFolder> = yield select(isMountedOrSharedFolder, item);
        if (isDomainFolder(item) || (item.isMounted && !isMountedFolder(item)) || (isShared && !isSharedFolder(item))) {
            // Если папка внутри примонтированной, то не показываем (нельзя шарить)
            // Если же это сама примонтированная папка, то показываем (там кнопка Отказаться)
            return;
        }

        if (IS_B2B_BIZ_USER) {
            yield publishAndToggleUploadRequest({ item: newItem, itemStorage: storage, publishFrom, autoPublish, defaultAccessRights });
        }

        if (isB2B && isPublicPasswordEnabled && IS_REQUIRED_B2B_PASSWORD_PUBLIC) {
            yield checkPublicPasswordDialogShowing(item, sharingData);
            return;
        }

        yield call(renderSharingNew, sharingData);
        return;
    }

    if (storage === EStorageType.attaches || storage === EStorageType.viewerAttaches || item.storage === EStorageType.attaches) {
        newItem = yield prepareHomeForAttachesClone(item, storage as EStorageType);

        if (!newItem) {
            return;
        }

        storage = EStorageType.home;

        sharingData.id = newItem.id;
        sharingData.itemStorage = storage;
    }

    yield publishAndToggleUploadRequest({ item: newItem, itemStorage: storage, publishFrom, autoPublish, defaultAccessRights });

    if (autoSeo && !item?.weblinkSeoEnabled) {
        yield put(toggleSeoRequest({ item }));
    }

    if (isNewFlowEnabled && currentStorage === EStorageType.sharedAutodelete) {
        sharingData.closeOnDocumentClick = true;
    }

    if (isB2B && isPublicPasswordEnabled && IS_REQUIRED_B2B_PASSWORD_PUBLIC) {
        yield checkPublicPasswordDialogShowing(item, sharingData);
        return;
    }

    yield call(renderSharingNew, sharingData);
}

export const handleUnPublishRequest = (payload: PayloadAction<IUnPublishRequest>) =>
    callSagaFromAction<IUnPublishRequest>(handleUnPublish, payload);

export const toggleDomestic = (payload: PayloadAction<ToggleWeblinkDomesticRequest>) =>
    callSagaFromAction<ToggleWeblinkDomesticRequest>(toggleDomesticRequest, payload);

export const handleOpenPublishDialog = (payload: PayloadAction<IOpenPublishDialog>) =>
    callSagaFromAction<IOpenPublishDialog>(openPublishDialogSaga, payload);

export const toggleUpload = (payload: PayloadAction<ToggleWeblinkUploadRequest>) =>
    callSagaFromAction<ToggleWeblinkUploadRequest>(toggleUploadRequest, payload);
