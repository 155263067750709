import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_BIZ_USER, IS_CORP_USER, IS_MBIZUSER, IS_MOBILE_BROWSER, IS_PAID_USER, IS_PUBLIC } from 'reactApp/appHelpers/configHelpers';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

const value: 'a' | 'b' | 'c' = getFeature('heavy-file-buy');
const userCondition = IS_PAID_USER || IS_BIZ_USER || IS_MBIZUSER || IS_CORP_USER;

if (!IS_MOBILE_BROWSER && !IS_PUBLIC && !userCondition) {
    registerExperiment(
        'heavy-file-buy',
        value,
        [
            AnalyticEventNames.BIG_FILE_DIALOG_SHOW,
            AnalyticEventNames.BIF_FILE_CLICK_BUY,
            AnalyticEventNames.BIF_FILE_CLICK_BUY_ONCE,
            AnalyticEventNames.FAST_CHECKOUT_SHOWN,
            AnalyticEventNames.TARIFF_BUY,
        ],
        {
            sendXrayArgs: {
                [AnalyticEventNames.TARIFF_BUY]: ({ paySource }, { eventName }) => {
                    let name = eventName;

                    switch (paySource) {
                        case 'cloud_popup_heavy_file_trial':
                            name = 'heavy_trial_buy';
                            break;
                        case 'cloud_popup_heavy_file_onetime':
                            name = 'heavy_once_buy';
                            break;
                        case 'cloud_popup_heavy_file':
                            name = 'heavy_buy';
                            break;
                        default:
                            break;
                    }

                    return {
                        eventName: name,
                    };
                },
                [AnalyticEventNames.FAST_CHECKOUT_SHOWN]: ({ paySource }, { eventName }) => {
                    let name = eventName;

                    switch (paySource) {
                        case 'cloud_popup_heavy_file_trial':
                            name = 'heavy_trial_checkout';
                            break;
                        case 'cloud_popup_heavy_file_onetime':
                            name = 'tariff_heavy_once_checkout';
                            break;
                        case 'cloud_popup_heavy_file':
                            name = 'heavy_checkout';
                            break;
                        default:
                            break;
                    }

                    return {
                        eventName: name,
                    };
                },
            },
        }
    );
}

export const abHeavyFileBuy = !IS_MOBILE_BROWSER && !userCondition && !IS_PUBLIC && value !== 'a' && value;
