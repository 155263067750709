import { Icon16ArrowLeftOutline, Icon16ArrowRightOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import createStoriesImg from 'img/createStoriesTooltip.png';
import { xray } from 'lib/xray';
import isEqual from 'lodash/isEqual';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createVideoInStories } from 'reactApp/appHelpers/featuresHelpers/features/createVideoInStories';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { getStoryListLoadMore, showStory } from 'reactApp/modules/stories/stories.module';
import { hasSummariesLoadMore } from 'reactApp/modules/stories/stories.selectors';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement, ETooltipSize } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';
import { STORIES_CREATE_PROMO_LOCAL_STORAGE_KEY } from 'reactApp/ui/StoriesWidget/StoriesWidget.constants';
import { sendStoryWidgetDwh } from 'reactApp/ui/StoriesWidget/StoriesWidget.helpers';
import type { StoriesWidgetNewProps } from 'reactApp/ui/StoriesWidgetComponent/Stories.types';
import { type Swiper as SwiperInstance, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { StoryItem } from './StoriesItem/StoryItem';
import styles from './StoriesWidgetComponent.css';

const swiperConfig = {
    pagination: {
        clickable: true,
    },
    modules: [Mousewheel],
};

const storiesRefs = new Map<number, React.MutableRefObject<HTMLDivElement | null>>();

export const StoriesWidgetComponent = memo<StoriesWidgetNewProps>(
    ({ stories }) => {
        const hasStoriesLoadMore = useSelector(hasSummariesLoadMore);
        const [navState, setNavState] = useState({ isFirst: true, isLast: true });
        const swiperRef = useRef<SwiperInstance | null>(null);

        const [isPromoClosed, setPromoClosed] = useState(storeHelper.getValue(STORIES_CREATE_PROMO_LOCAL_STORAGE_KEY));
        const [thanksTooltipRef, setThanksTooltipRef] = useState<React.MutableRefObject<HTMLDivElement | null>>();
        const mainCreateButton = useRef<HTMLDivElement | null>(null);

        const dispatch = useDispatch();

        const handleStoryClick = useCallback(({ id, type }) => {
            dispatch(showStory({ storyId: id, type }));
        }, []);

        const handleCreateClick = useCallback((idx) => {
            if (storiesRefs.has(idx)) {
                setThanksTooltipRef(storiesRefs.get(idx));
                if (!isPromoClosed) {
                    handleClosePromo(false);
                }
                sendStoryWidgetDwh({
                    action: 'create_video_click',
                });
            }
        }, []);

        const handleTooltipClose = useCallback(() => {
            setThanksTooltipRef(undefined);
            xray.send('stories_create_thanks_close');
        }, []);

        const handleClosePromo = useCallback(
            (sendXray = true) => {
                setPromoClosed(true);
                storeHelper.setValue(STORIES_CREATE_PROMO_LOCAL_STORAGE_KEY, true);

                if (sendXray) {
                    xray.send('stories_create_promo_closed');
                }
            },
            [isPromoClosed]
        );

        const updateNavState = useCallback(() => {
            setNavState({ isFirst: Boolean(swiperRef.current?.isBeginning), isLast: Boolean(swiperRef.current?.isEnd) });
        }, [setNavState]);

        const handleSwiper = useCallback((swiper: SwiperInstance) => {
            swiperRef.current = swiper;
        }, []);

        const handlePrevStory = useCallback(() => {
            swiperRef.current?.slidePrev();
        }, []);

        const handleNextStory = useCallback(() => {
            swiperRef.current?.slideNext();
            if (swiperRef.current?.isEnd && hasStoriesLoadMore) {
                dispatch(getStoryListLoadMore());
            }
        }, [hasStoriesLoadMore, dispatch]);

        const handleReachBeginning = useCallback(() => {
            setNavState({ isFirst: true, isLast: navState.isLast });
        }, [navState, setNavState]);

        const handleReachEnd = useCallback(() => {
            setNavState({ isFirst: navState.isFirst, isLast: true });
        }, [navState, setNavState]);

        const handleActiveIndexChange = useCallback(() => {
            updateNavState();
        }, [updateNavState]);

        useEffect(() => {
            updateNavState();
        }, [stories]);

        return (
            <div className={styles.root}>
                <div className={styles.navigation}>
                    <div
                        className={classNames(styles.navButton, styles.navButtonLeft, {
                            [styles.navButtonDisabled]: navState.isFirst,
                        })}
                        onClick={handlePrevStory}
                    >
                        <Icon16ArrowLeftOutline />
                    </div>
                    <div
                        className={classNames(styles.navButton, styles.navButtonRight, {
                            [styles.navButtonDisabled]: navState.isLast,
                        })}
                        onClick={handleNextStory}
                    >
                        <Icon16ArrowRightOutline />
                    </div>
                </div>
                <Swiper
                    slidesPerView="auto"
                    spaceBetween={16}
                    threshold={20}
                    pagination={swiperConfig.pagination}
                    onSwiper={handleSwiper}
                    onActiveIndexChange={handleActiveIndexChange}
                    onReachBeginning={handleReachBeginning}
                    onReachEnd={handleReachEnd}
                    modules={swiperConfig.modules}
                >
                    {stories.map((storyItem, idx) => (
                        <SwiperSlide key={storyItem.id} virtualIndex={idx} className={styles.slide}>
                            {() => {
                                return (
                                    <StoryItem
                                        item={storyItem}
                                        mainCreateButton={mainCreateButton}
                                        isFirst={idx === 0}
                                        onItemClick={handleStoryClick}
                                        idx={idx}
                                        storiesRefs={storiesRefs}
                                        onCreateClick={handleCreateClick}
                                    />
                                );
                            }}
                        </SwiperSlide>
                    ))}
                </Swiper>
                {createVideoInStories && mainCreateButton && !isPromoClosed && (
                    <FloatingTooltip
                        target={mainCreateButton}
                        placement={ETooltipPlacement.right}
                        qaId="tooltip-create-video"
                        className={styles.tooltipPromo}
                        arrowClassName={styles.tooltipPromoArrow}
                        closeClassName={styles.tooltipPromoClose}
                        onClose={handleClosePromo}
                        closeOnOutsideClick={false}
                        onShow={() => {
                            xray.send('stories_create_promo_show');
                        }}
                    >
                        <div className={styles.tooltipPromoRoot}>
                            <div className={styles.tooltipPromoText}>
                                Делитесь c близкими
                                <br />
                                видео-воспоминанием
                            </div>
                            <img className={styles.tooltipPromoImg} src={createStoriesImg} />
                        </div>
                    </FloatingTooltip>
                )}
                {createVideoInStories && thanksTooltipRef && (
                    <FloatingTooltip
                        target={thanksTooltipRef}
                        placement={ETooltipPlacement.right}
                        className={styles.tooltipThanks}
                        size={ETooltipSize.large}
                        qaId="tooltip-create-thanks"
                        title="Спасибо за интерес!"
                        text="Мы ещё работаем над функцией — когда всё будет готово, сразу же сообщим о запуске"
                        buttonText="Буду ждать"
                        isAlternativeButton
                        onClose={handleTooltipClose}
                        onClick={handleTooltipClose}
                        onShow={() => {
                            xray.send('stories_create_thanks_show');
                        }}
                    />
                )}
            </div>
        );
    },
    (prev, next) => isEqual(prev.stories, next.stories)
);

StoriesWidgetComponent.displayName = 'StoriesWidgetComponent';
