import { Icon20Chain } from '@vkontakte/icons';
import React, { type MouseEvent, forwardRef } from 'react';
import { MenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/MenuButton/MenuButton';

interface Props {
    text?: string;
    onClick?: (evt: MouseEvent) => void;
}

export const ShareMenuButton = forwardRef<HTMLDivElement, Props>(({ text = 'Поделиться', onClick }, ref) => {
    return <MenuButton text={text} icon={<Icon20Chain />} onClick={onClick} ref={ref} />;
});

ShareMenuButton.displayName = 'ShareMenuButton';
