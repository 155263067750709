import { classNames } from '@vkontakte/vkui';
import React from 'react';
import { useSelector } from 'react-redux';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { Loader } from 'reactApp/ui/Loader/Loader';
import { Icon20ChevronRight } from 'reactApp/ui/touchIcons';
import { createAvatarUrl } from 'reactApp/utils/avatar';

import styles from './UserList.css';
import type { IProps } from './WeblinkUserList.types';

export const WeblinkUserCollapsedList = React.memo(function WeblinkUserList({ acList, isLoading, isPhone, item, isReadOnly }: IProps) {
    const email = useSelector(UserSelectors.getEmail);

    const owner = email
        ? {
              email,
              avatarUrl: createAvatarUrl(email),
          }
        : null;

    const ownerOrFirstUserItem = owner || acList[0];
    const firstOrSecondUser = owner ? acList[0] : acList[1];
    const restUsersLength = owner ? acList.length : acList.length - 1;

    const handleOpenListModal = () => {
        openPopupHelper({
            popupName: popupNames.SHARING_WEBLINK_COLLAPSED_USER_LIST,
            data: { isPhone, isReadOnly, item },
        });
    };

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_phone]: isPhone,
            })}
        >
            <div className={classNames(styles.usersList, { [styles.usersList_scroll]: acList.length > 2 })}>
                {isLoading ? (
                    <div className={styles.loading}>
                        <Loader centered isModal />
                    </div>
                ) : (
                    <button className={styles.collapsedList} onClick={handleOpenListModal}>
                        <div className={styles.collapsedList_avatarContainer}>
                            <img src={firstOrSecondUser.avatarUrl} className={styles.collapsedList_avatar} />
                            <img src={ownerOrFirstUserItem.avatarUrl} className={styles.collapsedList_avatar} />
                        </div>
                        <div className={styles.collapsedList_users}>
                            {ownerOrFirstUserItem.email} и {restUsersLength} других
                        </div>
                        <Icon20ChevronRight className={styles.collapsedList_chevron} />
                    </button>
                )}
            </div>
        </div>
    );
});
