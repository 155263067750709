import Cookies from 'js-cookie';
import { logger } from 'lib/logger';
import { isEmpty } from 'ramda';
import { isAdmitadEnabled } from 'reactApp/appHelpers/featuresHelpers/features/admitad';
import { SPACE_LIST } from 'reactApp/constants/spaceList';
import type { Product } from 'reactApp/types/Billing';
import { sendXray } from 'reactApp/utils/ga';
import { isYearPeriod } from 'reactApp/utils/Period';

const SPACE_TO_YEAR_CODE_MAP = {
    [SPACE_LIST.gb_64]: '2',
    [SPACE_LIST.gb_128]: '2',
    [SPACE_LIST.gb_256]: '3',
    [SPACE_LIST.gb_512]: '3',
    [SPACE_LIST.tb_1]: '4',
    [SPACE_LIST.tb_2]: '4',
    [SPACE_LIST.tb_4]: '5',
    [SPACE_LIST.tb_5]: '6',
};

const SPACE_TO_MONTH_CODE_MAP = {
    [SPACE_LIST.gb_64]: '3',
    [SPACE_LIST.gb_128]: '3',
    [SPACE_LIST.gb_256]: '4',
    [SPACE_LIST.gb_512]: '4',
    [SPACE_LIST.tb_1]: '5',
    [SPACE_LIST.tb_2]: '5',
    [SPACE_LIST.tb_4]: '6',
    [SPACE_LIST.tb_5]: '7',
};

export const sendAdmitadProduct = (product: Product) => {
    try {
        const { period, price, discountPrice, hasTrial, id, space } = product;

        const productPrice = hasTrial ? 0 : discountPrice || price;

        if (!isAdmitadEnabled) {
            return;
        }

        ADMITAD = window.ADMITAD || {};

        if (!ADMITAD || isEmpty(ADMITAD)) {
            sendXray('buy-product-no-admitad');
            return;
        }

        ADMITAD.Invoice = ADMITAD.Invoice || {};

        // определение канала для Admitad
        // Покупка месячной подписки - 1
        // Покупка годовой подписки - 2
        ADMITAD.Invoice.category = isYearPeriod(period) ? '2' : '1';

        const categoryMap = isYearPeriod(period) ? SPACE_TO_YEAR_CODE_MAP : SPACE_TO_MONTH_CODE_MAP;

        const orderedItem: any[] = [];

        orderedItem.push({
            Product: {
                productID: id,
                category: categoryMap[space?.original] || '1',
                price: productPrice.toString(),
                priceCurrency: 'RUB',
            },
            orderQuantity: '1',
            additionalType: 'sale',
        });

        ADMITAD.Invoice.referencesOrder = ADMITAD.Invoice.referencesOrder || [];

        ADMITAD.Invoice.referencesOrder.push({
            orderNumber: new Date().getTime().toString(),
            orderedItem,
        });

        ADMITAD.Tracking.processPositions();
    } catch (error) {
        sendXray('admitad-fail');
        logger.error(error);
    }
};

export const patchPaysourceWithAdmitad = (paySource?: string) => {
    const admitadPaySource = 'admitadps';
    if (Cookies.get('tagtag_aid')) {
        return paySource ? `${paySource}_${admitadPaySource}` : admitadPaySource;
    }
    return paySource;
};
