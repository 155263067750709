/* eslint-disable complexity */
import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isDomainFolder, isReadOnly } from 'reactApp/appHelpers/appHelpers';
import { datalistInlineRenameEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { shouldPublishButtonsOnAttachList } from 'reactApp/appHelpers/featuresHelpers/features/publishButtonsOnAttachList';
import { seoEnable } from 'reactApp/appHelpers/featuresHelpers/features/seoEnable';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { getAllDocumentsCategory } from 'reactApp/modules/allDocuments/allDocuments.selectors';
import { openAttachLetter } from 'reactApp/modules/attaches/attaches.helpers';
import { selectOne } from 'reactApp/modules/selections/selections.actions';
import { getStorageConfig } from 'reactApp/modules/storage/storage.config';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { trashbinRestoreRequest } from 'reactApp/modules/trashbin/trashbin.module';
import { cancelDescriptor } from 'reactApp/modules/uploading/uploading.module';
import { EFileError, EFileStatus } from 'reactApp/modules/uploadList/uploadList.model';
import { updateUploadFilesAction } from 'reactApp/modules/uploadList/uploadList.module';
import type { DataListItemRowProps } from 'reactApp/ui/DataListItemRow/DataListItemRow.types';

import { useItemAuthor } from './useItemAuthors';

// eslint-disable-next-line max-lines-per-function
export const useRowProps = ({
    view,
    item,
    uploadingItem,
    handleOnCheckboxClick: onCheckboxClick,
    isSelected,
    isSelecting,
    isActive,
    tipData,
    id,
    storage,
    favoritesAvailable,
    onFavorites,
    onPublish,
    onDownload,
    isVirus,
    thumb,
    isPublicDownloaded,
    query,
    listOfBannedToFavoriteItems,
}): DataListItemRowProps | undefined => {
    const dispatch = useDispatch();
    const currentCategory = useSelector(getAllDocumentsCategory);
    const itemId = item?.id;
    const storageConfig = getStorageConfig(item.storage);
    const isFavoritesAvailable = storageConfig.favorites;

    // TODO: isReadOnly - Здесь для каждого элемента даталиста ищутся бредкрампы, много перерендеров!
    const isRenameAvailable =
        datalistInlineRenameEnabled && storageConfig.rename && !(item.isFolder && isReadOnly(item)) && !isDomainFolder(item);

    const parentItemRef = useRef<HTMLDivElement>(null);

    const author = useItemAuthor(item);

    const onAccept = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            dispatch(selectOne({ selectedIdx: itemId, storage }));
            toolbarActions.mount();
        },
        [dispatch, itemId, storage]
    );

    const onReject = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(selectOne({ selectedIdx: itemId, storage }));
            toolbarActions.remove();
        },
        [dispatch, itemId, storage]
    );

    const onMount = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(selectOne({ selectedIdx: itemId, storage }));
            toolbarActions.share();
        },
        [dispatch, itemId, storage]
    );

    const onRestore = useCallback(() => {
        dispatch(trashbinRestoreRequest({ ids: [itemId], from: 'datalist' }));
    }, [dispatch, itemId]);

    const onCancelUpload = useCallback(() => {
        if (!uploadingItem || (!uploadingItem.descriptorId && !uploadingItem.cloudPath)) {
            return;
        }

        if (uploadingItem?.files?.length || uploadingItem?.extension === 'folder') {
            dispatch(cancelDescriptor(uploadingItem.localPath));
        }

        dispatch(
            updateUploadFilesAction({
                cloudPath: uploadingItem.cloudPath,
                descriptorId: uploadingItem.descriptorId,
                status: EFileStatus.CANCEL,
                error: EFileError.CANCELLED_FILE,
                hideError: true,
            })
        );
    }, [dispatch, uploadingItem]);

    const onClone = useCallback(
        (source: string, destination?: string) => {
            toolbarActions.clone({
                id: item.id,
                destination,
                source,
            });
        },
        [item.id]
    );

    const handleOpenAttachLetter = useCallback(
        () => openAttachLetter({ message_id: item.message_id, folderId: item.folderId }),
        [item.folderId, item.message_id]
    );

    if (view !== 'list' || !item) {
        return;
    }

    const baseProps = {
        onCheckboxClick,
        id,
        storage: item.storage,
        tipData,
        isActive,
        isSelected,
        isSelecting,
        isPublicDownloaded,
        isPublic: Boolean(item.weblink),
        parentItemRef,
        isSeoEnabled: seoEnable && item.weblinkSeoEnabled,
    };

    // eslint-disable-next-line sonarjs/max-switch-cases
    switch (item.storage) {
        case EStorageType.sharedIncoming: {
            return {
                ...baseProps,
                name: item.name,
                owner: item.owner,
                access: item.access,
                isMounted: !item.inviteToken,
                kind: item.kind,
                onAccept,
                onReject,
                onMount,
                size: item.size,
            };
        }
        case EStorageType.home: {
            return {
                ...baseProps,
                name: item.isFolder ? item.name : item.nameWithoutExt,
                isFolder: item.isFolder,
                ext: item.isFolder ? undefined : item.ext,
                kind: item.kind,
                mtime: item.isFolder ? undefined : item.mtime,
                size: item.size,
                isInFavorites: item.isInFavorites,
                isAutoDelete: item?.weblinkAutoDelete,
                publicExpires: item?.weblinkExpires,
                author,
                thumbUrl: thumb,
                favoritesAvailable,
                onFavorites,
                onPublish,
                onDownload,
                onCancelUpload,
                isVirus,
                renameAvailable: isRenameAvailable,
            };
        }
        case EStorageType.sharedLinks:
        case EStorageType.sharedAutodelete: {
            return {
                ...baseProps,
                name: item.isFolder ? item.name : item.nameWithoutExt,
                isFolder: item.isFolder,
                ext: item.isFolder ? undefined : item.ext,
                kind: item.kind,
                mtime: item.isFolder ? undefined : item.mtime,
                size: item.size,
                isAutoDelete: item?.weblinkAutoDelete,
                publicExpires: item?.weblinkExpires,
                thumbUrl: thumb,
                onPublish,
                onDownload,
                isVirus,
                renameAvailable: isRenameAvailable,
            };
        }
        case EStorageType.public: {
            return {
                ...baseProps,
                name: item.isFolder ? item.name : item.nameWithoutExt,
                isFolder: item.isFolder,
                ext: item.isFolder ? undefined : item.ext,
                kind: item.kind,
                author,
                mtime: item.isFolder ? undefined : item.mtime,
                size: item.size,
                thumbUrl: thumb,
                onDownload,
                isVirus,
            };
        }
        case EStorageType.favorites: {
            return {
                ...baseProps,
                name: item.nameWithoutExt,
                isFolder: item.isFolder,
                ext: item.ext,
                kind: item.kind,
                mtime: item.mtime,
                size: item.size,
                isInFavorites: true,
                author,
                isAutoDelete: item?.weblinkAutoDelete,
                publicExpires: item?.weblinkExpires,
                thumbUrl: thumb,
                favoritesAvailable: true,
                onFavorites,
                onPublish,
                onDownload,
                isVirus,
            };
        }
        case EStorageType.stock: {
            return {
                ...baseProps,
                name: item.isFolder ? item.name : item.nameWithoutExt,
                isFolder: item.isFolder,
                ext: item.isFolder ? undefined : item.ext,
                kind: item.kind,
                size: item.isFolder ? undefined : item.size,
                thumbUrl: thumb,
                onDownload,
                isVirus,
            };
        }
        case EStorageType.search: {
            return {
                ...baseProps,
                name: item.isFolder ? item.name : item.nameWithoutExt,
                isFolder: item.isFolder,
                ext: item.isFolder ? undefined : item.ext,
                kind: item.kind,
                isAutoDelete: item?.weblinkAutoDelete,
                publicExpires: item?.weblinkExpires,
                mtime: item.isFolder ? undefined : item.mtime,
                size: item.isFolder ? undefined : item.size,
                home: item.parentFolder,
                thumbUrl: thumb,
                onDownload,
                onPublish,
                isVirus,
                isInFavorites: item.isInFavorites,
                favoritesAvailable,
                onFavorites,
                renameAvailable: isRenameAvailable,
                srchSrc: item.srchSrc,
                query,
                listOfBannedToFavoriteItems,
            };
        }
        case EStorageType.attaches: {
            return {
                ...baseProps,
                name: item.nameWithoutExt,
                isFolder: item.isFolder,
                ext: item.ext,
                mtime: item.mtime,
                size: item.size,
                thumbUrl: thumb,
                onDownload,
                onPublish: shouldPublishButtonsOnAttachList ? onPublish : undefined,
                subject: item.subject,
                kind: 'file',
                owner: {
                    name: item.authorName,
                    email: item.authorEmail,
                },
                openAttachLetter: handleOpenAttachLetter,
                onClone,
            };
        }
        case EStorageType.trashbin: {
            return {
                ...baseProps,
                name: item.isFolder ? item.name : item.nameWithoutExt,
                isFolder: item.isFolder,
                ext: item.isFolder ? undefined : item.ext,
                kind: item.kind,
                deletedAt: item.deletedAt,
                deletedFrom: item.deletedFrom,
                size: item.size,
                thumbUrl: thumb,
                binName: item.binName,
                onRestore,
            };
        }
        case EStorageType.feed: {
            return {
                ...baseProps,
                home: item.parent,
                name: item.nameWithoutExt,
                isFolder: false,
                ext: item.ext,
                kind: item.kind,
                mtime: item.mtime,
                size: item.size,
                thumbUrl: thumb,
                onPublish,
                onDownload,
                isVirus,
            };
        }
        case EStorageType.gallery: {
            return {
                ...baseProps,
                home: item.parent,
                name: item.nameWithoutExt,
                isFolder: false,
                ext: item.ext,
                kind: item.kind,
                mtime: item.mtime,
                size: item.size,
                thumbUrl: thumb,
                onPublish,
                onDownload,
                isVirus,
                favoritesAvailable: true,
                isInFavorites: item.isInFavorites,
                onFavorites,
                renameAvailable: isRenameAvailable,
            };
        }
        case EStorageType.documents:
        case EStorageType.alldocuments: {
            return {
                ...baseProps,
                home: item.parent,
                name: item.nameWithoutExt,
                isFolder: false,
                ext: item.ext,
                kind: item.kind,
                mtime: item.mtime,
                size: item.size,
                isPublic: Boolean(item.weblink),
                thumbUrl: thumb,
                onPublish,
                onDownload,
                isVirus,
                favoritesAvailable: !EStorageType.alldocuments,
                isInFavorites: item.isInFavorites,
                onFavorites,
                currentCategory,
                ctime: item?.ctime,
            };
        }
        case EStorageType.albums: {
            return {
                ...baseProps,
                name: item.nameWithoutExt,
                isFolder: false,
                ext: item.ext,
                kind: item.kind,
                mtime: item.mtime,
                size: item.size,
                thumbUrl: thumb,
                onDownload,
                isVirus,
                favoritesAvailable: isFavoritesAvailable,
                isInFavorites: item.isInFavorites,
                onFavorites,
                renameAvailable: false,
            };
        }
        case EStorageType.quotaCleaner: {
            return {
                ...baseProps,
                isFolder: item.isFolder,
                name: item.nameWithoutExt,
                kind: item.kind,
                ext: item.ext,
                isVirus: item.isVirus,
                thumbUrl: thumb,
                date: item.date,
                size: item.size,
                author: item.correspondents?.from?.[0],
                onDownload,
            };
        }
        case EStorageType.incomingPublic: {
            return {
                ...baseProps,
                author: item.owner,
                name: item.nameWithoutExt,
                isFolder: false,
                ext: item.ext,
                kind: item.kind,
                ctime: item.ctime,
                isPublic: true,
                onDownload,
                isVirus,
            };
        }
    }
};
