/* eslint-disable max-lines */
import type { PayloadAction } from '@reduxjs/toolkit';
import api from 'Cloud/Application/api';
import config from 'Cloud/config';
import type { AxiosResponse } from 'lib/axios';
import { xray } from 'lib/xray';
import { type DeleteTrashbinFilesList, DeleteFilesFromTrashbinAPICall } from 'reactApp/api/DeleteFilesFromTrashbinAPICall';
import { RestoreAllTrashbinAPICall } from 'reactApp/api/RestoreAllTrashbinAPICall';
import { RestoreStatusCheckAPICall } from 'reactApp/api/RestoreStatusCheckAPICall';
import { UserFullQuotaAPICall } from 'reactApp/api/user/FullQuotaAPICall';
import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { BIG_TRASHBIN_SIZE_INFO } from 'reactApp/appHelpers/featuresHelpers/features/bigTrashbinSizeInfo';
import { seriallyClearBinFeature } from 'reactApp/appHelpers/featuresHelpers/features/seriallyClearBin';
import { trashbinSelectiveDeletion } from 'reactApp/appHelpers/featuresHelpers/features/tashbinSelectiveDeletion';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { resetSelected } from 'reactApp/modules/selections/selection.saga';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { getPrivateList } from 'reactApp/modules/start/start.saga';
import { getLoadMoreLimit, getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import {
    binClearRequest,
    binClearSuccess,
    deleteBatchInSeriallyClearingBinRequest,
    deleteFilesFromBinRequest,
    seriallyBinClearRequest,
    toggleBin,
    trashbinLoad,
    trashbinLoadMoreRequest,
    trashbinLoadMoreSuccess,
    trashbinLoadSuccess,
    trashbinRestoreAllRequest,
    trashbinRestoreAllStart,
    trashbinRestoreAllUpdate,
    trashbinRestoreRequest,
    trashbinUpdateSizeSuccess,
    updateBinByIdSuccess,
} from 'reactApp/modules/trashbin/trashbin.module';
import { getBinById, getBinItemById, getOpenedBinCountAll, getRemovingState } from 'reactApp/modules/trashbin/trashbin.selectors';
import type { BinDeleteFilesRequestAction, LoadTrashbinSuccessAction, TrashbinApiResponse } from 'reactApp/modules/trashbin/trashbin.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { updateUser } from 'reactApp/modules/user/user.thunkActions';
import type { RootState } from 'reactApp/store';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import ping from 'reactApp/utils/ping';
import { deleteMalePluralV1, deleteMalePluralV2, filesPlural, hePlural } from 'reactApp/utils/pluralHelpers';
import { all, call, cancel, debounce, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { EnvironmentSelectors } from '../environment/environment';
import type { ApiFolderResponse } from '../home/home.types';
import { removeFileSuccess } from '../modifying/modifying.actions';
import { snackbarController } from '../snackbar/snackbar.controller';
import { groupedIds } from '../storage/storage.selectors';
import { loadUserQuotaSuccess } from '../userQuota/userQuota.module';
import { restoreFiles } from './trashbin.helpers';

const LOAD_LIMIT = getLoadMoreLimit(EStorageType.trashbin);
const restoreAllApiCall = new RestoreAllTrashbinAPICall().makeRequest;
const checkApiCall = () => new RestoreStatusCheckAPICall().makeRequest();
const emptyTrashbin = 'empty-trashbin';
export const deleteFilesApiCall = (email: string, paths: string[]): Promise<AxiosResponse<{ body?: DeleteTrashbinFilesList }>> =>
    new DeleteFilesFromTrashbinAPICall().makeRequest({ 'x-email': email, paths });
const getTrashbinSizeApi = () => new UserFullQuotaAPICall().makeRequest({ trash: true });

function* updateBin(id, limit, tree, itemsCount?: { files: number; folders: number }) {
    try {
        const trashbin = yield Promise.resolve(api.trashbin({ tree, limit }));
        yield put(
            updateBinByIdSuccess({
                id,
                bin: trashbin,
                itemsCount,
            })
        );
    } catch (error) {
        yield cancel();
    }
}

function* loadTrashbin() {
    let trashbinFolderData: ApiFolderResponse;
    if (seriallyClearBinFeature) {
        try {
            trashbinFolderData = yield call(getPrivateList, '/:trash', 0);
        } catch (error) {
            yield cancel();
        }
    }

    try {
        const isBizUser = yield select(UserSelectors.isBizUser);
        const result: LoadTrashbinSuccessAction = [];
        const bins: {
            tree?: string;
            name: string;
            home: string;
            isDomain?: boolean;
        }[] = [
            {
                name: isBizUser ? 'Личные папки' : 'Облако',
                home: '/',
            },
        ];

        if (isBizUser) {
            const domainFolders = yield Promise.resolve(api.domain.folders());
            domainFolders
                .filter((folder) => !folder.mode || folder.mode === 'rw')
                .forEach((folder) => {
                    bins.push({
                        name: folder.name || folder.home.split('/').pop(),
                        home: folder.home,
                        tree: folder.tree,
                        isDomain: true,
                    });
                });
        }

        yield bins.reduce((promise, bin) => {
            return promise
                .then(() => api.trashbin({ tree: bin.tree, limit: LOAD_LIMIT }))
                .then((files: TrashbinApiResponse) => {
                    result.push({
                        ...files,
                        id: bin.home,
                        name: bin.name,
                        tree: bin.tree,
                        isDomain: !!bin.isDomain,
                        count: trashbinFolderData?.count,
                    });
                });
        }, Promise.resolve());

        yield put(trashbinLoadSuccess(result));
    } catch (error) {
        yield cancel();
    }
}

function* restore(action) {
    const { ids, from } = action.payload;

    if (!ids?.length) {
        return;
    }

    try {
        const items = yield all(ids.map((id) => select(getBinItemById, id)));
        const bin = yield select(getBinById, items[0].parent);
        sendPaymentGa({
            eventCategory: ECategoryGa.basket,
            action: 'return',
            type_for: from,
            type_object: items.length === bin.count.all ? 'all' : items.length === 1 ? 'one' : 'some',
        });

        const { foldersCount, filesCount } = yield call(restoreFiles, { items, tree: bin.tree, isDomainBin: bin.isDomain });
        yield updateBin(
            bin.id,
            bin.childs.length,
            bin.tree,
            seriallyClearBinFeature ? { files: filesCount, folders: foldersCount } : undefined
        );
        yield resetSelected();

        // @ts-ignore
        yield put(updateUser());
    } catch (error) {
        yield cancel();
    }
}

function* deleteFilesFromBin(action: PayloadAction<BinDeleteFilesRequestAction>) {
    const { selected } = action.payload;

    const maxSelectedCount = trashbinSelectiveDeletion.limit;

    if (selected.length > maxSelectedCount) {
        yield put(
            showSnackbarAction({
                type: SnackbarTypes.warning,
                id: emptyTrashbin,
                text: `Выбрано файлов больше допустимого (${maxSelectedCount})`,
                closable: true,
            })
        );
        return;
    }

    const ids = selected.map((file) => `/:trash/${file.split('/').at(-1)}`);

    const login = config.get('user.email');

    sendPaymentGa({
        eventCategory: ECategoryGa.basket,
        action: 'clean-confirm',
        count_files: ids.length,
    });
    const isMobile = EnvironmentSelectors.isMobile();

    try {
        yield deleteFilesApiCall(login, ids);
        yield resetSelected();
        yield loadTrashbin();
        xray.send(`${isMobile ? 'mobile_' : ''}trashbin_selected_item_success_${ids.length}`);
    } catch (error) {
        xray.send(`${isMobile ? 'mobile_' : ''}trashbin_selected_item_error`);
        yield put(
            showSnackbarAction({
                type: SnackbarTypes.failure,
                id: emptyTrashbin,
                text: seriallyClearBinFeature ? `Не получилось удалить файлы. \nПовторите попытку позже` : `Не удалось очистить Корзину`,
                closable: true,
                wrapText: seriallyClearBinFeature,
            })
        );
        yield cancel();
    }
}

let stopSeriallyCleaningBin = false;

function* seriallyClearBin() {
    const storage = yield select(getCurrentStorage);
    const items = (yield select((state: RootState) => groupedIds(state, storage as EStorageType, false))) as string[];

    if (stopSeriallyCleaningBin) {
        stopSeriallyCleaningBin = false;
        return;
    }

    if (storage !== EStorageType.trashbin) {
        return;
    }

    const binItemsTotalLength = yield select((state: RootState) => getOpenedBinCountAll(state));
    const isMobile = EnvironmentSelectors.isMobile();

    if (!items || (items && !items.length)) {
        xray.send(`${isMobile ? 'mobile_' : ''}trashbin_selected_item_error`);
        yield put(
            showSnackbarAction({
                type: SnackbarTypes.failure,
                id: emptyTrashbin,
                text: `Не получилось удалить файлы. \nПовторите попытку позже`,
                closable: true,
                wrapText: true,
            })
        );
        return;
    }
    snackbarController.hideSnackbar(emptyTrashbin);
    snackbarController.hideSnackbar('stoppedSeriallyCleaningBin');
    snackbarController.showSnackbar({
        id: 'seriallyClearBin',
        text: 'Удаление файлов',
        type: SnackbarTypes.loading,
        disableCloseTimeout: true,
        buttonText: items?.length >= LOAD_LIMIT ? 'Остановить' : undefined,
        buttonRight: items?.length >= LOAD_LIMIT,
        onButtonClick: () => {
            if (items?.length >= LOAD_LIMIT) {
                stopSeriallyCleaningBin = true;
            }
        },
    });

    sendPaymentGa({
        eventCategory: ECategoryGa.basket,
        action: 'clean-confirm',
        count_files: binItemsTotalLength,
    });

    try {
        yield resetSelected();
        yield put(deleteBatchInSeriallyClearingBinRequest({ deletedLength: 0 }));
    } catch (error) {
        xray.send(`${isMobile ? 'mobile_' : ''}trashbin_selected_item_error`);
        yield put(
            showSnackbarAction({
                type: SnackbarTypes.failure,
                id: emptyTrashbin,
                text: `Не получилось удалить файлы. \nПовторите попытку позже`,
                closable: true,
                wrapText: true,
            })
        );
        snackbarController.hideSnackbar('seriallyClearBin');
        yield cancel();
    }
}

function* deleteBatchInSeriallyClearingBin(action) {
    const { deletedLength } = action.payload;
    const storage = yield select(getCurrentStorage);
    const originalItems = (yield select((state: RootState) => groupedIds(state, storage as EStorageType, false))) as string[];
    const isMobile = EnvironmentSelectors.isMobile();

    if ((stopSeriallyCleaningBin && originalItems.length) || storage !== EStorageType.trashbin) {
        snackbarController.hideSnackbar('seriallyClearBin');
        snackbarController.showSnackbar({
            type: SnackbarTypes.warning,
            id: 'stoppedSeriallyCleaningBin',
            text: `${deletedLength} ${filesPlural(deletedLength)} уже ${deleteMalePluralV2(deletedLength)} — восстановить ${hePlural(
                deletedLength
            )} не получится. Остальные будут в Облаке`,
            hideCloseIcon: true,
            closable: true,
        });
        stopSeriallyCleaningBin = false;
        if (storage !== EStorageType.trashbin) {
            yield cancel();
        }
        return;
    }

    if (!originalItems.length) {
        xray.send(`${isMobile ? 'mobile_' : ''}trashbin_selected_item_success_${deletedLength}`);
        snackbarController.hideSnackbar('seriallyClearBin');
        yield put(
            showSnackbarAction({
                id: 'seriallyClearBinSuccess',
                type: SnackbarTypes.success,
                text: `${deletedLength} ${filesPlural(deletedLength)} ${deleteMalePluralV1(deletedLength)}`,
                closable: true,
            })
        );
        stopSeriallyCleaningBin = false;
        return;
    }

    const maxSelectedCount = trashbinSelectiveDeletion.limit;

    const items = originalItems.slice(0, maxSelectedCount);

    if (items?.length > maxSelectedCount) {
        xray.send(`${isMobile ? 'mobile_' : ''}trashbin_selected_item_error`);
        snackbarController.hideSnackbar('seriallyClearBin');
        yield put(
            showSnackbarAction({
                type: SnackbarTypes.failure,
                id: emptyTrashbin,
                text: `Не получилось удалить файлы. \nПовторите попытку позже`,
                closable: true,
                wrapText: true,
            })
        );
        stopSeriallyCleaningBin = false;
        return;
    }

    const ids = items.map((file) => `/:trash/${file.split('/').at(-1)}`);
    const login = config.get('user.email');

    try {
        yield deleteFilesApiCall(login, ids);
        yield loadTrashbin();
        yield put(
            deleteBatchInSeriallyClearingBinRequest({
                deletedLength: deletedLength + (items.length < maxSelectedCount ? items.length : maxSelectedCount),
            })
        );
    } catch (error) {
        xray.send(`${isMobile ? 'mobile_' : ''}trashbin_selected_item_error`);
        yield put(
            showSnackbarAction({
                type: SnackbarTypes.failure,
                id: emptyTrashbin,
                text: `Не получилось удалить файлы. \nПовторите попытку позже`,
                closable: true,
                wrapText: true,
            })
        );
        snackbarController.hideSnackbar('seriallyClearBin');
        yield cancel();
    }
}

function* clearBin(action) {
    const { tree, id } = action.payload;
    const bin = yield select(getBinById, id);
    sendPaymentGa({
        eventCategory: ECategoryGa.basket,
        action: 'clean-all-confirm',
        count_files: bin.count.all,
    });

    try {
        yield Promise.resolve(api.trashbin.empty({ tree }));
        yield put(binClearSuccess({ id }));
        yield resetSelected();
    } catch (error) {
        yield put(
            showSnackbarAction({
                type: SnackbarTypes.failure,
                id: 'empty-trashbin',
                text: seriallyClearBinFeature ? `Не получилось удалить файлы. \nПовторите попытку позже` : `Не удалось очистить Корзину`,
                closable: true,
                wrapText: seriallyClearBinFeature,
            })
        );
        yield cancel();
    }
}

function* loadMore(action) {
    const { nextChunkRev, id, tree } = action.payload;

    try {
        const bin: TrashbinApiResponse = yield Promise.resolve(
            api.trashbin({
                tree,
                offset_revision: nextChunkRev,
                limit: LOAD_LIMIT,
            })
        );
        yield put(
            trashbinLoadMoreSuccess({
                id,
                bin,
            })
        );
    } catch (error) {
        yield cancel();
    }
}

function* checkRestoreStatus() {
    try {
        const initialState = yield select(getRemovingState);

        const { data } = yield ping({
            request: checkApiCall,
            check: ({ data }) => data && data.processing !== initialState,
        });

        if (data && data.code === 'OK') {
            yield put(
                trashbinRestoreAllUpdate({
                    status: Boolean(data && data.processing),
                })
            );
        }
    } catch (error) {
        yield cancel();
    }
}

function* restoreAll() {
    try {
        sendPaymentGa({ eventCategory: ECategoryGa.basket, action: 'return-all' });
        const { data } = yield call(restoreAllApiCall);
        if (data && data.code === 'OK') {
            yield put(trashbinRestoreAllStart());

            yield put(
                showSnackbarAction({
                    id: 'trashbin-restoring-all',
                    text: 'Файлы восстанавливаются. Мы уведомим, когда все восстановим',
                    closable: true,
                })
            );

            yield checkRestoreStatus();
            yield resetSelected();
            yield put(
                showSnackbarAction({
                    type: SnackbarTypes.success,
                    id: 'trashbin-restore-all',
                    text: 'Все файлы восстановлены',
                    closable: true,
                })
            );
        }
    } catch (error) {
        if (seriallyClearBinFeature) {
            yield put(
                showSnackbarAction({
                    type: SnackbarTypes.failure,
                    id: emptyTrashbin,
                    text: `Не получилось восстановить файлы. \nПовторите попытку позже`,
                    closable: true,
                    wrapText: true,
                })
            );
        }
        yield cancel();
    }
}

function* getTrashbinSize() {
    const isBizUser = yield select(UserSelectors.isBizUser);
    const storage = yield select(getCurrentStorage);
    const {
        isAllDocuments,
        isRecommend,
        isHome,
        isGallery,
        isFavorites,
        isDocuments,
        isSharedAutodelete,
        isAlbums,
        isTrashBin,
        isFeed,
        isSharedIncoming,
        isSharedLinks,
        isAttaches,
    } = getStorage(storage);

    const internalPage =
        isHome ||
        isGallery ||
        isFavorites ||
        isSharedAutodelete ||
        isAlbums ||
        isTrashBin ||
        isFeed ||
        isRecommend ||
        isSharedIncoming ||
        isSharedLinks ||
        isSharedLinks ||
        isAttaches ||
        isDocuments;

    if (!BIG_TRASHBIN_SIZE_INFO || IS_PHONE_BROWSER || isBizUser || isAllDocuments || !internalPage) {
        return;
    }

    try {
        const { data } = yield call(getTrashbinSizeApi);

        yield put(trashbinUpdateSizeSuccess({ size: data?.body?.trash_size ?? 0 }));
    } catch (error) {
        yield cancel();
    }
}

export function* watchTrashbin() {
    yield takeEvery(trashbinLoad.toString(), loadTrashbin);
    yield takeEvery(toggleBin.toString(), resetSelected);
    yield takeEvery(trashbinRestoreRequest.toString(), restore);
    yield takeEvery(deleteFilesFromBinRequest.toString(), deleteFilesFromBin);
    yield takeEvery(trashbinLoadMoreRequest.toString(), loadMore);
    yield takeEvery(trashbinRestoreAllRequest.toString(), restoreAll);
    yield takeEvery(seriallyBinClearRequest.toString(), seriallyClearBin);
    yield takeEvery(binClearRequest.toString(), clearBin);
    yield takeEvery(deleteBatchInSeriallyClearingBinRequest.toString(), deleteBatchInSeriallyClearingBin);

    yield takeLatest(
        [
            // Получаем данные по корзине после инициалиации пользователя
            loadUserQuotaSuccess.toString(),
            // Обновляем если данные по корзине получены
            trashbinLoadSuccess.toString(),
            // Обновляем если корзину очистили
            binClearSuccess.toString(),
            // Обновляем если восстановили файлы из корзины
            updateBinByIdSuccess.toString(),
        ],
        getTrashbinSize
    );

    // Обновляем если переместили файлы в корзину
    yield debounce(1000, removeFileSuccess.toString(), getTrashbinSize);
}
