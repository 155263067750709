import type { Dispatch, ReactElement, ReactEventHandler, SetStateAction } from 'react';
import type { TariffBorder } from 'reactApp/sections/QuotaLanding/TariffsPlans/TariffPlansCard/TariffPlansCard.types';
import type { ITariffFeatureListProps } from 'reactApp/ui/TariffCardNew/components/TariffFeatureList.types';
import type { ITariffLabelProps } from 'reactApp/ui/TariffCardNew/components/TariffLabel.types';
import type { ITariffSpaceProps } from 'reactApp/ui/TariffCardNew/components/TariffSpace.types';
import type { ISwitchProps } from 'reactApp/ui/TariffCardNew/components/TariffSwitch.types';

export enum ETariffCardWidth {
    w352 = 'w352',
    w360 = 'w360',
    w300 = 'w300',
    fluid = 'fluid',
}

export enum ETariffCardSizeButtonSize {
    big = 'big',
}

export enum EButtonTextSize {
    middle = 'middle',
    large = 'large',
}

export enum TariffCardContentSize {
    middle = 'middle',
    compact = 'compact',
    small = 'small',
}

export interface ITariffCardProps {
    dataQa?: string;
    width?: ETariffCardWidth;
    compact?: boolean;
    contentSize?: TariffCardContentSize;
    disable?: boolean;
    action?: boolean;
    tariffId: string;
    buttonText: ReactElement | string;
    buttonSize?: ETariffCardSizeButtonSize;
    buttonTextSize?: EButtonTextSize;
    infoBlock?: ReactElement;
    footerText?: ReactElement;
    headerPrimaryText?: string;
    headerPrimaryTextCrossedOut?: boolean;
    headerPrimaryTextLarge?: boolean;
    headerPrimaryTextExtraLarge?: boolean;
    headerPrimaryTextLight?: boolean;
    buttonPrimary?: boolean;
    buttonPrimaryLight?: boolean;
    buttonDisabled?: boolean;
    buttonInFooter?: boolean;
    buttonInFooterGap?: number;
    /* tempexp_16800-start */
    buttonInInfoBlock?: boolean;
    compactFeatureList?: boolean;
    stretchInfoBlock?: boolean;
    /* tempexp_16800-end */
    buttonAdditionalText?: ReactElement | string;
    withoutButton?: boolean;
    accentFeature?: boolean;
    focused?: boolean;
    focusedWithoutShadow?: boolean;
    discountLabel?: number;
    label?: string;
    labelKind?: ITariffLabelProps['kind'];
    space: Pick<ITariffSpaceProps, 'space' | 'units'>;
    showSpaceWithPlus?: boolean;
    giftSpace?: Pick<ITariffSpaceProps, 'space' | 'units'>;
    onClickButton: ReactEventHandler;
    onClick?: ReactEventHandler;
    onClickSwitch?: ISwitchProps['onClick'];
    switchChecked?: ISwitchProps['checked'];
    switchOptions?: ISwitchProps['options'];
    featureListOptions?: ITariffFeatureListProps['items'];
    footerFeatureListOptions?: ITariffFeatureListProps['items'];
    savingInButton?: number;
    accentFocused?: boolean;
    // tempexp_16481-next-line
    onLabelClick?: Dispatch<SetStateAction<boolean>>;
    boldSavingLabel?: boolean;
    // tempexp_17086_next-line
    bordered?: boolean;
    isRebrandingQuotaLanding?: boolean;
    featuresSmallSize?: boolean;
    isRebranding?: boolean;
    // tempexp_17371-next-line
    hideButtonInMonthTariff?: boolean;
    /* tempexp_17509-next-line */
    isFromLitres?: boolean;
    limeSavingLabel?: boolean;
    withMascot?: boolean;
    withSpace?: boolean;
    withInfoBlock?: boolean;
    subscriptionPage?: boolean;
    borderType?: TariffBorder;
    disableAction?: boolean;
}
