/**
 *  Документация: https://confluence.vk.team/pages/viewpage.action?pageId=1178954077

 * Во избежании конфликтов при добавлении таски в merge train НЕ ДОБАВЛЯЕМ в файл новых экспериментов!
 *
 * Вместо этого следуем соглашению https://confluence.vk.team/pages/viewpage.action?pageId=1229289649
 *
 */
import type { QueryParams } from 'reactApp/modules/settings/settings.types';
import { MODAL_TARIFF_BENEFITS_PAY_SOURCE } from 'reactApp/sections/QuotaLanding/DetailedTariffBenefitsPopup/DetailedTariffBenefitsPopup.constants';

import {
    IS_ANDROID_MOBILE,
    IS_MOBILE_BROWSER,
    IS_MOBILE_OR_TABLET_BROWSER,
    IS_PAID_USER,
    IS_PHONE_BROWSER,
    IS_WEBVIEW,
} from './configHelpers';
import { registerExperiment } from './experimentAnalytic';
import { AnalyticEventNames } from './experimentAnalytic/eventNames';
import {
    abBlockOverqueota,
    abSharingLinkFakedoor,
    disableButtonModal,
    mailSpaceTrialPromo,
    moreBenefitsInExpensiveTariffs,
    preferredTariffs,
    quotaTooltip,
    sidebarTrialPromo,
    threeMonthsPeriodTariffsAndroid,
    touchTariffMinSize,
    uploadFileSizeLimitMbAb,
    welcomeDefaultTariff,
    yearlyPriceOnMonthlyTariffButtonTouch,
} from './featuresHelpers';
import { getQueryParams } from './settingsHelpers';

export const registerABExperiments = () => {
    // tempexp_16948-start
    if (IS_WEBVIEW) {
        registerExperiment(
            'y-to-m-price',
            yearlyPriceOnMonthlyTariffButtonTouch,
            [
                AnalyticEventNames.TARIFFS_SHOWN,
                AnalyticEventNames.TARIFF_CLICK,
                AnalyticEventNames.FAST_CHECKOUT_SHOWN,
                AnalyticEventNames.TARIFF_BUY,
            ],
            {
                mapEventNames: {
                    TARIFFS_SHOWN: 'show',
                    TARIFF_CLICK: 'click',
                },
            }
        );
    }
    // tempexp_16948-end

    /** CLOUDWEB-17018: ограничение максимального размера загружамеого файла в облаке */
    registerExperiment(
        'ab-upload-limit',
        uploadFileSizeLimitMbAb ? `${uploadFileSizeLimitMbAb}mb` : 'ctrl',
        [
            AnalyticEventNames.BIG_FILE_DIALOG_SHOW,
            AnalyticEventNames.BIG_FILE_DIALOG_PAYMENT_SHOW,
            AnalyticEventNames.BIG_FILE_DIALOG_PAYMENT_BUY,
        ],
        {
            sendXrayArgs: {
                [AnalyticEventNames.BIG_FILE_DIALOG_PAYMENT_BUY]: ({ tariff }) => {
                    return {
                        iData: tariff,
                    };
                },
            },
        }
    );

    // tempexp_17072-start
    // CLOUDWEB-17072, FMAIL-24483: Эксп со стороны почты, определить его можно только по query параметрам
    const queryParams: QueryParams = getQueryParams();
    const groupName = queryParams.attach_size_limit_id;
    if (groupName) {
        registerExperiment(
            'attach-limit',
            groupName,
            [
                AnalyticEventNames.MAIL_POPUP_SHOW,
                AnalyticEventNames.MAIL_POPUP_CLICK,
                AnalyticEventNames.MAIL_POPUP_PAYMENT_SHOW,
                AnalyticEventNames.MAIL_POPUP_PAYMENT_SUCCESS,
                AnalyticEventNames.MAIL_POPUP_PAYMENT_FAILURE,
            ],
            {
                sendXrayArgs: {
                    // Отправляем радары сразу же, иначе iframe закроется и они не успеют отправиться
                    [AnalyticEventNames.MAIL_POPUP_PAYMENT_SUCCESS]: () => ({ options: { sendImmediately: true } }),
                },
            }
        );
    }
    // tempexp_17072-end

    // tempexp_16800-start
    if (!IS_MOBILE_OR_TABLET_BROWSER) {
        registerExperiment(
            'tariff-benefits',
            moreBenefitsInExpensiveTariffs,
            [
                AnalyticEventNames.QUOTA_LANDING_SHOWN,
                AnalyticEventNames.TARIFF_ADVANTAGES_CLICK,
                AnalyticEventNames.MODAL_TARIFF_ADVANTAGES_SHOW,
                AnalyticEventNames.TARIFF_CLICK,
                AnalyticEventNames.FAST_CHECKOUT_SHOWN,
                AnalyticEventNames.TARIFF_BUY,
            ],
            {
                sendXrayArgs: {
                    TARIFF_CLICK: ({ paySource }, { eventName }) => ({
                        eventName: paySource === MODAL_TARIFF_BENEFITS_PAY_SOURCE ? 'advnt_modal_click' : eventName,
                    }),
                    TARIFF_BUY: ({ paySource }, { eventName }) => ({
                        eventName: paySource === MODAL_TARIFF_BENEFITS_PAY_SOURCE ? 'advnt_modal_buy' : eventName,
                    }),
                    FAST_CHECKOUT_SHOWN: ({ paySource }, { eventName }) => ({
                        eventName: paySource === MODAL_TARIFF_BENEFITS_PAY_SOURCE ? 'advnt_modal_checkout' : eventName,
                    }),
                },
            }
        );
    }
    // tempexp_16800-end

    // tempexp_17061-start
    if (IS_PHONE_BROWSER) {
        registerExperiment(
            'increase-old',
            touchTariffMinSize,
            [
                AnalyticEventNames.QUOTA_LANDING_SHOWN,
                AnalyticEventNames.TARIFF_CLICK,
                AnalyticEventNames.FAST_CHECKOUT_SHOWN,
                AnalyticEventNames.TARIFF_BUY,
            ],
            {
                sendXrayArgs: (_, { expValue }) => ({ cancel: +expValue !== 256 }),
            }
        );
    }
    // tempexp_17061-end

    // tempexp-17154-start
    registerExperiment(
        'disable-button',
        disableButtonModal,
        [
            AnalyticEventNames.DISABLE_BUTTON_CLICK,
            AnalyticEventNames.DISABLE_BUTTON_SHOW_CHECKOUT,
            AnalyticEventNames.DISABLE_BUTTON_PAYMENT,
        ],
        {
            abortIfUnexpectedFeatureValue: false,
        }
    );
    // tempexp-17154-end

    // tempexp_17220-start
    if (preferredTariffs.length) {
        registerExperiment('preferred-tariffs', '', [
            AnalyticEventNames.QUOTA_LANDING_SHOWN,
            AnalyticEventNames.TARIFF_CLICK,
            AnalyticEventNames.FAST_CHECKOUT_SHOWN,
            AnalyticEventNames.TARIFF_BUY,
        ]);
    }
    // tempexp_17220-end

    // tempexp_17145-start
    registerExperiment('quota-tooltip', quotaTooltip, [
        AnalyticEventNames.QUOTA_TOOLTIP_SHOW,
        AnalyticEventNames.QUOTA_TOOLTIP_CLOSE,
        AnalyticEventNames.QUOTA_BUTTON_SHOW,
        AnalyticEventNames.QUOTA_BUTTON_CLICK,
    ]);
    // tempexp_17145-end

    // tempexp_17130-start
    registerExperiment('mail-space-trial-promo', mailSpaceTrialPromo, [
        AnalyticEventNames.MAIL_SPACE_TRIAL_PROMO_SHOW,
        AnalyticEventNames.MAIL_SPACE_TRIAL_PROMO_CLICK,
        AnalyticEventNames.MAIL_SPACE_TRIAL_PROMO_CLOSE,
    ]);
    // tempexp_17130-end

    // tempexp_17237-start
    if (!IS_MOBILE_OR_TABLET_BROWSER) {
        const sendXrayArgsForTooltip = (_, { expName, eventName }) => ({ names: [`${expName}-${eventName}`] });
        registerExperiment(
            'nice-link',
            abSharingLinkFakedoor,
            [
                AnalyticEventNames.DATALIST_ITEM_CLICK_PUBLISH,
                AnalyticEventNames.NICE_LINK_TOOLTIP_SHOW,
                AnalyticEventNames.NICE_LINK_TOOLTIP_CLOSE,
                AnalyticEventNames.NICE_LINK_TOOLTIP_CLICK,
                AnalyticEventNames.NICE_LINK_THANKS_TOOLTIP_SHOW,
                AnalyticEventNames.NICE_LINK_THANKS_TOOLTIP_CLICK,
            ],
            {
                sendPlatformData: false,
                mapEventNames: {
                    DATALIST_ITEM_CLICK_PUBLISH: 'click-share-folder',
                    NICE_LINK_TOOLTIP_SHOW: 'tooltip_show',
                    NICE_LINK_TOOLTIP_CLOSE: 'tooltip_close',
                    NICE_LINK_TOOLTIP_CLICK: 'tooltip_click',
                    NICE_LINK_THANKS_TOOLTIP_SHOW: 'thanks-tooltip_show',
                    NICE_LINK_THANKS_TOOLTIP_CLICK: 'thanks-tooltip_click',
                },
                sendXrayArgs: {
                    NICE_LINK_TOOLTIP_SHOW: sendXrayArgsForTooltip,
                    NICE_LINK_TOOLTIP_CLOSE: sendXrayArgsForTooltip,
                    NICE_LINK_TOOLTIP_CLICK: sendXrayArgsForTooltip,
                    NICE_LINK_THANKS_TOOLTIP_SHOW: sendXrayArgsForTooltip,
                    NICE_LINK_THANKS_TOOLTIP_CLICK: sendXrayArgsForTooltip,
                },
            }
        );
    }
    // tempexp_17237-end
    // tempexp_17086-start
    if (!IS_MOBILE_OR_TABLET_BROWSER) {
        registerExperiment('welcome-tariff', welcomeDefaultTariff?.variant || 'a', [
            AnalyticEventNames.TARIFF_CLICK,
            AnalyticEventNames.TARIFF_BUY,
            AnalyticEventNames.WELCOME_DEFAULT_TARIFFS_SHOW,
            AnalyticEventNames.WELCOME_DEFAULT_TARIFFS_CLICK_FREE,
        ]);
    }
    // tempexp_17086-end

    // tempexp_17125-start
    if (!IS_MOBILE_OR_TABLET_BROWSER && !IS_PAID_USER) {
        registerExperiment('block-over', abBlockOverqueota, [
            AnalyticEventNames.OVERQUOTA_POPUP_SHOW,
            AnalyticEventNames.OVERQUOTA_POPUP_CLICK,
            AnalyticEventNames.TARIFF_BUY,
            AnalyticEventNames.QUOTA_BLOCK_OVER_VIEW,
            AnalyticEventNames.QUOTA_BLOCK_OVER_CLICK,
            AnalyticEventNames.BLOCK_OVERQUOTA_VIEW,
            AnalyticEventNames.BLOCK_OVERQUOTA_CLICK,
            AnalyticEventNames.BLOCK_OVERQUOTA_FILES_VIEW,
            AnalyticEventNames.BLOCK_OVERQUOTA_FILES_CLICK,
        ]);
    }
    // tempexp_17125-end

    // tempexp_17157-start
    registerExperiment('sidebar-trial-promo', sidebarTrialPromo, [
        AnalyticEventNames.SIDEBAR_TRIAL_PROMO_SHOW,
        AnalyticEventNames.SIDEBAR_TRIAL_PROMO_CLICK,
        AnalyticEventNames.SIDEBAR_TRIAL_PROMO_CLOSE,
        AnalyticEventNames.SIDEBAR_TRIAL_BANNER_SHOW,
        AnalyticEventNames.SIDEBAR_TRIAL_BANNER_CLICK,
    ]);
    // tempexp_17157-end

    // tempexp-17076-start
    if (IS_ANDROID_MOBILE && IS_WEBVIEW) {
        registerExperiment('3-mon-tariffs', threeMonthsPeriodTariffsAndroid.variant, [
            AnalyticEventNames.MOBILE_LANDING_SHOWN,
            AnalyticEventNames.TARIFF_CLICK,
            AnalyticEventNames.TARIFF_BUY,
        ]);
    }
    // tempexp-17076-end

    // tempexp_17127-start
    if (!IS_MOBILE_BROWSER) {
        const queryParams = getQueryParams();
        const groupValue = queryParams.noadsExp || 'a';
        registerExperiment(
            'noads-modal',
            groupValue,
            [
                AnalyticEventNames.BUY_FAST_CHECKOUT_VIEW,
                AnalyticEventNames.BUY_FAST_CHECKOUT_CLICK_PAY,
                AnalyticEventNames.BUY_DISABLE_ADS_MODAL_SHOWN,
                AnalyticEventNames.BUY_DISABLE_ADS_MODAL_PERIOD_CHANGE,
                AnalyticEventNames.BUY_DISABLE_ADS_MODAL_TARIFF_CLICK,
            ],
            {
                sendPlatformData: false,
                mapEventNames: {
                    [AnalyticEventNames.BUY_FAST_CHECKOUT_VIEW]: 'checkout-view',
                    [AnalyticEventNames.BUY_FAST_CHECKOUT_CLICK_PAY]: 'checkout-click',
                    [AnalyticEventNames.BUY_DISABLE_ADS_MODAL_SHOWN]: 'modal-shown',
                    [AnalyticEventNames.BUY_DISABLE_ADS_MODAL_PERIOD_CHANGE]: 'change-period',
                    [AnalyticEventNames.BUY_DISABLE_ADS_MODAL_TARIFF_CLICK]: 'tariff-click',
                },
                sendXrayArgs: {
                    [AnalyticEventNames.BUY_DISABLE_ADS_MODAL_TARIFF_CLICK]: ({ product }, { eventName }) => ({
                        eventName: `${eventName}_${product.space.space}_${product.period}`,
                    }),
                    [AnalyticEventNames.BUY_FAST_CHECKOUT_VIEW]: ({ product }, { eventName }) => ({
                        eventName: `${eventName}_${product.space.space}_${product.period}`,
                    }),
                    [AnalyticEventNames.BUY_FAST_CHECKOUT_CLICK_PAY]: ({ product }, { eventName }) => ({
                        eventName: `${eventName}_${product.space.space}_${product.period}`,
                    }),
                },
            }
        );
    }
    // tempexp_17127-end
};

/**
 * Во избежании конфликтов при добавлении таски в merge train НЕ ДОБАВЛЯЕМ в файл новых экспериментов!
 *
 * Вместо этого следуем соглашению https://confluence.vk.team/pages/viewpage.action?pageId=1229289649
 *
 */
