import { Icon24ArrowRightOutline } from '@vkontakte/icons';
import { Input, Spacing, Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import image from 'img/quota-landing/tariff-poll.png';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';

import { renderTariffPollSuccessModal } from '../TariffPollSuccessDialog/TariffPollSuccessDialog.helpers';
import { sendTariffPollAnalytics } from './TariffPollDialog.analytics';
import styles from './TariffPollDialog.css';
import { TARIFF_POLL_MODAL_ID } from './TariffPollDialog.helpers';
import type { TariffPollDialogProps } from './TariffPollDialog.types';

export const TariffPollDialog = memo<TariffPollDialogProps>(({ onClose }) => {
    const dispatch = useDispatch();

    const [showOtherReason, setShowOtherReason] = useState(false);
    const [value, setValue] = useState('');

    useEffect(() => {
        dispatch(UserStorageActions.set(TARIFF_POLL_MODAL_ID, true));
        emitAnalyticEvent(AnalyticEventNames.TARIFF_POLL_SHOW);
        sendTariffPollAnalytics({ action: 'show', name: 'survey_landing' });
    }, []);

    const handleClose = useCallback(() => {
        onClose?.();
        sendTariffPollAnalytics({ action: 'close', name: 'survey_landing' });
    }, [onClose]);

    const handleShowOtherReason = useCallback(() => {
        setShowOtherReason(true);
    }, [setShowOtherReason]);

    const handleChangeInput = useCallback(
        (event) => {
            const { value } = event.target || {};
            setValue(value);
        },
        [setValue]
    );

    const handleSubmitReason = useCallback(
        (e) => {
            const name_button = e.target.textContent ? e.target.textContent : value;
            sendTariffPollAnalytics({ action: 'click', name: 'survey_landing', name_button });
            onClose?.();
            renderTariffPollSuccessModal();
        },
        [onClose, value]
    );

    return (
        <Dialog open onCancel={handleClose} size="tiny" id="tariff-poll-dialog" className={styles.root}>
            <Content isModal>
                <Spacing size={70} />
                <img src={image} alt="Не подошла подписка? Расскажите почему" className={styles.image} />
                <Text className={styles.title}>
                    Не подошла подписка?
                    <br />
                    Расскажите почему
                </Text>
                <Spacing size={32} />
                <div className={styles.buttons}>
                    <Button sizeMode={ButtonSizeMode.big} fluid theme="vk" className={styles.button} onClick={handleSubmitReason}>
                        Не устроила цена
                    </Button>
                    <Button sizeMode={ButtonSizeMode.big} fluid theme="vk" className={styles.button} onClick={handleSubmitReason}>
                        Нет подходящего тарифа
                    </Button>
                    <Button sizeMode={ButtonSizeMode.big} fluid theme="vk" className={styles.button} onClick={handleSubmitReason}>
                        Мало информации
                    </Button>
                    <Button sizeMode={ButtonSizeMode.big} fluid theme="vk" className={styles.button} onClick={handleSubmitReason}>
                        Уже есть подписка
                    </Button>
                </div>
                <Spacing size={12} />
                <div className={styles.otherReason}>
                    {showOtherReason ? (
                        <>
                            <Input
                                type="text"
                                placeholder="Напишите свою причину"
                                className={styles.input}
                                value={value}
                                onChange={handleChangeInput}
                            />
                            <Button
                                sizeMode={ButtonSizeMode.big}
                                theme="vk"
                                primary
                                disabled={!value}
                                className={classNames(styles.button, styles.submit)}
                                onClick={handleSubmitReason}
                            >
                                <Icon24ArrowRightOutline />
                            </Button>
                        </>
                    ) : (
                        <Button sizeMode={ButtonSizeMode.big} fluid theme="vk" className={styles.button} onClick={handleShowOtherReason}>
                            Другая причина
                        </Button>
                    )}
                </div>
                <Spacing size={32} />
            </Content>
        </Dialog>
    );
});

TariffPollDialog.displayName = 'TariffPollDialog';
