import type { EStorageType } from 'reactApp/modules/storage/storage.types';
import { sendDwh } from 'reactApp/utils/ga';

export const sendDisableDownloadDwh = ({
    eventCategory = 'ban-downloading',
    action = 'show',
    storage = 'unk',
    isPaid = false,
}: {
    eventCategory?: 'ban-downloading' | 'ban-tooltip' | 'ban-tooltip-mini';
    action?: 'show' | 'click' | 'click_lock';
    storage?: EStorageType | 'unk';
    isPaid?: boolean;
}) => {
    sendDwh({
        eventCategory,
        label: action,
        dwhData: {
            is_paid: isPaid,
            source: storage,
        },
    });
};
