import * as OAuth from '@mail/oauth';
import { getFeature } from 'Cloud/Application/FeaturesEs6';
import config from 'Cloud/config';
import axios from 'lib/axios';
import { logger } from 'lib/logger';
import { makeRequestHelper } from 'reactApp/api/apiHelpers';
import { ViewArchiveInfoAPICall } from 'reactApp/api/ViewArchiveInfoAPICall';
import { ViewArchiveItemDownloadAPICall } from 'reactApp/api/ViewArchiveItemDownloadAPICall';
import { ViewVKArchiveInfoAPICall } from 'reactApp/api/ViewVKArchiveInfoAPICall';
import { ViewVKArchiveItemDownloadAPICall } from 'reactApp/api/ViewVKArchiveItemDownloadAPICall';
import { downloadByUrl } from 'reactApp/appHelpers/appDownload';
import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { zipviewAttachPoll } from 'reactApp/appHelpers/featuresHelpers';
import { CORSAPI_HOST, MAIL_HOST, ZIP_VIEW_HOST } from 'reactApp/modules/attaches/attaches.constants';
import { getStockIdOfFile, getWeblinkFromCloudAttachGetUrl } from 'reactApp/modules/attaches/attaches.helpers';
import { AttachesSelectors } from 'reactApp/modules/attaches/attaches.selectors';
import { EAttachTypes } from 'reactApp/modules/attaches/attaches.types';
import { unpackOnClientController } from 'reactApp/modules/file/unpackOnClientController';
import { downloadByLink } from 'reactApp/modules/file/utils';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { getCurrentItem, getStorageItemById } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import {
    archiveInfoFail,
    archiveInfoSuccess,
    getArchiveItemPreviewSuccess,
    startArchiveProlongTimer,
} from 'reactApp/modules/viewer/viewer.module';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { type IArchiveInfoApiResponseItem, type IZipViewProcessResponse, EArchiveType } from 'reactApp/modules/viewer/viewer.types';
import { sendKaktamLog, sendXray } from 'reactApp/utils/ga';
import { promiseTimeout } from 'reactApp/utils/helpers';
import { captureException } from 'reactApp/utils/tracer';
import { call, cancel, put, select } from 'redux-saga/effects';

// CLOUDWEB-15166
// const axiosInstance = axios.create({
//     withCredentials: true,
// });
//
// axiosInstance.interceptors.response.use(
//     async function (response) {
//         return response;
//     },
//     async function (error) {
//         const originalRequest = error.config;
//         if (error.response.status === 401 && !originalRequest._retry) {
//             originalRequest._retry = true;
//             originalRequest.params['access_token'] = await getAuthToken(true);
//             return axiosInstance(originalRequest);
//         }
//         if (error.response.status === 500 && !originalRequest._retry) {
//             originalRequest._retry = true;
//             return axiosInstance(originalRequest);
//         }
//         return Promise.reject(error);
//     }
// );

const CLIENT_ID = config.get('CORS_API_CLIENT_ID');
const OAUTH_HOST = config.get('OAUTH_HOST');
const login = config.get('user.email');
let OAuthToken: string | null = '';
const OAUTH_TOKEN_TTL = getFeature('oauth-token-ttl');
const oauthTokenTTL = new Date();

const updateOauthTokenTTL = (date: Date, minutes: number) => {
    date.setMinutes(date.getMinutes() + minutes);

    return date;
};

export function checkAuth(fromCache = false) {
    const clientId = CLIENT_ID;
    const timeout = setTimeout(() => {
        sendXray('oauth', 'timeout');
    }, 5000);

    // инициализация OAuth клиента
    OAuth.init({
        clientId,
        login,
    });
    OAuth.setOAuthHost(OAUTH_HOST);

    return new Promise((resolve, reject) => {
        // Возвращаем токен из кеша, если прошло меньше ttl и токен существует
        if (fromCache && OAuthToken && OAUTH_TOKEN_TTL && oauthTokenTTL > new Date()) {
            clearTimeout(timeout);
            sendXray('oauth', 'connected');

            return resolve(OAuthToken);
        }
        // запрос за авторизацией, работает только с доменов, привязанных к clientId
        OAuth.auth((state) => {
            clearTimeout(timeout);
            sendXray('oauth', state.status);

            const isAuth = state.status === 'connected';
            OAuthToken = state.access_token;

            if (isAuth) {
                updateOauthTokenTTL(oauthTokenTTL, OAUTH_TOKEN_TTL);
                resolve(OAuthToken);
            } else {
                sendKaktamLog(
                    {
                        state,
                        login,
                        clientId,
                    },
                    'cloud_oauth'
                );

                reject(`oauth: ${state.status}`);
            }
        });
    });
}

const mapMailZipItemOnCloudItem = (item, parent = ''): IArchiveInfoApiResponseItem => {
    const newItem: IArchiveInfoApiResponseItem = {
        name: item.name,
        size: item.unpacked_size,
        list: [],
        path: parent ? `${parent}/${item.name}` : item.name,

        previewUrl: item.content_link,
        viewersUrl: item?.viewers?.ms?.url?.replace(MAIL_HOST, CORSAPI_HOST),
        downloadUrl: `${item.content_link}?download=1`,

        kind: item.type === 'directory' ? 'folder' : 'file',
    };

    if (item.type === 'directory') {
        newItem.list = item.files?.map((subItem) => mapMailZipItemOnCloudItem(subItem, newItem.path));
    }

    return newItem;
};

const getUrlName = (storage: EStorageType): string => {
    switch (storage) {
        case EStorageType.home:
        case EStorageType.stock:
        case EStorageType.embedded:
            return storage;
        case EStorageType.public:
            return 'weblink';
    }
    return EStorageType.home;
};

const sendXrayError = (error) => sendXray(['attaches', 'archive', 'error', error]);
const sendXrayArchive = (action, storage, label = '') =>
    sendXray([`archive-${IS_PHONE_BROWSER ? 'ph' : 'dsk'}-${storage}`, 'open', action, label]);

const processApiUrls = (url) => {
    if (!url) {
        return url;
    }
    const fileId = url.split('/').pop();
    return url.replace(fileId, encodeURIComponent(fileId));
};

const makeArchiveInfoApiCall = (storage: EStorageType, id) => {
    const paramName = getUrlName(storage);
    return makeRequestHelper(new ViewArchiveInfoAPICall(), { [paramName]: id }, { url: paramName });
};

function makeVKArchiveInfoApiCall(params: { url: string; path?: string; size: number }) {
    return makeRequestHelper(new ViewVKArchiveInfoAPICall(), params);
}

const makeAttachesArchiveInfoApiCall = async (url, email) => {
    if (!url || !email) {
        sendXrayError('noparams');
        return;
    }

    try {
        const { data } = await axios.get(url, { withCredentials: true, params: { ajax_call: 1, 'x-email': email } });
        //
        // // 0: "AjaxResponse"
        // // 1: "Redirect"
        // // 2: "https://af.attachmail.ru/cgi-bin/readms
        if (!Array.isArray(data) || data.length !== 3) {
            sendXrayError('noredirdata');
            return;
        }

        let token = await checkAuth();
        const newUrl = data[2];

        const { data: zipViewLinks } = await axios.get<IZipViewProcessResponse>(
            `${ZIP_VIEW_HOST}/process?url=${encodeURIComponent(newUrl)}`,
            {
                withCredentials: true,
                params: { 'x-email': email, access_token: token },
            }
        );

        for (let i = 0; i < zipviewAttachPoll.pollCount; i++) {
            token = await checkAuth();
            const { data: structureResp } = await axios.get(zipViewLinks.info_link, {
                withCredentials: true,
                params: { access_token: token },
            });

            if (structureResp.status_string === 'Done') {
                return {
                    structure: structureResp.structure,
                    ttl: structureResp.ttl,
                    prolongLink: zipViewLinks.prolong_link,
                };
            } else if (structureResp.status_string === 'Got') {
                await promiseTimeout(zipviewAttachPoll.pollPeriodMs);
            } else {
                if (structureResp.status_string === 'Some error') {
                    sendXrayError('process');
                } else {
                    sendXrayError('timeout');
                }
                break;
            }
        }

        return null;
    } catch (error) {
        logger.error(error);
        captureException(error);
        sendXrayError('exception');
    }
};

function makeVKArchiveItemInfoApiCall(params: { url: string; size: number; path: string }) {
    return makeRequestHelper(new ViewVKArchiveItemDownloadAPICall(), params);
}

const makeArchiveItemInfoApiCall = (storage: EStorageType, archiveId, path) => {
    const paramName = getUrlName(storage);
    return makeRequestHelper(new ViewArchiveItemDownloadAPICall(), { [paramName]: archiveId, path }, { url: paramName });
};

function* getAttachesArchiveInfo(id: string, storage: EStorageType) {
    const item = yield select(
        storage === EStorageType.viewerAttaches ? AttachesSelectors.getViewerAttachById : AttachesSelectors.getAttachById,
        id
    );
    const email = yield select(UserSelectors.getEmail);
    const isCloudStock = item.attachType === EAttachTypes.cloudStock;

    if (isCloudStock || item.attachType === EAttachTypes.cloud) {
        const archiveId = isCloudStock ? getStockIdOfFile(id, item.name) : getWeblinkFromCloudAttachGetUrl(item?.url?.get);
        const res = yield call(makeArchiveInfoApiCall, isCloudStock ? EStorageType.stock : EStorageType.public, archiveId);
        if (res.error) {
            yield put(archiveInfoFail({ error: res.error?.message, archiveId: id }));
            yield cancel();
        } else {
            yield put(archiveInfoSuccess({ list: res.data, archiveId: id }));
        }

        return;
    }

    const res = yield call(makeAttachesArchiveInfoApiCall, item?.url?.download, email);

    if (!res || res.error) {
        yield put(archiveInfoFail({ error: res?.error?.message || 'error', archiveId: id }));
        yield cancel();

        return;
    }

    yield put(
        archiveInfoSuccess({
            list: res.structure.files?.map((item) => mapMailZipItemOnCloudItem(item)),
            archiveId: id,
        })
    );

    if (res.ttl && res.prolongLink) {
        yield put(startArchiveProlongTimer({ prolongLink: res.prolongLink, ttl: res.ttl }));
    }
}

function* getCloudZipInfo(id: string, storage: EStorageType) {
    let res;

    if (storage === EStorageType.embedded) {
        const file = yield select(getCurrentItem);
        res = yield call(makeVKArchiveInfoApiCall, { url: file.url.get, size: parseInt(file.size) });
    } else {
        res = yield call(makeArchiveInfoApiCall, storage, id);
    }

    if (!res || res.error) {
        yield put(archiveInfoFail({ error: res?.error?.message || 'error', archiveId: id }));
        yield cancel();
    } else {
        yield put(archiveInfoSuccess({ list: res.data, archiveId: id }));
    }
}

function* getArchiveOnClientInfo(id: string, storage: EStorageType, type: EArchiveType) {
    sendXrayArchive('opening', storage);

    yield unpackOnClientController.init();

    const item = yield select(getStorageItemById, storage, id);
    const isEmbedded = storage === EStorageType.embedded;

    const res = yield unpackOnClientController.unpack({
        name: item.name,
        fileUrl: item.url.get || item.url.download,
        type,
        directDownload: isEmbedded || storage === EStorageType.viewerAttaches || storage === EStorageType.stock,
        withCredentials: !isEmbedded,
    });

    if (!res || res.error) {
        yield put(archiveInfoFail({ error: res?.error?.message || 'error', archiveId: id }));
        sendXrayArchive('open', storage, res.error ? 'fail' : 'nores');
        yield cancel();
    } else {
        if (!res.data) {
            sendXrayArchive('open', storage, 'nodata');
        }
        yield put(archiveInfoSuccess({ list: res.data, archiveId: id }));
    }
}

const canUnpackOnClient = (type?: EArchiveType) => type && [EArchiveType.rar, EArchiveType._7z].includes(type);

export function* handleRequestArchiveInfo(action) {
    const { storage, id } = action.payload;

    try {
        const archive: ReturnType<typeof ViewerSelectors.getArchiveInfo> = yield select(ViewerSelectors.getArchiveInfo);

        if (!archive) {
            return;
        }

        const isClientUnpacker = canUnpackOnClient(archive.type);
        if (storage === EStorageType.attaches || storage === EStorageType.viewerAttaches) {
            const archive = yield select(ViewerSelectors.getViewerItem);
            const isCloudStock = archive.attachType === EAttachTypes.cloudStock;
            if (!(isCloudStock && isClientUnpacker)) {
                yield call(getAttachesArchiveInfo, id, storage);
                return;
            }
        }

        if (isClientUnpacker) {
            yield call(getArchiveOnClientInfo, id, storage, archive.type);
            return;
        }

        yield call(getCloudZipInfo, id, storage);
    } catch (error: any) {
        logger.error(error);
        captureException(error);
        sendXrayError('fail');
        yield put(archiveInfoFail({ error: error?.message, archiveId: id }));
        yield cancel();
    }
}

export function* handleRequestArchiveItemInfo(action) {
    const { storage, archiveId, itemId } = action.payload;

    try {
        const file = yield select(ViewerSelectors.getArchiveActiveItem);

        if (file?.previewUrl === null) {
            return;
        }

        const archive: ReturnType<typeof ViewerSelectors.getArchiveInfo> = yield select(ViewerSelectors.getArchiveInfo);

        const isClientUnpacker = canUnpackOnClient(archive?.type);

        let res;
        if (storage === EStorageType.attaches || storage === EStorageType.viewerAttaches) {
            // сюда добираются только клики по клауд стокам и клауд аттачам
            const archive = yield select(ViewerSelectors.getViewerItem);
            const isCloudStock = archive.attachType === EAttachTypes.cloudStock;
            if (isClientUnpacker && isCloudStock) {
                const url = yield unpackOnClientController.getFileContentBlobUrl(itemId);
                res = { url };
            } else {
                const id = isCloudStock ? getStockIdOfFile(archiveId, archive.name) : getWeblinkFromCloudAttachGetUrl(archive?.url?.get);
                res = yield call(makeArchiveItemInfoApiCall, isCloudStock ? EStorageType.stock : EStorageType.public, id, itemId);
            }
        } else if (isClientUnpacker) {
            const url = yield unpackOnClientController.getFileContentBlobUrl(itemId);
            res = { url };
        } else if (storage !== EStorageType.embedded) {
            res = yield call(makeArchiveItemInfoApiCall, storage, archiveId, itemId);
        } else {
            const file = yield select(getCurrentItem);

            res = yield call(makeVKArchiveItemInfoApiCall, {
                url: file.url.get,
                size: parseInt(file.size),
                path: itemId,
            });
        }

        const previewUrl = res?.url ?? processApiUrls(res.data?.inline_url) ?? null;

        if (!res.error) {
            yield put(getArchiveItemPreviewSuccess({ itemId, previewUrl }));
        }
    } catch (error) {
        logger.error(error);
        captureException(error);
        yield cancel();
    }
}

export function* handleDownloadArchiveItemRequest(action) {
    let { storage, archiveId } = action.payload;
    const { itemId } = action.payload;

    if (storage === EStorageType.attaches || storage === EStorageType.viewerAttaches) {
        const item = yield select(ViewerSelectors.getArchiveItemById, itemId);
        if (!item) {
            return;
        }
        const viewerItem = yield select(ViewerSelectors.getViewerItem);

        if (item.downloadUrl) {
            downloadByUrl(item.downloadUrl, item.name, storage);
            return;
        }

        if (viewerItem.attachType === EAttachTypes.cloudStock) {
            storage = EStorageType.stock;
            archiveId = getStockIdOfFile(archiveId, viewerItem.name);
        } else if (viewerItem.attachType === EAttachTypes.cloud) {
            storage = EStorageType.public;
            archiveId = getWeblinkFromCloudAttachGetUrl(viewerItem?.url?.get);
        }
    }

    const archive = (yield select(ViewerSelectors.getArchiveInfo)) as ReturnType<typeof ViewerSelectors.getArchiveInfo>;

    const name = itemId.split('/').pop();
    if (canUnpackOnClient(archive?.type)) {
        const url = yield unpackOnClientController.getFileContentBlobUrl(itemId);
        if (url) {
            downloadByLink(url, name);
        }
        return;
    }

    let res;
    if (storage === EStorageType.embedded) {
        const file = yield select(getCurrentItem);
        res = yield call(makeVKArchiveItemInfoApiCall, {
            url: file.url.get,
            size: parseInt(file.size),
            path: itemId,
        });
    } else {
        res = yield call(makeArchiveItemInfoApiCall, storage, archiveId, itemId);
    }

    if (res.error) {
        yield put(
            showSnackbarAction({
                type: SnackbarTypes.failure,
                id: 'dld-from-zip-viewer',
                text: 'Не удалось скачать файл из архива',
                closable: true,
            })
        );
        yield cancel();
    } else {
        const url = processApiUrls(res.data?.attach_url);
        if (url) {
            downloadByUrl(url, name, storage);
        }
    }
}

export function* handleStartArchiveProlongTimer(action) {
    yield promiseTimeout(zipviewAttachPoll.prolongPeriodMs || (action.payload.ttl * 1000) / 2);

    const { prolongLink, ttl } = yield select(ViewerSelectors.getArchiveProlong);

    if (!prolongLink || !ttl) {
        return;
    }

    try {
        const token = yield call(checkAuth);
        yield call(axios.get, prolongLink, {
            withCredentials: true,
            params: { access_token: token },
        });
        sendXray(['attaches', 'archive', 'prolong']);
    } catch (error) {
        sendXrayError('prolong');
    }

    yield call(handleStartArchiveProlongTimer, { payload: { ttl: zipviewAttachPoll.prolongPeriodMs || ttl } });
}
