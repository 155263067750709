import { type RefObject, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadNewFileSelectingDialogs } from 'reactApp/appHelpers/featuresHelpers/features/uploadNewFileApi';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { snackbarController } from 'reactApp/modules/snackbar/snackbar.controller';
import { getAllowedExtensions } from 'reactApp/modules/upload/upload.selectors';
import { getEntries } from 'reactApp/modules/uploading/helpers/dataTransfer.helpers';
import { sendGaUploaderNew } from 'reactApp/modules/uploading/helpers/uploading.helpers';
import { uploadingLog } from 'reactApp/modules/uploading/serviceClasses/UploadingLog';
import { addToUploadQueue } from 'reactApp/modules/uploading/uploading.module';
import type { EUploadSource, IDescriptorOptions } from 'reactApp/modules/uploading/uploading.types';
import type { RootState } from 'reactApp/store';
import { captureException } from 'reactApp/utils/tracer';

const inputEventToHandle = uploadNewFileSelectingDialogs ? 'click' : 'change';

interface IProps {
    input?: RefObject<HTMLInputElement> | null;
    disableDrop?: boolean;
    source?: EUploadSource;
}

let isFileChooserShown = false;
let prevOpenTime = 0;

export const useUploadInputHandlers = ({ input = null, disableDrop = false, source }: IProps = {}) => {
    const dispatch = useDispatch();
    const storage = useSelector(getCurrentStorage);
    // @ts-ignore
    const allowedExtensions = useSelector((state: RootState) => getAllowedExtensions(state, storage));

    const onDrop = useCallback(
        async (event, data, descriptorOptions: IDescriptorOptions = {}) => {
            const entries = await getEntries(data);

            if (entries?.length) {
                dispatch(addToUploadQueue({ files: entries, descriptorOptions: { ...descriptorOptions, source } }));
            } else {
                const files = Array.from(data.files) as File[];

                if (files?.length) {
                    dispatch(addToUploadQueue({ files, descriptorOptions: { ...descriptorOptions, source } }));
                }
            }
        },
        [source]
    );

    const onUpload = useCallback(
        async (files: (File | FileSystemEntry)[]) => {
            try {
                dispatch(addToUploadQueue({ files, descriptorOptions: { source } }));

                uploadingLog.info({
                    event: 'input files',
                    details: files.length,
                    radarName: 'input-files',
                    radarValue: files.length,
                });

                uploadingLog.info({
                    event: 'enqueue',
                    details: files.length,
                });
            } catch (error: any) {
                snackbarController.showSnackbar({ id: 'uploaderror', text: 'Файлы не найдены' });
                uploadingLog.error(error);
            }
        },
        [source]
    );

    const handlerInputChange = useCallback(
        async (event: Event | React.ChangeEvent<HTMLInputElement>) => {
            switch (event.type) {
                case 'drop':
                    if (!disableDrop) {
                        await onDrop(event, null, { isDrop: true });
                    }
                    break;

                case 'change': {
                    const entries = (event as React.ChangeEvent<HTMLInputElement>).target.webkitEntries;
                    const files = (event as React.ChangeEvent<HTMLInputElement>).target.files;

                    if (!files?.length && !entries?.length) {
                        return;
                    }

                    // entries это хендлы файлов, сами файлы получаются из них потом, потому в приоритете используем их
                    await onUpload(entries?.length ? [...entries] : Array.from(files as FileList));

                    break;
                }

                case 'click': {
                    let handles;
                    let webkitdirectory;
                    const types: FilePickerAcceptType[] = [];

                    if (isFileChooserShown) {
                        sendGaUploaderNew('filedlg', 'warning-shown');
                        return;
                    }

                    try {
                        const multiple = true;
                        if (allowedExtensions?.length) {
                            types.push({
                                accept: {
                                    ['application/octet-stream']: allowedExtensions.map((item) => `.${item}`),
                                },
                            });
                        }

                        // @ts-ignore
                        webkitdirectory = event?.target?.getAttribute('webkitdirectory');

                        isFileChooserShown = true;

                        if (webkitdirectory) {
                            handles = [await window.showDirectoryPicker()];
                        } else {
                            handles = await window.showOpenFilePicker({
                                multiple,
                                types,
                                excludeAcceptAllOption: types.length > 0,
                            });
                        }

                        isFileChooserShown = false;
                        prevOpenTime = new Date().valueOf();
                    } catch (error: any) {
                        isFileChooserShown = false;

                        // Fail silently if the user has simply canceled the dialog.
                        if (error.name !== 'AbortError') {
                            captureException(error, {
                                issueKey: 'showOpenFilePicker error',
                                webkitdirectory,
                                types: types.length ? JSON.stringify(types) : '',
                                // @ts-ignore
                                className: event?.target?.className,
                                storage,
                                source: source ?? '',
                                hasInputArg: !!input,
                                errName: error.name,
                                prevOpenTime: prevOpenTime ? Math.round((new Date().valueOf() - prevOpenTime) / 1000) : 0,
                            });
                        }
                        prevOpenTime = new Date().valueOf();
                    }

                    if (!handles?.length) {
                        return;
                    }

                    await onUpload(handles);

                    break;
                }
            }
        },
        [onUpload, onDrop, storage, allowedExtensions]
    );

    const isInputExist = input?.current !== null;

    useEffect(() => {
        // https://legacy.reactjs.org/blog/2020/08/10/react-v17-rc.html#effect-cleanup-timing
        const current = input?.current;

        current?.addEventListener(inputEventToHandle, handlerInputChange);

        return () => {
            current?.removeEventListener(inputEventToHandle, handlerInputChange);
        };
    }, [isInputExist, handlerInputChange]);

    const processDrop = useCallback((data: DataTransfer) => onDrop(null, data, { checkAllowedExtensions: true, isDrop: true }), [onDrop]);

    return { processDrop };
};
