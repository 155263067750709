import './plugin.css';

import type { VideoPlayer } from 'reactApp/ui/ReactViewer/VideoPlayer/VideoPlayer.types';

import { type ConcreteButtonType, ConcreteButton } from './ConcreteButton';
import { type ConcreteMenuItemType, ConcreteMenuItem } from './ConcreteMenuItem';

export type QualityValue = number | 'auto';

export interface HlsQualitySelectorPluginOptions {
    displayCurrentQuality?: boolean;
    startQuality?: QualityValue;
    placementIndex?: number;
    vjsIconClass?: string;
}

export interface VideoPlayerWithHlsQualitySelectorPlugin extends VideoPlayer {
    _hlsQualitySelector: HlsQualitySelectorPlugin;
    hlsQualitySelector: (options?: HlsQualitySelectorPluginOptions) => void;
}

export class HlsQualitySelectorPlugin {
    player: VideoPlayerWithHlsQualitySelectorPlugin;
    config: HlsQualitySelectorPluginOptions;
    _currentQuality: QualityValue;
    _qualityButton: ConcreteButtonType;

    constructor(player: VideoPlayerWithHlsQualitySelectorPlugin, options?: HlsQualitySelectorPluginOptions) {
        this.player = player;
        this.config = options || {};
        this._qualityButton = new ConcreteButton(player, { name: 'QualityButton' });
        this._currentQuality = options?.startQuality || 'auto';

        // Create the quality button.
        this.createQualityButton();
        this.bindPlayerEvents();
    }

    private getQualityButtonText(quality: QualityValue) {
        return quality === 'auto' ? this.player.localize('auto') : `${quality}p`;
    }

    bindPlayerEvents() {
        this.player.qualityLevels().on('addqualitylevel', this.onAddQualityLevel.bind(this));
    }

    createQualityButton() {
        const player = this.player;

        const placementIndex = player.controlBar.children().length - 2;
        const concreteButtonInstance = player.controlBar.addChild(
            this._qualityButton,
            { componentClass: 'qualitySelector' },
            this.config.placementIndex || placementIndex
        );

        concreteButtonInstance.addClass('vjs-quality-selector');
        if (!this.config.displayCurrentQuality) {
            const icon = ` ${this.config.vjsIconClass || 'vjs-icon-hd'}`;

            concreteButtonInstance.menuButton_.$('.vjs-icon-placeholder').className += icon;
        } else {
            this.setButtonInnerText(this.getQualityButtonText('auto'));
        }
        concreteButtonInstance.removeClass('vjs-hidden');
    }

    setButtonInnerText(text: string) {
        if (this._qualityButton) {
            this._qualityButton.menuButton_.$('.vjs-icon-placeholder').textContent = text;
        }
    }

    getQualityMenuItem(item: { label: string; value: QualityValue; selected?: boolean }): ConcreteMenuItemType {
        const player = this.player;

        return new ConcreteMenuItem(player, item, this._qualityButton, this);
    }

    onAddQualityLevel() {
        // Получаем список уровней качества с определенными шириной и высотой в пикселях по возрастанию битрейтов
        // TODO: Возможно нужно будет закэшировать в будущем
        const qualityList = Array.from(this.player.qualityLevels())
            .filter(({ width, height }) => Boolean(width) && Boolean(height))
            .sort((a, b) => a.bitrate - b.bitrate)
            .map((qualityLevel) => {
                /**
                 * Ширина и высота здесь определены, так как мы отфильтровали их выше
                 */
                const width = qualityLevel.width!;
                const height = qualityLevel.height!;
                return {
                    ...qualityLevel,
                    width,
                    height,
                    pixels: width > height ? height : width,
                };
            });

        const levelItems: ConcreteMenuItemType[] = [];

        /**
         * Берем максимальное качество, которое меньше или равно текущему
         *
         * @example
         * Качества = [480, 720, 1080], _currentQuality = 900
         * После редьюса _currentQuality станет 720
         *
         * При _currentQuality = 'auto' редьюсер вернет 'auto'
         */
        this._currentQuality = qualityList.reduce((currentQualityLevel, qualityLevel) => {
            if (this._currentQuality === 'auto') {
                return currentQualityLevel;
            }

            const { pixels } = qualityLevel;
            if (pixels <= this._currentQuality) {
                return pixels;
            }

            return currentQualityLevel;
        }, this._currentQuality);

        for (let i = 0; i < qualityList.length; ++i) {
            const { pixels } = qualityList[i];

            if (
                !levelItems.filter((_existingItem) => {
                    return _existingItem.item && _existingItem.item.value === pixels;
                }).length
            ) {
                const levelItem = this.getQualityMenuItem({
                    label: `${pixels}p`,
                    value: pixels,
                    selected: this._currentQuality === pixels,
                });

                levelItems.push(levelItem);
                qualityList[i].enabled = pixels === this._currentQuality || this._currentQuality === 'auto';
            }
        }

        levelItems.push(
            this.getQualityMenuItem({
                label: this.player.localize('Auto'),
                value: 'auto',
                selected: this._currentQuality === 'auto',
            })
        );

        if (this._qualityButton) {
            this._qualityButton.setItems(levelItems);
            this._qualityButton.update();

            if (this.config.displayCurrentQuality) {
                this.setButtonInnerText(this.getQualityButtonText(this._currentQuality));
            }

            this._qualityButton.unpressButton();
        }
    }

    setQuality(quality: QualityValue) {
        const qualityList = this.player.qualityLevels();

        this.player.trigger('changequality');

        // Set quality on plugin
        this._currentQuality = quality;

        if (this.config.displayCurrentQuality) {
            this.setButtonInnerText(this.getQualityButtonText(quality));
        }

        for (let i = 0; i < qualityList.length; ++i) {
            const { width = 0, height = 0 } = qualityList[i];
            const pixels = width > height ? height : width;

            qualityList[i].enabled = pixels === quality || quality === 'auto';
        }
        this._qualityButton?.unpressButton();
    }

    getCurrentQuality(): string | number {
        return this._currentQuality || 'auto';
    }
}

const onPlayerReady = (player: VideoPlayerWithHlsQualitySelectorPlugin, options?: HlsQualitySelectorPluginOptions) => {
    player.addClass('vjs-hls-quality-selector');
    player._hlsQualitySelector = new HlsQualitySelectorPlugin(player, options);
};

const hlsQualitySelector = function (this: VideoPlayerWithHlsQualitySelectorPlugin, options?: HlsQualitySelectorPluginOptions) {
    this.ready(() => {
        if (!this.qualityLevels || !this.tech({ IWillNotUseThisInPlugins: true })?.vhs) {
            return;
        }
        onPlayerReady(this, options);
    });
};

export default hlsQualitySelector;
