import { ReactComponent as ShapeIcon } from 'img/icons/shape.svg';
import React, { type FC, useRef } from 'react';
import { FaceInfoTooltip } from 'reactApp/ui/ReactViewer/ViewerHeader/FaceInfoTooltip/FaceInfoTooltip';
import { MenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/MenuButton/MenuButton';

interface Props {
    count: number;
    onClick?: () => void;
}

export const FacesMenuButton: FC<Props> = ({ count, onClick }) => {
    const ref = useRef<HTMLDivElement>(null);

    return (
        <div>
            <MenuButton hint="Показать фильтр по лицам" icon={<ShapeIcon />} textOnIcon={`${count}`} onClick={onClick} ref={ref} />
            {ref && <FaceInfoTooltip onApply={onClick} targetRef={ref} />}
        </div>
    );
};
