import { Icon20ErrorCircleOutline } from '@vkontakte/icons';
import { Button, Spacing, Subhead, Text } from '@vkontakte/vkui';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
    DEMO_BUSINESS_TEMPLATES_END_DATE,
    DEMO_BUSINESS_TEMPLATES_PRODUCT_ID,
} from 'reactApp/appHelpers/featuresHelpers/features/businessTemplates';
import { businessTemplatesOpenFC } from 'reactApp/modules/businessTemplates/businessTemplates.actions';
import { useInformerAnalytics } from 'reactApp/sections/BusinessTemplates/BusinessTemplates.helpers';
import { SASS_PAYMENT_FEATURES } from 'reactApp/sections/SassLanding/SassLanding.data';

import styles from './BusinessTemplateInformer.css';

export const BusinessTemplateInformer = () => {
    const dispatch = useDispatch();
    const { clickConnectButton } = useInformerAnalytics();

    const handleConnectClick = useCallback(() => {
        clickConnectButton();
        dispatch(
            businessTemplatesOpenFC({
                hideOtherTariffs: true,
                title: 'Оплата тарифа Mail Space PRO для бизнеса',
                productId: DEMO_BUSINESS_TEMPLATES_PRODUCT_ID,
                paySource: 'cloud_biz_templates',
                hideSwitchSidebarBlock: true,
                featuresList: SASS_PAYMENT_FEATURES,
            })
        );
    }, [clickConnectButton, dispatch]);

    return (
        <div className={styles.demoInformer}>
            <div className={styles.icon}>
                <Icon20ErrorCircleOutline />
            </div>

            <div className={styles.content}>
                <Text className={styles.headline} weight="1">
                    Шаблоны доступны до {DEMO_BUSINESS_TEMPLATES_END_DATE}. Дальше — с подпиской Mail&nbsp;Space&nbsp;PRO
                </Text>
                <Spacing size={4} />
                <Subhead>Подключите, чтобы пользоваться шаблонами и добавить гигабайты</Subhead>
            </div>

            <div className={styles.actions}>
                <Button size={'l'} onClick={handleConnectClick} mode={'primary'} appearance={'accent-invariable'}>
                    Подключить
                </Button>
            </div>
        </div>
    );
};
