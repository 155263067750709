import classNames from 'clsx';
import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ENABLE_FULL_RESPONSIVE, IS_B2B_BIZ_USER, NEW_PORTAL_HEADER } from 'reactApp/appHelpers/configHelpers';
import { AccessStatus, DomainAccess } from 'reactApp/components/SharingNewBiz/SharingNew.types';
import { getPublicSharingInfo } from 'reactApp/modules/public/public.actions';
import { getItemById, isMountedOrSharedFolder } from 'reactApp/modules/storage/storage.selectors';
import type { RootState } from 'reactApp/store';
import { AccessRights } from 'reactApp/types/Tree';
import { sendGa } from 'reactApp/utils/ga';
import { getPublicUrl } from 'reactApp/utils/urlHelper';

import styles from './EmptyFolder.css';
import { useEmptyFolderContent } from './EmptyFolder.hooks';
import { type IProps, EItemType, gaSuffix } from './EmptyFolder.types';

export const EmptyFolder = memo(function EmptyFolder(props: IProps) {
    const { folder } = props;
    const dispatch = useDispatch();
    // eslint-disable @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isAlbum = folder?.type === 'album';
    const publicUrl = getPublicUrl(folder);
    const emptyFolderContent = useEmptyFolderContent(folder?.id || '', publicUrl, isAlbum);
    // @ts-ignore
    const { isReadOnly, parent, weblink, weblinkAccessRights, weblinkDownloadable } = folder;
    const isMounted = useSelector((state: RootState) => isMountedOrSharedFolder(state, folder)).isMounted;
    // @ts-ignore
    const isParentReadOnly = useSelector((state: RootState) => getItemById(state, parent))?.isReadOnly;
    const isPublicReadWrite = weblinkAccessRights === AccessRights.rw;
    const isCascadedReadOnly = isReadOnly || (isMounted && isParentReadOnly);
    const uploadOrReadOnlyStatus = isPublicReadWrite && !isCascadedReadOnly ? AccessStatus.Upload : AccessStatus.ReadOnly;
    const accessRights = weblinkDownloadable ? AccessStatus.Download : uploadOrReadOnlyStatus;
    // eslint-enable @typescript-eslint/ban-ts-comment

    const handleCm = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
    }, []);

    useEffect(() => sendGa(gaSuffix, 'show'), []);

    useEffect(() => {
        if (
            IS_B2B_BIZ_USER &&
            folder &&
            'weblinkDomainAccess' in folder &&
            folder.weblinkDomainAccess === DomainAccess.EXTERNAL &&
            weblink
        ) {
            dispatch(getPublicSharingInfo({ public_id: weblink }));
        }
    }, [folder]);

    return (
        <div
            className={classNames(styles.root, {
                [styles.rootResponsive]: ENABLE_FULL_RESPONSIVE,
                [styles.root_new_portal_header]: NEW_PORTAL_HEADER,
                [styles.rootAlbum]: isAlbum,
            })}
            onContextMenu={handleCm}
        >
            {Object.entries(emptyFolderContent).map((data) => {
                const [type, itemData] = data;
                const item = itemData(accessRights);
                const shared = Boolean(type === EItemType.COSHARING && publicUrl);

                return (
                    <section
                        key={type}
                        role="presentation"
                        onClick={item?.onClick}
                        className={classNames({
                            [styles.item]: true,
                            [styles.itemResponsive]: ENABLE_FULL_RESPONSIVE,
                            [styles[`item_type_${type}`]]: Boolean(type),
                            [styles.item_shared]: shared,
                            [styles.albumItem]: isAlbum,
                        })}
                    >
                        <div className={styles.actionIcon}>{item.Icon}</div>
                        <h1 className={styles.header}>{item.title}</h1>
                        <p className={styles.annotation}>{item.annotation}</p>
                        {item.additionLinks}
                    </section>
                );
            })}
        </div>
    );
});
