import throttle from 'lodash.throttle';
import { type Ref, type RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { VariableSizeList } from 'react-window';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { BREAKPOINT_SM } from 'reactApp/constants/breakpoints';
import { useWindowSize } from 'reactApp/hooks/useWindowSize';
import useResizeObserver from 'use-resize-observer/polyfilled';

export const useWindowResizer = (): [Ref<HTMLDivElement>, number] => {
    const [size, setSize] = useState({ width: window.innerWidth });

    const throttledCalculateSize = useMemo(() => throttle(setSize, 200), []);

    const { ref } = useResizeObserver<HTMLDivElement>({
        onResize: throttledCalculateSize,
    });

    return [ref, size.width];
};

export const useCompactView = (breakPoint = BREAKPOINT_SM) => {
    const { windowWidth } = useWindowSize();

    return ENABLE_FULL_RESPONSIVE && windowWidth && windowWidth < breakPoint;
};

// Для VirtualList нужна высота, внутри которой элементы будут рендериться.
// Не на пабликах должен скролиться window, поэтому подписываемся на скролл и самостоятельно проскраливаем VirtualList
type TScrollerReturnType = [RefObject<VariableSizeList>, RefObject<HTMLDivElement>];

export const useWindowScroller = (customScrollElement?: HTMLDivElement | null): TScrollerReturnType => {
    const ref = useRef<VariableSizeList>(null);
    const outerRef = useRef<HTMLDivElement>(null);
    const scrollElement = customScrollElement || window;

    const handleScroll = useCallback((): void => {
        const offsetTop = outerRef.current?.getBoundingClientRect().top || 0;
        const scrollTop = customScrollElement ? customScrollElement.scrollTop : -offsetTop;
        ref.current?.scrollTo(scrollTop);
    }, [customScrollElement]);

    const throttledHandleScroll = throttle(handleScroll, 100);

    useEffect(() => {
        scrollElement.addEventListener('scroll', throttledHandleScroll);

        return (): void => scrollElement.removeEventListener('scroll', throttledHandleScroll);
    }, [throttledHandleScroll, scrollElement]);

    return [ref, outerRef];
};
