import type { PayloadAction } from '@reduxjs/toolkit';
import { logger } from 'lib/logger';
import { addParams } from 'lib/urlUtils';
import { IS_ANDROID_MOBILE, IS_FAMILY_USER, IS_IOS_MOBILE, IS_WEBVIEW, PROMO_TARIFFS } from 'reactApp/appHelpers/configHelpers';
import {
    isFamilyAfterPurchaseABEnabled,
    isFamilyAfterPurchaseCompact,
} from 'reactApp/appHelpers/featuresHelpers/features/familyAfterPurchase';
import { litresTariffIdsListCommon } from 'reactApp/appHelpers/featuresHelpers/features/litres';
import { abTrialFastCheckout } from 'reactApp/appHelpers/featuresHelpers/features/trialFastchecout';
import { openDisableAdsScreen } from 'reactApp/components/BuyDisableAds/BuyDisableAds';
import { renderBuyModal } from 'reactApp/components/BuyModal/renderBuyModal';
import { openVkBuyModalWithSidebar } from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar.helpers';
import { EPaymentModalType } from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar.types';
import { checkMobileWebviewPayAvailable } from 'reactApp/modules/billing/billing.helpers';
import { CardActions } from 'reactApp/modules/creditCard/creditCard.module';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { familyInitDataSuccess, familyLoadRequest } from 'reactApp/modules/family/family.actions';
import { isUserWithFamily, userCanCreateFamily } from 'reactApp/modules/family/family.selectors';
import { receivedGiftsUpdateSuccess } from 'reactApp/modules/giftReceived/actions/update.action';
import { updateReceivedGiftsAction } from 'reactApp/modules/giftReceived/giftReceived.actions';
import {
    loadDataForSubscriptions,
    loadProductsAndOpenFastCheckout,
    openBuyCheckout,
    openBuyDisableAds,
    openFastCheckout,
    openPromocodeGiftModalAfterBuy,
    openRenewSubscription,
    openTariffBuy,
} from 'reactApp/modules/payment/payment.module';
import type {
    LoadProductsAndOpenFastCheckoutAction,
    OpenBuyCheckoutAction,
    OpenBuyDisableAdsAction,
    OpenFastCheckoutAction,
    OpenPromocodeGiftModalAfterBuyAction,
    OpenTariffBuyAction,
} from 'reactApp/modules/payment/payment.types';
import { openRenewModal } from 'reactApp/modules/payment/sagas/openRenewModal.saga';
import { authPopup } from 'reactApp/modules/ph/ph.thunkActions';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { productsController } from 'reactApp/modules/products/products.controller';
import { AUTOUPLOAD_10TB_REGEX } from 'reactApp/modules/products/products.helpers';
import { updateProducts } from 'reactApp/modules/products/products.module';
import { getProductById, ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { EProductPeriod } from 'reactApp/modules/products/products.types';
import { removePromo } from 'reactApp/modules/promo/promo.module';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { initPromoTariffs } from 'reactApp/modules/promoTariffs/promoTariffs.saga';
import { updatePurchasedGiftsAction } from 'reactApp/modules/purchasedGifts/purchasedGifts.actions';
import { getLastPurchasedGiftById } from 'reactApp/modules/purchasedGifts/purchasedGifts.selectors';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { updateSubscriptionsRequest } from 'reactApp/modules/subscriptions/subscriptions.module';
import { handleSubscriptionsRequest } from 'reactApp/modules/subscriptions/subscriptions.saga';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { updateUser } from 'reactApp/modules/user/user.thunkActions';
import { waitForUserUpdate } from 'reactApp/modules/user/waitForUserUpdate';
import { openAutouploadSuccessDialog } from 'reactApp/sections/QuotaLanding/AutouploadSuccessDialog/AutouploadSuccessDialog.helpers';
import { openLitresSuccessDialog } from 'reactApp/sections/QuotaLanding/LitresSuccessDialog/LitresSuccessDialog.helpers';
import { revealPlatformPopup } from 'reactApp/sections/QuotaLanding/TariffsPlans/TariffPlansCard/TariffPlansCard.helpers';
import { openFamilyAfterPurchaseModal } from 'reactApp/ui/FamilyAfterPurchaseModal/FamilyAfterPurchaseModal.helpers';
import { openTariffBuyDialog } from 'reactApp/ui/TariffBuy/TariffBuy.helpers';
import { callSagaFromAction } from 'reactApp/utils/callSagaFromAction';
import { sendWebviewRadar } from 'reactApp/utils/sendWebviewRadar';
import { channel } from 'redux-saga';
import { call, cancel, put, putResolve, select, take, takeEvery } from 'redux-saga/effects';

function* openFastCheckoutModal(payload: OpenFastCheckoutAction) {
    const {
        productId,
        onShow,
        onSuccess,
        afterUpdate,
        onClose,
        paySource,
        title,
        hideOtherTariffs,
        featuresList,
        hideSwitchSidebarBlock,
        onPayBtnClick,
    } = payload || {};

    const isPayAvailable = checkMobileWebviewPayAvailable();

    if (!isPayAvailable) {
        revealPlatformPopup(productId || '', IS_IOS_MOBILE, IS_ANDROID_MOBILE);
        sendWebviewRadar('fcheckout', 'open', isPayAvailable);
        return;
    }

    if (!productId) {
        yield call(initPromoTariffs, {});
    }

    const tariffData = yield select(ProductsSelectors.getPrimaryProduct, productId);

    const { tariff, product } = tariffData || {};

    if (!product) {
        yield put(
            showSnackbarAction({
                type: SnackbarTypes.failure,
                id: 'file-upload-err',
                text: 'Нет доступных тарифов',
                closable: true,
            })
        );

        onClose?.();
        return;
    }

    const fastCheckoutChannel = channel();
    const isPhone = yield select(EnvironmentSelectors.isPhone);

    if (isPhone) {
        yield openTariffBuyDialog({
            onSuccess: () => fastCheckoutChannel.put(true),
            onClose: () => {
                onClose?.();
                fastCheckoutChannel.close();
            },
            onError: () => {
                onClose?.();
                fastCheckoutChannel.close();
            },
            id: product.id,
            isMobile: true,
            paySource,
            onPayBtnClick,
        });
    } else {
        yield openVkBuyModalWithSidebar({
            onSuccess: () => fastCheckoutChannel.put(true),
            onClose: () => {
                onClose?.();
                fastCheckoutChannel.close();
            },
            onShow,
            productId: product.id,
            tariff,
            type: EPaymentModalType.fastcheckout,
            paySource,
            featuresList,
            title,
            hideOtherTariffs,
            hideSwitchSidebarBlock,
            onPayBtnClick,
        });
    }

    const isSuccess = yield take(fastCheckoutChannel);

    if (isSuccess) {
        /* Убираем промку после успешной оплаты */
        yield put(removePromo(EPromoType.space));

        yield onSuccess?.();
        yield put(updateProducts());
        yield waitForUserUpdate();
        yield afterUpdate?.();
    }
}

function* openPromocodeGiftModalAfterBuySaga(action: PayloadAction<OpenPromocodeGiftModalAfterBuyAction>) {
    const { productId } = action.payload;

    const product = yield select(ProductsSelectors.getProductById, productId);

    if (!product?.gift) {
        return;
    }

    try {
        yield putResolve(yield call(updatePurchasedGiftsAction));
    } catch (_) {
        yield cancel();
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const gift = yield select(getLastPurchasedGiftById, product.gift.id);

    openPopupHelper({
        popupName: popupNames.MOBILE_GIFT_PROMOCODE_DIALOG,
        data: {
            code: gift.code,
            space: gift.space,
            giftId: gift.id,
        },
    });
}

const openFastCheckoutModalFromAction = (action: PayloadAction<OpenFastCheckoutAction>) =>
    callSagaFromAction<OpenFastCheckoutAction>(openFastCheckoutModal, action);

function* openTariffBuyModal(action: PayloadAction<OpenTariffBuyAction>) {
    const {
        productId,
        onSuccess,
        onClose,
        source,
        checkAnonymous,
        isMobile,
        onError,
        isQuotaCleaner,
        paySource,
        onClick,
        subTitle,
        title,
        paymentDescription,
        enabledMidasPayment,
    } = action.payload;

    const product = yield select(ProductsSelectors.getProductById, productId);

    const isAnonymous = yield select(UserSelectors.isAnonymous);

    if (checkAnonymous && isAnonymous) {
        yield put(
            authPopup({
                closable: true,
                loginRequest: true,
                successPage: addParams(window.location.href, {
                    action: 'request-payment',
                    productId,
                }),
            })
        );

        yield cancel();
    }

    const paymentChannel = channel();
    // tempexp_16481-next-line
    const closeChannel = channel();

    if (product?.hasTrial && !isMobile) {
        // tempexp_18329-next-line
        if (abTrialFastCheckout === 'b') {
            yield openVkBuyModalWithSidebar({
                onSuccess: () => {
                    paymentChannel.put(true);
                },
                onClose: () => {
                    onClose?.();
                    paymentChannel.close();
                    // tempexp_17509-start
                    closeChannel.put(true);
                    // tempexp_17509-end
                },
                productId: product.id,
                type: EPaymentModalType.fastcheckout,
                paySource,
                title,
                hideSwitchSidebarBlock: true,
            });
        } else {
            yield call(renderBuyModal, {
                id: productId,
                onSuccess: () => paymentChannel.put(true),
                onClose: () => {
                    onClose?.();
                    paymentChannel.close();
                    // tempexp_17509-start
                    closeChannel.put(true);
                    // tempexp_17509-end
                },
                source,
                paySource,
            });
        }
    } else {
        yield openTariffBuyDialog({
            source,
            isMobile,
            onSuccess: () => paymentChannel.put(true),
            onClose: () => {
                onClose?.();
                paymentChannel.close();
                // tempexp_16481-next-line
                closeChannel.put(true);
            },
            onError: () => {
                onError?.();
            },
            id: productId,
            isQuotaCleaner,
            paySource,
            onClick: () => {
                onClick?.();
            },
            subTitle,
            enabledMidasPayment,
            title,
            paymentDescription,
        });
    }

    const isSuccess = yield take(paymentChannel);

    if (isSuccess) {
        try {
            yield onSuccess?.();
        } catch (error) {
            logger.error(error);
        }

        // tempexp_16481-start
        if (AUTOUPLOAD_10TB_REGEX.test(product?.id)) {
            yield put(updateReceivedGiftsAction());
        }
        // tempexp_16481-end
        yield put(updateProducts());
        yield put(CardActions.updateCard({ isSilent: true, delay: 1000 }));
        yield put(updateSubscriptionsRequest());
        // tempexp_16481-start
        if (!AUTOUPLOAD_10TB_REGEX.test(product?.id) && !isFamilyAfterPurchaseABEnabled) {
            yield put(updateUser());
        }
        // tempexp_16481-end

        yield put(
            showSnackbarAction({
                type: SnackbarTypes.success,
                id: 'buy',
                text: 'Подписка успешно оформлена',
                closable: true,
            })
        );

        // tempexp_16481-start
        if (AUTOUPLOAD_10TB_REGEX.test(product?.id)) {
            yield take(closeChannel);
            if (!IS_WEBVIEW) {
                yield openAutouploadSuccessDialog();
            }
            yield take(receivedGiftsUpdateSuccess);
            yield put(updateUser());
        }
        // tempexp_16481-end

        // tempexp_17509-start
        if (litresTariffIdsListCommon.includes(product?.id)) {
            yield take(closeChannel);
            yield openLitresSuccessDialog();
            yield put(updateUser());
        }
        // tempexp_17509-end

        // tempexp_18014-start
        if (isFamilyAfterPurchaseABEnabled) {
            yield putResolve(yield call(updateUser));
            yield take(closeChannel);
            const withFamily = yield select(isUserWithFamily);
            if (!IS_FAMILY_USER && !withFamily) {
                yield put(familyLoadRequest({}));
                yield take(familyInitDataSuccess);
                const canCreateFamily = yield select(userCanCreateFamily);
                if (canCreateFamily) {
                    yield openFamilyAfterPurchaseModal({
                        compact: isFamilyAfterPurchaseCompact,
                    });
                }
            }
        }
        // tempexp_18014-end
    }
}

function* loadDataForSubscriptionsSaga() {
    yield call(handleSubscriptionsRequest);
    yield call(initPromoTariffs, {});
    yield put(CardActions.loadCard());
}

function* loadProductsAndOpenFastCheckoutSaga(action: PayloadAction<LoadProductsAndOpenFastCheckoutAction>) {
    const { quota, productId, onSuccess, paySource, title, hideOtherTariffs } = action.payload;

    yield productsController.loadProducts();

    const product = yield select(getProductById, productId);
    const { product: productByQuota } = yield select(ProductsSelectors.getProductAndTariffByQuota, quota, EProductPeriod.year, false);

    yield openFastCheckoutModal({
        productId: productId ? product?.id : productByQuota?.id,
        onSuccess,
        paySource,
        title,
        hideOtherTariffs,
    });
}

function* openBuyCheckoutModal(action: PayloadAction<OpenBuyCheckoutAction>) {
    yield productsController.loadProducts();

    const suggestedProductId = action.payload.productId || PROMO_TARIFFS?.default64GbTrial;
    const { product } = yield select(ProductsSelectors.getBuyFrameProduct, suggestedProductId);

    yield openFastCheckoutModal({
        ...action.payload,
        productId: product.id,
    });
}

function* openBuyDisableAdsModal(action: PayloadAction<OpenBuyDisableAdsAction>) {
    yield productsController.loadProducts();

    yield openDisableAdsScreen({
        ...action.payload,
    });
}

export function* watchPayment() {
    yield takeEvery(openTariffBuy.toString(), openTariffBuyModal);
    yield takeEvery(openFastCheckout.toString(), openFastCheckoutModalFromAction);
    yield takeEvery(openPromocodeGiftModalAfterBuy.toString(), openPromocodeGiftModalAfterBuySaga);
    yield takeEvery(loadDataForSubscriptions.toString(), loadDataForSubscriptionsSaga);
    yield takeEvery(loadProductsAndOpenFastCheckout.toString(), loadProductsAndOpenFastCheckoutSaga);
    yield takeEvery(openBuyCheckout.toString(), openBuyCheckoutModal);
    yield takeEvery(openBuyDisableAds.toString(), openBuyDisableAdsModal);
    yield takeEvery(openRenewSubscription.toString(), openRenewModal);
}
