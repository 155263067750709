import { Subhead, Title } from '@vkontakte/vkui';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { openAnyFileEditorIfCan } from 'reactApp/appHelpers/editorHelpers';
import { DEMO_BUSINESS_TEMPLATES_ENABLED } from 'reactApp/appHelpers/featuresHelpers/features/businessTemplates';
import { businessTemplatesCreateFileFromTemplateAction } from 'reactApp/modules/businessTemplates/businessTemplates.actions';
import {
    selectAllBusinessTemplates,
    selectBusinessTemplatesByCategoryId,
    selectBusinessTemplatesCopyLoadingState,
    selectBusinessTemplatesListLoadingState,
} from 'reactApp/modules/businessTemplates/businessTemplates.selectors';
import type { BusinessTemplateItem, CreateFromTemplateRejectionReason } from 'reactApp/modules/businessTemplates/businessTemplates.types';
import { snackbarController } from 'reactApp/modules/snackbar/snackbar.controller';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { BusinessTemplateInformer } from 'reactApp/sections/BusinessTemplates/BusinessTemplateInformer/BusinessTemplateInformer';
import { type RootState, store as reduxStore } from 'reactApp/store';
import { openDisabledFeaturePopupHelper } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.helpers';
import { DisabledFeature } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.types';
import { Loader } from 'reactApp/ui/Loader/Loader';

import { BusinessTemplateCard } from './BusinessTemplateCard/BusinessTemplateCard';
import styles from './BusinessTemplates.css';
import { useAnalytics } from './BusinessTemplates.helpers';

const SNACKBAR_ID = 'saved-template' as const;

const BusinessTemplatesList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { templatesCategory } = useParams();

    const category = useSelector((state: RootState) => selectBusinessTemplatesByCategoryId(state, templatesCategory));
    const allTemplates = useSelector(selectAllBusinessTemplates);

    const { sendFileCreated } = useAnalytics(templatesCategory ? `templates/${templatesCategory}` : 'tempaltes/root');

    const copyFile = useCallback(
        (template: BusinessTemplateItem) =>
            new Promise<string>((resolve, reject) =>
                dispatch(businessTemplatesCreateFileFromTemplateAction({ template, onSuccess: resolve, onError: reject }))
            ),
        [dispatch]
    );

    const createFileFromTemplate = useCallback(
        async (template: BusinessTemplateItem, ext: string) => {
            const copyLoadingState = selectBusinessTemplatesCopyLoadingState(reduxStore.getState());

            if (copyLoadingState === 'pending') {
                return;
            }

            try {
                snackbarController.showSnackbar({
                    id: SNACKBAR_ID,
                    text: 'Создаём файл из шаблона',
                    closable: true,
                    type: SnackbarTypes.loading,
                });
                const path = await copyFile(template);
                const cloudItem = { id: path, ext, size: template.size, storage: EStorageType.home };
                openAnyFileEditorIfCan(cloudItem);
                snackbarController.showSnackbar({
                    id: SNACKBAR_ID,
                    text: 'Файл создан в Облаке',
                    closable: true,
                    type: SnackbarTypes.success,
                    buttonText: 'Открыть',
                    onButtonClick: () => navigate(`/home${path}`),
                });
                sendFileCreated(template, ext);
            } catch (error) {
                const reason = error as CreateFromTemplateRejectionReason;
                switch (reason) {
                    case 'quota':
                        return openDisabledFeaturePopupHelper({ disabledFeature: DisabledFeature.newFile });
                    case 'unk':
                    default:
                        snackbarController.showSnackbar({
                            id: SNACKBAR_ID,
                            text: 'Не удалось создать файл в Облаке',
                            closable: true,
                            type: SnackbarTypes.failure,
                        });
                }
            }
        },
        [copyFile, navigate, sendFileCreated]
    );

    return (
        <div className={styles.root}>
            <div className={styles.head}>
                <Title level="1" data-qa-id="business-templates-title">
                    {category?.name || 'Бизнесу'}
                </Title>
                <Subhead className={styles.subhead} data-qa-id="business-templates-subhead">
                    Используйте готовые шаблоны документов
                </Subhead>
            </div>

            {DEMO_BUSINESS_TEMPLATES_ENABLED && <BusinessTemplateInformer />}

            <div className={styles.main}>
                {(category?.templates ?? allTemplates).map((template) => (
                    <BusinessTemplateCard key={template.hash} template={template} onClick={createFileFromTemplate} />
                ))}
            </div>
        </div>
    );
};

const BusinessTemplatesInner = () => {
    const { templatesCategory } = useParams();

    const listLoadingState = useSelector(selectBusinessTemplatesListLoadingState);
    const category = useSelector((state: RootState) => selectBusinessTemplatesByCategoryId(state, templatesCategory));
    const allTemplates = useSelector(selectAllBusinessTemplates);

    // Данных пока нет - показываем крутилочку
    if (listLoadingState === 'idle' || listLoadingState === 'pending') {
        return <Loader centered />;
    }

    // Если данные подгрузить не удалось - ливаем в хоум
    if (listLoadingState === 'rejected') {
        return <Navigate to="/home" />;
    }

    // Если данные загрузились, но там пусто - ливаем в хоум
    if (!allTemplates.length) {
        return <Navigate to="/home" />;
    }

    // Если оказались на несуществующей категории - ливаем в рут
    if (templatesCategory && !category) {
        return <Navigate to="/templates" />;
    }

    return <BusinessTemplatesList />;
};

export const BusinessTemplates = React.memo(BusinessTemplatesInner);
