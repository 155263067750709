import type { ReactElement } from 'react';
import type { IPromoQuotaSubscription } from 'reactApp/modules/promoTariffs/promoTariffs.types';
import {
    type IBaseSubscription,
    type ISubscription,
    type Partner,
    ESubscriptionsTypes,
} from 'reactApp/modules/subscriptions/subscriptions.types';
import type { Product } from 'reactApp/types/Billing';

export type TSubscriptionItem = ISubscription | IBaseSubscription | IPromoQuotaSubscription;

export interface IProps {
    subscription: ISubscription | IBaseSubscription;
    onClick: (subscriptionId: string) => void;
}

export interface IContent {
    header: ReactElement;
    warning: string;
}

export interface ISubscriptionModalContent {
    subscription: TSubscriptionItem;
    product: Product | undefined;
    partner?: Partner;
}

export const SubscriptionMobileName: Partial<Record<ESubscriptionsTypes, string>> = {
    [ESubscriptionsTypes.ios]: 'AppStore',
    [ESubscriptionsTypes.android]: 'Google Play',
    [ESubscriptionsTypes.rustore]: 'RuStore',
};
