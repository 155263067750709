import React, { type FC } from 'react';
import { IS_B2B_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import type { AttachesItem } from 'reactApp/modules/attaches/attaches.types';
import type { CloudFile } from 'reactApp/modules/storage/storage.types';
import { CloneMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/CloneMenuButton/CloneMenuButton';
import { CloseMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/CloseMenuButton/CloseMenuButton';
import { DownloadMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/DownloadMenuButton/DownloadMenuButton';
import { MenuInfo } from 'reactApp/ui/ReactViewer/ViewerMenu/MenuInfo/MenuInfo';
import { MenuLogo } from 'reactApp/ui/ReactViewer/ViewerMenu/MenuLogo/MenuLogo';
import { ShareMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/ShareMenuButton/ShareMenuButton';
import {
    type ViewerMenuProps,
    ViewerMenuCenter,
    ViewerMenuEnd,
    ViewerMenuStart,
    ViewerMenuWrapper,
} from 'reactApp/ui/ReactViewer/ViewerMenu/ViewerMenu';
import { Zoomer } from 'reactApp/ui/ReactViewer/ViewerMenu/Zoomer/Zoomer';
import { canCloneWeblink } from 'reactApp/utils/cloneHelpers';

interface Props extends ViewerMenuProps {
    file: CloudFile | AttachesItem;
}

export const StockMenu: FC<Props> = ({
    file,
    fileStorage,
    showLogo,
    isAuthorized,
    isArchiveItem,
    zoom,
    decreaseZoom,
    increaseZoom,
    onPublish,
    onClone,
    showDownload,
    onDownload,
    showClose,
    onClose,
}) => {
    return (
        <ViewerMenuWrapper>
            <ViewerMenuStart>
                {showLogo && IS_B2B_BIZ_USER && <MenuLogo />}
                <MenuInfo file={file} />
            </ViewerMenuStart>
            <ViewerMenuCenter>{zoom && <Zoomer value={zoom} decrease={decreaseZoom} increase={increaseZoom} />}</ViewerMenuCenter>
            <ViewerMenuEnd>
                {!isArchiveItem && (
                    <>
                        <ShareMenuButton
                            onClick={() => {
                                onPublish(file);
                            }}
                        />
                        {canCloneWeblink(isAuthorized, fileStorage, true) && <CloneMenuButton onClick={onClone} />}
                    </>
                )}
                {showDownload && (
                    <DownloadMenuButton
                        file={file}
                        onClick={() => {
                            onDownload(file);
                        }}
                    />
                )}
                {showClose && (
                    <CloseMenuButton
                        onClick={() => {
                            onClose(file);
                        }}
                    />
                )}
            </ViewerMenuEnd>
        </ViewerMenuWrapper>
    );
};
