import { isAvailableUrl } from 'Cloud/Application/DownloadAsJpeg.helpers';
import { getFeatureParam } from 'Cloud/Application/FeaturesEs6';
import config from 'Cloud/config';
import { extInfo } from 'lib/extInfo';
import { IS_MOBILE_BROWSER, IS_PUBLIC } from 'reactApp/appHelpers/configHelpers';
import { isHiDPI } from 'reactApp/appHelpers/settingsHelpers';
import { Dispatcher } from 'reactApp/modules/dispatcher/dispatcher';
import { isNewVideoStreamUrl, isUploadResumeAvailable } from 'reactApp/modules/features/features.helpers';
import { pathJoin } from 'reactApp/utils/helpers';

import { encodeId } from './encodeId';

export const thumbsSize = {
    '26x26': {
        size: 'w1',
        sizeByPath: 'xw11',
    },
    '52x52': {
        size: 'w2',
        sizeByPath: 'xw12',
    },
    '160x120': {
        size: 'w0',
        sizeByPath: 'xw10',
    },
    '170x162': {
        size: 'w3',
        sizeByPath: 'xw20',
    },
    '36x48': {
        size: 'w22',
        sizeByPath: 'xw22',
    },
    '72x96': {
        size: 'w23',
        sizeByPath: 'xw23',
    },
    '168x152': {
        size: 'w24',
        sizeByPath: 'xw24',
    },
    '336x304': {
        size: 'w25',
        sizeByPath: 'xw25',
    },
    '320x240': {
        size: 'w3',
        sizeByPath: 'xw13',
    },
    '320x240crop': {
        size: 'w6',
        sizeByPath: 'xw16',
    },
    '270x365': {
        size: 'xw26',
        sizeByPath: 'xw26',
    },
    '540x730': {
        size: 'xw27',
        sizeByPath: 'xw27',
    },
    '1000x1000': {
        size: 'xw2', // xw2 = "s1000x1000q85EI"
        sizeByPath: 'xw2',
        isBig: true,
    },
    '1200x1200': {
        size: 'xw0',
        sizeByPath: 'xw0',
        isBig: true,
    },
    original: {
        size: 'xw1',
        sizeByPath: 'xw1',
        isBig: true,
    },
};

/**
 * xw10 = "s160x120q85cE"
 xw11 = "s26x26q85cES"
 xw12 = "s52x52q85E"
 xw13 = "s320x240q85EI"
 xw14 = "s160x120q85E"
 xw15 = "s206x154q85E"
 xw16 = "s320x240q85cEI"
 xw17 = "s160x120q85cE"
 xw18 = "s305x230q85cE"
 xw19 = "s206x206q85cE"
 xw22 = "s36x48q85EI" - thumb для вида списком
 xw23 = "s72x96q85EI" - thumb для вида списком для ретины
 xw24 = "s168x152q85EI" - thumb для вида плиткой
 xw25 = "s336x304q85EI" - thumb для вида плиткой для ретины
 xw26 = "s270x365q85EI" - thumb для вертикальной картинки в галерее
 xw27 = "s540x730q85EI" - thumb для вертикальной картинки в галерее для ретины
 */

const uploadResumableUrl = isUploadResumeAvailable && getFeatureParam('upload-resumable', 'url');

export class UrlBuilder {
    private dispatcher: any;

    constructor() {
        this.dispatcher = new Dispatcher();
    }

    getUrl(name) {
        return this.dispatcher.get(name);
    }

    // TODO Покрыть метод тестами.
    getThumbUrl({ code, ext, name, hash, id, path, dwl_token, isBig, isPublic, isStock }: any) {
        let resultPath;
        if (isStock) {
            return pathJoin(this.getUrl('stock_thumbs'), code, `${dwl_token}/${name}`);
        }
        resultPath = this.getUrl(isPublic ? 'weblink_thumbnails' : 'thumbnails');

        if (isBig && id) {
            const extEncode = encodeId(ext);
            const idHasEtx = extEncode && id.lastIndexOf(extEncode) === id.length - extEncode.length;

            if (idHasEtx) {
                resultPath = pathJoin(resultPath, code, id);
            } else {
                resultPath = pathJoin(resultPath, code, id, encodeURIComponent(name));
            }
        } else if (path) {
            resultPath = pathJoin(resultPath, code, encodeId(path));
        } else {
            resultPath = pathJoin(resultPath, code, `${hash}.${ext}`);
        }

        return resultPath;
    }

    // TODO Покрыть метод тестами.
    getThumb({ hash = '', ext, isPublic, id = null, weblink, size, kind, isStock = false, dwl_token = null, name, path = null }: any) {
        if (!size || size < config.get('ITEM_WITHOUT_HASH_MAX_SIZE')) {
            return;
        }
        const fileId = isPublic ? encodeId(weblink) : id;
        const extParams = extInfo.get(ext);
        const maxSize = extParams.maxThumbnailableSize;
        const thumbnails: Record<string, string> = {};

        if (!maxSize || size > maxSize) {
            return;
        }

        const prefix = kind === 'video' ? 'v' : '';
        const sizePath = path ? 'sizeByPath' : 'size';

        if (isStock) {
            for (const size in thumbsSize) {
                if (thumbsSize.hasOwnProperty(size)) {
                    thumbnails[size] = this.getThumbUrl({
                        dwl_token,
                        name,
                        ext,
                        isStock: true,
                        code: `${prefix}${thumbsSize[size][sizePath]}`,
                    });
                }
            }

            return thumbnails;
        }

        for (const size in thumbsSize) {
            if (thumbsSize.hasOwnProperty(size)) {
                thumbnails[size] = this.getThumbUrl({
                    isPublic,
                    id: fileId,
                    path,
                    ext,
                    hash,
                    name,
                    isBig: !!thumbsSize[(size as any).isBig],
                    code: `${prefix}${thumbsSize[size][sizePath]}`,
                });
            }
        }

        if (extParams.showThumbnailInList) {
            // PIC (Mini thumb)
            const mobileSize = isHiDPI ? '160x120' : '52x52';
            const size = IS_MOBILE_BROWSER ? mobileSize : '72x96';
            thumbnails['pic'] = thumbnails[size];
        }

        return thumbnails;
    }

    getUrls({ ext, isPublic, id = null, weblink, kind, subKind, isStock = false, dwl_token = null, name, path = null }: any) {
        id = encodeId(isPublic ? weblink : id);

        const url: any = {};

        url.get = this.builderGet({ id: id || path, isPublic, isStock, dwl_token, name });
        url.view = this.builderView({ id: id || path, isPublic, isStock, dwl_token, name });

        if (subKind === 'video' || subKind === 'audio') {
            url.media = this.builderPlaylist({ id: id || path, isPublic, isStock, dwl_token, isAudio: subKind === 'audio' });
        } else if (kind === 'document') {
            url.viewDirect = this.builderViewDirect({ id: id || path, isPublic });
        } else if (isAvailableUrl(ext)) {
            url.download = this.builderDownload({ id: id || path, isStock, isPublic, weblink, name, ext, dwl_token, suffix: '.jpeg' });
        }

        return url;
    }

    builderGet = ({ id, isPublic, isStock, dwl_token, name }) => {
        if (isStock) {
            return pathJoin(this.getUrl('stock_get'), dwl_token, encodeURIComponent(name));
        } else if (isPublic) {
            return pathJoin(this.getUrl('weblink_get'), id);
        }

        return pathJoin(this.getUrl('get'), id);
    };

    builderView = ({ id, isPublic, isStock, dwl_token, name }) => {
        if (isStock) {
            return pathJoin(this.getUrl('stock_view'), dwl_token, encodeURIComponent(name));
        } else if (isPublic) {
            return pathJoin(this.getUrl('weblink_view'), id);
        }

        return pathJoin(this.getUrl('view'), id);
    };

    builderPlaylist = ({ id, isPublic, isStock, dwl_token, isAudio }) => {
        let url;

        if (isStock) {
            url = `${pathJoin(this.getUrl('stock_video'), dwl_token)}.m3u8`;
        } else {
            id = btoa(id);

            if (isPublic) {
                url = `${pathJoin(this.getUrl(isNewVideoStreamUrl ? 'videowl_view' : 'weblink_video'), id)}.m3u8?double_encode=1`;
            } else {
                url = `${pathJoin(this.getUrl('video'), id)}.m3u8?double_encode=1`;
            }
        }

        return isAudio ? url.replace(/(video|videowl\/view\/.+\/g\/.+)\/0p/, '$1/audio') : url;
    };

    builderViewDirect = ({ id, isPublic, isStock, dwl_token, name }: any) => {
        if (isStock) {
            return pathJoin(this.getUrl('stock_view'), dwl_token, encodeURIComponent(name));
        } else if (isPublic) {
            return pathJoin(this.getUrl('weblink_view'), id);
        }

        return pathJoin(this.getUrl('view_direct'), id);
    };

    builderDownload = ({ id, isPublic, isStock, weblink, name, ext, dwl_token, suffix = '' }) => {
        if (isStock) {
            return pathJoin(this.getUrl('stock_thumbs'), 'download', 'xw1', `${dwl_token}/${name}${suffix}`);
        }

        id = isPublic ? encodeId(weblink) : id;

        const path = this.getUrl(isPublic ? 'weblink_thumbnails' : 'thumbnails');

        ext = encodeId(ext);
        const idHasExt = ext && id.lastIndexOf(ext) === id.length - ext.length;

        const itemId = idHasExt ? id + suffix : id;

        if (idHasExt) {
            return pathJoin(path, 'download', 'xw1', itemId);
        }

        return pathJoin(path, 'download', 'xw1', itemId, encodeURIComponent(name + suffix));
    };

    upload(noResumableApi = false) {
        if (!noResumableApi && isUploadResumeAvailable) {
            const backendUploadResumableUrl = this.getUrl('upload_resumable');

            return `${backendUploadResumableUrl || uploadResumableUrl}[HASH]`; // + '?cloud_domain=2'
        }

        return `${this.getUrl(IS_PUBLIC ? 'public_upload' : 'upload')}?cloud_domain=2`;
    }
}
