import classNames from 'clsx';
import React, { type ReactElement, memo } from 'react';
import { Switch } from 'reactApp/ui/Switch/Switch';
import { getPeriodName } from 'reactApp/utils/Period';

import styles from './FastCheckoutSidebar.css';

interface ProductSwitchProps {
    productId: string;
    isYear?: boolean;
    monthProductPeriod: string;
    onSwitchChange: () => void;
}

export const ProductSwitch = memo(({ productId, isYear, monthProductPeriod, onSwitchChange }: ProductSwitchProps): ReactElement => {
    return (
        <div className={styles.switch} onClick={onSwitchChange}>
            <div
                className={classNames({
                    [styles.switchLabel]: true,
                    [styles.switchLabel_active]: isYear,
                })}
            >
                На год
            </div>

            <Switch isAlwaysWhite name={`tariff_${productId}`} checked={!isYear} theme="big" />

            <div
                className={classNames({
                    [styles.switchLabel]: true,
                    [styles.switchLabel_right]: true,
                    [styles.switchLabel_active]: !isYear,
                })}
            >
                На {getPeriodName(monthProductPeriod)}
            </div>
        </div>
    );
});

ProductSwitch.displayName = 'ProductSwitch';
