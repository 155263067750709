import { ReactComponent as Logo } from 'img/cloud-logo-20.svg';
import React, { memo, useCallback } from 'react';

import styles from './TreePromoDesktopApp.css';

const desktopAppLandingUrl = 'https://cloud.mail.ru/promo/desktop';

interface IProps {
    onClose?: () => void;

    onClick(): void;
}

export const TreePromoDesktopApp = memo<IProps>(({ onClick }) => {
    const handleDeskAppClick = useCallback(() => {
        onClick();

        open(desktopAppLandingUrl, '_blank');
    }, [onClick]);

    return (
        <div className={styles.root} onClick={handleDeskAppClick}>
            <Logo className={styles.logo} />
            <span className={styles.text}>Установить приложение</span>
        </div>
    );
});

TreePromoDesktopApp.displayName = 'TreePromoMobileApp';
