/* eslint-disable simple-import-sort/imports */
import { watchAlbums } from 'reactApp/modules/albums/albums.saga';
import { watchAttchesRoot } from 'reactApp/modules/attaches/attaches.saga';
import { watchContacts } from 'reactApp/modules/contacts/contacts.saga';
import { watchFaces } from 'reactApp/modules/faces/faces.saga';
import { watchFeed } from 'reactApp/modules/feed/feed.saga';
import { watchFileHistory } from 'reactApp/modules/fileHistory/fileHistory.saga';
import { watchAccessControlList } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.saga';
import { watchGallery } from 'reactApp/modules/gallery/gallery.saga';
import { watchModifying } from 'reactApp/modules/modifying/modifying.saga';
import { watchLoadDocuments } from 'reactApp/modules/personalDocuments/personalDocuments.saga';
import { promoRoot } from 'reactApp/modules/promo/promo.saga';
import { watchPublic } from 'reactApp/modules/public/public.saga';
import { watchSearch } from 'reactApp/modules/search/search.saga';
import { watchShared } from 'reactApp/modules/shared/shared.saga';
import { watchSharedAutoDelete } from 'reactApp/modules/sharedAutoDelete/sharedAutoDelete.saga';
import { watchSmsSender } from 'reactApp/modules/smsSender/smsSender.saga';
import { watchSortRoot } from 'reactApp/modules/sort/sort.saga';
import { watchLoadStart } from 'reactApp/modules/start/start.saga';
import { watchStock } from 'reactApp/modules/stock/stock.saga';
import { watchStoriesRoot } from 'reactApp/modules/stories/stories.saga';
import { watchTrashbin } from 'reactApp/modules/trashbin/trashbin.saga';
import { watchUploaderRoot } from 'reactApp/modules/upload/upload.saga';
import { watchContinueUploadRoot } from 'reactApp/modules/uploadList/continueUpload/continueUpload.saga';
import { watchUploadListRoot } from 'reactApp/modules/uploadList/uploadList.saga';
import { watchViewerRoot } from 'reactApp/modules/viewer/viewer.saga';
import { watchUserQuotaCleaner } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.saga';
import { all, fork } from 'redux-saga/effects';
import { watchIncoming } from 'reactApp/modules/incoming/incoming.saga';
import { watchFilesFilter } from 'reactApp/modules/filesFilter/filesFilter.saga';
import { watchSelections } from 'reactApp/modules/selections/selection.saga';
import { watchEmbedded } from 'reactApp/modules/embedded/embedded.saga';
import { watchUploadingRoot } from 'reactApp/modules/uploading/uploading.saga';
import { watchAllDocuments } from 'reactApp/modules/allDocuments/allDocuments.saga';
import { watchIntegration } from 'reactApp/modules/integration/integration.saga';
import { watchFamily } from 'reactApp/modules/family/family.saga';
import { watchCopyOfNoneEditableFile } from 'reactApp/components/CreateCopyOfNoneditableFileModal/useCreateCopyOfFile.saga';
import { watchLlmDialog } from 'reactApp/modules/editor/sagas/llmDialog.saga';
import { watchEbook } from 'reactApp/modules/ebook/ebook.saga';
import { watchActivities } from 'reactApp/modules/activities/activities.saga';
import { watchWeblinkAccessControl } from './modules/weblinkAccessControl/weblinkAccessControl.saga';
import { watchLoadAllBusinessTemplates } from './modules/businessTemplates/businessTemplates.saga';
import { watchThemeOutsource } from 'reactApp/modules/theme.outsource/theme.outsource.saga';
import { watchThemePublic } from 'reactApp/modules/themePublic/themePublic.saga';
import { watchEditor } from 'reactApp/modules/editor/editor.saga';
import { commonQuotaSagas } from 'reactApp/commonQuotaSagas';
import { watchAdv } from 'reactApp/modules/adv/adv.saga';
import { watchDomainFolders } from 'reactApp/modules/domainFolders/domainFolders.saga';
import { watchWelcomeRoot } from 'reactApp/modules/welcome/welcome.saga';
import { watchIncomingPublic } from './modules/incomingPublic/incomingPublic.saga';

export function* rootSaga(): any {
    yield all([
        fork(commonQuotaSagas),
        fork(watchPublic),
        fork(watchStock),
        fork(watchSearch),
        fork(watchTrashbin),
        fork(watchFilesFilter),
        fork(watchFeed),
        fork(watchWelcomeRoot),
        fork(watchModifying),
        fork(watchAttchesRoot),
        fork(watchLoadStart),
        fork(watchViewerRoot),
        fork(watchUploaderRoot),
        fork(watchUploadListRoot),
        fork(watchContinueUploadRoot),
        fork(watchContacts),
        fork(watchGallery),
        fork(watchSharedAutoDelete),
        fork(watchShared),
        fork(watchLoadDocuments),
        fork(watchFileHistory),
        fork(watchSmsSender),
        fork(promoRoot),
        fork(watchAccessControlList),
        fork(watchStoriesRoot),
        fork(watchAlbums),
        fork(watchSortRoot),
        fork(watchFaces),
        fork(watchIncoming),
        fork(watchUserQuotaCleaner),
        fork(watchSelections),
        fork(watchEmbedded),
        fork(watchUploadingRoot),
        fork(watchAllDocuments),
        fork(watchIntegration),
        fork(watchFamily),
        fork(watchAdv),
        fork(watchDomainFolders),
        fork(watchCopyOfNoneEditableFile),
        fork(watchLlmDialog),
        fork(watchEbook),
        fork(watchActivities),
        fork(watchWeblinkAccessControl),
        fork(watchLoadAllBusinessTemplates),
        fork(watchThemeOutsource),
        fork(watchThemePublic),
        fork(watchEditor),
        fork(watchIncomingPublic),
    ]);
}
