import { Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IS_MBIZUSER, IS_PAID_USER } from 'reactApp/appHelpers/configHelpers';
import { DEMO_PROMO_VARIANT } from 'reactApp/appHelpers/featuresHelpers/features/demoPromo';
import { selectAllBusinessTemplates } from 'reactApp/modules/businessTemplates/businessTemplates.selectors';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { type DemoPromoTooltipData, EPromoType } from 'reactApp/modules/promo/promo.types';
import { sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './DemoPromo.css';
import { DemoPromoTooltip } from './DemoPromoTooltip/DemoPromoTooltip';

type DemoPromoCfg = {
    onClick: () => void;
    text: string;
    icon: React.ReactNode;
    tooltipData: DemoPromoTooltipData | null;
};

const useDemoPromoCfg = () => {
    const tooltipData = useSelector(PromoSelectors.pullPromo(EPromoType.demoPromoTooltip)) || null;
    const navigate = useNavigate();
    const businessTemplatesCategories = useSelector(selectAllBusinessTemplates);

    return useMemo<DemoPromoCfg | null>(() => {
        // eslint-disable-next-line sonarjs/no-small-switch
        switch (DEMO_PROMO_VARIANT) {
            case 'business-templates':
                // Не показыаем промо если нет шаблонов, которые промоутим
                if (!businessTemplatesCategories.length) {
                    return null;
                }

                return {
                    text: 'Шаблоны для бизнеса',
                    onClick: () => {
                        navigate('/templates');
                        sendPaymentGa({
                            eventCategory: 'click_go_to_biz_template',
                            is_mailspace_pro: IS_MBIZUSER,
                            is_mailspace: IS_PAID_USER,
                        });
                        tooltipData?.onClose();
                    },
                    icon: (
                        <div className={styles.businessTemplatesIcon}>
                            <div className={styles.businessTemplatesIconBackdrop}>
                                <img src="https://imgs2.imgsmail.ru/static/cloud/business-templates/emoji_doc.png" />
                            </div>
                        </div>
                    ),
                    tooltipData,
                };
            default:
                return null;
        }
    }, [businessTemplatesCategories.length, navigate, tooltipData]);
};

const DemoPromoInner = () => {
    const rootRef = useRef<HTMLDivElement>(null);
    const cfg = useDemoPromoCfg();

    if (!cfg) {
        return null;
    }

    const { onClick, text, icon, tooltipData } = cfg;

    return (
        <div className={styles.root} ref={rootRef}>
            <div className={styles.wrapper} onClick={onClick}>
                <Text className={styles.text}>{text}</Text>
                <div className={classNames(styles.iconWrapper)}>{icon}</div>
            </div>
            {tooltipData && <DemoPromoTooltip {...tooltipData} target={rootRef} />}
        </div>
    );
};

export const DemoPromo = React.memo(React.forwardRef(DemoPromoInner));
