import { Icon20ChevronRight } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getFeatureShowNewStoriesCounter } from 'reactApp/modules/features/features.selectors';
import { getLastFilesForWidget } from 'reactApp/modules/feed/feed.selectors';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getNewStoriesCount, getStorySummaryCovers } from 'reactApp/modules/stories/stories.selectors';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import { TabMenu } from 'reactApp/ui/Menu/TabMenu';
import { ETabMenuAlign, ETabMenuMod } from 'reactApp/ui/Menu/TabMenu.types';
import { TabMenuItem } from 'reactApp/ui/Menu/TabMenuItem';
import styles from 'reactApp/ui/StoriesWidget/StoriesWidget.css';
import { sendStoryWidgetDwh } from 'reactApp/ui/StoriesWidget/StoriesWidget.helpers';
import type { StorySummaryItem } from 'reactApp/ui/StoriesWidgetComponent/Stories.types';
import { StoriesWidgetComponent } from 'reactApp/ui/StoriesWidgetComponent/StoriesWidgetComponent';
import { noop } from 'reactApp/utils/helpers';
import opener from 'reactApp/utils/opener';

import { STORIES_WIDGET_LOCAL_STORAGE_KEY } from './StoriesWidget.constants';

enum EStoryTab {
    stories = 'stories',
    feed = 'feed',
}

const menuTabs = [
    {
        name: 'Истории',
        key: EStoryTab.stories,
    },
    { name: 'Последние файлы', key: EStoryTab.feed },
];

interface IProps {
    stories?: StorySummaryItem[];
    onExpand?: (value: boolean) => void;
}

export const STORIES_WIDGET_TAB_KEY = 'storytab';

/* eslint-disable max-lines-per-function */
export const StoriesWidget = memo(function StoriesWidget({ stories, onExpand }: IProps): JSX.Element | null {
    const [currentTab, setCurrentTab] = useState(storeHelper.getValue(STORIES_WIDGET_TAB_KEY) || EStoryTab.stories);

    const [isClosed, setClosed] = useState(storeHelper.getValue(STORIES_WIDGET_LOCAL_STORAGE_KEY));
    const showCounter = useSelector(getFeatureShowNewStoriesCounter);
    const storeStories = useSelector(getStorySummaryCovers);
    const newStoriesCount = useSelector(getNewStoriesCount);
    if (!stories) {
        stories = storeStories;
    }
    const lastFiles = useSelector(getLastFilesForWidget);

    useEffect(() => {
        if (stories?.length) {
            sendStoryWidgetDwh({
                action: isClosed ? 'view-stories-hide' : 'view-stories-open',
                source: EStorageType.home,
                tab: EStorageType.home,
            });
        }
    }, [isClosed]);

    useEffect(() => {
        if (currentTab === EStoryTab.feed) {
            sendStoryWidgetDwh({
                action: 'view-last-files',
                source: EStorageType.story,
            });
        }
    }, [currentTab]);

    useEffect(() => {
        onExpand?.(!isClosed);
    }, [isClosed, onExpand]);

    const handleTabClick = useCallback(
        (value) => {
            setCurrentTab(value);
            storeHelper.setValue(STORIES_WIDGET_TAB_KEY, value);
            sendStoryWidgetDwh({
                action: `click_to_${value === EStoryTab.feed ? 'last_files' : 'stories'}`,
                source: EStorageType.story,
            });
        },
        [setCurrentTab]
    );

    const handleExpand = useCallback(() => {
        const newValue = !isClosed;
        setClosed(newValue);

        storeHelper.setValue(STORIES_WIDGET_LOCAL_STORAGE_KEY, newValue);

        sendStoryWidgetDwh({
            action: newValue ? 'view-stories_close' : 'view-stories_reopen',
            source: EStorageType.story,
        });
    }, [isClosed, setClosed]);

    const handleFeedSeeAll = useCallback(() => {
        opener('/feed', true);
    }, []);

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div className={styles.collapser} onClick={handleExpand}>
                    <Icon20ChevronRight
                        className={classNames({
                            [styles.expandIcon]: true,
                            [styles.expandIconExpanded]: !isClosed,
                        })}
                    />
                </div>
                <div className={styles.tabs} onClick={() => setClosed(false)}>
                    <TabMenu align={ETabMenuAlign.left} mod={ETabMenuMod.widget}>
                        {menuTabs.map(({ name, key }) =>
                            lastFiles?.length < 5 && key === EStoryTab.feed ? null : (
                                <TabMenuItem
                                    key={key}
                                    name={name}
                                    badge={showCounter && key === EStoryTab.stories && newStoriesCount ? newStoriesCount.toString() : ''}
                                    badgeHint={showCounter && key === EStoryTab.stories ? 'Ваши непросмотренные истории' : ''}
                                    value={key}
                                    active={currentTab === key}
                                    onClick={handleTabClick}
                                    changeHash={false}
                                />
                            )
                        )}
                    </TabMenu>
                </div>
                {!isClosed && currentTab === EStoryTab.feed && (
                    <div className={styles.leftControls}>
                        <div className={styles.feedAll} onClick={handleFeedSeeAll}>
                            Посмотреть все
                        </div>
                    </div>
                )}
            </div>
            <div className={classNames({ [styles.content]: true, [styles.content_hidden]: isClosed })}>
                {currentTab === EStoryTab.stories && <StoriesWidgetComponent stories={stories} />}
                {currentTab === EStoryTab.feed && (
                    <div className={styles.lastFiles} data-qa-name="lastFiles">
                        <DataList
                            goTo={noop}
                            storage={EStorageType.feed}
                            viewMode={EViewMode.thumbs}
                            gaSuffix="wjfeed"
                            disableCenteredLoader
                            limitItemsNumber={10}
                            disableLoadOnScroll
                            disableTips
                            itemsList={lastFiles?.map((item) => item.id)}
                            storiesWidget
                        />
                    </div>
                )}
            </div>
        </div>
    );
});
