import type { FeedItemBase } from 'reactApp/modules/feed/feed.types';
import type { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { FileType } from 'reactApp/types/FileType';
import type { IFlags, Kind, VirusScan } from 'reactApp/types/Tree';

/**
 * Секции в doc.mail.ru, для каждого из которых использован один сторадж - allDocuments
 */
export const enum EAllDocumentsType {
    document = 'document',
    spreadsheet = 'spreadsheet',
    presentation = 'presentation',
    pdf = 'pdf',
}

/**
 * Секции в doc.mail.ru, для каждого из которых реализован отдельный сторадж
 */
export const enum EAdditionalSections {
    trashbin = 'trashbin',
    incoming = 'incoming-public',
}

export interface AllDocumentsItem extends FeedItemBase {
    storage: EStorageType.alldocuments;
}

interface AllDocumentsBase<T> {
    name: string;
    childs: string[];
    cursor: string;
    isLoaded: boolean;
    isLoading: boolean;
    hasMoreToLoad: boolean;
    id: T;
    value: T;
}

export enum AllDocumentsCategoryType {
    all = 'all',
    youShared = 'youShared',
    onlyYours = 'onlyYours',
    sharedWithYou = 'sharedWithYou',
}

export interface AllDocumentsCategory extends AllDocumentsBase<AllDocumentsCategoryType> {}

export interface IAllDocumentsFolder extends AllDocumentsBase<EAllDocumentsType> {}

export interface IAllDocumentsState {
    currentDocType: EAllDocumentsType | null;
    idxs: string[];
    docsSize: number;
    isLoading: boolean;
    isLoaded: boolean;
    error?: string | null;
    cursor: string;
    hasMoreToLoad: boolean;
    currentSection: AllDocumentsSections | null;
    prevSection: AllDocumentsSections | null;

    categories: AllDocumentsCategory[];
    currentCategory: AllDocumentsCategoryType;
}

export interface IWeblinksIncomingData {
    links: [
        {
            type: 'folder' | 'file';
            name: string;
            id: string;
            ctime: number;
            owner: {
                email: string;
            };
        }
    ];
}

export type AllDocumentsSections = EAllDocumentsType | EAdditionalSections;

export enum AllDocumentsXrayTypes {
    onSuccess = 'onSuccess',
    onError = 'onError',
}

// START -- /api/v4/weblinks/list
// Внимание! В типах описаны в основном только используемые поля

export interface WeblinksListApiV4Response {
    cursor?: string;
    count: number;
    list: WeblinksListApiV4ResponseListItem[];
}

export interface WeblinksListApiV4ResponseListItem {
    public: WeblinksListApiV4ResponseListItemPublic;
    kind: Kind;
    mtime: number;
    name: string;
    nodeid: string;
    path: string;
    size: number;
    type: FileType;
    hash: string;
    flags?: {
        favorite?: boolean;
    };
}

export interface WeblinksListApiV4ResponseListItemPublic {
    type: FileType;
    path: string;
    name: string;
    // параметр вида xvHm/uUcUQFxa3
    id: string;
    ctime: number;
    flags?: IFlags;
}

// END -- /api/v4/weblinks/list

// START -- /api/v4/private/feed
// Внимание! В типах описаны в основном только используемые поля

export interface FeedApiV4Response {
    cursor?: string;
    list: FeedApiV4ResponseListItem[];
}

export interface FeedApiV4ResponseListItem {
    public: FeedApiV4ResponseListItemPublic;
    flags: {
        favorite: boolean;
    };
    kind: Kind;
    mtime: number;
    name: string;
    nodeid: string;
    path: string;
    size: number;
    type: FileType;
    hash?: string;
    home: string;
    virus_scan: VirusScan;
}

export interface FeedApiV4ResponseListItemPublic {
    ctime: number;
    extid: string;
    flags: {
        domestic: boolean;
        writable: boolean;
    };
    // параметр вида xuHm/uUcUQFxa3
    id: string;
    name: string;
    type: string;
}

// END -- /api/v4/private/feed
