import { IS_MOBILE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { getUtmSourceString } from 'reactApp/appHelpers/settingsHelpers';
import type { Product } from 'reactApp/types/Billing';
import { sendXray } from 'reactApp/utils/ga';
import { EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

export enum PaymentWindowAction {
    view = 'view',
    click = 'click',
    switchPaymethod = 'switch-paymethod',
    checkBind = 'check-bin-cards',
}

export enum PaymentWindowType {
    default = 'default',
    mail = 'mail-upload',
    frame = 'frame',
    fastcheckout = 'fastcheckout',
    trial = 'trial',
    promo = 'promo',
}

interface SendAnalyticsParams extends Record<string, any> {
    action: PaymentWindowAction;
    product: Product;
    windowType?: PaymentWindowType;
    paySource?: string;
    paymethod?: string;
}

export const getPaySource = (paySource: string): string => (IS_MOBILE_BROWSER ? `${paySource}_touch` : paySource);

export const sendPaymentWindowAnalytics = ({
    action,
    product,
    windowType,
    paySource = '',
    paymethod = undefined,
    ...restData
}: SendAnalyticsParams): void => {
    if (!product) {
        return;
    }

    const utm = getUtmSourceString();

    sendXray(['buy-window', windowType, action]);
    sendXray(['bw', action, paySource ? paySource : 'none', utm?.length ? 'utm' : 'noutm']);

    sendPaymentGa({
        action: EPaymentGa.paymentWindow,
        label: action,
        type: windowType ?? 'none',
        price: product?.hasTrial ? 0 : product.discountPrice || product.price,
        isTrial: product.hasTrial,
        isDiscount: product?.hasDiscount,
        tariff: product.id,
        paySource,
        paymethod,
        ...restData,
    });
};
