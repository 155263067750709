import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

/* tempexp_17604-start */
type UploadBigFileModalTariffs = {
    trial: string;
    forceTrial: string;
    default: string;
};

// CLOUDWEB-17604 Проверять платника/бесплатник в окне загрузки большого файла
export const uploadBigFileModalAb: 'a' | 'b' = getFeature('upload-big-file-modal-ab');

export const uploadBigFileModalTariffs: UploadBigFileModalTariffs = getFeature('upload-big-file-modal-tariffs');

registerExperiment('upload-ab', uploadBigFileModalAb, [
    AnalyticEventNames.BIG_FILE_DIALOG_SHOW,
    AnalyticEventNames.BIG_FILE_DIALOG_PAYMENT_SHOW,
    AnalyticEventNames.BIG_FILE_DIALOG_PAYMENT_BUY,
    AnalyticEventNames.BIG_FILE_DIALOG_CLICK,
]);
/* tempexp_17604-end */
