import { Icon20MenuOutline, Icon20MoreVertical, Icon20ShareExternalOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import { ReactComponent as CloudIcon } from 'img/icons/mobile/logo.svg';
import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_ONPREMISE, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { abPublicMobileHeaderValue } from 'reactApp/appHelpers/featuresHelpers/features/publicHeader';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getIsViewerFileVerifiedByKaspersky } from 'reactApp/modules/file/utils';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { Dropdown } from 'reactApp/ui/Dropdown/Dropdown';
import { DropdownMenu } from 'reactApp/ui/Dropdown/DropdownMenu';
import { renderMobileLeftMenu } from 'reactApp/ui/Mobile/LeftMenu/LeftMenu.helpers';
import { ProtectedTooltip } from 'reactApp/ui/Mobile/ProtectedTooltip/ProtectedTooltip';
import { renderMobileUserLoginMenu } from 'reactApp/ui/Mobile/UserLoginMenu/UserLoginMenu.helpers';
import { NameComponent } from 'reactApp/ui/Name/Name';
import { tooltipPlacements } from 'reactApp/ui/Tooltip/Tooltip.constants';
import type { IFile } from 'reactApp/ui/ViewerToolbar/ViewerToolbar.types';
import { LinkIcon, MailIcon } from 'reactApp/ui/VKUIIcons';
import { copy } from 'reactApp/utils/copyToClipboard';
import { formatLocale } from 'reactApp/utils/formatDate';
import { sendDwh, sendGa } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';
import { EQueryParams } from 'server/helpers/getRequestParams';

import styles from './ViewerToolbarMobileNew.css';

interface ViewerToolbarMobileNew {
    file: IFile;
    openToolbarOptions: () => void;
}

export const ViewerToolbarMobileNew = memo<ViewerToolbarMobileNew>(({ file, openToolbarOptions }) => {
    const isAnonymous = useSelector(UserSelectors.isAnonymous);

    const isViewerFileVerifiedByKaspersky = useSelector(getIsViewerFileVerifiedByKaspersky);

    const fromDeeplink = getQueryParams()[EQueryParams.fromDeeplink];
    const isSuperAppWebView = Boolean(IS_WEBVIEW) && fromDeeplink;

    const isMobile = useSelector(EnvironmentSelectors.isMobile);
    const isNativeShareSupported = isMobile && 'share' in navigator && typeof navigator.share === 'function';

    const dispatch = useDispatch();
    const showNotify = useCallback((options) => dispatch(showSnackbarAction(options)), []);

    const openOptions = useCallback(() => {
        openToolbarOptions();
    }, [openToolbarOptions]);

    const onMenuClick = useCallback(() => {
        /* tempexp_17777-start */
        sendDwh({
            eventCategory: ECategoryGa.public,
            action: 'go-to-cloud_touch',
            dwhData: {
                extension: file.extension,
                type: 'menu',
            },
        });
        /* tempexp_17777-end */

        if (isAnonymous) {
            renderMobileUserLoginMenu();
        } else {
            renderMobileLeftMenu({ animated: true, isRebranding: false });
        }
    }, [isAnonymous]);

    const copyLink = useCallback(() => {
        copy(window.location.href)
            .then(({ hasBeenCopied }) => {
                if (hasBeenCopied) {
                    showNotify({
                        text: 'Ссылка успешно скопирована',
                        type: SnackbarTypes.success,
                        id: 'copyLink',
                        closable: true,
                    });
                }
            })
            .catch(() => {
                showNotify({
                    text: 'Не удалось скопировать ссылку',
                    type: SnackbarTypes.failure,
                    id: 'copyLink',
                    closable: true,
                });
            });
    }, []);

    const handleOnSharingClick = useCallback((id) => {
        switch (id) {
            case 'send':
                return sendGa('email-click');
            case 'copy':
                return copyLink();
        }
    }, []);

    const renderSharingDropdown = () => {
        const list = [
            {
                id: 'copy',
                text: 'Скопировать ссылку',
                icon: <LinkIcon height={20} width={20} />,
            },
        ];

        if (!IS_ONPREMISE) {
            list.push({
                id: 'send',
                text: 'Отправить по почте',
                icon: <MailIcon />,
            });
        }

        return (
            <>
                <DropdownMenu list={list} onClick={handleOnSharingClick} />
            </>
        );
    };

    /* tempexp_17777-start */
    const sendShareClickDwh = useCallback(
        () =>
            sendDwh({
                eventCategory: ECategoryGa.public,
                action: 'share-click-in-header_touch',
                dwhData: {
                    extension: file?.extension,
                },
            }),
        []
    );
    /* tempexp_17777-end */

    const handleOnButtonClick = useCallback(() => {
        sendShareClickDwh();
        sendGa('share-click');
    }, []);

    const onNativeShare = useCallback(() => {
        sendShareClickDwh();

        if (isNativeShareSupported) {
            navigator
                .share({
                    url: window.location.href,
                })
                .catch(() => {
                    sendGa('share-error');
                });
        }
    }, []);

    const date = file.date ? formatLocale(file.date, 'dd MMMM yyyy') : '';

    /* tempexp_17777-start */
    const renderSharingBtn = () =>
        isNativeShareSupported ? (
            <div className={classNames(styles.button, styles.share)} onClick={onNativeShare} data-qa-button="share">
                <Icon20ShareExternalOutline width={20} height={20} fill="#currentColor" />
            </div>
        ) : (
            <Dropdown
                as={({ children, onClick }) => <div onClick={onClick}>{children}</div>}
                size="big"
                theme="octavius"
                basic={true}
                content={renderSharingDropdown()}
                placement={tooltipPlacements.BOTTOM_RIGHT}
            >
                <div className={classNames(styles.button, styles.share)} onClick={handleOnButtonClick} data-qa-button="share">
                    <Icon20ShareExternalOutline width={20} height={20} fill="#currentColor" />
                </div>
            </Dropdown>
        );
    /* tempexp_17777-end */

    return (
        <div className={classNames(styles.root, 'vkui--cloud--dark')}>
            {/* tempexp_17777-start */}
            {!isSuperAppWebView &&
                (abPublicMobileHeaderValue === 'c' ? (
                    <div
                        onClick={() => {
                            sendDwh({
                                eventCategory: ECategoryGa.public,
                                action: 'go-to-cloud_touch',
                                dwhData: {
                                    extension: file.extension,
                                    type: 'logo',
                                },
                            });
                        }}
                    >
                        <a href="/" data-qa-button="logo">
                            <CloudIcon width={30} height={30} fill="var(--vkui--color_text_contrast)" />
                        </a>
                    </div>
                ) : (
                    <div className={styles.menu} onClick={onMenuClick} data-qa-button="menu">
                        <Icon20MenuOutline width={20} height={20} fill="var(--vkui--color_text_contrast)" />
                    </div>
                ))}
            {/* tempexp_17777-end */}
            <div className={styles.info}>
                <div className={styles.name}>
                    <NameComponent name={file.name} extension={file.extension} useH1={file.weblinkSeoEnabled} />
                    {isViewerFileVerifiedByKaspersky && <ProtectedTooltip />}
                </div>
                <div className={styles.meta}>
                    {file.size}
                    {date ? ` • ${date}` : ''}
                </div>
            </div>
            {/* tempexp_17777-start */}
            {(abPublicMobileHeaderValue === 'b' || abPublicMobileHeaderValue === 'c') && !isSuperAppWebView && renderSharingBtn()}
            {/* tempexp_17777-end */}
            <div className={styles.button} onClick={openOptions} data-qa-button="options">
                <Icon20MoreVertical width={20} height={20} fill="#currentColor" />
            </div>
        </div>
    );
});

ViewerToolbarMobileNew.displayName = 'ViewerToolbarMobileNew';
