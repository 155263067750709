define(function (require, exports, module) {
    if (process.env.SSR) {
        return;
    }

    const $ = require('jquery');
    const { sendXray } = require('reactApp/utils/ga');

    const CloudAPI = require('Cloud/API/v2');
    require('Cloud/API/v3');
    const config = require('Cloud/config');

    const userEmail = config.get('user.email');
    const csrfToken = config.get('tokens.csrf');
    const { captureException } = require('reactApp/utils/tracer');

    const prevRequests = {};
    let clearTime = 0;

    function cleanData(now) {
        Object.keys(prevRequests).forEach(function (key) {
            const item = prevRequests[key];
            if (now - item > 60) {
                delete prevRequests[key];
            }
        });

        clearTime = now;
    }

    function requestsTracking(name, params) {
        const key = `${name}|${typeof params === 'object' ? JSON.stringify(params) : params}`;
        const sec = (Date.now() / 1000) | 0;
        const prev = prevRequests[key];

        if (prev) {
            if (sec - prev < 60) {
                sendXray(`repeated_${name}`);
            }
        }

        prevRequests[key] = sec;

        if (sec - clearTime > 90) {
            cleanData(sec);
        }
    }

    module.exports = new CloudAPI({
        email: userEmail,
        token: csrfToken,
        htmlencoded: false,
        build: config.get('BUILD'),
        'x-page-id': config.get('x-page-id'),

        promiseBuilder(transportDeferred, name, params) {
            const requestDeferred = new $.Deferred();

            const getData = function (response) {
                const data = response.getData();

                switch (name) {
                    case 'batch':
                        return data.map(function (item) {
                            const subResponse = new CloudAPI.ResponseFacade(item);

                            return {
                                status: subResponse.getStatus(),
                                data: subResponse.getData(),
                                response: subResponse,
                            };
                        });
                    default:
                        return data;
                }
            };

            const methodKey = `api_${name.replace(/\//g, '-')}_v2`;
            const sendRadar = (name) => sendXray([methodKey, name]);
            requestsTracking(methodKey, params);

            transportDeferred
                .done(function (response, xhr) {
                    sendRadar(`${response.getStatus()}_success`);

                    const revision = response.getResult().revision;

                    requestDeferred.resolve(getData(response), response.getStatus(), response, revision);
                })
                .fail((response, xhr) => {
                    sendRadar(`${response.getStatus()}_error`);

                    if (response.isFail()) {
                        this.emit('api-fail', response.getStatus(), 'status');
                    } else if (response.isUnavailable()) {
                        this.emit('api-maintenance', response.getStatus(), response.getData());
                    } else if (response.isDenied()) {
                        this.emit('accessDenied', 'api');
                    }

                    requestDeferred.reject(getData(response), response.getStatus(), response);
                })
                .always(function (response, xhr) {
                    let label = response.getStatus();

                    if (isNaN(label)) {
                        captureException(null, {
                            message: 'NaN status',
                            arguments: response.getResult(),
                        });
                    }

                    if (xhr) {
                        label += `-${xhr.status}`;
                    }

                    sendXray(`api-status_${label}`);
                });

            return requestDeferred.promise();
        },
    });
});
