import { plural } from 'lib/plural';
import React from 'react';
import { IS_PUBLIC_ALBUM } from 'reactApp/appHelpers/configHelpers';
import { isAlbum } from 'reactApp/modules/albums/albums.types';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getItemTypeName } from 'reactApp/modules/file/utils';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { filesPlural, foldersPlural as foldersPluralUtil } from 'reactApp/utils/pluralHelpers';
import { truncateTextMiddle } from 'reactApp/utils/textHelpers';

const isMobile = EnvironmentSelectors.isMobile();

export const foldersPlural = (count: number): string => ['выбранную папку', 'выбранные папки', 'выбранных папок'][plural(count)];
export const filePlural = (count: number): string => ['выбранный файл', 'выбранных файла', 'выбранных файлов'][plural(count)];

const getItemsCount = (items) => {
    return items.reduce(
        (result, item) => {
            if (item.isFolder) {
                result.folderCount++;
            } else {
                result.fileCount++;
            }

            return result;
        },
        { folderCount: 0, fileCount: 0 }
    );
};

const getAlbumItemsContent = (items) => {
    if (!items?.length) {
        return;
    }

    const isAllItemsAlbums = items.every(isAlbum);
    const itemName = isMobile ? truncateTextMiddle(items[0].name, 20) : items[0].name;
    const isSingleFile = items.length === 1;

    const ending = isAllItemsAlbums || !isSingleFile ? 'Файлы из Облака удалены не будут.' : 'Файл из Облака удалён не будет.';

    if (isAllItemsAlbums) {
        return isSingleFile
            ? `Вы действительно хотите удалить альбом «${itemName}»? ${ending}`
            : `Вы действительно хотите удалить альбомы? ${ending}`;
    }

    return isSingleFile
        ? `Вы действительно хотите убрать «${itemName}» из альбома? ${ending}`
        : `Вы действительно хотите убрать файлы из альбома? ${ending}`;
};

const getMountedItemsContent = ({ folderCount, fileCount, items }) => {
    if (items.length === 1) {
        const itemName = isMobile ? truncateTextMiddle(items[0].name, 20) : items[0].name;

        return `${folderCount ? 'Удаляемая папка' : 'Удаляемый файл'} «${itemName}» находится в общей папке, при удалении ${
            folderCount ? 'она будет перемещена' : 'он будет перемещён'
        } в Корзину владельца.`;
    }

    return `Удаляемые ${fileCount ? 'файлы ' : ''}${fileCount && folderCount ? 'и ' : ''}${
        folderCount ? 'папки ' : ''
    } находятся в общей папке, при удалении они будут перемещены в Корзину владельца.`;
};

export const getContent = ({ isMounted, items, hasAlienFiles, currentStorage }) => {
    const { folderCount, fileCount } = getItemsCount(items);
    const isAlbumFiles = IS_PUBLIC_ALBUM || (items.length && items.every((item) => item.storage === EStorageType.albums));

    if (isMounted) {
        return getMountedItemsContent({ folderCount, fileCount, items });
    }

    const aliensFiles = hasAlienFiles && (
        <>
            Вы не можете удалять чужие файлы и папки.
            <br />
        </>
    );

    if (isAlbumFiles) {
        return (
            <>
                {aliensFiles}
                {getAlbumItemsContent(items)}
            </>
        );
    }

    if (items.length === 1) {
        const itemName = truncateTextMiddle(items[0].name, 20);
        const isFolder = items[0].isFolder;

        return (
            <>
                {aliensFiles}
                {currentStorage === EStorageType.alldocuments
                    ? `Файл «${itemName}» будет удалён из Документов и Облака. Вы можете восстановить его из корзины в течение 14 дней`
                    : `Через 14 дней ${isFolder ? 'папка' : 'файл'} «${itemName}» будет ${
                          isFolder ? 'удалена' : 'удалён'
                      } навсегда — и место в Облаке освободится`}
            </>
        );
    }

    let content = '';

    const folderText = currentStorage === EStorageType.alldocuments ? foldersPlural(folderCount) : foldersPluralUtil(folderCount);

    const fileText = filesPlural(fileCount);

    content += folderCount ? `${folderCount} ${folderText} ` : '';
    content += fileCount && folderCount ? 'и ' : '';
    content += fileCount ? `${fileCount} ${fileText}` : '';

    return (
        <>
            {aliensFiles}
            {content && (
                <>
                    {currentStorage === EStorageType.alldocuments
                        ? `Выбранные файлы будут удалены из Документов и Облака. Вы можете восстановить их из корзины в течение 14 дней`
                        : `Через 14 дней ${content.trim()} будут удалены навсегда — и место в Облаке освободится`}
                </>
            )}
        </>
    );
};

export const renderRemoveDialog = (data) => openPopupHelper({ popupName: popupNames.REMOVE_DIALOG, data });

export const getTitle = ({ items, currentStorage }) => {
    const isAlbumFiles = IS_PUBLIC_ALBUM || (items.length && items.every((item) => item.storage === EStorageType.albums && !isAlbum(item)));
    const areAllFiles = items.every((item) => !item.isFolder);

    if (isAlbumFiles) {
        return 'Удалить из альбома';
    }

    if (currentStorage === EStorageType.albums) {
        return 'Удаление';
    }

    if (currentStorage === EStorageType.alldocuments) {
        return items.length === 1
            ? 'Переместить файл в корзину?'
            : `Переместить ${items.length}\xA0${filesPlural(items.length)} в корзину?`;
    }

    if (areAllFiles && items.length > 0) {
        return items.length === 1 ? 'Переместить файл в корзину?' : `Переместить файлы в корзину?`;
    }

    return items.length === 1 ? `Переместить ${getItemTypeName(items[0])} в корзину?` : 'Переместить в корзину?';
};
