import { type DemoPromoTooltipVariant, DEMO_PROMO_TOOLTIP_VARIANT } from 'reactApp/appHelpers/featuresHelpers/features/demoPromo';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { store as reduxStore } from 'reactApp/store';
import { put } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';

const PERIOD_TO_SHOW_DAYS = 1;

const DISABLED_STORAGES_BY_VARIANT: Record<DemoPromoTooltipVariant, EStorageType[]> = {
    'business-templates-tooltip': [EStorageType.templates],
};

export function* initDemoPromoTooltip() {
    if (!DEMO_PROMO_TOOLTIP_VARIANT) {
        return;
    }

    // Показываем только на тех стораджах (страницах), где не запрещено
    const storage = yield* select(getCurrentStorage);
    const disabledByStorage = DISABLED_STORAGES_BY_VARIANT[DEMO_PROMO_TOOLTIP_VARIANT].includes(storage);

    if (disabledByStorage) {
        return;
    }

    // Показывается 1 раз в день
    const allowShowPromo =
        !storeHelper.getValue(DEMO_PROMO_TOOLTIP_VARIANT) ||
        storeHelper.isPassed(DEMO_PROMO_TOOLTIP_VARIANT, { daysCount: PERIOD_TO_SHOW_DAYS });

    if (!allowShowPromo) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.demoPromoTooltip,
            promoId: DEMO_PROMO_TOOLTIP_VARIANT,
            variant: DEMO_PROMO_TOOLTIP_VARIANT,

            onShow: () => {
                storeHelper.markAsShown(DEMO_PROMO_TOOLTIP_VARIANT!);
                reduxStore.dispatch(promoShown(EPromoType.demoPromoTooltip));
            },
            onClose: () => {
                reduxStore.dispatch(removePromo(EPromoType.demoPromoTooltip));
            },
        })
    );
}
