import React, { type FC } from 'react';
import type { AttachesItem } from 'reactApp/modules/attaches/attaches.types';
import type { CloudFile } from 'reactApp/modules/storage/storage.types';
import { CloseMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/CloseMenuButton/CloseMenuButton';
import { DownloadMenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/DownloadMenuButton/DownloadMenuButton';
import { MenuInfo } from 'reactApp/ui/ReactViewer/ViewerMenu/MenuInfo/MenuInfo';
import { MenuLogo } from 'reactApp/ui/ReactViewer/ViewerMenu/MenuLogo/MenuLogo';
import {
    type ViewerMenuProps,
    ViewerMenuCenter,
    ViewerMenuEnd,
    ViewerMenuStart,
    ViewerMenuWrapper,
} from 'reactApp/ui/ReactViewer/ViewerMenu/ViewerMenu';
import { Zoomer } from 'reactApp/ui/ReactViewer/ViewerMenu/Zoomer/Zoomer';

interface Props extends ViewerMenuProps {
    file: CloudFile | AttachesItem;
}

export const SharingAttachMenu: FC<Props> = ({ file, zoom, decreaseZoom, increaseZoom, showDownload, onDownload, showClose, onClose }) => {
    return (
        <ViewerMenuWrapper>
            <ViewerMenuStart>
                <MenuLogo />
                <MenuInfo file={file} />
            </ViewerMenuStart>
            <ViewerMenuCenter>{zoom && <Zoomer value={zoom} decrease={decreaseZoom} increase={increaseZoom} />}</ViewerMenuCenter>
            <ViewerMenuEnd>
                {showDownload && (
                    <DownloadMenuButton
                        file={file}
                        onClick={() => {
                            onDownload(file);
                        }}
                    />
                )}
                {showClose && (
                    <CloseMenuButton
                        onClick={() => {
                            onClose(file);
                        }}
                    />
                )}
            </ViewerMenuEnd>
        </ViewerMenuWrapper>
    );
};
