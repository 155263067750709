import { Icon16Cancel, Icon20HideOutline, Icon20ViewOutline } from '@vkontakte/icons';
import { Caption, classNames, Input, Spacing, Text, Title } from '@vkontakte/vkui';
import React, { type ForwardedRef, forwardRef, memo, useMemo, useRef } from 'react';
import { IS_REQUIRED_B2B_PASSWORD_PUBLIC } from 'reactApp/appHelpers/configHelpers';
import type { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';

import styles from './PublicPassword.css';
import type { PublicPasswordProps } from './PublicPassword.types';
import { usePassword } from './use-password';

export const getTitle = (name?: string) => {
    if (name) {
        return (
            <>
                Установите пароль на доступ
                <br />
                по ссылке к «{name}»
            </>
        );
    }

    return (
        <>
            Установите пароль на доступ
            <br />
            по ссылке
        </>
    );
};

export const PublicPassword = memo(
    forwardRef(({ item, pin, onClose, onSuccess }: IPropsWithPopup<PublicPasswordProps>, ref: ForwardedRef<HTMLDivElement>) => {
        const inputRef = useRef<HTMLInputElement>(null);

        const { error, password, showPassword, handleChangePassowrd, handleSavePassword, clearPassword, tooglePasswordVisibility } =
            usePassword({
                item,
                pin,
                onClose,
                onSuccess,
                inputRef,
            });

        const disabled = !password || Boolean(error);

        const hint = useMemo(() => {
            if (IS_REQUIRED_B2B_PASSWORD_PUBLIC) {
                return item?.isFolder
                    ? 'Доступ по ссылке «Для всех» нужно защищать паролем. Все у кого есть ссылка и пароль, будут иметь доступ ко всем вложенным папкам и файлам'
                    : 'Доступ по ссылке «Для всех» нужно защищать паролем. Файл смогут открыть все пользователи, которые перейдут по ссылке и введут пароль';
            }

            return `Все, кто перейдёт по ссылке и введёт пароль, получат доступ к ${item?.isFolder ? 'папке' : 'файлу'}`;
        }, [item?.isFolder]);

        return (
            <div ref={ref} className={styles.root}>
                <Title className={styles.title} level="1">
                    {getTitle(item?.name)}
                </Title>
                <Spacing size={12} />
                <Text className={styles.text}>{hint}</Text>
                <Spacing size={20} />
                <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Придумайте пароль"
                    className={classNames(styles.password, {
                        [styles.passwordError]: Boolean(error),
                    })}
                    value={password}
                    onChange={handleChangePassowrd}
                    getRef={inputRef}
                    after={
                        <div className={styles.inputActions}>
                            {Boolean(password.length) && (
                                <div className={styles.cancel} onClick={clearPassword}>
                                    <Icon16Cancel />
                                </div>
                            )}
                            <div className={styles.passwordToogle} onClick={tooglePasswordVisibility}>
                                {showPassword ? <Icon20HideOutline /> : <Icon20ViewOutline />}
                            </div>
                        </div>
                    }
                />
                {Boolean(error) && (
                    <>
                        <Spacing size={6} />
                        <Caption level="1" className={styles.errorText}>
                            {error}
                        </Caption>
                    </>
                )}
                <Spacing size={24} />
                <div className={styles.footer}>
                    <Button theme="octavius" primary sizeMode={ButtonSizeMode.small} disabled={disabled} onClick={handleSavePassword}>
                        Установить
                    </Button>
                    <Button sizeMode={ButtonSizeMode.small} onClick={() => onClose?.()}>
                        Отмена
                    </Button>
                </div>
            </div>
        );
    })
);

PublicPassword.displayName = 'PublicPassword';
