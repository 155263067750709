import { Tabs, TabsItem } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { sendXrayWithPlatform } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { SASS_RADAR, sendSassGa } from 'reactApp/sections/SassLanding/SassLanding.helpers';
import { getSassTariffsList } from 'reactApp/sections/SassLanding/SassLanding.selectors';
import { SassTariffCardLoader } from 'reactApp/sections/SassLanding/SassTariffCardLoader/SassTariffCardLoader';
import { sendSassTmrGoal } from 'reactApp/sections/SassLanding/SassTmr';
import styles from 'reactApp/sections/SassLanding/TariffPlans/SassTariffPlans.css';
import { SassTariffPlansCard } from 'reactApp/sections/SassLanding/TariffPlans/SassTariffPlansCard';
import type { RootState } from 'reactApp/store';
import { ETabsName } from 'reactApp/ui/TariffSectionNew/TariffSection.constants';

export type TariffPlansListProps = {
    size?: 'sm' | 'm' | 'lg';
    initialSelected?: ETabsName.month | ETabsName.year;
};

export const TariffPlansList = ({ size, initialSelected = ETabsName.year }: TariffPlansListProps) => {
    const [selected, setSelected] = useState<ETabsName>(initialSelected);
    const tariffList = useSelector((state: RootState) => getSassTariffsList(state));

    const filteredTariffList = useMemo(() => {
        return tariffList.filter((tariff) => Boolean(tariff.products[`${selected}Product`]));
    }, [selected, tariffList]);

    const { isLoading } = useSelector(ProductsSelectors.getLifeCycleState);

    useEffect(() => {
        if (tariffList.length) {
            sendXrayWithPlatform([SASS_RADAR.tariff_load]);
        }
    }, [tariffList]);

    return (
        <>
            <div
                className={classNames(styles.tabs, {
                    [styles.medium]: size === 'm',
                })}
            >
                <Tabs>
                    <TabsItem
                        className={styles.sassTabItem}
                        selected={selected === ETabsName.month}
                        onClick={() => {
                            sendSassGa({
                                action: 'switch_period',
                                eventCategory: 'click',
                                period: ETabsName.month,
                            });
                            sendXrayWithPlatform([SASS_RADAR.trff_tab_month]);
                            sendSassTmrGoal({ goal: 'switch_period_month' });
                            setSelected(ETabsName.month);
                        }}
                        id="tab-month"
                        aria-controls="tab-content-month"
                    >
                        На месяц
                    </TabsItem>
                    <TabsItem
                        className={styles.sassTabItem}
                        selected={selected === ETabsName.year}
                        onClick={() => {
                            sendSassGa({
                                action: 'switch_period',
                                eventCategory: 'click',
                                period: ETabsName.year,
                            });
                            sendSassTmrGoal({ goal: 'switch_period_year' });
                            sendXrayWithPlatform([SASS_RADAR.trff_tab_year]);
                            setSelected(ETabsName.year);
                        }}
                        id="tab-year"
                        aria-controls="tab-content-year"
                    >
                        На год
                    </TabsItem>
                </Tabs>
            </div>
            {isLoading ? (
                <div className={styles.tariffList_skeletonWrapper}>
                    {Array.from(new Array(3)).map((_, idx) => (
                        <div className={styles.tariffList_skeleton} key={`sassTariffCard_${idx}`}>
                            <SassTariffCardLoader />
                        </div>
                    ))}
                </div>
            ) : (
                <div className={styles.tariffList}>
                    {filteredTariffList.map((tariff, ind) => (
                        <SassTariffPlansCard key={ind} activeTab={selected} tariff={tariff} size={size} />
                    ))}
                </div>
            )}
        </>
    );
};

TariffPlansList.displayName = 'TariffPlansList';
