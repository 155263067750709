import React, { memo } from 'react';
import { Portal } from 'react-portal';
import { IS_BIZ_USER, IS_ONPREMISE } from 'reactApp/appHelpers/configHelpers';
import { BaseInputDialog } from 'reactApp/components/BaseConfirmDialog/BaseInputDialog';
import { ConvertFb2ToEpubDialog } from 'reactApp/components/ConvertFb2ToEpubDialog/ConvertFb2ToEpubDialog';
import { CreateCopyOfNonEditableFileModal } from 'reactApp/components/CreateCopyOfNoneditableFileModal/CreateCopyOfNoneditableFileModal';
import { CreateDocumentByLinkDialog } from 'reactApp/components/CreateDocumentByLinkDialog/CreateDocumentByLinkDialog';
import { DisableAdsPromo } from 'reactApp/components/DisableAdsPromo/DisableAdsPromo';
import { EmptyTrashBin } from 'reactApp/components/EmptyTrashBin/EmptyTrashBin';
import { NewDocumentModal } from 'reactApp/components/NewDocumentModal/NewDocumentModal';
import { NewFolderModal } from 'reactApp/components/NewFolderModal/NewFolderModal';
import { RemoveDialog } from 'reactApp/components/RemoveDialog/RemoveDialog';
import { SharingNewMount } from 'reactApp/components/SharingNewBiz/Mount/SharingNewMount';
import { PublicPasswordDialog } from 'reactApp/components/SharingNewBiz/PublicPasswordDialog/PublicPasswordDialog';
import { SharingNewReject } from 'reactApp/components/SharingNewBiz/Reject/SharingNewReject';
import { SharingNewBiz } from 'reactApp/components/SharingNewBiz/SharingNewBiz';
import { SharingNewUnMount } from 'reactApp/components/SharingNewBiz/Unmount/SharingNewUnMount';
import { DeletePublicPasswordDialog } from 'reactApp/components/SharingNewBiz/WeblinkPublicPassword/DeletePublicPasswordDialog/DeletePublicPasswordDialog';
import { SharingNew } from 'reactApp/components/SharingWindow/SharingNew/SharingNew';
import { DeletePublicPasswordConfirmModal } from 'reactApp/components/SharingWindow/SharingNew/Weblink/PublicPassword/DeletePublicPasswordConfirmModal/DeletePublicPasswordConfirmModal';
import { SharingNewCollapsedDialog } from 'reactApp/components/SharingWindow/SharingNew/Weblink/SharingNewCollapsedDialog/SharingNewCollapsedDialog';
import { UploadBigFileDialog } from 'reactApp/components/UploadBigFileDialog/UploadBigFileDialog';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { SelectAlbumPopup } from 'reactApp/sections/AlbumsPage/SelectAlbumPopup/SelectAlbumPopup';
import { SelectFromCloudPopup } from 'reactApp/sections/AlbumsPage/SelectFromCloudPopup/SelectFromCloudPopup';
import { FamilyAcceptInviteDialog } from 'reactApp/sections/FamilyPage/FamilyAcceptInviteDialog/FamilyAcceptInviteDialog';
import { FamilyErrorDialog } from 'reactApp/sections/FamilyPage/FamilyErrorDialog/FamilyErrorDialog';
import { FeaturesModal } from 'reactApp/sections/MobileQuotaTariffsLanding/FeaturesModal/FeaturesModal';
import { DeletePageModal } from 'reactApp/sections/PdfEditPage/DeletePageModal/DeletePageModal';
import { DeletePageRejectedModal } from 'reactApp/sections/PdfEditPage/DeletePageRejectedModal/DeletePageRejectedModal';
import { RecognitionPopup } from 'reactApp/sections/PersonalDocuments/RecognitionPopup/RecognitionPopup';
import { PromocodeResultDialog } from 'reactApp/sections/PresentLanding/ResultDialog/PromocodeResultDialog';
import { DetailedTariffBenefitsPopup } from 'reactApp/sections/QuotaLanding/DetailedTariffBenefitsPopup/DetailedTariffBenefitsPopup';
import { MobileNavigationPopup } from 'reactApp/sections/QuotaLanding/MobileNavigationPanel/MobileNavigationPopup';
import { SassMobileNavigationPopup } from 'reactApp/sections/SassLanding/SassMobileNavigationPanel/SassMobileNavigationPopup';
import { SassMobileMenu } from 'reactApp/sections/SassLanding/SassMobileSubscriptionsPage/SassMobileMenu/SassMobileMenu';
import { AsidePromoModal } from 'reactApp/ui/AsidePromoModal/AsidePromoModal';
import { AuthDialog } from 'reactApp/ui/AuthDialog/AuthDialog';
import { BannerOffer } from 'reactApp/ui/BannerOffer/BannerOffer';
import { BannerOfferRedesign } from 'reactApp/ui/BannerOffer/redesign/BannerOffer';
import { BuyBusinessDialog } from 'reactApp/ui/BuyBusinessDialog/BuyBusinessDialog';
import { CoEditPublicAlertPopup } from 'reactApp/ui/CoEditPublicAlertPopup/CoEditPublicAlertPopup';
import { PreCopyWeblinkDialog } from 'reactApp/ui/CopyWeblink/PreCopyWeblinkDialog/PreCopyWeblinkDialog';
import { DeleteConfirmationDialog } from 'reactApp/ui/DeleteConfirmationDialog/DeleteConfirmationDialog';
import { DisabledFeatureDialog } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog';
import { DiscountInOutflowModal } from 'reactApp/ui/DiscountInOutflowModal/DiscountInOutflowModal';
import { EbookReaderDialog } from 'reactApp/ui/EbookReaderDialog/EbookReaderDialog';
import { FamilyInviteModal } from 'reactApp/ui/Family/FamilyInviteModal/FamilyInviteModal';
import { FamilyAfterPurchaseModal } from 'reactApp/ui/FamilyAfterPurchaseModal/FamilyAfterPurchaseModal';
import { FileHistory } from 'reactApp/ui/FileHistory/FileHistory';
import { LitresModal } from 'reactApp/ui/LitresModal/LitresModal';
import { AuthModal } from 'reactApp/ui/Mobile/AuthModal/AuthModal';
import { CloudNewFeaturesModal } from 'reactApp/ui/Mobile/CloudNewFeaturesModal/CloudNewFeaturesModal';
import { DownloadAppModal } from 'reactApp/ui/Mobile/DownloadAppModal/DownloadAppModal';
import { DownloadDropdown } from 'reactApp/ui/Mobile/DownloadDropdown/DownloadDropdown';
import { FileInfoModal } from 'reactApp/ui/Mobile/FileInfoModal/FileInfoModal';
import { FilterAuthorsModal } from 'reactApp/ui/Mobile/FilterAuthorsModal/FilterAuthorsModal';
import { FilterFacesModal } from 'reactApp/ui/Mobile/FilterFacesModal/FilterFacesModal';
import { MobilePublicPromoBanner } from 'reactApp/ui/Mobile/MobilePublicPromoBanner/MobilePublicPromoBanner';
import { MobileViewer } from 'reactApp/ui/Mobile/MobileViewer/MobileViewer';
import { OptionsModal } from 'reactApp/ui/Mobile/OptionsModal/OptionsModal';
import { PublicPasswordDeleteModal } from 'reactApp/ui/Mobile/PublicPasswordDeleteModal/PublicPasswordDeleteModal';
import { PublicPasswordSetModal } from 'reactApp/ui/Mobile/PublicPasswordSetModal/PublicPasswordSetModal';
import { PublicPasswordTariffModal } from 'reactApp/ui/Mobile/PublicPasswordTariffModal/PublicPasswordTariffModal';
import { RequiredSignUpDialog } from 'reactApp/ui/Mobile/RequiredSignUpDialog/RequiredSignUpDialog';
import { SearchHistoryNotification } from 'reactApp/ui/Mobile/SearchHistoryNotification/SearchHistoryNotification';
import { SearchNotification } from 'reactApp/ui/Mobile/SearchNotification/SearchNotification';
import { SuspiciousDialog as SuspiciousDialogMobile } from 'reactApp/ui/Mobile/SuspiciousDialog/SuspiciousDialog';
import { UserChangeModal } from 'reactApp/ui/Mobile/UserChangeModal/UserChangeModal';
import { UserLoginMenu } from 'reactApp/ui/Mobile/UserLoginMenu/UserLoginMenu';
import { YearFilterModal } from 'reactApp/ui/Mobile/YearFilterModal/YearFilterModal';
import { NewRecognitionPopup } from 'reactApp/ui/NewRecognitionPopup/NewRecognitionPopup';
import { NotDocumentModal } from 'reactApp/ui/NotDocumentModal/NotDocumentModal';
import { NoWarningDialog } from 'reactApp/ui/NoWarningDialog/NoWarningDialog';
import { OverquotaModal } from 'reactApp/ui/OverquotaPopup/new/OverquotaModal';
import { OverquotaSplash } from 'reactApp/ui/OverquotaPopup/newSplash/OverquotaSplash';
import { OverquotaPopup } from 'reactApp/ui/OverquotaPopup/OverquotaPopup';
import { PersonalDocumentGroups } from 'reactApp/ui/PersonalDocumentGroups/PersonalDocumentGroups';
import { PopupMobileAppQR } from 'reactApp/ui/PopupMobileAppQR/PopupMobileAppQR';
import { QuotaPopup } from 'reactApp/ui/QuotaPopup/QuotaPopup';
import { ImageEditorSurvey } from 'reactApp/ui/ReactViewer/ViewerImage/components/ImageEditSurvey/ImageEditorSurvey';
import { RebrandingPromoModal } from 'reactApp/ui/RebrandingPromoModal/RebrandingPromoModal';
import { ReferralProgramPromoModal } from 'reactApp/ui/ReferralProgramPromoModal/ReferralProgramPromoModal';
import { RestoreTrashbinFilesModal } from 'reactApp/ui/RestoreTrashbinFiles/RestoreTrashbinFilesModal';
import { RestoreTrashbinFilesThanksModal } from 'reactApp/ui/RestoreTrashbinFilesThanksModal/RestoreTrashbinFilesThanksModal';
import { SafeFakedoorModal } from 'reactApp/ui/SafeFakedoorModal/SafeFakedoorModal';
import { SelectFolderConnected } from 'reactApp/ui/SelectFolderDialog/SelectFolderDialog';
import { SharingPublicThemeTariffsModal } from 'reactApp/ui/SharingPublicThemeTariffsModal/SharingPublicThemeTariffsModal';
import { SliderModal } from 'reactApp/ui/SliderModal/SliderModal';
import { SmallWindowWarningDialog } from 'reactApp/ui/SmallWindowWarningDialog/SmallWindowWarningDialog';
import { SuspiciousDialog } from 'reactApp/ui/SuspiciousDialog/SuspiciousDialog';
import { ThanksBalloon } from 'reactApp/ui/ThanksBalloon/ThanksBalloon';
import { ThanksModal } from 'reactApp/ui/ThanksModal/ThanksModal';
import { UnlinkCardConfirmModal } from 'reactApp/ui/UnlinkCardConfirmModal/UnlinkCardConfirmModal';
import { UnsavedChangesModal } from 'reactApp/ui/UnsavedChangesModal/UnsavedChangesModal';
import { UsedSpacePostpaidModal } from 'reactApp/ui/UsedSpacePostpaidModal/UserSpacePostpaidModal';
import { UsedSpacePostpaidModalThanks } from 'reactApp/ui/UsedSpacePostpaidModalThanks/UsedSpacePostpaidModalThanks';
import { ViolatedFilenameDialog } from 'reactApp/ui/ViolatedFilenameDialog/ViolatedFilenameDialog';
import { WelcomeDefaultTariffSplash } from 'reactApp/ui/WelcomeDefaultTariffSplash/WelcomeDefaultTariffSplash';
import { ReduxPopup } from 'redux-popup';

import { getPopupProps } from './Popup.helpers';
import { PopupsCommon } from './Popups.common';

const QuotaCleanerPage = React.lazy(
    () => import(/* webpackChunkName: "quotaCleanerLanding" */ '../../ui/QuotaCleanerPage/QuotaCleanerPage')
);
const QuotaCleanerPageMobile = React.lazy(
    () => import(/* webpackChunkName: "QuotaCleanerMobile" */ '../../ui/QuotaCleanerPage/QuotaCleanerPageMobile/QuotaCleanerPageMobile')
);

export const PopupsNew = memo(() => (
    <Portal>
        <PopupsCommon />
        <ReduxPopup {...getPopupProps({ name: popupNames.DISABLED_FEATURE, component: DisabledFeatureDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.RECOGNITION_POPUP, component: RecognitionPopup })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.NEW_RECOGNITION_POPUP, component: NewRecognitionPopup })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.PERSONAL_DOCUMENT_GROUPS, component: PersonalDocumentGroups })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SELECT_FOLDER_DIALOG, component: SelectFolderConnected })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.FILE_HISTORY, component: FileHistory })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.REMOVE_DIALOG, component: RemoveDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.RENAME_DIALOG, component: BaseInputDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.ASK_NAME_DIALOG, component: BaseInputDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOUNT_DIALOG, component: SharingNewMount })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.UNMOUNT_DIALOG, component: SharingNewUnMount })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.REJECT_DIALOG, component: SharingNewReject })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.DOWNLOAD_MOBILE, component: DownloadDropdown })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.CREATE_FOLDER, component: NewFolderModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.CREATE_DOCUMENT, component: NewDocumentModal })} />
        <ReduxPopup
            {...getPopupProps({ name: popupNames.SHARING_DIALOG, component: IS_ONPREMISE || IS_BIZ_USER ? SharingNewBiz : SharingNew })}
        />
        <ReduxPopup {...getPopupProps({ name: popupNames.BIZ_PUBLIC_PASSWORD_DIALOG, component: PublicPasswordDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.BIZ_DELETE_PUBLIC_PASSWORD, component: DeletePublicPasswordDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SHARING_WEBLINK_COLLAPSED_USER_LIST, component: SharingNewCollapsedDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.OVERQUOTA_BANNER, component: OverquotaPopup })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.OVERQUOTA_MODAL, component: OverquotaModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.OVERQUOTA_SPLASH, component: OverquotaSplash })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_OPTIONS, component: OptionsModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_VIEWER, component: MobileViewer })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_FILTER_AUTHOR, component: FilterAuthorsModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_FILTER_FACES, component: FilterFacesModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.NO_WARNING, component: NoWarningDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.AUTH_MODAL, component: AuthModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_SUSPICIOS_DIALOG, component: SuspiciousDialogMobile })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SELECT_ALBUM, component: SelectAlbumPopup })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SUSPICIOS_DIALOG, component: SuspiciousDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SASS_MOBILE_MENU, component: SassMobileMenu })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.QUOTA_MOBILE_NAVIGATION, component: MobileNavigationPopup })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SASS_QUOTA_MOBILE_NAVIGATION, component: SassMobileNavigationPopup })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.DISABLE_ADS_PROMO, component: DisableAdsPromo })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_FILE_INFO_MODAL, component: FileInfoModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.BANNER_OFFER, component: BannerOffer })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.BANNER_OFFER_REDESIGN, component: BannerOfferRedesign })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.CLEAR_TRASHBIN, component: EmptyTrashBin })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_DOWNLOAD_APP, component: DownloadAppModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SELECT_FROM_CLOUD_DIALOG, component: SelectFromCloudPopup })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.DELETE_CONFIRMATION_DIALOG, component: DeleteConfirmationDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.QUOTA_CLEANER_PAGE, component: QuotaCleanerPage })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.QUOTA_CLEANER_PAGE_MOBILE, component: QuotaCleanerPageMobile })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.UPLOAD_BIG_FILE, component: UploadBigFileDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.QUOTA_POPUP, component: QuotaPopup })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.PRE_COPY_WEBLINK, component: PreCopyWeblinkDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_USER_CHANGE_MODAL, component: UserChangeModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_USER_LOGIN_MENU, component: UserLoginMenu })} />
        {IS_ONPREMISE && <ReduxPopup {...getPopupProps({ name: popupNames.VIOLATED_FILENAME, component: ViolatedFilenameDialog })} />}
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_SEARCH_HISTORY_NOTIFICATION, component: SearchHistoryNotification })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_SEARCH_NOTIFICATION, component: SearchNotification })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.CO_EDIT_ALERT, component: CoEditPublicAlertPopup })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_CLOUD_NEW_FEATURES, component: CloudNewFeaturesModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.ASIDE_PROMO_MODAL, component: AsidePromoModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.BUY_BUSINESS_DIALOG, component: BuyBusinessDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.AUTH_DIALOG, component: AuthDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SMALL_WINDOW_WARNING_DIALOG, component: SmallWindowWarningDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.NOT_DOCUMENT_MODAL, component: NotDocumentModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.DELETE_PAGE_MODAL, component: DeletePageModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.DELETE_PAGE_REJECTED_MODAL, component: DeletePageRejectedModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.YEAR_FILTER_MODAL, component: YearFilterModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.CREATE_COPY_OF_NONEDITABLEFILE, component: CreateCopyOfNonEditableFileModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.PROMOCODE_RESULT, component: PromocodeResultDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.IMAGE_EDITOR, component: ImageEditorSurvey })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.EBOOK_READER, component: EbookReaderDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SLIDER_MODAL, component: SliderModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.FAMILY_INVITE_MODAL, component: FamilyInviteModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.FAMILY_ACCEPT_INVITE, component: FamilyAcceptInviteDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.FAMILY_ERROR, component: FamilyErrorDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_PUBLIC_BANNER, component: MobilePublicPromoBanner })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.CONVERT_FB2_TO_EPUB, component: ConvertFb2ToEpubDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SAFE_FAKEDOOR, component: SafeFakedoorModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_REQUIRED_SIGNUP, component: RequiredSignUpDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.CREATE_NEW_DOCUMENT_BY_LINK, component: CreateDocumentByLinkDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_APP_QR, component: PopupMobileAppQR })} />

        {/* tempexp_16800-next-line */}
        <ReduxPopup {...getPopupProps({ name: popupNames.DETAILED_TARIFF_BENEFITS, component: DetailedTariffBenefitsPopup })} />
        {/* tempexp_17086-next-line */}
        <ReduxPopup {...getPopupProps({ name: popupNames.WELCOME_DEFAULT_TARIFF_SPLASH, component: WelcomeDefaultTariffSplash })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.UNSAVED_CHANGES_MODAL, component: UnsavedChangesModal })} />
        <ReduxPopup
            {...getPopupProps({ name: popupNames.SHARING_PUBLIC_THEME_TARIFFS_MODAL, component: SharingPublicThemeTariffsModal })}
        />
        <ReduxPopup {...getPopupProps({ name: popupNames.REBRANDING_PROMO_MODAL, component: RebrandingPromoModal })} />
        {/* tempexp_17532-next-line */}
        <ReduxPopup {...getPopupProps({ name: popupNames.LITRES_MODAL, component: LitresModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.REFERRAL_PROGRAM_PROMO_MODAL, component: ReferralProgramPromoModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.THANKS_MODAL, component: ThanksModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.DELETE_PUBLIC_PASSWORD, component: DeletePublicPasswordConfirmModal })} />
        {/* tempexp_17825-next-line */}
        <ReduxPopup {...getPopupProps({ name: popupNames.UNLINK_CARD_CONFIRM_MODAL, component: UnlinkCardConfirmModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.PUBLIC_PASSWORD_TARIFF_MOBILE, component: PublicPasswordTariffModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.PUBLIC_PASSWORD_SET_MOBILE, component: PublicPasswordSetModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.PUBLIC_PASSWORD_DELETE_MOBILE, component: PublicPasswordDeleteModal })} />
        {/* tempexp_17581-next-line */}
        <ReduxPopup {...getPopupProps({ name: popupNames.DISCOUNT_IN_OUTFLOW_MODAL, component: DiscountInOutflowModal })} />
        {/* tempexp_17906-next-line */}
        <ReduxPopup {...getPopupProps({ name: popupNames.TARIFF_FEATURES_LIST_MOBILE, component: FeaturesModal })} />
        <ReduxPopup
            {...getPopupProps({ name: popupNames.RESTORE_TRASHBIN_FILES_THANKS_MODAL, component: RestoreTrashbinFilesThanksModal })}
        />
        <ReduxPopup {...getPopupProps({ name: popupNames.RESTORE_TRASHBIN_FILES_MODAL, component: RestoreTrashbinFilesModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.USED_SPACE_POSTPAID_THANKS_MODAL, component: UsedSpacePostpaidModalThanks })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.USED_SPACE_POSTPAID_MODAL, component: UsedSpacePostpaidModal })} />
        {/* tempexp_18238_next-line */}
        <ReduxPopup {...getPopupProps({ name: popupNames.FAMILY_AFTER_PURCHASE_MODAL, component: FamilyAfterPurchaseModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.THANKS_BALLOON, component: ThanksBalloon })} />
    </Portal>
));

PopupsNew.displayName = 'PopupsNew';
