import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { favorableTariffs, isRebrandingQuotaLanding } from 'reactApp/appHelpers/featuresHelpers';
import { cheapestTariff } from 'reactApp/appHelpers/featuresHelpers/features/cheapestTariff';
import { getPaySource } from 'reactApp/modules/payment/payment.analytics';
import { openTariffBuy } from 'reactApp/modules/payment/payment.module';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';
import { getQuotaBillingSource, sendQuotaGa } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { revealPlatformPopup } from 'reactApp/sections/QuotaLanding/TariffsPlans/TariffPlansCard/TariffPlansCard.helpers';
import { getTariffsList } from 'reactApp/sections/QuotaLanding/TariffsPlans/TariffsPlans.selectors';
import type { RootState } from 'reactApp/store';
import type { ITariffsByTab } from 'reactApp/ui/TariffSectionNew/TariffsSection.types';
/**
 * Хук покупки рекомендованного тарифа "Единой квоты"
 * Используется в чистилке как тариф по умолчанию
 */
export const useBuyCleanerTariff = (
    isMobilePayAvailable = false,
    isIOSPromo = false,
    isAndroidPromo = false
): {
    tariffId?: string;
    tariffPrice?: number;
    tariffValue?: string;
    onBuy: () => void;
} => {
    const dispatch = useDispatch();
    const tariffList = useSelector((state: RootState) => getTariffsList(state, ''));
    const isMobile = useSelector(QuotaLandingSelectors.isMobile);

    const recommendedTariff: Partial<ITariffsByTab> = useMemo(() => {
        // tempexp_16598-start
        if (cheapestTariff) {
            const recommended = favorableTariffs.length ? 'W1T' : 'W256G';
            return (
                (tariffList as ITariffsByTab[])?.find(
                    (tariff) => tariff?.products?.monthProduct && tariff.products.monthProduct?.id.includes(recommended)
                ) || {}
            );
        }
        // tempexp_16598-end
        return (tariffList as ITariffsByTab[])?.find((tariff) => tariff.isRecommended && tariff.products.monthProduct) || {};
    }, [tariffList]);

    const { id, price, discountPrice, space } = recommendedTariff?.products?.monthProduct || {};

    const onSuccess = useCallback(() => {
        sendQuotaGa({ action: 'tariff', label: 'buy', tariff: id, amount: discountPrice || price });
    }, [id, price, discountPrice]);

    const onBuy = useCallback(() => {
        if ((isAndroidPromo || isIOSPromo) && !isMobilePayAvailable && id && isMobile) {
            revealPlatformPopup(id, isIOSPromo, isAndroidPromo, isRebrandingQuotaLanding);
            return;
        }
        dispatch(
            openTariffBuy({
                productId: id,
                source: getQuotaBillingSource(),
                onSuccess,
                isMobile,
                isQuotaCleaner: true,
                paySource: getPaySource('cleaner_promo'),
            })
        );
    }, [dispatch, id, onSuccess]);

    return {
        tariffId: id,
        tariffPrice: discountPrice || price,
        tariffValue: space?.value,
        onBuy,
    };
};
