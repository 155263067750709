export interface BillingDataResponse {
    data: BillingData;
    services: BillingDataServices;
    paid_info: BillingPaidInfo;
}

export enum BillingDataPlatform {
    web = 'web',
    ios = 'ios',
    android = 'android',
    reward = 'reward',
    base = 'base',
    huawei = 'huawei',
    rustore = 'rustore',
}

export enum BillingDataPartner {
    disco = 'disco',
    rostelecom = 'rostelecom',
    mail = 'ru.mail.special',
    tele2 = 'tele2',
    megafon = 'megafon',
    sferum = 'sferum',
}

export enum BillingDataStatus {
    ACTIVE = 'A',
    FINISHED = 'F',
    SUSPENDED = 'S',
    GRACE = 'G',
    HOLD = 'H', // Пока нет
    LAPSED = 'L',
    WAITING = 'W',
}

export enum BillingDataType {
    TRIAL = 'trial',
    PAID = 'paid',
    DISCOUNT = 'discount',
    PREPAID = 'prepaid',
    FREE = 'free',
}

export enum BillingDataPeriod {
    DISCOUNT = 'discount',
    TRIAL = 'trial',
    REGULAR = 'regular',
}

/** Подписки по платформам, подарки, промопериоды и прочее */
interface BillingData {
    web: BillingDataWeb[];
    ios: BillingDataIos[];
    android: BillingDataReward[];
    huawei: BillingDataReward[];
    reward: BillingDataReward[];
    rustore: BillingDataReward[];
    promo: BillingDataPromo[];
    bonus: BillingDataPromo[];
    gift_receiver: BillingDataGiftReceiver[];
    gift_sender: BillingDataGiftSender[];
    purchase: Partial<Record<BillingDataPlatform, Record<string, BillingDataPurchase>>>;
}

export interface BillingDataWebAmounts {
    // Цена покупки подписки (для подписки со скидкой тут будет указана скидочная цена, для подписки с триалом будет 0)
    purchase: number;
    // Цена продления подписки
    renewal: number;
}

export interface BillingDataWeb {
    // ID тарифа
    product: string;
    // ID подписки
    sub_id: string;
    // Время начала действия подписки в формате timestamp
    start: number;
    // Дата окончания подписки в формате timestamp
    finish: number;
    // Дата следущего продления в формате timestamp
    renew_next: number;
    // Продлится ли подписка самостоятельно
    autorenewable: boolean;
    // Можно ли обновить подписку вручную
    renewable?: boolean;
    // Цены подписки
    amounts: BillingDataWebAmounts;
    // Список типов подписки. Доступные значения: paid, prepaid, discount, trial, free
    type: BillingDataType[];
    /** В каком периоде находится сейчас. Или был на момент отмены / завершения */
    period: BillingDataPeriod;
    services: Partial<BillingDataServices>;
    /** Основная стоимость */
    amount: number;
    /** Выдавался ли по промокоду */
    promo: boolean;
    /** Предоплачен или нет (здесь нет информации о периодах предоплаты и пр.) */
    prepaid: boolean;
    status:
        | BillingDataStatus.ACTIVE
        | BillingDataStatus.FINISHED
        | BillingDataStatus.GRACE
        | BillingDataStatus.HOLD
        | BillingDataStatus.WAITING;
}

export interface BillingDataIos extends Omit<BillingDataWeb, 'autorenewable' | 'renew_next' | 'status' | 'amounts'> {
    status: BillingDataStatus.ACTIVE | BillingDataStatus.FINISHED | BillingDataStatus.LAPSED;
}

export interface BillingDataReward {
    // ID продукта
    product: string;
    // ID подписки
    reward_id: string;
    // Дата начала действия подписки в формате timestamp
    start: number;
    // Дата окончания действия подписки в формате timestamp
    finish: number;
    services: BillingDataServices;
    // Платформа/партнер, с которой(го) была получена подписка
    platform: BillingDataPlatform | BillingDataPartner;
    // Предоплачена ли подписка
    prepaid?: boolean;
    status: BillingDataStatus.ACTIVE | BillingDataStatus.FINISHED;
}

interface BillingDataPromo {
    // ID промо периода
    id: string;
    // ID промо акции
    action: string;
    // Дата начала периода в формате timestamp
    start: number;
    // Дата окончания периода в формате timestamp
    finish: number;
    // Квота добавляемая при активации периода
    quota: number;
    services: BillingDataServices;
}

interface BillingDataGiftible {
    product: string;
    services: BillingDataServices;
}

interface BillingDataGiftReceiver {
    giftible: BillingDataGiftible[];
    gift_id: string;
    start: number;
    finish: number;
    create: number;
    source: string;
    platform: BillingDataPlatform;
    /** Был ли получен получателем */
    received: boolean;
}

interface BillingDataGiftSender {
    giftible: BillingDataGiftible[];
    gift_id: string;
    start: number;
    finish: number;
    create: number;
    platform: BillingDataPlatform;
    /** Был ли получен получателем */
    given: boolean;
    /** Был ли погашен (использован) */
    redeemed: boolean;
}

interface BillingDataPurchase {
    payment_id: string;
    product: string;
    product_name: string;
    amount: number;
    ctime: number;
    start: number;
    finish: number;
    refund: number;
    extra: Record<string, any>;
}

export interface BillingPlatformPaidInfo {
    trial: number;
    paid: number;
}

interface BillingPaidInfo {
    cloud?: Record<string, BillingPlatformPaidInfo>;
}

/**
 * Суммарная информация по всем услугам;
 * Каждая группа и каждый элемент в группе требует отдельной работы при покупке подписки
 */
interface BillingDataServices {
    common?: {
        /** Является ли пользователь микро-бизнесом */
        mb_user: boolean;
        /** Отключена ли реклама в Почте/Облаке */
        disable_ads: boolean;
    };
    cloud?: {
        /** Флаги, выставляемые в UDB */
        flags: {
            /** Дает аплоад в 32 гб */
            PAID_UPLOAD: boolean;
            /** Выключает рекламу */
            PAID_ACCOUNT: boolean;
            /** Дает аплоад в 100 ГБ */
            PRO?: boolean;
        };
        /** Базовая квота пользователя */
        basequota?: number;
        /** Добавляемая квота байтах */
        quota: number;
        /** Подарки, выдаваемые при покупке, подарки типа "cloud" создаем в необанке при покупке тарифа */
        gifts?: string[];
        /** Может пользоваться шаблонами документов */
        mb_doc_templates?: boolean;
        // Тарифы, которые пользователь получит при активации партнерской подписки (Если есть данное поле, то остальных полей в cloud не будет)
        unlocked?: {
            // ID веб тарифа
            web: string;
        };
    };
    mail?: {
        /** Может добавлять марки к письмам в почте */
        labels: boolean;
    };
    notes?: {
        /** Может использовать AI помощника в Заметках */
        mb_ai_assistant: boolean;
    };
}
