import { Spacing, Text, Title } from '@vkontakte/vkui';
import img from 'img/tooltip/change-public-design-sharing.png';
import React, { type MouseEvent, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { QA_VALUE } from 'reactApp/components/SharingWindow/Sharing.constants';
import stylesSharing from 'reactApp/components/SharingWindow/Weblink/SharingWeblink.css';
import type { PublishItem } from 'reactApp/modules/modifying/modifying.types';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { ButtonLink } from 'reactApp/ui/ButtonLink/ButtonLink';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';
import { usePublicThemeId } from 'reactApp/ui/PublicThemePreview/hooks/usePublicThemeId';
import { ThemePreview } from 'reactApp/ui/PublicThemePreview/ThemePreview/ThemePreview';
import { sendDwh } from 'reactApp/utils/ga';
import opener from 'reactApp/utils/opener';
import { getPublicUrl } from 'reactApp/utils/urlHelper';

import styles from './ChangeDesign.css';

type ChangeDesignProps = {
    item?: PublishItem;
    forcePromo?: boolean;
};

export const PUBLIC_DESIGN_TOOLTIP_ID = 'changePublicDesignSharing';

export const ChangeDesign = memo<ChangeDesignProps>(({ item, forcePromo = false }) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const dispatch = useDispatch();
    const [showTooltip, setShowTooltip] = useState(false);

    const themeId = usePublicThemeId(item?.weblink || '');

    useEffect(() => {
        if (forcePromo) {
            setShowTooltip(forcePromo);
            dispatch(UserStorageActions.set(PUBLIC_DESIGN_TOOLTIP_ID, new Date().valueOf()));
        }
    }, [forcePromo]);

    useEffect(() => {
        emitAnalyticEvent(AnalyticEventNames.SHARING_BRAND_PAGE_SHOW);
        sendDwh({ eventCategory: 'sharing-brand-page', action: 'show' });
    }, []);

    const onTooltipShow = useCallback(() => {
        emitAnalyticEvent(AnalyticEventNames.SHARING_TOOLTIP_BRAND_PAGE_SHOW);
        sendDwh({ eventCategory: 'sharing-tooltip-brand-page', action: 'show' });
    }, []);

    const onTooltipClose = useCallback(() => {
        emitAnalyticEvent(AnalyticEventNames.SHARING_TOOLTIP_BRAND_PAGE_CLOSE);
        sendDwh({ eventCategory: 'sharing-tooltip-brand-page', action: 'close' });
    }, []);

    const handleChooseTheme = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            emitAnalyticEvent(AnalyticEventNames.SHARING_BRAND_PAGE_CLICK);
            sendDwh({ eventCategory: 'sharing-brand-page', action: 'click' });
            const url = new URL(getPublicUrl(item));
            url.searchParams.append('themeChooser', 'true');
            url.searchParams.append('source', 'sharing');
            opener(url.toString());
            e.currentTarget.blur();
        },
        [item]
    );

    return (
        <div className={stylesSharing.rightItem} data-qa-item={QA_VALUE.changeDesign}>
            <span className={stylesSharing.rightTitle}>
                <div className={styles.titleWrapper}>
                    Оформление для папки:
                    {themeId && <ThemePreview themeId={themeId} className={styles.themePreview} isSharing />}
                </div>
            </span>
            <div className={stylesSharing.dropdown} ref={ref}>
                {item?.weblink && (
                    <ButtonLink hoverUnderline={false} className={styles.buttonLink} onClick={handleChooseTheme}>
                        {themeId ? 'Изменить' : 'Выбрать'}
                    </ButtonLink>
                )}
            </div>
            {showTooltip && (
                <FloatingTooltip
                    target={ref}
                    placement={ETooltipPlacement.right}
                    qaId="change-public-design-sharing-tooltip"
                    withinModal
                    closable
                    closeOnOutsideClick
                    usePortal
                    onShow={onTooltipShow}
                    onClose={onTooltipClose}
                >
                    <div className={styles.tooltip}>
                        <Title className={styles.tooltipTitle}>
                            Оформление с подпиской
                            <br />
                            Mail Space
                        </Title>
                        <Spacing size={4} />
                        <Text className={styles.tooltipText}>
                            Светлый, тёмный или с картинками —
                            <br />
                            задавайте свой цвет настроения
                            <br />в папках, которыми делитесь
                        </Text>
                        <Spacing size={4} />
                        <img src={img} alt="Оформление с подпиской Mail Space" className={styles.image} />
                        <Text className={styles.infoText}>Функция доступна только на компьютере</Text>
                    </div>
                </FloatingTooltip>
            )}
        </div>
    );
});

ChangeDesign.displayName = 'ChangeDesign';
