import React, { type FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { EFrom } from 'reactApp/components/SharingWindow/Sharing.types';
import { renderUnpublishWeblinkTabDialog } from 'reactApp/components/SharingWindow/Weblink/SharingWeblink.helpers';
import type { PublishItem } from 'reactApp/modules/modifying/modifying.types';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { weblinkDeleteEmailAccess } from 'reactApp/modules/weblinkAccessControl/weblinkAccessControl.actions';
import { getACL } from 'reactApp/modules/weblinkAccessControl/weblinkAccessControl.selectors';
import { dispatch } from 'reactApp/react-into-toolkit-block-wrapper/renderComponentInExternalDom';
import { Button, ButtonSizeMode, ButtonVariant } from 'reactApp/ui/Button/Button';
import { sendGa } from 'reactApp/utils/ga';

import { SharingNewWeblinkList } from '../WeblinkList/SharingNewWeblinkList';
import styles from './SharingNewCollapsedDialog.css';

interface CollapsedContentProps {
    isPhone?: boolean;
    isReadOnly: boolean;
    item?: PublishItem;
}

export const SharingNewCollapsedContent: FC<CollapsedContentProps> = ({ isPhone, item, isReadOnly }) => {
    const itemStorage = useSelector(getCurrentStorage);
    const storage = item?.storage || itemStorage;
    const acList = useSelector(getACL);

    const onActionClick = useCallback(() => {
        sendGa('unpublish');
        if (storage === EStorageType.attaches) {
            sendGa('attaches-unpublish');
        }

        if (!item || !item.weblink) {
            return;
        }

        acList.forEach((user) => dispatch(weblinkDeleteEmailAccess({ email: user.email, weblink: item.weblink! })));
    }, [storage, item, acList]);

    const onCloseModalClick = useCallback(() => {
        closePopupHelper(popupNames.SHARING_WEBLINK_COLLAPSED_USER_LIST);
    }, []);

    const onCloseAccessClick = useCallback(() => {
        renderUnpublishWeblinkTabDialog({ onActionClick });
        onCloseModalClick();
    }, [onActionClick, onCloseModalClick]);

    return (
        <>
            <h3 className={styles.heading}>У кого есть доступ</h3>
            <SharingNewWeblinkList item={item} isPhone={isPhone} isReadOnly={isReadOnly} isTabsDesign isEmbeded from={EFrom.WEBLINK} />
            <div className={styles.buttons}>
                <Button theme={'base'} middle onClick={onCloseModalClick} sizeMode={isPhone ? ButtonSizeMode.big : ButtonSizeMode.middle}>
                    <span>Вернуться назад</span>
                </Button>
                <Button
                    className={styles.closeBtn}
                    variant={ButtonVariant.flat}
                    theme={'base'}
                    middle
                    onClick={onCloseAccessClick}
                    sizeMode={isPhone ? ButtonSizeMode.big : ButtonSizeMode.middle}
                >
                    <span>Закрыть доступ всем</span>
                </Button>
            </div>
        </>
    );
};
