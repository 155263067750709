import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_MOBILE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

const spaceButtonValue: 'a' | 'b' = getFeature('ab-space-button');

if (!IS_MOBILE_BROWSER) {
    registerExperiment('space-btn', spaceButtonValue, [
        AnalyticEventNames.QUOTA_BLOCK_SPACE_BUTTON_VIEW,
        AnalyticEventNames.QUOTA_BLOCK_SPACE_BUTTON_CLICK,
        AnalyticEventNames.QUOTA_BLOCK_MENU_CLICK,
    ]);
}

export const abSpaceButton = !IS_MOBILE_BROWSER && spaceButtonValue === 'b';
