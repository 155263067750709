import { extInfo } from 'lib/extInfo';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { getFeatureAlbumsPage, getFeatureCreatePublicAlbum } from 'reactApp/modules/features/features.selectors';
import { getCurrentRouteId, getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { RootState } from 'reactApp/store';
import type { BreadcrumbsListItem } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { createSelector } from 'reselect';

import { CUSTOM_ALBUMS_ID } from './albums.constants';
import { isAlbumAllowedExtension } from './albums.helpers';
import { type AlbumItem, type IAlbum, type IAlbumsState, isAlbum } from './albums.types';

const getAlbumsState = (state: RootState): IAlbumsState => state.albums;

export const getAlbumList = (state: RootState) => getAlbumsState(state).list;

export const getAlbumsItemById = createSelector(
    getAlbumList,
    (state, id) => id,
    (list, id): AlbumItem | undefined => list[id]
);

export const getCustomAlbumsIds = createSelector(getAlbumList, (list): string[] => {
    const item = list[CUSTOM_ALBUMS_ID];

    return isAlbum(item) ? item?.childs || [] : [];
});

export const getAlbumPath = createSelector(getAlbumsItemById, getAlbumList, (item, list) => {
    const paths: string[] = [];

    let albumIter = item;
    while (albumIter) {
        paths.push(albumIter.id);
        albumIter = list[albumIter.parent];
    }

    return paths.reverse().join('/');
});

export const isCreatePublicAlbumsEnabled = (state, items): boolean => {
    const currentStorage = getCurrentStorage(state);
    // В разделе альбомов нужно показывать кнопку поделиться, но только с включенной фичей
    if (items[0]?.type === 'album' && currentStorage === EStorageType.albums) {
        return Boolean(getFeatureCreatePublicAlbum(state));
    }

    if (items?.length < 2) {
        return false;
    }

    if (!getFeatureCreatePublicAlbum(state)) {
        return false;
    }

    return items.every((item) => isAlbumAllowedExtension(item?.ext));
};

export const getCurrentAlbum = createSelector(getCurrentRouteId, getAlbumList, getCurrentStorage, (routeId, list, storage) => {
    if (storage !== EStorageType.albums) {
        return undefined;
    }

    return list[routeId] as IAlbum;
});

export const isAddToAlbumsEnabled = (state, items): boolean => {
    if (!getFeatureAlbumsPage(state)) {
        return false;
    }

    const currentAlbum = getCurrentAlbum(state);

    if (currentAlbum && items?.length && items?.every((item) => !isAlbum(item))) {
        return false;
    }

    const currentStorage = getCurrentStorage(state);
    if (currentStorage === EStorageType.albums) {
        return true;
    }

    if (!items?.length) {
        return false;
    }

    return items.every((item) => isAlbumAllowedExtension(item?.ext));
};

export const getCustomAlbumsList = createSelector(
    getAlbumList,
    getCustomAlbumsIds,
    (list, ids) => (ids.map((id) => list[id]).filter(Boolean) || []) as IAlbum[]
);

export const getSharedAlbums = createSelector(getCustomAlbumsList, (list) => list.filter((item) => Boolean(item.weblink)));

export const getAlbumCursor = createSelector(getCurrentAlbum, (album): string | undefined => (isAlbum(album) ? album.cursor : undefined));

export const getCurrentAlbumList = createSelector(getCurrentAlbum, (album) => (isAlbum(album) ? album?.childs || [] : []));

export const isAlbumsLoading = createSelector(getCurrentAlbum, (album): boolean =>
    album ? Boolean(isAlbum(album) && !album?.isLoaded && album?.isLoading) : true
);

export const isAlbumFolderCustom = createSelector(getCurrentAlbum, (album): boolean =>
    isAlbum(album) ? album?.id === CUSTOM_ALBUMS_ID : false
);

export const getAlbumsBreadcrumbs = createSelector(getCurrentAlbum, getAlbumList, (album, list) => {
    const rootBreadcrum = {
        name: 'Альбомы',
        id: '/albums',
        kind: 'storage' as const,
        storage: EStorageType.albums,
    };

    if (!album) {
        return [rootBreadcrum];
    }

    const result: BreadcrumbsListItem[] = [];

    let albumIterator = album as AlbumItem;
    while (albumIterator) {
        result.push({
            name: albumIterator.name,
            id: albumIterator.id,
            storage: EStorageType.albums,
            kind: 'folder',
        });

        albumIterator = list[albumIterator.parent];
    }

    result.push(rootBreadcrum);

    return result.reverse();
});

export const getAlbumAllowedExtensions = (): string[] => extInfo.getExtsForAlbums();

export const getAlbumIdsParams = createSelector(
    (state) => state,
    getCurrentAlbum,
    (state, idList) => idList,
    (state, currentAlbum, idList: string[]) => {
        if (!idList?.length) {
            return;
        }

        const albumId = idList[0];
        const album = getAlbumsItemById(state, albumId);

        if (idList.length === 1 && isAlbum(album)) {
            return { id: albumId };
        }

        return currentAlbum ? { id: currentAlbum.id, album_list: idList } : undefined;
    }
);

export const getUploadAlbumId = createSelector(getAlbumsState, getCurrentAlbum, (state, currentAlbum) => {
    if (!currentAlbum) {
        return;
    }

    if (currentAlbum.id === ROOT_FOLDER_ID && isAlbum(currentAlbum)) {
        return state.currentUploadAlbum;
    }

    return currentAlbum.id;
});

export const selectFromCloudGetSelected = createSelector(
    getAlbumsState,
    (state, id): string => id,
    (state, id) => state.selectedItems?.[id]
);
