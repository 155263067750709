import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectHistoryPath } from 'reactApp/modules/router/router.selectors';

interface ChangeHistoryProps {
    // Маршруты, которые игнорируются при редиректе
    ignorePaths?: string[];
}

export const ChangeHistory = ({ ignorePaths = [] }: ChangeHistoryProps) => {
    const historyPath = useSelector(selectHistoryPath);

    const decodedHistoryPath = decodeURI(historyPath || '');
    const isUnreachablePath = ignorePaths.includes(decodedHistoryPath);

    const [prevPath, setPrevPath] = useState(historyPath);

    const navigate = useNavigate();
    const location = useLocation();
    const isNewPath = historyPath !== location.pathname + location.search && prevPath !== historyPath;

    useEffect(() => {
        setPrevPath(historyPath);

        if (!historyPath || !isNewPath || isUnreachablePath) {
            return;
        }

        navigate(historyPath);
    }, [historyPath, isNewPath, setPrevPath, isUnreachablePath]);

    return null;
};
