import { ReactComponent as LogoCloud } from 'img/icons/logo_cloud.svg';
import React, { type FC, useRef } from 'react';
import { IS_B2B_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { Logo } from 'reactApp/ui/Header/Logo/Logo';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { MobileAppPromoTooltip } from 'reactApp/ui/ReactViewer/ViewerHeader/MobileAppPromoTooltip';
import opener from 'reactApp/utils/opener';

import styles from './MenuLogo.css';

const handleClick: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    opener('/home', true);
};

export const MenuLogo: FC = () => {
    const logoRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <MobileAppPromoTooltip elementRef={logoRef} />
            {IS_B2B_BIZ_USER ? (
                <Logo className={styles.logo} white />
            ) : (
                <div className={styles.logo} ref={logoRef}>
                    <Hint theme="dark" text="Облако Mail" delay={500} showDelay>
                        <LogoCloud className={styles.icon} height={32} width={32} onClick={handleClick} />
                    </Hint>
                </div>
            )}
        </>
    );
};
