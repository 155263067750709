import { bytesToNDigits } from '@mail/cross-sizes-utils';
import welcomeDialogAllDocumentsImage from 'img/welcome/welcomeDialogAllDocuments.jpg';
import React, { type FC, useCallback } from 'react';
import { BASEQUOTA } from 'reactApp/appHelpers/configHelpers';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { LicenseBlock } from 'reactApp/ui/LicenseBlock/LicenseBlock';

import styles from './WelcomeDialogAllDocuments.css';

export interface WelcomeDialogProps {
    onSuccess: () => void;
    onShow: () => void;
    onClose: () => void;
}

export const WelcomeDialogAllDocuments: FC<WelcomeDialogProps> = ({ onSuccess, onShow, onClose }) => {
    const handleClick = useCallback(() => {
        onSuccess();
        onClose();
    }, [onSuccess, onClose]);

    return (
        <WhatsNewDialog
            contentClassName={styles.content}
            imageWrapperClassName={styles.imageWrapper}
            textClassName={styles.text}
            footerClassName={styles.footer}
            primaryButtonText="Хорошо"
            onShow={onShow}
            onClick={handleClick}
            onClose={onClose}
            title="Работайте с документами онлайн"
            text={
                <>
                    Редактируйте и просматривайте файлы вместе с коллегами и&nbsp;друзьями, а {bytesToNDigits(BASEQUOTA, 3).value} в Облаке
                    на старте сохранят все важные изменения
                </>
            }
            imageUrl={welcomeDialogAllDocumentsImage}
            buttonTheme="vk"
            closable={false}
            footerText={<LicenseBlock buttonText="Хорошо" />}
        />
    );
};
