import { IS_MOBILE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { sendDwh } from 'reactApp/utils/ga';

type sendPublicPasswordAnalyticsArgs = {
    eventCategory?: string;
    action: string;
};

export enum PublicPasswordEventCategory {
    passwordFolder = 'password-folder',
    deletePassword = 'delete-password',
    tooltipPassword = 'tooltip-password',
    publicFolderGuest = 'password-folder-guest',
}

export const sendPublicPasswordAnalytics = ({
    eventCategory = PublicPasswordEventCategory.passwordFolder,
    action,
}: sendPublicPasswordAnalyticsArgs) => {
    sendDwh({
        eventCategory,
        action,
        dwhData: {
            source: IS_MOBILE_BROWSER ? 'touch' : 'desktop',
        },
    });
};
