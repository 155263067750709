/* tempexp_17604-start */
import { useSelector } from 'react-redux';
import { uploadBigFileModalTariffs } from 'reactApp/appHelpers/featuresHelpers/features/uploadBigFileModal';
import { type TariffInfo, EShowTariffType } from 'reactApp/components/UploadBigFileDialog/UploadBigFileDialog.types';
import { PaidInfoSelectors } from 'reactApp/modules/paidInfo/paidInfo.selectors';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { EProductPeriod } from 'reactApp/modules/products/products.types';
import type { RootState } from 'reactApp/store';
import type { Product } from 'reactApp/types/Billing';

export const useTariffAb = (fileSizeBytes: number): TariffInfo => {
    const trial = useSelector((state: RootState) => ProductsSelectors.getProductById(state, uploadBigFileModalTariffs.trial)) as Product;
    const forceTrial = useSelector((state: RootState) =>
        ProductsSelectors.getProductById(state, uploadBigFileModalTariffs.forceTrial)
    ) as Product;
    const defaultProduct = useSelector((state: RootState) =>
        ProductsSelectors.getProductById(state, uploadBigFileModalTariffs.default)
    ) as Product;

    const { product: productByQuota } = useSelector((state: RootState) =>
        ProductsSelectors.getProductAndTariffByQuota(state, fileSizeBytes, EProductPeriod.month, false)
    );

    const isUserWithoutPayment = useSelector(PaidInfoSelectors.isUserWithoutPayment);
    const paidLessThan3Month = useSelector((state: RootState) => PaidInfoSelectors.lastPaymentWasLessThan(state, { months: 3 }));

    // если у юзера не было оплат, то обычный, если были - форс триал
    const trialForUser = isUserWithoutPayment ? trial : forceTrial;
    // если загружаемая квота не влезет в обычный тариф, то выбираем тариф исходя из квоты
    const productForUser = fileSizeBytes < defaultProduct?.space.original ? defaultProduct : productByQuota;

    // если юзер без оплат или платил больше 3ех месяцев назад, и загружаемая квота влезает в триал
    const isTrialUser =
        (!paidLessThan3Month || isUserWithoutPayment) && Boolean(trialForUser) && fileSizeBytes < trialForUser?.space.original;

    return {
        trial: trialForUser,
        usualProduct: productForUser,
        showTariffType: isTrialUser ? EShowTariffType.TRIAL : EShowTariffType.USUAL_TARIFF,
    };
};
/* tempexp_17604-end */
