import classNames from 'clsx';
import throttle from 'lodash.throttle';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { noop } from 'reactApp/utils/helpers';
import useResizeObserver from 'use-resize-observer/polyfilled';

import styles from './PaginatorLine.css';

const AUTO_PLAY_TIME_S = 6;

interface Props {
    className?: string;
    count: number;
    currentIdx: number;
    onClick?({ itemIndex: number });
    transitionTime?: number;
    isPaused?: boolean;
    nextBlockId?: number | null;
    id?: string;
}

export const PaginatorLine = memo(function PaginatorLine({
    className,
    onClick = noop,
    count,
    currentIdx,
    transitionTime,
    isPaused = false,
    nextBlockId,
    id,
}: Props): JSX.Element | null {
    const [size, setSize] = useState({ width: 0 });
    const handleOnResize = useMemo(() => throttle(setSize, 200), [setSize]);
    const stripes = useMemo(() => new Array<number>(count).fill(0), [count]);
    const { ref } = useResizeObserver<HTMLDivElement>({ onResize: handleOnResize });

    /**
     * HACK CLOUDWEB-18414
     * Принудительно сбрасываем анимацию прогресс-бара сторизов, так как при переходе между подборками не происходит ре-рендер
     */
    const animatedStripeRef = useRef<HTMLDivElement>();
    useEffect(() => {
        if (!animatedStripeRef.current) {
            return;
        }

        animatedStripeRef.current.style.animationName = 'none';

        setTimeout(() => {
            if (animatedStripeRef.current) {
                animatedStripeRef.current.style.animationName = '';
            }
        }, 0);
    }, [nextBlockId, id, count, currentIdx]);

    const handleOnClick = useCallback(
        (event) => {
            const width = size.width;

            if (!width || !count) {
                return;
            }

            const currentTargetRect = event.target?.offsetParent?.getBoundingClientRect() ?? 0;
            const itemIndex = Math.floor(((event.clientX - currentTargetRect.left) / width) * count);

            onClick({ itemIndex });
        },
        [size, count]
    );

    const onAnimationEnd = useCallback(() => {
        onClick({ itemIndex: currentIdx === count - 1 ? nextBlockId : currentIdx + 1 });
    }, [currentIdx, count, nextBlockId]);

    return (
        <div className={classNames(styles.root, className)} ref={ref} onClick={handleOnClick}>
            {stripes.map((_, idx) => {
                const isFilled = idx < currentIdx && count > 0;
                const animateCurrent = size.width > 0 ? idx === currentIdx : false;
                let style = {};
                let ref;
                if (animateCurrent) {
                    style = {
                        animationDuration: `${transitionTime || AUTO_PLAY_TIME_S}s`,
                    };
                    ref = animatedStripeRef;
                }

                return (
                    <div
                        style={style}
                        ref={ref}
                        key={idx}
                        className={classNames({
                            [styles.stripe]: true,
                            [styles.stripe_filled]: isFilled,
                            [styles.stripe_animate]: animateCurrent,
                            [styles.stripe_animate_pause]: animateCurrent && isPaused,
                        })}
                        onAnimationEnd={animateCurrent ? onAnimationEnd : undefined}
                    />
                );
            })}
        </div>
    );
});

PaginatorLine.displayName = 'PaginatorLine';

PaginatorLine.whyDidYouRender = true;
