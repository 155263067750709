import classNames from 'clsx';
import { parse } from 'qs';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { useDarkTheme } from 'reactApp/hooks/useDarkTheme';
import { BillingBuyTemplates } from 'reactApp/modules/buySubscription/buySubscription.types';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { useRenewFromQuery } from 'reactApp/sections/MobileQuotaTariffsLanding/hooks/useRenewFromQuery';
import { AddCardModal } from 'reactApp/ui/AddCardModal/AddCardModal';
import { Loader } from 'reactApp/ui/Loader/Loader';
import { TabMenu } from 'reactApp/ui/Menu/TabMenu';
import { ETabMenuMod } from 'reactApp/ui/Menu/TabMenu.types';
import { TabMenuItem } from 'reactApp/ui/Menu/TabMenuItem';
import { renderSubscriptionsModal } from 'reactApp/ui/Mobile/SubscriptionsModal/SubscriptionsModal.helpers';
import { Toolbar } from 'reactApp/ui/Mobile/Toolbar/Toolbar';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { EQueryParams } from 'server/helpers/getRequestParams';

import { Discounts } from './Discounts/Discounts';
import { useCardHandlers } from './hooks/useCardHandlers';
import { useDiscounts } from './hooks/useDiscounts';
import { useOnPageLoad } from './hooks/useOnPageLoad';
import { TABS, TABS_MAP } from './MobileSubscriptionsPage.constants';
import styles from './MobileSubscriptionsPage.css';
import { getActiveTab, getAvailableHash } from './MobileSubscriptionsPage.helpers';
import {
    useLoading,
    useLoadProductsOrLogin,
    useMarketingPromo,
    useProductFromQuery,
    useRemoveCardModal,
    useTariffs,
} from './MobileSubscriptionsPage.hooks';
import type { TabValue } from './MobileSubscriptionsPage.types';
import { Tariffs } from './Tariffs/Tariffs';

export const MobileSubscriptionsPage = memo(() => {
    const tariffs = useTariffs();
    const discounts = useDiscounts();
    const location = useLocation();
    const { marketingPromo } = useMarketingPromo();
    const hash = getAvailableHash(location.hash, marketingPromo);
    const isLoading = useLoading();
    const { showAddCardModal, onSuccessAddCard, onCloseAddCard, onUnlinkCard } = useCardHandlers();
    const [activeTab, setActiveTab] = useState<TabValue>(() => getActiveTab(hash));

    const isWebview = useSelector(EnvironmentSelectors.isWebview);
    const activeTabWithHash = `#${activeTab}`;
    const showTariff = TABS_MAP.usual === activeTabWithHash || TABS_MAP.special === activeTabWithHash;
    const showDiscounts = TABS_MAP.discounts === activeTabWithHash;

    const isPromoActionPage = Boolean(marketingPromo) && showTariff && !isRebranding;

    const subsPopupShown = useRef<boolean>(false);

    useEffect(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'touch-subscriptions' });
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'page-entered', source: 'touch-subscriptions' });
    }, []);

    useEffect(() => {
        const search = parse(window.location.search, { ignoreQueryPrefix: true }) || {};
        if (!isLoading && search?.[EQueryParams.my_subscriptions] && !subsPopupShown.current) {
            subsPopupShown.current = true;
            renderSubscriptionsModal({ isRebranding });
        }
    }, [isLoading]);

    useDarkTheme(isRebranding);

    useLoadProductsOrLogin();
    useProductFromQuery();
    useOnPageLoad();
    useRemoveCardModal();
    useRenewFromQuery();

    useEffect(() => {
        setActiveTab(getActiveTab(hash));
    }, [hash]);

    useEffect(() => {
        if (showDiscounts) {
            sendDwh({ eventCategory: ECategoryGa.payment, action: 'action-open', dwhData: { source: 'touch' } });
        }
    }, [showDiscounts]);

    if (isLoading) {
        return (
            <div className={styles.root}>
                <Loader centered />
            </div>
        );
    }

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_actionPromo]: isPromoActionPage && !isRebranding,
                [styles.root_rebranding]: isRebranding,
            })}
        >
            {!isWebview && <Toolbar disableActions showMenu showSubscriptions isRebranding={isRebranding} />}
            {showAddCardModal && (
                <AddCardModal
                    size="tiny"
                    isMobile
                    onSuccess={onSuccessAddCard}
                    onClose={onCloseAddCard}
                    checkoutSkin={BillingBuyTemplates.CLOUD_B2C_MOBILE}
                    onUnlinkCard={onUnlinkCard}
                />
            )}
            <div
                className={classNames(styles.content, {
                    [styles.content_rebranding]: isRebranding,
                })}
            >
                {isRebranding ? (
                    <div
                        className={classNames(styles.title, {
                            [styles.title_rebranding]: isRebranding,
                        })}
                    >
                        Mail Space — сервисы с&nbsp;новыми опциями
                    </div>
                ) : (
                    <div
                        className={styles.title}
                        dangerouslySetInnerHTML={{
                            __html: isPromoActionPage
                                ? marketingPromo?.mobileTitle || marketingPromo?.title || 'Объём Облака'
                                : 'Объём Облака',
                        }}
                    />
                )}

                {isPromoActionPage && Boolean(marketingPromo?.text) && <div className={styles.description}>{marketingPromo?.text}</div>}
                <TabMenu isMobile mod={ETabMenuMod.mobileSubscription}>
                    <div
                        className={classNames(styles.tabs, {
                            [styles.tabs_rebranding]: isRebranding,
                        })}
                    >
                        {TABS.map(({ name, value, match }) => (
                            <TabMenuItem
                                key={value}
                                name={name}
                                value={value}
                                active={match.includes(activeTab)}
                                onClick={setActiveTab}
                                mod={ETabMenuMod.mobileSubscription}
                            />
                        ))}
                    </div>
                </TabMenu>
                {showTariff && <Tariffs marketingPromo={marketingPromo} tariffs={tariffs} isRebranding={isRebranding} />}
                {showDiscounts && <Discounts marketingPromo={marketingPromo} discounts={discounts} subscriptionsPage />}
            </div>
        </div>
    );
});

MobileSubscriptionsPage.displayName = 'MobileSubscriptionsPage';
