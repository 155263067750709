import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { SubscriptionRenewalActions } from 'reactApp/modules/subscriptionRenewal/subscriptionRenewal.module';
import type { ISubscription } from 'reactApp/modules/subscriptions/subscriptions.types';
import { SUBSCRIPTION_ANALYTICS } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscription.analytics';
import type { HardlyUsedThunkDispatch } from 'reactApp/types/app';
import { sendDwh } from 'reactApp/utils/ga';

export const useHandlers = (subscription: ISubscription, onClose: () => void) => {
    const dispatch = useDispatch<HardlyUsedThunkDispatch>();
    const { expires, productId, start, partner } = subscription;

    const dwhData = useMemo(
        () => ({
            product: productId,
            start_date: start,
            expired_date: expires,
            source: 'touch',
            plarform: String(EnvironmentSelectors.getMobileType()).toLowerCase(),
        }),
        [expires, productId, start]
    );

    const handleUpdateCard = useCallback(() => {
        dispatch(
            SubscriptionRenewalActions.addOrUpdateCardMobile({
                dwhData,
                closeSubscriptionCard: onClose,
            })
        );
    }, [dispatch, dwhData, onClose]);

    const handleCancelNextPayment = useCallback(
        (isRebrandingQuotaLanding?: boolean, isRebranding?: boolean) => {
            dispatch(
                SubscriptionRenewalActions.cancelAutoRenewMobile({
                    subscription,
                    dwhData,
                    closeSubscriptionCard: onClose,
                    isRebranding,
                    isRebrandingQuotaLanding,
                })
            );
        },
        [dispatch, subscription, dwhData, onClose]
    );

    const handleRenewNextPayment = useCallback(() => {
        dispatch(
            SubscriptionRenewalActions.renewMobile({
                closeSubscriptionCard: onClose,
                dwhData,
                subscription,
            })
        );
    }, [dispatch, dwhData, subscription, onClose]);

    const handleGoToSubscription = useCallback(() => {
        sendDwh({
            ...SUBSCRIPTION_ANALYTICS.MY_TARIFF_ADD_SPACE,
            dwhData,
        });

        onClose();
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [dwhData, onClose]);

    const handlePartnerClick = useCallback(() => {
        sendDwh({
            ...SUBSCRIPTION_ANALYTICS.MY_TARIFF_PARTNER_CLICK,
            dwhData: {
                parner_name: partner,
                source: 'touch',
            },
        });
    }, [partner]);

    return {
        handleUpdateCard,
        handleCancelNextPayment,
        handleGoToSubscription,
        handleRenewNextPayment,
        handlePartnerClick,
    };
};
