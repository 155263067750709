import { Spacing, Text } from '@vkontakte/vkui';
import coverDarkImage from 'img/LitresDarkModal.png';
import coverLightImage from 'img/LitresLightModal.png';
import React, { useEffect, useMemo } from 'react';
import { litresEndDate } from 'reactApp/appHelpers/featuresHelpers/features/litres';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';

import styles from './LitresModal.css';

export interface LitresModalProps {
    onClose: () => void;
    onShow: () => void;
    onClick: () => void;
    onCancel: () => void;
}

export function LitresModal({ onClose, onShow, onCancel, onClick }: LitresModalProps) {
    const containsDarkTheme = isDarkThemeModeEnabled();
    const coverImage = containsDarkTheme ? coverDarkImage : coverLightImage;

    useEffect(() => {
        onShow();
    }, []);

    const content = useMemo(() => {
        return (
            <div className={styles.content}>
                <Spacing size={12} />
                <Text className={styles.text}>
                    Сможете читать электронные книги и заодно
                    <br />
                    отключите рекламу в Почте и Облаке
                </Text>
                <Spacing size={24} />
            </div>
        );
    }, []);

    const footer = useMemo(() => {
        return (
            <div className={styles.footer}>
                <span>Реклама. ООО «ВК», ИНН 7743001840. Акция до {litresEndDate}.</span>
                <span>
                    Условия:{' '}
                    <a rel="noreferrer" target="_blank" href="https://vk.cc/cAmio4">
                        https://vk.cc/cAmio4
                    </a>{' '}
                    Erid: 2VtzqxF6XZy
                </span>
            </div>
        );
    }, []);

    return (
        <WhatsNewDialog
            imageUrl={coverImage}
            titleClassName={styles.title}
            imageClassName={styles.image}
            contentClassName={styles.wsncontent}
            title="Литрес на 2 месяца — с подпиской Mail&nbsp;Space"
            content={content}
            primaryButtonText="Выбрать тариф"
            secondaryButtonText="Неинтересно"
            onSecondary={onCancel}
            onClick={onClick}
            onClose={onClose}
            imageBgColor="var(--vkui--color_background_modal)"
            buttonTheme="vk"
            dialogSize="tiny"
            qaId="litres-modal"
            isRebranding
            footerText={footer}
            footerTextBelowButtons
        />
    );
}
