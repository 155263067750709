import classNames from 'clsx';
import { sanitize } from 'dompurify';
import React, { type ReactElement, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IS_MY_TEAM } from 'reactApp/appHelpers/configHelpers';
import { isPersonalAccessSharingEnabled } from 'reactApp/appHelpers/featuresHelpers/features/personalAccessSharing';
import { UserDomesticDropdown } from 'reactApp/components/SharingNewBiz/DomesticDropdown/UserDomesticDropdown';
import { DomainAccess } from 'reactApp/components/SharingNewBiz/SharingNew.types';
import type { Contact } from 'reactApp/modules/contacts/contacts.module';
import { contactsSelectors } from 'reactApp/modules/contacts/contacts.selectors';
import { getFeaturePublicDomestic } from 'reactApp/modules/features/features.selectors';
import { getError, isAccessControlListLoading } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import {
    getError as getWeblinkError,
    isAccessControlListLoading as isWeblinkAccessControlListLoading,
} from 'reactApp/modules/weblinkAccessControl/weblinkAccessControl.selectors';
import type { RootState } from 'reactApp/store';
import { Button } from 'reactApp/ui/Button/Button';
import { InputSuggest } from 'reactApp/ui/InputSuggest/InputSuggest';
import { EInputSuggestTheme } from 'reactApp/ui/InputSuggest/InputSuggest.types';

import styles from './SharingNewAddUser.css';
import { useAccessRights, useSuggestionItem } from './SharingNewAddUser.hooks';
import type { IProps } from './SharingNewAddUser.types';

// нужно добавить проверку на доместик и подождать intra_domain_share
export const SharingNewAddUser = React.memo(function SharingNewAddUser(props: IProps): ReactElement {
    const { folderId = '', publicId, storage, isPhone, isMounted, item, domain, isAlbum, onCleanupExternalUsers } = props;

    const errorMessage = useSelector((state: RootState) =>
        !isPersonalAccessSharingEnabled || item?.isFolder ? getError(state) : getWeblinkError(state)
    );
    const tempSuggest = useSelector(contactsSelectors.getContactSuggestions);
    const loading = useSelector((state: RootState) =>
        !isPersonalAccessSharingEnabled || item?.isFolder ? isAccessControlListLoading(state) : isWeblinkAccessControlListLoading(state)
    );
    const isInputDisabled = loading || isMounted;
    const [isButtonDisabled, setButtonDisabled] = useState<boolean>(true);
    const isBizUser = useSelector(UserSelectors.isBizUser);
    const isCorpUser = useSelector(UserSelectors.isCorpUser);
    const isDomesticFeatureEnabled = useSelector(getFeaturePublicDomestic);
    const showUserDomesticSettings = !isAlbum && (isBizUser || isCorpUser) && Boolean(isDomesticFeatureEnabled);

    const { currentEmail, checked, handleAccessSelection, handleAddUser, renderDropdown, setCurrentEmail } = useAccessRights({
        item,
        folderId,
        publicId,
        domain,
        isInputDisabled,
        onCleanupExternalUsers,
    });
    const [renderItem, handleGetSuggestions] = useSuggestionItem();

    const contactSuggestions =
        checked === DomainAccess.DOMESTIC && tempSuggest
            ? tempSuggest.filter((el: Contact) => {
                  return el.email.includes(domain || '');
              })
            : tempSuggest;

    const handleLoginChange = useCallback((e, { value }) => {
        setCurrentEmail(value);
        setButtonDisabled(!value.length);
    }, []);

    const action = useMemo(() => <div className={styles.dropdown}>{renderDropdown()}</div>, [renderDropdown]);

    return (
        <>
            <form
                className={classNames(styles.root, {
                    [styles.root_phone]: !IS_MY_TEAM && isPhone,
                    [styles.root_phone_vkt]: IS_MY_TEAM && isPhone,
                })}
            >
                <div className={styles.inputRow}>
                    <div className={styles.inputField}>
                        <InputSuggest
                            value={currentEmail}
                            placeholder="Введите ФИО или почту"
                            hasError={Boolean(errorMessage)}
                            suggestions={contactSuggestions}
                            getSuggestionItemId={(item) => item.email}
                            onChange={handleLoginChange}
                            onRequestSuggestions={handleGetSuggestions}
                            disabled={isInputDisabled}
                            focus={false}
                            action={isPhone ? undefined : action}
                            theme={isPhone ? EInputSuggestTheme.vk : undefined}
                            renderSuggestionItem={renderItem}
                            className={styles.inputSuggesst}
                            inputClassName={styles.input}
                        />
                    </div>
                    <div className={styles.buttonWrapper}>
                        <div className={styles.action}>{isPhone ? action : undefined}</div>
                        <Button
                            theme={isPhone ? 'vk' : 'octavius'}
                            primary
                            middle
                            disabled={isInputDisabled || isButtonDisabled}
                            onClick={handleAddUser}
                            className={styles.button}
                        >
                            Пригласить
                        </Button>
                    </div>
                </div>
                {Boolean(errorMessage) && (
                    <div className={styles.errorMessage} dangerouslySetInnerHTML={{ __html: sanitize(errorMessage) }} />
                )}
            </form>
            {showUserDomesticSettings && (
                <UserDomesticDropdown
                    item={props.item}
                    onChecked={handleAccessSelection}
                    checked={checked}
                    domain={domain}
                    storage={storage}
                    disabled={props.item.intraDomainShare}
                />
            )}
        </>
    );
});
