// @todo роутинг
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { IS_DOCUMENTS_DOMAIN } from 'reactApp/appHelpers/configHelpers';
import { ALL_DOCUMENTS_DOMAIN_ROUTES } from 'reactApp/modules/allDocuments/allDocuments.constants';
import { historyPush } from 'reactApp/modules/router/router.module';
import { searchUpdateStartLocation } from 'reactApp/modules/search/search.module';
import { getSearchStartLocation } from 'reactApp/modules/search/search.selectors';
import { getIdByStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { RootState } from 'reactApp/store';

export const useFallbackSearchUrl = (inPortalHeader = false) => {
    const dispatch = useDispatch();

    let location = {
        pathname: IS_DOCUMENTS_DOMAIN ? ALL_DOCUMENTS_DOMAIN_ROUTES.document : getIdByStorage(EStorageType.home),
        search: '',
    };

    if (!inPortalHeader) {
        // для случаев рендера вне контекста react-router
        // это, например, рендер в порталке, то есть почти везде
        try {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            location = useLocation();
        } catch (err) {}
    }

    const { pathname, search } = window.location;

    const id = pathname?.startsWith('/search') || IS_DOCUMENTS_DOMAIN ? location.pathname : pathname;

    const searchStartLocation = useSelector((state: RootState) => getSearchStartLocation(state));

    const setStartLocation = useCallback(() => {
        dispatch(searchUpdateStartLocation({ id, search }));
    }, [dispatch, id, search]);

    const startSearch = useCallback(() => {
        dispatch(searchUpdateStartLocation({ id, search }));
        dispatch(historyPush({ id: getIdByStorage(EStorageType.search) }));
    }, [dispatch, id, search]);

    const closeSearch = useCallback(() => {
        dispatch(historyPush(searchStartLocation));
    }, [dispatch, searchStartLocation]);

    return { setStartLocation, startSearch, closeSearch };
};
