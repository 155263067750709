import { Icon20Cancel } from '@vkontakte/icons';
import { IconButton } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { type FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { CHOSEN_EDITOR, ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { ACTION_PANEL_ID } from 'reactApp/constants/magicIdentificators';
import { useActionPanel } from 'reactApp/hooks/useActionPanel';
import { ActionPanelSelectors } from 'reactApp/modules/actionpanel/actionpanel.selectors';
import { Hint, HintPosition } from 'reactApp/ui/Hint/Hint';

import styles from './ActionPanel.css';

export const ActionPanel: FC = () => {
    const { close } = useActionPanel();

    const isOpened = useSelector(ActionPanelSelectors.isActionPanelOpened);
    const Component = useSelector(ActionPanelSelectors.activeComponent);
    const headerText = useSelector(ActionPanelSelectors.headerText);
    const isEditor = Boolean(CHOSEN_EDITOR);

    const closePanelHandler = useCallback(() => close(), [close]);

    if (isEditor) {
        return null;
    }

    return (
        <div
            id={ACTION_PANEL_ID}
            className={classNames(styles.root, {
                [styles.opened]: isOpened,
                [styles.responsive]: ENABLE_FULL_RESPONSIVE,
            })}
        >
            <div className={styles.content}>
                <div className={styles.header}>
                    <header>{headerText && <>{headerText}</>}</header>
                    <Hint text="Закрыть" positionStrategy={HintPosition.relativeToCursor}>
                        <IconButton className={styles.close} onClick={closePanelHandler} aria-label="Закрыть">
                            <Icon20Cancel />
                        </IconButton>
                    </Hint>
                </div>
                <div className={styles.portal_placeholder}>{Component && <Component />}</div>
            </div>
        </div>
    );
};
