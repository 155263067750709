import React, { type MouseEvent } from 'react';
import { isFamilySubsInTariff, isOnlyWebAdInTariffs } from 'reactApp/appHelpers/featuresHelpers';
import { mailSpaceBenifits } from 'reactApp/appHelpers/featuresHelpers/features/mailSpaceBenefits';
import { getFeaturesModalList } from 'reactApp/sections/MobileQuotaTariffsLanding/FeaturesModal/FeaturesModal.data';
import { renderFeaturesListMobileModal } from 'reactApp/sections/MobileQuotaTariffsLanding/FeaturesModal/FeaturesModal.helpers';
import { sendQuotaGa } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import type { MobileBuyClick } from 'reactApp/sections/QuotaLanding/TariffsList/TariffsList.types';
import type { Product } from 'reactApp/types/Billing';
import { getPeriodName, isMonthPeriod } from 'reactApp/utils/Period';
import { formatPrice, getMonthProductPrice } from 'reactApp/utils/priceHelpers';

export const getButtonText = (price: number, period: string, isFullText: boolean, product: Product, priceByMonth?: boolean): string => {
    if (product.hasTrial && product.trialPeriod) {
        return `${isMonthPeriod(product.trialPeriod) ? 'Месяц' : 'Год'} бесплатно`;
    }

    if (priceByMonth) {
        return `${formatPrice(getMonthProductPrice(product))} ₽ в месяц`;
    }

    if (isFullText) {
        return `Купить за ${formatPrice(price, 'ru')} ₽ в ${getPeriodName(period)}`;
    }

    return `${formatPrice(price, 'ru')} ₽ в ${getPeriodName(period)}`;
};

export const getYearPriceWithMonthProduct = (products) => {
    const monthProduct = products.find((p) => isMonthPeriod(p.period));

    return `${formatPrice((monthProduct?.price || 0) * 12, 'ru')} ₽ в год`;
};

export const getDiscountText = (discountPrice: number, discountPeriod: string): string => {
    return `${formatPrice(discountPrice, 'ru')} ₽/${getPeriodName(discountPeriod)}`;
};

export const getTariffLabelByQuota = (quota: number): string => {
    if (quota >= 256) {
        return 'Оптимальный';
    } else if (quota >= 128) {
        return 'Популярный';
    } else if (quota >= 64) {
        return 'Специальный';
    }

    return 'Выгодный';
};
// tempexp_17509-next-line
const litres2mFree = (
    <>
        Подписка на Литрес —<br />2 месяца бесплатно
    </>
);
const withoutAdInWeb = 'Без рекламы в веб-версии';
const withoutAdInCloudAndMail = 'Без рекламы в Почте и Облаке';
const upload100gb = 'Загрузка файлов до 100 ГБ';
const shareSpace = 'Делитесь местом с близкими';
const send100gbFiles = 'Отправка файлов до 100 ГБ';

export const getFeatureList = (
    countSnapshotsBySpace?: number,
    isSingleQuota?: boolean,
    skipBenefitsFeature?: boolean,
    isRebrandingQuotaLanding?: boolean,
    isRebrandingSubscription?: boolean,
    // tempexp_17509-next-line
    isFromLitres?: boolean,
    // tempexp_17906-start
    yearProduct?: Product,
    onBuyClick?: MobileBuyClick
    // tempexp_17906-end
) => {
    /* tempexp_17509-start */
    if (isFromLitres) {
        return [
            litres2mFree,
            isOnlyWebAdInTariffs ? withoutAdInWeb : withoutAdInCloudAndMail,
            isFamilySubsInTariff ? (isRebrandingQuotaLanding ? 'Добавление ещё 3 участников' : shareSpace) : 'Быстрый ответ поддержки',
            !skipBenefitsFeature && (
                <>
                    И другие{'\u00A0'}
                    <a href="#benefits">возможности</a>
                </>
            ),
        ].filter((val) => !!val);
    }
    /* tempexp_17509-end */
    if (isSingleQuota) {
        // tempexp_17906-start
        const scrollToBenifits = (e: MouseEvent) => {
            e.preventDefault();
            sendQuotaGa({ action: 'tariff_benefits', label: 'click' });
            document.querySelector('#benefits')?.scrollIntoView({ behavior: 'smooth' });
        };

        const handleShowBenifits = (e: MouseEvent) => {
            e.preventDefault();
            renderFeaturesListMobileModal({
                product: yearProduct,
                onBuyClick,
            });
        };
        // tempexp_17906-end

        return [
            isOnlyWebAdInTariffs ? withoutAdInWeb : withoutAdInCloudAndMail,
            upload100gb,
            isFamilySubsInTariff ? (isRebrandingQuotaLanding ? 'Добавление ещё 3 участников' : shareSpace) : 'Быстрый ответ поддержки',
            // tempexp_17906-next-line
            (!skipBenefitsFeature || mailSpaceBenifits === 'b') && (
                <>
                    И другие{'\u00A0'}
                    <a href="#benefits" onClick={mailSpaceBenifits === 'b' ? scrollToBenifits : undefined}>
                        возможности
                    </a>
                </>
            ),
            // tempexp_17906-next-line
            mailSpaceBenifits === 'c' && yearProduct && (
                <>
                    Все{' '}
                    <a href="#benefits" onClick={handleShowBenifits}>
                        {getFeaturesModalList().length} возможностей
                    </a>
                </>
            ),
        ].filter((val) => !!val);
    }

    if (isRebrandingSubscription) {
        return [
            isOnlyWebAdInTariffs ? withoutAdInWeb : withoutAdInCloudAndMail,
            upload100gb,
            send100gbFiles,
            ...(isFamilySubsInTariff ? ['Делитесь местом с близкими'] : []),
        ];
    }

    return [
        isFamilySubsInTariff ? shareSpace : countSnapshotsBySpace && `+${countSnapshotsBySpace} новых фото в Облаке`,
        upload100gb,
        send100gbFiles,
        isOnlyWebAdInTariffs ? withoutAdInWeb : withoutAdInCloudAndMail,
    ].filter((val) => !!val);
};
