import { Icon20PrinterOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import printPhotoAlbumImg from 'img/printPhotoAlbumTooltip.png';
import React, { type FC, useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { getCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipAppearance, ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';
import { renderThanksModal } from 'reactApp/ui/ThanksModal/ThanksModal.helpers';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';

import styles from './PrintPhotoAlbumButton.css';

const PRINT_PHOTO_ALBUM_LOCAL_STORAGE_KEY = 'print-photo-album';
const PRINT_PHOTO_ALBUM_TOOLTIP_LOCAL_STORAGE_KEY = 'print-photo-album-tooltip';

interface Props {
    className?: string;
}

export const PrintPhotoAlbumButton: FC<Props> = ({ className }) => {
    const [hidden, setHidden] = useState(storeHelper.getValue(PRINT_PHOTO_ALBUM_LOCAL_STORAGE_KEY));
    const [closedTooltip, setClosedTooltip] = useState(storeHelper.getValue(PRINT_PHOTO_ALBUM_TOOLTIP_LOCAL_STORAGE_KEY));

    const currentFolder = useSelector(getCurrentFolder);

    const ref = useRef<HTMLButtonElement>(null);

    const isDarkTheme = isDarkThemeModeEnabled();

    const handleCloseTooltip = useCallback(() => {
        setClosedTooltip(true);
        storeHelper.setValue(PRINT_PHOTO_ALBUM_TOOLTIP_LOCAL_STORAGE_KEY, true);
    }, []);

    return currentFolder?.childs && currentFolder.childs.length > 0 ? (
        <>
            {!hidden && (
                <button
                    className={classNames(styles.button, className)}
                    type="button"
                    onClick={() => {
                        sendDwh({
                            eventCategory: ECategoryGa.uploadFileToCloud,
                            action: 'click-print-album',
                        });

                        setHidden(true);
                        storeHelper.setValue(PRINT_PHOTO_ALBUM_LOCAL_STORAGE_KEY, true);

                        handleCloseTooltip();

                        renderThanksModal();
                    }}
                    ref={ref}
                >
                    <Icon20PrinterOutline height={16} width={16} />
                    Напечатать фотографии
                </button>
            )}
            {!closedTooltip && (
                <FloatingTooltip
                    target={ref}
                    appearance={isDarkTheme ? undefined : ETooltipAppearance.blue}
                    placement={ETooltipPlacement.bottom}
                    qaId="tooltip-create-portfolio"
                    className={styles.tooltip}
                    arrowClassName={styles.tooltipArrow}
                    contentClassName={styles.contentTooltip}
                    closeClassName={styles.tooltipClose}
                    onClose={handleCloseTooltip}
                    closeOnOutsideClick={false}
                >
                    <div className={styles.tooltipRoot}>
                        <div className={styles.tooltipText}>У вас будет печатный фотоальбом с самыми теплыми воспоминаниями</div>
                        <img className={styles.tooltipImg} src={printPhotoAlbumImg} />
                    </div>
                </FloatingTooltip>
            )}
        </>
    ) : null;
};
