import { Spacing, Text } from '@vkontakte/vkui';
import image from 'img/restoreTrashbinFiles/restore-files-processing.png';
import imageDark from 'img/restoreTrashbinFiles/restore-files-processing-dark.png';
import React, { memo, useEffect } from 'react';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';

import styles from './RestoreTrashbinFilesThanksModal.css';
import type { Props } from './RestoreTrashbinFilesThanksModal.types';

export const RestoreTrashbinFilesThanksModal = memo<Props>(({ onShow, onClick, onClose }) => {
    useEffect(onShow, []);

    const content = (
        <>
            <Spacing size={12} />
            <Text className={styles.text} weight="3">
                Мы пока работаем над этой функцией. Сообщим сразу, как&nbsp;появится
            </Text>
            <Spacing size={24} />
        </>
    );

    const containsDarkTheme = isDarkThemeModeEnabled();

    return (
        <WhatsNewDialog
            primaryButtonText="Хорошо"
            title="Спасибо за интерес"
            imageUrl={containsDarkTheme ? imageDark : image}
            content={content}
            dialogSize="average"
            buttonTheme="vk"
            onClick={onClick}
            onClose={onClose}
            closeAfterClick
        />
    );
});

RestoreTrashbinFilesThanksModal.displayName = 'RestoreTrashbinFilesThanksModal';
