/* eslint-disable simple-import-sort/imports */
import { watchBuySubscription } from 'reactApp/modules/buySubscription/buySubscription.saga';
import { watchCreditCard } from 'reactApp/modules/creditCard/creditCard.saga';
import { watchCreditCardDmrLink } from 'reactApp/modules/creditCardDmrLink/creditCardDmrLink.saga';
import { watchDwh } from 'reactApp/modules/dwh/dwh.saga';
import { initSagas } from 'reactApp/modules/init/init.saga';
import { watchPaidInfo } from 'reactApp/modules/paidInfo/paidInfo.saga';
import { watchPayment } from 'reactApp/modules/payment/payment.saga';
import { watchDialogsRoot } from 'reactApp/modules/popup/popup.saga';
import { watchProductsRoot } from 'reactApp/modules/products/products.saga';
import { watchProfile } from 'reactApp/modules/profile/profile.saga';
import { watchPromoTariffsRoot } from 'reactApp/modules/promoTariffs/promoTariffs.saga';
import { watchQuotaLanding } from 'reactApp/modules/quotaLanding/quotaLanding.saga';
import { watchRouterRoot } from 'reactApp/modules/router/router.saga';
import { watchSettings } from 'reactApp/modules/settings/settings.saga';
import { watchSubscriptionsRoot } from 'reactApp/modules/subscriptions/subscriptions.saga';
import { watchUserQuota } from 'reactApp/modules/userQuota/userQuota.saga';
import { all, fork } from 'redux-saga/effects';
import { watchUserAndFamilyQuota } from 'reactApp/modules/userAndFamilyQuota/userAndFamilyQuota.saga';
import { watchTheme } from 'reactApp/modules/theme/theme.saga';
import { watchRouteChange } from 'reactApp/modules/home/home.saga';
import { watchBilling } from 'reactApp/modules/billing/billing.saga';
import { watchPromocodes } from 'reactApp/modules/promocode/promocode.saga';
import { watchBillingDataRoot } from 'reactApp/modules/billingData/billingData.saga';
import { watchSubscriptionsRenewal } from 'reactApp/modules/subscriptionRenewal/subscriptionRenewal.saga';
import { watchSubscriptionsRenewalMobile } from 'reactApp/modules/subscriptionRenewal/subscriptionRenewal.mobile.saga';

export function* commonQuotaSagas(): any {
    yield all([
        fork(watchRouteChange),
        fork(watchPaidInfo),
        fork(watchSettings),
        fork(watchCreditCard),
        fork(watchCreditCardDmrLink),
        fork(watchDialogsRoot),
        fork(watchProfile),
        fork(watchSubscriptionsRoot),
        fork(watchBillingDataRoot),
        fork(watchUserQuota),
        fork(watchQuotaLanding),
        fork(watchBuySubscription),
        fork(watchRouterRoot),
        fork(initSagas),
        fork(watchProductsRoot),
        fork(watchPromoTariffsRoot),
        fork(watchPromocodes),
        fork(watchPayment),
        fork(watchDwh),
        fork(watchUserAndFamilyQuota),
        fork(watchBilling),
        fork(watchTheme),
        fork(watchSubscriptionsRenewal),
        fork(watchSubscriptionsRenewalMobile),
    ]);
}
