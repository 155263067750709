import { bytesToNDigits } from '@mail/cross-sizes-utils';
import {
    type BillingDataIos,
    type BillingDataResponse,
    type BillingDataReward,
    type BillingDataWeb,
    BillingDataPartner,
    BillingDataPlatform,
    BillingDataStatus,
    BillingDataType,
} from 'reactApp/api/billing/data/BillingDataAPICall.types';
import { generateSubscriptionId } from 'reactApp/utils/generateSubscriptionId';

import { PARTNER_LIST } from './billing.data';
import { getBillingDataLinks } from './billingData.links';
import { type BillingDataSubscriptionRecord, type BillingPaidInfo, BillingDataSubscriptionInfoStatus } from './billingData.types';

const STATUS_MAP = {
    A: BillingDataSubscriptionInfoStatus.ACTIVE,
    F: BillingDataSubscriptionInfoStatus.FINISHED,
    S: BillingDataSubscriptionInfoStatus.SUSPENDED,
    G: BillingDataSubscriptionInfoStatus.GRACE,
    H: BillingDataSubscriptionInfoStatus.HOLD, // Пока нет
    L: BillingDataSubscriptionInfoStatus.LAPSED,
    W: BillingDataSubscriptionInfoStatus.WAITING,
} as const;

const getStatus = (status: BillingDataStatus, autorenewal?: boolean): BillingDataSubscriptionInfoStatus => {
    if (status === BillingDataStatus.ACTIVE && autorenewal === false) {
        return BillingDataSubscriptionInfoStatus.SUSPENDED;
    }
    return STATUS_MAP[status];
};

const getRenewAt = (renewNext?: number) => {
    return typeof renewNext === 'undefined' ? undefined : calculateDate(renewNext);
};

const SUBSCRIPTION_TYPE_LIST = [
    BillingDataPlatform.web,
    BillingDataPlatform.ios,
    BillingDataPlatform.android,
    BillingDataPlatform.reward,
    BillingDataPlatform.rustore,
] as const;

const calculateDate = (date: number) => date && date * 1000;

function hasSubscriptionId(item: unknown): item is BillingDataWeb {
    return !!item && typeof item === 'object' && 'sub_id' in item;
}

function hasRewardId(item: unknown): item is BillingDataReward {
    return !!item && typeof item === 'object' && 'reward_id' in item;
}

function mapCommon(subscription: BillingDataWeb | BillingDataIos | BillingDataReward, type: BillingDataPlatform) {
    const expires = calculateDate(subscription.finish);
    const quota = subscription?.services?.cloud?.quota ?? 0;
    const { common } = subscription.services || ({} as any);
    const space = bytesToNDigits(quota, 3);
    const flags = subscription.services.cloud?.flags;
    return {
        expires,
        quota,
        type,
        productId: subscription.product,
        space,
        flags: {
            upload: !!flags?.PAID_UPLOAD,
            paidFeatures: !!flags?.PAID_ACCOUNT,
            pro: !!flags?.PRO,
        },
        isMail: Boolean(common?.disable_ads),
    } as const;
}

function mapSubscription(subscription: BillingDataWeb, type: BillingDataPlatform, start: number): BillingDataSubscriptionRecord {
    const { expires, quota, ...rest } = mapCommon(subscription, type);
    const { icon } = getBillingDataLinks(type);
    const { autorenewable, renew_next, amounts, renewable = false } = subscription;
    const status = getStatus(subscription.status, autorenewable);
    const id = generateSubscriptionId({
        period: subscription.period,
        subId: subscription.sub_id,
        start,
        quota,
        type,
        expires,
    }).toString() as string;
    return {
        [id]: {
            ...rest,
            id,
            start,
            expires,
            icon,
            status,
            autorenewal: autorenewable,
            renewalCost: amounts?.renewal,
            purchaseCost: amounts?.purchase,
            renewAt: getRenewAt(renew_next),
            renewable,
            isTrial: subscription.type.includes(BillingDataType.TRIAL),
            isPrepaid: subscription.type.includes(BillingDataType.PREPAID),
        },
    };
}

const supportedPlatforms = [
    BillingDataPartner.mail,
    BillingDataPlatform.android,
    BillingDataPlatform.base,
    BillingDataPlatform.huawei,
    BillingDataPlatform.ios,
    BillingDataPlatform.reward,
    BillingDataPlatform.web,
    BillingDataPlatform.rustore,
];

function mapReward(subscription: BillingDataReward, type: BillingDataPlatform, start: number): BillingDataSubscriptionRecord {
    const { expires, quota, ...rest } = mapCommon(subscription, type);
    const partner = subscription.platform;
    /** TODO
     * Удалить блок с if после полного перехода на billingData;
     * Старая ручка subscriptions/list возвращала в ревардах только подписки с партнером ru.mail.special;
     * Поэтому для совместимости с другим кодом, ожидающим такое поведение добавил сюда проверку
     * Познее, когда billind/data будет использоваться и для получения promo, if нужно будет убрать,
     * с изменением соотвествующей логики в других местах;
     *
     * Касаемо платформ в списке BillingDataPlatform supportedPlatforms, это в связи с тем, что реварды и
     * платформ-подписки это одна сущность и тоже добавлено для обратной совсестимости с promo
     * */
    const { platform } = subscription;

    if (!supportedPlatforms.includes(platform)) {
        return {};
    }

    const { icon, accountLink } = getBillingDataLinks(type, platform);

    const status = getStatus(subscription.status);
    const id = generateSubscriptionId({
        subId: subscription.reward_id,
        start,
        quota,
        type,
        expires,
    }).toString() as string;
    return {
        [id]: {
            ...rest,
            id,
            start,
            expires,
            partner: PARTNER_LIST.includes(partner) ? partner : undefined,
            icon,
            accountLink,
            status,
            renewAt: getRenewAt(),
            renewable: false,
            isTrial: false,
            isPrepaid: false,
        },
    };
}

type BillingDataUnion = BillingDataWeb | BillingDataIos | BillingDataReward;

export function mapBillingDataSubscription(billingData: BillingDataResponse): BillingDataSubscriptionRecord {
    const today = Date.now();
    return SUBSCRIPTION_TYPE_LIST.reduce((subscriptionsAcc, type) => {
        const billingDataByType = billingData?.data?.[type];
        if (!billingDataByType || !billingDataByType.length) {
            return subscriptionsAcc;
        }

        const subscriptionsByType = (billingDataByType as BillingDataUnion[]).reduce((subscriptionByTypeAcc, subscription) => {
            const start = calculateDate(subscription.start);

            if (start && today < start) {
                return subscriptionByTypeAcc;
            }
            if (hasSubscriptionId(subscription)) {
                return { ...subscriptionByTypeAcc, ...mapSubscription(subscription, type, start) };
            }
            if (hasRewardId(subscription)) {
                return { ...subscriptionByTypeAcc, ...mapReward(subscription, type, start) };
            }
            return subscriptionByTypeAcc;
        }, {} as BillingDataSubscriptionRecord);
        return { ...subscriptionsAcc, ...subscriptionsByType };
    }, {} as BillingDataSubscriptionRecord);
}

export const normalizeBillingPaidInfo = (billingData: BillingDataResponse): BillingPaidInfo => {
    const cloudPaidInfo = billingData?.paid_info?.cloud;

    let trialExpires = 0;
    let paidExpires = 0;

    if (cloudPaidInfo) {
        Object.keys(cloudPaidInfo).forEach((platform) => {
            const trial = cloudPaidInfo[platform].trial * 1000;
            const paid = cloudPaidInfo[platform].paid * 1000;

            paidExpires = Math.max(paid, paidExpires);
            trialExpires = Math.max(trial, trialExpires);
        });
    }

    return {
        trialExpires,
        paidExpires,
    };
};
