import classNames from 'clsx';
import React, { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import type { Product } from 'reactApp/types/Billing';
import { TariffCard } from 'reactApp/ui/TariffCardNew/TariffCard';
import {
    EButtonTextSize,
    ETariffCardSizeButtonSize,
    ETariffCardWidth,
    TariffCardContentSize,
} from 'reactApp/ui/TariffCardNew/TariffCard.types';
import { useButtonAdditionalText } from 'reactApp/ui/TariffSectionNew/hooks/useButtonAdditionalText';
import { useSwitchProducts } from 'reactApp/ui/TariffSectionNew/hooks/useSwitchProducts';
import { useTariffLabel } from 'reactApp/ui/TariffSectionNew/hooks/useTariffLabel';
import type { ETabsName } from 'reactApp/ui/TariffSectionNew/TariffSection.constants';
import type { TariffByModalTab } from 'reactApp/ui/TariffsModal/TariffsModalContent.types';
import { noopVoid } from 'reactApp/utils/helpers';
import { isMonthPeriod } from 'reactApp/utils/Period';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';

import { useProductPrice } from './hooks/useProductPrice';
import styles from './TariffListCard.css';
import { TariffListCardInfoBlock } from './TariffListCardInfoBlock';

interface TariffListCardProps {
    activeTab?: ETabsName;
    tariff: TariffByModalTab;
    onBuyClick: (product: Product) => void;
}

export const TariffListCard = memo<TariffListCardProps>(({ activeTab, tariff, onBuyClick }) => {
    const { isRecommended, products, tariffId, features, withMascot, withInfoBlock = true, withSpace = true, title = '' } = tariff;
    const containsDarkTheme = isDarkThemeModeEnabled();
    const userQuota = useSelector(UserQuotaSelectors.getTotalOrBase);
    const { product, yearSavingDiscount, isYearFallbackProduct } = useSwitchProducts({
        products,
        activeTab,
    });
    const savingDiscount = product.hasTrial ? 0 : yearSavingDiscount;
    const { discount, priceText } = useProductPrice({ product });
    const [showLabel, label, labelKind] = useTariffLabel({
        product,
        skipDefaultLabel: true,
        showDiscount: false,
        isRecommended: isRecommended && !discount,
        primaryLabel: isRecommended ? 'Выгодно' : '',
        isRebranding: true,
    });
    const buttonAdditionalText = useButtonAdditionalText(product);
    const onClickButton = useCallback(() => onBuyClick(product), [onBuyClick, product]);
    const newQuota = userQuota + product.space.original;
    const infoBlock = useMemo(() => <TariffListCardInfoBlock newQuota={newQuota} />, [newQuota]);

    return (
        <div className={classNames(styles.root, { [styles.recommended]: isRecommended })}>
            <TariffCard
                disableAction
                buttonDisabled={isYearFallbackProduct}
                accentFocused
                compact
                isRebranding
                boldSavingLabel
                buttonTextSize={EButtonTextSize.large}
                contentSize={TariffCardContentSize.small}
                buttonInFooterGap={24}
                buttonPrimaryLight
                buttonInFooter
                onClick={noopVoid}
                tariffId={tariffId}
                width={ETariffCardWidth.fluid}
                focused={isRecommended}
                focusedWithoutShadow
                buttonPrimary={isRecommended}
                buttonText={priceText}
                onClickButton={onClickButton}
                buttonAdditionalText={buttonAdditionalText}
                space={product.space}
                featureListOptions={features}
                buttonSize={ETariffCardSizeButtonSize.big}
                infoBlock={infoBlock}
                savingInButton={isMonthPeriod(product.period) ? 0 : savingDiscount}
                limeSavingLabel={isRecommended && containsDarkTheme}
                {...(showLabel
                    ? {
                          label,
                          labelKind,
                      }
                    : {})}
                withMascot={withMascot}
                withInfoBlock={withInfoBlock}
                withSpace={withSpace}
                headerPrimaryTextExtraLarge={withMascot}
                headerPrimaryText={title}
            />
        </div>
    );
});

TariffListCard.displayName = 'TariffListCard';
