import classNames from 'clsx';
import React, { memo, useEffect, useMemo, useState } from 'react';
import TruncateMiddle from 'react-truncate-string';
import { Hint, HintPosition } from 'reactApp/ui/Hint/Hint';
import { noop } from 'reactApp/utils/helpers';

import styles from './Name.css';

interface IProps {
    name?: string;
    extension?: string;
    fullWidth?: boolean;
    showTooltip?: boolean;
    light?: boolean;
    truncate?: boolean;
    delayTruncate?: boolean;
    showUnknownExtension?: boolean;
    disableEllipsis?: boolean;
    noOverflow?: boolean;
    useH1?: boolean;
    className?: string;
    onDoubleClick?: React.MouseEventHandler;
}

export const NameComponent = memo(
    ({
        name,
        extension = '',
        fullWidth = false,
        light = false,
        showTooltip = false,
        truncate = false,
        delayTruncate = false,
        showUnknownExtension = false,
        disableEllipsis = false,
        useH1 = false,
        noOverflow,
        className,
        onDoubleClick = noop,
    }: IProps) => {
        const [showExt, setShowExt] = useState(!!extension);
        const [truncateVal, setTruncateVal] = useState(delayTruncate ? false : truncate);

        useEffect(() => {
            if (extension === 'unknown' && !showUnknownExtension) {
                setShowExt(false);
            } else {
                setShowExt(!!extension);
            }
        }, [extension, showUnknownExtension]);

        useEffect(() => {
            if (!delayTruncate) {
                setTruncateVal(truncate);
                return;
            }

            if (!truncate) {
                return;
            }

            // Иногда TruncateMiddle не определяет ширину компонента и делает слишком большой truncate
            // Но при ресайзе - все становится ок.
            // В таких случаях передаем delayTruncate и сначала рендерим полную строку, потом уже делаем ей truncate
            // Пока это проявляется только на одиночных пабликах.
            // Возможно, из-за рендеринга компонента в портале в старом коде, в componentDidMount бывает еще нет ref на ноду
            const timerId = window.setTimeout(() => setTruncateVal(true));
            return () => {
                window.clearTimeout(timerId);
            };
        }, [delayTruncate, truncate]);

        const TagName = useH1 ? 'h1' : 'div';

        const Wrap = useMemo(
            () => (
                <div className={styles.wrap} onDoubleClick={onDoubleClick}>
                    <TagName
                        className={classNames({
                            [styles.name]: true,
                            [styles.multiline]: disableEllipsis,
                        })}
                    >
                        {truncateVal ? <TruncateMiddle text={name} /> : name}
                    </TagName>
                    {showExt && <TagName className={styles.extension}>.{extension}</TagName>}
                </div>
            ),
            [disableEllipsis, extension, name, onDoubleClick, showExt, truncateVal]
        );

        return (
            <div
                className={classNames(styles.root, className, {
                    [styles.root_fullWidth]: fullWidth,
                    [styles.root_light]: light,
                    [styles.root_no_overflow]: noOverflow,
                })}
            >
                {showTooltip ? (
                    <Hint text={`${name}${showExt ? `.${extension}` : ''}`} positionStrategy={HintPosition.relativeToCursor}>
                        {Wrap}
                    </Hint>
                ) : (
                    Wrap
                )}
            </div>
        );
    }
);

NameComponent.displayName = 'Name';
