import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

/* tempexp_17604-start */
type MailPopupUploadTariffs = {
    trial: string;
    forceTrial: string;
    default: string;
};

// CLOUDWEB-17604 Проверять платника/бесплатник в почтовом попапе на загрузку файла
export const mailPopupUploadAb: 'a' | 'b' = getFeature('mail-popup-upload-ab');

export const mailPopupUploadTariffs: MailPopupUploadTariffs = getFeature('mail-popup-upload-tariffs');

registerExperiment(
    'mail-upload-ab',
    mailPopupUploadAb,
    [
        AnalyticEventNames.MAIL_POPUP_SHOW,
        AnalyticEventNames.MAIL_POPUP_CLICK,
        AnalyticEventNames.MAIL_POPUP_PAYMENT_SHOW,
        AnalyticEventNames.MAIL_POPUP_PAYMENT_SUCCESS,
        AnalyticEventNames.MAIL_POPUP_PAYMENT_FAILURE,
    ],
    {
        sendPlatformData: false,
        mapEventNames: {
            MAIL_POPUP_SHOW: 'show',
            MAIL_POPUP_CLICK: 'click',
            MAIL_POPUP_PAYMENT_SHOW: 'pay-show',
            MAIL_POPUP_PAYMENT_SUCCESS: 'pay-success',
            MAIL_POPUP_PAYMENT_FAILURE: 'pay-fail',
        },
        sendXrayArgs: {
            MAIL_POPUP_SHOW: ({ isRestrictedUploadLimit }) => ({ cancel: !isRestrictedUploadLimit }),
            MAIL_POPUP_CLICK: ({ isRestrictedUploadLimit }) => ({ cancel: !isRestrictedUploadLimit }),
            MAIL_POPUP_PAYMENT_SHOW: ({ isRestrictedUploadLimit }) => ({ cancel: !isRestrictedUploadLimit }),
            MAIL_POPUP_PAYMENT_SUCCESS: ({ isRestrictedUploadLimit }) => ({ cancel: !isRestrictedUploadLimit }),
            MAIL_POPUP_PAYMENT_FAILURE: ({ isRestrictedUploadLimit }) => ({ cancel: !isRestrictedUploadLimit }),
        },
    }
);

/* tempexp_17604-end */
