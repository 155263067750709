import type { IR7Data } from 'Cloud/Application/types';
import type { Method } from 'lib/axios';
import { APICallV4Post } from 'reactApp/api/APICall';

class R7ViewDirectAPICall extends APICallV4Post<{ r7editor: IR7Data }, { direct_url: string; name: string; user_id: string }> {
    _type: Method = 'post';
    _method = 'r7/embedded/view_direct';
}

export const callR7ViewDirectAPICall = async (params: { direct_url: string; name: string; user_id: string }) =>
    await new R7ViewDirectAPICall().makeRequest(params);
