import classNames from 'clsx';
import React, { type FC, type ReactNode, type SVGProps, type VFC } from 'react';
import { Icon20ChevronRight } from 'reactApp/ui/touchIcons';

import styles from './FileInfoItem.css';

type Direction = 'up' | 'down' | 'left' | 'right';

type Props = {
    id: string;
    section: string;
    Icon: FC<SVGProps<SVGSVGElement>> | FC;
    rightIcon?: Direction;
    active?: boolean;
    value?: ReactNode | string;
    onClick?: () => void;
};

const mapDirectionToDeg: Record<Direction, string> = {
    up: 'deg270',
    down: 'deg90',
    left: 'deg180',
    right: 'deg0',
};

export const FileInfoItem: VFC<Props> = ({ id, value, active, section, Icon, rightIcon, onClick }) => (
    <div className={styles.root} onClick={onClick}>
        <div className={classNames(styles.item, styles.icon, { [styles.activeIcon]: active })}>
            <Icon />
        </div>
        <div className={classNames(styles.item, styles.infoContainer)}>
            <div className={styles.info} data-qa-id={`ItemInfo__${id}`}>
                {value}
            </div>
            <div className={styles.section}>{section}</div>
        </div>
        {rightIcon && (
            <Icon20ChevronRight
                className={classNames({ [styles.item]: true, [mapDirectionToDeg[rightIcon]]: true })}
                width={20}
                height={20}
            />
        )}
    </div>
);
