import React, { type ReactElement, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { isPersonalAccessSharingEnabled } from 'reactApp/appHelpers/featuresHelpers/features/personalAccessSharing';
import { SharingNewDropdown } from 'reactApp/components/SharingNewBiz/Dropdown/SharingNewDropdown';
import { EDropdownColors } from 'reactApp/components/SharingNewBiz/Dropdown/SharingNewDropdown.types';
import { TOOLTIP_OFFSET } from 'reactApp/components/SharingNewBiz/SharingNew.constants';
import { sendGa } from 'reactApp/components/SharingNewBiz/SharingNew.helpers';
import { type IAccessRightsOptions, EAccessRights } from 'reactApp/components/SharingNewBiz/SharingNew.types';
import { deleteUser, setAccessRight } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.actions';
import { unmountRequest } from 'reactApp/modules/modifying/modifying.actions';
import { weblinkDeleteEmailAccess } from 'reactApp/modules/weblinkAccessControl/weblinkAccessControl.actions';
import { Dropdown } from 'reactApp/ui/Dropdown/Dropdown';
import { DropdownMenu } from 'reactApp/ui/Dropdown/DropdownMenu';
import { tooltipPlacements } from 'reactApp/ui/Tooltip/Tooltip.constants';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import { ACCESS_RIGHTS_OPTIONS, DELETE_USER, UNMOUNT_USER } from './User.constants';
import styles from './User.css';
import { renderDeleteUserDialog } from './User.helpers';

export const useAccessRights = ({ user, item, isMounted, onClose, isPhone, isOwner, publicId }): ReactElement | null | string => {
    const dispatch = useDispatch();
    const folderId = !isPersonalAccessSharingEnabled || item?.isFolder ? item?.id : null;
    const weblink = !isPersonalAccessSharingEnabled || item?.isFolder ? null : publicId;
    const accessRights = user?.accessRights === 'read' ? EAccessRights.READ_ONLY : user?.accessRights;
    const userRight = ACCESS_RIGHTS_OPTIONS[accessRights];

    const onActionClick = useCallback(() => {
        if (!user || !item) {
            return;
        }
        sendGa('delete-user');
        sendPaymentGa({
            eventCategory: publicId ? ECategoryGa.public : ECategoryGa.sharedFolder,
            action: 'shutdown-sharing',
            public_id: publicId,
        });
        if (!isPersonalAccessSharingEnabled || item?.isFolder) {
            dispatch(deleteUser({ email: user.email, item, name: user.name }));
        } else {
            dispatch(weblinkDeleteEmailAccess({ email: user.email, weblink }));
        }
    }, [dispatch, item, publicId, user, weblink]);

    const handleAccessRightsUpdate = useCallback(
        (accessRight: EAccessRights): void => {
            if (!user) {
                return;
            }

            if (accessRight === DELETE_USER.key) {
                renderDeleteUserDialog({ user, onActionClick, isFolder: item?.isFolder });
                return;
            }

            sendGa('user-rights-change');

            dispatch(setAccessRight({ email: user.email, accessRight, folderId, weblink, isFolder: item?.isFolder }));
        },
        [dispatch, onActionClick, user, folderId, weblink, item]
    );

    const onUnmount = useCallback(() => {
        sendGa('unmount');
        onClose();
    }, [onClose]);

    const handleOpenUnmountDialog = useCallback(() => {
        dispatch(unmountRequest({ item, onUnmount }));
    }, [item, onUnmount]);

    const renderAccessRightsDropdown = useCallback(
        (onClick: void): ReactElement | null => {
            const values = {
                ...ACCESS_RIGHTS_OPTIONS,
                [EAccessRights.DELETE_USER]: DELETE_USER,
            };

            const list = Object.values(values).map((item: IAccessRightsOptions) => ({
                id: item.key,
                text: item.text,
                check: item.key === accessRights,
                qaValue: item.key,
                isDanger: item.key === EAccessRights.DELETE_USER,
            }));

            return <DropdownMenu list={list} theme="octavius" onClick={onClick} className={styles.dropdownMenu} />;
        },
        [accessRights]
    );

    if (isOwner) {
        return <div className={styles.owner}>Владелец {item?.isFolder ? 'папки' : 'файла'}</div>;
    }

    if (isMounted) {
        return (
            <div className={styles.unmount} onClick={handleOpenUnmountDialog} data-qa-value={UNMOUNT_USER.key}>
                <div className={styles.unmountText}>{UNMOUNT_USER.text}</div>
            </div>
        );
    }

    return (
        <div className={styles.dropdown}>
            <Dropdown
                withinDialog
                theme="octavius"
                value={userRight?.text}
                qaValue={userRight?.key}
                as={SharingNewDropdown}
                color={isPhone ? EDropdownColors.BLUE : EDropdownColors.GRAY}
                placement={tooltipPlacements.BOTTOM_LEFT}
                content={renderAccessRightsDropdown}
                onClickItem={handleAccessRightsUpdate}
                tooltipOffset={TOOLTIP_OFFSET}
                animatedTooltip
                isPhone={isPhone}
                isUserDropdown
                tooltipClassName={isPhone ? styles.tooltip : undefined}
                noPageOffset
            />
        </div>
    );
};
