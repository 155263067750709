import type { DomainAccess } from 'reactApp/components/SharingWindow/Sharing.types';
import type { WeblinkDownloadable } from 'reactApp/modules/public/public.types';
import type { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { AccessRights, Count, Kind, Sort, SubKind, Url, VirusScan, Weblink } from 'reactApp/types/Tree';

export enum SharedTab {
    links = 'links',
    incoming = 'incoming',
    autodelete = 'autodelete',
    indexed = 'indexed',
}

export interface ApiSharedLinksResponseItem {
    tree?: string;
    name: string;
    grev?: number;
    size: number;
    kind: Kind;
    weblink?: string;
    weblink_access_rights?: AccessRights;
    weblink_expires?: number;
    rev?: number;
    type: 'folder' | 'file';
    home: string;
    hash?: string;
    mtime?: number;
    virus_scan: VirusScan;
    public: Weblink;
}

export interface SharedFolder extends WeblinkDownloadable {
    id: string;
    home: string;
    name: string;
    type: 'folder';
    weblink?: string;
    weblinkAccessRights?: AccessRights;
    weblinkExpires?: number;
    expiresTimeSize?: number;
    weblinkDomestic?: boolean;
    weblinkDomainAccess?: DomainAccess;
    weblinkAutoDelete?: boolean;
    weblinkSeoEnabled?: boolean;
    weblinkEnabledDisabledDownload?: boolean;
    parent: '/';
    isFolder: true;
    storage: EStorageType.sharedLinks;
    rev: number;
    size: number;
    tree: string;
    kind: Kind;
    ctime: number; // public link creation time
    __reduxTree: true;
    isReadOnly?: boolean;
    intraDomainShare?: boolean;
}

export interface SharedFile extends WeblinkDownloadable {
    id: string;
    isFolder: false;
    parent: '/';
    virusScan: VirusScan;
    ext: string;
    nameWithoutExt: string;
    name: string;
    home: string;
    size: number;
    type: 'file';
    kind: Kind;
    subKind: SubKind;
    weblink?: string;
    weblinkAccessRights?: AccessRights;
    weblinkExpires?: number;
    weblinkDomestic?: boolean;
    weblinkDomainAccess?: DomainAccess;
    weblinkSeoEnabled?: boolean;
    weblinkEnabledDisabledDownload?: boolean;
    mtime: number;
    expiresTimeSize?: number;
    ctime: number; // public link creation time
    hash: string;
    __reduxTree: boolean;
    storage: EStorageType.sharedLinks;
    thumbnails?: {
        [key: string]: string;
    };
    url?: Url;
    isInFavorites: boolean;
    isReadOnly?: boolean;
    intraDomainShare?: boolean;
}

export type SharedItem = SharedFolder | SharedFile;

export interface ApiSharedLinksResponse {
    list: ApiSharedLinksResponseItem[];
}

export interface SharedStorage {
    id: string;
    sort: Sort;
    count: Count;
    name: string;
    isLoaded: boolean;
    isLoading: boolean;
    childs: string[];
}

export interface SharedLinksState {
    '/': SharedStorage;
    list: {
        [key: string]: SharedItem;
    };
    sharedIncomingCounter: number;
    currentTab?: SharedTab;
}

export interface PayloadActionUpdateIncomingCount {
    count: number;
}
