import { Spacing, Text } from '@vkontakte/vkui';
import image from 'img/inProcess.png';
import React, { memo, useEffect } from 'react';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import styles from 'reactApp/ui/ThanksModal/ThanksModal.css';
import type { Props } from 'reactApp/ui/ThanksModal/ThanksModal.types';

export const ThanksModal = memo<Props>(({ onShow, onClick, onClose }) => {
    useEffect(() => {
        onShow?.();
    }, []);

    const content = (
        <>
            <Spacing size={12} />
            <Text className={styles.text} weight="3">
                Мы пока работаем над этой функцией. Сообщим сразу, как появится
            </Text>
            <Spacing size={24} />
        </>
    );

    return (
        <WhatsNewDialog
            primaryButtonText="Хорошо"
            title="Спасибо за ваш интерес"
            imageWrapperClassName={styles.imageWrapper}
            imageClassName={styles.image}
            imageUrl={image}
            content={content}
            dialogSize="small"
            buttonTheme="vk"
            onClick={onClick}
            onClose={onClose}
            closeAfterClick
        />
    );
});

ThanksModal.displayName = 'ThanksModal';
