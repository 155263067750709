import { IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { pdfWorkWithPagesPromoFeature } from 'reactApp/appHelpers/featuresHelpers/features/pdfWorkWithPagesPromo';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getFeaturePdfWorkWithPages } from 'reactApp/modules/features/features.selectors';
import { pdfEditorSelectors } from 'reactApp/modules/pdfEditor/pdfEditor.selectors';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { store as reduxStore } from 'reactApp/store';
import { put, select } from 'typed-redux-saga';

const PROMO_ID = 'pdf-work-with-pages-promo';

export function* initPdfWorkWithPagesPromo() {
    const isPdfWorkWithPagesFeature = yield* select(getFeaturePdfWorkWithPages);
    const isEncrypted = yield* select(pdfEditorSelectors.getIsEncrypted);
    const alreadyShown = storeHelper.getValue(PROMO_ID);
    const storage = yield* select(getCurrentStorage);
    const isMobile = EnvironmentSelectors.isMobile();
    const isPdfEditPage = storage === EStorageType.pdfEdit;
    if (
        !isPdfWorkWithPagesFeature?.workWithPages ||
        !pdfWorkWithPagesPromoFeature ||
        !isPdfEditPage ||
        isEncrypted ||
        alreadyShown ||
        IS_WEBVIEW ||
        isMobile
    ) {
        return;
    }

    yield* put(
        addPromoToShowQueue({
            type: EPromoType.pdfWorkWithPagesPromo,
            promoId: PROMO_ID,
            onShow: () => {
                reduxStore.dispatch(promoShown(EPromoType.pdfWorkWithPagesPromo));
            },
            onClose: () => {
                reduxStore.dispatch(removePromo(EPromoType.pdfWorkWithPagesPromo));
                storeHelper.markAsShown(PROMO_ID);
            },
        })
    );
}
