import type { RequiredReactRendererDependencies } from '@mail/3promo/render/react/dependencies';
import React, { type PropsWithChildren, useEffect } from 'react';
import ReactDOM from 'react-dom';

type ReactRendererCbWrapperProps = PropsWithChildren<{ onMount?: () => void; onUnmount?: () => void }>;

const ReactRendererCbWrapper = ({ children, onMount, onUnmount }: ReactRendererCbWrapperProps) => {
    useEffect(() => {
        onMount?.();
        return onUnmount;
    }, []);

    return <>{children}</>;
};

const roots = new Map<HTMLElement, HTMLElement>();

export const defaultReactRenderDeps: RequiredReactRendererDependencies = {
    reactRender: (tsx, rootElement, callback) => {
        if (!roots.has(rootElement)) {
            const container = document.createElement('div');
            rootElement.appendChild(container);
            roots.set(rootElement, container);
        }
        const container = roots.get(rootElement)!;

        ReactDOM.render(
            <ReactRendererCbWrapper onMount={callback} onUnmount={() => roots.delete(rootElement)}>
                {tsx}
            </ReactRendererCbWrapper>,
            container
        );
    },
    reactUnmount: (rootElement) => {
        const container = roots.get(rootElement);
        if (!container) {
            return;
        }
        ReactDOM.unmountComponentAtNode(container);
        rootElement.removeChild(container);
        roots.delete(rootElement);
    },
};
