import { Icon20MagnifierMinusOutline, Icon20MagnifierPlusOutline } from '@vkontakte/icons';
import React, { type FC, type MouseEvent } from 'react';
import { MenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/MenuButton/MenuButton';

import styles from './Zoomer.css';

interface Props {
    value: number;
    decrease?: (evt: MouseEvent) => void;
    increase?: (evt: MouseEvent) => void;
}

export const Zoomer: FC<Props> = ({ value, decrease, increase }) => {
    return (
        <div className={styles.wrapper}>
            <MenuButton className={styles.button} hint="Уменьшить" icon={<Icon20MagnifierMinusOutline />} onClick={decrease} />
            <div className={styles.value}>{value}%</div>
            <MenuButton className={styles.button} hint="Увеличить" icon={<Icon20MagnifierPlusOutline />} onClick={increase} />
        </div>
    );
};
