import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { openRenewSubscription } from 'reactApp/modules/payment/payment.module';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { revealPlatformPopup } from 'reactApp/sections/QuotaLanding/TariffsPlans/TariffPlansCard/TariffPlansCard.helpers';
import { sendXray } from 'reactApp/utils/ga';

interface MobileInfo {
    isIOSPromo?: boolean;
    isAndroidPromo?: boolean;
    isMobilePayAvailable?: boolean;
}

export const useRenewFromQuery = (mobileInfo?: MobileInfo) => {
    const queryParams = getQueryParams();
    const dispatch = useDispatch();

    const productsState = useSelector(ProductsSelectors.getLifeCycleState);

    const isFromAction = queryParams?.action === 'request-renew';
    const id = isFromAction ? queryParams.subscriptionId : '';
    const isFromActionAndLoaded = isFromAction && productsState.isLoaded;

    useEffect(() => {
        if (!isFromActionAndLoaded) {
            return;
        }

        const { isAndroidPromo, isIOSPromo, isMobilePayAvailable } = mobileInfo || {};

        if (mobileInfo && (isAndroidPromo || isIOSPromo) && !isMobilePayAvailable) {
            revealPlatformPopup(id, Boolean(isIOSPromo), Boolean(isAndroidPromo), true);
            return;
        }

        sendXray(['subs', 'renew', 'show', 'touch']);
        dispatch(openRenewSubscription({ subscriptionId: id, isMobile: true }));
    }, [id, isFromActionAndLoaded]);
};
