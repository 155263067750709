import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';

interface TrashbinSelectiveDeletionFeature {
    limit: number;
    web?: boolean;
    touch?: boolean;
}

/**
 * alpha-team
 * CLOUDWEB-17511: Выборочное удаления файлов из корзины
 */
export const trashbinSelectiveDeletion: TrashbinSelectiveDeletionFeature = getFeature('trashbin-selective-deletion');

/**
 * alpha-team
 * CLOUDWEB-17511: Выборочное удаления файлов из корзины
 * Проверка что фича выборочного удаления файлов из корзины включена и ее значние валидно
 */
export const isTrashbinSelectiveActive =
    !EnvironmentSelectors.isMobile() &&
    trashbinSelectiveDeletion &&
    typeof trashbinSelectiveDeletion?.limit === 'number' &&
    trashbinSelectiveDeletion?.web;

export const isMobileTrashbinSelectiveActive =
    EnvironmentSelectors.isMobile() &&
    trashbinSelectiveDeletion &&
    typeof trashbinSelectiveDeletion?.limit === 'number' &&
    trashbinSelectiveDeletion?.touch;
