import type { VideoPlayer } from 'reactApp/ui/ReactViewer/VideoPlayer/VideoPlayer.types';

import { MIN_TIME_TO_REWIND } from './constants';

export const stimulateNav = (player: VideoPlayer) => {
    player.reportUserActivity(true);
};

export const hideNav = (player: VideoPlayer) => {
    player.userActive(false);
    player.userActivity_ = false;
};

export const toggleNav = (player: VideoPlayer) => {
    if (player.userActive()) {
        hideNav(player);
    } else {
        stimulateNav(player);
    }
};

export function getFastRewindFunctions(rewindTime: number) {
    const goBackward = (player: VideoPlayer): boolean => {
        const currentTime = player.currentTime();
        if (currentTime > MIN_TIME_TO_REWIND) {
            player.trigger('rewindbackward');
            player.currentTime(currentTime - rewindTime);
            return true;
        }
        return false;
    };

    const goForward = (player: VideoPlayer): boolean => {
        const currentTime = player.currentTime();
        const remainingTime = player.remainingTime();
        if (remainingTime > MIN_TIME_TO_REWIND) {
            player.trigger('rewindforward');
            player.currentTime(currentTime + rewindTime);
            return true;
        }
        return false;
    };

    return {
        goForward,
        goBackward,
    };
}

export const togglePlay = (player: VideoPlayer) => {
    if (player.paused()) {
        player.play();
    } else {
        player.pause();
    }
};
