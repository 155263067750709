import { useSelector } from 'react-redux';
import { IS_FREE_BIZ_SAAS_USER } from 'reactApp/appHelpers/configHelpers';
import { getCreateDescriptions } from 'reactApp/modules/editor/editor.selectors';
import { getOwner } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.selectors';
import { getCurrentFolderHome } from 'reactApp/modules/home/home.selectors';
import { isIntegrationStorage } from 'reactApp/modules/storage/storage.helpers';
import { hasParentsDomainFolder } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { RootState } from 'reactApp/store';
import { DropdownTheme } from 'reactApp/ui/DropdownList/DropdownList';
import { TOOLBAR_CONFIG } from 'reactApp/ui/Toolbar/Toolbar.config';
import { getEditorsItem, getUploadItem, toolbarDropdownItems, toolbarItems } from 'reactApp/ui/Toolbar/Toolbar.data';
import type { TToolbarItem } from 'reactApp/ui/Toolbar/Toolbar.types';

export const useAddActions = ({ storage }: { storage: EStorageType }): TToolbarItem[] => {
    const settings = TOOLBAR_CONFIG[storage];
    const availableCreateEditors = useSelector(getCreateDescriptions);
    const currentFolder = useSelector(getCurrentFolderHome);
    const hasParentDomain = useSelector((state: RootState) => (currentFolder ? hasParentsDomainFolder(state, currentFolder) : false));
    const owner = useSelector(getOwner);
    if (!settings?.add) {
        return [];
    }

    const uploadItem = getUploadItem(storage);

    if (isIntegrationStorage(storage)) {
        return [uploadItem];
    }

    const isUserAllowedToCreateDocument =
        IS_FREE_BIZ_SAAS_USER &&
        currentFolder?.isMounted &&
        owner?.is_paid &&
        !currentFolder?.isReadOnly &&
        currentFolder?.kind !== 'domain-folder' &&
        !hasParentDomain;

    const list = IS_FREE_BIZ_SAAS_USER && !isUserAllowedToCreateDocument ? [] : [...getEditorsItem(availableCreateEditors)];

    if (storage !== EStorageType.alldocuments && storage !== EStorageType.incomingPublic) {
        list.unshift(toolbarDropdownItems.createFolder);
    }

    return [
        uploadItem,
        {
            ...toolbarItems.create,
            dropdownTheme: DropdownTheme.medium,
            list,
        } as TToolbarItem,
    ];
};
