import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import autumnPromoModalImage from 'img/aside-promo-modal/autumn-promo-modal.png';
import { IS_ACTION_USER } from 'reactApp/appHelpers/configHelpers';
import { promotionEndDate } from 'reactApp/appHelpers/featuresHelpers';
import { autumnPromo } from 'reactApp/appHelpers/featuresHelpers/features/autumnPromo';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getQuotaPromoUrl } from 'reactApp/sections/QuotaLanding/QuotaLanding.data';
import { store as reduxStore } from 'reactApp/store';
import { openAsidePromoModal } from 'reactApp/ui/AsidePromoModal/AsidePromoModal.helpers';
import opener from 'reactApp/utils/opener';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { put } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';

const AUTUMN_PROMO_MODAL_ID = 'autumn-promo-modal';
const PERIOD_IN_DAYS = 3;

export function* initAutumnPromoModal() {
    const storage = yield* select(getCurrentStorage);

    const periodPassed =
        storeHelper.getValue(AUTUMN_PROMO_MODAL_ID) && storeHelper.isPassed(AUTUMN_PROMO_MODAL_ID, { daysCount: PERIOD_IN_DAYS });

    if (storeHelper.getValue(AUTUMN_PROMO_MODAL_ID) && !periodPassed) {
        return;
    }

    const isCorrectUrl = storage === EStorageType.home || storage === EStorageType.public || storage === EStorageType.gallery;

    if (!IS_ACTION_USER || !isCorrectUrl || !autumnPromo) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.autumnPromo,
            promoId: AUTUMN_PROMO_MODAL_ID,
            onClick() {
                sendPaymentGa({
                    action: 'click',
                    eventCategory: ECategoryGa.balloon,
                    name: 'autumn_action',
                    place: storage,
                });

                opener(
                    getQuotaPromoUrl({
                        query: 'utm_source=cloud&utm_medium=web_union_quota_landing&utm_campaign=balloon_autumn_action',
                    }),
                    true
                );
                reduxStore.dispatch(removePromo(EPromoType.autumnPromo));
            },
            onShow: () => {
                storeHelper.markAsShown(AUTUMN_PROMO_MODAL_ID);
                sendPaymentGa({
                    action: 'show',
                    eventCategory: ECategoryGa.balloon,
                    name: 'autumn_action',
                    place: storage,
                });
                reduxStore.dispatch(promoShown(EPromoType.autumnPromo));
            },
            onClose: () => {
                reduxStore.dispatch(removePromo(EPromoType.autumnPromo));
            },
        })
    );
}

export function* showAutumnPromoModal() {
    const promo = yield* select(PromoSelectors.getPromo(EPromoType.autumnPromo));

    if (promo) {
        const { onShow, onClick, onClose } = promo;

        yield openAsidePromoModal({
            title: 'Скидки шуршат!',
            text: '4 ТБ в Облаке для ваших фото и\u00A0других файлов со скидкой до\u00A085%\u00A0— оформите Mail Space',
            buttonText: 'Забрать скидку',
            imageUrl: autumnPromoModalImage,
            showAboveViewer: true,
            alwaysDark: true,
            imageText: `Сроки акции с 21.10.2024 по ${format(
                parseISO(promotionEndDate),
                'dd.MM.yyyy'
            )}. Подробные условия: https://vk.cc/cCBra0`,
            onShow,
            onClick,
            onClose,
        });
    }
}
