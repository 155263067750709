import throttle from 'lodash.throttle';
import { splitEvery } from 'ramda';
import { updateItem } from 'reactApp/modules/modifying/modifying.actions';
import type { UpdateItemAction } from 'reactApp/modules/modifying/modifying.types';
import { uploadingService } from 'reactApp/modules/uploading/serviceClasses/UploadingService';
import { type IUpdateInputFile, EFileStatus, EProgressStatus } from 'reactApp/modules/uploadList/uploadList.model';
import {
    setProgressStatusAction,
    updateSmartUploadedBytes,
    updateSpeedForLowSpeed,
    updateTotalProgress,
    updateUploadFilesAction,
} from 'reactApp/modules/uploadList/uploadList.module';
import { store } from 'reactApp/store';
import { ActionsBatcher } from 'reactApp/utils/reduxHelpers';

export const updateUploadFilesActionBatcher = new ActionsBatcher<IUpdateInputFile | IUpdateInputFile[]>((items) => {
    const chunks = splitEvery<IUpdateInputFile>(100, items);

    chunks?.forEach((chunk) => {
        // Из-за троттлинга может прилететь прогресс после отмены загрузки, оно ломает подсчет общего состояния загрузки
        const chunkTemp = chunk.filter((item) => item.status !== EFileStatus.PROGRESS || !uploadingService.isUserCanceled);

        store.dispatch(updateUploadFilesAction(chunkTemp));
    });
}, 250);

export const updateItemActionBatcher = new ActionsBatcher<UpdateItemAction>((items) => {
    const chunks = splitEvery<UpdateItemAction>(100, items);

    chunks?.forEach((chunk) => {
        store.dispatch(updateItem(chunk));
    });
}, 500);

export const setProgressStatusThrottled = throttle((packetId: number) => {
    if (uploadingService.isUserCanceled) {
        return;
    }

    store.dispatch(setProgressStatusAction({ status: EProgressStatus.PROGRESS, packetId }));
}, 500);

export const uploaderUploadSpeedThrottled = throttle((speedMbSec: number) => {
    store.dispatch(updateTotalProgress({ speedMbSec }));
}, 500);

export const uploaderUploadSpeedForLowSpeedThrottled = throttle((speedMbSec: number) => {
    store.dispatch(updateSpeedForLowSpeed(speedMbSec));
}, 500);

export const uploaderSmartBytesThrottled = throttle((bytes: number) => {
    store.dispatch(updateSmartUploadedBytes(bytes));
}, 500);
