import { createAction, createReducer } from '@reduxjs/toolkit';
import type { EPdfErrorStatus } from 'reactApp/modules/pdfEditor/pdfEditor.types';
import type { EStatus } from 'reactApp/sections/ErrorPage/ErrorPage.types';

import type { HomeFile } from '../home/home.types';
import type { EStorageType } from '../storage/storage.types';

export const loadFile = createAction<{ id?: string | null; storage?: EStorageType | null }>('pdfEditor/loadFile');
export const autoSave = createAction<{ content: Uint8Array; editQueueIndex: number; fileEditQueueIndex: number }>('pdfEditor/autoSave');
export const saveFile = createAction<{ content: Uint8Array; editQueueIndex: number; fileEditQueueIndex: number }>('pdfEditor/saveFile');
export const setSavedIndex = createAction<{ editQueueIndex: number; fileEditQueueIndex: number }>('pdfEditor/fileSaved');
export const setFileHash = createAction<{ hash: string }>('pdfEditor/setFileHash');
export const pdfEditorError = createAction<{ error: EStatus | EPdfErrorStatus }>('pdfEditor/error');
export const notifyError = createAction<{ error: string } | undefined>('pdfEditor/notifyError');
export const pdfEditorPassword = createAction<{ password: string }>('pdfEditor/setPassword');
export const pdfEditorContent = createAction<{ content: Buffer }>('pdfEditor/setContent');
export const closePdfEditor = createAction('pdfEditor/close');
export const setOpenWorkWithPages = createAction<{ openWorkWithPages: boolean }>('pdfEditor/setOpenWorkWithPages');

export interface IPdfEditor {
    fileId: string;
    hash: string;
    storage: string;
    isError: boolean;
    isLoaded: boolean;
    savedEditQueueIndex: number;
    savedFileEditQueueIndex: number;
    password: string;
    isEncrypted: boolean;
    error: EStatus | EPdfErrorStatus | null;
    fileContent: Buffer | null;
    file?: HomeFile;
    openWorkWithPages: boolean;
}

const initialState: IPdfEditor = {
    fileId: '',
    hash: '',
    storage: '',
    fileContent: null,
    isLoaded: false,
    isError: false,
    savedEditQueueIndex: 0,
    savedFileEditQueueIndex: 0,
    password: '',
    isEncrypted: false,
    error: null,
    openWorkWithPages: false,
};

export const pdfEditorReducer = createReducer(initialState, {
    [loadFile.type]: (state, action: ReturnType<typeof loadFile>): void => {
        state.fileId = action.payload.id || '';
        state.storage = action.payload.storage || '';
        state.isError = false;
        state.isLoaded = false;
        state.fileContent = null;
    },
    [pdfEditorError.type]: (state, action: ReturnType<typeof pdfEditorError>): void => {
        state.error = action.payload.error || '';
        state.isError = true;
        state.isLoaded = true;
    },
    [pdfEditorContent.type]: (state, action: ReturnType<typeof pdfEditorContent>): void => {
        state.fileContent = action.payload.content;
        state.isLoaded = true;
    },
    [pdfEditorPassword.type]: (state, action: ReturnType<typeof pdfEditorPassword>): void => {
        state.password = action.payload.password;
        state.isEncrypted = true;
    },
    [setFileHash.type]: (state, action: ReturnType<typeof setFileHash>) => {
        state.hash = action.payload.hash;
    },
    [setSavedIndex.type]: (state, action: ReturnType<typeof setSavedIndex>) => {
        state.savedEditQueueIndex = action.payload.editQueueIndex;
        state.savedFileEditQueueIndex = action.payload.fileEditQueueIndex;
    },
    [closePdfEditor.type]: () => {
        return initialState;
    },
    [setOpenWorkWithPages.type]: (state, action: ReturnType<typeof setOpenWorkWithPages>) => {
        state.openWorkWithPages = action.payload.openWorkWithPages;
    },
});
