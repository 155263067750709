import type { IInputFile } from 'reactApp/modules/uploadList/uploadList.model';
import { createGaSender } from 'reactApp/utils/ga';

export const sendGaUploader = createGaSender('uploader');
export const sendGaTouchUploader = createGaSender('touchuploader');

export const animationDelay = 1000;

export const fileInFolder = (path: string) => (path?.split('/') || []).length > 2;

export const folderName = (path: string) => (path?.split('/') || [])[1];

// выделяем путь до папки: /папка/файл.jpg -> /папка; /файл.jpg -> /
export const folderCloudPath = (path: string) => {
    if (!path) {
        return '';
    }

    const splitPath = path?.split('/') || [];
    return splitPath.slice(0, splitPath.length - 1).join('/') || '/';
};

export const findFolderCloudPath = (cloudPath: string, folderName: string) => {
    const path = folderCloudPath(cloudPath);
    const start = path.indexOf(folderName);
    if (start > 0) {
        return path.slice(0, Math.max(0, start + folderName.length));
    }
    return path;
};

export const isFileInCurrentFolder = (path: string) => {
    const pathName = window.location.pathname;
    const urlEndsWithSlash = pathName.charAt(pathName.length - 1) === '/' ? '/' : '';
    const itemPath = `${folderCloudPath(path)}${urlEndsWithSlash}`;

    return encodeURI(`/home${itemPath}`) === pathName || encodeURI(`/public/${itemPath}`) === pathName;
};

// удаляем поля, чтобы не сработала сага
export const removeFields = (item: IInputFile) => {
    const editedFile = { ...item };
    delete editedFile.status;
    delete editedFile.highlight;
    delete editedFile.loaded;

    return editedFile;
};
