import React, { type ChangeEvent, forwardRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeHistory } from 'reactApp/modules/router/router.module';
import { getAllowedMimeTypes } from 'reactApp/modules/upload/upload.selectors';
import { addToUploadQueue } from 'reactApp/modules/uploading/uploading.module';
import type { EDocumentType } from 'reactApp/sections/PersonalDocuments/PersonalDocuments.constants';

import styles from './DocumentUpload.css';

interface Props {
    type: EDocumentType;
    onUpload?: () => void;
}

export const DocumentUpload = forwardRef<HTMLInputElement, Props>(({ type, onUpload }, ref) => {
    const dispatch = useDispatch();
    const allowedMimeTypes = useSelector(getAllowedMimeTypes)?.join(',');

    const onInputChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const files = e.target?.files;

            if (!files?.length) {
                return;
            }

            dispatch(addToUploadQueue({ files: Array.from(files), descriptorOptions: { documentType: type } }));
            dispatch(changeHistory(`/documents/${type}`));
            onUpload?.();
        },
        [dispatch, type, onUpload]
    );

    return <input type="file" multiple className={styles.input} ref={ref} onChange={onInputChange} accept={allowedMimeTypes} />;
});

DocumentUpload.displayName = 'DocumentUpload';
