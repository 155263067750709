import classNames from 'clsx';
import webviewTitleCoverImage from 'img/quota-landing/blackFriday2024/webviewTitle.png';
import CyberMonday2025WebviewTitleCoverImage from 'img/quota-landing/cyberMonday2025/webviewTitle.png';
import React, { memo, useCallback } from 'react';
import { isRebrandingQuotaLanding } from 'reactApp/appHelpers/featuresHelpers';
import { autumnPromo } from 'reactApp/appHelpers/featuresHelpers/features/autumnPromo';
import { blackFriday2024 } from 'reactApp/appHelpers/featuresHelpers/features/blackFriday2024';
import { cyberMonday2025 } from 'reactApp/appHelpers/featuresHelpers/features/cyberMonday2025';
import { sendQuotaGa } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { Button } from 'reactApp/ui/Button/Button';
import { renderMobileDiscountsModal } from 'reactApp/ui/Mobile/DiscountsModal/DiscountsModal.helpers';

import styles from './Header.css';

export const Header = memo(() => {
    const onActionClick = useCallback(() => {
        sendQuotaGa({ action: 'action', label: 'click' });
        renderMobileDiscountsModal({ isRebrandingQuotaLanding });
    }, []);

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_rebranding]: isRebrandingQuotaLanding,
                [styles.root_autumnPromo]: autumnPromo,
                [styles.root_cyberMonday2025]: cyberMonday2025,
            })}
        >
            <div className={styles.title}>
                Тарифы <br /> Mail Space
                {blackFriday2024 && <img src={webviewTitleCoverImage} className={styles.webviewTitleImage} alt="cover image" />}
                {cyberMonday2025 && (
                    <img src={CyberMonday2025WebviewTitleCoverImage} className={styles.webviewTitleImage} alt="cover image" />
                )}
            </div>
            <Button className={styles.button} onClick={onActionClick}>
                Акции
            </Button>
        </div>
    );
});

Header.displayName = 'Header';
