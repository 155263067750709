import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { sortPromoListToShow } from 'reactApp/modules/promoTariffs/promoTariffs.selectors';
import { SubscriptionsSelector } from 'reactApp/modules/subscriptions/subscriptions.selectors';
import { SUBSCRIPTION_ANALYTICS } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscription.analytics';
import { useSendGa } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage.hooks';
import type { SubscriptionList } from 'reactApp/ui/Mobile/SubscriptionsModal/SubscriptionsModal.types';
import { sendDwh } from 'reactApp/utils/ga';

export const useHandlers = () => {
    const basicSubscription = useSelector(SubscriptionsSelector.getBasicSubscription);
    const subscriptions = useSelector(SubscriptionsSelector.sortSubscriptionsBySpace);
    const promoSubscriptions = useSelector(sortPromoListToShow);
    const subscriptionList = [basicSubscription, ...subscriptions, ...promoSubscriptions] as SubscriptionList;
    const sendGa = useSendGa();

    const handleAllTariffsClick = useCallback(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        sendGa('all-tariffs');
    }, [sendGa]);

    const handleFAQClick = useCallback(() => {
        sendDwh({
            ...SUBSCRIPTION_ANALYTICS.FAQ_CANCEL_SUBS_OPEN,
            dwhData: {
                count_subs: subscriptionList.length,
                source: 'touch',
            },
        });
    }, [subscriptionList.length]);

    return {
        handleAllTariffsClick,
        handleFAQClick,
    };
};
