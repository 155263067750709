import { BIG_TRASHBIN_SIZE_INFO } from 'reactApp/appHelpers/featuresHelpers/features/bigTrashbinSizeInfo';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import type { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

const getTrashbin = (state: RootState) => state.trashbin;

export const getBins = createSelector(getTrashbin, (trashbin) => trashbin.bins);
export const getTrashbinFiles = createSelector(getTrashbin, (trashbin) => trashbin.files);
export const getOpenedBinId = (state: RootState) => getTrashbin(state).opened;
export const getBinById = createSelector(
    getBins,
    (state, id: string) => id,
    (bins, id) => bins.find((bin) => bin.id === id)
);
export const getOpenedBin = createSelector(
    getOpenedBinId,
    (state) => state,
    (id, state) => getBinById(state, id)
);
export const getBinItemById = createSelector(
    getTrashbinFiles,
    (state, id: string) => id,
    (files, id) => files[id]
);

export const getOpenedBinCountAll = createSelector(getOpenedBin, (trashbin) => trashbin?.count?.all);
export const getRemovingState = createSelector(getTrashbin, (trashbin) => trashbin.removing);
export const getTrashbinSize = createSelector(getTrashbin, (trashbin) => trashbin?.size ?? 0);
export const isLargeTrashbinInfoVisible = createSelector([getTrashbinSize, UserSelectors.getCloudSpace], (trashbinSize, cloudSpace) => {
    const trashbinPercent = Math.max(1, Math.round((trashbinSize / cloudSpace.total.original) * 100));

    /*
        Бизнес-требования показа инфы о большой корзине:
        - корзина занимает более 10% пространства облака;
        - облако заполненj более чем на 80%;
    */
    return BIG_TRASHBIN_SIZE_INFO && trashbinPercent > 10 && cloudSpace.usedPercent > 80;
});
