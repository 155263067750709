import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isTabFolderSharingDesign } from 'reactApp/appHelpers/featuresHelpers/features/tabsFolderSharingDesign';
import type { IProps } from 'reactApp/components/SharingWindow/Sharing.types';
import type { PublishItem } from 'reactApp/modules/modifying/modifying.types';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { getItemById, getStorageItemById } from 'reactApp/modules/storage/storage.selectors';
import type { EStorageType } from 'reactApp/modules/storage/storage.types';
import { createGaSender } from 'reactApp/utils/ga';

import { AB_FEATURE_NAME } from './Sharing.constants';

export const sendGa = createGaSender(AB_FEATURE_NAME);

export const renderSharingNew = (data: IProps) => openPopupHelper<IProps>({ popupName: popupNames.SHARING_DIALOG, data });

export const useIsTabsDesign = ({ id, item, itemStorage }: { id: string; item?: PublishItem; itemStorage?: EStorageType }): boolean => {
    const getItem = useCallback(
        (state) => {
            if (item) {
                return item;
            }

            return itemStorage ? getStorageItemById(state, itemStorage, id) : getItemById(state, id);
        },
        [id, item, itemStorage]
    );

    const resolvedItem = useSelector(getItem);

    const isFolder = resolvedItem?.isFolder;

    return isTabFolderSharingDesign && isFolder;
};
