import { lazyInit } from '@mail-core/std';

export const lazyKotlett = lazyInit(() =>
    Promise.all([
        import(/* webpackChunkName: "kotlett" */ '@mail/kotlett'),
        import(/* webpackChunkName: "kotlett-feature-bundle" */ '@mail/kotlett-feature-bundle'),
    ]).then(([kotletModule, featuresModule]) => {
        const { WebKotlett } = kotletModule;
        const { runtime } = featuresModule;

        return { WebKotlett, runtime };
    })
);
