import { isFamilySubsInTariff, moreBenefitsInExpensiveTariffs } from 'reactApp/appHelpers/featuresHelpers';
import { SPACE_LIST } from 'reactApp/constants/spaceList';

import { type BenefitsInfoType, BenefitKeyType, benefitsInfo } from './benefits.info';
// tempexp_16800-start
const isExpActive = moreBenefitsInExpensiveTariffs === 'a';

type BenefitsBySpaceType = Partial<Record<number, Partial<BenefitsInfoType>>>;

export const benefitsBySpace: BenefitsBySpaceType = {
    [SPACE_LIST.tb_2]: {
        ad: benefitsInfo[BenefitKeyType.Ad],
        upload: benefitsInfo[BenefitKeyType.Upload],
        family: isFamilySubsInTariff ? benefitsInfo[BenefitKeyType.Family] : undefined,
        support: benefitsInfo[BenefitKeyType.Support],
        mobile: benefitsInfo[BenefitKeyType.Mobile],
        safety: benefitsInfo[BenefitKeyType.Safety],
        calendar: benefitsInfo[BenefitKeyType.Calendar],
        folders: benefitsInfo[BenefitKeyType.Folders],
    },
    [SPACE_LIST.tb_1]: {
        ad: benefitsInfo[BenefitKeyType.Ad],
        upload: benefitsInfo[BenefitKeyType.Upload],
        family: isFamilySubsInTariff ? benefitsInfo[BenefitKeyType.Family] : undefined,
        support: benefitsInfo[BenefitKeyType.Support],
        mobile: isExpActive ? benefitsInfo[BenefitKeyType.Mobile] : undefined,
        safety: benefitsInfo[BenefitKeyType.Safety],
        calendar: benefitsInfo[BenefitKeyType.Calendar],
        folders: isExpActive ? benefitsInfo[BenefitKeyType.Folders] : undefined,
    },
    [SPACE_LIST.gb_256]: {
        ad: benefitsInfo[BenefitKeyType.Ad],
        upload: benefitsInfo[BenefitKeyType.Upload],
        family: isFamilySubsInTariff ? benefitsInfo[BenefitKeyType.Family] : undefined,
        support: benefitsInfo[BenefitKeyType.Support],
        mobile: isExpActive ? benefitsInfo[BenefitKeyType.Mobile] : undefined,
        safety: isExpActive ? benefitsInfo[BenefitKeyType.Safety] : undefined,
        calendar: isExpActive ? benefitsInfo[BenefitKeyType.Calendar] : undefined,
        folders: isExpActive ? benefitsInfo[BenefitKeyType.Folders] : undefined,
    },
};

export function checkAdditionalBenefitsInSpace(space: number) {
    return !!benefitsBySpace[space];
}

// tempexp_16800-end
