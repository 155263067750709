import { logger } from 'lib/logger';
import { cancelAutopayFamily } from 'reactApp/appHelpers/featuresHelpers/features/cancelAutopayFamily';
import { UserQuotaSelectors } from 'reactApp/modules//userQuota/userQuota.selectors';
import { isFamilyEmpty, isUserFamilyMember, isUserFamilyOwner } from 'reactApp/modules/family/family.selectors';
import { SubscriptionsSelector } from 'reactApp/modules/subscriptions/subscriptions.selectors';
import { captureException } from 'reactApp/utils/tracer';
import { select } from 'redux-saga/effects';

// tempexp_17359-start
export function* canShowFamilyModal() {
    const isOverquota = yield select(UserQuotaSelectors.isOverquota);
    const isFamilyMember = yield select(isUserFamilyMember);
    const isFamilyOwner = yield select(isUserFamilyOwner);
    const subscriptionsCount = yield select(SubscriptionsSelector.getSubscriptionsCount);
    const isEmptyFamily = yield select(isFamilyEmpty);
    return (
        cancelAutopayFamily &&
        !isFamilyMember &&
        ((!isOverquota && !isFamilyMember && !isFamilyOwner) ||
            (isFamilyOwner && !isEmptyFamily && subscriptionsCount === 1) ||
            (isFamilyOwner && isEmptyFamily))
    );
}
// tempexp_17359-end

export function logError(error: unknown) {
    logger.error(error);
    captureException(error, { issueKey: 'subs renewal error' });
}
