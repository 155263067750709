import { Icon12Cancel, Icon16Cancel, Icon16Done } from '@vkontakte/icons';
import theme from '@vkontakte/vkui-tokens/themes/cloud/cssVars/theme';
import { ReactComponent as MarkIcon } from 'img/icons/mark2.svg';
import React from 'react';

export const templateFeatureIcons = {
    vkui16Done: <Icon16Done fill={theme.colorIconAccent.normal.value} />,
    vkui16Cancel: <Icon16Cancel fill={theme.colorIconSecondary.normal.value} />,
    vkui12Cancel: <Icon12Cancel fill={theme.colorIconSecondary.normal.value} width={14} height={14} />,
    mark12x11: <MarkIcon width={12} height={11} />,
    mark13x11: <MarkIcon width={13} height={11} />,
};
