import { classNames } from '@vkontakte/vkui';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NEW_PORTAL_HEADER, platform } from 'reactApp/appHelpers/configHelpers';
import { getQueryParams, getUtmObject } from 'reactApp/appHelpers/settingsHelpers';
import { loadIncomingPublicRequest } from 'reactApp/modules/incomingPublic/incomingPublic.module';
import { getIncomingPublicState } from 'reactApp/modules/incomingPublic/incomingPublic.selectors';
import type { EIncomingPublicCategory } from 'reactApp/modules/incomingPublic/incomingPublic.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { MainPage } from 'reactApp/sections/MainPage/MainPage';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import { sendDwh, sendXray } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

import styles from './IncomingPublicPage.css';

interface IIncomingPublicPageProps {
    showSpace?: boolean;
    category: EIncomingPublicCategory;
}

const IncomingPublicPage = ({ showSpace = false, category }: IIncomingPublicPageProps) => {
    const dispatch = useDispatch();
    const { isLoaded } = useSelector(getIncomingPublicState);
    const [isSent, setIsSent] = useState(false);

    useEffect(() => {
        if (isSent) {
            return;
        }

        const queries = getQueryParams();
        const { utm_source } = getUtmObject();

        const tab = queries?.fromPage;

        const addDwhParams = {
            ...(tab ? { tab } : {}),
            ...(utm_source ? { referal: utm_source } : {}),
        };

        setIsSent(true);

        sendDwh({
            eventCategory: ECategoryGa.entered,
            action: 'incoming-public',
            dwhData: {
                platform,
                ...addDwhParams,
            },
        });
    }, [isSent]);

    useEffect(() => {
        dispatch(loadIncomingPublicRequest({ category }));
        sendXray(['page', 'enter', 'incoming-public']);
    }, [category, dispatch]);

    useEffect(() => {
        if (!isLoaded) {
            return;
        }

        sendDwh({
            eventCategory: ECategoryGa.entered,
            action: 'incoming-public-section',
            dwhData: {
                platform,
            },
        });
    }, [isLoaded]);

    return (
        <MainPage storage={EStorageType.incomingPublic} renderWorm showSpace={showSpace}>
            <div className={styles.root}>
                <div className={classNames({ [styles.headerContainerNewPortalHeader]: NEW_PORTAL_HEADER })}>
                    <BreadcrumbsContainer />
                </div>
                <div className={classNames({ [styles.datalistContainerNewPortalHeader]: NEW_PORTAL_HEADER })}>
                    <DataList goTo={noop} storage={EStorageType.incomingPublic} />
                </div>
            </div>
        </MainPage>
    );
};

export default IncomingPublicPage;
