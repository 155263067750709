import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import React, { type VFC, useEffect } from 'react';
import { IS_REACT_PROMO_QUOTA_TARIFFS_PAGE } from 'reactApp/appHelpers/configHelpers';
import { promotionEndDate } from 'reactApp/appHelpers/featuresHelpers';
import { autumnPromo } from 'reactApp/appHelpers/featuresHelpers/features/autumnPromo';
import type { ACTIONS_DEFS } from 'reactApp/ui/TariffSectionNew/TariffSection.constants';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

import { ACTIVATE_PROMOCODE_CARD, DISCOUNTS } from './Components/DiscountCard/DiscountCard.constant';
import type { Props as DiscountCardProps } from './Components/DiscountCard/DiscountCard.types';
import { DiscountList } from './Components/DiscountList/DiscountList';
import { EmptyDiscounts } from './Components/EmptyDiscounts/EmptyDiscounts';
import type { Discount } from './Discounts.types';

type Props = {
    marketingPromo?: (typeof ACTIONS_DEFS)['marketingPromo'];
    discounts: Discount[];
    isCompact?: boolean;
    isRebrandingQuotaLanding?: boolean;
    subscriptionsPage?: boolean;
};

const sendAnalytics = (action: string, label: string) => {
    sendDwh({
        eventCategory: IS_REACT_PROMO_QUOTA_TARIFFS_PAGE ? ECategoryGa.quotaTariffs : ECategoryGa.payment,
        action,
        label,
    });
};

export const Discounts: VFC<Props> = ({ marketingPromo, discounts, isCompact, isRebrandingQuotaLanding, subscriptionsPage }) => {
    const showDiscounts = discounts.length || marketingPromo;

    const list: Array<Omit<DiscountCardProps, 'onClick' | 'buttonText'>> = [
        ...(autumnPromo && !subscriptionsPage
            ? [
                  {
                      id: DISCOUNTS.autumnPromo,
                      type: DISCOUNTS.autumnPromo,
                      title: 'Фото не превратятся в тыкву',
                      text: 'Если сохранить их в Облаке — заберите\u00A0скидку 85% на Mail Space',
                      analyticsName: 'autumn_action',
                      promoConditions: `Сроки акции с 21.10.2024 по ${format(
                          parseISO(promotionEndDate),
                          'dd.MM.yyyy'
                      )}. Подробные условия: https://vk.cc/cCBra0`,
                  },
              ]
            : []),
        ...discounts,
    ];

    list.push(ACTIVATE_PROMOCODE_CARD);

    useEffect(() => {
        sendAnalytics(showDiscounts ? 'discounts-empty' : 'discounts', 'show');
    }, []);

    return showDiscounts ? (
        <DiscountList data={list} isRebrandingQuotaLanding={isRebrandingQuotaLanding} />
    ) : (
        <EmptyDiscounts isCompact={isCompact} isRebrandingQuotaLanding={isRebrandingQuotaLanding} />
    );
};
