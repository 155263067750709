export const HttpErrorCodes = {
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    READ_ONLY_ACCESS: 405,
    REQUEST_TIMEOUT: 408,
    CONFLICT: 409,
    TOO_LARGE: 413,
    ILLEGAL: 451,
    INTERNAL_SERVER_ERROR: 500,
    BAD_GATEWAY: 502,
    SERVICE_UNAVAILABLE: 503,
    GATEWAY_TIMEOUT: 504,
    INSUFFICIENT_STORAGE: 428,
    INSUFFICIENT_STORAGE_PREV: 507,
};

export const HttpOkCodes = {
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
};

const readOnlyHttpStatuses = [
    HttpErrorCodes.READ_ONLY_ACCESS,
    HttpErrorCodes.INSUFFICIENT_STORAGE,
    HttpErrorCodes.INSUFFICIENT_STORAGE_PREV,
];

export const isReadOnlyHttpStatus = (status: number) => readOnlyHttpStatuses.includes(status);
