import { Spacing } from '@vkontakte/vkui';
import classNames from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import type { Product } from 'reactApp/types/Billing';
import Content from 'reactApp/ui/Content/Content';
import { ETabsName } from 'reactApp/ui/TariffSectionNew/TariffSection.constants';

import { TariffList } from './TariffList/TariffList';
import { TariffPeriodSwitch } from './TariffPeriodSwitch/TariffPeriodSwitch';
import styles from './TariffsModalContent.css';
import type { TariffByModalTab } from './TariffsModalContent.types';

interface TariffsModalContentProps {
    title: string;
    className?: string;
    titleClassName?: string;
    activeTab: ETabsName;
    setActiveTab: (tab: ETabsName) => void;
    tariffs: TariffByModalTab[];
    yearTariffsCount: number;
    monthTariffsCount: number;
    onBuyClick: (product: Product) => void;
    isIframe?: boolean;
}

export function TariffsModalContent({
    title,
    titleClassName,
    className,
    activeTab,
    setActiveTab,
    tariffs,
    yearTariffsCount,
    monthTariffsCount,
    onBuyClick,
    isIframe = false,
}: TariffsModalContentProps) {
    const { hasError } = useSelector(ProductsSelectors.getLifeCycleState);
    const tariffsCount = activeTab === ETabsName.year ? yearTariffsCount : monthTariffsCount;
    return (
        <Content wrapClass={classNames(styles.content, className, { [styles.isIframe]: isIframe })} hasError={hasError}>
            <div className={classNames(styles.title, titleClassName)}>{title}</div>
            <Spacing size={32} />
            <TariffPeriodSwitch checkedOption={activeTab} onClickSwitch={setActiveTab} />
            <Spacing size={24} />
            <TariffList tariffs={tariffs} tariffsCount={tariffsCount} activeTab={activeTab} onBuyClick={onBuyClick} />
        </Content>
    );
}
