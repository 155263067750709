import { isProgressComplete } from 'reactApp/modules/uploadList/uploadList.getters';
import { EProgressStatus } from 'reactApp/modules/uploadList/uploadList.model';
import { setPercentLoaded, setProgressStatusAction, sortFilesByError } from 'reactApp/modules/uploadList/uploadList.module';
import { getCountLeft, getProgressStatus } from 'reactApp/modules/uploadList/uploadList.selectors';
import { updateUser } from 'reactApp/modules/user/user.thunkActions';
import { put, select } from 'redux-saga/effects';

export function* checkProgressStatus(forceCheck = false) {
    const countLeft: number = yield select(getCountLeft);

    // TODO: отменять загруку после фактической отмены, а не из UI
    if (countLeft > 0 && !forceCheck) {
        return;
    }

    // После массовых действий типа отмены загрузки папки прилетает куча одинаковых событий
    // и мы отсылаем кучу setPercentLoaded(100)
    // и в sortFilesByError каждый раз делаем сортировку
    const isComplete = isProgressComplete(yield select(getProgressStatus));

    if (isComplete) {
        return;
    }

    // @ts-ignore
    yield put(updateUser());
    yield put(setProgressStatusAction({ status: EProgressStatus.COMPLETE }));
    yield put(setPercentLoaded(100));

    yield put(sortFilesByError());
}
