import { Spacing, Text, Title } from '@vkontakte/vkui';
import folderImg from 'img/tooltip/public_dwnld_disable.png';
import docImg from 'img/tooltip/public_dwnld_disable_doc.png';
import fileImg from 'img/tooltip/public_dwnld_disable_file.png';
import React, { type RefObject, forwardRef, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import type { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { sendDisableDownloadDwh } from 'reactApp/ui/DisableSeoTooltip/sendDisableDownloadDwh.helper';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';

import styles from './Tooltip.css';

type TooltipProps = {
    newFeatureText: boolean;
    isFolder: boolean;
    isDocument: boolean;
    storage?: EStorageType | 'unk';
    onClose: () => void;
    onClick: () => void;
};

export const DISABLE_PUBLIC_DOWNLOAD_TOOLTIP_ID = 'publicDownloadTooltipId';

export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
    ({ newFeatureText = false, isFolder = false, isDocument = false, onClose, onClick, storage = 'unk' }, ref) => {
        const dispatch = useDispatch();

        useEffect(() => {
            dispatch(UserStorageActions.set(DISABLE_PUBLIC_DOWNLOAD_TOOLTIP_ID, true));
        }, []);

        const handleBuyClick = useCallback(() => {
            sendDisableDownloadDwh({ eventCategory: 'ban-tooltip', action: 'click', storage, isPaid: newFeatureText });

            if (!newFeatureText) {
                onClick();
            }

            onClose();
        }, [dispatch, onClose, newFeatureText]);

        const handleClose = useCallback(() => {
            onClose();
        }, [onClose]);

        const text = newFeatureText ? (
            <>
                Вы можете запретить скачивать ваши
                <br />
                файлы, когда делитесь ими по ссылке
            </>
        ) : (
            <>
                С подпиской Mail Space ваши файлы
                <br />
                скачают только с вашего разрешения
            </>
        );

        const imgUrl = isFolder ? folderImg : isDocument ? docImg : fileImg;

        return (
            <FloatingTooltip
                onClose={handleClose}
                target={ref as RefObject<HTMLDivElement>}
                placement={ETooltipPlacement.right}
                qaId="public-download-disable"
                withinModal
                closable
                closeOnOutsideClick
                usePortal
            >
                <div className={styles.tooltip}>
                    <Title className={styles.tooltipTitle}>{newFeatureText ? 'Новая настройка' : 'Смотреть, но не скачивать'}</Title>
                    <Spacing size={4} />
                    <Text className={styles.tooltipText}>{text}</Text>
                    <Spacing size={8} />
                    <Button theme="vk" sizeMode={ButtonSizeMode.small} className={styles.tooltipButton} onClick={handleBuyClick}>
                        {newFeatureText ? 'Хорошо' : 'Попробовать за 1 ₽'}
                    </Button>
                    <img src={imgUrl} alt="Смотреть, но не скачивать — с подпиской Mail Space" className={styles.image} />
                </div>
            </FloatingTooltip>
        );
    }
);

Tooltip.displayName = 'DisablePublicDownloadTooltip';
