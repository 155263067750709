import './AlbumsTokens.css';

import classNames from 'clsx';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NEW_PORTAL_HEADER } from 'reactApp/appHelpers/configHelpers';
import { createAlbumRequest, loadAlbumsListRequest } from 'reactApp/modules/albums/albums.actions';
import { CUSTOM_ALBUMS_ID } from 'reactApp/modules/albums/albums.constants';
import { getCustomAlbumsList, getSharedAlbums } from 'reactApp/modules/albums/albums.selector';
import { routeChangeSuccess } from 'reactApp/modules/router/router.module';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { Footer } from 'reactApp/ui/Footer/Footer';
import { PageTabMenu } from 'reactApp/ui/PageTabMenu/PageTabMenu';

import { Album } from './Album/Album';
import { AlbumsGrid } from './AlbumsGrid/AlbumsGrid';
import { PersonalAlbumsPageTabs as CustomAlbumsPageTabs } from './AlbumsPage.types';
import { CreateAlbum } from './CreateAlbum/CreateAlbum';
import styles from './CustomAlbumsPage.css';

export const personalAlbumsPageTabs = [
    {
        name: 'Все',
        value: CustomAlbumsPageTabs.all,
    },
    {
        name: 'Общие',
        value: CustomAlbumsPageTabs.shared,
    },
];

export const CustomAlbumsPage = memo(function CustomAlbumsPage() {
    const dispatch = useDispatch();
    const [currentTab, setCurrentTab] = useState(CustomAlbumsPageTabs.all);

    useEffect(() => {
        dispatch(
            routeChangeSuccess({ id: CUSTOM_ALBUMS_ID, storage: EStorageType.albums, params: {}, __isFolder: true, url: '', __parent: '' })
        );
        dispatch(loadAlbumsListRequest());
    }, []);

    function handleTabsClick(selectedTab: CustomAlbumsPageTabs) {
        setCurrentTab(selectedTab);
    }

    function hadndleAlbumCreate() {
        dispatch(createAlbumRequest());
    }

    const albums = useSelector(getCustomAlbumsList);
    const shared = useSelector(getSharedAlbums);

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_new_portal_header]: NEW_PORTAL_HEADER,
            })}
        >
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <BreadcrumbsContainer />
                    <PageTabMenu current={currentTab} tabs={personalAlbumsPageTabs} onClick={handleTabsClick} />
                </div>

                <div className={styles.grid_container}>
                    <AlbumsGrid>
                        <div className={styles.album}>
                            <CreateAlbum onClick={hadndleAlbumCreate} />
                        </div>
                        {currentTab === CustomAlbumsPageTabs.all && albums.map((album) => <Album key={album.id} {...album} />)}
                        {currentTab === CustomAlbumsPageTabs.shared && shared.map((album) => <Album key={album.id} {...album} />)}
                    </AlbumsGrid>
                </div>

                <Footer storage={EStorageType.albums} isAbsolutePos={true} />
            </div>
        </div>
    );
});
