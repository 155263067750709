import React, { type ChangeEvent, type MouseEvent, useCallback, useRef, useState } from 'react';
import type { EStorageType } from 'reactApp/modules/storage/storage.types';
import { sendDisableDownloadDwh } from 'reactApp/ui/DisableSeoTooltip/sendDisableDownloadDwh.helper';

import { TooltipShort } from './TooltipShort/TooltipShort';

export const useTooltipShort = (onClick: () => void, storage?: EStorageType, isPaid = false) => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    const target = useRef(null as HTMLDivElement | HTMLInputElement | null);

    const handleShowTip = useCallback(
        (event: MouseEvent<HTMLDivElement> | ChangeEvent<HTMLInputElement>) => {
            target.current = event.currentTarget;

            setShowTooltip(!showTooltip);

            if (!showTooltip) {
                sendDisableDownloadDwh({
                    eventCategory: 'ban-tooltip-mini',
                    action: 'show',
                    storage,
                    isPaid,
                });
            }
        },
        [showTooltip, isPaid, storage]
    );

    const handleCloseTip = useCallback(() => {
        setShowTooltip(false);
    }, []);

    const handleClick = useCallback(() => {
        onClick();
        setShowTooltip(false);
        sendDisableDownloadDwh({
            eventCategory: 'ban-tooltip-mini',
            action: 'click',
            storage,
            isPaid,
        });
    }, [onClick, storage, isPaid]);

    const tooltipShort = showTooltip ? <TooltipShort target={target} onClose={handleCloseTip} onClick={handleClick} /> : null;

    return { handleShowTip, tooltipShort };
};
