import type { PayloadAction } from '@reduxjs/toolkit';
import { del, delMany, entries, setMany } from 'idb-keyval';
import { extInfo } from 'lib/extInfo';
import { path } from 'ramda';
import { PLATFORM } from 'reactApp/appHelpers/configHelpers';
import { sizeGroups } from 'reactApp/constants';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { getExtension } from 'reactApp/modules/file/helpers/getExtention';
import { isReadOnly } from 'reactApp/modules/home/home.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { closeUploadPanelAction, openUploadPanelAction, uploaderTotalProgress } from 'reactApp/modules/upload/upload.module';
import { getAllowedExtensions } from 'reactApp/modules/upload/upload.selectors';
import { FileConflict } from 'reactApp/modules/uploading/errors/FileConflict';
import { FileTooLargeError } from 'reactApp/modules/uploading/errors/FileTooLargeError';
import { ConnectionFail } from 'reactApp/modules/uploading/fails/ConnectionFail';
import { FileReaderError } from 'reactApp/modules/uploading/fails/FileReaderError';
import { HashCalcError } from 'reactApp/modules/uploading/fails/HashCalcError';
import { LocalFileNotFoundFail } from 'reactApp/modules/uploading/fails/LocalFileNotFoundFail';
import { LocalFileNotReadableFail } from 'reactApp/modules/uploading/fails/LocalFileNotReadableFail';
import { OverQuotaFail } from 'reactApp/modules/uploading/fails/OverQuotaFail';
import { ReadOnlyDirectoryFail } from 'reactApp/modules/uploading/fails/ReadOnlyDirectoryFail';
import { RetryUploadFileFail } from 'reactApp/modules/uploading/fails/RetryUploadFileFail';
import { UnsupportedFolderTransferFail } from 'reactApp/modules/uploading/fails/UnsupportedFolderTransferFail';
import { UserFileSizeLimitFail } from 'reactApp/modules/uploading/fails/UserFileSizeLimitFail';
import { joinPath } from 'reactApp/modules/uploading/helpers/fs/fs.helpers';
import { getWeightedMovingAverage, sendGaUploaderNew, sendUploadSpeedMetric } from 'reactApp/modules/uploading/helpers/uploading.helpers';
import { addToUserCloud } from 'reactApp/modules/uploading/sagas/addToUserCloud';
import { processFileUploadError } from 'reactApp/modules/uploading/sagas/processFileUploadError';
import { handleProcessUploadQueue } from 'reactApp/modules/uploading/sagas/processUploadQueue';
import { uploaderSmartBytesThrottled, uploaderUploadSpeedThrottled } from 'reactApp/modules/uploading/serviceClasses/batchHelpers';
import { MB } from 'reactApp/modules/uploading/serviceClasses/helpers';
import { Uploader } from 'reactApp/modules/uploading/serviceClasses/Uploader';
import { UploaderData } from 'reactApp/modules/uploading/serviceClasses/UploaderData';
import { UploadingCancel } from 'reactApp/modules/uploading/serviceClasses/UploadingCancel';
import type { UploadingDescriptor } from 'reactApp/modules/uploading/serviceClasses/UploadingDescriptor';
import { uploadingLog } from 'reactApp/modules/uploading/serviceClasses/UploadingLog';
import { type UploadingReason, EUploadReasonSource } from 'reactApp/modules/uploading/serviceClasses/UploadingReason';
import { uploadingService } from 'reactApp/modules/uploading/serviceClasses/UploadingService';
import {
    BIG_FILE_SZIE,
    DEFAULT_FILE_UPLOAD_SPEED,
    RETRY_TIMEOUT_CONNECTION_ERROR,
    RETRY_TIMEOUT_ERROR_FILE_SKIP,
} from 'reactApp/modules/uploading/uploading.constants';
import { cancelUploading, startFileUploading } from 'reactApp/modules/uploading/uploading.module';
import { ERetryErrorFileOptions, EUploadingMakeAction, EUploadingState } from 'reactApp/modules/uploading/uploading.types';
import { mapStorageToFolder } from 'reactApp/modules/uploadList/uploadList.constants';
import { EFileError, EFileStatus, EProgressStatus } from 'reactApp/modules/uploadList/uploadList.model';
import { cancelAllUploadFileAction, setProgressStatusAction, updateUploadFilesAction } from 'reactApp/modules/uploadList/uploadList.module';
import { handleCreateFolderForUpload } from 'reactApp/modules/uploadList/uploadList.saga';
import { store } from 'reactApp/store';
import { renderSelectUploadFolderDialog } from 'reactApp/ui/SelectFolderDialog/SelectFolderDialog.toolkit';
import { getOpenState } from 'reactApp/ui/UploadDropArea/UploadDropArea';
import { sendDwh, sendXray } from 'reactApp/utils/ga';
import { roundCustom } from 'reactApp/utils/helpers';
import { getFileExtLimit } from 'reactApp/utils/textHelpers';
import { channel } from 'redux-saga';
import { call, delay, put, select, take } from 'redux-saga/effects';

export function* handleStartFileUploading(action: PayloadAction<UploadingDescriptor>) {
    const descriptor = action.payload;

    if (uploadingService.isUserCanceled) {
        return;
    }

    try {
        const checkResult = yield prepareAndCheck(descriptor);
        if (!checkResult) {
            return;
        }

        descriptor.state = EUploadingState.STATE_UPLOADING;
        descriptor.shouldRetry = false;

        const isUploaded = !!descriptor.cloudHash;

        try {
            if (isUploaded || descriptor.isDirectory) {
                // пропустить шаг аплоада, если уже зааплоадили
                descriptor.state = EUploadingState.STATE_UPLOADED;
            } else {
                yield descriptor.getFile(true);

                const uploader = new Uploader(descriptor);
                descriptor.uploader = uploader;

                const data: UploaderData = yield uploader.upload();

                // Файл успешно загружен.
                descriptor.progress = 100;
                descriptor.size = data.size;
                descriptor.cloudHash = data.hash;
                descriptor.state = EUploadingState.STATE_UPLOADED;

                descriptor.uploaderData = data;
            }

            descriptor.uploader = null;
        } catch (error: any) {
            yield call(preprocessErrorState, error, descriptor);
        }

        if (!uploadingService.isUserCanceled) {
            const start = performance.now();

            yield addToUserCloud(descriptor, descriptor.uploadingPacketConfig.workingDirectory);

            descriptor.uploadingPacketConfig.successCount++;
            descriptor.uploadingPacketConfig.addTime += Math.round(performance.now() - start);
        }

        sendSuccessRadars(descriptor);

        yield finishDescriptorUploading(descriptor);
    } catch (error: any) {
        const retryResult = yield call(processFileUploadError, error, descriptor);

        let retryTimeout = 0;
        switch (retryResult) {
            case ERetryErrorFileOptions.connectionErrorRetry:
                retryTimeout = RETRY_TIMEOUT_CONNECTION_ERROR;
                descriptor.uploadingPacketConfig.hasError = true;
                descriptor.uploadingPacketConfig.hasNetworkError = true;
                break;
            case ERetryErrorFileOptions.shouldRetry:
                retryTimeout = RETRY_TIMEOUT_ERROR_FILE_SKIP;
                break;
            case ERetryErrorFileOptions.shouldRetryImmediately:
                retryTimeout = 1;
                break;
            default:
                break;
        }

        if (retryTimeout) {
            yield delay(retryTimeout);
            descriptor.retryCount++;
            yield put(startFileUploading(descriptor));
        } else if (retryResult === ERetryErrorFileOptions.postpone) {
            uploadingService.postpone(descriptor.id);
        } else {
            descriptor.uploadingPacketConfig.hasError = true;
            uploadingService.cancel(descriptor.id);
            descriptor.uploadingPacketConfig.cancelCount++;
            yield finishDescriptorUploading(descriptor);
        }
    }
}

let waitChannel;

function* prepareAndCheck(descriptor: UploadingDescriptor) {
    const config = descriptor.uploadingPacketConfig;

    const allowedStatesForUpload = [EUploadingState.STATE_PENDING, EUploadingState.STATE_PAUSED, EUploadingState.STATE_SUSPENDED];

    const state = descriptor.state;
    const { isPublic, isHome, isAllDocuments, isIntegration, isInlineIntegration, isIncomingPublic } = getStorage(config.storage);

    if (!allowedStatesForUpload.includes(state)) {
        throw new Error(`wrong state: ${state}`);
    }

    // В альбомах и документах надо создать соответствующие папки перед тем, как начать загружать
    const waitForFolderCreatedName = mapStorageToFolder[config.storage];

    if (waitForFolderCreatedName) {
        yield call(handleCreateFolderForUpload, waitForFolderCreatedName);
    }

    const isReadOnlyDir = yield select(isReadOnly, config.workingDirectory);

    if (isReadOnlyDir) {
        const stack = new Error('ReadOnlyDirectoryFail');
        const reason = new ReadOnlyDirectoryFail(stack, EUploadReasonSource.SOURCE_WEB_CLIENT);

        sendGaUploaderNew('file', 'read_only_directory');
        throw reason;
    }

    if (descriptor.uploadingPacketConfig.makeAction === EUploadingMakeAction.move) {
        descriptor.cloudPath = joinPath(config.workingDirectory, descriptor.localPath);
        descriptor.error = null;

        if (descriptor.isFile) {
            yield put(
                updateUploadFilesAction({
                    descriptorId: descriptor.id,
                    cloudPath: descriptor.initialCloudPath,
                    status: EFileStatus.MOVED,
                })
            );
        }
    }

    // Даём выбрать папку для загрузки везде, кроме
    // а) хомяка - там грузим в текущую сразу,
    // б) в документах по клику по типу документа (или внутри раздела типа документа) - там сразу грузим в папку документов
    // в) паблик папки - там грузим в нее же
    // г) при продолжении загрузки после рефреша
    // д) экрана интеграции облака с электронными журналами (integration) - он должен вести себя как home
    // e) раздела альбомов, при попытке загрузить туда документ
    // ж) isIncomingPublic подраздел документов - там сразу грузим в папку документов
    if (
        !isHome &&
        !isIntegration &&
        !isInlineIntegration &&
        !isPublic &&
        !config.isSpecificDocument &&
        !isAllDocuments &&
        !isIncomingPublic &&
        !config.continueUpload
    ) {
        if (config.albumId) {
            // Если находимся внутри альбома

            const allowedExtensions = getAllowedExtensions(
                store.getState(),
                // @ts-ignore
                EStorageType.albums
            );

            const isFileAllowed = (name) => {
                const ext = (getExtension(name) || '').toLowerCase();
                return allowedExtensions ? allowedExtensions.includes(ext) : true;
            };

            if (isFileAllowed(descriptor.cloudName)) {
                // Если находимся внутри альбома и вставляется фото - то вставляем сразу
                // Иначе (если находимся внутри альбома и вставляется не фото) - выводим окно выбора папки, куда загрузить файл
                return true;
            }
        }

        if (config.wasAnotherDirAsked) {
            /**
             * Необходимо всегда обновлять путь до папки.
             * Это необходимо при загрузке из галереи и других разделов
             * */
            descriptor.cloudPath = joinPath(config.workingDirectory, descriptor.localPath);
        } else {
            if (waitChannel) {
                const stack = new Error('RetryUploadFileFail');
                throw new RetryUploadFileFail(stack, EUploadReasonSource.SOURCE_WEB_CLIENT);
            }

            const prevDirectory = config.workingDirectory;

            waitChannel = channel();

            yield put(closeUploadPanelAction());

            const onSuccess = (answer) => {
                if (answer.action === 'upload') {
                    const { destination } = answer;
                    descriptor.cloudPath = joinPath(destination, descriptor.localPath);
                    config.workingDirectory = destination;
                    config.wasAnotherDirAsked = true;

                    sendGaUploaderNew('file', `upload_from_${descriptor.uploadingPacketConfig.storage}`);
                }

                waitChannel.put(answer.action);
            };

            let action = 'select';
            while (action === 'select') {
                renderSelectUploadFolderDialog({ onSuccess });

                action = yield take(waitChannel);

                // Надо подождать, пока отработает закрытие уже открытого диалога, которое идет через редакс
                yield delay(250);
            }

            waitChannel.close();
            waitChannel = null;

            yield put(openUploadPanelAction());

            if (action !== 'upload') {
                sendGaUploaderNew('file', `skip_from_${descriptor.uploadingPacketConfig.storage}`);
                yield put(
                    updateUploadFilesAction({
                        descriptorId: descriptor.id,
                        status: EFileStatus.CANCEL,
                        error: EFileError.SKIPPED_FILE,
                        currentUpload: false,
                        hideError: true,
                    })
                );

                uploadingService.cancel(descriptor.id);

                yield put(cancelAllUploadFileAction());
                yield put(cancelUploading());
                yield put(setProgressStatusAction({ status: EProgressStatus.COMPLETE }));

                throw descriptor.error;
            } else if (config.workingDirectory !== ROOT_FOLDER_ID) {
                try {
                    const filesHandles = yield entries();

                    const delKeys: string[] = [];
                    const entriesNew: [string, any][] = [];

                    if (filesHandles?.length) {
                        filesHandles.forEach((fileHandle) => {
                            const key = fileHandle[0];
                            delKeys.push(key);
                            entriesNew.push([key.replace(prevDirectory, `${config.workingDirectory}/`), fileHandle[1]]);
                        });
                    }

                    if (delKeys.length) {
                        yield delMany(delKeys);
                    }

                    if (entriesNew.length) {
                        yield setMany(entriesNew);
                    }
                } catch (_) {}
            }
        }
    }

    if (!allowedStatesForUpload.includes(descriptor.state)) {
        throw new Error('upload() called in wrong state');
    }

    return true;
}

function* finishDescriptorUploading(descriptor: UploadingDescriptor) {
    if (!(descriptor.error instanceof ConnectionFail && descriptor.state !== EUploadingState.STATE_DONE)) {
        uploadingService.stopUploading(descriptor);
    }

    if (descriptor.state === EUploadingState.STATE_DONE && descriptor.initialCloudPath !== descriptor.cloudPath) {
        //  Остальные удаляются в загрузчике, но там нет initialCloudPath
        try {
            yield del(descriptor.initialCloudPath);
        } catch (_) {}
    }

    const shouldUpdateFileUploadSpeed =
        descriptor.state === EUploadingState.STATE_DONE &&
        (!!descriptor.uploader?._metrics?.putTime ||
            uploadingService.uploadPacketSpeedMbytesSec === DEFAULT_FILE_UPLOAD_SPEED ||
            descriptor.size < 100 * MB);

    const packet = descriptor.uploadingPacketConfig;
    // Вычитаем descriptor.loaded, так как добавляли их в прогрессе и оно может не равнятся тут descriptor.size
    packet.currentProgressBytes += descriptor.size - descriptor.loaded;

    if (shouldUpdateFileUploadSpeed && descriptor.size > 1024) {
        const timeDiff = (Date.now() - packet.startTime) / 1000;
        const currentPacketSpeed = packet.currentProgressBytes / 1024 / 1204 / timeDiff;

        if (currentPacketSpeed > 0) {
            const speedMbSec = getWeightedMovingAverage(uploadingService.uploadPacketSpeedMbytesSec, currentPacketSpeed);
            uploaderUploadSpeedThrottled(speedMbSec);
            uploadingService.uploadPacketSpeedMbytesSec = speedMbSec;
        }
    }

    if (descriptor.previouslyUploadedBytes > 0 && !descriptor.error) {
        uploaderSmartBytesThrottled(descriptor.previouslyUploadedBytes);
    }

    yield handleProcessUploadQueue();

    const progressStatus = uploadingService.getProgressStatus();

    if (progressStatus.total > 0 && progressStatus.finished >= progressStatus.total) {
        yield call(processUploadingTotalEnd, progressStatus);

        // Фикс для корректного отображения пустых папок в UI загрузчика
        const folders = uploadingService
            .getRootFolders(descriptor.uploadingPacketConfig.packetId)
            .filter((folder) => folder.size === 0 || folder.size === -1);

        for (const folder of folders) {
            yield put(
                updateUploadFilesAction({
                    descriptorId: folder.id,
                    cloudPath: folder.cloudPath,
                    folderCloudPath: folder.cloudPath,
                    currentUpload: false,
                    status: EFileStatus.DONE,
                    extension: 'folder',
                    localPath: folder.cloudName,
                    name: folder.cloudName,
                })
            );
        }

        if (descriptor.uploadingPacketConfig.size === 0) {
            // Если грузят только пустые папки через D&D, то нужен такой фикс для корректной остановки
            yield put(setProgressStatusAction({ status: EProgressStatus.COMPLETE }));
        }
    }
}

function* processUploadingTotalEnd(progressStatus: ReturnType<typeof uploadingService.getProgressStatus>) {
    yield put(uploaderTotalProgress(progressStatus));
}

function sendSuccessRadars(descriptor: UploadingDescriptor) {
    const { extension = '' } = descriptor.nameParts || {};
    const fileSize = descriptor.size;
    const startTime = descriptor.startTime;
    const wasSuspended = descriptor.wasSuspended;
    const isPublic = descriptor.uploadingPacketConfig.storage === EStorageType.public;

    sendGaUploaderNew(`env-${isPublic ? 'public' : 'private'}}`, 'success', {
        [`file-type-${getFileExtLimit(extension)}`]: 1,
        [`platform-${PLATFORM}`]: 1,
    });

    if (fileSize >= BIG_FILE_SZIE) {
        sendGaUploaderNew('successbig');
    }
    if (wasSuspended) {
        sendGaUploaderNew('successresume');
    }

    const extensionData = extInfo.get(extension);
    sendGaUploaderNew(`ftype`, extensionData ? extensionData.kind : 'unk');

    const group = roundCustom(fileSize, sizeGroups.values, sizeGroups.labels).toString();
    sendGaUploaderNew(`filesize`, group);

    if (startTime) {
        const uploadTime = Math.round(performance.now() - startTime);
        sendGaUploaderNew(`time`, '', {
            ms: uploadTime,
        });
        sendGaUploaderNew(group, 'time', {
            ms: uploadTime,
        });

        sendUploadSpeedMetric(fileSize, uploadTime);
    }

    if (isPublic) {
        sendXray(['public-upload-types', getFileExtLimit(extension).toLowerCase()]);
    } else {
        const isAccentUploadOpen = getOpenState();

        sendXray(['upload', 'upload-success', `accent-upload_${isAccentUploadOpen ? 'open' : 'normal'}`]);
    }
}

function* preprocessErrorState(error: UploadingReason, descriptor: UploadingDescriptor) {
    // Файл не загружен.
    const cloudPath = descriptor.cloudPath;
    const descriptorId = descriptor.id;

    descriptor.knownError = false;

    if (!descriptor.wasFailRadarSent) {
        descriptor.wasFailRadarSent = true;

        const size = descriptor.size || 0;
        let { extension = 'un' } = descriptor.nameParts ?? {};
        if (!descriptor.isFile) {
            extension = 'folder';
        }

        const group = roundCustom(size, sizeGroups.values, sizeGroups.labels);
        sendGaUploaderNew(`errsize`, getFileExtLimit(extension), group);
        sendDwh({
            eventCategory: 'upload_error',
            dwhData: {
                extension,
                sizeGroup: group,
                size,
                error: path(['error', 'radarName'], error),
            },
        });
    }

    if (error instanceof UploaderData && error.error) {
        error = error.error;
    }

    if (error instanceof LocalFileNotFoundFail || error instanceof LocalFileNotReadableFail) {
        sendGaUploaderNew('file', 'local_file_not_found');
        yield put(
            updateUploadFilesAction({
                descriptorId,
                cloudPath,
                currentUpload: false,
                status: EFileStatus.ERROR,
                error: EFileError.LOCAL_FILE_NOT_FOUND,
            })
        );
    } else if (error instanceof FileReaderError || error instanceof HashCalcError) {
        yield put(
            updateUploadFilesAction({
                descriptorId,
                cloudPath,
                currentUpload: false,
                status: EFileStatus.ERROR,
                error: EFileError.LOCAL_FILE_READ_ERROR,
            })
        );
    }

    let state;
    const isConnFailOrConflict = error instanceof ConnectionFail || error instanceof FileConflict;
    if (error instanceof UserFileSizeLimitFail || error instanceof OverQuotaFail || error instanceof FileTooLargeError) {
        state = EUploadingState.STATE_PAUSED;
        descriptor.knownError = true;
    } else if (error instanceof UploadingCancel) {
        state = EUploadingState.STATE_CANCEL;
    } else if (isConnFailOrConflict || descriptor.shouldRetry) {
        state = EUploadingState.STATE_SUSPENDED;
        descriptor.shouldRetry = false;
        sendGaUploaderNew('suspended');
        descriptor.knownError = isConnFailOrConflict;
    } else {
        state = EUploadingState.STATE_FAIL;
    }

    descriptor.uploader = null;
    descriptor.progress = 0;
    // @ts-ignore
    descriptor.error = error;
    descriptor.state = state;
    descriptor.wasSuspended = state === EUploadingState.STATE_SUSPENDED;

    if (error instanceof UnsupportedFolderTransferFail) {
        // Safari для папок создает инстанс File,
        // но его никак нельзя использовать.
        descriptor.isFile = false;
        descriptor.isDirectory = true;
        descriptor.isUnreadDirectory = false;
    }

    uploadingLog.error(error);

    throw error;
}
