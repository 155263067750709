import Lottie, { type LottieRefCurrentProps } from 'lottie-react';
import React, { type FC, type MouseEvent, useRef } from 'react';
import { MenuButton } from 'reactApp/ui/ReactViewer/ViewerMenu/MenuButton/MenuButton';

import animation from './animation.json';
import styles from './CloneMenuButton.css';

interface Props {
    onClick?: (evt: MouseEvent) => void;
}

export const CloneMenuButton: FC<Props> = ({ onClick }) => {
    const lottieRef = useRef<LottieRefCurrentProps>(null);

    return (
        <MenuButton
            hint="Сохранить в облако"
            animated={false}
            icon={<Lottie className={styles.icon} animationData={animation} autoplay={false} loop={false} lottieRef={lottieRef} />}
            onClick={onClick}
            onMouseEnter={() => {
                lottieRef?.current?.goToAndPlay(0);
            }}
        />
    );
};
