import config from 'Cloud/config';
import classNames from 'clsx';
import * as React from 'react';
import { type FC, type ReactEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { type OnMessage, useIframeChannel } from 'reactApp/hooks/useIframeChannel';
import { useNormalizedOvidius } from 'reactApp/hooks/useNormalizedOvidius';
import type { CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';
import type { Message } from 'reactApp/ui/ReactViewer/OvidiusV2/OvidiusV2.types';
import { CONTENT_LOAD_TIMEOUT } from 'reactApp/ui/ReactViewer/ReactViewer.constants';
import { Spinner } from 'reactApp/ui/Spinner/Spinner';
import { useStandardAnalytics } from 'reactApp/utils/analytics';
import { OvidiusEvents, StandardPrefix } from 'reactApp/utils/analytics/types';

import styles from './OvidiusV2.css';

interface Props {
    storage: EStorageType;
    file: CloudFile;
    scale?: number;
    setContentForPrint?: (id: string, content: ArrayBuffer[] | null) => void;
    fillParent?: boolean;
    enableViewerMenu?: boolean;
    onError: (error: string) => void;
}

export const OvidiusV2: FC<Props> = ({ storage, file, scale, setContentForPrint, fillParent, enableViewerMenu, onError }) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [isFrameLoaded, setIsFrameLoaded] = useState(false);
    const [isRenderError, setIsRenderError] = useState(false);
    const [isReady, setIsReady] = useState(false);

    const [channelId] = useState(Date.now().toString());

    const iframeUrl = useMemo(
        () =>
            new URL(
                `${config.get('BUILD_URLS')?.ovidius}?channelId=${channelId}&hostname=${window?.location?.hostname}`,
                window?.location?.origin
            ),
        [channelId]
    );

    const { sendRadar } = useStandardAnalytics(StandardPrefix.OVIDIUS, file, storage);

    const onMessageHandler: OnMessage<Message, OvidiusEvents> = useCallback(
        (data) => {
            if (data.type === 'start') {
                setIsFrameLoaded(true);
            } else if (data.type === 'ready') {
                setIsReady(true);
                sendRadar(OvidiusEvents.RENDER, true);
            } else if (data.type === 'error') {
                setIsRenderError(true);
                sendRadar(OvidiusEvents.VIEW_ERR, false, true, data.message);
            } else if (data.type === 'xray') {
                sendRadar(data.event);
            }
        },
        [sendRadar]
    );

    const { postMessage } = useIframeChannel<Message, OvidiusEvents>({
        iframeRef: iframeRef.current,
        isFrameLoaded,
        onMessage: onMessageHandler,
        channelId,
        sendToOrigin: iframeUrl.origin,
        isInsideIframe: false,
    });

    useEffect(() => {
        sendRadar(OvidiusEvents.VIEW_OPEN);
    }, [sendRadar]);

    useEffect(() => {
        setIsReady(false);
        setIsRenderError(false);
    }, [file.id]);

    const { content, error, partsAmount } = useNormalizedOvidius({
        file,
        storage,
        sendRadar,
    });

    useEffect(() => {
        if (content.length === partsAmount && partsAmount > 0) {
            setContentForPrint?.(file.id, content);
        }
    }, [content, file.id, partsAmount, setContentForPrint]);

    useEffect(() => {
        if (content.length === 1) {
            sendRadar(OvidiusEvents.FIRST_CONTENT, true);
        }
    }, [content, sendRadar]);

    useEffect(() => {
        if (content.length && content.length === partsAmount) {
            sendRadar(OvidiusEvents.FULL_CONTENT, true);
        }
    }, [content, partsAmount, sendRadar]);

    useEffect(() => {
        postMessage({
            type: 'file',
            content,
            id: file.id,
            partsAmount: partsAmount.toString(),
            ...(enableViewerMenu ? { offsets: [56, 64] } : {}),
        });
    }, [content, partsAmount, file.id, postMessage]);

    useEffect(() => {
        if (scale) {
            postMessage({ type: 'scale', scale });
        }
    }, [postMessage, scale]);

    useEffect(() => {
        if (isReady || error || isRenderError) {
            return;
        }

        const timeout = window.setTimeout(() => {
            setIsRenderError(true);
            sendRadar(OvidiusEvents.IFRAME_TIMEOUT, false, true, 'iframe timeout');
        }, CONTENT_LOAD_TIMEOUT);

        return () => {
            clearTimeout(timeout);
        };
    }, [isReady, sendRadar, error, isRenderError]);

    const isLoading = useMemo(() => !isFrameLoaded || content?.length === 0 || !isReady, [content?.length, isFrameLoaded, isReady]);

    const handleError = useCallback<ReactEventHandler<HTMLIFrameElement>>(
        (error) => {
            setIsRenderError(true);
            sendRadar(OvidiusEvents.IFRAME_ERR, false, true, `iframe startup error / ${error.type}`);
        },
        [sendRadar]
    );

    if (error || isRenderError) {
        onError(isRenderError ? 'render-error' : error || '');
        return null;
    }

    return (
        <div className={styles.wrapper} data-qa-id="ovidius-v2">
            {isLoading && <Spinner />}
            <iframe
                loading="lazy"
                onError={handleError}
                className={classNames(styles.iframe, {
                    [styles.hide]: isLoading,
                    [styles.fillParent]: fillParent,
                    [styles.iframe_menu]: enableViewerMenu,
                })}
                sandbox="allow-same-origin allow-scripts"
                frameBorder={0}
                src={iframeUrl.toString()}
                ref={iframeRef}
            />
        </div>
    );
};
