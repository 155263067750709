import apiError from 'Cloud/API/apiError';
import api from 'Cloud/Application/api';
import { HttpErrorCodes, isReadOnlyHttpStatus } from 'reactApp/api/HttpErrorCodes';
import { getHomeItemById } from 'reactApp/modules/home/home.selectors';
import { addFolderHelper } from 'reactApp/modules/modifying/modifying.helpers';
import { getPublicItemById } from 'reactApp/modules/public/public.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { HttpError } from 'reactApp/modules/uploading/errors/HttpError';
import { InvalidCharactesFail } from 'reactApp/modules/uploading/fails/InvalidCharactesFail';
import { PathTooLongFail } from 'reactApp/modules/uploading/fails/PathTooLongFail';
import { getResponseStatusCodeFromOldApi } from 'reactApp/modules/uploading/helpers/cloudFs/getResponseStatusCodeFromOldApi';
import { MAX_RETRIES_COUNT } from 'reactApp/modules/uploading/serviceClasses/Uploader';
import type { UploadingDescriptor } from 'reactApp/modules/uploading/serviceClasses/UploadingDescriptor';
import { EUploadReasonSource } from 'reactApp/modules/uploading/serviceClasses/UploadingReason';
import { select } from 'redux-saga/effects';

import { ConnectionFail } from '../../fails/ConnectionFail';
import { ReadOnlyDirectoryFail } from '../../fails/ReadOnlyDirectoryFail';
import { WrongDestinationPathFail } from '../../fails/WrongDestinationPathFail';
import { isRetryableHttpStatus } from '../uploading.helpers';

export function* addFolder(descriptor: UploadingDescriptor) {
    try {
        let cloudPath = yield new Promise(function (resolve, reject) {
            const deferred = api.folder.add({
                home: descriptor.cloudPath,
                conflict: 'ignore', // TODO: strict
            });

            deferred.done(function (data) {
                if (typeof data === 'string') {
                    resolve(data);
                } else {
                    resolve(data.id);
                }
            });

            deferred.fail(function (error, status, response) {
                reject(response);
            });
        });

        const isOwnPublic =
            descriptor.uploadingPacketConfig.storage === EStorageType.public && descriptor.uploadingPacketConfig.isOwnPublic;
        let workingDirectory = descriptor.uploadingPacketConfig.workingDirectory;
        let pathParts: string[];

        if (isOwnPublic && descriptor.uploadingPacketConfig.ownPublicFolderWeblink) {
            // загрузили все в хомяк, но мы в паблике и фейк элементы надо добавлять туда
            cloudPath = cloudPath.replace(workingDirectory, '');
            workingDirectory = descriptor.uploadingPacketConfig.ownPublicFolderWeblink;
            pathParts = [workingDirectory, ...cloudPath.split('/')];
        } else {
            pathParts = cloudPath.split('/'); // → ['', 'folder1', 'folder2']
        }

        let parent = pathParts[0];

        for (let i = 1; i < pathParts.length; i++) {
            const name = pathParts[i];
            const path = `${parent}${isOwnPublic && !name ? '' : '/'}${name}`;
            const cloudItem =
                descriptor.uploadingPacketConfig.storage === EStorageType.public
                    ? yield select(getPublicItemById, path)
                    : yield select(getHomeItemById, path);

            if (path !== workingDirectory && !cloudItem) {
                if (!parent) {
                    parent = '/';
                }

                const fakeCloudItem = {
                    id: path,
                    parent,
                    name,
                    kind: 'folder',
                    mtime: Math.floor(Date.now() / 1000),
                };

                if (isOwnPublic) {
                    fakeCloudItem['weblink'] = path;
                }

                addFolderHelper({
                    item: fakeCloudItem,
                    parent,
                    skipXhr: true,
                    conflictMode: undefined,
                    storage: descriptor.uploadingPacketConfig.storage,
                });
            }

            parent = path;
        }

        return cloudPath;
    } catch (error: any) {
        const source = EUploadReasonSource.SOURCE_WEB_BACKEND;
        let stack;
        let reason;
        const xhr = error.getXHR();
        const status: number = getResponseStatusCodeFromOldApi(xhr.status);
        const isRetryableStatus = isRetryableHttpStatus(status);

        if (status && !isRetryableStatus) {
            const isBadRequest = status === HttpErrorCodes.BAD_REQUEST;
            const isReadOnly = isReadOnlyHttpStatus(status);

            if (isBadRequest || isReadOnly) {
                const data = error.getData();
                const errorCode = apiError.getCode(data);

                switch (errorCode) {
                    case PathTooLongFail.WEB_BACKEND_ERROR:
                        stack = new Error('PathTooLongFail');
                        reason = new PathTooLongFail(stack, source);

                        break;

                    case InvalidCharactesFail.WEB_BACKEND_ERROR:
                        stack = new Error('InvalidCharactesFail');
                        reason = new InvalidCharactesFail(stack, source);

                        break;

                    case ReadOnlyDirectoryFail.WEB_BACKEND_ERROR:
                        stack = new Error('ReadOnlyDirectoryFail');
                        reason = new ReadOnlyDirectoryFail(stack, source);

                        break;

                    case WrongDestinationPathFail.WEB_BACKEND_ERROR:
                        stack = new Error('WrongDestinationPathFail');
                        reason = new WrongDestinationPathFail(stack, source);

                        break;

                    case 'exists':
                        // TODO: const, conflict strict
                        break;
                }
            }

            if (!reason) {
                stack = new Error('HttpError');
                reason = new HttpError(stack, source, status, xhr.responseText);
            }
        } else {
            stack = new Error('ConnectionFail');
            const retry = !status || (isRetryableStatus && 'retryCount' in descriptor && descriptor.retryCount < MAX_RETRIES_COUNT);
            reason = retry ? new ConnectionFail(stack, source, status) : new HttpError(stack, source, status, '');
        }

        throw reason;
    }
}
