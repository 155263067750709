import { HintPosition } from 'reactApp/ui/Hint/Hint';

const PADDING = 10;
const LEFT_PADDING = 0;
const TOP_PADDING = 35;

export interface Position {
    top: number;
    left: number;
}

export const getMinHorizontalPosition = (windowWidth: number, rectWidth: number, paddings: number): number => {
    const realPosX = windowWidth - rectWidth - paddings;
    return realPosX < 0 ? paddings : realPosX;
};

const calculateLeftOffset = (hintRect: DOMRect, left: number, windowWidth: number, padding: number) => {
    let offset = left;
    if (hintRect.width + left + padding >= windowWidth) {
        offset = getMinHorizontalPosition(windowWidth, hintRect.width, padding);
    } else {
        offset += padding;
    }

    return offset;
};

const calculateTopOffset = (hintRect: DOMRect, top: number, windowHeight: number, padding: number) => {
    let offset = top;

    if (hintRect.height + top + padding >= window.scrollY + windowHeight) {
        offset = windowHeight - hintRect.height - padding;
        if (offset < 0) {
            offset = padding;
        }
    } else {
        offset += padding;
    }

    return offset;
};

const calculatePositionRelativeToCursor = (element: HTMLElement, currentPos: Position): Position => {
    const hintRect = element.getBoundingClientRect();

    const windowWidth = Math.min(window.innerWidth, window.document.body.clientWidth);
    const windowHeight = Math.min(window.innerHeight, window.document.body.clientHeight || window.innerHeight);

    return {
        left: calculateLeftOffset(hintRect, currentPos.left, windowWidth, PADDING),
        top: calculateTopOffset(hintRect, currentPos.top, windowHeight, PADDING),
    };
};

export const calculatePosition = (
    element: HTMLElement,
    currentPos: Position,
    positionStrategy: HintPosition,
    children: HTMLElement | null
): void => {
    const hintRect = element.getBoundingClientRect();

    const windowWidth = Math.min(window.innerWidth, window.document.body.clientWidth);
    const windowHeight = Math.min(window.innerHeight, window.document.body.clientHeight || window.innerHeight);

    let left, top;

    /*
        CLOUDWEB-17165: Позиционируем тултип относительно элемента (если есть возможность)
        В противном случае - относительно курсора
     */
    if (positionStrategy === HintPosition.relativeToElement && children) {
        try {
            const rect = children.getBoundingClientRect();
            left = calculateLeftOffset(hintRect, rect.right, windowWidth, LEFT_PADDING);
            top = calculateTopOffset(hintRect, rect.top, windowHeight, TOP_PADDING);
        } catch {
            ({ left, top } = calculatePositionRelativeToCursor(element, currentPos));
        }
    } else {
        ({ left, top } = calculatePositionRelativeToCursor(element, currentPos));
    }

    element.style.left = `${left}px`;
    element.style.top = `${top}px`;
    element.style.opacity = '1';
};
