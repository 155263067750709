import { getFeature } from 'Cloud/Application/FeaturesEs6';
import type { quotaStates } from 'server/types/context/IUser';

import { overquotaNewFullBlocked } from './overquotaNewFullBlocked';

// CLOUDWEB-18435
// Добавить проверку значения текущей квоты для всех статусов (кроме after_clear, restore_requested, cleared)
export const overStatesCheckedByQuota: quotaStates[] = getFeature('overq-states-checked-by-quota') || [
    'started',
    'blocked',
    'unblocked',
    'new',
];

if (overquotaNewFullBlocked) {
    overStatesCheckedByQuota.push('full_blocked');
}
