import { IS_REACT_PROMO_NEW_YEAR } from 'reactApp/appHelpers/configHelpers';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { isIntegrationStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { renderConfirmEmailDialog, renderConfirmEmailScreen } from 'reactApp/ui/ConfirmEmail/renderConfirmEmail';
import { openSpecialBanner } from 'reactApp/ui/SpecialBanner/SpecialBanner.helpers';
import { sendGa } from 'reactApp/utils/ga';
import { call, cancel, put, putResolve, select } from 'redux-saga/effects';

import { confirmEmail } from './actions/confirmEmail.action';
import { loadSocialUser } from './actions/loadSocialUser.action';
import { getEmail, isEmailAbsent, isEmailConfirmed, isEmailConfirming } from './socialUser.selectors';

const snackbarId = 'confirming-social-email';

const renderEmailBanner = ({ email, domain }: { email: string; domain: string }) => {
    openSpecialBanner({
        text: `На адрес ${email} отправлено письмо для подтверждения почты. Откройте его и перейдите по ссылке.`,
        onButtonClick: () => {
            sendGa('social-user', 'no-mail-click', domain);
            renderDialog({ email, domain });
            closePopupHelper(popupNames.SPECIAL_BANNER);
        },
        buttonText: 'Я не получил письмо',
    });
};

const renderDialog = ({ email, domain }) => {
    renderConfirmEmailDialog({ onClose: () => renderEmailBanner({ email, domain }) });
};

export function* socialUserConfirmEmail() {
    const storage = yield select(getCurrentStorage);
    const isSocialUser = yield select(UserSelectors.isSocialUser);
    const email = yield select(getEmail);
    const domain = yield select(UserSelectors.getDomain);

    if (
        storage === EStorageType.public ||
        storage === EStorageType.editor ||
        !isSocialUser ||
        IS_REACT_PROMO_NEW_YEAR ||
        isIntegrationStorage(storage)
    ) {
        return;
    }

    try {
        yield putResolve(yield call(loadSocialUser));
    } catch (_) {
        yield cancel();
    }

    const isUserEmailAbsent = yield select(isEmailAbsent);
    const isUserEmailConfirmed = yield select(isEmailConfirmed);
    const isUserEmailConfirming = yield select(isEmailConfirming);

    if (isUserEmailConfirmed) {
        return;
    }

    if (isUserEmailAbsent) {
        renderConfirmEmailScreen({ onClose: () => renderEmailBanner({ email, domain }) });

        return;
    }

    if (isUserEmailConfirming) {
        renderEmailBanner({ email, domain });
    }

    const queryParams = yield select(SettingsSelectors.getQueryParams);

    if (queryParams.action !== 'social-auth') {
        return;
    }

    try {
        yield putResolve(yield call(confirmEmail, queryParams.token));
    } catch (_) {
        yield cancel();
    }

    const isAbsent = yield select(isEmailAbsent);

    if (!isAbsent) {
        yield put(
            showSnackbarAction({
                id: snackbarId,
                closable: true,
                text: 'Ваша почта активирована',
                type: SnackbarTypes.success,
            })
        );
    }
}
