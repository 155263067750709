import { fileSystemEntryReader } from 'reactApp/modules/uploading/helpers/fs/FileSystemEntryReader';
import { testIsInstanceOfFileSystemFileHandle } from 'reactApp/modules/uploading/helpers/fs/fs.helpers';
import { tryGetFileFromHandle } from 'reactApp/modules/uploading/helpers/fs/tryGetFileFromHandle';
import { isAllowedConflictResolution } from 'reactApp/modules/uploading/serviceClasses/helpers';
import type { Uploader } from 'reactApp/modules/uploading/serviceClasses/Uploader';
import type { UploadingPacketConfig } from 'reactApp/modules/uploading/serviceClasses/UploadingPacketConfig';
import type { UploadingReason } from 'reactApp/modules/uploading/serviceClasses/UploadingReason';
import { type IErrorFile, EConflictResolution, EUploadingMakeAction, EUploadingState } from 'reactApp/modules/uploading/uploading.types';

import { JavaScriptError } from '../errors/JavaScriptError';
import type { UploaderData } from './UploaderData';

const finishedStates = [EUploadingState.STATE_DONE, EUploadingState.STATE_FAIL, EUploadingState.STATE_CANCEL];
const cancelableStates = [
    EUploadingState.STATE_PENDING,
    EUploadingState.STATE_SUSPENDED,
    EUploadingState.STATE_PAUSED,
    EUploadingState.STATE_UPLOADING,
    EUploadingState.STATE_UPLOADED,
];

export class UploadingDescriptor {
    constructor({
        id,
        file,
        fileHandle,
        entry,
        size,
        localName,
        localPath,
        isFile,
        isDirectory,
        isUnreadDirectory,
        uploadingPacketConfig,
    }: {
        id: string;
        file?: File;
        fileHandle?: FileSystemHandle | null;
        entry?: FileSystemEntry | FileSystemDirectoryEntry | null;
        size?: number;
        localName: string;
        localPath: string;
        isFile: boolean;
        isDirectory: boolean;
        isUnreadDirectory: boolean;
        uploadingPacketConfig: UploadingPacketConfig;
    }) {
        this.id = id;
        this.file = file || null;
        this.fileHandle = fileHandle || null;
        this.entry = entry || null;
        this.localName = localName;
        this.localPath = localPath;
        this.isFile = isFile;
        this.isDirectory = isDirectory;
        this.isUnreadDirectory = isUnreadDirectory;

        this.uploadingPacketConfig = uploadingPacketConfig;

        if (size) {
            this.size = size;
        }
    }

    id = '';

    // Имя файла или папки (в Unicode NFC) в локальной ФС.
    localName = '';
    // Путь к файлу или папке (в Unicode NFC) в локальной ФС.
    localPath = '';
    // Имя файла или папки (в Unicode NFC) в Облаке.
    cloudName = '';
    /**
     * Имя файла или папки для отображения.
     * Пример: в macOS "file:name.ext" отображается, как "file/name.ext"
     */
    visibleName = '';

    visiblePath = '';

    cloudPath = '';
    initialCloudPath = '';

    // Тут храним параметры для всей пачки файлов
    uploadingPacketConfig: UploadingPacketConfig;

    // Имя и расширение.
    nameParts: {
        // Имя файла (без расширения) или папки.
        name: string;
        // Расширение файла в исходном регистре.
        extension: string;
    } | null = null;

    // Облачный hash файла (SHA1 + salt).
    cloudHash = '';

    hash = '';

    // Размер файла (в байтах).
    size = -1;

    /**
     * Что-то вроде дескриптора файла или папки в локальной ФС,
     * содержат информацию о имени и относительном пути.
     * Служит для получения инстанса File.
     */
    entry: FileSystemEntry | FileSystemDirectoryEntry | null = null;

    // Соответствует файлу в локальной ФС. Служит для получения содержимого файла.
    // Извне дескриптора стараться использовать через getFile
    // Так как файл мог лежать в очереди долго и быть модифицирован,
    // в getFile идет переполучение файла, иначе будет ошибка чтения/ошибка доступа
    file: File | null | undefined = null;
    // Указатель/handle файла в локальной ФС. Служит для получения содержимого объекта файла File.
    fileHandle: FileSystemHandle | null = null;

    // Любая ошибка.
    error: UploadingReason | null = null;
    // Описание ошибки для UI
    errorFile: IErrorFile | null = null;
    hasNameTooLongAutoFix = false;
    hasInvalidCharAutoFix = false;

    isFile = false;
    isDirectory = false;

    // Используется для рекурсивного чтения DirectoryEntry.
    isUnreadDirectory = false;

    // Родительский каталог.
    parent: UploadingDescriptor | null = null;

    // Коллекция дескрипторов дочерних файлов и папок.
    children: UploadingDescriptor[] = [];

    // Прогресс загрузки (в процентах).
    progress = 0;
    // ...  в байтах
    loaded = 0;
    speed = 0;
    previouslyUploadedBytes = 0;

    // Состояние загрузки файла.
    state: EUploadingState = EUploadingState.STATE_PENDING;

    isCanceledFolder = false;

    uploaderData: null | UploaderData = null;
    uploader: Uploader | null = null;
    wasFailRadarSent = false;
    wasSuspended = false;

    firstReadError = false;
    firstReadErrorStatus = '';
    hashCalcError = false;
    hashCalcErrorStatus = '';
    fileReadError = false;
    fileReadErrorStatus = '';
    putFileError = false;
    putErrorStatus;
    putErrorStage = '';
    addFileError = false;
    addFileErrorStatus;
    knownError = false;
    retryCount = 0;
    shouldRetry = false;

    conflictResolution: EConflictResolution = EConflictResolution.strict;

    startTime = 0;

    isFinishedState = (state: EUploadingState) => finishedStates.includes(state);

    async getFile(forceRegetFile = false) {
        if (forceRegetFile && testIsInstanceOfFileSystemFileHandle(this.fileHandle)) {
            this.file = await tryGetFileFromHandle(this.fileHandle as FileSystemFileHandle);
            this.size = this.size || (this.file?.size ?? -1);
        }

        const entry = this.entry;
        let file = this.file;

        // Если есть entry, то тоже берем от него файл заново, если был forceRegetFile
        if (file && (!entry || !forceRegetFile)) {
            return file;
        }

        if (!entry) {
            return null;
        }
        try {
            file = await fileSystemEntryReader.getFile(entry as FileSystemFileEntry);

            this.file = file;
            this.size = file.size;

            return file;
        } catch (error) {
            // @ts-ignore
            this.error = error;
            this.state = EUploadingState.STATE_FAIL;

            if (error instanceof JavaScriptError) {
                return null;
            }

            throw error;
        }
    }

    hasCancelableState() {
        return cancelableStates.includes(this.state);
    }

    failPaused() {
        if (this.state === EUploadingState.STATE_PAUSED) {
            this.state = EUploadingState.STATE_FAIL;
        }
    }

    setConflictResolution(resolution: EConflictResolution) {
        if (!isAllowedConflictResolution(resolution)) {
            resolution = EConflictResolution.strict;
        }

        this.conflictResolution = resolution;
    }

    hasCanceledParent() {
        let parent = this?.parent;
        while (parent) {
            if (parent.state === EUploadingState.STATE_CANCEL || parent.isCanceledFolder) {
                return true;
            }
            parent = parent.parent;
        }

        return false;
    }

    getTopMostCanceledDescriptor() {
        let parent = this?.parent;
        while (parent) {
            if (!parent.parent) {
                break;
            }
            if (
                parent.parent.state !== EUploadingState.STATE_CANCEL &&
                !parent.parent.isCanceledFolder &&
                this.uploadingPacketConfig.makeAction !== EUploadingMakeAction.cancel
            ) {
                break;
            }

            parent = parent.parent;
        }
        if (
            parent?.state === EUploadingState.STATE_CANCEL ||
            parent?.isCanceledFolder ||
            this.uploadingPacketConfig.makeAction === EUploadingMakeAction.cancel
        ) {
            return parent;
        }
        if (this.state === EUploadingState.STATE_CANCEL) {
            return this;
        }
        return null;
    }

    setErrorState(reason: UploadingReason, state: EUploadingState) {
        this.error = reason;
        this.state = state;
    }
}
