import classNames from 'clsx';
import React, { type ReactElement, memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NEW_PORTAL_HEADER } from 'reactApp/appHelpers/configHelpers';
import { loadSharedLinksStartRequest } from 'reactApp/modules/shared/shared.module';
import { getSeoPublished, getSharedLoadingState } from 'reactApp/modules/shared/shared.selectors';
import { SharedTab } from 'reactApp/modules/shared/shared.type';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import { Empty } from 'reactApp/ui/Empty/Empty';
import { emptyIndexedConfig } from 'reactApp/ui/Empty/new/Empty.Indexed.config';
import { Footer } from 'reactApp/ui/Footer/Footer';
import { SharedTabs } from 'reactApp/ui/SharedTabs/SharedTabs';
import { sendGa } from 'reactApp/utils/ga';
import opener from 'reactApp/utils/opener';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './SharedIndexedPage.css';

export const SharedIndexedPage = memo((): ReactElement => {
    const dispatch = useDispatch();

    const { isLoaded } = useSelector(getSharedLoadingState);
    const items = useSelector(getSeoPublished);

    const showEmpty = isLoaded && !items?.length;

    const handleItemClick = useCallback(({ id }) => {
        opener(`/home${encodeURIComponent(id)}`, !id);
    }, []);

    useEffect(() => {
        sendGa('indexed-page', 'show');
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'indexed' });
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'page-entered', source: 'indexed' });

        dispatch(loadSharedLinksStartRequest());
    }, [dispatch]);

    if (showEmpty) {
        return (
            <div
                className={classNames(styles.root, {
                    [styles.root_new_portal_header]: NEW_PORTAL_HEADER,
                })}
            >
                <div className={classNames({ [styles.headerContainer_new_portal_header]: NEW_PORTAL_HEADER })}>
                    <BreadcrumbsContainer />
                    <SharedTabs tab={SharedTab.indexed} />
                    <Empty.New config={emptyIndexedConfig} />
                    <Footer storage={EStorageType.sharedLinks} isAbsolutePos={true} withMargins />
                </div>
            </div>
        );
    }

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_new_portal_header]: NEW_PORTAL_HEADER,
            })}
        >
            <div className={classNames({ [styles.headerContainer_new_portal_header]: NEW_PORTAL_HEADER })}>
                {isLoaded ? <BreadcrumbsContainer /> : <div className={styles.breadPlaceholder} />}
                <SharedTabs tab={SharedTab.indexed} />
            </div>
            {!!isLoaded && (
                <DataList
                    goTo={handleItemClick}
                    itemsList={items}
                    storage={EStorageType.sharedLinks}
                    selectableClassName={styles.datalistSelectable}
                />
            )}
        </div>
    );
});

SharedIndexedPage.displayName = 'SharedIndexedPage';
