import './LoopButton.css';

import type videojs from 'video.js';

import type { VideoPlayer } from '../VideoPlayer.types';
import { LoopButton } from './LoopButton';

export interface LoopPluginOptions {
    placementIndex?: number;
}

export interface VideoPlayerWithLoopPlugin extends VideoPlayer {
    _loopPlugin: LoopPlugin;
    loopPlugin: (options?: LoopPluginOptions) => void;
}

export class LoopPlugin {
    player: VideoPlayerWithLoopPlugin;
    config: LoopPluginOptions;
    _loopButton?: videojs.Button;

    constructor(player: VideoPlayerWithLoopPlugin, options?: LoopPluginOptions) {
        this.player = player;
        this.config = options || {};

        this.createLoopButton();
    }

    /**
     * Adds the loop button to the player control bar.
     */
    createLoopButton() {
        const player = this.player;

        this._loopButton = new LoopButton(player, { name: 'LoopButton' });

        const placementIndex = player.controlBar.children().length - 4;
        const concreteButtonInstance = player.controlBar.addChild(
            this._loopButton!,
            { componentClass: 'loopButton' },
            this.config.placementIndex || placementIndex
        );

        concreteButtonInstance.addClass('vjs-loop-button');

        concreteButtonInstance.removeClass('vjs-hidden');
    }
}

const onPlayerReady = (player: VideoPlayerWithLoopPlugin, options?: LoopPluginOptions) => {
    player.addClass('vjs-loop-plugin');
    player._loopPlugin = new LoopPlugin(player, options);
};

const loopPlugin = function (this: VideoPlayerWithLoopPlugin, options?: LoopPluginOptions) {
    this.ready(() => {
        onPlayerReady(this, options);
    });
};

export default loopPlugin;
