/* eslint-disable max-lines-per-function */
import { type MouseEvent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { IS_ONPREMISE } from 'reactApp/appHelpers/configHelpers';
import { isInlineEditInProgress } from 'reactApp/modules/modifying/modifying.selectors';
import { resetSelect } from 'reactApp/modules/selections/selections.actions';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { onClickCreateNew, onItemClick } from 'reactApp/ui/Datalist/UserActions/onClickAction';
import { onContextMenu, onContextMenuItem } from 'reactApp/ui/Datalist/UserActions/onContextMenuAction';
import { onDocumentClick } from 'reactApp/ui/Datalist/UserActions/onDocumentAction';
import { onBeginSelection, onSelect, onSelectBoxSelection, onSelecting } from 'reactApp/ui/Datalist/UserActions/onSelectAction';

export const useHandleCallback = ({
    isModalOpen,
    setActiveIndex,
    dispatch,
    selectedItem,
    prevSelectHandler,
    storage,
    gaSuffix,
    folder,
    items,
    selectedIdxs,
    selectingIdxs,
    setSelectingIdxs,
    routerParams,
    itemCreateNewRef,
    viewMode,
    isPopup,
    onItemSelectCallback,
    attachType,
    attachFolderId,
    currentAllDocsType,
    featureFormatsData,
    currentCategory,
}) => {
    const isInlineEditing = useSelector(isInlineEditInProgress);
    // tempexp_17125-start
    const isOverquota = useSelector(UserQuotaSelectors.isOverquota);
    const isBizUser = useSelector(UserSelectors.isBizUser);
    const isCorpUser = useSelector(UserSelectors.isCorpUser);
    const isSocialUser = useSelector(UserSelectors.isSocialUser);
    const blockViewer = isOverquota && !isBizUser && !isCorpUser && !IS_ONPREMISE && !isSocialUser;
    // tempexp_17125-end

    const handleOnDocumentClick = useCallback(
        (event: MouseEvent) => {
            onDocumentClick({ event, isModalOpen, setActiveIndex, dispatch, isInlineEditing });
        },
        [resetSelect, dispatch, isModalOpen, selectedItem, isInlineEditing]
    );

    const handleOnSelectGroupClick = useCallback((e) => {
        e.nativeEvent.stopImmediatePropagation();
        // На mousedown в SelectableGroup срабатывает onBeginSelection
        // Но не срабатывает onEndSelection
        // Поэтому дополнительно восстановим onselectstart на click
        document.onselectstart = prevSelectHandler.current;
    }, []);

    const handleBeginSelection = useCallback(
        (event) => {
            return onBeginSelection({ event, dispatch, prevSelectHandler, itemCreateNewRef, isInlineEditing });
        },
        [resetSelect, dispatch, prevSelectHandler, isInlineEditing]
    );

    const handleOnContextMenu = useCallback(
        (event) => {
            onContextMenu({ event, gaSuffix, storage, isPopup });
        },
        [storage, gaSuffix, isPopup]
    );

    const handleOnContextMenuItem = useCallback(
        (event) => {
            onContextMenuItem({ event, gaSuffix, storage });
        },
        [storage]
    );

    const handleOnSelect = useCallback(
        ({ event, id, isCheckbox }: { event: MouseEvent; id: string; isCheckbox?: boolean }): void => {
            onSelect({ event, setActiveIndex, items, id, isCheckbox, storage, dispatch });
            onItemSelectCallback?.({ id });
        },
        [dispatch, items?.length, storage, setActiveIndex, onItemSelectCallback]
    );

    const handleSelectboxSelection = useCallback(
        (idxs, event) => {
            onSelectBoxSelection({
                event,
                setActiveIndex,
                idxs,
                selectedIdxs,
                selectingIdxs,
                storage,
                setSelectingIdxs,
                dispatch,
                prevSelectHandler,
            });
        },
        [dispatch, setSelectingIdxs, selectingIdxs, setActiveIndex, storage, selectedIdxs]
    );

    const handleSelecting = useCallback(
        (idxs) => {
            onSelecting({ setActiveIndex, idxs, selectingIdxs, setSelectingIdxs });
        },
        [selectingIdxs, setSelectingIdxs]
    );

    const handleOnClick = useCallback(
        (id, item) => {
            // tempexp_17125-next-line
            onItemClick({
                id,
                item,
                folder,
                dispatch,
                storage,
                viewMode,
                attachType,
                attachFolderId,
                featureFormatsData,
                blockViewer,
                currentCategory,
            });
        },
        // tempexp_17125-next-line
        [dispatch, routerParams, viewMode, attachType, attachFolderId, featureFormatsData, blockViewer]
    );

    const handleClickCreateNew = useCallback(
        (e) => {
            onClickCreateNew(e, { gaSuffix, storage, currentAllDocsType });
        },
        [storage, currentAllDocsType]
    );

    return {
        handleOnDocumentClick,
        handleOnSelectGroupClick,
        handleBeginSelection,
        handleOnContextMenu,
        handleOnContextMenuItem,
        handleOnSelect,
        handleSelectboxSelection,
        handleSelecting,
        handleOnClick,
        handleClickCreateNew,
    };
};
