import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_FAMILY_USER, IS_PHONE_BROWSER, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { isFamilySubsAvailable } from 'reactApp/appHelpers/featuresHelpers';
import { isPurchaseDisabled } from 'reactApp/appHelpers/featuresHelpers/features/superAppAuthorizationInfo';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { askUserForPaymentFiles } from 'reactApp/modules/uploadList/oldUploadListBridge';
import type { IInputFile } from 'reactApp/modules/uploadList/uploadList.model';
import { updateUser } from 'reactApp/modules/user/user.thunkActions';
import { ESplashScreenAction, sendSplashScreenAnalytics } from 'reactApp/ui/Mobile/SplashScreen/SplashScreen.analytics';
import { truncateTextMiddle } from 'reactApp/utils/textHelpers';
import { EQueryParams } from 'server/helpers/getRequestParams';

import styles from './PopupWarnings.css';

interface IProps {
    file: IInputFile;
    isStrangerOwner: boolean;
}

const OverQuota = ({ file, isStrangerOwner }: IProps) => {
    const dispatch = useDispatch();

    const storage = useSelector(getCurrentStorage);
    const isInlineIntegration = storage === EStorageType.inlineIntegration;
    const fromDeeplink = getQueryParams()[EQueryParams.fromDeeplink];
    const isSuperAppWebView = IS_WEBVIEW && fromDeeplink;
    const isDisabledPurchase = isSuperAppWebView && isPurchaseDisabled();

    const sendAnalytics = (action: ESplashScreenAction) => {
        sendSplashScreenAnalytics({
            action,
            name: `load_file_overquota${IS_PHONE_BROWSER ? '_touch' : ''}`,
        });
    };

    const handleOverQuota = useCallback(() => {
        sendAnalytics(ESplashScreenAction.click);
        askUserForPaymentFiles('payment', file.descriptorId);
    }, []);

    const handleOverQuotaSkip = useCallback(() => {
        sendAnalytics(ESplashScreenAction.skip);
        askUserForPaymentFiles('skip', file.descriptorId);
    }, []);

    const renderHeader = useCallback(() => <div>Невозможно загрузить файл «{truncateTextMiddle(file.name, 22)}»</div>, [file.name]);

    const renderContent = useCallback(() => {
        const content = isStrangerOwner
            ? 'Загрузка невозможна. У владельца этой папки недостаточно места в Облаке для ваших файлов'
            : 'Ваше Облако переполнено. Удалите лишнее или увеличьте его размер.';

        return (
            <div className={styles.content}>
                <div>{content}</div>
            </div>
        );
    }, []);

    useEffect(() => {
        sendAnalytics(ESplashScreenAction.show);
        if (!isStrangerOwner && IS_FAMILY_USER && isFamilySubsAvailable) {
            dispatch(updateUser());
        }
    }, []);

    return (
        //  TODO: вернуть, когда сможем открывать лендинг квоты под соцаккаунтом
        <BaseConfirmDialog
            dataQAId="overquota-dialog"
            renderHeader={renderHeader}
            successButtonText="Посмотреть тарифы"
            cancelButtonText="Пропустить файл"
            buttons={isInlineIntegration || isDisabledPurchase ? [EButtonType.cancel] : [EButtonType.accept, EButtonType.cancel]}
            onSuccess={handleOverQuota}
            onClose={handleOverQuotaSkip}
            renderContent={renderContent}
            className={styles.root}
        />
    );
};

export const OverQuotaComponent = memo(OverQuota);
