/* eslint-disable max-lines */
import { EStorageType } from 'reactApp/modules/storage/storage.types';

export const storages = {
    [EStorageType.home]: {
        id: EStorageType.home,
        title: 'Все файлы',
        isAvailableOnMobile: true,
        isAvailableToAnonymous: false,
        isSearchAvailable: true,
        settings: {
            path_as_id: true,
            hierarchy: true,
            add: true,
            remove: true,
            sub_folders: true,
            publish_files: true,
            publish_folders: true,
            download: true,
            multiDownload: true,
            move: true,
            rename: true,
            cache: true,
            root: true,
            edit: true,
            revisions: true,
        },
    },
    [EStorageType.sharedLinks]: {
        id: EStorageType.sharedLinks,
        title: 'Вы поделились',
        isSearchAvailable: true,
        gaPage: 'shared-links',
        settings: {
            hierarchy: false,
            path_as_id: true,
            add: false,
            remove: false,
            sub_folders: false,
            publish_files: true,
            publish_folders: false,
            download: true,
            multiDownload: true,
            move: false,
            rename: false,
            cache: true,
            root: true,
            edit: false,
            revisions: false,
        },
    },
    [EStorageType.sharedAutodelete]: {
        id: EStorageType.sharedAutodelete,
        title: 'Автоудаляемые',
        isSearchAvailable: true,
        gaPage: 'shared-autodelete',
        settings: {
            hierarchy: false,
            path_as_id: true,
            add: false,
            remove: false,
            sub_folders: false,
            publish_files: false,
            publish_folders: true,
            download: true,
            multiDownload: true,
            move: false,
            rename: false,
            cache: false,
            root: true,
            edit: false,
            revisions: false,
        },
    },
    [EStorageType.sharedIncoming]: {
        id: EStorageType.sharedIncoming,
        title: 'С вами поделились',
        isSearchAvailable: true,
        gaPage: 'shared-incoming',
        settings: {
            hierarchy: false,
            path_as_id: false,
            add: false,
            remove: true,
            sub_folders: false,
            publish_files: true,
            publish_folders: false,
            download: true,
            multiDownload: true,
            move: false,
            rename: false,
            cache: false,
            root: true,
            edit: false,
            revisions: false,
        },
    },
    [EStorageType.public]: {
        id: EStorageType.public,
        title: 'Публичные',
        isAvailableToAnonymous: true,
        isAvailableOnMobile: true,
        settings: {
            path_as_id: false,
            hierarchy: true,
            add: true,
            remove: false,
            sub_folders: false,
            publish_files: false,
            publish_folders: false,
            download: true,
            multiDownload: true,
            move: false,
            rename: false,
            cache: true,
            root: false,
            edit: false,
            revisions: false,
        },
    },
    static: {
        id: 'static',
        settings: {},
    },
    [EStorageType.search]: {
        id: EStorageType.search,
        title: 'Результаты поиска',
        isSearchAvailable: true,
        isAvailableOnMobile: true,
        gaPage: 'search',
        settings: {
            remove: true,
            publish_files: true,
            publish_folders: true,
            download: true,
            move: true,
            rename: true,
            root: true,
            edit: true,
            path_as_id: true,
            folderNameLink: true,
        },
    },
    [EStorageType.r7]: {
        id: EStorageType.r7,
        title: 'R7 office',
        isAvailableOnMobile: true,
        settings: {
            path_as_id: true,
            hierarchy: true,
            add: false,
            remove: false,
            sub_folders: true,
            publish_files: true,
            publish_folders: false,
            download: true,
            multiDownload: false,
            move: false,
            rename: true,
            cache: false,
            root: false,
            edit: true,
            revisions: false,
        },
    },
    [EStorageType.myoffice]: {
        id: EStorageType.myoffice,
        title: 'Мой офис',
        settings: {
            path_as_id: true,
            hierarchy: true,
            add: false,
            remove: false,
            sub_folders: true,
            publish_files: true,
            publish_folders: false,
            download: true,
            multiDownload: false,
            move: false,
            rename: true,
            cache: false,
            root: false,
            edit: true,
            revisions: false,
        },
    },
    [EStorageType.myofficeAttaches]: {
        id: EStorageType.myofficeAttaches,
        title: 'Мой офис',
        settings: {
            path_as_id: true,
            hierarchy: true,
            add: false,
            remove: false,
            sub_folders: true,
            publish_files: true,
            publish_folders: false,
            download: true,
            multiDownload: false,
            move: false,
            rename: true,
            cache: false,
            root: false,
            edit: true,
            revisions: false,
        },
    },

    [EStorageType.r7wopi]: {
        id: EStorageType.r7wopi,
        title: 'Р7 WOPI',
        settings: {
            path_as_id: true,
            hierarchy: true,
            add: false,
            remove: false,
            sub_folders: true,
            publish_files: true,
            publish_folders: false,
            download: true,
            multiDownload: false,
            move: false,
            rename: true,
            cache: false,
            root: false,
            edit: true,
            revisions: false,
        },
    },
    [EStorageType.stock]: {
        id: EStorageType.stock,
        title: 'Почтовые вложения',
        isAvailableToAnonymous: true,
        isAvailableOnMobile: true,
        settings: {
            path_as_id: false,
            hierarchy: false,
            add: false,
            remove: false,
            sub_folders: false,
            share_files: false,
            share_folders: false,
            download: true,
            multiDownload: true,
            move: false,
            rename: false,
            cache: true,
            root: false,
            edit: false,
            revisions: false,
        },
    },
    [EStorageType.trashbin]: {
        id: EStorageType.trashbin,
        title: 'Корзина',
        isSearchAvailable: true,
        isAvailableOnMobile: true,
        isAvailableToAnonymous: false,
        gaPage: 'trashbin',
        settings: {
            path_as_id: false,
            hierarchy: false,
            add: false,
            remove: false,
            sub_folders: false,
            share_files: false,
            share_folders: false,
            download: false,
            multiDownload: false,
            move: false,
            rename: false,
            cache: true,
            root: true,
            edit: false,
            revisions: false,
        },
    },
    [EStorageType.attaches]: {
        id: EStorageType.attaches,
        title: 'Из почты',
        isAvailableOnMobile: true,
        isSearchAvailable: true,
        settings: {
            path_as_id: true,
            hierarchy: false,
            add: false,
            remove: false,
            sub_folders: true,
            share_files: false,
            share_folders: false,
            download: true,
            multiDownload: true,
            move: false,
            rename: false,
            cache: true,
            root: true,
            edit: false,
            revisions: false,
        },
    },
    [EStorageType.favorites]: {
        id: EStorageType.favorites,
        title: 'Избранные',
        isSearchAvailable: true,
        gaPage: 'favorites',
        settings: {
            path_as_id: true,
            hierarchy: false,
            add: true,
            remove: true,
            sub_folders: false,
            share_files: true,
            share_folders: false,
            publish_files: true,
            download: true,
            multiDownload: true,
            move: false,
            rename: false,
            cache: true,
            root: true,
            edit: false,
            revisions: false,
        },
    },
    [EStorageType.feed]: {
        id: EStorageType.feed,
        title: 'Последние файлы',
        isSearchAvailable: true,
        gaPage: 'feed',
        settings: {
            path_as_id: true,
            hierarchy: false,
            add: true,
            remove: true,
            sub_folders: false,
            share_files: true,
            share_folders: false,
            publish_files: true,
            download: true,
            multiDownload: true,
            move: false,
            rename: false,
            cache: true,
            root: true,
            edit: false,
            revisions: false,
        },
    },
    [EStorageType.recommend]: {
        id: EStorageType.recommend,
        title: 'VK рекомендует',
        gaPage: 'recommend',
        settings: {
            path_as_id: true,
            hierarchy: false,
            add: false,
            remove: false,
            sub_folders: false,
            share_files: false,
            share_folders: false,
            publish_files: false,
            download: true,
            multiDownload: true,
            move: false,
            rename: false,
            cache: true,
            root: true,
            edit: false,
            revisions: false,
        },
    },
    [EStorageType.family]: {
        id: EStorageType.family,
        title: 'Общая подписка',
        isAvailableOnMobile: true,
        isAvailableToAnonymous: false,
        isSearchAvailable: false,
        gaPage: 'family',
        settings: {},
    },
    [EStorageType.start]: {
        id: EStorageType.start,
        title: 'Быстрый доступ',
        isSearchAvailable: true,
        gaPage: 'start',
        settings: {
            path_as_id: true,
            hierarchy: true,
            add: false,
            remove: false,
            sub_folders: true,
            publish_files: true,
            publish_folders: true,
            download: false,
            multiDownload: false,
            move: false,
            rename: false,
            cache: true,
            root: true,
            edit: false,
            revisions: true,
        },
    },
    [EStorageType.gallery]: {
        id: EStorageType.gallery,
        title: 'Галерея',
        isSearchAvailable: true,
        isAvailableOnMobile: true,
        gaPage: 'gallery',
        settings: {
            path_as_id: true,
            hierarchy: false,
            add: true,
            remove: true,
            sub_folders: false,
            publish_files: true,
            publish_folders: false,
            download: true,
            multiDownload: true,
            move: false,
            rename: false,
            cache: true,
            root: true,
            edit: false,
            revisions: true,
        },
    },
    [EStorageType.documents]: {
        id: EStorageType.documents,
        title: 'Личные документы',
        isSearchAvailable: true,
        gaPage: 'documents',
        settings: {
            path_as_id: true,
            hierarchy: true,
            add: false,
            remove: true,
            sub_folders: true,
            publish_files: true,
            publish_folders: false,
            download: true,
            multiDownload: true,
            move: true,
            rename: false,
            cache: true,
            root: true,
            edit: false,
            revisions: true,
        },
    },
    [EStorageType.alldocuments]: {
        id: EStorageType.alldocuments,
        title: 'Документы',
        isSearchAvailable: true,
        gaPage: 'alldocuments',
        settings: {
            path_as_id: true,
            hierarchy: false,
            add: false,
            remove: true,
            sub_folders: false,
            share_files: true,
            share_folders: false,
            publish_files: true,
            download: true,
            multiDownload: true,
            move: true,
            rename: true,
            cache: true,
            root: true,
            edit: true,
            revisions: false,
        },
    },
    [EStorageType.albums]: {
        id: EStorageType.albums,
        title: 'Альбомы',
        isSearchAvailable: true,
        gaPage: 'albums',
        settings: {
            path_as_id: false,
            hierarchy: true,
            add: false,
            remove: true,
            sub_folders: false,
            publish_files: false,
            publish_folders: true,
            download: true,
            multiDownload: true,
            move: false,
            rename: false,
            cache: false,
            root: true,
            edit: false,
            revisions: false,
        },
    },
    [EStorageType.subscriptions]: {
        id: EStorageType.subscriptions,
        isAvailableOnMobile: true,
        isAvailableToAnonymous: false,
        isSearchAvailable: false,
        gaPage: 'subscriptions',
        settings: {},
    },
    [EStorageType.embedded]: {
        id: EStorageType.embedded,
        title: 'Встраиваемый просмотр внешних файлов',
        isAvailableOnMobile: true,
        isAvailableToAnonymous: true,
        isSearchAvailable: false,
        gaPage: 'embedded',
        settings: {},
    },
    [EStorageType.pdfEdit]: {
        id: EStorageType.pdfEdit,
        title: 'Редактор PDF',
        isAvailableOnMobile: true,
        isAvailableToAnonymous: false,
        isSearchAvailable: false,
        settings: {
            path_as_id: true,
            hierarchy: false,
            add: false,
            remove: true,
            sub_folders: false,
            publish_files: true,
            publish_folders: false,
            download: true,
            multiDownload: true,
            move: true,
            rename: true,
            cache: true,
            root: true,
            edit: true,
            revisions: true,
        },
    },
    [EStorageType.integration]: {
        id: EStorageType.integration,
        title: 'Все файлы',
        isAvailableOnMobile: false,
        isAvailableToAnonymous: false,
        isSearchAvailable: false,
        gaPage: 'integration',
        settings: {},
    },
    [EStorageType.sharingAttach]: {
        id: EStorageType.sharingAttach,
        title: 'Почтовые вложения',
        isAvailableOnMobile: false,
        isAvailableToAnonymous: true,
        isSearchAvailable: false,
        gaPage: 'sharing-attach',
        settings: {},
    },
    [EStorageType.inlineIntegration]: {
        id: EStorageType.inlineIntegration,
        title: 'Все файлы',
        isAvailableOnMobile: false,
        isAvailableToAnonymous: false,
        isSearchAvailable: false,
        gaPage: 'inline-integration',
        settings: {},
    },
    [EStorageType.templates]: {
        id: EStorageType.templates,
        title: 'Бизнесу',
        gaPage: 'templates',
        isReactPage: true,
        isAvailableOnMobile: false,
        isAvailableToAnonymous: false,
        isSearchAvailable: true,
        settings: {
            path_as_id: true,
            hierarchy: true,
            add: true,
            remove: true,
            sub_folders: true,
            publish_files: true,
            publish_folders: true,
            download: true,
            multiDownload: true,
            move: true,
            rename: true,
            cache: true,
            root: true,
            edit: true,
            revisions: true,
        },
    },
};
